import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number-2";
import React from "react";
interface State {
  open: boolean;
  handleClose: () => void;
  handleRemindMeNextTimeButton: () => void;
  handleSendCodeButton: () => void;
  generateOTP: () => void;
  formik: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  // height: "37vh",
  p: 4,
  bgcolor: "background.paper",
  borderRadius: "8px",
};

const TwoFactorVerification = (props: State) => {
  const {
    open,
    handleClose,
    handleRemindMeNextTimeButton,
    handleSendCodeButton,
    formik,
  } = props;
  const maskPhoneNumber = (phoneNumber: any) => {
    // Extract last 4 digits
    const lastFourDigits = phoneNumber.slice(-4);

    // Mask the rest of the digits with "*"
    const maskedPart = phoneNumber.slice(0, -4).replace(/./g, "*");

    // Combine masked part with last 4 digits
    const maskedPhoneNumber = maskedPart + lastFourDigits;

    return maskedPhoneNumber;
  };

  const handleChange = (e: any) => {
    return;
  };
  return (
    <Box>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center" }}>
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "20px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                marginTop: "5px",
                marginBottom: "10px",
              }}
            >
              Protect your account
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              padding: "2%",
              marginTop: "2%",
            }}
          >
            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "5%",
              }}
            >
              We’ll send you a one-time passcode now to this number{" "}
              {formik.values.mobile && maskPhoneNumber(formik.values.mobile)}{" "}
              and each time you log in to ensure that you’r the only one with
              access to your account
            </Typography>

            {/* <MuiPhoneNumber
              sx={{ width: "55%" }}
              defaultCountry={"in"}
              countryCodeEditable={true}
              disabled
              id="mobile"
              name="mobile1"
              label="Phone"
              variant="outlined"
              placeholder="Phone"
              InputLabelProps={{ shrink: true }}
              value={formik.values.mobile}
              onChange={handleChange}
            /> */}

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "100%",
                marginTop: "3%",
              }}
            >
              <Button
                onClick={handleRemindMeNextTimeButton}
                sx={{ textTransform: "none" }}
                variant="outlined"
                color="warning"
              >
                Remind me next time
              </Button>
              <Button
                onClick={handleSendCodeButton}
                sx={{ textTransform: "none" }}
                variant="contained"
                color="warning"
              >
                Send Code
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default TwoFactorVerification;
