import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const UPDATE_THE_DRIVER_COMMISSION = gql`
  mutation AddDriverCommission(
    $employeeIds: JSONString!
    $locationId: Int!
    $commission: Int!
    $businessId: Int!
  ) {
    addDriverCommission(
      employeeIds: $employeeIds
      commission: $commission
      locationId: $locationId
      businessId: $businessId
    ) {
      errorMessage
      success
      commission {
        commission
        locationId
        employeeId
      }
    }
  }
`;
export const CREATE_THE_DRIVER_COMMISSION = gql`
  mutation AddDriverCommission(
    $employeeIds: JSONString!
    $locationId: Int!
    $commission: Int!
    $businessId: Int!
  ) {
    addDriverCommission(
      employeeIds: $employeeIds
      commission: $commission
      locationId: $locationId
      businessId: $businessId
    ) {
      errorMessage
      success
      commission {
        commission
        locationId
        employeeId
      }
    }
  }
`;

export const CREATE_DRIVER_PETTY_CASH = gql`
  mutation AddDriverPettycash(
    $employeeIds: JSONString!
    $date: Date!
    $pettycash: Float!
    $businessId: Int!
    $locationId: Int!
  ) {
    addDriverPettycash(
      businessId: $businessId
      employeeIds: $employeeIds
      locationId: $locationId
      date: $date
      pettycash: $pettycash
    ) {
      errorMessage
      success
      pettycash {
        employeeId
        pettycash
        date
      }
    }
  }
`;

export const UPDATE_DRIVER_PETTY_CASH = gql`
  mutation AddDriverPettycash(
    $employeeIds: JSONString!
    $date: Date!
    $pettycash: Float!
    $businessId: Int!
    $locationId: Int!
  ) {
    addDriverPettycash(
      businessId: $businessId
      employeeIds: $employeeIds
      locationId: $locationId
      date: $date
      pettycash: $pettycash
    ) {
      errorMessage
      success
      pettycash {
        employeeId
        pettycash
        date
      }
    }
  }
`;

export const ADD_DELIVERY_SETTINGS = gql`
  mutation AddDeliverySettings(
    $storeId: ID!
    $businessId: ID!
    $extraMileShippingRate: Boolean
    $ordersPerHour: Int
    $rateForExtraMiles: Float
    $maximumDistance: Int
  ) {
    addDeliverySettings(
      storeId: $storeId
      businessId: $businessId
      extraMileShippingRate: $extraMileShippingRate
      ordersPerHour: $ordersPerHour
      rateForExtraMiles: $rateForExtraMiles
      maximumDistance: $maximumDistance
    ) {
      message
      deliverySettings {
        deliverySettingId
        paymentType
        ordersPerHour
        rateForExtraMiles
        extraMileShippingRate
      }
      success
    }
  }
`;

export const ADD_DELIVERY_RULE = gql`
  mutation AddDeliveryRule(
    $postCode: String
    $deliverySetting: Int!
    $shippingRate: Float
    $areaName: String
    $distance: Int
    $deliveryTime: JSONString
    $postCodesForZone: JSONString
    $cartAmount: Float
    $colourCode: String
    $extraShippingRate: Float
    $timeTaken: Int
    $zoneName: String
    $zoneType: String
    $isDefault: Boolean
  ) {
    addDeliveryRule(
      postCode: $postCode
      deliverySetting: $deliverySetting
      shippingRate: $shippingRate
      areaName: $areaName
      distance: $distance
      deliveryTime: $deliveryTime
      postCodesForZone: $postCodesForZone
      cartAmount: $cartAmount
      colourCode: $colourCode
      extraShippingRate: $extraShippingRate
      timeTaken: $timeTaken
      zoneName: $zoneName
      zoneType: $zoneType
      isDefault: $isDefault
    ) {
      message
      deliveryRule {
        deliveryRuleId
        deliverySetting {
          deliverySettingId
        }
        shippingRate
        areaName
        distance
      }
      success
    }
  }
`;

export const UPDATE_DELIVERY_RULE = gql`
  mutation EditDeliveryRule(
    $deliveryRule: Int!
    $shippingRate: Float
    $areaName: String
    $colourCode: String
    $distance: Int
    $deliveryTime: JSONString
    $postCodesForZone: JSONString
    $zoneName: String
    $postCode: String
    $cartAmount: Float
    $extraShippingRate: Float
    $timeTaken: Int
  ) {
    editDeliveryRule(
      deliveryRule: $deliveryRule
      postCodesForZone: $postCodesForZone
      shippingRate: $shippingRate
      areaName: $areaName
      colourCode: $colourCode
      distance: $distance
      deliveryTime: $deliveryTime
      zoneName: $zoneName
      postCode: $postCode
      cartAmount: $cartAmount
      extraShippingRate: $extraShippingRate
      timeTaken: $timeTaken
    ) {
      message
      deliveryRule {
        deliveryRuleId
        deliverySetting {
          deliverySettingId
        }
        shippingRate
        areaName
        distance
      }
      success
    }
  }
`;

export const DELETE_DELIVERY_RULE = gql`
  mutation DeleteDeliveryRule($deliveryRule: Int!) {
    deleteDeliveryRule(deliveryRule: $deliveryRule) {
      message
      success
    }
  }
`;

export const UPDATE_DEFAULT_DELIVERY_RULE = gql`
  mutation ChangeDefaultRule(
    $deliverySetting: ID!
    $zoneType: String!
    $isDefault: Boolean!
  ) {
    changeDefaultRule(
      deliverySetting: $deliverySetting
      zoneType: $zoneType
      isDefault: $isDefault
    ) {
      success
      message
    }
  }
`;

export const CHANGE_DEFAULT_DELIVERY_RULE = gql`
  mutation ChangeDefaultRule(
    $deliverySetting: Int
    $zoneType: String!
    $isDefault: Boolean
    $locationId: Int!
  ) {
    changeDefaultRule(
      deliverySetting: $deliverySetting
      zoneType: $zoneType
      isDefault: $isDefault
      locationId: $locationId
    ) {
      success
      message
    }
  }
`;
