import React, { useState } from "react";
import { Box, Button, Typography, Link } from "@mui/material";
import OnBordHome from "../../../assets/onBordHome.png";
import { useNavigation } from "../Steper/StepperNavigationContext/useNavigation";
import Stepper from "../Steper";

const Wellcome = () => {
  const [showSteper, setShowSteper] = useState(false);
  const email = sessionStorage.getItem("email")

  const { stepperCounter, setStepperCounter } = useNavigation();

  const stepperHandler = () => {
    setStepperCounter(stepperCounter + 1);
    setShowSteper(true);
  };
  return (
    <>
      {showSteper ? (
        <Stepper />
      ) : (
        <Box
          maxWidth={"870px"}
          width={"100%"}
          marginX={"auto"}
          display={"flex"}
          justifyContent={"center"}
          marginTop={"5vh"}
          marginBottom={"5vh"}
        >
          <Box
            width={"100%"}
            textAlign={"center"}
            paddingX={{ md: "0px", xs: "15px" }}
          >
            <Typography
              fontFamily={"poppins"}
              color={"#F38B08"}
              fontSize={{ xs: "28px", sm: "32px" }}
              fontWeight={700}
              letterSpacing={"0.5px"}
              lineHeight={{ sm: "24px", xs: "32px" }}
            >
              Welcome to Smart Serve Solutions
            </Typography>

            <Typography
              fontFamily={"poppins"}
              fontSize={"16px"}
              fontWeight={"500"}
              color={"#333333"}
              lineHeight={"28px"}
              letterSpacing={"0.5px"}
              mt={"2vh"}
            >
              Congratulations for Embarking on Streamlined Restaurant Outlet
              Management.
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "28px",
                letterSpacing: "0.5px",
                color: "#707070",
                marginTop: { xs: "10px", sm: "2px" },
              }}
            >
              {`You've successfully signed up with the email
              ${email}`}
            </Typography>

            <Box marginTop={"3vh"}>
              <Box
                sx={{
                  maxWidth: { md: "fit-content", xs: "314px" },
                  marginX: "auto",
                  height: '40vh'
                }}
              >
                <img
                  height={"100%"}
                  width={"100%"}
                  src={OnBordHome}
                  alt="OnBordHome"
                />
              </Box>
            </Box>
            <Box marginTop={"21.5px"}>
              <Typography
                textAlign={"center"}
                sx={{
                  fontFamily: "poppins",
                  fontSize: "18px",
                  lineHeight: "26px",
                  letterSpacing: "0.5px",
                  color: "#707070",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                Follow through with the
                <Link
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: "500",
                    fontSize: "18px",
                    lineHeight: "26px",
                    letterSpacing: "0.5px",
                    color: "#F38B08",
                    marginTop: "21.5px",
                  }}
                  marginX={"4px"}
                  underline="none"
                >
                  Business Setup
                </Link>
                Process
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "18px",
                  lineHeight: "26px",
                  color: "#707070",
                  width: "89%",
                  textAlign: "center",
                  marginX: "auto",
                }}
              >
                Choose your business type, service y, select a plan, and you'll
                be prepared to operate your business seamlessly.
              </Typography>
            </Box>
            <Button
              onClick={() => stepperHandler()}
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                lineHeight: "27px",
                fontSize: "18px",
                backgroundColor: "#F38B08",
                color: "#FEFEFE",
                marginTop: "44px",
                maxWidth: "320px",
                width: "100%",
                marginBottom: "20px",
                height: "48px",
                border: "1px solid #F38B08",
                "&:hover": {
                  backgroundColor: "#F38B08",
                },
              }}
            >
              Lets Go !
            </Button>
          </Box>
        </Box>
      )}
    </>
  );
};

export default Wellcome;
