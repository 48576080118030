/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";

import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { ActivityLogReportType } from "../../../types/activityLogReports";
import { ACTIVITY_LOG_REPORTS } from "../../../graphicalQl/usequery/activityReport";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Employee Name", value: "employee_name" },
  { label: "Role", value: "role_name" },
  { label: "Date", value: "created_on_str" },
  { label: "Updated / Changed", value: "note" },
  { label: "Location", value: "store_name" },
  { label: "IP Address", value: "ip_address" },
];

const ActivityLogReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Employee Name", value: "employee_name" },
    { label: "Role", value: "role_name" },
    { label: "Date", value: "created_on_str" },
    { label: "Updated / Changed", value: "note" },
    { label: "Location", value: "store_name" },
    { label: "IP Address", value: "ip_address" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "employee_name",
    "role_name",
    "created_on_str",
    "note",
    "store_name",
    "ip_address",
  ]);
  const [activityLogReports, setActivityLogReports] = useState<
    Array<ActivityLogReportType>
  >([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)

  // const [locationId, setLocationId] = useState<number>(44);
  const [employee, setEmployee] = useState<number>();
  const [change, setChange] = useState<string>("category");
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const businessId = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { loading: activityLogReportsLoading, data: activityLogReportsData } =
    useQuery(
      ACTIVITY_LOG_REPORTS(
        +businessId,
        date.startDate,
        date.endDate,
        fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
        reportLocation || +locationId,

        change,
        reportEmployee
      ),
      {
        fetchPolicy: "no-cache",
        context: { clientName: "categoryClient" },
      }
    );

  const getActivityLogReports = () => {
    if (!activityLogReportsLoading) {
      const activityLogReports = activityLogReportsData?.activityLogReports;
      const newActivityLogReports = JSON.parse(activityLogReports || "[]").map(
        (logs: any) => ({
          ...logs,
          created_on_str: dayjs(logs.created_on_str).format(
            "HH:MM; DD-MM-YYYY"
          ),
        })
      );
      setActivityLogReports(newActivityLogReports);
    }
  };

  useEffect(() => {
    getActivityLogReports();
  }, [activityLogReportsLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      {activityLogReports.length ? (
        <Box mt={4}>
          <ReportsTable
            labels={labels}
            headers={headers}
            options={options}
            setLabels={setLabels}
            setHeaders={setHeaders}
            data={activityLogReports}
            title={"Activity Log Details"}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default ActivityLogReport;
