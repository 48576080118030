import React, { useEffect } from "react";
import { t } from "i18next";

import {
  Box,
  FormGroup,
  TextField,
  FormControlLabel,
  Typography,
  FormControl,
  Switch,
  Button,
} from "@mui/material";
import "../options/createProduct.css";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CONFIGURATION_IN_CREATION } from "../../graphicalQl/mutation/productMutation";
import { useDispatch, useSelector } from "react-redux";
import { setproductConfiguration } from "../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Divider } from "primereact/divider";
import { UPDATE_PRODUCT_CONFIGURATION } from "../../graphicalQl/mutation/productMutation";
import {
  CONFIGURATION_DETAILS_IN_PRODUCT,
  FILTERING_PRODUCTS_LIST,
} from "../../graphicalQl/usequery/productsListQuery";

const validationSchemaPart1 = Yup.object({
  minQuantity: Yup.number().typeError("* must be a number"),
  maxSlices: Yup.number().typeError(" * must be a number"),
  alertAt: Yup.number().typeError("* must be a number"),
  minDiscountAllowed: Yup.number().typeError("* must be a number"),
  maxDiscountAllowed: Yup.number().typeError("* must be a number"),
});

interface Props {
  handleBack: () => void;
  handleComplete: () => void;
}
const ProductConfiguration = (props: Props) => {
  const { handleBack, handleComplete } = props;
  const dispatch = useDispatch();

  const { productId } = useSelector((state: any) => state.pricing);
  const { productIdByEdit } = useSelector((state: any) => state.app);

  const {
    minQuantity,
    maxSlices,
    openPrice,
    allowRefunds,
    allowPrice,
    minDiscountAllowed,
    maxDiscountAllowed,
    TrackStock,
    LowStockAlert,
    alertAt,
  } = useSelector((state: any) => state.configuration);

  const handleInputChange2 = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setproductConfiguration(field, e.target.value));
  };

  const handleInputChange3 = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setproductConfiguration(field, e.target.checked));
  };

  const formik = useFormik({
    initialValues: {
      minQuantity: minQuantity,
      maxSlices: maxSlices,
      openPrice: allowRefunds,
      allowRefunds: allowRefunds,
      allowPrice: allowPrice,
      LowStockAlert: LowStockAlert,
      TrackStock: TrackStock,
      alertAt: alertAt,
      minDiscountAllowed: minDiscountAllowed,
      maxDiscountAllowed: maxDiscountAllowed,
    },
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (productIdByEdit) {
        editProduct({
          variables: filteredSingleData,
          refetchQueries: [FILTERING_PRODUCTS_LIST],
          onCompleted: () => {
            handleComplete();
          },
        });
      } else {
        upDateProduct({
          variables: filteredbulkData,
          refetchQueries: [FILTERING_PRODUCTS_LIST],
          onCompleted: () => {
            handleComplete();
          },
        });
      }
    },
  });

  const [upDateProduct, { loading: bulkLoading }] = useMutation(UPDATE_CONFIGURATION_IN_CREATION, {
    context: { clientName: "categoryClient" },
  });

  const [editProduct, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT_CONFIGURATION, {
    context: { clientName: "categoryClient" },
  });

  // const { data: productDataFromApi } = useQuery(CONFIGURATION_DETAILS_IN_PRODUCT, {
  //   context: { clientName: "categoryClient" },variables:{productId:+productIdByEdit}

  // });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      minQuantity: minQuantity,
      maxSlices: maxSlices,
      openPrice: allowRefunds,
      allowRefunds: allowRefunds,
      allowPrice: allowPrice,
      LowStockAlert: LowStockAlert,
      TrackStock: TrackStock,
      alertAt: alertAt,
      minDiscountAllowed: minDiscountAllowed,
      maxDiscountAllowed: maxDiscountAllowed,
    });
  }, [
    minQuantity,
    maxSlices,
    openPrice,
    allowRefunds,
    allowPrice,
    LowStockAlert,
    TrackStock,
    alertAt,
    minDiscountAllowed,
    maxDiscountAllowed,
  ]);

  //     useEffect(()=>{
  // if(productDataFromApi&& productDataFromApi.product){
  //   dispatch(setproductConfiguration("minQuantity" , productDataFromApi.product.minQuantity  ));
  //   dispatch(setproductConfiguration("maxSlices" , productDataFromApi.product.maxNoOfSlices  ));
  //   dispatch(setproductConfiguration("openPrice" , productDataFromApi.product.allowOpenPrice  ));
  //   dispatch(setproductConfiguration("allowRefunds" , productDataFromApi.product.allowRefunds  ));
  //   dispatch(setproductConfiguration("allowPrice" , productDataFromApi.product.allowDiscounts  ));
  //   dispatch(setproductConfiguration("alertAt" , productDataFromApi.product.lowStockAlertAt  ));
  //   dispatch(setproductConfiguration("minDiscountAllowed" , productDataFromApi.product.minDiscountAllowed  ));
  //   dispatch(setproductConfiguration("maxDiscountAllowed" , productDataFromApi.product.maxDiscountAllowed  ));
  //   dispatch(setproductConfiguration("TrackStock" , productDataFromApi.product.isTrackStock  ));
  //   dispatch(setproductConfiguration("LowStockAlert" , productDataFromApi.product.isTrackLowStock  ));

  // }
  //     },[productDataFromApi])

  const bulkData = {
    productIds: JSON.stringify(productId),
    minQuantity: +minQuantity,

    allowOpenPrice: openPrice,
    allowDiscounts: allowPrice,
    allowRefunds: allowRefunds,
    maxDiscountAllowed: +maxDiscountAllowed,
    minDiscountAllowed: +minDiscountAllowed,
    isTrackStock: TrackStock,
    lowStockAlertAt: +alertAt,
    isTrackLowStock: LowStockAlert,
  };

  const filteredbulkData = Object.fromEntries(
    Object.entries(bulkData).filter(
      ([_, value]) => value !== null && value !== ""
    )
  );
  const singleData = {
    productId: +productIdByEdit,
    minQuantity: +minQuantity,

    allowOpenPrice: openPrice,
    allowDiscounts: allowPrice,
    allowRefunds: allowRefunds,
    maxDiscountAllowed: +maxDiscountAllowed,
    minDiscountAllowed: +minDiscountAllowed,
    isTrackStock: TrackStock,
    lowStockAlertAt: +alertAt,
    isTrackLowStock: LowStockAlert,
  };

  const filteredSingleData = Object.fromEntries(
    Object.entries(singleData).filter(
      ([_, value]) => value !== null && value !== ""
    )
  );
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          padding: "1.25rem 0.75rem ",
          display: "flex",
          flexDirection: "column",
          gap: "1.25rem",
          border: "1px solid #EEEEEE",
          marginBottom: "1.5rem",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <div>
            <TextField
              label={t("product.min_qty")}
              color="warning"
              name="minQuantity"
              value={formik.values.minQuantity}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                const val = e.target.value;

                if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                  handleInputChange2("minQuantity", e);
                  formik.handleChange(e);
                }
              }}
              onBlur={formik.handleBlur}
            />
            {formik.touched.minQuantity && formik.errors.minQuantity && (
              <div style={{ color: "red" }}>
                {formik.errors.minQuantity as string}
              </div>
            )}
          </div>
          {/* <div>
        <TextField
          label="Max No Of Slices"
          color="warning"
          style={{ marginLeft: '4%' }}
          name='maxSlices'
          value={formik.values.maxSlices}
          onChange={(e: React.ChangeEvent<HTMLInputElement>)=>{handleInputChange2("maxSlices",e);formik.handleChange(e)}}
          onBlur={formik.handleBlur}
        />
       
       {formik.touched.maxSlices && formik.errors.maxSlices && (
                <div style={{color:"red"}}>{formik.errors.maxSlices as string}</div>
              )}
               </div> */}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "3.5rem" }}>
          <FormControl style={{ marginLeft: "0px" }}>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={openPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange3("openPrice", e);
                      formik.handleChange(e);
                    }}
                    name="openPrice"
                    color="warning"
                  />
                }
                label={
                  <Typography className="create-product-switch-text">
                    {t("product.open_price")}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={allowRefunds}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange3("allowRefunds", e);
                      formik.handleChange(e);
                    }}
                    name="allowRefunds"
                    color="warning"
                  />
                }
                label={
                  <Typography className="create-product-switch-text">
                    {t("product.allow_refunds")}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "3.5rem" }}>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={allowPrice}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange3("allowPrice", e);
                      formik.handleChange(e);
                    }}
                    name="allowPrice"
                    color="warning"
                  />
                }
                label={
                  <Typography className="create-product-switch-text">
                    {t("product.allow_discounts")}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          {allowPrice && (
            <>
              <div>
                <TextField
                  label={t("product.min_discount_allowed")}
                  color="warning"
                  name="minDiscountAllowed"
                  style={{}}
                  value={formik.values.minDiscountAllowed}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const val = e.target.value;

                    if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                      handleInputChange2("minDiscountAllowed", e);
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.minDiscountAllowed &&
                  formik.errors.minDiscountAllowed && (
                    <div style={{ color: "red" }}>
                      {formik.errors.minDiscountAllowed as string}
                    </div>
                  )}
              </div>
              <div>
                <TextField
                  label={t("product.max_discount_allowed")}
                  color="warning"
                  style={{ marginLeft: "4%" }}
                  name="maxDiscountAllowed"
                  value={formik.values.maxDiscountAllowed}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const val = e.target.value;

                    if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                      handleInputChange2("maxDiscountAllowed", e);
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                />
                {formik.touched.maxDiscountAllowed &&
                  formik.errors.maxDiscountAllowed && (
                    <div style={{ color: "red" }}>
                      {formik.errors.maxDiscountAllowed as string}
                    </div>
                  )}
              </div>
            </>
          )}
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: "3.5rem" }}>
          <FormControl>
            <FormGroup>
              <FormControlLabel
                control={
                  <Switch
                    checked={formik.values.TrackStock}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      handleInputChange3("TrackStock", e);
                      formik.handleChange(e);
                    }}
                    name="TrackStock"
                    color="warning"
                  />
                }
                label={
                  <Typography className="create-product-switch-text">
                    {t("product.track_stock")}
                  </Typography>
                }
                labelPlacement="start"
              />
            </FormGroup>
          </FormControl>
          {TrackStock && (
            <>
              <FormControl>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.LowStockAlert}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          handleInputChange3("LowStockAlert", e);
                          formik.handleChange(e);
                        }}
                        name="LowStockAlert"
                        color="warning"
                      />
                    }
                    label={
                      <Typography className="create-product-switch-text">
                        {t("product.low_stock_alert")}
                      </Typography>
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
              {LowStockAlert && (
                <div>
                  <TextField
                    label={t("product.alert_at")}
                    color="warning"
                    style={{}}
                    name="alertAt"
                    value={formik.values.alertAt}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val = e.target.value;

                      if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                        handleInputChange2("alertAt", e);
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.alertAt && formik.errors.alertAt && (
                    <div style={{ color: "red" }}>
                      {formik.errors.alertAt as string}
                    </div>
                  )}
                </div>
              )}
            </>
          )}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button className="create_category_BacK_button" onClick={handleBack}>
          {<ArrowBackIcon />}
          {t("product.back")}{" "}
        </Button>
        <Button
          className="createCategory-next-active"
          sx={{ marginLeft: "3px", textTransform: "none" }}
          type="submit"
          disabled={bulkLoading || updateLoading}
        >
          {t("product.Save_next")} {<ArrowForwardIcon color="warning" />}
        </Button>
      </Box>
    </form>
  );
};
export default ProductConfiguration;
