import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Divider } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useMutation, useQuery } from "@apollo/client";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ONLINE_ORDERING_SETTINGS } from "../../graphicalQl/mutation/onlineOrdering";
import { useTranslation } from "react-i18next";
import { GET_ONLINE_ORDERING_SETTINGS } from "../../graphicalQl/mutation/onlineOrdering";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";

const validationSchemaPart1 = Yup.object({});
const OnlineOderingSettings = () => {
  const [updatedRows, setUpdatedRows] = React.useState([]);
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const initialState = {
    loginType: "email",
    allowVerification: false,
    allowPop: false,
    allowMobile: false,
    allowEmail: false,
    menuIds: [],
  };
  const { t } = useTranslation();
  const [selectedIds, setSelectedIds] = React.useState<number[]>([]);
  const naviagate = useNavigate();
  const businessId = sessionStorage.getItem("businessId") as string;

  const formik = useFormik({
    initialValues: {
      ...initialState,
      // Include selected IDs in initialValues
    },
    validationSchema: validationSchemaPart1,

    onSubmit: (values) => {
      upateDomain({
        variables: {
          businessId: parseInt(businessId),
          onlineSettings: JSON.stringify({
            allow_login_with: formik.values.loginType,
            allow_verification: formik.values.allowVerification,
            allow_popup_notifications: formik.values.allowPop,
            allow_mobile_notifications: formik.values.allowMobile,
            allow_email_notifications: formik.values.allowEmail,
            menus: formik.values.menuIds,
          }),
        },
      });
    },
  });

  const [upateDomain] = useMutation(ONLINE_ORDERING_SETTINGS, {
    context: { clientName: "businessClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const { data: settingsData } = useQuery(GET_ONLINE_ORDERING_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: {
      businessId: parseInt(businessId),
    },
    fetchPolicy: "network-only",
  });

  React.useEffect(() => {
    if (settingsData && settingsData.business) {
      formik.setValues({
        loginType: JSON.parse(settingsData.business.onlineSettings)
          .allow_login_with,
        allowEmail: JSON.parse(settingsData.business.onlineSettings)
          .allow_email_notifications,
        allowMobile: JSON.parse(settingsData.business.onlineSettings)
          .allow_mobile_notifications,
        allowPop: JSON.parse(settingsData.business.onlineSettings)
          .allow_popup_notifications,
        allowVerification: JSON.parse(settingsData.business.onlineSettings)
          .allow_verification,
        menuIds: JSON.parse(settingsData.business.onlineSettings).menus,
      });
      setSelectedIds(JSON.parse(settingsData.business.onlineSettings).menus);
    }
  }, [settingsData]);

  const handleAutocompleteChange = (event: any, newValue: any) => {
    setSelectedIds(newValue.map((option: any) => +option.id));
    formik.setFieldValue(
      "menuIds",
      newValue.map((option: any) => +option.id)
    );
    // Assuming each option has an 'id' field
  };

  const { loading, data } = useQuery(MENU_LIST, {
    context: { clientName: "categoryClient" },
    variables: {
      businessId: +businessId,
      locationId: +locationId,
    },
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (data && data.menuList) {
      const list = data.menuList.edges.map((each: any) => ({
        id: each.node.menuId,
        menuName: each.node.name,
      }));
      setUpdatedRows(list);
    }
  }, [data]);

  const capitalizeWords = (str: string) => {
    return str.replace(/\b\w/g, (char) => char.toUpperCase());
  };
  // const apiJsonString = {
  //     allow_login_with: formik.values.loginType,
  //     allow_verification: formik.values.allowVerification,
  //     allow_popup_notifications: formik.values.allowPop,
  //     allow_mobile_notifications: formik.values.allowMobile,
  //     allow_email_notifications: formik.values.allowEmail,
  //     menus: formik.values.menuIds
  // }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "12px",
          }}
        >
          <IconButton
            className="exit-container"
            onClick={() => {
              naviagate("/online/doamins-listing");
            }}
          >
            <CloseIcon className="exitIcon" />
          </IconButton>
          <Typography
            className="main-head"
            sx={{ textTransform: "capitalize" }}
          >
            {t("online_ordering.ordering_settings")}
          </Typography>
          <Button className="main-button" type="submit">
            {t("buttons.save")}
          </Button>
        </Box>
        <Box
          sx={{
            display: "flex",
            padding: "20px 24px",
            paddingBottom: "20px",
            flexDirection: "column",
            alignItems: "flex-start",
            gap: "16px",
            borderRadius: "4px",
            border: "1px solid #EEE",
            background: "#FFF",
          }}
        >
          <Typography className="head-style-grey">
            {t("online_ordering.logIn_signUp_settings")}
          </Typography>
          <Typography className="odering-text">
            {t("online_ordering.logIn_type")}
          </Typography>

          <FormControl>
            <RadioGroup
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="loginType"
              value={formik.values.loginType}
              onChange={formik.handleChange}
            >
              <FormControlLabel
                value="email"
                control={<Radio color="warning" />}
                label={
                  <Typography className="head-style-grey">
                    {t("online_ordering.log_In_with_mail")}
                  </Typography>
                }
              />
              <FormControlLabel
                value="mobile"
                control={<Radio color="warning" />}
                label={
                  <Typography className="head-style-grey">
                    {t("online_ordering.log_in_with_mobile")}
                  </Typography>
                }
              />
            </RadioGroup>
          </FormControl>
          <Divider sx={{ width: "100%" }} />
          <Typography className="odering-text">
            {t("online_ordering.allow_verfication_code")}
          </Typography>
          <Typography className="normal-grey-text-12">
            ❗️{t("online_ordering.verification_para")}
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "35%",
            }}
          >
            <Typography className="url-text-style-grey">
              {t("online_ordering.adding_moblile_number")}
            </Typography>
            <Switch
              color="warning"
              checked={formik.values.allowVerification}
              name="allowVerification"
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "30%",
            }}
          >
            <Typography className="odering-text">
              {t("online_ordering.allow_pop")}
            </Typography>
            <Switch
              color="warning"
              checked={formik.values.allowPop}
              name="allowPop"
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "30%",
            }}
          >
            <Typography className="odering-text">
              {t("online_ordering.allow_mobile")}
            </Typography>
            <Switch
              color="warning"
              checked={formik.values.allowMobile}
              name="allowMobile"
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "30%",
            }}
          >
            <Typography className="odering-text">
              {t("online_ordering.allow_email")}
            </Typography>
            <Switch
              color="warning"
              checked={formik.values.allowEmail}
              name="allowEmail"
              onChange={formik.handleChange}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "70%",
            }}
          >
            <Typography className="odering-text">
              {t("online_ordering.show_menu")}
            </Typography>
            <Autocomplete
              multiple
              limitTags={2}
              id="multiple-limit-tags"
              options={updatedRows}
              getOptionLabel={(option: any) => capitalizeWords(option.menuName)}
              onChange={handleAutocompleteChange}
              value={updatedRows.filter(
                (option: { id: number; name: string }) =>
                  selectedIds && selectedIds.includes(+option.id)
              )}
              renderInput={(params) => (
                <TextField {...params} label="Menu" placeholder="Menu" />
              )}
              sx={{ width: "40%" }}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
};

export default OnlineOderingSettings;
