import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import {
  IconButton,
  Box,
  Button,
  Tooltip,
  Divider,
  Paper,
  Typography,
  TextField,
  InputAdornment,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { USER_REQUESTS } from "../../graphicalQl/usequery/userProfileUseQuery";
import { UNBLOCK_EMPLOYEE } from "../../graphicalQl/mutation/userProfileMutation";
import { useQuery, useMutation } from "@apollo/client";
import moment from "moment";
import "./userRequests.css";
import CircularLoader from "../../loader/loader";

import { toast } from "react-toastify";
import CustomFooter from "../category/footer";
import { localeToFormat } from "../../utils/dateFormat";
import NodataImage from "../../utils/nodataImage";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";
interface State {
  height: string;
  width: string;
  heightForContainer: string;
}
interface StateUserRequests {
  id: string;
  userName: string;
  email: string;
  date: string;
  phone: string;
  pin: string;
  role: string;
}

function UserRequests() {
  const userLang = navigator.language;
  const { t } = useTranslation();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [searchInput, setSearchInput] = React.useState("");
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const variables = {
    businessId: +businessId,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
    query: searchInput,
  };
  const filteredCategory = Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => value !== null)
  );
  const { data, loading, error } = useQuery(USER_REQUESTS, {
    context: { clientName: "userClient" },
    variables: filteredCategory,
  });

  const [usersRequestsList, setUsersRequestsList] = useState<
    StateUserRequests[]
  >([]);

  useEffect(() => {
    if (data) {
      const requiredDataList = data.unblockRequestList.edges.map(
        (each: any) => ({
          id: each.node.employee.employeeId,
          userName: `${each.node.employee.firstName} ${each.node.employee.lastName}`,
          email: each.node.employee.email,
          status: each.node.employeeStatus,
          date: each.node.createdOn,
          pin: each.node.employee.pin,
          phone: each.node.employee.mobile,
          role: each.node.employee.role,
        })
      );
      setUsersRequestsList(requiredDataList);
    }
  }, [data]);

  const [unblockEmployee] = useMutation(UNBLOCK_EMPLOYEE, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [USER_REQUESTS],
  });

  const [showData, setShowData] = React.useState(
    usersRequestsList.map(() => false)
  );

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleUnblockButton = (id: string) => {
    unblockEmployee({ variables: { employeeId: id } });
    toast.success(
      <div>
        <Typography>{t("toast.unblocked")}</Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      headerClassName: "user-role-header-names",
      //@ts-ignore
      headerName: t("buttons.id"),
      width: 80,
    },
    {
      field: "userName",
      //@ts-ignore
      headerName: t("users.user_name"),
      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "email",
      //@ts-ignore
      headerName: t("users.email"),
      headerClassName: "user-role-header-names",
      flex: 1.25,
    },
    {
      field: "phone",
      //@ts-ignore
      headerName: t("users.phone"),
      headerClassName: "user-role-header-names",
      flex: 1,
    },
    {
      field: "role",
      //@ts-ignore
      headerName: t("users.role"),
      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => (
        <Box>{params.value !== null ? params.value.roleName : "no-role"}</Box>
      ),
    },
    {
      field: "date",
      //@ts-ignore
      headerName: t("users.requested_on"),
      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => (
        <Box>
          {moment(params.value).format(`${localeToFormat[userLang]}`)}
          {/* {moment(params.value).local().format("D - MMM - YYYY")} */}
        </Box>
      ),
    },

    {
      field: "pin",
      //@ts-ignore
      headerName: t("users.reveal_pin"),
      headerClassName: "user-role-header-names",
      flex: 1,

      renderCell: (params) => {
        //show the visibleOffIcon and pin
        const toggleVisibility = (index: number) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        };
        //show the visibleIcon and stars
        const toggleVisibilityHide = (index: any) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        };

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {showData[params.row.id]
              ? params.value
              : "* ".repeat(params.value && params.value.length)}
            {showData[params.row.id] ? (
              <Tooltip title={t("users.hide_pin")} placement="top" arrow>
                <IconButton onClick={() => toggleVisibilityHide(params.row.id)}>
                  <VisibilityOffIcon sx={{ marginLeft: "10px" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("users.view_pin")} placement="top" arrow>
                <IconButton onClick={() => toggleVisibility(params.row.id)}>
                  <VisibilityIcon sx={{ marginLeft: "10px" }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("users.actions"),
      headerClassName: "user-role-header-names",
      flex: 1,

      renderCell: (params) => (
        <Button
          onClick={() => handleUnblockButton(params.row.id)}
          sx={{ textTransform: "none" }}
          color="warning"
          className="user-requests-header-inside-table-button"
          variant="outlined"
        >
          Unblock
        </Button>
      ),
    },
  ];

  //Search results

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.unblockRequestList?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.unblockRequestList?.pageInfo.startCursor || null);
  };
  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Box className="user-requests-header-order">
        <Typography className="user-requests-header-title">
          {t("users.user_requests")}
        </Typography>
      </Box>

      <DelayedInput
        width="30%"
        margin="10px 0px 10px 8px"
        setSearchValue={setSearchInput}
        placeHolder={t("users.search_by_name")}
        size={"medium"}
      />

      {loading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            padding: "10px",
            height: "65vh",
            width: "100%",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={usersRequestsList}
            columns={columns}
            getRow={getRow}
            totalCount={data?.unblockRequestList?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}
export default UserRequests;
