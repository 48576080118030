import { Box, TextField, Typography, Autocomplete, Button } from "@mui/material";
import DetailsImage from "../../../../assets/detailsImage.png";
import CountrySelect from "./countriesField";
import LanguageSelect from "./languageField";
import { useEffect, useState } from "react";
import BusinessTypeSelection from "./businessTypeSelection";
import { useNavigation } from "../StepperNavigationContext/useNavigation";
import ForwardArrow from "../../../../assets/arrow-forward.png";
import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_BUSINESS, UPDATE_BUSINESS_IN_CREATE } from "../../../../graphicalQl/mutation/signUp";
import { useMutation } from "@apollo/client";
import { clientName } from "../../../orderUtils/constant";
import ErrorModal from "../../../errorHandling/errorModal";
import { Country } from 'country-state-city';

const validationSchema = Yup.object({
  businessName: Yup.string().required("Required"),
  postCode: Yup.string().required("Required"),
  country: Yup.string().required("Required"),
  address: Yup.string().required("Required"),
});

const range = [
  { label: "1 - 40 " },
  {
    label: "40 - 60",
  },
  {
    label: "60 - 80",
  },
  {
    label: "80 - 100",
  },
];

const BusinessDetails = () => {
  const [BusinessType, setBusinessType] = useState(false);
  const { setStepperCounter, stepperCounter } = useNavigation();

  const handleNext = () => {
    setBusinessType(true);
    setStepperCounter(stepperCounter + 1);
  };
  const [statusCode, setStatusCode] = useState<number>(0)
  const [errorModal, setErrorModal] = useState<boolean>(false)

  const handleErrorModal = () => {
    setErrorModal(false)
  }
  const [createBusiness] = useMutation(CREATE_BUSINESS, { context: { clientName: "businessClient" } })
  const [updateBusinessType] = useMutation(UPDATE_BUSINESS_IN_CREATE, { context: { clientName: "businessClient" } })

  const formik = useFormik({
    validationSchema,
    initialValues: {
      businessName: "",
      postCode: "",
      country: "",
      address: "",
      city: ""
    },
    onSubmit: (values) => {
      handleNext()
      if (!sessionStorage.getItem("newBusiness")) {
        createBusiness(
          {
            variables: {
              firstName: sessionStorage.getItem("name"),
              legalBusinessName: values.businessName,
              email: sessionStorage.getItem("email"),
              mobile: sessionStorage.getItem("mobile"),
              addressDetails: {
                city: values.address.split(",")[0],
                address1: values.address,

                postCode: values.postCode,
                country: values.country,
                latitude: latitude.toString(),
                longitude: longitude.toString()
              }
            },
            onCompleted: (data) => {
              handleNext()
              sessionStorage.setItem("newBusiness", data.createBusiness.business.businessId)

            },
            onError: (error) => {
              const graphqlError = error.graphQLErrors[0];

              if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
                // Extract the status code from the extensions property
                setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
                setErrorModal(true)
              }
              const errorMessage = graphqlError.message || 'An error occurred';
              console.error(`GraphQL error: Status ${statusCode}, Message: ${errorMessage}`);


            },
          }
        )
      } else {
        updateBusinessType(
          {
            variables: {
              firstName: sessionStorage.getItem("name"),
              legalBusinessName: values.businessName,
              email: sessionStorage.getItem("email"),
              mobile: sessionStorage.getItem("mobile"),
              businessId: sessionStorage.getItem("newBusiness"),
              addressDetails: {
                city: values.address.split(",")[0],
                address1: values.address,

                postCode: values.postCode,
                country: values.country,
                latitude: latitude.toString(),
                longitude: longitude.toString()
              }
            },
            onCompleted: (data) => {
              handleNext()

            },
            onError: (error) => {
              const graphqlError = error.graphQLErrors[0];

              if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
                // Extract the status code from the extensions property
                setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
                setErrorModal(true)
              }
              const errorMessage = graphqlError.message || 'An error occurred';
              console.error(`GraphQL error: Status ${statusCode}, Message: ${errorMessage}`);


            },
          }
        )
      }




    },
  });
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const locationKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE";

  function getCurrentLocation(addressComponents: any) {
    let currentLocation = "";
    const lanmarkComponrnt = addressComponents.find((component: any) =>
      component.types.includes("landmark")
    );
    if (lanmarkComponrnt) {
      currentLocation += lanmarkComponrnt.long_name + ", ";
    }
    // Look for the locality (city)
    const localityComponent = addressComponents.find(
      (component: any) =>
        // component.types.includes("political") ||
        component.types.includes("sublocality") ||
        component.types.includes("sublocality_level_2")
    );
    if (localityComponent) {
      currentLocation += localityComponent.long_name + ", ";
    }
    const cityComponent = addressComponents.find((component: any) =>
      component.types.includes("locality")
    );
    if (cityComponent) {
      currentLocation += cityComponent.long_name + ", ";
    }
    const stateComponent = addressComponents.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );
    if (stateComponent) {
      currentLocation += stateComponent.long_name + ", ";
    }
    // If neither city nor state is available, use country
    const countryComponent = addressComponents.find((component: any) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      currentLocation += countryComponent.long_name + ", ";
    }
    const postalCodeComponent = addressComponents.find((component: any) =>
      component.types.includes("postal_code")
    );
    if (postalCodeComponent) {
      currentLocation += postalCodeComponent.long_name;
    }
    return currentLocation;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getPinData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.postCode}&key=${locationKey}`
        );
        const data = await response.json();
        if (data) {
          if (data.status === "OK") {
            const { results } = data;
            if (results) {
              const { address_components, geometry } = results[0];
              const currentLocation = getCurrentLocation(address_components);
              formik.setFieldValue("address", currentLocation);
              const { location } = geometry;
              const { lat, lng } = location;
              setLatitude(lat);
              setLongitude(lng);
              const extractCityAndState = (addressComponents: any) => {
                let city = "";
                let state = "";
                let country = "";
                addressComponents.forEach((component: any) => {
                  if (
                    component.types.includes("postal_town") ||
                    component.types.includes("locality")
                  ) {
                    city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    state = component.long_name;
                  }
                  else if (
                    component.types.includes("country")
                  ) {
                    country = component.long_name;
                  }

                });
                return { city, state, country };
              };
              const { city, state, country } = extractCityAndState(address_components);
              formik.setFieldValue("city", city);
              formik.setFieldValue("country", country);
            }
          }
        }
        // fetch(
        //   `https://api.postalpincode.in/pincode/${
        //     formik.values.postCode && formik.values.postCode
        //   }`
        // )
        //   .then((response) => response.json())
        //   .then((data) => {
        //     if (data) {
        //       const { PostOffice } = data[0];
        //       if (PostOffice) {
        //         const { State, Division } = PostOffice[0];
        //         formik.setFieldValue("state", State);
        //         formik.setFieldValue("city", Division);
        //       }
        //     }
        //   });
      } catch (error) { }
    };
    getPinData();
    return () => {
      controller.abort();
    };
  }, [formik.values.postCode]);
  return (
    <>
      {BusinessType ? (
        <>
          <BusinessTypeSelection setBusinessType={setBusinessType} />
        </>
      ) : (
        < form onSubmit={formik.handleSubmit}>
          <Box paddingX={{ sm: "20px", xs: "10px", marginBottom: "2vh" }}>
            <Box
              sx={{
                backgroundColor: "#FBFBFB",
                paddingY: "2vh",
                paddingX: { sm: "2vh", xs: "10px" },
                borderRadius: "8px",
              }}
            >
              <Box
                // marginTop={"16px"}
                alignItems={"center"}
                textAlign={"center"}
                marginX={"auto"}
                justifyContent={"center"}
              >
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "20px", md: "24px" },
                    fontWeight: "600",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    color: "#333333",
                  }}
                >
                  Tell us more about your Business
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "20px",
                    letterSpacing: "0.5px",
                    color: "#707070",
                    marginTop: "12px",
                  }}
                >
                  Personalize your account according to your business type by
                  entering the required information below.
                </Typography>

                <Box
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"space-between"}
                  marginTop={"1vh"}
                  width={"100%"}
                  marginX={"auto"}
                  sx={{
                    flexDirection: {
                      xs: "column",
                      md: "row",
                      paddingX: { xs: "4px", md: "12px" },
                      maxWidth: { xs: "90%", md: "1360px" },
                    },
                  }}
                >
                  <Box
                    sx={{
                      padding: "0px 0px",
                      width: { sm: "auto", xs: "250px" },
                      minWidth: { sm: "468px" },
                      height: "50vh"
                    }}
                  >
                    <img width={"100%"} height={"100%"} src={DetailsImage} alt="DetailsImage" />
                  </Box>
                  <Box
                    maxWidth={"704px"}
                    display={"flex"}
                    flexWrap={"wrap"}
                    sx={{
                      marginTop: { xs: "1vh", md: "0px" },
                      gap: { xs: "12px", md: "24px" },
                      width: "100%",
                      backgroundColor: "#fff",
                      borderRadius: "8px",
                    }}
                  >
                    <Box
                      paddingY={"32px"}
                      paddingX={{ xs: "2vh", sm: "2vh" }}
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        gap: "1vh",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Box sx={{ width: "100%" }}>
                        <TextField
                          sx={{
                            width: "100%",
                            fontFamily: "poppins",
                            fontSize: "12px",
                            lineHeight: "16px",
                            color: "black",
                          }}
                          label="Business Name"
                          name="businessName"
                          onChange={formik.handleChange}
                          value={formik.values.businessName}
                          error={Boolean(formik.touched.businessName && formik.errors.businessName)}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.businessName && formik.errors.businessName && (
                          <Box sx={{ width: "100%", textAlign: "left" }}>
                            <Typography className="create-a-user-formik-label">
                              {formik.errors.businessName}
                            </Typography>
                          </Box>
                        )}
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: { lg: "nowrap", xs: "wrap" },
                          gap: "2vh",
                          width: "100%",
                        }}
                      >
                        <Box sx={{ width: "100%" }}>
                          <TextField
                            sx={{
                              width: {
                                xs: "100% !important",
                                lg: "100% !important",
                              },
                            }}
                            label="Post Code"
                            name="postCode"
                            error={Boolean(formik.touched.postCode && formik.errors.postCode)}

                            value={formik.values.postCode}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />

                          {formik.touched.postCode && formik.errors.postCode && (
                            <Box sx={{ width: "100%", textAlign: "left" }}>
                              <Typography className="create-a-user-formik-label">
                                {formik.errors.postCode}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                        {/* <Box
                        sx={{
                          width: {
                            xs: "100% !important",
                            lg: "48% !important",
                          },
                        }}
                      >
                        <CountrySelect />
                      </Box> */}
                        <Box sx={{ width: "100%" }}>
                          {/* <TextField
                            sx={{
                              width: {
                                xs: "100% !important",
                                lg: "100% !important",
                              },
                            }}
                            label="Country"
                            name="country"
                            onChange={formik.handleChange}
                            error={Boolean(formik.touched.country && formik.errors.country)}

                            value={formik.values.country}
                            onBlur={formik.handleBlur}
                            type="text"
                          /> */}
                          <Autocomplete
                            id="country-select-demo"

                            options={Country.getAllCountries()}
                            autoHighlight
                            getOptionLabel={(option) => option.name}
                            onChange={(event, newValue) => {
                              formik.setFieldValue('country', newValue ? newValue.name : '');
                            }}
                            onBlur={formik.handleBlur}
                            renderOption={(props, option) => (
                              <Box
                                key={option.name}
                                component="li"
                                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                                {...props}
                              >
                                <img
                                  loading="lazy"
                                  width="20"
                                  srcSet={`https://flagcdn.com/w40/${option.isoCode.toLowerCase()}.png 2x`}
                                  src={`https://flagcdn.com/w20/${option.isoCode.toLowerCase()}.png`}
                                  alt=""
                                />
                                {option.name}
                              </Box>
                            )}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Choose a country"
                                name="country"
                                error={Boolean(formik.touched.country && formik.errors.country)}
                                // helperText={formik.touched.country && formik.errors.country}
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'new-password', // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                          {formik.touched.country && formik.errors.country && (
                            <Box sx={{ width: "100%", textAlign: "left" }}>
                              <Typography className="create-a-user-formik-label">
                                {formik.errors.country}
                              </Typography>
                            </Box>
                          )}
                        </Box>

                      </Box>
                      <Box sx={{ width: '100%' }}>
                        <TextField
                          sx={{ width: "100%" }}
                          label="Business Address"
                          name="address"
                          error={Boolean(formik.touched.address && formik.errors.address)}

                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          type="text"
                        />
                        {formik.touched.address && formik.errors.address && (
                          <Box sx={{ width: "100%", textAlign: "left" }}>
                            <Typography className="create-a-user-formik-label">
                              {formik.errors.address}
                            </Typography>
                          </Box>
                        )}
                      </Box>
                      {/* <Box
                      sx={{
                        display: "flex",
                        flexWrap: { lg: "nowrap", xs: "wrap" },
                        gap: "24px",
                        width: "100%",
                      }}
                    >
                      <LanguageSelect />
                      <Autocomplete
                        id="range-select-demo"
                        sx={{ width: { xs: "100%", lg: "48%" } }}
                        options={range}
                        autoHighlight
                        getOptionLabel={(option) => option.label}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Staff/Users Range"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    </Box> */}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box
                display={"flex"}
                marginTop={"1vh"}
                maxWidth={"1360px"}
                marginX={"auto"}
                sx={{
                  justifyContent: { xs: "center", md: "end" },
                  marginBottom: "1vh",
                }}
              >
                <Button
                  sx={{ width: "220px" }}
                  type="submit"
                  // onClick={handleNext}
                  className="main-button"

                // customImage={ForwardArrow}
                >
                  Next
                </Button>
              </Box>
            </Box>
          </Box>
          <ErrorModal errorModal={errorModal} statusCode={statusCode} handleErrorModal={handleErrorModal} />

        </form>
      )}
    </>
  );
};

export default BusinessDetails;
