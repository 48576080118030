export interface StatisticsDataType {
  headers: string[];
}
export const ProductStatisticsData: StatisticsDataType = {
  headers: ["Top 5 Products", "Quantity", "Sales"],
};
export const CategoryStatisticsData: StatisticsDataType = {
  headers: ["Top 5 Category", "Quantity", "Sales"],
};
export const DeliveryReportsStatisticsData: StatisticsDataType = {
  headers: ["Top 5 Delivery Zones", "Orders", "Sales"],
};
export const PaymentStatisticsData: StatisticsDataType = {
  headers: ["Payment type", "Sales"],
};
