import {
  FormControl,
  InputLabel,
  Box,
  Select,
  MenuItem,
  IconButton,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  Typography,
  TextField,
  List,
  InputAdornment,
  Popover,
  ListItem,
  ListItemText,
  Divider,
  SelectChangeEvent,
} from "@mui/material";
import React from "react";
import AutoCompleteDropdown from "../popups/autoCompleteDropdown";
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { MutableRefObject } from "react";
import DelayedInput from "../../utils/delayedInput";
import { t } from "i18next";

interface State {
  handleCategory: (newData: any) => void;
  categoryId: string | null;
  categoryList: any[];
  categoryName: string | null;
  accordionRef: MutableRefObject<HTMLDivElement | null>;
  expanded: boolean;
  handleChangeForAccordian: (isExpanded: boolean) => void;
  handleSearchIconOptions: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => void;
  searchInputOptions: string;
  searchGroupsResults: any[];
  expandedForGroup: string | false;
  handleChange: (id: string) => void;
  handleOptionGroup: (id: string, isChecked: boolean) => void;
  handleOptionId: (
    optionId: string,
    GroupId: string,
    isChecked: boolean
  ) => void;
  searchResults: any[];
  handleNavigation: () => void;
  actions: string | undefined;
  handleActions: (event: SelectChangeEvent<string>) => void;
  handlePopupCheckbox: (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => void;

  setSearchInput: React.Dispatch<React.SetStateAction<string | null>>;
}
const FiltersAndSearchForProductList = (props: State) => {
  const {
    categoryId,
    handleCategory,
    handlePopupCheckbox,
    handleActions,
    actions,
    searchResults,
    handleOptionId,
    handleOptionGroup,
    expandedForGroup,
    handleChange,
    searchGroupsResults,
    searchInputOptions,
    categoryList,
    handleNavigation,
    categoryName,
    accordionRef,
    expanded,
    handleChangeForAccordian,
    handleSearchIconOptions,
    setSearchInput,
  } = props;
  return (
    <Box
      sx={{
        display: "flex",
        zIndex: 15,
        width: "100%",
        padding: "10px 15px 10px 15px",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "60%",
          display: "flex",
          gap: "10px",
          alignItems: "flex-start",
        }}
      >
        <AutoCompleteDropdown
          handleChange={handleCategory}
          data={categoryList}
          width="30%"
          label={t("product.all_category")}
          value={{ id: categoryId || "", label: categoryName || "" }}
        />

        <Box sx={{ width: "50%", zIndex: 10 }}>
          <Accordion
            ref={accordionRef}
            expanded={expanded}
            onChange={(event, isExpanded) =>
              handleChangeForAccordian(isExpanded)
            }
            elevation={0}
            sx={{
              width: "100%",
              border: "1px solid #d9d9d9",
              height: "53px",
              padding: "2px",
            }}
          >
            <AccordionSummary
              sx={{ width: "100%", marginBottom: 0 }}
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                className="product-table-all-filter-by-text"
                sx={{ flexShrink: 0 }}
              >
                {t("product.filter_by_option")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails
              sx={{
                "&.MuiAccordionDetails-root": {
                  padding: "0px !important",
                },
              }}
            >
              <Box
                sx={{
                  height: "37vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  zIndex: 10000,
                }}
              >
                <Paper
                  elevation={2}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    overflowY: "auto",
                    WebkitOverflowScrolling: "touch",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                    maxHeight: "50vh",
                    padding: "10px 10px 10px 15px",
                    minHeight: "10vh",
                    zIndex: 10000,
                  }}
                >
                  <TextField
                    onChange={(e) => handleSearchIconOptions(e)}
                    placeholder={t("users.search_by_name") || ""}
                    value={searchInputOptions}
                    id="outlined-start-adornment"
                    sx={{ width: "98%" }}
                    color="warning"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "scroll",
                      maxHeight: "50vh",
                      marginTop: "10px",
                      minHeight: "10vh",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                  >
                    <FormGroup>
                      {searchGroupsResults.map((each: any) => (
                        <Box
                          sx={{
                            border: "0px",
                            padding: "0px",
                            marginLeft: "0px",
                            width: "100%",
                            "&.MuiAccordionSummary-content": {
                              margin: "0px !important",
                            },
                          }}
                        >
                          <Accordion
                            sx={{
                              marginLeft: "0px",
                              padding: "0px",
                              "&.MuiAccordionSummary-content": {
                                margin: "0px !important",
                              },
                            }}
                            elevation={0}
                            key={each.groupId}
                            expanded={expandedForGroup === each.groupId}
                          >
                            <AccordionSummary
                              aria-controls="panel2d-content"
                              id="panel2d-header"
                              sx={{
                                marginLeft: "0px",
                                width: "100%",
                                padding: "0px",
                                "& .MuiAccordionSummary-content": {
                                  margin: "0px !important",
                                },
                              }}
                            >
                              {each.optionObjects.length > 0 && (
                                <IconButton
                                  sx={{
                                    "& .MuiSvgIcon-root": { fontSize: 20 },
                                  }}
                                  onClick={() => {
                                    // Prevent the Accordion from toggling when IconButton is clicked
                                    handleChange(each.groupId);
                                  }}
                                >
                                  {expandedForGroup === each.groupId ? (
                                    <ArrowDropDownIcon />
                                  ) : (
                                    <ArrowRightIcon />
                                  )}
                                </IconButton>
                              )}
                              <FormControlLabel
                                label={
                                  <Typography
                                    sx={{ textTransform: "capitalize" }}
                                    className="group-popup-checkbox"
                                  >
                                    {each.groupName}
                                  </Typography>
                                }
                                control={
                                  <Checkbox
                                    color="warning"
                                    sx={{
                                      "& .MuiSvgIcon-root": { fontSize: 20 },
                                    }}
                                    checked={
                                      each.optionObjects.length ===
                                      each.optionObjects.filter(
                                        (eachOption: any) =>
                                          eachOption.isChecked
                                      ).length
                                    }
                                    indeterminate={
                                      each.optionObjects.filter(
                                        (eachOption: any) =>
                                          eachOption.isChecked
                                      ).length > 0 &&
                                      each.optionObjects.length >
                                        each.optionObjects.filter(
                                          (eachOption: any) =>
                                            eachOption.isChecked
                                        ).length
                                    }
                                    onChange={(e) =>
                                      handleOptionGroup(
                                        each.groupId,
                                        e.target.checked
                                      )
                                    }
                                  />
                                }
                              />
                            </AccordionSummary>
                            <AccordionDetails
                              sx={{
                                "&.MuiAccordionDetails-root": {
                                  padding: "0px !important",
                                },
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  marginBottom: "0px",
                                }}
                              >
                                {each.optionObjects &&
                                  each.optionObjects.map((eachOption: any) => (
                                    <FormControlLabel
                                      key={eachOption.optionId}
                                      label={
                                        <Typography
                                          sx={{
                                            textTransform: "capitalize",
                                          }}
                                          className="group-popup-checkbox"
                                        >
                                          {eachOption.name}
                                        </Typography>
                                      }
                                      control={
                                        <Checkbox
                                          color="warning"
                                          sx={{
                                            "& .MuiSvgIcon-root": {
                                              fontSize: 20,
                                            },
                                            marginLeft: "11%",
                                            marginTop: "0%",
                                          }}
                                          checked={eachOption.isChecked}
                                          onChange={(e) =>
                                            handleOptionId(
                                              eachOption.optionId,
                                              each.groupId,
                                              e.target.checked
                                            )
                                          }
                                        />
                                      }
                                    />
                                  ))}
                              </Box>
                            </AccordionDetails>
                          </Accordion>
                        </Box>
                      ))}
                      {searchResults &&
                        searchResults.map((each: any) => (
                          <FormControlLabel
                            key={each.optionId}
                            sx={{ marginLeft: "5px" }}
                            control={
                              <Checkbox
                                onChange={(e) => {
                                  handlePopupCheckbox(each.optionId, e);
                                }}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: 20 },
                                }}
                                color="warning"
                                checked={each.isChecked}
                              />
                            }
                            label={
                              <Typography
                                sx={{ textTransform: "capitalize" }}
                                className="group-popup-checkbox"
                              >
                                {each.name}
                              </Typography>
                            }
                          />
                        ))}
                    </FormGroup>
                  </Box>
                </Paper>
              </Box>
            </AccordionDetails>
          </Accordion>
        </Box>

        <DelayedInput
          width="60%"
          setSearchValue={setSearchInput}
          placeHolder={t("product.search_by_name_description")}
          size={"medium"}
        />
      </Box>

      <Box
        style={{
          display: "flex",
          alignItems: "center",
          width: "30%",
          gap: "10px",
        }}
      >
        <FormControl style={{ width: "70%" }}>
          <InputLabel
            id="demo-simple-select-label"
            style={{ color: "#F38B08" }}
          >
            {t("product.actions")}
          </InputLabel>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={actions}
            label={t("product.actions")}
            onChange={handleActions}
            color="warning"
          >
            <MenuItem value="import">{t("product.import")}</MenuItem>
            <MenuItem value="export">{t("product.export")}</MenuItem>
          </Select>
        </FormControl>
        <Button className="product_button_style" onClick={handleNavigation}>
          {" "}
          {t("product.create_a_Product")}
        </Button>
      </Box>
    </Box>
  );
};

export default FiltersAndSearchForProductList;
