import React, { useState } from "react";
import styled from "styled-components";
import { Box, Button, List, Typography } from "@mui/material";
import CloseButton from "../../../../assets/close.png";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Checkmark from "../../../../assets/yellowcheckmark.png";
import LinearProgress from "@mui/material/LinearProgress";
import GrayTik from "../../../../assets/graycheckmark.png";
import ForwardArrow from "../../../../assets/arrow-forward.png";

const settingConstant: any = {
  marginTop: "45px",
  marginBottom: "300px",
  border: "1px solid #eeeeee",
  borderRadius: "8px",
  maxWidth: "727px",
  width: { sm: "calc(100% - 92px)", xs: "100%" },
  marginLeft: "auto",
  marginRight: "auto",
  ".accordions .css-i4bv87-MuiSvgIcon-root ": {
    fill: "#707070",
  },
  ".accordions .Mui-expanded .css-i4bv87-MuiSvgIcon-root ": {
    fill: "#F38B08",
  },
  ".accordions .css-o4b71y-MuiAccordionSummary-content.Mui-expanded": {
    marginBottom: "12px",
  },
  ".accordions .css-eglki6-MuiLinearProgress-root": {
    height: "6px",
    background: "#F9C58499",
    borderRadius: "20px",
  },
  ".setupguidebar .css-eglki6-MuiLinearProgress-root": {
    height: "6px",
    background: "#F9C58499",
    borderRadius: "20px",
  },
  ".accordions .css-5xe99f-MuiLinearProgress-bar1": {
    background: "#F38B08",
    borderRadius: "20px",
  },
  ".setupguidebar .css-5xe99f-MuiLinearProgress-bar1": {
    background: "#F38B08",
    borderRadius: "20px",
  },
  ".accordions .css-1c35hjw-MuiPaper-root-MuiAccordion-root": {
    boxShadow: "none",
  },
  ".accordions .css-o4b71y-MuiAccordionSummary-content": {
    justifyContent: "space-between",
    alignItems: "center",
  },
};

const SettingsWrapper = styled("div")(settingConstant);

const ApplicationSettings = () => {
  const [expanded, setExpanded] = useState("");

  const handleChange = (panel: any) => (event: any, isExpanded: any) => {
    setExpanded(isExpanded ? panel : false);
  };

  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  });

  return (
    <SettingsWrapper>
      <Box
        sx={{
          paddingY: "20px",
          paddingX: { sm: "32px", xs: "16px" },
          display: "flex",
          flexDirection: "column",
          gap: "24px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "20px",
            borderBottom: "1px solid #D9D9D9",
          }}
        >
          <Typography
            sx={{
              fontSize: { sm: "32px", xs: "24px" },
              lineHeight: "24px",
              fontWeight: "600",
              letterSpacing: "0.5px",
              fontFamily: "Poppins",
            }}
          >
            Setup Guide
          </Typography>
          <Button
            sx={{
              minWidth: { sm: "44px", xs: "36px" },
              height: { sm: "44px", xs: "40px" },
              backgroundColor: "#eeeeee",
              borderRadius: "50%",
            }}
          >
            <img src={CloseButton} alt="CloseButton" />
          </Button>
        </Box>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: "12px",
              alignItems: "center",
              paddingBottom: "12px",
              borderBottom: "1px solid #D9D9D9",
              flexWrap: { sm: "nowrap", xs: "wrap" },
              justifyContent: { sm: "space-between", xs: "center" },
            }}
          >
            <Typography
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontFamily: "Poppins",
                color: "#333333",
                textAlign: { sm: "start", xs: "center" },
              }}
            >
              You chose manual data entry. Would you prefer switching to default
              data?
            </Typography>
            <Button
              sx={{
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "500",
                color: "#F38B08",
                border: "1px solid #F38B08",
                backgroundColor: "#FFF8F0",
                borderRadius: "4px",
                minWidth: "208px",
                height: "33px",
                padding: "0px",
                textTransform: "none",
                boxShadow: "none !important",
                // "&:hover": {
                //   backgroundColor: "#F38B08",
                // },
              }}
            >
              Yes, Switch to Default data
            </Button>
          </Box>
          <Box
            className="accordions"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: { sm: "12px", xs: "0px" },
            }}
          >
            <Accordion
              expanded={expanded === "panel1"}
              onChange={handleChange("panel1")}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                sx={{
                  padding: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <img src={Checkmark} alt="Checkmark" />
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      lineHeight: "27px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#333333",
                    }}
                  >
                    General settings
                  </Typography>
                </Box>
                {/* <Box sx={{ width: "100%" }}>
                  <LinearProgress variant="determinate" value={75} />
                </Box> */}
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingY: "0px",
                }}
              >
                <List
                  sx={{
                    listStyle: "none",
                    paddingY: "0px",
                  }}
                >
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AE26",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        1
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Add store
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AE26",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        2
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Select Feature Settings
                      </Typography>
                    </Box>
                  </li>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel2"}
              onChange={handleChange("panel2")}
              sx={{
                borderTop: "1px solid #eeeeee",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel2bh-content"
                id="panel2bh-header"
                sx={{
                  padding: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <img src={GrayTik} alt="GrayTik" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: { sm: "nowrap", xs: "wrap" },
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "27px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        color: "#333333",
                      }}
                    >
                      Menu
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        color: "#707070",
                      }}
                    >
                      (Products, Categories, Options)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "65px",
                    paddingRight: "8px",
                  }}
                >
                  <LinearProgress variant="determinate" value={75} />
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingY: "0px",
                }}
              >
                <List
                  sx={{
                    listStyle: "none",
                    paddingY: "0px",
                  }}
                >
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AE26",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        1
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Products Create
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        2
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Categories
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        3
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Options
                      </Typography>
                    </Box>
                  </li>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel3"}
              onChange={handleChange("panel3")}
              sx={{
                borderTop: "1px solid #eeeeee",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel3bh-content"
                id="panel3bh-header"
                sx={{
                  padding: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <img src={GrayTik} alt="GrayTik" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      alignItems: "center",
                      flexWrap: { sm: "nowrap", xs: "wrap" },
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "27px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        color: "#333333",
                      }}
                    >
                      Account
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        color: "#707070",
                      }}
                    >
                      ( Receipts, Taxes)
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "65px",
                    paddingRight: "8px",
                  }}
                >
                  <LinearProgress variant="determinate" value={75} />
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingY: "0px",
                }}
              >
                <List
                  sx={{
                    listStyle: "none",
                    paddingY: "0px",
                  }}
                >
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AE26",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        1
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Products Create
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        2
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Categories
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        3
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Options
                      </Typography>
                    </Box>
                  </li>
                </List>
              </AccordionDetails>
            </Accordion>
            <Accordion
              expanded={expanded === "panel4"}
              onChange={handleChange("panel4")}
              sx={{
                borderTop: "1px solid #eeeeee",
                boxShadow: "none",
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel4bh-content"
                id="panel4bh-header"
                sx={{
                  padding: "0px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                    }}
                  >
                    <img src={GrayTik} alt="GrayTik" />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: { sm: "nowrap", xs: "wrap" },
                      gap: "12px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "18px",
                        lineHeight: "27px",
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        color: "#333333",
                      }}
                    >
                      Devices
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        color: "#707070",
                      }}
                    >
                      ( POS, KDS, other){" "}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "65px",
                    paddingRight: "8px",
                  }}
                >
                  <LinearProgress variant="determinate" value={75} />
                </Box>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  paddingY: "0px",
                }}
              >
                <List
                  sx={{
                    listStyle: "none",
                    paddingY: "0px",
                  }}
                >
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#00AE26",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        1
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Products Create
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        2
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Categories
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        paddingY: "8px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "20px",
                          height: "20px",
                          backgroundColor: "#ABABAB",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          color: "#fff",
                          fontSize: "12px",
                          fontFamily: "Poppins",
                        }}
                      >
                        3
                      </Box>
                      <Typography
                        sx={{
                          width: "calc(100% - 20px)",
                          fontSize: "14px",
                          lineHeight: "21px",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        Create Options
                      </Typography>
                    </Box>
                  </li>
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            flexWrap: { sm: "nowrap", xs: "wrap" },
            justifyContent: { sm: "space-between", xs: "center" },
            alignItems: "center",
            paddingTop: "12px",
            borderTop: "1px solid #eee",
          }}
        >
          <Box
            className="setupguidebar"
            sx={{
              width: "155px",
            }}
          >
            <Typography
              sx={{
                fontSize: "12px",
                lineHeight: "18px",
                fontWeight: "500",
                fontFamily: "Poppins",
                paddingBottom: "8px",
                textAlign: "center",
                color: "#707070",
              }}
            >
              <b>1</b>/4 is done
            </Typography>
            <LinearProgress variant="determinate" value={25} />
          </Box>
          <Button
            // onClick={() => setApplicationSettings(true)}
            sx={{
              width: "220px",
              height: "48px",
              backgroundColor: "#F38B08",
              display: "flex",
              gap: "24px",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
              textTransform: "capitalize",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
              }}
            >
              Continue
            </Typography>
            <img src={ForwardArrow} alt="ForwardArrow" />
          </Button>
        </Box>
      </Box>
    </SettingsWrapper>
  );
};

export default ApplicationSettings;
