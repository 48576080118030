//write the basic structure of the component
import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect } from "react";
import Header from "./header";
import ReviewsDataTable from "./reviewsTable";
import ReviewData from "./reviewData";
import { localeToFormat } from "../../utils/dateFormat";
import moment from "moment";
import { useTranslation } from "react-i18next";

const ReviewsAndRatingsMain = () => {
  const [search, setSearch] = React.useState("");
  const [filterBy, setFilterBy] = React.useState("0");
  const [date, setDate] = React.useState({});

  const [data, setData] = React.useState<any>([]);

  const [rows, setRows] = React.useState<any>([]);
  const { t } = useTranslation()
  const userLang = navigator.language;

  useEffect(() => {
    if (data && data.ratings) {
      const list = data.ratings.edges.map((item: any) => {
        return {
          customerName:
            item.node.customerDetails &&
            JSON.parse(item.node.customerDetails).customer_name,
          review: item.node.review,
          rating: item.node.rating,
          id: item.node.orderId,
          date:
            item.node.reviewOptions &&
            moment(JSON.parse(item.node.reviewOptions).review_given_on).format(
              `${localeToFormat[userLang]}`
            ),
          hide:
            item.node.reviewOptions &&
            JSON.parse(item.node.reviewOptions).is_hidden,
        };
      });
      setRows(list);
    }
  }, [data]);

  return (
    <Box>
      <Box sx={{ padding: "22px 0px 12px 22px" }}>
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          {t('reviews.reviews_ratings')}
        </Typography>
      </Box>
      <Divider sx={{ mb: "12px" }} />
      <Header
        search={search}
        setSearch={setSearch}
        filterBy={filterBy}
        setFilterBy={setFilterBy}
        setDate={setDate}
        date={date}
      />
      <ReviewsDataTable rows={rows} />
      <ReviewData
        search={search}
        filterBy={filterBy}
        date={date}
        setData={setData}
      />
    </Box>
  );
};
export default ReviewsAndRatingsMain;
