import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Divider,
  FormGroup,
  FormControlLabel,
  Checkbox,
  TextField,
  Box,
  Switch,
  IconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import "./discountReasons.css";
import { DISCOUNT_REASONS } from "../../graphicalQl/usequery/discountQuery";
import { useMutation, useQuery } from "@apollo/client";
import { CREATE_REASON } from "../../graphicalQl/mutation/discountMutation";
import DeleteIcon from "@mui/icons-material/Delete";
import { DELETE_DISCOUNT_REASON } from "../../graphicalQl/mutation/discountMutation";
import { SAVE_DISCOUNT_REASONS } from "../../graphicalQl/mutation/discountMutation";
import { toast } from "react-toastify";
import ErrorModal from "../errorHandling/errorModal";

const DiscountReason = () => {
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { t } = useTranslation();

  const [checkboxes, setCheckboxes] = useState<
    { id: string; reason: string; checked: boolean }[]
  >([]);
  const [voidCheckboxes, setVoidCheckboxes] = useState<
    { id: string; reason: string; checked: boolean }[]
  >([]);

  const [newLabel, setNewLabel] = useState<string>("");

  const [createDiscountReason] = useMutation(CREATE_REASON, {
    context: { clientName: "discountClient" },
    refetchQueries: [DISCOUNT_REASONS],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [deleteDiscountReason] = useMutation(DELETE_DISCOUNT_REASON, {
    context: { clientName: "discountClient" },
    refetchQueries: [DISCOUNT_REASONS],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [saveDiscountReasons] = useMutation(SAVE_DISCOUNT_REASONS, {
    context: { clientName: "discountClient" },
    // refetchQueries: [DISCOUNT_REASONS],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.saved")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [discountSwitch, setDiscountSwitch] = useState(true);
  const [voidSwitch, setVoidSwitch] = useState(true);
  const checkedDiscountIds = checkboxes
    .filter((each) => each.checked === true)
    .map((item) => +item.id);
  const checkedVoidDiscountIds = voidCheckboxes
    .filter((each) => each.checked === true)
    .map((item) => +item.id);

  const finalIds = [
    ...(discountSwitch ? checkedDiscountIds : []),
    ...(voidSwitch ? checkedVoidDiscountIds : []),
  ];

  const handleSaveDiscountReasons = () => {
    saveDiscountReasons({
      variables: {
        businessId: parseInt(businessId),
        reasonIds: JSON.stringify(finalIds),
      },
    });
  };
  const handleDeleteReason = (value: string) => {
    deleteDiscountReason({ variables: { reasonId: +value } });
  };

  // Function to handle checkbox change
  const handleCheckboxChange = (index: string) => {
    const updatedCheckboxes = checkboxes.map((checkbox, i) =>
      checkbox.id === index
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );

    setCheckboxes(updatedCheckboxes);
  };

  const handleAllCheckBoxesFalse = () => {
    const list = checkboxes.map((each) => ({ ...each, checked: false }));
    setCheckboxes(list);
  };
  const handleAllCheckBoxesFalse1 = () => {
    const list = voidCheckboxes.map((each) => ({ ...each, checked: false }));
    setVoidCheckboxes(list);
  };
  const handleVoidCheckboxChange = (index: string) => {
    const updatedCheckboxes = voidCheckboxes.map((checkbox, i) =>
      checkbox.id === index
        ? { ...checkbox, checked: !checkbox.checked }
        : checkbox
    );

    setVoidCheckboxes(updatedCheckboxes);
  };

  const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.target.value);
  };
  const [addValue, setAddValue] = useState(false);

  const handleAdd = () => {
    setAddValue(true);
  };
  const [statusCode, setStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  const [addVoidValue, setAddVoidValue] = useState(false);

  const handleVoidAdd = () => {
    setAddVoidValue(true);
  };

  const handleAddCheckbox = (value: string) => {
    if (newLabel.trim() !== "") {
      // setCheckboxes([...checkboxes, { checked: false, reason: newLabel, id: uuid() }]);

      createDiscountReason({
        variables: {
          businessId: +businessId,
          reason: newLabel.trim(),
          reasonType: value,
        },
        onCompleted: () => {
          setNewLabel("");
          value === "Discount" ? setAddValue(false) : setAddVoidValue(false);
        },
        onError: (error) => {
          const graphqlError = error.graphQLErrors[0];

          if (
            graphqlError &&
            graphqlError.extensions &&
            graphqlError.extensions.status
          ) {
            // Extract the status code from the extensions property
            setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
            setErrorModal(true);
          }
          const errorMessage = graphqlError.message || "An error occurred";
          console.error(
            `GraphQL error: Status ${statusCode}, Message: ${errorMessage}`
          );
        },
      });
    }
  };

  const { loading, data: discountReasons } = useQuery(DISCOUNT_REASONS, {
    context: { clientName: "discountClient" },
    variables: { reasonType: "Discount", businessId: +businessId },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });

  React.useEffect(() => {
    if (discountReasons && discountReasons.discountReasons) {
      const list: { id: string; reason: string; checked: boolean }[] =
        discountReasons.discountReasons.edges.map((each: any) => ({
          id: each.node.reasonId,
          reason: each.node.reason,
          checked: each.node.isSelected,
        }));

      const checkedDiscountIds = list
        .filter((each) => each.checked === true)
        .map((item) => +item.id);
      if (checkedDiscountIds.length > 0) {
        setDiscountSwitch(true);
      }

      setCheckboxes(list);
    }
  }, [discountReasons]);
  const { data: discountVoidReasons } = useQuery(DISCOUNT_REASONS, {
    context: { clientName: "discountClient" },
    variables: { reasonType: "Void", businessId: +businessId },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });

  React.useEffect(() => {
    if (discountVoidReasons && discountVoidReasons.discountReasons) {
      const list: { id: string; reason: string; checked: boolean }[] =
        discountVoidReasons.discountReasons.edges.map((each: any) => ({
          id: each.node.reasonId,
          reason: each.node.reason,
          checked: each.node.isSelected,
        }));
      const checkedDiscountIds = list
        .filter((each) => each.checked === true)
        .map((item) => +item.id);

      if (checkedDiscountIds.length > 0) {
        setVoidSwitch(true);
      }

      setVoidCheckboxes(list);
    }
  }, [discountVoidReasons]);

  const [hoveredIndex, setHoveredIndex] = useState<string | null>(null);

  return (
    <div>
      <Box
        sx={{
          padding: "0.625rem 1.5% 0.625rem 2.4%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography className="reasons-head-text">
          {t("reasons.discount_reason")}
        </Typography>
        <Button className="main-button" onClick={handleSaveDiscountReasons}>
          {t("reasons.save")}
        </Button>
      </Box>
      <Divider style={{ backgroundColor: " #EEEEEE" }} />
      <Box
        sx={{
          height: "75vh",
          overflowY: "scroll",
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Paper
          elevation={0}
          style={{
            marginLeft: "2.4%",
            marginTop: "0.875rem",
            width: "65%",
            padding: "0.5rem 0.625rem ",
            borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)",
          }}
          className="reason-page"
        >
          <div style={{ width: "100%" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Typography
                className="reason-head"
                style={{ marginRight: "6.5%" }}
              >
                {t("reasons.reason_type")}
              </Typography>
              {/* <Switch
                color="warning"
                onChange={(e) => {
                  setDiscountSwitch(e.target.checked);
                  handleAllCheckBoxesFalse()
                }
                }
                checked={discountSwitch}
              /> */}
            </div>
            <Typography className="reason-para" sx={{ marginTop: "5px" }}>
              {t("reasons.reason_para")}
            </Typography>
          </div>

          <FormGroup
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginLeft: "0.625rem",
              marginTop: "0.625rem",
              width: "100%",
            }}
          >
            {checkboxes.map((checkbox) => (
              <div
                key={checkbox.id}
                onMouseEnter={() => setHoveredIndex(checkbox.id)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{ position: "relative", width: "45%" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox.checked}
                      onChange={() => handleCheckboxChange(checkbox.id)}
                      color="warning"
                      sx={{ width: "1rem", height: "1rem" }}
                    />
                  }
                  label={
                    <Typography
                      className="reason-head4"
                      sx={{
                        marginLeft: "0.625rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {checkbox.reason}
                    </Typography>
                  }
                  className="reason-chekbox-styling"
                />
                {hoveredIndex === checkbox.id && (
                  <IconButton
                    aria-label="delete"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: "5%",
                      "&:hover": {
                        background: "#FFF8F0",
                      },
                    }}
                    onClick={() => handleDeleteReason(checkbox.id)}
                  >
                    <DeleteIcon sx={{ width: "70%" }} />
                  </IconButton>
                )}
              </div>
            ))}
          </FormGroup>

          {!addValue && (
            <Button
              onClick={handleAdd}
              style={{ marginTop: "0.5rem", textTransform: "none" }}
              className="reason-reason"
            >
              {t("reasons.add_reason")}{" "}
            </Button>
          )}
          <div>
            {addValue && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <TextField
                  label="Enter Name"
                  value={newLabel}
                  onChange={handleNewLabelChange}
                  variant="outlined"
                  margin="dense"
                  style={{}}
                  color="warning"
                />

                <Button
                  onClick={() => setAddValue(false)}
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                  }}
                  className="reason-cancel"
                >
                  {t("reasons.cancel")}{" "}
                </Button>
                <Button
                  onClick={() => handleAddCheckbox("Discount")}
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                  }}
                  className="main-button"
                >
                  {t("reasons.add_reason")}
                </Button>
              </div>
            )}
          </div>
        </Paper>
        <Paper
          elevation={0}
          style={{
            marginLeft: "2.4%",
            marginTop: "1rem",
            width: "65%",
            padding: "0.5rem 0.625rem ",
            borderLeft: "2px solid  var(--stroke-color-1-violet, #BAE29B)",
          }}
          className="reason-page"
        >
          <div style={{ width: "54%" }}>
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <Typography
                className="reason-head"
                style={{ marginRight: "6.5%" }}
              >
                {" "}
                {t("reasons.void_reason")}
              </Typography>
              {/* <Switch
                color="warning"
                onChange={(e) => {
                  setVoidSwitch(e.target.checked);
                  handleAllCheckBoxesFalse1()
                }}
                checked={voidSwitch}
              /> */}
            </div>
          </div>
          <Typography className="reason-para" sx={{ marginTop: "5px" }}>
            {t("reasons.void_para")}
          </Typography>

          <FormGroup
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "0.5rem",
              marginLeft: "0.625rem",
              width: "100%",
              marginTop: "0.625rem",
            }}
          >
            {voidCheckboxes.map((checkbox, index) => (
              <div
                key={checkbox.id}
                onMouseEnter={() => setHoveredIndex(checkbox.id)}
                onMouseLeave={() => setHoveredIndex(null)}
                style={{ position: "relative", width: "45%" }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={checkbox.checked}
                      onChange={() => handleVoidCheckboxChange(checkbox.id)}
                      color="warning"
                      sx={{ width: "1rem", height: "1rem" }}
                    />
                  }
                  label={
                    <Typography
                      className="reason-head4"
                      sx={{
                        marginLeft: "0.625rem",
                        textTransform: "capitalize",
                      }}
                    >
                      {checkbox.reason}
                    </Typography>
                  }
                  className="reason-chekbox-styling"
                />
                {hoveredIndex === checkbox.id && (
                  <IconButton
                    aria-label="delete"
                    sx={{
                      position: "absolute",
                      top: 0,
                      right: "5%",

                      "&:hover": {
                        background: "#FFF8F0",
                      },
                    }}
                    onClick={() => handleDeleteReason(checkbox.id)}
                  >
                    <DeleteIcon sx={{ width: "70%" }} />
                  </IconButton>
                )}
              </div>
            ))}
          </FormGroup>

          {!addVoidValue && (
            <Button
              onClick={handleVoidAdd}
              style={{ marginTop: "0.5rem", textTransform: "none" }}
              className="reason-reason"
            >
              {t("reasons.add_reason")}
            </Button>
          )}
          <div>
            {addVoidValue && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "0.5rem",
                }}
              >
                <TextField
                  label={t("reasons.enter_name")}
                  value={newLabel}
                  onChange={handleNewLabelChange}
                  variant="outlined"
                  margin="dense"
                  style={{}}
                  color="warning"
                />

                <Button
                  onClick={() => setAddVoidValue(false)}
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                  }}
                  className="reason-cancel"
                >
                  {t("reasons.cancel")}{" "}
                </Button>
                <Button
                  onClick={() => handleAddCheckbox("Void")}
                  style={{
                    marginLeft: "10px",
                    display: "flex",
                    alignItems: "center",
                    textTransform: "none",
                  }}
                  className="main-button"
                >
                  {t("reasons.add_reason")}
                </Button>
              </div>
            )}
          </div>
          <ErrorModal
            errorModal={errorModal}
            statusCode={statusCode}
            handleErrorModal={handleErrorModal}
          />
        </Paper>
      </Box>
    </div>
  );
};
export default DiscountReason;
