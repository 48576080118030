import { Box, Typography } from "@mui/material";
import ActivityBarChartIcon from "../../../assets/mianDashboard/Activity-bar-chart.svg";
import ActivityGraph from "./activityGraph";
import React, { useEffect, useState } from "react";
import DashboardApi from "../../../services/http/dashboard";
import { ActivityData } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

interface State {
  activityForDashboard: any[];
}

const Activity = (props: State) => {
  const { t } = useTranslation();

  const { activityForDashboard } = props;

  // const [activity, setActivity] = useState<ActivityData[]>();

  // useEffect(() => {
  //   const fetchActivity = async () => {
  //     try {
  //       DashboardApi.getActivity().then(({ data }) => {
  //         setActivity(data?.activity);
  //       })
  //         .catch((e) => {
  //           console.log("Error while fetching activity: ", e);
  //         })
  //     } catch (e) {
  //       console.log("Error while fetching activity: ", e)
  //     }
  //   }
  //   fetchActivity();
  // }, [])

  const mappedDataLabels = activityForDashboard?.map((item) => item?.date);
  const mappedDataValues1 = activityForDashboard?.map((item) => item?.orders);
  const mappedDataValues2 = activityForDashboard?.map(
    (item) => item?.customers
  );

  return (
    <Box
      sx={{
        width: { xs: "100%", md: "62.3%" },
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box sx={{ padding: "20px" }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: "21px" }}>
          <img src={ActivityBarChartIcon} alt="ActivityBarChartIcon" />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              color: "#333333",
            }}
          >
            {t("dashboard.Activity")}
          </Typography>
        </Box>
        <ActivityGraph
          label={mappedDataLabels}
          values1={mappedDataValues1}
          values2={mappedDataValues2}
        />
      </Box>
    </Box>
  );
};

export default Activity;
