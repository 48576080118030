export const BASE_URL = "http://51.75.170.243:8080/v1/ordering"

export const ORDERING_GRAPHQL_BASE_URL = "http://51.75.170.243:8080/v1/ordering"
export const BUSINESS_GRAPHQL_BASE_URL = "http://51.75.170.243:8080/v1/business"
export const CUSTOMER_GRAPHQL_BASE_URL = "http://51.75.170.243:8080/v1/customer"
export const ADDRESS_GRAPHQL_BASE_URL = "http://51.75.170.243:8080/v1/address"

export const clientName = {
  ordering: { context: { clientName: "ordering" } },
  business: { context: { clientName: "business" } },
  customer: { context: { clientName: "customer" } },
};

