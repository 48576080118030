import "chart.js/auto";

import {
  Chart as ChartJS,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import React from "react";
import { Bar } from "react-chartjs-2";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

interface Props {
  label?: string[];
  values1?: any;
  values2?: any;
}

const ActivityGraph = ({ label, values1, values2 }: Props) => {
  const { t } = useTranslation();

  const getOrCreateLegendList = (id: any) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.margin = "0";
      listContainer.style.padding = "0";
      listContainer.style.gap = "26px";
      listContainer.style.borderBottom = "1px solid #EEEEEE";
      listContainer.style.paddingBottom = "26px";
      listContainer.style.justifyContent = "left";

      legendContainer && legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList("legend-container-activity");

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";

        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box

        let boxSpan = null;
        boxSpan = document.createElement("span");
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";
        boxSpan.style.borderRadius = "2px";

        // Text
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.fontSize = "16px";
        textContainer.style.fontWeight = "500";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  const labels = label;
  const datasets = [
    {
      label: `${t("dashboard.Orders")}`,
      data: values1,
      borderColor: "#FBDCB5",
      backgroundColor: "#FBDCB5",
      borderWidth: 1,
      borderRadius: 1,
    },
    {
      label: `${t("dashboard.Customers")}`,
      data: values2,
      borderColor: "#F6A846",
      backgroundColor: "#F6A846",
      borderWidth: 1,
      borderRadius: 1,
    },
  ];
  const options: any = {
    responsive: true,
    showTooltips: true,
    maintainAspectRatio: false,

    aspectRatio: 2.7,
    plugins: {
      customCanvasBackgroundColor: {
        color: "#F3F5FA",
      },
      legend: {
        display: false,
        position: "top",
        align: "center",
        labels: {
          usePointStyle: true,
          boxWidth: 5,
          boxHeight: 5,
          padding: 5,
          textAlign: "left",
        },
      },
      title: {
        display: true,
      },
    },
    scales: {
      x: {
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
      },

      y: {
        beginAtZero: true,

        grid: {
          color: "#F2F2F2",
          borderDash: 5,
        },
        border: {
          dash: [8, 8],
          display: false,
        },
      },
    },
  };

  const data = {
    labels,
    datasets,
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        marginTop: "15px",
      }}
    >
      <Box id="legend-container-activity"></Box>
      <Box sx={{ height: "100%", pading: "6px", width: "100%" }}>
        <Bar
          height={400}
          width={"100%"}
          options={options}
          data={data}
          plugins={[htmlLegendPlugin]}
        />
      </Box>
    </Box>
  );
};
export default ActivityGraph;
