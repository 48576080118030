import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { useTranslation } from "react-i18next";

interface LocationPopupState {
  open: boolean;
  handleClose: () => void;
  spantext: string;
  beforeSpanText: string;
  afterSpanText: string;
}

const LocationAlertPopup = (props: LocationPopupState) => {
  const { t } = useTranslation();
  const { open, handleClose, spantext, beforeSpanText, afterSpanText } = props;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "38%",
    bgcolor: "background.paper",
    borderRadius: "8px",
    p: 3,
  };

  return (
    <Box>
      <Modal open={open} onClose={handleClose}>
        <Box sx={style}>
          <Box sx={{ padding: "5px" }}>
            <IconButton
              onClick={handleClose}
              className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{ marginTop: "5px", marginBottom: "5px", width: "100%" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "5px",
              padding: "10px",
            }}
          >
            <Typography className="alert-popup-text">
              {beforeSpanText}{" "}
              <span className="alert-popup-text-span">{spantext}</span>{" "}
              {afterSpanText}
            </Typography>
          </Box>
          <Box sx={{ textAlign: "right", padding: "10px" }}>
            <Button
              onClick={handleClose}
              color="warning"
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              {t("buttons.ok")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default LocationAlertPopup;
