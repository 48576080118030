import { Box } from "@mui/material";
import React from "react";
import CategoryReport from "../../components/reports/categories";

const CategoriesReportPage = () => {
  return (
    <Box>
      <CategoryReport />
    </Box>
  );
};
export default CategoriesReportPage;
