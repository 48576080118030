import React, { ChangeEvent, useEffect, useRef, useState } from "react";
import { Box, Button, Divider, IconButton } from "@mui/material";
import { Paper, Typography, TextField } from "@mui/material";
import "./createDiscount.css";
import { FormControlLabel } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Switch } from "@mui/material";
import AssignModalInDiscount, { ChildRef } from "./assignItemsModal";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import CustomerModal, { CustomerRef } from "./customerGroupModal";
import { styled } from '@mui/system';
import CurrencyDisplay from "../utils/currencySymbol";
import { useTranslation } from "react-i18next";

interface Props {
  formik: any;
  handleDataFromSubChild: (data: any) => void;
  itemsList: any;
  handleEligibleDataFromSubChild: (data: any) => void;
  eligibleItemsList: any;
}
const CreateDiscountExactQty = (props: Props) => {

  const CustomStyles = styled('div')({
    '& .MuiTableCell-root': {
      padding: "0px",
      paddingLeft: "10px",
      borderBottom: "0px solid #EEEEEE",
      textTransform: "capitalize",


    }
  });
  const { handleDataFromSubChild, formik, itemsList, handleEligibleDataFromSubChild, eligibleItemsList, } = props
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };

  const [openCustomerModal, setOpenCustomerModal] = useState(false);

  const handleOpenCustomerModal = () => {
    setOpenCustomerModal(!openCustomerModal);
  };
  const [finalList, setFinalList] = useState<{ type: string, id: string, price: string, name: string }[]>([])
  const [finalCustomerList, setFinalCoustomerList] = useState<{ type: string, id: string, price: string, name: string }[]>([])
  const [SecondFinalList, setSecondFinalList] = useState<{ type: string, id: string, price: string, name: string }[]>([])
  const childRef = useRef<ChildRef>(null);
  const customerRef = useRef<CustomerRef>(null);

  const [buy1String, setBuy1String] = useState("")

  const handleClickInParent = (type: string, id: string, value: string) => {
    // Call function in child component
    if (childRef.current) {
      childRef.current.childFunction(type, id, value);

    }
  };

  const handleClickInCustomerParent = (type: string, id: string) => {
    // Call function in child component
    if (customerRef.current) {
      customerRef.current.deletCustomer(id);

    }
  };

  const handleCopyAllItems = () => {
    if (childRef.current) {

      childRef.current.copyAllItems();

    }
    setSecondFinalList(finalList)
  }

  const discountType = sessionStorage.getItem("discountType")
  const { t } = useTranslation();

  const [rulePara, setRulePara] = useState("")
  const [textFieldCondition, setTextFieldCondition] = useState(false)
  const [labelText, setLabelText] = useState("")
  const updateRuleParameters = () => {
    let rulePara = "";
    let textFieldCondition = false;
    let labelText = "";


    switch (discountType) {
      case "customerGroup":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = false;
        break;
      case "buy1Get1":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = true;
        labelText = t("createDiscount.label_text");
        break;
      case "exactQty":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = true;
        labelText = t("createDiscount.label_text");
        break;
      case "forItem":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = false;
        break;
      case "freeDiscount":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = true;
        labelText = t("createDiscount.min_cart_amount");
        break;
      case "minQty":
        rulePara = t("createDiscount.discount_para2");
        textFieldCondition = true;
        labelText = t("createDiscount.min_qty");
        break;
      default:
        break;
    }

    setRulePara(rulePara);
    setTextFieldCondition(textFieldCondition);
    setLabelText(labelText)
  };

  // Call the function whenever `discountType` changes
  useEffect(() => {
    updateRuleParameters();
  }, [discountType]);

  const handleAllItems = () => {

  }

  return (
    // discount details
    <div  >
      <div >
        <Paper
          elevation={3}
          style={{
            padding: "10px",

            borderLeft: "2px solid #9BDAE2",
          }}
        >
          <Typography className="createDiscount_table_head">
            {t("createDiscount.discount_rule")}{" "}
            <span className="createDiscount_span_text">
              {t("createDiscount.rule_para")}
            </span>
          </Typography>

          {discountType === "customerGroup" &&

            <div
              className="createDiscount_discountRule_box"
            // style={{ marginTop: "20px" }}
            >
              <Box style={{ backgroundColor: " #FBFBFB", padding: "12px 8px 13px 12px" }}>
                <Typography className="createDiscount_table_head2">

                  {t("createDiscount.customer_para")}
                </Typography>
              </Box>
              <div
                style={{
                  display: "flex",

                  border: "1px solid #EEE",
                }}
              >
                <div style={{ backgroundColor: "#FFFFFF", width: "22%" }}>
                  {textFieldCondition ? (
                    <div>
                      <TextField
                        variant="standard"
                        placeholder={labelText}
                        className="hide-number-input-icons"
                        type="number"
                        sx={{ width: "100%" }}
                        InputProps={{
                          disableUnderline: true, // This removes the underline
                        }}
                      />
                      <Divider />
                    </div>
                  ) : <Typography className="applyItems-text" sx={{ padding: "12px" }}>{t("createDiscount.apply_to_customers")}</Typography>}
                </div>
                <Box sx={{ border: "1px solid #EEEEEE", width: "78%" }}>

                  {(finalCustomerList.length > 0 && !formik.values.allItemsBuy1) &&
                    <CustomStyles>
                      <TableContainer sx={{ maxHeight: "250px", overflowY: "scroll" }}>
                        <Table sx={{}} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>


                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {finalCustomerList.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: "0px" }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography className="table-product-text"> {row.name}</Typography>

                                </TableCell>
                                <TableCell align="right"><Typography className="table-product-text">{row.price}</Typography></TableCell>
                                <TableCell align="left"><IconButton onClick={() => handleClickInCustomerParent(row.type, row.id)}><CloseIcon /></IconButton></TableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomStyles>
                  }
                  <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                    <Button
                      className="createDiscount_button_style"
                      onClick={() => { handleOpenCustomerModal() }}
                      disabled={formik.values.allItemsBuy1}
                      sx={{ textTransform: "none" }}
                    >
                      {t("createDiscount.add_customer_group")}
                    </Button>
                    {/* <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography className="createDiscount_apply_all">
                        Apply to all items
                      </Typography>

                      <FormControlLabel
                        label=""
                        control={<Switch color="warning"
                          name="allItemInBuy1"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.handleChange(e)}
                        />}
                        style={{ marginLeft: "5px" }}
                      />
                    </div> */}
                  </Box>
                </Box>
              </div>
            </div>}

          <div
            className="createDiscount_discountRule_box"
            style={{ marginTop: "20px" }}
          >
            <Box sx={{ backgroundColor: "#FBFBFB", padding: "12px 8px 13px 12px" }}>
              <Typography className="createDiscount_table_head2">
                {/* When customer purchases any item from */}
                {rulePara}
              </Typography>
            </Box>
            <div
              style={{
                display: "flex",

                border: "1px solid #EEE",
              }}
            >
              <div style={{ backgroundColor: "#FFFFFF", width: "22%", padding: "14px 32px 0px 8px" }}>
                {textFieldCondition ? (
                  <div>
                    <TextField
                      variant="standard"
                      placeholder={labelText}
                      name="itemQuantity"
                      type="number"
                      className="hide-number-input-icons"
                      onBlur={formik.handleBlur}
                      value={formik.values.itemQuantity}
                      onChange={(e) => formik.handleChange(e)}
                      InputProps={{
                        disableUnderline: true, // This removes the underline
                      }}
                    />


                    <Divider />
                  </div>
                ) : <Typography className="applyItems-text">{t("createDiscount.apply_to_items")}</Typography>}
              </div>
              <Box sx={{ border: "1px solid #EEEEEE", width: "78%" }}>

                {(finalList.length > 0 && !formik.values.allItems) &&
                  <CustomStyles>
                    <TableContainer sx={{ maxHeight: "250px", overflowY: "scroll" }}>
                      <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>


                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {finalList.map((row) => (
                            <TableRow
                              key={row.name}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 }, padding: "0px" }}
                            >
                              <TableCell component="th" scope="row">
                                <Typography className="table-product-text" sx={{ padding: "0px" }}> {row.name}</Typography>

                              </TableCell>
                              <TableCell align="right"><Typography className="table-price-text">{row.type === "product" ? <CurrencyDisplay countryName={'UnitedKingdom'} amount={row.price ? row.price.split('-') : "0"} /> : row.price
                              }</Typography></TableCell>
                              <TableCell align="left" width={20}><IconButton onClick={() => handleClickInParent(row.type, row.id, "first")}
                                sx={{
                                  '&: hover': {
                                    backgroundColor: '*FFF8F0 !important'
                                  }
                                }}><CloseIcon /></IconButton></TableCell>

                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </CustomStyles>
                }
                <Box style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                  <Button
                    className={formik.values.allItems ? "createDiscount_button_style2" : "createDiscount_button_style"}
                    onClick={() => { handleOpenModal(); setBuy1String("first") }}

                    disabled={formik.values.allItems}
                    sx={{ textTransform: "none" }}
                  >
                    {t("createDiscount.add_eligible_items")}
                  </Button>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography className="createDiscount_apply_all">
                      {t("createDiscount.apply_to_all")}
                    </Typography>

                    <FormControlLabel
                      label=""
                      control={<Switch color="warning"
                        name="allItems"
                        checked={formik.values.allItems}

                        onChange={(e: ChangeEvent<HTMLInputElement>) => { formik.handleChange(e); handleAllItems() }}
                      />}
                      style={{ marginLeft: "5px" }}
                    />
                  </div>
                </Box>
              </Box>

            </div>
            {formik.touched.itemQuantity && formik.errors.itemQuantity && (
              <div style={{ color: "red" }}><Typography sx={{ fontSize: '12px' }}>{formik.errors.itemQuantity as string}</Typography></div>
            )}
          </div>

          {discountType === "buy1Get1" &&

            <div
              className="createDiscount_discountRule_box"
              style={{ marginTop: "20px" }}
            >
              <Box style={{ backgroundColor: " #FBFBFB", padding: "12px 8px" }}>

                <Typography className="createDiscount_table_head2">

                  {t("createDiscount.apply_para")}                </Typography>
              </Box>
              <div
                style={{
                  display: "flex",

                  border: "1px solid #EEE",

                }}
              >
                <div style={{ backgroundColor: "#FFFFFF", width: "22%", padding: "12px" }}>
                  {textFieldCondition ? (
                    <TextField
                      variant="standard"
                      placeholder={labelText}
                      name="eligibleItemsQty"
                      type="number"
                      className="hide-number-input-icons"
                      onChange={(e) => formik.handleChange(e)}
                      value={formik.values.eligibleItemsQty}
                      onBlur={formik.handleBlur}

                      InputProps={{
                        disableUnderline: true, // This removes the underline
                      }}
                    />
                  ) : <Typography>{t("createDiscount.apply_to_items")}</Typography>}
                  <Divider />
                </div>
                <Box sx={{ border: "1px solid #EEEEEE", width: "78%" }}>

                  {(SecondFinalList.length > 0 && !formik.values.allItemsBuy1) &&
                    <CustomStyles>
                      <TableContainer sx={{ maxHeight: "250px", overflowY: "scroll" }}>
                        <Table sx={{}} size="small" aria-label="a dense table">
                          <TableHead>
                            <TableRow>


                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {SecondFinalList.map((row) => (
                              <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                <TableCell component="th" scope="row">
                                  <Typography className="table-product-text">{row.name}</Typography>
                                </TableCell>
                                <TableCell align="right"><Typography className="table-price-text">{row.price}</Typography></TableCell>
                                <TableCell align="left" width={20}><IconButton onClick={() => handleClickInParent(row.type, row.id, "second")}><CloseIcon /></IconButton></TableCell>

                              </TableRow>
                            ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </CustomStyles>
                  }
                  <Box style={{ display: "flex", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', flexDirection: "column" }}>
                      <Button
                        className={formik.values.allItemInBuy1
                          ? "createDiscount_button_style2" : "createDiscount_button_style"}

                        disabled={formik.values.allItemInBuy1
                        }
                        sx={{ textTransform: "none" }}
                        onClick={handleCopyAllItems}
                      >
                        {t("createDiscount.copy_items_para")}
                      </Button>
                      <Button
                        className={formik.values.allItemInBuy1
                          ? "createDiscount_button_style2" : "createDiscount_button_style"}
                        onClick={() => { handleOpenModal(); setBuy1String("second") }}
                        disabled={formik.values.allItemInBuy1
                        }
                        sx={{ textTransform: "none" }}
                      >
                        {t("createDiscount.add_eligible")}
                      </Button>
                    </Box>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Typography className="createDiscount_apply_all">
                        {t("createDiscount.apply_to_all")}
                      </Typography>

                      <FormControlLabel
                        label=""
                        control={<Switch color="warning"
                          name="allItemInBuy1"
                          checked={formik.values.allItemInBuy1}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => formik.handleChange(e)}
                        />}
                        style={{ marginLeft: "5px" }}
                      />
                    </div>
                  </Box>
                </Box>
              </div>
              {formik.touched.eligibleItemsQty && formik.errors.eligibleItemsQty && (
                <div style={{ color: "red" }}><Typography sx={{ fontSize: '12px' }}>{formik.errors.eligibleItemsQty as string}</Typography></div>
              )}
            </div>}
        </Paper>
        {/* <Typography className="createMinimum_para">
          If multiple automatic discounts are applicable, the greater discount
          will be applied. Learn more
        </Typography> */}
      </div>
      <AssignModalInDiscount
        openModal={openModal}
        handleOpenModal={handleOpenModal}
        ref={childRef}
        setFinalList={setFinalList}
        buy1String={buy1String}
        handleDataFromSubChild={handleDataFromSubChild}
        setSecondFinalList={setSecondFinalList}
        handleEligibleDataFromSubChild={handleEligibleDataFromSubChild}
        itemsList={itemsList}
        eligibleItemsList={eligibleItemsList}
      />
      <CustomerModal openCustomerModal={openCustomerModal} handleOpenCustomerModal={handleOpenCustomerModal} setFinalCoustomerList={setFinalCoustomerList} ref={customerRef} formik={formik} />
    </div>
  );
};

export default CreateDiscountExactQty;
