import React from "react";
import Header from "./header";
import { Box } from "@mui/material";
import Footer from "./footer";
import Wellcome from "./wellcome";
import NavigationProvider from "./Steper/StepperNavigationContext/useNavigation";


const BusinessOnboarding = () => {
  return (
    <NavigationProvider>
      <Box >
        {/* <Header /> */}
        <Wellcome />
        <Footer />
      </Box>
    </NavigationProvider>
  );
}

export default BusinessOnboarding;
