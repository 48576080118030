import { Box, Typography, Grid, Tabs } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import KdsSetup from "./kdsSetup";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";

import PosSetup from "./posSetup";
import KioskSetup from "./kioskSetup";
import "./deviceSetUp.css";
import { useQuery } from "@apollo/client";
import { FILTERED_STORE_LIST } from "../../graphicalQl/usequery/deviceManagementQuery";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { deviceSetup } from "../../store";
import { useTranslation } from "react-i18next";
import { Margin } from "@mui/icons-material";

function DeviceSetUp() {
  const { t } = useTranslation();
  const { tabId } = useSelector((state: any) => state.deviceSetup);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [locationsListForKds, seLocationsListForKds] = useState<any[]>([]);
  const [locationsListForPos, seLocationsListForPos] = useState<any[]>([]);
  const [locationsListForKiosk, seLocationsListForKiosk] = useState<any[]>([]);
  const [collectedLocationIdsForKds, setCollectedLocationIdsForKds] = useState<
    any[]
  >([]);
  const [collectedLocationIdsForPos, setCollectedLocationIdsForPos] = useState<
    any[]
  >([]);
  const [collectedLocationIdsForKiosk, setCollectedLocationIdsForKiosk] =
    useState<any[]>([]);

  const [rowsForKds, setRowsForKds] = React.useState<any[]>([]);
  const [rowsForPos, setRowsForPos] = React.useState<any[]>([]);
  const [rowsForKiosk, setRowsForKiosk] = React.useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const filterdData =
      locationsListForKds &&
      locationsListForKds
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForKds(filterdData);
  }, [locationsListForKds]);

  useEffect(() => {
    const filterdData =
      locationsListForPos &&
      locationsListForPos
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForPos(filterdData);
  }, [locationsListForPos]);

  useEffect(() => {
    const filterdData =
      locationsListForKiosk &&
      locationsListForKiosk
        .filter((each: any) => each.isChecked)
        .map((each: any) => +each.storeId);
    setCollectedLocationIdsForKiosk(filterdData);
  }, [locationsListForKiosk]);

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: true,
        })
      );
      seLocationsListForKds(requiredDataList);
      seLocationsListForPos(requiredDataList);
      seLocationsListForKiosk(requiredDataList);
    }
  }, [locationsData]);

  const [endCursorForKds, setEndCursorForKds] = useState<string | null>(null);
  const [startCursorForKds, setStartCursorForKds] = useState<string | null>(
    null
  );

  const [paginationModelForKds, setPaginationModelForKds] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForKds, setCurrentPageForKds] = React.useState(0);

  const filteredDataFromNullForKds = {
    storeIds:
      collectedLocationIdsForKds.length > 0
        ? JSON.stringify(collectedLocationIdsForKds)
        : JSON.stringify([null]),
    first: !startCursorForKds ? paginationModelForKds.pageSize : null,
    after: endCursorForKds,
    before: startCursorForKds,
    last: startCursorForKds && paginationModelForKds.pageSize,
  };
  const filteredIdsFromKds = Object.fromEntries(
    Object.entries(filteredDataFromNullForKds).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: storeListDataForKds,
    loading: storeListLoading,
    error: storeListError,
  } = useQuery(FILTERED_STORE_LIST, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKds,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (storeListDataForKds) {
      const { selectedStoreList } = storeListDataForKds;
      if (selectedStoreList) {
        const { edges } = selectedStoreList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              id: eachStore.node.storeId,
              storeName: eachStore.node.storeName,
              noOfKds: eachStore.node.noOfKds,
              noOfPos: eachStore.node.noOfPos,
            };
          });
          setRowsForKds(filteredList);
        }
      }
    } else {
      setRowsForKds([]);
    }
  }, [storeListDataForKds]);

  const [endCursorForPos, setEndCursorForPos] = useState<string | null>(null);
  const [startCursorForPos, setStartCursorForPos] = useState<string | null>(
    null
  );

  const [paginationModelForPos, setPaginationModelForPos] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForPos, setCurrentPageForPos] = React.useState(0);

  const shouldUpdatePaginationModelIsTrueForPos = (newModel: any) => {
    setPaginationModelForPos(newModel);
    setCurrentPageForPos(newModel.page);
  };

  const handleUpdatingValuesToNullForPos = (newModel: any) => {
    setStartCursorForPos(null);
    setEndCursorForPos(null);
    setCurrentPageForPos(0);
    setPaginationModelForPos({
      ...newModel,
      page: 0,
    });
  };

  const [endCursorForKiosk, setEndCursorForKiosk] = useState<string | null>(
    null
  );
  const [startCursorForKiosk, setStartCursorForKiosk] = useState<string | null>(
    null
  );

  const [paginationModelForKiosk, setPaginationModelForKiosk] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPageForKiosk, setCurrentPageForKiosk] = React.useState(0);

  const shouldUpdatePaginationModelIsTrueForKiosk = (newModel: any) => {
    setPaginationModelForKiosk(newModel);
    setCurrentPageForKiosk(newModel.page);
  };

  const handleUpdatingValuesToNullForKiosk = (newModel: any) => {
    setStartCursorForKiosk(null);
    setEndCursorForKiosk(null);
    setCurrentPageForKiosk(0);
    setPaginationModelForKiosk({
      ...newModel,
      page: 0,
    });
  };

  const shouldUpdatePaginationModelIsTrueForKds = (newModel: any) => {
    setPaginationModelForKds(newModel);
    setCurrentPageForKds(newModel.page);
  };

  const handleUpdatingValuesToNullForKds = (newModel: any) => {
    setStartCursorForKds(null);
    setEndCursorForKds(null);
    setCurrentPageForKds(0);
    setPaginationModelForKds({
      ...newModel,
      page: 0,
    });
  };

  const filteredDataFromNullForPos = {
    storeIds:
      collectedLocationIdsForPos.length > 0
        ? JSON.stringify(collectedLocationIdsForPos)
        : JSON.stringify([null]),
    first: !startCursorForPos ? paginationModelForPos.pageSize : null,
    after: endCursorForPos,
    before: startCursorForPos,
    last: startCursorForPos && paginationModelForPos.pageSize,
  };
  const filteredIdsFromPos = Object.fromEntries(
    Object.entries(filteredDataFromNullForPos).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: storeListDataForPos,
    loading: storeListLoadingPos,
    error: storeListErrorPos,
  } = useQuery(FILTERED_STORE_LIST, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromPos,
    fetchPolicy: "network-only",
  });

  const handleEndCursorForPos = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      storeListDataForPos?.selectedStoreList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForPos = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      storeListDataForPos?.selectedStoreList?.pageInfo.startCursor || null
    );
  };

  const handleEndCursorForKds = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      storeListDataForKds?.selectedStoreList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForKds = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      storeListDataForKds?.selectedStoreList?.pageInfo.startCursor || null
    );
  };

  const handleEndCursorForKiosk = () => {
    setStartCursorForPos(null);
    setEndCursorForPos(
      storeListDataForKiosk?.selectedStoreList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursorForKiosk = () => {
    setEndCursorForPos(null);
    setStartCursorForPos(
      storeListDataForKiosk?.selectedStoreList?.pageInfo.startCursor || null
    );
  };

  useEffect(() => {
    if (storeListDataForPos) {
      const { selectedStoreList } = storeListDataForPos;
      if (selectedStoreList) {
        const { edges } = selectedStoreList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              id: eachStore.node.storeId,
              storeName: eachStore.node.storeName,
              noOfKds: eachStore.node.noOfKds,
              noOfPos: eachStore.node.noOfPos,
            };
          });
          setRowsForPos(filteredList);
        } else {
          setRowsForPos([]);
        }
      }
    }
  }, [storeListDataForPos]);

  const filteredDataFromNullForKiosk = {
    storeIds:
      collectedLocationIdsForKiosk.length > 0
        ? JSON.stringify(collectedLocationIdsForKiosk)
        : JSON.stringify([null]),
    first: !startCursorForKiosk ? paginationModelForKiosk.pageSize : null,
    after: endCursorForKiosk,
    before: startCursorForKiosk,
    last: startCursorForKiosk && paginationModelForKiosk.pageSize,
  };
  const filteredIdsFromKiosk = Object.fromEntries(
    Object.entries(filteredDataFromNullForKiosk).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: storeListDataForKiosk,
    loading: storeListLoadingKisok,
    error: storeListErrorKiosk,
  } = useQuery(FILTERED_STORE_LIST, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKiosk,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (storeListDataForKiosk) {
      const { selectedStoreList } = storeListDataForKiosk;
      if (selectedStoreList) {
        const { edges } = selectedStoreList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => {
            return {
              id: eachStore.node.storeId,
              storeName: eachStore.node.storeName,
              noOfKds: eachStore.node.noOfKds,
              noOfPos: eachStore.node.noOfPos,
              noOfKiosk: eachStore.node.noOfKiosk,
            };
          });
          setRowsForKiosk(filteredList);
        } else {
          setRowsForKiosk([]);
        }
      }
    }
  }, [storeListDataForKiosk]);

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    dispatch(deviceSetup("tabId", newValue));
  };

  return (
    <Grid container className="device-setup-container">
      <Grid className="device-setup-header-container" item xs={12}>
        <Typography className="device-set-up-header">
          {t("devices.device_setup")}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={12}>
        <TabContext value={tabId}>
          <Grid item xs={12} sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={tabId}
              onChange={handleChange}
              variant="scrollable"
              scrollButtons="auto"
              sx={{
                backgroundColor: "#F9F9F9",
                // margin: "0px 0px 0px 10px",
                // padding: "0px 0px 0px 10px",
              }}
              className="background"
            >
              <Tab
                label={
                  <Typography
                    className={
                      tabId === "1"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.pos_Setup")}
                  </Typography>
                }
                className={
                  tabId === "1"
                    ? "change-color device-setup-tabs-margin"
                    : "add-color device-setup-tabs-margin"
                }
                value="1"
              />
              <Tab
                sx={{ marginRight: "10px" }}
                label={
                  <Typography
                    className={
                      tabId === "2"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.kds_setup")}
                  </Typography>
                }
                className={tabId === "2" ? "change-color" : "add-color"}
                value="2"
              />
              <Tab
                label={
                  <Typography
                    className={
                      tabId === "3"
                        ? "device-set-up-tabs-header-orange"
                        : "device-set-up-tabs-header"
                    }
                  >
                    {t("devices.kiosk_setup")}
                  </Typography>
                }
                className={tabId === "3" ? "change-color" : "add-color"}
                value="3"
              />
            </Tabs>
          </Grid>
          <Grid
            sx={{
              "& .MuiTabPanel-root": {
                padding: "18px !important",
              },
            }}
            item
            xs={12}
          >
            <TabPanel value="1">
              <PosSetup
                Loading={storeListLoadingPos ? storeListLoadingPos : false}
                shouldUpdatePaginationModelIsTrue={
                  shouldUpdatePaginationModelIsTrueForPos
                }
                handleUpdatingValuesToNull={handleUpdatingValuesToNullForPos}
                paginationModel={paginationModelForPos}
                handleEndCursor={handleEndCursorForPos}
                handleStartCursor={handleStartCursorForPos}
                currentPage={currentPageForPos}
                rows={rowsForPos}
                seLocationsList={seLocationsListForPos}
                locationsList={locationsListForPos}
              />
            </TabPanel>
            <TabPanel value="2">
              <KdsSetup
                Loading={storeListLoading ? storeListLoading : false}
                shouldUpdatePaginationModelIsTrue={
                  shouldUpdatePaginationModelIsTrueForKds
                }
                handleUpdatingValuesToNull={handleUpdatingValuesToNullForKds}
                paginationModel={paginationModelForKds}
                handleEndCursor={handleEndCursorForKds}
                handleStartCursor={handleStartCursorForKds}
                currentPage={currentPageForKds}
                rows={rowsForKds}
                seLocationsList={seLocationsListForKds}
                locationsList={locationsListForKds}
              />
            </TabPanel>
            <TabPanel value="3">
              <KioskSetup
                Loading={storeListLoadingKisok ? storeListLoadingKisok : false}
                shouldUpdatePaginationModelIsTrue={
                  shouldUpdatePaginationModelIsTrueForKiosk
                }
                handleUpdatingValuesToNull={handleUpdatingValuesToNullForKiosk}
                paginationModel={paginationModelForKiosk}
                handleEndCursor={handleEndCursorForKiosk}
                handleStartCursor={handleStartCursorForKiosk}
                currentPage={currentPageForKiosk}
                rows={rowsForKiosk}
                seLocationsList={seLocationsListForKiosk}
                locationsList={locationsListForKiosk}
              />
            </TabPanel>
          </Grid>
        </TabContext>
      </Grid>
    </Grid>
  );
}

export default DeviceSetUp;
