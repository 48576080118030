import {
  Box,
  Button,
  Typography,
  IconButton,
  Paper,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import LocationAlertPopup from "./locationComformationPopup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
interface ContactImformationState {
  formik: any;
  editId: string | undefined;
  conditions: boolean[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  formikForBusinessAddress: any;
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
}
const LocationContactInformation = (props: ContactImformationState) => {
  const {
    formik,
    editId,
    conditions,
    setActiveStep,
    formikForBusinessAddress,
    setConditions,
  } = props;
  const [alertPopup, setAlertPopup] = useState<boolean>(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (
      (conditions[1] === true &&
        formikForBusinessAddress.values.address1 == null) ||
      (conditions[1] === true && formikForBusinessAddress.values.address1 == "")
    ) {
      setAlertPopup(true);
    } else {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[1] = false;
        return updatedConditions;
      });
    }
  }, []);

  const index = conditions.findIndex((condition) => condition === true);

  const handleAlertPopupClose = () => {
    setAlertPopup(false);
    setActiveStep(index);
  };

  const handleEmailAndPhone = (value: string, field: string) => {
    if (field === "phoneNumber") {
      if (value === "" && !formik.values.email) {
        setConditions((prevConditions) => {
          const updatedConditions = [...prevConditions];
          updatedConditions[2] = true;
          return updatedConditions;
        });
      }
    } else if (field === "email") {
      if (value === "" && !formik.values.phoneNumber) {
        setConditions((prevConditions) => {
          const updatedConditions = [...prevConditions];
          updatedConditions[2] = true;
          return updatedConditions;
        });
      }
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <LocationAlertPopup
        spantext={
          index === 0
            ? t("locations.basic_details")
            : t("locations.business_address")
        }
        beforeSpanText={t("locations.please_enter")}
        afterSpanText={t("locations.to_continue_next")}
        handleClose={handleAlertPopupClose}
        open={alertPopup}
      />
      <Paper
        elevation={2}
        sx={{ width: "90%", padding: "15px", borderLeft: "2px solid #BAE29B" }}
      >
        <Box>
          <Typography className="basic-details-heading">
            {t("locations.contact_information")}
          </Typography>
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                width: "75%",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "54%" }}
              >
                <TextField
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleEmailAndPhone(e.target.value, "email");
                  }}
                  onBlur={formik.handleBlur}
                  name="email"
                  type="email"
                  value={formik.values.email || ""}
                  color="warning"
                  sx={{ width: "92%", margin: "10px 0px 5px 0px" }}
                  label={<Typography>{t("locations.email")}</Typography>}
                  variant="outlined"
                />
                {formik.touched.email && formik.errors.email && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {t("error.required")}
                    </Typography>
                  </Box>
                )}
              </Box>

              <Box
                sx={{ display: "flex", flexDirection: "column", width: "50%" }}
              >
                <TextField
                  onBlur={formik.handleBlur}
                  name="phoneNumber"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (
                      !val ||
                      (/^[0-9]+$/.test(val) && e.target.value.length < 12)
                    ) {
                      formik.handleChange(e);
                      handleEmailAndPhone(e.target.value, "phoneNumber");
                    }
                  }}
                  value={formik.values.phoneNumber || ""}
                  color="warning"
                  sx={{ width: "99%", margin: "10px 0px 5px 0px" }}
                  label={<Typography>{t("locations.phone_number")}</Typography>}
                  type="text"
                  variant="outlined"
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {t("error.required")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "75%",
              }}
            >
              <TextField
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="twitter"
                value={formik.values.twitter || ""}
                color="warning"
                sx={{ width: "48%", margin: "10px 0px 10px 0px" }}
                label={<Typography>{t("locations.twitter")}</Typography>}
                variant="outlined"
              />
              <TextField
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="instagram"
                value={formik.values.instagram || ""}
                color="warning"
                sx={{ width: "48%", margin: "10px 0px 10px 0px" }}
                label={<Typography>{t("locations.instagram")}</Typography>}
                variant="outlined"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "75%",
              }}
            >
              <TextField
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="facebook"
                value={formik.values.facebook || ""}
                color="warning"
                sx={{ width: "48%", margin: "10px 0px 10px 0px" }}
                label={<Typography>{t("locations.facebook")}</Typography>}
                variant="outlined"
              />
              <TextField
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                name="website"
                value={formik.values.website || ""}
                color="warning"
                sx={{ width: "48%", margin: "10px 0px 10px 0px" }}
                label={<Typography>{t("locations.website")}</Typography>}
                variant="outlined"
              />
            </Box>
          </Box>

          <Box sx={{ textAlign: "right", margin: "10px 5px 10px 5px" }}>
            {editId === undefined && (
              <Button
                sx={{ textTransform: "none" }}
                className="create-a-use-role-clear-button"
                variant="outlined"
                color="warning"
              >
                {t("buttons.clear")}
              </Button>
            )}
            <Button
              type="submit"
              sx={{ textTransform: "none", marginLeft: "10px" }}
              variant="contained"
              color="warning"
            >
              {editId === undefined ? t("product.next") : t("buttons.save")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LocationContactInformation;
