import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";

import { Map } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";


import {
  CardContent,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import MyButton from "./Button";
import { useQuery } from "@apollo/client";
import { GET_STORE } from "../orderUtils/graphqlQueries";
import { clientName } from "../orderUtils/constant";
import { useEffect, useState } from "react";

export default function DeliveryCardTwo(props: any) {
  const { data, title, actionText, icon, viewLocationBtn, actionBtn, image } = props;

  const iconComponent = () => {
    return icon === "view" ? (
      <VisibilityIcon sx={{ color: "#707070", marginLeft: "10px" }} />
    ) : (
      <EditIcon sx={{ color: "#707070", marginLeft: "10px" }} />
    );
  };
  return (
    <Box
      className="css-my-text-gray-1 bg-white"

      sx={{
        padding: "10px",
        width: "100%",
        marginBottom: "20px",
        borderRadius: "4px",
      }}
    >
      <Box display={"flex"} justifyContent={"space-between"}>
        <Box sx={{ padding: 1 }} className="bg-white deliverymandiv">
          {/* <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box className="deliveryheading">{title}</Box>
            {actionBtn && <Button
              size="small"
              variant="outlined"
              onClick={() => { }}
              className={`lightgraybutton deliveryCardTwoActionText`}
            >
              {actionText}
              {iconComponent()}
            </Button>}
          </Box> */}
          {title === "Store details"&&data ? <CardContent sx={{ width: '100%', padding: '0px', marginTop: '20px' }}>
            <Grid container spacing={2}>
              <Grid item>
                <img src={image} />
              </Grid>
              <Grid item xs>
                <Box className="nameText" >{data?.storeName}</Box>
                <Box className="emailText" color="textSecondary">
                  <EmailIcon className="cardIconStyle" /> {data?.email}
                </Box>
                <Box className="phoneText" color="textSecondary">
                  <PhoneIcon className="cardIconStyle" /> {data?.mobile}
                </Box>
                {data?.address && <Box className="phoneText" color="textSecondary">
                  <Map className="cardIconStyle" /> {data?.address}
                </Box>}

              </Grid>
              {viewLocationBtn && <MyButton icon={""} lable="View location on map" />}
            </Grid>
          </CardContent> :
            title === "customer details"&&data ? (<CardContent sx={{ width: '100%', padding: '0px', marginTop: '20px' }}>
              <Grid container spacing={2}>
                <Grid item>
                  <img src={image} />
                </Grid>
                <Grid item xs>
                  <Box className="nameText" >{data?.customer_name}</Box>
                  <Box className="emailText" color="textSecondary">
                    <EmailIcon className="cardIconStyle" /> {data?.customer_email}
                  </Box>
                  <Box className="phoneText" color="textSecondary">
                    <PhoneIcon className="cardIconStyle" /> {data?.customer_mobile}
                  </Box>
                  {data?.address && <Box className="phoneText" color="textSecondary">
                    <Map className="cardIconStyle" /> {data?.address}
                  </Box>}

                </Grid>
                {/* {viewLocationBtn && <MyButton icon={""} lable="View location on map" />} */}
              </Grid>
            </CardContent>) :
              (<CardContent sx={{ width: '100%', padding: '0px', marginTop: '20px' }}>
                <Grid container spacing={2}>
                  <Grid item>
                    <img src={image} />
                  </Grid>
                  <Grid item xs>
                    <Box className="nameText" >{data?.name}</Box>
                    <Box className="emailText" color="textSecondary">
                      <EmailIcon className="cardIconStyle" /> {data?.email}
                    </Box>
                    <Box className="phoneText" color="textSecondary">
                      <PhoneIcon className="cardIconStyle" /> {data?.phone}
                    </Box>
                    {data?.address && <Box className="phoneText" color="textSecondary">
                      <Map className="cardIconStyle" /> {data?.address}
                    </Box>}

                  </Grid>
                  {/* {viewLocationBtn && <MyButton icon={""} lable="View location on map" />} */}
                </Grid>
              </CardContent>)

          }
        </Box>
      </Box>
    </Box>
  );
}
