// import { Box, Divider, IconButton, Modal, Typography } from '@mui/material'
// import React, { useEffect, useState } from 'react'
// import CloseIcon from '@mui/icons-material/Close';
// import { Scanner } from '@yudiel/react-qr-scanner';
// import './tillSettings.css';

// const deletePopupStyle = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     width: "22%",
//     height:"55vh",
//     bgcolor: 'background.paper',
//     borderRadius:"10px",
//     p: 2,
//   };

// interface State{
//     open: boolean
//     setResult:React.Dispatch<React.SetStateAction<string|null>>
//     enableCamera: boolean
//     callingFunction: boolean
//     handleSuccessPopupClose:()=>void

//     handleError:(text:any) => void

// }
// const ScanningPopup = (props:State) => {
//   const {open,handleSuccessPopupClose,setResult }=props

//   const IScannerComponents={

//     audio:false}
//   return (
//     <Box>
//          <Modal
//           open={open}
//           onClose={handleSuccessPopupClose}
//           aria-labelledby="modal-modal-title"
//           aria-describedby="modal-modal-description"
//         >
//           <Box sx={deletePopupStyle}>

//             <Box sx={{width:"65%",display:"flex",justifyContent:"space-between",alignItems:"center"}}>
//               <IconButton onClick={handleSuccessPopupClose} className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
//                 <CloseIcon/>
//               </IconButton>
//               <Typography>Scan QR Code</Typography>
//             </Box>
//             <Divider sx={{marginTop:"2%",marginBottom:"2%"}}/>

//            <Box sx={{width:"100%",display:"flex",justifyContent:"center",alignItems:"center"}}>
//            <Box sx={{width:"70%",height:"80vh"}}>
//            <Scanner
//            onResult={(text:any) =>setResult(text)}
//            onError={(error:any) => console.log(error?.message)}
//            components={IScannerComponents}

//        />
//            </Box>
//        </Box>

//           </Box>

//         </Modal>

//     </Box>

//   )
// }

// export default ScanningPopup

//

import {
  Box,
  Divider,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import { Scanner } from "@yudiel/react-qr-scanner";
import "./posSetup";
import { useTranslation } from "react-i18next";

const deletePopupStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "20%",
  height: "450px",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};

interface State {
  open: boolean;
  setResult: React.Dispatch<React.SetStateAction<string | null>>;
  enableCamera: boolean;
  callingFunction: boolean;
  handleSuccessPopupClose: () => void;
  handleError: (text: any) => void;
}

interface IScannerStyles {
  container?: React.CSSProperties;
  video?: React.CSSProperties;
  finderBorder?: number;
}
interface IScannerComponents {
  // tracker?: TrackFunction;
  audio?: boolean;
  // onOff?: boolean;
  // torch?: boolean;
  // zoom?: boolean;
  // finder?: boolean;
}
const ScanningPopup = (props: State) => {
  const scannerStyles: IScannerStyles = {
    container: {
      backgroundColor: "#ffffff",
      width: "500px",
      height: "500px",
      borderRadius: "8px",
    },
    video: {
      width: "50%",
      height: "100vh",
      borderRadius: "8px",
    },
    finderBorder: 10, // Example numeric value for the finder border
  };
  const { t } = useTranslation();
  const { open, handleSuccessPopupClose, setResult } = props;
  const components: IScannerComponents = {
    audio: false,
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleScanResult = (text: any) => {
    const result = text[0].rawValue;
    if (result) {
      setResult(result);
    }
  };

  const renderContent = () => {
    return (
      <Box className="device-setup-scanning-popup-container">
        <Box className="device-setup-icon-order">
          <IconButton
            onClick={handleSuccessPopupClose}
            className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
          >
            <CloseIcon />
          </IconButton>
          <Typography className="scan-popup-scan-text">
            {t("devices.scan_qr_code")}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",

            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "15px",
            marginTop: "20px",
          }}
        >
          <Scanner
            // styles={scannerStyles}
            components={components}
            scanDelay={1000}
            onScan={handleScanResult}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleSuccessPopupClose}
          sx={{
            "& .MuiDrawer-paper": {
              height: "100%", // Set drawer height to 50% of screen height
            },
          }}
        >
          {renderContent()}
        </Drawer>
      ) : (
        <Modal
          open={open}
          onClose={handleSuccessPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deletePopupStyle}>{renderContent()}</Box>
        </Modal>
      )}
    </Box>
  );
};

export default ScanningPopup;
