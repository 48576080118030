/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import Statistics from "./productsGraph/statistics";
import TrendingUp from "../../../assets/bar-chart.png";
import { useQuery } from "@apollo/client";
import { ProductReportType } from "../../../types/productReports";
import { TopProductType } from "../../../types/productReports";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { ProductStatisticsData } from "../../constants/reportStatistics";
import PieChart from "../pieChartReports";
import {
  PRODUCT_REPORTS,
  TOP_PRODUCT_REPORTS,
} from "../../../graphicalQl/usequery/productReport";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Product Name", value: "product_name" },
  { label: "Category", value: "category" },
  { label: "Quantity", value: "quantity" },
  { label: "Return/Refunded", value: "refund" },
  { label: "Amount", value: "amount" },
];

const ProductsReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Product Name", value: "product_name" },
    { label: "Category", value: "category" },
    { label: "Quantity", value: "quantity" },
    { label: "Return/Refunded", value: "refund" },
    { label: "Amount", value: "amount" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "product_name",
    "category",
    "quantity",
    "refund",
    "amount",
  ]);
  const [productReports, setProductReports] = useState<
    Array<ProductReportType>
  >([]);
  // const [locationId, setLocationId] = useState<number>(6);
  const [topProducts, setTopProducts] = useState<Array<TopProductType>>([]);
  const [graphLabels, setGraphLabels] = useState<Array<string>>([]);
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [employee, setEmployee] = useState<number>();
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { loading: topProductsLoading, data: topProductsData } = useQuery(
    TOP_PRODUCT_REPORTS(
      date.startDate, date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportLocation || +locationId,
      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const { loading: productReportsLoading, data: productReportsData } = useQuery(
    PRODUCT_REPORTS(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), reportLocation || +locationId, reportEmployee),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const capitalizeFirstLetter = (str: string) => {
    if (!str) {
      return '';
    }

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  const currencySymbol = sessionStorage.getItem("currencySymbol")
  const getProductReports = () => {
    if (!productReportsLoading) {
      const productReports = productReportsData?.productsReports;
      const newStoreReports = JSON.parse(productReports || "[]");
      const list = newStoreReports.map((each: any) => {
        return {
          ...each,
          product_name: capitalizeFirstLetter(each.product_name),
          category: capitalizeFirstLetter(each.category),
          amount: `${currencySymbol} ${each.amount.toFixed(2)}`,
        }
      })
      setProductReports(list);
    }
  };

  const getTopProducts = () => {
    if (!topProductsLoading) {
      const tempLabels: any = [];
      const tempData: any = [];
      const topProducts = topProductsData?.topProductsReports;
      const newtopStores = JSON.parse(topProducts || "[]");

      const list = newtopStores && newtopStores.map((item: any) => {
        return {
          ...item,
          sales: `${currencySymbol} ${item.sales.toFixed(2)}`
        }
      })
      newtopStores?.map((product: any) => {
        tempLabels.push(product?.product_name);
        tempData.push(product?.sales);
        return "";
      });
      setTopProducts(list);
      setGraphLabels(tempLabels);
      setGraphData(tempData);
    }
  };

  useEffect(() => {
    getProductReports();
  }, [productReportsLoading]);

  useEffect(() => {
    getTopProducts();
  }, [topProductsLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box
        mt={"18px"}
        p={3}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "9px",
            alignItems: "center",
          }}
        >
          <img
            height={44}
            width={44}
            src={TrendingUp}
            alt="trending"
            style={{ height: "44px", width: "44px" }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "20px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
              marginTop: "9px",
            }}
          >
            Products
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Statistics
            headers={ProductStatisticsData.headers}
            topProducts={topProducts}
          />
          <PieChart datapoints={graphData} labels={graphLabels} />
        </Box>
      </Box>
      <Box mt={0}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={productReports}
          title={"Products"}
        />
      </Box>
    </Box>
  );
};

export default ProductsReport;
