const RESET_STATE = 'RESET_STATE';
interface ResetStateAction {
    type: typeof RESET_STATE;
}
export const resetState = (): ResetStateAction => ({
    type: RESET_STATE,
});

export interface RecieptData {

    recieptName: string | null,
    recieptType: string | null,
    recieptInUse: string | null,

}



const RECIEPT_DETAILS = 'RECIEPT_DETAILS';



export interface MainRecieptData {
    type: typeof RECIEPT_DETAILS;
    payload: {
        field: string;
        name: string;
    };
}




const recieptData: RecieptData = {

    recieptName: null,
    recieptType: "billTemplate",
    recieptInUse: "INUSE",

};


export const setMainData = (field: string, name: string | any): MainRecieptData => ({
    type: RECIEPT_DETAILS,
    payload: {
        field,
        name
    },
});





export const recieptDataReducer = (state = recieptData, action: MainRecieptData | ResetStateAction): RecieptData => {
    switch (action.type) {
        case RECIEPT_DETAILS:
            return {
                ...state,
                [action.payload.field]: action.payload.name,
            };


        // case RESET_STATE:
        //     return {
        //         ...recieptData,
        //     };

        default:
            return state;
    }
};

