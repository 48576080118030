import { Box, Typography } from "@mui/material";
import SetUpGuideForwardArrow from "../../../assets/mianDashboard/setUpGuaid-arrow-forward.svg";
import React from "react";
import { useTranslation } from "react-i18next";

const SetupGuide = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        // paddingTop:'26px',
        paddingLeft: "30px",
        paddingRight: "20px",
        // paddingBottom:'38px',
        width: { xs: "auto", md: "48%", lg: "92.3%", xl: "auto" },
        height: { xs: "auto", lg: "140px" },
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: { xs: "14px", sm: "18px" },
          fontWeight: "500",
          lineHeight: "27px",
          textAlign: "left",
          color: "#000000",
          marginTop: "26px",
        }}
      >
        {t("dashboard.Setup_Guide")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          alignItems: { md: "start", lg: "center" },
          flexDirection: { md: "column", lg: "row" },
          justifyContent: "space-between",
          marginTop: "13px",
          gap: "12px",
          paddingBottom: "38px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
            width: { md: "300px" },
            whiteSpace: "nowrap,",
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "16px", sm: "24px" },
              fontWeight: "600",
              lineHeight: "36px",
              color: "#000000",
            }}
          >
            {t("dashboard.You_are")}
          </Typography>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "16px", sm: "24px" },
              fontWeight: "600",
              lineHeight: { xs: "24px", sm: "36px" },
              color: "#F38B08",
            }}
          >
            {" "}
            80% {t("dashboard.done")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "20px", md: "31px" },
          }}
        >
          <Box
            sx={{
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: { xs: "120px", sm: "220px" },
                height: "12px",
                borderRadius: "20px",
                backgroundColor: "rgba(249, 197, 132, 0.6)",
              }}
            ></Box>
            <Box
              sx={{
                position: "absolute",
                // width:'180px',
                width: { xs: "90px", sm: "180px" },
                height: "12px",
                borderRadius: "20px",
                backgroundColor: "#F38B08",
                bottom: "0px",
              }}
            ></Box>
          </Box>
          <img src={SetUpGuideForwardArrow} alt="arrowIcon" />
        </Box>
      </Box>
    </Box>
  );
};

export default SetupGuide;
