import {
  Box,
  Paper,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Button,
} from "@mui/material";
import React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import LocationAlertPopup from "./locationComformationPopup";

import { useQuery } from "@apollo/client";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface OrderTypeAndPayments {
  dineInCheckboxList: any[];
  collectionCheckboxList: any[];
  deliveryCheckboxList: any[];
  shopCheckboxList: any[];
  setDineInCheckboxList: React.Dispatch<React.SetStateAction<any[]>>;
  setCollectionCheckboxList: React.Dispatch<React.SetStateAction<any[]>>;
  setDeliveryCheckboxList: React.Dispatch<React.SetStateAction<any[]>>;
  setShopCheckboxList: React.Dispatch<React.SetStateAction<any[]>>;
  handleOrderPaymentsNextButton: () => void;
  dineInCheckbox: boolean;
  deliveryCheckbox: boolean;
  collectionCheckbox: boolean;
  shopCheckbox: boolean;
  setDineIncheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  setDeliverycheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  setCollectioncheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  setShopcheckbox: React.Dispatch<React.SetStateAction<boolean>>;
  editId: string | undefined;
  conditions: boolean[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  checkboxEmptyalertPopup: boolean;
  setCheckBoxEmptysetAlertPopup: React.Dispatch<React.SetStateAction<boolean>>;
  formikForContactImformation: any;
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
}

const LocationOrderTypeAndPayments = (props: OrderTypeAndPayments) => {
  const {
    setCheckBoxEmptysetAlertPopup,
    checkboxEmptyalertPopup,
    conditions,
    setConditions,
    formikForContactImformation,
    setActiveStep,
    editId,
    collectionCheckbox,
    dineInCheckbox,
    deliveryCheckbox,
    shopCheckbox,
    setDineIncheckbox,
    setCollectioncheckbox,
    setDeliverycheckbox,
    setShopcheckbox,
    handleOrderPaymentsNextButton,
    dineInCheckboxList,
    collectionCheckboxList,
    deliveryCheckboxList,
    shopCheckboxList,
    setDineInCheckboxList,
    setDeliveryCheckboxList,
    setCollectionCheckboxList,
    setShopCheckboxList,
  } = props;

  const AccordionStyle = {
    "&.MuiPaper-root.MuiAccordion-root.Mui-disabled": {
      backgroundColor: "rgba(242, 242, 242, 1) !important",
    },
  };

  const [alertPopup, setAlertPopup] = useState<boolean>(false);
  const { t } = useTranslation();
  const handleCheckEmptyPopupClose = () => {
    setCheckBoxEmptysetAlertPopup(false);
  };
  useEffect(() => {
    if (
      conditions[0] === true ||
      conditions[1] === true ||
      (conditions[2] === true &&
        (!formikForContactImformation.values.email ||
          !formikForContactImformation.values.phoneNumber))
    ) {
      setAlertPopup(true);
    } else {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[2] = false;
        return updatedConditions;
      });
    }
  }, []);

  const [dineInExpanded, setDineInExpanded] = useState<boolean | string>(false);
  const [collectionExpanded, setCollectionExpanded] = useState<
    boolean | string
  >(false);
  const [deliveryExpanded, setDeliveryExpanded] = useState<boolean | string>(
    false
  );
  const [shopExpanded, setShopExpanded] = useState<boolean | string>(false);

  const handleDineInCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (!e.target.checked) {
      setDineInExpanded(false);
      setDineIncheckbox(e.target.checked);
    } else {
      setDineIncheckbox(e.target.checked);
      setDineInExpanded(id);
    }
  };

  const handleCollectionCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (!e.target.checked) {
      setCollectionExpanded(false);
      setCollectioncheckbox(e.target.checked);
    } else {
      setCollectioncheckbox(e.target.checked);
      setCollectionExpanded(id);
    }
  };

  const handleDeliveryCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (!e.target.checked) {
      setDeliveryExpanded(false);
      setDeliverycheckbox(e.target.checked);
    } else {
      setDeliverycheckbox(e.target.checked);
      setDeliveryExpanded(id);
    }
  };
  const handleShopCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    if (!e.target.checked) {
      setShopExpanded(false);
      setShopcheckbox(e.target.checked);
    } else {
      setShopcheckbox(e.target.checked);
      setShopExpanded(id);
    }
  };

  const handleDineInAccordian = (id: string | false) => {
    setDineInExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleCollectionAccordian = (id: string | false) => {
    setCollectionExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleDeliveryAccordian = (id: string | false) => {
    setDeliveryExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleShopAccordian = (id: string | false) => {
    setShopExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleDineInCheckboxLabel = (
    event: React.SyntheticEvent<Element, Event>,
    id: string
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const newCheckboxList = dineInCheckboxList.map((eachCheckbox) => {
      if (eachCheckbox.id === id) {
        return { ...eachCheckbox, isChecked: checked };
      } else {
        return eachCheckbox;
      }
    });
    const collectedList = newCheckboxList.filter((each: any) => each.isChecked);
    if (collectedList.length === 0) {
      setDineIncheckbox(false);
      setDineInExpanded(false);
    }
    setDineInCheckboxList(newCheckboxList);
  };

  const handleCollectionCheckboxLabel = (
    event: React.SyntheticEvent<Element, Event>,
    id: string
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const newCheckboxList = collectionCheckboxList.map((eachCheckbox) => {
      if (eachCheckbox.id === id) {
        return { ...eachCheckbox, isChecked: checked };
      } else {
        return eachCheckbox;
      }
    });
    const collectedList = newCheckboxList.filter((each: any) => each.isChecked);
    if (collectedList.length === 0) {
      setCollectioncheckbox(false);
      setCollectionExpanded(false);
    }
    setCollectionCheckboxList(newCheckboxList);
  };

  const handleDeliveryCheckboxLabel = (
    event: React.SyntheticEvent<Element, Event>,
    id: string
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const newCheckboxList = deliveryCheckboxList.map((eachCheckbox) => {
      if (eachCheckbox.id === id) {
        return { ...eachCheckbox, isChecked: checked };
      } else {
        return eachCheckbox;
      }
    });
    const collectedList = newCheckboxList.filter((each: any) => each.isChecked);
    if (collectedList.length === 0) {
      setDeliverycheckbox(false);
      setDeliveryExpanded(false);
    }
    setDeliveryCheckboxList(newCheckboxList);
  };

  const handleShopCheckboxLabel = (
    event: React.SyntheticEvent<Element, Event>,
    id: string
  ) => {
    const { checked } = event.target as HTMLInputElement;
    const newCheckboxList = shopCheckboxList.map((eachCheckbox) => {
      if (eachCheckbox.id === id) {
        return { ...eachCheckbox, isChecked: checked };
      } else {
        return eachCheckbox;
      }
    });
    const collectedList = newCheckboxList.filter((each: any) => each.isChecked);
    if (collectedList.length === 0) {
      setShopcheckbox(false);
      setShopExpanded(false);
    }
    setShopCheckboxList(newCheckboxList);
  };

  const handleNextButton = () => {
    handleOrderPaymentsNextButton();
  };

  const index = conditions.findIndex((condition) => condition === true);
  let text = "";
  if (index === 0) {
    text = t("locations.basic_details");
  } else if (index === 1) {
    text = t("locations.business_address");
  } else if (index === 2) {
    text = t("locations.contact_information");
  }
  const handleAlertPopupClose = () => {
    setAlertPopup(false);
    setActiveStep(index);
  };

  const handleClearButton = () => {
    setDineIncheckbox(false);
    setShopcheckbox(false);
    setDeliverycheckbox(false);
    setCollectioncheckbox(false);
  };

  return (
    <Box>
      <LocationAlertPopup
        spantext={text}
        beforeSpanText={t("locations.please_enter")}
        afterSpanText={t("locations.to_continue_next")}
        handleClose={handleAlertPopupClose}
        open={alertPopup}
      />
      <LocationAlertPopup
        spantext={t("orders.payment_type")}
        beforeSpanText={t("locations.add_a")}
        afterSpanText={t("locations.to_the_orders")}
        handleClose={handleCheckEmptyPopupClose}
        open={checkboxEmptyalertPopup}
      />
      <Paper
        sx={{
          borderLeft: "2px solid #AE9BE2",
          width: "90%",
          padding: "10px",
          overflowY: "scroll",
          height: "67vh",
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
        elevation={2}
      >
        <Typography className="basic-details-heading">
          {t("locations.order_and_payment_type")}
        </Typography>

        <Box
          sx={{
            padding: "13px",
            backgroundColor: "#FAFAFA",
            marginTop: "15px",
          }}
        >
          <Box sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={dineInCheckbox}
                  onChange={(e) => handleDineInCheckbox(e, "dineIn")}
                  color="warning"
                />
              }
              label={
                <Typography
                  sx={{
                    whiteSpace: "nowrap", // Prevents text from wrapping onto the next line
                    overflow: "hidden", // Ensures any overflow text is hidden
                    textOverflow: "ellipsis", // Adds an ellipsis (...) if the text overflows
                  }}
                  className="delivery-zone-checkbox-label"
                >
                  {t("locations.dine_in")}
                </Typography>
              }
              className="delivery-zone-checkbox"
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 28 },
                width: "25%",
              }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Accordion
              sx={AccordionStyle}
              disabled={!dineInCheckbox}
              expanded={dineInExpanded === "dineIn"}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Button
                    className="orders-select-payment-type-accordian-text"
                    variant="text"
                    onClick={() => handleDineInAccordian("dineIn")}
                  >
                    {t("locations.select_payment_type")}
                  </Button>
                  <IconButton onClick={() => handleDineInAccordian("dineIn")}>
                    {dineInExpanded === "dineIn" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <Box sx={{ display: "flex" }}>
                    {dineInCheckboxList.map((eachCheckbox: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginRight: "5px",
                          width: "40%",
                          padding: "0px 5px 0px 15px",
                        }}
                      >
                        <FormControlLabel
                          onChange={(e) =>
                            handleDineInCheckboxLabel(e, eachCheckbox.id)
                          }
                          key={eachCheckbox.id}
                          control={
                            <Checkbox
                              checked={eachCheckbox.isChecked}
                              // onChange={(e)=>handleCheckbox(e,eachCheckbox.id)}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="delivery-zone-checkbox-label">
                              {eachCheckbox.label}
                            </Typography>
                          }
                          className="delivery-zone-checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            width: "100%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "13px",
            backgroundColor: "#FAFAFA",
            marginTop: "15px",
          }}
        >
          <Box sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={collectionCheckbox}
                  onChange={(e) => handleCollectionCheckbox(e, "collection")}
                  color="warning"
                />
              }
              label={
                <Typography className="delivery-zone-checkbox-label">
                  {t("locations.collection")}
                </Typography>
              }
              className="delivery-zone-checkbox"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, width: "25%" }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Accordion
              sx={AccordionStyle}
              disabled={!collectionCheckbox}
              expanded={collectionExpanded === "collection"}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Button
                    className="orders-select-payment-type-accordian-text"
                    variant="text"
                    onClick={() => handleCollectionAccordian("collection")}
                  >
                    {t("locations.select_payment_type")}
                  </Button>
                  <IconButton
                    onClick={() => handleCollectionAccordian("collection")}
                  >
                    {collectionExpanded === "collection" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    {collectionCheckboxList.map((eachCheckbox: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginRight: "5px",
                          width: "100%",
                          padding: "0px 5px 0px 15px",
                        }}
                      >
                        <FormControlLabel
                          onChange={(e) =>
                            handleCollectionCheckboxLabel(e, eachCheckbox.id)
                          }
                          key={eachCheckbox.id}
                          control={
                            <Checkbox
                              checked={eachCheckbox.isChecked}
                              // onChange={(e)=>handleCheckbox(e,eachCheckbox.id)}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="delivery-zone-checkbox-label">
                              {eachCheckbox.label}
                            </Typography>
                          }
                          className="delivery-zone-checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 27 },
                            width: "100%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "13px",
            backgroundColor: "#FAFAFA",
            marginTop: "15px",
          }}
        >
          <Box sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={deliveryCheckbox}
                  onChange={(e) => handleDeliveryCheckbox(e, "delivery")}
                  color="warning"
                />
              }
              label={
                <Typography className="delivery-zone-checkbox-label">
                  {t("locations.delivery")}
                </Typography>
              }
              className="delivery-zone-checkbox"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, width: "20%" }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Accordion
              sx={AccordionStyle}
              disabled={!deliveryCheckbox}
              expanded={deliveryExpanded === "delivery"}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Button
                    className="orders-select-payment-type-accordian-text"
                    variant="text"
                    onClick={() => handleDeliveryAccordian("delivery")}
                  >
                    {t("locations.select_payment_type")}
                  </Button>
                  <IconButton
                    onClick={() => handleDeliveryAccordian("delivery")}
                  >
                    {collectionExpanded === "delivery" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    {deliveryCheckboxList.map((eachCheckbox: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginRight: "5px",
                          width: "40%",
                          padding: "0px 5px 0px 15px",
                        }}
                      >
                        <FormControlLabel
                          onChange={(e) =>
                            handleDeliveryCheckboxLabel(e, eachCheckbox.id)
                          }
                          key={eachCheckbox.id}
                          control={
                            <Checkbox
                              checked={eachCheckbox.isChecked}
                              // onChange={(e)=>handleCheckbox(e,eachCheckbox.id)}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="delivery-zone-checkbox-label">
                              {eachCheckbox.label}
                            </Typography>
                          }
                          className="delivery-zone-checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            width: "100%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "13px",
            backgroundColor: "#FAFAFA",
            marginTop: "15px",
          }}
        >
          <Box sx={{ marginLeft: "10px" }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={shopCheckbox}
                  onChange={(e) => handleShopCheckbox(e, "shop")}
                  color="warning"
                />
              }
              label={
                <Typography className="delivery-zone-checkbox-label">
                  {t("locations.shop")}
                </Typography>
              }
              className="delivery-zone-checkbox"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 }, width: "20%" }}
            />
          </Box>
          <Box sx={{ marginTop: "10px" }}>
            <Accordion
              sx={AccordionStyle}
              disabled={!shopCheckbox}
              expanded={shopExpanded === "shop"}
            >
              <AccordionSummary>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                    padding: 0,
                  }}
                >
                  <Button
                    className="orders-select-payment-type-accordian-text"
                    variant="text"
                    onClick={() => handleShopAccordian("shop")}
                  >
                    {t("locations.select_payment_type")}
                  </Button>
                  <IconButton onClick={() => handleShopAccordian("shop")}>
                    {collectionExpanded === "shop" ? (
                      <ArrowDropUpIcon />
                    ) : (
                      <ArrowDropDownIcon />
                    )}
                  </IconButton>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                <FormGroup>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    {shopCheckboxList.map((eachCheckbox: any) => (
                      <Box
                        sx={{
                          display: "flex",
                          marginRight: "5px",
                          width: "40%",
                          padding: "0px 5px 0px 15px",
                        }}
                      >
                        <FormControlLabel
                          onChange={(e) =>
                            handleShopCheckboxLabel(e, eachCheckbox.id)
                          }
                          key={eachCheckbox.id}
                          control={
                            <Checkbox
                              checked={eachCheckbox.isChecked}
                              // onChange={(e)=>handleCheckbox(e,eachCheckbox.id)}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="delivery-zone-checkbox-label">
                              {eachCheckbox.label}
                            </Typography>
                          }
                          className="delivery-zone-checkbox"
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 28 },
                            width: "100%",
                          }}
                        />
                      </Box>
                    ))}
                  </Box>
                </FormGroup>
              </AccordionDetails>
            </Accordion>
            <Box>
              <Box sx={{ textAlign: "right", margin: "20px 5px 10px 5px" }}>
                {editId === undefined && (
                  <Button
                    onClick={handleClearButton}
                    sx={{ textTransform: "none" }}
                    className="create-a-use-role-clear-button"
                    variant="outlined"
                    color="warning"
                  >
                    {t("buttons.clear")}
                  </Button>
                )}
                <Button
                  onClick={handleNextButton}
                  sx={{ textTransform: "none", marginLeft: "10px" }}
                  variant="contained"
                  color="warning"
                >
                  {editId === undefined ? t("product.next") : t("buttons.save")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default LocationOrderTypeAndPayments;
