import { Box, Divider, Drawer, IconButton, Typography } from "@mui/material";
import { GridColDef, DataGrid } from "@mui/x-data-grid";
import moment from "moment";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { localeToFormat } from "../../utils/dateFormat";

interface State {
  rows: any[];
  popUpOpen: boolean;
  DriverName: string | null;
  handlePopupClose: () => void;
}

const DriverCommissionHistory = (props: State) => {
  const userLang = navigator.language;
  
  const {t}=useTranslation()
  const { DriverName, handlePopupClose, popUpOpen, rows } = props;

  const columns: GridColDef[] = [
    {
      field: "commission",
      //@ts-ignore
      headerName: t("deliveries.commission"),
      headerClassName: "driver-commission-table-header-name",
      flex: 1,
      renderCell: (params) => <Typography>{params.value}%</Typography>,
    },

    {
      field: "date",
      //@ts-ignore
      headerName: t("deliveries.date"),
      headerClassName: "driver-commission-table-header-name",
      type: "datetime",
      flex: 1,

      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "", alignItems: "center" }}>
          <Typography className="user-list-table-row">
            {params.value
              ? moment(params.value).format(`${localeToFormat[userLang]}, hh:mm`)
              : "N/A"}
          </Typography>
        </Box>
      ),
    },
  ];

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };
  return (
    <Box>
      <Drawer anchor={"right"} open={popUpOpen} onClose={handlePopupClose}>
        <Box width={550} sx={{ padding: "2%" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "82%",
            }}
          >
            <IconButton
              onClick={handlePopupClose}
              className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
            >
              <CloseIcon />
            </IconButton>
            <Typography
              sx={{ textTransform: "capitalize" }}
              className="driver-commission-history-header"
            >
              {DriverName} {t("deliveries.commission_histroy")}
            </Typography>
          </Box>
          <Divider sx={{ marginTop: "2%", marginBottom: "1%" }} />

          <Box
            sx={{
              padding: "10px",
              height: "85vh",
              width: "98%",
            }}
          >
            <DataGrid
              rows={rows}
              columns={columns}
              getRowClassName={getRow}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 25 },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
            />
          </Box>
        </Box>
      </Drawer>
    </Box>
  );
};

export default DriverCommissionHistory;
