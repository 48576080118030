import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
// import { styled } from '@mui/material/styles';
import Checkmark from "../../../assets/check.svg";
import LableImportant from "../../../assets/label-important.svg";
import Button from "@mui/material/Button";
import Link from "@mui/material/Link";
import InvoiceReceipt from "../../../assets/addOns/invoice-receipt-svgrepo-com 1.svg";
import GiftSvgrepo from "../../../assets/addOns/gift-svgrepo-com 1.svg";
import MarketingSvg from "../../../assets/addOns/marketing-spam-svgrepo-com 1.svg";
import GiftBox from "../../../assets/addOns/gift-svgrepo-com 1.svg";
import ComputerMonitor from "../../../assets/addOns/computer-monitor-svgrepo-com 1.svg";
import { List } from "@mui/material";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import styled from "styled-components";

function Pricing() {
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
      slidesToSlide: 2, // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2, // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1, // optional, default to 1.
    },
  };

  const PricingWrapper = styled("div")({
    ".react-multi-carousel-list": {
      position: "unset",
    },
    ".react-multi-carousel-track li": {
      maxWidth: "220px",
      width: "100%",
      "@media screen and (max-width: 600px)": {
        maxWidth: "194px",
      },
    },
    ".react-multi-carousel-track": {
      gap: "24px",
      "@media screen and (max-width: 600px)": {
        gap: "12px",
      },
    },
    ".react-multi-carousel-dot-list": {
      gap: "20px",
      bottom: "-32px",
      "@media screen and (max-width: 600px)": {
        gap: "6px",
      },
    },
    ".react-multi-carousel-dot button": {
      width: "24px",
      height: "4px",
      background: "#eeeeee",
      borderRadius: "8px",
      margin: "0px",
      border: "none",
      "@media screen and (max-width: 600px)": {
        width: "6px",
      },
    },
    ".react-multi-carousel-dot--active button": {
      background: "#F38B08",
      width: "44px",
      border: "none",
      borderRadius: "8px",
      "@media screen and (max-width: 600px)": {
        width: "8px",
      },
    },
    ".react-multiple-carousel__arrow::before": {
      "@media screen and (max-width: 600px)": {
        fontSize: "16px",
      },
    },
    ".react-multiple-carousel__arrow--left": {
      "@media screen and (max-width: 600px)": {
        left: "0",
      },
    },
    ".react-multiple-carousel__arrow--right": {
      "@media screen and (max-width: 600px)": {
        right: "0",
      },
    },
    ".react-multiple-carousel__arrow": {
      "@media screen and (max-width: 600px)": {
        minHeight: "28px",
        minWidth: "28px",
      },
    },
  });
  return (
    <>
      <PricingWrapper>
        <Box
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "space-between",
            padding: "12px",
            flexDirection: { xs: "column", md: "row" },
          }}
        >
          <Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "baseline",
                gap: "4px",
                flexDirection: { xs: "column", sm: "row" },
              }}
            >
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                }}
              >
                Next Payment
              </Typography>

              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "12px",
                  lineHeight: "18px",
                  color: "#707070",
                }}
              >
                (£25/month)
              </Typography>
            </Box>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#707070",
                marginTop: "8px",
              }}
            >
              on 25 Jan, 2024
            </Typography>
          </Box>
          <Box
            sx={{
              width: { xs: "100%", md: "342px" },
              marginTop: { xs: "40px", md: "0px" },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                }}
              >
                Days
              </Typography>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontWeight: "500",
                  lineHeight: "20px",
                  letterSpacing: "0em",
                  textAlign: "left",
                  paddingRight: { xs: "0px", md: "22.62px" },
                }}
              >
                26 of 30 days
              </Typography>
            </Box>
            <Box
              sx={{
                paddingRight: { xs: "0px", md: "22.62px" },
                marginTop: "8px",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  width: { xs: "100%", md: "319.69px" },
                  height: "10px",
                  backgroundColor: "#FEF1E1",
                  borderRadius: "10px",
                }}
              ></Box>
              <Box
                sx={{
                  width: { xs: "60%", md: "227.15px" },
                  height: "8px",
                  backgroundColor: "#F38B08",
                  borderRadius: "10px",
                  position: "absolute",
                  left: "2.18px",
                  top: "1px",
                }}
              ></Box>
            </Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                lineHeight: "20px",
                color: "#707070",
                whiteSpace: { xs: "wrap", md: "nowrap" },
                marginTop: "12px",
              }}
            >
              4 days remaining until your plan requires update
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            border: "1px solid #eeeeee",
            paddingTop: "22px",
            borderLeft: "none",
            borderRight: "none",
          }}
        >
          <Box sx={{ padding: "12px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "24px",
                alignItems: "start",
                flexDirection: { xs: "column", lg: "row" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "620px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "baseline",
                    flexDirection: { xs: "column", sm: "row" },
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: { xs: "16px", sm: "18px" },
                      lineHeight: "36px",
                      color: "#333333",
                    }}
                  >
                    Your Current Plan is Silver plan
                  </Typography>

                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { xs: "12px", sm: "18px" },
                      lineHeight: "27px",
                      color: "#707070",
                    }}
                  >
                    (£19/month)
                  </Typography>
                </Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#707070",
                    marginTop: "12px",
                    fontWeight: "500",
                  }}
                >
                  Your plan includes:
                </Typography>
                <Box mt={"8px"}>
                  <List
                    sx={{
                      listStyle: "none",
                      padding: "0",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: { sm: "noWrap", xs: "wrap" },
                        gap: { sm: "20px", xs: "0px" },
                        alignItems: "center",
                        height: { sm: "42px", xs: "auto" },
                      }}
                    >
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Epos (Digital and QR Menu)
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Restaurant Website/Mobile App
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: { sm: "noWrap", xs: "wrap" },
                        gap: { sm: "20px", xs: "0px" },
                        alignItems: "center",
                        height: { sm: "42px", xs: "auto" },
                        mt: "2px",
                      }}
                    >
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Epos (Digital and QR Menu)
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Restaurant Website/Mobile App
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: { sm: "noWrap", xs: "wrap" },
                        gap: { sm: "20px", xs: "0px" },
                        alignItems: "center",
                        height: { sm: "42px", xs: "auto" },
                        mt: "2px",
                      }}
                    >
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Epos (Digital and QR Menu)
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                      <Box width={{ sm: "50%", xs: "100%" }}>
                        <li>
                          <Box
                            sx={{
                              display: "flex",
                              gap: "8px",
                              marginTop: "16px",
                            }}
                          >
                            <img src={Checkmark} alt="Checkmark" />
                            <Typography
                              sx={{
                                fontFamily: "poppins",
                                fontSize: "14px",
                                lineHeight: "21px",
                                color: "#707070",
                              }}
                            >
                              Restaurant Website/Mobile App
                            </Typography>
                          </Box>
                        </li>
                      </Box>
                    </Box>
                  </List>
                </Box>
              </Box>
              <Box
                sx={{
                  gap: "8px",
                  maxWidth: "554px",
                  width: "100%",
                  border: "1px solid #F38B08",
                  borderRadius: "4px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: { md: "nowrap", xs: "wrap" },
                    alignItems: "center",
                    justifyContent: { md: "space-between", xs: "center" },
                    paddingX: "10px",
                    paddingY: "4px",
                    gap: { sm: "4px", xs: "10px" },
                  }}
                >
                  <Box textAlign={{ sm: "left", xs: "center" }}>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: { xs: "15px", sm: "16px" },
                        fontWeight: "500",
                        lineHeight: "24px",
                        color: "#333333",
                        whiteSpace: { md: "nowrap", xs: "wrap" },
                      }}
                    >
                      Save 30% per month with an Annual Plan
                    </Typography>
                    <Typography
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "21px",
                        color: "#707070",
                        marginTop: "6px",
                        textAlign: { xs: "center", md: "start" },
                        marginBottom: { xs: "10px", md: "0px" },
                      }}
                    >
                      save 30% yearly & £10.5 per month
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      width: "193px",
                      backgroundColor: "#F38B08",
                      height: "40px",
                      whiteSpace: "nowrap",
                      flexShrink: "none",
                      borderRadius: "4px",
                      fontFamily: "Poppins",
                      fontSize: { xs: "13px", sm: "16px" },
                      fontWeight: "500",
                      lineHeight: "24px",
                      padding: "0px",
                      textTransform: "none",
                      color: "#FEFEFE",
                      boxShadow: "none !important",
                      "&:hover": {
                        backgroundColor: "#F38B08", // Specify the hover color you want
                      },
                    }}
                    variant="contained"
                  >
                    Upgrade to Annual
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: { xs: "center", md: "end" },
                gap: "8px",
                marginTop: { md: "0px", xs: "16px" },
              }}
            >
              <Link
                component="button"
                variant="body2"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#F38B08",
                  textDecoration: "none",
                }}
                onClick={() => {}}
              >
                View All Plans
              </Link>
              <img src={LableImportant} alt="LableImportant" />
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "#FFFBF5",
            paddingTop: "20px",
            paddingBottom: "44px",
            paddingX: "12px",
            borderRadius: "8px",
            marginTop: "49px",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexDirection: { xs: "column", md: "row" },
              marginTop: "20px",
            }}
          >
            <Typography
              sx={{
                textAlign: { xs: "center", md: "start" },
                fontFamily: "Poppins",
                fontSize: { xs: "22px", sm: "24px" },
                lineHeight: "36px",
                color: "#333333",
                marginBottom: { xs: "22px", sm: "0px" },
              }}
            >
              Add On’s to Customise Your Selected Package
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#00AE26",
                paddingY: "6px",
                paddingX: "12px",
                backgroundColor: "#e5f3e0",
                maxWidth: "149px",
                borderRadius: "20px",
                textAlign: "center",
                marginBottom: { xs: "20px", sm: "0px" },
              }}
            >
              Recommended
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              color: "#707070",
              marginTop: "12px",
            }}
          >
            Crafted to draw in fresh customers and encourage return visits
            through these software solutions.
          </Typography>

          <Box
            sx={{
              marginTop: "24px",
              width: "100%",
              // OverflowX: "auto",
              position: "relative",
            }}
          >
            <Carousel
              // showDots customDot={<CustomDot />}
              // responsive={responsive}
              responsive={responsive}
              showDots={true}
              className="custom-carousel"
            
            >
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img
                    width={"50px"}
                    height={"50px"}
                    src={InvoiceReceipt}
                    alt="InvoiceReceiptImg"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Receipt Builder
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £12/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Get now!
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={GiftSvgrepo} alt="GiftSvgrepoImage" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Gift Cards
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £8/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={MarketingSvg} alt=" MarketingSvg" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Email marketing
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £22/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={GiftBox} alt="GiftBox" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Loyalty Points
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £12/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={ComputerMonitor} alt="ComputerMonitor" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    POS
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £3/terminal
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img
                    width={"50px"}
                    height={"50px"}
                    src={InvoiceReceipt}
                    alt="InvoiceReceiptImg"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Receipt Builder
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £12/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img
                    width={"50px"}
                    height={"50px"}
                    src={InvoiceReceipt}
                    alt="InvoiceReceiptImg"
                  />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Receipt Builder
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £12/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Get now!
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={GiftSvgrepo} alt="GiftSvgrepoImage" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Gift Cards
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £8/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
              <Box
                sx={{
                  width: "100%",
                  height: "194px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                  gap: "20px",
                  // paddingY: "12px",
                  borderColor: "#EEEEEE",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  backgroundColor: "white",
                }}
              >
                <Box>
                  <img src={MarketingSvg} alt=" MarketingSvg" />
                </Box>
                <Box>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    Email marketing
                  </Typography>
                  <Typography
                    sx={{
                      color: "#333333",
                      fontFamily: "poppins",
                      fontSize: "14px",
                      fontWeight: "500",
                      lineHeight: "21px",
                      textAlign: "center",
                    }}
                  >
                    £22/mo
                  </Typography>
                </Box>
                <Button
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "500",
                    lineHeight: "24px",
                    textAlign: "center",
                    color: "#F38B08",
                    padding: "0px",
                    textTransform: "none",
                  }}
                  variant="text"
                >
                  Add to Pack
                </Button>
              </Box>
            </Carousel>
            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "24px",
                gap: "20px",
              }}
            >
              <Box
                sx={{
                  width: "44px",
                  height: "4px",
                  backgroundColor: "#F38B08",
                  borderRadius: "8px",
                }}
              ></Box>
              <Box
                sx={{
                  width: "44px",
                  height: "4px",
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                }}
              ></Box>
              <Box
                sx={{
                  width: "44px",
                  height: "4px",
                  backgroundColor: "#EEEEEE",
                  borderRadius: "8px",
                }}
              ></Box>
            </Box> */}
          </Box>
        </Box>
      </PricingWrapper>
    </>
  );
}

export default Pricing;
