import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import styled from "styled-components";
import Pricing from "../pricing";
import Plans from "../plans";
import Details from "../details";
import History from "../history";

const ProfileWrapper = styled("div")({
  marginTop: "20px",
  marginBottom: "300px",
  border: "1px solid #eeeeee",
  borderRadius: "4px",
  width: "calc(100% - 40px)",
  marginLeft: "auto",
  marginRight:'auto',
  // paddingX: "0px",
  ".css-17zc7fe-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#F38B08",
    backgroundColor: "#ffffff",
  },
  ".css-1aquho2-MuiTabs-indicator": {
    backgroundColor: "#F38B08",
  },
  // ".css-17zc7fe-MuiButtonBase-root-MuiTab-root.Mui-selected": {
  //   color: "#F38B08",
  // },
});
const ProfilePageTab = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event:any, newValue:any) => {
    setValue(newValue);
  };

  return (
    <ProfileWrapper>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Box
            sx={{
              borderBottom: 1,
              borderColor: "divider",
              backgroundColor: "#F9F9F9",
            }}
          >
            <TabList
              sx={{
                overflowX: "auto",
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#707070",
                  paddingTop: "12px",
                  paddingX: "16px",
                  height: "42px",
                  border: "1px solid transparent",
                  textTransform: "none",
                }}
                label=" Pricing & Subscription"
                value="1"
              />
              <Tab
                sx={{
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#707070",
                  paddingTop: "12px",
                  paddingX: "16px",
                  height: "42px",
                  border: "1px solid transparent",
                  textTransform: "none",
                }}
                label="Plans"
                value="2"
              />
              <Tab
                sx={{
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#707070",
                  paddingTop: "12px",
                  paddingX: "16px",
                  height: "42px",
                  border: "1px solid transparent",
                  textTransform: "none",
                }}
                label="Billing Details"
                value="3"
              />
              <Tab
                sx={{
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", sm: "16px" },
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#707070",
                  paddingTop: "12px",
                  paddingX: "16px",
                  height: "42px",
                  border: "1px solid transparent",
                  textTransform: "none",
                }}
                label="Billing History"
                value="4"
              />
            </TabList>
          </Box>
          <Box
            sx={{
              // height:'94px',
              // paddingX:'24px',
              // paddingTop:'12px',
              // paddingBottom:'24px',
              marginTop: "10px",
              border: "1px solid #eeeeee",
              borderRadius: "4px",
              justifyContent: "space-between",
            }}
          >
            <TabPanel sx={{}} value="1">
              <Pricing />
            </TabPanel>
            <TabPanel sx={{}} value="2">
              <Plans />
            </TabPanel>
            <TabPanel sx={{}} value="3">
              <Details />
            </TabPanel>
            <TabPanel sx={{}} value="4">
              <History />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </ProfileWrapper>
  );
};

export default ProfilePageTab;
