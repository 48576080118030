import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Recaptcha from "../../../../assets/recaptcha-icon 1.png";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { SENT_MOBILE_OTP } from "../../../../graphicalQl/mutation/signUp";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useNavigate } from "react-router";
import { getCountryCodeFromTimeZone } from "../../../../utils/getCountryfromTimeline";
import Verification from "../verification";
import ReCAPTCHA from "react-google-recaptcha";

interface State {
  verificationUser: any;
  setDetails: any;
  details: any;

  verification: any;
}

export default function SignUpForm(props: State) {
  const [phone, setPhone] = React.useState("");
  const [phoneNumberLength, setPhoneNumberLength] = React.useState(10);
  const validationSchema = Yup.object({
    phone: Yup.string().required("Required"),

    phoneNumber: Yup.string()
      .required("Required")
      .test(
        "len",
        `Please enter ${phoneNumberLength} digits`,
        (val) => val?.length === phoneNumberLength
      ),

    // description: Yup.string().max(100, 'Maximum of 100 characters allowed'),
    name: Yup.string().required("Requried"),
    email: Yup.string().required("Requried"),
    recaptchaToken: Yup.string().required("Please check 'I'm not a robot'"),
    // robot: Yup.boolean()
    //   .oneOf([true], "Please confirm you are not a robot")
  });
  const { verificationUser, setDetails, details, verification } = props;
  const { t } = useTranslation();
  const { phoneCountryCode } = useSelector(
    (state: any) => state.headerLocation
  );

  const [moblieError, setMobileError] = React.useState(false);
  const [emailError, setEmailError] = React.useState(false);
  const [moblieError1, setMobileError1] = React.useState(false);
  const [emailError1, setEmailError1] = React.useState(false);
  const [setMobileOtp] = useMutation(SENT_MOBILE_OTP, {
    context: { clientName: "userClient" },
  });
  const recaptchaKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY;
  const businessId = sessionStorage.getItem("businessId") as string;
  const formik = useFormik({
    initialValues: {
      phone: "",
      email: "",
      name: "",
      robot: false,
      phoneNumber: "",
      recaptchaToken: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setMobileOtp({
        variables: {
          mobile: `+${values.phone}`,
          forRegistration: true,
          // businessId: parseInt(businessId),
        },
        onCompleted: () => {
          setMobileError(true);
        },
        onError: (error) => {
          const graphqlError = error.graphQLErrors[0];

          if (
            graphqlError &&
            graphqlError.extensions &&
            graphqlError.extensions.status
          ) {
            // Extract the status code from the extensions property
            // setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
            setMobileError1(true);
          }
          const errorMessage = graphqlError.message || "An error occurred";
          console.error(`GraphQL error: Status, Message: ${errorMessage}`);
        },
      });
      setMobileOtp({
        variables: {
          // businessId: parseInt(businessId),
          email: values.email,
          forRegistration: true,
        },
        onCompleted: () => {
          setEmailError(true);
        },
        onError: (error) => {
          const graphqlError = error.graphQLErrors[0];

          if (
            graphqlError &&
            graphqlError.extensions &&
            graphqlError.extensions.status
          ) {
            // Extract the status code from the extensions property
            // setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
            setEmailError1(true);
          }
          const errorMessage = graphqlError.message || "An error occurred";
          console.error(`GraphQL error: Status, Message: ${errorMessage}`);
        },
      });
    },
  });

  const navigate = useNavigate();

  React.useEffect(() => {
    if (moblieError && emailError) {
      setDetails({
        mobile: formik.values.phone,
        email: formik.values.email,
        name: formik.values.name,
        recaptchaToken: "",
      });
      verificationUser(true);
    }
  }, [moblieError, emailError]);

  // const handleSubmit = (event: any) => {
  //   event.preventDefault();
  //   const data = new FormData(event.currentTarget);
  // };

  // const locale = navigator.language; // This gets the browser's locale, e.g., "en-US"
  // console.log(locale)
  // const countryCode = getCountryCodeFromLocale(locale);
  // console.log(countryCode)

  const [countryCode, setCountryCode] = React.useState<string>("US"); // Default to US initially
  const [dailCode, setDailCode] = React.useState("");

  React.useEffect(() => {
    const code = getCountryCodeFromTimeZone();
    setCountryCode(code);
  }, []);

  const countDots = (str: string, value: string) => {
    // Split the string into characters and filter for dots
    const dots = str.split("").filter((char) => char === ".");
    setPhoneNumberLength(dots.length - value.length);
  };
  const onChange = (token: string) => {
    formik.setFieldValue("recaptchaToken", token);
  };

  return (
    <>
      {verification ? (
        <Verification
          details={details}
          verificationUser={verificationUser}
          setEmailError={setEmailError}
          setMobileError={setMobileError}
        />
      ) : (
        <Grid
          item
          width={"100%"}
          // height={"100vh"}
          maxWidth={{ md: "680px", sm: "470px", xs: "100%" }}
          component={Paper}
          elevation={6}
          square
          sx={{
            display: "flex",
            alignItems: { lg: "center", md: "center" },
            justifyContent: "center",
            boxShadow: "0",
            width: "100%",
            maxWidth: { sm: "420px", xs: "100%" },
            marginLeft: "auto",
            marginRight: "auto",
            paddingX: "20px",
            // marginTop: "36px",
          }}
        >
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyItems: "center",
                alignItems: { md: "start", xs: "center" },
              }}
            >
              <Typography
                component="h1"
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { sm: "24px", lg: "24px" },
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#333333",
                  fontWeight: "600",
                }}
              >
                Sign Up
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  color: "#707070",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  marginTop: { lg: "8px", xs: "20px" },
                }}
              >
                Let's create your account
              </Typography>
              <Box sx={{ mt: "2vh" }}>
                <TextField
                  sx={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#333333",
                    borderTopLeftRadius: "4px !important",
                    borderTopRightRadius: "4px !important",
                    borderBottomLeftRadius: "0px !important",
                    borderBottomRightRadius: "0px !important",
                    marginY: "0px",
                  }}
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Full Name"
                  onBlur={formik.handleBlur}
                  name="name"
                  type="text"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={Boolean(formik.touched.name && formik.errors.name)}
                />
                {formik.touched.name && formik.errors.name && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {formik.errors.name}
                    </Typography>
                  </Box>
                )}
                {/* <TextField
              sx={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#333333",
                marginTop: "24px",
                marginBottom: "0px",
              }}
              margin="normal"
              required
              fullWidth
              name="phone"
              label="Phone Number"
              // type="number"
              id="phone"
            /> */}
                {/* <TextField
              onBlur={formik.handleBlur}

              name="phone"
              fullWidth
              sx={{
                fontSize: "12px",
                lineHeight: "16px",
                color: "#333333",
                marginTop: "24px",
                marginBottom: "0px",
              }}
              label="Phone Number"
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 12)
                ) {

                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.phone}
              color="warning"
              error={Boolean(formik.touched.phone && formik.errors.phone)}
              InputProps={{
                inputProps: {
                  minLength: 10,
                  maxLength: 11,
                },
              }}




            /> */}

                {/* <ReactPhoneInput
              value={""}
              // defaultCountry={'gb'}
              onChange={formik.handleChange}
              // inputClass={classes.field}
              //  dropdownClass={classes.countryList}
              component={TextField}
            /> */}
                <Box sx={{ marginTop: "2vh", width: "100%" }}>
                  <PhoneInput
                    inputStyle={{
                      width: "100%",
                    }}
                    specialLabel="Phone Number"
                    country={countryCode.toLocaleLowerCase()}
                    // country={"us"}
                    enableSearch={true}
                    value={formik.values.phone}
                    disableDropdown
                    countryCodeEditable={false}
                    inputClass="phone-input-container"
                    onChange={(phone: any, metadata: any) => {
                      setDailCode(metadata.dialCode);

                      formik.setFieldTouched("phoneNumber", true);
                      countDots(metadata.format, metadata.dialCode);
                      formik.setFieldValue("phone", phone);
                      formik.setFieldValue(
                        "phoneNumber",
                        phone.substring(metadata.dialCode.length)
                      );
                    }}
                  />
                  {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {formik.errors.phoneNumber}
                      </Typography>
                    </Box>
                  )}
                  {moblieError1 && (
                    <Box sx={{ marginTop: "8px" }}>
                      <Typography className="create-a-user-formik-label">
                        Mobile Number Already Existed
                      </Typography>
                    </Box>
                  )}
                </Box>
                <TextField
                  sx={{
                    fontSize: "12px",
                    lineHeight: "16px",
                    color: "#333333",
                    marginTop: "12px",
                    marginBottom: "0px",
                  }}
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email Address"
                  type="email"
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  name="email"
                  error={Boolean(formik.touched.email && formik.errors.email)}
                />
                {formik.touched.email && formik.errors.email && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {formik.errors.email}
                    </Typography>
                  </Box>
                )}
                {emailError1 && (
                  <Box sx={{ marginTop: "8px" }}>
                    <Typography className="create-a-user-formik-label">
                      Email Already Existed
                    </Typography>
                  </Box>
                )}

                <Typography
                  mt={"2vh"}
                  mb={"8px"}
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "12px",
                    lineHeight: "16.8px",
                    color: "#707070",
                  }}
                >
                  By clicking
                  <Link
                    mx={"2px"}
                    fontWeight={600}
                    fontFamily={"poppins"}
                    color={"#F38B08"}
                    underline="none"
                  >
                    Continue
                  </Link>
                  below, you agree to our Terms and Conditions and Privacy
                  Policy.
                </Typography>
                {/* <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      backgroundColor: "#eeeeee",
                      marginRight: "0px",
                      marginLeft: "0px",
                      borderRadius: "4px",
                      height: "7vh",
                      padding: "2vh",
                    }}
                  >
                    <FormControlLabel
                      sx={{
                        gap: "8px",
                        margin: "0px",
                        cursor: "pointer",
                      }}
                      control={
                        <Checkbox
                          checked={formik.values.robot}
                          name="robot"
                          onChange={formik.handleChange}
                          sx={{
                            padding: "0px",
                            margin: "0px",
                            width: "18px",
                            height: "18px",
                            margan: "0px",
                            "&.Mui-checked": { color: "#F38B08 !important" },
                          }}
                        />
                      }
                      label={
                        <Typography
                          variant="body1"
                          sx={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            lineHeight: "16px",
                            letterSpecing: "0.4px",
                            color: "#333333",
                          }}
                        >
                          I'm not a Robot
                        </Typography>
                      }
                    />
                    <img src={Recaptcha} alt="Recaptcha" />
                  </Box>

                  {formik.touched.robot && formik.errors.robot && (
                    <Box>
                      <Typography className="create-a-user-formik-label" sx={{ marginToP: '8px' }}>
                        {formik.errors.robot}
                      </Typography>
                    </Box>
                  )} */}
                <Box sx={{ padding: "0px 0px 0px 40px" }}>
                  <ReCAPTCHA
                    sitekey={recaptchaKey as string}
                    // sitekey="6LcD-BIqAAAAABC_Z7fdnW75SMBnxzp7DynmcCgx"
                    onChange={(value: any) => {
                      formik.setFieldValue("recaptchaToken", value);
                    }}
                  />
                  {formik.touched.recaptchaToken &&
                    formik.errors.recaptchaToken && (
                      <Box>
                        <Typography
                          className="create-a-user-formik-label"
                          sx={{ marginToP: "8px" }}
                        >
                          {formik.errors.recaptchaToken}
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{
                    fontFamily: "poppins",
                    marginY: "12px",
                    fontSize: "18px",
                    lineHeight: "27px",
                    color: "#FEFEFE",
                    fontWeight: "500",
                    height: "48px",
                    backgroundColor: "#F38B08",
                    textTransform: "none",
                    borderRadius: "4px",
                    padding: "0px",
                    boxShadow: "none !important",
                    ":hover": {
                      backgroundColor: "#F38B08",
                    },
                  }}
                >
                  Continue to free Sign Up
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    gap: "5px",
                    color: "#333333",
                  }}
                >
                  Already have an account?
                  <Button
                    onClick={() => {
                      navigate("/sign-in");
                    }}
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      lineHeight: "24px",
                      letterSpacing: "0.5px",
                      color: "#F38B08",
                      fontWeight: "600",
                      textDecoration: "none",
                      textTransform: "none",
                    }}
                  >
                    {" "}
                    {"Sign In"}
                  </Button>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    marginTop: "8px",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "0px",
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "#707070",
                    }}
                  >
                    Need assistance or have questions?
                  </Typography>
                  <Link
                    href="#"
                    variant="body2"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "12px",
                      lineHeight: "24px",
                      color: "#F5431C",
                      fontWeight: "500",
                      textDecoration: "none",
                    }}
                  >
                    {"Contact Support"}
                  </Link>
                </Box>
              </Box>
            </Box>
          </form>
        </Grid>
      )}
    </>
  );
}
