import { Box, Button, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../menu/menuHistory.css";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { MENU_HISTORY } from "../../graphicalQl/usequery/menuQuery";
import { DISCOUNT_HISTORY } from "../../graphicalQl/usequery/discountQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { USE_THIS } from "../../graphicalQl/mutation/menuMutation";
import moment from "moment";
import { localeToFormat } from "../../utils/dateFormat";
import { useSelector } from "react-redux";

const DiscountHistory = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/discount/discount-list")
  }
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { data: discountHistory } = useQuery(DISCOUNT_HISTORY, {
    context: { clientName: "discountClient" },
    variables: { discountId: id && +id, locationId: locationId && +locationId },
    fetchPolicy: "network-only",
  });

  const [getValue] = useMutation(USE_THIS, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_HISTORY],
  });

  const handleUseThis = (id: number) => {
    getValue({ variables: { menuId: +id } })
      .then((result) => {
        // Handle the result if needed
      })
      .catch((error) => {
        // Handle the error if needed
        console.error("Mutation error:", error);
      });
  };
  const userLang = navigator.language;

  const columns: GridColDef[] = [
    {
      field: "id",
      //@ts-ignore
      headerName: "S.NO",
      flex: 1,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
    },

    {
      field: "orderId",
      //@ts-ignore
      headerName: "Order Id",
      flex: 1,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
    },

    // {
    //     field: 'date',
    //     //@ts-ignore
    //     headerName: t("menu.menuHistory.date"),
    //     cellClassName: "tableCellInCategory",
    //     headerClassName: "table-column-header ",
    //     flex: 1,
    //     disableColumnMenu: true,
    //     renderCell: (params) => {
    //         // Convert the string to a Date object
    //         const dateObject = new Date(params.row.date);

    //         // Check if the conversion was successful and dateObject is a valid Date
    //         if (!isNaN(dateObject.getTime())) {
    //             // Format the Date object to "YYYY-MM-DD"
    //             const formattedDate = `${dateObject.getFullYear()}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${String(dateObject.getDate()).padStart(2, '0')}`;
    //             return moment(params.row.date).format(`${localeToFormat[userLang]}`);;
    //         } else {
    //             // Handle the case where the conversion failed

    //             return 'Invalid Date';
    //         }
    //     },

    // },
    {
      field: "employee",
      //@ts-ignore
      headerName: "Customer",

      sortable: false,
      disableColumnMenu: true,

      flex: 1,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
    },
    {
      field: "amount",
      //@ts-ignore
      headerName: "Amount",
      sortable: false,
      flex: 1,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <div>
            {params.row.isLatest ? (
              <Typography
                sx={{
                  color: "#333",
                  textTransform: "none",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                }}
              >
                Currently using
              </Typography>
            ) : (
              <Button
                onClick={() => handleUseThis(params.row.id)}
                sx={{
                  color: "#333",
                  textTransform: "none",
                  fontSize: "0.875rem",
                  fontWeight: "400",
                }}
              >
                use this
              </Button>
            )}
          </div>
        );
      },
    },
    {
      field: "date",
      //@ts-ignore
      headerName: "Date",
      flex: 1,
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      disableColumnMenu: true,
      renderCell: (params) => {
        return params.value
          ? moment(params.row.updatedOn).format(`${localeToFormat[userLang]}`)
          : "N/A";
        // <Typography className="tableCellInCategory">{params.value ? moment(params.value).format("MM-DD-YYYY HH:mm") : "N/A"}</Typography>
      },
    },
  ];

  const [rows, setRows] = useState<
    {
      id: number;
      amount: number;
      date: string;
      employee: string;
      orderId: string;
      note: string;
    }[]
  >([]);
  // const rows = [
  //     { id: 1, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 2, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 3, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 4, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 5, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 6, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 7, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 8, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  //     { id: 9, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
  // ];
  const getCellClass = () => {
    return "tableCellInCategory";
  };

  const getRowClass = () => {
    return "menuHistory-row";
  };
  useEffect(() => {
    if (discountHistory && discountHistory.discountCustomerMappingList) {
      const list = discountHistory.discountCustomerMappingList.edges.map(
        (each: any, index: number) => ({
          id: index + 1,
          amount: each.node.amount,
          date: each.node.createdOn,
          employee: each.node.customerName,
          orderId: each.node.orderId,
          note: each.node.discount.name,
        })
      );
      setRows(list);
    }
  }, [discountHistory]);
  const handleRowClass = () => {
    return "menuListingRow";
  };

  const handleCellClass = () => {
    return "menuListigCell";
  };
  return (
    <Box sx={{ padding: "1%" }}>
      <Box className="menuHistory">
        <IconButton onClick={handleNavigate} className="exit-container">
          <ArrowBackIcon className="exitIcon" />
        </IconButton>
        <Typography className="menuHistory-text">
          Offer History {rows.length > 0 && (rows[0].note)}
        </Typography>
      </Box>
      <Box sx={{ height: 400, width: "100%" }}>
        <DataGrid
          rows={rows}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5]}
          // getCellClassName={getCellClass}
          // getRowClassName={getRowClass}
          disableRowSelectionOnClick
          sx={{
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
          }}
        />
      </Box>
    </Box>
  );
};
export default DiscountHistory;
