import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  Divider,
  TextField,
  RadioGroup,
  Radio,
  Box,
  IconButton,
  Switch,
} from "@mui/material";
import "./createServiceCharge.css";

import { FormControl, FormControlLabel, Checkbox } from "@mui/material";

import { useNavigate, useParams } from "react-router-dom";
import { InputLabel, Select, MenuItem } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "./createSalesTax.css";
import "./createServiceCharge.css";
import BasicModal from "./modal";
import { useQuery } from "@apollo/client";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";

import { useFormik } from "formik";
import * as Yup from "yup";
import {
  CREATE_SERVICE_CHARGE,
  UPDATE_SERVICE_CHARGE,
} from "../../graphicalQl/mutation/salesTaxMutation";
import { useMutation } from "@apollo/client";
import {
  GET_CHARGES_LIST,
  GET_SERVICE_CHARGES,
} from "../../graphicalQl/usequery/settingQuery";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import ErrorModal from "../errorHandling/errorModal";
import { useSelector } from "react-redux";

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}
const validationSchemaPart1 = Yup.object({
  chargeName: Yup.string().required("Required"),
  chargeRate: Yup.string().required("Required"),
});
const CreateServiceCharge = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const { locationId } = useSelector((state: any) => state.headerLocation);
  const navigate = useNavigate();
  const chargeValues = {
    chargeName: "",
    chargeRate: "",
    selectedLocation: locationId,
    noOfCustomers: "",
    totalCartAmount: "",
    extraCharge: "",
    radioValue: "price",
    extraChargeType: "flat",
    customSettings: false,
  };
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const formik = useFormik({
    initialValues: chargeValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (id) {
        updateCharge({
          variables: {
            chargeId: parseInt(id),

            chargeName: values.chargeName.trim(),
            chargePercentage: parseFloat(values.chargeRate),
            noOfCustomers:
              values.radioValue === "order"
                ? parseInt(values.noOfCustomers)
                : null,
            totalCartAmount:
              values.radioValue === "order"
                ? null
                : parseFloat(values.totalCartAmount),
            extraCharge: parseFloat(values.extraCharge),
            applicableTo: JSON.stringify(checkedOrderTypes),
            allowCustomSettings: values.customSettings,
            extraChargeType: values.extraChargeType,
          },
          onCompleted: () => {
            navigate("/service/charge");
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            // const errorMessage = graphqlError.message || 'An error occurred';
          },
        });
      } else {
        createServiceCharge({
          variables: {
            storeId: parseInt(values.selectedLocation),
            business: parseInt(businessId),
            chargeName: values.chargeName.trim(),
            chargePercentage: parseFloat(values.chargeRate),
            noOfCustomers:
              values.radioValue === "order"
                ? parseInt(values.noOfCustomers)
                : null,
            totalCartAmount:
              values.radioValue === "order"
                ? null
                : parseFloat(values.totalCartAmount),
            extraCharge: parseFloat(values.extraCharge),
            applicableTo: JSON.stringify(checkedOrderTypes),
            allowCustomSettings: values.customSettings,
            extraChargeType: values.extraChargeType,
          },
          onCompleted: () => {
            navigate("/service/charge");
            toast.success(
              <div>
                <Typography>{t("toast.created")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            // const errorMessage = graphqlError.message || 'An error occurred';
          },
        });
      }
    },
  });

  const [value, setValue] = useState(false);
  const handleNavigateExit = () => {
    // setValue(prevState => !prevState)
    navigate("/service/charge");
  };

  const [orderTypes, setOrderType] = useState([
    { id: 1, order: t("settings.all_orders"), isChecked: true, value: "All" },
    { id: 2, order: t("settings.dineIn"), isChecked: true, value: "dine_in" },
    { id: 3, order: t("settings.shop"), isChecked: true, value: "shop" },
    {
      id: 4,
      order: t("settings.collections"),
      isChecked: true,
      value: "collection",
    },
    {
      id: 5,
      order: t("settings.delivery"),
      isChecked: true,
      value: "delivery",
    },
  ]);

  // list of order orderTypes which id !==1  which are checked
  const checkedOrderTypes = orderTypes
    .filter((each) => each.id !== 1 && each.isChecked)
    .map((each) => each.value);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    const name1 = parseInt(name);
    let updatedCheckboxes = orderTypes.map((checkbox) => {
      if (checkbox.id === name1 || name1 === 1) {
        return { ...checkbox, isChecked: checked };
      }
      return checkbox;
    });

    // Check if all checkboxes except the "All" checkbox are checked
    const areAllChecked = updatedCheckboxes
      .slice(1)
      .every((checkbox) => checkbox.isChecked);

    // Update the "All" checkbox based on the checked status of other checkboxes
    updatedCheckboxes = updatedCheckboxes.map((checkbox, index) => {
      if (index === 0) {
        return { ...checkbox, isChecked: areAllChecked };
      }
      return checkbox;
    });

    setOrderType(updatedCheckboxes);
  };

  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });

  const [locationsList, setLocationsList] = useState<Locations[]>([]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );

      // Updating state with the required data
      setLocationsList(requiredDataList);
    }
  }, [locationsData]);
  const [createServiceCharge, { data: createServiceChargeData }] = useMutation(
    CREATE_SERVICE_CHARGE,
    {
      context: { clientName: "businessClient" },
      refetchQueries: [GET_CHARGES_LIST],
    }
  );
  const [updateCharge] = useMutation(UPDATE_SERVICE_CHARGE, {
    context: { clientName: "businessClient" },
    refetchQueries: [GET_CHARGES_LIST],
  });
  const { data } = useQuery(GET_SERVICE_CHARGES, {
    variables: { chargeId: id && +id },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data && data.charge) {
      formik.setFieldValue("chargeName", data.charge.chargeName);
      formik.setFieldValue("chargeRate", data.charge.chargePercentage);

      formik.setFieldValue(
        "selectedLocation",
        data.charge.store && data.charge.store.storeId
      );
      formik.setFieldValue("noOfCustomers", data.charge.noOfCustomers);
      formik.setFieldValue("totalCartAmount", data.charge.totalCartAmount);
      formik.setFieldValue("extraCharge", data.charge.extraCharge);
      formik.setFieldValue(
        "radioValue",
        data.charge.noOfCustomers ? "order" : "price"
      );
      formik.setFieldValue("extraChargeType", data.charge.extraChargeType);
      formik.setFieldValue("customSettings", data.charge.allowCustomSettings);
      //data.charge.applicableTo.length===4 then id 1 is checked
      const list2 = orderTypes.map((each: any) => {
        if (
          data.charge.applicableTo &&
          JSON.parse(data.charge.applicableTo.length) === 4
        ) {
          return { ...each, isChecked: true };
        }
        if (
          data.charge.applicableTo &&
          JSON.parse(data.charge.applicableTo).includes(each.value)
        ) {
          return { ...each, isChecked: true };
        }
        return { ...each, isChecked: false };
      });
      setOrderType(list2);
    }
  }, [data]);

  const handleClear = () => {
    formik.setValues(chargeValues);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} style={{ paddingBottom: "20px" }}>
          <div
            className="create_tax_head_container"
            style={{ padding: "12px 20px 0px 12px" }}
          >
            <IconButton
              className="create_exitIcon"
              onClick={handleNavigateExit}
            >
              <ArrowBackIcon sx={{ height: "24px", width: "24px" }} />
            </IconButton>
            {!id ? (
              <Typography className="create_tax_main">
                {t("settings.create_service_charge")}
              </Typography>
            ) : (
              <Typography className="create_tax_main">
                {t("settings.edit_service_charge")}
              </Typography>
            )}
            <div className="create_tax_button_con">
              <Button
                variant="contained"
                className="create_tax_button_style"
                onClick={handleClear}
              >
                {t("settings.clear")}
              </Button>
              <Button
                variant="contained"
                className="create_tax_button_style1"
                type="submit"
              >
                {t("settings.save")}
              </Button>
            </div>
          </div>
          <div style={{ marginLeft: "8%", marginTop: "44px", width: "73%" }}>
            <Paper
              elevation={1}
              style={{
                padding: "20px 12px 20px 12px",
                borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)",
              }}
              className="create_tax_page"
            >
              <Typography className="create_tax_basicDetails">
                {t("settings.basic_details")}
              </Typography>
              <Box>
                <TextField
                  label={
                    <Typography className="create_tax_label">
                      {t("settings.charge_name")}
                    </Typography>
                  }
                  variant="outlined"
                  name="chargeName"
                  value={formik.values.chargeName}
                  error={
                    formik.touched.chargeName &&
                    Boolean(formik.errors.chargeName)
                  }
                  helperText={
                    formik.touched.chargeName && formik.errors.chargeName
                  }
                  // onChange={(e) => formik.handleChange(e)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const val = e.target.value;
                    if (val.length <= 25) {
                      // handleInputChange(e);
                      formik.handleChange(e);
                    }
                  }}
                  className="create_tax_textField"
                  color="warning"
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #F9C584",
                      },
                    },
                  }}
                />
                <Box sx={{ textAlign: "right", width: "74%" }}>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: "#707070",
                      marginTop: "12px",
                    }}
                  >
                    {formik.values.chargeName
                      ? formik.values.chargeName.toString().length
                      : 0}
                    /25
                  </Typography>
                </Box>
              </Box>
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  marginTop: "20px",
                }}
              >
                <Box
                // sx={{ width: "35%" }}
                >
                  <TextField
                    label={
                      <Typography className="create_tax_label">
                        {t("settings.charge%")}
                      </Typography>
                    }
                    variant="outlined"
                    value={formik.values.chargeRate}
                    // type="number"
                    className="hide-number-input-icons create_tax_textField1"
                    name="chargeRate"
                    error={
                      formik.touched.chargeRate &&
                      Boolean(formik.errors.chargeRate)
                    }
                    helperText={
                      formik.touched.chargeRate && formik.errors.chargeRate
                    }
                    // onChange={(e) => formik.handleChange(e)}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val = e.target.value;
                      if ((!val || /^[0-9]+$/.test(val)) && val.length <= 3) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    color="warning"
                    sx={{
                      "&:hover": {
                        "&& fieldset": {
                          border: "2px solid #F9C584",
                        },
                      },
                    }}
                  />
                  <Box sx={{ textAlign: "right", mt: "8px", width: "100%" }}>
                    <Typography sx={{ fontSize: "12px", color: "#707070" }}>
                      {formik.values.chargeRate
                        ? formik.values.chargeRate.toString().length
                        : 0}
                      /3
                    </Typography>
                  </Box>
                </Box>
                {!id && (
                  <FormControl
                    className="create_tax_dropDown"
                    style={{ margin: "0px 0px 0px 24px", width: "35%" }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      {t("settings.select_location")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={formik.values.selectedLocation}
                      name="selectedLocation"
                      onChange={(e) => formik.handleChange(e)}
                      label={t("settings.select_location")}
                      color="warning"
                      sx={{
                        "&:hover": {
                          "&& fieldset": {
                            border: "2px solid #F9C584",
                          },
                        },
                      }}
                    >
                      {locationsList.map((each) => (
                        <MenuItem key={each.storeId} value={each.storeId}>
                          {each.storeName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                )}
                {value && <BasicModal />}
              </div>
            </Paper>

            <Paper
              elevation={1}
              style={{
                padding: "20px 12px 20px 12px",
                borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)",
                marginTop: "24px",
              }}
              className="create_tax_page"
            >
              <Typography className="create_tax_basicDetails">
                {t("settings.charge_applicable")}
              </Typography>
              {orderTypes.map((each: any) => (
                <Box sx={{ marginLeft: "12px" }} key={each.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="warning"
                        onChange={handleCheckboxChange}
                        name={each.id.toString()} // Ensure name is a string
                        checked={each.isChecked}
                      />
                    }
                    label={
                      <Typography className="createService_check_text">
                        {each.order}
                      </Typography>
                    }
                    className="createService_check_box"
                  />
                  {each.id === 1 && (
                    <Box>
                      <Typography className="createService_check_para">
                        {t("settings.orders_options")}
                      </Typography>
                      <Divider style={{ marginTop: "12px" }} />
                    </Box>
                  )}
                </Box>
              ))}

              {/* <div style={{ marginLeft: "12px" }}>
              <FormControlLabel
                control={<Checkbox color='warning' />}
                label={<Typography className='createService_check_text'>{t("settings.all_orders")}</Typography>}
                className='createService_check_box'
              />

              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.dineIn")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.shop")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.collections")}</Typography>}
                  className='createService_check_box'
                />
                <FormControlLabel
                  control={<Checkbox color='warning' />}
                  label={<Typography className='createService_check_text'>{t("settings.delivery")}</Typography>}
                  className='createService_check_box'
                />
              </div>
            </div> */}
            </Paper>
            <Paper
              elevation={1}
              style={{
                padding: "20px 12px 20px 10px",
                borderLeft: "2px solid var(--stroke-color-3-green, #BAE29B)",
                marginTop: "24px",
              }}
              className="create_tax_page"
            >
              <FormControlLabel
                value="top"
                control={
                  <Switch
                    color="warning"
                    name="customSettings"
                    onChange={formik.handleChange}
                    checked={formik.values.customSettings}
                  />
                }
                label={
                  <Typography className="create_tax_basicDetails">
                    {t("settings.custom_settings")}
                  </Typography>
                }
                labelPlacement="start"
              />
              <Divider style={{ marginTop: "16px", marginBottom: "8px" }} />
              {formik.values.customSettings && (
                <Box sx={{ ml: "12px" }}>
                  <Typography className="createService_para2">
                    {t("settings.custom_para")}
                  </Typography>

                  <FormControl component="fieldset">
                    <RadioGroup
                      aria-label="options"
                      value={formik.values.radioValue}
                      name="radioValue"
                      onChange={(e) => formik.handleChange(e)}
                    >
                      <FormControlLabel
                        value="price"
                        control={<Radio color="warning" />}
                        label={
                          <Typography className="createService_radio_text">
                            {t("settings.custom_radio1")}
                          </Typography>
                        }
                      />
                      <FormControlLabel
                        value="order"
                        control={<Radio color="warning" />}
                        label={
                          <Typography className="createService_radio_text">
                            {t("settings.custom_radio2")}
                          </Typography>
                        }
                      />
                    </RadioGroup>
                  </FormControl>
                  <div style={{ display: "flex", alignItems: "flex-start" }}>
                    <FormControl
                      className="create_tax_dropDown"
                      style={{ margin: "20px 0px 0px ", width: "30%" }}
                    >
                      <InputLabel
                        id="demo-simple-select-label"
                        className="create_service_label"
                      >
                        {t("settings.extra_charge")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={formik.values.extraChargeType}
                        label={t("settings.extra_charge")}
                        name="extraChargeType"
                        onChange={(e) => formik.handleChange(e)}
                        color="warning"
                        sx={{
                          "&:hover": {
                            "&& fieldset": {
                              border: "2px solid #F9C584",
                            },
                          },
                        }}
                      >
                        <MenuItem value="flat">{t("settings.flat")}</MenuItem>
                        <MenuItem value="percentage">%</MenuItem>
                      </Select>
                    </FormControl>
                    <Box>
                      <TextField
                        label={
                          <Typography className="create_service_label">
                            {t("settings.enter_value")}
                          </Typography>
                        }
                        variant="outlined"
                        value={formik.values.extraCharge}
                        // type="number"
                        className="hide-number-input-icons create_tax_textField2"
                        name="extraCharge"
                        // onChange={(e) => formik.handleChange(e)}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        style={{ marginLeft: "16px" }}
                        color="warning"
                        sx={{
                          "&:hover": {
                            "&& fieldset": {
                              border: "2px solid #F9C584",
                            },
                          },
                        }}
                      />
                      <Box sx={{ textAlign: "right", mt: "8px" }}>
                        <Typography sx={{ fontSize: "12px", color: "#707070" }}>
                          {formik.values.extraCharge
                            ? formik.values.extraCharge.toString().length
                            : 0}
                          /5
                        </Typography>
                      </Box>
                    </Box>
                    {formik.values.radioValue === "order" ? (
                      <Box>
                        <TextField
                          label={
                            <Typography className="create_service_label">
                              {t("settings.no_of_customers")}
                            </Typography>
                          }
                          variant="outlined"
                          value={formik.values.noOfCustomers}
                          name="noOfCustomers"
                          // onChange={(e) => formik.handleChange(e)}
                          color="warning"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const val = e.target.value;
                            if (
                              (!val || /^[0-9]+$/.test(val)) &&
                              val.length <= 5
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          type="text"
                          className="hide-number-input-icons create_tax_textField1"
                          sx={{
                            mt: "24px",
                            ml: "16px",
                            "&:hover": {
                              "&& fieldset": {
                                border: "2px solid #F9C584",
                              },
                            },
                          }}
                        />
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.noOfCustomers
                              ? formik.values.noOfCustomers.toString().length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </Box>
                    ) : (
                      <Box>
                        <TextField
                          label={
                            <Typography className="create_service_label">
                              {t("settings.enter_amount")}
                            </Typography>
                          }
                          variant="outlined"
                          value={formik.values.totalCartAmount}
                          name="totalCartAmount"
                          // type="number"
                          className="hide-number-input-icons create_tax_textField1"
                          onChange={(
                            e: React.ChangeEvent<HTMLInputElement>
                          ) => {
                            const val = e.target.value;
                            if (
                              (!val || /^[0-9]+$/.test(val)) &&
                              val.length <= 5
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          type="text"
                          color="warning"
                          sx={{
                            mt: "24px",
                            ml: "16px",
                            "&:hover": {
                              "&& fieldset": {
                                border: "2px solid #F9C584",
                              },
                            },
                          }}
                        />
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.totalCartAmount
                              ? formik.values.totalCartAmount.toString().length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </Box>
                    )}
                  </div>
                </Box>
              )}
            </Paper>
          </div>
        </Paper>
      </form>
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
    </div>
  );
};
export default CreateServiceCharge;
