import { gql } from "@apollo/client";

export const GET_IMAGE = gql`
  query ImageList($businessId: Int, $imageType: String, $customerId: Int) {
    imageList(
      businessId: $businessId
      imageType: $imageType
      customerId: $customerId
    )
  }
`;
