import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useSelector } from "react-redux";
import {
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { t } from "i18next";
import { CloseTwoTone } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setPricing } from "../../store";
import {
  DUPLICATE_BARCODE,
  DUPLICATE_SKU,
} from "../../graphicalQl/mutation/productMutation";
import { useMutation } from "@apollo/client";
import uuid from "react-uuid";
import DeleteDailouge from "../category/deleteDailouge";
import { useDebouncedCallback } from "use-debounce";
interface Row {
  id: number | string;
  name: string;
  price: string;
  sku: string;
  slice: string;
  barcode: string;
  is_preselected: boolean;
  groupName: string;
  skuDuplicate: boolean;
  barcodeDuplicate: boolean;
}

interface Props {
  setAddQuantityModal: any;
  addQuantityModal: boolean;
  setDeleteVariantIds: any;
}
const validationSchemaPart1 = Yup.object().shape({
  // price: Yup.string().when("variations1", {
  //     is: (variations1: any[]) => !variations1 || variations1.length === 0,
  //     then: (schema) => schema.required("*Required"),
  //     otherwise: (schema) => schema.notRequired(),
  // }),
  variations1: Yup.array().of(
    Yup.object().shape({
      name: Yup.string()
        .required("*Required")
        .test("is-unique", "Already Existed", function (value) {
          const str = this.path;
          const regex = /\[(\d+)\]/g;
          let match;
          const indices = [];

          while ((match = regex.exec(str)) !== null) {
            indices.push(parseInt(match[1]));
          }
          const data = this.options.context && { ...this.options.context };
          let variations1;
          if (data) {
            ({ variations1 } = data);
          }

          const allVariantNames =
            variations1 &&
            variations1.map((variant: any) =>
              variant.name ? variant.name.trim().toLowerCase() : ""
            );
          return (
            allVariantNames &&
            allVariantNames.filter(
              (name: string) => name === value.trim().toLowerCase()
            ).length <= 1
          );
        }),
      // price: Yup.string().required("Price is required"),
      sku: Yup.string().test("is-unique", "Already Existed", function (value) {
        const str = this.path;
        const regex = /\[(\d+)\]/g;
        let match;
        const indices = [];

        while ((match = regex.exec(str)) !== null) {
          indices.push(parseInt(match[1]));
        }
        const data = this.options.context && { ...this.options.context };
        let variations1;
        if (data) {
          ({ variations1 } = data);
        }

        const allVariantNames =
          variations1 &&
          variations1.map((variant: any) =>
            variant.sku ? variant.sku.trim().toLowerCase() : ""
          );
        return (
          allVariantNames &&
          allVariantNames.filter(
            (name: string) => name === value && value.trim().toLowerCase()
          ).length <= 1
        );
      }),
      barcode: Yup.string().test(
        "is-unique",
        "Already Existed",
        function (value) {
          const str = this.path;
          const regex = /\[(\d+)\]/g;
          let match;
          const indices = [];

          while ((match = regex.exec(str)) !== null) {
            indices.push(parseInt(match[1]));
          }
          const data = this.options.context && { ...this.options.context };
          let variations1;
          if (data) {
            ({ variations1 } = data);
          }

          const allVariantNames =
            variations1 &&
            variations1.map((variant: any) =>
              variant.barcode ? variant.barcode.trim().toLowerCase() : ""
            );
          return (
            allVariantNames &&
            allVariantNames.filter(
              (name: string) => name === value && value.trim().toLowerCase()
            ).length <= 1
          );
        }
      ),
    })
  ),
});
export default function AddQuantityModal(props: Props) {
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { setAddQuantityModal, addQuantityModal } = props;
  const { productIdByEdit } = useSelector((state: any) => state.app);
  const { variantGroup } = useSelector((state: any) => state.pricing);
  const { variations1, price, slice, sku, barcode } = useSelector(
    (state: any) => state.pricing
  );
  // const { productIdByEdit } = useSelector((state: any) => state.app);

  const { t } = useTranslation();
  const [rows, setRows] = React.useState<Row[]>([]);

  const initialState = {
    variations1: rows,
  };

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      const list = [...variations1]; // Make a copy of variations1 to start with

      rows.forEach((row) => {
        // Find index of element in list with the same id as current row
        const index = list.findIndex((item) => item.id === row.id);

        if (index !== -1) {
          // Replace the element in list with the corresponding row from rows
          list[index] = row;
        } else {
          // If no matching id found in variations1, add row to list
          list.push(row);
        }
      });
      const checkDuplicate = rows.some(
        (each: any) => each.skuDuplicate || each.barcodeDuplicate
      );
      if (!checkDuplicate) {
        dispatch(setPricing("variations1", list));
        setAddQuantityModal(false);
        formik.resetForm();
      }
    },
  });

  const [data, setData] = React.useState([
    //     {
    //     id: 1,
    //     name: "Quantity",
    //     text: "Quantity Variations like- Small, Medium, Large.. etc",
    //     image: QuantityImage
    // },
    {
      id: 2,
      name: "Size",
      text: t("product.size_para"),

      value: t("product.size1"),
      button: "Add Size",
    },

    {
      id: 3,
      name: "Base",
      text: t("product.base_para"),

      value: t("product.base"),
      button: "Add Base",
    },
    {
      id: 4,
      name: "Preparation type",
      text: t("product.preaparation_para"),

      value: t("product.preaparation_type"),
      button: "Add Type",
    },
    // {
    //     id: 5,
    //     name: "Make your own",
    //     text: "Unable to find your preferred variation? Innovate and personalize your own.",
    //     image: AddImage
    // },
  ]);
  const filterdList = data.filter((each) => each.name === variantGroup);
  console.log(filterdList);

  const [variantGroupName, setVariantGroupName] = React.useState(
    filterdList && filterdList[0] && filterdList[0].value
  );

  React.useEffect(() => {
    const list = [
      //     {
      //     id: 1,
      //     name: "Quantity",
      //     text: "Quantity Variations like- Small, Medium, Large.. etc",
      //     image: QuantityImage
      // },
      {
        id: 2,
        name: "Size",
        text: t("product.size_para"),

        value: t("product.size1"),
        button: "Add Size",
      },

      {
        id: 3,
        name: "Base",
        text: t("product.base_para"),

        value: t("product.base"),
        button: "Add Base",
      },
      {
        id: 4,
        name: "Preparation type",
        text: t("product.preaparation_para"),

        value: t("product.preaparation_type"),
        button: "Add Type",
      },
      // {
      //     id: 5,
      //     name: "Make your own",
      //     text: "Unable to find your preferred variation? Innovate and personalize your own.",
      //     image: AddImage
      // },
    ];
    setData(list);
  }, [t]);
  // const handleClose = () => setAddQuantityModal(false);

  // const [cancelButtonData, setCancelButtonData] = React.useState([])

  React.useEffect(() => {
    setVariantGroupName(variantGroup);
  }, [variantGroup]);

  React.useEffect(() => {
    const variantsOfPerticularGroup =
      variations1 &&
      variations1.filter((each: any) => each.groupName === variantGroup);
    // const variantsOfPerticularGroup2 = variations1 && variations1.filter((each: any) => each.groupName !== variantGroup)

    if (addQuantityModal) {
      setRows(variantsOfPerticularGroup);
      formik.setFieldValue("variations1", variantsOfPerticularGroup);
      // dispatch(setPricing("variations1", variantsOfPerticularGroup2))
      // setCancelButtonData(variantsOfPerticularGroup)
    }
  }, [addQuantityModal]);

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // setVariantGroupName(event.target.value)
  };
  const [duplictateSku, { loading: skuLoading }] = useMutation(DUPLICATE_SKU, {
    context: { clientName: "categoryClient" },
  });
  const [duplictateBarcode, { loading: barcodeLoading }] = useMutation(
    DUPLICATE_BARCODE,
    {
      context: { clientName: "categoryClient" },
    }
  );

  const debouncedSku = useDebouncedCallback(
    // function
    (value, id) => {
      if (value !== "") {
        duplictateSku({
          variables: {
            productSku: value,
            businessId: +businessId,
            productId: +productIdByEdit,
            variantId: typeof id === "number" ? id : null,
          },
          onCompleted: (data) => {
            const updatedRows2 = rows.map((row) =>
              row.id !== id
                ? row
                : {
                    ...row,
                    skuDuplicate:
                      data.duplicateProductSkuCheck.duplicate ||
                      [
                        ...variations1.map((each: any) => each.sku),
                        sku,
                      ].includes(value),
                  }
            );
            setRows(updatedRows2);
          },
        });
      } else {
        const updatedRows2 = rows.map((row) =>
          row.id !== id ? row : { ...row, skuDuplicate: false }
        );
        setRows(updatedRows2);
      }
    },
    // delay in ms
    1000
  );

  const debouncedBarcode = useDebouncedCallback(
    // function
    (value, id) => {
      if (value !== "") {
        duplictateBarcode({
          variables: {
            productBarcode: value,
            businessId: +businessId,
            productId: +productIdByEdit,
            variantId: typeof id === "number" ? id : null,
          },
          onCompleted: (data) => {
            const updatedRows2 = rows.map((row) =>
              row.id !== id
                ? row
                : {
                    ...row,
                    barcodeDuplicate:
                      data.duplicateProductBarcodeCheck.duplicate ||
                      [
                        ...variations1.map((each: any) => each.barcode),
                        barcode,
                      ].includes(value),
                  }
            );
            setRows(updatedRows2);
          },
        });
      } else {
        const updatedRows2 = rows.map((row) =>
          row.id !== id ? row : { ...row, barcodeDuplicate: false }
        );
        setRows(updatedRows2);
      }
    },
    // delay in ms
    1000
  );
  const handleRowInputChange = (
    id: number | string,
    field: keyof Row,
    value: string
  ) => {
    if (field === "sku") {
      const row = rows.map((row) =>
        row.id !== id ? row : { ...row, [field]: value }
      );
      setRows(row);

      // if (value !== "") {
      //     duplictateSku({
      //         variables: { productSku: value, productId: +productIdByEdit, variantId: typeof (id) === "number" ? id : null }, onCompleted: (data) => {
      //             const updatedRows2 = row.map((row) =>
      //                 row.id !== id ? row : { ...row, "skuDuplicate": data.duplicateProductSkuCheck.duplicate || [...variations1.map((each: any) => each.sku), sku].includes(value), }
      //             );

      //         }
      //     })
      // }
      debouncedSku(value, id);
    } else if (field === "barcode") {
      const row = rows.map((row) =>
        row.id !== id ? row : { ...row, [field]: value }
      );
      setRows(row);
      debouncedBarcode(value, id);
    } else {
      const updatedRows = rows.map((row) =>
        row.id !== id ? row : { ...row, [field]: value }
      );
      setRows(updatedRows);
    }
  };

  // const handleRowInputChange = async (id: number | string, field: keyof Row, value: string) => {
  //     const variantId = typeof id === "number" ? id : null;

  //     const checkDuplicateSku = async (sku: string) => {
  //         try {
  //             const { data } = await duplictateSku({
  //                 variables: { productSku: sku, productId: +productIdByEdit, variantId }
  //             });
  //             return data.duplicateProductSkuCheck.duplicate;
  //         } catch (error) {
  //             console.error('Error checking duplicate SKU:', error);
  //             return false;
  //         }
  //     };

  //     const checkDuplicateBarcode = async (barcode: string) => {
  //         try {
  //             const { data } = await duplictateBarcode({
  //                 variables: { productBarcode: barcode, productId: +productIdByEdit, variantId }
  //             });
  //             return data.duplicateProductBarcodeCheck.duplicate;
  //         } catch (error) {
  //             console.error('Error checking duplicate barcode:', error);
  //             return false;
  //         }
  //     };

  //     const isDuplicateInLocalList = (value: string, field: keyof Row) => {
  //         return [...variations1.map((each: any) => each[field]), field === "sku" ? sku : barcode].includes(value);
  //     };

  //     if (field === "sku" && value !== "") {
  //         const isDuplicateSku = await checkDuplicateSku(value);
  //         setRows((prevRows) =>
  //             prevRows.map((row) =>
  //                 row.id !== id
  //                     ? row
  //                     : {
  //                         ...row,
  //                         skuDuplicate: isDuplicateSku || isDuplicateInLocalList(value, "sku"),
  //                         [field]: value,
  //                     }
  //             )
  //         );
  //     } else if (field === "barcode" && value !== "") {
  //         const isDuplicateBarcode = await checkDuplicateBarcode(value);
  //         setRows((prevRows) =>
  //             prevRows.map((row) =>
  //                 row.id !== id
  //                     ? row
  //                     : {
  //                         ...row,
  //                         barcodeDuplicate: isDuplicateBarcode || isDuplicateInLocalList(value, "barcode"),
  //                         [field]: value,
  //                     }
  //             )
  //         );
  //     } else {
  //         setRows((prevRows) =>
  //             prevRows.map((row) =>
  //                 row.id !== id ? row : { ...row, [field]: value }
  //             )
  //         );
  //     }
  // };

  const handleCheckboxChange = (id: number | string, value: boolean) => {
    const updatedRows = rows.map((row) =>
      row.id === id
        ? { ...row, is_preselected: value }
        : { ...row, is_preselected: !value }
    );
    setRows(updatedRows);
  };

  const toggleDrawer = (newOpen: boolean) => () => {
    setAddQuantityModal(newOpen);

    // dispatch(setPricing("variations1", variations1.concat(cancelButtonData)))
  };

  const handleAddSizeClick = () => {
    const newRow: Row = {
      id: uuid(),
      name: "",
      price: "",
      sku: "",
      slice: "",
      barcode: "",
      is_preselected: false,
      groupName: variantGroup,
      skuDuplicate: false,
      barcodeDuplicate: false,
    };

    setRows([
      ...rows,
      {
        ...newRow,
        is_preselected: rows.length === 0 ? true : false,
      },
    ]);

    formik.setValues({ ...formik.values, variations1: [...rows, newRow] });
  };

  const dispatch = useDispatch();
  // React.useEffect(() => {
  //     if (rows.length > 0) {
  //         const requiredRowsData = rows.map((each) => ({
  //             name: each.name,
  //             price: +each.price,
  //             sku: each.sku,

  //             barcode: each.barcode,
  //             is_preselected: each.is_preselected,
  //             slices: ""
  //         }))

  //         //   setVariations(requiredRowsData)

  //     }
  //     dispatch(setPricing("variations1", rows))
  // }, [rows])

  const [varintId, setVarintId] = React.useState<string>("");
  const handleUpdateVariantId = (id: any) => {
    setVarintId(id);
  };
  const [deletemodal, setDeleteModal] = React.useState(false);
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };
  const handleDeleteRow = () => {
    const updatedRows = rows.filter((row: any) => row.id !== varintId);
    setRows(updatedRows);
    formik.setValues({ ...formik.values, variations1: updatedRows });
    const updatedRows2 = variations1.filter((row: any) => row.id !== varintId);
    dispatch(setPricing("variations1", updatedRows2));
    // if (typeof id === "string" || (productId && productId.length > 0 && typeof id === "number")) {
    //     setDeleteVariantIds((prev: any) => [...prev, +id])
    // }
  };
  const handleDeleteClick = () => {};
  const drawerList = (
    <Box sx={{ width: "50vw", padding: "12px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "var(--base-base-300, #F9F9F9)",
          padding: "12px",
        }}
      >
        <IconButton onClick={toggleDrawer(false)} className="exit-container">
          <CloseIcon className="exitIcon" />
        </IconButton>
        <Typography className="main-head3">
          {t("product.add_quantity")}
        </Typography>
        <Button
          className="main-button"
          type="submit"
          disabled={skuLoading || barcodeLoading}
        >
          {t("product.next")}
        </Button>
      </Box>
      <Typography
        className="normalHeader"
        sx={{ padding: "24px 12px 24px 12px" }}
      >
        {/* {filterdList && filterdList[0] && filterdList[0].text} */}
        {variantGroupName}
      </Typography>
      <TextField
        label="Variant group name"
        value={variantGroupName}
        onChange={handleNameChange}
        disabled
      />
      <Box
        sx={
          {
            // maxHeight: '60vh',
            // overflowY: 'auto', padding: "12px",
            // WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
            //     display: 'none',
            // }
          }
        }
      >
        {rows &&
          rows.map((row, index) => (
            <Box
              key={row.id}
              sx={{
                display: "flex",
                width: "100%",
                gap: "12px",
                marginTop: "12px",
                border: "1px solid #EEE",
                borderRadius: "8px",
                alignItems: "center",
                padding: "12px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  gap: "12px",
                }}
              >
                <Box style={{ width: "5%" }}>
                  <Radio
                    checked={row.is_preselected}
                    size="small"
                    color="warning"
                    onChange={(event) =>
                      handleCheckboxChange(row.id, event.target.checked)
                    }
                    name="radio-buttons"
                    // inputProps={{ 'aria-label': 'A' }}
                  />
                </Box>
                <Box style={{ width: "25%" }}>
                  <TextField
                    label={t("product.variant")}
                    color="warning"
                    value={row.name}
                    name={`variations1[${index}].name`}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      handleRowInputChange(row.id, "name", event.target.value);
                      formik.handleChange(event);
                    }}
                    error={
                      formik.touched.variations1 &&
                      formik.touched.variations1[index] &&
                      formik.touched.variations1[index]?.name &&
                      Boolean(
                        formik.errors.variations1 &&
                          formik.errors.variations1[index] &&
                          (formik.errors.variations1 as FormikErrors<Row[]>)[
                            index
                          ]?.name
                      )
                    }
                    helperText={
                      formik.touched.variations1 &&
                      formik.touched.variations1[index] &&
                      formik.touched.variations1[index]?.name &&
                      formik.errors.variations1 &&
                      formik.errors.variations1[index] &&
                      (formik.errors.variations1 as FormikErrors<Row[]>)[index]
                        ?.name
                    }
                  />

                  {/* {formik.touched.variations1 &&
                                        formik.touched.variations1[index] &&
                                        formik.touched.variations1[index]?.name &&
                                        formik.errors.variations1 &&
                                        formik.errors.variations1[index] &&
                                        (formik.errors.variations1 as FormikErrors<Row[]>)[index]?.name && (
                                            <Typography style={{ color: "red", fontSize: "12px" }}>{(formik.errors.variations1 as FormikErrors<Row[]>)[index]?.name}</Typography>
                                        )} */}
                </Box>
                <Box style={{ width: "15%" }}>
                  <Typography className="product-basePrice">
                    {t("product.base_price1")}
                  </Typography>
                  <Typography className="basePrice">{price}</Typography>
                </Box>
                <Typography>+</Typography>
                <Box style={{ width: "25%" }}>
                  <TextField
                    label="aditional Price"
                    color="warning"
                    value={row.price}
                    name={`variations1[${index}].price`}
                    // onChange={(event) => {
                    //     handleRowInputChange(row.id, 'price', event.target.value);
                    //     //  formik.handleChange(event)
                    // }}

                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val = e.target.value;

                      if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                        handleRowInputChange(row.id, "price", e.target.value);
                        // formik.handleChange(e);
                      }
                    }}
                  />
                  {
                    // formik.touched.variations1 &&
                    //   formik.touched.variations1[index] &&
                    //   formik.touched.variations1[index]?.price &&
                    formik.errors.variations1 &&
                      formik.errors.variations1[index] &&
                      (formik.errors.variations1 as FormikErrors<Row[]>)[index]
                        ?.price && (
                        <div style={{ color: "red" }}>
                          {
                            (formik.errors.variations1 as FormikErrors<Row[]>)[
                              index
                            ]?.price
                          }
                        </div>
                      )
                  }
                </Box>
                {/* <Typography>=</Typography> */}
                <Box style={{ width: "15%" }}>
                  <Typography className="product-basePrice">
                    {t("product.final_price")}
                  </Typography>
                  <Typography className="basePrice">
                    {+price + +row.price}
                  </Typography>
                </Box>
                {slice && variantGroup === "Size" && (
                  <Box sx={{ width: "15%" }}>
                    {/* <TextField label="Slice" color="warning" value={row.slice}
            name={`variations1[${index}].slice`}
            onChange={(event) => { handleRowInputChange(row.id, 'slice', event.target.value); formik.handleChange(event) }} /> */}
                    <FormControl sx={{ width: "100%" }}>
                      <InputLabel id="demo-simple-select-label">
                        {t("product.slice")}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder="slice"
                        name={`variations1[${index}].slice`}
                        label={t("product.slice")}
                        color="warning"
                        value={row.slice}
                        onChange={(event) => {
                          handleRowInputChange(
                            row.id,
                            "slice",
                            event.target.value
                          );
                          formik.handleChange(event);
                        }}
                      >
                        <MenuItem value="1">1</MenuItem>
                        <MenuItem value="2">2</MenuItem>
                        <MenuItem value="3">3</MenuItem>
                        <MenuItem value="4">4</MenuItem>
                        <MenuItem value="5">5</MenuItem>
                        <MenuItem value="6">6</MenuItem>
                        <MenuItem value="7">7</MenuItem>
                        <MenuItem value="8">8</MenuItem>
                        <MenuItem value="9">9</MenuItem>
                        <MenuItem value="10">10</MenuItem>
                        <MenuItem value="11">11</MenuItem>
                        <MenuItem value="12">12</MenuItem>
                      </Select>
                    </FormControl>
                    {formik.touched.variations1 &&
                      formik.touched.variations1[index] &&
                      formik.touched.variations1[index]?.slice &&
                      formik.errors.variations1 &&
                      formik.errors.variations1[index] &&
                      (formik.errors.variations1 as FormikErrors<Row[]>)[index]
                        ?.slice && (
                        <div style={{ color: "red" }}>
                          {
                            (formik.errors.variations1 as FormikErrors<Row[]>)[
                              index
                            ]?.slice
                          }
                        </div>
                      )}
                  </Box>
                )}
                <Box sx={{ width: "25%" }}>
                  <TextField
                    label={t("product.sku")}
                    color="warning"
                    value={row.sku}
                    name={`variations1[${index}].sku`}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      handleRowInputChange(row.id, "sku", event.target.value);
                      formik.handleChange(event);
                    }}
                    error={
                      (formik.touched.variations1 &&
                        formik.touched.variations1[index] &&
                        formik.touched.variations1[index]?.sku &&
                        Boolean(
                          formik.errors.variations1 &&
                            formik.errors.variations1[index] &&
                            (formik.errors.variations1 as FormikErrors<Row[]>)[
                              index
                            ]?.sku
                        )) ||
                      row.skuDuplicate
                    }
                    helperText={
                      (formik.touched.variations1 &&
                        formik.touched.variations1[index] &&
                        formik.touched.variations1[index]?.sku &&
                        formik.errors.variations1 &&
                        formik.errors.variations1[index] &&
                        (formik.errors.variations1 as FormikErrors<Row[]>)[
                          index
                        ]?.sku) ||
                      (row.skuDuplicate && "Already Existed")
                    }
                  />

                  {/* {formik.touched.variations1 &&
                                        formik.touched.variations1[index] &&
                                        formik.touched.variations1[index]?.sku &&
                                        formik.errors.variations1 &&
                                        formik.errors.variations1[index] && !row.skuDuplicate &&
                                        (formik.errors.variations1 as FormikErrors<Row[]>)[index]?.sku && (
                                            <Typography style={{ color: "red", fontSize: "12px" }}>{(formik.errors.variations1 as FormikErrors<Row[]>)[index]?.sku}</Typography>
                                        )} */}
                  {/* {row.skuDuplicate && <Typography style={{ color: "red", fontSize: "12px" }}>Already Existed</Typography>} */}
                </Box>
                <Box sx={{ width: "25%" }}>
                  <TextField
                    label={t("product.bar_code")}
                    color="warning"
                    value={row.barcode}
                    name={`variations1[${index}].barcode`}
                    onBlur={formik.handleBlur}
                    onChange={(event) => {
                      handleRowInputChange(
                        row.id,
                        "barcode",
                        event.target.value
                      );
                      formik.handleChange(event);
                    }}
                    error={
                      (formik.touched.variations1 &&
                        formik.touched.variations1[index] &&
                        formik.touched.variations1[index]?.barcode &&
                        Boolean(
                          formik.errors.variations1 &&
                            formik.errors.variations1[index] &&
                            (formik.errors.variations1 as FormikErrors<Row[]>)[
                              index
                            ]?.barcode
                        )) ||
                      row.barcodeDuplicate
                    }
                    helperText={
                      (formik.touched.variations1 &&
                        formik.touched.variations1[index] &&
                        formik.touched.variations1[index]?.barcode &&
                        formik.errors.variations1 &&
                        formik.errors.variations1[index] &&
                        (formik.errors.variations1 as FormikErrors<Row[]>)[
                          index
                        ]?.barcode) ||
                      (row.barcodeDuplicate && "Already Existed")
                    }
                  />

                  {/* {formik.touched.variations1 &&
                                        formik.touched.variations1[index] &&
                                        formik.touched.variations1[index]?.barcode &&
                                        formik.errors.variations1 &&
                                        formik.errors.variations1[index] && !row.barcodeDuplicate &&
                                        (formik.errors.variations1 as FormikErrors<Row[]>)[index]?.barcode && (
                                            <Typography style={{ color: "red", fontSize: "12px" }}>{(formik.errors.variations1 as FormikErrors<Row[]>)[index]?.barcode}</Typography>)}
                                    {row.barcodeDuplicate && <Typography style={{ color: "red", fontSize: "12px" }}>{t("product.already_existed")}</Typography>} */}
                </Box>
                {/* <Box sx={{ width: "200px" }}> <FormControlLabel
                                    control={<Checkbox
                                        color="warning"

                                        onChange={(event) => handleCheckboxChange(row.id, event.target.checked)}
                                        checked={row.is_preselected}

                                    />}
                                    label={<Typography className='create-product-check' style={{ width: "100px" }}
                                    >{t("product.pre_select")}</Typography>}
                                /></Box> */}
                {row.is_preselected && (
                  <Box sx={{ textAlign: "right", width: "25%" }}>
                    <Typography className="pre-select">
                      {t("product.pre_selected")}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box style={{ padding: "0px", width: "10%" }}>
                <IconButton
                  onClick={() => {
                    handleUpdateVariantId(row.id);
                    handleDeleteModal();
                  }}
                >
                  <CloseTwoTone />
                </IconButton>
              </Box>
            </Box>
          ))}
        <Button
          style={{ textTransform: "none", marginTop: "12px" }}
          className="create-product-add-size"
          onClick={handleAddSizeClick}
        >
          {filterdList && filterdList[0] && filterdList[0].button}
        </Button>
      </Box>
      <DeleteDailouge
        id={varintId}
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDeleteRow}
        handleDeleteClick={handleDeleteClick}
        type="Variant"
      />
    </Box>
  );

  return (
    <div>
      {/* <Modal
                open={addQuantityModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
               
            </Modal> */}

      <Drawer
        open={addQuantityModal}
        // onClose={toggleDrawer(false)}
        anchor="right"
      >
        <form onSubmit={formik.handleSubmit}>{drawerList}</form>
      </Drawer>
    </div>
  );
}
