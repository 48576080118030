import React, { useEffect, useState } from "react";
import {
  Table,
  TableContainer,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  FormControl,
  InputLabel,
  Input,
  IconButton,
  Button,
  Paper,
  Box,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Clear";
import AddIcon from "@mui/icons-material/Add";
import { useTranslation } from "react-i18next";

const WeeklyStatus = (props: any) => {
  const { data, updateState, updateErrorState } = props; // Added updateErrorState prop
  const [isMobile, setIsMobile] = useState(false);
  const [hasError, setHasError] = useState(false); // State to track errors

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600); // Set the threshold for mobile screens
    };

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);
    // Initial check for mobile screen on component mount
    handleResize();
    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    validateTimeRanges(); // Validate time ranges on component mount
  }, [data]); // Run this effect whenever data changes

  const handleCheckboxChange = (event: any, index: any) => {
    const { checked } = event.target;
    const updatedBusinessHours = [...data];
    updatedBusinessHours[index].selected = checked;
    updateState(updatedBusinessHours);
    validateTimeRanges(updatedBusinessHours);
  };

  const renderInputField = (
    value: any,
    onChange: any,
    onBlur: any,
    error: any,
    inputType: any,
    index: any,
    minTime?: any,
    maxTime?: any
  ) => {
    return (
      <FormControl
        sx={{ m: 1 }}
        variant="standard"
        focused
        key={`${inputType}-${index}`}
      >
        <InputLabel htmlFor="standard-adornment-block-start">
          {inputType}
        </InputLabel>
        <Input
          type="time"
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          inputProps={{ min: minTime, max: maxTime }}
          style={{ margin: "5px", padding: "10px 0 5px", width: "110px" }}
        />
      </FormControl>
    );
  };

  const handleInputChange = (
    event: any,
    index: any,
    setIndex: any,
    field: any
  ) => {
    const { value } = event.target;
    const updatedBusinessHours = [...data];
    updatedBusinessHours[index].sets[setIndex][field] = value;

    // Check for valid input
    const hasValidInput = updatedBusinessHours[index].sets.some(
      (set: any) =>
        set.openTime && set.closingTime && set.openTime < set.closingTime
    );

    updatedBusinessHours[index].selected = hasValidInput;
    updateState(updatedBusinessHours);
    validateTimeRanges(updatedBusinessHours);
  };

  const handleInputBlur = (index: any, setIndex: any, field: any) => {
    const updatedBusinessHours = [...data];
    const { openTime, closingTime } =
      updatedBusinessHours[index].sets[setIndex];

    if (field === "openTime" && closingTime && openTime >= closingTime) {
      updatedBusinessHours[index].sets[setIndex].closingTime = "";
    } else if (field === "closingTime" && openTime && closingTime <= openTime) {
      updatedBusinessHours[index].sets[setIndex].openTime = "";
    }

    updateState(updatedBusinessHours);
    validateTimeRanges(updatedBusinessHours);
  };

  const handleAddSet = (index: any) => {
    const updatedBusinessHours = [...data];
    const sets = updatedBusinessHours[index].sets;
    const lastSet = sets[sets.length - 1];

    if (lastSet && lastSet.closingTime) {
      updatedBusinessHours[index].sets.push({
        openTime: lastSet.closingTime,
        closingTime: "",
      });
    } else {
      updatedBusinessHours[index].sets.push({ openTime: "", closingTime: "" });
    }

    updateState(updatedBusinessHours);
    validateTimeRanges(updatedBusinessHours);
  };

  const handleClearRow = (index: any, setIndex: any) => {
    const updatedBusinessHours = [...data];
    if (updatedBusinessHours[index].sets.length === 1) {
      updatedBusinessHours[index].sets = [{ openTime: "", closingTime: "" }];
      updatedBusinessHours[index].selected = false;
    } else {
      updatedBusinessHours[index].sets.splice(setIndex, 1);
    }
    updateState(updatedBusinessHours);
    validateTimeRanges(updatedBusinessHours);
  };

  const { t } = useTranslation();

  const renderDeleteButton = (rowData: any, index: any, setIndex: any) => {
    return !isMobile ? (
      <IconButton onClick={() => handleClearRow(index, setIndex)}>
        <DeleteIcon />
      </IconButton>
    ) : (
      <Button size="small" variant="text" color="warning">
        Clear time
      </Button>
    );
  };

  const hasInputValues = (hour: any) => {
    return hour?.sets.every((set: any) => set.openTime && set.closingTime);
  };

  const renderPlusButton = (rowData: any, index: any) => {
    return (
      hasInputValues(rowData) && (
        <IconButton
          onClick={() => handleAddSet(index)}
          disabled={!rowData.selected && !hasInputValues(rowData)}
        >
          <AddIcon />
        </IconButton>
      )
    );
  };

  const validateTimeOverlap = (sets: any[]) => {
    for (let i = 0; i < sets.length; i++) {
      for (let j = i + 1; j < sets.length; j++) {
        if (
          (sets[i].openTime < sets[j].closingTime &&
            sets[i].closingTime > sets[j].openTime) ||
          (sets[j].openTime < sets[i].closingTime &&
            sets[j].closingTime > sets[i].openTime)
        ) {
          return true;
        }
      }
    }
    return false;
  };

  const validateTimeRanges = (updatedData = data) => {
    let hasError = false;
    for (const hour of updatedData) {
      if (validateTimeOverlap(hour.sets)) {
        hasError = true;
        break;
      }
      for (const set of hour.sets) {
        if (
          set.openTime &&
          set.closingTime &&
          set.openTime >= set.closingTime
        ) {
          hasError = true;
          break;
        }
      }
      if (hasError) break;
    }
    setHasError(hasError);
    updateErrorState(hasError); // Notify the parent about the error state
  };

  return (
    <TableContainer>
      <Table>
        <TableBody>
          {data &&
            data.map((hour: any, index: any) => (
              <TableRow key={index}>
                <TableCell width={"50px"} sx={{ backgroundColor: "#F5F5F5" }}>
                  <Checkbox
                    checked={hour.selected}
                    // onChange={(e) => handleCheckboxChange(e, index)}
                    color="warning"
                  />
                </TableCell>
                <TableCell width={"90px"} sx={{ backgroundColor: "#F5F5F5" }}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                    }}
                  >
                    {hour.text}
                  </Typography>
                </TableCell>
                <TableCell>
                  {hour.sets.map((set: any, setIndex: any) => {
                    const minTime =
                      setIndex > 0 ? hour.sets[setIndex - 1].closingTime : null;
                    const maxTime =
                      setIndex < hour.sets.length - 1
                        ? hour.sets[setIndex + 1].openTime
                        : null;
                    return (
                      <Box
                        key={`${index}-${setIndex}`}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {renderInputField(
                          set.openTime,
                          (e: any) =>
                            handleInputChange(e, index, setIndex, "openTime"),
                          () => handleInputBlur(index, setIndex, "openTime"),
                          set.openTime &&
                            set.closingTime &&
                            set.openTime >= set.closingTime,
                          t("locations.open"),
                          setIndex,
                          minTime,
                          maxTime
                        )}
                        {renderInputField(
                          set.closingTime,
                          (e: any) =>
                            handleInputChange(
                              e,
                              index,
                              setIndex,
                              "closingTime"
                            ),
                          () => handleInputBlur(index, setIndex, "closingTime"),
                          set.openTime &&
                            set.closingTime &&
                            set.closingTime <= set.openTime,
                          t("locations.close"),
                          setIndex,
                          set.openTime,
                          maxTime
                        )}
                        {renderDeleteButton(hour, index, setIndex)}
                        <br />
                      </Box>
                    );
                  })}
                  {validateTimeOverlap(hour.sets) && (
                    <Typography color="error">
                      Overlapping time ranges!
                    </Typography>
                  )}
                </TableCell>
                <TableCell>{renderPlusButton(hour, index)}</TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default WeeklyStatus;
