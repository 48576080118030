import { gql } from "@apollo/client";

export const FILTERED_STORE_LIST = gql`
  query selectedStoreList(
    $storeIds: JSONString
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    selectedStoreList(
      storeIds: $storeIds
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          storeId
          storeName
          noOfKds
          noOfPos
          noOfKiosk
          storeStatus
        }
      }
    }
  }
`;

export const GETTING_PAIRED_DEVICES_FOR_KDS = gql`
  query pairedDevicesList($businessId: ID, $storeId: ID, $deviceType: String) {
    pairedDevicesList(
      businessId: $businessId
      storeId: $storeId
      deviceType: $deviceType
    ) {
      edges {
        node {
          pairingId
          kdsStationName
          deviceName
          deviceType
          deviceSettings
          store {
            storeId
            storeName
          }
        }
      }
    }
  }
`;

export const GET_UPDATED_DATA_FOR_KDS = gql`
  query storeSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      kdsSettings
      paymentTypesForAdvanceBooking
      store {
        storeId

        deliverySlotTime
        storeName
      }
      business {
        businessId
        brandName
      }
    }
  }
`;

export const DEVICE_LIST_FOR_KDS = gql`
  query pairedDevicesList(
    $businessId: ID
    $storeIds: JSONString
    $deviceType: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    pairedDevicesList(
      businessId: $businessId
      storeIds: $storeIds
      deviceType: $deviceType
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          pairingId
          serialNumber
          deviceName
          deviceCode
          kdsproductsSet {
            edges {
              node {
                products
              }
            }
          }
          store {
            storeName
          }
          kdsStationName
          status

          status
          isDeleted
        }
      }
    }
  }
`;

export const DEVICES_AND_RELAVANT_PRODUCTS = gql`
  query kdsProductsList($deviceIds: JSONString, $locationId: Int) {
    kdsProductsList(deviceIds: $deviceIds, locationId: $locationId)
  }
`;

export const PRODUCT_LIST_FOR_KDS = gql`
  query productList($businessId: ID, $locationId: ID) {
    productList(businessId: $businessId, locationId: $locationId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          kdsStations
        }
      }
    }
  }
`;

export const GET_PAIRED_DEVICE_DETAILS = gql`
  query pairedDevice($deviceId: ID) {
    pairedDevice(deviceId: $deviceId) {
      pairingId
      deviceCode
      deviceName
      deviceType
      deviceSettings
      kdsStationName
      serialNumber
      store {
        storeId
        storeName
      }
      business {
        businessId
        brandName
      }
      status
      isDeleted
    }
  }
`;
