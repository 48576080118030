import React, { useState, useEffect, ChangeEvent } from "react";
import Box from "@mui/material/Box";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import {
  DataGrid,
  GridCellParams,
  GridColDef,
  GridOverlay,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  FormControl,
  Select,
  MenuItem,
  IconButton,
  Button,
  Typography,
  InputLabel,
  Checkbox,
  InputAdornment,
  Stack,
  Snackbar,
} from "@mui/material";
import { TextField } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import "./productTable.css";
import "./BulkProduct.css";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Popover from "@mui/material/Popover";
import {
  CATEGORY_LIST_IN_PRODUCT,
  FILTERING_PRODUCTS_LIST,
} from "../../graphicalQl/usequery/productsListQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";
import { GET_PRODUCTS_IN_BULK } from "../../graphicalQl/usequery/bulkQuery";
import uuid from "react-uuid";
import OptionsModalInBulk from "./optionsGropModal";
import {
  CREATE_BULK,
  UPDATE_BULK_PRODUCTS,
} from "../../graphicalQl/mutation/bulkMutation";
import ImageDrawer from "../menu/image/imageDrawer";
import OutlinedInput from "@mui/material/OutlinedInput";
import ListItemText from "@mui/material/ListItemText";
import { SelectChangeEvent } from "@mui/material/Select";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import { PRODUCT_DUPLICATE_CHECK } from "../../graphicalQl/mutation/productMutation";
import { DUPLICATE_SKU } from "../../graphicalQl/mutation/productMutation";
import { processVariants } from "../utils/arrayCheckingFunction";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// interface Row {
//   id: number;
//   category: string|null;
//   product: string;
//   sku:string;
//   location :number[];
//   soldBy: string|null;
//   price:string;
//   description:string|null;
//   optionsGroup:number[];
//   options:number[];
//   condtion:boolean;
//   image: string | null;

//   clickCount: boolean;
//   mainProduct:string;
//   show:boolean
// }

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}
interface Variation {
  sku: string;
  product: string;
  price: number | string | null;
  id: string;
  isChecked: boolean;
  skuError: boolean;
  variantGroup: string;
}

interface Product {
  id: string;
  category: any;
  sku: string;
  product: string;
  location: number[];
  locationName: string[];
  soldBy: string;
  price: number | string;
  optionsGroup: any[];
  options: any[];
  description: string;
  condtion: boolean;
  image: string | null;
  color: string | null;
  clickCount: boolean;
  mainProduct: string;
  variations: Variation[];
  minPrice: string;
  maxPrice: string;
  skuError: boolean;
  duplicateCondition: boolean;
}

export default function BulkProduct2() {
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const navigate = useNavigate();

  // const { locationId } = useSelector((state: any) => state.headerLocation);

  const { t } = useTranslation();

  const rows: Product[] = [
    {
      id: "1",
      sku: "",
      product: "",
      location: [],
      locationName: [],
      category: null,
      soldBy: "",
      price: "",
      optionsGroup: [],
      options: [],
      description: "",
      condtion: false,
      image: null,
      clickCount: true,
      mainProduct: "",
      color: null,
      minPrice: "",
      maxPrice: "",
      skuError: false,
      variations: [],
      duplicateCondition: false,
    },
  ];

  const [bulkRows, setBulkRows] = useState<Product[]>(rows);

  const [productId, setProductId] = useState<number | null>(null);

  // const [idsFromSession,setIdsFromSession]=useState<string|null>(null)
  const idsFromSession = sessionStorage.getItem("editBulkProductIds") as string;

  // const removeSession = () => {
  //   sessionStorage.removeItem("editBulkProductIds");
  // }
  // useEffectOnce(() => {

  //   return () => {

  //     removeSession();

  //   };
  // },);

  const idsFromProduct: number[] = idsFromSession && JSON.parse(idsFromSession);
  const [skusList, setSkusList] = useState<{ id: string; value: string }[]>([]);

  const [duplictateSku] = useMutation(DUPLICATE_SKU, {
    context: { clientName: "categoryClient" },
  });
  const validationSchema = Yup.array().of(
    Yup.object().shape({
      product: Yup.string()
        .required("Required")
        .test("is-unique", "Should be unique", function (value) {
          if (!this.options.context || !value) {
            return true;
          }

          const trimmedValue = value.trim().toLowerCase();
          const list = this.options.context.map((each: any) =>
            each.product ? each.product.trim().toLowerCase() : ""
          );

          // Check if the value is unique in the list
          const isUnique =
            list.indexOf(trimmedValue) === list.lastIndexOf(trimmedValue);
          return isUnique;
        }),
      locationName: Yup.array().min(
        1,
        "*At least one location value is required"
      ),
      sku: Yup.string().test(
        "unique-sku",
        "SKU must be unique",
        function (value) {
          const { id } = this.parent;
          if (!value) return true;
          const list = skusList.map((each) => each.value);
          return list.filter((each) => each === value).length <= 1;
        }
      ),
      // sku must be unique not requried

      variations: Yup.array().of(
        Yup.object().shape({
          product: Yup.string()
            .required("Required")
            .test("is-unique", "should be unique", function (value) {
              const str = this.path;
              const regex = /\[(\d+)\]/g;
              let match;
              const indices = [];

              while ((match = regex.exec(str)) !== null) {
                indices.push(parseInt(match[1]));
              }

              const data =
                this.options.context && this.options.context[indices[0]];
              if (!data) {
                return true;
              }

              const { variations } = data;
              const allVariantNames =
                variations &&
                variations.map((variant: any) =>
                  variant.product ? variant.product.trim().toLowerCase() : ""
                );
              return (
                allVariantNames &&
                allVariantNames.filter(
                  (name: string) => name === value.trim().toLowerCase()
                ).length <= 1
              );
            }),
          price: Yup.number().required("Required"),
          sku: Yup.string().test(
            "unique-sku",
            "SKU must be unique",
            function (value) {
              const { id } = this.parent;
              if (!value) return true;
              const list = skusList.map((each) => each.value);
              return list.filter((each) => each === value).length <= 1;
            }
          ),
        })
      ),
      // price: Yup.string().when("variations", (variations, schema) => {
      //   if (variations && variations.length === 0) {
      //     return schema;
      //   }
      //   return Yup.string().required("Price is required");
      // }),
      // price: Yup.number().when("variations", {
      //   is: (value: string) => value && value.length === 0,
      //   then: (schema) =>
      //     schema
      //       .required("Required"),
      // }),
      price: Yup.number()
        .required("* Required")
        .moreThan(0, "Price must be greater than 0"),
    })
  );

  const validationSchema2 = Yup.array().of(
    Yup.object().shape({
      product: Yup.string()
        .required("Required")
        .test("is-unique", "should be unique", function (value) {
          const list =
            this.options.context &&
            this.options.context.map((each: any) => each.product);
          return (
            list &&
            list.filter(
              (name: string) => name && name.trim() === value && value.trim()
            ).length <= 1
          );
        }),
      sku: Yup.string().test(
        "unique-sku",
        "SKU must be unique",
        function (value) {
          const { id } = this.parent;
          if (!value) return true;
          return (
            skusList.filter((each) => each.value === value && each.id !== id)
              .length === 0
          );
        }
      ),
      // price: Yup.number().required('Price is required').positive('Price must be a positive number'),
      variations: Yup.array()
        .of(
          Yup.object().shape({
            product: Yup.string()
              .required("Required")
              .test("is-unique", "should be unique", function (value) {
                const str = this.path;
                const regex = /\[(\d+)\]/g;
                let match;
                const indices = [];

                while ((match = regex.exec(str)) !== null) {
                  indices.push(parseInt(match[1]));
                }

                const data = this.options.context && {
                  ...this.options.context[indices[0]],
                };
                const { variations } = data;
                const allVariantNames =
                  variations &&
                  variations.map((variant: any) => variant.product);
                return (
                  allVariantNames &&
                  allVariantNames.filter(
                    (name: string) =>
                      name && name.trim() === value && value.trim()
                  ).length <= 1
                );
              }),
            sku: Yup.string().test(
              "unique-sku",
              "SKU must be unique",
              function (value) {
                const { id } = this.parent;
                if (!value) return true;
                return (
                  skusList.filter(
                    (each) => each.value === value && each.id !== id
                  ).length === 0
                );
              }
            ),
            price: Yup.number().required("Required"),
          })
        )
        .nullable(),
      // price: Yup.number().when("variations", {
      //   is: (value: string) => value && value.length === 0,
      //   then: (schema) =>
      //     schema
      //       .required("Required"),
      // }),
      price: Yup.number()
        .required("* Required")
        .moreThan(0, "Price must be greater than 0"),
    })
  );

  // 599,566,570,607
  const filterProductsBySkuError = (products: Product[]): Product[] => {
    const filteredProducts = products.filter((product) => {
      // Check if skuError is true in main product
      if (product.skuError) {
        return true;
      }

      // Check if skuError is true in any variation
      const hasErrorInVariations =
        product.variations &&
        product.variations.some((variation) => variation.skuError);

      return hasErrorInVariations;
    });

    return filteredProducts;
  };

  const formik = useFormik({
    initialValues: bulkRows,
    validationSchema:
      idsFromProduct && idsFromProduct.length > 0
        ? validationSchema2
        : validationSchema,
    onSubmit: () => {
      const productNamesList = bulkRows.map((each) => each.product);
      const setProductList = new Set(productNamesList);
      const productsWithSkuError = filterProductsBySkuError(bulkRows);

      if (
        !(
          bulkRows.some((each) => each.duplicateCondition) &&
          productNamesList.length === setProductList.size
        ) &&
        productsWithSkuError.length === 0
      ) {
        if (idsFromProduct) {
          updateBulk({
            variables: {
              items: JSON.stringify(updateBulkItem),
              businessId: +businessId,
            },
            onCompleted: () => {
              sessionStorage.removeItem("editBulkProductIds");
              navigate("/product/product-list");
              toast.success(
                <div>
                  <Typography>{t("toast.updated")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            },
          });
        } else {
          createBulk({
            variables: {
              items: JSON.stringify(createBulkItem),
              businessId: +businessId,
            },
            onCompleted: () => {
              navigate("/product/product-list");
              toast.success(
                <div>
                  <Typography>{t("toast.created")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            },
          });
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues(bulkRows);
  }, [bulkRows]);


  const { data: categoryData } = useQuery(CATEGORY_LIST_IN_PRODUCT, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  const [categoryList, setCategoryList] =
    useState<{ id: string; name: string }[]>();
  useEffect(() => {
    if (categoryData) {
      const list = categoryData.categoryList.edges.map((each: any) => ({
        id: each.node.categoryId,
        name: each.node.name,
      }));
      setCategoryList(list);
    }
  }, [categoryData]);

  const categoryOptions = [
    { value: "per_item", label: t("product.per_item"), id: "1" },
    { value: "per_gm", label: t("product.per_gram"), id: "2" },
    { value: "per_ml", label: "Per Ml", id: "3" },
    { value: "per_ounce", label: t("product.per_ounce"), id: "4" },
    { value: "per_kg", label: "Per Kg", id: "5" },
    { value: "per_litre", label: t("product.per_litre"), id: "5" },
    // { value: "perYard", label: t("product.per_yard"), id: '6' },
  ];
  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  const [locationsList, seLocationsList] = useState<Locations[]>([]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );

      seLocationsList(requiredDataList);
    }
  }, [locationsData]);

  const { data: productData } = useQuery(GET_PRODUCTS_IN_BULK, {
    context: { clientName: "categoryClient" },
    variables: { productIds: JSON.stringify(idsFromProduct) },
    fetchPolicy: "network-only",
  });

  const [personName, setPersonName] = React.useState<string[]>([]);

  const [checkDuplicate] = useMutation(PRODUCT_DUPLICATE_CHECK, {
    context: { clientName: "categoryClient" },
  });

  const handleLocationChange = (
    event: SelectChangeEvent<typeof personName>,
    rowId: string,
    productName: string
  ) => {
    const {
      target: { value },
    } = event;
    setPersonName(typeof value === "string" ? value.split(",") : value);
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      const locationListIds = locationsList
        .filter(
          (each) => value.includes(each.storeName) || value === each.storeName
        )
        .map((item) => +item.storeId);

      newRows[rowIndex].location = locationListIds;
      newRows[rowIndex].locationName =
        typeof value === "string" ? value.split(",") : value;
      setBulkRows(newRows);

      checkDuplicate({
        variables: {
          productName: productName.toLowerCase().trim(),
          locationIds: JSON.stringify(locationListIds),
        },
        onCompleted: (data) => {
          const updatedRows = bulkRows.map((row) => {
            if (row.id.toString() === rowId.toString()) {
              return {
                ...row,
                duplicateCondition: data.duplicateProductNameCheck.duplicate,
              };
            }
            return row;
          });

          setBulkRows(updatedRows);
        },
      });
    }
  };

  const [initilaGetData, setInitialGetData] = useState<any>([]);

  const [resultObject, setResultObject] = useState<any>(null);

  useEffect(() => {
    if (idsFromProduct) {
      const result = processVariants(bulkRows, initilaGetData, true);
      setResultObject(result);
    }
  }, [bulkRows]);
  useEffect(() => {
    if (productData && productData.selectedProductList) {
      if (productData.selectedProductList.edges.length > 0) {
        const list = productData.selectedProductList.edges.map(
          (product: any) => ({
            id: product.node.productId,
            category: product.node.category && product.node.category.categoryId,
            sku: product.node.sku || "",
            product: product.node.name,
            location: product.node.productId,
            locationName: [],
            soldBy: product.node.unit,
            price: product.node.price || "",
            optionsGroup: JSON.parse(product.node.optionGroups),
            options: JSON.parse(product.node.options),
            description: product.node.description,
            condtion: false,
            image: product.node.image,
            color: product.node.color,
            clickCount: true,
            mainProduct: "",
            minPrice: "",
            maxPrice: "",
            duplicateCondition: false,
            skuError: false,
            variations:
              product.node.variants &&
              product.node.variants.map((variant: any) => ({
                id: variant.variantId,
                sku: variant.sku || "",
                product: variant.name,
                price: variant.additionalPrice,
                isChecked: false,
                skuError: false,
                variantGroup: variant.variantGroup,
              })),
          })
        );

        setBulkRows(list);
        setInitialGetData(list);
      }
    }
  }, [productData]);

  const handleAccordion = (row1: Product) => {
    const list = bulkRows.map((each) =>
      each.id.toString() === row1.id.toString()
        ? { ...each, clickCount: false }
        : each
    );
    setBulkRows(list);
  };

  const handleAccordion2 = (row1: Product) => {
    const list = bulkRows.map((each) =>
      each.id.toString() === row1.id.toString()
        ? { ...each, clickCount: true }
        : each
    );
    setBulkRows(list);
  };

  const handleAddRowClick = (rowId: number) => {
    const newRow: Product[] = bulkRows.map((each) =>
      each.id.toString() === rowId.toString()
        ? {
            ...each,
            price: each.price,
            sku: each.sku,

            variations: Array.isArray(each.variations)
              ? [
                  ...each.variations,
                  {
                    sku: "",
                    product: "",
                    price: null,
                    id: uuid(),
                    isChecked: false,
                    skuError: false,
                    variantGroup: "Size",
                  },
                ]
              : [
                  {
                    sku: "",
                    product: "",
                    price: null,
                    id: uuid(),
                    isChecked: false,
                    skuError: false,
                    variantGroup: "Size",
                  },
                ],
          }
        : each
    );

    setBulkRows(newRow);
  };

  const handleProductChange = (
    rowId: number,
    event: React.ChangeEvent<HTMLInputElement>,
    locations: any
  ) => {
    event.stopPropagation();
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].product = event.target.value;
      setBulkRows(newRows);
    }

    checkDuplicate({
      variables: {
        productName: event.target.value.toLowerCase().trim(),
        locationIds: JSON.stringify(locations ? locations : []),
      },
      onCompleted: (data) => {
        const updatedRows = bulkRows.map((row) => {
          if (row.id.toString() === rowId.toString()) {
            return {
              ...row,
              duplicateCondition: data.duplicateProductNameCheck.duplicate,
            };
          }
          return row;
        });

        setBulkRows(updatedRows);
      },
    });
  };

  const handleVariantChange = (
    rowId: number,
    newValue: string,
    variant: string
  ) => {
    const updatedRows = bulkRows.map((row) => {
      if (row.id.toString() === rowId.toString()) {
        return {
          ...row,
          variations: row.variations.map((item) =>
            item.id === variant ? { ...item, product: newValue } : item
          ),
        };
      }
      return row;
    });

    setBulkRows(updatedRows);
  };

  const handleSkuChange = (rowId: number, newValue: string) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];
      newRows[rowIndex].sku = newValue;
      setBulkRows(newRows);
      if (newValue.length > 0) {
        duplictateSku({
          variables: {
            productSku: newValue,
            productId: idsFromProduct && +rowId,
            variantId: null,
            businessId: +businessId,
          },
          onCompleted: (data) => {
            newRows[rowIndex].skuError =
              data.duplicateProductSkuCheck.duplicate;
            // setSkuDuplicate(data.duplicateProductSkuCheck.duplicate)
            // dispatch(setPricing("skuDuplicate", data.duplicateProductSkuCheck.duplicate));
          },
        });
      }
    }
  };
  const handleBulkRowsByVariantSku = (
    row: any,
    variant: string,
    newValue: string,
    index: any,
    result: boolean
  ) => {
    const updatedRow = {
      ...row,
      variations:
        row.variations &&
        row.variations.map((item: any) =>
          item.id === variant
            ? { ...item, sku: newValue, skuError: result }
            : item
        ),
    };

    // Update bulkRows with the updatedRow
    setBulkRows((prevBulkRows) => {
      const updatedRows = [...prevBulkRows];
      updatedRows[index] = updatedRow;
      return updatedRows;
    });
  };
  const handleVariantskuChange = (
    rowId: number,
    newValue: string,
    variant: string
  ) => {
    bulkRows.forEach(async (row, index) => {
      if (row.id.toString() === rowId.toString()) {
        if (newValue !== "") {
          try {
            const { data } = await duplictateSku({
              variables: {
                productSku: newValue,
                productId: null,
                variantId: idsFromProduct && parseInt(variant),
              },
            });
            const result = data.duplicateProductSkuCheck.duplicate;

            // Update the row in bulkRows with the new SKU and skuError
            // const updatedRow = {
            //   ...row,
            //   variations: row.variations.map((item) =>
            //     item.id === variant ? { ...item, sku: newValue, skuError: result } : item
            //   ),
            // };

            // // Update bulkRows with the updatedRow
            // setBulkRows((prevBulkRows) => {
            //   const updatedRows = [...prevBulkRows];
            //   updatedRows[index] = updatedRow;
            //   return updatedRows;
            // });
            handleBulkRowsByVariantSku(row, variant, newValue, index, result);
          } catch (error) {
            console.error("Error checking duplicate SKU:", error);
          }
        } else {
          handleBulkRowsByVariantSku(row, variant, newValue, index, false);
        }
      }
    });
  };

  const handleVariantPriceChange = (
    rowId: number,
    newValue: string,
    variant: string
  ) => {
    const updatedRows = bulkRows.map((row) => {
      if (row.id.toString() === rowId.toString()) {
        return {
          ...row,
          variations: row.variations.map((item) =>
            item.id === variant ? { ...item, price: newValue } : item
          ),
        };
      }
      return row;
    });
    setBulkRows(updatedRows);
  };

  const handlecategoryChange = (rowId: number, newValue: string) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];
      newRows[rowIndex].category = newValue;
      setBulkRows(newRows);
    }
  };

  const handlePriceChange = (rowId: number, newValue: string) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].price = newValue;
      setBulkRows(newRows);
    }
  };

  const handlesoldChange = (rowId: number, newValue: string) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].soldBy = newValue;
      setBulkRows(newRows);
    }
  };

  const handleDescriptionChange = (rowId: number, newValue: string) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === rowId.toString()
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].description = newValue;
      setBulkRows(newRows);
    }
  };

  const handleOptionGroup = (newValue: number[]) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === (productId && productId.toString())
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].optionsGroup = newValue;
      setBulkRows(newRows);
    }
  };
  const [optionIds, setOptionIds] = useState<number[]>([]);
  const [optionGroupIds, setOptionGroupIds] = useState<number[]>([]);

  const handleOptions = (productIds: any) => {
    const filteredData = bulkRows.filter(
      (each) => each.id.toString() === productIds?.toString()
    );
    const optionIds = filteredData.map((each) => each.options);
    const optionGroupIds = filteredData.map((each) => each.optionsGroup);
    setOptionIds(optionIds[0]);
    setOptionGroupIds(optionGroupIds[0]);
  };

  const handleOption = (newValue: number[]) => {
    const rowIndex = bulkRows.findIndex(
      (row) => row.id.toString() === (productId && productId.toString())
    );
    if (rowIndex !== -1) {
      const newRows = [...bulkRows];

      newRows[rowIndex].options = newValue;
      setBulkRows(newRows);
    }
  };
  const [rowid, setRowid] = useState<string | null>(null);
  const getRowIdFromRowModel = (value: string) => {
    setRowid(value);
  };

  const handleImage = (value: string | null) => {
    const list = bulkRows.map((each) =>
      each.id === rowid ? { ...each, image: value, color: null } : each
    );
    setBulkRows(list);
  };

  const handleColor = (value: string | null) => {
    const list = bulkRows.map((each) =>
      each.id === rowid ? { ...each, color: value, image: null } : each
    );
    setBulkRows(list);
  };

  function calculateMinMaxPrice(products: Product[]): void {
    for (const product of products) {
      let minPrice: number | null = null;
      let maxPrice: number | null = null;

      if (product.variations) {
        for (const variation of product.variations) {
          if (variation.price != null) {
            if (minPrice === null || +variation.price < minPrice) {
              minPrice = +variation.price;
            }
            if (maxPrice === null || +variation.price > maxPrice) {
              maxPrice = +variation.price;
            }
          }
        }
      }

      product.minPrice = minPrice != null ? minPrice.toString() : "";
      product.maxPrice = maxPrice != null ? maxPrice.toString() : "";
    }
  }

  useEffect(() => {
    calculateMinMaxPrice(bulkRows);
  }, [bulkRows]);

  const [variantIds, setVariantIds] = useState<string[]>([]);
  const handleVariantCheckedIds = (
    id: string,
    value: boolean,
    rowId: string
  ) => {
    if (value) {
      setVariantIds((prevState) => [...prevState, id]);
    } else {
      setVariantIds((prevState) => prevState.filter((item) => item !== id));
      setBulkRowselectionModel((prevState) =>
        prevState.filter((item) => item !== rowId)
      );
    }

    const updatedRows = bulkRows.map((row) => {
      if (row.id.toString() === rowId.toString()) {
        return {
          ...row,

          variations: row.variations.map((item) =>
            item.id === id ? { ...item, isChecked: value } : item
          ),
        };
      }
      return row;
    });

    setBulkRows(updatedRows);
  };
  const handleVariantGroupChange = (
    rowId: number,
    newValue: string,
    variant: string
  ) => {
    const updatedRows = bulkRows.map((row) => {
      if (row.id.toString() === rowId.toString()) {
        return {
          ...row,
          variations: row.variations.map((item) =>
            item.id === variant ? { ...item, variantGroup: newValue } : item
          ),
        };
      }
      return row;
    });

    setBulkRows(updatedRows);
  };

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  // const handleDeleteVariation = () => {

  //   setBulkRows(updatedRows);
  // };

  const handleSaveSkuList = (id: string, sku: string) => {
    // Check if the id exists in the skusList
    const exists = skusList.some((each) => each.id === id);

    const list = exists
      ? skusList.map((each) =>
          each.id === id ? { ...each, value: sku } : each
        )
      : [...skusList, { id, value: sku }];

    // Assuming you have some way to update the skusList state
    setSkusList(list);
  };

  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const columns: GridColDef[] = [
    {
      field: "product",
      //@ts-ignore
      headerName: t("bulk_product.products"),
      headerClassName: "bulk-product-column",
      disableClickEventBubbling: true,
      width: 400,
      cellClassName: "bulk-product-column",
      editable: false,
      disableColumnMenu: true,

      renderCell: (params: GridCellParams) => {
        const rowIndex = bulkRows.findIndex((row) => row.id === params.row.id);

        return (
          <div style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                height: "120px",
                alignItems: "center",
              }}
            >
              {params.row.variations &&
                params.row.variations.length > 0 &&
                (params.row.clickCount ? (
                  <IconButton onClick={() => handleAccordion(params.row)}>
                    <ArrowDropUpIcon />
                  </IconButton>
                ) : (
                  <IconButton onClick={() => handleAccordion2(params.row)}>
                    {" "}
                    <ArrowDropDownIcon />
                  </IconButton>
                ))}

              <Box
                sx={{ height: "56px", width: "16%", marginRight: "3%" }}
                className="create-product-img-con"
                onClick={() => getRowIdFromRowModel(params.row.id)}
              >
                <ImageDrawer
                  handleImage={handleImage}
                  handleColor={handleColor}
                  colorCodeFromApi={params.row.color}
                  imageFromApi={params.row.image}
                  heightValue={40}
                  textCondition={false}
                />
              </Box>

              <div>
                <TextField
                  defaultValue={params.value}
                  className="inputFieldForSpace"
                  //@ts-ignore
                  placeholder={t("bulk_product.product_name")}
                  name={`[${rowIndex}].product`}
                  label={t("bulk_product.product_name")}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    if (e.target.value.length <= 25) {
                      handleProductChange(
                        params.row.id,
                        e,
                        params.row.location
                      );
                    }
                    // handleProductChange(params.row.id, e);
                  }}
                  color="warning"
                  // variant='standard'
                  // InputProps={{
                  //   disableUnderline: true, // This removes the underline
                  // }}
                  // sx={{
                  //   "&:hover": {
                  //     "&& fieldset": {
                  //       border: "2px solid #F9C584"
                  //     }
                  //   }
                  // }}
                  //  onKeyDown={(e:React.KeyboardEvent<HTMLInputElement>)=>handleKeyDown(e)}
                  onKeyDown={(event) => event.stopPropagation()}
                  onBlur={formik.handleBlur}
                  error={
                    (formik.touched &&
                      formik.touched[rowIndex]?.product &&
                      Boolean(
                        formik.errors && formik.errors[rowIndex]?.product
                      )) ||
                    params.row.duplicateCondition
                  }
                  helperText={
                    (formik.touched &&
                      formik.touched[rowIndex]?.product &&
                      formik.errors &&
                      formik.errors[rowIndex]?.product) ||
                    (params.row.duplicateCondition && "must be unique")
                  }
                />

                {/* <input type='text'/> */}
                <Box sx={{ textAlign: "right" }}>
                  <Typography sx={{ fontSize: "12px", color: "#ababab" }}>
                    {params.row.product.length}/25
                  </Typography>
                </Box>
                {/* {params.row.duplicateCondition && <Typography sx={{ fontSize: "12px", color: "red" }}>*Product Name Already Existed </Typography>} */}
                {/* {params.row.duplicateCondition && (
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    Product Name Already Existed
                  </Typography>
                )}
                {formik.touched &&
                  formik.touched[rowIndex]?.product &&
                  formik.errors &&
                  formik.errors[rowIndex]?.product && (
                    <div style={{ color: "red" }}>
                      <Typography sx={{ fontSize: "0.75rem" }}>
                        {formik.errors[rowIndex]?.product}
                      </Typography>
                    </div>
                  )} */}
              </div>
              <Box
                sx={{
                  marginTop: "auto",
                  marginLeft: "auto",
                  marginBottom: "4px",
                }}
              >
                <IconButton
                  onClick={() => handleAddRowClick(params.row.id)}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={handlePopoverOpen}
                  onMouseLeave={handlePopoverClose}
                  sx={{ height: "20px" }}
                >
                  <AddIcon className="bulk-product-plus" />
                </IconButton>
              </Box>
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Typography sx={{ p: 1 }} className="bulk-product-popover">
                  {t("bulk_product.add_variant")}
                </Typography>
              </Popover>
            </div>
            {params.row.clickCount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {params.row.variations &&
                  params.row.variations.map((each: any, index: number) => (
                    <div
                      style={{
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        alignItems: "start",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                        gap: "4%",
                        paddingTop: "18px",
                      }}
                      key={each.id}
                    >
                      {/* <Divider sx={{width:"100%",margin:"3%",}}/> */}
                      <Checkbox
                        color="warning"
                        sx={{}}
                        onChange={(e: ChangeEvent<HTMLInputElement>) =>
                          handleVariantCheckedIds(
                            each.id,
                            e.target.checked,
                            params.row.id
                          )
                        }
                        checked={each.isChecked}
                      />
                      <FormControl style={{ width: "40%" }}>
                        <InputLabel id="demo-simple-select-label">
                          Variant Group
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          color="warning"
                          label="Variant Group"
                          value={each.variantGroup}
                          name={`[${rowIndex}].variations.[${index}].varinantGroup`}
                          onChange={(e: SelectChangeEvent) => {
                            handleVariantGroupChange(
                              params.row.id,
                              e.target.value,
                              each.id
                            );
                            formik.handleChange(e);
                          }}

                          // value={formik.values.category || ""}
                          // onChange={(e: SelectChangeEvent) => { handleInputChange3("category", e.target.value); formik.handleChange(e) }}
                        >
                          <MenuItem key="size" value="Size">
                            Size
                          </MenuItem>
                          <MenuItem key="Base" value="Base">
                            Base
                          </MenuItem>
                          <MenuItem key="preparation" value="Preparation type">
                            Preparation type
                          </MenuItem>
                        </Select>
                      </FormControl>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          defaultValue={each.product}
                          className="inputFieldForSpace"
                          //@ts-ignore
                          placeholder={t("bulk_product.variant_name")}
                          // InputProps={{
                          //   startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          // }}
                          name={`[${rowIndex}].variations.[${index}].product`}
                          onChange={(e) => {
                            if (e.target.value.length <= 25) {
                              handleVariantChange(
                                params.row.id,
                                e.target.value,
                                each.id
                              );
                              formik.handleChange(e);
                            }
                          }}
                          color="warning"
                          onKeyDown={(event) => event.stopPropagation()}
                          onBlur={formik.handleBlur}
                          error={
                            formik.touched &&
                            formik.touched[rowIndex]?.variations &&
                            (
                              formik.touched?.[rowIndex]
                                ?.variations as FormikTouched<Variation[]>
                            )[index]?.product &&
                            Boolean(
                              formik.errors &&
                                formik.errors?.[rowIndex]?.variations &&
                                (
                                  formik.errors?.[rowIndex]
                                    ?.variations as FormikErrors<Variation[]>
                                )[index]?.product
                            )
                          }
                          // InputProps={{
                          //   disableUnderline: true, // This removes the underline
                          // }}
                          helperText={
                            formik.touched &&
                            formik.touched[rowIndex]?.variations &&
                            (
                              formik.touched?.[rowIndex]
                                ?.variations as FormikTouched<Variation[]>
                            )[index]?.product &&
                            formik.errors &&
                            formik.errors?.[rowIndex]?.variations &&
                            (
                              formik.errors?.[rowIndex]
                                ?.variations as FormikErrors<Variation[]>
                            )[index]?.product
                          }
                        />
                        <Box sx={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#ababab" }}
                          >
                            {each.product.length}/25
                          </Typography>
                        </Box>
                        {/* {formik.touched &&
                          formik.touched[rowIndex]?.variations &&
                          (
                            formik.touched?.[rowIndex]
                              ?.variations as FormikTouched<Variation[]>
                          )[index]?.product &&
                          formik.errors &&
                          formik.errors?.[rowIndex]?.variations &&
                          (
                            formik.errors?.[rowIndex]
                              ?.variations as FormikErrors<Variation[]>
                          )[index]?.product && (
                            <div style={{ color: "red" }}>
                              <Typography sx={{ fontSize: "0.75rem" }}>
                                {
                                  (
                                    formik.errors?.[rowIndex]
                                      ?.variations as FormikErrors<Variation[]>
                                  )[index]?.product
                                }
                              </Typography>
                            </div>
                          )} */}
                      </Box>
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      },
    },
    {
      field: "sku",
      //@ts-ignore
      headerName: t("bulk_product.sku"),
      headerClassName: "bulk-product-column",
      cellClassName: "bulk-product-column",
      editable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        const rowIndex = bulkRows.findIndex((row) => row.id === params.row.id);
        return (
          <div style={{}}>
            <Box sx={{ height: "120px", paddingTop: "18px" }}>
              <TextField
                className="inputFieldForSpace"
                // disabled={
                //   params.row.variations && params.row.variations.length > 0
                // }

                label={t("bulk_product.sku")}
                name={`[${rowIndex}].sku`}
                defaultValue={params.value}
                //@ts-ignore
                placeholder={t("bulk_product.sku")}
                onChange={(e) => {
                  handleSkuChange(params.row.id, e.target.value);
                  handleSaveSkuList(params.row.id, e.target.value);
                }}
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                color="warning"
                onKeyDown={(event) => {
                  event.stopPropagation();
                }}
                error={
                  (formik.touched[rowIndex]?.sku &&
                    Boolean(formik.errors[rowIndex]?.sku)) ||
                  params.row.skuError
                }
                helperText={
                  (formik.touched[rowIndex]?.sku &&
                    formik.errors[rowIndex]?.sku) ||
                  (params.row.skuError && "SKU must be uniqe")
                }
              />
              {/* {((formik.touched[rowIndex]?.sku && formik.errors[rowIndex]?.sku) || params.row.skuError) && (
                <div style={{ color: "red" }}>
                  <Typography sx={{ fontSize: "0.75rem" }}>
                    {formik.errors[rowIndex]?.sku || "SKU must be uniqe"}
                  </Typography>
                </div>
              )} */}
            </Box>

            {params.row.clickCount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {params.row.variations &&
                  params.row.variations.map((each: any, index: number) => (
                    <div
                      style={{
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        flexDirection: "column",
                        paddingTop: "18px",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      key={index}
                    >
                      {/* <Divider sx={{width:"100%",margin:"3%"}}/> */}
                      <TextField
                        className="inputFieldForSpace"
                        label={t("bulk_product.sku")}
                        name={`[${rowIndex}].variations.[${index}].sku`}
                        defaultValue={each.sku}
                        //@ts-ignore
                        placeholder={t("bulk_product.sku")}
                        onChange={(e) => {
                          handleVariantskuChange(
                            params.row.id,
                            e.target.value,
                            each.id
                          );
                          handleSaveSkuList(each.id, e.target.value);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                        }}
                        color="warning"
                        onKeyDown={(event) => event.stopPropagation()}
                        error={
                          (formik.touched &&
                            formik.touched[rowIndex]?.variations &&
                            (
                              formik.touched?.[rowIndex]
                                ?.variations as FormikTouched<Variation[]>
                            )[index]?.sku &&
                            Boolean(
                              formik.errors &&
                                formik.errors?.[rowIndex]?.variations &&
                                (
                                  formik.errors?.[rowIndex]
                                    ?.variations as FormikErrors<Variation[]>
                                )[index]?.sku
                            )) ||
                          each.skuError
                        }
                        helperText={
                          (formik.touched &&
                            formik.touched[rowIndex]?.variations &&
                            (
                              formik.touched?.[rowIndex]
                                ?.variations as FormikTouched<Variation[]>
                            )[index]?.sku &&
                            formik.errors &&
                            formik.errors?.[rowIndex]?.variations &&
                            (
                              formik.errors?.[rowIndex]
                                ?.variations as FormikErrors<Variation[]>
                            )[index]?.sku) ||
                          (each.skuError && "Sku must be unique")
                        }
                      />
                      {/* {((formik.touched &&
                        formik.touched[rowIndex]?.variations &&
                        (
                          formik.touched?.[rowIndex]
                            ?.variations as FormikTouched<Variation[]>
                        )[index]?.sku &&
                        formik.errors &&
                        formik.errors?.[rowIndex]?.variations &&
                        (
                          formik.errors?.[rowIndex]
                            ?.variations as FormikErrors<Variation[]>
                        )[index]?.sku) || each.skuError) && (
                          <div style={{ color: "red" }}>
                            <Typography sx={{ fontSize: "0.75rem" }}>
                              {!each.skuError ?
                                ((
                                  formik.errors && formik.errors?.[rowIndex]
                                    ?.variations as FormikErrors<Variation[]>
                                )[index]?.sku) : "Sku must be unique"
                              }
                            </Typography>
                          </div>
                        )} */}
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: "price",
      //@ts-ignore
      headerName: t("bulk_product.price"),
      headerClassName: "bulk-product-column",
      cellClassName: "bulk-product-column",
      width: 150,
      sortable: true,
      name: "price",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const rowIndex = bulkRows.findIndex((row) => row.id === params.row.id);
        return (
          <div>
            {/* {params.row.variations && params.row.variations.length > 0 ? (
              <Box
                sx={{
                  height: "120px",
                  display: "flex",
                  flexDirection: "column",
                  paddingTop: "18px",
                }}
              >
                <TextField
                  value={
                    params.row.minPrice.toLocaleString() === "0" &&
                      params.row.maxPrice.toLocaleString() === "0"
                      ? ""
                      : params.row.minPrice.toLocaleString() === "0"
                        ? `${params.row.minPrice}-${params.row.maxPrice}`
                        : params.row.minPrice === params.row.maxPrice
                          ? `${params.row.minPrice}`
                          : `${params.row.minPrice}-${params.row.maxPrice}`
                  }
                  disabled
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        {currencySymbol}
                      </InputAdornment>
                    ),
                  }}
                  // onChange={(e) => {
                  //   handlePriceChange(params.row.id, e.target.value);
                  //   formik.handleChange(e);
                  // }}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!val || /^[0-9.]+$/.test(val)) {
                      handlePriceChange(params.row.id, e.target.value);
                      formik.handleChange(e);
                    }
                  }}
                  type="text"
                  color="warning"
                  className="hide-number-input-icons inputFieldForSpace"
                  onKeyDown={(event) => event.stopPropagation()}
                />
              </Box>
            ) : ( */}
            <Box
              sx={{
                height: "120px",
                display: "flex",
                flexDirection: "column",
                paddingTop: "18px",
              }}
            >
              <TextField
                defaultValue={params.value}
                name={`[${rowIndex}].price`}
                label={t("bulk_product.price")}
                type="text"
                // onChange={(e) =>
                //   {
                //     handlePriceChange(params.row.id, e.target.value); formik.handleChange(e)

                //   }}
                onChange={(e) => {
                  const val = e.target.value;
                  if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 6) {
                    handlePriceChange(params.row.id, e.target.value);
                    formik.handleChange(e);
                  }
                }}
                color="warning"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }}
                className="hide-number-input-icons inputFieldForSpace"
                onKeyDown={(event) => event.stopPropagation()}
                onKeyUp={(e) => e.stopPropagation()}
                onBlur={formik.handleBlur}
                error={
                  formik.touched &&
                  formik.touched[rowIndex]?.price &&
                  Boolean(formik.errors && formik.errors[rowIndex]?.price)
                }
                helperText={
                  formik.touched &&
                  formik.touched[rowIndex]?.price &&
                  formik.errors &&
                  formik.errors[rowIndex]?.price
                }
              />
              {/* {formik.touched &&
                  formik.touched[rowIndex]?.price &&
                  formik.errors &&
                  formik.errors[rowIndex]?.price && (
                    <div style={{ color: "red" }}>
                      {" "}
                      <Typography sx={{ fontSize: "0.75rem" }}>
                        {formik.errors[rowIndex]?.price}{" "}
                      </Typography>
                    </div>
                  )} */}
            </Box>
            {/* )
            } */}

            {params.row.clickCount && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                }}
              >
                {params.row.variations &&
                  params.row.variations.map((each: any, index: number) => (
                    <div
                      style={{
                        width: "100%",
                        height: "120px",
                        display: "flex",
                        paddingTop: "18px",
                        flexDirection: "column",
                        borderTop: "1px solid rgba(224, 224, 224, 1)",
                      }}
                      key={index}
                    >
                      {/* <Divider sx={{width:"100%",marginTop:"3%",marginBottom:'3%'}}/>      */}
                      {/* <TextField 
                       value={each.price}
                       type='number'
                       placeholder='price'
                       onChange={(e) => handleVariantPriceChange (params.row.id, e.target.value,each.id)}
                       color='warning'
                     /> */}

                      <TextField
                        id="price"
                        label={t("bulk_product.price")}
                        type="text"
                        name={`[${rowIndex}].variations.[${index}].price`}
                        color="warning"
                        defaultValue={each.price}
                        value={each.price}
                        // onChange={(e) => {
                        //    handleVariantPriceChange(params.row.id, e.target.value, each.id); formik.handleChange(e)
                        //   }}

                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9.]+$/.test(val)) &&
                            val.length <= 6
                          ) {
                            handleVariantPriceChange(
                              params.row.id,
                              e.target.value,
                              each.id
                            );
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        className=" inputFieldForSpace"
                        onKeyDown={(event) => event.stopPropagation()}
                        error={
                          formik.touched &&
                          formik.touched[rowIndex]?.variations &&
                          (
                            formik.touched?.[rowIndex]
                              ?.variations as FormikTouched<Variation[]>
                          )[index]?.price &&
                          Boolean(
                            formik.errors &&
                              formik.errors?.[rowIndex]?.variations &&
                              (
                                formik.errors?.[rowIndex]
                                  ?.variations as FormikErrors<Variation[]>
                              )[index]?.price
                          )
                        }
                        helperText={
                          formik.touched &&
                          formik.touched[rowIndex]?.variations &&
                          (
                            formik.touched?.[rowIndex]
                              ?.variations as FormikTouched<Variation[]>
                          )[index]?.price &&
                          formik.errors &&
                          formik.errors?.[rowIndex]?.variations &&
                          (
                            formik.errors?.[rowIndex]
                              ?.variations as FormikErrors<Variation[]>
                          )[index]?.price
                        }
                      />
                      {/* {formik.touched &&
                        formik.touched[rowIndex]?.variations &&
                        (
                          formik.touched?.[rowIndex]
                            ?.variations as FormikTouched<Variation[]>
                        )[index]?.price &&
                        formik.errors &&
                        formik.errors?.[rowIndex]?.variations &&
                        (
                          formik.errors?.[rowIndex]?.variations as FormikErrors<
                            Variation[]
                          >
                        )[index]?.price && (
                          <div style={{ color: "red" }}>
                            <Typography sx={{ fontSize: "0.75rem" }}>
                              {
                                (
                                  formik.errors?.[rowIndex]
                                    ?.variations as FormikErrors<Variation[]>
                                )[index]?.price
                              }
                            </Typography>
                          </div>
                        )} */}
                    </div>
                  ))}
              </div>
            )}
          </div>
        );
      },
    },

    {
      field: "category",
      //@ts-ignore
      headerName: t("bulk_product.category"),

      headerClassName: "bulk-product-column",
      cellClassName: "bulk-product-column",
      editable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return !params.row.condtion ? (
          <Box style={{ width: "100%", paddingTop: "18px" }}>
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="demo-simple-select-label">
                {t("product.select_category")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                color="warning"
                label={t("product.select_category")}
                name="category"
                value={params.value || ""}
                onChange={(e) =>
                  handlecategoryChange(params.row.id, e.target.value)
                }
                // value={formik.values.category || ""}
                // onChange={(e: SelectChangeEvent) => { handleInputChange3("category", e.target.value); formik.handleChange(e) }}
              >
                <MenuItem key="none" value="none">
                  {t("product.none")}
                </MenuItem>
                {categoryList &&
                  categoryList.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {option.name}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Typography></Typography>
        );
      },
    },

    ...(!idsFromProduct
      ? [
          {
            field: "location",
            //@ts-ignore
            headerName: t("bulk_product.location"),
            headerClassName: "bulk-product-column",
            cellClassName: "bulk-product-column",
            editable: false,
            //@ts-ignore
            hide: true,
            hideable: true,
            width: 300,
            disableColumnMenu: true,
            renderCell: (params: any) => {
              const rowIndex = bulkRows.findIndex(
                (row) => row.id === params.row.id
              );
              return (
                !params.row.condtion && (
                  <div style={{ paddingTop: "18px" }}>
                    <FormControl sx={{ width: 200 }}>
                      <InputLabel id="demo-multiple-checkbox-label">
                        {t("bulk_product.location")}{" "}
                      </InputLabel>
                      <Select
                        labelId="demo-multiple-checkbox-label"
                        id="demo-multiple-checkbox"
                        multiple
                        value={params.row.locationName}
                        name={`[${rowIndex}].locationName`}
                        onChange={(e) => {
                          handleLocationChange(
                            e,
                            params.row.id,
                            params.row.product
                          );
                          formik.handleChange(e);
                        }}
                        input={
                          <OutlinedInput label={t("bulk_product.location")} />
                        }
                        renderValue={(selected) => selected.join(", ")}
                        MenuProps={MenuProps}
                        color="warning"
                        onBlur={formik.handleBlur}
                      >
                        {locationsList.map((each) => (
                          <MenuItem key={each.storeId} value={each.storeName}>
                            <Checkbox
                              checked={
                                params.row.locationName &&
                                params.row.locationName.indexOf(
                                  each.storeName
                                ) > -1
                              }
                              color="warning"
                            />
                            <ListItemText primary={each.storeName} />
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {formik.touched &&
                      formik.touched[rowIndex]?.locationName &&
                      formik.errors &&
                      formik.errors[rowIndex]?.locationName && (
                        <div style={{ color: "red" }}>
                          <Typography sx={{ fontSize: "0.75rem" }}>
                            {formik.errors[rowIndex]?.locationName}
                          </Typography>
                        </div>
                      )}
                  </div>
                )
              );
            },
          },
        ]
      : []),

    {
      field: "soldBy",
      //@ts-ignore
      headerName: t("bulk_product.soldBy"),

      headerClassName: "bulk-product-column",
      width: 200,
      cellClassName: "bulk-product-column",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return !params.row.condtion ? (
          <Box sx={{ paddingTop: "18px", width: "100%" }}>
            <FormControl style={{ width: "90%" }}>
              <InputLabel id="demo-simple-select-label">
                {t("product.unit")}
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                color="warning"
                label={t("product.unit")}
                name="unit"
                value={params.value || "null"} // Ensure the value is not undefined
                onChange={(e) =>
                  handlesoldChange(params.row.id, e.target.value)
                }
                // value={formik.values.unit || ""}
                // onChange={(e: SelectChangeEvent) => { handleInputChange3("unit", e.target.value); formik.handleChange(e) }}
              >
                <MenuItem key="none" value="null">
                  {t("product.none")}
                </MenuItem>
                {categoryOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        ) : (
          <Typography>{params.row.soldBy}</Typography>
        );
      },
    },

    {
      field: "description",
      //@ts-ignore
      headerName: t("bulk_product.description"),
      headerClassName: "bulk-product-column",
      cellClassName: "bulk-product-column",
      editable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return !params.row.condtion ? (
          // <TextField
          //   multiline
          //   maxRows={2}
          //   placeholder='description'
          //   defaultValue={params.value}
          //   onChange={(e) => handleDescriptionChange(params.row.id, e.target.value)}
          //   color='warning'
          //   onKeyDown={(event) => { event.stopPropagation(); }}
          //   style={{ paddingTop: "18px" }}
          // />

          <div
            className="textarea-box"
            style={{ width: "90%", paddingTop: "18px" }}
          >
            <textarea
              id="textarea"
              onChange={(e) => {
                updateCharCount(e);
                handleDescriptionChange(params.row.id, e.target.value);
              }}
              style={{ width: "100%" }}
              name="description"
              value={params.value}
              onKeyDown={(event) => {
                event.stopPropagation();
              }}
              //@ts-ignore
              placeholder={t("bulk_product.enter_description")}
            >
              {params.value}
            </textarea>
            <div id="charCount" className="char-count">
              {params.value && params.value.length}/500
            </div>
          </div>
        ) : (
          <Typography></Typography>
        );
      },
    },
    {
      field: "optionsGroup",
      //@ts-ignore
      headerName: " Options/Option Groups",
      headerClassName: "bulk-product-column",
      cellClassName: "bulk-product-column",
      sortable: false,
      editable: false,
      width: 200,
      disableColumnMenu: true,
      renderCell: (params) => {
        return !params.row.condtion ? (
          <Box sx={{ paddingTop: "18px" }}>
            <Button
              onClick={() => {
                setDeleteModal(true);
                setProductId(params.row.id);
                handleOptions(params.row.id);
              }}
              style={{
                color: "orange",
                textTransform: "none",
                fontSize: "0.875rem",
                fontWeight: "400",
              }}
            >
              {t("bulk_product.add_options")}
            </Button>
          </Box>
        ) : (
          <Typography></Typography>
        );
      },
    },
  ];

  const [rowSelectionModel, setBulkRowselectionModel] =
    useState<GridRowSelectionModel>([]);

  useEffect(() => {
    // check the variants of id of product included in the rowSelectionModel and update the state
    const updatedRows = bulkRows.map((row) => {
      const allChecked =
        row.variations &&
        row.variations.every((each) => each.isChecked === true);
      return {
        ...row,
        variations:
          row.variations &&
          row.variations.map((item) => ({
            ...item,
            isChecked: allChecked
              ? rowSelectionModel.includes(row.id)
              : item.isChecked
              ? true
              : rowSelectionModel.includes(row.id),
          })),
      };
    });
    setBulkRows(updatedRows);

    const idsList = updatedRows
      .map(
        (row) =>
          row.variations &&
          row.variations
            .filter((each) => each.isChecked === true)
            .map((item) => item.id)
      )
      .flat();
    setVariantIds(idsList.filter((each) => each !== null));
  }, [rowSelectionModel]);

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state

    const updatedRows = bulkRows.filter(
      (row) => !rowSelectionModel.includes(row.id)
    );
    const updatedRows2 = updatedRows.map((row) => ({
      ...row,
      variations:
        row.variations &&
        row.variations
          .filter((item) => !variantIds.includes(item.id))
          .map((item) => ({ ...item })),
    }));

    setBulkRows(updatedRows2);
    setSkusList((prevList) =>
      prevList.filter(
        (each) => ![...rowSelectionModel, ...variantIds].includes(each.id)
      )
    );

    // Clear the selection after deletion
    setBulkRowselectionModel([]);
    setVariantIds([]);
    toast.success(
      <div
        style={{
          display: "flex",
          alignItems: "center",
          height: "8px",
          justifyContent: "center",
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z"
            fill="white"
          />
        </svg>
        <Typography>{t("toast.removed")}</Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
    formik.setTouched([]);
  };

  const handleDeselectAll = () => {
    setBulkRowselectionModel([]);
    const updatedRows = bulkRows.map((row) => {
      return {
        ...row,
        variations: row.variations.map((item) => ({
          ...item,
          isChecked: false,
        })),
      };
    });

    setBulkRows(updatedRows);
    setVariantIds([]);
  };

  const cellClass = () => {
    return "bulk-product-cell";
  };
  const rowClass = () => {
    return "bulk-product-row";
  };

  const handleAddRowClick2 = () => {
    // Create a new row with values copied from the last row in the table
    const newRows = [...bulkRows];
    const newRow: Product = {
      id: uuid(),
      sku: "",
      product: "",
      location: [],
      locationName: [],
      category: null,
      soldBy: "",
      price: "",
      optionsGroup: [],
      options: [],
      description: "",
      condtion: false,
      image: null,
      clickCount: true,
      color: null,
      mainProduct: "",
      variations: [],
      minPrice: "",
      maxPrice: "",
      skuError: false,
      duplicateCondition: false,
    };

    newRows.push(newRow);

    setBulkRows(newRows);
  };

  // const finalRows=bulkRows.filter((each)=>each.show===true)
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const [deletemodal, setDeleteModal] = useState<boolean>(false);
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const updateBulkItem =
    resultObject &&
    resultObject.map((each: any) => ({
      product_id: +each.id,
      name: each.product,
      sku: each.sku,
      category: parseInt(each.category),
      price: parseInt(each.price),
      image: each.image,
      unit: each.soldBy,

      description: each.description,
      option_groups: each.optionsGroup,
      options: each.options,
      has_variant: !!bulkRows.find((eachProduct) => eachProduct.id === each.id)
        ?.variations?.length,
      variants: each.variantObject,
      // each.variations &&
      // each.variations.map((variation) => ({
      //   name: variation.product,
      //   sku: variation.sku,
      //   additional_price: variation.price,
      //   variant_group: variation.variantGroup
      // })),
    }));

  const createBulkItem = bulkRows.map((each) => ({
    name: each.product,
    sku: each.sku,
    category: each.category === "none" ? null : each.category,
    price: each.price,
    unit: each.soldBy,
    locations: each.location,
    image: each.image,
    description: each.description,
    option_groups: each.optionsGroup,
    options: each.options,
    has_variant: each.variations?.length > 0 ? true : false,
    variants:
      each.variations &&
      each.variations.map((variation) => ({
        name: variation.product,
        sku: variation.sku,
        additional_price: variation.price,
        variant_group: variation.variantGroup,
      })),
  }));

  const [updateBulk] = useMutation(UPDATE_BULK_PRODUCTS, {
    refetchQueries: [FILTERING_PRODUCTS_LIST],
    onCompleted: (data) => {
      if (data) {
      }
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        // setCategoryStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        // setErrorModal(true)
      }
      // const errorMessage = graphqlError.message || 'An error occurred';
      // console.error(`GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`);
    },
    context: { clientName: "categoryClient" },
  });

  const [createBulk] = useMutation(CREATE_BULK, {
    onCompleted: (data) => {
      if (data) {
      }
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        // setCategoryStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        // setErrorModal(true)
      }
      // const errorMessage = graphqlError.message || 'An error occurred';
      // console.error(`GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`);
    },
    refetchQueries: [FILTERING_PRODUCTS_LIST],

    context: { clientName: "categoryClient" },
  });

  const handleUpdateBundle = () => {};
  // const customLocaleText = {
  //   noRowsLabel: 'Custom No Data Available Message',
  // };
  const CustomNoRowsOverlay = () => (
    <GridOverlay>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
        }}
      >
        <p>No Data Available</p>
        <Button
          onClick={handleAddRowClick2}
          sx={{
            textTransform: "none",
            fontSize: "16px",
            color: "#f38b08",
            border: "1px solid #f38b08",
            padding: "8px",
            mt: "12px",
          }}
        >
          {t("bulk_product.add_product")}
        </Button>
      </div>
    </GridOverlay>
  );

  return (
    <div style={{ width: "100%", padding: "1%" }}>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "1% 1% 1% 0%",
            width: "100%",
          }}
        >
          <IconButton
            className="exit-container"
            onClick={() => navigate("/product/product-list")}
          >
            <ArrowBackIcon className="exitIcon" />
          </IconButton>
          {idsFromProduct ? (
            <Typography className="bulk-product-head">
              {t("bulk_product.bulk_edit_product")}
            </Typography>
          ) : (
            <Typography className="bulk-product-head">
              {t("bulk_product.bulk_add_product")}
            </Typography>
          )}
          {/* <Typography className="bulk-product-head">{t("bulk_product.bulk_add")}</Typography> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",

              gap: "5%",
            }}
          >
            {!idsFromProduct && (
              <Button
                onClick={handleAddRowClick2}
                sx={{ textTransform: "none" }}
                className="bulk-product-button2"
              >
                {t("bulk_product.add_product")}
              </Button>
            )}
            <Button
              className="bulk-product-button"
              style={{
                textTransform: "none",
              }}
              onClick={handleUpdateBundle}
              type="submit"
            >
              {t("bulk_product.save")}
            </Button>
          </div>
        </div>
        <Box>
          {/* <div style={{width:"100%",textAlign:"right"}}>
      <Delete onClick={handleDeleteClick}/>
      </div> */}
          {/* <CustomStyles> */}
          <Box style={{ height: "70vh", width: "100%" }}>
            <DataGrid
              rows={bulkRows}
              getCellClassName={cellClass}
              getRowClassName={rowClass}
              getRowHeight={() => "auto"}
              columns={columns}
              hideFooterPagination
              rowSelectionModel={rowSelectionModel}
              checkboxSelection
              showCellVerticalBorder={true}
              showColumnVerticalBorder={true}
              disableRowSelectionOnClick
              onRowSelectionModelChange={(newRowSelectionModel) => {
                setBulkRowselectionModel(newRowSelectionModel);
              }}
              // localeText={customLocaleText}
              components={{
                NoRowsOverlay: CustomNoRowsOverlay,
              }}
              sx={{
                "&.MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                  outline: "solid #1976d2 0px !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                  {
                    outline: "solid #1976d2 0px !important",
                  },
                "& .MuiDataGrid-columnHeader": {
                  background: "#f5f5f5",
                },
              }}
            />
          </Box>
          {/* </CustomStyles> */}
          <OptionsModalInBulk
            deletemodal={deletemodal}
            handleDeleteModal={handleDeleteModal}
            handleOptionGroup={handleOptionGroup}
            handleOption={handleOption}
            optionIds={optionIds}
            optionGroupIds={optionGroupIds}
          />
          <Stack spacing={2} sx={{ width: "100%" }}>
            <Box sx={{ textAlign: "center" }}>
              <Snackbar
                sx={{ width: "50%", marginLeft: "54px", marginBottom: "-18px" }}
                open={variantIds.length !== 0 || rowSelectionModel.length > 0}
              >
                <Box
                  className="product-edit-delete-notification"
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                    padding: "8px 10px 8px 10px",
                  }}
                >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconButton>
                      <ErrorOutlineIcon color="warning" />
                    </IconButton>
                    <Typography className="product-variations-text">
                      {variantIds.length + rowSelectionModel.length}{" "}
                      {variantIds.length + rowSelectionModel.length !== 1
                        ? "Variations"
                        : "Variation"}{" "}
                      {t("bulk_product.selected")}
                    </Typography>
                    {/* <Typography className='product-variations-text' sx={{marginLeft:"20px"}}>{rowSelectionModel.length} {rowSelectionModel.length!==1?"Products":"Product"} Selected</Typography> */}
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {/* <IconButton  sx={{marginRight:"15px"}} className='product-delete-icon'>
          <Delete/>
        </IconButton> */}
                    <Button
                      className="product-deselect-all-button"
                      sx={{ textTransform: "none", marginRight: "10px" }}
                      onClick={handleDeselectAll}
                    >
                      {t("bulk_product.deselect_all")}
                    </Button>
                    <Button
                      sx={{ textTransform: "none" }}
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        handleDeleteClick();
                      }}
                    >
                      {t("bulk_product.delete")}
                    </Button>
                  </Box>
                </Box>
              </Snackbar>
            </Box>
          </Stack>
        </Box>
      </form>
    </div>
  );
}
