import React, { useState, useEffect, ChangeEvent } from 'react';
import { Paper, Typography, Tooltip, IconButton, Box, Switch, TextField, InputAdornment } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './advanceBooking.css';
import EditIcon from '@mui/icons-material/Edit';
import { GETTING_LOCATIONS_LIST } from '../../graphicalQl/usequery/locationQuery';
import SearchIcon from '@mui/icons-material/Search';




import { useMutation, useQuery } from '@apollo/client';
import { DataGrid, GridColDef, GridRowSelectionModel, GridSortModel } from '@mui/x-data-grid';
import { LOCATION_STATUS_CHANGE } from '../../graphicalQl/mutation/salesTaxMutation';
import { useTranslation } from "react-i18next";
import PopupWithTitle from '../popups/popupWithTitle';



const AdvanceBooking: React.FC = () => {
  const businessId: string = sessionStorage.getItem("businessId") as string
  const { t } = useTranslation();
  const navigate = useNavigate();
  const handleNavigate = (id: number) => {
    navigate(`/booking/${id}/update-advance-booking`);
  };

  const [searchLocation, setSearchLocation] = useState("")

  const [statusChange] = useMutation(LOCATION_STATUS_CHANGE, { context: { clientName: "businessClient" }, refetchQueries: [GETTING_LOCATIONS_LIST] })



  const [locationList, setLocationList] = useState<any>([])
  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, { context: { clientName: "businessClient" }, variables: { businessId: +businessId, storeName_Icontains: searchLocation } })
  useEffect(() => {
    if (locationsData) {
      if (locationsData.storeList) {
        const list = locationsData.storeList.edges.map((item: any) => ({
          storename: item.node.storeName,
          id: item.node.storeId,
          storeStatus: item.node.storeStatus === "Active" ? true : false,
        }));
        setLocationList(list)
      }
    }
  }, [locationsData]);
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'slugName',
      sort: 'asc',
    },
  ]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const handleRowClass = () => {
    return "dataGrid-table-row"
  }
  const handleCellClass = () => {
    return "dataGrid-table-cell"
  }
  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);

  // const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
  //   const status = event.target.checked ? "Active" : "InActive"
  //   statusChange({ variables: { storeId: +id, status: status }, refetchQueries: [GETTING_LOCATIONS_LIST] })


  // };
  const [statusId, setStatusId] = useState<number | null>(null)
  const [statusState, setStatusState] = useState<boolean>(false)


  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {

    setStatusId(id)
    setStatusState(event.target.checked)


  };

  const handleStatusCall = () => {
    const status = statusState ? "Active" : "InActive"
    statusChange({ variables: { storeId: statusId && +statusId, status: status } })
  }


  const columns: GridColDef[] = [
    {
      field: 'storename',
      //@ts-ignore
      headerName: t("settings.store_name"),
      flex: 1,
      // columnWidth: "60%",
      headerClassName: "table-column-header ",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className='dataGrid-table-cell'>{params.value}</Typography>
          </Box>
        )
      }
    },
    {
      field: "storeStatus",
      //@ts-ignore
      headerName: t("settings.status"),
      // flex: 0.5,
      width: 400,
      headerClassName: "table-column-header ",
      align: "left",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.storeStatus}
            onChange={(event) => { handleStatus(event, params.row.id); setIsModalOpen1(true) }}
            color='warning'
          />
        )
      }
    },
    {
      field: 'product',
      //@ts-ignore
      headerName: t("settings.action"),
      // flex: 0.5,
      width: 200,
      headerClassName: 'table-column-header ',
      editable: false,
      cellClassName: "tableCellInCategory",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>

            <Tooltip title="Edit" placement="top" arrow>
              <IconButton onClick={() => handleNavigate(+params.row.id)} sx={{ color: "grey", ':hover': { backgroundColor: '#EEE' } }}>
                <EditIcon sx={{ color: "grey", }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    }
  ];

  return (
    <div>
      <Paper elevation={0} className="paper-container">
        <div className="header-container">
          <Typography className='booking_title'>{t("settings.advanced_bookings")}</Typography>
        </div>

        <TextField
          label="Search Location"
          id="outlined-start-adornment"
          sx={{ m: "0px 0px 12px 24px", width: '250px' }}
          onChange={(e) => setSearchLocation(e.target.value)}
          InputProps={{
            startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
          }}
        />

        <DataGrid
          rows={locationList}
          columns={columns}
          sortModel={sortModel}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columnHeaderHeight={48}
          rowHeight={54}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[5, 15, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onSortModelChange={handleSortModelChange}
          onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          // sx={{ marginLeft: "20px" }}
          sx={{
            marginLeft: "20px",
            '&.MuiDataGrid-columnHeaderTitle': {
              fontWeight: '500',
            },
            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
              outline: 'solid #1976d2 0px !important',
            },
            '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
              outline: 'solid #1976d2 0px !important',
            },
            "& .MuiDataGrid-columnHeader": {

              background: "#f5f5f5",

            },
          }}

        />
      </Paper>
      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Menu?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => { setIsModalOpen1(false); handleStatusCall() }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
    </div>
  );
}

export default AdvanceBooking;