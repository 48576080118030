import { Box, Button, Sheet, Typography } from "@mui/joy";
import React, { useState } from "react";
import CloseIcon from "../../../../../assets/close2.png";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import axios from "axios";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface Props {
  verificationCodeModal: any;
  setVerificationCodeModal: any;
}

const UpdatePasswordModal = (props: Props) => {
  const { t } = useTranslation();
  const { verificationCodeModal, setVerificationCodeModal } = props;
  const [current, setCurrent] = useState({
    value: "",
    show: false,
  });
  const [newPassword, setNewPassword] = useState({
    value: "",
    show: false,
  });
  const [confirm, setConfirm] = useState({
    value: "",
    show: false,
  });
  const navigate = useNavigate();

  const tokenValue = sessionStorage.getItem("token");
  const changePassword = () => {
    const token = tokenValue; //sessionStorage.getItem("token");
    if (token) {
      axios
        .put(
          `https://apidomain.bayforall.co.uk/v1/user/change-password`,
          {
            current_password: current.value,
            new_password: newPassword.value,
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          // alert("Successfully changed password!");
          toast.success(
            <div>
              <Typography>{t("toast.updated")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );

          sessionStorage.removeItem("token");
          sessionStorage.removeItem("employeeId");

          sessionStorage.removeItem("refresh");
          sessionStorage.removeItem("userId");
          sessionStorage.removeItem("locationId");

          navigate("/sign-in");
        })
        .catch((error) => {
          console.log({ newPasswordChangeError: error });
          // alert("Error in changing password!");
        });
    }
  };

  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "720px",
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() => {
            setVerificationCodeModal({
              state: false,
              data: {},
            });
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <Button
            onClick={() => changePassword()}
            disabled={
              confirm.value !== newPassword.value || current.value.length === 0
            }
            sx={{
              width: "155px",
              height: "44px",
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#FEFEFE",
                textTransform: "capitalize",
              }}
            >
              {t("two_factor.Confirm")}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          marginLeft: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          {t("two_factor.update_password")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "32px",
          }}
        >
          {t("two_factor.protection_para")}
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "22px",
          rowGap: "20px",
          width: "100% !important",
        }}
      >
        <TextField
          type={current.show ? "text" : "password"}
          fullWidth
          sx={{
            width: "280px",
            height: "48px",
          }}
          label="Current Password"
          placeholder="Current Password"
          id="outlined-size-normal"
          value={current.value}
          onChange={(e) =>
            setCurrent((prev) => ({
              ...prev,
              value: e.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setCurrent((prev) => ({
                      ...prev,
                      show: !current.show,
                    }))
                  }
                >
                  {current.show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type={newPassword.show ? "text" : "password"}
          fullWidth
          sx={{
            width: "280px",
            height: "48px",
          }}
          label={t("two_factor.new_passowrd")}
          //@ts-ignore
          placeholder={t("two_factor.new_passowrd")}
          id="outlined-size-normal"
          value={newPassword.value}
          onChange={(e) =>
            setNewPassword((prev) => ({
              ...prev,
              value: e.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setNewPassword((prev) => ({
                      ...prev,
                      show: !newPassword.show,
                    }))
                  }
                >
                  {newPassword.show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <TextField
          type={confirm.show ? "text" : "password"}
          fullWidth
          sx={{
            width: "280px",
            height: "48px",
          }}
          label={t("two_factor.conform_password")}
          //@ts-ignore
          placeholder={t("two_factor.conform_password")}
          id="outlined-size-normal"
          value={confirm.value}
          onChange={(e) =>
            setConfirm((prev) => ({
              ...prev,
              value: e.target.value,
            }))
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={() =>
                    setConfirm((prev) => ({
                      ...prev,
                      show: !confirm.show,
                    }))
                  }
                >
                  {confirm.show ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {confirm.value !== newPassword.value && confirm.value.length > 0 && (
          <Typography>{t("two_factor.password_not_matched")}</Typography>
        )}
      </Box>
    </Sheet>
  );
};

export default UpdatePasswordModal;
