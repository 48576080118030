/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import TrendingUp from "../../../assests/bar-chart.png";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { EmployeeReportType } from "../../../types/employeeReports";
import { EMPLOYEE_REPORTS } from "../../../graphicalQl/usequery/employeeReport";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Employee Name", value: "employee_name" },
  { label: "Role", value: "role" },
  { label: "Orders", value: "orders" },
  { label: "Refunds", value: "refunds" },
  { label: "Tips", value: "tips" },
  { label: "Total Sales", value: "sales" },
];

const EmployeeReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Employee Name", value: "employee_name" },
    { label: "Role", value: "role" },
    { label: "Orders", value: "orders" },
    { label: "Refunds", value: "refunds" },
    { label: "Tips", value: "tips" },
    { label: "Total Sales", value: "sales" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "employee_name",
    "role",
    "orders",
    "refunds",
    "tips",
    "sales",
  ]);
  const [employeeReports, setEmployeeReports] = useState<
    Array<EmployeeReportType>
  >([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)

  // const [locationId, setLocationId] = useState<number>(44);
  const [employee, setEmployee] = useState<number>();
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { loading: employeeReportsLoading, data: employeeReportsData } =
    useQuery(
      EMPLOYEE_REPORTS(
        date.startDate,
        date.endDate,
        fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),

        reportLocation || +locationId,
        reportEmployee
      ),
      {
        fetchPolicy: "no-cache",
        context: { clientName: "userClient" },
      }
    );

  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const getEmployeeReports = () => {
    if (!employeeReportsLoading) {
      const employeeReports = employeeReportsData?.employeeReports;
      const newEmployeeReports = JSON.parse(employeeReports || "[]");
      const list = newEmployeeReports && newEmployeeReports.map((item: any) => {
        return {
          ...item,
          refunds: `${currencySymbol} ${item.refunds.toFixed(2)}`,
          tips: `${currencySymbol} ${item.tips.toFixed(2)}`,
          sales: `${currencySymbol} ${item.sales.toFixed(2)}`,
        };
      }
      );
      setEmployeeReports(list);
    }
  };

  useEffect(() => {
    getEmployeeReports();
  }, [employeeReportsLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box mt={"24px"}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={employeeReports}
          title={"Employees"}
        />
      </Box>
    </Box>
  );
};

export default EmployeeReport;
