import { Box, Typography } from "@mui/material";
// import NoData from "../../src/assets/noData.png"
import nodata from "../../src/assets/Nodata.svg";
interface State {
  height: string;
  width: string;
  heightForContainer: string;
  fontSizeForNoDataText?: string;
}
const NodataImage = (props: State) => {
  const { height, width, heightForContainer, fontSizeForNoDataText } = props;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: heightForContainer,
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img alt="nodata" width={width} height={height} src={nodata} />
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: fontSizeForNoDataText ? fontSizeForNoDataText : "16px",
            color: "#707070",
          }}
        >
          No Data Available
        </Typography>
      </Box>
    </Box>
  );
};

export default NodataImage;
