import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "../category/createCategory.css";
import { useEffect } from "react";
import { Divider } from "primereact/divider";
import { useNavigate } from "react-router";
import { t } from "i18next";
import { setPricing } from "../../store";
import { useDispatch } from "react-redux";

interface Props {
  modalState: boolean;
  handleModalState: () => void;
  handleSubmit: (e?: React.FormEvent<HTMLFormElement> | undefined) => void;
  item?: string;
  isValid: boolean;
  fromAssignOptions?: boolean;
  handleCloseFromAssign?: () => void;
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

export default function EditDailouge(props: Props) {
  const {
    modalState,
    handleModalState,
    handleSubmit,
    handleCloseFromAssign,
    isValid,
    item,
    fromAssignOptions,
  } = props;
  const [open, setOpen] = React.useState(modalState);

  const handleClose = () => {
    setOpen(false);
    handleModalState();
  };

  useEffect(() => {
    setOpen(modalState);
  }, [modalState]);

  const navigate = useNavigate();
  const handleNavigate = () => {
    if (fromAssignOptions && handleCloseFromAssign) {
      handleCloseFromAssign();
    } else {
      navigate("/option/options-list");
    }
  };

  const dispatch = useDispatch();
  const handleChanges = () => {
    if (isValid) {
      handleSubmit();
      handleModalState();
      dispatch(setPricing("noVariantChange", true));
    } else {
      handleClose();
    }
  };

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "60%",
              justifyContent: "space-between",
            }}
          >
            <Box
              className="exit-container"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleClose}
            >
              <CloseIcon className="exitIcon" />
            </Box>

            <Typography className="category-created">
              {t("menu.catageory.unsaved_changes")}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: "3% 1% 3% 1%", marginTop: "1%" }}>
            <Typography className="create_category_modal_text">
              {t("menu.catageory.unsaved_text")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2%",
            }}
          >
            {item !== "price" && (
              <Button
                className="create_category_modal_discard_button"
                onClick={() => {
                  handleClose();
                  handleNavigate();
                }}
              >
                {t("menu.catageory.discard_changes")}
              </Button>
            )}
            <Button
              className="create_category_modal_saveChanges_button"
              sx={{ marginLeft: "8%" }}
              onClick={() => {
                handleClose();
                handleChanges();
              }}
            >
              {t("menu.catageory.save_changes")}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
