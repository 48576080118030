import React, { useEffect, useState } from "react";
import {
  Modal,
  Box,
  Typography,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import { useTranslation } from "react-i18next";

export default function StatusModal(props: any) {
  const { t } = useTranslation();
  const {
    open,
    status,
    handleClose,
    handleStatusChange,
    handleSavePress,
    setActiveStepsForDropdown,
    activeSteps,
    formik,
  } = props;

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2,
  };

  const reasonsListForHidingTextfield = ["Cancelled"];

  return (
    <div style={{ zIndex: 999 }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <Typography
              id="modal-modal-title"
              variant="h6"
              component="h2"
              align="center"
              sx={{
                fontSize: "18px",
                fontWeight: "600",
                lineHeight: "27px",
              }}
            >
              {t("orders.status")}
            </Typography>
            <FormControl fullWidth margin="normal">
              <InputLabel id="status-label">{t("orders.status")}</InputLabel>
              <Select
                labelId="status-label"
                id="status-select"
                value={formik.values.status}
                label="Status"
                name="status"
                color="warning"
                onChange={handleStatusChange}
                sx={{
                  height: "48px",
                  verticalAlign: "center",
                  // paddingBottom: '10px'
                }}
              >
                {activeSteps &&
                  activeSteps.map((step: any, index: number) => {
                    return (
                      <MenuItem
                        disabled={step.value === "Dispatched"}
                        // sx={{
                        //   "&.MuiButtonBase-root-MuiMenuItem-root.Mui-selected": {
                        //     color: "#F38B08 !important",
                        //   },
                        // }}
                        key={index}
                        value={step.value}
                      >
                        {step.text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            {reasonsListForHidingTextfield.includes(formik.values.status) && (
              <Box>
                <div
                  className="textarea-box"
                  style={{ width: "100%", marginTop: "1%" }}
                >
                  <textarea
                    id="textarea"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    style={{ width: "100%" }}
                    name="reason"
                    value={formik.values.reason || ""}
                    placeholder={t("orders.enter_reason") || ""}
                  >
                    {formik.values.reason}
                  </textarea>
                  <div
                    id="charCount"
                    style={{
                      color: "#666",
                      fontSize: "12px",
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {formik.touched.reason && formik.errors.reason && (
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    )}
                    {
                      <Typography sx={{ marginLeft: "auto" }}>
                        {formik.values.reason && formik.values.reason.length}
                        /500
                      </Typography>
                    }
                  </div>
                </div>
              </Box>
            )}

            <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
              <Button
                variant="outlined"
                onClick={handleClose}
                sx={{
                  mr: 1,
                  borderRadius: "4px",
                  borderColor: "#D9D9D9",
                  color: "#707070",
                  textTransform: "none",
                  fontSize: "14px",
                  "&:hover": {
                    borderColor: "#D9D9D9",
                    backgroundColor: "rgba(255, 165, 0, 0.04)",
                  },
                  background: "transparent",
                  height: "44px",
                  fontWeight: "400",
                }}
              >
                {t("buttons.cancel")}
              </Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#F38B08",
                  textTransform: "none",
                  "&:hover": {
                    backgroundColor: "#F38B08", // Light orange background on hover
                  },
                }}
                variant="contained"
                // onClick={handleSavePress}
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
