import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Typography, IconButton } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { MoreVertSharp } from "@mui/icons-material";
import { Popover, List, ListItem, ListItemText, Divider } from "@mui/material";
import { useMutation } from "@apollo/client";
import { GET_OPTION_GROUP_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";

import { GET_OPTIONS_LIST_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";
import { DELETE_OPTION } from "../../graphicalQl/mutation/optionsMutation";

import AssignProducts from "./assignOptions";
import DeleteDailouge from "../category/deleteDailouge";
import { useTranslation } from "react-i18next";

import { DUPLICATE_OPTION } from "../../graphicalQl/mutation/optionsMutation";
// import styled from 'styled-components';
import { toast } from "react-toastify";
import CustomFooter from "../category/footer";

interface Row2 {
  id: string;
  name: string;
  value: any;
}

interface Props {
  handleSelectedData: (data: Row2[]) => void;
  optionGroupName: string;
  optionsChecked: boolean;
  inputValue: string;
  filter: string;
  deleteId: boolean;
  handleSetDelete: (value: boolean) => void;
  optionsList: Row2[];
  handleEndCursor: () => void;
  handleStartCursor: () => void;
  paginationModel: any;
  setPaginationModel: any;
  currentPage: number;
  setCurrentPage: any;
  optionData: any;
  setStartCursor: any;
  setEndCursor: any;
  loading: any;
}

function OptionsGridTable(props: Props) {
  const { t } = useTranslation();

  const {
    handleSelectedData,
    optionGroupName,
    optionsChecked,
    deleteId,
    handleSetDelete,
    optionsList,
    handleEndCursor,
    handleStartCursor,
    paginationModel,
    setPaginationModel,
    currentPage,
    setCurrentPage,
    optionData,
    setStartCursor,
    setEndCursor,
    loading,
  } = props;

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  useEffect(() => {
    setRowSelectionModel([]);
  }, [optionGroupName, optionsChecked]);

  const [checkedData, setCheckedData] = useState<Row2[]>([]);
  const handleCheckedRow = () => {
    const updatedRows = optionsList.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    setCheckedData(updatedRows);
  };

  // console.log(checkedData)

  useEffect(() => {
    handleCheckedRow();
    handleSelectedData(checkedData);
  }, [rowSelectionModel]);

  useEffect(() => {
    handleSelectedData(checkedData);
  }, [checkedData]);

  const [deleteOption] = useMutation(DELETE_OPTION, {
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTIONS_LIST_IN_OPTIONS, GET_OPTION_GROUP_IN_OPTIONS],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [duplicateOption] = useMutation(DUPLICATE_OPTION, {
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTIONS_LIST_IN_OPTIONS],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleDuplicateOption = (id: string) => {
    duplicateOption({ variables: { optionId: +id } });
  };

  // const handleDeleteOption = (optionId: string) => {

  //   deleteOption({ variables: { optionIds: +optionId }, refetchQueries: [GET_OPTIONS_LIST_IN_OPTIONS, GET_OPTION_GROUP_IN_OPTIONS] })

  // }

  const handleDleteByPopover = (id: string) => {
    deleteOption({ variables: { optionIds: JSON.stringify([+id]) } });
    setCategoryId("");
  };

  const handleRowStyle = () => {
    return "dataGrid-table-row";
  };
  function CustomPopoverCell({ categoryId }: { categoryId: string }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const navigate = useNavigate();
    const handleEdit = (id: string) => {
      navigate(`/option/${id}/update-option`);
    };
    const open = Boolean(anchorEl);
    const id = open ? categoryId : "";

    const popoverContent = (
      <List style={{ padding: "0px 0px 0px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleEdit(id)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="pover-text-style"
          >
            <ListItemText primary={t("menu.catageory.edit")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            setDeleteModal(true);
            setCategoryId(id);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="pover-text-style"
          >
            <ListItemText primary={t("menu.catageory.delete")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleDuplicateOption(id)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="pover-text-style"
          >
            <ListItemText primary="Duplicate" />
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVertSharp />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const [categoryId, setCategoryId] = useState("");
  const handleCategoryId = (category: any) => {
    setCategoryId(category);
  };

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state

    const updatedRows = optionsList.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    const optionIds = updatedRows.map((each) => +each.id);

    deleteOption({ variables: { optionIds: JSON.stringify(optionIds) } });

    setRowSelectionModel([]);

    handleSetDelete(false);
  };
  const [productIds, setProductIds] = useState<any>([]);

  const columns: GridColDef[] = [
    {
      field: "name",
      //@ts-ignore
      headerName: t("options.option"),

      flex: 1,
      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "dataGrid-table-cell-less-wt",
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Box>
            <Typography className="categories-table-text-head">
              {params.row.name}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "value",
      //@ts-ignore
      headerName: t("options.option_values"),

      flex: 1,

      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "dataGrid-table-cell-less-wt",
      headerAlign: "center",
      disableColumnMenu: true,
      align: "center",
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="dataGrid-table-cell-less-wt">
              {params.row.value && params.row.value.length}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "description",
      headerName: "",

      flex: 1,

      headerClassName: "table-column-header ",

      cellClassName: "dataGrid-table-cell-less-wt ",
      editable: false,

      align: "center",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            <Button
              className="categories-button2"
              onClick={() => {
                handleOpen();
                handleCategoryId(params.row.id);
                setProductIds(params.row.produts);
              }}
            >
              {t("menu.catageory.assignProducts")}
            </Button>
          </>
        );
      },
    },
    {
      field: "ty",
      //@ts-ignore
      headerName: t("buttons.actions"),

      flex: 1,

      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "dataGrid-table-cell-less-wt",
      headerAlign: "center",

      hideSortIcons: true,
      align: "center",
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <>
            <CustomPopoverCell categoryId={params.row.id} />
          </>
        );
      },
    },
  ];

  const [deletemodal, setDeleteModal] = useState<boolean>(false);

  const handleDeleteModal = () => {
    setDeleteModal(false);
    handleSetDelete(false);
  };

  useEffect(() => {
    setDeleteModal(deleteId);
  }, [deleteId]);

  // if (loading) return <CircularLoader />;
  // if (error) return <p>Error : {error.message}</p>;

  return (
    // <CustomStyles>

    <Box>
      <Box
        sx={{ height: "60vh", width: "100%", marginTop: "2%" }}
        // ref={containerRef}
      >
        <DataGrid
          rows={optionsList}
          columns={columns}
          getRowClassName={handleRowStyle}
          loading={loading}
          pageSizeOptions={[5, 15, 25]}
          pagination
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: paginationModel.pageSize,
              },
            },
          }}
          rowCount={optionData?.optionList?.totalCount || 0}
          paginationModel={paginationModel}
          // onPaginationModelChange={(newModel) => {

          //   if (newModel.page > currentPage) {
          //     handleEndCursor()
          //   } else if (newModel.page < currentPage) {
          //     handleStartCursor()
          //   }

          //   setCurrentPage(newModel.page); // Update the current page
          //   setPaginationModel(newModel);

          // }
          // }
          // paginationMode="server"
          onPaginationModelChange={(newModel) => {
            let shouldUpdatePaginationModel = true;

            if (newModel.pageSize !== paginationModel.pageSize) {
              setStartCursor(null);
              setEndCursor(null);
              setCurrentPage(0);
              setPaginationModel({
                ...newModel,
                page: 0,
              });
              shouldUpdatePaginationModel = false; // Prevent further updates within this call
            } else if (newModel.page > currentPage) {
              handleEndCursor();
            } else if (newModel.page < currentPage) {
              handleStartCursor();
            }

            // Update the current page

            // Only update paginationModel here if it wasn't updated above
            if (shouldUpdatePaginationModel) {
              setPaginationModel(newModel);
              setCurrentPage(newModel.page);
            }
          }}
          paginationMode="server"
          components={{
            Footer: (props) => (
              <CustomFooter
                totalRowCount={Math.ceil(
                  (optionData?.optionList?.totalCount || 0) /
                    paginationModel.pageSize
                )}
                {...props}
                page={paginationModel.page}
              />
            ),
          }}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          // onResize={handleResize}
          sx={{
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
              {
                outline: "solid #1976d2 0px !important",
              },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
            marginLeft: "20px",
            ".MuiTablePagination-displayedRows": {
              display: "none", // 👈 to hide huge pagination number
            },
          }}
        />
      </Box>

      <AssignProducts
        open={open}
        upDateOpen={handleClose}
        categoryId={categoryId}
        assigncategoryDataIds={productIds}
        setProductIds={setProductIds}
      />
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDleteByPopover={handleDleteByPopover}
        id={categoryId}
        handleDeleteModal={handleDeleteModal}
        type="option"
        handleDeleteClick={handleDeleteClick}
      />
    </Box>
    // </CustomStyles>
  );
}
export default OptionsGridTable;
