import React, { ChangeEvent, useEffect, useState } from 'react';
import { t } from 'i18next';
import { Box, Button, FormGroup, TextField, FormControlLabel, Typography, FormControl, List, ListItem } from "@mui/material"
import '../options/createProduct.css'
import Checkbox from '@mui/material/Checkbox';
import DeleteIcon from '@mui/icons-material/Delete';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Tab from '@mui/material/Tab';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import Rating from '@mui/material/Rating';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { UPDATE_PRODUCT_SETTINGS } from '../../graphicalQl/mutation/productMutation';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setsettings } from '../../store'
import { FormikErrors, useFormik } from 'formik';
import * as Yup from 'yup';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { UPDATE_PRODUCT_SETTINGS_IN_CREATION } from '../../graphicalQl/mutation/productMutation';
import { FILTERING_PRODUCTS_LIST, SETTINGS_DETAILS_IN_PRODUCT } from '../../graphicalQl/usequery/productsListQuery';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import ProductKds from './productKds';
const validationSchemaPart1 = Yup.object({
  delivery: Yup.number().typeError('* must be a number'),
  collection: Yup.number().typeError(' * must be a number'),
  shop: Yup.number().typeError('* must be a number'),
  dineIn: Yup.number().typeError('* must be a number'),
  channel1: Yup.number().typeError('* must be a number'),
  channel2: Yup.number().typeError('* must be a number'),
  channel3: Yup.number().typeError('* must be a number'),
  channel4: Yup.number().typeError('* must be a number'),
  nutritionValue: Yup.array().of(
    Yup.object().shape({
      value: Yup.number().typeError('* must be a number')
    })
  ),
  nutritionList: Yup.array().of(
    Yup.object().shape({

      value: Yup.number().typeError('* must be a number')


    })
  ),

});

interface List1 {
  id: string;
  value: string;
  isChecked: boolean
}
interface OrderType {
  isChecked: boolean;
  value: string;
  price: number | null | string;
}

interface Nutritional {
  id: number, label: string, value: string,
}


interface Props {
  handleBack: () => void; handleComplete: () => void;
}

const StyledRating = styled(Rating)({
  '& .MuiRating-iconFilled': {
    color: '#ff6d75',
  },
  '& .MuiRating-iconHover': {
    color: '#ff3d47',
  },
});

export const orderType = [{
  id: 1,
  name: "Dine In",
  isChecked: false,
  value: "dine_in",
  price: 0,
}, {
  id: 2,
  name: "Delivery",
  isChecked: false,
  value: "delivery",
  price: 0,
}, {
  id: 3,
  name: "Shop",
  isChecked: false,
  value: "shop",
  price: 0,
}, {
  id: 4,
  name: "Collection",
  isChecked: false,
  value: "collection",
  price: 0,
}]

const ProductSettings = (props: Props) => {
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            borderBottom: '2px solid #F38B08',
          },
        },
      },
    },
  });

  const { handleBack, handleComplete } = props
  const { productIdByEdit } = useSelector((state: any) => state.app);
  const { location } = useSelector((state: any) => state.app);

  const dispatch = useDispatch();
  const { ratingValue, selectedValue, dineIn, delivery, shop, collection, channel3, channel1, channel2, channel4, nutritionValue, lowCalaries, lowCarboHydrates, perValue, checkBoxList, oredrPricing, storeIds, ingredients } = useSelector((state: any) => state.settings);

  const { productId } = useSelector((state: any) => state.pricing);




  const formik = useFormik({
    initialValues: {
      dineIn: dineIn,
      delivery: delivery,
      shop: shop,
      collection: collection,
      channel1: channel1,
      channel2: channel2,
      channel3: channel3,
      channel4: channel4,
      nutritionValue: nutritionValue,

    },
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {

      if (productIdByEdit) {
        //i have two list [1,2,3] and [g,5,5] i want to merge them in one list like this "[{\"device_id\":316,\"location_id\":24},{\"device_id\":317,\"location_id\":24},{\"device_id\":338,\"location_id\":24}]" and locations list of ids
        const requriedList = location.map((each: any) => {
          storeIds.map((each1: any) => {
            return { device_id: each1, location_id: each }
          }
          )
        }
        )
        let value;
        switch (ratingValue) {
          case "1":
            value = "very_low"
            break;
          case "2":
            value = "low"
            break;
          case "3":
            value = "medium"
            break;
          case "4":
            value = "high"
            break;
          case "5":
            value = "very_high"
            break;
          default:
            value = ""
            break;
        }





        updateProductInEdit({
          variables: {
            productId: +productIdByEdit, preparationTime: JSON.stringify(preparationTime), nutritionalValues: JSON.stringify(nutritionalValuesforApi), allergens: JSON.stringify(allergens), productType: selectedValue, spiceIndicator: value,
            orderTypePriceVariable: JSON.stringify(requriedFormOfOrderList),
            kdsStations: JSON.stringify(storeIds),
            ingredients: JSON.stringify(ingredients)
          }, refetchQueries: [FILTERING_PRODUCTS_LIST], onCompleted: () => {
            handleComplete()
          }
        })

      }
      else {
        const requiredList = location.flatMap((each: any) => {
          return storeIds.map((each1: any) => {
            return { device_id: each1, location_id: each }
          })
        })
        updateProduct({
          variables: {
            productIds: JSON.stringify(productId),
            preparationTime: JSON.stringify(preparationTime),
            nutritionalValues: JSON.stringify(nutritionalValuesforApi),
            allergens: JSON.stringify(allergens),
            productType: selectedValue,
            spiceIndicator: value,
            orderTypePriceVariable: JSON.stringify(requriedFormOfOrderList),
            kdsStationsList: JSON.stringify(requiredList),
            ingredients: JSON.stringify(ingredients)
          }, refetchQueries: [FILTERING_PRODUCTS_LIST], onCompleted: () => {
            handleComplete()
          }

        })

      }

    },

  })
  const [state, setState] = useState<List1[]>(checkBoxList);




  const handleInputChange = (id: string, value: boolean) => {
    const list = state.map((each) => (
      each.id === id ? { ...each, isChecked: value } : each
    ))
    setState(list)
  }


  const [expanded2, setExpanded2] = React.useState<string | false>(false);

  const handleChange2 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded2(isExpanded ? panel : false);
    };
  const [expanded3, setExpanded3] = React.useState<string | false>(false);

  const handleChange3 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded3(isExpanded ? panel : false);
    };
  const [expanded4, setExpanded4] = React.useState<string | false>(false);

  const handleChange4 =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded4(isExpanded ? panel : false);
    };



  const [value, setValue] = React.useState("1");

  const handleChangetab = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };



  const handleAddNutrition = (nutritionValue1: string) => {
    const newItem = {
      value: "",
      id: Date.now(),
      label: nutritionValue1
    };

    setNutritionValues([...nutritionValues, newItem]);
    setAddNutrition(false)
  }

  const handleDeleteNutrition = (id: number) => {
    const updateList = nutritionValues.filter((each) => each.id !== id)
    setNutritionValues(updateList)
  }

  const [addNutritionValue, setAddNutrition] = React.useState<boolean>(false)

  const handleaddNutritionValue = () => {
    setAddNutrition(true)
    setNutritionValue("")
  }


  // const nutritionalCreatedValues = nutritionList1.map((each) => ({ [each.label]: each.value }));
  // const mergedNutritionalValues = { ...nutritionalCreatedValues.reduce((acc, obj) => ({ ...acc, ...obj }), {}) };


  const handleRemoveNutrition = () => {
    setAddNutrition(false)
  }

  const [nutritionValue1, setNutritionValue] = useState<string>("")

  const checkedAllegens = (state.filter((each) => (
    each.isChecked === true
  ))
  ).map((each) => each.value)

  const allergens = checkedAllegens

  const [updateProduct, { loading: bulkLoading }] = useMutation(UPDATE_PRODUCT_SETTINGS_IN_CREATION, { context: { clientName: 'categoryClient' } })

  const [updateProductInEdit, { loading: updateLoading }] = useMutation(UPDATE_PRODUCT_SETTINGS, { context: { clientName: 'categoryClient' } })





  const handleInputChange2 = (field: string, newValue: number | null) => {
    dispatch(setsettings(field, newValue !== null ? newValue.toString() : ""));
  };

  const handleChangeValues = (field: string, value: string | boolean) => {
    dispatch(setsettings(field, value));
  };

  const preparationTime = {
    order_type: { dine_in: dineIn, collection: collection, shop: shop, delivery: delivery },
    channel: { "channel-1": +channel1, "channel-2": +channel2, "channel-3": +channel3, "channel-4": +channel4, }
  }



  const [nutritionValues, setNutritionValues] = useState<{ id: number; label: string; value: string; }[]>(nutritionValue);

  const handleNutritionValues = (id: number, newValue: string) => {

    const list = nutritionValues.map((item: any) =>
      item.id === id ? { ...item, value: newValue } : item
    )
    setNutritionValues(list)
  };


  // const handleStoreNutritionValue = () => {
  //   dispatch(setsettings("nutritionValue", nutritionValues));
  // }

  // const handleStoreNutritionLsit = () => {
  //   dispatch(setsettings("nutritionList", nutritionList1));
  // }

  const handleStoreCheckBoxList = () => {
    dispatch(setsettings("checkBoxList", state));
  }


  const apiCallNutritions = nutritionValues.map((each) => ({
    [each.label]: each.value === "" ? null : each.value
  }))


  // const changedapiCallNutritions = { ...apiCallNutritions.reduce((acc, obj) => ({ ...acc, ...obj }), {}) };

  const nutritionalValuesforApi = { low_calorie: lowCalaries, low_carbohydrates: lowCarboHydrates, per_serving: perValue === "perServing", per_100g: perValue === "per100g", values: [...apiCallNutritions] }

  // useEffect(() => {
  //   handleStoreNutritionLsit()
  // }, [nutritionList1])

  useEffect(() => {
    dispatch(setsettings("nutritionValue", nutritionValues));
  }, [nutritionValues])

  useEffect(() => {
    handleStoreCheckBoxList()
  }, [state])

  // const { data: settingsDataFromApi } = useQuery(SETTINGS_DETAILS_IN_PRODUCT, { context: { clientName: 'categoryClient' }, variables: { productId: +productIdByEdit } })
  // useEffect(() => {
  //   if (settingsDataFromApi && settingsDataFromApi.product) {
  //     dispatch(setsettings("ratingValue", settingsDataFromApi.product.spiceIndicator));
  //     const updateCheckBoxList = checkBoxList.map((each: any) => (
  //       JSON.parse(settingsDataFromApi.product.allergens).includes(each.value) ? { ...each, isChecked: true } : each
  //     ));
  //     dispatch(setsettings("checkBoxList", updateCheckBoxList));
  //     dispatch(setsettings("selectedValue", settingsDataFromApi.product.shelfLife));
  //     dispatch(setsettings("channel1", (JSON.parse(settingsDataFromApi.product.preparationTime).channel['channel-1']).toString()));
  //     dispatch(setsettings("channel2", (JSON.parse(settingsDataFromApi.product.preparationTime).channel['channel-2']).toString()));
  //     dispatch(setsettings("channel3", (JSON.parse(settingsDataFromApi.product.preparationTime).channel['channel-3']).toString()));
  //     dispatch(setsettings("channel4", (JSON.parse(settingsDataFromApi.product.preparationTime).channel['channel-4']).toString()));
  //     dispatch(setsettings("dineIn", (JSON.parse(settingsDataFromApi.product.preparationTime).order_type.dine_in).toString()));
  //     dispatch(setsettings("delivery", (JSON.parse(settingsDataFromApi.product.preparationTime).order_type.delivery).toString()));
  //     dispatch(setsettings("shop", (JSON.parse(settingsDataFromApi.product.preparationTime).order_type.shop).toString()));
  //     dispatch(setsettings("collection", (JSON.parse(settingsDataFromApi.product.preparationTime).order_type.collection).toString()));

  //   }

  // }, [settingsDataFromApi])

  useEffect(() => {
    setState(checkBoxList)
  }, checkBoxList)

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      channel1, channel2, channel3, channel4, dineIn: dineIn,
      delivery: delivery,
      shop: shop,
      collection: collection,
    })
  }, [channel1, channel2, channel3, channel4, dineIn, delivery, shop, collection])

  const [orderTypelist, setOrderType] = useState<{
    id: number;
    name: string;
    isChecked: boolean;
    value: string;
    price: string | number;
  }[]>([{
    id: 1,
    name: "Dine In",
    isChecked: false,
    value: "dine_in",
    price: "",
  }, {
    id: 2,
    name: "Delivery",
    isChecked: false,
    value: "delivery",
    price: "",
  }, {
    id: 3,
    name: "Shop",
    isChecked: false,
    value: "shop",
    price: "",
  }, {
    id: 4,
    name: "Collection",
    isChecked: false,
    value: "collection",
    price: "",
  }])
  const handleOrderTypeChange = (id: number, value: boolean) => {
    const list = orderTypelist.map((each) => (
      each.id === id ? { ...each, isChecked: value } : each
    ))
    setOrderType(list)

    dispatch(setsettings("oredrPricing", list))
  }

  const handleOrderTypePrice = (value: string, id: number,) => {
    const list = orderTypelist.map((each) => (
      each.id === id ? { ...each, price: +value } : each
    ))
    setOrderType(list)

    dispatch(setsettings("oredrPricing", list))

  }
  const requriedFormOfOrderList = orderTypelist && orderTypelist.reduce((acc: Record<string, string | number | null>, each: OrderType) => {
    if (each.isChecked) {
      acc[each.value] = each.price;
    } else {
      acc[each.value] = null;
    }
    return acc;
  }, {});


  useEffect(() => {
    if (oredrPricing) {
      setOrderType(oredrPricing)
    }
  }, [oredrPricing]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ height: "65vh", overflowY: "scroll", padding: "1.25rem 0.75rem ", display: "flex", flexDirection: 'column', gap: "1.25rem", border: '1px solid #EEEEEE', marginBottom: "1.5rem" }}>
        <Box className='create-product-base-size-con' sx={{ padding: '0.625rem 1.5rem' }}>
          <Typography className="create-product-details">{t("product.product_settings")}</Typography>
        </Box >
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }} >
          <Box sx={{ display: "flex", alignItems: "center", width: "40%", padding: "1%" }} className="create-product-rating-con">
            <Typography className='create-product-switch-text'>{t("product.spice_indicator")}</Typography>
            <Box
              sx={{
                marginLeft: "20%",

                '& > legend': { mt: 2 },
              }}
            >

              <StyledRating
                name="customized-color"
                defaultValue={1}
                value={+ratingValue}
                onChange={(e: any, newValue: number | null) => handleInputChange2("ratingValue", newValue)}

                icon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46 46" fill="none">
                  <g clipPath="url(#clip0_5377_73402)">
                    <path d="M28.0846 10.8892L26.8577 13.6434L31.6007 26.2847L29.5607 37.0185C29.5607 37.0185 34.3213 32.9863 35.4173 27.2481C36.5133 21.5099 35.2175 16.7635 33.3278 13.7424C31.4363 10.723 30.441 10.2581 30.441 10.2581L28.0846 10.8892Z" fill="#DC0D28" />
                    <path d="M20.9834 14.8049C20.9834 14.8049 19.8891 16.2315 21.4465 19.1183C23.0039 22.0051 25.1288 23.3981 25.5601 26.8169C25.9915 30.2358 25.3621 32.3253 22.509 34.2486C18.611 36.8773 16.4332 34.2787 15.3089 35.1449C14.4462 35.8096 16.4367 38.7299 19.555 39.5254C22.6734 40.3209 26.4564 39.5926 29.1434 37.3687C31.8304 35.1449 34.7172 29.4721 33.4232 23.3981C32.1292 17.324 29.8081 14.4054 28.2171 12.6801C26.6261 10.9548 23.4706 12.3813 23.4706 12.3813L20.9834 14.8049Z" fill="#FF5117" />
                    <path d="M24.0452 16.604C23.0747 16.7295 23.0252 18.073 23.7712 19.367C24.5172 20.661 25.3639 21.5572 25.7122 22.2025C26.0604 22.8477 26.3592 23.9455 27.1812 23.721C28.0032 23.4965 27.5542 22.0045 27.0079 21.0587C26.4617 20.113 25.6132 18.918 25.3904 18.1472C25.1677 17.3765 24.8088 16.505 24.0452 16.604Z" fill="#FFCB88" />
                    <path d="M27.4534 24.725C26.6898 25.0892 27.0557 25.6884 27.1052 25.9359C27.1547 26.1834 27.3368 26.7332 27.853 26.6165C28.4275 26.4875 28.4505 25.9518 28.3656 25.5382C28.2825 25.1228 27.8883 24.5164 27.4534 24.725Z" fill="#FFCB88" />
                    <path d="M21.8619 14.7887C22.6751 14.7711 22.4912 15.7504 23.0233 15.685C23.6491 15.6072 24.084 14.0587 25.1641 13.5124C26.2442 12.9662 27.1723 13.9614 27.8352 13.4293C28.4999 12.899 27.9165 12.351 28.8464 11.6545C29.7762 10.958 31.2187 11.6368 31.5016 11.1242C31.7844 10.6115 30.2571 9.5155 28.3832 9.565C26.8895 9.60565 26.2265 9.96451 25.827 9.66399C25.4293 9.36524 24.1848 7.39241 23.5042 6.47848C22.8254 5.56631 21.6958 4.50388 20.2038 5.00239C18.71 5.49913 18.3282 6.81082 18.4448 7.25983C18.5845 7.80253 19.136 8.20912 19.6575 7.04416C20.1384 5.96582 21.3829 6.14967 21.9804 6.94517C22.5779 7.74066 23.041 9.26801 23.1259 9.98219C23.2107 10.6964 23.2761 11.0941 23.0923 11.377C22.9102 11.658 22.014 12.2891 21.3334 13.0369C20.522 13.9261 20.057 15.2431 20.5202 15.4764C20.9833 15.7098 21.2273 14.8011 21.8619 14.7887Z" fill="#98B71E" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5377_73402">
                      <rect width="32" height="32" fill="white" transform="translate(0 23) rotate(-45)" />
                    </clipPath>
                  </defs>
                </svg>}
                emptyIcon={<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 46 46" fill="none">
                  <g clipPath="url(#clip0_5377_73402)">
                    <path d="M28.0846 10.8892L26.8577 13.6434L31.6007 26.2847L29.5607 37.0185C29.5607 37.0185 34.3213 32.9863 35.4173 27.2481C36.5133 21.5099 35.2175 16.7635 33.3278 13.7424C31.4363 10.723 30.441 10.2581 30.441 10.2581L28.0846 10.8892Z" fill="#707070" />
                    <path d="M20.9834 14.8049C20.9834 14.8049 19.8891 16.2315 21.4465 19.1183C23.0039 22.0051 25.1288 23.3981 25.5601 26.8169C25.9915 30.2358 25.3621 32.3253 22.509 34.2486C18.611 36.8773 16.4332 34.2787 15.3089 35.1449C14.4462 35.8096 16.4367 38.7299 19.555 39.5254C22.6734 40.3209 26.4564 39.5926 29.1434 37.3687C31.8304 35.1449 34.7172 29.4721 33.4232 23.3981C32.1292 17.324 29.8081 14.4054 28.2171 12.6801C26.6261 10.9548 23.4706 12.3813 23.4706 12.3813L20.9834 14.8049Z" fill="#707070" />
                    <path d="M24.0452 16.604C23.0747 16.7295 23.0252 18.073 23.7712 19.367C24.5172 20.661 25.3639 21.5572 25.7122 22.2025C26.0604 22.8477 26.3592 23.9455 27.1812 23.721C28.0032 23.4965 27.5542 22.0045 27.0079 21.0587C26.4617 20.113 25.6132 18.918 25.3904 18.1472C25.1677 17.3765 24.8088 16.505 24.0452 16.604Z" fill="#707070" />
                    <path d="M27.4534 24.725C26.6898 25.0892 27.0557 25.6884 27.1052 25.9359C27.1547 26.1834 27.3368 26.7332 27.853 26.6165C28.4275 26.4875 28.4505 25.9518 28.3656 25.5382C28.2825 25.1228 27.8883 24.5164 27.4534 24.725Z" fill="#707070" />
                    <path d="M21.8619 14.7887C22.6751 14.7711 22.4912 15.7504 23.0233 15.685C23.6491 15.6072 24.084 14.0587 25.1641 13.5124C26.2442 12.9662 27.1723 13.9614 27.8352 13.4293C28.4999 12.899 27.9165 12.351 28.8464 11.6545C29.7762 10.958 31.2187 11.6368 31.5016 11.1242C31.7844 10.6115 30.2571 9.5155 28.3832 9.565C26.8895 9.60565 26.2265 9.96451 25.827 9.66399C25.4293 9.36524 24.1848 7.39241 23.5042 6.47848C22.8254 5.56631 21.6958 4.50388 20.2038 5.00239C18.71 5.49913 18.3282 6.81082 18.4448 7.25983C18.5845 7.80253 19.136 8.20912 19.6575 7.04416C20.1384 5.96582 21.3829 6.14967 21.9804 6.94517C22.5779 7.74066 23.041 9.26801 23.1259 9.98219C23.2107 10.6964 23.2761 11.0941 23.0923 11.377C22.9102 11.658 22.014 12.2891 21.3334 13.0369C20.522 13.9261 20.057 15.2431 20.5202 15.4764C20.9833 15.7098 21.2273 14.8011 21.8619 14.7887Z" fill="#707070" />
                  </g>
                  <defs>
                    <clipPath id="clip0_5377_73402">
                      <rect width="32" height="32" fill="white" transform="translate(0 23) rotate(-45)" />
                    </clipPath>
                  </defs>
                </svg>}
              />

            </Box>

          </Box>
          <FormControl>

            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedValue}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChangeValues("selectedValue", e.target.value)}
            >
              <FormControlLabel value="veg" control={<Radio color="success" />} label={<Typography className='create-product-switch-text'>{t("product.veg")}</Typography>} />
              <FormControlLabel value="non_veg" control={<Radio color="error" />} label={<Typography className='create-product-switch-text'>{t("product.non_veg")}</Typography>} />
              <FormControlLabel value="vegan" control={<Radio color="warning" />} label={<Typography className='create-product-switch-text'>{t("product.vegon")}</Typography>} />

            </RadioGroup>
          </FormControl>
        </Box>


        <Box sx={{ width: '100%', marginTop: '1px' }} className="create-product-border_style">
          <ThemeProvider theme={theme}>

            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider', backgroundColor: "#F9F9F9" }}>
                <TabContext value={value}>
                  <TabList onChange={handleChangetab} aria-label="basic tabs example">
                    <Tab
                      label={t("product.orderType")}
                      value="1"
                      className={value === "1" ? 'create-product-change_color' : 'create-product-add_color'}
                      sx={{ textTransform: "none" }}
                    />
                    <Tab
                      label={t("product.channel")}
                      value="2"
                      className={value === "2" ? 'create-product-change_color' : 'create-product-add_color'}
                      sx={{ textTransform: "none" }}
                    />
                  </TabList>
                </TabContext>
              </Box>
              <TabPanel value="1" >
                <Box>
                  <Typography className='create-product-preparation'>{t("product.preparation_time")}</Typography>
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "2%", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.dine_in")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "10%", marginRight: "1%" }}
                          name="dineIn"
                          value={dineIn}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('dineIn', e.target.value); formik.handleChange(e)
                            }
                          }
                          }
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.dineIn && formik.errors.dineIn && (
                          <div style={{ color: "red" }}>{formik.errors.dineIn as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.collection")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "5%", marginRight: "1%" }}
                          value={collection}
                          name='collection'
                          onBlur={formik.handleBlur}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('collection', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.collection && formik.errors.collection && (
                          <div style={{ color: "red" }}>{formik.errors.collection as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "2%", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.shop")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "15%", marginRight: "1%" }}
                          value={shop}
                          name='shop'
                          onBlur={formik.handleBlur}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('shop', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.shop && formik.errors.shop && (
                          <div style={{ color: "red" }}>{formik.errors.shop as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.delivery")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "5%", marginRight: "1%" }}
                          value={delivery}
                          name="delivery"
                          onBlur={formik.handleBlur}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('delivery', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.delivery && formik.errors.delivery && (
                          <div style={{ color: "red" }}>{formik.errors.delivery as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value="2" >
                <Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "2%", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', }}>
                      <Typography className='create-product-preparation'>{t("product.channel1")} </Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "10%", marginRight: "1%" }}
                          value={channel1}
                          onBlur={formik.handleBlur}
                          name="channel1"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('channel1', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.channel1 && formik.errors.channel1 && (
                          <div style={{ color: "red" }}>{formik.errors.channel1 as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.channel2")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "10%", marginRight: "1%" }}
                          value={channel2}
                          name="channel2"
                          onBlur={formik.handleBlur}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('channel2', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.channel2 && formik.errors.channel2 && (
                          <div style={{ color: "red" }}>{formik.errors.channel2 as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ display: "flex", alignItems: "center", marginTop: "2%", justifyContent: "space-between" }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.channel3")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "10%", marginRight: "1%" }}
                          value={channel3}
                          name="channel3"
                          onBlur={formik.handleBlur}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('channel3', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.channel3 && formik.errors.channel3 && (
                          <div style={{ color: "red" }}>{formik.errors.channel3 as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      <Typography className='create-product-preparation'>{t("product.channel4")}</Typography>
                      <div>
                        <TextField label={t("product.time")} color='warning' style={{ marginLeft: "10%", marginRight: "1%" }}
                          value={channel4}
                          onBlur={formik.handleBlur}
                          name="channel4"
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleChangeValues('channel4', e.target.value); formik.handleChange(e)
                            }
                          }} />
                        {formik.touched.channel4 && formik.errors.channel4 && (
                          <div style={{ color: "red" }}>{formik.errors.channel4 as string}</div>
                        )}
                      </div>
                      <Typography className='min-product'>{t("product.min")}</Typography>
                    </Box>
                  </Box>
                </Box>
              </TabPanel>
            </TabContext>
          </ThemeProvider>
        </Box>

        <Box>
        </Box>


        <Box className="product-order-con">
          <Box className="product-order-con-inner ">
            <Typography className='dataGrid-table-cell'>Select Order Type</Typography>
            <Box sx={{ display: "flex", gap: "12px", alignItems: "flex-start", marginLeft: "12px" }}>

              {orderTypelist && orderTypelist.map((each: any, index: number) => (
                <FormControlLabel
                  control={
                    <Checkbox
                      color="warning"
                      checked={each.isChecked}
                      onChange={(e) => handleOrderTypeChange(each.id, e.target.checked)}
                    />
                  }
                  label={<Typography className='heavyHeader-para'>{each.name}</Typography>}
                  className='product-checkBox-con'
                  key={each.id}
                />

              ))}
            </Box>
          </Box>
          <Box className="product-order-con-inner2">
            <Typography className='dataGrid-table-cell'>Variable Price based on Order Type</Typography>
            <Box sx={{ display: "flex", gap: "20px", alignItems: "flex-start" }}>
              {orderTypelist && orderTypelist.map((each: any, index: number) => (


                <TextField label={each.name} value={each.price} color='warning' disabled={!each.isChecked} type='text' className="hide-number-input-icons" onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const val = e.target.value;

                  if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                    handleOrderTypePrice(e.target.value, each.id)
                  }
                }} />

              ))}
            </Box>

          </Box>
        </Box>

        <ProductKds />
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ width: "45%", marginRight: "2%" }}>
            <Accordion expanded={expanded3 === 'panel3'} elevation={0} onChange={handleChange3('panel3')} style={{ width: "95%", border: "1px solid #ababab", borderRadius: "4px", }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography className='create-product-switch-text'>{t("product.nutrition")}</Typography>

              </AccordionSummary>
              <AccordionDetails >
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>
                  <FormControlLabel control={<Checkbox color='warning' checked={lowCalaries}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeValues('lowCalaries', e.target.checked)} />} label={<Typography className='create-product-check'>{t("product.low_calories")}</Typography>} />
                  <FormControlLabel control={<Checkbox color='warning' checked={lowCarboHydrates}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeValues('lowCarboHydrates', e.target.checked)} />} label={<Typography className='create-product-check' >{t("product.low_carbohydreates")}</Typography>} />

                </Box>
                <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}></Box>
                <FormControl>

                  <RadioGroup
                    row
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={perValue}
                    onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeValues('perValue', e.target.value)}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                      <FormControlLabel value="perServing" control={<Radio color='warning' />} label={<Typography className='create-product-check'>{t("product.per_serving")}</Typography>} />
                      <FormControlLabel value="per100g" control={<Radio color='warning' />} label={<Typography className='create-product-check'>{t("product.per_100g")}</Typography>} />
                    </Box>
                  </RadioGroup>
                </FormControl>
                <div>
                  {nutritionValues.map((each, index) => (
                    <div key={each.id}>
                      <div style={{ display: "flex", alignItems: 'center' }}>
                        <TextField
                          label={each.label}
                          fullWidth
                          color="warning"
                          style={{ marginTop: '2%' }}
                          onBlur={formik.handleBlur}
                          value={each.value}
                          name={`nutritionValue[${index}].value`}
                          onChange={(e) => {
                            const val = e.target.value;

                            if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                              handleNutritionValues(each.id, e.target.value);
                              // handleStoreNutritionValue();
                              formik.handleChange(e);
                            }
                          }}
                        />
                        {each.id > 8 && <DeleteIcon sx={{ color: "#ABABAB", marginLeft: "20%" }} onClick={() => { handleDeleteNutrition(each.id) }} />}
                      </div>
                      {formik.touched.nutritionValue &&
                        Array.isArray(formik.touched.nutritionValue) &&
                        formik.touched.nutritionValue[index] &&
                        (formik.touched.nutritionValue[index]).value &&
                        formik.errors.nutritionValue &&
                        Array.isArray(formik.errors.nutritionValue) &&
                        formik.errors.nutritionValue[index] &&
                        (formik.errors.nutritionValue as FormikErrors<Nutritional[]>)[index]?.value && (
                          <div style={{ color: 'red' }}>
                            {(formik.errors.nutritionValue as FormikErrors<Nutritional[]>)[index]?.value}
                          </div>
                        )}

                    </div>
                  ))}
                </div>
                {/* <List style={{ width: "100%", paddingLeft: "0px" }}>
                  {nutritionList1.map((each: any, index) => (
                    <ListItem key={each.id} style={{ width: "100%", paddingLeft: "0px" }}>
                      <TextField color='warning'
                        onBlur={formik.handleBlur}
                        value={each.value}
                        name={`nutritionList[${index}].value`}
                        style={{ marginTop: "1%", width: "80%", paddingLeft: "0px" }} label={each.label} onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleNewNutritionValue(each.id, e.target.value); handleStoreNutritionLsit(); formik.handleChange(e); }} />
                      {formik.touched.nutritionList &&
                        Array.isArray(formik.touched.nutritionList) &&
                        formik.touched.nutritionList[index] &&
                        (formik.touched.nutritionList[index]).value &&
                        formik.errors.nutritionList &&
                        Array.isArray(formik.errors.nutritionList) &&
                        formik.errors.nutritionList[index] &&
                        (formik.errors.nutritionList as FormikErrors<Nutritional[]>)[index]?.value && (
                          <div style={{ color: 'red' }}>
                            {(formik.errors.nutritionList as FormikErrors<Nutritional[]>)[index]?.value}
                          </div>
                        )}
                      <DeleteIcon sx={{ color: "#ABABAB", marginLeft: "20%" }} onClick={() => { handleDeleteNutrition(each.id) }} />
                    </ListItem>
                  ))}
                </List> */}
                {addNutritionValue && (
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>

                    <TextField label="enter nutritional value" value={nutritionValue1} color='warning' style={{ marginTop: "2%", width: "7 0%" }} onChange={(e) => {
                      setNutritionValue(e.target.value)
                    }} />
                    <Button onClick={handleRemoveNutrition} className='create-product-button-cancel'>{t("product.cancel")}</Button>
                    <Button onClick={() => { handleAddNutrition(nutritionValue1) }} className='create-product-button-add'>{t("product.add")}</Button>
                  </Box>
                )}


                <Button onClick={handleaddNutritionValue} className='create-product-addValue-button'>{t("product.add_value")}</Button>
              </AccordionDetails>
            </Accordion>
          </Box>
          <Box sx={{ width: "45%", }}>
            <Accordion expanded={expanded4 === 'panel4'} elevation={0} onChange={handleChange4('panel4')} style={{ width: "95%", border: "1px solid #ababab", borderRadius: "4px", }}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{ display: "flex", alignItems: "center" }}
              >
                <Typography className='create-product-switch-text'>{t("product.allegens")}</Typography>

              </AccordionSummary>
              <AccordionDetails >
                <List sx={{ display: "flex", alignItems: "center", flexWrap: "wrap" }}>
                  {state.map((each) => (
                    <ListItem key={each.id} sx={{ width: "45%" }}>
                      <FormGroup>
                        <FormControlLabel
                          name={each.id}
                          control={
                            <Checkbox
                              color='warning'

                              checked={each.isChecked}
                              onChange={(e: ChangeEvent<HTMLInputElement>) => { handleInputChange(each.id, e.target.checked) }}
                            />
                          }
                          label={<Typography className='create-product-switch-text'>{each.value}</Typography>}
                        />
                      </FormGroup>
                    </ListItem>
                  ))}
                </List>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>

      </Box>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon />} {t("product.back")}</Button>
        <Button className='createCategory-next-active' sx={{ marginLeft: "3px", textTransform: "none" }} type='submit' disabled={updateLoading || bulkLoading}>{t("product.Save_next")}{<ArrowForwardIcon color='warning' />}</Button>

      </Box>
    </form>
  )
}
export default ProductSettings