import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const PRODUCTS_LIST = gql`
  query productList($businessId: ID, $locationId: ID) {
    productList(businessId: $businessId, locationId: $locationId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          unit
          locations
          minQuantity
          price
          sku
          image
          slugName
          category {
            categoryId
            name
            image
          }
          description
          variants {
            variantId
            price
            name
          }
          image
          inventory {
            locationId
            variant {
              variantId
              price
              name
            }
          }
          sequence {
            sequenceName
            amount
            variant {
              variantId
            }
            product {
              productId
            }
          }
          inventoryTransactions {
            transactionId

            inventory {
              inventoryId

              locationId
              variant {
                variantId
              }
            }
          }
        }
      }
    }
  }
`;
export const CATEGORY_LIST_DROPDOWN = gql`
  query CategoryList($businessId: Int) {
    categoryList(businessId: $businessId) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          categoryId
          name
        }
      }
    }
  }
`;

export const SEARCH_QUERY_CATEGORT_LIST = gql`
  query ProductList($businessId: ID, $query: String, $locationId: ID) {
    productList(
      businessId: $businessId
      query: $query
      locationId: $locationId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name

          unit
          productStatus
          locations
          minQuantity
          sku
          price
          image
          slugName
          category {
            categoryId
            name
            image
          }
          description
          variants {
            variantId
            price
            name
          }
          image
          inventory {
            locationId
            variant {
              variantId
              price
              variantStatus
              name
            }
            stockCount
          }
          sequence {
            sequenceName
            amount
            variant {
              variantId
            }
            product {
              productId
            }
          }
          inventoryTransactions {
            transactionId
            stockCount
            inventory {
              inventoryId

              locationId
              variant {
                variantId
              }
            }
          }
        }
      }
    }
  }
`;
export const FILTERING_PRODUCTS_LIST = gql`
  query ProductList(
    $businessId: ID
    $categoryIds: JSONString
    $locationId: ID
    $query: String
    $optionGroupIds: JSONString
    $optionIds: JSONString
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    productList(
      businessId: $businessId
      categoryIds: $categoryIds
      locationId: $locationId
      query: $query
      optionGroupIds: $optionGroupIds
      optionIds: $optionIds
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          price
          productStatus
          sku

          image
          category {
            name
          }
          unit
          variants {
            variantId
            variantStatus
            price
            additionalPrice
            name
          }
        }
      }
    }
  }
`;

export const INVENTORY_PRODUCT_LIST = gql`
  query ProductList($businessId: ID, $locationId: ID, $query: String) {
    productList(
      businessId: $businessId
      locationId: $locationId
      query: $query
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          recurringSetting
          recurring
          price
          stockCount
          variantGroups
          variants {
            variantId

            price
            recurringSetting
            recurring
            stockCount
            name
          }
        }
      }
    }
  }
`;

export const PRODUCTS_LIST_IN_DISCOUNT = gql`
  query ProductList(
    $businessId: ID
    $categoryIds: JSONString
    $locationId: ID
  ) {
    productList(
      businessId: $businessId
      categoryIds: $categoryIds
      locationId: $locationId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId

          variants {
            variantId
          }
        }
      }
    }
  }
`;

export const CATEGORY_LIST_IN_PRODUCT = gql`
  query CategoryList($businessId: Int) {
    categoryList(businessId: $businessId) {
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          categoryId
          name
        }
      }
    }
  }
`;

export const SEARCH_CATEGORY_LIST_BY_OPTION_ID = gql`
  query ProductList(
    $businessId: ID
    $locationId: ID
    $optionIds: JSONString
    $optionGroupIds: JSONString
  ) {
    productList(
      businessId: $businessId
      locationId: $locationId
      optionIds: $optionIds
      optionGroupIds: $optionGroupIds
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          productStatus
          locations
          minQuantity
          sku
          price
          image
          slugName
          category {
            categoryId
            name
            image
          }
          description
          unit

          variants {
            variantId
            price
            name
          }
          image
          inventory {
            locationId
            variant {
              variantId
              price
              name
            }
            stockCount
          }
          sequence {
            sequenceName
            amount
            variant {
              variantId
            }
            product {
              productId
            }
          }
          inventoryTransactions {
            transactionId
            stockCount
            inventory {
              inventoryId

              locationId
              variant {
                variantId
              }
            }
          }
        }
      }
    }
  }
`;

export const OPTIONS_LIST = gql`
  query optionList($businessId: ID) {
    optionList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionId

          name
        }
      }
    }
  }
`;

export const OPTIONS_GROUP_LIST = gql`
  query OptionGroupList($businessId: ID) {
    optionGroupList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionGroupId
          slugName
          options
          optionObjs {
            name
            priceToDisplay
            optionId
            optionValues {
              optionValueId
              name
              price
              image
            }
          }
          name
        }
      }
    }
  }
`;

export const GENERAL_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      productId
      name
      description
      image

      isPos
      shortCode
      locations
      course {
        courseId
        name
      }
      category {
        name
        categoryId
      }
      isOnline
      unit
    }
  }
`;

export const PRICING_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      productVariants {
        edges {
          node {
            variantId
            sku
            barcode
            price
            isPreselected
            name
          }
        }
      }
    }
  }
`;

export const SEQUENCE_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      sequence {
        sequenceId
        sequenceName
        amount
        variant {
          name
          variantId
        }
      }
    }
  }
`;

export const CONFIGURATION_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      minQuantity
      maxNoOfSlices
      allowRefunds
      allowOpenPrice
      allowDiscounts
      isTrackLowStock
      isTrackStock
      lowStockAlertAt
      maxDiscountAllowed
      minDiscountAllowed
    }
  }
`;

export const ACCESSING_OPTION_GROUP_IDS_TO_THE_PRODUCT = gql`
  query OptionGroupRequiredList($businessId: ID!, $groupIds: JSONString) {
    optionGroupRequiredList(businessId: $businessId, groupIds: $groupIds) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionGroupId
          slugName
          name
          options
          optionObjs {
            optionId
            modifiers {
              modifierId
              name
            }
            optionValues {
              optionValueId
              name
              price
              image
            }
            name
          }
        }
      }
    }
  }
`;

export const ACCESSING_OPTIONS_IDS_TO_THE_PRODUCT = gql`
  query OptionRequiredList($businessId: ID!, $optionIds: JSONString) {
    optionRequiredList(businessId: $businessId, optionIds: $optionIds) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionId
          modifiers
          optionGroup
          name
          optionValues {
            optionValueId
            image
            name
            price
          }
        }
      }
    }
  }
`;
export const SETTINGS_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      productType
      spiceIndicator
      preparationTime
      nutritionalValues
      allergens
    }
  }
`;

export const ADITIONAL_DETAILS_IN_PRODUCT = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      seoKeyword
      metatagKeywords
      metatagDescription
    }
  }
`;

export const PRODUCT_DATA = gql`
  query ($productId: ID) {
    product(productId: $productId) {
      productId

      variants {
        variantId

        sku

        maxNoOfSlices
        barcode
        isPreselected
        variantGroup
        additionalPrice
        name
      }
      name
      taxIds
      isNonTaxableProduct
      orderTypePriceVariable
      description
      allowSlices
      isShopProduct
      image
      bgColourCode
      price
      sku
      barcode
      maxNoOfSlices
      isPos

      shortCode
      locationId
      course {
        courseId
        name
      }
      reportingGroup {
        name
        reportingGroupId
      }
      category {
        name
        categoryId
      }
      isOnline
      unit

      seoKeyword
      metatagKeywords
      metatagDescription

      minQuantity
      maxNoOfSlices
      allowRefunds
      allowOpenPrice
      allowDiscounts
      isTrackLowStock
      isTrackStock
      lowStockAlertAt
      maxDiscountAllowed
      minDiscountAllowed
      productType
      spiceIndicator
      preparationTime
      nutritionalValues
      allergens
      kdsStations
      ingredients
      sequence {
        sequenceId
        sequenceName
        amount
        variant {
          name
          variantId
        }
      }
    }
  }
`;

export const COURSE_LIST = gql`
  query CourseList($businessId: ID) {
    courseList(businessId: $businessId) {
      edges {
        node {
          courseId
          name
        }
      }
    }
  }
`;
export const OPTION_GROUPS_IN_PRODUCT = gql`
  query OptionValueListingInProducts($productId: Int) {
    optionValueListingInProducts(productId: $productId)
  }
`;

// export const REMOVE_OPTION_FROM

export const PRODUCT_LIST_IN_MENU = gql`
  query productList($businessId: ID, $locationId: ID) {
    productList(
      businessId: $businessId
      locationId: $locationId
      productStatus: "Active"
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          price
          course {
            courseId
            name
          }

          category {
            categoryId
            name
            image
            description
          }
          image

          variants {
            variantId
            variantGroup
            additionalPrice
            name
          }
        }
      }
    }
  }
`;

export const OVERALL_PRODUCTS = gql`
  query ProductList($businessId: ID) {
    productList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          locationId
          priceToDisplay
          name
          price
          image
          slugName
          category {
            categoryId
            image
          }
          description
          variants {
            variantId
            price
            variantStatus
            name
          }
          inventory {
            locationId
            variant {
              variantId
              variantStatus
            }
            stockCount
          }
          sequence {
            sequenceName
            amount
            variant {
              variantId
            }
            product {
              productId
            }
          }
          inventoryTransactions {
            transactionId
            stockCount
            inventory {
              inventoryId
              locationId
              variant {
                variantId
              }
            }
          }
        }
      }
    }
  }
`;

export const KDS_LIST = gql`
  query pairedDevicesList($businessId: ID, $storeId: ID) {
    pairedDevicesList(
      businessId: $businessId
      storeId: $storeId
      deviceType: "KDS"
      status: "Active"
    ) {
      edges {
        node {
          deviceName
          pairingId
          serialNumber
        }
      }
    }
  }
`;
