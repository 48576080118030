import { gql } from "@apollo/client";

export const UPLOAD_IMAGE = gql`
  mutation uploadImage(
    $businessId: Int
    $image: String!
    $imageType: String
    $customerId: Int
  ) {
    uploadImage(
      customerId: $customerId
      businessId: $businessId
      image: $image
      imageType: $imageType
    ) {
      success
      icoImageUrl
      imageUrl
    }
  }
`;

export const DELETE_IMAGE = gql`
  mutation DeleteImage($image: String!) {
    deleteImage(image: $image) {
      message
    }
  }
`;
