import { gql } from "@apollo/client";

export const TAX_METADATA_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number
) => {
  return gql`
  query TaxMetadata {
    taxMetadata(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
        )
    }
  `;
};

export const TAX_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number
) => {
  return gql`
  query TaxReports {
    taxReports(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
        )
    }
  `;
};
