import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CheackCircle from "../../../assets/check-circleTwo.png";
import CloseButton from "../../../assets/close.png";
import { useNavigate } from 'react-router';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

export default function SucessModal() {
    const [open, setOpen] = React.useState(true);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const navigate = useNavigate()
    const handleNavigation = () => {
        navigate("/sign-in")
        setOpen(false)
    }

    return (
        <div>

            <Modal
                open={open}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, textAlign: 'center' }}>
                    {/* <Box sx={{ width: '100%', textAlign: 'center' }}> */}
                    <img
                        width={"133.33px"}
                        height={"133.33px"}
                        src={CheackCircle}
                        alt="CheackCircle"
                    />
                    {/* </Box> */}
                    {/* <Button
                        sx={{
                            minWidth: { sm: "44px", xs: "36px" },
                            height: { sm: "44px", xs: "40px" },
                            backgroundColor: "#eeeeee",
                            borderRadius: "50%",
                            position: "absolute",
                            top: { sm: "12px", xs: "20px" },
                            left: { sm: "12px", xs: "30px" },
                        }}
                    >
                        <img src={CloseButton} alt="CloseButton" />
                    </Button> */}
                    <Typography
                        id="modal-modal-title"
                        variant="h6"
                        component="h2"
                        sx={{
                            fontFamily: "poppins",
                            fontSize: { sm: "28px", xs: "24px" },
                            fontWeight: "600",
                            lineHeight: { sm: "42px", xs: "36px" },
                            marginTop: "25px",
                            color: "#F38B08",
                            textAlign: "center",
                        }}
                    >
                        You have successfully bought the plan
                    </Typography>
                    <Typography
                        id="modal-modal-description"
                        sx={{
                            marginTop: "20px",
                            fontFamily: "poppins",
                            fontSize: { sm: "20px", xs: "18px" },
                            fontWeight: "500",
                            lineHeight: { sm: "30px", xs: "27px" },
                            color: "#707070",
                            textAlign: "center",
                        }}
                    >
                        You are now on Silver plan paying yearly!
                    </Typography>
                    <Box sx={{ marginTop: "64px" }}>
                        <Button
                            sx={{
                                fontFamily: "poppins",
                                fontSize: "18px",
                                fontWeight: "500",
                                lineHeight: "27px",
                                color: "#FEFEFE",
                                backgroundColor: "#F38B08",
                                paddingX: "13px",
                                paddingY: "10.5px",
                                boxShadow: "none !important",
                                textTransform: "none",
                                cursor: 'pointer',
                                '&:hover': {
                                    backgroundColor: '#F38B08'
                                }
                            }}
                            variant="contained"
                            onClick={handleNavigation}
                        >
                            Go to LogIn
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
