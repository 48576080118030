import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './createBundle.css'
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

interface Props {
    dailougeValue: boolean,
    setDailouge: any,
    setLeftItems: any,
    setGroup: any,
    value: any,
    formik: any
}

export default function BundleModal(props: Props) {
    const { t } = useTranslation();

    const { setDailouge, dailougeValue, setLeftItems, setGroup, value, formik } = props
    const handleClose = () => {
        setDailouge(false)
        if (value === "combo") {
            formik.setFieldValue("radioValue", "setMeal")
        }
        else {
            formik.setFieldValue("radioValue", "combo")
        }

    }
    const [combo, setCombo] = React.useState("")
    const [meal, setMeal] = React.useState("")

    React.useEffect(() => {
        if (value === "combo") {
            setCombo("Set Meals")
            setMeal("Combo")
        }
        else {
            setCombo("Combo")
            setMeal("Set Meals")
        }

    }, [value])

    const handleYes = () => {
        setLeftItems([])
        setGroup([{ id: '0', name: "", description: "", minimumSelect: "", maximumSelect: "", check: false, textCount: 0 },
        ])
        setDailouge(false)
    }

    return (
        <div>

            <Modal
                open={dailougeValue}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <IconButton className="exit-container" onClick={handleClose}>
                        <CloseIcon className="exitIcon" />
                    </IconButton>
                    <Divider sx={{ mt: "12px", mb: '12px' }} />
                    <Box sx={{ textAlign: "center" }}>
                        <Typography className="normalHeader">
                            {t("menu.bundle.switchBundle", { combo, meal })}
                        </Typography>
                        <Typography sx={{ marginTop: "12px" }} className='normal-text'>
                            {t("menu.bundle.modificationsInfo", { meal })}
                        </Typography>
                    </Box>
                    <Box sx={{ marginTop: '12px', textAlign: "center" }}>
                        <Button onClick={handleClose} className="modal-cancel-button">
                            {t("menu.bundle.discardChanges")}
                        </Button>
                        <Button onClick={handleYes} className="modal-yes-button" sx={{ marginLeft: '18px' }}>
                            {t("menu.bundle.continue")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}