import { gql } from "@apollo/client";

export const RECIEPT_BUILD_MUTATION = gql`
mutation CreateReceipt($businessId:ID!,$receipt:JSONString,$receiptData:JSONString,$receiptType:String,$receiptName:String,){
	createReceipt(
		businessId:$businessId,
		
		receipt:$receipt
		receiptData:$receiptData
		receiptType:$receiptType
		receiptName:$receiptName
	)
	{message
		receipt{receiptId
		receipt}
}
}
`

export const RECIEPT_UPDATE_MUTATION = gql`
mutation updateReceipt($receiptId:ID!,$receipt:JSONString,$receiptData:JSONString,$receiptType:String,$receiptName:String,$receiptStatus:String){
	updateReceipt(
		receiptId:$receiptId,
		
		receipt:$receipt
		receiptData:$receiptData
		receiptType:$receiptType
		receiptName:$receiptName
		receiptStatus:$receiptStatus
	)
	{message
		receipt{receiptId
		receipt}
}
}
`

export const DELETE_RECIEPT_MUTATION = gql`
mutation DeleteReceipts($receiptIds:JSONString!){
	deleteReceipts(
		receiptIds:$receiptIds,
		
		
	)
	{message
		receipt{receiptId
		receipt}
}
}
`

export const DUPLICATE_RECIEPT_MUTATION = gql`
mutation DuplicateReceipt($receiptId:Int!) {
	duplicateReceipt(
		receiptId:$receiptId,
	) {
		success
		errorMessage
		receipt{receiptId}
		
		
	}
		
	}
`

export const RECIEPT_DUPLICATE = gql`
mutation  DuplicateReceipt($businessId:Int, $receiptName:String!, $receiptId:Int){
duplicateReceiptNameCheck(
	businessId:$businessId,
	receiptName:$receiptName
	receiptId:$receiptId
	
	
  ) {
		message
	duplicate
		success
		}
}
`