import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import QuantityImage from "../../assets/variant images/bowls-svgrepo-com 1.png";
import SizeImage from "../../assets/variant images/two-bread-toasts-svgrepo-com 1.png";
import BaseImage from "../../assets/variant images/bread-baguette-outline-svgrepo-com 1.png";
import AddImage from "../../assets/variant images/add.png";
import "./product.css";
import PreparationImage from "../../assets/variant images/kitchen-cooker-utensils-svgrepo-com 1.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPricing } from "../../store";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute" as "absolute",
  top: 0,
  right: 0,
  // transform: 'translate(-50%, -50%)',
  width: 720,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 2,
};

interface Props {
  groupModal: boolean;
  setGroupModal: any;
  setAddQuantityModal: any;
}

export default function VariantModal(props: Props) {
  const { groupModal, setGroupModal, setAddQuantityModal } = props;

  const { variantGroup } = useSelector((state: any) => state.pricing);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [data, setData] = React.useState([
    //     {
    //     id: 1,
    //     name: "Quantity",
    //     text: "Quantity Variations like- Small, Medium, Large.. etc",
    //     image: QuantityImage
    // },
    {
      id: 2,
      name: "Size",
      text: t("product.size_para"),
      image: SizeImage,
      value: t("product.size1"),
    },

    {
      id: 3,
      name: "Base",
      text: t("product.base_para"),
      image: BaseImage,
      value: t("product.base"),
    },
    {
      id: 4,
      name: "Preparation type",
      text: t("product.preaparation_para"),
      image: PreparationImage,
      value: t("product.preaparation_type"),
    },
    // {
    //     id: 5,
    //     name: "Make your own",
    //     text: "Unable to find your preferred variation? Innovate and personalize your own.",
    //     image: AddImage
    // },
  ]);

  React.useEffect(() => {
    const list = [
      //     {
      //     id: 1,
      //     name: "Quantity",
      //     text: "Quantity Variations like- Small, Medium, Large.. etc",
      //     image: QuantityImage
      // },
      {
        id: 2,
        name: "Size",
        text: t("product.size_para"),
        image: SizeImage,
        value: t("product.size1"),
      },

      {
        id: 3,
        name: "Base",
        text: t("product.base_para"),
        image: BaseImage,
        value: t("product.base"),
      },
      {
        id: 4,
        name: "Preparation type",
        text: t("product.preaparation_para"),
        image: PreparationImage,
        value: t("product.preaparation_type"),
      },
      // {
      //     id: 5,
      //     name: "Make your own",
      //     text: "Unable to find your preferred variation? Innovate and personalize your own.",
      //     image: AddImage
      // },
    ];

    setData(list);
  }, [t]);

  const [selectedGroup, setSelectedGroup] = React.useState<string>("Size");

  const handleSelect = (id: number, value: string) => {
    setSelectedGroup(value);
    dispatch(setPricing("variantGroup", value));
  };

  const handleClose = () => {
    setGroupModal(false);
    setSelectedGroup("");
  };

  const handleNext = () => {
    setAddQuantityModal(true);
    setGroupModal(false);
    setSelectedGroup("");
  };

  return (
    <div>
      <Modal
        open={groupModal}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              backgroundColor: "var(--base-base-300, #F9F9F9)",
              padding: "12px",
            }}
          >
            <IconButton className="exit-container" onClick={handleClose}>
              <CloseIcon className="exitIcon" />
            </IconButton>
            <Typography className="main-head3">
              {t("product.add_variant_group")}
            </Typography>
            <Button className="main-button" onClick={handleNext}>
              {t("product.next")}
            </Button>
          </Box>
          <Typography
            className="normalHeader"
            sx={{ padding: "24px 12px 24px 12px" }}
          >
            {t("product.variant_para_1")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "12px",
              flexWrap: "wrap",
              gap: "12px",
            }}
          >
            {data.map((item) => (
              <Box
                key={item.id}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "45%",
                  borderRadius: "8px",
                  gap: "12px",
                }}
                className={
                  item.name === variantGroup
                    ? "variant-group-box-active"
                    : "variant-group-box"
                }
                onClick={() => handleSelect(item.id, item.name)}
              >
                <img
                  src={item.image}
                  alt={item.name}
                  style={{
                    height: "44px",
                    width: "44px",
                    backgroundColor:
                      item.name === selectedGroup ? "#fff8f0" : "#FFFFFF",
                  }}
                />{" "}
                <Box>
                  <Typography className="dataGrid-table-cell">
                    {item.value}
                  </Typography>
                  <Typography className="normal-text">{item.text}</Typography>
                </Box>
              </Box>
            ))}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
