import * as React from "react";
import Modal from "@mui/joy/Modal";
import { useState } from "react";
import ShortCutsModal from "./shortCutsModal";
import RushHourModal from "./rushHourModal";
import BlockBookingsModal from "./blockBookingsModal";


interface State{
  open:boolean;
  setOpen:React.Dispatch<React.SetStateAction<boolean>>
}
const ShortCuts = (props:State) => {
  const { open, setOpen } = props;
  const [openRushHour, setOpenRushHour] = useState<any>(false);
  const [openBlockBookings, setOpenBlockBookings] = useState<boolean>(false);

  return (
    <>
      <React.Fragment>
        <Modal
          disablePortal={true}
          aria-labelledby="close-modal-title"
          open={open}
          onClose={(event, reason) => {
            setOpen(false);
          }}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "end",
            top:{xs:'160px', md:'89px'},
            right: {xs:'20px', md: "403px", lg: "474px" },
            opacity: 1,
          }}
        >
          <ShortCutsModal
            setOpen={setOpen}
            setOpenBlockBookings={setOpenBlockBookings}
            setOpenRushHour={setOpenRushHour}
          />
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          disablePortal={true}
          aria-labelledby="close-modal-title"
          open={openRushHour}
          onClose={(event, reason) => {
            setOpenRushHour(false);
          }}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "end",
            position: "fixed",
            top: {xs:'134px',sm:"80px"},
            right: {xs:'22px !important', sm:"90px"},
            left:{xs:'22px', sm:'0px'},
            opacity: 1,
          }}
        >
          <RushHourModal setOpenRushHour={setOpenRushHour} />
        </Modal>
      </React.Fragment>

      <React.Fragment>
        <Modal
          disablePortal={true}
          aria-labelledby="close-modal-title"
          open={openBlockBookings}
          onClose={(event, reason) => {
            setOpenBlockBookings(false);
          }}
          sx={{
            display: "flex",
            alignItems: "start",
            justifyContent: "end",
            position: "fixed",
            top: {sm:"80px", xs:'134px'},
            // right: {md:"90px"},
            right:{xs:'22px', sm:'90px'},
            left:{xs:'22px', sm:'0px'},
            opacity: 1,
          }}
        >
          <BlockBookingsModal setOpenOptionsGropModal={setOpenBlockBookings} />
        </Modal>
      </React.Fragment>
    </>
  );
};

export default ShortCuts;
