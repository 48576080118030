import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { styled } from "@mui/system";
import SignUpForm from "./signUpForm";
import Verification from "./verification";
import CompneyInfo from "../compneyInfo";
import { Box } from "@mui/material";

const defaultTheme = createTheme();

const SignUpWrapper = styled("div")({
  ".butun": {
    color: "#000",
    backgroundColor: "#fff",
  },
});

export default function SignUp() {
  const [verification, setVerification] = React.useState(false);
  const [details, setDetails] = React.useState({
    mobile: "",
    email: "",
    name: ""
  })




  return (
    <ThemeProvider theme={defaultTheme}>
      <SignUpWrapper>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: '100vh'
          }}
        >
          {/* <CssBaseline /> */}

          <CompneyInfo />

          {/* {verification ? (
            <Verification details={details} verificationUser={setVerification} />
          ) : ( */}
          <SignUpForm verificationUser={setVerification} setDetails={setDetails} details={details} verification={verification} />
          {/* )} */}
        </Box>
      </SignUpWrapper>
    </ThemeProvider>
  );
}
