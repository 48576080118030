import { gql } from "@apollo/client";

export const CREATE_COURSE=gql`
mutation CreateCourse($businessId:Int!,$name:String){
    createCourse(
        businessId:$businessId,
        name:$name,
        
        
        
      ) {
            errorMessage
            course{courseId
                name
            }
            success
        }}
`

export const DELETE_COURSE=gql`
mutation DeleteCourses($courseIds:JSONString!){
    deleteCourses(
        courseIds:$courseIds,
        
        
        
        
      ) {
            message
            success
            }
      }
`

export const UPDATE_COURSE=gql`
mutation DpdateCourse($courseId:Int!,$name:String){
    updateCourse(
        courseId:$courseId,
        
        name:$name,
        
        
        
      ) {
            errorMessage
            course{courseId
                name
            }
            success
            }
      }
`