import React, { useEffect, useState, } from 'react';
import { Box, TextField, IconButton } from "@mui/material";

import '../options/createProduct.css'
import './createCategory.css'
import { useTranslation } from "react-i18next";
import Chip from '@mui/material/Chip';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { setCreateCategory } from '../reduxReducers/categoryReducer';
// import { useQuery } from '@apollo/client';
// import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import uuid from 'react-uuid';



interface Props {
  categoryId: string
}



export const CategoryAditionalDetails = ((props: Props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const { categoryId } = props
  const { metaTag, seoKeyWord, metaKeyWord } = useSelector((state: any) => state.creatCategory);


  const [chips, setChips] = useState<any[]>(seoKeyWord);
  const [newChipText, setNewChipText] = useState('');

  useEffect(() => {
    if (seoKeyWord) {
      setChips(seoKeyWord)
    }
  }, [seoKeyWord])

  const handleAddChip = () => {
    if (newChipText.trim() !== '') {
      // setChips(prevChips => {

      //   const updatedChips = [...prevChips, newChipText];
      //   dispatch(setCreateCategory("seoKeyWord", updatedChips));
      //   return updatedChips;
      // });
      const list = [...chips, { id: uuid(), name: newChipText }]
      dispatch(setCreateCategory("seoKeyWord", list));
      setChips(list)
      setNewChipText('');
    }
  };

  const handleDeleteChip = (chipToDelete: any) => {
    const list = chips.filter((chip) => chip.id !== chipToDelete)
    setChips(list);
    dispatch(setCreateCategory("seoKeyWord", list));

  };

  const [chips1, setChips1] = useState<any[]>(metaKeyWord);
  const [newChipText1, setNewChipText1] = useState('');

  useEffect(() => {
    if (metaKeyWord) {
      setChips1(metaKeyWord)
    }
  }, [metaKeyWord])

  const handleAddChip1 = () => {
    if (newChipText1.trim() !== '') {
      // setChips1(prevChips => {
      //   const updatedChips = [...prevChips, newChipText1];
      //   dispatch(setCreateCategory("metaKeyWord", updatedChips));
      //   return updatedChips;
      // });
      const list = [...chips1, { id: uuid(), name: newChipText1 }]
      dispatch(setCreateCategory("metaKeyWord", list));
      setChips1(list)
      setNewChipText1('');
    }
  };

  const handleDeleteChip1 = (chipToDelete: any) => {
    const list = chips1.filter((chip) => chip.id !== chipToDelete)
    setChips1(list);
    dispatch(setCreateCategory("metaKeyWord", list));
  };


  const [meta, setMetaTag] = useState("");




  useEffect(() => {
    setMetaTag(metaTag)
  }, [metaTag])


  // const { data: editDataValue } = useQuery(EDIT_CATEGORY, { variables: { categoryId: categoryId }, context: { clientName: 'categoryClient' } })
  // useEffect(() => {
  //   if (editDataValue) {
  //     if (editDataValue !== editData) {
  //       editDataValue.category.metatagDescription && dispatch(setCreateCategory("metaTag", editDataValue.category.metatagDescription));
  //       editDataValue.category.seoKeyword && dispatch(setCreateCategory("seoKeyWord", JSON.parse(editDataValue.category.seoKeyword).map((each: string) => {
  //         return { id: uuid(), name: each }
  //       })));
  //       editDataValue.category.metatagKeywords && dispatch(setCreateCategory("metaKeyWord", JSON.parse(editDataValue.category.metatagKeywords).map((each: string) => {
  //         return { id: uuid(), name: each }
  //       })));
  //       dispatch(setCreateCategory("editData", (editDataValue)));
  //     }




  //   }
  // }, [editDataValue]);

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };



  return (
    <Box className="visiblity-box" sx={{ marginTop: "12px", }}>

      <div style={{ display: "flex", width: "70%", alignItems: "flex-start" }} className='create-product-chip'>
        <div style={{ display: "flex", flexWrap: "wrap", width: '60%' }}>
          {chips.map((chip) => (
            <Chip
              key={chip.id}
              label={chip.name}
              sx={{ margin: "1%" }}
              onDelete={() => handleDeleteChip(chip.id)}

            />
          ))}
        </div>
        <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }}>
          <TextField
            label={t("menu.catageory.seo_keyword")}
            variant="standard"
            value={newChipText}
            onChange={(e: any) => setNewChipText(e.target.value)}
          />
          <IconButton onClick={handleAddChip} >
            <AddIcon />
          </IconButton>
        </div>
      </div>
      {/* <TextField multiline
        minRows={2}
        label={t("menu.catageory.meta_description")}
        placeholder="Meta tag description"
        name='metaTag'
        value={meta}

        onChange={(event: any) => {
          dispatch(setCreateCategory("metaTag", event.target.value));

        }}
        style={{ width: "70%", }} color="warning" /> */}

      <div className="textarea-box" style={{ width: "70%" }}>
        <textarea id="textarea" style={{ width: '100%' }} name='description'
          value={metaTag}

          onChange={(event: any) => {
            dispatch(setCreateCategory("metaTag", event.target.value));
            updateCharCount(event);
          }}

          //@ts-ignore
          placeholder={t("product.enter_description")}>
          {metaTag}</textarea>
        <div id="charCount" className="char-count" >{metaTag.length}/500</div>
      </div>
      <div style={{ display: "flex", width: "70%", alignItems: "flex-start" }} className='create-product-chip'>
        <div style={{ display: "flex", flexWrap: "wrap", width: '60%' }}>
          {chips1.map((chip) => (
            <Chip
              key={chip.id}
              label={chip.name}
              sx={{ margin: "1%" }}
              onDelete={() => handleDeleteChip1(chip.id)}

            />
          ))}
        </div>
        <div style={{ marginLeft: "auto", display: "flex", alignItems: "center" }} >
          <TextField
            label={t("menu.catageory.meta_keywords")}
            variant="standard"

            value={newChipText1}
            onChange={(e: any) => setNewChipText1(e.target.value)}
          />
          <IconButton onClick={handleAddChip1} >
            <AddIcon />
          </IconButton>
        </div>
      </div>



    </Box>
  );
});


