import { Button, Divider, Paper, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { TextField, IconButton, Box } from "@mui/material";
import "./optionsList.css";
import { useQuery, useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DeleteIcon from "@mui/icons-material/Delete";
import { GET_OPTION_GROUP_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";
import { DELETE_OPTIONGROUP_OPTION } from "../../graphicalQl/mutation/optionsMutation";
import CloseIcon from "@mui/icons-material/Close";
import { REMOVE_OPTION_FROM_OPTIONGROUP } from "../../graphicalQl/mutation/optionsMutation";
import { ADD_OPTIONS_TO_OPTIONGROUP } from "../../graphicalQl/mutation/optionsMutation";
import Popover from "@mui/material/Popover";
import DeleteDailouge from "./deleteDailougeOptionGroup";

import { UPDATE_OPTION_GROUP_NAME } from "../../graphicalQl/mutation/optionsMutation";
import { t } from "i18next";
import SimpleSnackbar from "../utils/snackBar";
import { toast } from "react-toastify";

// const styles = {
//   paper: {
//     width: '100%',
//     display: 'flex',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//   },
//   typography: {
//     color: '#333',
//     fontFamily: 'Poppins',
//     fontSize: '112.5%',
//     fontWeight: 600,
//   },
//   button: {
//     color: 'var(--base-base-100, #FEFEFE)',
//     fontFamily: 'Poppins',
//     fontSize: '112.5%',
//     fontWeight: 500,
//     display: 'inline-flex',
//     height: '25%',
//     padding: '8px 16px',
//     justifyContent: 'center',
//     alignItems: 'center',
//     flexShrink: 0,
//     borderRadius: '4px',
//     backgroundColor: '#F38B08',
//     cursor: 'pointer',
//     textTransform: "none",
//     '&:hover': {
//       backgroundColor: '#E5850B',
//     },
//   },
//   selectBox: {
//     display: 'flex',
//     padding: '0px 12px',
//     justifyContent: 'space-between',
//     alignItems: 'center',
//     flex: '1 0 0',
//     alignSelf: 'stretch',
//     borderRadius: '4px',

//   }
// };
interface Data {
  id: string;
  name: string;
  value: string;
}

interface OptionValue {
  name: string;
  optionId: string;
  isChecked: boolean;
}

interface OptionGroup {
  groupName: string;
  groupId: string;
  ids: number[];
  optionsObj: OptionValue[];
}

interface Props {
  selectedDataIds: number[];
  optionsChecked: boolean;
  setOptionsChecked: React.Dispatch<React.SetStateAction<boolean>>;
}

const OptionGroupAccordion = (props: Props) => {
  const { selectedDataIds, optionsChecked, setOptionsChecked } = props;

  // const handleChangeFilter = (event: SelectChangeEvent) => {
  //   setfilter(event.target.value);
  // };

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP_OPTION, {
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [selectedData, setSelectedData] = useState<Data[]>([]);

  // const selectedDataIds = selectedData.map((each) => +each.id);
  const [optionGroupData, setOptionGroup] = useState<OptionGroup[]>([]);
  const { data } = useQuery(GET_OPTION_GROUP_IN_OPTIONS, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  const [groupAccordinId, setGroupAccordionId] = useState<string>("");
  const handleDeleteAccordion = (accordionId: string) => {
    deleteOptionGroup({
      variables: { optionGroupId: +accordionId },
      refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
    });
  };
  useEffect(() => {
    if (data) {
      if (data.optionGroupList) {
        const formatedOptionData = data.optionGroupList.edges.map(
          (each: any) => ({
            groupName: each.node.name,
            groupId: each.node.optionGroupId,
            ids:
              each.node.optionObjs &&
              each.node.optionObjs.map((item: any) => +item.optionId),
            optionsObj:
              each.node.optionObjs &&
              each.node.optionObjs.map((item: any) => ({
                name: item.name,
                optionId: item.optionId,
                isChecked: false,
              })),
          })
        );

        setOptionGroup(formatedOptionData);
      }
    }
  }, [data]);

  const handleMouseEnter = (index: string) => {
    const hoverData = optionGroupData.map((each) => ({
      ...each,
      optionsObj:
        each.optionsObj &&
        each.optionsObj.map((item) =>
          item.optionId === index
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false }
        ),
    }));

    setOptionGroup(hoverData);
  };
  const handleMouseLeave = (index: string) => {
    const hoverData = optionGroupData.map((each) => ({
      ...each,
      optionsObj:
        each.optionsObj &&
        each.optionsObj.map((item) =>
          item.optionId === index ? { ...item, isChecked: false } : item
        ),
    }));
    setOptionGroup(hoverData);
  };

  const [removeOption] = useMutation(REMOVE_OPTION_FROM_OPTIONGROUP, {
    context: { clientName: "categoryClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handelDeleteOptionFromGroup = (optionGroupId: string, id: string) => {
    removeOption({
      variables: { optionGroupId: +optionGroupId, optionId: +id },
      refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
    });
  };

  const [addOptions] = useMutation(ADD_OPTIONS_TO_OPTIONGROUP, {
    context: { clientName: "categoryClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.added")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handelAddOptionsToOptiinGroup = (ids: any, optionGroupId: string) => {
    //write intersction of ids and idsList

    //   setSnackBar(true)
    if (ids && ids.length > 0) {
      addOptions({
        variables: {
          optionGroupId: +optionGroupId,
          optionIds: JSON.stringify(ids),
        },
        refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
      });
    }
    //   setSnackBarText("Options added to the group succesfully")
  };

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [popoverId, setPopoverId] = useState<string>("");

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setAnchorEl(event.currentTarget);
    // setPopoverId(idValue)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? popoverId : undefined;

  const [expandedAccordion, setExpandedAccordion] = useState<string | null>(
    null
  );

  const handleExpandIconClick =
    (panel: string) => (event: React.MouseEvent<{}>) => {
      event.stopPropagation();
      setExpandedAccordion((prev) => (prev === panel ? null : panel));
    };

  const [groupNameChange, setGroupNameChange] = useState<string>("");
  const handleGroupNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setGroupNameChange(event.target.value);
  };

  const [optionGroupNameChange] = useMutation(UPDATE_OPTION_GROUP_NAME, {
    context: { clientName: "categoryClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handelUpdateOptionGroup = (name: String, optionGroupId: string) => {
    optionGroupNameChange({
      variables: { optionGroupId: +optionGroupId, name: name },
      refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
    });
    setGroupNameChange("");
  };

  // const [optionsChecked, setOptionsChecked] = useState(false)
  const [deletemodal, setDeleteModal] = useState<boolean>(false);

  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  return (
    <Paper elevation={0} style={{ width: "100%", padding: "10px" }}>
      <Box
        sx={{
          padding: "1%",
          width: "100%",
          height: "100%",
          backgroundColor: "#FEFEFE",
          mt: "24px",
        }}
      >
        <Typography className="heavyHeader">
          {t("options.option_group_name")}
        </Typography>
        <Divider sx={{ backgroundColor: "##F9F9F9", mt: "12px", mb: "12px" }} />
        <Box style={{ width: "100%" }} />
        <Box style={{ overflowY: "auto", width: "100%", maxHeight: "60vh" }}>
          {optionGroupData.map((accordion, index) => (
            <Accordion
              key={accordion.groupId}
              expanded={expandedAccordion === accordion.groupId}
              // onChange={handleAccordionChange(accordion.groupId)}
            >
              <AccordionSummary
                expandIcon={
                  <IconButton
                    onClick={handleExpandIconClick(accordion.groupId)}
                    className="delete_button"
                  >
                    <ExpandMoreIcon />
                  </IconButton>
                }
                aria-controls={`panel${accordion.groupId}-content`}
                id={`panel${accordion.groupId}-header`}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography className="dataGrid-table-cell">
                    {accordion.groupName}{" "}
                  </Typography>

                  <Box>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <Box sx={{ padding: "24px" }}>
                        <Box sx={{ display: "flex", flexDirection: "column" }}>
                          <TextField
                            label="Enter Name"
                            color="warning"
                            onChange={handleGroupNameChange}
                            value={groupNameChange}
                          />
                          {groupNameChange === "" && (
                            <Typography sx={{ fontSize: "12px", color: "red" }}>
                              Enter The Value
                            </Typography>
                          )}
                        </Box>
                        <Button
                          className="option_table_button"
                          sx={{
                            marginLeft: "60%",
                            textTransform: "capitalize",
                          }}
                          onClick={() => {
                            if (id && groupNameChange !== "") {
                              handelUpdateOptionGroup(groupNameChange, id);
                              handleClose();
                            }
                          }}
                        >
                          {t("options.save")}
                        </Button>
                      </Box>
                    </Popover>

                    <Button
                      aria-describedby={id}
                      variant="contained"
                      onClick={(
                        event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                      ) => {
                        handleClick(event);
                        setPopoverId(accordion.groupId);
                        setGroupNameChange(accordion.groupName);
                      }}
                      sx={{
                        backgroundColor: "transparent",
                        color: "gray",
                        boxShadow: "none",
                        lineHeight: "0px",
                        minWidth: "0px",
                        padding: "6px",
                        borderRadius: "50%",
                        "&:hover": {
                          backgroundColor: "#FFF8F0",
                        },
                      }}
                    >
                      <EditIcon />
                    </Button>
                    <IconButton
                      onClick={() => {
                        setGroupAccordionId(accordion.groupId);
                        setDeleteModal(true);
                      }}
                      className="delete_button"
                    >
                      <DeleteIcon />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionSummary>
              <Typography className="normal-text">
                Select Options you want to add to this group
              </Typography>
              {accordion.optionsObj &&
                accordion.optionsObj.map((each, dataIndex) => (
                  <AccordionDetails key={dataIndex} sx={{ padding: "4px" }}>
                    <Box
                      onMouseEnter={() => handleMouseEnter(each.optionId)}
                      onMouseLeave={() => handleMouseLeave(each.optionId)}
                      className="options_table_row"
                    >
                      {each.name}

                      {each.isChecked && (
                        <IconButton
                          onClick={() =>
                            handelDeleteOptionFromGroup(
                              accordion.groupId,
                              each.optionId
                            )
                          }
                          className="delete_button"
                        >
                          <CloseIcon />
                        </IconButton>
                      )}
                    </Box>
                  </AccordionDetails>
                ))}
              <AccordionDetails>
                <Button
                  className="options_add_button"
                  onClick={() => {
                    handelAddOptionsToOptiinGroup(
                      selectedDataIds,
                      accordion.groupId
                    );
                    setOptionsChecked(!optionsChecked);
                  }}
                  sx={{ textTransform: "none" }}
                >
                  {t("options.add_options")}
                </Button>
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDleteByPopover={handleDeleteAccordion}
        id={groupAccordinId}
        handleDeleteModal={handleDeleteModal}
      />

      {/* <SimpleSnackbar snackBar={snackBar} setSnackBar={setSnackBar} snackBarText={snackBarText} /> */}
    </Paper>
  );
};

export default OptionGroupAccordion;
