import {
  Box,
  Button,
  FormControlLabel,
  IconButton,
  Typography,
  Checkbox,
  Divider,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import RenderGoogleMap from "../utils/googleMaps";
import AlertPopupForLastRuleDelete from "./alertPopupForLastRuleDelete.tsx";
import { useFormik } from "formik";
import * as Yup from "yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  DELIVERY_RULES_LIST,
  DEFAULT_ZONE,
} from "../../graphicalQl/usequery/deliveryManagementQuery.tsx";

import {
  ADD_DELIVERY_RULE,
  UPDATE_DELIVERY_RULE,
  DELETE_DELIVERY_RULE,
  CHANGE_DEFAULT_DELIVERY_RULE,
} from "../../graphicalQl/mutation/deliveryManagementMutation.tsx";
import { useMutation } from "@apollo/client";
import EditIcon from "@mui/icons-material/Edit";
import DoneIcon from "@mui/icons-material/Done";

import { useQuery } from "@apollo/client";
import ComfirmationPopup from "./comfirmationPopup.tsx";
import TimeRangePicker from "../popups/timeRangePicker.tsx";
import "./areaWise.css";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader.tsx";
import DeletePopup from "../popups/deletePopup.tsx";
import { useTranslation } from "react-i18next";
import NodataImage from "../../utils/nodataImage.tsx";

interface Newrow {
  id: Date;
  areaName: string | null;
  color: string | null;
  postCode: string | null;
  distance: string | null;
  cartAmount: string | null;
  shippingRate: string | null;
  extraShippingRate: string | null;
  timeTaken: string | null;
  startTime: string | null;
  endTime: string | null;
  isEdited: boolean;
}

interface Location {
  lat: number;
  lng: number;
}

interface Areawise {
  deliverySettingId: string | null;
  updatedDefaultZone: string | null;
  updatedDefaultZoneForStar: string | null;
  editId: undefined | string;
  storeLocation: Location | null;
  editRowId: string | null;
  setEditRowId: React.Dispatch<React.SetStateAction<string | null>>;
  locationKey: string | null;
}
function AreaWise(props: Areawise) {
  const {
    deliverySettingId,
    locationKey,
    setEditRowId,
    editRowId,
    updatedDefaultZone,

    editId,
    storeLocation,
  } = props;

  const { t } = useTranslation();

  const [addingDeliveryRule] = useMutation(ADD_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { addDeliveryRule } = data;
        const { success } = addDeliveryRule;
        if (success) {
          setActiveDistanceButton(false);
          setHandleAddButton(false);
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [updateDeliveryRule] = useMutation(UPDATE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { editDeliveryRule } = data;
        const { success } = editDeliveryRule;
        if (success) {
          setActiveDistanceButton(false);
          setHandleAddButton(false);
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [deleteDeliveryRule] = useMutation(DELETE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [changeDefaultDeliveryRule] = useMutation(
    CHANGE_DEFAULT_DELIVERY_RULE,
    {
      onCompleted: (data) => {
        if (data) {
          const { addDeliveryRule } = data;
          const { success } = addDeliveryRule;
          if (success) {
            setHandleAddButton(true);
            // setNewRowCheckboxValue(initialAddTimeRow)
            // setActiveDistanceButton(false)
            // setHandleAddButton(false)
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "businessClient" },
      refetchQueries: [DEFAULT_ZONE],
    }
  );

  const {
    loading: deliveryRulesListLoading,
    error: deliveryRulesListError,
    data: deliveryRulesListData,
  } = useQuery(DELIVERY_RULES_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      settingId: deliverySettingId && +deliverySettingId,
      distanceOrder: true,
    },
  });
  useEffect(() => {
    if (deliveryRulesListData) {
      const { deliveryRules } = deliveryRulesListData;
      const { edges } = deliveryRules;
      const filteredData =
        edges &&
        edges.length > 0 &&
        edges.map((eachRule: any) => ({
          areaName: eachRule.node.areaName,
          cartAmount: eachRule.node.cartAmount,
          color: eachRule.node.colourCode,
          deliveryRuleId: eachRule.node.deliveryRuleId,
          deliveryTime: JSON.parse(eachRule.node.deliveryTime),
          startTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["from_time"],
          endTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["to_time"],
          distance: eachRule.node.distance,
          extraShippingRate: eachRule.node.extraShippingRate,
          postCode: eachRule.node.postCode,
          postCodesForZone: eachRule.node.postCodesForZone,
          shippingRate: eachRule.node.shippingRate,
          timeTaken: eachRule.node.timeTaken,
          zoneName: eachRule.node.zoneName,
          zoneShape: eachRule.node.zoneShape,
          zoneType: eachRule.node.zoneType,
          isEdited: false,
          isNew: false,
        }));
      const filteredAreaList = filteredData
        ? filteredData.filter((eachZone: any) => eachZone.zoneType === "area")
        : [];
      setRows(filteredAreaList);
    }
  }, [deliveryRulesListData]);
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const [handleAddButton, setHandleAddButton] = useState<boolean>(false);
  const [activeDistanceButton, setActiveDistanceButton] = useState(false);
  const [checkboxOpen, setCheckboxOpen] = useState<boolean>(false);
  const [handleTime, setHandleTime] = useState<boolean>(false);
  const [deltedId, setDeletedId] = useState<string | null>(null);
  const [rows, setRows] = useState<any[]>([]);
  const initialValues = {
    startTime: null,
    endTime: null,
  };

  const validation = Yup.object({
    startTime: Yup.string().required("Start Time Required"),
    endTime: Yup.string()
      .test(
        "is-greater",
        "End time must be greater than start time",
        function (value) {
          const { startTime } = this.parent; // Access the values of other fields
          if (startTime && value) {
            return startTime <= value;
          }
          return true; // Return true if either field is empty
        }
      )
      .required("End Time Required"),
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validation,
    onSubmit: (values) => {
      handlePopupTimeDoneButton();
    },
  });

  const [alertPopupForLastRuleDelete, setAlertPopupForLastRuleDelete] =
    useState<boolean>(false);
  const handleCloseForLastDeleteRule = () => {
    setAlertPopupForLastRuleDelete(false);
  };

  const handleCheckboxPopupOpen = () => {
    setCheckboxOpen(true);
  };

  const handleCheckboxPopupClose = () => {
    setCheckboxOpen(false);
    setCheckkboxState(false);
  };

  const validationForNewRow = Yup.object({
    areaName: Yup.string().required("Required"),
    postCode: Yup.string().required("Required"),
    distance: Yup.string().required("Required"),
    cartAmount: Yup.string().required("Required"),
    shippingRate: Yup.string().required("Required"),
  });

  const validationForNewRowForEdit = Yup.object({
    areaName: Yup.string().required("Required"),
    postCode: Yup.string().required("Required"),
    distance: Yup.string().required("Required"),
    cartAmount: Yup.string().required("Required"),
    shippingRate: Yup.string().required("Required"),
  });

  const initialNewRow: Newrow = {
    id: new Date(),
    areaName: null,
    postCode: null,
    color: "#333333",
    distance: null,
    cartAmount: null,
    shippingRate: null,
    extraShippingRate: null,
    timeTaken: null,
    startTime: null,
    endTime: null,
    isEdited: false,
  };

  const formikForDeliveryRule = useFormik({
    initialValues: initialNewRow,
    validationSchema: validationForNewRow,
    onSubmit: (values) => {
      handleCreateSaveButton();
    },
  });

  const formikForDeliveryRuleForEdit = useFormik({
    initialValues: initialNewRow,
    validationSchema: validationForNewRowForEdit,
    onSubmit: (values) => {
      if (editRowId) {
        handleEditSaveButton();
      }
    },
  });

  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);

  const [checkkboxState, setCheckkboxState] = useState<boolean>(false);

  //time range picker functions

  const [timePickerOpen, setTimePickerOpen] = React.useState(false);
  const handleTimePickerOpen = () => setTimePickerOpen(true);
  const handleTimePickerClose = () => {
    setTimePickerOpen(false);
    formik.resetForm();
  };

  const combineOpenAndPassingData = (id: string) => {
    if (rows.length === 1) {
      setAlertPopupForLastRuleDelete(true);
      setDeletedId(null);
    } else {
      setDeletePopupOpen(true);
      setDeletedId(id);
    }
  };

  const handleSetTimeButton = () => {
    setTimePickerOpen(true);
    formik.setFieldValue("startTime", formikForDeliveryRule.values.startTime);
    formik.setFieldValue("endTime", formikForDeliveryRule.values.endTime);
  };

  const handleCreateSaveButton = () => {
    const variables = {
      deliverySetting: deliverySettingId && +deliverySettingId,
      postCode: formikForDeliveryRule.values.postCode,
      shippingRate: formikForDeliveryRule.values.shippingRate
        ? +formikForDeliveryRule.values.shippingRate
        : null,
      areaName: formikForDeliveryRule.values.areaName,
      distance: formikForDeliveryRule.values.distance
        ? +formikForDeliveryRule.values.distance
        : null,
      deliveryTime:
        formikForDeliveryRule.values.startTime &&
        formikForDeliveryRule.values.endTime
          ? JSON.stringify({
              from_time: formikForDeliveryRule.values.startTime,
              to_time: formikForDeliveryRule.values.endTime,
            })
          : null,
      cartAmount: formikForDeliveryRule.values.cartAmount
        ? +formikForDeliveryRule.values.cartAmount
        : null,
      colourCode: formikForDeliveryRule.values.color,
      extraShippingRate: formikForDeliveryRule.values.extraShippingRate
        ? +formikForDeliveryRule.values.extraShippingRate
        : null,
      timeTaken: formikForDeliveryRule.values.timeTaken
        ? +formikForDeliveryRule.values.timeTaken
        : null,
      zoneType: "area",
      isDefault: updatedDefaultZone === "area" ? true : false,
    };

    const filteredVariables = Object.fromEntries(
      Object.entries(variables).filter(([_, value]) => value !== null)
    );
    setHandleTime(false);
    setActiveDistanceButton(false);
    addingDeliveryRule({ variables: filteredVariables });

    toast.success(
      <div>
        <Typography>{t("toast.created")}</Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
    // formik.resetForm();
    formikForDeliveryRule.resetForm();
  };

  const handleEditSaveButton = () => {
    setHandleTime(false);

    const variables = {
      deliveryRule:
        formikForDeliveryRuleForEdit.values.id &&
        +formikForDeliveryRuleForEdit.values.id,
      postCode: formikForDeliveryRuleForEdit.values.postCode,
      shippingRate: formikForDeliveryRuleForEdit.values.shippingRate
        ? +formikForDeliveryRuleForEdit.values.shippingRate
        : null,
      areaName: formikForDeliveryRuleForEdit.values.areaName,
      distance: formikForDeliveryRuleForEdit.values.distance
        ? +formikForDeliveryRuleForEdit.values.distance
        : null,
      deliveryTime:
        formikForDeliveryRuleForEdit.values.startTime &&
        formikForDeliveryRuleForEdit.values.endTime
          ? JSON.stringify({
              from_time: formikForDeliveryRuleForEdit.values.startTime,
              to_time: formikForDeliveryRuleForEdit.values.endTime,
            })
          : null,
      cartAmount: formikForDeliveryRuleForEdit.values.cartAmount
        ? +formikForDeliveryRuleForEdit.values.cartAmount
        : null,
      colourCode: formikForDeliveryRuleForEdit.values.color,
      extraShippingRate: formikForDeliveryRuleForEdit.values.extraShippingRate
        ? +formikForDeliveryRuleForEdit.values.extraShippingRate
        : null,
      timeTaken: formikForDeliveryRuleForEdit.values.timeTaken
        ? +formikForDeliveryRuleForEdit.values.timeTaken
        : null,
      zoneType: "area",
    };

    updateDeliveryRule({ variables: variables });

    toast.success(
      <div>
        <Typography>{t("toast.updated")}</Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
    formikForDeliveryRule.resetForm();
    // formik.resetForm();
    const filtereddata = rows.map((each: any) => {
      return { ...each, isEdited: false };
    });
    setRows(filtereddata);
  };

  const handleEditIcon = (id: string, row: any, e: any) => {
    e.preventDefault();
    setActiveDistanceButton(false);
    const filteredRows = rows.map((eachRow: any) => {
      if (eachRow.deliveryRuleId === id) {
        return { ...eachRow, isEdited: true };
      } else {
        return { ...eachRow, isEdited: false };
      }
    });
    setRows(filteredRows);
    formikForDeliveryRuleForEdit.setFieldValue("id", row.deliveryRuleId);
    formikForDeliveryRuleForEdit.setFieldValue("areaName", row.areaName);
    formikForDeliveryRuleForEdit.setFieldValue("color", row.color);
    formikForDeliveryRuleForEdit.setFieldValue("postCode", row.postCode);
    formikForDeliveryRuleForEdit.setFieldValue("distance", row.distance);
    formikForDeliveryRuleForEdit.setFieldValue("cartAmount", row.cartAmount);
    formikForDeliveryRuleForEdit.setFieldValue("startTime", row.startTime);
    formikForDeliveryRuleForEdit.setFieldValue("endTime", row.endTime);
    formikForDeliveryRuleForEdit.setFieldValue(
      "shippingRate",
      row.shippingRate
    );
    formikForDeliveryRuleForEdit.setFieldValue(
      "extraShippingRate",
      row.extraShippingRate
    );
    formikForDeliveryRuleForEdit.setFieldValue(
      "startTime",
      row.deliveryTime?.from_time
    );
    formikForDeliveryRuleForEdit.setFieldValue(
      "endTime",
      row.deliveryTime?.to_time
    );

    formikForDeliveryRuleForEdit.setFieldValue("timeTaken", row.timeTaken);
    setHandleAddButton(true);
    setEditRowId(id);
  };

  const handleDontRemoveButton = () => {
    setDeletedId(null);
    setDeletePopupOpen(false);
  };

  const [showCloseIcon, setShowCloseIcon] = React.useState(
    rows.length > 0 ? rows.map((each: any) => false) : []
  );
  const handleMouseEnter = (index: number) => {
    const updatedShowData =
      rows.length > 0 ? rows.map((each: any) => false) : [];
    updatedShowData[index] = true;
    setShowCloseIcon(updatedShowData);
  };

  const handleMouseLeave = (index: number) => {
    const updatedShowData = [...showCloseIcon];
    updatedShowData[index] = false;
    setShowCloseIcon(updatedShowData);
  };

  const combineDeleteRowAndPopupClose = () => {
    deleteDeliveryRule({
      variables: {
        deliveryRule: deltedId && +deltedId,
      },
    });
    setDeletePopupOpen(false);
    setDeletedId(null);
  };

  const handlePopupTimeDoneButton = () => {
    setHandleTime(true);
    setTimePickerOpen(false);
    if (editRowId) {
      const filtredRow = rows.find((row) => row.deliveryRuleId === editRowId);
      if (filtredRow) {
        const updatedRow = {
          ...filtredRow,
          startTime: formik.values.startTime,
          endTime: formik.values.endTime,
        };
        const indexOfExistingRow = rows.indexOf(filtredRow);
        rows[indexOfExistingRow] = updatedRow;
        setRows(rows);
        setTimePickerOpen(false);
        formikForDeliveryRuleForEdit.setFieldValue(
          "startTime",
          formik.values.startTime
        );
        formikForDeliveryRuleForEdit.setFieldValue(
          "endTime",
          formik.values.endTime
        );
      }
    } else {
      formikForDeliveryRule.setFieldValue("startTime", formik.values.startTime);
      formikForDeliveryRule.setFieldValue("endTime", formik.values.endTime);
    }
  };

  const handleOnDistanceButton = () => {
    setActiveDistanceButton(true);
    setHandleAddButton(true);
  };

  const handleCloseIconCancelButton = (id: string) => {
    const filteredRows = rows.map((eachRow: any) => {
      if (eachRow.id === id) {
        return { ...eachRow, isEdited: false };
      } else {
        return { ...eachRow, isEdited: false };
      }
    });
    setRows(filteredRows);
    setHandleAddButton(false);
    // formik.resetForm();
    formikForDeliveryRule.resetForm();
  };

  const handleAddButtonCloseIcon = () => {
    setActiveDistanceButton(false);
    formikForDeliveryRule.resetForm();
    setHandleAddButton(false);
    // formik.resetForm();
  };
  const hadleCheckboxForDefault = (isChecked: boolean) => {
    if (isChecked) {
      setCheckboxOpen(true);
    }
    setCheckkboxState(isChecked);
  };

  const handleSaveButton = () => {
    changeDefaultDeliveryRule({
      variables: {
        deliverySetting: deliverySettingId && +deliverySettingId,
        zoneType: "area",
        isDefault: true,
        locationId: editId && +editId,
      },
    });
    handleCheckboxPopupClose();
  };

  const handleEditTime = (id: string) => {
    const filteredData = rows.filter((each: any) => each.deliveryRuleId === id);
    const time = {
      startTime: filteredData[0].startTime,
      endTime: filteredData[0].endTime,
    };
    formik.setValues(time);
    setTimePickerOpen(true);
  };

  const imperialCountries: string[] = ["US", "LR", "MM", "GB"];

  function getDistanceUnit(countryCode: string): string {
    if (imperialCountries.includes(countryCode.toUpperCase())) {
      return t("buttons.miles"); // Imperial units (miles)
    }

    // Default to metric units (kilometers)
    return t("buttons.km");
  }

  // Example usage

  const countryCode: string | null = sessionStorage.getItem("country");
  const distanceUnit: string = getDistanceUnit(
    countryCode === null ? "" : countryCode
  );


  return (
    <Box sx={{ padding: "15px" }}>
      <AlertPopupForLastRuleDelete
        open={alertPopupForLastRuleDelete}
        handleClose={handleCloseForLastDeleteRule}
      />
      <TimeRangePicker
        formik={formik}
        open={timePickerOpen}
        handleClose={handleTimePickerClose}
      />

      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleDontRemoveButton}
        handleOpen={() => setDeletePopupOpen(true)}
        handleSaveButton={combineDeleteRowAndPopupClose}
        mainText={`${t("buttons.are_you_sure_delete")} ${t("deliveries.rule")}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />

      <Box sx={{ display: "flex", alignItems: "center" }}>
        <FormControlLabel
          control={
            <Checkbox
              checked={updatedDefaultZone === "area"}
              onChange={(e) => hadleCheckboxForDefault(e.target.checked)}
              color="warning"
              sx={{ "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
            />
          }
          label={t("deliveries.make_this_as_default")}
        />
      </Box>
      <ComfirmationPopup
        accessText={t("deliveries.area_wise")}
        handleSaveButton={handleSaveButton}
        open={checkboxOpen}
        handleClose={handleCheckboxPopupClose}
        handleOpen={handleCheckboxPopupOpen}
      />
      {deliveryRulesListLoading ? (
        <CircularLoader />
      ) : (
        <>
          <Box
            sx={{ borderRadius: "5px", width: "100%" }}
            className="mapContainer"
            id="map-canvas"
          >
            {/* <LocationIdentifier /> */}
            <RenderGoogleMap
              locationKey={locationKey}
              storeLocation={storeLocation}
              setRows={setRows}
              rows={rows.length > 0 ? rows : []}
            />
          </Box>
          <Box
            sx={{
              marginTop: "10px",
            }}
          >
            <TableContainer
              sx={{
                padding: "2px",
                border: "2px solid #eee",
                minHeight: "20vh",
                maxHeight: "70vh", // You can adjust this height as needed
                overflowY: "auto",
                width: "100%",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
              component={Paper}
              elevation={0}
            >
              <form onSubmit={formikForDeliveryRuleForEdit.handleSubmit}>
                {/* <TableHead
                    sx={{
                      position: "sticky",
                      top: 0,
                      backgroundColor: "#f5f5f5",
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{ flex: 3 }}
                        className="area-wise-table-column"
                      >
                        {t("deliveries.area_name")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                      >
                        {t("deliveries.color")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                      >
                        {t("deliveries.postcode")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.distance")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.cart_amount")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.shipping_rate")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 0.5 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.extra_shipping_for_less_cart_amt")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 1 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.time_taken_for_delivery")}
                      </TableCell>
                      <TableCell
                        sx={{ flex: 2 }}
                        className="area-wise-table-column"
                        align="center"
                      >
                        {t("deliveries.delivery_time")}
                      </TableCell>
                      <TableCell
                        sx={{ width: "7%" }}
                        className="area-wise-table-column"
                        align="center"
                      ></TableCell>
                    </TableRow>
                  </TableHead> */}
                <div
                  style={{
                    position: "sticky",
                    padding: "10px",
                    top: 0,
                    backgroundColor: "#f5f5f5",
                    zIndex: 1,
                  }}
                  className="header-wrapper-for-area-wise"
                >
                  <div
                    style={{ textAlign: "start" }}
                    className="area-wise-table-column"
                  >
                    {t("deliveries.area_name")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.color")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.postcode")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.distance")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.cart_amount")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.shipping_rate")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.extra_shipping_for_less_cart_amt")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.time_taken_for_delivery")}
                  </div>
                  <div className="area-wise-table-column">
                    {t("deliveries.delivery_time")}
                  </div>
                  <div className="area-wise-table-column"></div>
                </div>

                {rows.length > 0 ? (
                  rows.map((row: any, index: number) => (
                    <div
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      className="header-wrapper-for-area-wise"
                      style={{
                        marginBottom: 2,
                        border: "1px solid rgba(224, 224, 224, 1)",
                        height: "70px",
                        borderRadius: "5px",
                        padding: "15px 5px 15px 5px",
                        marginTop: "5px",
                      }}
                    >
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "60px",
                            }}
                          >
                            <TextField
                              color="warning"
                              name="areaName"
                              size="small"
                              onChange={(e) => {
                                formikForDeliveryRuleForEdit.handleChange(e);
                              }}
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.areaName ||
                                ""
                              }
                              //@ts-ignore
                              placeholder={t("deliveries.area_name")}
                            />
                            {formikForDeliveryRuleForEdit.touched.areaName &&
                              formikForDeliveryRuleForEdit.errors.areaName && (
                                <Box>
                                  <Typography
                                    sx={{
                                      marginBottom: "5px",
                                      textAlign: "start",
                                    }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography
                            sx={{
                              textTransform: "capitalize",
                              textAlign: "start",
                              paddingTop: "7px",
                            }}
                          >
                            {row.areaName}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {" "}
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "50px",
                            }}
                          >
                            <TextField
                              size="small"
                              color="warning"
                              onChange={(e) => {
                                formikForDeliveryRuleForEdit.handleChange(e);
                              }}
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={formikForDeliveryRuleForEdit.values.color}
                              type="color"
                              sx={{ width: "100%" }}
                              name="color"
                              //@ts-ignore
                              placeholder={t("deliveries.color")}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {row.color}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            <TextField
                              sx={{ width: "100%" }}
                              color="warning"
                              size="small"
                              onChange={(e) => {
                                formikForDeliveryRuleForEdit.handleChange(e);
                              }}
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.postCode ||
                                ""
                              }
                              name="postCode"
                              //@ts-ignore
                              placeholder={t("deliveries.postcode")}
                            />
                            {formikForDeliveryRuleForEdit.touched.postCode &&
                              formikForDeliveryRuleForEdit.errors.postCode && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {row.postCode}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",

                              alignItems: "flex-start",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.distance ||
                                ""
                              }
                              size="small"
                              name="distance"
                              //@ts-ignore
                              placeholder={t("deliveries.distance")}
                            />
                            {formikForDeliveryRuleForEdit.touched.distance &&
                              formikForDeliveryRuleForEdit.errors.distance && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {`${row.distance} ${distanceUnit}`}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "50px",
                              alignItems: "flex-start",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .cartAmount || ""
                              }
                              size="small"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              name="cartAmount"
                              //@ts-ignore
                              placeholder={t("deliveries.cart_amount")}
                            />
                            {formikForDeliveryRuleForEdit.touched.cartAmount &&
                              formikForDeliveryRuleForEdit.errors
                                .cartAmount && (
                                <Box>
                                  <Typography
                                    sx={{ textAlign: "start" }}
                                    className="create-a-user-formik-label"
                                  >
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography paddingTop="7px">
                            {currencySymbol} {row.cartAmount}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "50px",
                              alignItems: "flex-start",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .shippingRate || ""
                              }
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              size="small"
                              name="shippingRate"
                              //@ts-ignore
                              placeholder={t("deliveries.shipping_rate")}
                            />
                            {formikForDeliveryRuleForEdit.touched
                              .shippingRate &&
                              formikForDeliveryRuleForEdit.errors
                                .shippingRate && (
                                <Box>
                                  <Typography className="create-a-user-formik-label">
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {currencySymbol} {row.shippingRate}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {" "}
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "50px",
                              alignItems: "flex-start",
                            }}
                          >
                            <TextField
                              size="small"
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              value={
                                formikForDeliveryRuleForEdit.values
                                  .extraShippingRate || ""
                              }
                              name="extraShippingRate"
                              //@ts-ignore
                              placeholder={t(
                                "deliveries.extra_shipping_for_less_cart_amt"
                              )}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {currencySymbol}{" "}
                            {row.extraShippingRate
                              ? row.extraShippingRate
                              : "0"}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {row.isEdited === true ? (
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "50px",
                              alignItems: "flex-start",
                            }}
                          >
                            <TextField
                              color="warning"
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formikForDeliveryRuleForEdit.handleBlur}
                              value={
                                formikForDeliveryRuleForEdit.values.timeTaken ||
                                ""
                              }
                              size="small"
                              name="timeTaken"
                              //@ts-ignore
                              placeholder={t(
                                "deliveries.time_taken_for_delivery"
                              )}
                            />
                          </Box>
                        ) : (
                          <Typography sx={{ paddingTop: "7px" }}>
                            {row.timeTaken ? row.timeTaken : "0"}
                          </Typography>
                        )}
                      </div>
                      <div className="area-wise-table-row">
                        {" "}
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          <IconButton
                            onClick={
                              row.isEdited
                                ? () => handleEditTime(row.deliveryRuleId)
                                : undefined
                            }
                          >
                            <AccessTimeIcon color="warning" />
                          </IconButton>
                          {row.startTime && row.endTime ? (
                            <Typography className="distance-wise-popup-time-text">
                              {row.startTime} to {row.endTime}
                            </Typography>
                          ) : (
                            <Typography
                              sx={{
                                color: "#ababab",
                              }}
                            >
                              {t("deliveries.set_time")}
                            </Typography>
                          )}
                        </Box>
                      </div>
                      <div>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          {row.isEdited === true ? (
                            <IconButton
                              type="submit"
                              sx={{ marginRight: "5px" }}
                              className="area-wise-add-done-icone"
                            >
                              <DoneIcon />
                            </IconButton>
                          ) : (
                            showCloseIcon[index] && (
                              <IconButton
                                onClick={(e) =>
                                  handleEditIcon(row.deliveryRuleId, row, e)
                                }
                              >
                                <EditIcon />
                              </IconButton>
                            )
                          )}
                          {row.isEdited === true ? (
                            <IconButton
                              type="button"
                              onClick={() =>
                                handleCloseIconCancelButton(row.deliveryRuleId)
                              }
                            >
                              <CloseIcon />
                            </IconButton>
                          ) : (
                            showCloseIcon[index] && (
                              <IconButton
                                type="button"
                                onClick={() =>
                                  combineOpenAndPassingData(row.deliveryRuleId)
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            )
                          )}
                        </Box>
                      </div>
                    </div>
                  ))
                ) : (
                  <NodataImage
                    height="150px"
                    width="150px"
                    heightForContainer="25vh"
                    fontSizeForNoDataText="14px"
                  />
                )}

                {/* <TableBody>
                  {rows &&
                    rows.map((row: any, index: number) => (
                      <TableRow
                        onMouseEnter={() => handleMouseEnter(index)}
                        onMouseLeave={() => handleMouseLeave(index)}
                        key={row.id}
                        sx={{
                          marginBottom: 2,
                          border: "1px solid rgba(224, 224, 224, 1)",
                          height: "60px",
                          borderRadius: "5px",
                        }}
                      >
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                              }}
                            >
                              <TextField
                                color="warning"
                                name="areaName"
                                size="small"
                                onChange={(e) => {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }}
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .areaName || ""
                                }
                                //@ts-ignore
                                placeholder={t("deliveries.area_name")}
                              />
                              {formikForDeliveryRuleForEdit.touched.areaName &&
                                formikForDeliveryRuleForEdit.errors
                                  .areaName && (
                                  <Box>
                                    <Typography
                                      sx={{ marginBottom: "5px" }}
                                      className="create-a-user-formik-label"
                                    >
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            <Typography
                              sx={{ textTransform: "capitalize", flex: 2 }}
                            >
                              {row.areaName}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                              }}
                            >
                              <TextField
                                size="small"
                                color="warning"
                                onChange={(e) => {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }}
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values.color
                                }
                                type="color"
                                sx={{ width: "100%" }}
                                name="color"
                                //@ts-ignore
                                placeholder={t("deliveries.color")}
                              />
                            </Box>
                          ) : (
                            row.color
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                          component="th"
                          scope="row"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                              }}
                            >
                              <TextField
                                color="warning"
                                size="small"
                                onChange={(e) => {
                                  formikForDeliveryRuleForEdit.handleChange(e);
                                }}
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .postCode || ""
                                }
                                sx={{ flex: 1 }}
                                name="postCode"
                                //@ts-ignore
                                placeholder={t("deliveries.postcode")}
                              />
                              {formikForDeliveryRuleForEdit.touched.postCode &&
                                formikForDeliveryRuleForEdit.errors
                                  .postCode && (
                                  <Box>
                                    <Typography className="create-a-user-formik-label">
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            row.postCode
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                          align="center"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                alignItems: "flex-start",
                              }}
                            >
                              <TextField
                                color="warning"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formikForDeliveryRuleForEdit.handleChange(
                                      e
                                    );
                                  }
                                }}
                                type="text"
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .distance || ""
                                }
                                sx={{ flex: 1, marginRight: "5px" }}
                                size="small"
                                name="distance"
                                //@ts-ignore
                                placeholder={t("deliveries.distance")}
                              />
                              {formikForDeliveryRuleForEdit.touched.distance &&
                                formikForDeliveryRuleForEdit.errors
                                  .distance && (
                                  <Box>
                                    <Typography className="create-a-user-formik-label">
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            `${row.distance} ${distanceUnit}`
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                          align="center"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                alignItems: "flex-start",
                              }}
                            >
                              <TextField
                                color="warning"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formikForDeliveryRuleForEdit.handleChange(
                                      e
                                    );
                                  }
                                }}
                                type="text"
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .cartAmount || ""
                                }
                                size="small"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol}
                                    </InputAdornment>
                                  ),
                                }}
                                sx={{ flex: 1, marginRight: "5px" }}
                                name="cartAmount"
                                //@ts-ignore
                                placeholder={t("deliveries.cart_amount")}
                              />
                              {formikForDeliveryRuleForEdit.touched
                                .cartAmount &&
                                formikForDeliveryRuleForEdit.errors
                                  .cartAmount && (
                                  <Box>
                                    <Typography className="create-a-user-formik-label">
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            <Typography>
                              {currencySymbol} {row.cartAmount}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                          align="center"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                alignItems: "flex-start",
                              }}
                            >
                              <TextField
                                color="warning"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formikForDeliveryRuleForEdit.handleChange(
                                      e
                                    );
                                  }
                                }}
                                type="text"
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .shippingRate || ""
                                }
                                sx={{ flex: 1, marginRight: "5px" }}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol}
                                    </InputAdornment>
                                  ),
                                }}
                                size="small"
                                name="shippingRate"
                                //@ts-ignore
                                placeholder={t("deliveries.shipping_rate")}
                              />
                              {formikForDeliveryRuleForEdit.touched
                                .shippingRate &&
                                formikForDeliveryRuleForEdit.errors
                                  .shippingRate && (
                                  <Box>
                                    <Typography className="create-a-user-formik-label">
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          ) : (
                            <Typography>
                              {currencySymbol} {row.shippingRate}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 1 }}
                          className="area-wise-table-row"
                          align="center"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                alignItems: "flex-start",
                              }}
                            >
                              <TextField
                                size="small"
                                color="warning"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formikForDeliveryRuleForEdit.handleChange(
                                      e
                                    );
                                  }
                                }}
                                type="text"
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol}
                                    </InputAdornment>
                                  ),
                                }}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .extraShippingRate || ""
                                }
                                sx={{ flex: 1, marginRight: "5px" }}
                                name="extraShippingRate"
                                //@ts-ignore
                                placeholder={t(
                                  "deliveries.extra_shipping_for_less_cart_amt"
                                )}
                              />
                            </Box>
                          ) : (
                            <Typography>
                              {currencySymbol}{" "}
                              {row.extraShippingRate
                                ? row.extraShippingRate
                                : "0"}
                            </Typography>
                          )}
                        </TableCell>
                        <TableCell
                          sx={{ flex: 2 }}
                          className="area-wise-table-row"
                          align="center"
                        >
                          {row.isEdited === true ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                height: "50px",
                                alignItems: "flex-start",
                              }}
                            >
                              <TextField
                                color="warning"
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formikForDeliveryRuleForEdit.handleChange(
                                      e
                                    );
                                  }
                                }}
                                type="text"
                                onBlur={formikForDeliveryRuleForEdit.handleBlur}
                                value={
                                  formikForDeliveryRuleForEdit.values
                                    .timeTaken || ""
                                }
                                size="small"
                                sx={{ flex: 1, marginRight: "5px" }}
                                name="timeTaken"
                                //@ts-ignore
                                placeholder={t(
                                  "deliveries.time_taken_for_delivery"
                                )}
                              />
                            </Box>
                          ) : row.timeTaken ? (
                            row.timeTaken
                          ) : (
                            "0"
                          )}
                        </TableCell>
                        <TableCell sx={{ flex: 2 }} align="center">
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                              width: "100%",
                            }}
                          >
                            <IconButton
                              onClick={
                                row.isEdited
                                  ? () => handleEditTime(row.deliveryRuleId)
                                  : undefined
                              }
                            >
                              <AccessTimeIcon color="warning" />
                            </IconButton>
                            {row.startTime && row.endTime ? (
                              <Typography className="distance-wise-popup-time-text">
                                {row.startTime} to {row.endTime}
                              </Typography>
                            ) : (
                              <Typography
                                sx={{
                                  color: "#ababab",
                                }}
                              >
                                {t("deliveries.set_time")}
                              </Typography>
                            )}
                          </Box>
                        </TableCell>
                        <TableCell
                          className="area-wise-table-row"
                          align="center"
                        >
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            {row.isEdited === true ? (
                              <IconButton
                                type="submit"
                                sx={{ marginRight: "5px" }}
                                className="area-wise-add-done-icone"
                              >
                                <DoneIcon />
                              </IconButton>
                            ) : (
                              showCloseIcon[index] && (
                                <IconButton
                                  onClick={(e) =>
                                    handleEditIcon(row.deliveryRuleId, row, e)
                                  }
                                >
                                  <EditIcon />
                                </IconButton>
                              )
                            )}
                            {row.isEdited === true ? (
                              <IconButton
                                type="button"
                                onClick={() =>
                                  handleCloseIconCancelButton(
                                    row.deliveryRuleId
                                  )
                                }
                              >
                                <CloseIcon />
                              </IconButton>
                            ) : (
                              showCloseIcon[index] && (
                                <IconButton
                                  type="button"
                                  onClick={() =>
                                    combineOpenAndPassingData(
                                      row.deliveryRuleId
                                    )
                                  }
                                >
                                  <CloseIcon />
                                </IconButton>
                              )
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody> */}
              </form>
            </TableContainer>

            {activeDistanceButton && (
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <form onSubmit={formikForDeliveryRule.handleSubmit}>
                  <div className="header-wrapper-for-area-wise-for-new-row">
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          color="warning"
                          onChange={(e) => {
                            formikForDeliveryRule.handleChange(e);
                          }}
                          onBlur={formikForDeliveryRule.handleBlur}
                          value={formikForDeliveryRule.values.areaName}
                          name="areaName"
                          //@ts-ignore
                          placeholder={t("deliveries.area_name")}
                        />

                        {formikForDeliveryRule.touched.areaName &&
                          formikForDeliveryRule.errors.areaName && (
                            <Box>
                              <Typography
                                sx={{ textAlign: "start" }}
                                className="create-a-user-formik-label"
                              >
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </div>
                    <div>
                      <TextField
                        color="warning"
                        type="color"
                        onChange={(e) => {
                          formikForDeliveryRule.handleChange(e);
                        }}
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={formikForDeliveryRule.values.color}
                        sx={{ width: "100%" }}
                        name="color"
                        //@ts-ignore
                        placeholder={t("deliveries.color")}
                      />
                    </div>
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          color="warning"
                          onChange={(e) => {
                            formikForDeliveryRule.handleChange(e);
                          }}
                          onBlur={formikForDeliveryRule.handleBlur}
                          value={formikForDeliveryRule.values.postCode}
                          name="postCode"
                          //@ts-ignore
                          placeholder={t("deliveries.postcode")}
                        />

                        {formikForDeliveryRule.touched.postCode &&
                          formikForDeliveryRule.errors.postCode && (
                            <Box>
                              <Typography
                                sx={{ textAlign: "start" }}
                                className="create-a-user-formik-label"
                              >
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </div>
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          color="warning"
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val || /^[0-9]+$/.test(val)) {
                              formikForDeliveryRule.handleChange(e);
                            }
                          }}
                          type="text"
                          onBlur={formikForDeliveryRule.handleBlur}
                          value={formikForDeliveryRule.values.distance || ""}
                          name="distance"
                          //@ts-ignore
                          placeholder={t("deliveries.distance")}
                        />

                        {formikForDeliveryRule.touched.distance &&
                          formikForDeliveryRule.errors.distance && (
                            <Box>
                              <Typography
                                sx={{ textAlign: "start" }}
                                className="create-a-user-formik-label"
                              >
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </div>
                    <div>
                      {" "}
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          color="warning"
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val || /^[0-9]+$/.test(val)) {
                              formikForDeliveryRule.handleChange(e);
                            }
                          }}
                          type="text"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                          onBlur={formikForDeliveryRule.handleBlur}
                          value={formikForDeliveryRule.values.cartAmount || ""}
                          name="cartAmount"
                          //@ts-ignore
                          placeholder={t("deliveries.cart_amount")}
                        />
                        {formikForDeliveryRule.touched.cartAmount &&
                          formikForDeliveryRule.errors.cartAmount && (
                            <Box>
                              <Typography
                                sx={{ textAlign: "start" }}
                                className="create-a-user-formik-label"
                              >
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </div>
                    <div>
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          color="warning"
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val || /^[0-9]+$/.test(val)) {
                              formikForDeliveryRule.handleChange(e);
                            }
                          }}
                          type="text"
                          onBlur={formikForDeliveryRule.handleBlur}
                          value={
                            formikForDeliveryRule.values.shippingRate || ""
                          }
                          name="shippingRate"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {currencySymbol}
                              </InputAdornment>
                            ),
                          }}
                          //@ts-ignore
                          placeholder={t("deliveries.shipping_rate")}
                        />
                        {formikForDeliveryRule.touched.shippingRate &&
                          formikForDeliveryRule.errors.shippingRate && (
                            <Box>
                              <Typography
                                sx={{ textAlign: "start" }}
                                className="create-a-user-formik-label"
                              >
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    </div>
                    <div>
                      <TextField
                        color="warning"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val || /^[0-9]+$/.test(val)) {
                            formikForDeliveryRule.handleChange(e);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        type="text"
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={
                          formikForDeliveryRule.values.extraShippingRate || ""
                        }
                        name="extraShippingRate"
                        //@ts-ignore
                        placeholder={t(
                          "deliveries.extra_shipping_for_less_cart_amt"
                        )}
                      />
                    </div>
                    <div>
                      <TextField
                        color="warning"
                        onChange={(e) => {
                          const val = e.target.value;
                          if (!val || /^[0-9]+$/.test(val)) {
                            formikForDeliveryRule.handleChange(e);
                          }
                        }}
                        type="text"
                        onBlur={formikForDeliveryRule.handleBlur}
                        value={formikForDeliveryRule.values.timeTaken || ""}
                        name="timeTaken"
                        //@ts-ignore
                        placeholder={t("deliveries.time_taken_for_delivery")}
                      />
                    </div>
                    <div>
                      <Button style={{ textAlign: "start" }}>
                        <IconButton
                          onClick={handleSetTimeButton}
                          color="warning"
                          className="area-wise-table-clock-button"
                        >
                          <AccessTimeIcon sx={{ marginRight: "10px" }} />
                          {formikForDeliveryRule.values.startTime &&
                          formikForDeliveryRule.values.endTime ? (
                            <Typography>
                              {formikForDeliveryRule.values.startTime} :{" "}
                              {formikForDeliveryRule.values.endTime}
                            </Typography>
                          ) : (
                            t("deliveries.set_time")
                          )}
                        </IconButton>
                      </Button>
                    </div>
                    <div>
                      <Box sx={{ paddingTop: "7px" }}>
                        <IconButton
                          type="submit"
                          className="area-wise-add-done-icone"
                        >
                          <DoneIcon />
                        </IconButton>
                        <IconButton onClick={handleAddButtonCloseIcon}>
                          <CloseIcon />
                        </IconButton>
                      </Box>
                    </div>
                  </div>
                </form>
              </Box>
            )}

            <Button
              disabled={handleAddButton}
              onClick={handleOnDistanceButton}
              className={
                handleAddButton
                  ? "area-wise-add-distance-button-inactive"
                  : "area-wise-add-distance-button-active"
              }
            >
              {t("deliveries.add_distance")}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default AreaWise;
