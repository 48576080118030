import { gql } from "@apollo/client";

export const STOCK_HISTORY_LIST = gql`
  query inventoryTransactionList(
    $businessId: ID
    $locationId: ID
    $query: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    inventoryTransactionList(
      businessId: $businessId
      locationId: $locationId
      query: $query
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          transactionId
          reason
          createdOn

          inventory {
            product {
              name
              productId
            }

            variantDetails
          }
        }
      }
    }
  }
`;

export const SELECTED_REASONS_LIST = gql`
  query inventoryReasons($locationId: ID) {
    inventoryReasons(locationId: $locationId) {
      reasons
    }
  }
`;

export const RECURRING_SETTINGS = gql`
  query recurringSettings($locationId: ID) {
    recurringSettings(locationId: $locationId) {
      settingId
      locationId
      recurringDays
    }
  }
`;

export const INVENTORY_LIST = gql`
  query inventoryList(
    $businessId: ID
    $categoryId:Int
    $isRecurring: Boolean
    $locationId: ID
    $search: String
    $inventoryIds: JSONString
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    inventoryList(
      
      businessId: $businessId
      categoryId:$categoryId
      isRecurring: $isRecurring
      locationId: $locationId
      inventoryIds: $inventoryIds
      search: $search
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          inventoryId

          stockCount
          variants
          variantDetails
          isRecurring
          sku
          barcode
          recurringSetting
          recurringQuantity
          product {
            productId
            name
            sku
          }
        }
        cursor
      }
    }
  }
`;

export const GET_SINGLE_INVENTORY_DETAILS = gql`
  query inventory($inventoryId: ID!) {
    inventory(inventoryId: $inventoryId) {
      inventoryId
      stockCount
      isRecurring
      sku
      barcode
      locationId
      recurringQuantity
      recurringSetting
      product {
        name
        price
        sku
        unit
      }
      variants
      variantDetails
    }
  }
`;
