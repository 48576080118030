import React, { useState } from "react";
import InventoryListSub from "./inventoryListsSub";
import { Box } from "@mui/material";
const InventoryLists = () => {
  const [searchInput, setSearchInput] = useState<string | null>("");

  return (
    <Box>
      <InventoryListSub
        searchInput={searchInput}
        setSearchInput={setSearchInput}
      />
    </Box>
  );
};

export default InventoryLists;
