import { Box } from "@mui/material";
import React from "react";
import DeliveryReport from "../../components/reports/delivery";

const DeliveryReportPage = () => {
  return (
    <Box>
      <DeliveryReport />
    </Box>
  );
};
export default DeliveryReportPage;
