import { Box, Typography } from "@mui/material";
import React from "react";

interface State{
  order:any
}

const SalesOrdersCard = (props:State) => {
  const { order } = props;
  return (
    <Box
      sx={{
        width: "100%",
        maxWidth: {xs:'100%', md:'48.3%', lg:'47.7%' ,xl:'48.5%'},
        // className: "maindDashboardBoxShadow",
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        gap: {xs:'12px', sm:'26px'},
        borderRadius: "12px",
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.04)',
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
          background: `${
            order.id === "sales"
              ? "#8dcf59"
              : order.id === "orders"
              ? "#f6c65b"
              : order.id === "deliveries"
              ? "#ae9be2"
              : "#ff8189"
          }`,
          borderRadius: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft:{xs:'15px', sm:'22px'},
        }}
      >
        <img src={order.image} alt="TotalSales" />
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "19px",
          marginBottom: "28px",
          paddingRight:'2px',
        }}
      >
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize:{xs:'14px', sm:'18px', md:'15px', xl:'18px'},
            lineHeight: "27px",
            fontWeight: "500",
            color: `${
              order.id === "sales"
                ? "#779D59"
                : order.id === "orders"
                ? "#FDB606"
                : order.id === "deliveries"
                ? "#896DD7"
                : "#F5431C"
            }`,
            paddingX: {xs:'4px', sm:'8px'},
            paddingY: "4px",
            borderRadius: "20px",
            backgroundColor: `${
              order.id === "sales"
                ? "#E5F7E9"
                : order.id === "orders"
                ? "rgba(255,188,22,0.1)"
                : order.id === "deliveries"
                ? "rgba(137,109,215,0.1)"
                : "rgba(245,67,28,0.1)"
            }`,
            minWidth: "115px",
            textAlign: "center",
          }}
        >
          {order.description}
        </Typography>
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: {xs:'28px', sm:'32px'},
            lineHeight: "48px",
            fontWeight: "600",
            color: "#000000",
            marginTop: "10px",
          }}
        >
          {order.id === "sales" ? "£ " : ""}
          {order.value}
        </Typography>
      </Box>
    </Box>
  );
};
export default SalesOrdersCard;
