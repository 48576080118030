import React, { useEffect, useState } from "react";
import OrderNumberingComponent from "../../components/orders/orderNumbering";
import { useQuery } from "@apollo/client";
import { ORDER_NUMBERING } from '../../graphicalQl/usequery/customerDataQuery';

const OrderNumbering = () => {
  const [orderNumberingData, setOrderNumberingData] = useState<any>(null);

  const {
    data: fetchOrderNumberingData,
    loading: fetchOrderNumberingLoading,
    error: fetchOrderNumberingError,
    refetch,
  } = useQuery(ORDER_NUMBERING, {
    variables: { storeId: 44 },
    context: { clientName: "businessClient" },
  });

  useEffect(() => {
    if (fetchOrderNumberingData?.storeSettings?.orderNumberingSettings) {
      const parsedData = JSON.parse(
        fetchOrderNumberingData?.storeSettings?.orderNumberingSettings
      );
      setOrderNumberingData({
        name: parsedData?.name,
        numberingType: parsedData?.numbering_type,
        prefix: parsedData?.prefix,
        maxLength: parsedData?.min_length,
      });
    }
  }, [fetchOrderNumberingData]);

  return orderNumberingData ? (
    <OrderNumberingComponent
      data={orderNumberingData}
      orderNumberingDataRefetch={refetch}
    />
  ) : (
    <></>
  );
};

export default OrderNumbering;
