// import dayjs from "dayjs";
// import { useTranslation } from "react-i18next";


// export function getDateLabel(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs) {
//     const {t}=useTranslation()
//     const today = dayjs();
//     const startOfLastYear = today.startOf('year').subtract(1, 'year');
//     const endOfLastYear = today.endOf('year').subtract(1, 'year');

//     if (startDate.isSame(endDate, 'day') && startDate.isSame(today, 'day')) {
//         return t("dashboard.Today")
//     } else if (
//         startDate.isSame(endDate, 'day') &&
//         startDate.isSame(today.subtract(1, 'day'), 'day')
//     ) {
//         return 'Yesterday';
//     } else if (
//         startDate.isSame(today.startOf('week'), 'day') &&
//         endDate.isSame(today.endOf('week'), 'day')
//     ) {
//         return 'This Week';
//     } else if (
//         startDate.isSame(today.subtract(7, 'day').startOf('week'), 'day') &&
//         endDate.isSame(today.subtract(7, 'day').endOf('week'), 'day')
//     ) {
//         return 'Last Week';
//     } else if (
//         startDate.isSame(today.startOf('month'), 'day') &&
//         endDate.isSame(today.endOf('month'), 'day')
//     ) {
//         return 'This Month';
//     } else if (
//         startDate.isSame(today.subtract(1, 'month').startOf('month'), 'day') &&
//         endDate.isSame(today.subtract(1, 'month').endOf('month'), 'day')
//     ) {
//         return 'Last Month';
//     } else if (
//         startDate.isSame(today.startOf('year'), 'day') &&
//         endDate.isSame(today.endOf('year'), 'day')
//     ) {
//         return 'This Year';
//     } else if (
//         startDate.isSame(startOfLastYear, 'day') &&
//         endDate.isSame(endOfLastYear, 'day')
//     ) {
//         return 'Last Year';
//     } else {
//         return `${startDate?.format("YYYY-MM-DD")} - ${endDate?.format("YYYY-MM-DD")}`;
//     }

// }
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

export function getDateLabel(startDate: dayjs.Dayjs, endDate: dayjs.Dayjs,t: (key: string) => string) {
    // const { t } = useTranslation();
    const today = dayjs();
    const startOfLastYear = today.startOf('year').subtract(1, 'year');
    const endOfLastYear = today.endOf('year').subtract(1, 'year');

    if (startDate.isSame(endDate, 'day') && startDate.isSame(today, 'day')) {
        return t("dashboard.Today");
    } else if (
        startDate.isSame(endDate, 'day') &&
        startDate.isSame(today.subtract(1, 'day'), 'day')
    ) {
        return t('dashboard.Yesterday');
    } else if (
        startDate.isSame(today.startOf('week'), 'day') &&
        endDate.isSame(today.endOf('week'), 'day')
    ) {
        return t('dashboard.This_Week');
    } else if (
        startDate.isSame(today.subtract(7, 'day').startOf('week'), 'day') &&
        endDate.isSame(today.subtract(7, 'day').endOf('week'), 'day')
    ) {
        return t('dashboard.Last_Week');
    } else if (
        startDate.isSame(today.startOf('month'), 'day') &&
        endDate.isSame(today.endOf('month'), 'day')
    ) {
        return t('dashboard.This_Month');
    } else if (
        startDate.isSame(today.subtract(1, 'month').startOf('month'), 'day') &&
        endDate.isSame(today.subtract(1, 'month').endOf('month'), 'day')
    ) {
        return t('dashboard.Last_Month');
    } else if (
        startDate.isSame(today.startOf('year'), 'day') &&
        endDate.isSame(today.endOf('year'), 'day')
    ) {
        return t('dashboard.This_Year');
    } else if (
        startDate.isSame(startOfLastYear, 'day') &&
        endDate.isSame(endOfLastYear, 'day')
    ) {
        return t('dashboard.Last_Year');
    } else {
        return `${startDate?.format("YYYY-MM-DD")} - ${endDate?.format("YYYY-MM-DD")}`;
    }
}
