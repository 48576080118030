import React, { useEffect, useState } from "react";
import BusinessAccountComponent from "../../components/settings/businessAccount";
import { useQuery } from "@apollo/client";
import { BUSINESS_DETAILS } from "../../graphicalQl/usequery/customerDataQuery";

function BusinessAccount() {
  const [businessDetails, setBusinessDetails] = useState<any>(null);
  console.log(businessDetails, "businessDetails");
  const businessId = sessionStorage.getItem("businessId");

  const { data, loading, error, refetch } = useQuery(BUSINESS_DETAILS, {
    variables: {
      businessId: businessId && parseInt(businessId),
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      const parsedData = data.business;
      setBusinessDetails({
        businessName: parsedData.legalBusinessName,
        brandLogo: parsedData.brandLogo,
        mobile: parsedData.mobile,
        businessId: parsedData.businessId,
        addressId: parsedData.addressId,
        favIcon: parsedData.favIcon,
        favColor: null,
      });
    }
  }, [data]);
  return businessDetails ? (
    <BusinessAccountComponent
      businessDetails={businessDetails}
      refetchBusinessDetails={refetch}
    />
  ) : (
    <></>
  );
}

export default BusinessAccount;
