import {
  Box,
  IconButton,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "./stockAdjustments.css";
import { INVENTORY_LIST } from "../../graphicalQl/usequery/inventoryListsQuery";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import {
  SELECTED_REASONS_LIST,
  STOCK_HISTORY_LIST,
} from "../../graphicalQl/usequery/inventoryListsQuery";
import { UPDATE_BULK_STOCK_ADJUSMENTS } from "../../graphicalQl/mutation/inventoryListsMutation";
import {
  FILTERING_PRODUCTS_LIST,
  INVENTORY_PRODUCT_LIST,
} from "../../graphicalQl/usequery/productsListQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { useFormik, FormikErrors, FormikTouched } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ReasonsList = [
  {
    value: "stock_received",
    label: "Stock Received",
  },
  {
    value: "inventory_recount",
    label: "Inventory Recount",
  },
  {
    value: "damage",
    label: "Damage",
  },
  {
    value: "lost",
    label: "Lost",
  },
  {
    value: "restock_return",
    label: "Restock Return",
  },
  {
    value: "item_purchased",
    label: "Item Purchased",
  },
  {
    value: "order_rejected",
    label: "Order Rejected",
  },
];

interface Row {
  id: string;
  name: string;
  sku: string;
  stockCount: number;
  barcode: string;
  variantName: string | null;
  reasonsLIst: any[];
  adjustments: string | null;
  selectedReasonForStock: null | string;
}

function collectVariantsName(data: any[]): string {
  let names: string[] = [];

  data.forEach((item) => {
    names.push(item.name);
  });

  return names.join(",");
}

function StockAdjustments() {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const navigate = useNavigate();
  const invenoryIdsFromSessionStoarge =
    sessionStorage.getItem("inventoryIds") || [];

  const [reasonsList, setReasonsList] = useState<any[]>([]);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [variantsList, setVariantsList] = useState<any[]>([]);
  const [enableForSaveButton, setEnableForSaveButton] =
    useState<boolean>(false);

  const [combinedProductsAndVarinatsList, setCombinedProductsAndVarinatsList] =
    useState<Row[]>([]);

  const initialValues: { formikRows: Row[] } = {
    formikRows: combinedProductsAndVarinatsList,
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: Yup.object({
      formikRows: Yup.array().of(
        Yup.object({
          adjustments: Yup.string().required("Required"),
        })
      ),
    }),
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const filteringObject = {
    locationId: locationId && +locationId,
    businessId: +businessId,
    inventoryIds:
      invenoryIdsFromSessionStoarge.length > 0
        ? invenoryIdsFromSessionStoarge
        : null,
  };
  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const {
    loading: productsDataLoadingByCategoryId,
    error: productsDataErrorByCategoryId,
    data: productsDataByFilteringAllFilters,
  } = useQuery(INVENTORY_LIST, {
    context: { clientName: "categoryClient" },
    variables: requiredFormatAddress,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    formik.setFieldValue("formikRows", combinedProductsAndVarinatsList);
  }, [combinedProductsAndVarinatsList]);

  const [updateBulkStockkAdjustment] = useMutation(
    UPDATE_BULK_STOCK_ADJUSMENTS,
    {
      onCompleted: (data) => {
        if (data) {
          const { addBulkInventory } = data;
          if (addBulkInventory) {
            const { success } = addBulkInventory;
            if (success) {
              navigate("/inventory/inventory-list");
              toast.success(
                <div>
                  <Typography>{t("toast.updated")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            }
          }
        }
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [STOCK_HISTORY_LIST, INVENTORY_LIST],
    }
  );

  const {
    loading: selectedReasonsDataError,
    error: selectedReasonsError,
    data: selectedReasonsdata,
  } = useQuery(SELECTED_REASONS_LIST, {
    context: { clientName: "categoryClient" },
    variables: { locationId: locationId && +locationId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (selectedReasonsdata) {
      const { inventoryReasons } = selectedReasonsdata;
      if (inventoryReasons) {
        const { reasons } = inventoryReasons;
        const parsedReasons = JSON.parse(reasons);
        const filteredData = ReasonsList.filter((each: any) =>
          parsedReasons.includes(each.value)
        );
        setReasonsList(filteredData);
      }
    }
  }, [selectedReasonsdata, productsDataByFilteringAllFilters]);

  const handleChangeAdjustmnentsInTable = (value: string, id: string) => {
    const filteredData = combinedProductsAndVarinatsList.map((each: any) => {
      if (each.id === id) {
        return { ...each, adjustments: value };
      }
      return each;
    });
    setCombinedProductsAndVarinatsList(filteredData);
  };

  const lessStockReasons = ["damage", "lost"];
  const columns: GridColDef[] = [
    {
      field: "name",
      //@ts-ignore
      headerName: t("inventory.products"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,
      renderCell: (params) => (
        <Typography className="stock-adjustments-inactive-value-in-row">
          {params.value}
        </Typography>
      ),
    },
    {
      field: "sku",
      //@ts-ignore
      headerName: t("inventory.sku"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,
      renderCell: (params) => (
        <Typography className="stock-adjustments-inactive-value-in-row">
          {params.value ? params.value : "N/A"}
        </Typography>
      ),
    },
    {
      field: "variantName",
      //@ts-ignore
      headerName: t("inventory.variation"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,
      renderCell: (params) => (
        <Typography className="stock-adjustments-inactive-value-in-row">
          {params.value ? params.value : "N/A"}
        </Typography>
      ),
    },
    {
      field: "barcode",
      //@ts-ignore
      headerName: t("inventory.barcode"),
      headerClassName: "stock-adjustments-table-column",

      flex: 1,
      renderCell: (params) => (
        <Typography className="stock-adjustments-inactive-value-in-row">
          {params.value ? params.value : "N/A"}
        </Typography>
      ),
    },
    {
      field: "stockCount",
      //@ts-ignore
      headerName: t("inventory.stock_in_hand"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,
      renderCell: (params) => (
        <Typography className="stock-adjustments-inactive-value-in-row">
          {params.value ? params.value : "0"}
        </Typography>
      ),
    },
    {
      field: "reasonsLIst",
      //@ts-ignore
      headerName: t("inventory.reason"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,

      renderCell: (params) => (
        <FormControl sx={{ width: "100%" }}>
          <Select
            variant="standard"
            disableUnderline={true}
            color="warning"
            labelId="assign-role-label"
            defaultValue="stock_received"
            value={params.row.selectedReasonForStock}
            onChange={(e: SelectChangeEvent) =>
              handleReasonChangeInTable(e.target.value, params.row.id)
            }
          >
            {params.value?.map((each: any) => (
              <MenuItem key={each.value} value={each.value}>
                <Typography className="stock-adjustments-active-value-in-row">
                  {each.label}
                </Typography>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    },
    {
      field: "adjustments",
      //@ts-ignore
      headerName: t("inventory.adjustments"),
      headerClassName: "stock-adjustments-table-column",
      flex: 1,
      renderCell: (params) => {
        const rowIndex = combinedProductsAndVarinatsList.findIndex(
          (row: any) => row.id === params.row.id
        );
        const rowIndexString: string = rowIndex.toString();

        return (
          <Box>
            <TextField
              onKeyDown={(event) => event.stopPropagation()}
              defaultValue={params.row.adjustments || ""}
              value={params.row.adjustments || ""}
              disabled={
                lessStockReasons.includes(params.row.selectedReasonForStock) &&
                parseInt(params.row.stockCount) === 0
              }
              onChange={(e) => {
                const val = e.target.value;
                if (!val || /^[0-9]+$/.test(val)) {
                  handleChangeAdjustmnentsInTable(
                    e.target.value,
                    params.row.id
                  );
                }
              }}
              type="text"
              name={`formikRows[${rowIndex}].adjustments`}
              className="hide-number-input-icons"
              //@ts-ignore
              placeholder={t("inventory.enter_adjustments")}
              id="outlined-start-adornment"
              sx={{ width: "80%" }}
              color="warning"
              InputProps={{
                disableUnderline: true,
              }}
              variant="standard"
            />

            {/* <Box>
              {formik.touched?.formikRows &&
                formik.errors?.formikRows &&
                formik.touched.formikRows[rowIndex]?.adjustments &&
                (formik.errors.formikRows as FormikErrors<Row>[])[rowIndex]
                  ?.adjustments && (
                  <Typography className="create-a-user-formik-label">
                    {(formik.errors.formikRows as FormikErrors<Row>[])[rowIndex]
                      ?.adjustments && "Required"}
                  </Typography>
                )}
            </Box>

            
            {lessStockReasons.includes(params.row.selectedReasonForStock) &&
            parseInt(params.row.stockCount) === 0 ? (
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textTransform: "none",
                  letterSpacing: "0.4px",
                  color: "#F5431C",
                  fontWeight: "500",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
              >
                Adjustment not allowed
              </Typography>
            ) : lessStockReasons.includes(params.row.selectedReasonForStock) &&
              parseInt(params.row.adjustments) >
                parseInt(params.row.stockCount) ? (
              <Typography
                sx={{
                  fontFamily: "Roboto",
                  fontSize: "12px",
                  lineHeight: "16px",
                  textTransform: "none",
                  letterSpacing: "0.4px",
                  color: "#F5431C",
                  fontWeight: "500",
                  textDecoration: "none",
                  cursor: "pointer",
                  marginTop: "4px",
                }}
              >
                {`Limit for Adjustment is ${params.row.stockCount}`}
              </Typography>
            ) : null} */}
            <Box>
              {formik.touched?.formikRows &&
              formik.errors?.formikRows &&
              formik.touched.formikRows[rowIndex]?.adjustments &&
              (formik.errors.formikRows as FormikErrors<Row>[])[rowIndex]
                ?.adjustments ? (
                <Typography className="create-a-user-formik-label">
                  {(formik.errors.formikRows as FormikErrors<Row>[])[rowIndex]
                    ?.adjustments && t("error.required")}
                </Typography>
              ) : lessStockReasons.includes(
                  params.row.selectedReasonForStock
                ) && parseInt(params.row.stockCount) === 0 ? (
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    lineHeight: "16px",
                    textTransform: "none",
                    letterSpacing: "0.4px",
                    color: "#F5431C",
                    fontWeight: "500",
                    textDecoration: "none",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                >
                  {t("inventory.adjustment_not_allowed")}
                  Adjustment not allowed
                </Typography>
              ) : lessStockReasons.includes(
                  params.row.selectedReasonForStock
                ) &&
                parseInt(params.row.adjustments) >
                  parseInt(params.row.stockCount) ? (
                <Typography
                  sx={{
                    fontFamily: "Roboto",
                    fontSize: "12px",
                    lineHeight: "16px",
                    textTransform: "none",
                    letterSpacing: "0.4px",
                    color: "#F5431C",
                    fontWeight: "500",
                    textDecoration: "none",
                    cursor: "pointer",
                    marginTop: "4px",
                  }}
                >
                  {`${t("inventory.limit_for_adjustment_is")} ${
                    params.row.stockCount
                  }`}
                </Typography>
              ) : null}
            </Box>
          </Box>
        );
      },
    },
  ];

  useEffect(() => {
    if (productsDataByFilteringAllFilters) {
      const { inventoryList } = productsDataByFilteringAllFilters;
      if (inventoryList) {
        const { edges } = inventoryList;
        const filteredData = edges.map((each: any) => ({
          id: each.node.inventoryId,
          name: each.node.product?.name,
          variantName: each.node.variantDetails
            ? collectVariantsName(JSON.parse(each.node.variantDetails))
            : null,

          recurringSetting: each.node.recurringSetting,
          sku: each.node.sku,
          recurring: each.node.isRecurring,
          stockCount: each.node.stockCount,
          reasonsLIst: reasonsList,
          adjustments: null,
          selectedReasonForStock:
            reasonsList && reasonsList[0] && reasonsList[0].value,
        }));
        setCombinedProductsAndVarinatsList(filteredData);
      }
    }
  }, [productsDataByFilteringAllFilters, reasonsList]);

  useEffect(() => {
    const collected = combinedProductsAndVarinatsList.map((each: any) => {
      return {
        ...each,
        isShowErrorMsg:
          lessStockReasons.includes(each.selectedReasonForStock) &&
          parseInt(each.adjustments) > parseInt(each.stockCount),
      };
    });

    const collectedTrues = collected.filter((each: any) => each.isShowErrorMsg);
    if (collectedTrues.length > 0) {
      setEnableForSaveButton(true);
    } else {
      setEnableForSaveButton(false);
    }
  }, [combinedProductsAndVarinatsList]);

  const getHover = () => {
    return "stock-adjustments-table-row stock-adjustments-hover";
  };

  const handleReasonChangeInTable = (value: string, id: string) => {
    const filteredCombinedList = combinedProductsAndVarinatsList.map(
      (each: any) => {
        if (each.id === id) {
          return { ...each, selectedReasonForStock: value };
        }
        return each;
      }
    );
    setCombinedProductsAndVarinatsList(filteredCombinedList);
  };

  const handleSaveButton = () => {
    const requiredFormatData = combinedProductsAndVarinatsList.map(
      (each: any) => ({
        inventory_id: +each.id,
        reason: each.selectedReasonForStock,
        adjustment: each.adjustments && +each.adjustments,
      })
    );

    const collectedVariables = {
      businessId: +businessId,
      locationId: locationId && +locationId,
      inventory: JSON.stringify(requiredFormatData),
    };
    const variantListFilteredByNull = Object.fromEntries(
      Object.entries(collectedVariables).filter(([_, value]) => value !== null)
    );

    updateBulkStockkAdjustment({
      variables: variantListFilteredByNull,
    });
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "15px",
          }}
        >
          <IconButton
            onClick={() => {
              navigate("/inventory/inventory-list");
            }}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="edit-inventory-header-name">
            {t("inventory.stock_adjustments")}
          </Typography>
          <Button
            type="submit"
            disabled={enableForSaveButton}
            className={
              !enableForSaveButton ? "verify-enable" : "verify-disable"
            }
            variant="contained"
            sx={{ textTransform: "none" }}
          >
            {t("buttons.save")}
          </Button>
        </Box>
        <Box sx={{ marginTop: "15px", padding: "15px" }}>
          <Box sx={{ height: "70vh", width: "100%" }}>
            <DataGrid
              rows={combinedProductsAndVarinatsList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    page: 0,
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
              getRowClassName={getHover}
              showCellVerticalBorder={true}
              showColumnVerticalBorder={true}
              rowHeight={70}
              sx={{
                "&.MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                  outline: "solid #1976D2 0px !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                  {
                    outline: "solid #1976D2 0px !important",
                  },
                "& .MuiDataGrid-columnHeader": {
                  background: "#F5F5F5",
                },

                ".MuiTablePagination-displayedRows": {
                  display: "none", // :point_left: to hide huge pagination number
                },
              }}
            />
          </Box>
        </Box>
      </form>
    </Box>
  );
}

export default StockAdjustments;
