type LocaleFormat = {
  [key: string]: string;
  "en-US": string;
  "en-GB": string;
  "de-DE": string;
  "fr-FR": string;
  "ja-JP": string;
  "zh-CN": string;
  "ru-RU": string;
  "en-IN": string; // Add en-IN for India
};

export const localeToFormat: LocaleFormat = {
  "en-US": "MMM/DD/YYYY", // United States
  "en-GB": "DD/MM/YYYY", // United Kingdom
  "de-DE": "DD.MM.YYYY", // Germany
  "fr-FR": "DD/MM/YYYY", // France
  "ja-JP": "YYYY/MM/DD", // Japan
  "zh-CN": "YYYY/MM/DD", // China
  "ru-RU": "DD.MM.YYYY", // Russia
  "en-IN": "DD/MM/YYYY", // India
};
