import React, { useState, useEffect, ChangeEvent } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import { MoreVert } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { ALLOW_SALES_TAX } from '../../graphicalQl/mutation/salesTaxMutation';
import {
  Typography,
  Switch,
  Divider,

  Box,
  Button,
  IconButton,
  Tooltip,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './salesTax.css';
import { useTranslation } from "react-i18next";

import { useMutation, useQuery } from '@apollo/client';
import { GET_SALES_TAX, GET_TOOGLE_STATUS } from '../../graphicalQl/usequery/settingQuery';
import { DELETE_SALES_TAX, TAX_STATUS_CHANGE } from '../../graphicalQl/mutation/salesTaxMutation';
import DeleteDailouge from '../category/deleteDailouge';
import CircularLoader from '../../loader/loader';
import { useSelector } from 'react-redux';
import PopupWithTitle from '../popups/popupWithTitle';



interface UserData {
  id: number;
  taxName: string;

  status: string;
  taxRate: number;
}

const SalesTax: React.FC = () => {
  const { t } = useTranslation();

  const CustomStyles = styled('div')({
    '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
      color: "#333 !important",
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "15px  !important",

      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "21px !important"
    },
    '& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: "solid #1976d2 0px !important",
    },
    '& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: "solid #1976d2 0px !important",
    }
  });
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/sales/create-sales-tax');
  };

  const [taxData, setTaxData] = useState<UserData[]>([]);
  const [toggleState, setToggleState] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleState(event.target.checked);
    allowSalesTax({ variables: { storeId: locationId && +locationId, allowSalesTax: event.target.checked } })

  };
  const [statusId, setStatusId] = useState<number | null>(null)
  const [statusState, setStatusState] = useState<boolean>(false)



  // const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {


  //   const status = event.target.checked ? "Active" : "InActive"
  //   salesTaxStatusChange({ variables: { taxId: +id, status: status } })

  // };
  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {

    setStatusId(id)
    setStatusState(event.target.checked)


  };

  const handleStatusCall = () => {
    const status = statusState ? "Active" : "InActive"
    salesTaxStatusChange({ variables: { taxId: statusId && +statusId, status: status } })
  }


  const handleEditNavigate = (id: string) => [
    navigate(`/sales/${id}/update-sales-tax`)
  ]





  const [deletemodal, setDeleteModal] = useState(false)

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal)
    // setRowSelectionModel([])

  }
  const [taxValue, setTaxValue] = useState("")
  const getTaxId = (id: string) => {
    setTaxValue(id)
  }

  const handleDleteByPopover = (id: string) => {

    deleteSalesTax({ variables: { taxIds: JSON.stringify([+id]) } })
    setTaxValue("")
  }

  const handleDeleteBulkCategories = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = taxData.filter((row) => rowSelectionModel.includes(row.id));
    const taxIdValues = updatedRows.map((each) => +each.id);
    deleteSalesTax({ variables: { taxIds: JSON.stringify(taxIdValues) } })
    setRowSelectionModel([]);


  }

  const [salesTaxStatusChange] = useMutation(TAX_STATUS_CHANGE, { context: { clientName: "businessClient", }, refetchQueries: [GET_SALES_TAX] });

  const [deleteSalesTax] = useMutation(DELETE_SALES_TAX, { context: { clientName: "businessClient", }, refetchQueries: [GET_SALES_TAX] });


  const [allowSalesTax] = useMutation(ALLOW_SALES_TAX, { context: { clientName: "businessClient" } })


  const { data: toggleData } = useQuery(GET_TOOGLE_STATUS, { variables: { storeId: locationId && +locationId }, context: { clientName: "businessClient" }, fetchPolicy: "network-only" })

  useEffect(() => {
    if (toggleData && toggleData.storeSettings) {
      setToggleState(toggleData.storeSettings.allowSalesTax)
    }
  }
    , [toggleData])

  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);


  const columns: GridColDef[] = [

    {
      field: 'taxName',
      //@ts-ignore

      headerName: t('settings.tax_name'),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      //@ts-ignore

      headerName: t('settings.status'),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status === "Active" ? true : false}
            onChange={(event) => { handleStatus(event, params.row.id); setIsModalOpen1(true) }}
            color='warning'
          />
        )
      }
    },

    {
      field: 'taxRate',
      //@ts-ignore

      headerName: t('settings.tax_rate'),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography>{params.row.taxRate}%</Typography>
        )
      }

    },
    {
      field: '.',
      //@ts-ignore

      headerName: t('settings.action'),

      disableColumnMenu: true,
      headerAlign: "center",
      flex: 1,
      filterable: false,
      align: 'center',
      hideSortIcons: true,

      renderCell: (params) => {
        return (
          // <CustomPopoverCell
          //   value={params.row.category}
          //   bundleId={params.row.id}
          // />
          <>
            <Tooltip title="Delete" placement="top-start" arrow>
              <IconButton onClick={() => { handleDeleteModal(); getTaxId(params.row.id) }} sx={{ ':hover': { backgroundColor: '#EEE' } }} >
                <DeleteIcon sx={{ color: "grey", }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit" placement="top" arrow>
              <IconButton onClick={() => handleEditNavigate(params.row.id)} sx={{ color: "grey", ':hover': { backgroundColor: '#EEE' } }}>
                <EditIcon sx={{ color: "grey", }} />
              </IconButton>
            </Tooltip></>
        );

      },

    },
  ];

  const { data, loading } = useQuery(GET_SALES_TAX, { variables: { storeIds: JSON.stringify([locationId && +locationId]) }, context: { clientName: 'businessClient' }, fetchPolicy: "network-only" })
  useEffect(() => {
    if (data && data.taxesList) {
      const list = data.taxesList.edges.map((each: any) => ({
        id: each.node.taxId,
        taxName: each.node.taxName,

        status: each.node.taxStatus,
        taxRate: each.node.taxPercentage,
      }))
      setTaxData(list)
    }
  }, [data])

  const getRowClass = () => {
    return "sales-rowClass"
  }
  if (loading) {
    return (<CircularLoader />)
  }

  return (
    <div>
      <Box >
        <div style={{ paddingLeft: '12px', marginBottom: '8px', marginTop: '8px' }}>
          <div className='sales_head_container'>
            <div className='sales_head_center'>
              <Typography className='sales_head'>{t('settings.sales_head')}</Typography>
            </div>
            <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
          </div>
          <Typography className='sales_para'>{t('settings.sales_para')}</Typography>
        </div>
        <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: '15px' }} />
        {toggleState && (
          <>
            <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              {/* <FormControl style={{ margin: '12px 0px 12px 20px', width: '180px', height: '48px' }}>
                <InputLabel id='demo-simple-select-label'>{t('settings.select_location')}</InputLabel>
                <Select
                  labelId='demo-simple-select-label'
                  id='demo-simple-select'
                  value={selectedOption}
                  label={t('settings.select_location')}
                  onChange={handleChange}
                  className='dropDown_con'
                  color='warning'
                  sx={{
                    '&:hover': {
                      '&& fieldset': {
                        border: '2px solid #F9C584',
                      },
                    },
                  }}
                >
                  {options.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl> */}

              <Button variant='contained' onClick={handleNavigate} className='salesButton' sx={{ textTransform: "none" }}>
                {t('settings.create_tax')}
              </Button>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            <CustomStyles>
              <Box sx={{ height: " 55vh", width: '100%' }}>

                <DataGrid
                  rows={taxData}
                  columns={columns}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  pageSizeOptions={[5, 15, 25]}
                  columnHeaderHeight={48}
                  getRowClassName={getRowClass}
                  rowHeight={48}
                  checkboxSelection
                  disableRowSelectionOnClick
                  onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                  }}
                  rowSelectionModel={rowSelectionModel}
                />

              </Box>
            </CustomStyles>
          </>
        )}
      </Box>
      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Sales Tax?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => { setIsModalOpen1(false); handleStatusCall() }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
      <DeleteDailouge deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDleteByPopover} id={taxValue} handleDeleteClick={handleDeleteBulkCategories} type="salesTax" text="Are you certain you want to delete SALES TAX? This action may impact other sections like products and reports." />

    </div>
  );
};

export default SalesTax;
