import React, { useState } from "react";
import { Divider, TextField } from "@mui/material";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import "./bulkAdjustment.css";
import { IconButton } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BULK_PRICE_ADJUSTMENTS } from "../../graphicalQl/mutation/menuMutation";
import { useSelector } from "react-redux";
import { MENU_SETUP } from "../../graphicalQl/mutation/menuMutation.tsx";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useMutation } from "@apollo/client";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import dayjs, { Dayjs } from "dayjs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { MENU_DATA, MENU_LIST } from "../../graphicalQl/usequery/menuQuery.tsx";
import { convertDateTimeToUTC } from "../../utils/utcformat.tsx";

interface Props {
  openModal: boolean;
  handleClose: () => void;
  finalList: any;
  active: any;
}
// interface Radio{
//     id:number
//     value:string;
// }
interface RadioValue {
  id: number;
  value: string;
  name: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

export default function Publish(props: Props) {
  const { menu } = useSelector((state: any) => state.menuState);
  const { t } = useTranslation();

  const { openModal, handleClose, finalList, active } = props;
  const [open, setOpen] = React.useState(false);
  const radioGroup: RadioValue[] = [
    { id: 1, value: "Now", name: t("menu_setUp.now") },
    { id: 2, value: "Later", name: t("menu_setUp.later") },
  ];

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  const [selectedValue, setSelectedValue] = useState<string>("Now");

  const handleRadioChange = (event: SelectChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value as string);
  };

  const [selectedRound, setSelectedRound] = useState<string>("none");

  const handleRadioRound = (event: SelectChangeEvent<HTMLInputElement>) => {
    setSelectedRound(event.target.value as string);
  };

  const [valueChange, setValueChange] = React.useState("increase");

  const handleChange = (event: SelectChangeEvent) => {
    setValueChange(event.target.value as string);
  };
  const [percentageValue, setPercentageValue] = React.useState<number>(0);

  const handlePercentageValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPercentageValue(+event.target.value);
  };

  const [bulkPrice] = useMutation(BULK_PRICE_ADJUSTMENTS, {
    context: { clientName: "categoryClient" },
  });

  // const handleSubmit = () => {
  //   bulkPrice({
  //     variables: {
  //       appliesFor: selectedValue, priceMode: valueChange, rounding: "upwards_to_.99", percentage: +percentageValue,
  //       menuId: +menu
  //     }
  //   })
  // }

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
    }
  };

  const [noteValue, setNoteValue] = useState<string>("");

  const handleNoteChange = (value: string) => {
    setNoteValue(value);
  };

  const [menuSetUp, { loading: publishLoading }] = useMutation(MENU_SETUP, {
    context: { clientName: "categoryClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [MENU_DATA, MENU_LIST],
  });
  const handlePublish = () => {
    menuSetUp({
      variables: {
        menuId: +active,
        items: finalList,
        publishNote: noteValue,
        publishOn:
          selectedValue === "Now" ? null : convertDateTimeToUTC(selectedDate),
        publishNow: selectedValue === "Now" ? true : false,
      },
    });
  };

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              background: "#F9F9F9",
              padding: "1%",
            }}
          >
            <IconButton
              className="bulk-adjustment-exit-icon"
              onClick={handleClose}
            >
              <CloseOutlined />
            </IconButton>
            <Typography className="bulk-adjustment-text">
              {t("menu_setUp.publish_changes")}
            </Typography>
            <Button
              className="bulk-adjustment-button"
              onClick={() => {
                handlePublish();
                handleClose();
              }}
              disabled={publishLoading}
            >
              {t("menu_setUp.publish")}
            </Button>
          </Box>
          <RadioGroup
            value={selectedValue}
            onChange={(event) => handleRadioChange(event)}
            name="radio-buttons-group"
          >
            {radioGroup.map((each, index) => (
              <React.Fragment key={each.id}>
                <FormControlLabel
                  value={each.name}
                  control={<Radio color="warning" />}
                  label={
                    <Typography
                      className="bulk-adjustment-text-publish"
                      sx={{ textTransform: "capitalize" }}
                    >
                      {each.name}
                    </Typography>
                  }
                />
                {index < radioGroup.length - 1 && (
                  <Box
                    sx={{
                      display: "flex",
                      alignitems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Divider sx={{ width: "40%" }} />
                    <Typography>Or</Typography>
                    <Divider sx={{ width: "40%" }} />
                  </Box>
                )}{" "}
                {/* Add divider if not the last item */}
              </React.Fragment>
            ))}
          </RadioGroup>
          {selectedValue === "Later" && (
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["DateTimePicker", "DateTimePicker"]}>
                <DateTimePicker
                  label="Select Date&Time"
                  value={selectedDate}
                  onChange={handleDateChange}
                  minDate={dayjs().startOf("day")} // Set minimum date to today
                  viewRenderers={{
                    hours: renderTimeViewClock,
                    minutes: renderTimeViewClock,
                    seconds: renderTimeViewClock,
                  }}
                />
              </DemoContainer>
            </LocalizationProvider>
          )}

          <Typography
            className="bulk-adjustment-text-publish"
            sx={{ marginTop: "2%" }}
          >
            {t("menu_setUp.note")}
          </Typography>
          {/* <TextField sx={{ width: '80%', marginTop: '2%' }} color='warning' multiline
            value={noteValue}
            onChange={handleNoteChange}
            rows={2} label="Add changes in the note" />
          */}
          <div
            className="textarea-box"
            style={{ width: "80%", marginTop: "12px" }}
          >
            <textarea
              id="textarea"
              onChange={(e) => {
                updateCharCount(e);
                handleNoteChange(e.target.value);
              }}
              style={{ width: "100%" }}
              name="description"
              value={noteValue}
              //@ts-ignore
              placeholder={t("menu_setUp.change_note")}
            >
              {noteValue}
            </textarea>
            <div id="charCount" className="char-count">
              {charCount}/500
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
