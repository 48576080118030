import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
} from "@mui/material";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import MenuIconMainDashboard from "../../../assets/mianDashboard/MainDashboardMenuIcon.svg";
import { useState } from "react";
import ShortCuts from "../shortCuts";
import React from "react";
import BasicRangeShortcuts from "../../../components/reports/customers/filters/dateRangeFilter";
import { useTranslation } from "react-i18next";
import DaysSelectionTest from "../../../components/reports/reportsHeaders/DaysSelectionTest";

interface DateType {
  startDate: string;
  endDate: string;
}

interface State {
  date: DateType;
  setDate: React.Dispatch<React.SetStateAction<DateType>>;
  dateDropdownLabel: string;
  setDateDropdownLabel: React.Dispatch<React.SetStateAction<string>>;
}
const Details = (props: State) => {
  const { date, setDate, dateDropdownLabel, setDateDropdownLabel } = props;
  const [openShortcuts, setOpenShortcuts] = useState(false);
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        marginBottom: "21px",
        display: "flex",
        alignItems: "center",
        // justifyContent: "space-between",
      }}
      className="backdropBlur"
    >
      {/* <TextField
        onChange={(e)=>handleDaysRange(e)}
        className="backdropBlur"
        value={value}
        sx={{
          '& .MuiOutlinedInput-input.MuiSelect-select':{
            height:'44px',
            padding:'0px !important',
            display:'flex',
            alignItems:'center',
          },
          '& .MuiMenu-paper': {
            boxShadow: 'none',
          },
          padding:'0px',
          width: "180px",
          backgroundColor: "white",
          borderRadius: "4px",
          outline:'none',
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: '#D9D9D9',
              borderRadius:'4px',
            },
            '&:hover fieldset': {
              borderColor: '#D9D9D9',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#D9D9D9',
            },
          },
        }}
        id="#"
        select
        defaultValue=""
      >
        {Days.map((option) => (
          <MenuItem sx={{
            backgroundColor:'white !important',
            '&:hover': {
              backgroundColor: '#FFF8F0 !important',
            },
            '&.Mui-focusVisible': {
              backgroundColor: '#FFF8F0 !important',
            },
          }}
            
            key={option.value}
            
          >
            <Box sx={{
              fontFamily:'poppins', 
              color:'#333333', 
              fontSize:'16px', 
              display:'flex !important', 
              alignItems:'center',
              paddingLeft:'12px'
              }}>
            {option.label}
            </Box>
          </MenuItem>
        ))}
      </TextField> */}

      {/* <FormControl sx={{ width: '20%' }}>
          <InputLabel className='create-a-use-role-label' id="assign-role-label">Select Date Range</InputLabel>
          <Select
            color='warning'
            value={value}
            labelId="assign-role-label"
            // defaultValue="7"
            label={<Typography>Select Date Range</Typography>}
            onChange={(e) => {handleDaysRange(e.target.value)  }}
          >
            {Days.map((each) => (
              <MenuItem key={each.value} value={each.value}>
                {each.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl> */}
      <Select
        displayEmpty
        sx={{
          width: dateDropdownLabel.length > 10 ? 260 : 200,
          height: 44,
          marginRight: "30px",
        }}
        value={dateDropdownLabel}
        renderValue={(selected) =>
          selected ? selected : t("dashboard.This_Month")
        }
      >
        <BasicRangeShortcuts
          setDate={setDate}
          date={date}
          setDateDropdownLabel={setDateDropdownLabel}
        />
      </Select>

      <DaysSelectionTest />

      {/* <Box
        onClick={() => {
          setOpenShortcuts(true);
        }}
        sx={{
          width: "68px !important",
          height: "42px",
          borderRadius: "8px",
          border: "1px solid #F9C584",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "4px",
          cursor:'pointer',
        }}
      >
        <img src={MenuIconMainDashboard} alt="MenuIconMainDashboard" />
      </Box> */}
      <ShortCuts open={openShortcuts} setOpen={setOpenShortcuts} />
    </Box>
  );
};

export default Details;
