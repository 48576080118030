import { Box } from "@mui/material";
import React from "react";
import DiscountsReports from "../../components/reports/discounts";

const DiscountsReportPage = () => {
  return (
    <Box>
      <DiscountsReports />
    </Box>
  );
};
export default DiscountsReportPage;
