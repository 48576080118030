const timeZoneToCountryCodeMap: { [key: string]: string } = {
    'America/New_York': 'US',
    'America/Los_Angeles': 'US',
    'Europe/London': 'GB',
    'Asia/Calcutta': 'IN',
    'America/Chicago': 'US',
    'America/Denver': 'US',
    'America/Phoenix': 'US',
    'America/Anchorage': 'US',
    'America/Honolulu': 'US',
    'Europe/Paris': 'FR',
    'Europe/Berlin': 'DE',
    'Europe/Madrid': 'ES',
    'Asia/Tokyo': 'JP',
    'Asia/Shanghai': 'CN',
    'Asia/Dubai': 'AE',
    'Asia/Singapore': 'SG',
    'Asia/Kolkata': 'IN',
    'Asia/Hong_Kong': 'HK',
    'Australia/Sydney': 'AU',
    'Australia/Melbourne': 'AU',
    'Africa/Johannesburg': 'ZA',
    'Pacific/Auckland': 'NZ',
    // Add more mappings as needed
};

export const getCountryCodeFromTimeZone = (): string => {
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return timeZoneToCountryCodeMap[timeZone] || 'GB'; // Default to 'US' if timezone is not mapped
};