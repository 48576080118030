import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Accordion,
  AccordionSummary,
  Typography,
  AccordionDetails,
  IconButton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import React, { forwardRef, useImperativeHandle } from "react";
import CloseIcon from "@mui/icons-material/Close";
import Modal from "@mui/material/Modal";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import AssignOptionsToProductOptionValues from "./assignOptionsToProductOptionValues";
import "./renderingOptions.css";
import { toast } from "react-toastify";

import AssignOptionToProductByWithouGroup from "./assignOptionToProductByWithouGroup";
import { useSelector, useDispatch } from "react-redux";
import { assignOptions } from "../../store";
import { useTranslation } from "react-i18next";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
};
export interface ChildRef {
  clearData: () => void;
}
interface RenderingOptions1 {
  productId: string | null;
  variantId: string | null;
  variantName: string | null;
  forInitialRenderingWithoutGroups: any[];
  setOptionsListByOptionId: React.Dispatch<React.SetStateAction<any[]>>;
  setOptionsListByGroupId: React.Dispatch<React.SetStateAction<any[]>>;
  enableAddButton: boolean;
  handleClearButton: () => void;
  updatedGroupIds: any[];
  updatedOptionIds: any[];
  updatedOptionIdsWithoutGroup: any[];
  setUpdatedGroupIds: React.Dispatch<React.SetStateAction<any[]>>;
  setUpdatedOptionIds: React.Dispatch<React.SetStateAction<any[]>>;
  setUpdatedOptionIdsWithoutGroup: React.Dispatch<React.SetStateAction<any[]>>;
  groupsDataFromParent: any[];
  setWithoutGroupsDataFromParent: React.Dispatch<React.SetStateAction<any[]>>;
  withoutGroupsDataFromParent: any[];
  setGroupsDataFromParent: React.Dispatch<React.SetStateAction<any[]>>;
  existingList: any[];
  groupsDataWithGroupFromParent: any[];
  activeProductId: string | null;
  activeVariantId: string | null;
  setActiveVariantId: React.Dispatch<React.SetStateAction<string | null>>;
  setActiveProductId: React.Dispatch<React.SetStateAction<string | null>>;
  setOptionGroupList: React.Dispatch<React.SetStateAction<any[]>>;
  setEnableButton: React.Dispatch<React.SetStateAction<boolean>>;
  accessText: string;
  updatedDataList: any;
  setOptionIdsWithoutGroup: React.Dispatch<React.SetStateAction<any[]>>;
  initialOptionGroupsList: any[];
  optionIdsWithoutgroup: any[];
  forInitialRenderingOptionGroups: any[];
  productName: string;
  initialOptionsListWithoutGroup: any[];
  handleAddButtonWhenClick: () => void;
  optionsListByOptionId: any[];
  setAddButtonClickText: React.Dispatch<React.SetStateAction<string>>;
  setPopperTrue: React.Dispatch<React.SetStateAction<boolean>>;
  optionsListByGroupId: any[];
  setTotalModifiedOptions: React.Dispatch<React.SetStateAction<any[]>>;
}

const RenderingOptions = forwardRef((props: RenderingOptions1, ref) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    optionIdsWithoutgroup,
    handleClearButton,
    groupsDataFromParent,
    withoutGroupsDataFromParent,
    setWithoutGroupsDataFromParent,
    setGroupsDataFromParent,
    forInitialRenderingOptionGroups,
    setUpdatedGroupIds,
    setUpdatedOptionIdsWithoutGroup,
    forInitialRenderingWithoutGroups,
    setUpdatedOptionIds,
    updatedOptionIdsWithoutGroup,
    updatedGroupIds,
    updatedOptionIds,
    groupsDataWithGroupFromParent,
    variantName,
    updatedDataList,
    existingList,
    activeProductId,
    activeVariantId,
    setActiveVariantId,
    setActiveProductId,
    optionsListByGroupId,
    initialOptionsListWithoutGroup,
    setOptionIdsWithoutGroup,
    setOptionGroupList,
    handleAddButtonWhenClick,
    initialOptionGroupsList,
    enableAddButton,
    setEnableButton,
    setOptionsListByOptionId,
    setOptionsListByGroupId,
    optionsListByOptionId,
    productId,
    variantId,
    setPopperTrue,
    accessText,
    productName,
    setAddButtonClickText,
  } = props;

  const [assignedOptionsCount, setAssignedOptionsCount] = useState<number>(0);
  const [iteratingOptionGroups, setIteratingngOptionGroups] = useState<any[]>(
    []
  );
  const [iteratingOptionOptions, setIteratingngOptionOptions] = useState<any[]>(
    []
  );
  const [updatedDataWithoutGroup, setUpdatedDataWithoutGroup] = useState<any[]>(
    []
  );
  const [colectingExistingIds, setCollectingExistingIds] = useState<any[]>([]);
  const [activeGroupId, setActiveGroupId] = useState<any>("");
  const [activeOptionOptionId, setActiveOptionOptionId] = useState<string>("");
  const [activeOptionGroupId, setActiveOptionGroupId] = useState<string>("");
  const [activeOptionVales, setActiveOptionValues] = useState<any[]>([]);
  const [activeModifiers, setActiveModifiers] = useState<any[]>([]);
  const [activeOptionId, setActiveOptionsId] = useState<any>("");
  const [isOpen, setIsOpen] = useState(false);
  const [expanded, setExpanded] = useState<string | boolean>(false);
  const [selectedOptionsWithGroup, setSelectedOptionsWithGroup] = useState<{
    [key: number]: any;
  }>({});
  const [checkedState, setCheckedState] = useState<{
    [key: string]: { [key: string]: boolean };
  }>({});
  const [checkedStateWithoutGroup, setCheckedStateWithoutGroup] = useState<{
    [key: string]: boolean;
  }>({});
  const [selectedOptionsWithoutGroup, setSelectedOptionsWithoutGroup] =
    useState<any | null>(null);
  const [
    iterationOptionGroupsWithoutExistingData,
    setIteratingOptionsGroupsWithoutExistingData,
  ] = useState<any[]>([]);
  const [
    initialIterationOptionGroupsWithoutExistingData,
    setInitialIteratingOptionsGroupsWithoutExistingData,
  ] = useState<any[]>([]);

  const [dialogueAlertForUpdatingData, setDialogueAlertForUpdatingData] =
    React.useState(false);
  const [existedCount, setExistedCount] = useState<number | null>(null);
  const [popupText, setPopupText] = useState<string>("");
  const handleOpen = () => setDialogueAlertForUpdatingData(true);
  const handleClose = () => setDialogueAlertForUpdatingData(false);

  const [
    iterationOptionWithoutGroupWithoutExistingData,
    setIteratingOptionsWithoutGroupExistingData,
  ] = useState<any[]>([
    {
      productId: null,
      variantId: null,
      optionGroupId: null,
      optionObjects: [],
    },
  ]);

  const [
    initialRenderingOptionsListWithoutGroup,
    setInitialRenderingOptionsListWithoutGroup,
  ] = useState<any[]>([
    {
      productId: null,
      variantId: null,
      optionGroupId: null,
      optionObjects: [],
    },
  ]);
  const [intialrenderingExistingList, setIntialrenderingExistingList] =
    useState<any[]>([]);
  const [
    iteratingOptionOptionsWithoutGroup,
    setIteratingngOptionOptionsWithoutGroup,
  ] = useState<any[]>([]);
  const { productOptionIds, productGroupId } = useSelector(
    (state: any) => state.AssignOptions
  );

  let existingCountForWithoutGroup = 0;
  let newCountForWithoutGroup = 0;
  let exitedCountForWithoutGroupProduct = 0;
  let existingCountForGroup = 0;
  let newCountForGroup = 0;
  let exitedCountForProduct = 0;
  let existingOptionsCount = 0;
  let newOptionsCount = 0;
  let existingOptionWithoutGroupCount = 0;
  let newOptionWithoutGroupCount = 0;

  useEffect(() => {
    if (accessText === "product") {
      if (
        iterationOptionWithoutGroupWithoutExistingData?.[0]?.optionObjects
          ?.length > 0 ||
        iterationOptionGroupsWithoutExistingData.length > 0
      ) {
        const optionObjects =
          iterationOptionWithoutGroupWithoutExistingData?.[0]?.optionObjects;
        const filteredData = optionObjects
          ? optionObjects.map((each: any) => each.optionId)
          : [];
        const collectedOptionsFromExistingGroup =
          iterationOptionGroupsWithoutExistingData
            .flatMap((eachGroup) => {
              return eachGroup.optionObjects;
            })
            .map((each: any) => each.optionId);
        dispatch(
          assignOptions("productOptionIds", [
            ...filteredData,
            ...collectedOptionsFromExistingGroup,
          ])
        );
      } else {
        dispatch(assignOptions("productOptionIds", []));
      }
    }
  }, [
    iterationOptionWithoutGroupWithoutExistingData,
    iterationOptionGroupsWithoutExistingData,
  ]);

  useEffect(() => {
    if (existingList.length > 0) {
      const data = existingList.filter(
        (each: any) => each.productName === productName
      );
      const filteredOptionsListWithGroup = data
        ? data[0] &&
          data[0].data
            .filter((each: any) => each.optionGroupId !== null)
            .map((each: any) => ({
              ...each,
              productId: data[0].productId,
              variantId: data[0].variantId,
            }))
        : [];

      const filteredOptionsListWithoutGroup = data
        ? data[0] &&
          data[0].data
            .filter((each: any) => each.optionGroupId === null)
            .map((each: any) => ({
              ...each,
              productId: data[0].productId,
              variantId: data[0].variantId,
            }))
        : [];
      const collectedOptionObjects =
        filteredOptionsListWithoutGroup &&
        filteredOptionsListWithoutGroup.map((each: any) => {
          return each.optionObjects;
        });
      const collectedProductId =
        filteredOptionsListWithoutGroup &&
        filteredOptionsListWithoutGroup.map((each: any) => {
          return each.productId;
        });

      const collectedVariantId =
        filteredOptionsListWithoutGroup &&
        filteredOptionsListWithoutGroup.map((each: any) => {
          return each.variantId;
        });

      const required: any = [
        {
          productId: collectedProductId ? collectedProductId[0] : null,
          variantId: collectedVariantId ? collectedVariantId[0] : null,
          optionGroupId: null,
          optionObjects: collectedOptionObjects ? collectedOptionObjects : [],
        },
      ];

      setIteratingOptionsGroupsWithoutExistingData([
        ...(filteredOptionsListWithGroup || []),
        ...(iterationOptionGroupsWithoutExistingData || []),
      ]);
      setInitialIteratingOptionsGroupsWithoutExistingData([
        ...(filteredOptionsListWithGroup || []),
        ...(iterationOptionGroupsWithoutExistingData || []),
      ]);
      if (
        required &&
        required[0].optionObjects &&
        required[0].optionObjects.length > 0
      ) {
        setIteratingOptionsWithoutGroupExistingData(
          required &&
            required[0]?.optionObjects &&
            required[0]?.optionObjects.length > 0
            ? [...required]
            : []
        );
        setInitialRenderingOptionsListWithoutGroup(
          required &&
            required[0]?.optionObjects &&
            required[0]?.optionObjects.length > 0
            ? [...required]
            : []
        );
      } else {
        setIteratingOptionsWithoutGroupExistingData([
          {
            productId: null,
            variantId: null,
            optionGroupId: null,
            optionObjects: [],
          },
        ]);
        setInitialRenderingOptionsListWithoutGroup([
          {
            productId: null,
            variantId: null,
            optionGroupId: null,
            optionObjects: [],
          },
        ]);
      }

      setExpanded("panel1");
      // setIntialrenderingExistingList(filteredOptionsListWithGroup)
    }
  }, [existingList]);

  useEffect(() => {
    setIteratingOptionsWithoutGroupExistingData((prevArray) => {
      const updatedArray =
        prevArray &&
        prevArray.map((obj, index) => {
          if (index === 0) {
            const updatedOptionObjects = [...updatedDataWithoutGroup];
            return {
              ...obj,
              productId: activeProductId,
              variantId: activeVariantId,
              optionObjects: [...updatedOptionObjects, ...obj.optionObjects],
            };
          }
          return obj;
        });
      return updatedArray;
    });

    setInitialRenderingOptionsListWithoutGroup((prevArray) => {
      const updatedArray =
        prevArray &&
        prevArray.map((obj, index) => {
          if (index === 0) {
            const updatedOptionObjects = [...updatedDataWithoutGroup];
            return {
              ...obj,
              productId: activeProductId,
              variantId: activeVariantId,
              optionObjects: updatedOptionObjects,
            };
          }
          return obj;
        });

      return updatedArray;
    });
  }, [updatedDataWithoutGroup]);

  useEffect(() => {
    if (accessText === "product" && iterationOptionGroupsWithoutExistingData) {
      const collected = iterationOptionGroupsWithoutExistingData
        .filter((each: any) => each?.optionGroupId)
        .map((eachGroup: any) => eachGroup?.optionGroupId);

      dispatch(assignOptions("productGroupId", collected ?? []));
    }
  }, [iterationOptionGroupsWithoutExistingData]);

  useEffect(() => {
    const initialSelectedOptions: { [key: number]: any } = {};
    initialIterationOptionGroupsWithoutExistingData.forEach((group) => {
      if (group.optionObjects.length > 0) {
        initialSelectedOptions[group.optionGroupId] = group.optionObjects[0];
      }
    });
    setSelectedOptionsWithGroup(initialSelectedOptions);
  }, [initialIterationOptionGroupsWithoutExistingData]);

  useEffect(() => {
    const updated = iterationOptionGroupsWithoutExistingData.map(
      (each: any) => {
        return {
          ...each,
          productId: activeProductId ? activeProductId : null,
          variantId: activeVariantId ? activeVariantId : null,
        };
      }
    );
    setIteratingOptionsGroupsWithoutExistingData(updated);
  }, [activeProductId, activeVariantId]);

  useEffect(() => {
    if (
      initialRenderingOptionsListWithoutGroup &&
      initialRenderingOptionsListWithoutGroup.length > 0 &&
      initialRenderingOptionsListWithoutGroup[0].optionObjects.length > 0
    ) {
      setSelectedOptionsWithoutGroup(
        initialRenderingOptionsListWithoutGroup[0].optionObjects[0]
      );
    }
  }, [initialRenderingOptionsListWithoutGroup]);

  useEffect(() => {
    if (
      optionsListByGroupId.length > 0 ||
      optionsListByOptionId.length > 0 ||
      optionIdsWithoutgroup.length > 0
    ) {
      setIsOpen(true);
    } else {
      setIsOpen(false);
    }
  }, [optionsListByGroupId, optionsListByOptionId, optionIdsWithoutgroup]);

  useEffect(() => {
    const optionCountInWithoutGroup =
      iterationOptionWithoutGroupWithoutExistingData &&
      iterationOptionWithoutGroupWithoutExistingData[0] &&
      iterationOptionWithoutGroupWithoutExistingData[0].optionObjects
        ? iterationOptionWithoutGroupWithoutExistingData[0].optionObjects.length
        : 0;

    const optionIdCount = iterationOptionGroupsWithoutExistingData.reduce(
      (acc, curr) => acc + curr.optionObjects.length,
      0
    );
    const totalOptionsCount = optionCountInWithoutGroup + optionIdCount;
    setAssignedOptionsCount(totalOptionsCount);
  }, [
    iterationOptionGroupsWithoutExistingData,
    iterationOptionWithoutGroupWithoutExistingData,
  ]);

  useEffect(() => {
    updatedDataList(
      (productId && productId) || (variantId && variantId),
      variantName && variantName,
      accessText,
      [
        ...(iterationOptionGroupsWithoutExistingData || []),
        ...(iterationOptionWithoutGroupWithoutExistingData &&
        iterationOptionWithoutGroupWithoutExistingData.length > 0 &&
        iterationOptionWithoutGroupWithoutExistingData[0].optionObjects.length >
          0
          ? [iterationOptionWithoutGroupWithoutExistingData[0]]
          : []),
      ]
    );
  }, [
    iterationOptionGroupsWithoutExistingData,
    iterationOptionWithoutGroupWithoutExistingData,
  ]);

  useEffect(() => {
    if (
      !iterationOptionGroupsWithoutExistingData?.length &&
      !iterationOptionWithoutGroupWithoutExistingData?.[0]?.optionObjects
        ?.length
    ) {
      setExpanded(false);
    }
  }, [
    iterationOptionGroupsWithoutExistingData,
    iterationOptionWithoutGroupWithoutExistingData,
  ]);

  useEffect(() => {
    if (
      iterationOptionGroupsWithoutExistingData?.length ||
      iterationOptionWithoutGroupWithoutExistingData?.[0]?.optionObjects?.length
    ) {
      setExpanded("panel1");
    }
  }, [
    iterationOptionGroupsWithoutExistingData,
    iterationOptionWithoutGroupWithoutExistingData,
  ]);

  const handleChipsDeleteWithGroup = (group: any, modifier: any) => {
    setIteratingOptionsGroupsWithoutExistingData((prevData) => {
      const updatedData = prevData.map((groupItem) => {
        if (groupItem.optionGroupId === group.optionGroupId) {
          const updatedOptionObjects = groupItem.optionObjects.map(
            (optionItem: any) => {
              if (
                selectedOptionsWithGroup[group.optionGroupId]?.optionId ===
                optionItem.optionId
              ) {
                // Update the modifiers of the selected option
                const updatedModifiers = optionItem.modifiers.filter(
                  (m: any) => m.modifierId !== modifier.modifierId
                );
                return {
                  ...optionItem,
                  modifiers: updatedModifiers,
                };
              }
              return optionItem;
            }
          );
          return {
            ...groupItem,
            optionObjects: updatedOptionObjects,
          };
        }
        return groupItem;
      });

      return updatedData;
    });

    setSelectedOptionsWithGroup((prevSelectedOptions) => {
      const selectedOption = prevSelectedOptions[group.optionGroupId];
      if (selectedOption) {
        // Update the modifiers of the selected option
        const updatedModifiers = selectedOption.modifiers.filter(
          (m: any) => m.modifierId !== modifier.modifierId
        );
        return {
          ...prevSelectedOptions,
          [group.optionGroupId]: {
            ...selectedOption,
            modifiers: updatedModifiers,
          },
        };
      }
      return prevSelectedOptions;
    });
  };

  const handleChipsDeleteWithoutGroup = (modifier: any) => {
    setIteratingOptionsWithoutGroupExistingData((prevData) => {
      const updatedOptionObjects = prevData[0].optionObjects.map(
        (optionItem: any) => {
          if (selectedOptionsWithoutGroup?.optionId === optionItem.optionId) {
            // Update the modifiers of the selected option
            const updatedModifiers = optionItem.modifiers.filter(
              (m: any) => m.modifierId !== modifier.modifierId
            );
            return {
              ...optionItem,
              modifiers: updatedModifiers,
            };
          }
          return optionItem;
        }
      );

      return [{ ...prevData[0], optionObjects: updatedOptionObjects }];
    });

    setSelectedOptionsWithoutGroup((prevSelectedOption: any) => {
      if (prevSelectedOption) {
        // Update the modifiers of the selected option
        const updatedModifiers = prevSelectedOption.modifiers.filter(
          (m: any) => m.modifierId !== modifier.modifierId
        );
        return {
          ...prevSelectedOption,
          modifiers: updatedModifiers,
        };
      }
      return prevSelectedOption;
    });
  };

  const deleteOptionValueFromoptionWithoutGroup = (
    optionId: string,
    optionValueId: string
  ) => {
    const filteredData = iterationOptionWithoutGroupWithoutExistingData.map(
      (eachOptionGroup: any) => ({
        ...eachOptionGroup,
        optionObjects: eachOptionGroup.optionObjects
          .map((eachOption: any) => {
            if (eachOption.optionId === optionId) {
              eachOption.optionValues = eachOption.optionValues.filter(
                (eachOptionValue: any) =>
                  eachOptionValue.optionValueId !== optionValueId
              );
            }
            return eachOption;
          })
          .filter((eachOption: any) => eachOption.optionValues.length > 0),
      })
    );

    setIteratingOptionsWithoutGroupExistingData(filteredData);
  };

  const deleteOptionFromProductWithoutGroup = (optionId: string) => {
    const updatedGroupList = iterationOptionWithoutGroupWithoutExistingData.map(
      (eachGroup: any) => {
        // Filter out the deleted option from the group's optionObjects
        const filteredOptionList = eachGroup.optionObjects.filter(
          (eachOption: any) => eachOption.optionId !== optionId
        );

        // Update the active option value based on the deleted option
        let activeOptionIndex =
          filteredOptionList &&
          filteredOptionList.findIndex(
            (option: any) =>
              option.optionId === selectedOptionsWithoutGroup?.optionId
          );

        // If the deleted option was the active one, adjust the active option index based on the remaining options
        if (activeOptionIndex === -1) {
          const totalOptions = filteredOptionList.length;
          if (totalOptions > 0) {
            const deletedOptionIndex = eachGroup.optionObjects.findIndex(
              (option: any) => option.optionId === optionId
            );
            if (deletedOptionIndex < totalOptions) {
              activeOptionIndex = deletedOptionIndex;
            } else {
              activeOptionIndex = totalOptions - 1; // If the deleted option was the last one, select the new last option
            }
          }
        }

        const activeOption =
          filteredOptionList[activeOptionIndex >= 0 ? activeOptionIndex : 0];

        // Set the selected options and checked state
        setSelectedOptionsWithoutGroup(activeOption);
        if (activeOption && activeOption.optionValues) {
          setCheckedStateWithoutGroup(
            activeOption.optionValues.reduce(
              (acc: any, value: any) => ({
                ...acc,
                [value.optionValueId]: value.isChecked,
              }),
              {}
            )
          );
        }

        // Return the updated group
        return {
          ...eachGroup,
          optionObjects: filteredOptionList,
        };
      }
    );

    // Filter out groups with no options left
    const filteredGroupList = updatedGroupList.filter(
      (group) => group.optionObjects.length > 0
    );

    // Update the state with the updated group list
    if (filteredGroupList.length === 0) {
      setIteratingOptionsWithoutGroupExistingData([
        {
          productId: null,
          variantId: null,
          optionGroupId: null,
          optionObjects: [],
        },
      ]);
    } else {
      setIteratingOptionsWithoutGroupExistingData(filteredGroupList);
    }
  };

  const handleClearData = () => {
    setIteratingOptionsGroupsWithoutExistingData([]);
    setIteratingOptionsWithoutGroupExistingData([]);
    setAssignedOptionsCount(0);
    setActiveOptionOptionId("");
    setActiveOptionGroupId("");
    setActiveOptionValues([]);
    setActiveModifiers([]);
    setActiveOptionsId("");
    setActiveGroupId("");
  };

  useImperativeHandle(ref, () => ({
    clearData: () => {
      handleClearData();
    },
  }));

  const handleDeleteGroupFromWithGroup = (groupId: string) => {
    const filteredGroupList = iterationOptionGroupsWithoutExistingData.filter(
      (eachGroup: any) => eachGroup.optionGroupId !== groupId
    );
    setIteratingOptionsGroupsWithoutExistingData(filteredGroupList);
  };

  const handleDeleteOptionFromWithGroup = (groupId: any, optionId: string) => {
    // Find the group with the specified groupId
    const updatedGroupList = iterationOptionGroupsWithoutExistingData.map(
      (eachGroup) => {
        if (eachGroup.optionGroupId === groupId) {
          // Filter out the deleted option from the group's optionObjects
          const filteredOptionList = eachGroup.optionObjects.filter(
            (eachOption: any) => eachOption.optionId !== optionId
          );

          // Update the active option value based on the deleted option
          let activeOptionIndex = filteredOptionList.findIndex(
            (option: any) =>
              option.optionId === selectedOptionsWithGroup[groupId]?.optionId
          );

          // If the deleted option was the active one, adjust the active option index based on the remaining options
          if (activeOptionIndex === -1) {
            const totalOptions = filteredOptionList.length;
            if (totalOptions > 0) {
              const deletedOptionIndex = eachGroup.optionObjects.findIndex(
                (option: any) => option.optionId === optionId
              );
              if (deletedOptionIndex < totalOptions) {
                activeOptionIndex = deletedOptionIndex;
              } else {
                activeOptionIndex = totalOptions - 1; // If the deleted option was the last one, select the new last option
              }
            }
          }

          const activeOption =
            filteredOptionList[activeOptionIndex >= 0 ? activeOptionIndex : 0];

          setSelectedOptionsWithGroup({
            ...selectedOptionsWithGroup,
            [groupId]: activeOption,
          });

          // Remove the deleted option from the checkedState
          setCheckedState((prevCheckedState) => {
            const { [optionId]: _, ...rest } = prevCheckedState[groupId] || {};
            return {
              ...prevCheckedState,
              [groupId]: rest,
            };
          });

          // Return the updated group
          return {
            ...eachGroup,
            optionObjects: filteredOptionList,
          };
        } else {
          // Return the group as it is
          return eachGroup;
        }
      }
    );

    // Filter out groups with no options left
    const filteredGroupList = updatedGroupList.filter(
      (group) => group.optionObjects.length > 0
    );

    // Update the state with the updated group list
    setIteratingOptionsGroupsWithoutExistingData(filteredGroupList);
  };

  const handleDeleteOptionValueFromWithGroup = (
    group: any,
    option: any,
    value: any,
    e: React.MouseEvent
  ) => {
    e.stopPropagation();

    const updatedOptionValues = option.optionValues.filter(
      (v: any) => v.optionValueId !== value.optionValueId
    );
    const updatedOption = {
      ...option,
      optionValues: updatedOptionValues,
    };

    const updatedData = iterationOptionGroupsWithoutExistingData.map(
      (groupItem) => {
        if (groupItem.optionGroupId === group.optionGroupId) {
          return {
            ...groupItem,
            optionObjects: groupItem.optionObjects.map((optionItem: any) => {
              return optionItem.optionId === option.optionId
                ? updatedOption
                : optionItem;
            }),
          };
        }
        return groupItem;
      }
    );

    setIteratingOptionsGroupsWithoutExistingData(updatedData);
    setSelectedOptionsWithGroup((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [group.optionGroupId]: updatedOption,
    }));
    setCheckedState((prevCheckedState) => {
      const { [value.optionValueId]: _, ...rest } =
        prevCheckedState[group.optionGroupId] || {};
      return {
        ...prevCheckedState,
        [group.optionGroupId]: rest,
      };
    });
  };
  const handleActiveOptionWithGroup = (group: any, option: any) => {
    setSelectedOptionsWithGroup({
      ...selectedOptionsWithGroup,
      [group.optionGroupId]: option,
    });
    // Set the initial checked state for the selected option
    setCheckedState((prevCheckedState: any) => ({
      ...prevCheckedState,
      [group.optionGroupId]: option.optionValues.reduce(
        (acc: any, value: any) => ({
          ...acc,
          [value.optionValueId]: value.isChecked,
        }),
        {}
      ),
    }));
  };

  const handleActiveOptionWihoutGroup = (option: any) => {
    setSelectedOptionsWithoutGroup(option);
    setCheckedStateWithoutGroup(
      option.optionValues.reduce(
        (acc: any, value: any) => ({
          ...acc,
          [value.optionValueId]: value.isChecked,
        }),
        {}
      )
    );
  };

  const handleExpandMoreIcon = (id: string | false) => {
    setExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleUpdateOptionValue = (
    group: any,
    option: any,
    value: any,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const filtered = group.optionObjects.filter(
      (each: any) => each.optionId === option.optionId
    );

    const updatedOptionValues = filtered[0].optionValues.map((v: any) =>
      v.optionValueId === value.optionValueId
        ? { ...v, isChecked: e.target.checked }
        : v
    );
    const updatedOption = {
      ...option,
      optionValues: updatedOptionValues,
    };
    const updatedData = iterationOptionGroupsWithoutExistingData.map(
      (groupItem) => {
        if (groupItem.optionGroupId === group.optionGroupId) {
          return {
            ...groupItem,
            optionObjects: groupItem.optionObjects.map((optionItem: any) => {
              return optionItem.optionId === option.optionId
                ? updatedOption
                : optionItem;
            }),
          };
        }
        return groupItem;
      }
    );

    setIteratingOptionsGroupsWithoutExistingData(updatedData);
    setSelectedOptionsWithGroup((prevSelectedOptions) => ({
      ...prevSelectedOptions,
      [group.optionGroupId]: updatedOption,
    }));
    setCheckedState((prevCheckedState) => ({
      ...prevCheckedState,
      [group.optionGroupId]: {
        ...prevCheckedState[group.optionGroupId],
        [value.optionValueId]: e.target.checked,
      },
    }));
  };

  const handleUpdateOptionValueWithoutGroup = (
    value: any,
    e: React.ChangeEvent<HTMLInputElement>,
    optionId: string
  ) => {
    const filteredData =
      iterationOptionWithoutGroupWithoutExistingData &&
      iterationOptionWithoutGroupWithoutExistingData[0].optionObjects.filter(
        (each: any) => each.optionId === optionId
      );
    if (selectedOptionsWithoutGroup) {
      const updatedOptionValues =
        filteredData &&
        filteredData[0].optionValues.map((v: any) =>
          v.optionValueId === value.optionValueId
            ? { ...v, isChecked: e.target.checked }
            : v
        );

      const updatedOption = {
        ...selectedOptionsWithoutGroup,
        optionValues: updatedOptionValues,
      };

      const updatedOptionObjects =
        iterationOptionWithoutGroupWithoutExistingData.length > 0 &&
        iterationOptionWithoutGroupWithoutExistingData[0].optionObjects.map(
          (option: any) =>
            option.optionId === updatedOption.optionId ? updatedOption : option
        );

      setIteratingOptionsWithoutGroupExistingData((prevData) => [
        {
          ...prevData[0],
          optionObjects: updatedOptionObjects,
        },
      ]);

      setSelectedOptionsWithoutGroup(updatedOption);
      setCheckedStateWithoutGroup((prevCheckedState) => ({
        ...prevCheckedState,
        [value.optionValueId]: e.target.checked,
      }));
    }
  };

  const handleAddButton = (id: string | false) => {
    setActiveVariantId(variantId);
    setActiveProductId(productId);
    setEnableButton(false);
    setAddButtonClickText(productName);
    // setExpanded((prevExpanded) => (prevExpanded === id ? id : id));
    setOptionGroupList(initialOptionGroupsList);
    setOptionIdsWithoutGroup(initialOptionsListWithoutGroup);

    if (accessText === "product") {
      const updatedDataWithGroup = [
        ...(iterationOptionGroupsWithoutExistingData || []),
      ];
      const combinedDataWithGroup = [...groupsDataFromParent];
      const collectedOptionsFromExistingGroup =
        iterationOptionGroupsWithoutExistingData.flatMap((eachGroup) => {
          return eachGroup.optionObjects;
        });
      combinedDataWithGroup.forEach((newDataItem) => {
        const existingIndex = updatedDataWithGroup.findIndex(
          (existingItem) =>
            existingItem.optionGroupId === newDataItem.optionGroupId
        );

        if (existingIndex !== -1) {
          const existingItem = updatedDataWithGroup[existingIndex];
          const existingOptionIds = existingItem.optionObjects.map(
            (obj: any) => obj.optionId
          );
          const newOptions = newDataItem.optionObjects.filter(
            (obj: any) => !existingOptionIds.includes(obj.optionId)
          );
          existingOptionsCount += 1;

          if (newOptions.length > 0) {
            existingItem.optionObjects.push(...newOptions);
            newOptionsCount += newOptions.length;
          }
        } else {
          updatedDataWithGroup.push(newDataItem);
          newOptionsCount += 1;
        }
      });

      setIteratingOptionsGroupsWithoutExistingData(updatedDataWithGroup);
      setInitialIteratingOptionsGroupsWithoutExistingData(updatedDataWithGroup);

      const existingOptionsWithoutGroupList =
        (iterationOptionWithoutGroupWithoutExistingData &&
          iterationOptionWithoutGroupWithoutExistingData[0] &&
          iterationOptionWithoutGroupWithoutExistingData[0].optionObjects) ||
        [];
      const updatedOptionsWithoutGroupList = [
        ...existingOptionsWithoutGroupList,
        ...collectedOptionsFromExistingGroup,
      ];
      const collectingNotExistedOptions: any[] = [];
      withoutGroupsDataFromParent.forEach((newDataItem) => {
        const existingIndex = updatedOptionsWithoutGroupList.findIndex(
          (existingItem) => existingItem.optionId === newDataItem.optionId
        );
        if (existingIndex === -1) {
          collectingNotExistedOptions.push(newDataItem);
          newOptionWithoutGroupCount += 1;
        } else {
          existingOptionWithoutGroupCount += 1;
        }
      });

      setUpdatedDataWithoutGroup(collectingNotExistedOptions);

      if (existingOptionsCount === 0 && existingOptionWithoutGroupCount === 0) {
        toast.success(
          <div>
            <Typography>{t("toast.added")}</Typography>
          </div>,
          {
            // Toast options
          }
        );
      } else if (newOptionsCount === 0 && newOptionWithoutGroupCount === 0) {
        toast.success(
          <div>
            <Typography>{t("error.already_existed")}</Typography>
          </div>,
          {
            // Toast options
          }
        );
      } else {
        setDialogueAlertForUpdatingData(true);
        setPopupText(
          "The product now includes the mentioned options along with the rest of the options grouped together."
        );
      }
    }

    if (accessText !== "product") {
      const existingOptionGroupIds = productGroupId;
      const combinedDataWithGroup = [
        ...groupsDataWithGroupFromParent,
        ...groupsDataFromParent,
      ];
      const updatedDataWithGroup = [
        ...(iterationOptionGroupsWithoutExistingData || []),
      ];

      const filteredNewData = combinedDataWithGroup.reduce((acc, newItem) => {
        // Check for duplicate data
        const existingIndex = updatedDataWithGroup.findIndex(
          (existingItem) => existingItem.optionGroupId === newItem.optionGroupId
        );
        if (existingIndex !== -1) {
          // Group exists in updatedDataWithGroup
          const existingItem = updatedDataWithGroup[existingIndex];
          const existingOptionIds = new Set(
            existingItem.optionObjects.map((obj: any) => obj.optionId)
          );
          newItem.optionObjects.forEach((obj: any) => {
            if (!existingOptionIds.has(obj.optionId)) {
              existingItem.optionObjects.push(obj);
            }
          });
          existingCountForGroup++;
        } else if (!existingOptionGroupIds.includes(newItem.optionGroupId)) {
          // Group not in existingOptionGroupIds
          acc.push(newItem);
          newCountForGroup++;
        } else {
          // Group in existingOptionGroupIds but not in updatedDataWithGroup
          exitedCountForProduct++;
        }
        return acc;
      }, []);

      // Update existing data with filtered new data
      updatedDataWithGroup.push(...filteredNewData);

      // Remove duplicate entries in updatedDataWithGroup
      const uniqueUpdatedDataWithGroup = updatedDataWithGroup.filter(
        (item, index, self) =>
          index ===
          self.findIndex((obj) => obj.optionGroupId === item.optionGroupId)
      );

      // Update counts and set data
      existingCountForGroup +=
        uniqueUpdatedDataWithGroup.length - updatedDataWithGroup.length;
      setIteratingOptionsGroupsWithoutExistingData(uniqueUpdatedDataWithGroup);
      setInitialIteratingOptionsGroupsWithoutExistingData(
        uniqueUpdatedDataWithGroup
      );

      const existingOptionIdsWithoutGroup = productOptionIds || [];
      const existingOptionsWithoutGroupList =
        (iterationOptionWithoutGroupWithoutExistingData &&
          iterationOptionWithoutGroupWithoutExistingData[0] &&
          iterationOptionWithoutGroupWithoutExistingData[0].optionObjects) ||
        [];

      const optionExistsInProduct = (newItem: any) => {
        return existingOptionIdsWithoutGroup.includes(newItem.optionId);
      };

      const optionExistsInExistingList = (newItem: any) => {
        return existingOptionsWithoutGroupList.some(
          (existingItem: any) => existingItem.optionId === newItem.optionId
        );
      };
      const filteredNewOptionsData = withoutGroupsDataFromParent.reduce(
        (acc, newItem) => {
          if (optionExistsInProduct(newItem)) {
            exitedCountForWithoutGroupProduct++;
          } else if (optionExistsInExistingList(newItem)) {
            existingCountForWithoutGroup++;
          } else {
            acc.push(newItem);
            newCountForWithoutGroup++;
          }
          return acc;
        },
        []
      );

      // Update existing without group options list
      const updatedOptionsWithoutGroupList = [...filteredNewOptionsData];

      // Update counts and set data
      setUpdatedDataWithoutGroup(updatedOptionsWithoutGroupList);

      if (
        (exitedCountForProduct > 0 &&
          existingCountForGroup === 0 &&
          newCountForGroup === 0) ||
        (exitedCountForWithoutGroupProduct > 0 &&
          newCountForWithoutGroup === 0 &&
          existingCountForWithoutGroup === 0)
      ) {
        toast.success(
          <div>
            <Typography>{t("error.already_existed_in_product")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      }
      // Scenario 2: New data existed in existing list but not in product
      else if (
        (newCountForGroup === 0 &&
          exitedCountForProduct === 0 &&
          existingCountForGroup > 0 &&
          uniqueUpdatedDataWithGroup.length === updatedDataWithGroup.length &&
          updatedOptionsWithoutGroupList.length ===
            existingOptionsWithoutGroupList.length) ||
        (existingCountForWithoutGroup > 0 &&
          exitedCountForWithoutGroupProduct == 0 &&
          newCountForWithoutGroup === 0 &&
          updatedOptionsWithoutGroupList.length ===
            existingOptionsWithoutGroupList.length &&
          uniqueUpdatedDataWithGroup.length === updatedDataWithGroup.length)
      ) {
        toast.success(
          <div>
            <Typography>{t("error.already_existed")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      }
      // Scenario 3: New data did not exist in both product and existing list
      else if (
        (newCountForGroup > 0 &&
          existingCountForGroup === 0 &&
          exitedCountForProduct === 0) ||
        (exitedCountForWithoutGroupProduct === 0 &&
          newCountForWithoutGroup > 0 &&
          existingCountForWithoutGroup === 0)
      ) {
        toast.success(
          <div>
            <Typography>{t("toast.added")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      }
      // Scenario 4: Other cases where data is updated
      else {
        setDialogueAlertForUpdatingData(true);
        setPopupText(t("product.this_product_now_includes") || "");
      }
    }
    setPopperTrue(false);
    handleAddButtonWhenClick();
    setOptionsListByGroupId([]);
    setOptionsListByOptionId([]);
    setOptionIdsWithoutGroup([]);
  };

  return (
    <Box sx={{ padding: "15px", margin: "10px" }}>
      <Box>
        <Modal
          open={dialogueAlertForUpdatingData}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Typography className="popup-text">{popupText}</Typography>
            <Box sx={{ textAlign: "right" }}>
              <Button
                variant="contained"
                color="warning"
                onClick={handleClose}
                sx={{ mt: 2, mb: 1, textTransform: "none" }}
              >
                {t("buttons.ok")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Accordion expanded={expanded === "panel1"}>
        <AccordionSummary
          sx={{ backgroundColor: "#fbfbfb" }}
          aria-controls="panel1a-content"
        >
          <Box
            sx={{
              display: "flex",
              width: "95%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ width: "40%" }}
              className="rendering-options-product-name"
            >
              {productName}{" "}
              <span className="rendering-options-product-variant-span">
                {accessText === "product"
                  ? t("product.total_product")
                  : t("product.product_variant")}
              </span>
            </Typography>
            <Typography
              sx={{ width: "30%" }}
              className="rendering-options-option-assigned-text"
            >
              {assignedOptionsCount}{" "}
              {assignedOptionsCount === 1
                ? t("product.option")
                : t("product.options")}{" "}
              {t("product.assigned")}
            </Typography>
            <Button
              disabled={!enableAddButton}
              onClick={() => handleAddButton("panel1")}
              className="rendering-options-add-button"
              sx={{ textTransform: "none", width: "30%" }}
              variant="contained"
              color="warning"
            >
              {t("buttons.add")}
            </Button>
            <IconButton onClick={() => handleExpandMoreIcon("panel1")}>
              {expanded === "panel1" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          {iterationOptionGroupsWithoutExistingData &&
            iterationOptionGroupsWithoutExistingData.map(
              (eachOptionGroup: any) => (
                <Box
                  key={eachOptionGroup.optionGroupId}
                  sx={{
                    borderTopLeftRadius: "8px",
                    borderTopRightRadius: "8px",
                    border: "1px solid #eee",
                    marginBottom: "8px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#eee",
                      borderRadius: "5px",
                      padding: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography className="rendering-options-option-group-name">
                      {eachOptionGroup.optionGroupName}
                      <span className="rendering-options-option-group-span">
                        ({t("product.option_group")})
                      </span>
                    </Typography>
                    <IconButton
                      onClick={() =>
                        handleDeleteGroupFromWithGroup(
                          eachOptionGroup.optionGroupId
                        )
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      {eachOptionGroup.optionObjects &&
                        eachOptionGroup.optionObjects.map((eachOption: any) => (
                          <Box
                            sx={{
                              overflowX: "scroll",
                              WebkitOverflowScrolling: "touch",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                            }}
                            key={eachOption.optionId}
                          >
                            <Button
                              onClick={() =>
                                handleActiveOptionWithGroup(
                                  eachOptionGroup,
                                  eachOption
                                )
                              }
                              sx={{
                                padding: "10px",
                                textTransform: "capitalize",
                                borderRadius: "8px",
                                margin: "10px 5px 2px 5px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "60px",
                                width: "220px",
                              }}
                              className={
                                selectedOptionsWithGroup[
                                  eachOptionGroup.optionGroupId
                                ] &&
                                selectedOptionsWithGroup[
                                  eachOptionGroup.optionGroupId
                                ].optionId === eachOption.optionId
                                  ? "rendering-optins-active-option"
                                  : "rendering-optins-inactive-option"
                              }
                            >
                              <Typography className="rendering-options-option-name">
                                {eachOption.optionName}
                                <span className="rendering-options-option-span">
                                  ({t("product.option")})
                                </span>
                              </Typography>
                            </Button>
                            <Box></Box>
                          </Box>
                        ))}
                    </Box>
                    <Box sx={{ margin: "5px" }}>
                      <AssignOptionsToProductOptionValues
                        setIteratingOptionsGroupsWithoutExistingData={
                          setIteratingOptionsGroupsWithoutExistingData
                        }
                        activeModifiers={
                          selectedOptionsWithGroup &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ] &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ].modifiers
                        }
                        handleChipsDeleteWithGroup={handleChipsDeleteWithGroup}
                        handleDeleteOptionValueFromWithGroup={
                          handleDeleteOptionValueFromWithGroup
                        }
                        optionGroup={eachOptionGroup}
                        optionGroupId={eachOptionGroup.optionGroupId}
                        handleDeleteOptionFromWithGroup={
                          handleDeleteOptionFromWithGroup
                        }
                        // itartingExistingList={iterationOptionGroupsWithoutExistingData}
                        handleUpdateOptionValue={handleUpdateOptionValue}
                        option={
                          selectedOptionsWithGroup &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ]
                        }
                        activeOptionId={
                          selectedOptionsWithGroup &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ] &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ].optionId
                        }
                        optionValues={
                          selectedOptionsWithGroup &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ] &&
                          selectedOptionsWithGroup[
                            eachOptionGroup.optionGroupId
                          ].optionValues
                        }
                        // setSelectedOptionsWithGroup={setSelectedOptionsWithGroup}
                      />
                    </Box>
                  </Box>
                </Box>
              )
            )}

          <Box sx={{ display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                overflowX: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {iterationOptionWithoutGroupWithoutExistingData &&
                iterationOptionWithoutGroupWithoutExistingData.length > 0 &&
                iterationOptionWithoutGroupWithoutExistingData[0]
                  .optionObjects &&
                iterationOptionWithoutGroupWithoutExistingData[0].optionObjects
                  .length > 0 &&
                iterationOptionWithoutGroupWithoutExistingData[0].optionObjects.map(
                  (eachOption: any) => (
                    <Box
                      sx={{ margin: "5px 5px 5px 2px" }}
                      key={eachOption.optionId}
                    >
                      <Button
                        onClick={() =>
                          handleActiveOptionWihoutGroup(eachOption)
                        }
                        sx={{
                          padding: "10px",
                          textTransform: "capitalize",
                          borderRadius: "8px",

                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "60px",
                          width: "220px",
                        }}
                        className={
                          selectedOptionsWithoutGroup &&
                          selectedOptionsWithoutGroup.optionId ===
                            eachOption.optionId
                            ? "rendering-optins-active-option"
                            : "rendering-optins-inactive-option"
                        }
                      >
                        <Typography className="rendering-options-option-name">
                          {eachOption.optionName}
                          <span className="rendering-options-option-span">
                            ({t("product.option")})
                          </span>
                        </Typography>
                      </Button>
                    </Box>
                  )
                )}
            </Box>
            <Box>
              {selectedOptionsWithoutGroup && (
                <Box>
                  <AssignOptionToProductByWithouGroup
                    setIteratingOptionsWithoutGroupExistingData={
                      setIteratingOptionsWithoutGroupExistingData
                    }
                    handleChipsDeleteWithoutGroup={
                      handleChipsDeleteWithoutGroup
                    }
                    handleUpdateOptionValueWithoutGroup={
                      handleUpdateOptionValueWithoutGroup
                    }
                    activeOptionId={
                      selectedOptionsWithoutGroup &&
                      selectedOptionsWithoutGroup.optionId
                    }
                    deleteOptionFromProductWithoutGroup={
                      deleteOptionFromProductWithoutGroup
                    }
                    deleteOptionValueFromoptionWithoutGroup={
                      deleteOptionValueFromoptionWithoutGroup
                    }
                    optionValues={
                      selectedOptionsWithoutGroup &&
                      selectedOptionsWithoutGroup.optionValues
                    }
                    activeModifiers={
                      selectedOptionsWithoutGroup &&
                      selectedOptionsWithoutGroup.modifiers
                    }
                  />
                </Box>
              )}
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
});

export default RenderingOptions;
