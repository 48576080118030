/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { TaxReportType } from "../../../types/taxesReports";
import {
  TAX_METADATA_REPORTS,
  TAX_REPORTS,
} from "../../../graphicalQl/usequery/taxReports";
import { useSelector } from "react-redux";
import { convertTimeToUTC } from "../../../utils/utcformat";
import { GraphContext } from "../../../Contexts/GraphContext";

const options = [
  { label: "Tax Name", value: "tax_name" },
  { label: "Tax Rates", value: "tax_rate" },
  { label: "Taxable Sales", value: "tax_sales" },
  { label: "Tax Amount", value: "tax_amount" },
];

const TaxReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Tax Name", value: "tax_name" },
    { label: "Tax Rates", value: "tax_rate" },
    { label: "Taxable Sales", value: "tax_sales" },
    { label: "Tax Amount", value: "tax_amount" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "tax_name",
    "tax_rate",
    "tax_sales",
    "tax_amount",
  ]);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const [taxReports, setTaxReports] = useState<Array<TaxReportType>>([]);
  const [taxReportMetaData, setTaxReportMetaData] = useState<{
    taxable_sales: number;
    non_taxable_sales: number;
    total_net_sales: number;
  }>({ taxable_sales: 0, non_taxable_sales: 0, total_net_sales: 0 });

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)
  
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { loading: taxReportsLoading, data: taxReportsData } = useQuery(
    TAX_REPORTS(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), +reportLocation || +locationId),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const { loading: taxMetaDataLoading, data: taxMetaData } = useQuery(
    TAX_METADATA_REPORTS(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      +reportLocation || +locationId
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );

  const getTaxReports = () => {
    if (!taxReportsLoading) {
      const taxReports = taxReportsData?.taxReports;
      const newTaxReports = JSON.parse(taxReports || "[]");
      setTaxReports(newTaxReports);
    }
  };
  const getTaxMetadata = () => {
    if (!taxMetaDataLoading) {
      const taxMetadata = taxMetaData?.taxMetadata;
      const newStoreReports = JSON.parse(taxMetadata || "[]");
      setTaxReportMetaData(newStoreReports);
    }
  };

  useEffect(() => {
    getTaxReports();
  }, [taxReportsLoading]);

  useEffect(() => {
    getTaxMetadata();
  }, [taxMetaDataLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
      // setFromTime={setFromTime}
      // setToTime={setToTime}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "120px",
          justifyContent: "center",
          paddingTop: "25px",
          paddingBottom: "21px",
          backgroundColor: "#FFF",
          marginTop: "24px"
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "30%",
            paddingTop: "21px",
          }}
        >
          <Typography
            sx={{
              color: "#F38B08",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {taxReportMetaData.taxable_sales}
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Taxable Sales
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "30%",
            borderLeft: "2px solid #EEE",
            borderRight: "2px solid #EEE",
            paddingTop: "21px",
          }}
        >
          <Typography
            sx={{
              color: "#F38B08",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {taxReportMetaData.non_taxable_sales}
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Non-Taxable Sales
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: "30%",
            paddingTop: "21px",
          }}
        >
          <Typography
            sx={{
              color: "#F38B08",
              fontFamily: "Poppins",
              fontSize: "32px",
              fontStyle: "normal",
              fontWeight: 600,
            }}
          >
            {taxReportMetaData.total_net_sales}
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "20px",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Total Net Sales
          </Typography>
        </Box>
      </Box>
      <Box mt={"24px"}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={taxReports}
          title={"Taxes"}
        />
      </Box>
    </Box>
  );
};

export default TaxReport;
