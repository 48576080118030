import React, { useEffect, useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import "../options/createProduct.css";
import "../category/createCategory.css";

import image from "../../assets/createProduct.png";
import { useMutation, useQuery } from "@apollo/client";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";

import { OPTION_GROUPS_IN_PRODUCT } from "../../graphicalQl/usequery/productsListQuery";
import { DELETE_OPTIONGROUP_IN_PRODUCT } from "../../graphicalQl/mutation/productMutation";
import { useSelector } from "react-redux";
import { REMOVE_OPTION_FROM_VARIANT } from "../../graphicalQl/mutation/productMutation";

import { Route, Link, Routes } from "react-router-dom";
import AssignOptionsToProduct from "./assignOptionsToproduct";
import CircularLoader from "../../loader/loader";
import DeleteDailouge from "../category/deleteDailouge";
import { useTranslation } from "react-i18next";
interface Props {}

type OptionType = {
  name: string;
  optionValueId: string;
  price: number;
  image: string | null;
};

type OptionGroupType = {
  name: string;
  optionId: string;
  optionValues: OptionType[];
};
interface DeleteOptionGroupVariables {
  optionGroupId?: number;
  productIds: string;
  optionId?: number;
}

interface DeleteOptionGroupVariables2 {
  optionGroupId?: number;
  variantId: number;
  optionId?: number;
}

type OptionType1 = {
  option_value_id: string;
  option_value_name: string;
};

const OptionsModal = (props: Props) => {
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { t } = useTranslation();

  // const { data: optionsData } = useQuery(OPTIONS_LIST,{ context: { clientName: 'categoryClient' },fetchPolicy:"network-only",variables:{businessId:+businessId}})
  const { productId } = useSelector((state: any) => state.pricing);
  const { productIdByEdit } = useSelector((state: any) => state.app);

  const id = productId.length > 0 ? productId[0] : productIdByEdit;

  const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP_IN_PRODUCT, {
    context: { clientName: "categoryClient" },
    refetchQueries: [OPTION_GROUPS_IN_PRODUCT],
  });
  const [deleteOptionGroupInVariant] = useMutation(REMOVE_OPTION_FROM_VARIANT, {
    context: { clientName: "categoryClient" },
    refetchQueries: [OPTION_GROUPS_IN_PRODUCT],
  });

  const handleDelteOptionGroup = (
    productId2: null | string,
    variantId: null | string,
    optionGroupId: string,
    value: string
  ) => {
    if (productId2) {
      const list: DeleteOptionGroupVariables = {
        productIds: JSON.stringify([+productId2]),
      };

      if (value === "group") {
        list.optionGroupId = +optionGroupId;
      } else {
        list.optionId = +optionGroupId;
      }

      deleteOptionGroup({
        variables: list,
      });
    }

    if (variantId) {
      const list2: DeleteOptionGroupVariables2 = {
        variantId: +variantId,
      };

      if (value === "group") {
        list2.optionGroupId = +optionGroupId;
      } else {
        list2.optionId = +optionGroupId;
      }
      deleteOptionGroupInVariant({
        variables: list2,
      });
    }
  };

  const [selectedOptions, setSelectedOptions] = useState<any>([]);

  const { data: optionsGroupData, loading: dataLoading } = useQuery(
    OPTION_GROUPS_IN_PRODUCT,
    {
      context: { clientName: "categoryClient" },
      fetchPolicy: "cache-and-network",
      variables: { productId: +id },
    }
  );
  const [optionGroupDataInProduct, setOptionGroupData] = useState<any>();

  useEffect(() => {
    if (
      optionsGroupData &&
      JSON.parse(optionsGroupData.optionValueListingInProducts)
    ) {
      const list = JSON.parse(
        optionsGroupData.optionValueListingInProducts
      ).map((each: any) => ({
        productId: each.product_id ? each.product_id : null,
        variantId: each.product_id ? null : each.variant_id,
        name: each.product_id ? each.product_name : each.variant_name,
        data: each.data.map((each1: any) => ({
          optionGroupId: each1.option_group_id,
          optionGroupName: each1.option_group_name,
          // options: each1.options.map((item: any) => ({
          //   optionId: item.option_id,
          //   optionName: item.option_name
          // })),
          optionId: each1.option_id,
          optionValue: each1.option_name,
        })),
      }));
      setOptionGroupData(list);
    }
  }, [optionsGroupData]);

  const [deletemodal, setDeleteModal] = useState(false);

  // Toggle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const handleDelete = (id: string) => {};

  if (dataLoading) {
    return <CircularLoader />;
  }

  return (
    <Box className="optionsBox">
      <Box sx={{ overflowY: "scroll", maxHeight: "60vh", width: "100%" }}>
        {optionGroupDataInProduct && optionGroupDataInProduct.length > 0 ? (
          <Box>
            {optionGroupDataInProduct.map((item: any) => (
              <Box key={item.name}>
                <Box
                  sx={{ display: "flex", flexWrap: "wrap" }}
                  className={
                    item.productId ? "optionGroup-background" : undefined
                  }
                >
                  {item.productId ? (
                    <Typography className="normalHeader" sx={{ margin: "1%" }}>
                      {item.name}
                    </Typography>
                  ) : (
                    <Typography
                      className="dataGrid-table-cell-less-wt"
                      sx={{ margin: "1%" }}
                    >
                      {item.name}
                    </Typography>
                  )}

                  {item.data.map((each: any) =>
                    each.optionGroupName ? (
                      <Box key={each.optionGroupId} sx={{ paddingLeft: "8px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <Chip
                              key={each.optionGroupId}
                              label={each.optionGroupName}
                              onDelete={() =>
                                handleDelteOptionGroup(
                                  item.productId,
                                  item.variantId,
                                  each.optionGroupId,
                                  "group"
                                )
                              }
                              sx={{ marginRight: "2%" }}
                            />
                          </Stack>
                        </div>
                      </Box>
                    ) : (
                      <Box key={each.optionId} sx={{ paddingLeft: "8px" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ display: "flex", flexWrap: "wrap" }}
                          >
                            <Chip
                              key={each.optionId}
                              label={each.optionValue}
                              onDelete={() =>
                                handleDelteOptionGroup(
                                  item.productId,
                                  item.variantId,
                                  each.optionId,
                                  "option"
                                )
                              }
                              sx={{ marginRight: "2%" }}
                            />
                          </Stack>
                        </div>
                      </Box>
                    )
                  )}
                </Box>
                <Divider sx={{ marginTop: "6px", marginBottom: "6px" }} />
              </Box>
            ))}
          </Box>
        ) : (
          <div style={{ textAlign: "center" }}>
            <img src={image} alt="product" style={{ width: "36%" }} />
          </div>
        )}
      </Box>
      <Box sx={{ textAlign: "center", width: "100%" }}>
        <ul style={{ listStyleType: "none" }}>
          <li>
            <Link
              to={`/assign/options/to/product/?action=${
                productIdByEdit ? "edit" : "create"
              }&prod_id=${productIdByEdit ? productIdByEdit : productId[0]}`}
              className="create-product-button"
            >
              {t("product.assign_options_to_product")}
            </Link>
          </li>
        </ul>
      </Box>
      <Routes>
        <Route
          path={`/assign/options/to/product/?action=${
            productIdByEdit ? "edit" : "create"
          }&prod_id=${productIdByEdit ? productIdByEdit : productId[0]}`}
          element={<AssignOptionsToProduct />}
        />
      </Routes>

      {/* <DeleteDailouge deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDelete} id={"5"} handleDeleteClick={handleDeleteClick} type='category' /> */}
    </Box>
  );
};

export default OptionsModal;
