import { Box } from "@mui/material";
import OrderRate from "./orderRateGraph";
import SalesOrdersDetails from "./salesOrdersDetails";
import SetupGuide from "./setupGuide";
import AddOns from "./addOns";
import PaymentTypes from "./paymentTypes";
import PopularItems from "./popularItems";
import RecentOrders from "./recentOrders";
import Activity from "./activity";
import Customers from "./customers";
import Details from "./details";
import React, { useEffect, useState } from "react";
import { useQuery } from "@apollo/client";
import { Payments } from "../../types/dashboard";
import { Stats } from "../../types/dashboard";
import CircularLoader from "../../loader/loader";
import dayjs from "dayjs";

import {
  RECENT_ORDERS_FOR_DASHBOARD,
  ACTIVITY_FOR_DASHBOARD,
  STATISTICS_FOR_DATA,
  POPULAR_ITEMS_FOR_DASHBOARD,
} from "../../graphicalQl/usequery/dashBoardOrderingQuery";
import { useSelector } from "react-redux";
import moment from "moment";
import {
  CUSTOMERS_IN_DASHBOARD1,
  CUSTOMERS_IN_DASHBOARD2,
} from "../../graphicalQl/usequery/customerDataQuery";

interface DateType {
  startDate: string;
  endDate: string;
}

interface Customers1 {
  totalCustomers: string | null;
  newCustomers: string | null;
  returningCustomers: string | null;
  inActiveCustomers: string | null;
  positiveFeedBack: string | null;
  negitiveFeedBack: string | null;
}

const DashboardMain = () => {
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [recentOrders, setRecentOrders] = useState<any[]>([]);
  const [popularItems, setPopularItems] = useState<any[]>([]);
  const [activityForDashboard, setActivityForDashboard] = useState<any[]>([]);
  const [orders, setOrders] = useState<any[]>([]);
  const [customers, setCustomers] = useState<Customers1>({
    totalCustomers: null,
    newCustomers: null,
    returningCustomers: null,
    inActiveCustomers: null,
    negitiveFeedBack: null,
    positiveFeedBack: null,
  });

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [dateDropdownLabel, setDateDropdownLabel] = useState<string>("");

  const [statistics, setStatistics] = useState<Stats>({
    sale: 0,
    order: 0,
    delivered: 0,
    cancelled: 0,
  });
  const [payments, setPayments] = useState<Payments | undefined>({
    card: 0,
    cash: 0,
    online: 0,
  });

  const variablesForRecentOrders = {
    locationId: locationId && +locationId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
    top: 5,
  };

  const filteredDataForRecentOrders = Object.fromEntries(
    Object.entries(variablesForRecentOrders).filter(
      ([_, value]) => value !== null
    )
  );

  const variablesForActivity = {
    locationId: locationId && +locationId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
  };

  const filteredDataForActivity = Object.fromEntries(
    Object.entries(variablesForActivity).filter(([_, value]) => value !== null)
  );

  const variablesForStatisticeForDashboard = {
    locationId: locationId && +locationId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
  };

  const filteredDataForStatisticsForDashboard = Object.fromEntries(
    Object.entries(variablesForStatisticeForDashboard).filter(
      ([_, value]) => value !== null
    )
  );

  const variablesForPopularItemsForDashboard = {
    locationId: locationId && +locationId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
  };



  const filteredDataForPopularForDashboard = Object.fromEntries(
    Object.entries(variablesForPopularItemsForDashboard).filter(
      ([_, value]) => value !== null
    )
  );

  const variablesForCustomers1 = {
    businessId: +businessId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
  };
  const filteredDataForCustomers1 = Object.fromEntries(
    Object.entries(variablesForCustomers1).filter(
      ([_, value]) => value !== null
    )
  );

  const variablesForCustomers2 = {
    businessId: businessId,
    fromDate: date ? date.startDate : null,
    toDate: date ? date.endDate : null,
  };
  const filteredDataForCustomers2 = Object.fromEntries(
    Object.entries(variablesForCustomers2).filter(
      ([_, value]) => value !== null
    )
  );

  const {
    loading: recentOrdersLoading,
    error: recentOrdersError,
    data: recentOredrsData,
  } = useQuery(RECENT_ORDERS_FOR_DASHBOARD, {
    context: { clientName: "orderClient" },
    variables: filteredDataForRecentOrders,
    fetchPolicy: "network-only",
  });
  const {
    loading: activityLoading,
    error: activityError,
    data: activityData,
  } = useQuery(ACTIVITY_FOR_DASHBOARD, {
    context: { clientName: "orderClient" },
    variables: filteredDataForActivity,
    fetchPolicy: "network-only",
  });
  const {
    loading: statisticsForDashboardLoading,
    error: statisticsForDashboardError,
    data: statisticsForDashboardData,
  } = useQuery(STATISTICS_FOR_DATA, {
    context: { clientName: "orderClient" },
    variables: filteredDataForStatisticsForDashboard,
    fetchPolicy: "network-only",
  });
  const {
    loading: popularForDashboardLoading,
    error: popularForDashboardError,
    data: popularForDashboardData,
  } = useQuery(POPULAR_ITEMS_FOR_DASHBOARD, {
    context: { clientName: "orderClient" },
    variables: filteredDataForPopularForDashboard,
    fetchPolicy: "network-only",
  });
  const {
    loading: customersLoading1,
    error: customersError1,
    data: customersData1,
  } = useQuery(CUSTOMERS_IN_DASHBOARD1, {
    context: { clientName: "customerClient" },
    variables: filteredDataForCustomers1,
    fetchPolicy: "network-only",
  });
  const {
    loading: customersLoading2,
    error: customersError2,
    data: customersData2,
  } = useQuery(CUSTOMERS_IN_DASHBOARD2, {
    context: { clientName: "ordering" },
    variables: filteredDataForCustomers2,
  });

  useEffect(() => {
    if (customersData2) {
      const { customerDashboard } = customersData2;
      if (customerDashboard) {
        const parsedData = JSON.parse(customerDashboard);
        if (parsedData) {
          const { return_customers, positive_feedback, negative_feedback } =
            parsedData;
          setCustomers((prev: any) => ({
            ...prev,
            returningCustomers: return_customers,
            positiveFeedBack: positive_feedback,
            negitiveFeedBack: negative_feedback,
          }));
        }
      }
    }
  }, [customersData2]);
  useEffect(() => {
    if (customersData1) {
      const { customerReports } = customersData1;
      if (customerReports) {
        const parsedData = JSON.parse(customerReports);
        const { inactive_customers, new_customers, total_customers } =
          parsedData;
        setCustomers((prev: any) => ({
          ...prev,
          inActiveCustomers: inactive_customers,
          newCustomers: new_customers,
          totalCustomers: total_customers,
        }));
      }
    }
  }, [customersData1]);
  useEffect(() => {
    if (popularForDashboardData) {
      const { popularItemsForDashboard } = popularForDashboardData;
      if (popularItemsForDashboard) {
        const parsedData = JSON.parse(popularItemsForDashboard);
        setPopularItems(parsedData);
      }
    }
  }, [popularForDashboardData]);

  useEffect(() => {
    if (statisticsForDashboardData) {
      const { statisticsForDashboard } = statisticsForDashboardData;
      if (statisticsForDashboard) {
        const parsedData = JSON.parse(statisticsForDashboard);
        const { orders, payments, stats } = parsedData;
        if (stats) {
          const { sale, order, delivered, cancelled } = stats;
          setStatistics({ sale, order, delivered, cancelled });
        }
        if (orders) {
          setOrders(orders);
        }
        if (payments) {
          setPayments(payments);
        }
      }
    }
  }, [statisticsForDashboardData]);

  useEffect(() => {
    if (activityData) {
      const { activityForDashboard } = activityData;
      if (activityForDashboard) {
        const parsedData = JSON.parse(activityForDashboard);
        setActivityForDashboard(parsedData);
      }
    }
  }, [activityData]);

  useEffect(() => {
    if (recentOredrsData) {
      const { recentOrdersForDashboard } = recentOredrsData;
      if (recentOrdersForDashboard) {
        const parsedData = JSON.parse(recentOrdersForDashboard);
        setRecentOrders(parsedData);
      }
    }
  }, [recentOredrsData]);

  const [stats, setStats] = useState<any>();

  // useEffect(() => {
  //   const fetchStats = async () => {
  //     try {
  //       DashboardApi.getStats().then(({ data }) => {
  //         setStats(data);
  //       })
  //         .catch((e) => {
  //           console.log("Error while fetching stats: ", e);
  //         })
  //     } catch (e) {
  //       console.log("Error while fetching stats: ", e)
  //     }
  //   }
  //   fetchStats();
  // }, [])

  if (recentOrdersLoading) return <CircularLoader />;

  return (
    <Box
      sx={{
        background: "#fbfbfb",
        paddingY: "20px",
        paddingLeft: "24px",
        paddingRight: "20px",
      }}
    >
      <Details
        setDateDropdownLabel={setDateDropdownLabel}
        dateDropdownLabel={dateDropdownLabel}
        date={date}
        setDate={setDate}
      />
      <Box sx={{ width: "100%", marginX: "auto" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: { xs: "column", lg: "row" },
            gap: "22px",
            justifyContent: "space-between",
          }}
        >
          <SalesOrdersDetails stats={statistics} />
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column", md: "row", lg: "column" },
              gap: "20px",
              width: "100%",
              maxWidth: { xs: "100%", md: "99%", lg: "50%" },
            }}
          >
            <SetupGuide />
            <AddOns />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", xl: "space-between" },
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "26px", md: "20px", lg: "26px" },
            marginTop: "26px",
            width: { xs: "100%", md: "100%", xl: "100%" },
          }}
        >
          <OrderRate orders={orders} />
          <PaymentTypes payments={payments} />
        </Box>
        <Box
          sx={{
            // width:'100%',
            display: "flex",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "33px", md: "26px", lg: "26px" },
            marginTop: "33px",
            // marginX:'auto',
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <PopularItems popularItems={popularItems} />
          <RecentOrders recentOrders={recentOrders} />
        </Box>
        <Box
          sx={{
            display: "flex",
            width: "100%",
            flexDirection: { xs: "column", md: "row" },
            gap: { xs: "32px", md: "26px", lg: "26px" },
            marginTop: "32px",
          }}
        >
          <Activity activityForDashboard={activityForDashboard} />
          <Customers customers={customers} />
        </Box>
      </Box>
    </Box>
  );
};

export default DashboardMain;
