import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  Typography,
} from "@mui/material";
import React, { useEffect } from "react";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useQuery } from "@apollo/client";
import { GET_SALES_TAX } from "../../graphicalQl/usequery/settingQuery";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { setName } from "../../store";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
//create function componet
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};
interface Props {
  formik: any;
}
const TaxElementModal = (props: Props) => {
  const { formik } = props;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { taxList, nonTaxable, location, productIdByEdit } = useSelector(
    (state: any) => state.app
  );
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClick = () => {
    handleOpen();
  };
  const { t } = useTranslation()
  const [taxData, setTaxData] = React.useState<any[]>([]);
  const { data } = useQuery(GET_SALES_TAX, {
    variables: {
      storeIds:
        location.length > 0
          ? JSON.stringify(location)
          : JSON.stringify([+locationId]),
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (!taxList) {
      if (data && data.taxesList) {
        const list = data.taxesList.edges.map((each: any) => ({
          id: each.node.taxId,
          taxName: each.node.taxName,
          taxRate: each.node.taxPercentage,
          locationId: each.node.store && each.node.store.storeId,
          locationName: each.node.store && each.node.store.storeName,
          isChecked: false,
        }));
        setTaxData(list);
      }
    }
  }, [data]);
  const [nonTaxableValue, setNonTaxable] = React.useState(nonTaxable);
  const handleCheckBox = (e: any, id: number) => {
    const target = e.target as HTMLInputElement;
    const updatedTaxData = taxData.map((each: any) => {
      if (each.id === id) {
        return { ...each, isChecked: target.checked };
      }
      return each;
    });
    setTaxData(updatedTaxData);
  };
  const handleClose = () => {
    setOpen(false);
    // setTaxData(taxData.map((each: any) => ({ ...each, isChecked: false })))
  };
  const dispatch = useDispatch();
  const handleSaveButton = () => {
    if (nonTaxableValue) {
      formik.setFieldValue("taxList", []);
      setOpen(false);
      formik.setFieldValue("nonTaxable", true);
      dispatch(setName("taxList", []));
      dispatch(setName("nonTaxable", nonTaxableValue));
      setTaxData(taxData.map((each: any) => ({ ...each, isChecked: false })));
    } else {
      const selectedTax =
        taxData &&
        taxData
          .filter((each) => each.isChecked)
          .map((item) => ({ tax_id: item.id, location_id: +item.locationId }));
      formik.setFieldValue("taxList", selectedTax);
      setOpen(false);
      dispatch(setName("taxList", selectedTax));
      dispatch(setName("nonTaxable", nonTaxableValue));
    }
  };
  // if (taxList && taxList.length > 0) {
  //     const selectedTax = taxData.map((each) => {
  //         const isExist = taxList.find((item: any) => +item === each.id)
  //         if (isExist) {
  //             return { ...each, isChecked: true }
  //         }
  //         return each
  //     })
  //     setTaxData(selectedTax)
  // }
  // if (nonTaxable) {
  //     setNonTaxable(nonTaxable)
  // }
  useEffect(() => {
    if (taxList) {
      let taxIds;
      if (taxList && taxList.length > 0) {
        taxIds =
          taxList &&
          taxList.map((each: any) => each.tax_id && each.tax_id.toString());
      } else {
        taxIds = taxList && taxList.map((each: any) => each && each.toString());
      }

      if (data && data.taxesList) {
        const list = data.taxesList.edges.map((each: any) => ({
          id: each.node.taxId,
          taxName: each.node.taxName,
          taxRate: each.node.taxPercentage,
          locationId: each.node.store && each.node.store.storeId,
          locationName: each.node.store && each.node.store.storeName,
          isChecked: taxIds.includes(each.node.taxId.toString()),
        }));

        setTaxData(list);
      }
    }
    // if (nonTaxable) {
    //     setNonTaxable(nonTaxable)
    // }
  }, [data, taxList]);
  return (
    <Box>
      <Box
        onClick={handleClick}
        className="tax-container"
        sx={{ width: "100%", justifyContent: "space-between" }}
      >
        <Box sx={{ display: "flex" }}>
          {taxData.filter((each) => each.isChecked).length === 0 ? (
            <Typography className="normal-text-for-textField">{t("product.tax")}</Typography>
          ) : (
            <Typography className="normal-text">
              {taxData.filter((each) => each.isChecked).length} {t("product.taxes")}
            </Typography>
          )}
        </Box>
        <ArrowDropDownIcon />
      </Box>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton className="exit-container" onClick={handleClose}>
                <CloseIcon className="exitIcon" />
              </IconButton>
              <Typography className="heavyHeader">{t("product.taxes")}</Typography>
              <Typography></Typography>
            </Box>
            <Divider sx={{ marginTop: "12px", marginBottom: "36px" }} />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "100%",
              }}
            >
              <Box sx={{ width: "60%" }}>
                <FormControlLabel
                  value="end"
                  control={
                    <Switch
                      color="warning"
                      checked={nonTaxableValue}
                      onChange={(e) => {
                        const target = e.target as HTMLInputElement;
                        setNonTaxable(target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography className="normalHeader">
                      {t("product.non_taxable_product")}
                    </Typography>
                  }
                  labelPlacement="end"
                />
                {!nonTaxableValue &&
                  taxData.map((each: any) => (
                    <Box key={each.id}>
                      <Divider sx={{ marginTop: "6px", marginBottom: "6px" }} />
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="warning"
                              checked={each.isChecked}
                              onClick={(e) => {
                                handleCheckBox(e, each.id);
                              }}
                            />
                          }
                          label={
                            <Typography className="dataGrid-table-cell-less-wt">{`${each.taxName}(${each.taxRate}%)`}</Typography>
                          }
                          labelPlacement="end"
                        />
                        <Typography className="dataGrid-table-cell-less-wt">
                          {each.locationName}
                        </Typography>
                      </Box>
                    </Box>
                  ))}
                <Typography className="normal-text" sx={{ marginTop: "18px" }}>
                  {t("product.tax_para")}
                </Typography>
              </Box>
            </Box>
            <Box sx={{ textAlign: "right" }}>
              <Button
                className="main-button"
                onClick={() => handleSaveButton()}
              >
                {t("product.save")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};
export default TaxElementModal;
