import {
  TextField,
  Box,
  Button,
  IconButton,
  Tooltip,
  Checkbox,
  FormControlLabel,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import AccessTimeIcon from "@mui/icons-material/AccessTime";
import { useTranslation } from "react-i18next";
import RenderGoogleMap from "../utils/googleMaps";
import AlertPopupForLastRuleDelete from "./alertPopupForLastRuleDelete.tsx";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ComfirmationPopup from "./comfirmationPopup.tsx";
import {
  DELIVERY_RULES_LIST,
  DEFAULT_ZONE,
} from "../../graphicalQl/usequery/deliveryManagementQuery.tsx";
import {
  ADD_DELIVERY_RULE,
  UPDATE_DELIVERY_RULE,
  DELETE_DELIVERY_RULE,
  CHANGE_DEFAULT_DELIVERY_RULE,
} from "../../graphicalQl/mutation/deliveryManagementMutation.tsx";
import { useQuery } from "@apollo/client";
import { useMutation } from "@apollo/client";
import uuid from "react-uuid";
import "./distanceOrZoneWise.css";
import DeletePopup from "../popups/deletePopup.tsx";
import CircularLoader from "../../loader/loader.tsx";
import { toast } from "react-toastify";
import NodataImage from "../../utils/nodataImage.tsx";
// import RenderGoogleMap from '../utils/deletedGoogleMap.tsx';

interface Location {
  lat: number;
  lng: number;
}

interface DistanceOrZonewise {
  deliverySettingId: string | null;
  updatedDefaultZone: string | null;
  updatedDefaultZoneForStar: string | null;
  editId: undefined | string;
  storeLocation: Location | null;
  locationKey: string | null;
}

interface State1 {
  areaName: any;
  color: any;
  distance: any;
  cartAmount: any;
  lowCartSurcharge: any;
  shippingRate: any;
  timeTaken: any;
  postCodesForZone: any;
  startTime: any;
  endTime: any;
  circleButton: any;
}

function DistanceOrZoneWise(props: DistanceOrZonewise) {
  const {
    deliverySettingId,
    updatedDefaultZone,
    updatedDefaultZoneForStar,
    editId,
    locationKey,
    storeLocation,
  } = props;
  const [zones, setZones] = useState<any[]>([]);
  const [initialZones, setInitialZones] = useState<any[]>([]);
  const { t } = useTranslation();
  const [handleAddButton, setHandleAddButton] = useState<boolean>(false);
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const {
    loading: deliveryRulesListLoading,
    error: deliveryRulesListError,
    data: deliveryRulesListData,
  } = useQuery(DELIVERY_RULES_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      settingId: deliverySettingId && +deliverySettingId,
      distanceOrder: true,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (deliveryRulesListData) {
      const { deliveryRules } = deliveryRulesListData;
      const { edges } = deliveryRules;
      const filteredData =
        edges &&
        edges.length > 0 &&
        edges.map((eachRule: any) => ({
          areaName: eachRule.node.areaName,
          cartAmount: eachRule.node.cartAmount,
          color: eachRule.node.colourCode,
          deliveryRuleId: eachRule.node.deliveryRuleId,
          deliveryTime: JSON.parse(eachRule.node.deliveryTime),
          startTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["from_time"],
          endTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["to_time"],
          distance: eachRule.node.distance,
          isTimeShow: eachRule.node.deliveryTime ? true : false,
          originalDistance: eachRule.node.distance,
          lowCartSurcharge: eachRule.node.extraShippingRate,
          postCodesForZone: eachRule.node.postCodesForZone
            ? JSON.parse(eachRule.node.postCodesForZone).join(",")
            : [],
          shippingRate: eachRule.node.shippingRate,
          timeTaken: eachRule.node.timeTaken,
          // zoneName:eachRule.node.zoneName,
          zoneShape: eachRule.node.zoneShape,
          zoneType: eachRule.node.zoneType,
          isEdited: false,
          isNew: false,
          isVisible: false,
          circleButton: "Circle",
        }));

      const filteredZonesList = filteredData
        ? filteredData.filter((eachZone: any) => eachZone.zoneType === "zone")
        : [];
      setZones(filteredZonesList);
      setInitialZones(filteredZonesList);
    }
  }, [deliveryRulesListData]);

  const [changeDefaultDeliveryRule] = useMutation(
    CHANGE_DEFAULT_DELIVERY_RULE,
    {
      onCompleted: (data) => {
        if (data) {
          const { addDeliveryRule } = data;
          const { success } = addDeliveryRule;
          if (success) {
            setHandleAddButton(true);
            // setNewRowCheckboxValue(initialAddTimeRow)
            // setActiveDistanceButton(false)
            // setHandleAddButton(false)
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "businessClient" },
      refetchQueries: [DEFAULT_ZONE],
    }
  );

  const [addingDeliveryRule] = useMutation(ADD_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { addDeliveryRule } = data;
        const { success } = addDeliveryRule;
        if (success) {
          setHandleAddButton(false);
          toast.success(
            <div>
              <Typography>{t("toast.created")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [updateDeliveryRule] = useMutation(UPDATE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
        const { editDeliveryRule } = data;
        const { success } = editDeliveryRule;
        if (success) {
          // setNewRowCheckboxValue(initialAddTimeRow)
          // setActiveDistanceButton(false)
          setHandleAddButton(false);
          toast.success(
            <div>
              <Typography>{t("toast.updated")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const [deleteDeliveryRule] = useMutation(DELETE_DELIVERY_RULE, {
    onCompleted: (data) => {
      if (data) {
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DELIVERY_RULES_LIST],
  });

  const formik = useFormik({
    initialValues: {
      formikRows: zones?.map((zone: State1) => ({
        areaName: zone.areaName || "",
        color: zone.color || "#333333",
        distance: zone.distance || "",
        cartAmount: zone.cartAmount || "",
        lowCartSurcharge: zone.lowCartSurcharge || "",
        shippingRate: zone.shippingRate || "",
        timeTaken: zone.timeTaken || "",
        postCodesForZone: zone.postCodesForZone || "",
        startTime: zone.startTime || "",
        endTime: zone.endTime || "",
        circleButton: "Circle",
      })),
    },
    validationSchema: Yup.object({
      formikRows: Yup.array().of(
        Yup.object({
          areaName: Yup.string().required("Required"),
          distance: Yup.string().required("Required"),
          cartAmount: Yup.string().required("Required"),
          shippingRate: Yup.string().required("Required"),
          postCodesForZone: Yup.string().required("Required"),

          endTime: Yup.string().test(
            "is-greater",
            "End time must be greater than start time",
            function (value) {
              const { startTime } = this.parent;

              if (startTime && value) {
                return startTime <= value;
              }
              return true; // Return true if either field is empty
            }
          ),
        })
      ),
    }),
    onSubmit: (values) => {
      // handlePopupTimeDoneButton()
    },
  });

  const [alertPopupForLastRuleDelete, setAlertPopupForLastRuleDelete] =
    useState<boolean>(false);

  const handleCloseForLastDeleteRule = () => {
    setAlertPopupForLastRuleDelete(false);
  };

  const [alignment, setAlignment] = React.useState("Circle");
  const handleAddZoneClick = () => {
    const distance =
      zones.length > 0 ? zones[zones.length - 1].distance + 1 : 1;
    setZones((prev: any) => [
      ...prev,
      {
        deliveryRuleId: uuid(),
        areaName: `Zone ${zones.length + 1}`,
        color: "#707070",
        distance: distance,
        originalDistance: distance,
        cartAmount: "",
        shippingRate: "",
        extraShippingRate: "",
        timeTaken: "",
        postCodes: "",
        postCodesForZone: ["500081", "50018"],
        startTime: "",
        endTime: "",
        isNew: true,
        isTimeShow: false,

        circleButton: "Circle",
      },
    ]);

    setHandleAddButton(true);
  };

  const handleChangeButton = (indexFromIteration: number, e: any) => {
    const filterData = zones?.map((each: any, index: number) => {
      if (index === indexFromIteration) {
        return {
          ...each,
          circleButton:
            e.target.value === undefined ? "Circle" : e.target.value,
        };
      }
      return each;
    });
    setZones(filterData);
  };

  const handleCancelButton = (id: string, e: any) => {
    e.preventDefault();
    const filteredData = initialZones.filter(
      (each: any) => each.deliveryRuleId === id
    );
    const updatingData = zones?.map((each: any) => {
      if (each.deliveryRuleId === id) {
        return { ...each, ...filteredData[0] };
      }
      return each;
    });
    setZones(updatingData);
  };

  useEffect(() => {
    formik.setFieldValue("formikRows", zones);
  }, [zones]);

  useEffect(() => {
    if (zones?.length === 0) {
      setHandleAddButton(false);
    }
  }, [zones]);

  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);

  const handleClose = () => {
    setDeletePopupOpen(false);
    setDeleteId(null);
    setIsNew(null);
  };

  const [deleteId, setDeleteId] = useState<string | null>(null);
  const [isNew, setIsNew] = useState<boolean | null>(null);
  const handleDeleteIconAndPopup = (id: string, isNew: boolean) => {
    if (zones.length === 1) {
      setAlertPopupForLastRuleDelete(true);
      setDeleteId(null);
    } else {
      setDeleteId(id);
      setIsNew(isNew);
      setDeletePopupOpen(true);
    }
  };

  const toggleVisibility = (zoneId: string) => {
    setZones((prevZones) =>
      prevZones.map((zone) =>
        zone.deliveryRuleId === zoneId
          ? {
              ...zone,
              isVisible: !zone.isVisible,
              distance: !zone.isVisible ? 0 : zone.originalDistance,
            }
          : zone
      )
    );
  };

  const [checkboxOpen, setCheckboxOpen] = useState<boolean>(false);
  const [checkkboxState, setCheckkboxState] = useState<boolean>(false);
  const hadleCheckboxForDefault = (isChecked: boolean) => {
    if (isChecked) {
      setCheckboxOpen(true);
    }
    setCheckkboxState(isChecked);
  };

  const handleSaveButton = () => {
    changeDefaultDeliveryRule({
      variables: {
        deliverySetting: deliverySettingId && +deliverySettingId,
        zoneType: "zone",
        isDefault: true,
        locationId: editId && +editId,
      },
    });
    handleCheckboxPopupClose();
  };
  const handleCheckboxPopupClose = () => {
    setCheckboxOpen(false);
    setCheckkboxState(false);
  };
  const handleCheckboxPopupOpen = () => {
    setCheckboxOpen(true);
  };

  const handleYesRemoveButton = () => {
    if (isNew) {
      setZones((prev: any) =>
        prev.filter((eachZone: any) => {
          return eachZone.deliveryRuleId !== deleteId;
        })
      );
      setHandleAddButton(false);
    } else {
      deleteDeliveryRule({
        variables: {
          deliveryRule: deleteId && +deleteId,
        },
      });
      setHandleAddButton(false);
    }
    handleClose();
  };

  const handleSubmit = (values: any, zoneIndex: any) => {
    const { isNew } = values;
    const createdAndUpdatedObject = { ...values };
    if (isNew) {
      addingDeliveryRule({
        variables: {
          deliverySetting: deliverySettingId && +deliverySettingId,
          postCodesForZone: JSON.stringify(
            createdAndUpdatedObject.postCodesForZone.split(",")
          ),
          shippingRate: +createdAndUpdatedObject.shippingRate,
          areaName: createdAndUpdatedObject.areaName,
          distance: +createdAndUpdatedObject.distance,
          deliveryTime:
            createdAndUpdatedObject.startTime && createdAndUpdatedObject.endTime
              ? JSON.stringify({
                  from_time: createdAndUpdatedObject.startTime,
                  to_time: createdAndUpdatedObject.endTime,
                })
              : null,
          cartAmount: +createdAndUpdatedObject.cartAmount,
          colourCode: createdAndUpdatedObject.color,
          extraShippingRate: +createdAndUpdatedObject.lowCartSurcharge,
          timeTaken: +createdAndUpdatedObject.timeTaken,
          zoneType: "zone",
        },
      });
    } else {
      updateDeliveryRule({
        variables: {
          deliveryRule:
            createdAndUpdatedObject.deliveryRuleId &&
            +createdAndUpdatedObject.deliveryRuleId,
          postCodesForZone: JSON.stringify(
            createdAndUpdatedObject.postCodesForZone.split(",")
          ),
          shippingRate: +createdAndUpdatedObject.shippingRate,
          areaName: createdAndUpdatedObject.areaName,
          distance: +createdAndUpdatedObject.distance,
          deliveryTime:
            createdAndUpdatedObject.startTime && createdAndUpdatedObject.endTime
              ? JSON.stringify({
                  from_time: createdAndUpdatedObject.startTime,
                  to_time: createdAndUpdatedObject.endTime,
                })
              : null,
          cartAmount: +createdAndUpdatedObject.cartAmount,
          colourCode: createdAndUpdatedObject.color,
          extraShippingRate: +createdAndUpdatedObject.lowCartSurcharge,
          timeTaken: +createdAndUpdatedObject.timeTaken,
          zoneType: "zone",
          isDefault: updatedDefaultZone === "zone" ? true : false,
        },
      });
    }
  };

  const handleTimeInZone = (indexFromIteration: number) => {
    const filterZones = formik.values.formikRows.map(
      (each: any, index: number) => {
        if (index === indexFromIteration) {
          return {
            ...each,
            isTimeShow: true,
          };
        } else {
          return each;
        }
      }
    );
    setZones(filterZones);
  };

  const handleZoneSubmit = async (index: number, e: any) => {
    e.preventDefault();
    formik.validateForm().then((errors) => {
      const hasErrors = Object.keys(errors).length > 0;
      const zoneErrors = errors.formikRows ? errors.formikRows[index] : null;

      if (!hasErrors || !zoneErrors) {
        handleSubmit(formik.values.formikRows[index], index);
      } else {
        formik.setTouched({
          formikRows: formik.values.formikRows.map((zone, i) =>
            i === index
              ? Object.keys(zoneErrors).reduce(
                  (acc, field) => ({ ...acc, [field]: true }),
                  {}
                )
              : {}
          ),
        });
      }
    });
  };

  const imperialCountries: string[] = ["US", "LR", "MM", "GB"];

  // Function to get the distance unit based on the country code
  function getDistanceUnit(countryCode: string): string {
    // Check if the country code is in the list of imperial countries
    if (imperialCountries.includes(countryCode.toUpperCase())) {
      return t("buttons.miles"); // Imperial units (miles)
    }

    // Default to metric units (kilometers)
    return t("buttons.km");
  }

  // Example usage
  const countryCode: string | null = sessionStorage.getItem("country");
  const distanceUnit: string = getDistanceUnit(
    countryCode === null ? "" : countryCode
  );

  return (
    <Box sx={{ padding: "15px", width: "100%" }}>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleClose}
        handleOpen={() => setDeletePopupOpen(true)}
        handleSaveButton={handleYesRemoveButton}
        mainText={`${t("buttons.are_you_sure_delete")} ${t("deliveries.rule")}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />
      <AlertPopupForLastRuleDelete
        open={alertPopupForLastRuleDelete}
        handleClose={handleCloseForLastDeleteRule}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={updatedDefaultZone === "zone"}
              onChange={(e) => hadleCheckboxForDefault(e.target.checked)}
              color="warning"
              sx={{ "& .MuiSvgIcon-root": { fontSize: "2rem" } }}
            />
          }
          label={t("deliveries.make_this_as_default")}
        />
        <Button
          disabled={handleAddButton}
          onClick={handleAddZoneClick}
          color="warning"
          sx={{ textTransform: "none", marginRight: "2px" }}
          variant="contained"
        >
          {t("deliveries.add_zone")}
        </Button>
      </Box>

      <ComfirmationPopup
        accessText={t("deliveries.zone_wise")}
        handleSaveButton={handleSaveButton}
        open={checkboxOpen}
        handleClose={handleCheckboxPopupClose}
        handleOpen={handleCheckboxPopupOpen}
      />

      {deliveryRulesListLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            width: "100%",
            display: "flex",
            gap: "1%",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <Box sx={{ width: "55%" }}>
            <RenderGoogleMap
              locationKey={locationKey}
              storeLocation={storeLocation}
              setRows={setZones}
              rows={zones ? zones : []}
            />
          </Box>

          <Box
            sx={{
              width: "45%",
              padding: "10px",
              backgroundColor: "#f5f5f5",
              overflowY: "scroll",
              height: "395px",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {zones.length > 0 ? (
              zones.map((eachZone: any, index: any) => (
                <Box
                  key={eachZone.deliveryRuleId}
                  sx={{ marginBottom: "10px" }}
                >
                  <form onSubmit={formik.handleSubmit}>
                    <Accordion elevation={2}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        {/* <Button variant='text' disabled={eachZone.isVisible}  sx={{textTransform:"none"}}
            className={(eachZone.isVisible)?"distance-wise-accrodina-header-disable":"distance-wise-accrodina-header"}
            >{!eachZone.isNew?eachZone.areaName:`Zone ${zones.length}`}</Button> */}
                        <Button
                          variant="text"
                          disabled={eachZone.isVisible}
                          sx={{ textTransform: "none" }}
                          className={
                            eachZone.isVisible
                              ? "distance-wise-accrodina-header-disable"
                              : "distance-wise-accrodina-header"
                          }
                        >
                          {eachZone.areaName}
                        </Button>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          display: "flex",
                          width: "100%",
                          flexDirection: "column",
                          justifyContent: "center",
                          alignItems: "center",
                          padding: "10px",
                        }}
                      >
                        <Box sx={{ width: "60%", marginBottom: "15px" }}>
                          <ToggleButtonGroup
                            value={
                              formik.values &&
                              formik.values.formikRows &&
                              formik.values.formikRows[index] &&
                              formik.values.formikRows[index].circleButton
                            }
                            exclusive
                            fullWidth
                            onChange={(e) => handleChangeButton(index, e)}
                          >
                            <ToggleButton
                              className={`distance-wise-circle-button ${
                                eachZone.circleButton === "Circle" &&
                                "distance-wise-group-circle-button"
                              }`}
                              sx={{ textTransform: "none" }}
                              value="Circle"
                            >
                              <Typography className="distance-wise-circle-button">
                                {t("deliveries.circle")}
                              </Typography>
                            </ToggleButton>
                            S
                            <ToggleButton
                              className={`distance-wise-circle-button ${
                                eachZone.circleButton === "Shape" &&
                                "distance-wise-group-circle-button"
                              }`}
                              sx={{ textTransform: "none" }}
                              value="Shape"
                            >
                              <Typography className="distance-wise-circle-button">
                                {t("deliveries.shape")}
                              </Typography>
                            </ToggleButton>
                          </ToggleButtonGroup>
                        </Box>
                        <form>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <Box sx={{ width: "55%" }}>
                              <TextField
                                label={
                                  <Typography className="distance-wise-label-text">
                                    {t("deliveries.zone")}
                                  </Typography>
                                }
                                color="warning"
                                value={
                                  (formik.values &&
                                    formik.values.formikRows &&
                                    formik.values.formikRows[index] &&
                                    formik.values.formikRows[index].areaName) ||
                                  ""
                                }
                                name={`formikRows[${index}].areaName`}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                sx={{ width: "90%" }}
                              />
                              {formik.touched?.formikRows &&
                                formik.errors?.formikRows &&
                                formik.touched.formikRows[index]?.areaName &&
                                (
                                  formik.errors
                                    .formikRows as FormikErrors<State1>[]
                                )[index]?.areaName && (
                                  <Typography className="create-a-user-formik-label">
                                    {(
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.areaName && t("error.required")}
                                  </Typography>
                                )}
                            </Box>

                            <TextField
                              label={t("deliveries.color")}
                              value={
                                formik.values &&
                                formik.values.formikRows &&
                                formik.values.formikRows[index] &&
                                formik.values.formikRows[index].color
                              }
                              name={`formikRows[${index}].color`}
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              onBlur={formik.handleBlur}
                              color="warning"
                              variant="outlined"
                              defaultValue="#hexCode"
                              sx={{ width: "48%" }}
                              type="color"
                            />
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <Box sx={{ width: "48%" }}>
                              <TextField
                                value={
                                  (formik.values &&
                                    formik.values.formikRows &&
                                    formik.values.formikRows[index] &&
                                    formik.values.formikRows[index].distance) ||
                                  ""
                                }
                                name={`formikRows[${index}].distance`}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                                onBlur={formik.handleBlur}
                                color="warning"
                                sx={{ width: "100%" }}
                                label={
                                  <Typography className="distance-wise-label-text">
                                    {t("deliveries.distance")}
                                  </Typography>
                                }
                                placeholder={`${t("deliveries.distance")} ${t(
                                  "deliveries.in"
                                )} ${distanceUnit}`}
                              />
                              {formik.touched?.formikRows &&
                                formik.errors?.formikRows &&
                                formik.touched.formikRows[index]?.distance &&
                                (
                                  formik.errors
                                    .formikRows as FormikErrors<State1>[]
                                )[index]?.distance && (
                                  <Typography className="create-a-user-formik-label">
                                    {(
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.distance && t("error.required")}
                                  </Typography>
                                )}
                            </Box>
                            <Box sx={{ width: "47%" }}>
                              <TextField
                                value={
                                  (formik.values &&
                                    formik.values.formikRows &&
                                    formik.values.formikRows[index] &&
                                    formik.values.formikRows[index]
                                      .cartAmount) ||
                                  ""
                                }
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol}
                                    </InputAdornment>
                                  ),
                                }}
                                // helperText={formik.touched.formikRows && formik.touched.formikRows[index]?.cartAmount && formik.errors && typeof formik.errors === 'object' && formik.errors.formikRows && typeof formik.errors.formikRows === 'object' && formik.errors.formikRows[index]?.cartAmount}
                                name={`formikRows[${index}].cartAmount`}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                                onBlur={formik.handleBlur}
                                color="warning"
                                sx={{ width: "100%" }}
                                label={
                                  <Typography className="distance-wise-label-text">
                                    {t("deliveries.cart_amount")}
                                  </Typography>
                                }
                              />
                              {formik.touched?.formikRows &&
                                formik.errors?.formikRows &&
                                formik.touched.formikRows[index]?.cartAmount &&
                                (
                                  formik.errors
                                    .formikRows as FormikErrors<State1>[]
                                )[index]?.cartAmount && (
                                  <Typography className="create-a-user-formik-label">
                                    {(
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.cartAmount && t("error.required")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <TextField
                              value={
                                (formik.values &&
                                  formik.values.formikRows &&
                                  formik.values.formikRows[index] &&
                                  formik.values.formikRows[index]
                                    .lowCartSurcharge) ||
                                ""
                              }
                              name={`formikRows[${index}].lowCartSurcharge`}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    {currencySymbol}
                                  </InputAdornment>
                                ),
                              }}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formik.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formik.handleBlur}
                              color="warning"
                              sx={{ width: "48%" }}
                              label={
                                <Typography className="distance-wise-label-text">
                                  {t("deliveries.low_car_surcharge")}
                                </Typography>
                              }
                            />

                            <Box
                              sx={{
                                width: "47%",
                              }}
                            >
                              <TextField
                                value={
                                  (formik.values &&
                                    formik.values.formikRows &&
                                    formik.values.formikRows[index] &&
                                    formik.values.formikRows[index]
                                      .shippingRate) ||
                                  ""
                                }
                                name={`formikRows[${index}].shippingRate`}
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      {currencySymbol}
                                    </InputAdornment>
                                  ),
                                }}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                                onBlur={formik.handleBlur}
                                color="warning"
                                sx={{ width: "100%" }}
                                label={
                                  <Typography className="distance-wise-label-text">
                                    {t("deliveries.shipping_rate")}
                                  </Typography>
                                }
                              />
                              {formik.touched?.formikRows &&
                                formik.errors?.formikRows &&
                                formik.touched.formikRows[index]
                                  ?.shippingRate &&
                                (
                                  formik.errors
                                    .formikRows as FormikErrors<State1>[]
                                )[index]?.shippingRate && (
                                  <Typography className="create-a-user-formik-label">
                                    {(
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.shippingRate &&
                                      t("error.required")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>

                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              justifyContent: "space-between",
                              marginTop: "15px",
                              marginBottom: "15px",
                            }}
                          >
                            <TextField
                              value={
                                (formik.values &&
                                  formik.values.formikRows &&
                                  formik.values.formikRows[index] &&
                                  formik.values.formikRows[index].timeTaken) ||
                                ""
                              }
                              name={`formikRows[${index}].timeTaken`}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (!val || /^[0-9]+$/.test(val)) {
                                  formik.handleChange(e);
                                }
                              }}
                              type="text"
                              onBlur={formik.handleBlur}
                              color="warning"
                              sx={{ width: "48%" }}
                              label={
                                <Typography className="distance-wise-label-text">
                                  {t("deliveries.time_taken_for_delivery")}
                                </Typography>
                              }
                            />
                            <Box sx={{ width: "47%" }}>
                              <TextField
                                color="warning"
                                value={
                                  (formik.values &&
                                    formik.values.formikRows &&
                                    formik.values.formikRows[index] &&
                                    formik.values.formikRows[index]
                                      .postCodesForZone) ||
                                  ""
                                }
                                name={`formikRows[${index}].postCodesForZone`}
                                onChange={(e) => {
                                  formik.handleChange(e);
                                }}
                                onBlur={formik.handleBlur}
                                sx={{ width: "100%" }}
                                label={
                                  <Typography className="distance-wise-label-text">
                                    {t("deliveries.postcodes")}
                                  </Typography>
                                }
                              />
                              {formik.touched?.formikRows &&
                                formik.errors?.formikRows &&
                                formik.touched.formikRows[index]
                                  ?.postCodesForZone &&
                                (
                                  formik.errors
                                    .formikRows as FormikErrors<State1>[]
                                )[index]?.postCodesForZone && (
                                  <Typography className="create-a-user-formik-label">
                                    {(
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.postCodesForZone &&
                                      t("error.required")}
                                  </Typography>
                                )}
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              width: "96%",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              marginTop: "20px",
                            }}
                          >
                            {eachZone.isTimeShow ? (
                              <>
                                <Box
                                  sx={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    width: "86%",
                                    marginLeft: "20px",
                                  }}
                                >
                                  <Box sx={{ marginRight: "10px" }}>
                                    <Typography>
                                      {t("deliveries.start_time")}
                                    </Typography>
                                    <TextField
                                      color="warning"
                                      type="time"
                                      value={
                                        (formik.values &&
                                          formik.values.formikRows &&
                                          formik.values.formikRows[index] &&
                                          formik.values.formikRows[index]
                                            .startTime) ||
                                        ""
                                      }
                                      name={`formikRows[${index}].startTime`}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                      }}
                                      onBlur={formik.handleBlur}
                                      sx={{ width: "150px" }}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography>
                                      {t("deliveries.end_time")}
                                    </Typography>
                                    <TextField
                                      color="warning"
                                      type="time"
                                      value={
                                        (formik.values &&
                                          formik.values.formikRows &&
                                          formik.values.formikRows[index] &&
                                          formik.values.formikRows[index]
                                            .endTime) ||
                                        ""
                                      }
                                      name={`formikRows[${index}].endTime`}
                                      onChange={(e) => {
                                        formik.handleChange(e);
                                      }}
                                      onBlur={formik.handleBlur}
                                      sx={{ width: "150px" }}
                                    />
                                  </Box>
                                </Box>
                                <Box>
                                  {((formik.touched?.formikRows &&
                                    formik.errors?.formikRows &&
                                    formik.touched.formikRows[index]
                                      ?.endTime) ||
                                    (formik.touched?.formikRows &&
                                      formik.errors?.formikRows &&
                                      formik.touched.formikRows[index]
                                        ?.startTime)) &&
                                    (
                                      formik.errors
                                        .formikRows as FormikErrors<State1>[]
                                    )[index]?.endTime && (
                                      <Typography className="create-a-user-formik-label">
                                        {t("error.end_time")}
                                      </Typography>
                                    )}
                                </Box>
                              </>
                            ) : (
                              <Button
                                onClick={() => handleTimeInZone(index)}
                                className="distance-wise-clock-button"
                                color="warning"
                                sx={{
                                  marginLeft: "12px",
                                }}
                              >
                                <AccessTimeIcon sx={{ marginRight: "10px" }} />
                                {t("deliveries.set_time")}
                              </Button>
                            )}
                          </Box>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                              marginTop: "20px",
                              width: "100%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                width: "40%",
                              }}
                            >
                              <Tooltip title="Delete" placement="top" arrow>
                                <IconButton
                                  onClick={() =>
                                    handleDeleteIconAndPopup(
                                      eachZone.deliveryRuleId,
                                      eachZone.isNew
                                    )
                                  }
                                  className="distance-wise-cross-icon-button"
                                >
                                  <DeleteIcon />
                                </IconButton>
                              </Tooltip>

                              {!eachZone.isNew &&
                                (eachZone.isVisible ? (
                                  <Tooltip
                                    title={t("deliveries.view_zone")}
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() =>
                                        toggleVisibility(
                                          eachZone.deliveryRuleId
                                        )
                                      }
                                      className="distance-wise-cross-icon-button"
                                    >
                                      <VisibilityIcon />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={t("deliveries.hide_zone")}
                                    placement="top"
                                    arrow
                                  >
                                    <IconButton
                                      onClick={() =>
                                        toggleVisibility(
                                          eachZone.deliveryRuleId
                                        )
                                      }
                                      className="distance-wise-cross-icon-button"
                                    >
                                      <VisibilityOffIcon />
                                    </IconButton>
                                  </Tooltip>
                                ))}
                            </Box>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "space-evenly",
                                alignItems: "center",
                                width: "50%",
                                gap: "2%",
                              }}
                            >
                              {!eachZone.isNew && (
                                <Button
                                  sx={{ width: "45%" }}
                                  onClick={(e) =>
                                    handleCancelButton(
                                      eachZone.deliveryRuleId,
                                      e
                                    )
                                  }
                                  className="distance-wise-cancel-button"
                                  color="warning"
                                  variant="outlined"
                                >
                                  {t("deliveries.cancel")}
                                </Button>
                              )}
                              <Button
                                type="submit"
                                sx={{ width: "45%" }}
                                onClick={(e) => handleZoneSubmit(index, e)}
                                className="distance-wise-save-button"
                                color="warning"
                                variant="contained"
                              >
                                {t("deliveries.save")}
                              </Button>
                            </Box>
                          </Box>
                        </form>
                        <Box></Box>
                      </AccordionDetails>
                    </Accordion>
                  </form>
                </Box>
              ))
            ) : (
              <NodataImage
                height="200px"
                width="200px"
                heightForContainer="40vh"
                fontSizeForNoDataText="14px"
              />
            )}
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default DistanceOrZoneWise;
