import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useQuery } from "@apollo/client";
import { COUSTOMER_GROUPS } from "../../graphicalQl/usequery/discountQuery";
import { Divider, IconButton, InputAdornment, TextField } from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import "./assignItemsModal.css";
import { styled } from "@mui/system";

import SearchIcon from "@mui/icons-material/Search";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

interface Row {
  id: string;
  productCount: number;
  slugName: string;
}
export interface CustomerRef {
  deletCustomer: (id: string) => void;
}

interface Props {
  openCustomerModal: boolean;
  handleOpenCustomerModal: () => void;
  setFinalCoustomerList: React.Dispatch<
    React.SetStateAction<
      { type: string; id: string; price: string; name: string }[]
    >
  >;
  formik: any;
}

const CustomerModal = React.forwardRef<CustomerRef, Props>(
  (props: Props, ref) => {
    const CustomStyles = styled("div")({
      "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
        color: "#333 !important",
        fontFamily: "Poppins, sans-serif !important",
        fontSize: "0.875rem !important",
        fontStyle: "normal !important",
        fontWeight: "600 !important",
        lineHeight: "21px !important",
        textTransform: "capitalize !important",
      },
      "& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus":
        {
          outline: "solid #1976d2 0px !important",
        },
      "& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus-within":
        {
          outline: "solid #1976d2 0px !important",
        },
    });

    const {
      openCustomerModal,
      handleOpenCustomerModal,
      setFinalCoustomerList,
      formik,
    } = props;
    const [open, setOpen] = React.useState(openCustomerModal);
    React.useEffect(() => {
      setOpen(openCustomerModal);
    }, [openCustomerModal]);

    const handleClose = () => {
      handleOpenCustomerModal();
      setOpen(false);
    };

    const businessId: string = sessionStorage.getItem("businessId") as string;
    const { data: customerData } = useQuery(COUSTOMER_GROUPS, {
      context: { clientName: "customerClient" },
      fetchPolicy: "cache-and-network",
      variables: { businessId: +businessId },
    });
    const [customerList, setCustomerList] = React.useState<Row[]>([]);
    React.useEffect(() => {
      if (customerData && customerData.customerGroupList) {
        const list = customerData.customerGroupList.edges.map(
          (item: any, index: number) => ({
            id: item.node.groupId,
            slugName: item.node.groupName,
            productCount: JSON.parse(item.node.customerGroup).length,
          })
        );

        setCustomerList(list);
      }
    }, [customerData]);
    const columns: GridColDef[] = [
      {
        field: "slugName",
        headerName: "Customer Group",
        headerClassName: "header-text",
        cellClassName: "cell-text",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
      },
      {
        field: "productCount",
        headerName: "Customers",
        headerAlign: "center",
        headerClassName: "header-text",
        cellClassName: "cell-text2",
        flex: 1,
        editable: false,
        disableColumnMenu: true,
        align: "center",
        renderCell(params: any) {
          return <Typography>{params.value} Customers</Typography>;
        },
      },
    ];

    const [rowSelectionModel, setRowSelectionModel] =
      React.useState<GridRowSelectionModel>([]);
    const [searchValue, setSearchValue] = React.useState("");
    const searchedTableList = customerList.filter((each) =>
      each.slugName.toLowerCase().includes(searchValue.toLowerCase())
    );

    const handleDone = () => {
      const checkedSecondMenuData = customerList.filter((customer) =>
        rowSelectionModel.includes(customer.id)
      );

      const finalCustomerData = checkedSecondMenuData.map((customer) => ({
        type: "customer",
        id: customer.id,
        price: customer.productCount + " customers",
        name: customer.slugName,
      }));
      setFinalCoustomerList(finalCustomerData);
      formik.setFieldValue("customerGroup", rowSelectionModel);
    };

    React.useImperativeHandle(ref, () => ({
      deletCustomer: (id: string) => {
        const list = rowSelectionModel.filter((each) => each !== id);
        setRowSelectionModel(list);
        const checkedSecondMenuData = customerList.filter((customer) =>
          list.includes(+customer.id)
        );

        const finalCustomerData = checkedSecondMenuData.map((customer) => ({
          type: "customer",
          id: customer.id,
          price: customer.productCount + " customers",
          name: customer.slugName,
        }));
        setFinalCoustomerList(finalCustomerData);
        formik.setFieldValue("customerGroup", list);
      },
    }));

    const handleExitIcon = () => {
      handleClose();
      setRowSelectionModel([]);
    };

    React.useEffect(() => {
      const checkedSecondMenuData = customerList.filter(
        (customer) =>
          formik.values.customerGroup &&
          formik.values.customerGroup.includes(customer.id)
      );
      setRowSelectionModel(formik.values.customerGroup);
      const finalCustomerData = checkedSecondMenuData.map((customer) => ({
        type: "customer",
        id: customer.id,
        price: customer.productCount + " customers",
        name: customer.slugName,
      }));
      setFinalCoustomerList(finalCustomerData);
    }, [formik.values.customerGroup, customerList]);
    return (
      <div>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ padding: "10px 0px 0px 20px" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  sx={{
                    width: "40px",
                    height: "40px",
                    borderRadius: "4px",
                    color: "#333333",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    bgcolor: "#EEEEEE",
                    "& hover": { backgroundColor: "#FFF8F0" },
                  }}
                  onClick={handleExitIcon}
                >
                  <Box>
                    <CloseIcon sx={{ width: "24px", height: "24px" }} />
                  </Box>
                </IconButton>
                <Typography className="head-text">Select Customers</Typography>
                <Typography></Typography>
              </Box>
              <Divider
                sx={{ marginTop: "0.75rem", marginBottom: "1.375rem" }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "2.5%",
                  marginBottom: "2rem",
                }}
              >
                <Box sx={{ width: "28%" }}>
                  {/* <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">select</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        label="select"
                                        color='warning'
                                        size='medium'
                                        // value={typeSelect}
                                        // onChange={handleSelectChange}
                                    >
                                        <MenuItem value="category">All Categories</MenuItem>
                                        <MenuItem value="product">All Products</MenuItem>
                                        <MenuItem value="bundles">All Bundles</MenuItem>
                                        {(discountType === "customerGroup" || discountType === "forItem" || discountType === "freeDiscount") &&
                                            <MenuItem value="menu">All Menus</MenuItem>}

                                    </Select>
                                </FormControl> */}
                </Box>
                <TextField
                  sx={{ width: "62%" }}
                  color="warning"
                  label="Search by name"
                  size="medium"
                  onChange={(e) => setSearchValue(e.target.value)}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box
                sx={{ maxHeight: "40vh", width: "100%", overflowY: "scroll" }}
              >
                <CustomStyles>
                  <DataGrid
                    rows={searchedTableList}
                    columns={columns}
                    rowHeight={48}
                    columnHeaderHeight={48}
                    // initialState={{
                    //     pagination: {
                    //         paginationModel: {
                    //             pageSize: 5,
                    //         },
                    //     },
                    // }}
                    // pageSizeOptions={[5]}
                    hideFooterPagination
                    scrollbarSize={0}
                    sx={{ marginRight: "2%" }}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(
                      newRowSelectionModel: GridRowSelectionModel
                    ) => {
                      setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                  />
                </CustomStyles>
              </Box>
              <Box sx={{ marginTop: "4px", marginBottom: "4px" }}>
                <Button
                  className="main-button"
                  sx={{ marginLeft: "85%" }}
                  onClick={() => {
                    handleClose();
                    handleDone();
                  }}
                >
                  Done
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
);
export default CustomerModal;
