import {
  Box,
  Button,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as DownloadIcon } from "../../../assets/cloud-download.svg";
import { ReactComponent as RestoreIcon } from "../../../assets/replay.svg";
import { ReactComponent as RotateRightIcon } from "../../../assets/rotate-right.svg";

function Backups() {
  const [backupName, setBackupName] = useState("");
  const [backupNameSearch, setBackupNameSearch] = useState("");
  const [customerData, setCustomerData] = useState([
    {
      id: 1,
      backupName: "test1",
      versionDetails: "2.3.0",
      backupDate: moment().format("DD-MM-YYYY, HH:MM"),
    },
    {
      id: 2,
      backupName: "test2",
      versionDetails: "2.3.1",
      backupDate: moment().format("DD-MM-YYYY, HH:MM"),
    },
    {
      id: 3,
      backupName: "test3",
      versionDetails: "2.3.2",
      backupDate: moment().format("DD-MM-YYYY, HH:MM"),
    },
  ]);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeader": {
      color: "#333333",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    "& .MuiDataGrid-cell": {
      color: "#000",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  }));

  // column definition for  Data Grid component
  const columns: GridColDef[] = [
    {
      field: "backupName",
      headerName: "Backup Name",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "versionDetails",
      headerName: "Version Details",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "backupDate",
      headerName: "Date",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      flex: 0.3,
      getActions: (params) => [
        <GridActionsCellItem
          label="Download"
          icon={<DownloadIcon />}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root": {
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              columnGap: "41px",
            },
          }}
        />,
        <GridActionsCellItem
          label="Restore"
          icon={<RestoreIcon />}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root": {
              flexDirection: "row-reverse",
              justifyContent: "space-between",
              columnGap: "41px",
            },
          }}
        />,
      ],
    },
  ];

  return (
    <div>
      <Typography
        sx={{
          color: "#333",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          marginTop: "31px",
          marginLeft: "32px",
        }}
      >
        Backups
      </Typography>
      <Box
        sx={{
          paddingLeft: "64px",
          marginTop: "20px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #EEE",
          borderTop: "1px solid #EEE",
          paddingTop: "20px",
          paddingBottom: "24px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              marginBottom: "10px",
            }}
          >
            Create a New Backup
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginBottom: "24px",
            }}
          >
            Use this option to create a manual backup. Manual backups are
            recommended before making any major changes to the application.
          </Typography>
          <TextField
            fullWidth
            sx={{ width: "522px" }}
            label="Backup Name"
            variant="outlined"
            placeholder="Backup Name"
            InputLabelProps={{ shrink: true }}
            value={backupName}
            onChange={(e) => setBackupName(e.target.value)}
            helperText="Example: Pre-update-backup"
          />
        </Box>
        <Button
          sx={{
            width: "fit-content",
            marginRight: "20px",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            Create New Backup
          </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          paddingLeft: "64px",
          display: "flex",
          justifyContent: "space-between",
          borderBottom: "1px solid #EEE",
          borderTop: "1px solid #EEE",
          paddingTop: "24px",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
              marginBottom: "10px",
            }}
          >
            Backups
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginBottom: "24px",
            }}
          >
            All site backups are listed here. Use this section to download or
            restore a backup, Automatic backups are taken every 24 hours.
          </Typography>
        </Box>
        <TextField
          fullWidth
          sx={{ width: "220px", marginRight: "20px" }}
          label="Backup Name"
          placeholder="Every 24 hours"
          value={backupNameSearch}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <RotateRightIcon style={{ height: "18px", width: "18px" }} />
              </InputAdornment>
            ),
          }}
          onChange={(e) => setBackupNameSearch(e.target.value)}
        />
      </Box>

      <div style={{ width: "100%" }}>
        <StyledDataGrid
          rows={customerData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 25, page: 0 },
            },
          }}
          disableColumnMenu
        />
      </div>
    </div>
  );
}

export default Backups;
