import React from "react";
import { Box, Typography } from "@mui/material";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import ArrowIcon from "../../../assets/play-for-work.png";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CountrySelect from "../../businessOnboarding/Steper/businessDetails/countriesField";
import Checkbox from "@mui/material/Checkbox";
import Modal from "@mui/material/Modal";
import CheackCircle from "../../../assets/check-circleTwo.png";
import CloseButton from "../../../assets/close.png";
import { Link as RouterLink } from "react-router-dom";
// import MenuItem from '@mui/material/MenuItem';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { sm: 400, xs: 300 },
  bgcolor: "background.paper",
  // border: '2px solid #000',
  borderRadius: "8px",
  boxShadow: 24,
  paddingY: "54px",
  paddingX: "32px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
};

function PaymentPage() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  return (
    <Box
      marginTop="16px"
      textAlign="center"
      paddingX={{ sm: "20px", xs: "12px" }}
      marginBottom={'65px'}
    >
      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          paddingY: "24px",
          paddingX: { sm: "20px", xs: "12px" },
          borderRadius: "8px",
        }}
      >
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "26px", sm: "32px" },
            fontWeight: "500",
            lineHeight: "48px",
            color: "#333333",
            textAlign: "center",
          }}
        >
          Payment Page
        </Typography>
        <Box
          sx={{
            maxWidth: { xs: "100%", md: "1360px" },
            marginTop: "10px",
            marginX: "auto",
            border: "1px solid #EEEEEE",
            borderRadius: "4px",
            gap: "40px",
            display: "flex",
            justifyContent: { md: "space-between", xs: "center" },
            flexWrap: { md: "nowrap", xs: "wrap" },
            paddingY: { md: "0px", xs: "16px" },
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "520px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              paddingY: "12px",
              paddingX: { sm: "20px", xs: "8px" },
            }}
          >
            <Box textAlign={{ md: "left", xs: "center" }}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "16px",
                  fontWeight: "500",
                  lineHeight: "24px",
                  color: "#F38B08",
                }}
              >
                Gold Package
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { md: "start", xs: "center" },
                  flexWrap: "wrap",
                  alignItems: "center",
                  gap: "4px",
                  my: "16px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: { xs: "26px", sm: "32px" },
                    fontWeight: "500",
                    lineHeight: "48px",
                    color: "#333333",
                  }}
                >
                  UK£84.00
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "16px",
                    fontWeight: "400",
                    lineHeight: "24px",
                    color: "#707070",
                  }}
                >
                  /month
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  fontWeight: "300",
                  lineHeight: "21px",
                  color: "#707070",
                }}
              >
                Manage Multiple Stores and Brands Across Unlimited Locations -
                Access Native Client, Vendor, and Driver Apps - Empower Vendors
                with a Feature-Rich Web Admin - Utilize Vouchers, Discounts, and
                Promotional Tools - Enjoy Unlimited Users and Listings
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              width: "100%",
              maxWidth: "384px",
            }}
          >
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                paddingY: "24px",
                paddingX: { sm: "32px", xs: "16px" },
                gap: "12px",
                backgroundColor: "white",
              }}
            >
              <Button
                sx={{
                  width: "100%",
                  fontFamily: "poppins",
                  fontSize: { xs: "14px", sm: "18px" },
                  fontWeight: "500",
                  lineHeight: "27px",
                  color: "#FFFFFF",
                  height: "43px",
                  backgroundColor: "#00AE26",
                  gap: "12px",
                  boxShadow: 'none !important',
                  ":hover": {
                    backgroundColor: "#00AE26",
                  },
                }}
                variant="contained"
              >
                Pay with Link
                <img src={ArrowIcon} alt="ArrowIcon" />
              </Button>
              <Divider
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#707070",
                  marginTop: "14.5px",
                }}
              >
                Or pay with card
              </Divider>
              <Box
                sx={{
                  marginTop: "14.5px",
                }}
              >
                <TextField
                  type="text"
                  sx={{ width: "100%" }}
                  label="Email"
                  placeholder="kurosaki@sinigam.com"
                  id="outlined-size-normal"
                />
                <TextField
                  type="number"
                  sx={{ width: "100%", marginTop: "24px" }}
                  label="Card Number"
                  placeholder="0000-0000-0000"
                  id="outlined-size-normal"
                />
                <Box
                  sx={{
                    gap: "20px",
                    display: "flex",
                    marginTop: "12px",
                    flexWrap: { sm: "nowrap", xs: "wrap" },
                  }}
                >
                  <TextField
                    type="month"
                    sx={{
                      width: { sm: "50%", xs: "100%" },
                      backgroundColor: "white",
                    }}
                    id="outlined-basic"
                    label="MM/YY"
                    variant="outlined"
                  />
                  <TextField
                    type="number"
                    sx={{
                      width: { sm: "50%", xs: "100%" },
                      backgroundColor: "white",
                    }}
                    id="outlined-basic"
                    label="CVC"
                    placeholder="CVC"
                    variant="outlined"
                  />
                </Box>
                <TextField
                  type="text"
                  sx={{ width: "100%", marginTop: "24px" }}
                  label="Name on Card"
                  placeholder="Name on Card"
                  id="outlined-size-normal"
                  defaultValue="Normal"
                />
                <Box sx={{ marginTop: "24px" }}>
                  <CountrySelect width={"100%"} />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "8px",
                  marginTop: "24px",
                  py: "8px",
                }}
              >
                <Box>
                  <Checkbox
                    sx={{
                      padding: "0px", width: "20px", height: "20px",
                      '&.Mui-checked': { color: '#F38B08 !important' },
                    }}
                    // checked={checked}
                    // onChange={handleChange}
                    inputProps={{ "aria-label": "controlled" }}

                  />
                </Box>
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: { xs: "12px", md: "14px" },
                    lineHeight: "21px",
                    color: "#707070",
                  }}
                >
                  I agree to terms and condition by ...... this{" "}
                </Typography>
              </Box>
              <Button
                onClick={handleOpen}
                sx={{
                  width: "100%",
                  fontFamily: "poppins",
                  fontSize: "18px",
                  fontWeight: "500",
                  lineHeight: "27px",
                  color: "#FEFEFE",
                  marginTop: "24px",
                  backgroundColor: "#F38B08",
                  height: "48px",
                  textTransform: "none",
                  boxShadow: 'none !important',
                  ":hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
                variant="contained"
              >
                Pay
              </Button>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box sx={style}>
                  <img
                    width={"133.33px"}
                    height={"133.33px"}
                    src={CheackCircle}
                    alt="CheackCircle"
                  />
                  <Button
                    sx={{
                      minWidth: { sm: "44px", xs: "36px" },
                      height: { sm: "44px", xs: "40px" },
                      backgroundColor: "#eeeeee",
                      borderRadius: "50%",
                      position: "absolute",
                      top: { sm: "12px", xs: "20px" },
                      left: { sm: "12px", xs: "30px" },
                    }}
                  >
                    <img src={CloseButton} alt="CloseButton" />
                  </Button>
                  <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { sm: "28px", xs: "24px" },
                      fontWeight: "600",
                      lineHeight: { sm: "42px", xs: "36px" },
                      marginTop: "25px",
                      color: "#F38B08",
                      textAlign: "center",
                    }}
                  >
                    You have successfully bought the plan
                  </Typography>
                  <Typography
                    id="modal-modal-description"
                    sx={{
                      marginTop: "20px",
                      fontFamily: "poppins",
                      fontSize: { sm: "20px", xs: "18px" },
                      fontWeight: "500",
                      lineHeight: { sm: "30px", xs: "27px" },
                      color: "#707070",
                      textAlign: "center",
                    }}
                  >
                    You are now on Gold plan paying yearly!
                  </Typography>
                  <Box sx={{ marginTop: "64px" }}>
                    <Button
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "18px",
                        fontWeight: "500",
                        lineHeight: "27px",
                        color: "#FEFEFE",
                        backgroundColor: "#F38B08",
                        paddingX: "13px",
                        paddingY: "10.5px",
                        boxShadow: "none !important",
                        textTransform: "none",
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: '#F38B08'
                        }
                      }}
                      variant="contained"
                    >
                      Go to Dashboard
                    </Button>
                  </Box>
                </Box>
              </Modal>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: { xs: "16px", lg: "32px" },
            flexDirection: { xs: "column", sm: "row" },
            maxWidth: "1360px",
            marginX: "auto",
            position: "relative",
            top: { xs: "10px", md: "-30px" },
            paddingLeft: { lg: "20px", xs: "16px" },
            justifyContent: { xs: "center", md: "start" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "4px",
              flexWrap: "wrap",
              justifyContent: { sm: "start", xs: "center" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "400",
                lineHeight: "21px",
                color: "#707070",
              }}
            >
              Powered by
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                lineHeight: "21px",
                fontWeight: "600",
                color: "#4725F3",
              }}
            >
              GoCardless
            </Typography>
          </Box>
          <Box
            sx={{
              width: "4px",
              height: "21px",
              backgroundColor: "#ABABAB",
              display: { xs: "none", md: "block" },
            }}
          ></Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { xs: "16px", lg: "32px" },
            }}
          >
            <Link
              component={RouterLink}
              to={"#"}
              sx={{
                textDecoration: "none",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "21px",
                color: "#707070",
              }}
            >
              Terms
            </Link>
            <Link
              sx={{
                textDecoration: "none",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "21px",
                color: "#707070",
              }}
              component={RouterLink}
              to="#"
            >
              Privacy
            </Link>
            <Link
              sx={{
                textDecoration: "none",
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "300",
                lineHeight: "21px",
                color: "#707070",
              }}
              component={RouterLink}
              to="#"
            >
              Returns
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentPage;
