import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import CloseIcon from "@mui/icons-material/Close";
import image from "../../assets/Ellipse 17.png";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PrintIcon from "@mui/icons-material/Print";
import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@apollo/client";
import { IconButton, Box, Divider, Typography } from "@mui/material";
import {
  USER_PROFILE_DETAILS,
  USER_ORDER_DETAILS,
  LOCATIONS_LIST,
  USER_TOTAL_HOURS,
  USER_REPORT,
} from "../../graphicalQl/usequery/userProfileUseQuery";
import { useNavigate } from "react-router";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import Details from "../mainDashboard/details";
import moment from "moment";
import { useTranslation } from "react-i18next";
import "./userList.css";
import { useSelector } from "react-redux";
import dayjs from "dayjs";
import DataGridTable from "../../utils/dataGridTable";

interface DriverProfileProps {
  handlePopupClose: () => void;
  profileId: string | null;
}

interface DateType {
  startDate: string;
  endDate: string;
}

interface UserProfileProps {
  clockInAt: string | null;
  clockOutAt: string | null;
  email: string;
  employeeId: string;
  firstName: string;
  lastName: string;
  mobile: string;
  role: {
    roleName: string;
    roleId: string;
  };
}

const initalProfileDetails: UserProfileProps = {
  clockInAt: null,
  clockOutAt: null,
  email: "",
  employeeId: "",
  firstName: "",
  lastName: "",
  mobile: "",
  role: {
    roleName: "",
    roleId: "",
  },
};

interface ReportValues {
  inTimeDeliveries: string;
  orderCompleted: string;
  totalOrders: string;
  cancelledOrders: string;
  commissionEarned: string;
  totalTips: string;
  totalDeliveries: string;
}

const initialUserReportValues: ReportValues = {
  inTimeDeliveries: "",
  orderCompleted: "",
  totalOrders: "",
  cancelledOrders: "",
  commissionEarned: "",
  totalTips: "",
  totalDeliveries: "",
};
interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}

function DriverProfile(props: DriverProfileProps) {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [isDriver, setIsDriver] = useState<boolean>(false);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [dateDropdownLabel, setDateDropdownLabel] = useState<string>("");
  const navigate = useNavigate();
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const { handlePopupClose, profileId } = props;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [profileDetails, setProfileDetails] = useState(initalProfileDetails);
  const [userReportDetails, setUserReportDetails] = useState<ReportValues>(
    initialUserReportValues
  );
  const [userReportTotalHours, setUserReportTotalHours] = useState<string>("");
  const [uuid, setUuid] = useState<null | string>(null);

  const todayDate = moment().format("YYYY-MM-DD");
  const [orderDetailsList, setOrderDetailsList] = useState<any[]>([]);

  const [locationsList, seLocationsList] = useState<Locations[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const {
    loading: userProfileLoading,
    error: userProfileError,
    data: userProfileData,
  } = useQuery(USER_PROFILE_DETAILS, {
    context: { clientName: "userClient" },
    variables: { employeeId: profileId && +profileId },
    fetchPolicy: "network-only",
  });

  const variables = {
    employeeId: uuid,
    dateOf: todayDate,
    storeId: +locationId,
    businessId: +businessId,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };

  const filteredVariables = Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => value !== null)
  );

  const {
    loading: userOrdersLoading,
    error: userOrdersError,
    data: userOrdersData,
  } = useQuery(USER_ORDER_DETAILS, {
    context: { clientName: "orderClient" },
    variables: filteredVariables,
    fetchPolicy: "network-only",
  });

  const {
    loading: userReportsLoading,
    error: userReportsError,
    data: userReportsData,
  } = useQuery(USER_REPORT, {
    context: { clientName: "orderClient" },
    fetchPolicy: "network-only",
    variables: {
      employeeId: uuid,
      isDriver: isDriver,
      fromDate: date.startDate,
      toDate: date.endDate,
    },
  });

  const {
    loading: userTotalHoursLoading,
    error: userTotalHoursError,
    data: userTotalHoursData,
  } = useQuery(USER_TOTAL_HOURS, {
    context: { clientName: "userClient" },
    variables: {
      employeeId: profileId,
      fromDate: date.startDate,
      toDate: date.endDate,
    },
    fetchPolicy: "network-only",
  });
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  const filteredLocationsList = locationsList.filter(
    (each: any) => each.storeId === locationId
  );
  let locationName: any;
  if (filteredLocationsList.length === 1) {
    locationName = filteredLocationsList[0].storeName;
  }

  useEffect(() => {
    if (userReportsData) {
      if (userReportsData.userReport) {
        const { userReport } = userReportsData;
        let userReportObject = JSON.parse(userReport);
        const userReports = {
          inTimeDeliveries: userReportObject.in_time_deliveries,
          orderCompleted: userReportObject.orders_completed,
          totalOrders: userReportObject.total_orders,
          cancelledOrders: userReportObject.cancelled_orders,
          commissionEarned: userReportObject.commission_earned,
          totalTips: userReportObject.total_tips,
          totalDeliveries: userReportObject.total_deliveries,
        };
        setUserReportDetails(userReports);
      }
    }
  }, [userReportsData]);
  useEffect(() => {
    if (userTotalHoursData) {
      if (userTotalHoursData.employeeTotalHours) {
        setUserReportTotalHours(userTotalHoursData.employeeTotalHours);
      }
    }
  }, [userTotalHoursData]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );
      seLocationsList(requiredDataList);
    }
  }, [locationsData]);
  useEffect(() => {
    if (userOrdersData) {
      if (userOrdersData.orders) {
        const { orders } = userOrdersData;
        const filteredList = orders.edges.map((each: any, index: number) => ({
          id: index + 1,
          orderId: each.node.orderId,
          storeId: each.node.storeId,
          orderType: each.node.orderType,
          deliveryStatus: each.node.deliveryStatus,
          orderStatus: each.node.orderStatus,
          tipAmount: each.node.tipAmount,
          location: locationName,
        }));
        setOrderDetailsList(filteredList);
      }
    }
  }, [userOrdersData]);

  useEffect(() => {
    if (userProfileData) {
      const { employee } = userProfileData;
      const {
        clockInAt,
        email,
        uuid,
        clockOutAt,
        employeeId,
        lastName,
        firstName,
        mobile,
        role,
        permissions,
      } = employee;
      setUuid(uuid);
      setProfileDetails({
        clockOutAt: clockOutAt || null,
        clockInAt: clockInAt || null,
        email,
        employeeId,
        lastName,
        firstName,
        mobile,
        role,
      });

      setIsDriver(permissions && permissions.includes(44));
    }
  }, [userProfileData]);
  const handleCloseIcon = () => {
    handlePopupClose();
    setProfileDetails(initalProfileDetails);
  };
  //date functions
  //for getting date
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Month is zero-indexed
  const day = currentDate.getDate().toString().padStart(2, "0");
  const formattedDate = `${day}-${month}-${year}`;

  const [popUpDate, setPopUpDate] = React.useState<string>(formattedDate);

  const handlePopupDate = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPopUpDate(event.target.value);
  };

  const handlePopupRows = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const initialPopUpColumns: GridColDef[] = [
    {
      field: "id",
      //@ts-ignore
      headerName: t("users.sno"),
      width: 150,
      headerClassName: "driver-list-popup-columns",
      disableColumnMenu: true,
    },
    {
      field: "orderId",
      //@ts-ignore
      headerName: t("users.order_details"),
      flex: 1,
      headerClassName: "driver-list-popup-columns",
      disableColumnMenu: true,
    },

    {
      field: isDriver ? "deliveryStatus" : "orderStatus",
      disableColumnMenu: true,
      //@ts-ignore
      headerName: isDriver
        ? t("deliveries.delivery_status")
        : t("users.status"),
      flex: 1,
      headerClassName: "driver-list-popup-columns",
      renderCell: (params) => {
        var styleOfStatus;
        if (params.value.trim() === "Delivered") {
          styleOfStatus = "driver-list-popup-delivered-status";
        } else if (params.value.trim() === "Cancelled") {
          styleOfStatus = "driver-list-popup-cancelled-status";
        } else {
          styleOfStatus = "driver-list-popup-rows";
        }
        return (
          <Typography className={styleOfStatus}>{params.value}</Typography>
        );
      },
    },
    {
      field: "tipAmount",
      disableColumnMenu: true,
      //@ts-ignore
      headerName: t("users.tip"),
      flex: 1,
      headerClassName: "driver-list-popup-columns",
    },
  ];

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(userOrdersData?.orders?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(userOrdersData?.orders?.pageInfo.startCursor || null);
  };

  return (
    <Box sx={{ padding: "1.5%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          width: "65%",
          alignItems: "center ",
          marginBottom: "5%",
        }}
      >
        <IconButton
          onClick={handleCloseIcon}
          className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
        >
          <CloseIcon />
        </IconButton>
        <Typography className="delivery-list-header-text">
          {t("users.user_profile")}
          <span className="user-profile-profile-name">
            ({profileDetails.role.roleName})
          </span>
        </Typography>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <img src={image} alt="profile" />
            <Typography
              sx={{ marginLeft: "8px", marginRight: "3px" }}
              className="driver-list-popup-profile-text"
            >
              {profileDetails.firstName} {profileDetails.lastName}
            </Typography>
            <IconButton
              onClick={() => navigate(`/users/${profileId}/update-user`)}
            >
              <EditIcon />
            </IconButton>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "8px",
              alignItems: "center",
              marginBottom: "10px",
              marginLeft: "10px",
            }}
          >
            <IconButton className="driver-list-popup-phone-icon">
              <LocalPhoneOutlinedIcon />
            </IconButton>
            <Typography className="driver-list-popup-phone-number">
              {profileDetails.mobile}
            </Typography>
          </Box>

          <Box sx={{ display: "flex", alignItems: "center" }}>
            <IconButton sx={{ marginRight: "3px" }}>
              <EmailOutlinedIcon />
            </IconButton>
            <Typography className="driver-list-popup-email">
              {profileDetails.email}
            </Typography>
          </Box>
        </Box>

        <Box sx={{ display: "flex", flexDirection: "column", width: "70%" }}>
          {profileDetails.clockInAt && (
            <Box
              className="driver-profile-clock-in"
              sx={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "10px",
                alignItems: "center",

                padding: "8px ",
              }}
            >
              <AccessTimeIcon sx={{ color: "#00AE26" }} />
              <Typography
                sx={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="driver-profile-clock-in-text"
              >
                {t("deliveries.clock_in_at")}{" "}
                {moment(profileDetails?.clockInAt).local().format("hh:mm A")}
              </Typography>
            </Box>
          )}

          {profileDetails.clockOutAt && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                padding: "8px ",
              }}
              className="driver-profile-clock-out"
            >
              <AccessTimeIcon sx={{ color: "#F5431C" }} />
              <Typography
                sx={{
                  marginLeft: "5px",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="driver-profile-clock-out-text"
              >
                {t("deliveries.clock_out_at")}{" "}
                {moment(profileDetails?.clockOutAt).local().format("hh:mm A")}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "15px",
          marginBottom: "15px",
        }}
      >
        <Typography className="driver-list-popup-assigned-text">
          {t("users.assigned_orders")}
        </Typography>
        {/* <Typography className='driver-list-popup-status-text'>Status:<span className='driver-list-popup-10min-text'>10min</span><span className='driver-list-popup-toreach-text'> to reach delivery location</span></Typography> */}
      </Box>
      <Box sx={{ height: 280 }}>
        {/* <DataGrid
          rows={orderDetailsList}
          columns={initialPopUpColumns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[4]}
          getRowClassName={handlePopupRows}
        /> */}
        <DataGridTable
          shouldUpdatePaginationModelIsTrue={shouldUpdatePaginationModelIsTrue}
          handleUpdatingValuesToNull={handleUpdatingValuesToNull}
          paginationModel={paginationModel}
          rows={orderDetailsList}
          columns={initialPopUpColumns}
          getRow={handlePopupRows}
          totalCount={userOrdersData?.orders?.totalCount || 0}
          handleEndCursor={handleEndCursor}
          handleStartCursor={handleStartCursor}
          currentPage={currentPage}
          imageHeight="100px"
          imageWidth="100px"
          heightForContainer="160px"
          fontSizeForNoDataText="12px"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "15px",
        }}
      >
        <Typography className="driver-list-popup-driver-text">
          {profileDetails.role.roleName} {t("users.report")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
          }}
        >
          <IconButton>
            <PrintIcon color="warning" />
          </IconButton>

          <Details
            setDateDropdownLabel={setDateDropdownLabel}
            dateDropdownLabel={dateDropdownLabel}
            date={date}
            setDate={setDate}
          />
        </Box>
      </Box>
      <Box
        sx={{
          marginTop: "15px",
          marginBottom: "10px",
          display: "flex",
          marginLeft: "5px",
          gap: "15px",
          alignItems: "center",
        }}
      >
        <Box
          className="driver-list-popup-boxes"
          sx={{ backgroundColor: "#FEF1E1", color: "#F38B08" }}
        >
          <Typography className="driver-list-popup-boxes-number">
            {userReportTotalHours}
          </Typography>
          <Typography className="driver-list-popup-boxes-text">
            {t("users.total_hours")}
          </Typography>
        </Box>
        {isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#FEF1E1", color: "#F38B08" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {userReportDetails.inTimeDeliveries}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.in_time_deliveries")}
            </Typography>
          </Box>
        )}

        {!isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#FEF1E1", color: "#F38B08" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {userReportDetails.orderCompleted}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.orders_completed")}
            </Typography>
          </Box>
        )}

        {/* <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#FEF1E1",color:"#F38B08"}}>
                      <Typography color="warning" className='driver-list-popup-boxes-number'>{userReportDetails.inTimeDeliveries}</Typography>
                      <Typography color="warning" className='driver-list-popup-boxes-text'>In Time Deliveries</Typography>
                    </Box> */}
        {isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#E5F7E9", color: "#00AE26" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {currencySymbol} {userReportDetails.commissionEarned}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.commissions_earned")}
            </Typography>
          </Box>
        )}
        {/* <Box className="driver-list-popup-boxes" sx={{backgroundColor:"#E5F7E9",color:"#00AE26"}}>
                      <Typography color="#00AE26" className='driver-list-popup-boxes-number'>$ {userReportDetails.commissionEarned}</Typography>
                      <Typography color="#00AE26" className='driver-list-popup-boxes-text'>Commission Earned</Typography>
                    </Box> */}
        {!isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#E5F7E9", color: "#00AE26" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {currencySymbol} {userReportDetails.totalTips}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.total_tips")}
            </Typography>
          </Box>
        )}
      </Box>

      <Box
        sx={{
          marginTop: "15px",
          marginBottom: "10px",
          marginLeft: "5px",
          display: "flex",
          gap: "15px",
          alignItems: "center",
        }}
      >
        {isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#E5F7E9", color: "#00AE26" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {currencySymbol} {userReportDetails.totalTips}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.total_tips")}
            </Typography>
          </Box>
        )}
        {isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#FEF1E1", color: "#F38B08" }}
          >
            <Typography
              color="warning"
              className="driver-list-popup-boxes-number"
            >
              {userReportDetails.orderCompleted}
            </Typography>
            <Typography
              color="warning"
              className="driver-list-popup-boxes-text"
            >
              {t("users.total_deliveries")}
            </Typography>
          </Box>
        )}
        {!isDriver && (
          <Box
            className="driver-list-popup-boxes"
            sx={{ backgroundColor: "#FEF1E1", color: "#F38B08" }}
          >
            <Typography className="driver-list-popup-boxes-number">
              {userReportDetails.totalOrders}
            </Typography>
            <Typography className="driver-list-popup-boxes-text">
              {t("users.Total_Orders")}
            </Typography>
          </Box>
        )}
        <Box
          className="driver-list-popup-boxes"
          sx={{ backgroundColor: "#FEECE8", color: "#F5431C" }}
        >
          <Typography className="driver-list-popup-boxes-number">
            {userReportDetails.cancelledOrders}
          </Typography>
          <Typography className="driver-list-popup-boxes-text">
            {t("users.cancelled_orders")}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
}
export default DriverProfile;
