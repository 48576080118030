import { useMutation } from "@apollo/client";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UPDATE_ORDER_NUMBERING } from "../../../graphicalQl/mutation/customerDataMutation";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
interface Props {
  data: any;
  orderNumberingDataRefetch: any;
}
function OrderNumbering(props: Props) {
  const { data, orderNumberingDataRefetch } = props;
  const [orderNumbering, setOrderNumbering] = useState<any>({
    ...data,
  });
  const { t } = useTranslation();
  const [
    updateOrderNumbering,
    { data: updateOrderNumberingData, error: updateOrderNumberingError },
  ] = useMutation(UPDATE_ORDER_NUMBERING, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  //check for successfull updated and perform the required functions
  useEffect(() => {
    if (updateOrderNumberingData?.branchSettings?.success) {
      orderNumberingDataRefetch();
    }
  }, [updateOrderNumberingData]);

  const { locationId } = useSelector((state: any) => state.headerLocation);

  const saveAccountPreferences = () => {
    updateOrderNumbering({
      variables: {
        storeId: locationId && +locationId,
        orderNumberingSettings: JSON.stringify({
          name: orderNumbering.name,
          numbering_type: orderNumbering.numberingType,
          // prefix: orderNumbering.prefix,
          min_length: orderNumbering.maxLength,
        }),
      },
    });
  };
  return (
    <Box>
      <Box
        sx={{
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
          display: "flex",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {t("orders.order_numbering")}
        </Typography>
        <Button
          onClick={() => saveAccountPreferences()}
          sx={{
            marginLeft: "auto",
            marginRight: "24px",
            width: "94px",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            {t("buttons.save")}
          </Typography>
        </Button>
      </Box>

      <Box
        sx={{
          width: "804px",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          boxSizing: "border-box",
          borderLeft: "2px solid #AE9BE2",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
          marginLeft: "32px",
          marginTop: "32px",
        }}
      >
        <Box
          sx={{
            marginTop: "20px",
            paddingLeft: "32px",
            paddingBottom: "22px",
            borderBottom: "1px solid #EEE",
          }}
        >
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {t("orders.basic_details")}
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginTop: "4px",
            }}
          >
            {t("orders.system_dynamically")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginLeft: "26px",
          }}
        >
          <TextField
            fullWidth
            sx={{
              width: "320px",
              margin: "auto",
              marginBottom: "32px",
              marginRight: "24px",
            }}
            label="Name"
            variant="outlined"
            placeholder="Name"
            InputLabelProps={{ shrink: true }}
            value={orderNumbering.name}
            onChange={(e) =>
              setOrderNumbering((prev: any) => ({
                ...prev,
                name: e.target.value,
              }))
            }
          />

          <FormControl sx={{ width: "320px" }}>
            <InputLabel id="numberingType">
              {t("orders.numbering_typw")}
            </InputLabel>
            <Select
              id="numberingType"
              name="Numbering Type"
              label="Numbering Type"
              renderValue={(selected) => {
                // show placeholder text when no option is selected
                return selected !== "" ? selected : "Select Numbering Type";
              }}
              value={orderNumbering.numberingType}
              onChange={(event: SelectChangeEvent) =>
                setOrderNumbering((prev: any) => ({
                  ...prev,
                  numberingType: event.target.value,
                }))
              }
            >
              <MenuItem value={"Alphanumeric"}>
                {t("orders.alpha_numeric")}
              </MenuItem>
              <MenuItem value={"Numeric"}>{t("orders.numeric")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Box
        sx={{
          width: "804px",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          boxSizing: "border-box",
          borderLeft: "2px solid #9BDAE2",
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          marginLeft: "32px",
          marginTop: "32px",
        }}
      >
        <Box
          sx={{
            marginTop: "23px",
            paddingLeft: "32px",
            paddingBottom: "22px",
            borderBottom: "1px solid #EEE",
          }}
        >
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            {t("orders.customise_number")}
          </Typography>
        </Box>

        {/* <Box
          sx={{
            display: "flex",
            columnGap: "32px",
            alignItems: "center",
            marginLeft: "28px",
          }}
        >
          <TextField
            fullWidth
            sx={{ width: "320px" }}
            label="Prefix"
            variant="outlined"
            placeholder="enter prefix like PF or P"
            InputLabelProps={{ shrink: true }}
            value={orderNumbering.prefix}
            onChange={(e) =>
              setOrderNumbering((prev: any) => ({
                ...prev,
                prefix: e.target.value,
              }))
            }
          />
          <Box
            sx={{
              display: "flex",
              width: "120px",
              height: "44px",
              lineHeight: "44px",
              flexDirection: "column",
              borderRadius: "4px",
              background: "#EEE",
              paddingLeft: "20px",
            }}
          >
            Ex: P00021
          </Box>
        </Box> */}
        <TextField
          fullWidth
          sx={{ width: "320px", marginLeft: "28px", marginBottom: "32px" }}
          label={t("orders.minimum_lenght")}
          variant="outlined"
          placeholder={t("orders.minimum_lenght") || ""}
          InputLabelProps={{ shrink: true }}
          value={orderNumbering.maxLength}
          onChange={(e) =>
            setOrderNumbering((prev: any) => ({
              ...prev,
              maxLength: e.target.value,
            }))
          }
        />
      </Box>
    </Box>
  );
}

export default OrderNumbering;
