import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  TextField,
} from "@mui/material";
import "../options/createProduct.css";
import "./createCategory.css";
import { useTranslation } from "react-i18next";
import { CREATE_CATEGORY } from "../../graphicalQl/mutation/mutation";
import { useMutation, useQuery } from "@apollo/client";
import "react-toastify/dist/ReactToastify.css";
import { Divider } from "primereact/divider";
import { CATEGORY_STATUS_CHANGE } from "../../graphicalQl/mutation/mutation";
import AlertDialog from "./dailougeBox";
import { CATEGORY_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useSelector } from "react-redux";
import { EDIT_CATEGORY } from "../../graphicalQl/usequery/categoryListQuery";
import { UPDATE_CATEGORY_GENARAL_DETAILS } from "../../graphicalQl/mutation/mutation";
import ErrorModal from "../errorHandling/errorModal";
import ImageDrawer from "../menu/image/imageDrawer";
import CircularLoader from "../../loader/loader";

interface Props {
  getCategoryId: (id: string) => void;
  handleComplete: () => void;
  categoryId2: string;
}
interface State {
  [key: string]: string | boolean;
  search: string;
  description: string;
  showPOS: boolean;
  showOnline: boolean;
}



const CategoryDetails = (props: Props) => {
  const { t, i18n } = useTranslation();
  const validate = () => {
    return Yup.object({
      // name: Yup.string().required("*Category Name Required"),
      name: Yup.string().required(t("error.category_name") as string),
    })
  }
  const [validationSchema, setValidationSchema] = useState(validate())
    //  Yup.object({
    //   // name: Yup.string().required("*Category Name Required"),
    //   name: Yup.string().required(t("error.category_name") as string),

    // })
    ;

  useEffect(() => {
    setValidationSchema(validate())

  }, [i18n.language, t])

  const { name, description } = useSelector(
    (state: any) => state.creatCategory
  );
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: name || "",
      description: description || "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (categoryId2) {
        updateCategoryData({ variables: filteredCategoryUpdate });
      } else {
        createCatageory({ variables: filteredCategory });
      }
    },
  });
 

  const { getCategoryId, handleComplete, categoryId2 } = props;
  const initialState: State = {
    search: "",
    description: "",
    showPOS: true,
    showOnline: true,
  };
  const [mainState, setState] = useState<State>(initialState);
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = event.target;

    setState((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const [categoryId, setCategoryId] = useState<string>("");
  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  const [createCatageory, { loading: createLoading }] = useMutation(CREATE_CATEGORY, {
    onCompleted: (data) => {
      if (data) {
        setCategoryId(data.createCategory.category.categoryId);
      }
      categoryStatus({
        variables: { categoryId: data.createCategory.category.categoryId },
      });
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        setCategoryStatusCode(
          parseInt(graphqlError.extensions.status, 10) || 500
        );
        setErrorModal(true);
      }
      // const errorMessage = graphqlError.message || 'An error occurred';
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [CATEGORY_LIST],
  });

  const [categoryStatus] = useMutation(CATEGORY_STATUS_CHANGE, {
    context: { clientName: "categoryClient" },
    refetchQueries: [CATEGORY_LIST],
  });

  const imageDrawerRef = useRef<any>();
  const handleCancelButton = () => {
    formik.setValues({
      name: "",
      description: "",
    });
    if (imageDrawerRef.current) {
      imageDrawerRef.current.handleNull();
    }
    setUploadColor(null);
    setUploadImg(null);
  };

  useEffect(() => {
    getCategoryId(categoryId);
  });

  const [dailouge, setDailouge] = useState<boolean>(false);
  const handleOpenDailouge = () => {
    setDailouge(true);
  };

  const handleUpdateDailougeState = () => {
    setDailouge(false);
  };

  const [colorCodeFromApi, setColorCodeFromAPi] = useState("");
  const [imageFromApi, setImageFromAPi] = useState("");
  const { data: editData, loading: dataLoading } = useQuery(EDIT_CATEGORY, {
    variables: { categoryId: categoryId2 ? +categoryId2 : "" },
    context: { clientName: "categoryClient" },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (editData) {
      if (editData.category !== null) {
        formik.setValues({
          name: editData.category.name,
          description: editData.category.description,
        });
        setState((prevState) => ({
          ...prevState,
          showPOS: editData.category.isPos,
          showOnline: editData.category.isOnline,
        }));
        setCharCount(editData.category.description.length);
        setColorCodeFromAPi(editData.category.bgColourCode);
        setImageFromAPi(editData.category.image);
        setCharCount1(editData.category.name.length)
      }
    }
  }, [editData]);

  const [updateCategoryData, { loading: updateLoading }] = useMutation(UPDATE_CATEGORY_GENARAL_DETAILS, {
    context: { clientName: "categoryClient" },
    refetchQueries: [CATEGORY_LIST],
    onCompleted: () => {
      handleComplete();
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        setCategoryStatusCode(
          parseInt(graphqlError.extensions.status, 10) || 500
        );
        setErrorModal(true);
      }
      // const errorMessage = graphqlError.message || 'An error occurred';
    },
  });

  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadColor, setUploadColor] = useState<string | null>(null);

  const handleImage = (value: string | null) => {
    setUploadImg(value);
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
  };

  const category = {
    name: formik.values.name.trim(),
    description: formik.values.description,
    isPos: mainState.showPOS,
    isShow: mainState.showOnline,
    image: uploadImg,
    businessId: +businessId,
    bgColourCode: uploadColor,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(category).filter(([_, value]) => value !== null)
  );

  const categoryUpdate = {
    categoryId: categoryId2,
    name: formik.values.name.trim(),
    businessId: +businessId,
    description: formik.values.description,
    isPos: mainState.showPOS,
    isOnline: mainState.showOnline,
    image: uploadImg,
    bgColourCode: uploadColor,
  };

  const filteredCategoryUpdate = Object.fromEntries(
    Object.entries(categoryUpdate).filter(([_, value]) => value !== null)
  );
  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  const [charCount1, setCharCount1] = useState(0);

  const updateCharCount1 = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 25) {
      event.target.value = event.target.value.substring(0, 25);
      count = 25;
    }
    // Update the character count state
    setCharCount1(count);
  };

  if (dataLoading) {
    return <CircularLoader />;
  }

  return (
    <Box>
      {/* <Typography className="create-product-details">{t("menu.catageory.details")}</Typography> */}
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ width: "100%", display: "flex" }}>
          <div style={{ width: "90%" }}>
            <div>
              <TextField
                id="outlined-basic"
                size="medium"
                label={t("menu.catageory.categoryName")}
                variant="outlined"
                name="name"
                sx={{ width: "60%" }}
                color="warning"
                error={categoryStatusCode === 409}
                onChange={(e) => {
                  if (e.target.value.length <= 25) {
                    formik.handleChange(e);
                    setCategoryStatusCode(0);
                    updateCharCount1(e);
                  }
                }}
                onBlur={formik.handleBlur}
                value={formik.values.name}
              />
              <Box sx={{ display: 'flex', width: "55%", justifyContent: 'space-between' }}>
                {formik.touched.name && formik.errors.name ? (
                  <div style={{ color: "red" }}>
                    <Typography sx={{ fontSize: "12px", mt: '5px' }}>
                      {formik.errors.name as string}{" "}
                    </Typography>
                  </div>
                ) : (
                  <div style={{ color: "red" }}>
                    <Typography sx={{ fontSize: "12px" }}>

                    </Typography>
                  </div>
                )}
                <div
                  id="charCount"
                  className="char-count1"
                  style={{ width: "0%" }}
                >
                  {charCount1}/25
                </div>
              </Box>
              {/* {statusCode === 409&&(<Typography sx={{color:"red"}}>{errorMsg}</Typography>)} */}
            </div>
            <div style={{ marginTop: "32px", width: "100%" }}>
              <div className="textarea-box" style={{ width: "100%" }}>
                <textarea
                  id="textarea"
                  onChange={(e) => {
                    updateCharCount(e);
                    formik.handleChange(e);
                  }}
                  style={{ width: "100%" }}
                  name="description"
                  value={formik.values.description}
                  //@ts-ignore
                  placeholder={t("product.enter_description")}
                >
                  {formik.values.description}
                </textarea>
                <div id="charCount" className="char-count">
                  {formik.values.description.length}/500
                </div>
              </div>
            </div>
          </div>

          <Box
            sx={{
              marginLeft: "86px",
              height: "150px",
              width: "150px",
              padding: "2px",
            }}
            className="create-product-img-con"
          >
            <ImageDrawer
              ref={imageDrawerRef}
              handleImage={handleImage}
              handleColor={handleColor}
              colorCodeFromApi={colorCodeFromApi}
              imageFromApi={imageFromApi}
              heightValue={120}
            />
          </Box>
        </Box>

        <div style={{ width: "100%", marginTop: "2.5%" }}>
          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                name="showPOS"
                checked={mainState.showPOS}
                onChange={handleInputChange}
              />
            }
            label={
              <Typography className="create-product-switch-text">
                {t("menu.catageory.showPos")}
              </Typography>
            }
          />
          <FormControlLabel
            control={
              <Checkbox
                color="warning"
                name="showOnline"
                checked={mainState.showOnline}
                onChange={handleInputChange}
              />
            }
            label={
              <Typography className="create-product-switch-text">
                {t("menu.catageory.showOnline")}
              </Typography>
            }
          // style={{ marginLeft:"2.5%" }}
          />
        </div>
        <Divider style={{ marginTop: "2.5%" }} />
        <div
          style={{ display: "flex", alignItems: "center", marginTop: "2.5%" }}
        >
          <Button
            sx={{
              textTransform: "none",
              textDecoration: "underline",
              marginRight: "2.1%",
            }}
            className="create-product-cancel_Button"
            onClick={handleCancelButton}
          >
            {t("menu.catageory.clear")}
          </Button>
          <Button
            sx={{ textTransform: "none" }}
            className="create-product-button"
            // id={mainState.categoryName.length < 1 ? "createCategory-save-button" : undefined}
            disabled={createLoading || updateLoading}
            type="submit"
            onClick={handleOpenDailouge}
          >
            {t("menu.catageory.save")}
          </Button>
        </div>
      </form>
      <AlertDialog
        dailouge={dailouge}
        handleUpdateDailougeState={handleUpdateDailougeState}
        handleComplete={handleComplete}
        categoryId={categoryId}
      />
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
    </Box>
  );
};

export default CategoryDetails;
