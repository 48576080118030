// src/components/Orders/index.tsx
import React, { useEffect, useState } from "react";
import OrdersTable from "../../components/OrdersTable";
import Filters from "./Filters";
import Header from "../../components/Header";
import "./styles.css";
import { Box, CircularProgress, Typography } from "@mui/material";
import { useMutation, useQuery } from "@apollo/client";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  GET_ORDERS_LIST,
  GET_STORE,
  ORDER_STATUS_CHANGE,
} from "../../orderUtils/graphqlQueries";
import { clientName } from "../../orderUtils/constant";
import Loader from "../../components/Loader";
import { orderingClient } from "../../orderUtils/helperFunctions";
import { useSelector } from "react-redux";
import { stringify } from "qs";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
interface State1 {
  status: string;
  reason: string;
}
const initialValues: State1 = {
  status: "",
  reason: "",
};
const reasonsListForHidingTextfield = ["Cancelled"];

const validationSchemaBasicDetails = Yup.object({
  status: Yup.string(),
  reason: Yup.string().when("status", {
    is: (value: string) => reasonsListForHidingTextfield.includes(value),
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const Orders: React.FC = () => {
  const { t } = useTranslation();
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaBasicDetails,
    onSubmit: (values) => {
      handleSavePress();
    },
  });
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [orderList, setOrderList] = useState<any>();
  const [changeId, setChangeId] = useState();
  const [orderResponse, setOrderResponse]: any = useState();
  // const [status, setStatus] = useState("");
  const [statusFilter, setstatusFilter] = useState<null | string>(null);
  const [ordertypeFilter, setordertypeFilter] = useState<null | string>(null);
  const [statusChangeData, setstatusChangeData] = useState({});
  const [searchQuery, setSearchQuery] = useState<null | string>(null);
  const [byOrders, setByOrders] = useState<null | string>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);

  const [currentPage, setCurrentPage] = React.useState(0);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(ordersData?.orders?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(ordersData?.orders?.pageInfo.startCursor || null);
  };

  const [open, setOpen] = useState(false);

  const [statusChange] = useMutation(ORDER_STATUS_CHANGE, {
    onCompleted: (data) => {
      if (data) {
        const { orderStatusChange } = data;
        if (orderStatusChange) {
          const { success } = orderStatusChange;
          if (success) {
            setOpen(false);
            formik.resetForm();
          }
        }
      }
    },
    onError: (error) => {
      if (error) {
        if (
          error.message.trim() ===
          "Order has pending Balance amount, Can't complete order status!"
        ) {
          toast.error(
            <div>
              <Typography>{t("error.order_pending")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        } else {
          console.log(error.message);
        }
      }
    },
    context: { clientName: "orderClient" },
    refetchQueries: [GET_ORDERS_LIST],
  });

  // useEffect(() => {
  //   setLoader(true)
  //   getOrderList()
  // }, [statusFilter, ordertypeFilter, statusChangeData,searchQuery,locationId]);

  const variables = {
    orderStatus: statusFilter,
    byOrders: byOrders !== "" ? byOrders : null,
    orderType: ordertypeFilter,
    businessId: businessId,
    storeId: +locationId,
    query: searchQuery,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };

  const filteredVariables = Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => value !== null)
  );

  const {
    loading: ordersLoading,
    error: ordersError,
    data: ordersData,
  } = useQuery(GET_ORDERS_LIST, {
    context: { clientName: "orderClient" },
    variables: filteredVariables,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (ordersData) {
      const { orders } = ordersData;
      if (orders) {
        const { edges } = orders;
        if (edges) {
          const filteredData = edges.map((each: any, index: number) => ({
            id: each.node.orderNo,
            // id: index,
            orderId: each.node.orderId,
            createdOn: each.node.createdOn,
            createdBy: each.node.createdBy,
            totalAmount: each.node.totalAmount,
            paymentStatus: each.node.paymentStatus,
            requestedTime: each.node.requestedTime,
            orderType: each.node.orderType,
            customerId: each.node.customerId,
            customerDetails: each.node.customerDetails,
            orderStatus: each.node.orderStatus,
            cart: each.node.cart,
          }));
          setOrderList(filteredData);
        }
      }
      // setOrderList(ordersData?.orders?.edges?.map((el: any) => el.node));
    }
  }, [ordersData]);

  // const getOrderList = () => {
  //   orderingClient.query({
  //     query: GET_ORDERS_LIST,
  //     variables:filteredVariables,
  //   }).then(response => {
  //     setOrderResponse(response)
  //     console.log(response,"orderResponse")
  //     setLoader(false)
  //     setOrderList(response?.data?.orders?.edges?.map((el: any) => el.node))
  //   }).catch(error => {
  //     console.error('error:', error);
  //     // setOrderList([])
  //   });
  // }

  const handleSavePress = () => {
    statusChange({
      variables: {
        orderId: changeId && +changeId,
        orderStatus: formik.values.status,
        cancellationReason: formik.values.reason,
      },
    });
  };

  const handleFilterStatusChange = (event: any) => {
    setstatusFilter(event.target.value);
  };
  const handleFilterOrderTypeChange = (event: any) => {
    setordertypeFilter(event.target.value);
  };

  const handleBByorders = (e: any) => {
    setByOrders(e.target.value);
  };

  return (
    <div style={{ margin: "1%" }}>
      {/* <Header /> */}

      <Typography className="order-list-header">
        {t("orders.orders_listing")}
      </Typography>
      <Filters
        handleFilterStatusChange={handleFilterStatusChange}
        statusFilter={statusFilter}
        byOrders={byOrders}
        handleBByorders={handleBByorders}
        ordertypeFilter={ordertypeFilter}
        handleFilterOrderTypeChange={handleFilterOrderTypeChange}
        setSearchQuery={setSearchQuery}
      />

      <OrdersTable
        // orderList={orderResponse?.data?.orders?.edges.length > 0 ? orderResponse?.data?.orders?.edges?.map((el: any) => el.node) : []}
        orderList={orderList?.length > 0 ? orderList : []}
        handleSavePress={handleSavePress}
        shouldUpdatePaginationModelIsTrue={shouldUpdatePaginationModelIsTrue}
        handleUpdatingValuesToNull={handleUpdatingValuesToNull}
        setChangeId={setChangeId}
        paginationModel={paginationModel}
        currentPage={currentPage}
        handleStartCursor={handleStartCursor}
        open={open}
        handleEndCursor={handleEndCursor}
        formik={formik}
        setOpen={setOpen}
        loader={ordersLoading}
        ordersData={ordersData}
      />

      <Loader loading={orderResponse?.loading} />
    </div>
  );
};

export default Orders;
