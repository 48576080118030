import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  IconButton,
  Modal,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { t } from "i18next";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import QrCodeScannerRoundedIcon from "@mui/icons-material/QrCodeScannerRounded";
import CloseIcon from "@mui/icons-material/Close";
import { useMutation } from "@apollo/client";
import { IS_VALID_FOR_CONNECT } from "../../graphicalQl/mutation/deviceMangementMutation";
import "./posSetup.css";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface State {
  open: boolean;
  handlePopUpClose: () => void;
  handleScanQrCodeButton: () => void;
  handleNextButton: () => void;
  formik: any;
  headerText: string;
  installText: string;
  installSubText: string;
  signInText: string;
}

const GenerateDeviceCodePopup = (props: State) => {
  const {
    open,
    handlePopUpClose,
    handleScanQrCodeButton,
    handleNextButton,
    headerText,
    formik,
    installText,
    installSubText,
    signInText,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [deleteDeliveryRule] = useMutation(IS_VALID_FOR_CONNECT, {
    onCompleted: (data) => {
      if (data) {
        const { deviceCodeValidityCheck } = data;
        if (deviceCodeValidityCheck) {
          const { valid } = deviceCodeValidityCheck;
          if (!valid) {
            formik.setFieldValue("isValid", true);
          } else {
            formik.setFieldValue("isValid", false);
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
  });

  const breadcrumbs = [
    <Typography
      className="device-set-up-first-pop-up-header-bredcrump1"
      key="2"
    >
      {t("devices.generate_device_code")}
    </Typography>,
    <Typography
      className="device-set-up-first-pop-up-header-bredcrump2"
      key="1"
    >
      {t("devices.device_details")}
    </Typography>,
  ];

  const handleDeviceCodeChange = (code: string) => {
    formik.setFieldValue("deviceCode", code);
    deleteDeliveryRule({
      variables: {
        deviceCode: code,
        deviceType: headerText,
      },
    });
  };

  const renderContent = () => (
    <Box className="generate-device-code-container">
      <form onSubmit={formik.handleSubmit}>
        <Box className="generate-device-code-breadcrumps-close-icon-order">
          <IconButton
            onClick={handlePopUpClose}
            className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
          >
            <CloseIcon />
          </IconButton>
          <Box sx={{ textAlign: "center" }}>
            <Typography className="device-set-up-first-pop-up-header">
              {t("devices.new")} {headerText} {t("devices.set_up")}
            </Typography>
            <Breadcrumbs
              sx={{
                "& ol": {
                  justifyContent: "center",
                },
              }}
              separator={<NavigateNextIcon fontSize="small" />}
              aria-label="breadcrumb"
            >
              {breadcrumbs}
            </Breadcrumbs>
          </Box>
        </Box>
        <Divider sx={{ marginTop: "10px" }} />

        <Box className="device-created-and-sign-in-texts-order ">
          <Box className="device-setup-device-create-text-width">
            <Typography className="device-set-up-second-pop-up-device-text">
              {t("devices.device_created")}
            </Typography>
            <Typography className="device-set-up-second-pop-up-signin-text">
              {t("devices.sign_in_with_your_device_code")}
            </Typography>
            <Typography className="device-set-up-second-pop-up-install-text">
              {t("devices.install_etm")} {installText} {t("devices.display")}
            </Typography>
            <Typography className="device-set-up-second-pop-up-install2-text">
              {t("devices.install_etm_small")} {installSubText}{" "}
              {t("devices.display_from_app")}
            </Typography>
            <Typography className="device-set-up-second-pop-up-install-text">
              {t("devices.sign_in")}
            </Typography>
            <Typography className="device-set-up-second-pop-up-install2-text">
              {t("devices.use_this_device")} {signInText} {t("devices.display")}
            </Typography>
          </Box>

          <Box
            sx={{
              width: isMobile ? "100%" : "40%",
              // height: isMobile ? "" : "45vh",
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              backgroundColor: "#FBFBFB",
              border: "2px solid #EEEEEE",
              borderRadius: "6px",
              padding: isMobile ? "10px" : "32px 20px 32px 20px",
              marginTop: isMobile ? 1 : 0,
            }}
          >
            <Typography
              sx={{ marginBottom: "5px" }}
              className="device-set-up-second-pop-up-device-code-text"
            >
              {t("devices.enter_device_code")}
            </Typography>

            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <TextField
                value={formik.values.deviceCode}
                onBlur={formik.handleBlur}
                name="deviceCode"
                onChange={(e) => {
                  handleDeviceCodeChange(e.target.value);
                }}
                //@ts-ignore
                placeholder={t("devices.enter_device_code")}
                id="outlined-start-adornment"
                sx={{ width: "100%" }}
                color="warning"
              />
              {(formik.touched.deviceCode && formik.errors.deviceCode) ||
              formik.values.isValid ? (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {(formik.errors.deviceCode && t("error.required")) ||
                      (formik.values.isValid && t("error.invalid"))}
                  </Typography>
                </Box>
              ) : null}
            </Box>

            <Box className="enter-device-code-text-container">
              <Typography
                sx={{ marginBottom: "10px" }}
                className="device-set-up-second-pop-up-enter-device-text"
              >
                {t("devices.enter_device_code_or_scan")}
              </Typography>
            </Box>
            <Button
              onClick={handleScanQrCodeButton}
              className="device-setup-scan-qr-code-popup"
              variant="contained"
            >
              <QrCodeScannerRoundedIcon sx={{ marginRight: "5px" }} />{" "}
              {t("devices.scan_qr_code")}
            </Button>
          </Box>
        </Box>

        <Box className="device-setup-next-button-order">
          <Button
            type="submit"
            className="device-set-up-first-pop-up-next-button"
            variant="contained"
          >
            {t("devices.next")}
          </Button>
        </Box>
      </form>
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handlePopUpClose}
          sx={{
            "& .MuiDrawer-paper": {
              height: "100%", // Set drawer height to 50% of screen height
            },
          }}
        >
          {renderContent()}
        </Drawer>
      ) : (
        <Modal
          open={open}
          // onClose={handleFirstPopUpClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>{renderContent()}</Box>
        </Modal>
      )}
    </Box>
  );
};

export default GenerateDeviceCodePopup;
