import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './createCategory.css'
import { useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { useNavigate } from 'react-router';
import { useTranslation } from "react-i18next";

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

interface Props {
  dailouge: boolean
  handleUpdateDailougeState: () => void;
  handleComplete: () => void;
  categoryId: string
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function AlertDialog(props: Props) {
  const { dailouge, handleUpdateDailougeState, handleComplete, categoryId } = props
  const [open, setOpen] = React.useState(dailouge);
  const { t } = useTranslation();


  const handleClose = () => {
    setOpen(false);
    handleUpdateDailougeState()
  };

  useEffect(() => {
    if (categoryId !== "")
      setOpen(true);
  }, [categoryId]);


  const navigate = useNavigate()
  const handleSkipNavigate = () => {
    navigate('/category/category-listing')
  }

  const handleNextButton = () => {
    handleComplete()
  }

  return (
    <div>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: "center", width: "100%" }}>
            {/* <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
  </Box> */}

            <Typography className='category-created'>{t("menu.catageory.category_created")}</Typography>
          </Box>
          <Divider />
          <Box sx={{ padding: "3% 1% 3% 1%", marginTop: '1%' }}>


            <Typography className='create_category_modal_text'>

              <span className='createCategory-table-head'> {t("menu.catageory.assign_products_msg")}</span>  {t("menu.catageory.then")} <span className='create_category_modal_next_text'>{t("menu.catageory.click_next")}</span>{t("menu.catageory.skip_msg")}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
            <Button className='create_category_modal_skip_button' onClick={() => { handleClose(); handleSkipNavigate() }}> {t("menu.catageory.skip")}</Button>
            <Button className='create_category_modal_next_button' sx={{ marginLeft: "3%" }} onClick={() => { handleClose(); handleNextButton() }}>{t("menu.catageory.next")} {<ArrowForwardIcon />}</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}










