import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { TIME_ZONES, LANGUAGES, CURRENCIES } from "../../../common/contants";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_ACCOUNT_PREFERENCES } from "../../../../graphicalQl/mutation/customerDataMutation";
import { GET_PREFENCES } from "../../../../graphicalQl/usequery/settingQuery";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const Preferences = () => {
  const [preferences, setPreferences] = useState({
    timeZone: "",
    currency: "",
    languages: "",
  });
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [
    updateAccountPreferences,
    {
      data: updateAccountPreferencesData,
      error: updateAccountPreferencesError,
    },
  ] = useMutation(UPDATE_ACCOUNT_PREFERENCES, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      // show success message
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  //check for successfull updated and perform the required functions
  useEffect(() => {
    if (updateAccountPreferencesData?.branchSettings?.success) {
    }
  }, [updateAccountPreferencesData]);

  const saveAccountPreferences = () => {
    updateAccountPreferences({
      variables: {
        ...preferences,
        storeId: +locationId,
        languages: JSON.stringify([preferences.languages]),
      },
    });
  };

  const { data } = useQuery(GET_PREFENCES, {
    context: { clientName: "businessClient" },
    variables: {
      storeId: +locationId,
    },
  });

  useEffect(() => {
    if (data?.storeSettings) {
      setPreferences({
        timeZone: data.storeSettings.timeZone,
        currency: data.storeSettings.currency,
        languages: data.storeSettings.languages
          ? JSON.parse(data.storeSettings.languages)[0]
          : "",
      });
    }
  }, [data]);

  return (
    <Box>
      <Box
        sx={{
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {t("account_settings.account_preference")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "4px",
          }}
        >
          {t("account_settings.set_account_preference")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "804px",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          padding: "20px 12px",
          boxSizing: "border-box",
          borderLeft: "2px solid #AE9BE2",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
          marginLeft: "32px",
          marginTop: "32px",
        }}
      >
        <FormControl sx={{ width: "480px" }}>
          <InputLabel id="Country">
            {t("account_settings.time_zones")}
          </InputLabel>
          <Select
            id="timeZone"
            name="Time Zones"
            label={t("account_settings.time_zones")}
            renderValue={(selected) => {
              // show placeholder text when no option is selected
              return selected !== ""
                ? selected
                : t("account_settings.time_zones");
            }}
            value={preferences.timeZone}
            onChange={(event: SelectChangeEvent) =>
              setPreferences((prev) => ({
                ...prev,
                timeZone: event.target.value,
                currency:
                  TIME_ZONES.find(
                    (each: any) => each.abbr === event.target.value
                  )?.currency || "",
              }))
            }
          >
            {TIME_ZONES.map((each: any) => (
              <MenuItem value={each.abbr}>{each.value}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "480px" }}>
          <InputLabel id="languagess">
            {t("account_settings.languagess")}
          </InputLabel>
          <Select
            id="languagess"
            name="languagess"
            label={t("account_settings.languagess")}
            renderValue={(selected) => {
              // show placeholder text when no option is selected
              return selected !== ""
                ? selected
                : t("account_settings.select_languages");
            }}
            value={preferences.languages}
            onChange={(event: SelectChangeEvent) =>
              setPreferences((prev) => ({
                ...prev,
                languages: event.target.value,
              }))
            }
          >
            {LANGUAGES.map((each: any) => (
              <MenuItem value={each.name}>{each.name}</MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl sx={{ width: "480px" }}>
          <InputLabel id="currencies">
            {t("account_settings.currencies")}
          </InputLabel>
          <Select
            id="currencies"
            name="currencies"
            label={t("account_settings.currencies")}
            disabled
            renderValue={(selected: any) => {
              // show placeholder text when no option is selected
              return selected !== ""
                ? selected
                : t("account_settings.select_currency");
            }}
            value={preferences.currency}
            onChange={(event: SelectChangeEvent) =>
              setPreferences((prev) => ({
                ...prev,
                currency: event.target.value,
              }))
            }
          >
            {/* {CURRENCIES.map((each: any) => (
              <MenuItem value={each.cc}>{each.name}</MenuItem>
            ))} */}
          </Select>
        </FormControl>
        <Button
          onClick={() => saveAccountPreferences()}
          sx={{
            width: "94px",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            {t("buttons.save")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default Preferences;
