import React, { FC, MouseEvent } from "react";

interface ButtonProps {
  title: string;
  clickHandler: (event: MouseEvent<HTMLButtonElement>) => void;
  width: string;
  customImage?: string;
}

const Button: FC<ButtonProps> = ({ title, clickHandler, width, customImage }) => {
  return (
    <button
      style={{ width }}
      className="commonButton"
      onClick={clickHandler}
      type="button"
    >
      {title}
      {customImage && (
        <img className="buttonImage" src={customImage} alt="" />
      )}
    </button>
  );
};

export default Button;
