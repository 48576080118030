import React from 'react';

interface CurrencyDisplayProps {
  countryName: string;
  amount: string | number | (string | number)[];
}

const CurrencyDisplay: React.FC<CurrencyDisplayProps> = ({ countryName, amount }) => {
  const currencyCodeFromSession = sessionStorage.getItem("currency")?.toUpperCase()
  const getCurrencyCode = (countryName: string): string => {
    const countryToCurrency: { [key: string]: string } = {
      India: 'INR',
      UnitedStates: 'USD',
      UnitedKingdom: 'GBP',
      Russia: 'RUB',
      Belarus: 'BYN',
    };

    return countryToCurrency[countryName] || countryName;
  };

  const currencyFormat = (value: number, countryName: string): string => {
    // const currencyCode = getCurrencyCode(countryName);
    const currencyCode = currencyCodeFromSession || "GBP"
    return value.toLocaleString('en-US', {
      style: 'currency',
      currency: currencyCode,
    });
  };

  const renderCurrencyRange = (range: (string | number)[]): JSX.Element => (
    <>
      {range.map((value, index) => (
        <span key={index}>
          {currencyFormat(typeof value === 'string' ? parseFloat(value) : value, countryName)}
          {index < range.length - 1 && '- '}
        </span>
      ))}
    </>
  );

  return (
    <div>
      {Array.isArray(amount) ? (
        renderCurrencyRange(amount)
      ) : (
        <span> {currencyFormat(typeof amount === 'string' ? parseFloat(amount) : amount, countryName)}</span>
      )}
    </div>
  );
};

export default CurrencyDisplay;
