import { gql } from "@apollo/client";

export const CREATE_OPTION_GROUP = gql`
mutation createOptionGroup($name:String,$options:JSONString,$businessId:Int!) {
    createOptionGroup(
        businessId:$businessId,
        name:$name,
        options:$options
        
      ) {
            errorMessage
            optionGroup{optionGroupId
            name
            businessId
            options}
        success
         
        
      }
    }
`
export const DELETE_OPTIONGROUP_OPTION = gql`
mutation deleteOptionGroup($optionGroupId:Int!) {
  deleteOptionGroup(
  
    optionGroupId:$optionGroupId
    ) {
      message
      success
       
      
    }
  }
`

export const REMOVE_OPTION_FROM_OPTIONGROUP = gql`
mutation removeOptionFromOptiongroup($optionGroupId:Int!,$optionId:Int!) {
  removeOptionFromOptiongroup(
    optionGroupId:$optionGroupId,
    optionId:$optionId
    
    ) {
      errorMessage
      optionGroup{optionGroupId
        name
      options
      
      }
    
      success
       
      
    }
  }
`

export const ADD_OPTIONS_TO_OPTIONGROUP = gql`
mutation addOptionsToOptiongroup($optionGroupId:Int!,$optionIds:JSONString!){
  addOptionsToOptiongroup(
    optionGroupId:$optionGroupId,
    optionIds:$optionIds
    
    ) {
      errorMessage
      optionGroup{optionGroupId
        name
      options
      
      }
    
      success
    }
  }
`

export const UPDATE_OPTION_GROUP_NAME = gql`
mutation updateOptionGroup($optionGroupId:Int!,$name:String){
  updateOptionGroup(
    optionGroupId:$optionGroupId,
    name:$name
    
    ) {
      errorMessage
      optionGroup{optionGroupId
        name
      options
      }
    
    
      success
       
      
    }
  }
  
`
export const DELETE_OPTION = gql`
mutation DeleteOptions($optionIds:JSONString!){
  deleteOptions(
    optionIds:$optionIds,
    
    
    ) {
      message
    
      
      success
      }
    }
`

export const CREATE_OPTION = gql`
mutation createOption($businessId:Int!,$name:String,$minSelect:Int,$maxSelect:Int,$isConversationalModifier:Boolean,$optionValues:JSONString,$modifiers:JSONString){
  createOption(
    businessId:$businessId,
    name:$name,
    minSelect:$minSelect,
    maxSelect:$maxSelect,
    isConversationalModifier:$isConversationalModifier,
    modifiers:$modifiers,
    optionValues:$optionValues
  ) {
      errorMessage
      option{optionId}
      success
       
      
    }
  }
  
`
export const EDIT_OPTION = gql`
query Option($optionId:ID){
    option(optionId:$optionId) {
        optionId
          
            
                minSelect
                maxSelect
        isConversationalModifier
        
                isConversationalModifier
                name
                modifiers{
                  modifierId
                   name
                }
                optionValues{optionValueId
                    name
                    price
                    image
                    bgColourCode
                }
                }
            }
    
`

export const UPDATE_OPTION = gql`
mutation UpdateOption($optionId:Int!,$name:String,$minSelect:Int,$maxSelect:Int,$allowMultipleTimes:Boolean,$modifiers:JSONString,
  $optionValues:JSONString,$isConversationalModifier:Boolean){
  updateOption(
    optionId:$optionId,
    name:$name
    minSelect:$minSelect,
    maxSelect:$maxSelect,
    allowMultipleTimes:$allowMultipleTimes,
    modifiers:$modifiers
    
    isConversationalModifier:$isConversationalModifier,
  optionValues:$optionValues
    
    
    ) {
      errorMessage
      option{optionId
        name
        minSelect
        maxSelect
        isConversationalModifier
      }
    
      success
    }
  }
`
export const ADD_MODIFIER = gql`
mutation AddModifier($businessId:Int!,$name:String){
  addModifier(
    businessId:$businessId,
    name:$name,
    
  
  
    
    ) {
  message
      success
      modifier{
		
        modifierId
      }
      }
     
       
      
    }
`

export const ASSIGN_OPTION_TO_PRODUCTS = gql`
mutation assignOptionToProducts($optionId:ID!,$products:JSONString){
  assignOptionToProducts(
    optionId:$optionId,
    products:$products
  ) {
    success
    message
    
      
  }
    
  }
`

export const DELETE_MODIFIER = gql`
mutation RemoveModifier($businessId:Int!,$modifierId:Int!){
	removeModifier(businessId:$businessId,modifierId:$modifierId){
	message
		success
	}
}
`

export const DUPLICATE_OPTION = gql`
mutation DuplicateOption($optionId:Int!){
  duplicateOption(
    optionId:$optionId
    
    
  ) {
    
    success
    errorMessage
    option{optionId}
    
      
  }
    
  }
  
`