import { Box } from "@mui/material"
import image from "../assets/400badRequest.png"

const BadRequestError = () => {
    return (
        <Box sx={{ width: "100vw", height: '80vh', display: "flex", alignItems: "center", justifyContent: "center" }}>
            <img src={image} alt="400 badRequest" />
        </Box>
    )
}

export default BadRequestError