import { gql } from "@apollo/client";

export const CHANGE_HIDE = gql`
mutation ShowHideReview ($orderId:Int!,$status:String){
showHideReview(
	orderId:$orderId
	status:$status
	
	
	
  ) {
		message
	
		success
		}
}
`