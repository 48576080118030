import { Box } from "@mui/material";
import CustomersReports from "../../components/reports/customers";
import React from "react";

const CustomerReportPage = () => {
  return (
    <Box>
      <CustomersReports />
    </Box>
  );
};
export default CustomerReportPage;
