import React, { FC } from "react";
import { Button, Typography } from "@mui/material";
import PrintIcon from "../../../assets/print.png";
import { useReactToPrint } from "react-to-print";

interface Props {
  data?: any[];
  contentRef: any;
}

const ExportButton: FC<Props> = ({ data, contentRef }) => {
  const handlePrint = useReactToPrint({
    content: () => contentRef.current,
  });

  // const handleExportPdf = () => {
  //   const doc = new jsPDF();
  //   doc.html(contentRef.current, {
  //     callback: function (pdf) {
  //       pdf.save("table.pdf");
  //     },
  //   });
  // };

  return (
    <Button
      onClick={() => {
        handlePrint();

        // const jsonString = JSON.stringify(data);
        // const base64Data = btoa(jsonString);
        // const fileName = "abc.pdf";
        // const linkSource = `data:application/pdf;base64`;

        // const downloadLink = document.createElement("a");
        // downloadLink.href = linkSource;
        // downloadLink.download = fileName;
        // downloadLink.click();

        // // Open a new window for printing
        // const printWindow = window.open("", "_blank");
        // if (printWindow) {
        //   printWindow.document.write(`
        //             <html>
        //             <head>
        //             <title>${fileName}</title>
        //             </head>
        //             <body>
        //             <embed width="100%" height="100%" type="application/pdf" src="${linkSource}" />
        //             </body>
        //             </html>
        //   `);

        //   // Wait for the document to be ready before triggering print
        //   printWindow.document.addEventListener("DOMContentLoaded", () => {
        //     printWindow.print();
        //     printWindow.onafterprint = () => {
        //       printWindow.close();
        //     };
        //   });
        // }
      }}
      sx={{
        border: "1px solid #EEEEEE",
        width: "120px",
        height: "44px",
        borderRadius: "8px",
        marginRight: "28px",
      }}
    >
      <Typography
        sx={{
          color: "#333333",
          fontWeight: "500",
          fontSize: "16px",
          fontFamily: "poppins",
          marginRight: "6px",
        }}
      >
        Export
      </Typography>
      <img src={PrintIcon} alt="PrintIcon" />
    </Button>
  );
};

export default ExportButton;
