import {
  Button,
  Paper,
  Typography,
  TextField,
  Switch,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  IconButton,
  Box,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useMutation, useQuery } from "@apollo/client";
import {
  createUserRole,
  UPDATE_ROlE,
} from "../../graphicalQl/mutation/userProfileMutation";
import {
  No_OF_PERMISSIONS,
  User_Roles,
  NUMBER_OF_PERMISSIONS_BY_ROLE_ID,
} from "../../graphicalQl/usequery/userProfileUseQuery";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../options/createProduct.css";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./createUserRole.css";
import Modal from "@mui/material/Modal";
import { useParams } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import ErrorModal from "../errorHandling/errorModal";
import AlertPopupForError from "../popups/AlertPopup";

const validationSchemaPart1 = Yup.object({
  roleName: Yup.string().trim().required("Role Name is required"),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface UserRole {
  roleName: string;
  posSwitch: boolean;
  adminSwitch: boolean;
  generalSwitch: boolean;
}
const CreateUserRole = () => {
  const [alertopen, setAlertOpen] = React.useState(false);
  const { t } = useTranslation();
  const [allPermissionsList, setAllPermissionsList] = useState<any[]>([]);
  const [allPermissionsListInitial, setAllPermissionsListInitial] = useState<
    any[]
  >([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const params = useParams();
  const { editId } = params;
  const [posPermissionList, setPosPermissionList] = useState<any>([]);
  const [generalPermissionList, setGeneralPermissionList] = useState<any>([]);
  const [adminPermissionList, setAdminPermissionList] = useState<any>([]);
  const [initialPosPermissionList, setInitialPosPermissionList] = useState<any>(
    []
  );
  const [initialAdminPermissionList, setInitialAdminPermissionList] =
    useState<any>([]);

  const [saveButtonState, setSaveButtonState] = useState(false);
  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [open, setOpen] = React.useState(false);
  const [existingRoleName, setExistingRoleName] = useState<string | null>(null);

  const [alertPopupForNotSaved, setAlertPopupForNotSaved] =
    useState<boolean>(false);

  const handleClose = () => setOpen(false);

  function toPascalCase(str: any) {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  }

  const initialValues: UserRole = {
    roleName: "",
    posSwitch: true,
    adminSwitch: true,
    generalSwitch: true,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (editId === undefined) {
        if (allPermissionsList.length === 0) {
          setAlertOpen(true);
        } else {
          handleCreateSaveSubitButton();
        }
      } else {
        if (allPermissionsList.length === 0) {
          setAlertOpen(true);
        } else {
          setOpen(true);
        }
      }
    },
  });

  useEffect(() => {
    function areArraysEqual(arr1: any, arr2: any) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }
      return true;
    }

    if (
      !areArraysEqual(allPermissionsList, allPermissionsListInitial) ||
      existingRoleName !== formik.values.roleName
    ) {
      setSaveButtonState(false);
    } else {
      setSaveButtonState(true);
    }
  }, [allPermissionsList, formik.values.roleName, allPermissionsListInitial]);

  const {
    data: editUserData,
    loading: editUserLoading,
    error: editUserError,
  } = useQuery(NUMBER_OF_PERMISSIONS_BY_ROLE_ID, {
    context: { clientName: "userClient" },
    variables: { roleId: editId },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (editUserData) {
      const { roleName, permissions } = editUserData.role;
      const filteredList = permissions
        .filter(
          (each: any) =>
            each.permissionId !== undefined && each.permissionId !== null
        ) // Filtering out undefined or null permissionId
        .map((eachId: any) => eachId.permissionId);
      if (permissions) {
        setAllPermissionsList(filteredList);
        setAllPermissionsListInitial(filteredList);
      } else {
        setAllPermissionsList([]);
        setAllPermissionsListInitial([]);
      }

      formik.setValues({ ...formik.values, roleName });
      setExistingRoleName(roleName);
    }
  }, [editUserData]);

  //getting number of permissions using API call

  const {
    data: numberOfPermissionsData,
    loading: numberOfPermissionsLoading,
    error: numberOfPermissionsError,
  } = useQuery(No_OF_PERMISSIONS, { context: { clientName: "userClient" } });
  useEffect(() => {
    if (numberOfPermissionsData) {
      const requiredDataList = numberOfPermissionsData.permissions.edges.map(
        (each: any) => ({
          name: each.node.name,
          appliesFor: JSON.stringify(each.node.appliesFor),
          permissionId: each.node.permissionId,
          isChecked: false,
        })
      );
      const editGeneralPermissionsList = requiredDataList.filter((each: any) =>
        each.appliesFor.includes("general")
      );
      const editPosPermissionList = requiredDataList.filter((each: any) =>
        each.appliesFor.includes("pos")
      );
      const editAdminPermissionList = requiredDataList.filter((each: any) =>
        each.appliesFor.includes("admin")
      );
      setPosPermissionList(editPosPermissionList);
      setGeneralPermissionList(editGeneralPermissionsList);
      setAdminPermissionList(editAdminPermissionList);
      setInitialAdminPermissionList(editAdminPermissionList);
      setInitialPosPermissionList(editPosPermissionList);
    }
  }, [numberOfPermissionsData]);

  //Creating user role by using API call
  const [creatingUserRole] = useMutation(createUserRole, {
    onCompleted: (data) => {
      if (data) {
        const { createRole } = data;
        if (createRole) {
          const { success } = createRole;
          if (success) {
            window.history.back();

            toast.success(
              <div>
                <Typography>{t("toast.created")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message) {
        if (error.message.trim() === "Role name should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.role_name")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        }
      }
    },
    context: { clientName: "userClient" },
    refetchQueries: [
      User_Roles,
      NUMBER_OF_PERMISSIONS_BY_ROLE_ID,
      No_OF_PERMISSIONS,
    ],
  });

  const [updateUserRole] = useMutation(UPDATE_ROlE, {
    onCompleted: (data) => {
      if (data) {
        const { updateRole } = data;
        if (updateRole) {
          const { success } = updateRole;
          if (success) {
            window.history.back();

            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message) {
        if (error.message.trim() === "Role name should be unique!") {
          toast.error(
            <div>
              <Typography>{t("toast.role_name")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast4",
            }
          );
        }
      }
      setOpen(false);
    },
    context: { clientName: "userClient" },
    refetchQueries: [
      User_Roles,
      NUMBER_OF_PERMISSIONS_BY_ROLE_ID,
      No_OF_PERMISSIONS,
    ],
  });

  const handleCreateSaveSubitButton = () => {
    const permissionsIntegersList = allPermissionsList.map((each) =>
      parseInt(each, 10)
    );

    creatingUserRole({
      variables: {
        businessId: +businessId,
        roleName: formik.values.roleName.trim(),
        permissions: JSON.stringify(permissionsIntegersList),
      },
    });

    setPosPermissionList(initialPosPermissionList);
    setAdminPermissionList(initialAdminPermissionList);
  };

  const handleEditSaveSubitButton = () => {
    setAlertPopupForNotSaved(false);

    if (editId) {
      const permissionsIntegersList = allPermissionsList.map((each) =>
        parseInt(each, 10)
      );
      // setOpen(true)
      updateUserRole({
        variables: {
          businessId: +businessId,
          roleId: +editId,
          roleName: formik.values.roleName.trim(),
          permissions: JSON.stringify(permissionsIntegersList),
        },
      });
    }

    setPosPermissionList(initialPosPermissionList);
    setAdminPermissionList(initialAdminPermissionList);
    // setRoleNameInput("")
  };

  //Updating Checkbox Values
  const handleChangeOnPosPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setAllPermissionsList((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(id)) {
        return [...prevIds, id];
      } else if (!e.target.checked && prevIds.includes(id)) {
        return prevIds.filter((prevId: any) => prevId !== id);
      }
      return prevIds;
    });
  };
  const handleChangeAdminPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setAllPermissionsList((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(id)) {
        return [...prevIds, id];
      } else if (!e.target.checked && prevIds.includes(id)) {
        return prevIds.filter((prevId: any) => prevId !== id);
      }
      return prevIds;
    });
  };

  const handleChangeGeneralPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    setAllPermissionsList((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(id)) {
        return [...prevIds, id];
      } else if (!e.target.checked && prevIds.includes(id)) {
        return prevIds.filter((prevId: any) => prevId !== id);
      }
      return prevIds;
    });
  };

  const handleClearButton = () => {
    setAllPermissionsList([]);
    formik.resetForm();
  };

  function areArraysEqual(arr1: any, arr2: any) {
    if (arr1.length !== arr2.length) {
      return false;
    }
    const sortedArr1 = arr1.slice().sort();
    const sortedArr2 = arr2.slice().sort();
    for (let i = 0; i < sortedArr1.length; i++) {
      if (sortedArr1[i] !== sortedArr2[i]) {
        return false;
      }
    }
    return true;
  }
  //handle back arrow button
  const handleNavigateBackButton = () => {
    if (editId) {
      const enable =
        !areArraysEqual(allPermissionsList, allPermissionsListInitial) ||
        existingRoleName?.trim() !== formik.values.roleName.trim();
      if (enable && existingRoleName?.trim() !== "Admin") {
        setAlertPopupForNotSaved(true);
      } else {
        window.history.back();
      }
    } else {
      window.history.back();
    }
  };

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  return (
    <Box sx={{ padding: "10px", border: "2px solid #EEE" }}>
      <AlertPopupForError
        title={t("users.information")}
        open={alertopen}
        setOpen={setAlertOpen}
        error={t("users.please_select_atleast_one_permission")}
      />
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
      <form onSubmit={formik.handleSubmit}>
        <Box
          className="create-a-use-role-header-order"
          sx={{ marginBottom: "10px", padding: "10px" }}
        >
          <IconButton
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
            onClick={handleNavigateBackButton}
          >
            <ArrowBackIcon />
          </IconButton>

          <Typography className="create-a-use-role-heading">
            {" "}
            {editId !== undefined
              ? t("users.edit_a_role")
              : t("users.create_a_user_role")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
              gap: "10px",
            }}
          >
            {editId === undefined && (
              <Button
                onClick={handleClearButton}
                sx={{ textTransform: "none" }}
                className="create-a-use-role-clear-button"
                variant="outlined"
                color="warning"
              >
                {t("users.clear")}
              </Button>
            )}
            <Button
              disabled={
                (editId &&
                  saveButtonState &&
                  allPermissionsList.length === 0) ||
                existingRoleName?.trim() === "Admin"
                  ? true
                  : false
              }
              type="submit"
              sx={{ textTransform: "none" }}
              variant="contained"
              color="warning"
            >
              {t("users.save")}
            </Button>
          </Box>
        </Box>
        <Box sx={{ padding: "25px" }}>
          <Box>
            <TextField
              id="outlined-required"
              name="roleName"
              value={formik.values.roleName}
              label={t("users.role_name")}
              sx={{ backgroundColor: "#ffffff", width: "26rem" }}
              color="warning"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            />
            {formik.touched.roleName && formik.errors.roleName && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {t("error.required")}
                </Typography>
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
              width: "100%",
            }}
          >
            <Paper
              sx={{
                width: "30%",
                height: "70vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  alignItems: "center",
                  height: "85px",
                }}
              >
                <Box>
                  <Typography className="create-a-use-role-checkbox-heading">
                    {t("users.pos")}
                  </Typography>
                  <Typography className="create-a-use-role-checkbox-paragraph">
                    {t("users.users_can_log")}
                  </Typography>
                </Box>
                {/* <Box>
                  <Switch
                    name="posSwitch"
                    onChange={formik.handleChange}
                    checked={formik.values.posSwitch}
                    color="warning"
                  />
                </Box> */}
              </Box>
              <Divider />

              <Box
                sx={{
                  height: "55vh",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <FormGroup sx={{ padding: "10px" }}>
                  {posPermissionList.map((each: any) => (
                    <FormControlLabel
                      disabled={!formik.values.posSwitch}
                      checked={allPermissionsList.includes(each.permissionId)}
                      control={
                        <Checkbox
                          className="create-a-use-role-checkbox-hover"
                          onChange={(e) => {
                            handleChangeOnPosPermissions(e, each.permissionId);
                          }}
                          color="warning"
                        />
                      }
                      label={
                        <Typography className="create-a-use-role-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      key={each.id}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>

            <Paper
              sx={{
                width: "30%",
                padding: "5px",
                height: "70vh",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "10px",
                  alignItems: "center",
                  height: "85px",
                }}
              >
                <Box>
                  <Typography className="create-a-use-role-checkbox-heading">
                    {t("users.back_office")}
                  </Typography>
                  <Typography className="create-a-use-role-checkbox-paragraph">
                    {t("users.users_can_log_into_admin")}
                  </Typography>
                </Box>
                {/* <Box>
                  <Switch
                    color="warning"
                    name="adminSwitch"
                    onChange={formik.handleChange}
                    checked={formik.values.adminSwitch}
                  />
                </Box> */}
              </Box>
              <Divider />
              <Box
                sx={{
                  height: "55vh",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <FormGroup sx={{ padding: "10px" }}>
                  {adminPermissionList.map((each: any) => (
                    <FormControlLabel
                      disabled={!formik.values.adminSwitch}
                      control={
                        <Checkbox
                          checked={allPermissionsList.includes(
                            each.permissionId
                          )}
                          onChange={(e) => {
                            handleChangeAdminPermissions(e, each.permissionId);
                          }}
                          color="warning"
                        />
                      }
                      label={
                        <Typography className="create-a-use-role-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      key={each.id}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>

            <Paper
              sx={{
                width: "30%",
                padding: "5px",
                height: "70vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "5px",
                  alignItems: "center",
                  height: "85px",
                }}
              >
                <Box>
                  <Typography className="create-a-use-role-checkbox-heading">
                    {t("users.general")}
                  </Typography>
                  <Typography className="create-a-use-role-checkbox-paragraph">
                    {t("users.general_permissions")}
                  </Typography>
                </Box>
                {/* <Box>
                  <Switch
                    color="warning"
                    name="generalSwitch"
                    onChange={formik.handleChange}
                    checked={formik.values.generalSwitch}
                  />
                </Box> */}
              </Box>
              <Divider />
              <Box
                sx={{
                  height: "55vh",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <FormGroup sx={{ padding: "10px" }}>
                  {generalPermissionList.map((each: any) => (
                    <FormControlLabel
                      disabled={!formik.values.generalSwitch}
                      control={
                        <Checkbox
                          checked={allPermissionsList.includes(
                            each.permissionId
                          )}
                          onChange={(e) => {
                            handleChangeGeneralPermissions(
                              e,
                              each.permissionId
                            );
                          }}
                          color="warning"
                        />
                      }
                      label={
                        <Typography className="create-a-use-role-checkbox-label">
                          {toPascalCase(each.name)}
                        </Typography>
                      }
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      key={each.id}
                    />
                  ))}
                </FormGroup>
              </Box>
            </Paper>
          </Box>
        </Box>
      </form>
      <Box>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <IconButton
                onClick={handleClose}
                className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
              }}
            >
              <Typography className="create-a-use-role-popup-text">
                {t("users.are_you_sure")}
              </Typography>
              <Typography
                className="create-a-use-role-popup-description"
                sx={{ mt: 2 }}
              >
                {t("users.permissions")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: "15px",
                  width: "100%",
                }}
              >
                <Button
                  onClick={handleEditSaveSubitButton}
                  className="create-user-role-pop-up-save-button"
                  sx={{ textTransform: "none", width: "35%" }}
                  variant="outlined"
                  color="warning"
                >
                  {t("buttons.save_changes")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>

        <Modal
          open={alertPopupForNotSaved}
          onClose={() => setAlertPopupForNotSaved(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <IconButton
                onClick={() => setAlertPopupForNotSaved(false)}
                className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
              >
                <CloseIcon />
              </IconButton>
            </Box>
            <Divider sx={{ marginTop: "10px", marginBottom: "5%" }} />
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                marginBottom: "5%",
              }}
            >
              <Typography className="create-a-use-role-popup-text">
                {t("users.are_you_sure")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                  marginTop: "10%",
                  width: "100%",
                }}
              >
                <Button
                  onClick={() => {
                    window.history.back();
                  }}
                  className="create-user-role-pop-up-save-button"
                  sx={{ textTransform: "none", width: "37%" }}
                  variant="outlined"
                  color="warning"
                >
                  Discard Changes
                </Button>
                <Button
                  onClick={handleEditSaveSubitButton}
                  className="create-user-role-pop-up-save-button"
                  sx={{ textTransform: "none", width: "35%", color: "#fff" }}
                  variant="contained"
                  color="warning"
                >
                  {t("buttons.save_changes")}
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default CreateUserRole;
