import { Box } from "@mui/material";
import React from "react";
import ActivityLogReport from "../../components/reports/activityLog";

const ActivityLogReportPage = () => {
  return (
    <Box>
      <ActivityLogReport />
    </Box>
  );
};
export default ActivityLogReportPage;
