import { Box, Button, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MobileIcon from "../../../../assets/phone-android copy.svg";
import TwoFactorAuthenticationModal from "./twoFactorAuthenticationModal";
import VerificationCode from "./verificationCodeModal";
import { useMutation, useQuery } from "@apollo/client";
import { BUSINESS_DETAILS } from "../../../../graphicalQl/usequery/customerDataQuery";
import UpdateEmailModal from "./updateEmailModal";
import UpdatePhoneModal from "./updatePhoneModal";
import UpdatePasswordModal from "./updatePassword";
import { SIGN_OUT_EVERYWHERE } from "../../../../graphicalQl/mutation/salesTaxMutation";
import moment from "moment";
import { localeToFormat } from "../../../../utils/dateFormat";
import { useTranslation } from "react-i18next";
const SignInAndSecurity = () => {
  const { t } = useTranslation()
  const [twoFactorAuthenticationModal, setTwoFactorAuthenticationModal] =
    useState({
      state: false,
      data: {},
    });

  const [verificationCodeModal, setVerificationCodeModal] = useState({
    state: false,
    data: {},
  });
  const [updateEmailModal, setUpdateEmailModal] = useState({
    state: false,
    data: {},
  });
  const [updatePasswordModal, setUpdatePasswordModal] = useState({
    state: false,
    data: {},
  });
  const [updateMobileModal, setUpdateMobileModal] = useState({
    state: false,
    data: {},
  });

  const businessId = sessionStorage.getItem("businessId")

  const [businessDetails, setBusinessDetails] = useState<any>(null);
  const { data, loading, error, refetch } = useQuery(BUSINESS_DETAILS, {
    variables: {
      businessId: businessId && +businessId,
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  const [signOutcall] = useMutation(SIGN_OUT_EVERYWHERE, { context: { clientName: "userClient" } },)
  const refreshToken = sessionStorage.getItem("refresh")
  const signOut = () => {
    signOutcall({
      variables: {
        refreshToken: refreshToken
      },

    })
  }
  const userLang = navigator.language;
  useEffect(() => {
    if (data) {
      setBusinessDetails(data.business);
    }
  }, [data]);

  return businessDetails ? (
    <div>
      <Typography
        sx={{
          color: "#333",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
        }}
      >
        {t("account_settings.sign_in_security")}
      </Typography>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "20px",
          marginBottom: "59px",
          marginLeft: "32px",
          marginTop: "40px",
        }}
      >
        {/* Email and password */}
        <Box
          sx={{
            width: "804px",
            borderRadius: "4px",
            border: "1px solid #EEE",
            background: "#FFF",
            padding: "12px 20px 20px 20px",
            boxSizing: "border-box",
            borderLeft: "2px solid #AE9BE2",
          }}
        >
          <Box
            sx={{
              borderBottom: "1px solid #EEE",
            }}
          >
            <Box
              sx={{
                display: "flex",
              }}
            >
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginBottom: "10px",
                }}
              >
                {t("account_settings.email")}
                {/* {businessDetails && businessDetails.emailVerified ? (
                  <div
                    className="customer-listing-status customer-listing-status--resolved"
                    style={{
                      fontSize: "16px",
                      fontWeight: 500,
                      marginLeft: "60px",
                    }}
                  >
                    Verified
                  </div>
                ) : null} */}
              </Typography>
              <Typography
                sx={{
                  color: "#F38B08",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  height: "24px",
                  marginLeft: "auto",
                  borderRight: "1px solid #ABABAB",
                  paddingRight: "40px",
                  cursor: "pointer",
                  // visibility: `${businessDetails.emailVerified ? "hidden" : "visible"
                  //   }`,
                }}
              // onClick={() =>
              //   setUpdateEmailModal({
              //     state: true,
              //     data: {
              //       viewMode: "verify",
              //       email: businessDetails.email,
              //       businessId: 12,
              //       refetchBusinessDetails: refetch,
              //     },
              //   })
              // }
              >
                {t("account_settings.verified")}
              </Typography>
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  paddingLeft: "40px",
                }}
                onClick={() =>
                  setUpdateEmailModal({
                    state: true,
                    data: {
                      email: businessDetails.email,
                      businessId: businessId && +businessId,
                      refetchBusinessDetails: refetch,
                    },
                  })
                }
              >
                {t("account_settings.update")}
              </Typography>
            </Box>

            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "20px",
              }}
            >
              {businessDetails.email}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {t("account_settings.password")}
            </Typography>
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                paddingLeft: "40px",
                marginLeft: "auto",
              }}
              onClick={() =>
                setUpdatePasswordModal({
                  state: true,
                  data: {},
                })
              }
            >
              {t("account_settings.update")}
            </Typography>
          </Box>

          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
            }}
          >
            {t("account_settings.last_updated")} {businessDetails.passwordUpdatedOn ? moment(businessDetails.passwordUpdatedOn).format(`${localeToFormat[userLang]}, hh:mm A`) : "N/A"}
          </Typography>
        </Box>

        {/* 2 step verification and Sign out options  */}
        <Box
          sx={{
            width: "804px",
            borderRadius: "4px",
            border: "1px solid #EEE",
            background: "#FFF",
            padding: "12px 20px 20px 20px",
            boxSizing: "border-box",
            borderLeft: "2px solid #BAE29B",
          }}
        >
          <Box
            sx={{
              paddingBottom: "12px",
              borderBottom: "1px solid #EEE",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "14px",
              }}
            >
              {t("account_settings.step_verification")}
              {!businessDetails.enableTwoStepVerification ? (
                <div
                  className="customer-listing-status"
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginLeft: "22px",
                  }}
                >
                  {t("account_settings.recommended")}
                </div>
              ) : (
                <div
                  className="customer-listing-status customer-listing-status--resolved"
                  style={{
                    fontSize: "16px",
                    fontWeight: 500,
                    marginLeft: "60px",
                  }}
                >
                  {t("account_settings.enabled")}
                </div>
              )}
            </Typography>
            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "20px",
              }}
            >
              {t("account_settings.verification_info")}
            </Typography>
            {!businessDetails.enableTwoStepVerification ? (
              <Button
                onClick={() =>
                  setVerificationCodeModal({
                    state: true,
                    data: {
                      mobile: businessDetails.mobile,
                      addressId: businessDetails.addressId,
                      businessId: businessDetails.businessId,
                      refetchBusinessDetails: refetch,
                    },
                  })
                }
                sx={{
                  width: "94px",
                  height: "44px",
                  backgroundColor: "#F38B08",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#fefefe",
                    textTransform: "capitalize",
                  }}
                >
                  {t("account_settings.enable")}
                </Typography>
              </Button>
            ) : (
              <Typography
                sx={{
                  color: "#F38B08",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  marginBottom: "20px",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setTwoFactorAuthenticationModal({
                    state: true,
                    data: {
                      businessId: businessDetails.businessId,
                      refetchBusinessDetails: refetch,
                    },
                  })
                }
              >
                {t("account_settings.manage_verification")}
              </Typography>
            )}
          </Box>

          <Box
            sx={{
              marginTop: "12px",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "10px",
              }}

            >
              {t("account_settings.sign_out_everywhere")}
            </Typography>
            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "20px",
              }}
            >
              {t("account_settings.sign_out_info")}
            </Typography>
            <Button
              sx={{
                color: "#D83511",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                textTransform: "none"
              }}
              // onclick={signOut}
              onClick={signOut}
            >
              {t("account_settings.sign_out_everywhere")}
            </Button>
          </Box>
        </Box>

        {/* verifications */}
        <Box
          sx={{
            width: "804px",
            borderRadius: "4px",
            border: "1px solid #EEE",
            background: "#FFF",
            padding: "12px 20px 20px 20px",
            boxSizing: "border-box",
            borderLeft: "2px solid #E19BE2",
          }}
        >
          <Box
            sx={{
              marginTop: "20px",
              paddingBottom: "20px",
              borderBottom: "1px solid #EEE",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "10px",
              }}
            >
              {t("account_settings.ways_to_verify")}
            </Typography>

            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              }}
            >
              {t("account_settings.verify_info")}
            </Typography>
          </Box>

          <Box
            sx={{
              borderBottom: "1px solid #EEE",
              marginTop: "12px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                marginBottom: "10px",
              }}
            >
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "24px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "12px",
                }}
              >
                <img src={MobileIcon} alt="mobile icon" />
                <div>{businessDetails.mobile}</div>
              </Typography>
              <Typography
                sx={{
                  color: "#F38B08",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  height: "24px",
                  marginLeft: "auto",
                  borderRight: "1px solid #ABABAB",
                  paddingRight: "40px",
                  cursor: "pointer",
                  visibility: `${businessDetails.mobileVerified ? "hidden" : "visible"
                    }`,
                }}
              // onClick={() =>
              //   setUpdateMobileModal({
              //     state: true,
              //     data: {
              //       viewMode: "verify",
              //       mobile: businessDetails.mobile,
              //       businessId: 12,
              //       refetchBusinessDetails: refetch,
              //     },
              //   })
              // }
              >
                {t("account_settings.verified")}
              </Typography>
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 500,
                  lineHeight: "normal",
                  paddingLeft: "40px",
                }}
                onClick={() =>
                  setUpdateMobileModal({
                    state: true,
                    data: {
                      mobile: businessDetails.mobile,
                      businessId: businessId && +businessId,
                      refetchBusinessDetails: refetch,
                    },
                  })
                }
              >
                {t("account_settings.update")}
              </Typography>
            </Box>

            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "20px",
              }}
            >
              {t("account_settings.recovery_phone")} {businessDetails.mobileUpdatedOn ? moment(businessDetails.mobileUpdatedOn).format(`${localeToFormat[userLang]}, hh:mm A`) : "N/A"}
            </Typography>
          </Box>

          {/* <Typography
            sx={{
              color: "#F38B08",
              fontSize: "16px",
              fontWeight: 500,
              fontFamily: "Poppins",
              marginTop: "12px",
            }}
          >
            Add email
          </Typography> */}
        </Box>
      </Box>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={verificationCodeModal.state}
        // onClose={() => {
        //   setVerificationCodeModal({
        //     state: false,
        //     data: {},
        //   });
        // }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // right: "360px",
          // top: "50px",
          opacity: 1,
        }}
      >
        <VerificationCode
          verificationCodeModal={verificationCodeModal}
          setVerificationCodeModal={setVerificationCodeModal}
          mobile={businessDetails.mobile}
        />
      </Modal>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={updateEmailModal.state}
        // onClose={() => {
        //   setUpdateEmailModal({
        //     state: false,
        //     data: {},
        //   });
        // }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // right: "360px",
          // top: "50px",
          opacity: 1,
        }}
      >
        <UpdateEmailModal
          verificationCodeModal={updateEmailModal}
          setVerificationCodeModal={setUpdateEmailModal}
        />
      </Modal>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={updatePasswordModal.state}
        // onClose={() => {
        //   setUpdatePasswordModal({
        //     state: false,
        //     data: {},
        //   });
        // }}
        sx={{
          // top: "50%",
          // right: "50%",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // right: "360px",
          // position: 'absolute' as 'absolute',
          // top: '25%',
          // left: '25%',
          // transform: 'translate(-50%, -50%)',
          width: "100%",
          // bgcolor: 'background.paper',
          opacity: 1,
        }}
      >
        <UpdatePasswordModal
          verificationCodeModal={updatePasswordModal}
          setVerificationCodeModal={setUpdatePasswordModal}
        />
      </Modal>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={updateMobileModal.state}
        // onClose={() => {
        //   setUpdateMobileModal({
        //     state: false,
        //     data: {},
        //   });
        // }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // right: "360px",
          // top: "50px",
          opacity: 1,
        }}
      >
        <UpdatePhoneModal
          verificationCodeModal={updateMobileModal}
          setVerificationCodeModal={setUpdateMobileModal}
        />
      </Modal>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={twoFactorAuthenticationModal.state}
        // onClose={() => {
        //   setTwoFactorAuthenticationModal({
        //     state: false,
        //     data: {},
        //   });
        // }}
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          // right: "360px",
          // top: "50px",
          opacity: 1,
        }}
      >
        <TwoFactorAuthenticationModal
          twoFactorAuthenticationModal={twoFactorAuthenticationModal}
          setTwoFactorAuthenticationModal={setTwoFactorAuthenticationModal}
        />
      </Modal>
    </div>
  ) : (
    <></>
  );
};

export default SignInAndSecurity;
