import { gql } from "@apollo/client";

export const GET_PRODUCTS_IN_BULK = gql`
query  selectedProductList($productIds:JSONString){
	selectedProductList(productIds:$productIds){
	edges{
		node{productId
		name
			sku
			optionGroups
			options
			
			price 
			image
			unit
            description
			category{
				name
				categoryId
			}
			locationId
			version
			variants{variantId
				sku
				price
				additionalPrice
				variantGroup
			name}
		
			
		locationId}
	}
		
	
	}
}`