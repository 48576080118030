// import React from "react";
// import { Box, Typography } from "@mui/material";
// import RecentOrderIcon from "../../../assets/mianDashboard/recentOrderIcon.svg";
// import { styled } from "@mui/material/styles";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import { RecentOrder } from "../../../types/dashboard";
// import { useTranslation } from "react-i18next";

// // Define the type for the data rows
// interface Row {
//   name: string;
//   burger: number;
//   brownie: number;
//   status: string;
//   orderId: number;
// }

// // Define the type for the component props (if any)
// interface RecentOrdersProps {}

// // Define the type for the component state (if any)
// interface RecentOrdersState {}

// // Define the type for the createData function
// type CreateDataRow = (
//   orderId: number,
//   name: string,
//   burger: number,
//   brownie: number,
//   status: string
// ) => Row;

// // Define the createData function with the correct type
// const createData: CreateDataRow = (orderId, name, burger, brownie, status) => ({
//   orderId,
//   name,
//   burger,
//   brownie,
//   status,
// });

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#F6F6F6",
//     color: "#333333",
//     fontSize: "14px",
//     fontFamily: "poppins",
//     fontWeight: "500",
//     lineHeight: "24px",
//     border: "0px",
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: "14px",
//     fontFamily: "poppins",
//     fontWeight: "500",
//     lineHeight: "24px",
//     color: "#000000",
//     height: "100%",
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&": {
//     borderBottom: "8px solid white",
//   },
//   "& > *": {
//     backgroundColor: "#FBFBFB",
//     borderRadius: "4px !important",
//   },
// }));

// interface RecentOrdersProps {
//   recentOrders: any[];
// }

// const RecentOrders: React.FC<RecentOrdersProps> = (
//   props: RecentOrdersProps
// ) => {
//   const { t } = useTranslation();
//   const { recentOrders } = props;
//   const currencySymbol = sessionStorage.getItem("currencySymbol");
//   const mappedData = recentOrders?.map((item: RecentOrder) => {
//     return createData(
//       item?.orderId,
//       item.customer,
//       item.quantity,
//       item.price,
//       item.status
//     );
//   });

//   return (
//     <Box
//       sx={{
//         overflow: "auto",
//         width: { xs: "100%", md: "53%" },
//         boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
//       }}
//     >
//       <Box
//         sx={{
//           minWidth: "664px",
//           height: "362px",
//           overflowX: "auto",
//           backgroundColor: "white",
//           borderRadius: "8px",
//           boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
//         }}
//       >
//         <Box
//           sx={{
//             height: "80px",
//             paddingTOp: "0px",
//             background: "white",
//             gap: "16px",
//             paddingX: "32px",
//             display: "flex",
//             alignItems: "center",
//             borderRadius: "8px",
//           }}
//         >
//           <img src={RecentOrderIcon} alt="recentOrderIcon" />
//           <Typography
//             sx={{
//               fontFamily: "poppins",
//               fontSize: "24px",
//               fontWeight: "500",
//               color: "#000000",
//             }}
//           >
//             {t("dashboard.Recent_Orders")}
//           </Typography>
//         </Box>

//         <TableContainer
//           sx={{
//             scrollbarWidth: "none",
//             "&::-webkit-scrollbar": {
//               display: "none",
//             },
//             "&-ms-overflow-style:": {
//               display: "none",
//             },
//             boxShadow: "none !important",
//           }}
//           component={Paper}
//         >
//           <Box
//             style={{
//               maxHeight: "274px",
//               overflow: "hidden",
//             }}
//           >
//             <TableHead
//               sx={{
//                 width: "100%",
//                 height: "32px",
//                 display: "flex !important",
//               }}
//             >
//               <TableRow sx={{ width: "100%", display: "flex" }}>
//                 <StyledTableCell
//                   sx={{
//                     display: "flex",
//                     width: "30%",
//                     textAlign: "center",
//                     alignItems: "center",
//                     paddingY: "4px",
//                     height: "32px",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       fontFamily: "poppins",
//                       fontSize: "16px",
//                       color: "#333333",
//                       fontWeight: "500",
//                       lineHeight: "24px",
//                     }}
//                   >
//                     {t("dashboard.Customer")}
//                   </Typography>
//                 </StyledTableCell>
//                 <StyledTableCell
//                   sx={{
//                     width: "25%",
//                     textAlign: "center",
//                     paddingY: "4px",
//                     height: "32px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       fontFamily: "poppins",
//                       fontSize: "16px",
//                       color: "#333333",
//                       fontWeight: "500",
//                       lineHeight: "24px",
//                       position: "relative",
//                       left: "30px",
//                     }}
//                   >
//                     {t("dashboard.Qty")}
//                   </Typography>
//                 </StyledTableCell>
//                 <StyledTableCell
//                   sx={{
//                     width: "25%",
//                     textAlign: "start",
//                     paddingY: "4px",
//                     height: "32px",
//                     paddingLeft: "0px !important",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "start",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       fontFamily: "poppins",
//                       fontSize: "16px",
//                       color: "#333333",
//                       fontWeight: "500",
//                       lineHeight: "24px",
//                       textAlign: "center",
//                       position: "relative",
//                       left: "20px",
//                     }}
//                   >
//                     {t("dashboard.Price")}
//                   </Typography>
//                 </StyledTableCell>
//                 <StyledTableCell
//                   sx={{
//                     width: "25%",
//                     paddingY: "4px",
//                     height: "32px",
//                     display: "flex",
//                     alignItems: "center",
//                     justifyContent: "center",
//                   }}
//                 >
//                   <Typography
//                     sx={{
//                       fontFamily: "poppins",
//                       fontSize: "16px",
//                       color: "#333333",
//                       fontWeight: "500",
//                       lineHeight: "24px",
//                       textAlign: "center",
//                       position: "relative",
//                       right: "0px",
//                       left: "20px",
//                     }}
//                   >
//                     {t("dashboard.Status")}
//                   </Typography>
//                 </StyledTableCell>
//               </TableRow>
//             </TableHead>
//             <Box>
//               <Table>
//                 <TableBody>
//                   {mappedData?.map((row, index) => (
//                     <StyledTableRow
//                       sx={{
//                         height: "48px !important",
//                       }}
//                       key={index}
//                     >
//                       <StyledTableCell
//                         sx={{
//                           width: "auto",
//                           height: "48px",
//                           textTransform: "capitalize",
//                           paddingY: "3.5px !important",
//                         }}
//                         component="th"
//                         scope="row"
//                       >
//                         {row.name ? row.name : "Guest"}
//                         <Typography
//                           sx={{
//                             fontFamily: "poppins",
//                             fontSize: "12px",
//                             lineHeight: "18px",
//                             color: "#707070",
//                             marginTop: "2px",
//                           }}
//                         >
//                           order id: #{row.orderId}
//                         </Typography>
//                       </StyledTableCell>
//                       <StyledTableCell
//                         sx={{
//                           width: "auto",
//                           textAlign: "center",
//                           paddingY: "3px !important",
//                         }}
//                       >
//                         {row.burger}
//                       </StyledTableCell>
//                       <StyledTableCell
//                         sx={{
//                           border: "0px",
//                           width: "15%",
//                           textAlign: "center",
//                           paddingY: "3.5px !important",
//                         }}
//                       >
//                         {currencySymbol} {row.brownie}
//                       </StyledTableCell>
//                       <StyledTableCell
//                         sx={{
//                           border: "0px",
//                           width: "auto",
//                           textAlign: "end",
//                           paddingY: "3.5px !important",
//                         }}
//                       >
//                         <Box
//                           sx={{
//                             paddingX: "12px",
//                             paddingY: "4px",
//                             borderRadius: "12px",
//                             backgroundColor: "rgba(255, 188, 22, 0.1)",
//                             color: "#FFBC16",
//                             textAlign: "center",
//                             maxWidth: "82px",
//                             display: "inline-block",
//                           }}
//                         >
//                           {row.status}
//                         </Box>
//                       </StyledTableCell>
//                     </StyledTableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </Box>
//           </Box>
//         </TableContainer>
//       </Box>
//     </Box>
//   );
// };
// export default RecentOrders;

import React from "react";
import { Box, Typography } from "@mui/material";
import RecentOrderIcon from "../../../assets/mianDashboard/recentOrderIcon.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { RecentOrder } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

// Define the type for the data rows
interface Row {
  name: string;
  burger: number;
  brownie: number;
  status: string;
  orderId: number;
}

// Define the type for the component props
interface RecentOrdersProps {
  recentOrders: RecentOrder[];
}

// Define the createData function
const createData = (
  orderId: number,
  name: string,
  burger: number,
  brownie: number,
  status: string
): Row => ({
  orderId,
  name,
  burger,
  brownie,
  status,
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    color: "#333333",
    fontSize: "14px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "24px",
    border: "0px",
    minWidth: "120px", // Adjusted width for headers
    position: "sticky",
    top: 0,
    zIndex: 1, // Ensure header is above the scrolling content
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#000000",
    padding: "16px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    borderBottom: "8px solid white",
  },
  "& > *": {
    backgroundColor: "#FBFBFB",
    borderRadius: "4px !important",
  },
}));

const RecentOrders: React.FC<RecentOrdersProps> = ({ recentOrders }) => {
  const { t } = useTranslation();
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  // Map recentOrders data to Row interface
  const mappedData: Row[] = recentOrders.map((item: RecentOrder) =>
    createData(
      item.orderId,
      item.customer,
      item.quantity,
      item.price,
      item.status
    )
  );

  return (
    <Box
      sx={{
        overflow: "hidden",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        overflowX: "auto",
        width: { xs: "100%", md: "47%" },
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          minWidth: "664px",
          height: "362px",

          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Box
          sx={{
            height: "80px",
            paddingTop: "0px",
            background: "white",
            gap: "16px",
            paddingX: "32px",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <img src={RecentOrderIcon} alt="recentOrderIcon" />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "24px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {t("dashboard.Recent_Orders")}
          </Typography>
        </Box>

        <TableContainer
          sx={{
            height: "calc(100% - 80px)", // Adjusted height to fit within parent Box
            position: "relative", // Ensure sticky header works correctly
          }}
          component={Paper}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <StyledTableCell>{t("dashboard.Customer")}</StyledTableCell>
                <StyledTableCell align="center">
                  {t("dashboard.Qty")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("dashboard.Price")}
                </StyledTableCell>
                <StyledTableCell align="center">
                  {t("dashboard.Status")}
                </StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              {mappedData.map((row, index) => (
                <StyledTableRow key={index}>
                  <StyledTableCell component="th" scope="row">
                    {row.name ? row.name : t("orders.guest")}
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "12px",
                        lineHeight: "18px",
                        color: "#707070",
                        marginTop: "2px",
                      }}
                    >
                      order id: #{row.orderId}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell align="center">{row.burger}</StyledTableCell>
                  <StyledTableCell align="center">
                    {currencySymbol} {row.brownie}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <Box
                      sx={{
                        paddingX: "12px",
                        paddingY: "4px",
                        borderRadius: "12px",
                        backgroundColor: "rgba(255, 188, 22, 0.1)",
                        color: "#FFBC16",
                        textAlign: "center",
                        maxWidth: "150px",
                        display: "inline-block",
                      }}
                    >
                      {row.status}
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default RecentOrders;
