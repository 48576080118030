import { Grid } from "@mui/material";
import DeliveryCardOne from "../../components/DeliveryCardOne";
import DeliveryCardTwo from "../../components/DeliveryCardTwo";
import UserImage from "../../../assets/images/Ellipse 17.png";
// import Home from "../../../assets/images/Home.png";
import Home from "../../../assets/images/Vector.png";

const RightDetails = (props: any) => {
  const { detailData, customerDetail, storeData, deliveryDetails } = props;


  return (
    <Grid container paddingLeft={2}>
      {deliveryDetails && (
        <DeliveryCardOne
          title={"Deliveryman"}
          description={
            "Please mark the order status to prepared and then contact the delivery partner"
          }
          disabled={true}
        />
      )}

      {/* <DeliveryCardOne
                title={"Deliveryman"}
                description={
                    "Please mark the order status to prepared and then contact the delivery partner"
                }
                disabled={false}
            /> */}
      {deliveryDetails && (
        <DeliveryCardTwo
          data={deliveryDetails}
          title={"Deliveryman"}
          description={
            "Please mark the order status to prepared and then contact the delivery partner"
          }
          disabled={false}
          actionText={"Change"}
          icon={"edit"}
          viewLocationBtn={true}
          actionBtn={true}
          image={UserImage}
        />
      )}

      {customerDetail && (
        <DeliveryCardTwo
          data={customerDetail}
          title={"customer details"}
          description={
            "Please mark the order status to prepared and then contact the delivery partner"
          }
          disabled={false}
          actionText={"View"}
          icon={"view"}
          viewLocationBtn={false}
          actionBtn={true}
          image={UserImage}
        />
      )}

      <DeliveryCardTwo
        data={storeData}
        title={"Store details"}
        description={
          "Please mark the order status to prepared and then contact the delivery partner"
        }
        disabled={false}
        actionText={"View"}
        icon={"view"}
        viewLocationBtn={false}
        image={Home}
      />
    </Grid>
  );
};

export default RightDetails;
