import { Box, CircularProgress } from '@mui/material'

export default function Loader(props: any) {
    const { loading } = props
    return (
        <>
            {loading ?
                <Box className={"loader-overlay"} >
                    <Box className={"loader"} >
                        <CircularProgress />
                    </Box>
                </Box>
                : null}
        </>
    )
}
