import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Box, TextField, Typography } from "@mui/material";
import { useState, useTransition } from "react";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import "./dateRangePicker.css";
import moment from "moment";

interface DaterangePickerState {
  width: string;
  startDateWidth: string;
  endDateWidth: string;
  formik: any;
}

export default function DateRangePicker(props: DaterangePickerState) {
  const { width, startDateWidth, endDateWidth, formik } = props;

  const { t } = useTranslation();

  const today = new Date().toISOString().split("T")[0];
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "70%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",

          width: "100%",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "50%",
          }}
        >
          <Typography>{t("buttons.start_date")}</Typography>
          <TextField
            color="warning"
            type="date"
            name="startDate"
            value={formik.values.startDate || ""}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            sx={{ width: "90%" }}
            inputProps={{ min: today }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "50%",
          }}
        >
          <Typography>{t("buttons.end_date")}</Typography>
          <TextField
            color="warning"
            type="date"
            name="endDate"
            value={formik.values.endDate || ""}
            onChange={(e) => {
              formik.handleChange(e);
            }}
            onBlur={formik.handleBlur}
            inputProps={{ min: today }}
            sx={{ width: "90%" }}
          />
        </Box>
      </Box>
      <Box>
        {(formik.touched.startDate && formik.errors.startDate) ||
        (formik.touched.endDate && formik.errors.endDate) ? (
          <Box>
            <Typography className="create-a-user-formik-label">
              {formik.errors.endDate==="End Date must be greater than From Date"?t("error.end_date"):t("error.required") }
            </Typography>
          </Box>
        ) : null}
      </Box>
    </Box>
  );
}
