/* eslint-disable react-hooks/exhaustive-deps */
import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useContext, useEffect, useState } from "react";
import CustomerGraph from "./customerGraph";
import ReportsTable from "../ReportsTable";
import { gql, useQuery } from "@apollo/client";
import {
  ACTIVITY_FOR_DASHBOARD,
  CUSTOMER_REPORTS,
} from "../../../graphicalQl/usequery/customersReport";
import {
  CustomerGraphType,
  CustomerReportType,
} from "../../../types/customerReports";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import moment from "moment";
import { localeToFormat } from "../../../utils/dateFormat";
import { capitalizeFirstLetter } from "../../../pages/utils/capitalizeWord";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Name", value: "name" },
  { label: "Email", value: "email" },
  { label: "Phone", value: "mobile" },
  { label: "Last Order", value: "last_order" },
  { label: "Total Orders", value: "total_orders" },
  { label: "Total Spent", value: "total_spent" },
];
const CustomersReports = () => {
  const [customerReports, setCustomerReports] = useState<
    Array<CustomerReportType>
  >([]);
  const [customerReportsGraph, setCustomerReportsGraph] = useState<
    Array<CustomerGraphType>
  >([]);
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Name", value: "name" },
    { label: "Email", value: "email" },
    { label: "Phone", value: "mobile" },
    { label: "Last Order", value: "last_order" },
    { label: "Total Orders", value: "total_orders" },
    { label: "Total Spent", value: "total_spent" },
  ]);
  const [labels, setLabels] = useState<Array<string>>([
    "name",
    "email",
    "mobile",
    "last_order",
    "total_orders",
    "total_spent",
  ]);

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("01:00:00");
  // const [toTime, setToTime] = useState<string>("23:00:00");

  const { fromTime, toTime } = useContext(GraphContext);



  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [locations, setLocations] = useState<string>("")

  useEffect(() => {
    if (locationId !== null) {
      setLocations(locationId);
    }
  }, [locationId]);

  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);

  // const { locationId } = useSelector((state: any) => state.headerLocation);


  const { loading: customerReportsLoading, data: customerReportsData } =
    useQuery(CUSTOMER_REPORTS(date.startDate, date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), reportLocation || +locationId) ?? gql``, {
      fetchPolicy: "no-cache",
    });

  const {
    loading: customerReportsGraphLoading,
    data: customerReportsGraphData,
  } = useQuery(
    ACTIVITY_FOR_DASHBOARD(date.startDate, date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportLocation || +locationId),
    {
      fetchPolicy: "no-cache",
    }
  );

  const currencySymbol = sessionStorage.getItem("currencySymbol")
  const userLang = navigator.language;
  const getCustomerReports = () => {
    if (!customerReportsLoading) {
      const customerReports = customerReportsData && customerReportsData.customerReports;
      const newData = customerReports && JSON.parse(customerReports);


      const list = newData && newData.map((item: any) => {
        return {
          ...item,
          total_spent: `${currencySymbol} ${item.total_spent}`,
          last_order: moment(item.last_order).format(`${localeToFormat[userLang]}`),
          name: capitalizeFirstLetter(item.name),
          email: item.email ? item.email : "N/A",
          mobile: item.mobile ? item.mobile : "N/A"



        };
      }
      );
      setCustomerReports(list);
    }
  };

  const getCustomerReportsGraph = () => {
    if (!customerReportsGraphLoading) {
      const customerReportsGraph =
        customerReportsGraphData?.activityForDashboard;
      const newData = JSON.parse(customerReportsGraph || "{}");

      setCustomerReportsGraph(newData);

    }
  };

  useEffect(() => {
    getCustomerReports();
  }, [customerReportsLoading]);

  useEffect(() => {
    getCustomerReportsGraph();
  }, [customerReportsGraphLoading]);

  return (
    <Box sx={{ padding: { md: "24px 44px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
      // setFromTime={setFromTime}
      //setToTime={setToTime}

      />
      <Box
        mt={"30px"}
        pb={1}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <CustomerGraph customerReportsGraph={customerReportsGraph} />
      </Box>
      <Box mt={1}>
        <ReportsTable
          setHeaders={setHeaders}
          setLabels={setLabels}
          headers={headers}
          options={options}
          labels={labels}
          data={customerReports}
          title={"Customers"}
        />
      </Box>
    </Box>
  );
};

export default CustomersReports;
