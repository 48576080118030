import React, { useState } from "react";

import AddOns from "../addOns";

import PaymentPlans from "../../../common/paymentsPlans";
import SucessModal from "../../../common/paymentPage/successModal";

const SelectPlan = () => {
  const [addOnsSelectors, setAddOnsSelectors] = useState<boolean>(false);

  return (
    <>
      {addOnsSelectors ? (
        <SucessModal />
      ) : (
        <PaymentPlans setAddOnsSelectors={setAddOnsSelectors} />
      )}
    </>
  );
};

export default SelectPlan;
