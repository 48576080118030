import {
  Box,
  Typography,
  Autocomplete,
  TextField,
  Breadcrumbs,
  Modal,
  Button,
  Popover,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  InputAdornment,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { LOCATIONS_LIST } from "../../graphicalQl/usequery/userProfileUseQuery";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate } from "react-router";
import PopupWithTitle from "../popups/popupWithTitle";
import {
  DELETE_DEVICE,
  UPDATE_STATUS_DEVICE,
} from "../../graphicalQl/mutation/deviceMangementMutation";
import { DEVICE_LIST_FOR_KDS } from "../../graphicalQl/usequery/deviceManagementQuery";
import DeletePopup from "../popups/deletePopup";
import CircularLoader from "../../loader/loader";
import DataGridTable from "../../utils/dataGridTable";
import { useTranslation } from "react-i18next";

interface State {
  locationsList: any[];
  seLocationsList: React.Dispatch<React.SetStateAction<any[]>>;
  rows: any[];
  shouldUpdatePaginationModelIsTrue: (newModal: any) => void;
  handleUpdatingValuesToNull: (newModal: any) => void;
  paginationModel: any;
  handleEndCursor: () => void;
  handleStartCursor: () => void;
  currentPage: any;
  Loading: boolean;
}

function PosListing(props: State) {
  const {
    locationsList,
    currentPage,
    rows,
    handleStartCursor,
    seLocationsList,
    shouldUpdatePaginationModelIsTrue,
    handleUpdatingValuesToNull,
    paginationModel,
    Loading,
    handleEndCursor,
  } = props;
  const [value, setValue] = React.useState("1");
  const { t } = useTranslation();
  const [deactivatePopup, setDeactivatePopup] = useState<boolean>(false);
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [allCheckboxChecked, setAllCheckboxChecked] =
    React.useState<boolean>(true);
  const [storeName, setStoreName] = useState<string | null>(null);
  const [deviceName, setDeviceName] = useState<string | null>(null);

  const [profileId, setProfileId] = useState<string | null>(null);
  const [statusText, setStatusText] = useState<null | string>(null);
  const [serialNumber, setSerialNumber] = useState<string | null>(null);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState("");
  const [noOfLocationsSelected, setNoOfLocationsSelected] = React.useState<
    number | null
  >(0);
  const [updateStatus] = useMutation(UPDATE_STATUS_DEVICE, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });

  const [removeDevice] = useMutation(DELETE_DEVICE, {
    onCompleted: (data) => {
      if (data) {
        const { deleteDevice } = data;
        if (deleteDevice) {
          const { success } = deleteDevice;
          if (success) {
            handleDeletePopupClose();
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });

  useEffect(() => {
    if (locationsList) {
      setNoOfLocationsSelected(
        locationsList.filter((each: any) => each.isChecked).length
      );
    }
  }, [locationsList]);

  const handleDeactivateText = () => {
    setDeactivatePopup(true);
    setAnchorEl(null);
    //use prodile id fro deactivate
  };

  const PopoverOpen = Boolean(anchorEl);

  const id = PopoverOpen ? "simple-popover" : undefined;

  const handleThreeDotsClick = (
    id: string,
    event: React.MouseEvent<HTMLButtonElement>,
    status: string,
    deviceName: string,
    storeName: string,
    serialNumber: string
  ) => {
    setAnchorEl(event.currentTarget);
    setProfileId(id);
    setStatusText(status);
    setStatusText(status);
    setDeviceName(deviceName);
    setStoreName(storeName);
    setSerialNumber(serialNumber);
  };

  const columns: GridColDef[] = [
    {
      field: "deviceName",
      headerClassName: "device-set-up-table-header",
      //@ts-ignore
      headerName: t("devices.pos_name"),
      flex: 1,
    },
    {
      field: "deviceCode",
      headerClassName: "device-set-up-table-header",
      //@ts-ignore
      headerName: t("devices.device_code"),
      flex: 1,
    },
    {
      field: "status",
      headerClassName: "device-set-up-table-header",
      //@ts-ignore
      headerName: t("devices.status"),
      flex: 1,
    },

    {
      field: "Actions",
      disableColumnMenu: true,
      headerClassName: "device-set-up-table-header",
      width: 150,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={(e) =>
              handleThreeDotsClick(
                params.row.id,
                e,
                params.row.status,
                params.row.deviceName,
                params.row.storeName,
                params.row.serialNumber
              )
            }
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </Box>
      ),
    },
  ];

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const getRowSize = () => {
    return "device-set-up-table-row device-set-up-table-row-hover";
  };

  const handleEditButton = () => {
    navigate(`/devices/${profileId}/update-pos`);
  };

  const handleDeletePopupClose = () => {
    setDeletePopup(false);
    setProfileId(null);
    setProfileId(null);
    setDeviceName(null);
    setStoreName(null);
    setSerialNumber(null);
  };

  const handleDeleteButton = () => {
    setDeletePopup(true);
    setAnchorEl(null);
  };

  const popoverContent = (
    <List style={{ padding: "0px 0px 0px 0px" }}>
      <Button
        onClick={handleEditButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText
            className="device-set-up-table-row-hover"
            primary={t("buttons.edit")}
          />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDeactivateText}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText
            className="device-set-up-table-row-hover"
            primary={t("devices.unpair")}
          />
        </ListItem>
      </Button>
    </List>
  );

  const handlePopupCheckboxByAll = (isChecked: boolean) => {
    setAllCheckboxChecked(isChecked);
    const filteredDataList = locationsList.map((each: any) => {
      return { ...each, isChecked: isChecked };
    });
    seLocationsList(filteredDataList);

    if (isChecked) {
      setNoOfLocationsSelected(locationsList.length);
    } else {
      setNoOfLocationsSelected(0);
    }
  };
  const handlePopupCheckbox = (id: string, isChecked: boolean) => {
    const filteredList = locationsList.map((each: any) => {
      if (each.storeId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });

    seLocationsList(filteredList);
    const checkedCount = filteredList.filter(
      (location: any) => location.isChecked
    ).length;
    setNoOfLocationsSelected(checkedCount);
    setAllCheckboxChecked(checkedCount === filteredList.length);
  };

  const handleChangeLocationsSearch = (search: string) => {
    setSearchInput(search);
  };

  const handleYesDeleteButton = () => {
    removeDevice({
      variables: {
        serialNumber: serialNumber,
      },
    });
  };

  const gettingLocationsCount = (list: any[]) => {
    const checkedList = list.filter((each: any) => each.isChecked);
    if (checkedList.length === 0) {
      return `0 ${t("users.add_location")}`;
    } else if (checkedList.length === 1) {
      return `1 ${t("users.location")}`;
    } else {
      return `${checkedList.length} ${t("users.locations")}`;
    }
  };

  const searchResults = locationsList.filter((eachLocation: any) =>
    eachLocation.storeName.toLowerCase().includes(searchInput)
  );

  const mainText = `${t(
    "devices.are_you_sure_do_you_want"
  )} "${deviceName}" ${t("devices.of")} "${storeName}" ${t(
    "devices.question_mark"
  )}`;
  const button1Text = t("devices.yes_unpair_pos");
  const title = `${t("devices.unpair")} ${deviceName}`;

  const handleDeactivateButton = () => {
    if (statusText === "Active") {
      updateStatus({
        variables: {
          deviceId: profileId && +profileId,
          status: "InActive",
        },
      });
    } else {
      updateStatus({
        variables: {
          deviceId: profileId && +profileId,
          status: "Active",
        },
      });
    }
    handleDeactivatePopupClose();
  };

  const handleDeactivateRejectionButton = () => {
    handleDeactivatePopupClose();
  };

  const handleDeactivatePopupClose = () => {
    setDeactivatePopup(false);
    setProfileId(null);
    setDeviceName(null);
    setStoreName(null);
  };

  return (
    <Box>
      <DeletePopup
        open={deletePopup}
        handleClose={handleDeletePopupClose}
        handleOpen={() => setDeletePopup(true)}
        handleSaveButton={handleYesDeleteButton}
        mainText={`Are you sure,You want to Remove this "${deviceName}"`}
        button1Text="Yes,Remove"
        button2Text="Don't Remove"
        popUpwidth="40%"
      />
      <PopupWithTitle
        open={deactivatePopup}
        mainText={mainText}
        button1Text={button1Text}
        button2Text={t("buttons.no_idontwant")}
        popUpwidth="45%"
        functionForButton1={handleDeactivateButton}
        functionForButton2={handleDeactivateRejectionButton}
        functionForCloseIcon={handleDeactivatePopupClose}
        widthForTitle="62%"
        title={title}
        button1Variant="outlined"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="kds-listing-deactivate-button-1"
        button2Style="kds-listing-deactivate-button-2"
        titleStyle="kds-listing-deactivate-title"
      />

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box sx={{ width: "100%", height: "8vh", zIndex: 10 }}>
            <Accordion
              sx={{ width: "25%", border: "2px solid #d9d9d9" }}
              elevation={1}
            >
              <AccordionSummary
                sx={{ width: "100%", marginBottom: 0 }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className="create-a-use-role-label"
                  sx={{ marginBottom: "0px" }}
                >
                  {gettingLocationsCount(locationsList)}
                  {/* {noOfLocationsSelected === 0
                    ? locationsText
                    : `${noOfLocationsSelected} ${locationsText}`} */}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <TextField
                    onChange={(e) =>
                      handleChangeLocationsSearch(e.target.value)
                    }
                    placeholder="Search by name"
                    id="outlined-start-adornment"
                    sx={{ margin: "0px 0px 10px 16px ", width: "80%" }}
                    color="warning"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  sx={{
                    height: "37vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "auto",
                      maxHeight: "32vh",
                      marginTop: "10px",
                      minHeight: "10vh",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        sx={{ marginLeft: "1%" }}
                        control={
                          <Checkbox
                            name="all"
                            checked={allCheckboxChecked}
                            onChange={(e) =>
                              handlePopupCheckboxByAll(e.target.checked)
                            }
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                            }}
                            color="warning"
                          />
                        }
                        label={
                          <Typography className="driver-petty-cash-popup-checkbox">
                            All
                          </Typography>
                        }
                      />
                      {searchResults.map((each: any) => (
                        <FormControlLabel
                          sx={{ marginLeft: "1%" }}
                          key={each.id}
                          checked={each.isChecked}
                          control={
                            <Checkbox
                              onChange={() => {
                                handlePopupCheckbox(
                                  each.storeId,
                                  each.isChecked
                                );
                              }}
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                              }}
                              color="warning"
                              checked={each.isChecked}
                            />
                          }
                          label={
                            <Typography
                              sx={{ textTransform: "none" }}
                              className="create-a-user-label"
                            >
                              {each.storeName}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </Box>
        <Box sx={{ height: "60vh", width: "100%", marginTop: "10px" }}>
          {Loading ? (
            <CircularLoader />
          ) : (
            <DataGridTable
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrue
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNull}
              paginationModel={paginationModel}
              rows={rows}
              columns={columns}
              getRow={getRowSize}
              totalCount={rows ? rows.length : 0}
              handleEndCursor={handleEndCursor}
              handleStartCursor={handleStartCursor}
              currentPage={currentPage}
              imageHeight="200px"
              imageWidth="200px"
              heightForContainer="40vh"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default PosListing;
