import React from "react";
import { Box, Button } from "@mui/material";
import { Sheet, Typography } from "@mui/joy";
import LowStockAlert from "../../../assets/notifiactionIcons/NotificationLowStockIcon.svg";
import AllNotficationCrossIcon from "../../../assets/notifiactionIcons/dashboardAllNotificationCrossIcon.svg";

interface Props {
  setOpenAll: (open: boolean) => void;
}

const AllNotificationsModal = React.forwardRef<HTMLDivElement, Props>(
  (props, ref) => {
    const { setOpenAll } = props;

    return (
      <Sheet
        ref={ref}
        variant="outlined"
        sx={{
          width: "100%",
          maxWidth: "586px",
          height: "100vh",
          overflowY: "auto",
          position: "relative",
          marginY: "12px",
          outline: "none",
          borderBottomLeftRadius: "8px",
          borderTopLeftRadius: "8px",
          border: "0px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: "12px",
            paddingTop: "32px",
            paddingBottom: "12px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: { sm: "24px", xs: "18px" },
            }}
          >
            <Box
              onClick={() => setOpenAll(false)}
              sx={{
                width: "44px",
                height: "44px",
                borderRadius: "8px",
                backgroundColor: "#eeeeee",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "#FFF8F0",
                },
              }}
            >
              <img
                src={AllNotficationCrossIcon}
                alt="AllNotficationCrossIcon"
              />
            </Box>
            <Typography
              id="close-modal-title"
              level="h4"
              sx={{
                fontFamily: "Poppins",
                fontSize: "18px",
                fontWeight: "500",
                lineHeight: "27px",
                textAlign: "left",
              }}
            >
              Notifications
            </Typography>
          </Box>
          <Button
            variant="text"
            sx={{
              fontFamily: "Poppins",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#333333",
              display: "flex",
              alignSelf: "end",
              cursor: "pointer",
              "&:hover": {
                backgroundColor: "#FFF8F0",
              },
            }}
          >
            Mark all as read
          </Button>
        </Box>

        {/* Notification items */}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {/* Example notification item */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: { xs: "16px", sm: "23px" },
              paddingY: "12px",
              paddingX: "12px",
              borderTop: "1px solid #EEEEEE",
              borderBottom: "1px solid #EEEEEE",
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFF8F0",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: { xs: "16px", sm: "23px" },
              }}
            >
              <Box
                sx={{
                  width: { xs: "55px", sm: "64px" },
                  height: { xs: "55px", sm: "64px" },
                  borderRadius: "50px",
                  backgroundColor: "#EEEEEE",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img src={LowStockAlert} alt="LowStockAlertIcon" />
              </Box>
              <Box>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "14px", sm: "16px" },
                    fontWeight: "500",
                    lineHeight: "24px",
                    color: "#000000",
                  }}
                >
                  Low Stock Alert!
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: "#707070",
                    width: { xs: "150px", sm: "auto" },
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  Product 1 is low on stock.
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "12px",
                    lineHeight: "18px",
                    color: "#ABABAB",
                  }}
                >
                  Today
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                width: "8px",
                height: "8px",
                borderRadius: "50px",
                backgroundColor: "#F38B08",
                display: "flex",
                alignSelf: "baseline",
              }}
            />
          </Box>
        </Box>
      </Sheet>
    );
  }
);

export default AllNotificationsModal;
