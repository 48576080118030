import { Typography, Box, Link } from "@mui/material";
import React from "react";

const Footer = () => {
  return (
    <Box
      maxWidth={"1440px"}
      marginX={"auto"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      marginBottom={"20px"}
      // marginTop={"106.5px"}
      paddingX={"15px"}
    >
      <Typography
        sx={{
          fontFamily: "poppins",
          fontWeight: "400",
          fontSize: "12px",
          lineHeight: "24px",
          color: "#707070",
          textAlign:'center',
          flexWrap:'wrap',
        }}
      >
        Need assistance or have questions?
        <Link
          sx={{
            fontFamily: "poppins",
            fontWeight: "400",
            fontSize: "12px",
            lineHeight: "24px",
            color: "#F5431C",
            paddingLeft: "5px",
            textDecoration: "none",
            whiteSpace:'pre',
          }}
        >
          Contact Support
        </Link>
      </Typography>
    </Box>
  );
}

export default Footer;
