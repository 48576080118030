import { Box } from "@mui/material";
import SalesOrdersCard from "./salesOrdersCard";
import SalesImage from "../../../assets/sales.svg";
import OrdersImage from "../../../assets/orders.svg";
import DeliveriesImage from "../../../assets/deliveries.svg";
import CancelledImage from "../../../assets/cancelled.svg";
import React, { useEffect, useState } from "react";
import { Stats } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

interface Props {
  stats: Stats;
}

const SalesOrdersDetails = ({ stats }: Props) => {
  const { t } = useTranslation();
  const salesOrders = [
    {
      id: "sales",
      description: t("dashboard.Total_Sales"),
      value: Math.floor(stats?.sale | 0),
      image: SalesImage,
    },
    {
      id: "orders",
      description: t("dashboard.Total_Orders"),
      value: stats?.order,
      image: OrdersImage,
    },
    {
      id: "deliveries",
      description: t("dashboard.Total_Deliveries"),
      value: stats?.delivered,
      image: DeliveriesImage,
    },
    {
      id: "cancelled",
      description: t("dashboard.Total_Cancelled"),
      value: stats?.cancelled,
      image: CancelledImage,
    },
  ];
  const [salesOrders1, setSalesOrders] = useState<any[]>([]);
  useEffect(() => {
    setSalesOrders(salesOrders);
  }, [t, stats]);
  return (
    <Box
      sx={{
        // height:{xs:'auto', lg:'140px'},
        display: "flex",
        gap: "20px",
        width: { xs: "100%", md: "100%", lg: "50%" },
        flexWrap: "wrap",
        justifyContent: { xs: "center", lg: "space-between" },
      }}
    >
      {salesOrders1?.map((order: any) => {
        return <SalesOrdersCard key={order.id} order={order} />;
      })}
    </Box>
  );
};

export default SalesOrdersDetails;
