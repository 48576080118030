import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
  Switch,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
  Radio,
} from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../options/createProduct.css";

import InputLabel from "@mui/material/InputLabel";

import { CREATE_PRODUCT } from "../../graphicalQl/mutation/productMutation";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setPricing } from "../../store";
import AlertCreateDialog from "./dailougeBoxProduct";

import {
  FILTERING_PRODUCTS_LIST,
  PRODUCT_DATA,
} from "../../graphicalQl/usequery/productsListQuery";
import { UPDATE_PRICING_IN_PRODUCT_UPDATE } from "../../graphicalQl/mutation/productMutation";
import { UPDATE_PRICE_IN_BULK_UPDATE } from "../../graphicalQl/mutation/productMutation";
import ErrorModal from "../errorHandling/errorModal";
import VariantModal from "./addVariantModal";
import AddQuantityModal from "./addQuantityModal";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeletePopup from "../popups/deletePopup";
import {
  DUPLICATE_BARCODE,
  DUPLICATE_SKU,
} from "../../graphicalQl/mutation/productMutation";
import EditDailouge from "../options/editDailouge";

import { useTranslation } from "react-i18next";
import { useDebouncedCallback } from "use-debounce";

interface Row {
  id: number;
  name: string;
  price: string;
  sku: string;
  slice: string;
  barcode: string;
  is_preselected: boolean;
  groupName: string;
  skuDuplicate: boolean;
  barcodeDuplicate: boolean;
}
interface State2 {
  price: string;
  sku: string;
  slice: boolean;
  noOfSlices: string;
  barcode: string;
  variations1: Row[];
}
interface UpdatedVariant {
  variant_id?: number;
  name: string;
  additional_price: number;
  sku: any;
  barcode: any;
  is_preselected: any;
  max_no_of_slices: number;
  allow_slices: any;
  variant_group: any;
}
interface Props {
  handleComplete: () => void;
  handleBack: () => void;
}

interface Variations {
  name: string;
  sku: string;
  price: number;
  barcode: string;
  is_preselected: boolean;
}

const validationSchemaPart1 = Yup.object().shape({
  // price: Yup.string().when("variations1", {
  //   is: (variations1: any[]) => !variations1 || variations1.length === 0,
  //   then: (schema) => schema.required("*Required"),
  //   otherwise: (schema) => schema.notRequired(),
  // }),

  // price: Yup.string().required("* Requried"),
  price: Yup.number()
    .required("* Required")
    .moreThan(0, "Price must be greater than 0"),

  // variations1: Yup.array().of(
  //   Yup.object().shape({
  //     name: Yup.string()
  //       .required("*Variant Name is required")
  //       .test("is-unique", "Already Existed", function (value) {
  //         const str = this.path;
  //         const regex = /\[(\d+)\]/g;
  //         let match;
  //         const indices = [];

  //         while ((match = regex.exec(str)) !== null) {
  //           indices.push(parseInt(match[1]));
  //         }
  //         const data = this.options.context && { ...this.options.context };
  //         let variations1;
  //         if (data) {
  //           ({ variations1 } = data);
  //         }

  //         const allVariantNames =
  //           variations1 &&
  //           variations1.map((variant: any) =>
  //             variant.name ? variant.name.trim().toLowerCase() : ""
  //           );
  //         return (
  //           allVariantNames &&
  //           allVariantNames.filter(
  //             (name: string) => name === value.trim().toLowerCase()
  //           ).length <= 1
  //         );
  //       }),
  //     price: Yup.string().required("Price is required"),
  //   })
  // ),
});
const ProductPricing = (props: Props) => {
  const { handleComplete, handleBack } = props;
  const dispatch = useDispatch();
  // const [skuDuplicate, setSkuDuplicate] = useState(false)
  // const [barcodeDuplicate, setBarcodeDuplicate] = useState(false)
  const {
    name,
    shortCode,
    description,
    isPos,
    isOnline,
    category,
    unit,
    location,
    course,
    productIdByEdit,
    reportingGroup,
    image,
    color,
    taxList,
    nonTaxable,
    shopProduct,
  } = useSelector((state: any) => state.app);
  const {
    price,
    sku,
    barcode,
    slice,
    noOfSlices,
    variations1,
    productId,
    skuDuplicate,
    barcodeDuplicate,
    dailougeCondition,
  } = useSelector((state: any) => state.pricing);
  const [rows, setRows] = useState<Row[]>(variations1);

  const { t } = useTranslation();

  useEffect(() => {
    const variationSizes = variations1
      .filter((item: any) => item.groupName === "Size")
      .map((each: any) => each.name);

    dispatch(setPricing("variationSizes", variationSizes));
  }, [variations1]);

  const initialState: State2 = {
    price: price,
    slice: slice,
    sku: sku,
    noOfSlices: noOfSlices,
    barcode: barcode,
    variations1: rows,
  };

  const businessId: string = sessionStorage.getItem("businessId") as string;

  // i want to group the variations by their group Name
  const groupVariations = (variations: any[]) => {
    const groups: { groupName: string; variations: any[] }[] = [];
    variations.forEach((variation) => {
      const groupName = variation.groupName;
      let group = groups.find((g) => g.groupName === groupName);
      if (!group) {
        group = { groupName, variations: [] };
        groups.push(group);
      }
      group.variations.push(variation);
    });
    return groups;
  };

  const groupVariationsList = groupVariations(variations1);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,
    onSubmit: () => {
      if (!skuDuplicate && !barcodeDuplicate) {
        if (productIdByEdit) {
          updateProduct({
            variables: priceObj,
            refetchQueries: [PRODUCT_DATA, FILTERING_PRODUCTS_LIST],
            onCompleted: () => {
              handleComplete();
              dispatch(setPricing("noVariantChange", true));
            },
          });
        } else if (productId.length > 0) {
          bulkUpdateProduct({
            variables: priceObj2,
            refetchQueries: [FILTERING_PRODUCTS_LIST, PRODUCT_DATA],
            onCompleted: () => {
              handleComplete();
              dispatch(setPricing("noVariantChange", true));
            },
          });
        } else {
          createProduct({
            variables: { product: filteredProduct },
            refetchQueries: [FILTERING_PRODUCTS_LIST],
          });
        }
      }
    },
  });

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      price: price,
      slice: slice,
      sku: sku,
      barcode: barcode,
      variations1: variations1,
      noOfSlices,
    });
  }, [price, slice, sku, barcode, variations1, noOfSlices]);

  // const { data: productDataFromApi } = useQuery(PRICING_DETAILS_IN_PRODUCT, {
  //   context: { clientName: "categoryClient" },variables:{productId:productIdByEdit}

  // });

  // useEffect(() => {
  //   if (productDataFromApi && productDataFromApi.product) {
  //     const list = productDataFromApi.product.productVariants.edges.map((each: any) => ({
  //       id: each.node.variantId,
  //       name: each.node.name,
  //       price: each.node.price,
  //       sku: each.node.sku,
  //       barcode: each.node.barcode,
  //       is_preselected: each.node.isPreselected
  //     }));

  //     // Now you can do something with the 'list' array if needed
  //     dispatch(setPricing( "variations1", list ));
  //   }
  // }, [productDataFromApi]);

  // useEffect(() => {
  //   formik.setFieldValue("variations1", variations1);
  // }, [variations1]);

  // const handleAddSizeClick = () => {
  //   const newRow: Row = {
  //     id: Date.now(),
  //     name: "",
  //     price: "",
  //     sku: "",
  //     slice: "",
  //     barcode: "",
  //     is_preselected: false,
  //   };
  //   setRows([...rows, newRow]);
  // };

  const [deleteVariantIds, setDeleteVariantIds] = useState<number[]>([]);
  // const handleDeleteRow = (id: any) => {
  //   const updatedRows = rows.filter((row: any) => row.id !== id);
  //   setRows(updatedRows);
  //   if (typeof id === "string") {
  //     deleteVariantIds.push(parseInt(id));
  //   }
  // };

  // const handleCheckboxChange = (id: number, value: boolean) => {
  //   const updatedRows = rows.map((row) =>
  //     row.id === id
  //       ? { ...row, is_preselected: value }
  //       : { ...row, is_preselected: false }
  //   );
  //   setRows(updatedRows);
  // };

  // const handleRowInputChange = (
  //   id: number,
  //   field: keyof Row,
  //   value: string
  // ) => {
  //   const updatedRows = rows.map((row) =>
  //     row.id !== id ? row : { ...row, [field]: value }
  //   );
  //   setRows(updatedRows);
  // };

  // const [variations, setVariations] = useState<Variations[]>([]);

  // useEffect(() => {
  //   if (rows.length > 0) {
  //     const requiredRowsData = rows.map((each) => ({
  //       name: each.name,
  //       price: +each.price,
  //       sku: each.sku,

  //       barcode: each.barcode,
  //       is_preselected: each.is_preselected,
  //       slices: "",
  //     }));

  //     setVariations(requiredRowsData);
  //   }
  //   dispatch(setPricing("variations1", rows));
  // }, [rows]);

  const [resultObject, setResultObject] = useState<any>(null);
  const productPrice = {
    price: +price,
    sku: sku,
    barcode: barcode,
    max_no_of_slices: +noOfSlices,
    allow_slices: slice,
  };

  const variationsList =
    variations1 &&
    variations1.map((each: Row) => ({
      name: each.name,
      additional_price: parseFloat(each.price),
      sku: each.sku,
      barcode: each.barcode,
      is_preselected: each.is_preselected,
      max_no_of_slices: +each.slice,
      allow_slices: slice,
      variant_group: each.groupName,
    }));
  // const variationsListWithid =
  //   resultObject &&
  //   resultObject.unmatched &&
  //   resultObject.unmatched
  //     .filter((each: any) => typeof each.id === "string")
  //     .map((each: Row) => ({
  //       id: each.id,
  //       name: each.name,
  //       additional_price: parseInt(each.price),
  //       sku: each.sku,
  //       barcode: each.barcode,
  //       is_preselected: each.is_preselected,
  //       max_no_of_slices: +each.slice,
  //       allow_slices: slice,
  //       variant_group: each.groupName
  //     }));

  // const variationsListWithOutid =
  //   resultObject &&
  //   resultObject.unmatched &&
  //   resultObject.unmatched
  //     .filter((each: any) => typeof each.id === "number")
  //     .map((each: Row) => ({
  //       name: each.name,
  //       additional_price: parseInt(each.price),
  //       sku: each.sku,
  //       barcode: each.barcode,
  //       is_preselected: each.is_preselected,
  //       max_no_of_slices: +each.slice,
  //       allow_slices: slice,
  //       variant_group: each.groupName
  //     }));

  const variantObject: {
    new_variants?: any;
    updated_variants?: any;
    removable_variants?: any;
  } = {};

  if (resultObject && resultObject.deleted) {
    variantObject.new_variants =
      resultObject.deleted &&
      resultObject.deleted.map((each: any) => ({
        name: each.name,
        additional_price: +each.price || 0,
        sku: each.sku,
        barcode: each.barcode,
        is_preselected: each.is_preselected,
        max_no_of_slices: +each.slice,
        allow_slices: slice,
        variant_group: each.groupName,
      }));
  }
  if (resultObject && resultObject.changed) {
    variantObject.updated_variants =
      resultObject.changed &&
      resultObject.changed.map((each: any) => {
        let updatedVariant: UpdatedVariant = {
          name: each.old.name,
          additional_price: +each.old.price || 0,
          sku: each.old.sku,
          barcode: each.old.barcode,
          is_preselected: each.old.is_preselected,
          max_no_of_slices: +each.old.slice,
          allow_slices: slice,
          variant_group: each.old.groupName,
        };

        if (productIdByEdit) {
          updatedVariant.variant_id = +each.old.id;
        }

        return updatedVariant;
      });
  }
  if (resultObject && resultObject.added) {
    variantObject.removable_variants =
      resultObject.added &&
      resultObject.added.map((each: any) => {
        if (productIdByEdit) {
          return +each.id;
        } else {
          return each.name;
        }
      });
  }


  useEffect(() => {
    if( productId.length>0||productIdByEdit){
      dispatch(
        setPricing("noVariantChange", resultObject && resultObject.areEqual)
      );
    }
   
  }, [resultObject]);

  // const variantObject2: {
  //   new_variants?: any;
  //   updated_variants?: any;
  //   removable_variants?: any;
  // } = {};

  // if (resultObject && resultObject.deleted) {
  //   variantObject2.new_variants = resultObject.deleted && resultObject.deleted.map((each: any) => ({

  //     name: each.name,
  //     additional_price: parseInt(each.price),
  //     sku: each.sku,
  //     barcode: each.barcode,
  //     is_preselected: each.is_preselected,
  //     max_no_of_slices: +each.slice,
  //     allow_slices: slice,
  //     variant_group: each.groupName

  //   }))
  // }
  // if (resultObject && resultObject.changed) {
  //   variantObject2.updated_variants = resultObject.changed && resultObject.changed.map((each: any) => ({
  //     id: each.old.id,
  //     name: each.old.name,
  //     additional_price: parseInt(each.old.price),
  //     sku: each.old.sku,
  //     barcode: each.old.barcode,
  //     is_preselected: each.old.is_preselected,
  //     max_no_of_slices: +each.old.slice,
  //     allow_slices: slice,
  //     variant_group: each.old.groupName

  //   }))
  // }
  // if (resultObject && resultObject.added) {
  //   variantObject2.removable_variants = resultObject.added && resultObject.added.map((each: any) => (

  //     each.name
  //   ))
  // }
  // const isEmpty = Object.keys(variantObject).length === 0;

  const product = {
    name: name.trim(),
    shortCode: shortCode,
    description: description,
    isPos: isPos,
    isOnline: isOnline,
    category: +category || null,
    course: +course || null,
    reportingGroup: +reportingGroup || null,
    bgColourCode: color as string,
    image: image as string,
    unit: unit,
    locations: JSON.stringify(location),
    businessId: +businessId,
    price: JSON.stringify(productPrice),
    variants: variationsList.length > 0 ? JSON.stringify(variationsList) : null,
    hasVariant: variationsList.length > 0 ? true : false,
    isNonTaxableProduct: nonTaxable,
    taxIdsList: taxList && taxList.length > 0 ? JSON.stringify(taxList) : null,
    isShopProduct: shopProduct,
  };

  const filteredProduct = Object.fromEntries(
    Object.entries(product).filter(
      ([_, value]) => value !== null && value !== "null" && value !== ""
    )
  );
  const priceObj = {
    allowSlices: slice,
    productId: +productIdByEdit,
    price: JSON.stringify(productPrice),
    variants: JSON.stringify(variantObject),
    hasVariant: variations1.length > 0 ? true : false,
  };
  const priceObj2 = {
    allowSlices: slice,
    productIds: JSON.stringify(productId),
    price: JSON.stringify(productPrice),
    variants: JSON.stringify(variantObject),
    hasVariant: variations1.length > 0 ? true : false,
  };
  // const filteredPrice = Object.fromEntries(
  //   Object.entries(priceObj).filter(([_, value]) => value !== null && value !== "null" && value !== "")
  // );

  const [duplictateSku, { loading: skuLoading }] = useMutation(DUPLICATE_SKU, {
    context: { clientName: "categoryClient" },
  });
  const [duplictateBarcode, { loading: barcodeLoading }] = useMutation(
    DUPLICATE_BARCODE,
    {
      context: { clientName: "categoryClient" },
    }
  );
  const debouncedSku = useDebouncedCallback(
    // function
    (value) => {
      if (value !== "") {
        duplictateSku({
          variables: {
            productSku: value,
            productId: +productIdByEdit,
            variantId: null,
            businessId: +businessId,
          },
          onCompleted: (data) => {
            // setSkuDuplicate(data.duplicateProductSkuCheck.duplicate)
            dispatch(
              setPricing(
                "skuDuplicate",
                data.duplicateProductSkuCheck.duplicate
              )
            );
          },
        });
      } else {
        dispatch(setPricing("skuDuplicate", false));
      }
    },
    // delay in ms
    1000
  );

  const debouncedBarcode = useDebouncedCallback(
    // function
    (value) => {
      if (value !== "") {
        duplictateBarcode({
          variables: {
            productBarcode: value,
            productId: +productIdByEdit,
            variantId: null,
          },
          onCompleted: (data) => {
            // setBarcodeDuplicate(data.duplicateProductBarcodeCheck.duplicate)
            dispatch(
              setPricing(
                "barcodeDuplicate",
                data.duplicateProductBarcodeCheck.duplicate
              )
            );
          },
        });
      } else {
        dispatch(setPricing("barcodeDuplicate", false));
      }
    },
    // delay in ms
    1000
  );

  const handleInputChange2 = (field: string, value: string | boolean) => {
    dispatch(setPricing(field, value));
    if (field === "sku") {
      // duplictateSku({
      //   variables: { productSku: value, productId: +productIdByEdit, variantId: null }, onCompleted: (data) => {

      //     // setSkuDuplicate(data.duplicateProductSkuCheck.duplicate)
      //     dispatch(setPricing("skuDuplicate", data.duplicateProductSkuCheck.duplicate));
      //   }
      // })
      debouncedSku(value);
    } else if (field === "barcode") {
      // duplictateBarcode({
      //   variables: { productBarcode: value, productId: +productIdByEdit, variantId: null }, onCompleted: (data) => {

      //     // setBarcodeDuplicate(data.duplicateProductBarcodeCheck.duplicate)
      //     dispatch(setPricing("barcodeDuplicate", data.duplicateProductBarcodeCheck.duplicate));
      //   }
      // })
      debouncedBarcode(value);
    }
  };

  const [statusCode, setStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  const [createProduct, { loading: createLoading }] = useMutation(
    CREATE_PRODUCT,
    {
      context: { clientName: "categoryClient" },
      onCompleted: (data) => {
        dispatch(
          setPricing("productId", JSON.parse(data.createProduct.product))
        );
      },
      onError: (error) => {
        const graphqlError = error.graphQLErrors[0];

        if (
          graphqlError &&
          graphqlError.extensions &&
          graphqlError.extensions.status
        ) {
          // Extract the status code from the extensions property
          setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
          setErrorModal(true);
        }
        const errorMessage = graphqlError.message || "An error occurred";
        console.error(
          `GraphQL error: Status ${statusCode}, Message: ${errorMessage}`
        );
      },
      refetchQueries: [FILTERING_PRODUCTS_LIST],
    }
  );

  const [updateProduct, { loading: updateLoading }] = useMutation(
    UPDATE_PRICING_IN_PRODUCT_UPDATE,
    {
      context: { clientName: "categoryClient" },
    }
  );
  const [bulkUpdateProduct, { loading: bulkLoading }] = useMutation(
    UPDATE_PRICE_IN_BULK_UPDATE,
    {
      context: { clientName: "categoryClient" },
    }
  );

  const [dailouge, setDailouge] = useState<boolean>(false);
  const handleOpenDailouge = () => {
    setDailouge(true);
  };
  const handleUpdateDailougeState = () => {
    setDailouge(false);
  };

  const [groupModal, setGroupModal] = useState<boolean>(false);

  const [addQuantityModal, setAddQuantityModal] = useState<boolean>(false);
  function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key]))
        return false;
    }

    return true;
  }

  function deepArrayEqualWithUnmatched(
    arr1: any[],
    arr2: any[]
  ): { areEqual: boolean; changed: any[]; added: any[]; deleted: any[] } {
    const changed: any[] = [];
    const added: any[] = [];
    const deleted: any[] = [];

    const arr1Checked = new Set<number>();
    const arr2Checked = new Set<number>();

    // Identify added and changed items
    arr2.forEach((element2, i) => {
      const matchingIndex = arr1.findIndex(
        (element1) => element1.id === element2.id
      );
      if (matchingIndex !== -1) {
        // If a match by name is found, check if the IDs are the same
        if (arr1[matchingIndex].id !== element2.id) {
          // If the IDs are different, treat it as an addition
          added.push(element2);
        } else {
          // If the IDs are the same, check if the whole object is deeply equal
          if (!deepEqual(arr1[matchingIndex], element2)) {
            changed.push({ old: arr1[matchingIndex], new: element2 });
          }
        }
        arr1Checked.add(matchingIndex);
      } else {
        // If no match is found, it's an addition
        added.push(element2);
      }
      arr2Checked.add(i);
    });

    // Identify deleted items
    arr1.forEach((element1, i) => {
      const matchingIndex = arr2.findIndex(
        (element2) => element2.id === element1.id
      );
      if (matchingIndex === -1) {
        deleted.push(element1);
      } else if (arr2[matchingIndex].id !== element1.id) {
        deleted.push(element1);
      }
      arr1Checked.add(i);
    });

    return {
      areEqual:
        changed.length === 0 && added.length === 0 && deleted.length === 0,
      changed,
      added,
      deleted,
    };
  }
  // useEffect(() => {

  //   const result = deepArrayEqualWithUnmatched(formik.values.variations1, formik.initialValues.variations1)
  //   setResultObject(result)
  // }, [formik])

  useEffect(() => {
    const result = deepArrayEqualWithUnmatched(
      formik.values.variations1,
      formik.initialValues.variations1
    );
    setResultObject(result);
  }, [formik.values.variations1]);

  const handleEditGroup = (value: string) => {
    dispatch(setPricing("variantGroup", value));
    setAddQuantityModal(true);
  };

  const [selectedGroupName, setSelectedGroupName] = useState("");
  const handleDeleteVariantGroup = (value: string) => {
    setDeleteProductPopupOpen(true);
    setSelectedGroupName(value);
  };
  const [deleteProductPopupOpen, setDeleteProductPopupOpen] =
    useState<boolean>(false);

  const handleProductPoppoverDelete = () => {
    const list = variations1.filter(
      (each: any) => each.groupName !== selectedGroupName
    );
    dispatch(setPricing("variations1", list));
    setDeleteProductPopupOpen(false);
  };

  const handleDeleteProductPopupOpen = () => {
    setDeleteProductPopupOpen(true);
  };
  const handleDeleteProductPopupClose = () => {
    setDeleteProductPopupOpen(false);
  };
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const handleModalState = () => {
    dispatch(setPricing("dailougeCondition", false));
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box className="product-box-pricing">
          <div
            className="create-product-base-size-con"
            style={{ paddingLeft: "12px" }}
          >
            <Typography className="create-product-base-size">
              {t("product.base_price")}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography className="create-product-allow-slice">
                {t("product.allow_slices")}
              </Typography>
              <Switch
                name="slice"
                color="warning"
                checked={formik.values.slice}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange2("slice", e.target.checked);

                  formik.setFieldValue("slice", e.target.checked);
                }}
              />
            </div>
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              margin: "12px",
              gap: "24px",
            }}
          >
            <div style={{ width: "18%" }}>
              <TextField
                label={t("product.price")}
                // type='number'
                color="warning"
                name="price"
                value={price}
                className="hide-number-input-icons"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {currencySymbol}
                    </InputAdornment>
                  ),
                }}
                error={formik.touched.price && Boolean(formik.errors.price)}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  const val = e.target.value;

                  if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 6) {
                    handleInputChange2("price", e.target.value);
                    // formik.handleChange(e)
                  }
                }}
                onBlur={formik.handleBlur}
                helperText={
                  formik.touched.price &&
                  typeof formik.errors.price === "string"
                    ? formik.errors.price
                    : ""
                }
              />
              {/* {formik.touched.price && formik.errors.price && (
                  <div style={{ color: "red" }}>{formik.errors.price}</div>
                )} */}
            </div>

            <div style={{ width: "18%" }}>
              <TextField
                label={t("product.sku")}
                name="sku"
                color="warning"
                value={sku}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange2("sku", e.target.value);
                  formik.handleChange(e);
                }}
                error={skuDuplicate}
                onBlur={formik.handleBlur}
                helperText={skuDuplicate && t("product.already_existed")}
              />
              {/* {formik.touched.sku && formik.errors.sku && (
                <div style={{ color: "red" }}>{formik.errors.sku}</div>
              )}
              {skuDuplicate && <Typography style={{ color: "red", fontSize: "12px" }}>{t("product.already_existed")}</Typography>}
             */}
            </div>
            {slice && (
              <FormControl sx={{ width: "18%" }}>
                <InputLabel id="demo-simple-select-label">
                  {t("product.slice")}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.noOfSlices}
                  name="noOfSlices"
                  label={t("product.slice")}
                  color="warning"
                  onChange={(event) => {
                    handleInputChange2("noOfSlices", event.target.value);
                    formik.handleChange(event);
                  }}
                >
                  <MenuItem value="1">1</MenuItem>
                  <MenuItem value="2">2</MenuItem>
                  <MenuItem value="3">3</MenuItem>
                  <MenuItem value="4">4</MenuItem>
                  <MenuItem value="5">5</MenuItem>
                  <MenuItem value="6">6</MenuItem>
                  <MenuItem value="7">7</MenuItem>
                  <MenuItem value="8">8</MenuItem>
                  <MenuItem value="9">9</MenuItem>
                  <MenuItem value="10">10</MenuItem>
                  <MenuItem value="11">11</MenuItem>
                  <MenuItem value="12">12</MenuItem>
                </Select>
              </FormControl>
            )}
            <Box style={{ width: "18%", marginRight: "1%" }}>
              <TextField
                label={t("product.bar_code")}
                name="barcode"
                style={{ width: "100%" }}
                color="warning"
                value={barcode}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleInputChange2("barcode", e.target.value);
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                error={barcodeDuplicate}
                helperText={barcodeDuplicate && t("product.already_existed")}
              />
              {/* {barcodeDuplicate && <Typography style={{ color: "red", fontSize: "12px" }}>{t("product.already_existed")}</Typography>} */}
            </Box>
          </div>
        </Box>

        <Box className="product-box-pricing">
          <div
            className="create-product-base-size-con"
            style={{ padding: "12px" }}
          >
            <Typography className="create-product-base-size">
              {t("product.varinats")}
            </Typography>
          </div>
          <Box sx={{ padding: "12px" }}>
            <Typography
              sx={{
                fontSize: "12px",
                fontWeight: "400",
                lineHeight: "24px",
                color: "#707070",
              }}
            >
              {t("product.variant_para")}
            </Typography>
            <Box
              sx={{
                marginTop: "12px",
                display: "flex",
                padding: "0px 12px",
                alignItems: "flex-start",
                gap: "12px",
              }}
            >
              {groupVariationsList.map((group) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      width: "30%",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      gap: "12px",
                      borderRadius: "4px",
                      border: "1px solid #EEE",
                      background: "#FBFBFB",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        padding: "12px",
                        justifyContent: "space-between",
                        alignItems: "flex-start",
                        alignSelf: "stretch",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "var(--text-900, #333)",
                          fontFamily: "'Poppins', sans-serif", // Ensure fallback for font-family
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: "500",
                          lineHeight: "24px", // 171.429%
                          letterSpacing: "0.5px",
                        }}
                      >
                        {group.groupName}
                      </Typography>
                      <IconButton
                        onClick={() => {
                          handleEditGroup(group.groupName);
                        }}
                        sx={{
                          size: "small",
                          "&:hover": {
                            // Corrected ".hover" to ":hover" for the pseudo-class
                            backgroundColor: "#FFF8F0",
                          },
                        }}
                      >
                        <EditIcon />
                      </IconButton>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        padding: "0px 12px",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        gap: "16px",
                        alignSelf: "stretch",
                      }}
                    >
                      {group.variations.map((variation: any) => {
                        return (
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              gap: "20px",
                            }}
                          >
                            <Radio
                              checked={variation.is_preselected}
                              color="warning"
                            />
                            <Typography
                              sx={{
                                color: "var(--text-700, #707070)",
                                fontFamily: "Poppins, sans-serif", // Ensure fallback for font-family
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "500",
                                lineHeight: "24px", // 171.429%
                                letterSpacing: "0.5px",
                                textTransform: "capitalize",
                              }}
                            >
                              {variation.name}
                            </Typography>
                            <Typography
                              sx={{
                                color: "#707070",
                                textAlign: "center",
                                fontFamily: "Poppins, sans-serif", // Ensure fallback for font-family
                                fontSize: "14px",
                                fontStyle: "normal",
                                fontWeight: "400",
                                lineHeight: "normal",
                              }}
                            >
                              {" "}
                              {variation.price !== ""
                                ? `${currencySymbol} ${+variation.price}`
                                : `${currencySymbol} 0`}
                            </Typography>
                          </Box>
                        );
                      })}
                    </Box>
                    <Box sx={{ width: "100%", textAlign: "right" }}>
                      <IconButton
                        sx={{ color: "#F5431C" }}
                        onClick={() => {
                          handleDeleteVariantGroup(group.groupName);
                        }}
                      >
                        <DeleteOutlineIcon />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}
            </Box>
            <Button
              sx={{
                textTransform: "capitalize",
                padding: "8px 12px 8px 12px",
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "24px",
                color: "#F38B08",
                borderRadius: "4px",
                marginTop: "12px",
                border: "1px solid #F38B08",
              }}
              onClick={() => {
                setGroupModal(true);
              }}
            >
              +{t("product.add_variant_group")}
            </Button>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "4%" }}>
          <Button
            className="create_category_BacK_button"
            style={{ textTransform: "none", marginRight: "0.75rem" }}
            onClick={handleBack}
          >
            {<ArrowBackIcon />} {t("product.back")}
          </Button>

          <Button
            style={{ textTransform: "none" }}
            className="create-product-button"
            type="submit"
            onClick={handleOpenDailouge}
            disabled={
              bulkLoading ||
              createLoading ||
              updateLoading ||
              skuLoading ||
              barcodeLoading
            }
          >
            {t("product.save")}
          </Button>
        </Box>
      </form>
      <VariantModal
        groupModal={groupModal}
        setGroupModal={setGroupModal}
        setAddQuantityModal={setAddQuantityModal}
      />
      <ErrorModal
        errorModal={errorModal}
        statusCode={statusCode}
        handleErrorModal={handleErrorModal}
      />
      <AddQuantityModal
        addQuantityModal={addQuantityModal}
        setAddQuantityModal={setAddQuantityModal}
        setDeleteVariantIds={setDeleteVariantIds}
      />
      <AlertCreateDialog
        dailouge={dailouge}
        handleUpdateDailougeState={handleUpdateDailougeState}
        handleComplete={handleComplete}
        categoryId={productId}
      />
      <DeletePopup
        handleSaveButton={handleProductPoppoverDelete}
        popUpwidth="35%"
        open={deleteProductPopupOpen}
        handleClose={handleDeleteProductPopupClose}
        handleOpen={handleDeleteProductPopupOpen}
        mainText={"Are you sure you want to delete this Group?"}
        button1Text="Yes Remove"
        button2Text="Don't Remove"
      />
      <EditDailouge
        modalState={dailougeCondition}
        handleModalState={handleModalState}
        handleSubmit={formik.handleSubmit}
        isValid={formik.isValid}
        item="price"
      />
    </Box>
  );
};
export default ProductPricing;
