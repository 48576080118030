import { Sheet, Typography, Box, Button } from "@mui/joy";
import NotificationIcon from "../../../assets/NavNotficationIcon.svg";
import LowStockAlert from "../../../assets/notifiactionIcons/NotificationLowStockIcon.svg";
import NotficationBlocked from "../../../assets/notifiactionIcons/NotificationBlockUserIcon.svg";
import NotficationCrossIcon from "../../../assets/notifiactionIcons/NotfiactionCrossIcon.svg";
import NotficationRushIcon from "../../../assets/notifiactionIcons/NotificationRushHoursIcon.svg";
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  ref: any;
  setOpen: (open: boolean) => void;
  setOpenAll: (open: boolean) => void;
}

const NotificationMoal = forwardRef<HTMLDivElement, Props>(
  ({ setOpen, setOpenAll }, ref) => {
    const { t } = useTranslation();
    return (
      <Sheet
        ref={ref}
        variant="outlined"
        sx={{
          width: "100%",
          maxWidth: "586px",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: "12px",
          }}
        >
          <Typography
            component="h2"
            id="close-modal-title"
            sx={{
              fontFamily: "Poppins",
              fontSize: "18px",
              fontWeight: "500",
              lineHeight: "27px",
              textAlign: "left",
              marginY: "12px",
            }}
          >
            {t("buttons.notifications")}
          </Typography>
          <img src={NotificationIcon} alt="NotificationIcon" />
        </Box>
        {/* Notification items */}
        {/* Example notification item */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "23px",
            paddingY: "12px",
            paddingX: "12px",
            borderTop: "1px solid #EEEEEE",
            borderBottom: "1px solid #EEEEEE",
            cursor: "pointer",
            backgroundColor: "#FFFFFF",
            "&:hover": {
              backgroundColor: "#FFF8F0",
            },
          }}
        >
          <Box
            sx={{
              width: { xs: "55px", sm: "64px" },
              height: { xs: "55px", sm: "64px" },
              borderRadius: "50px",
              backgroundColor: "#EEEEEE",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <img src={LowStockAlert} alt="LowStockAlertIcon" />
          </Box>
          <Box>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#000000",
                width: { xs: "150px", sm: "auto" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Low Stock Alert!
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                lineHeight: "21px",
                color: "#707070",
                width: { xs: "150px", sm: "auto" },
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Product 1 is low on stock.
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#ABABAB",
              }}
            >
              Today
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            padding: "16.5px",
            borderTop: "1px solid #EEEEEE",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Button
            onClick={() => {
              setOpenAll(true);
              setOpen(false);
            }}
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "18px",
              lineHeight: "27px",
              color: "#F38B08",
              textAlign: "center",
              cursor: "pointer",
              backgroundColor: "#FFFFFF",
              "&:hover": {
                backgroundColor: "#FFF8F0",
              },
            }}
          >
            {t("buttons.view_all_notifications")}
          </Button>
        </Box>
      </Sheet>
    );
  }
);

export default NotificationMoal;
