import React, { useEffect, useState } from 'react';
import { Paper, Typography, Switch, Divider, Button, Checkbox, TextField, FormControlLabel, Box, IconButton, InputAdornment } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import './salesTax.css';
import './manageTips.css'
import { MANAGE_TIPS2 } from '../../graphicalQl/mutation/salesTaxMutation';
import { useMutation, useQuery } from '@apollo/client';
import { MANAGE_TIPS } from '../../graphicalQl/usequery/settingQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
const ManageTips: React.FC = () => {
    const [toggleState, setToggleState] = useState(false);

    const handleToggleChange = () => {
        setToggleState(!toggleState);
    };
    const [checkboxes, setCheckboxes] = useState([
        { checked: false, label: '5' },
        { checked: false, label: '10' },
        { checked: false, label: '15' },
        { checked: false, label: '0' },
    ]);

    // handle delete item from checkbox
    const handleDeletecheckboxes = (index: number) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes.splice(index, 1);
        setCheckboxes(updatedCheckboxes);
    };

    const [newLabel, setNewLabel] = useState('');

    const handleCheckboxChange = (index: number) => {
        // if (checkboxes.filter((each) => each.checked).length <= 2) {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
        setCheckboxes(updatedCheckboxes);

    };

    const { t } = useTranslation()

    const [textCondition, setTextCondition] = useState(false)
    const [textCondition1, setTextCondition1] = useState(false)
    const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim() !== '') {
            setTextCondition(false)
        }
        setNewLabel(event.target.value);
    };
    const handleAddCheckbox = () => {
        if (newLabel.trim() !== '') {
            setCheckboxes([...checkboxes, { checked: false, label: newLabel }]);
            setNewLabel('');
            setTextCondition(false)
            setAddValue(false)
        }
        else {
            setTextCondition(true)
        }
    };

    const [addValue, setAddValue] = useState(false);


    const handleAdd = () => {
        setAddValue(!addValue);
        setTextCondition(false)
        setNewLabel('')
    };


    const [checkboxes1, setCheckboxes1] = useState([
        { checked: false, label: '5' },
        { checked: false, label: '1' },
        { checked: false, label: '0.5' },
        { checked: false, label: '2' },
    ]);

    const handleDeletecheckboxes1 = (index: number) => {
        const updatedCheckboxes = [...checkboxes1];
        updatedCheckboxes.splice(index, 1);
        setCheckboxes1(updatedCheckboxes);
    };

    const [newLabel1, setNewLabel1] = useState('');

    const handleCheckboxChange1 = (index: number) => {
        const updatedCheckboxes1 = [...checkboxes1];
        updatedCheckboxes1[index].checked = !updatedCheckboxes1[index].checked;
        setCheckboxes1(updatedCheckboxes1);
    };

    const handleNewLabelChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.value.trim() !== '') {
            setTextCondition1(false)
        }
        setNewLabel1(event.target.value);
    };

    const handleAddCheckbox1 = () => {
        if (newLabel1.trim() !== '') {
            setCheckboxes1([...checkboxes1, { checked: false, label: newLabel1 }]);
            setNewLabel1('');
            setTextCondition1(false)
            setAddValue1(false)
        }
        else {
            setTextCondition1(true)
        }
    };

    const [addValue1, setAddValue1] = useState(false);

    const handleAdd1 = () => {
        setAddValue1(!addValue1);
        setTextCondition1(false)
        setNewLabel1('')
    };


    const [tipsAfterTax, setTipsAfterTax] = useState(false);

    const handleTipsAfterTax = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTipsAfterTax(event.target.checked);
    };

    const [smartTip, setSmartTip] = useState(false);

    const handleSmartTip = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSmartTip(event.target.checked);
    };

    const [customTip, setCustomTip] = useState(false);

    const handleCustomTip = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCustomTip(event.target.checked);
    };

    const [tippingScreen, setTippingScreen] = useState(false);

    const handleTippingScreen = (event: React.ChangeEvent<HTMLInputElement>) => {
        setTippingScreen(event.target.checked);
    };
    const [cartAMount, setCartAmount] = useState("")

    const [manageTips, { loading }] = useMutation(MANAGE_TIPS2, {
        context: { clientName: "businessClient" },
        onCompleted: () => {
            toast.success(<div >
                <Typography>{t("toast.updated")}</Typography>
            </div>,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    className: 'custom_toast3',
                })
        },
        onError: (error) => {
            console.error('Error:', error);
        },
    })
    const { locationId } = useSelector((state: any) => state.headerLocation);
    const currencySymbol = sessionStorage.getItem("currencySymbol")

    const handleSave = () => {
        manageTips({
            variables: {
                storeId: locationId && +locationId,
                collectTips: toggleState,
                percentageTips: JSON.stringify(checkboxes.filter((each) => each.checked === true).map((item) => +item.label)),
                flatAmountTips: JSON.stringify(checkboxes1.filter((each) => each.checked === true).map((item) => +item.label)),
                allowSmartTips: smartTip,
                setCartAmountForSmartTips: smartTip ? +cartAMount : null,
                calculateTipAfterTaxes: tipsAfterTax,
                allowCustomTips: customTip,
                separateTippingScreen: tippingScreen,
                percentageTipDetails: JSON.stringify(checkboxes),
                flatAmountTipDetails: JSON.stringify(checkboxes1),
            }
        })
    }

    const { data } = useQuery(MANAGE_TIPS, {
        variables: { storeId: locationId && +locationId }, context: { clientName: "businessClient" }, fetchPolicy: "network-only",
        onError: (error) => {
            console.error('Error:', error);
        },
    })
    useEffect(() => {
        if (data && data.storeSettings) {
            data.storeSettings.collectTips && setToggleState(data.storeSettings.collectTips)
            data.storeSettings.allowSmartTips && setSmartTip(data.storeSettings.allowSmartTips)
            data.storeSettings.setCartAmountForSmartTips && setCartAmount(data.storeSettings.setCartAmountForSmartTips)
            data.storeSettings.calculateTipAfterTaxes && setTipsAfterTax(data.storeSettings.calculateTipAfterTaxes)
            data.storeSettings.allowCustomTips && setCustomTip(data.storeSettings.allowCustomTips)
            data.storeSettings.separateTippingScreen && setTippingScreen(data.storeSettings.separateTippingScreen)
            data.storeSettings.percentageTipDetails && setCheckboxes(JSON.parse(data.storeSettings.percentageTipDetails))
            data.storeSettings.flatAmountTipDetails && setCheckboxes1(JSON.parse(data.storeSettings.flatAmountTipDetails))


        }

    }, [data])

    const [mouseEnter, setMouseEnter] = useState("")
    const [mouseEnter1, setMouseEnter1] = useState("")

    const duplicateCondition1 = checkboxes.filter((each) => each.label === newLabel).length > 0
    const duplicateCondition2 = checkboxes1.filter((each) => each.label === newLabel1).length > 0

    return (
        <div>
            <Paper elevation={0} style={{ padding: "10px", paddingBottom: "20px", width: "100%" }}>
                <div style={{ marginLeft: '20px', marginBottom: '10px' }}>
                    <div className="sales_head_container">
                        <div className="sales_head_center">
                            <Typography className="sales_head">{t("settings.collect_tips")}</Typography>
                        </div>
                        <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
                    </div>
                    <Typography className="sales_para">{t("settings.collect_para")}</Typography>
                </div>
                <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: "8px" }} />
                {toggleState && (
                    <>
                        <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>
                            <Typography className="create_tax_main">{t("settings.manage_tips")}</Typography>
                            <div className='create_tax_button_con'>
                                {/* <Button variant="contained" className='create_tax_button_style'>
                                    {t("settings.clear")}
                                </Button> */}
                                <Button variant="contained" className='create_tax_button_style1' onClick={handleSave}
                                    sx={{ textTransform: 'capitalize' }} disabled={loading}>
                                    {t("settings.save")}
                                </Button>
                            </div>
                        </div>
                        <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "20px 12px 20px 12px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='create_tax_page1'>
                            <div>
                                <Typography className="manage-tips_head">{t("settings.percentage_tips")}</Typography>
                                <Typography className="manage-tips_para">{t("settings.percentage_para")}</Typography>
                                <div style={{ display: "flex", marginTop: '20px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: "12px", width: "90%", flexWrap: "wrap", gap: '12px' }}>
                                        {checkboxes && checkboxes.map((checkbox, index) => (
                                            <Box sx={{ position: "relative" }} className='manage-tips_checkBox' onMouseEnter={() => setMouseEnter(index.toString())} onMouseLeave={() => setMouseEnter("")} key={index}>
                                                <FormControlLabel
                                                    key={index}
                                                    control={<Checkbox color='warning' checked={checkbox.checked} onChange={() => handleCheckboxChange(index)}
                                                        disabled={checkboxes.filter((each) => each.checked).length === 3 && checkbox.checked === false}
                                                    />}
                                                    label={<Typography className='createService_check_text'>{checkbox.label}%</Typography>}


                                                />
                                                {index.toString() === mouseEnter &&
                                                    <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDeletecheckboxes(index)}>
                                                        <DeleteIcon fontSize="small" />
                                                    </IconButton>
                                                }
                                            </Box>
                                        ))}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', alignItems: "center" }}>
                                    {!addValue && <Button className='manage-tips_button' onClick={handleAdd} style={{ marginTop: '10px', textTransform: 'capitalize' }}>
                                        {t("settings.add")}
                                    </Button>}
                                    <div>
                                        {addValue && (
                                            <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                                <Box>
                                                    <TextField
                                                        label={t("settings.enter_number")}
                                                        value={newLabel}
                                                        // onChange={handleNewLabelChange}
                                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                            const val = e.target.value;
                                                            if ((!val || /^[0-9]+$/.test(val)) && val.length <= 5) {
                                                                handleNewLabelChange(e);
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        margin="dense"
                                                        type="text"
                                                        className="hide-number-input-icons"
                                                        style={{ marginTop: '10px' }}
                                                        color='warning'
                                                        sx={{
                                                            "&:hover": {
                                                                "&& fieldset": {
                                                                    border: "2px solid #F9C584"
                                                                }
                                                            }
                                                        }}
                                                    />

                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Typography sx={{ fontSize: "12px", color: "#707070" }}>{newLabel ? newLabel.toString().length : 0}/5</Typography>
                                                    </Box>
                                                    {duplicateCondition1 && <Typography style={{ color: 'red', marginLeft: '10px', fontSize: '12px' }}>*{t("error.already_existed")}</Typography>}
                                                    {textCondition && <Typography style={{ color: 'red', marginLeft: '10px', fontSize: '12px' }}>*{t("error.required")}</Typography>}
                                                </Box>
                                                <Button sx={{ color: "#ABABAB" }} onClick={handleAdd} style={{ marginLeft: '10px', display: "flex", alignItems: "center", textTransform: 'capitalize' }}>
                                                    {t("settings.cancel")}
                                                </Button>
                                                <Button className={duplicateCondition1 ? "manage-tips_button1" : "manage-tips_button"} onClick={handleAddCheckbox} style={{ marginLeft: '10px', display: "flex", alignItems: "center", textTransform: 'capitalize' }} disabled={duplicateCondition1}>
                                                    {t("settings.add")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <Typography className="manage-tips_head" style={{ marginTop: '24px' }}>
                                {t("settings.flat_amount")}
                            </Typography>
                            <Typography className="manage-tips_para">{t("settings.flat_para")}</Typography>

                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: '12px', marginLeft: '12px', width: "90%", flexWrap: "wrap", gap: '12px' }}>
                                {checkboxes1 && checkboxes1.map((checkbox, index) => (
                                    <Box className="manage-tips_checkBox" sx={{ position: "relative" }} onMouseEnter={() => setMouseEnter1(index.toString())} onMouseLeave={() => setMouseEnter1("")} key={index}>


                                        <FormControlLabel
                                            key={index}
                                            control={
                                                <Checkbox
                                                    color="warning"
                                                    checked={checkbox.checked}
                                                    onChange={() => handleCheckboxChange1(index)}
                                                    disabled={checkboxes1.filter((each) => each.checked).length === 3 && checkbox.checked === false}
                                                />
                                            }
                                            label={
                                                <Typography className="createService_check_text">
                                                    {currencySymbol} {checkbox.label}
                                                </Typography>
                                            }

                                        />
                                        {index.toString() === mouseEnter1 &&
                                            <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDeletecheckboxes1(index)}>
                                                <DeleteIcon fontSize="small" />
                                            </IconButton>
                                        }
                                    </Box>
                                ))}
                            </div>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                {!addValue1 &&
                                    <Button className="manage-tips_button" onClick={handleAdd1} style={{ marginTop: '10px', textTransform: 'capitalize' }}>
                                        {t("settings.add")}
                                    </Button>}
                                <div>
                                    {addValue1 && (
                                        <div style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
                                            <Box>
                                                <TextField
                                                    label={t("settings.enter_number")}
                                                    value={newLabel1}
                                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                        const val = e.target.value;
                                                        if ((!val || /^[0-9]+$/.test(val)) && val.length <= 5) {
                                                            handleNewLabelChange1(e);
                                                        }
                                                    }} variant="outlined"
                                                    margin="dense"
                                                    type="text"
                                                    className="hide-number-input-icons"
                                                    style={{ marginTop: '10px' }}
                                                    color="warning"
                                                    sx={{
                                                        "&:hover": {
                                                            "&& fieldset": {
                                                                border: "2px solid #F9C584",
                                                            },
                                                        },
                                                    }}
                                                />
                                                <Box sx={{ textAlign: 'right' }}>
                                                    <Typography sx={{ fontSize: "12px", color: "#707070" }}>{newLabel1 ? newLabel1.toString().length : 0}/5</Typography>
                                                </Box>
                                                {duplicateCondition2 && <Typography style={{ color: 'red', marginLeft: '10px', fontSize: '12px' }}>*{t("error.already_existed")}</Typography>}
                                                {textCondition1 && <Typography style={{ color: 'red', marginLeft: '10px', fontSize: '12px' }}>*{t("error.required")}</Typography>}
                                            </Box>
                                            <Button sx={{ color: "#ABABAB" }} onClick={handleAdd1} style={{ marginLeft: '10px', display: "flex", alignItems: "center", textTransform: 'capitalize' }}>
                                                {t("settings.cancel")}
                                            </Button>
                                            <Button
                                                className={duplicateCondition2 ? "manage-tips_button1" : "manage-tips_button"}
                                                onClick={handleAddCheckbox1}
                                                style={{ marginLeft: '10px', display: 'flex', alignItems: 'center', textTransform: 'capitalize' }}
                                                disabled={duplicateCondition2}
                                            >
                                                {t("settings.add")}
                                            </Button>
                                        </div>
                                    )}
                                </div>
                            </div>

                        </Paper>





                        <div className="manage-tips_smartTip" style={{ paddingTop: '36px', marginLeft: '68px', marginBottom: "8px" }}>
                            <div style={{ display: "flex" }}>
                                <Switch
                                    color="warning"
                                    checked={smartTip}
                                    onChange={handleSmartTip}
                                />

                                <div style={{ marginLeft: '10px' }}>
                                    <Typography className="manage-tips_head">{t("settings.smart_tips")}</Typography>
                                    <Typography className="manage-tips_para">{t("settings.tips_para1")}<br />{t("settings.tips_para2")}</Typography>
                                </div>
                            </div>
                            {smartTip &&
                                <div style={{ display: "flex", alignItems: 'center', marginTop: "8px", marginRight: '26px' }} className="manage-tips-border">
                                    <Typography className="manage-tips-cart">{t("settings.set_cart")}</Typography>
                                    <TextField
                                        fullWidth
                                        variant="standard"
                                        value={cartAMount}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            const val = e.target.value;
                                            if ((!val || /^[0-9]+$/.test(val)) && val.length <= 5) {
                                                setCartAmount(e.target.value)
                                            }
                                        }}
                                        InputProps={{
                                            disableUnderline: true,
                                            startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,

                                        }}
                                        className="mangage_text"
                                    />
                                </div>}
                        </div>
                        <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "20px 12px 20px 12px", borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)" }} className="create_tax_page1">
                            <Typography className="manage-tips_head">{t("settings.adv_payment_types")}</Typography>
                            <div style={{ display: "flex" }}>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="manage-tips-head2" style={{ marginRight: "80px" }}>{t("settings.cal_tips_after_tax")}</Typography>
                                </div>
                                <Switch
                                    color="warning"
                                    checked={tipsAfterTax}
                                    onChange={handleTipsAfterTax}
                                />
                            </div>

                            <div style={{ display: "flex", marginTop: "12px" }} className="manage-tips-allowTip_con">

                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Typography className="manage-tips-head2" style={{ marginRight: "12px" }}>{t("settings.allow_custom_tips")}</Typography>
                                    <Tooltip title={
                                        <div style={{ padding: "12px" }}>
                                            <Typography className="manage_toolTip_head">{t("settings.customTips_para1")}<br />
                                                <span className="manageTips_toolTip_text">
                                                    {t("settings.customTips_para2")} {currencySymbol}3,  {t("settings.customTips_para5")}  {currencySymbol}3. <br />
                                                    {t("settings.customTips_para3")} {currencySymbol}3,  {t("settings.customTips_para6")}<br />
                                                    {t("settings.customTips_para4")} {currencySymbol}200,  {t("settings.customTips_para7")}  {currencySymbol}1000
                                                </span>
                                            </Typography>
                                        </div>
                                    } placement="bottom-start" classes={{ tooltip: 'custom-tooltip' }} style={{ marginRight: "106px" }}>
                                        <Button sx={{ m: 1 }}>
                                            <div style={{ position: 'relative', display: 'inline-block' }} className="custom-icon-container">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                                    <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                                                </svg>
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        position: 'absolute',
                                                        top: '50%',
                                                        left: '50%',
                                                        transform: 'translate(-50%, -50%)',
                                                        color: '#ffffff',
                                                        fontWeight: 'bold',
                                                        fontSize: '16px',
                                                    }}
                                                >
                                                    !
                                                </Typography>
                                            </div>
                                        </Button>
                                    </Tooltip>
                                    <Switch
                                        color="warning"
                                        checked={customTip}
                                        onChange={handleCustomTip}
                                    />

                                </div>
                                <Typography className="sales_para">{t("settings.pos_para")}</Typography>


                            </div>

                            <div style={{ display: "flex", alignItems: "center", marginTop: "12px" }}>

                                <Typography className="manage-tips-head2" style={{ marginRight: "80px" }}>{t("settings.separate_screen")}</Typography>

                                <Switch
                                    color="warning"
                                    checked={tippingScreen}
                                    onChange={handleTippingScreen}
                                />
                            </div>

                        </Paper>
                    </>
                )}
            </Paper>
        </div>
    );
};
export default ManageTips;
