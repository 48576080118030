import { Box, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React from "react";
import { TopProductType } from "../../../../types/productReports";

interface RecentOrdersProps {
  headers: string[];
  topProducts: TopProductType[];
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#FCFCFC",
    color: "#333333",
    fontSize: "14px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "24px",
    border: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "24px",
    color: "#333333",
    border: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    borderBottom: "0px solid white",
  },
  "& > *": {
    backgroundColor: "#FCFCFC",
    paddingY: "3.5px !important",
    border: "0px",
    borderRadius: "4px !important",
  },
}));

const Statistics: React.FC<RecentOrdersProps> = ({ topProducts, headers }) => {
  return (
    <Box
      sx={{
        width: "48%",
        marginTop: "40px",
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <TableContainer
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&-ms-overflow-style:": {
              display: "none",
            },
            boxShadow: "none !important",
          }}
          component={Paper}
        >
          <Table
            sx={{
              width: { lg: "100%", sm: "1060px" },
              overflow: "auto",
              border: "2px solid #EEE",
            }}
          >
            <TableRow
              sx={{
                width: "100%",
                height: "32px",
                background: "#FCFCFC",
                borderBottom: "2px solid #EEE",
              }}
            >
              {headers?.map((header, index) => {
                return (
                  <StyledTableCell
                    sx={{
                      paddingY: "4px",
                      height: "32px",
                      border: "0px",
                    }}
                    key={index}
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        color: "#333333",
                        fontWeight: "500",
                        lineHeight: "24px",
                        //textAlign: `${index === 0 ? "start" : "center"}`,
                      }}
                    >
                      {typeof header === "string" ? header : ""}
                    </Typography>
                  </StyledTableCell>
                );
              })}
            </TableRow>

            <TableBody>
              {topProducts?.map((row, rowIndex) => (
                <StyledTableRow key={rowIndex}>
                  <StyledTableCell>
                    {row.product_name}
                  </StyledTableCell>
                  <StyledTableCell>{row.quantity}</StyledTableCell>
                  <StyledTableCell>{row.sales}</StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default Statistics;
