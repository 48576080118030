import React, { useEffect, useState } from 'react';
import {
  Typography, Paper, Button, TextField, Switch, RadioGroup, Radio, FormControlLabel,
  FormControl, Select, MenuItem, Divider, InputLabel
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useParams } from 'react-router-dom';
import './createSalesTax.css'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { GETTING_LOCATIONS_LIST } from '../../graphicalQl/usequery/locationQuery';


import { useMutation, useQuery } from '@apollo/client';
import AssignProducts from './assignProductModalIndiscount';
import { CREATE_SALES_TAX, UPDATE_SALES_TAX } from '../../graphicalQl/mutation/salesTaxMutation';
import { TAX_EDIT_DATA } from '../../graphicalQl/usequery/settingQuery';
import { GET_PRODUCT } from '../../graphicalQl/usequery/categoryListQuery';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import ErrorModal from '../errorHandling/errorModal';


interface Locations {
  id: string;
  status: string
  storeId: string
  storeName: string
  isChecked: boolean
};

const validationSchemaPart1 = Yup.object({
  taxName: Yup.string().required('Required'),
  taxRate: Yup.string().required('Required'),


});
const CreateSalesTax = () => {
  const navigate = useNavigate();
  const { id } = useParams()
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const salesValues = {

    selectedRounding: '',
    taxName: '',
    taxRate: '',
    selectedLocation: locationId,
    selectedRadioValue: 'SelectedItems',
    taxSelection: false
  };
  const businessId: string = sessionStorage.getItem("businessId") as string
  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0)
  const [errorModal, setErrorModal] = useState<boolean>(false)

  const handleErrorModal = () => {
    setErrorModal(false)
  }
  const formik = useFormik({
    initialValues: salesValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (id) {
        updateTax({
          variables: { taxId: id && +id, taxName: values.taxName.trim(), taxPercentage: +values.taxRate, roundingRule: values.selectedRounding, items: JSON.stringify(selectProductIds), includeTaxInItemPrice: values.taxSelection },
          onCompleted: () => {
            toast(<div style={{ display: 'flex', alignItems: 'center', height: '8px', justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="white" />
              </svg>
              <Typography>Sales Tax Updated Sucessfully</Typography>
            </div>,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                className: 'custom_toast3',
              })
            navigate("/sales/tax")
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
              setErrorModal(true)
            }
            // const errorMessage = graphqlError.message || 'An error occurred';


          },

        })
      }
      else {
        createTax({
          variables: { storeId: +values.selectedLocation, business: +businessId, taxName: values.taxName.trim(), taxPercentage: +values.taxRate, roundingRule: values.selectedRounding, items: JSON.stringify(selectProductIds), includeTaxInItemPrice: values.taxSelection },
          onCompleted: () => {
            toast(<div style={{ display: 'flex', alignItems: 'center', height: '8px', justifyContent: "center" }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20ZM16.59 7.58L10 14.17L7.41 11.59L6 13L10 17L18 9L16.59 7.58Z" fill="white" />
              </svg>
              <Typography>Sales Tax Created Sucessfully</Typography>
            </div>,
              {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'light',
                className: 'custom_toast3',
              })
            navigate("/sales/tax")
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
              setErrorModal(true)
            }
            // const errorMessage = graphqlError.message || 'An error occurred';


          },
        })

      }
    },
  })



  const option1Element = (
    <div>
      <Typography className='create_tax_type_table_row_radio'>{t("settings.all_taxable")}</Typography>
      <Typography className='create_tax_type_table_row' style={{ fontSize: "14px" }}>{t("settings.all_taxable_para")}</Typography>
    </div>
  );

  const option2Element = (
    <div>
      <Typography className='create_tax_type_table_row_radio' >{t("settings.select_items")}</Typography>
      <Typography className='create_tax_type_table_row' style={{ fontSize: "14px" }}>{t("settings.select_items_para")}</Typography>
    </div>
  );



  const option1 = (
    <div>
      <Typography className='rounding_value'>{t("settings.none")}</Typography>
    </div>
  )
  const option3 = (
    <div>
      <Typography className='rounding_value'>{t("settings.half_down")}<span className='span_style'>({t("settings.if")} 7.5==7.00)</span></Typography>
    </div>
  )
  const option2 = (
    <div>
      <Typography className='rounding_value'>{t("settings.half_up")}<span className='span_style'>( {t("settings.if")} 7.5 ==8.00)</span></Typography>
    </div>
  )
  const option4 = (
    <div>
      <Typography className='rounding_value'>{t("settings.upwards")}<span className='span_style'>( {t("settings.if")} 7.62 ==8.00)</span></Typography>
    </div>
  )
  const option6 = (
    <div>
      <Typography className='rounding_value'>{t("settings.upwards_to")}<span className='span_style'>( {t("settings.if")} 7.62 ==7.99)</span></Typography>
    </div>
  )
  const option5 = (
    <div>
      <Typography className='rounding_value1'>{t("settings.downwards")}<span className='span_style'>({t("settings.if")} 7.62==7.00)</span></Typography>
    </div>
  )

  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, { context: { clientName: "businessClient" }, variables: { businessId: +businessId } })

  const [locationsList, setLocationsList] = useState<Locations[]>([]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map((each: any) => ({
        storeId: each.node.storeId,
        storeName: each.node.storeName,
        isChecked: false
      }));

      // Updating state with the required data
      setLocationsList(requiredDataList);
    }
  }, [locationsData]);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));


  const handleClose = () => setOpen(false);
  const [selectProductIds, setSelectedProductIds] = useState<number[]>([])
  const hnadleUpdateProductIds = (data: any) => {
    setSelectedProductIds(data)
  }

 

  const [createTax] = useMutation(CREATE_SALES_TAX, { context: { clientName: 'businessClient' } })
  const [updateTax] = useMutation(UPDATE_SALES_TAX, { context: { clientName: 'businessClient' } })

  const handleClear = () => {
    formik.setValues(salesValues)
    setSelectedProductIds([])
  }
  const [upDatedProductData, setProductData] = useState<any[]>([]);

  const { data: productData,refetch:productDataRefetch } = useQuery(GET_PRODUCT, { variables: { businessId: +businessId, locationId: formik.values.selectedLocation }, context: { clientName: 'categoryClient' }, fetchPolicy: "network-only",
  onCompleted:(data)=>{
    if (data) {
      const { productList } = data
      const { edges } = productList
      if (edges.length > 0) {
        const productUpdatedList = edges.map((item: any) => +item.node.productId);

        setProductData(productUpdatedList);
      }

    }
  } })
  useEffect(() => {
    if (!id) {
      setSelectedProductIds([])
      // setProductData([])
      productDataRefetch()
    }
  }, [formik.values.selectedLocation])


//   useEffect(() => {
//     if (productData) {
//       const { productList } = productData
//       const { edges } = productList
//       if (edges.length > 0) {
//         const productUpdatedList = edges.map((item: any) => +item.node.productId);

//         setProductData(productUpdatedList);
//       }

//     }
//   }, [productData]);


  const { data, loading } = useQuery(TAX_EDIT_DATA, { variables: { taxId: id && +id }, context: { clientName: 'businessClient' }, fetchPolicy: "network-only" })
  useEffect(() => {
    if (data && data.tax) {
      const list = {

        selectedRounding: data.tax.roundingRule,
        taxName: data.tax.taxName,
        taxRate: data.tax.taxPercentage,
        selectedLocation: data.tax.store.storeId,
        selectedRadioValue: JSON.parse(data.tax.items).length === upDatedProductData.length ? "applyToAll" : "SelectedItems",
        taxSelection: data.tax.includeTaxInItemPrice
      }
      formik.setValues(list)

      setSelectedProductIds(JSON.parse(data.tax.items))

    }
  }, [data, upDatedProductData])




  // useEffect(() => {
  //   if (formik.values.selectedRadioValue === "applyToAll") {
  //     setSelectedProductIds(upDatedProductData)
  //   }
  //   else {
  //     setSelectedProductIds([])
  //   }

  // }, [formik.values.selectedRadioValue])

  const handleRadioChange = (e: any) => {
    if (e.target.value === "applyToAll") {
      setSelectedProductIds(upDatedProductData)
    }
    else {
      setSelectedProductIds([])
    }
  }
  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} style={{ paddingBottom: "20px" }} >

          <div className='create_tax_head_container' style={{ padding: "12px 20px 0px 12px" }}>

            <div className='create_exitIcon'>
              <ArrowBackIcon sx={{ height: "24px", width: '24px' }} onClick={() => { navigate("/sales/tax") }} />
            </div>

            {id ? <Typography className="create_tax_main">Edit Tax</Typography>
              : <Typography className="create_tax_main">{t("settings.create_tax")}</Typography>
            }
            <div className='create_tax_button_con'>
              <Button variant="contained" className='create_tax_button_style' onClick={handleClear}>
                {t("settings.clear")}
              </Button>
              <Button variant="contained" className='create_tax_button_style1' type='submit'>
                {t("settings.save")}
              </Button>
            </div>
          </div>
          <div style={{ width: "80%", marginLeft: "10%", marginTop: "44px" }}>
            <Paper style={{ padding: "32px 12px ", borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)" }} className='create_tax_page'>
              <Typography className="create_tax_basicDetails">{t("settings.basic_details")}</Typography>

              <TextField
                label={
                  <Typography className='create_tax_label'>
                    {t("settings.tax_name")}
                  </Typography>
                }
                variant="outlined"
                value={formik.values.taxName}
                name='taxName'
                onChange={(e) => formik.handleChange(e)}
                className='create_tax_textField'
                color='warning'
                onBlur={formik.handleBlur}
                error={formik.touched.taxName && Boolean(formik.errors.taxName)}
                helperText={formik.touched.taxName && formik.errors.taxName}
                sx={{
                  "&:hover": {
                    "&& fieldset": {
                      border: "2px solid #F9C584"
                    }
                  }
                }}
              />
              <div
                id="charCount"
                className="char-count1"
                style={{ width: "70%", textAlign: "right" }}
              >
                {(formik.values.taxName).length}/25
              </div>
              <div style={{ display: "flex", alignItems: "center", width: '75%', gap: "12px", marginTop: "48px" }}>
                <TextField
                  label={
                    <Typography className='create_tax_label'>
                      {t("settings.tax_rate%")}
                    </Typography>
                  }
                  variant="outlined"
                  value={formik.values.taxRate}
                  name='taxRate'
                  type="number"
                  error={formik.touched.taxName && Boolean(formik.errors.taxRate)}
                  helperText={formik.touched.taxName && formik.errors.taxRate}
                  className="hide-number-input-icons create_tax_textField1"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    const val = e.target.value;

                    if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 3) {
                      formik.handleChange(e)                      // formik.handleChange(e)
                    }
                  }}

                  color='warning'
                  sx={{
                    "&:hover": {
                      "&& fieldset": {
                        border: "2px solid #F9C584"
                      }
                    },
                    width: "50%"
                  }}
                />
                {!id && <FormControl className='create_tax_dropDown' sx={{ width: "50%" }}>
                  <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label={t("settings.select_location")}
                    value={formik.values.selectedLocation}
                    name='selectedLocation'
                    onChange={(e) => {
                      formik.handleChange(e);
                      formik.setFieldValue("selectedRadioValue", "SelectedItems");
                    }} color='warning'
                    sx={{
                      "&:hover": {
                        "&& fieldset": {
                          border: "2px solid #F9C584"
                        }
                      }
                    }}
                  >
                    {locationsList.map((each) => (
                      <MenuItem key={each.storeId} value={each.storeId}>
                        {each.storeName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>}


              </div>
              {/* {salesValues.value && (
                <BasicModal />
              )} */}

            </Paper>

            <Paper style={{ padding: "20px 12px 20px 12px", borderLeft: "2px solid var(--stroke-color-2-tale, #9BDAE2)", marginTop: "24px" }} className='create_tax_page'>
              <Typography className="create_tax_basicDetails">{t("settings.tax_application")} </Typography>
              <Divider style={{ marginTop: "16px", marginBottom: "12px" }} />
              <FormControl component="fieldset">

                <RadioGroup
                  aria-label="options"
                  name="selectedRadioValue"
                  value={formik.values.selectedRadioValue}
                  onChange={(e) => { formik.handleChange(e); handleRadioChange(e) }}

                >
                  <FormControlLabel value="applyToAll" control={<Radio color='warning' />} label={option1Element} />
                  <Divider style={{ marginTop: "12px", marginBottom: "12px" }} />
                  <FormControlLabel value="SelectedItems" control={<Radio color='warning' />} label={option2Element} />

                </RadioGroup>
              </FormControl>
              <Divider style={{ marginTop: "16px", marginBottom: "12px" }} />
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography className='create_tax_selectProducts'>{t("settings.select_products")}</Typography>
                <Typography className='create_tax_products' style={{ marginLeft: '36px' }}>{selectProductIds.length} selected</Typography>
              </div>
              <Button className={formik.values.selectedRadioValue === "applyToAll" ? 'create_tax_select_button_diasble' : 'create_tax_select_button'} style={{ width: "100%", marginTop: "16px" }} onClick={handleOpen} disabled={formik.values.selectedRadioValue === "applyToAll"}>{t("settings.assign_products")}</Button>
            </Paper>
            {/* <Paper style={{ padding: "20px 12px 20px 10px",borderLeft: "2px solid var(--stroke-color-3-green, #BAE29B)",marginTop:"24px" }} className='create_tax_page'>
        
            <div style={{ display: "flex" }}>
              <Switch onChange={handleToggle} checked={salesValues.isOn} color='warning' />
              <div style={{marginLeft:'32px'}}>
                <Typography className="create_tax_exception">{t("settings.add_rule")}</Typography>
                <Typography className='create_tax_type_table_row' >{t("settings.add_rule_para")}</Typography>
              </div>
            </div>
            <Divider style={{marginTop:"20px"}}/>
            {salesValues.isOn &&
              <div>
                <TextField
              label={
                <Typography className='create_tax_label'>
                {t('settings.tax_exception_name')}
                </Typography>
              }
              variant="outlined"
              value={salesValues.taxException}
        onChange={handleTaxException}
        className='create_tax_textField'
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
        />
        <div style={{display:"flex"}}>
        <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 0px" }}>
      <InputLabel id="demo-simple-select-label">{t("settings.select_location")}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        label={t("settings.select_location")}
        value={salesValues.selectedLocation1}
        onChange={handleLocationChange1}
        color='warning'
        sx={{
          "&:hover": {
            "&& fieldset": {
              border: "2px solid #F9C584"
            }
          }
        }}
      >
        <MenuItem value="Hyderabad">Hyderabad</MenuItem>
        <MenuItem value="Vizag">Vizag</MenuItem>
        <MenuItem value="Kakinada">Kakinada</MenuItem>
      </Select>
    </FormControl>

    <FormControl className='create_tax_dropDown' style={{ margin: "20px 0px 0px 12px" }}>
      <InputLabel id="demo-simple-select-label">{t("settings.dining_options")}</InputLabel>
      <Select
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  label={t("settings.dining_options")}
  value={salesValues.selectedDine} 
  onChange={handleDine}
  color='warning'
  sx={{
    "&:hover": {
      "&& fieldset": {
        border: "2px solid #F9C584"
      }
    }
  }}
>
  <MenuItem value="dineIn">Dine In</MenuItem>
  <MenuItem value="DineOut">Dine Out</MenuItem>
  <MenuItem value="pickUp">Pick Up</MenuItem>
</Select>

    </FormControl>
        </div>
        <Divider style={{marginTop:"16px",marginBottom:"12px"}}/>
    <div style={{display:"flex",alignItems:"center"}}>
<Typography className='create_tax_selectProducts'>{t("settings.select_products")}</Typography>
<Typography className='create_tax_products' style={{marginLeft:'36px'}}>{t("settings.product_select")}</Typography>
</div>
<Button className='create_tax_select_button' style={{width:"100%",marginTop:"16px"}}>{t("settings.assign_products")}</Button>
              </div>
            }
          
          </Paper> */}
            <Paper style={{ padding: "12px 12px 20px 10px", borderLeft: "2px solid var(--stroke-color-4-brown, #E2A39B)", marginTop: "24px" }} className='create_tax_page'>
              <Typography className="create_tax_basicDetails" style={{ marginTop: "10px", marginBottom: "10px" }}>Tax Rounding</Typography>
              <div style={{ display: "flex" }}>
                <RadioGroup
                  aria-label="radio-button-group"
                  name="selectedRounding"
                  value={formik.values.selectedRounding}
                  onChange={(e) => formik.handleChange(e)}
                  row
                  style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}
                >
                  <FormControlLabel value="None" control={<Radio color='warning' />} label={option1} className='create_tax_rounding' />
                  <FormControlLabel value="option2" control={<Radio color='warning' />} label={option2} className='create_tax_rounding' />
                  <FormControlLabel value="option3" control={<Radio color='warning' />} label={option3} className='create_tax_rounding' />
                  <FormControlLabel value="option4" control={<Radio color='warning' />} label={option4} className='create_tax_rounding' />
                  <FormControlLabel value="option5" control={<Radio color='warning' />} label={option5} className='create_tax_rounding' />
                  <FormControlLabel value="option6" control={<Radio color='warning' />} label={option6} className='create_tax_rounding' />
                </RadioGroup>
              </div>
            </Paper>
            <Paper style={{ padding: "12px 12px 20px 10px", borderLeft: "2px solid var(--stroke-color-5-magenta, #E19BE2)", marginTop: "24px" }} className='create_tax_page'>

              <Typography className='create_tax_basicDetails' >{t("settings.tax_calculation")}</Typography>
              <Divider style={{ marginTop: "20px", marginBottom: "12px" }} />
              <div style={{ display: "flex", alignItems: 'center' }}>
                <Switch color='warning' style={{ marginRight: "32px" }} name='taxSelection' checked={formik.values.taxSelection} onChange={(e) => formik.handleChange(e)} />
                <div>
                  <Typography className='create-tax_last_head'>{t("settings.include_tax")}</Typography>
                  <Typography className='create-tax_last_para'>{t("settings.include_tax_para")}</Typography>
                </div>

              </div>
            </Paper>

          </div>

          <AssignProducts open={open} upDateOpen={handleClose} assigncategoryDataIds={selectProductIds} hnadleUpdateProductIds={hnadleUpdateProductIds} categoryId='' location={formik.values.selectedLocation} />
          <ErrorModal errorModal={errorModal} statusCode={categoryStatusCode} handleErrorModal={handleErrorModal} />


        </Paper>
      </form>
    </div>
  )
}
export default CreateSalesTax