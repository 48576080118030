export const convertSnakeCaseToPascalCase = (str: string) => {
  return str
    ? str
        .split("_")
        .map((s) => s.charAt(0).toUpperCase() + s.slice(1).toLowerCase())
        .join(" ")
    : "";
};

export const initCap = (str: string) => {
  return typeof str === "string"
    ? str.toLowerCase().replace(/(?:^|\s)[a-z]/g, function (m) {
        return m.toUpperCase();
      })
    : str;
};
