
import React, { useState } from 'react';
import { useEffect } from 'react';
import { Box, Typography, Button } from "@mui/material";
import '../options/createProduct.css'
import './createCategory.css'
import { CloseOutlined } from '@mui/icons-material';
import { DataGrid, GridColDef, GridSortModel } from '@mui/x-data-grid';
import image from '../../assets/createProduct.png';
import AssignProducts from './assigngnProductsModal';
import { useMutation, useQuery } from '@apollo/client';
import ProductDailouge from './productDailougeBox';
import { DELTE_PRODUCT_FROM_CATEGORY } from '../../graphicalQl/mutation/mutation';
import 'react-toastify/dist/ReactToastify.css';
import { IconButton } from "@mui/material";
import { PRODUCT_DATA_IN_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import CircularLoader from '../../loader/loader';
import CurrencyDisplay from '../utils/currencySymbol';
import DeleteDailouge from './deleteDailouge';
import { useTranslation } from "react-i18next";
import { useSelector } from 'react-redux';


interface Props {
  id: string;
  handleStepByProductModal: () => void;

}

interface Product {
  id: string,
  product: string,
  price: string
}


const CategoryAssignProduct = (props: Props) => {
  const { locationId } = useSelector((state: any) => state.headerLocation); const { t } = useTranslation();

  const { id, handleStepByProductModal } = props
  const businessId: string = sessionStorage.getItem("businessId") as string

  const [productId, setProductId] = useState<string>("")

  const handleGetProductId = (productId: string) => {

    setProductId(productId)
  }

  const handleDelteProductByDailouge = (productId: string) => {
    delteProduct({ variables: { categoryId: +id, productId: +productId } })

  }
  const columns: GridColDef[] = [

    {
      field: 'product',
      //@ts-ignore
      headerName: t("menu.catageory.product"),
      filterable: false,
      headerClassName: 'product-table-in-category-head',
      hideSortIcons: true,
      editable: false,
      disableColumnMenu: true,
      flex: 1,

    },
    {
      field: 'price',
      //@ts-ignore
      headerName: t("menu.catageory.price"),
      headerClassName: 'product-table-in-category-head',
      align: "right",
      filterable: false,
      headerAlign: "right",
      hideSortIcons: true,
      editable: false,
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          // <CurrencyDisplay countryName={'UnitedKingdom'} amount={params.row.price} />
          <Box>
            {/* <Typography>{params.value}</Typography> */}
            <CurrencyDisplay countryName={'UnitedKingdom'} amount={params.row.price ? params.row.price.split('-') : "0"} />
          </Box>
        )
      }
    },
    {
      field: '.',
      headerName: '',
      filterable: false,

      width: 110,
      hideSortIcons: true,
      headerClassName: 'product-table-in-category-head',
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      editable: false,
      renderCell: (params) => {
        return (
          <IconButton onClick={() => { handleGetProductId(params.row.id); handleDeleteModal() }} disabled={deleteLoading}>
            <CloseOutlined />
          </IconButton>
        )
      }
    },

  ];






  const [open, setOpen] = React.useState(false);
  const handleOpen = () => (setOpen(true));
  const handleClose = () => setOpen(false);


  const [assigncategoryData, setAssigncategoryData] = useState<Product[]>([])

  const { data: editData, loading: editDataLoading } = useQuery(PRODUCT_DATA_IN_CATEGORY, {
    variables: { categoryIds: JSON.stringify([+id]), businessId: +businessId, locationId: locationId && +locationId }, context: { clientName: 'categoryClient' }, fetchPolicy: "cache-and-network",
    onError: (error) => {
      console.error('Error:', error);
    },
  })
  const assigncategoryDataIds = assigncategoryData && assigncategoryData.map((each) => {
    return +each.id
  })


  useEffect(() => {
    if (editData) {

      if (editData.category !== null) {
        const productData = editData.productList.edges.map((item: any) => ({
          id: item.node.productId,
          product: item.node.name,
          price: item.node.price
        }))

        setAssigncategoryData(productData)
      }
    }

  }, [editData])

  const [delteProduct, { loading: deleteLoading }] = useMutation(DELTE_PRODUCT_FROM_CATEGORY, {
    context: { clientName: 'categoryClient' }, refetchQueries: [
      PRODUCT_DATA_IN_CATEGORY, // DocumentNode object parsed with gql

    ],
    onError: (error) => {
      console.error('Error:', error);
    },
  })

  const handleRowStyle = () => {
    return "dataGrid-table-row"
  }
  const handleCellclassName = () => {
    return "dataGrid-table-cell-less-wt"
  }
  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: 'product',
      sort: 'asc',
    },
  ]);
  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const [deletemodal, setDeleteModal] = useState<boolean>(false)
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal)
  }

  const handleDeleteClick = () => {

  }
  if (editDataLoading) {
    return (
      <CircularLoader />
    )

  }

  return (<Box sx={{ marginTop: "18px", marginBottom: '18px' }} className="product-box-in-category" >
    <Box sx={{ width: "100%", display: 'flex', flexDirection: "column" }}>
      {assigncategoryData.length > 0 ? (

        <Box sx={{
          marginLeft: "5%", marginRight: "5%", height: "40vh", overflowY: "scroll", WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
            display: 'none',
          },
        }}>
          <DataGrid
            rows={assigncategoryData}
            getRowClassName={handleRowStyle}
            columns={columns}
            columnHeaderHeight={36}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            rowHeight={44}
            getCellClassName={handleCellclassName}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 5,
                },
              },
            }}
            pageSizeOptions={[5]}
            disableRowSelectionOnClick
            sx={{
              marginLeft: "20px",
              marginRight: "20px",
              '.MuiTablePagination-displayedRows': {
                display: 'none', // 👈 to hide huge pagination number
              },
              '&.MuiDataGrid-columnHeaderTitle': {
                fontWeight: '500',
              },
              '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                outline: 'solid #1976d2 0px !important',
              },
              '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
                outline: 'solid #1976d2 0px !important',
              },
              "& .MuiDataGrid-columnHeader": {

                background: "#f5f5f5",

              },
              // marginLeft: "20px",

            }}
          />
        </Box>
      ) : (<div style={{ textAlign: 'center', background: ' var(--prime-orange-100, #FFF8F0,)' }}>
        <Typography className='assign_product_text'>{t("menu.catageory.click_on")} <span className='assign_product_text2'>{t("menu.catageory.assign_product_button")} </span> {t("menu.catageory.to_add_products")} </Typography>
        <img src={image} alt="product" style={{ width: "180px", height: "180px" }} />
      </div>)}
      <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', marginTop: "12px" }}>
        <Button className='main-button' onClick={handleOpen} sx={{ textTransform: "none" }}>
          {t("menu.catageory.assign_products")}
        </Button>
      </Box>
      <div>
        <AssignProducts open={open} upDateOpen={handleClose} categoryId={id} assigncategoryDataIds={assigncategoryDataIds} />
      </div>
      <ProductDailouge id={id} handleStepByProductModal={handleStepByProductModal} />
      <DeleteDailouge id={productId} deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDelteProductByDailouge} handleDeleteClick={handleDeleteClick} type="product" />
    </Box>

  </Box>
  );
}

export default CategoryAssignProduct;
