//write basic structure of the component
import React from 'react';
import ReviewsAndRatingsMain from './mainComponent';

const ReviewsAndRatings = () => {
    return (
        <div>
            <ReviewsAndRatingsMain />
        </div>
    )
}
export default ReviewsAndRatings;