import { Box, Typography, Button, IconButton, Paper } from "@mui/material";

import React, { useEffect, useState } from "react";
import ManualQuotingTime from "./manualQuotingTime";
import KdsSettingsOrders from "./kdsSettingsOrders";
import { useFormik } from "formik";
import * as Yup from "yup";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FILTERING_PRODUCTS_LIST } from "../../graphicalQl/usequery/productsListQuery";
import { GET_UPDATED_DATA_FOR_KITCHEN_SETTINGS } from "../../graphicalQl/usequery/locationQuery";
import { UPDATE_KITCHEN_CAPACITY_SETTINGS } from "../../graphicalQl/mutation/locationMutation";
import "./kitchenSettings.css";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { t } from "i18next";
import { useTranslation } from "react-i18next";

interface inputValues {
  dineInHr: string | null;
  dineInMin: string | null;
  collectionHr: string | null;
  collectionMin: string | null;
  deliveryHr: string | null;
  deliveryMin: string | null;

  channel1Hr: string | null;
  channel1Min: string | null;
  channel2Hr: string | null;
  channel2Min: string | null;
  channel3Hr: string | null;
  channel3Min: string | null;

  orderPricingRules: boolean;
  orderPricingAmount: string | null;
  orderPricingHr: string | null;
  orderPricingMin: string | null;

  LimitItemPerSlot0thIndex: string | null;
  ProductsIdsPerSlot0thIndex: any[];
  startTimePerSlot0thIndex: string | null;
  endTimePerSlot0thIndex: string | null;
  dayPerSlot0thIndex: any[];

  limitItemPerSlotList: any[];

  limitOrdersPerSlot: string | null;
  limitOrdersPerSlotFromTime: string | null;
  limitOrdersPerSlotToTime: string | null;
  limitOrdersPerSlotDay: any[];
}

const validationSchemaPart1 = Yup.object({
  orderPricingAmount: Yup.string().when("orderPricingRules", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  orderPricingHr: Yup.string().when("orderPricingRules", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  orderPricingMin: Yup.string().when("orderPricingRules", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  limitItemPerSlotList: Yup.array().of(
    Yup.object({
      limitPerUser: Yup.string().required("Required"),
      collectedProductIds: Yup.array()
        .min(1, "At least one Product must be selected")
        .required("At least one Product must be selected"),
      startTime: Yup.string().required("Required"),
      collectedDays: Yup.array()
        .min(1, "At least one Day must be selected")
        .required("At least one Day must be selected"),

      endTime: Yup.string()
        .test(
          "is-greater",
          "End time must be greater than start time",
          function (value) {
            const { startTime } = this.parent;
            // Access the values of other fields
            if (startTime && value) {
              return startTime <= value;
            }
            return true; // Return true if either field is empty
          }
        )
        .required("Required"),
    })
  ),

  endTimePerSlot0thIndex: Yup.string().test(
    "is-greater",
    "Invalid Time",
    function (value) {
      const { startTimePerSlot0thIndex } = this.parent; // Access the values of other fields
      if (startTimePerSlot0thIndex && value) {
        return startTimePerSlot0thIndex <= value;
      }
      return true; // Return true if either field is empty
    }
  ),
  limitOrdersPerSlotToTime: Yup.string().test(
    "is-greater",
    "Invalid Time",
    function (value) {
      const { limitOrdersPerSlotFromTime } = this.parent; // Access the values of other fields
      if (limitOrdersPerSlotFromTime && value) {
        return limitOrdersPerSlotFromTime <= value;
      }
      return true; // Return true if either field is empty
    }
  ),
});
const initialValuesOfTextFields: inputValues = {
  dineInHr: null,
  dineInMin: null,
  collectionHr: null,
  collectionMin: null,
  deliveryHr: null,
  deliveryMin: null,

  channel1Hr: null,
  channel1Min: null,
  channel2Hr: null,
  channel2Min: null,
  channel3Hr: null,
  channel3Min: null,

  orderPricingRules: false,
  orderPricingAmount: null,
  orderPricingHr: null,
  orderPricingMin: null,

  LimitItemPerSlot0thIndex: null,
  ProductsIdsPerSlot0thIndex: [],
  startTimePerSlot0thIndex: "",
  endTimePerSlot0thIndex: "",
  dayPerSlot0thIndex: [],

  limitItemPerSlotList: [],

  limitOrdersPerSlot: null,
  limitOrdersPerSlotFromTime: null,
  limitOrdersPerSlotToTime: null,
  limitOrdersPerSlotDay: [],
};

const GeneralSettingsFromDevice = () => {
  const { t } = useTranslation();
  function convertMinutesToHoursAndMinutes(totalMinutes: number) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return { hours, minutes };
  }
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [initialProductsList, setInitialProductsList] = useState<any[]>([]);
  const [initialDaysList, setInitialDaysList] = useState<any[]>([
    {
      id: 1,
      day: "Monday",
      text: t("devices.monday"),
      isChecked: false,
    },
    {
      id: 2,
      day: "Tuesday",
      text: t("devices.tuesday"),
      isChecked: false,
    },
    {
      id: 3,
      day: "Wednesday",
      text: t("devices.wednesday"),
      isChecked: false,
    },
    {
      id: 4,
      day: "Thursday",
      text: t("devices.thursday"),
      isChecked: false,
    },
    {
      id: 5,
      day: "Friday",
      text: t("devices.friday"),
      isChecked: false,
    },
    {
      id: 6,
      day: "Saturday",
      text: t("devices.saturday"),
      isChecked: false,
    },
    {
      id: 7,
      day: "Sunday",
      text: t("devices.sunday"),
      isChecked: false,
    },
  ]);

  const [allCheckboxCheckedFor0thIndex, setAllCheckboxCheckedFor0thIndex] =
    useState<boolean>(false);
  const [allCheckboxesForDays0thIndex, setAllCheckboxesForDays0thIndex] =
    useState<boolean>(false);
  const [
    allCheckboxesForLimitOrdersPerSlot,
    setAllCheckboxesForLimitOrdersPerSlot,
  ] = useState<boolean>(false);
  const [limitOrderPerItemList, setLimitOrderPerItemList] = React.useState<
    any[]
  >([]);

  const requiredVariables = {
    storeId: locationId,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(requiredVariables).filter(([_, value]) => value !== null)
  );

  const {
    loading: kitchenSettingsLoading,
    error: kitchenSettingsError,
    data: kitchenSettingsData,
  } = useQuery(GET_UPDATED_DATA_FOR_KITCHEN_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });
  const filteringObject = {
    locationId: locationId && +locationId,
    businessId: +businessId,
  };

  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );
  const {
    loading: productsDataLoadingByCategoryId,
    error: productsDataErrorByCategoryId,
    data: productsDataByFilteringAllFilters,
  } = useQuery(FILTERING_PRODUCTS_LIST, {
    context: { clientName: "categoryClient" },
    fetchPolicy: "network-only",
    variables: requiredFormatAddress,
  });

  useEffect(() => {
    if (productsDataByFilteringAllFilters) {
      if (productsDataByFilteringAllFilters !== null) {
        const { edges } = productsDataByFilteringAllFilters.productList;

        const filteredProductsData = edges.map((each: any) => ({
          name: each.node.name,
          id: each.node.productId,
          isChecked: false,
        }));

        setProductsList(filteredProductsData);
        setProductsListFor0thIndex(filteredProductsData);
        setInitialProductsList(filteredProductsData);
      }
    } else {
    }
  }, [productsDataByFilteringAllFilters]);

  const selectedProuctsForIteration = (productIds: any, productsList: any) => {
    const filteredList = productsList.map((each: any) => {
      if (productIds.includes(parseInt(each.id))) {
        return { ...each, isChecked: true };
      }
      return each;
    });
    return filteredList;
  };

  const selectedDaysForIteration = (days: any, initialDays: any) => {
    const filteredList = initialDays.map((each: any) => {
      if (days.includes(each.day)) {
        return { ...each, isChecked: true };
      }
      return each;
    });
    return filteredList;
  };
  useEffect(() => {
    if (kitchenSettingsData) {
      const { storeSettings } = kitchenSettingsData;
      if (storeSettings) {
        const { kitchenCapacitySettings } = storeSettings;
        const parsedJson = JSON.parse(kitchenCapacitySettings);
        const {
          preparation_time,
          channels,
          enable_order_pricing_rule,
          order_amount,
          delivery_time,
          item_limit_per_slot,
          order_limit_per_slot,
        } = parsedJson;
        if (item_limit_per_slot.length > 0) {
          const itemPerSlot0thIndex = item_limit_per_slot[0];
          if (itemPerSlot0thIndex) {
            const { days, limit, from_time, to_time, products } =
              itemPerSlot0thIndex;

            if (products) {
              formik.setFieldValue("ProductsIdsPerSlot0thIndex", products);
              setAllCheckboxCheckedFor0thIndex(
                products.length === productsListFor0thIndex.length
              );
              const filteredList = productsListFor0thIndex.map((each: any) => {
                if (products.includes(parseInt(each.id))) {
                  return { ...each, isChecked: true };
                }
                return each;
              });
              setProductsListFor0thIndex(filteredList);
            }
            formik.setFieldValue("LimitItemPerSlot0thIndex", limit);
            if (from_time) {
              formik.setFieldValue("startTimePerSlot0thIndex", from_time);
            } else {
              formik.setFieldValue("startTimePerSlot0thIndex", "");
            }
            if (to_time) {
              formik.setFieldValue("endTimePerSlot0thIndex", to_time);
            } else {
              formik.setFieldValue("endTimePerSlot0thIndex", "");
            }
            if (days) {
              setAllCheckboxesForDays0thIndex(
                days.length === daysListFor0thIndex.length
              );
              formik.setFieldValue("dayPerSlot0thIndex", days);
              const filterdDays = daysListFor0thIndex.map((each: any) => {
                if (days.includes(each.day)) {
                  return { ...each, isChecked: true };
                }
                return each;
              });
              setDaysListFor0thIndex(filterdDays);
            }
          }
          if (item_limit_per_slot.length > 1) {
            const newData = item_limit_per_slot
              .slice(1)
              .map((item: any, index: number) => ({
                id: index + 1,
                limitPerUser: item.limit,
                productsList: selectedProuctsForIteration(
                  item.products,
                  initialProductsList
                ),
                startTime: item.from_time,
                endTime: item.to_time,
                all: item.products.length === initialProductsList.length,
                everyDay: item.days.length === initialDaysList.length,
                collectedProductIds: item.products,
                collectedDays: item.days,
                daysList: selectedDaysForIteration(item.days, initialDaysList),
              }));
            setLimitOrderPerItemList(newData);
          }
        }
        if (order_limit_per_slot) {
          const { days, from_time, limit, to_time } = order_limit_per_slot;

          formik.setFieldValue("limitOrdersPerSlot", limit);
          if (from_time) {
            formik.setFieldValue("limitOrdersPerSlotFromTime", from_time);
          } else {
            formik.setFieldValue("limitOrdersPerSlotFromTime", "");
          }

          if (to_time) {
            formik.setFieldValue("limitOrdersPerSlotToTime", to_time);
          } else {
            formik.setFieldValue("limitOrdersPerSlotToTime", "");
          }
          if (days) {
            formik.setFieldValue("limitOrdersPerSlotDay", days);
            setAllCheckboxesForLimitOrdersPerSlot(
              days.length === daysListForLimitOrdersPerSlot.length
            );
            const filterdDays = daysListForLimitOrdersPerSlot.map(
              (each: any) => {
                if (days.includes(each.day)) {
                  return { ...each, isChecked: true };
                }
                return each;
              }
            );
            setDaysListForLimitOrdersPerSlot(filterdDays);
          }
        }

        if (delivery_time) {
          const { hours, minutes } =
            convertMinutesToHoursAndMinutes(delivery_time);
          formik.setFieldValue("orderPricingHr", hours);
          formik.setFieldValue("orderPricingMin", minutes);
        } else {
          formik.setFieldValue("orderPricingHr", "0");
          formik.setFieldValue("orderPricingMin", "0");
        }

        formik.setFieldValue("orderPricingRules", enable_order_pricing_rule);
        formik.setFieldValue("orderPricingAmount", order_amount);

        if (channels) {
          const { channel_1, channel_2, channel_3 } = channels;
          if (channel_1) {
            const { hours, minutes } =
              convertMinutesToHoursAndMinutes(channel_1);
            formik.setFieldValue("channel1Hr", hours);
            formik.setFieldValue("channel1Min", minutes);
          } else {
            formik.setFieldValue("channel1Hr", "0");
            formik.setFieldValue("channel1Min", "0");
          }

          if (channel_2) {
            const { hours, minutes } =
              convertMinutesToHoursAndMinutes(channel_2);
            formik.setFieldValue("channel2Hr", hours);
            formik.setFieldValue("channel2Min", minutes);
          } else {
            formik.setFieldValue("channel2Hr", "0");
            formik.setFieldValue("channel2MIn", "0");
          }

          if (channel_3) {
            const { hours, minutes } =
              convertMinutesToHoursAndMinutes(channel_3);
            formik.setFieldValue("channel3Hr", hours);
            formik.setFieldValue("channel3Min", minutes);
          } else {
            formik.setFieldValue("channel3Hr", "");
            formik.setFieldValue("channel3MIn", "");
          }
        }
        const { order_type } = preparation_time;
        const { collection, delivery, dine_in } = order_type;
        if (collection) {
          const { hours, minutes } =
            convertMinutesToHoursAndMinutes(collection);
          formik.setFieldValue("collectionHr", hours);
          formik.setFieldValue("collectionMin", minutes);
        } else {
          formik.setFieldValue("collectionHr", "");
          formik.setFieldValue("collectionMin", "");
        }

        if (dine_in) {
          const { hours, minutes } = convertMinutesToHoursAndMinutes(dine_in);
          formik.setFieldValue("dineInHr", hours);
          formik.setFieldValue("dineInMin", minutes);
        } else {
          formik.setFieldValue("dineInHr", "");
          formik.setFieldValue("dineInMin", "");
        }
        if (delivery) {
          const { hours, minutes } = convertMinutesToHoursAndMinutes(delivery);
          formik.setFieldValue("deliveryHr", hours);
          formik.setFieldValue("deliveryMin", minutes);
        } else {
          formik.setFieldValue("deliveryHr", "");
          formik.setFieldValue("deliveryMin", "");
        }
      }
    }
  }, [kitchenSettingsData, initialProductsList]);

  const newOrderPerItemList = {
    id: limitOrderPerItemList.length + 1,
    limitPerUser: "",
    productsList: productsList,
    startTime: "",
    endTime: "",
    all: false,
    everyDay: false,
    collectedProductIds: [],
    collectedDays: [],
    daysList: [
      {
        id: 1,
        day: "Monday",
        text: t("devices.monday"),
        isChecked: false,
      },
      {
        id: 2,
        day: "Tuesday",
        text: t("devices.tuesday"),
        isChecked: false,
      },
      {
        id: 3,
        day: "Wednesday",
        text: t("devices.wednesday"),
        isChecked: false,
      },
      {
        id: 4,
        day: "Thursday",
        text: t("devices.thursday"),
        isChecked: false,
      },
      {
        id: 5,
        day: "Friday",
        text: t("devices.friday"),
        isChecked: false,
      },
      {
        id: 6,
        day: "Saturday",
        text: t("devices.saturday"),
        isChecked: false,
      },
      {
        id: 7,
        day: "Sunday",
        text: t("devices.sunday"),
        isChecked: false,
      },
    ],
  };

  const [updateKitchenSettings] = useMutation(
    UPDATE_KITCHEN_CAPACITY_SETTINGS,
    {
      onCompleted: (data) => {
        if (data) {
          const { branchSettings } = data;
          const { success } = branchSettings;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "businessClient" },
      refetchQueries: [GET_UPDATED_DATA_FOR_KITCHEN_SETTINGS],
    }
  );

  useEffect(() => {
    formik.setFieldValue("limitItemPerSlotList", limitOrderPerItemList);
  }, [limitOrderPerItemList]);

  const [daysListFor0thIndex, setDaysListFor0thIndex] = useState<any[]>([
    {
      id: 1,
      day: "Monday",
      text: t("devices.monday"),
      isChecked: false,
    },
    {
      id: 2,
      day: "Tuesday",
      text: t("devices.tuesday"),
      isChecked: false,
    },
    {
      id: 3,
      day: "Wednesday",
      text: t("devices.wednesday"),
      isChecked: false,
    },
    {
      id: 4,
      day: "Thursday",
      text: t("devices.thursday"),
      isChecked: false,
    },
    {
      id: 5,
      day: "Friday",
      text: t("devices.friday"),
      isChecked: false,
    },
    {
      id: 6,
      day: "Saturday",
      text: t("devices.saturday"),
      isChecked: false,
    },
    {
      id: 7,
      day: "Sunday",
      text: t("devices.sunday"),
      isChecked: false,
    },
  ]);
  const [daysListForLimitOrdersPerSlot, setDaysListForLimitOrdersPerSlot] =
    useState<any[]>([
      {
        id: 1,
        day: "Monday",
        text: t("devices.monday"),
        isChecked: false,
      },
      {
        id: 2,
        day: "Tuesday",
        text: t("devices.tuesday"),
        isChecked: false,
      },
      {
        id: 3,
        day: "Wednesday",
        text: t("devices.wednesday"),
        isChecked: false,
      },
      {
        id: 4,
        day: "Thursday",
        text: t("devices.thursday"),
        isChecked: false,
      },
      {
        id: 5,
        day: "Friday",
        text: t("devices.friday"),
        isChecked: false,
      },
      {
        id: 6,
        day: "Saturday",
        text: t("devices.saturday"),
        isChecked: false,
      },
      {
        id: 7,
        day: "Sunday",
        text: t("devices.sunday"),
        isChecked: false,
      },
    ]);

  useEffect(() => {}, [t]);

  const [productsListFor0thIndex, setProductsListFor0thIndex] = useState<any[]>(
    []
  );

  const formik = useFormik({
    initialValues: initialValuesOfTextFields,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      const mergedLimitItemPerSlot = [
        {
          limitPerUser: formik.values.LimitItemPerSlot0thIndex,
          startTime: formik.values.startTimePerSlot0thIndex,
          endTime: formik.values.endTimePerSlot0thIndex,
          collectedProductIds: formik.values.ProductsIdsPerSlot0thIndex,
          collectedDays: formik.values.dayPerSlot0thIndex,
        },
        ...formik.values.limitItemPerSlotList,
      ];
      const data = {
        preparation_time: {
          order_type: {
            dine_in:
              parseInt(formik.values.dineInHr ?? "0") * 60 +
              parseInt(formik.values.dineInMin ?? "0"),
            collection:
              parseInt(formik.values.collectionHr ?? "0") * 60 +
              parseInt(formik.values.collectionMin ?? "0"),
            delivery:
              parseInt(formik.values.deliveryHr ?? "0") * 60 +
              parseInt(formik.values.deliveryMin ?? "0"),
          },
        },
        channels: {
          channel_1:
            parseInt(formik.values.channel1Hr ?? "0") * 60 +
            parseInt(formik.values.channel1Min ?? "0"),
          channel_2:
            parseInt(formik.values.channel2Hr ?? "0") * 60 +
            parseInt(formik.values.channel2Min ?? "0"),
          channel_3:
            parseInt(formik.values.channel3Hr ?? "0") * 60 +
            parseInt(formik.values.channel3Min ?? "0"),
        },
        enable_order_pricing_rule: formik.values.orderPricingRules,
        order_amount: formik.values.orderPricingRules
          ? formik.values.orderPricingAmount
          : null,
        delivery_time: formik.values.orderPricingRules
          ? parseInt(formik.values.orderPricingHr ?? "0") * 60 +
            parseInt(formik.values.orderPricingMin ?? "0")
          : null,
        item_limit_per_slot: mergedLimitItemPerSlot.map((each: any) => ({
          limit: each.limitPerUser,
          from_time: each.startTime,
          to_time: each.endTime,
          products: each.collectedProductIds,
          days: each.collectedDays,
        })),
        order_limit_per_slot: {
          limit: formik.values.limitOrdersPerSlot,
          from_time: formik.values.limitOrdersPerSlotFromTime,
          to_time: formik.values.limitOrdersPerSlotToTime,

          days: formik.values.limitOrdersPerSlotDay,
        },
      };
      updateKitchenSettings({
        variables: {
          storeId: +locationId,
          kitchenCapacitySettings: JSON.stringify(data),
        },
      });
    },
  });

  const handleProductIdsFor0thIndex = (id: string) => {
    const filteredProductsList = productsListFor0thIndex.map((each: any) => {
      if (each.id === id) {
        return {
          ...each,
          isChecked: !each.isChecked,
        };
      }
      return each;
    });
    setProductsListFor0thIndex(filteredProductsList);
    const collectedIdsFor0thIndex = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => +each.id);
    formik.setFieldValue("ProductsIdsPerSlot0thIndex", collectedIdsFor0thIndex);

    setAllCheckboxCheckedFor0thIndex(
      collectedIdsFor0thIndex.length === filteredProductsList.length
    );
  };

  const handleDaysFor0thIndex = (id: string) => {
    const filteredDaysList = daysListFor0thIndex.map((each: any) => {
      if (each.id === id) {
        return {
          ...each,
          isChecked: !each.isChecked,
        };
      }
      return each;
    });
    setDaysListFor0thIndex(filteredDaysList);
    const collectedIdsFor0thIndex = filteredDaysList
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.day);
    formik.setFieldValue("dayPerSlot0thIndex", collectedIdsFor0thIndex);
    setAllCheckboxesForDays0thIndex(
      collectedIdsFor0thIndex.length === filteredDaysList.length
    );
  };

  const handleDaysForLimitOrdersPerSlot = (id: string) => {
    const filteredDaysList = daysListForLimitOrdersPerSlot.map((each: any) => {
      if (each.id === id) {
        return {
          ...each,
          isChecked: !each.isChecked,
        };
      }
      return each;
    });
    setDaysListForLimitOrdersPerSlot(filteredDaysList);
    const collectedIdsFor0thIndex = filteredDaysList
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.day);
    formik.setFieldValue("limitOrdersPerSlotDay", collectedIdsFor0thIndex);
    setAllCheckboxesForLimitOrdersPerSlot(
      collectedIdsFor0thIndex.length === filteredDaysList.length
    );
  };

  const handleAddNewButton = () => {
    setLimitOrderPerItemList((prev) => [...prev, newOrderPerItemList]);
  };
  const handleDeleteOrderItem = (id: string) => {
    const filteredRows = limitOrderPerItemList.filter((each) => each.id !== id);
    setLimitOrderPerItemList(filteredRows);
  };

  const handleAllProductsFor0thIndex = (checked: boolean) => {
    setAllCheckboxCheckedFor0thIndex(checked);
    const filteredProductsList = productsListFor0thIndex.map((each: any) => {
      return {
        ...each,
        isChecked: checked,
      };
    });
    setProductsListFor0thIndex(filteredProductsList);
    const collectedIdsFor0thIndex = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => +each.id);
    formik.setFieldValue("ProductsIdsPerSlot0thIndex", collectedIdsFor0thIndex);
  };

  const handleAllDaysFor0thIndex = (checked: boolean) => {
    setAllCheckboxesForDays0thIndex(checked);
    const filteredProductsList = daysListFor0thIndex.map((each: any) => {
      return {
        ...each,
        isChecked: checked,
      };
    });
    setDaysListFor0thIndex(filteredProductsList);
    const collectedIdsFor0thIndex = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.day);
    formik.setFieldValue("dayPerSlot0thIndex", collectedIdsFor0thIndex);
  };
  const handleAllDaysForLimitOrdersPerSlot = (checked: boolean) => {
    setAllCheckboxesForLimitOrdersPerSlot(checked);
    const filteredProductsList = daysListForLimitOrdersPerSlot.map(
      (each: any) => {
        return {
          ...each,
          isChecked: checked,
        };
      }
    );
    setDaysListForLimitOrdersPerSlot(filteredProductsList);
    const collectedIdsFor0thIndex = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.day);
    formik.setFieldValue("limitOrdersPerSlotDay", collectedIdsFor0thIndex);
  };

  const handleAllProductsForIteration = (index: number, checked: boolean) => {
    const filteredProductsList = limitOrderPerItemList[index].productsList.map(
      (each: any) => {
        return {
          ...each,
          isChecked: checked,
        };
      }
    );

    const collectedIds = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => +each.id);

    limitOrderPerItemList[index].productsList = filteredProductsList;
    limitOrderPerItemList[index].all = checked;
    limitOrderPerItemList[index].collectedProductIds = collectedIds;
    setLimitOrderPerItemList([...limitOrderPerItemList]);
  };

  const handleCheckboxForIndividualProductsCheck = (
    index: number,
    id: string,
    checked: boolean
  ) => {
    const filteredProductsList = limitOrderPerItemList[index].productsList.map(
      (each: any) => {
        if (each.id === id) {
          return {
            ...each,
            isChecked: checked,
          };
        }
        return each;
      }
    );
    const collectedIds = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => +each.id);

    limitOrderPerItemList[index].productsList = filteredProductsList;
    limitOrderPerItemList[index].all =
      collectedIds.length === filteredProductsList.length;
    limitOrderPerItemList[index].collectedProductIds = collectedIds;
    setLimitOrderPerItemList([...limitOrderPerItemList]);
  };

  const handleAllDaysForIteration = (index: number, checked: boolean) => {
    const filteredProductsList = limitOrderPerItemList[index].daysList.map(
      (each: any) => {
        return {
          ...each,
          isChecked: checked,
        };
      }
    );

    const collectedIds = filteredProductsList
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.day);

    limitOrderPerItemList[index].daysList = filteredProductsList;
    limitOrderPerItemList[index].everyDay = checked;
    limitOrderPerItemList[index].collectedDays = collectedIds;
    setLimitOrderPerItemList([...limitOrderPerItemList]);
  };

  const handleIndividualForDays = (
    index: number,
    id: string,
    checked: boolean
  ) => {
    // Create a deep copy of the limitOrderPerItemList
    const updatedLimitOrderPerItemList = limitOrderPerItemList.map(
      (item, i) => {
        if (i === index) {
          // Create a copy of the item to update
          const updatedDaysList = item.daysList.map((each: any) => {
            if (each.id.toString() === id.toString()) {
              return {
                ...each,
                isChecked: checked,
              };
            }
            return each;
          });

          const collectedIds = updatedDaysList
            .filter((each: any) => each.isChecked)
            .map((each: any) => each.day);

          return {
            ...item,
            daysList: updatedDaysList,
            everyDay: collectedIds.length === updatedDaysList.length,
            collectedDays: collectedIds,
          };
        }

        return item;
      }
    );

    // Update the state with the new list
    setLimitOrderPerItemList(updatedLimitOrderPerItemList);
  };

  const handleStartTimeForIteration = (index: number, time: string) => {
    const updatedLimitOrderPerItemList = limitOrderPerItemList.map(
      (item, i) => {
        if (i === index) {
          // Create a copy of the item to update
          return {
            ...item,
            startTime: time,
          };
        }
        return item;
      }
    );
    setLimitOrderPerItemList(updatedLimitOrderPerItemList);
  };

  const handleEndTimeForIteration = (index: number, time: string) => {
    const updatedLimitOrderPerItemList = limitOrderPerItemList.map(
      (item, i) => {
        if (i === index) {
          // Create a copy of the item to update
          return {
            ...item,
            endTime: time,
          };
        }
        return item;
      }
    );
    setLimitOrderPerItemList(updatedLimitOrderPerItemList);
  };

  const handleLimitPerUserForIteration = (index: number, text: string) => {
    const updatedLimitOrderPerItemList = limitOrderPerItemList.map(
      (item, i) => {
        if (i === index) {
          // Create a copy of the item to update
          return {
            ...item,
            limitPerUser: text,
          };
        }
        return item;
      }
    );
    setLimitOrderPerItemList(updatedLimitOrderPerItemList);
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "15px",
            padding: "5px 35px 5px 3px",
            marginBottom: "20px",
          }}
        >
          <Typography></Typography>
          <Typography className="create-a-use-role-heading">
            {t("devices.kitchen_capacity_settings")}
          </Typography>

          <Button
            type="submit"
            sx={{ textTransform: "none" }}
            variant="contained"
            color="warning"
          >
            {t("buttons.save")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          marginTop: "5px",
        }}
      >
        <Paper sx={{ padding: "1%", width: "95%", boxShadow: 0.5 }}>
          <ManualQuotingTime formik={formik} />
        </Paper>
        <Paper
          sx={{
            padding: "1%",
            marginTop: "2%",
            width: "95%",
            marginBottom: "10px",
          }}
        >
          <KdsSettingsOrders
            handleLimitPerUserForIteration={handleLimitPerUserForIteration}
            allCheckboxesForLimitOrdersPerSlot={
              allCheckboxesForLimitOrdersPerSlot
            }
            handleDaysForLimitOrdersPerSlot={handleDaysForLimitOrdersPerSlot}
            daysListForLimitOrdersPerSlot={daysListForLimitOrdersPerSlot}
            handleEndTimeForIteration={handleEndTimeForIteration}
            handleStartTimeForIteration={handleStartTimeForIteration}
            handleAllDaysForIteration={handleAllDaysForIteration}
            handleIndividualForDays={handleIndividualForDays}
            handleCheckboxForIndividualProductsCheck={
              handleCheckboxForIndividualProductsCheck
            }
            handleAllDaysForLimitOrdersPerSlot={
              handleAllDaysForLimitOrdersPerSlot
            }
            handleAllProductsForIteration={handleAllProductsForIteration}
            handleAllDaysFor0thIndex={handleAllDaysFor0thIndex}
            allCheckboxesForDays0thIndex={allCheckboxesForDays0thIndex}
            handleAllProductsFor0thIndex={handleAllProductsFor0thIndex}
            allCheckboxCheckedFor0thIndex={allCheckboxCheckedFor0thIndex}
            handleDeleteOrderItem={handleDeleteOrderItem}
            handleAddNewButton={handleAddNewButton}
            limitOrderPerItemList={limitOrderPerItemList}
            daysListFor0thIndex={daysListFor0thIndex}
            handleDaysFor0thIndex={handleDaysFor0thIndex}
            handleProductIdsFor0thIndex={handleProductIdsFor0thIndex}
            productsListFor0thIndex={productsListFor0thIndex}
            formik={formik}
          />
        </Paper>
      </Box>
    </form>
  );
};

export default GeneralSettingsFromDevice;
