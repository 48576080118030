import React, { useEffect, useState } from "react";
import {
  Button,
  Paper,
  Typography,
  Divider,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Box,
} from "@mui/material";
import { USER_ACCESs_UPDATE_PERMISSIONS } from "../../graphicalQl/mutation/userProfileMutation";
import {
  TWO_STEP_VERIFICATION_PERMISSION_LIST,
  No_OF_PERMISSIONS,
} from "../../graphicalQl/usequery/userProfileUseQuery";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader";
import { useDispatch, useSelector } from "react-redux";
import Modal from "@mui/material/Modal";
import "./userAccess.css";
import { setUserAccessStore } from "../../store";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
};

const UserAccess: React.FC = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { arrayAreEqual, open } = useSelector((state: any) => state.UserAccess);
  function toPascalCase(str: any) {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  }

  const [updateUserPermissions] = useMutation(USER_ACCESs_UPDATE_PERMISSIONS, {
    onCompleted: (data) => {
      if (data) {
        const { twoStepVerificationPermissions } = data;
        if (twoStepVerificationPermissions) {
          const { success } = twoStepVerificationPermissions;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [TWO_STEP_VERIFICATION_PERMISSION_LIST],
  });

  const [totalNumberOfPermissions, setTotalNumberOfPermissions] = useState<
    any[]
  >([]);
  //getting number of permissions
  const {
    data: numberOfPermissionsData,
    loading: numberOfPermissionsLoading,
    error: numberOfPermissionsError,
  } = useQuery(No_OF_PERMISSIONS, {
    context: { clientName: "userClient" },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const [idsFromServer, setIdsFromServer] = useState<any[]>([]);

  useEffect(() => {
    if (numberOfPermissionsData) {
      if (numberOfPermissionsData.permissions) {
        const { permissions } = numberOfPermissionsData;
        const requiredDataList = permissions.edges.map((each: any) => ({
          name: each.node.name,
          appliesFor: each.node.appliesFor,
          permissionId: each.node.permissionId,
          isChecked: false,
        }));
        setTotalNumberOfPermissions(requiredDataList);
      }
    }
  }, [numberOfPermissionsData]);

  const {
    data: updatedPermissionsData,
    loading: updatedPermissionsLoading,
    error: updatedPermissionsError,
  } = useQuery(TWO_STEP_VERIFICATION_PERMISSION_LIST, {
    context: { clientName: "userClient" },
    variables: { businessId: +businessId },
    onError: (error) => {
      console.error("Error:", error);
    },
  });
  useEffect(() => {
    if (updatedPermissionsData) {
      if (updatedPermissionsData.twoStepVerificationList) {
        const { permissionsList } =
          updatedPermissionsData.twoStepVerificationList;
        const parsedPermissionsList = JSON.parse(permissionsList);

        setIdsFromServer(parsedPermissionsList);
        setGettingUniqueIdsFromUpdate(parsedPermissionsList);
      }
    }
  }, [updatedPermissionsData]);

  const [gettingUniqueIdsFromUpdate, setGettingUniqueIdsFromUpdate] = useState<
    any[]
  >([]);

  const handleChangeOnPosPermissions = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    // setTotalNumberOfPermissions((prevData: any) =>
    //   prevData.map((item: any) => (item.permissionId === id ? { ...item, isChecked: e.target.checked } : item)))
    setGettingUniqueIdsFromUpdate((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(+id)) {
        return [...prevIds, +id];
      } else if (!e.target.checked && prevIds.includes(+id)) {
        return prevIds.filter((prevId: any) => prevId !== +id);
      }
      return prevIds;
    });
  };

  const handleSubmitButton = () => {
    // const selectedIds = totalNumberOfPermissions.filter((each: any) => each.isChecked).map((each: any) => +each.permissionId)
    updateUserPermissions({
      variables: {
        permissionsList: JSON.stringify(gettingUniqueIdsFromUpdate),
        businessId: +businessId,
      },
    });
  };

  const handleSubmitButtonPopup = () => {
    dispatch(setUserAccessStore("open", false));
    updateUserPermissions({
      variables: {
        businessId: +businessId,
        permissionsList: JSON.stringify(gettingUniqueIdsFromUpdate),
      },
    });
  };

  const handleInternalNavigation = (
    event: React.MouseEvent<HTMLAnchorElement>
  ) => {
    if (interactionDetected && !arrayAreEqual) {
      event.preventDefault(); // Prevent the default navigation behavior
      dispatch(setUserAccessStore("open", true));
    }
  };

  const [interactionDetected, setInteractionDetected] = useState(false);
  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (!arrayAreEqual) {
        event.preventDefault();
        event.returnValue =
          "You have unsaved changes. Are you sure you want to leave?";
      }
    };

    const handlePopstate = (e: PopStateEvent) => {
      if (!arrayAreEqual) {
        e.preventDefault();
        dispatch(setUserAccessStore("open", true));
      }
    };

    const handleClick = () => {
      setInteractionDetected(true);
    };

    window.addEventListener("beforeunload", handleBeforeUnload);
    window.addEventListener("popstate", handlePopstate);
    document.addEventListener("click", handleClick);
    document.addEventListener("touchstart", handleClick);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      window.removeEventListener("popstate", handlePopstate);
      document.removeEventListener("click", handleClick);
      document.removeEventListener("touchstart", handleClick);
    };
  }, [arrayAreEqual]);

  useEffect(() => {
    function areArraysEqual(arr1: any, arr2: any) {
      if (arr1.length !== arr2.length) {
        return false;
      }
      const sortedArr1 = arr1.slice().sort();
      const sortedArr2 = arr2.slice().sort();
      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }
      return true;
    }

    if (!areArraysEqual(idsFromServer, gettingUniqueIdsFromUpdate)) {
      dispatch(setUserAccessStore("arrayAreEqual", false));
    } else {
      dispatch(setUserAccessStore("arrayAreEqual", true));
    }
  }, [gettingUniqueIdsFromUpdate, idsFromServer, dispatch]);
  const handleClose = () => {
    dispatch(setUserAccessStore("open", false));
  };

  const updatedList = totalNumberOfPermissions.map((each: any) => {
    if (gettingUniqueIdsFromUpdate.includes(+each.permissionId)) {
      return { ...each, isChecked: true };
    }
    return each;
  });

  if (updatedPermissionsLoading) return <CircularLoader />;

  return (
    <Box
      sx={{
        margin: "1%",
      }}
    >
      <Box className="user-access-header-order">
        <Box>
          <Typography className="user-access-checkbox-heading">
            {t("users.two_step")}
          </Typography>
          <Typography className="user-access-checkbox-paragraph">
            {t("users.enable_user_authentication")}
          </Typography>
        </Box>

        <Button
          disabled={arrayAreEqual}
          onClick={handleSubmitButton}
          sx={{ textTransform: "none" }}
          className={
            arrayAreEqual
              ? "user-access-save-button-disable"
              : "user-access-save-button-enable"
          }
          variant="contained"
        >
          {t("buttons.save")}
        </Button>
      </Box>

      <Divider />

      {/* <Box
      >
        <Paper sx={{ width: "45%", padding: "15px" }}>
          <FormGroup sx={{ padding: "10px" }}>
            {updatedList.map((each: any) => (
              <FormControlLabel
                key={each.permissionId}
                checked={each.isChecked}
                control={
                  <Checkbox
                    onChange={(e) => {
                      handleChangeOnPosPermissions(e, each.permissionId);
                    }}
                    color="warning"
                  />
                }
                label={
                  <Typography className="user-access-checkbox-label">
                    {toPascalCase(each.name)}
                  </Typography>
                }
                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
              />
            ))}
          </FormGroup>
        </Paper>

      </Box> */}

      <Box>
        <Paper sx={{ width: "100%", padding: "15px" }}>
          <FormGroup
            sx={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: "10px",
              padding: "10px",
            }}
          >
            {updatedList.map((each: any) => (
              <Box
                key={each.permissionId}
                sx={{
                  padding: "10px",
                  // border: "1px solid lightgrey",
                  // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  // borderRadius: "6px",
                }}
              >
                <FormControlLabel
                  checked={each.isChecked}
                  control={
                    <Checkbox
                      onChange={(e) => {
                        handleChangeOnPosPermissions(e, each.permissionId);
                      }}
                      color="warning"
                    />
                  }
                  label={
                    <Typography className="user-access-checkbox-label">
                      {toPascalCase(each.name)}
                    </Typography>
                  }
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                />
              </Box>
            ))}
          </FormGroup>
        </Paper>
      </Box>

      <Box>
        <Modal
          open={open}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                className="user-access-popup-save-text"
                sx={{ margin: "20px" }}
                id="modal-modal-title"
                variant="h6"
                component="h2"
              >
                Save Changes
              </Typography>
              <Box
                sx={{
                  width: "100%",
                  margin: "20px",
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                <Button
                  onClick={handleClose}
                  className="user-access-popup-dont-save-button"
                  color="warning"
                  sx={{ textTransform: "none" }}
                >
                  Don't Save
                </Button>
                <Button
                  onClick={handleSubmitButtonPopup}
                  className="user-access-popup-save-button"
                  sx={{ textTransform: "none", width: "35%" }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        </Modal>
      </Box>
    </Box>
  );
};

export default UserAccess;
