import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import OutletTypeSelection from "../outletTypeSelection";
import DetailsImage from "../../../../../assets/bussinessTypeSelection.png";
import StoreShpper from "../../../../../assets/store-shopper-svgrepo-com 1.png";
import HomeShpper from "../../../../../assets/homeShpper.png";
import CheckIcon from "../../../../../assets/check-with-bg.png";
import { useNavigation } from "../../StepperNavigationContext/useNavigation";
import ForwardArrow from "../../../../../assets/arrow-forward.png";
import { useMutation } from "@apollo/client";
import { UPDATE_BUSINESS_IN_CREATE } from "../../../../../graphicalQl/mutation/signUp";

interface Props {
  setBusinessType: any
}

const BusinessTypeSelection = (props: Props) => {
  const { setBusinessType } = props
  const [outletType, setOutletType] = useState(false);
  const [businessTypeSelection, setBusinessTypeSelection] = useState("");

  const { setStepperCounter, stepperCounter } = useNavigation();

  const handleNext = () => {
    setOutletType(true);
    setStepperCounter(stepperCounter + 1);
  };

  const handleBack = () => {
    setStepperCounter(stepperCounter - 1);
    setBusinessType()
  }

  const getSelectionHandler = (type: any) => {
    setBusinessTypeSelection(type);
  };


  const [updateBusinessType] = useMutation(UPDATE_BUSINESS_IN_CREATE, { context: { clientName: "businessClient" } })
  const handleApiCall = () => {
    handleNext()
    updateBusinessType({
      variables: {
        businessId: sessionStorage.getItem("newBusiness") || 5,
        businessType: businessTypeSelection
      },
      onCompleted: () => {
        // handleNext()

      }

    })
  }

  return (
    <>
      {outletType ? (
        <OutletTypeSelection setOutletType={setOutletType} />
      ) : (
        <Box
          marginTop="16px"
          textAlign="center"
          paddingX={{ sm: "20px", xs: "12px" }}
          marginBottom={"2vh"}
        >
          <Box
            sx={{
              backgroundColor: "#FBFBFB",
              paddingY: "2vh",
              paddingX: { sm: "2vh", xs: "2vh" },
              borderRadius: "8px",
              marginTop: "2vh",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: { xs: "24px", sm: "24px !important" },
                fontWeight: "600",
                color: "#333333",
                lineHeight: { md: "24px", xs: "24px" },
                letterSpacing: "0.5px",
              }}
            >
              Select Your Business Type
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px !important",
                color: "#707070",
                lineHeight: { md: "24px", xs: "32px" },
                letterSpacing: "0.5px",
                marginTop: "12px",
              }}
            >
              Personalize your account according to your business type by
              entering the required information below.
            </Typography>

            <Box
              display="flex"
              justifyContent={{ lg: "space-between", xs: "center" }}
              alignItems={"center"}
              gap="2vh"
              mt="2vh"
              mx="auto"
              maxWidth={{ lg: "1059px", xs: "100%" }}
              flexWrap={{ lg: "nowrap", xs: "wrap" }}
            >
              <Box maxWidth={"420px"} width={"100%"} height={"45vh"}>
                <img width="100%" style={{ height: "100%" }} src={DetailsImage} alt="DetailsImage" />
              </Box>
              <Box>
                {["food", "retail"].map((type, index) => (
                  <Box
                    className="customBoxShadow"
                    key={index}
                    onClick={() => getSelectionHandler(type)}
                    border="1px solid #F38B08"
                    sx={{
                      height: "20vh",
                      mt: type === "retail" ? "2vh" : "",
                      width: { xs: "100%", md: "278px" },
                      backgroundColor:
                        businessTypeSelection === type ? "#FFF8F0" : "",
                      cursor: "pointer",
                      position: "relative",
                      borderRadius: "4px",
                      border: "1px solid #F38B08",
                    }}
                  >
                    {businessTypeSelection === type && (
                      <Box position="absolute" top="6.67px" right="6.67px">
                        <img src={CheckIcon} alt="CheckIcon" />
                      </Box>
                    )}
                    <Box pt="2vh" pb="2vh" mx="auto">
                      <img
                        src={type === "food" ? StoreShpper : HomeShpper}
                        alt={type}
                        style={{ height: "10vh" }}
                      />
                    </Box>
                    <Typography
                      fontFamily="poppins"
                      fontSize="20px"
                      fontWeight="500"
                      lineHeight="30px"
                      color="#F38B08"
                      px="2vh"
                    >
                      {type === "food" ? "Food & Beverages" : "Retail"}
                    </Typography>
                  </Box>
                ))}
              </Box>
            </Box>

            <Box
              display="flex"
              mt={{ xs: "2vh", md: "2vh" }}
              mb="2vh"
              width="100%"
              maxWidth="1360px"
              mx="auto"
              gap="20px"
              justifyContent={{ xs: "center", md: "end" }}
            >
              <Button

                // clickHandler={handleNext}

                // customImage={ForwardArrow}
                sx={{
                  color: "#707070",
                  fontSize: '14px', backgroundColor: "#ffffff",
                  padding: '0px 24px',
                  textTransform: "none"
                }}
                onClick={handleBack}
              >
                Back
              </Button>
              <Button

                onClick={handleApiCall}
                className="main-button"
              >Continue
              </Button >
            </Box>
          </Box>
        </Box>
      )}
    </>
  );
};

export default BusinessTypeSelection;
