export interface Plan {
  name: string;
  price: string;
  description: string;
  buttonText: string;
  offer: string;
  features: string[];
  custom?: string;
  active?: string;
  recommended?: string;
  activePlan?: boolean;
}
// paymentCardInfo.tsx
export interface ProfilePlan {
  name: string;
  price: string;
  description: string;
  buttonText: string;
  offer: string;
  features: string[];
  custom?: string;
  active?: string;
  recommended?: string;
  activePlan?: boolean;
}

export const Monthlyplans: Plan[] = [
  {
    name: "Free",
    price: "£0",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Free pack includes:",
    activePlan : false,
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Silver",
    price: "£9",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Gold ",
    price: "£19",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    price: "£39",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    recommended: "recommended",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Custom ",
    price: "",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",

    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];

export const Weeklyplans = [
  {
    name: "Free",
    price: "£0",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Free pack includes:",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
    activePlan: false,
  },
  {
    name: "Silver",
    price: "£19",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Gold ",
    price: "£119",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    recommended: "recommended",

    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    price: "£139",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Custom ",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",
    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];

export const Yearlyplans = [
  {
    name: "Free",
    price: "£0",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Free pack includes:",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Silver",
    price: "£29",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Gold ",
    price: "£219",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    price: "£239",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    recommended: "recommended",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Custom ",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",
    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];

export const ProfileYearlyplans = [
  {
    name: "Silver",
    active: "Active Plan",
    price: "£29",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
    activePlan: true,
  },
  {
    name: "Gold ",
    active: "Active Plan",
    price: "£219",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    active: "Active Plan",
    price: "£239",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    recommended: "recommended",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Custom ",
    active: "Active Plan",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",
    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];
export const ProfileMonthlyplans = [
  {
    name: "Silver",
    active: "Active Plan",
    price: "£19",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
    activePlan: true,
  },
  {
    name: "Gold ",
    active: "Active Plan",
    price: "£119",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    active: "Active Plan",
    price: "£139",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    recommended: "recommended",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Custom ",
    active: "Active Plan",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",
    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];
export const ProfileWeeklyplans = [
  {
    name: "Silver",
    active: "Active Plan",
    price: "£9",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
    activePlan: true,
  },
  {
    name: "Gold ",
    active: "Active Plan",
    price: "£19",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get this Plan now",
    offer: "Everything in Free plus:",
    features: [
      "Custom Web Design",
      "KDS App",
      "Driver App",
      "Kiosk App",
      "Restaurant Website/Mobile App",
      "Epos (Digital and QR Menu)",
      "Admin App",
    ],
  },
  {
    name: "Platinum ",
    active: "Active Plan",
    price: "£39",
    description: "Per Year (Per terminal/Per User/Per store)",
    buttonText: "Get Started",
    offer: "Everything in Free plus:",
    recommended: "recommended",
    features: [
      "Epos (Digital and QR Menu)",
      "Admin App",
      "Restaurant Website/Mobile App",
    ],
  },
  {
    name: "Custom ",
    active: "Active Plan",
    custom: "Custom Pricing",
    description: "Make your own package",
    buttonText: "Get a Quote",
    offer: "Everything in Free plus:",
    features: [
      "Get a Custom Quotefor customised package tailored for your business needs.",
    ],
  },
];