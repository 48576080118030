import { gql } from "@apollo/client";

export const CREATE_BULK=gql`
mutation bulkCreateProducts($businessId:Int!,$items:JSONString){
    bulkCreateProducts(
        businessId:$businessId
        items:$items
    ) {
            message
            
        success
      }
    }
`

export const UPDATE_BULK_PRODUCTS=gql`
mutation  bulkUpdateProducts($businessId:Int!,$items:JSONString){
    bulkUpdateProducts(
        businessId:$businessId
        items:$items
    ) {
            message
            
        success
      }
    }
`