import { gql } from "@apollo/client";

export const DISCOUNT_LIST = gql`
query Discounts($businessId:ID,$name_Icontains:String,$isPremadeDiscount:Boolean,$isSelfmadeDiscount:Boolean,$location:String,$first:Int,$after:String,$before:String,$last:Int){
	discounts(businessId:$businessId,name_Icontains:$name_Icontains,isPremadeDiscount:$isPremadeDiscount,isSelfmadeDiscount:$isSelfmadeDiscount,location:$location,first:$first,after:$after,before:$before,last:$last){
	totalCount   
  pageInfo{hasNextPage,startCursor,endCursor,hasPreviousPage}
	edges{node{discountId
	image
		discountStatus
			image
		applyToAllItems
		orderType
		orderAvailability
		paymentType
		deliveryZone
		discountUsageType
couponCode
startDate
		endDate
		name
		amount
		discountType
		reason{
			reason
		}
		discountType
		applyDiscountTo
		
	}}
	
	}
}
`
export const DISCOUNT_REASONS = gql`
query DiscountReasons($businessId:ID,$reasonType:String){
	discountReasons(businessId:$businessId,reasonType:$reasonType){
	edges{node{reasonId
	reason
	isSelected
}}
	
	}
}
`

export const GET_PRODUCTS_IN_DISCOUNT = gql`
query ProductList($businessId:ID,$locationId:ID){
  productList(businessId:$businessId,locationId:$locationId) {
      pageInfo{hasNextPage
      hasPreviousPage}
      edges{node{
        productId
        name
        price
        variants{
			variantId
			 name
			additionalPrice
			variantGroup
		}
      }
      }
    
      
  }
    
  }
  
`

export const COUSTOMER_GROUPS = gql`
query CustomerGroupList($businessId:ID){
	customerGroupList(businessId:$businessId)
	{edges{node{groupId
	groupName
	customerGroup}}}
}
`

export const DISCOUNT_EDIT_DATA = gql`
query Discount($discountId:ID){
	discount(discountId:$discountId){
	discountId
		locationId
		name
		description
	reason{
		reasonId
	}
		discountType
		items
		limitedStockQuantity
		couponCode
		amount
		image
		orderType
		orderAvailability
		deliveryZone
		bgColourCode
		paymentType
		discountUsageType
		startDate
		startTime
		endDate
		endTime
		customerGroup
		minTotalCartAmount
		maxDiscountAmount
		markDiscountAs
		discountApplicableAs
		couponCode
		limitedStockQuantity
		discountSchedule
		isExactItemQuantity
		minNoOfItemsRequired
		applyDiscountTo
		applyToAllItems
		eligibleDiscountItems
		discountItems
		eligibleItems
	}
}`

export const DISCOUNT_HISTORY = gql`
query discountCustomerMappingList($discountId:Int,$locationId:Int){
discountCustomerMappingList(discountId:$discountId,locationId:$locationId){ 
	edges{node{mappingId
	amount
	customerName
		orderId
		createdOn
		
	discount{
		discountId
	name}}}}
}`

