import { gql } from "@apollo/client";

export const TOP_DELIVERY_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query TopDeliveriesReports {
        topDeliveriesReports(
            toDate: "${toDate}"
            fromDate: "${fromDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}
        )
    }
  `;
};

export const DELIVERY_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";

  return gql`
  query DeliveriesReports {
        deliveriesReports(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}
        )
    }
  `;
};
export const DELIVERY_GRAPH = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query GraphDeliveriesReports {
        graphDeliveriesReports(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}
        )
    }
  `;
};
