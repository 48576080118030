import { gql } from "@apollo/client";

export const SALES_SUMMARY = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  employee: string | null,
  locations: number
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";

  return gql`
     query orderClient {
      salesSummary(
         locationId: "${locations}"
         fromDate: "${fromDate}"
         toDate: "${toDate}"
         fromTime:"${fromTime}"
         toTime:"${toTime}"
         ${employeeParam}   
       )
     }
   `;
};

// export const SALES_SUMMARY = gql`
//   query orderClient {
//     salesSummary(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-02-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//       employee: "ec3d70f9-e2d2-4560-813e-ac2ee7228317"
//     )
//   }
// `;

// export const SALES_SUMMARY_REPORT = gql`
//   query orderClient {
//     salesSummaryReport(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-02-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//       # 		employee:"ec3d70f9-e2d2-4560-813e-ac2ee7228317"
//     )
//   }
// `;

export const SALES_SUMMARY_REPORT = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  employee: string | null,
  locations: number
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";

  return gql`
    query orderClient {
      salesSummaryReport(
        locationId: "${locations}"
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime: "${fromTime}"
        toTime: "${toTime}"
        ${employeeParam}      
      )
    }
  `;
};
