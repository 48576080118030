import { Sheet } from "@mui/joy";
import { Box, TextField, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TimePickerValue from "./timePicker";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { useFormik } from "formik";
import { UPDATE_BLOCK_BOOKINGS } from "../../../graphicalQl/mutation/locationMutation";
import { GETTING_BULK_AND_RUSH_HOUR_SETTINGS } from "../../../graphicalQl/usequery/locationQuery";
import { useMutation, useQuery } from "@apollo/client";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useSelector } from "react-redux";
import CircularLoader from "../../../loader/loader";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { createTheme, ThemeProvider } from "@mui/material";
import { toast } from "react-toastify";
import moment from "moment";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

interface State {
  setOpenOptionsGropModal: React.Dispatch<React.SetStateAction<boolean>>;
}

const initiallValues = {
  startTime: null,
  endTime: null,
  orderTypes: [] as string[],
  date: "",
  blockBookingsToggle: false,
  formattedDate: null,
};

const BlockBookingsModal = (props: State) => {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { setOpenOptionsGropModal } = props;

  const filteredCategory = {
    storeId: locationId,
  };
  const {
    loading: rushHourLoading,
    error: rushHourError,
    data: rushHourData,
  } = useQuery(GETTING_BULK_AND_RUSH_HOUR_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (rushHourData) {
      const { storeSettings } = rushHourData;
      if (storeSettings) {
        const { blockBookings } = storeSettings;
        if (blockBookings) {
          const {
            start,
            end,
            dine_in,
            delivery,
            collection,
            shop,
            date,
            allow_block_bookings,
          } = JSON.parse(blockBookings);
          formik.setFieldValue("startTime", start);
          const parsedFromDateForUpdating = dayjs(date);
          if (date) {
            formik.setFieldValue("formattedDate", parsedFromDateForUpdating);
            formik.setFieldValue("date", date);
          } else {
            formik.setFieldValue("formattedDate", null);
            formik.setFieldValue("date", null);
          }

          formik.setFieldValue("blockBookingsToggle", allow_block_bookings);
          formik.setFieldValue("endTime", end);
          const collected = orderTypes.map((each: any) => {
            if (each.checking === "dine_in") {
              return {
                ...each,
                isChecked: dine_in,
              };
            } else if (each.checking === "delivery") {
              return {
                ...each,
                isChecked: delivery,
              };
            } else if (each.checking === "collection") {
              return {
                ...each,
                isChecked: collection,
              };
            } else if (each.checking === "shop") {
              return {
                ...each,
                isChecked: shop,
              };
            }
          });
          const allChecked =
            orderTypes.length ===
            collected.filter((each: any) => each.isChecked).length;
          setOrderTypes(collected);
          setCheckBoxForAllOrderTypes(allChecked);
          formik.setFieldValue(
            "orderTypes",
            collected
              .filter((each: any) => each.isChecked)
              .map((each: any) => each.orderType)
          );
        }
      }
    }
  }, [rushHourData]);

  const validationSchema = Yup.object({
    startTime: Yup.string().when("blockBookingsToggle", {
      is: (value: boolean) => value === true,
      then: (schema) => schema.required("Required"),
    }),

    date: Yup.string().when("blockBookingsToggle", {
      is: (value: boolean) => value === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),

    endTime: Yup.string()
      .test(
        "is-greater",
        "End time must be greater than start time",
        function (value) {
          const { startTime } = this.parent; // Access the values of other fields
          if (startTime && value) {
            return startTime <= value;
          }
          return true; // Return true if either field is empty
        }
      )
      // .required("Required")
      .when("blockBookingsToggle", {
        is: (value: boolean) => value === true,
        then: (schema) => schema.required("Required"),
      }),

    orderTypes: Yup.array()
      // .min(1, "At least one order type must be selected")
      // .required("At least one Order Type must be selected")
      .when("blockBookingsToggle", {
        is: (value: boolean) => value === true,
        then: (schema) =>
          schema.min(1, "At least one order type must be selected"),
      }),
  });

  const [updateBlockBookings] = useMutation(UPDATE_BLOCK_BOOKINGS, {
    onCompleted: (data) => {
      if (data) {
        const { branchSettings } = data;
        if (branchSettings) {
          const { success } = branchSettings;
          if (success) {
            setOpenOptionsGropModal(false);
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [],
  });

  const [orderTypes, setOrderTypes] = useState<any[]>([
    {
      id: "1",
      orderType: t("dashboard.Delivery"),
      isChecked: false,
      checking: "delivery",
    },
    {
      id: "2",
      orderType: t("dashboard.Shop"),
      isChecked: false,
      checking: "shop",
    },
    {
      id: "3",
      orderType: t("dashboard.Collection"),
      isChecked: false,
      checking: "collection",
    },
    {
      id: "4",
      orderType: t("dashboard.Dine_In"),
      isChecked: false,
      checking: "dine_in",
    },
  ]);

  const [checkBoxForAllOrderTypes, setCheckBoxForAllOrderTypes] =
    useState<boolean>(false);

  const handleOrderTypeChange = (isChecked: boolean, id: string) => {
    const list = orderTypes.map((each) =>
      each.id === id ? { ...each, isChecked: isChecked } : each
    );
    setOrderTypes(list);
    const checkedOrderTypesForFormik = list
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.orderType);
    formik.setFieldValue("orderTypes", checkedOrderTypesForFormik);

    const checkedOrderTypes = list.filter((each: any) => each.isChecked);
    if (orderTypes.length === checkedOrderTypes.length) {
      setCheckBoxForAllOrderTypes(true);
    } else {
      setCheckBoxForAllOrderTypes(false);
    }
  };

  const handleOrderTypeChangeForAll = (isChecked: boolean) => {
    const list = orderTypes.map((each) => {
      return {
        ...each,
        isChecked: isChecked,
      };
    });
    setOrderTypes(list);
    setCheckBoxForAllOrderTypes(isChecked);
    const checkedOrderTypes = list
      .filter((each: any) => each.isChecked)
      .map((each: any) => each.orderType);
    formik.setFieldValue("orderTypes", checkedOrderTypes);
  };

  const formik = useFormik({
    initialValues: initiallValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const handleDate = (date: any) => {
    formik.setFieldValue("formattedDate", date);
    const requireDateFormat = dayjs(date).format("YYYY/MM/DD");
    formik.setFieldValue("date", requireDateFormat);
  };

  const handleSaveButton = () => {
    const blockBookingsForToggleOn = {
      start: formik.values.startTime,
      end: formik.values.endTime,
      dine_in: formik.values.orderTypes.includes("Dine In"),
      delivery: formik.values.orderTypes.includes("Delivery"),
      shop: formik.values.orderTypes.includes("Shop"),
      collection: formik.values.orderTypes.includes("Collection"),
      date: formik.values.date,
      allow_block_bookings: true,
    };
    const blockBookingsForToggleOff = {
      start: null,
      end: null,
      dine_in: false,
      delivery: false,
      shop: false,
      collection: false,
      date: null,
      allow_block_bookings: false,
    };

    const blockBookings = formik.values.blockBookingsToggle
      ? blockBookingsForToggleOn
      : !blockBookingsForToggleOff;

    const variables = {
      storeId: +locationId,
      businessId: businessId,
      blockBookings: JSON.stringify(blockBookings),
    };
    updateBlockBookings({
      variables: variables,
    });
  };

  const isStartTimeTouchedAndInvalid =
    formik.touched.startTime && formik.errors.startTime;
  const isEndTimeTouchedAndInvalid =
    formik.touched.endTime && formik.errors.endTime;

  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        maxWidth: "360px",
        marginY: "20px",
        outline: "none",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        marginX: "auto",
        position: "relative",
        top: "0px",
        left: { xs: "auto", md: "40px" },
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#333333",
              }}
            >
              {t("dashboard.blockbookings")}
            </Typography>
            <FormControlLabel
              sx={{ margin: "0px" }}
              name="blockBookingsToggle"
              value={formik.values.blockBookingsToggle}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={(e) => formik.handleBlur(e)}
              control={
                <Switch
                  checked={formik.values.blockBookingsToggle}
                  color="warning"
                />
              }
              label=""
            />
          </Box>
          <Typography
            sx={{
              marginTop: "7px",
              display: "inline-block",
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "21px",
              color: "#707070",
            }}
          >
            {t("dashboard.selectstartandend")}
          </Typography>
          <Box sx={{ marginTop: "10px" }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoItem
                label={<Typography>{t("dashboard.Select_Date")}</Typography>}
              >
                <DatePicker
                  disabled={!formik.values.blockBookingsToggle}
                  sx={{ width: "100%" }}
                  value={formik.values.formattedDate}
                  onChange={(newValue) => handleDate(newValue)}
                  format="DD/MM/YYYY"
                />
              </DemoItem>
            </LocalizationProvider>
            {formik.touched.date && formik.errors.date && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {formik.errors.date}
                </Typography>
              </Box>
            )}
          </Box>
          <Box marginTop={"10px"}>
            <TimePickerValue formik={formik} />
            {(isStartTimeTouchedAndInvalid || isEndTimeTouchedAndInvalid) && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {formik.errors.startTime || formik.errors.endTime}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              paddingY: "4px",
              "&.css-4jnixx-MuiStack-root": { overFlow: "hidden !important" },
              border: "1px solid #eee",
              marginBottom: "10px",
              width: "50%",
              marginTop: "20px",
              borderRadius: "4px",
            }}
          >
            <FormControlLabel
              sx={{
                margin: "0px",
                display: "flex",
                aligItems: "center",
                "& .Mui-checked": {
                  color: formik.values.blockBookingsToggle
                    ? "#F38B08 !important"
                    : "#ABABAB !important",
                },
              }}
              control={
                <Checkbox
                  onChange={(e: any) =>
                    handleOrderTypeChangeForAll(e.target.checked)
                  }
                  disabled={!formik.values.blockBookingsToggle}
                  checked={checkBoxForAllOrderTypes}
                  sx={{ paddingLeft: "8px" }}
                />
              }
              label={t("dashboard.All_Order_Types")}
              disabled={!formik.values.blockBookingsToggle}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              gap: "10px",
              flexWrap: "wrap",
              marginBottom: "5px",
            }}
          >
            {orderTypes.map((each: any) => (
              <Box
                key={each.id}
                sx={{
                  width: { xs: "100%", sm: "140px" },
                  height: "46px",
                  display: "flex",
                  aligItems: "center",
                  paddingX: "0px",
                  margin: "0px",
                  borderRadius: "4px",
                  border: "1px solid #EEEEEE",
                }}
              >
                <FormControlLabel
                  sx={{
                    margin: "0px !important",
                    paddingLeft: "8px",
                    gap: "12px",
                    "& .Mui-checked": {
                      color: formik.values.blockBookingsToggle
                        ? "#F38B08 !important"
                        : "#ABABAB !important",
                    },
                  }}
                  onChange={(e: any) =>
                    handleOrderTypeChange(e.target.checked, each.id)
                  }
                  control={
                    <Checkbox
                      disabled={!formik.values.blockBookingsToggle}
                      checked={each.isChecked}
                    />
                  }
                  label={each.orderType}
                />
              </Box>
            ))}
          </Box>
          {formik.touched.orderTypes && formik.errors.orderTypes && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {formik.errors.orderTypes}
              </Typography>
            </Box>
          )}

          <Box sx={{ display: "flex", boxShadow: "none" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginTop: "20px",
                paddingY: "8.5px",
                paddingX: "16px",
                backgroundColor: "#F38B08",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "18px",
                textTransform: "none",
                lineHeight: "27px",
                color: "#FEFEFE",
                cursor: "pointer",
                marginLeft: "auto",
                boxShadow: "none !important",
                "&:hover": {
                  backgroundColor: "#F38B08", // Specify the hover color you want
                },
              }}
            >
              {t("dashboard.Save")}
            </Button>
          </Box>
        </Box>
      </form>
    </Sheet>
  );
};

export default BlockBookingsModal;
