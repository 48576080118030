import React, { ChangeEvent, useState, } from 'react';
import { TextField, Switch, Typography, Box, IconButton, Popover, Divider, } from '@mui/material';
import { useTranslation } from "react-i18next";
import VisibilityRoundedIcon from '@mui/icons-material/VisibilityRounded';
import { DISCOUNT_EDIT_DATA, DISCOUNT_LIST } from '../../graphicalQl/usequery/discountQuery';

import { styled } from '@mui/system';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { useMutation, useQuery } from '@apollo/client';
import { DISCOUNT_STATUS_CHANGE } from '../../graphicalQl/mutation/discountMutation';
import CurrencyDisplay from '../utils/currencySymbol';
import { useSelector } from 'react-redux';


interface RowData {
  id: string,
  discountName: string,
  status: boolean,
  reason: string,
  amount: number,
  image: string

}

const DiscountPreMade: React.FC = () => {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const CustomStyles = styled('div')({
    '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
      color: "#333 !important",
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "15px !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "21px !important"
    },
    '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
      outline: 'solid #1976d2 0px !important',
    },
    '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
      outline: 'solid #1976d2 0px !important',
    },
  }
  );









  function CustomPopoverCell2({ value, id }: { value: string, id: string }) {

    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const { data: discountEditData } = useQuery(DISCOUNT_EDIT_DATA, {
      context: { clientName: "discountClient" }, variables: { discountId: id && +id }, fetchPolicy: "cache-and-network", onCompleted: (data) => {
      }
    });

    const [editData, setEditData] = useState<{ image: string, applyToAllItems: boolean, name: string, orderType: any[], orderAvailability: any[], paymentType: any[], deliveryZone: any[], discountUsageType: string, couponCode: string, endDate: string, startDate: string }>()

    React.useEffect(() => {
      if (discountEditData && discountEditData.discount) {
        const list = {
          image: discountEditData.discount.image,
          applyToAllItems: discountEditData.discount.applyToAllItems,
          name: discountEditData.discount.name,
          orderType: JSON.parse(discountEditData.discount.orderType),
          orderAvailability: JSON.parse(discountEditData.discount.orderAvailability),
          paymentType: JSON.parse(discountEditData.discount.paymentType),
          deliveryZone: JSON.parse(discountEditData.discount.deliveryZone),
          discountUsageType: discountEditData.discount.discountUsageType,
          couponCode: discountEditData.discount.couponCode,
          startDate: discountEditData.discount.startDate,
          endDate: discountEditData.discount.endDate,
        }
        setEditData(list)
      }
    }, [discountEditData])

    let itemsText = editData && editData.applyToAllItems ? "on  all items" : "on selected items"

    const popoverContent = (
      <Box sx={{ width: "300px", padding: "12px", gap: '8px' }}>
        <Box sx={{ backgroundColor: "#EEEEEE", display: "flex", alignItems: "center", justifyContent: "center", padding: "4px" }}>
          {editData && editData.image &&
            <img src={editData && editData.image} alt='discount' style={{ width: 60, height: 60 }} />
          }
        </Box>
        <Box >
          <Typography className="dataGrid-table-cell">
            What the Clients get:

          </Typography>

          <Typography className='heavy-para-text'>{editData && editData.name} {itemsText}</Typography>

          <Typography className='dataGrid-table-cell'>
            Condition :

          </Typography>
          {
            editData && editData.orderType &&
            <Box sx={{ display: 'flex', gap: "2px", flexWrap: "wrap" }}>
              <Typography className='heavy-para-text'>orderType:</Typography>
              <Box sx={{ display: 'flex', gap: "4px", flexWrap: "wrap" }}>

                {editData && editData.orderType && editData.orderType.map((each) => (<Typography className='heavy-para-text'>{` ${each},  `}  </Typography>))}
              </Box>
            </Box>
          }

          {editData && editData.orderAvailability &&
            <Box sx={{ display: 'flex', gap: "2px", flexWrap: "wrap" }}>
              <Typography className='heavy-para-text'>orderAvailability:</Typography>
              <Box sx={{ display: 'flex', gap: "4px", flexWrap: "wrap" }}>
                {editData && editData.orderAvailability && editData.orderAvailability.map((each) => (<Typography className='heavy-para-text'>{each},</Typography>))}
              </Box>
            </Box>
          }
          {editData && editData.paymentType &&
            <Box sx={{ display: 'flex', gap: "2px", flexWrap: "wrap" }}>

              <Typography className='heavy-para-text'>paymentType:</Typography>
              <Box sx={{ display: 'flex', gap: "4px", flexWrap: "wrap" }}>
                {editData && editData.paymentType && editData.paymentType.map((each) => (<Typography className='heavy-para-text'>{each},</Typography>))}
              </Box>
            </Box>
          }
          {/* {editData && editData.deliveryZone &&
            <Box sx={{ display: 'flex', gap: "2px", flexWrap: "wrap" }}>
              <Typography className='discount-pop-para-text'>deliveryZone:</Typography>
              {editData && editData.deliveryZone && editData.deliveryZone.map((each) => (<Typography className='discount-pop-para-text'>{each},</Typography>))}
            </Box>
          } */}
          <Typography className='heavy-para-text'>{editData && editData.discountUsageType}</Typography>

          <Typography className='dataGrid-table-cell'>
            Who gets the Promo deal :
          </Typography>
          {editData && editData.couponCode &&
            <Box sx={{ display: 'flex', gap: "4px", flexWrap: "wrap" }}>

              <Typography className='heavy-para-text'>Cupon code :</Typography>

              <Typography className='heavy-para-text'>{editData && editData.couponCode}</Typography>
            </Box>
          }
          {editData && editData.startDate &&

            <Box sx={{ display: 'flex', gap: "4px", flexWrap: "wrap" }}>
              <Typography className='heavy-para-text'>From :</Typography>

              <Typography className='heavy-para-text'>{editData && editData.startDate} to {editData && editData.endDate}</Typography>
            </Box>
          }
        </Box>
      </Box>
    );


    return (
      <Box>
        <div>
          <IconButton aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
            <VisibilityRoundedIcon />
          </IconButton>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'center',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }



  const [rows, setRows] = useState<RowData[]>([])
  const businessId: string = sessionStorage.getItem("businessId") as string
  const [searchValue, setSearchValue] = useState("")
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value)
  }
  const [discountStatusChange] = useMutation(DISCOUNT_STATUS_CHANGE, { context: { clientName: "discountClient", method: 'POST' }, refetchQueries: [DISCOUNT_LIST] });


  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {


    const status = event.target.checked ? "Active" : "InActive"
    discountStatusChange({ variables: { discountId: +id, discountStatus: status } })

  };
  const { loading, data } = useQuery(DISCOUNT_LIST, {
    context: { clientName: "discountClient" }, variables: { name_Icontains: searchValue, businessId: +businessId, isPremadeDiscount: true, isSelfmadeDiscount: false, location: locationId.toString() }, fetchPolicy: "cache-and-network", onCompleted: (data) => {
    }
  });
  React.useEffect(() => {
    if (data && data.discounts) {
      const list = data.discounts.edges.map((each: any) => ({
        id: each.node.discountId,
        discountName: each.node.name,
        status: each.node.discountStatus === "Active" ? true : false,
        reason: each.node.reason.reason,
        image: each.node.image,
        amount: each.node.amount,
      }));


      setRows(list)
    }
  }, [data])
  const columns: GridColDef[] = [

    {
      field: 'discountName',
      //@ts-ignore
      headerName: t('selfMadeDiscount.discount_name'),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}>
            <CustomPopoverCell2
              value={params.row.discountType}
              id={params.row.id}
            />
            {params.row.image &&
              <Box className="bundle_image_container">
                <img src={params.row.image} alt="bundle" style={{ width: "24px", height: "24px" }} />
              </Box>
            }

            <Typography className='bundleListigCell'>{params.value}</Typography>
          </Box>
        )
      }

    },

    {
      field: 'reason',
      //@ts-ignore
      headerName: t('selfMadeDiscount.reason'),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      flex: 1,
      disableColumnMenu: true

    },
    {
      field: 'status',
      //@ts-ignore
      headerName: t('selfMadeDiscount.status'),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      flex: 1,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={(event) => handleStatus(event, params.row.id)}
            color='warning'
          />
        )
      }
    },
    {
      field: 'amount',
      //@ts-ignore

      headerName: "Discount",
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography className='bundleListigCell'><CurrencyDisplay countryName={'UnitedKingdom'} amount={+params.value} />
          </Typography>
        )
      }

    },

  ];


  const handleCellClass = () => {
    return "dataGrid-table-cell-less-wt"
  }
  const handleRowClass = () => {
    return "dataGrid-table-row"
  }

  return (
    <div>
      <Typography className='main-head' sx={{ m: '20px' }}>{t("selfMadeDiscount.Pre_Made_Discounts")}</Typography>
      <Divider />
      <Box style={{ padding: '0.75rem 0 0 1.48%' }}>



        <TextField
          className="discountListingTextField"
          label={<Typography className='label-text-for-textField'>{t("selfMadeDiscount.search")}</Typography>}
          color='warning'
          onChange={handleSearch}
          value={searchValue}
          sx={{ marginBottom: "0.75rem", width: "24%" }}
        />
        <CustomStyles>
          <DataGrid
            rows={rows}
            columns={columns}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            rowHeight={48}
            columnHeaderHeight={48}
            // initialState={{
            //     pagination: {
            //         paginationModel: {
            //             pageSize: 5,
            //         },
            //     },
            // }}
            // pageSizeOptions={[5]}
            hideFooterPagination
            scrollbarSize={0}
            sx={{ marginRight: "2%" }}
            // checkboxSelection
            disableRowSelectionOnClick
          />
        </CustomStyles>





      </Box>
    </div>
  );
};

export default DiscountPreMade;
