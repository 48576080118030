/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useContext, useEffect, useState } from "react";
import SalesGraph from "./selesGraph";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";

import {
  SALES_SUMMARY,
  SALES_SUMMARY_REPORT,
} from "../../../graphicalQl/usequery/salesSummary";
import { SalesGraphType, SalesTableType } from "../../../types/salesReport";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";
const options = [
  { label: "Sale Type", value: "saleType" },
  { label: "Sales Generated", value: "salesGenerated" },
  { label: "Return/Refunded", value: "refunded" },
  { label: "Net Amount", value: "netAmount" },
];

const SalesReport = () => {
  const [employee, setEmployee] = useState<string | null>(null);
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Sale Type", value: "saleType" },
    { label: "Sales Generated", value: "salesGenerated" },
    { label: "Return/Refunded", value: "refunded" },
    { label: "Net Amount", value: "netAmount" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "saleType",
    "salesGenerated",
    "refunded",
    "netAmount",
  ]);
  const [salesGraph, setSalesGraph] = useState<Array<SalesGraphType>>([]);
  const [tableData, setTableData] = useState<Array<SalesTableType>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("23:59:00");
  const { fromTime, toTime } = useContext(GraphContext);

  // const [locations, setLocations] = useState<string>("")
  // const { locationId } = useSelector((state: any) => state.headerLocation);
  // useEffect(() => {
  //   if (locationId !== null) {
  //     setLocations(locationId);
  //   }
  // }, [locationId]);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);

  const { loading: salesReportLoading, data: salesReportData } = useQuery(
    SALES_SUMMARY_REPORT(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportEmployee,
      parseInt(reportLocation) || parseInt(locationId)
    ),
    {
      fetchPolicy: "no-cache",
    }
  );
  const { loading: salesGraphLoading, data: salesGraphData } = useQuery(
    SALES_SUMMARY(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportEmployee,
      reportLocation || +locationId
    ),
    {
      fetchPolicy: "no-cache",
    }
  );

  const getSalesGraph = () => {
    if (!salesGraphLoading) {
      const salesSummaryReport = salesGraphData.salesSummary;
      const newSalesSummaryReport = JSON.parse(salesSummaryReport);
      setSalesGraph(newSalesSummaryReport);
    }
  };
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const getSalesReport = () => {
    if (!salesReportLoading && salesReportData) {
      const salesSummary =
        salesReportData && salesReportData.salesSummaryReport;
      const newSalesSummary = JSON.parse(salesSummary);

      const tempTableData: SalesTableType[] = [];

      newSalesSummary?.map((sale: any) => {
        const key = Object.keys(sale)?.[0];
        const tempObj: SalesTableType = {
          saleType: "",
          salesGenerated: 0,
          refunded: 0,
          netAmount: 0,
        };
        tempObj.saleType = key;
        tempObj.netAmount = `${currencySymbol} ${sale?.[key].net_amount.toFixed(
          2
        )}`;
        tempObj.refunded = `${currencySymbol} ${sale?.[key].refunded.toFixed(
          2
        )}`;
        tempObj.salesGenerated =
          tempObj.saleType === "orders"
            ? `${currencySymbol} ${sale?.[key].total_sales.toFixed(2)}`
            : tempObj.saleType === "discounts"
              ? `${currencySymbol} ${sale?.[key].discount_sales.toFixed(2)}`
              : `${currencySymbol} ${sale?.[key].giftcard_sales.toFixed(2)}`;
        tempTableData.push(tempObj);
        return tempObj;
      });
      setTableData(tempTableData);
    }
  };
  useEffect(() => {
    getSalesGraph();
  }, [salesGraphLoading]);

  useEffect(() => {
    getSalesReport();
  }, [salesReportLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        employee={true}
        // setEmployee={setEmployee}
        setDate={setDate}
        date={date}
      // setFromTime={setFromTime}
      // setToTime={setToTime}
      // setLocations={setLocations}
      // locations={locations}
      />
      <Box
        mt={"20px"}
        pb={1}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <SalesGraph salesGraph={salesGraph} date={date} />
      </Box>
      <Box mt={5}>
        <ReportsTable
          options={options}
          labels={labels}
          headers={headers}
          setHeaders={setHeaders}
          setLabels={setLabels}
          data={tableData}
          title={"Sales Summary"}
        />
      </Box>
    </Box>
  );
};

export default SalesReport;
