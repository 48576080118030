import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  fromPromise,
} from "@apollo/client";
import {
  ApolloLink,
  FetchResult,
  execute,
  Operation,
  NextLink,
} from "@apollo/client/link/core";
import { onError } from "@apollo/client/link/error";
import axios from "axios";
import { Observable } from "apollo-link";
import { useNavigate } from "react-router";

const url = "https://apidomain.bayforall.co.uk";

const refreshAccessToken = async () => {
  try {
    const refreshToken = sessionStorage.getItem("refresh");
    const userID = sessionStorage.getItem("userId");

    const response = await axios.post(
      "https://apidomain.bayforall.co.uk/v1/refresh/token",
      { refresh: refreshToken, user_id: userID }
    );
    return response.data.access;
  } catch (error) {
    throw new Error("Failed to refresh access token");
  }
};

const authLink = new ApolloLink((operation, forward) => {
  const token = sessionStorage.getItem("token");
  if (token) {
    operation.setContext({
      headers: {
        authorization: `JWT ${token}`,
      },
      // clientName: operation.operationName,
    });
  }
  return forward(operation).map((response) => {
    // Modify the response here if needed
    return response;
  });
});

// const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
//   if (graphQLErrors) {
//     for (let err of graphQLErrors) {
//       if (err.extensions && err.extensions.status === '401') {
//         try {
//           // Attempt to refresh the access token
//           const newAccessToken = refreshAccessToken();
//           // Set the new access token in the operation context
//           const oldHeaders = operation.getContext().headers;
//           operation.setContext({
//             headers: {
//               ...oldHeaders,
//               authorization: `JWT ${newAccessToken}`,
//             },
//           });

//           // Retry the request with the new access token
//           return forward(operation);
//         } catch (refreshError) {
//           console.error('Failed to refresh access token:', refreshError);
//           // Optionally, you can throw the refreshError to propagate it further
//           throw refreshError;
//         }
//       }
//     }
//   }
//   if (networkError) {
//     console.error(`[Network error]: ${networkError}`);
//   }
// });
const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions && err.extensions.status) {
          case "401":
            return fromPromise(
              refreshAccessToken().catch((error: any) => {
                return;
              })
            )
              .filter((value) => Boolean(value))
              .flatMap((access) => {
                const oldHeaders = operation.getContext().headers;
                sessionStorage.setItem("token", access);
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `JWT ${access}`,
                  },
                });
                return forward(operation);
              });

          case "400":
            window.location.href = "/bad/request";
            break;
          case "503":
            window.location.href = "/service-unavailable";
            break;
          case "500":
            // window.location.href = '/internal-server-error'; // Redirect to internal server error screen
            break;
          case "403":
            window.location.href = "/permission-denied"; // Redirect to permission denied screen
            break;
        }
      }
    }
    if (networkError) {
      console.log("Network error:", networkError);
      // Handle network error according to your application's logic
      // window.location.href = '/internal-server-error';
    }
  }
);

const categoryClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/category` }),
  ]),
  cache: new InMemoryCache(),
});
const ordersClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/ordering` }),
  ]),
  cache: new InMemoryCache(),
});
const userClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/users` }),
  ]),
  cache: new InMemoryCache(),
});
const businessClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/business` }),
  ]),
  cache: new InMemoryCache(),
});
const addressClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/address` }),
  ]),
  cache: new InMemoryCache(),
});
const discountClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/discount` }),
  ]),
  cache: new InMemoryCache(),
});

const customerClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/customer` }),
  ]),
  cache: new InMemoryCache(),
});
const ordering = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/ordering` }),
  ]),
  cache: new InMemoryCache(),
});
const mediaClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/media` }),
  ]),
  cache: new InMemoryCache(),
});

const superUserClient = new ApolloClient({
  link: ApolloLink.from([
    errorLink,
    authLink,
    new HttpLink({ uri: `${url}/v1/super-user` }),
  ]),
  cache: new InMemoryCache(),
});

const customLink = new ApolloLink((operation, forward) => {
  const clientName =
    operation.getContext().clientName || operation.operationName;
  if (clientName === "categoryClient") {
    return execute(categoryClient.link, operation);
  } else if (clientName === "userClient") {
    return execute(userClient.link, operation);
  } else if (clientName === "businessClient") {
    return execute(businessClient.link, operation);
  } else if (clientName === "orderClient") {
    return execute(ordersClient.link, operation);
  } else if (clientName === "addressClient") {
    return execute(addressClient.link, operation);
  } else if (clientName === "discountClient") {
    return execute(discountClient.link, operation);
  } else if (clientName === "customerClient") {
    return execute(customerClient.link, operation);
  } else if (clientName === "ordering") {
    return execute(ordering.link, operation);
  } else if (clientName === "mediaContext") {
    return execute(mediaClient.link, operation);
  } else if (clientName === "superUserClient") {
    return execute(superUserClient.link, operation);
  }
  return forward(operation);
});
const Client = new ApolloClient({
  link: ApolloLink.from([authLink, customLink]),
  cache: new InMemoryCache(),
});
export default Client;
