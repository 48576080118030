import React, { useEffect, useState } from "react";
import {
  Paper,
  Typography,
  Button,
  Checkbox,
  TextField,
  FormControlLabel,
  Box,
  IconButton,
  Switch,
} from "@mui/material";
// import { t } from "i18next";
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import "../accountSettings/salesTax.css";
import "../accountSettings/manageTips.css";
import "./epos.css";
import { CUSTOM_DISCOUNTS } from "../../graphicalQl/mutation/salesTaxMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CUSTOM_DISCOUNTS } from "../../graphicalQl/usequery/settingQuery";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
// import { set } from 'react-hook-form';
import { useTranslation } from "react-i18next";

const EposDiscount: React.FC = () => {
  const [updateDiscounts] = useMutation(CUSTOM_DISCOUNTS, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [checkboxes, setCheckboxes] = useState([
    { checked: false, label: "5" },
    { checked: false, label: "10" },
    { checked: false, label: "15" },
    { checked: false, label: "0" },
  ]);

  const [newLabel, setNewLabel] = useState("");

  const handleCheckboxChange = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes[index].checked = !updatedCheckboxes[index].checked;
    setCheckboxes(updatedCheckboxes);
  };

  const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewLabel(event.target.value);
  };

  const handleAddCheckbox1 = () => {
    if (newLabel1.trim() !== "") {
      setCheckboxes1([...checkboxes1, { checked: false, label: newLabel1 }]);
      setNewLabel1("");
      setAddValue1(false);
      setTextCondition1(false);
    } else {
      setTextCondition1(true);
    }
  };

  const [addValue, setAddValue] = useState(false);

  const handleAdd = () => {
    setAddValue(!addValue);
    setTextCondition(false);
    setNewLabel("");
  };

  const [checkboxes1, setCheckboxes1] = useState([
    { checked: false, label: "5" },
    { checked: false, label: "1" },
    { checked: false, label: "0.5" },
    { checked: false, label: "2" },
  ]);
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const [newLabel1, setNewLabel1] = useState("");
  const [textCondition, setTextCondition] = useState(false);
  const [textCondition1, setTextCondition1] = useState(false);
  const handleCheckboxChange1 = (index: number) => {
    const updatedCheckboxes1 = [...checkboxes1];
    updatedCheckboxes1[index].checked = !updatedCheckboxes1[index].checked;
    setCheckboxes1(updatedCheckboxes1);
  };

  const handleNewLabelChange1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setNewLabel1(event.target.value);
  };

  const handleAddCheckbox = () => {
    if (newLabel.trim() !== "") {
      setCheckboxes([...checkboxes, { checked: false, label: newLabel }]);
      setNewLabel("");
      setTextCondition(false);
      setAddValue(false);
    } else {
      setTextCondition(true);
    }
  };

  const [addValue1, setAddValue1] = useState(false);

  const handleAdd1 = () => {
    setAddValue1(!addValue1);
    setTextCondition1(false);
    setNewLabel1("");
  };

  const [percentagesSwitch, setPercentagesSwitch] = useState(false);
  const [flatSwitch, setFlatSwitch] = useState(false);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const handleSave = () => {
    updateDiscounts({
      variables: {
        storeId: locationId && +locationId,
        allowFlatDiscountsForPos: flatSwitch,
        flatDiscountsForPos: JSON.stringify(
          checkboxes1.filter((each) => each.checked).map((item) => +item.label)
        ),
        allowPercentageDiscountsForPos: percentagesSwitch,
        discountPercentagesForPos: JSON.stringify(
          checkboxes.filter((each) => each.checked).map((item) => +item.label)
        ),
        flatAmountTipDetails: JSON.stringify(checkboxes1),
        flatDiscountsDetailsForPos: JSON.stringify(checkboxes),
      },
    });
  };

  const { data } = useQuery(GET_CUSTOM_DISCOUNTS, {
    context: { clientName: "businessClient" },
    variables: {
      storeId: locationId && +locationId,
    },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data && data.storeSettings) {
      setFlatSwitch(data.storeSettings.allowFlatDiscountsForPos);
      data.storeSettings.flatAmountTipDetails &&
        setCheckboxes1(JSON.parse(data.storeSettings.flatAmountTipDetails));
      setPercentagesSwitch(data.storeSettings.allowPercentageDiscountsForPos);
      data.storeSettings.flatDiscountsDetailsForPos &&
        setCheckboxes(
          JSON.parse(data.storeSettings.flatDiscountsDetailsForPos)
        );
    }
  }, [data]);

  const [mouseEnter, setMouseEnter] = useState("");
  const [mouseEnter1, setMouseEnter1] = useState("");

  const duplicateCondition1 =
    checkboxes &&
    checkboxes.filter((each) => each.label === newLabel).length > 0;
  const duplicateCondition2 =
    checkboxes1 &&
    checkboxes1.filter((each) => each.label === newLabel1).length > 0;
  const handleDeletecheckboxes = (index: number) => {
    const updatedCheckboxes = [...checkboxes];
    updatedCheckboxes.splice(index, 1);
    setCheckboxes(updatedCheckboxes);
  };
  const handleDeletecheckboxes1 = (index: number) => {
    const updatedCheckboxes = [...checkboxes1];
    updatedCheckboxes.splice(index, 1);
    setCheckboxes1(updatedCheckboxes);
  };

  const { t } = useTranslation();

  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "0.75rem",
        }}
      >
        <IconButton>
          {/* <ArrowBackIcon sx={{ width: "24px", height: "24px" }} /> */}
        </IconButton>
        <Typography className="epos-head-text">
          {t("custom_discount.custom_discounts_for_epos")}
        </Typography>
        <Button
          className="main-button"
          sx={{ textTransform: "none" }}
          onClick={handleSave}
        >
          Save
        </Button>
      </Box>

      <Paper
        elevation={0}
        style={{
          marginLeft: "5%",
          marginTop: "1.5rem",
          width: "72%",
          padding: "0.75rem",
          borderLeft: "2px solid  var(--stroke-color-1-violet, #BAE29B)",
        }}
        className="paper-border"
      >
        <div>
          <Box sx={{ display: "flex", gap: "1%" }}>
            <Box>
              <Typography className="epos-text">
                {t("custom_discount.percentage_discount")}
              </Typography>
              <Typography className="epos-text-para">
                {t("custom_discount.select_para")}
              </Typography>
            </Box>
            <Switch
              color="warning"
              onChange={(e) => {
                setPercentagesSwitch(e.target.checked);
              }}
              checked={percentagesSwitch}
            />
          </Box>

          {percentagesSwitch && (
            <Box>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "12px",
                  width: "100%",
                  marginTop: "1.25rem",
                  gap: "0.7rem",
                }}
              >
                {checkboxes &&
                  checkboxes.map((checkbox, index) => (
                    <Box
                      sx={{ position: "relative" }}
                      className="epos-checkBox"
                      onMouseEnter={() => setMouseEnter(index.toString())}
                      onMouseLeave={() => setMouseEnter("")}
                      key={index}
                    >
                      <FormControlLabel
                        key={index}
                        control={
                          <Checkbox
                            color="warning"
                            checked={checkbox.checked}
                            disabled={
                              checkboxes.filter((each) => each.checked)
                                .length === 4 && checkbox.checked === false
                            }
                            onChange={() => handleCheckboxChange(index)}
                          />
                        }
                        label={
                          <Typography className="epos-text-label">
                            {checkbox.label}%
                          </Typography>
                        }
                        className=""
                        sx={{ marginLeft: "10px" }}
                      />
                      {index.toString() === mouseEnter && (
                        <IconButton
                          sx={{ position: "absolute", top: 0, right: 0 }}
                          onClick={() => handleDeletecheckboxes(index)}
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      )}
                    </Box>
                  ))}
              </div>

              <div style={{ display: "flex", alignItems: "center" }}>
                {!addValue && (
                  <Button
                    className="manage-tips_button"
                    onClick={handleAdd}
                    style={{ marginTop: "10px" }}
                  >
                    {t("settings.add")}
                  </Button>
                )}
                <div>
                  {addValue && (
                    <div
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Box>
                        <TextField
                          label={t("custom_discount.enter_number")}
                          value={newLabel}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (
                              (!val || /^[0-9.]+$/.test(val)) &&
                              val.length <= 5
                            ) {
                              setNewLabel(val);
                            }
                          }}
                          variant="outlined"
                          margin="dense"
                          style={{ marginTop: "10px" }}
                          color="warning"
                          sx={{
                            "&:hover": {
                              "&& fieldset": {
                                border: "2px solid #F9C584",
                              },
                            },
                          }}
                        />
                        {duplicateCondition1 && (
                          <Typography
                            style={{
                              color: "red",
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                          >
                            *{t("custom_discount.alreadt_exist")}
                          </Typography>
                        )}
                        {textCondition && (
                          <Typography
                            style={{
                              color: "red",
                              marginLeft: "10px",
                              fontSize: "12px",
                            }}
                          >
                            *{t("custom_discount.requried")}
                          </Typography>
                        )}
                      </Box>
                      <Button
                        onClick={() => setAddValue(false)}
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          color: "grey",
                          textTransform: "none",
                          fontSize: "14px",
                        }}
                      >
                        {t("custom_discount.cancel")}
                      </Button>
                      <Button
                        className={
                          duplicateCondition1
                            ? "manage-tips_button1"
                            : "manage-tips_button"
                        }
                        onClick={handleAddCheckbox}
                        style={{
                          marginLeft: "10px",
                          display: "flex",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        disabled={duplicateCondition1}
                      >
                        {t("settings.add")}
                      </Button>
                    </div>
                  )}
                </div>
              </div>
            </Box>
          )}
        </div>

        <Box sx={{ display: "flex", gap: "1%", marginTop: "1.25rem" }}>
          <Box>
            <Typography className="epos-text">
              {t("custom_discount.flat_amount")}
            </Typography>
            <Typography className="epos-text-para">
              {t("custom_discount.flat_para")}
            </Typography>
          </Box>
          <Switch
            color="warning"
            onChange={(e) => {
              setFlatSwitch(e.target.checked);
            }}
            checked={flatSwitch}
          />
        </Box>
        {flatSwitch && (
          <Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                marginTop: "12px",
                gap: "0.7rem",
                marginLeft: "12px",
              }}
            >
              {checkboxes1 &&
                checkboxes1.map((checkbox, index) => (
                  <Box
                    className="epos-checkBox"
                    sx={{ position: "relative" }}
                    onMouseEnter={() => setMouseEnter1(index.toString())}
                    onMouseLeave={() => setMouseEnter1("")}
                    key={index}
                  >
                    <FormControlLabel
                      key={index}
                      control={
                        <Checkbox
                          color="warning"
                          checked={checkbox.checked}
                          onChange={() => handleCheckboxChange1(index)}
                          disabled={
                            checkboxes1.filter((each) => each.checked)
                              .length === 4 && checkbox.checked === false
                          }
                        />
                      }
                      label={
                        <Typography className="createService_check_text">
                          {currencySymbol} {checkbox.label}
                        </Typography>
                      }
                      className=""
                      sx={{ marginLeft: "10px" }}
                    />
                    {index.toString() === mouseEnter1 && (
                      <IconButton
                        sx={{ position: "absolute", top: 0, right: 0 }}
                        onClick={() => handleDeletecheckboxes1(index)}
                      >
                        <DeleteIcon fontSize="small" />
                      </IconButton>
                    )}
                  </Box>
                ))}
            </div>

            <div style={{ display: "flex", alignItems: "center" }}>
              {!addValue1 && (
                <Button
                  className="manage-tips_button"
                  onClick={handleAdd1}
                  style={{ marginTop: "10px" }}
                >
                  {t("settings.add")}
                </Button>
              )}
              <div>
                {addValue1 && (
                  <div
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Box>
                      <TextField
                        label={t("custom_discount.enter_number")}
                        value={newLabel1}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9.]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            setNewLabel1(val);
                          }
                        }}
                        // onChange={handleNewLabelChange1}
                        variant="outlined"
                        margin="dense"
                        style={{ marginTop: "10px" }}
                        color="warning"
                        sx={{
                          "&:hover": {
                            "&& fieldset": {
                              border: "2px solid #F9C584",
                            },
                          },
                        }}
                      />
                      {duplicateCondition2 && (
                        <Typography
                          style={{
                            color: "red",
                            marginLeft: "10px",
                            fontSize: "12px",
                          }}
                        >
                          *Already Exist
                        </Typography>
                      )}
                      {textCondition1 && (
                        <Typography
                          style={{
                            color: "red",
                            marginLeft: "10px",
                            fontSize: "12px",
                          }}
                        >
                          *Required
                        </Typography>
                      )}
                    </Box>
                    <Button
                      onClick={() => setAddValue1(false)}
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        color: "grey",
                        textTransform: "none",
                        fontSize: "14px",
                      }}
                    >
                      {t("custom_discount.cancel")}
                    </Button>
                    <Button
                      className={
                        duplicateCondition2
                          ? "manage-tips_button1"
                          : "manage-tips_button"
                      }
                      onClick={handleAddCheckbox1}
                      style={{
                        marginLeft: "10px",
                        display: "flex",
                        alignItems: "center",
                        textTransform: "capitalize",
                      }}
                      disabled={duplicateCondition2}
                    >
                      {t("settings.add")}
                    </Button>
                  </div>
                )}
              </div>
            </div>
          </Box>
        )}
      </Paper>
    </div>
  );
};
export default EposDiscount;
