import React, { useState } from "react";
import {
  IconButton,
  Box,
  Button,
  Popover,
  Divider,
  Typography,
  List,
  ListItem,
  ListItemText,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { GridColDef } from "@mui/x-data-grid";
import {
  GETTING_LOCATIONS_LIST,
  ADDRESS_LIST,
} from "../../graphicalQl/usequery/locationQuery";
import PopupWithTitle from "../popups/popupWithTitle";
import { useMutation, useQuery } from "@apollo/client";
import {
  STATUS_CHANGE_FOR_STORE,
  CLOSE_THE_STORE,
} from "../../graphicalQl/mutation/locationMutation";
import "./locationsList.css";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";
import CircularLoader from "../../loader/loader";

export default function LocationList() {
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [statusChangeForStore] = useMutation(STATUS_CHANGE_FOR_STORE, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST],
  });

  const [closeTheStore] = useMutation(CLOSE_THE_STORE, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST],
  });

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [popupShowText, setShowPopoupText] = useState<string>("");
  const [statusOfStore, setStatusOfStore] = useState<string | null>(null);
  const [popupShowTitlle, setShowPopoupTitle] = useState<string>("");
  const [popupShowTextButton2, setShowPopoupTextButton2] = useState<string>("");
  const [verificationForCloseTheStore, setVerificationForCloseTheStore] =
    useState<boolean>(false);
  const [popupOpen, setpopupOpen] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState(null);
  const open = Boolean(showOptions);
  const [locationsListWithoutAddess, setLocationsListWithoutAddress] = useState<
    any[]
  >([]);
  const [addressList, setAddressList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [searchInput, setSearchInput] = React.useState<string | null>(null);
  const [selectedStatusForFiltering, setSelectedStatusForFiltering] =
    useState<string>("all");

  const filteringObject = {
    businessId: +businessId,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
    storeName_Icontains: searchInput,
    storeStatus:
      selectedStatusForFiltering === "all" ? null : selectedStatusForFiltering,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });
  const {
    data: addressListData,
    loading: addressListLoading,
    error: addressListError,
  } = useQuery(ADDRESS_LIST, {
    context: { clientName: "addressClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (addressListData) {
      const { addressList } = addressListData;
      const { edges } = addressList;
      const filteredList =
        edges &&
        edges.map((each: any) => ({
          addressId: each.node.addressId,
          address1: each.node.address1,
        }));

      setAddressList(filteredList);
    }
  }, [addressListData]);

  useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData.storeList.edges.map(
        (each: any) => ({
          id: each.node.storeId,
          branchName: each.node.storeName,
          email: each.node.email,
          mobile: each.node.mobile,
          locationType: each.node.locationType,
          addressId: each.node.addressId,
          storeStatus: each.node.storeStatus,
        })
      );

      setLocationsListWithoutAddress(requiredDataList);
    }
  }, [locationsData]);

  useEffect(() => {
    const addressListMap = (addressList || []).reduce((map, item) => {
      map[item.addressId] = item.address1;
      return map;
    }, {});

    const updatedDataList =
      locationsListWithoutAddess &&
      locationsListWithoutAddess.map((item) => {
        const address1 = addressListMap[item.addressId];
        return address1 !== undefined ? { ...item, address1 } : item;
      });
    setLocationsList(updatedDataList);
  }, [locationsListWithoutAddess, addressList]);

  const handleEditButton = () => {
    navigate(`/location/${profileId}/update-location`);
    setAnchorEl(null);
  };
  const handleCloseTheStoreButton = () => {
    setShowPopoupText(t("locations.are_you_close") || "");
    setpopupOpen(true);
    setShowPopoupTitle(`${t("locations.close_location")} ${locationName}`);
    setShowPopoupTextButton2(t("locations.close_this_location") || "");
    setVerificationForCloseTheStore(true);
    setAnchorEl(null);
  };

  const handleDeactivateStoreButton = () => {
    if (statusOfStore === "Active") {
      setShowPopoupText(t("locations.are_you_sure_de_activete") || "");
      setShowPopoupTitle(
        `${t("locations.de_activate_location")} ${locationName}`
      );
    } else {
      setShowPopoupText(t("locations.are_you_sure_re_activete") || "");
      setShowPopoupTitle(
        `${t("locations.re_activate_location")} ${locationName}`
      );
    }
    setpopupOpen(true);
    if (statusOfStore === "Active") {
      setShowPopoupTextButton2(t("locations.de_activate_this_location") || "");
    } else {
      setShowPopoupTextButton2(t("locations.re_activate_this_location") || "");
    }
    setAnchorEl(null);
  };

  const handlePopupClose = () => {
    setpopupOpen(false);
    setProfileId(null);
    setShowPopoupTextButton2("");
    setShowPopoupTitle("");
    setShowPopoupText("");
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const PopoverOpen = Boolean(anchorEl);
  const id = PopoverOpen ? "simple-popover" : undefined;
  const [profileId, setProfileId] = React.useState<null | string>(null);
  const [locationName, setLocationName] = React.useState<null | string>(null);

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setProfileId(null);
    setVerificationForCloseTheStore(false);
    setStatusOfStore(null);
    setLocationName(null);
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    status: string,
    locationName: string
  ) => {
    setAnchorEl(event.currentTarget);
    setProfileId(id);
    setStatusOfStore(status);
    setLocationName(locationName);
  };

  const popoverContent = (
    <List style={{ padding: "0px 0px 0px 0px" }}>
      <Button
        onClick={handleEditButton}
        style={{
          textTransform: "capitalize",
          width: "180px",
          padding: "0px 0px 0px 0px",
        }}
      >
        <ListItem
          style={{ width: "100%", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleCloseTheStoreButton}
        style={{
          textTransform: "capitalize",
          width: "180px",
          padding: "0px 0px 0px 0px",
        }}
      >
        <ListItem
          style={{ width: "100%", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflow text
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              },
            }}
            primary={t("locations.close_the_location")}
          />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDeactivateStoreButton}
        style={{
          textTransform: "capitalize",
          width: "180px",
          padding: "0px 0px 0px 0px",
        }}
      >
        <ListItem
          style={{ width: "100%", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText
            primaryTypographyProps={{
              style: {
                whiteSpace: "nowrap", // Prevent text wrapping
                overflow: "hidden", // Hide overflow text
                textOverflow: "ellipsis", // Add ellipsis for overflow text
              },
            }}
            primary={
              statusOfStore === "Active"
                ? t("locations.de_activate_location")
                : t("locations.re_activate_location")
            }
          />
        </ListItem>
      </Button>
    </List>
  );

  const columns: GridColDef[] = [
    {
      field: "branchName",
      headerName: t("locations.branch_name") || "",
      headerClassName: "user-role-header-names",
      flex: 1,
    },
    {
      field: "locationType",
      //@ts-ignore
      headerName: t("locations.location_type") || "",
      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => (
        <Box className="user-list-table-row">
          {params.value === "PHYSICAL" ? "Physical" : "Mobile"}
        </Box>
      ),
    },
    {
      field: "address1",
      //@ts-ignore
      headerName: t("locations.address") || "",
      headerClassName: "user-role-header-names",
      flex: 2,
      renderCell: (params) => (
        <Box>{params.value !== null ? params.value : "NA"}</Box>
      ),
    },
    {
      field: "storeStatus",
      //@ts-ignore
      headerName: t("locations.status") || "",
      headerClassName: "user-role-header-names",
      flex: 0.8,
      renderCell: (params) => {
        let activeClassnames;
        if (params.value === "Active") {
          activeClassnames = "user-list-active-text";
        } else if (params.value === "InActive") {
          activeClassnames = "user-list-inactive-text";
        }
        return (
          <Typography className={activeClassnames}>{params.value}</Typography>
        );
      },
    },
    {
      field: "mobile",
      //@ts-ignore
      headerName: t("locations.phone") || "",
      headerClassName: "user-role-header-names",
      flex: 1,
    },
    {
      field: "email",
      //@ts-ignore
      headerName: t("locations.mail") || "",
      headerClassName: "user-role-header-names",
      flex: 1.5,
    },
    {
      field: t("buttons.actions") || "",
      //@ts-ignore

      headerClassName: "user-role-header-names",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={(event) =>
              handleClick(
                event,
                params.row.id,
                params.row.storeStatus,
                params.row.branchName
              )
            }
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "200px",
              height: "270px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </Box>
      ),
    },
  ];

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleCreateLocationButton = () => {
    navigate("/location/create-location");
  };

  const handleCloseStoreOrDeactivateStore = () => {
    if (verificationForCloseTheStore) {
      closeTheStore({
        variables: {
          storeId: profileId && +profileId,
        },
      });
    } else {
      const updatingStatus =
        statusOfStore && statusOfStore === "Active" ? "inactive" : "active";
      statusChangeForStore({
        variables: {
          storeId: profileId && +profileId,
          status: updatingStatus,
        },
      });
    }
    handlePopupClose();
  };

  const [
    filteringActiveActiveAndInactiveStatus,
    setfilteringActiveActiveAndInactiveStatus,
  ] = useState<any[]>([
    {
      label: t("locations.status"),
      value: "all",
    },
    {
      label: t("buttons.active"),
      value: "Active",
    },
    {
      label: t("buttons.in_active"),
      value: "InActive",
    },
  ]);

  useEffect(() => {
    setfilteringActiveActiveAndInactiveStatus([
      {
        label: t("locations.status"),
        value: "all",
      },
      {
        label: t("buttons.active"),
        value: "Active",
      },
      {
        label: t("buttons.in_active"),
        value: "InActive",
      },
    ]);
  }, [t]);

  const handleChangeStatusForFiltering = (status: string) => {
    setSelectedStatusForFiltering(status);
  };
  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(locationsData?.storeList?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(locationsData?.storeList?.pageInfo.startCursor || null);
  };
  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <PopupWithTitle
        button1Style="popup-button1-style"
        button2Style="popup-button2-style"
        open={popupOpen}
        functionForButton1={handlePopupClose}
        functionForCloseIcon={handlePopupClose}
        titleStyle="popup-title-style"
        widthForButtons="100%"
        button1Variant="outlined"
        button2Variant="contained"
        functionForButton2={handleCloseStoreOrDeactivateStore}
        widthForTitle="63%"
        title={popupShowTitlle}
        popUpwidth="40%"
        mainText={popupShowText}
        button1Text={t("buttons.cancel")}
        button2Text={popupShowTextButton2}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography className="locations-list-title">
          {t("locations.locations_listing")}
        </Typography>
        <Button
          onClick={handleCreateLocationButton}
          variant="contained"
          color="warning"
          sx={{ textTransform: "none", marginRight: "12px" }}
        >
          {t("locations.create_location")}
        </Button>
      </Box>

      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <DelayedInput
          width="30%"
          margin="10px 0px 10px 10px"
          setSearchValue={setSearchInput}
          placeHolder={t("users.search_by_name")}
          size={"medium"}
        />

        <FormControl sx={{ width: "15%" }}>
          <InputLabel
            className="create-a-use-role-label"
            id="assign-role-label"
          >
            {t("locations.status")}
          </InputLabel>
          <Select
            color="warning"
            labelId="assign-role-label"
            label={<Typography>{t("locations.status")}</Typography>}
            value={selectedStatusForFiltering}
            onChange={(e) => handleChangeStatusForFiltering(e.target.value)}
          >
            {filteringActiveActiveAndInactiveStatus.map((each) => (
              <MenuItem key={each.value} value={each.value}>
                {each.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box
        sx={{
          padding: "10px",
          height: "70vh",
          width: "100%",
        }}
      >
        {locationsLoading ? (
          <CircularLoader />
        ) : (
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={locationsList}
            columns={columns}
            getRow={getRow}
            totalCount={locationsData?.storeList?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        )}
      </Box>
    </Box>
  );
}
