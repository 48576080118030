import { Box, Grid, Typography } from "@mui/material";
import calenderImg from "../../../assets/icons/calendar-today.png";
import deliveryImg from "../../../assets/icons/fast-delivery-svgrepo-com 1.png";
import locationImg from "../../../assets/icons/location-on.png";
import mobileImg from "../../../assets/icons/mobile.png";
import shareOImg from "../../../assets/icons/share-o.png";
import MyButton from "../../components/Button";
import { CloudDownload } from "@mui/icons-material";
import moment from "moment";
import { localeToFormat } from "../../../utils/dateFormat";
import { useTranslation } from "react-i18next";

const LeftDetails = (props: any) => {
  const userLang = navigator.language;
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const { t } = useTranslation();
  const {
    detailData,
    addressData,
    customerDetail,
    cart = [],
    orderDate,
    itemTotal,
    loyaltyAmount,
  } = props;

  const deliveryTime = detailData?.requestedTime;

  const orderType =
    detailData?.orderType === "dine_in" ? "Dine In" : detailData?.orderType;

  return (
    <Grid container>
      <Box
        display={"flex"}
        gap={"10px"}
        alignItems={"start"}
        justifyContent="space-between"
        className="css-my-text-gray-1 bg-white"
        sx={{ padding: "20px", width: "100%" }}
        flexWrap={"wrap"}
      >
        <Box display={"flex"} flexDirection={"column"} gap={"10px"}>
          <Box
            display={"flex"}
            gap={"20px"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Box>{t("orders.created_date")}</Box>
            <Box>
              <img src={calenderImg} />
            </Box>
            <Box>
              {moment(orderDate).format(`${localeToFormat[userLang]} hh:mm a`)}
            </Box>
          </Box>
          <Box
            display={"flex"}
            gap={"20px"}
            alignItems={"center"}
            justifyContent="space-between"
          >
            <Box>{t("orders.requested_date")}</Box>
            <Box>
              <img src={calenderImg} />
            </Box>
            <Box>
              {moment(deliveryTime).format(
                `${localeToFormat[userLang]} hh:mm a`
              )}
            </Box>
          </Box>
          <Box
            display={"flex"}
            gap={"20px"}
            alignItems={"center"}
            // justifyContent="space-between"
          >
            <Box>{t("orders.payment")}:</Box>
            <Box className="complete-green">{detailData?.paymentStatus}</Box>
          </Box>
          <Box
            display={"flex"}
            gap={"20px"}
            alignItems={"center"}
            // justifyContent="space-between"
          >
            <Box>{t("orders.payment_type")}:</Box>
            <Box className="online-orange">{detailData?.paymentType}</Box>
          </Box>
          <Box display={"flex"} gap={"20px"} justifyContent="space-between">
            <Box>{t("orders.note")}</Box>
            <Box style={{ width: "255px" }}>{detailData?.notes}</Box>
          </Box>
        </Box>
        <Box
          display={"flex"}
          flexDirection={"column"}
          justifyContent={"space-between"}
          gap={"10px"}
        >
          <Box display={"flex"} gap={"20px"} alignItems={"center"}>
            <Box>{t("orders.status")}:</Box>
            <Box className="complete-green">{detailData?.orderStatus}</Box>
          </Box>
          <Box display={"flex"} gap={"10px"} alignItems={"center"}>
            <Box>{t("orders.order_type")}:</Box>
            <Box>
              <img src={deliveryImg} />
            </Box>
            <Box>{orderType}</Box>
          </Box>
          {addressData && (
            <>
              <Box
                display={"flex"}
                gap={"20px"}
                alignItems={"center"}
                justifyContent="space-between"
              >
                <Box>{t("orders.address")}</Box>
                <Box></Box>
              </Box>
              <Box
                display={"flex"}
                gap={"20px"}
                alignItems={"start"}
                justifyContent="space-between"
              >
                <Box>
                  {addressData?.addressType
                    ? addressData?.addressType
                    : t("orders.home")}
                  :
                </Box>
                <Box>
                  <Box display={"flex"} gap={"5px"} alignItems={"center"}>
                    <Box>
                      <img src={locationImg} />
                    </Box>
                    <Box>
                      {addressData?.address1} {addressData?.address2},{" "}
                      {addressData?.city}, {addressData?.state},{" "}
                      {addressData?.postCode}
                    </Box>
                  </Box>
                  <Box display={"flex"} gap={"10px"} alignItems={"center"}>
                    {addressData?.receiverMobile && (
                      <Box sx={{ marginLeft: "4px" }}>
                        <img src={mobileImg} />
                      </Box>
                    )}

                    <Box>{addressData?.receiverMobile}</Box>
                    <Box>{addressData?.receiverName}</Box>
                  </Box>
                </Box>
              </Box>
            </>
          )}

          {/* <Box sx={{ marginTop: "10px" }}>
            <MyButton icon={locationImg} lable="View location on map" />
          </Box> */}
        </Box>
      </Box>
      <Box
        className=" bg-white "
        sx={{ padding: "20px", marginTop: "20px", width: "100%" }}
      >
        <Box
          display={"flex"}
          gap={"5px"}
          alignItems={"center"}
          justifyContent="space-between"
          className="css-my-text-gray-1"
          sx={{ flexWrap: "wrap" }}
        >
          <Box sx={{ color: "black", fontSize: "16px", fontWeight: "600" }}>
            {t("orders.items")}
          </Box>
          {/* <Box display={"flex"} gap={"10px"} alignItems={"center"}>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              className="css-btn-2"
            >
              <CloudDownload sx={{ color: "#F38B08" }} />
              <Box>Download</Box>
            </Box>
            <Box
              display={"flex"}
              alignItems={"center"}
              gap={"10px"}
              className="css-btn-2"
            >
              <img src={shareOImg} />
              <Box>Share</Box>
            </Box>
          </Box> */}
        </Box>
        <Box className="border-gray"></Box>
        {cart?.map((item: any, index: any) => {
          return (
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
              gap={"10px"}
              marginTop={"10px"}
            >
              <Box>
                <Box display={"flex"} gap={"15px"} alignItems={"start"}>
                  {/* <Box
                    className={
                      item.vegType === "non-veg" ? `non-veg-logo` : `veg-logo`
                    }
                  >
                    <Circle sx={{ fontSize: "12px" }} />
                  </Box> */}
                  <Box>
                    <Box
                      sx={{ textTransform: "capitalize" }}
                      className="item-name-d"
                    >
                      {item?.quantity}*{item?.product_name}{" "}
                      {item?.entered_weight == null
                        ? ""
                        : `(${item?.entered_weight} ${item?.unit})`}
                    </Box>
                    <Box style={{ display: "flex" }}>
                      {item?.products?.map((el: any) => (
                        <Box className="item-desc-d">{el.name},&nbsp;</Box>
                      ))}
                    </Box>
                    <Box style={{ display: "flex" }}>
                      {item?.option_values?.map((el: any) => (
                        <Box className="item-desc-d">{el.name},&nbsp;</Box>
                      ))}
                    </Box>
                    <Box style={{ display: "flex" }}>
                      {item?.notes && (
                        <Typography className="item-name-d">
                          {t("orders.notes")}:{" "}
                          <span className="item-desc-d">{item?.notes}</span>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </Box>
              </Box>
              <Box className="price-d"> £ {item.item_total}</Box>
            </Box>
          );
        })}
        <Box className="border-gray-dash"></Box>
        <Box className="css-my-text-gray-2">
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.item_total")}</Box>
            <Box>
              {" "}
              {currencySymbol} {itemTotal ? itemTotal : "0.00"}
            </Box>
          </Box>
        </Box>
        <Box className="border-gray-dash-0"></Box>
        <Box
          className="css-my-text-gray-2"
          display={"flex"}
          flexDirection={"column"}
          gap={"8px"}
        >
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.discount")}</Box>
            <Box sx={{ color: "#3E9514", fontWeight: "400" }}>
              {currencySymbol} {detailData?.discountAmount}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.charges")}</Box>
            <Box>
              {" "}
              {currencySymbol}{" "}
              {detailData?.serviceCharges ? detailData?.serviceCharges : "0.00"}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.taxes")}</Box>
            <Box>
              {" "}
              {currencySymbol} {detailData?.tax}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.tip")}</Box>
            <Box>
              {" "}
              £ {detailData?.tipAmount ? detailData?.tipAmount : "0.00"}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.loyalty_points")}</Box>
            <Box sx={{ color: "#3E9514", fontWeight: "400" }}>
              {loyaltyAmount ? `£ ${loyaltyAmount}` : "0"}
            </Box>
          </Box>
          <Box
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Box>{t("orders.delivery_fee")}</Box>
            <Box>
              {" "}
              £{" "}
              {detailData?.deliveryCharges
                ? detailData?.deliveryCharges
                : "0.00"}
            </Box>
          </Box>
        </Box>
        <Box className="border-gray-dash-0"></Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Box
            sx={{
              fontWeight: "500",
              color: "#3E9514",
              fontSize: "14px",
              lineHeight: "21px",
            }}
          >
            {/* Paid via {detailData?.paymentType} */}
          </Box>
          <Box
            sx={{
              color: "black",
              fontWeight: "600",
              fontSize: "16px",
              lineHeight: "24px",
            }}
          >
            {t("orders.total_bill")} {currencySymbol}{" "}
            {detailData?.payableAmount}
          </Box>
        </Box>
      </Box>
    </Grid>
  );
};

export default LeftDetails;
