import { Box, Button, Sheet, Typography } from "@mui/joy";
import React, { useState } from "react";
import CloseIcon from "../../../../assets/close2.png";
import { TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
interface Props {
  editOrderStatusNameModal: any;
  setEditOrderStatusNameModal: any;
}

const EditOrderStatusName = (props: Props) => {
  const { t } = useTranslation();
  const { editOrderStatusNameModal, setEditOrderStatusNameModal } = props;
  const [alternateName, setAlternateName] = useState(
    editOrderStatusNameModal?.data?.alternateName
  );
  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "520px",
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() =>
            setEditOrderStatusNameModal({
              state: false,
              data: {},
            })
          }
        />
      </Box>

      <Box
        sx={{
          marginLeft: "22px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            textAlign: "center",
            marginBottom: "32px",
          }}
        >
          {t("buttons.edit")} '{editOrderStatusNameModal?.data?.alternateName}'{" "}
          {t("orders.order_status_to_be")}
        </Typography>
        <TextField
          fullWidth
          sx={{ width: "220px", margin: "auto", marginBottom: "32px" }}
          label={t("orders.alternate_name")}
          variant="outlined"
          placeholder={t("orders.alternate_name") || ""}
          InputLabelProps={{ shrink: true }}
          value={alternateName}
          onChange={(e) => setAlternateName(e.target.value)}
        />
        <Button
          onClick={() =>
            editOrderStatusNameModal?.data?.updateOrderStatusDetails(
              editOrderStatusNameModal?.data.id - 1,
              alternateName
            )
          }
          sx={{
            width: "77px",
            marginLeft: "auto",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#FEFEFE",
              textTransform: "capitalize",
            }}
          >
            {t("buttons.save")}
          </Typography>
        </Button>
      </Box>
    </Sheet>
  );
};

export default EditOrderStatusName;
