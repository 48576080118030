// import * as React from "react";
// import Box from "@mui/material/Box";
// import Stepper from "@mui/material/Stepper";
// import Step from "@mui/material/Step";
// import StepLabel from "@mui/material/StepLabel";

// export default function Steps(props: any) {
//   const { orderStatus, deliveryStatus,activeSteps } = props
//   const [hiddenStatusbar,setHiddenStatusBar]=React.useState<boolean>(false)
//   const [activeStep, setActiveStep] = React.useState<number>(0);
//   const hiddenStatusBarList=["Hold","Void","Cancelled","Rejected"]
//   const index = activeSteps.findIndex((step:any) => step === orderStatus);
//   React.useEffect(() => {
//      if(hiddenStatusBarList.includes(orderStatus)){
//       setHiddenStatusBar(true)
//     }
//     else if (activeSteps.includes(orderStatus)) {
//       setActiveStep(index)
//     }
//     else if (orderStatus==="Pending") {
//       setActiveStep(0)

//     }else if(orderStatus==="YetToPrepare"){
//       setActiveStep(1)

//     }
//   }, [orderStatus,activeSteps])

//   return (

//     <Box sx={{ width: "100%" }}>
//       {
//         !hiddenStatusbar&&(
//           <Stepper activeStep={activeStep} sx={{ overflowX: "auto" }}>
//         {activeSteps.map((label:any, index:any) => {
//           const stepProps: { completed?: boolean } = {};
//           const labelProps: {
//             optional?: React.ReactNode;
//           } = {};
//           return (
//             <Step  key={label} {...stepProps}>
//               <StepLabel sx={{
//               "&.Mui-completed":{
//                 color:"#f38b08"
//               }
//             }} {...labelProps}>{label}</StepLabel>
//             </Step>
//           );
//         })}
//       </Stepper>

//         )
//       }

//     </Box>
//   );
// }

import * as React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepIcon from "@mui/material/StepIcon";
import { styled } from "@mui/system";
import { useTranslation } from "react-i18next";

// Custom StepIcon with color for completed state
const CustomStepIcon = styled(StepIcon)(({ theme }) => ({
  "&.MuiStepIcon-root": {
    color: "#707070",
    fontSize: "30px",
  },
  "&.Mui-completed": {
    color: "#f38b08",
    fontSize: "30px",
  },
  "&.Mui-active": {
    color: "#f38b08",
    fontSize: "30px",
  },
}));

export default function Steps(props: any) {
  const { t } = useTranslation();
  const { orderStatus, deliveryStatus, activeSteps } = props;
  const [hiddenStatusbar, setHiddenStatusBar] = React.useState<boolean>(false);
  const [activeStep, setActiveStep] = React.useState<number>(0);
  const hiddenStatusBarList = ["Hold", "Void", "Cancelled", "Rejected"];
  const index = activeSteps.findIndex((step: any) => step === orderStatus);
  const [steps, setSteps] = React.useState<any[]>([]);
  React.useEffect(() => {
    const transformedActiveSteps = activeSteps.map((step: any) => ({
      value: step,
      text:
        step === "On the way" ? t(`orders.on_the_way`) : t(`orders.${step}`),
    }));
    setSteps(transformedActiveSteps);
  }, [t, activeSteps]);


  React.useEffect(() => {
    if (hiddenStatusBarList.includes(orderStatus)) {
      setHiddenStatusBar(true);
    } else if (activeSteps.includes(orderStatus)) {
      setActiveStep(index);
    } else if (orderStatus === "Pending") {
      setActiveStep(0);
    } else if (orderStatus === "YetToPrepare") {
      setActiveStep(1);
    }
  }, [orderStatus, activeSteps]);

  return (
    <Box sx={{ width: "100%" }}>
      {!hiddenStatusbar && (
        <Stepper activeStep={activeStep} sx={{ overflowX: "auto" }}>
          {steps.map((label: any, index: any) => {
            const stepProps: { completed?: boolean } = {};
            const labelProps: {
              optional?: React.ReactNode;
            } = {};
            return (
              <Step key={label.value} {...stepProps}>
                <StepLabel StepIconComponent={CustomStepIcon} {...labelProps}>
                  {label.text}
                </StepLabel>
              </Step>
            );
          })}
        </Stepper>
      )}
    </Box>
  );
}
