import { Box, Typography } from "@mui/material";
import paymentTypeIcon from "../../../assets/mianDashboard/paymentTypeIcon.svg";
// import paymentTypeImage from "../../../assets/mianDashboard/PaymentsTypeImage.svg";
import PaymentTypeGraph from "./paymentTypeGraph";
import React from "react";
import { Payments } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

interface Props {
  payments?: Payments;
}

const PaymentTypes = ({ payments }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "45.1%" },
        minHeight: "420px",
        height: "100%",
        width: "100%",
        background: "#FFF",
        borderRadius: "8px",
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          paddingY: "20px",
          paddingX: "32px",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", gap: "17px" }}>
          <img src={paymentTypeIcon} alt="paymentTypeIcon" />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "18px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {t("dashboard.Payment_Types")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
            maxWidth: "440px",
            margin: "0 auto",
          }}
        >
          <PaymentTypeGraph
            label={Object.keys(payments || {})}
            value={Object.values(payments || {})}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default PaymentTypes;
