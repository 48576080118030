/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";
import InvoiceReceipt from "../../../assets/invoice-receipt.svg";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { RECEIPTS_REPORTS } from "../../../graphicalQl/usequery/receiptReport";
import { ReceiptsReportType } from "../../../types/receiptsReports";
import { convertSnakeCaseToPascalCase } from "../../../utils/stringUtils";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import moment from "moment";
import { localeToFormat } from "../../../utils/dateFormat";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Receipt Number", value: "reciept_number" },
  { label: "Date", value: "date" },
  { label: "Employee", value: "employee" },
  { label: "Customer", value: "customer" },
  { label: "Type", value: "type" },
  { label: "Total", value: "total" },
];

const TaxReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Receipt Number", value: "reciept_number" },
    { label: "Date", value: "date" },
    { label: "Employee", value: "employee" },
    { label: "Customer", value: "customer" },
    { label: "Type", value: "type" },
    { label: "Total", value: "total" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "reciept_number",
    "date",
    "employee",
    "customer",
    "type",
    "total",
  ]);
  const [receiptReports, setReceiptReports] = useState<
    Array<ReceiptsReportType>
  >([]);
  const [receiptType, setReceiptType] = useState<string>("all_receipts");

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext);

  // const [locationId, setLocationId] = useState<number>(44);
  const [employee, setEmployee] = useState<number>();
  const { reportLocation, reportEmployee } = useSelector(
    (state: any) => state.reportData
  );
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const {
    loading: receiptReportsLoading,
    data: receiptReportsData,
    refetch,
  } = useQuery(
    RECEIPTS_REPORTS(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportLocation || +locationId,
      receiptType,

      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const userLang = navigator.language;
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const getReceiptReports = () => {
    if (!receiptReportsLoading) {
      const recieptsReports = receiptReportsData?.recieptsReports;
      const newReceiptReports = JSON.parse(recieptsReports || "[]").map(
        (receipt: any) => ({
          ...receipt,
          date: moment(receipt.date).format(
            `${localeToFormat[userLang]}, hh:mm A`
          ),
          type: convertSnakeCaseToPascalCase(receipt.type),
          total: `${currencySymbol} ${receipt.total.toFixed(2)}`,
        })
      );
      setReceiptReports(newReceiptReports);
    }
  };

  useEffect(() => {
    getReceiptReports();
  }, [receiptReportsLoading]);

  useEffect(() => {
    refetch();
  }, [receiptType]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "120px",
          justifyContent: "center",
          paddingTop: "25px",
          paddingBottom: "21px",
          backgroundColor: "#FFF",
        }}
      >
        <Box
          sx={{
            display: "flex",
            columnGap: "3vw",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
            paddingTop: "21px",
            ...(receiptType === "all_receipts" && {
              borderBottom: "5px solid #F38B08",
            }),
          }}
          onClick={() => setReceiptType("all_receipts")}
        >
          <img src={InvoiceReceipt} alt="invoice-icon" />
          <Typography
            sx={{
              color: receiptType === "all_receipts" ? "#F38B08" : "#707070",
              fontFamily: "Poppins",
              fontSize: "1.6vw",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            All Receipts
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            columnGap: "3vw",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
            borderLeft: "2px solid #EEE",
            borderRight: "2px solid #EEE",
            paddingTop: "21px",
            ...(receiptType === "sales_receipts" && {
              borderBottom: "5px solid #F38B08",
            }),
          }}
          onClick={() => setReceiptType("sales_receipts")}
        >
          <img src={InvoiceReceipt} alt="invoice-icon" />
          <Typography
            sx={{
              color: receiptType === "sales_receipts" ? "#F38B08" : "#707070",
              fontFamily: "Poppins",
              fontSize: "1.6vw",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Sales Receipts
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            columnGap: "3vw",
            alignItems: "center",
            justifyContent: "center",
            width: "30%",
            paddingTop: "21px",
            ...(receiptType === "refunded_receipts" && {
              borderBottom: "5px solid #F38B08",
            }),
          }}
          onClick={() => setReceiptType("refunded_receipts")}
        >
          <img src={InvoiceReceipt} alt="invoice-icon" />
          <Typography
            sx={{
              color:
                receiptType === "refunded_receipts" ? "#F38B08" : "#707070",
              fontFamily: "Poppins",
              fontSize: "1.6vw",
              fontStyle: "normal",
              fontWeight: 500,
            }}
          >
            Refunded Receipts
          </Typography>
        </Box>
      </Box>
      <Box mt={0}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={receiptReports}
          title={"Taxes"}
        />
      </Box>
    </Box>
  );
};

export default TaxReport;
