import { Box, Typography } from "@mui/material";
import "chart.js/auto";
import {
  Chart as ChartJS,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import React, { FC } from "react";
import { Bar } from "react-chartjs-2";
import TrendingUp from "../../../../assets/bar-chart.png";
import { SalesGraphData } from "../../../../types/salesReport";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);
interface Props {
  labels: string[];
  graphDataset: SalesGraphData;
}

const SalesGraphMain: FC<Props> = ({ labels, graphDataset }) => {
  const getOrCreateLegendList = (id: any) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.gap = "26px";
      listContainer.style.paddingLeft = "16px";
      listContainer.style.marginTop = "0px";
      listContainer.style.marginBottom = "0px";
      listContainer.style.justifyContent = "end";
      listContainer.style.position = "relative";
      listContainer.style.top = "-30px";

      legendContainer && legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList("legend-container-customers");

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "default";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.fontSize = "12px";

        li.onclick = () => {
          // const { type } = chart.config;
          // if (type === "pie" || type === "doughnut") {
          //   chart.toggleDataVisibility(item.index);
          // } else {
          //   chart.setDatasetVisibility(
          //     item.datasetIndex,
          //     !chart.isDatasetVisible(item.datasetIndex)
          //   );
          // }
          // chart.update();
        };

        // Color box

        let boxSpan = null;
        boxSpan = document.createElement("span");
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "16px";
        boxSpan.style.marginRight = "6px";
        boxSpan.style.width = "16px";
        boxSpan.style.borderRadius = "2px";

        // TextPayment Types
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.fontSize = "12px";
        textContainer.style.fontWeight = "500";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
  const currencySymbol = sessionStorage.getItem("currencySymbol")

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      title: {
        display: false,
        text: "Customers",
      },
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        bodySpacing: 10,
        enabled: true,
        backgroundColor: "#FFF8F0",
        bodyColor: "#F38B08",
        titleColor: "#F38B08",
        title: false,
        callbacks: {
          title: () => null,
        },
        padding: {
          x: 20,
          y: 10,
        },
        bodyFont: {
          weight: 700,
        },
      },
    },

    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        grid: { drawOnChartArea: false },
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        grid: { drawOnChartArea: false },
        display: true,
        title: {
          display: true,
        },
      },
    },
  };


  const data = {
    labels: labels,
    datasets: [
      {
        label: `Orders (${currencySymbol})`,
        data: graphDataset.orders,
        borderColor: "#BAE29B",
        backgroundColor: "#BAE29B",
        barPercentage: 0.5,
        tension: 0.5,
        barThickness: 10,
        borderRadius: 20,
      },
      {
        label: `Discounts (${currencySymbol})`,
        data: graphDataset.discounts,
        borderColor: "#F9C584",
        backgroundColor: "#F9C584",
        tension: 0.5,
        barThickness: 10,
        borderRadius: 20,
        gap: 10,
      },
      {
        label: `Gift Cards (${currencySymbol})`,
        data: graphDataset.giftCards,
        borderColor: "#AE9BE2",
        backgroundColor: "#AE9BE2",
        tension: 0.5,
        barThickness: 10,
        maxBarThickness: 14,
        borderRadius: 20,
      },
    ],
  };

  return (
    <>
      <Box
        sx={{
          maxWidth: "100%",
          width: "100%",
          background: "#FFF",
          borderRadius: "8px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          paddingTop: "12px",
          paddingLeft: { xs: "0px", md: "12px" },
          paddingRight: "6px",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "16px",
            alignItems: "center",
          }}
        >
          <img
            height={44}
            width={44}
            src={TrendingUp}
            alt="trending"
            style={{ height: "44px", width: "44px" }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "20px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            Sales Summary
          </Typography>
        </Box>
      </Box>
      <Box id="legend-container-customers"></Box>

      <Box
        sx={{
          position: "relative",
          margin: "auto",
          height: { sm: "350px", xs: "auto" },
          width: { lg: "94%", xs: "100%" },
          marginTop: "20px",
        }}
      >
        <Bar plugins={[htmlLegendPlugin]} options={options as any} data={data} />
      </Box>
    </>
  );
};

export default SalesGraphMain;
