import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string
// excludedEmployeeId: $excludedEmployeeId
// $excludedEmployeeId: Int
export const EmplpoyeesList = gql`
  query Employees(
    $businessId: ID
    $locationId: ID
    $query: String
    $first: Int
    $after: String
    $before: String
    $last: Int
    $excludedEmployeeId: Int
  ) {
    employees(
      businessId: $businessId
      locationId: $locationId
      query: $query

      excludedEmployeeId: $excludedEmployeeId
      first: $first
      after: $after
      last: $last
      before: $before
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          employeeId
          firstName
          lastName
          mobile
          permissions
          employeeStatus
          pin
          createdOn
          email
          role {
            roleId
            roleName
          }
          businessId
          uuid
        }
      }
    }
  }
`;

export const LOCATIONS_LIST = gql`
  query ($businessId: Int) {
    storeList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          storeId
          locationType
          address
          mobile
          email

          storeName
          brandLogo
          storeStatus
          currency
        }
      }
    }
  }
`;

export const User_Roles = gql`
  query Roles(
    $businessId: ID
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    roles(
      businessId: $businessId
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          roleId
          roleName
          noOfUsers
          description
          businessId
        }
      }
    }
  }
`;

export const No_OF_PERMISSIONS = gql`
  query {
    permissions(first: 100) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          permissionId
          name
          appliesFor
        }
      }
    }
  }
`;

export const USER_REQUESTS = gql`
  query UnblockRequestList(
    $businessId: ID
    $first: Int
    $after: String
    $before: String
    $last: Int
    $query: String
  ) {
    unblockRequestList(
      businessId: $businessId
      first: $first
      after: $after
      before: $before
      query: $query
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          employee {
            employeeId
            firstName
            lastName
            email
            mobile
            role {
              roleId
              roleName
            }
            pin
            createdOn
          }
          employeeStatus
          createdOn
          businessId
        }
      }
    }
  }
`;

export const SEARCH_PERMISSIONS_BY_ROLE_ID = gql`
  query role($roleId: ID) {
    role(roleId: $roleId) {
      roleId
      permissions {
        permissionId
        appliesFor
        name
      }
    }
  }
`;
export const GET_UPDATE_USER_DETAILS = gql`
  query employee($employeeId: ID) {
    employee(employeeId: $employeeId) {
      employeeId
      firstName
      lastName
      locations
      mobile
      email
      role {
        roleName
        roleId
      }
    }
  }
`;

export const USER_ROLES_WIITH_LOCATION = gql`
  query locationBasedRoles($businessId: ID, $locationId: Int) {
    locationBasedRoles(businessId: $businessId, locationId: $locationId)
  }
`;

export const GET_EDIT_USER_ROLE = gql`
  query RoleQuery($roleId: ID!) {
    role(roleId: $roleId) {
      roleId
      roleName
      permissions {
        permissionId
        appliesFor
        name
      }
    }
  }
`;

export const VIEW_ALL_PERMISSIONS_QUERY = gql`
  query ROLE($roleId: ID) {
    role(roleId: $roleId) {
      roleId
      roleName
      permissions {
        permissionId
        name
        appliesFor
      }
    }
  }
`;

export const TWO_STEP_VERIFICATION_PERMISSION_LIST = gql`
  query twoStepVerificationList($businessId: ID) {
    twoStepVerificationList(businessId: $businessId) {
      businessId
      permissionsList
    }
  }
`;
export const NUMBER_OF_PERMISSIONS_BY_ROLE_ID = gql`
  query role($roleId: ID) {
    role(roleId: $roleId) {
      roleId
      roleName
      permissions {
        permissionId
        appliesFor
        name
      }
    }
  }
`;

export const USER_PROFILE_DETAILS = gql`
  query employee($employeeId: ID) {
    employee(employeeId: $employeeId) {
      employeeId
      lastName
      uuid
      firstName
      permissions
      clockOutAt
      mobile
      email
      clockInAt
      role {
        roleId
        roleName
      }
    }
  }
`;
export const USER_ORDER_DETAILS = gql`
  query orders(
    $storeId: ID
    $dateOf: Date
    $employeeId: UUID
    $businessId: ID
  ) {
    orders(
      businessId: $businessId
      storeId: $storeId
      dateOf: $dateOf
      employeeId: $employeeId
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          orderId
          orderType
          customerId
          storeId
          businessId
          tipAmount
          orderStatus
          deliveryStatus
          tipAmount
        }
      }
    }
  }
`;

export const USER_REPORT = gql`
  query userReport(
    $employeeId: UUID
    $isDriver: Boolean
    $fromDate: Date
    $toDate: Date
  ) {
    userReport(
      employeeId: $employeeId
      isDriver: $isDriver
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;
export const USER_TOTAL_HOURS = gql`
  query employeeTotalHours($employeeId: ID, $fromDate: Date, $toDate: Date) {
    employeeTotalHours(
      employeeId: $employeeId
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;
export const EMPLOYEES_LIST = gql`
  query employees($businessId: Id, $locationId: ID) {
    employees(businessId: $businessId, locationId: $locationId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          employeeId
          uuid
          clockInAt
          isDeleted
          firstName
          lastName
          email
          isDeleted
          role {
            roleId
            roleName
          }
          businessId
          permissions
        }
      }
    }
  }
`;

export const GETTING_IS_ENABLED = gql`
  query business($businessId: ID) {
    business(businessId: $businessId) {
      businessId
      brandName
      legalBusinessName
      documents
      connectAccount
      email
      mobile
      addressId
      enableTwoStepVerification
    }
  }
`;
