import React, { FC, useEffect, useState } from "react";

import {
  OrderGraphDataset,
  OrderGraphType,
} from "../../../../types/orderReport";
import OrdersGraphMain from "./ordersGraph";

interface Props {
  ordersGraphData: OrderGraphType[];
}
const OrdersGraph: FC<Props> = ({ ordersGraphData }) => {
  const [labels, setLabels] = useState<Array<string>>([]);
  const [graphDataset, setGraphDataset] = useState<OrderGraphDataset>({
    delivery: [0],
    dineIn: [0],
    collection: [0],
    shop: [0],
  });

  useEffect(() => {
    const tempLabels = ordersGraphData?.map((cus) => {
      return cus.date;
    });
    const tempDineIn = ordersGraphData?.map((cus) => {
      return cus.orders;
    });
    const tempDelivery = ordersGraphData?.map((cus) => {
      return cus.delivery_orders;
    });
    const tempCollection = ordersGraphData?.map((cus) => {
      return cus.collection_orders;
    });
    const tempShop = ordersGraphData?.map((cus) => {
      return cus.shop_orders;
    });
    setGraphDataset({
      dineIn: tempDineIn,
      delivery: tempDelivery,
      collection: tempCollection,
      shop: tempShop,
    });
    setLabels(tempLabels);
  }, [ordersGraphData]);


  return (
    <>
      <OrdersGraphMain labels={labels} graphDataset={graphDataset}/>
    </>
  );
};

export default OrdersGraph;
