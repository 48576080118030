import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import "./posSettingsGeneralSettings.css";
import { useNavigate, useParams } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  DEVICE_LIST_FOR_KDS,
  GET_PAIRED_DEVICE_DETAILS,
} from "../../graphicalQl/usequery/deviceManagementQuery";
import { UPDATE_DEVICE_IN_POS } from "../../graphicalQl/mutation/deviceMangementMutation";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

interface State {
  storeName: string | null;
  pairedDevice: string | null;
  allowOrder: boolean;
  headerDeviceName: string | null;
}

const validationSchemaPart1 = Yup.object({
  pairedDevice: Yup.string().required(" Device Name required"),
});

const initialValues: State = {
  pairedDevice: null,
  storeName: null,
  allowOrder: false,
  headerDeviceName: null,
};

function PosSettingsGeneralSettings() {
  const { t } = useTranslation();
  const params = useParams();
  const { editId } = params;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [collectedMenuList, setCollectedMenuList] = useState<any[]>([]);

  const navigate = useNavigate();

  const [updateDevice] = useMutation(UPDATE_DEVICE_IN_POS, {
    onCompleted: (data) => {
      // console.log(data);
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });

  const [noOfLocationsSelected, setNoOfLocationsSelected] = React.useState<
    number | null
  >(0);
  const [searchInput, setSearchInput] = React.useState("");
  const [allCheckboxChecked, setAllCheckboxChecked] =
    React.useState<boolean>(false);
  const [menuList, setMenuList] = useState<any[]>([]);
  const {
    data: deviceData,
    loading: deviceLoading,
    error: deviceError,
  } = useQuery(GET_PAIRED_DEVICE_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { deviceId: editId && +editId },
    fetchPolicy: "network-only",
  });
  const {
    data: menuData,
    loading: menuLoading,
    error: menuError,
  } = useQuery(MENU_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: +locationId },
  });
  useEffect(() => {
    if (menuData) {
      const { menuList } = menuData;
      if (menuList) {
        const { edges } = menuList;
        const filteredDataFromMenuData =
          edges &&
          edges.map((each: any) => ({
            menuId: each.node.menuId,
            menuName: each.node.name,
            menuStatus: each.node.menuStatus,
            isChecked: false,
          }));

        const activeMenuData = filteredDataFromMenuData.filter(
          (each: any) => each.menuStatus === "Active"
        );

        const filtereData = activeMenuData.map((each: any) => {
          if (collectedMenuList.includes(+each.menuId)) {
            return { ...each, isChecked: true };
          }
          return each;
        });

        const checkedList = filtereData.filter((each: any) => each.isChecked);
        setMenuList(filtereData);
        setAllCheckboxChecked(checkedList.length === filtereData.length);
        setMenuList(filtereData);
      }
    }
  }, [menuData, collectedMenuList]);

  useEffect(() => {
    if (deviceData) {
      const { pairedDevice } = deviceData;
      if (pairedDevice) {
        const { deviceName, store, deviceSettings } = pairedDevice;
        const parsed = JSON.parse(deviceSettings);
        if (parsed) {
          const { allow_popup_notifications, select_menus } = parsed;
          setCollectedMenuList(select_menus);
          formik.setValues({
            ...formik.values,
            pairedDevice: deviceName,
            headerDeviceName: deviceName,
            storeName: store && store.storeName,
            allowOrder: allow_popup_notifications,
          });
        }
      }
    }
  }, [deviceData, menuData]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const handleSaveButton = () => {
    const collectedVariables = {
      deviceId: editId && +editId,
      businessId: +businessId,
      settings: JSON.stringify({
        device_name: formik.values.pairedDevice,
        allow_popup_notifications: formik.values.allowOrder,
        select_menus: JSON.stringify(
          menuList
            .filter((each: any) => each.isChecked)
            .map((each: any) => +each.menuId)
        ),
      }),
    };
    const filteredIdsFromKds = Object.fromEntries(
      Object.entries(collectedVariables).filter(([_, value]) => value !== null)
    );
    updateDevice({
      variables: filteredIdsFromKds,
    });
    navigate("/devices/device-list");
  };

  const [editToggle, setEditToggle] = useState<boolean>(false);
  const handleEditIcon = () => {
    setEditToggle(true);
  };
  const handleChangeLocationsSearch = (search: string) => {
    setSearchInput(search);
  };

  let locationsText;
  if (noOfLocationsSelected === 0) {
    locationsText = "Select Item";
  } else if (noOfLocationsSelected === 1) {
    locationsText = "Item";
  } else {
    locationsText = "Items";
  }

  const handlePopupCheckboxByAll = (isChecked: boolean) => {
    setAllCheckboxChecked(isChecked);
    const filteredDataList = menuList.map((each: any) => {
      return { ...each, isChecked: isChecked };
    });
    setMenuList(filteredDataList);

    if (isChecked) {
      setNoOfLocationsSelected(menuList.length);
    } else {
      setNoOfLocationsSelected(0);
    }
  };
  const handlePopupCheckbox = (id: string, isChecked: boolean) => {
    const filteredList = menuList.map((each: any) => {
      if (each.menuId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });

    setMenuList(filteredList);
    const checkedCount = filteredList.filter(
      (location: any) => location.isChecked
    ).length;
    setNoOfLocationsSelected(checkedCount);
    setAllCheckboxChecked(checkedCount === filteredList.length);
  };
  const searchResults =
    menuList &&
    menuList.filter((eachLocation: any) =>
      eachLocation.menuName.toLowerCase().includes(searchInput)
    );
  const handleArrowBackButton = () => {
    navigate("/devices/device-list");
  };

  return (
    <Box sx={{ padding: "2%" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "1%",
          }}
        >
          <IconButton
            onClick={handleArrowBackButton}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="kiosk-settings-title">
            {formik.values.headerDeviceName}
          </Typography>
          <Button
            type="submit"
            sx={{ textTransform: "none" }}
            color="warning"
            variant="contained"
          >
            {t("buttons.save")}
          </Button>
        </Box>
        <Typography className="kiosk-settings-kingfield-branch">
          {formik.values.storeName}
        </Typography>
        <Paper
          sx={{
            borderLeft: "2px solid #AE9BE2",
            width: "100%",
            marginTop: "1%",
            padding: "1%",
          }}
          elevation={1}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "39%",
            }}
          >
            <Typography className="kiosk-settings-device-name">
              {t("devices.device_name")}
            </Typography>
            {/* <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center",width:editToggle?"60%":"30%"}}>
        {
          editToggle?
          (<Box sx={{width:"100%"}}>
            <TextField
            name="pairedDevice"
            value={formik.values.pairedDevice}
            onChange={(e) => formik.handleChange(e)}
            onBlur={formik.handleBlur}

             sx={{width:"100%"}} id="outlined-basic"  variant="outlined" />
          </Box>)
          
          :<Typography>{formik.values.pairedDevice}</Typography>
        }
         {
          !editToggle &&(<IconButton onClick={handleEditIcon}>
            <EditIcon/>
           </IconButton>)
         }
        </Box> */}
            <Typography>{formik.values.pairedDevice}</Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "40%",
              marginTop: "1%",
            }}
          >
            <Typography>{t("devices.allow_pop_notifications")} </Typography>
            <Switch
              onChange={(e) => formik.handleChange(e)}
              name="allowOrder"
              checked={formik.values.allowOrder}
              color="warning"
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContet: "space-between",
              alignItems: "center",
              width: "100%",
              marginTop: "1%",
            }}
          >
            <Typography sx={{ width: "16%" }}>
              {t("devices.select_menus_to_show_in_pos")}{" "}
            </Typography>
            <Box sx={{ width: "80%", height: "8vh", zIndex: 10 }}>
              <Accordion
                sx={{ width: "30%", border: "2px solid #d9d9d9" }}
                elevation={1}
              >
                <AccordionSummary
                  sx={{ width: "100%", marginBottom: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className="create-a-use-role-label"
                    sx={{ marginBottom: "0px" }}
                  >
                    {noOfLocationsSelected === 0
                      ? locationsText
                      : `${noOfLocationsSelected} ${locationsText}`}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <TextField
                      onChange={(e) =>
                        handleChangeLocationsSearch(e.target.value)
                      }
                      placeholder={t("users.search_by_name") || ""}
                      id="outlined-start-adornment"
                      sx={{ margin: "0px 0px 10px 16px ", width: "80%" }}
                      color="warning"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box
                    sx={{
                      height: "32vh",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        overflowY: "auto",
                        maxHeight: "28vh",
                        marginTop: "10px",
                        minHeight: "10vh",
                      }}
                    >
                      <FormGroup>
                        <FormControlLabel
                          sx={{ marginLeft: "1.5%" }}
                          control={
                            <Checkbox
                              name="all"
                              checked={allCheckboxChecked}
                              onChange={(e) =>
                                handlePopupCheckboxByAll(e.target.checked)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                              }}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="driver-petty-cash-popup-checkbox">
                              {t("buttons.all")}
                            </Typography>
                          }
                        />
                        {searchResults.map((each: any) => (
                          <FormControlLabel
                            sx={{ marginLeft: "1.5%", width: "100%" }}
                            key={each.menuId}
                            checked={each.isChecked}
                            control={
                              <Checkbox
                                onChange={() => {
                                  handlePopupCheckbox(
                                    each.menuId,
                                    each.isChecked
                                  );
                                }}
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                                }}
                                color="warning"
                                checked={each.isChecked}
                              />
                            }
                            label={
                              <Typography
                                sx={{ textTransform: "none" }}
                                className="create-a-user-label"
                              >
                                {each.menuName}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Box>
          </Box>
        </Paper>
      </form>
    </Box>
  );
}

export default PosSettingsGeneralSettings;
