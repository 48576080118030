import React, { FC, useEffect, useState } from "react";

import { SalesGraphData, SalesGraphType } from "../../../../types/salesReport";
import SalesGraphMain from "./salesGraph";
import { DateType } from "../../reportsHeaders";
import { getGraphLabels } from "../../../../utils/getGraphLabels";

interface Props {
  salesGraph: SalesGraphType[];
  date: DateType;
}

const SalesGraph: FC<Props> = ({ salesGraph, date }) => {

  const [labels, setLabels] = useState<Array<string>>([]);
  const [graphDataset, setGraphDataset] = useState<SalesGraphData>({
    orders: [0],
    discounts: [0],
    giftCards: [0],
  });
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  useEffect(() => {
    const tempLabels = getGraphLabels(date.startDate, date.endDate);
    const tempOrders = salesGraph?.map((cus) => {
      return cus.orders;
    });
    const tempDiscounts = salesGraph?.map((cus) => {
      return cus.discounts;
    });
    const tempGifts = salesGraph?.map((cus) => {
      return cus.gift_cards;
    });

    setGraphDataset({
      orders: tempOrders,
      discounts: tempDiscounts,
      giftCards: tempGifts,
    });
    setLabels(tempLabels);
  }, [date.endDate, date.startDate, salesGraph]);

  return (
    <>
      <SalesGraphMain labels={labels} graphDataset={graphDataset} />
    </>
  );
};

export default SalesGraph;
