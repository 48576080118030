import { gql } from "@apollo/client";

export const DISCOUNT_STATUS_CHANGE = gql`
mutation ($discountId:ID!,$discountStatus:String){
    discountStatusChange(
        discountId:$discountId,
        discountStatus:$discountStatus
    ) {
        discount{discountId
        discountStatus}
        success
        errorMessage
        
    }
        
    }
`

export const CUPON_DUPLICATE = gql`
mutation couponCodeDuplicateCheck($locationId:Int!,$couponCode:String){
    couponCodeDuplicateCheck(
        
        locationId:$locationId
        couponCode:$couponCode
        
        
        
      ) {
            errorMessage
        duplicate
            success
            }
    }`

export const DELETE_DISCOUNT = gql`
mutation DeleteDiscounts($discountIds:JSONString!){
	deleteDiscounts(
		discountIds:$discountIds,
		
		
	)
	{message
	success
}
}
`
export const DUPLICATE_DISCOUNT = gql`
mutation duplicateDiscount($discountId:ID!){
    duplicateDiscount(
        discountId:$discountId
        
        
        
      ) {
            message
            discount{id
            discountId}
            success
            }
    }
`

export const CREATE_REASON = gql`
mutation CreateDiscountReason($businessId:Int!,$reason:String,$reasonType:String){
    createDiscountReason(
        businessId:$businessId
        reason:$reason
        reasonType:$reasonType
        
        
        
      ) {
            errorMessage
            discountReason{id
            reasonId}
            success
            }
      }
`

export const DELETE_DISCOUNT_REASON = gql`
mutation DeleteDiscountReason($reasonId:ID!){
    deleteDiscountReason(
    reasonId:$reasonId,
        
    ) {
        success
        
        message
    }
        
    }
`

export const SAVE_DISCOUNT_REASONS = gql`
mutation SelectedDiscountReasons($reasonIds:JSONString!,$businessId:Int){
    selectedDiscountReasons(
        reasonIds:$reasonIds
        businessId:$businessId
        
        
      ) {
            message
            
            success
            }
    }
`

export const CREATE_DISCOUNT = gql`
mutation CreateDiscount(
    $businessId: Int!
    $name: String!
    $reason: ID
    $description: String
    $amount: Float
    $locationId: Int
    $image: String
    $discountType: String
    $orderType: JSONString
    $orderAvailability:JSONString
    $bgColourCode:String
    $paymentType:JSONString
    $discountUsageType:String
    $startDate:Date
    $startTime:Time
    $endDate:Date
        $endTime:Time
        $minTotalCartAmount:Float
    $maxDiscountAmount:Float
    $markDiscountAs:String
    $discountApplicableAs:String
    $couponCode:String
    $limitedStockQuantity:Int
    $discountSchedule:JSONString
    $items:JSONString
    $isExactItemQuantity:Boolean
    $minNoOfItemsRequired:Int
  
    $applyDiscountTo:String
    $applyToAllItems:Boolean
    $customerGroup:JSONString
  
    $isSelfmadeDiscount:Boolean
    $isPremadeDiscount:Boolean
    $eligibleItems:JSONString
    $eligibleDiscountItems:JSONString
    $discountItems:JSONString
    $enableDateRange:Boolean
    $enableDiscountSchedule: Boolean
    $enableMinTotalCartAmount: Boolean,
  ){
    createDiscount(
        businessId:$businessId
        name:$name
        reason: $reason
       description:$description
         amount:$amount
            locationId: $locationId
            image:$image
            discountType:$discountType
            orderType: $orderType
            orderAvailability:$orderAvailability
            paymentType:$paymentType
            discountUsageType:$discountUsageType
            startDate:$startDate
            startTime:$startTime
            endDate:$endDate
            bgColourCode:$bgColourCode
            endTime:$endTime
            minTotalCartAmount:$minTotalCartAmount

            maxDiscountAmount:$maxDiscountAmount
            markDiscountAs:$markDiscountAs
            discountApplicableAs:$discountApplicableAs
            couponCode:$couponCode
            limitedStockQuantity:$limitedStockQuantity
           discountSchedule:$discountSchedule
items:$items
isExactItemQuantity:$isExactItemQuantity
minNoOfItemsRequired:$minNoOfItemsRequired
applyDiscountTo:$applyDiscountTo
applyToAllItems:$applyToAllItems
customerGroup:$customerGroup
isSelfmadeDiscount:$isSelfmadeDiscount
isPremadeDiscount:$isPremadeDiscount
eligibleItems:$eligibleItems
eligibleDiscountItems:$eligibleDiscountItems
discountItems:$discountItems
enableDateRange:$enableDateRange
    enableDiscountSchedule: $enableDiscountSchedule
    enableMinTotalCartAmount: $enableMinTotalCartAmount

      ) {
            errorMessage
            discount{id
            discountId}
            success
            }
      }
`

export const UPDATE_DISCOUNT = gql`
mutation UpdateDiscount(
    $discountId:ID!
    $businessId: Int
    $name: String
    $description: String
    $amount: Float
    $reason: ID
    $locationId: Int
    $image: String
    $discountType: String
    $orderType: JSONString
    $orderAvailability:JSONString
    $bgColourCode:String
    $paymentType:JSONString
    $discountUsageType:String
    $startDate:Date
$startTime:Time
$endDate:Date
	$endTime:Time
    $minTotalCartAmount:Float
    $maxDiscountAmount:Float
    $markDiscountAs:String
    $discountApplicableAs:String
    $couponCode:String
    $limitedStockQuantity:String
    $discountSchedule:JSONString
    $items:JSONString
    $isExactItemQuantity:Boolean
    $minNoOfItemsRequired:Int
    $applyDiscountTo:String
    $applyToAllItems:Boolean
    $customerGroup:JSONString
    $eligibleItems:JSONString
    $eligibleDiscountItems:JSONString
    $discountItems:JSONString
    $enableDateRange:Boolean
    $enableDiscountSchedule: Boolean
    $enableMinTotalCartAmount: Boolean,
){
    updateDiscount(
        discountId:$discountId
        businessId:$businessId
        name:$name
        description:$description
        amount:$amount
        reason: $reason
        locationId: $locationId
        image:$image
        discountType:$discountType
        orderType: $orderType
        orderAvailability:$orderAvailability
        paymentType:$paymentType
        discountUsageType:$discountUsageType
        startDate:$startDate
        startTime:$startTime
        endDate:$endDate
        bgColourCode:$bgColourCode
            endTime:$endTime
            minTotalCartAmount:$minTotalCartAmount
    maxDiscountAmount:$maxDiscountAmount
    markDiscountAs:$markDiscountAs
    discountApplicableAs:$discountApplicableAs
    couponCode:$couponCode
    limitedStockQuantity:$limitedStockQuantity
    discountSchedule:$discountSchedule
    items:$items
    isExactItemQuantity:$isExactItemQuantity
    minNoOfItemsRequired:$minNoOfItemsRequired
    applyDiscountTo:$applyDiscountTo
    applyToAllItems:$applyToAllItems
    customerGroup:$customerGroup
    eligibleItems:$eligibleItems
    eligibleDiscountItems:$eligibleDiscountItems
    discountItems:$discountItems
    enableDateRange:$enableDateRange
    enableDiscountSchedule: $enableDiscountSchedule
    enableMinTotalCartAmount: $enableMinTotalCartAmount
      ) {
		errorMessage
		discount{id
		discountId}
		success
		}
    }
`