import React, { useState } from "react";
import { Typography, IconButton, Button, Box, Checkbox } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import Popover from "@mui/material/Popover";

import "./productTable.css";

import NodataImage from "../../utils/nodataImage";
import Row from "./eachRowInProduct";
import { useTranslation } from "react-i18next";

const TableOfProduct = (props: {
  handleClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
  idForHeader: string | undefined;
  handleApplyButton: () => void;

  handleProductStatusChangeForVariant: (
    variantId: string,
    variantName: string,
    status: string
  ) => void;
  handleProductStatusChange: (
    productId: string,
    productName: string,
    status: string
  ) => void;
  handleThreedotsClick: (
    event: any,
    productId: string,
    productName: string
  ) => void;
  visibleColumns: any[];
  collectingColumn: any[];
  handleRowCheckBox: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;

  handlecancelButton: () => void;
  toggleColumnVisibility: (columnName: any) => void;
  hanldeBulkAddProducts: () => void;

  openForPoppoverForHeader: any;
  handleCloseForPoppover: () => void;
  productsList: any[];
  checkboxIds: any[];
  popoverAnchorElForHeader: HTMLButtonElement | null;
  openForPoppover: any;
  handleAllCheckbox: (isChecked: boolean) => void;
}) => {
  const { t } = useTranslation();
  const {
    handleThreedotsClick,
    idForHeader,
    handleAllCheckbox,
    handleRowCheckBox,

    collectingColumn,
    toggleColumnVisibility,
    visibleColumns,
    handleCloseForPoppover,
    openForPoppoverForHeader,
    handleApplyButton,
    handlecancelButton,
    handleClick,
    handleProductStatusChangeForVariant,
    handleProductStatusChange,

    checkboxIds,
    popoverAnchorElForHeader,
    productsList,
    hanldeBulkAddProducts,
  } = props;

  return (
    <Box sx={{ zIndex: "-10", width: "100%" }}>
      <Box sx={{ marginTop: "8px", width: "100%" }}>
        <div
          style={{
            backgroundColor: "#F5F5F5",
            border: "2px solid #eee",
            borderRadius: "4px 4px 0px 0px",
            padding: "15px",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
          className="header-wrapper"
        >
          <div className="table-header products-list-table-header">
            <Checkbox
              checked={
                productsList.length !== 0 &&
                productsList.length ===
                  productsList.filter((each: any) =>
                    checkboxIds.includes(+each.productId)
                  ).length
              }
              onChange={(e) => handleAllCheckbox(e.target.checked)}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: "1.9rem" },
                marginLeft: "10px",
              }}
              color="warning"
            />
            {t("product.products")}
          </div>
          <div
            style={{ paddingTop: "10px" }}
            className="table-header products-list-table-header"
          >
            {t("product.category")}
          </div>

          {visibleColumns.includes("Status") ? (
            <div
              style={{ paddingTop: "10px" }}
              className="table-header products-list-table-header"
            >
              {t("product.status")}
            </div>
          ) : (
            <div className="table-header products-list-table-header"></div>
          )}
          {visibleColumns.includes("Sku") ? (
            <div
              style={{ paddingTop: "10px" }}
              className="table-header products-list-table-header"
            >
              {t("product.sku")}
            </div>
          ) : (
            <div className="table-header products-list-table-header"></div>
          )}
          {visibleColumns.includes("Unit") ? (
            <div
              style={{ paddingTop: "10px" }}
              className="table-header products-list-table-header"
            >
              {t("product.unit")}
            </div>
          ) : (
            <div className="table-header"></div>
          )}
          <div
            style={{ paddingTop: "10px" }}
            className="table-header products-list-table-header"
          >
            {t("product.price")}
          </div>
          <div style={{ paddingTop: "6px" }} className="table-header">
            <IconButton onClick={handleClick}>
              <AddIcon />
            </IconButton>
          </div>
        </div>

        <TableContainer
          sx={{ border: "2px solid #eee", borderRadius: "2px 2px 6px 6px" }}
        >
          <Table aria-label="collapsible table">
            <TableBody>
              <Button
                color="warning"
                onClick={hanldeBulkAddProducts}
                sx={{
                  textTransform: "none",
                  margin: "4px 0px 4px 2px",
                }}
              >
                <IconButton
                  sx={{ marginRight: "10px" }}
                  className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
                >
                  <AddIcon />
                </IconButton>
                <Typography>{t("product.bulk_add_products")}</Typography>
              </Button>
              {productsList.length > 0 ? (
                productsList.map((row: any) => (
                  <Row
                    handleProductStatusChangeForVariant={
                      handleProductStatusChangeForVariant
                    }
                    handleProductStatusChange={handleProductStatusChange}
                    handleThreedotsClick={handleThreedotsClick}
                    key={row.productId}
                    handleRowCheckBox={handleRowCheckBox}
                    checkboxIds={checkboxIds}
                    row={row}
                    visibleColumns={visibleColumns}
                  />
                ))
              ) : (
                <Box>
                  <NodataImage
                    heightForContainer="50vh"
                    height="250px"
                    width="250px"
                  />
                </Box>
              )}
            </TableBody>

            <Popover
              id={idForHeader}
              open={openForPoppoverForHeader}
              anchorEl={popoverAnchorElForHeader}
              onClose={handleCloseForPoppover}
              sx={{ width: "100%" }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <Box sx={{ padding: "15px", width: "100%" }}>
                {[
                  {
                    text: t("product.status"),
                    value: "Status",
                  },
                  {
                    text: t("product.sku"),
                    value: "Sku",
                  },
                  {
                    text: t("product.unit"),
                    value: "Unit",
                  },
                ].map((col, index) => (
                  <Box
                    key={index}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Checkbox
                      sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      color="warning"
                      checked={collectingColumn.includes(col.value)}
                      onChange={() => toggleColumnVisibility(col.value)}
                    />

                    {col.text}
                  </Box>
                ))}
                <Box sx={{ margin: "5px" }}>
                  <Button
                    sx={{ textTransform: "none", marginRight: "10px" }}
                    variant="outlined"
                    color="warning"
                    onClick={handlecancelButton}
                  >
                    {t("buttons.cancel")}
                  </Button>
                  <Button
                    sx={{ textTransform: "none" }}
                    variant="contained"
                    color="warning"
                    onClick={handleApplyButton}
                  >
                    {t("buttons.apply")}
                  </Button>
                </Box>
              </Box>
            </Popover>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default TableOfProduct;
