import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormGroup,
  FormControlLabel,
  Checkbox,
  InputAdornment,
  Grid,
  Paper,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import EditIcon from "@mui/icons-material/Edit";
import GenerateDeviceCodePopup from "./generateDeviceCodePopup";
import DeviceDetailsPopup from "./deviceDetailsPopup";
import ScanningPopup from "./scanningPopup";
import ScanSuccessPopup from "./scanSuccessPopup";
import { t } from "i18next";
import { useMediaQuery, useTheme } from "@mui/material";
import "./posSetup.css";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { PAIR_A_NEW_DEVICE } from "../../graphicalQl/mutation/deviceMangementMutation";
import { useSelector } from "react-redux";
import { FILTERED_STORE_LIST } from "../../graphicalQl/usequery/deviceManagementQuery";
import { toast, ToastPosition } from "react-toastify";
import DataGridTable from "../../utils/dataGridTable";
import CircularLoader from "../../loader/loader";

interface State {
  locationsList: any[];
  seLocationsList: React.Dispatch<React.SetStateAction<any[]>>;
  rows: any[];
  shouldUpdatePaginationModelIsTrue: (newModal: any) => void;
  handleUpdatingValuesToNull: (newModal: any) => void;
  paginationModel: any;
  handleEndCursor: () => void;
  handleStartCursor: () => void;
  currentPage: any;
  Loading: boolean;
}

interface GenerateCode {
  deviceCode: string | null;
  isValid: boolean;
}

interface DeviceDetails {
  deviceName: null | string;
  storeId: string;
  stationName: null | string;
}

const validationSchemaForGenerateDeviceCode = Yup.object({
  deviceCode: Yup.string().required("Device Code is required"),
});
function PosSetup(props: State) {
  const {
    locationsList,
    currentPage,
    rows,
    handleStartCursor,
    seLocationsList,
    shouldUpdatePaginationModelIsTrue,
    handleUpdatingValuesToNull,
    paginationModel,
    Loading,
    handleEndCursor,
  } = props;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const navigate = useNavigate();
  const [callingFunction, setCallingFunction] = useState<boolean>(false);
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");

  const [pairANewDevice] = useMutation(PAIR_A_NEW_DEVICE, {
    onCompleted: (data) => {
      if (data) {
        const { createDevicePairing } = data;
        if (createDevicePairing) {
          const { success } = createDevicePairing;
          if (success) {
            setGenearateDeviceCodePopup(false);
            setDeviceDetalsPopup(false);
            formikForDeviceDetails.resetForm();
            formikForGenerateDeviceCode.resetForm();

            toast.success(
              <div>
                <Typography>{t("toast.paired")}</Typography>
              </div>,
              {
                position: isMobile ? "top-center" : "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message.trim() === "Device name should be unique!") {
        formikForDeviceDetails.setFieldValue("isError", true);
      } else if (error.message.trim() === "Device not found!") {
        setGenearateDeviceCodePopup(false);
        setDeviceDetalsPopup(false);
        formikForDeviceDetails.resetForm();
        formikForGenerateDeviceCode.resetForm();
        toast.success(
          <div>
            <Typography>{t("toast.device_not_found")}</Typography>
          </div>,
          {
            position: isMobile ? "top-center" : "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      }
    },
    context: { clientName: "businessClient" },
    refetchQueries: [FILTERED_STORE_LIST],
  });

  const initialValuesOfKdsSetupGenerateDeviceCode: GenerateCode = {
    deviceCode: null,
    isValid: false,
  };

  const formikForGenerateDeviceCode: any = useFormik({
    initialValues: initialValuesOfKdsSetupGenerateDeviceCode,
    validationSchema: validationSchemaForGenerateDeviceCode,
    onSubmit: (values) => {
      if (!formikForGenerateDeviceCode.values.isValid) {
        handleNextButton();
      }
    },
  });

  const validationSchemaForDeviceDetails = Yup.object({});
  const initialValuesOfKdsSetupDeviceDetails: DeviceDetails = {
    deviceName: null,
    storeId: locationId,
    stationName: null,
  };
  const formikForDeviceDetails = useFormik({
    initialValues: initialValuesOfKdsSetupDeviceDetails,
    validationSchema: validationSchemaForDeviceDetails,
    onSubmit: (values) => {
      handleDoneButtonInDeviceDetailsPopup();
    },
  });
  const [searchInput, setSearchInput] = React.useState("");
  const [noOfLocationsSelected, setNoOfLocationsSelected] = React.useState<
    number | null
  >(0);

  const [allCheckboxChecked, setAllCheckboxChecked] =
    React.useState<boolean>(true);
  const [enableCamera, setEnableCamera] = React.useState<boolean>(true);

  // first popup
  const [genearateDeviceCodePopup, setGenearateDeviceCodePopup] =
    React.useState(false);

  const handleGenerateDeviceCodePopUpClose = () => {
    setGenearateDeviceCodePopup(false);
    formikForGenerateDeviceCode.resetForm();
  };
  //second popup
  const [deviceDetailsPopup, setDeviceDetalsPopup] = React.useState(false);
  const [successPopupOpen, setSuccessPopupOpen] = React.useState(false);
  const [scanningPopupOpen, setScanningPopupOpen] = useState<boolean>(false);

  const columns: GridColDef[] = [
    {
      field: "storeName",
      headerClassName: "device-set-up-table-header",
      //@ts-ignore
      headerName: t("devices.branch_name"),
      flex: 3,
    },
    {
      field: "noOfPos",
      headerClassName: "device-set-up-table-header",
      //@ts-ignore
      headerName: t("devices.paired_devices"),
      flex: 1,
    },

    // {
    //  field:".",
    //  disableColumnMenu:true,
    //   width:150,

    //   renderCell:(params)=>(
    //     <IconButton onClick={()=>handleEditClick(params.row.id)} >
    //     <EditIcon/>
    //     </IconButton>

    //   )

    // },
  ];

  const handleEditClick = (id: string) => {
    navigate("/kds/setup/create");
  };

  const getRowSize = () => {
    return "device-set-up-table-row device-set-up-table-row-hover";
  };

  const handleDoneButtonInDeviceDetailsPopup = () => {
    const filteredDataFromNull = {
      deviceCode: formikForGenerateDeviceCode.values.deviceCode,
      storeId: formikForDeviceDetails.values.storeId,
      businessId: +businessId,
    };

    const filteredCategory = Object.fromEntries(
      Object.entries(filteredDataFromNull).filter(
        ([_, value]) => value !== null
      )
    );
    pairANewDevice({ variables: filteredCategory });
  };

  useEffect(() => {
    if (locationsList) {
      setNoOfLocationsSelected(
        locationsList.filter((each: any) => each.isChecked).length
      );
    }
  }, [locationsList]);

  const searchResults = locationsList.filter((eachLocation: any) =>
    eachLocation.storeName.toLowerCase().includes(searchInput)
  );

  const gettingLocationsCount = (list: any[]) => {
    const checkedList = list.filter((each: any) => each.isChecked);
    if (checkedList.length === 0) {
      return `0 ${t("users.add_location")}`;
    } else if (checkedList.length === 1) {
      return `1 ${t("users.location")}`;
    } else {
      return `${checkedList.length} ${t("users.locations")}`;
    }
  };

  const handlePopupCheckboxByAll = (isChecked: boolean) => {
    setAllCheckboxChecked(isChecked);
    const filteredDataList = locationsList.map((each: any) => {
      return { ...each, isChecked: isChecked };
    });
    seLocationsList(filteredDataList);

    if (isChecked) {
      setNoOfLocationsSelected(locationsList.length);
    } else {
      setNoOfLocationsSelected(0);
    }
  };
  const handlePopupCheckbox = (id: string, isChecked: boolean) => {
    const filteredList = locationsList.map((each: any) => {
      if (each.storeId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });

    seLocationsList(filteredList);
    const checkedCount = filteredList.filter(
      (location: any) => location.isChecked
    ).length;
    setNoOfLocationsSelected(checkedCount);
    setAllCheckboxChecked(checkedCount === filteredList.length);
  };

  const handleChangeLocationsSearch = (search: string) => {
    setSearchInput(search);
  };
  const handleNextButton = () => {
    setDeviceDetalsPopup(true);
    setGenearateDeviceCodePopup(false);
  };

  const handleDeviceDetalsPopupClose = () => {
    setDeviceDetalsPopup(false);
    setSuccessPopupOpen(false);
    formikForDeviceDetails.resetForm();
    formikForGenerateDeviceCode.resetForm();
  };

  const [result, setResult] = useState<string | null>(null);

  useEffect(() => {
    if (result !== null) {
      setSuccessPopupOpen(true);
      setScanningPopupOpen(false);

      formikForGenerateDeviceCode.setFieldValue("deviceCode", result);
      setResult(null);
    }
  }, [result]);

  const handleScanQrCodeButton = () => {
    setScanningPopupOpen(true);
    setGenearateDeviceCodePopup(false);
  };

  const handleError = (error: string) => {
    setCallingFunction(false);
  };

  const handleSuccessPopupClose = () => {
    setSuccessPopupOpen(false);
    formikForGenerateDeviceCode.resetForm();
  };

  const handleScanningPopupClose = () => {
    setScanningPopupOpen(false);
    setGenearateDeviceCodePopup(true);
  };

  const handleNextButtonInSuccessPopup = () => {
    setDeviceDetalsPopup(true);
    setSuccessPopupOpen(false);
  };

  const handlePairNewDeviceButton = () => {
    setGenearateDeviceCodePopup(true);
  };
  return (
    <Box>
      <ScanningPopup
        open={scanningPopupOpen}
        enableCamera={enableCamera}
        callingFunction={callingFunction}
        handleSuccessPopupClose={handleScanningPopupClose}
        setResult={setResult}
        handleError={handleError}
      />

      <ScanSuccessPopup
        handleSuccessClose={handleSuccessPopupClose}
        open={successPopupOpen}
        successText={`${t("devices.POS")} ${t("devices.success")}`}
        handleNextButton={handleNextButtonInSuccessPopup}
      />

      <GenerateDeviceCodePopup
        headerText={t("devices.POS")}
        installText={t("devices.POS")}
        installSubText={t("devices.POS")}
        signInText={t("devices.POS")}
        formik={formikForGenerateDeviceCode}
        open={genearateDeviceCodePopup}
        handlePopUpClose={handleGenerateDeviceCodePopUpClose}
        handleScanQrCodeButton={handleScanQrCodeButton}
        handleNextButton={handleNextButton}
      />

      <DeviceDetailsPopup
        headerText="POS"
        stationName={false}
        deviceName={false}
        formik={formikForDeviceDetails}
        open={deviceDetailsPopup}
        handleDeviceDetailsPopupClose={handleDeviceDetalsPopupClose}
        locationsList={locationsList}
        handleDoneButtonInDeviceDetailsPopup={
          handleDoneButtonInDeviceDetailsPopup
        }
      />

      <Grid container xs={12} md={12}>
        <Grid container className="device-setup-accordian-button-order">
          <Grid item xs={12} md={8} sx={{ zIndex: 10, height: "50px" }}>
            <div style={{ width: "100%", zIndex: 10 }}>
              <Accordion
                className="device-setup-accordian-width"
                sx={{
                  "&.MuiAccordion-root": {
                    boxShadow: "none",
                  },
                }}
                elevation={1}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className="create-a-use-role-label">
                    {/* {noOfLocationsSelected === 0
                      ? gettingLocationsCount(locationsList)
                      : `${noOfLocationsSelected} ${gettingLocationsCount()}`} */}
                    {gettingLocationsCount(locationsList)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <TextField
                      onChange={(e) =>
                        handleChangeLocationsSearch(e.target.value)
                      }
                      //@ts-ignore
                      placeholder={t("users.search_by_name")}
                      id="outlined-start-adornment"
                      className="device-setup-accordian-textfield"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Box>
                  <Box className="device-setup-accordian-height">
                    <Box className="device-setup-accordian-height-for-scroll">
                      <FormGroup>
                        <FormControlLabel
                          className="device-setup-checkbox-left-margin"
                          control={
                            <Checkbox
                              color="warning"
                              // className="device-setup-checkbox-color"
                              name="all"
                              checked={allCheckboxChecked}
                              onChange={(e) =>
                                handlePopupCheckboxByAll(e.target.checked)
                              }
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                              }}
                            />
                          }
                          label={
                            <Typography className="device-setup-checkbox-label">
                              {t("buttons.all")}
                            </Typography>
                          }
                        />
                        {searchResults.map((each) => (
                          <FormControlLabel
                            key={each.storeId}
                            className="device-setup-checkbox-left-margin"
                            control={
                              <Checkbox
                                color="warning"
                                // className="device-setup-checkbox-color"
                                onChange={() =>
                                  handlePopupCheckbox(
                                    each.storeId,
                                    each.isChecked
                                  )
                                }
                                sx={{
                                  "& .MuiSvgIcon-root": { fontSize: "1.75rem" },
                                }}
                                checked={each.isChecked}
                              />
                            }
                            label={
                              <Typography className="device-setup-checkbox-label">
                                {each.storeName}
                              </Typography>
                            }
                          />
                        ))}
                      </FormGroup>
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </div>
          </Grid>

          <Grid item xs={12} md={4} className="pair-device">
            <Button
              onClick={handlePairNewDeviceButton}
              className="device-set-up-header-button"
              variant="outlined"
            >
              {t("devices.add_new_pos_device")}
            </Button>
          </Grid>
        </Grid>

        <Grid sx={{ zIndex: 0 }} md={12}>
          <Grid item sx={{ display: { xs: "none", sm: "flex" } }}>
            <Box sx={{ height: "60vh", width: "100%" }}>
              {Loading ? (
                <CircularLoader />
              ) : (
                <DataGridTable
                  shouldUpdatePaginationModelIsTrue={
                    shouldUpdatePaginationModelIsTrue
                  }
                  handleUpdatingValuesToNull={handleUpdatingValuesToNull}
                  paginationModel={paginationModel}
                  rows={rows}
                  columns={columns}
                  getRow={getRowSize}
                  totalCount={rows ? rows.length : 0}
                  handleEndCursor={handleEndCursor}
                  handleStartCursor={handleStartCursor}
                  currentPage={currentPage}
                  imageHeight="250px"
                  imageWidth="250px"
                  heightForContainer="40vh"
                />
              )}
            </Box>
          </Grid>
        </Grid>

        <Grid
          sx={{
            display: { xs: "flex", sm: "none" },
            flexDirection: { xs: "column" },
            // marginTop:{xs:"50px"},
            maxHeight: "60vh", // or any height you prefer
            overflowY: "scroll",
            padding: "5px",
          }}
          item
          xs={12}
        >
          {rows.map((each: any) => (
            <Paper
              className="device-setup-mobile-list-view"
              sx={{
                "&.MuiPaper-root": {
                  boxShadow:
                    "0px 2px 5px rgba(0, 0, 0, 0.1), 0px -2px 5px rgba(0, 0, 0, 0.1), 2px 0px 5px rgba(0, 0, 0, 0.1), -2px 0px 5px rgba(0, 0, 0, 0.1)",
                },
              }}
            >
              <Box className="device-setup-name-editicon-order">
                <Typography>{each.storeName}</Typography>
                {/* <EditIcon /> */}
                <Typography> </Typography>
              </Box>
              <Typography sx={{ marginTop: "5px" }}>{each.noOfPos}</Typography>
            </Paper>
          ))}
        </Grid>
      </Grid>
    </Box>
  );
}

export default PosSetup;
