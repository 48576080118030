import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { countries } from "../../../common/countries"; // Assuming countries is an array of Country objects
import Checkbox from "@mui/material/Checkbox";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { useState } from "react";
import { GETTING_LOCATIONS_LIST } from "../../../../graphicalQl/usequery/locationQuery";
import { useDispatch } from "react-redux";
import { headerLocation } from "../../../../store";
import { useTranslation } from "react-i18next";

import {
  InputLabel,
  FormControl,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";
import LocationList from "../../../locations/locationsList";

interface Props {
  label?: string;
  width?: string | { lg: string; md: string; xs: string };
}

const CountrySelect: React.FC<Props> = ({ label, width }) => {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const dispatch = useDispatch();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  const [locationsList, setLocationsList] = useState<any[]>([]);
  const [checked, setChecked] = React.useState(true);

  const handleChange = () => {
    setChecked((prevChecked) => !prevChecked);
  };

  React.useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData?.storeList?.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          currency: each.node.currency,
        })
      );
      if (sessionStorage.getItem("locationId") === null) {
        dispatch(headerLocation("locationId", requiredDataList?.[0]?.storeId));
        sessionStorage.setItem("locationId", requiredDataList?.[0]?.storeId);
      }
      const currency = requiredDataList?.[0]?.currency || "gbp";

      sessionStorage.setItem("currency", currency);

      const currencyPart = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency,
      })
        .formatToParts(0)
        .find((part) => part.type === "currency");
      const currencySymbol = currencyPart ? currencyPart.value : "";

      sessionStorage.setItem("currencySymbol", currencySymbol);
      setLocationsList(requiredDataList);
      // dispatch(headerLocation("locationId", requiredDataList && requiredDataList[0].storeId))
      // sessionStorage.setItem("locationId", requiredDataList && requiredDataList[0].storeId)
    }
  }, [locationsData]);

  const handleChangeLocation = (location: any) => {
    dispatch(headerLocation("locationId", location));
    sessionStorage.setItem("locationId", location);
    const currency =
      locationsList.find(
        (each: any) => each.storeId.toString() === location.toString()
      ).currency || "gbp";
    sessionStorage.setItem("currency", currency);

    const currencyPart = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency,
    })
      .formatToParts(0)
      .find((part) => part.type === "currency");
    const currencySymbol = currencyPart ? currencyPart.value : "";

    sessionStorage.setItem("currencySymbol", currencySymbol);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <FormControl sx={{ width: "100%" }}>
        <InputLabel className="create-a-use-role-label" id="assign-role-label">
          {t("dashboard.location")}
        </InputLabel>
        <Select
          color="warning"
          labelId="assign-role-label"
          label={<Typography>{t("dashboard.location")}</Typography>}
          value={locationId || ""}
          defaultValue="location"
          onChange={(e) => handleChangeLocation(e.target.value)}
        >
          {locationsList &&
            locationsList.map((each: any) => (
              <MenuItem key={each.storeId} value={each.storeId}>
                {each.storeName}
              </MenuItem>
            ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default CountrySelect;
