import React, { ChangeEventHandler, useState } from "react";
import { FormControl, InputAdornment, TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
interface TypeSearchProps {
  placeholder?: string;
  handleTextChange?: (searchTerm: string) => void | null;
  customSx?: React.CSSProperties;
  handleClearIconClick?: () => void | null;
  value: string;
}

const TypeSearch = (props: TypeSearchProps) => {
  const [showClearIcon, setShowClearIcon] = useState("none");
  const {
    placeholder,
    handleTextChange,
    customSx,
    handleClearIconClick,
    ...rest
  } = props;

  const handleChange = (event: any) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    if (handleTextChange) handleTextChange(event.target.value);
  };

  const handleClick = () => {
    // TODO: Clear the search input
    if (handleClearIconClick) handleClearIconClick();
  };

  return (
    <Box
      sx={{
        position: "sticky",
        left: "0px",
        paddingX: "16px",
        marginTop: "20px",
        background: "white",
        paddingBottom: "12px",
      }}
    >
      <FormControl>
        <TextField
          {...rest}
          size="small"
          variant="outlined"
          placeholder={placeholder}
          sx={{ fontSize: "14px", borderRadius: "10px", ...customSx }}
          onChange={handleChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ height: "18px", width: "18px" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: showClearIcon }}
                onClick={handleClick}
              >
                <ClearIcon sx={{ height: "18px", width: "18px" }} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
};

export default TypeSearch;
