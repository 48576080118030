import React, { useEffect, useState } from "react";

import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useTranslation } from "react-i18next";

import Typography from "@mui/material/Typography";
import "./rounding.css";
import { CREATE_ROUNDING } from "../../graphicalQl/mutation/salesTaxMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ROUNDING } from "../../graphicalQl/usequery/settingQuery";
import { Box } from "@mui/material";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";

const Rounding: React.FC = () => {
  const { t } = useTranslation();

  const [checkedItems, setCheckedItems] = useState([
    {
      name: "Discount",
      isChecked: false,
    },
    { name: "Cart", isChecked: false },
    { name: "Taxes", isChecked: false },
    { name: "Tips", isChecked: false },
  ]);
  const { locationId } = useSelector((state: any) => state.headerLocation);
  // const option1 = (
  //     <div className='round-wrap'>
  //         <Typography className="rounding_checkBox">{t("settings.discount")}</Typography>
  //     </div>
  // );
  // const option2 = (
  //     <div>
  //         <Typography className="rounding_checkBox">{t("settings.cart")}</Typography>
  //     </div>
  // );
  // const option3 = (
  //     <div>
  //         <Typography className="rounding_checkBox">{t("settings.taxes")}</Typography>
  //     </div>
  // );
  // const option4 = (
  //     <div>
  //         <Typography className="rounding_checkBox">{t("settings.tips")}</Typography>
  //     </div>
  // );

  const handleCheckboxChange = (value: string) => {
    setCheckedItems((prevState) =>
      prevState.map((each) =>
        each.name === value ? { ...each, isChecked: !each.isChecked } : each
      )
    );
  };

  const [value, setValue] = useState("upWards");

  const handleRounding = (value: string) => {
    setValue(value);
  };

  const [rounding] = useMutation(CREATE_ROUNDING, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleSave = () => {
    rounding({
      variables: { roundingRule: value, storeId: locationId && +locationId },
    });
  };
  const handleCancle = () => {
    setCheckedItems((prevState) =>
      prevState.map((each) => ({
        ...each,
        isChecked: false,
      }))
    );
  };
  const { data: roundingData } = useQuery(GET_ROUNDING, {
    variables: { storeId: locationId && +locationId },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (
      roundingData &&
      roundingData.storeSettings &&
      roundingData.storeSettings.roundingRule
    ) {
      setValue(roundingData.storeSettings.roundingRule);
    }
  }, [roundingData]);

  return (
    <div>
      <Paper
        elevation={0}
        style={{ padding: "20px 20px 20px 10px", width: "100%" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography className="rounding_titleValue">
            {t("settings.r_options")}
          </Typography>
          <div style={{ display: "flex", alignItems: "center" }}>
            {/* <Button variant="contained" className="rounding_Button_clear" onClick={handleCancle}>
                            {t("settings.clear")}
                        </Button> */}
            <Button
              variant="contained"
              className="Rounding_save_button"
              style={{ marginLeft: "20px" }}
              onClick={handleSave}
            >
              {t("settings.save")}
            </Button>
          </div>
        </div>
        <Typography
          className="rounding_para"
          style={{ marginLeft: "58px", marginTop: "36px" }}
        >
          {t("settings.r_para")}
        </Typography>
        <div style={{ display: "flex", marginLeft: "36px", marginTop: "16px" }}>
          <div
            className={
              value === "upWards"
                ? "rounding_box_1_active"
                : "rounding_box_1_inactive"
            }
            style={{
              margin: "20px",
              borderLeft: "2px solid var(--stroke-color-1-violet, #E2A39B)",
            }}
            onClick={() => {
              handleRounding("upWards");
            }}
          >
            <Typography
              style={{ margin: "5px" }}
              className="rounding_button_innerStyle"
            >
              {t("settings.upwards")}
            </Typography>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number"> £0.112</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.125</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.13</Typography>
            </div>
          </div>
          <div
            className={
              value === "downwards"
                ? "rounding_box_1_active"
                : "rounding_box_1_inactive"
            }
            style={{
              margin: "20px",
              borderLeft: "2px solid var(--stroke-color-1-violet, #E19BE2)",
            }}
            onClick={() => {
              handleRounding("downwards");
            }}
          >
            <Typography
              style={{ margin: "5px" }}
              className="rounding_button_innerStyle"
            >
              {t("settings.downwards")}
            </Typography>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.11</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.112</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.11</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.125</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
          </div>
          <div
            className={
              value === "halfDown"
                ? "rounding_box_1_active"
                : "rounding_box_1_inactive"
            }
            style={{
              margin: "20px",
              borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)",
            }}
            onClick={() => {
              handleRounding("halfDown");
            }}
          >
            <Typography
              style={{ margin: "5px" }}
              className="rounding_button_innerStyle"
            >
              {t("settings.half_down")}
            </Typography>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{
                  marginLeft: "7px",
                  marginRight: "7px",
                  color: "#707070",
                }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
          </div>
          <div
            className={
              value === "halfUp"
                ? "rounding_box_1_active"
                : "rounding_box_1_inactive"
            }
            style={{
              margin: "20px",
              borderLeft: "2px solid var(--stroke-color-1-violet, #9BDAE2)",
            }}
            onClick={() => {
              handleRounding("halfUp");
            }}
          >
            <Typography
              style={{ margin: "5px" }}
              className="rounding_button_innerStyle"
            >
              {t("settings.half_up")}
            </Typography>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.116</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.12</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.112</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.11</Typography>
            </div>
            <div
              style={{ display: "flex", alignItems: "center", margin: "5px" }}
            >
              <Typography className="rounding_number">£0.125</Typography>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="12"
                viewBox="0 0 25 12"
                fill="none"
                style={{ marginLeft: "7px", marginRight: "7px" }}
              >
                <path
                  d="M1 5C0.447715 5 0 5.44772 0 6C0 6.55228 0.447715 7 1 7V5ZM25 6L15 0.226497V11.7735L25 6ZM1 7H16V5H1V7Z"
                  fill="#707070"
                />
              </svg>
              <Typography className="rounding_number">£0.13</Typography>
            </div>
          </div>
        </div>
        <Box></Box>
      </Paper>
    </div>
  );
};

export default Rounding;
