import { Box, Button, Checkbox, Switch, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_FEATURE_SETTINGS } from "../../../../graphicalQl/mutation/customerDataMutation";
import { STORE_SETTINGS } from "../../../../graphicalQl/usequery/customerDataQuery";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
interface Shifts {
  id: string;
  title: string;
  label: string;
  link: string;
  enabled: boolean;
}

const FeatureSettings = () => {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { data, loading, error, refetch } = useQuery(STORE_SETTINGS, {
    variables: {
      storeId: +locationId,
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  const [featureDetails, setFeatureDetails] = useState<Shifts[]>([]);

  const [
    updateFeatureSettings,
    { data: updateFeatureSettingsData, error: updateFeatureSettingsError, loading: submitLoading },
  ] = useMutation(UPDATE_FEATURE_SETTINGS, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(<div >

        <Typography>{t("toast.updated")}</Typography>
      </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          className: 'custom_toast3',
        })
    },
    onError: () => {
      console.error('Error:', error);
    }
  });

  //check for successfull updated and perform the required functions
  // useEffect(() => {
  //   if (updateFeatureSettingsData?.branchSettings?.success) {
  //     alert("Feature settings updated successfully.");
  //     refetch();
  //   }
  // }, [updateFeatureSettingsData]);

  useEffect(() => {
    if (data?.storeSettings) {
      const {
        enableShifts,
        enableClockinClockout,
        allowNegativeStockAlerts,
        lowStockNotifications,
        allowDinningOptions,
        allowOpenItems,
        sendOrdersTokitchenPrinters,
        allowCustomerDisplaySystems,
      } = data?.storeSettings;
      setFeatureDetails([
        {
          id: "enableShifts",
          title: t("features_settings.feature1"),
          label: t("features_settings.featurepara1"),
          link: "https://www.google.com",
          enabled: enableShifts,
        },
        {
          id: "enableClockinClockout",
          title: t("features_settings.feature2"),
          label: t("features_settings.featurepara2"),
          link: "https://www.google.com",
          enabled: enableClockinClockout,
        },
        {
          id: "allowOpenItems",
          title: t("features_settings.feature3"),
          label: t("features_settings.featurepara3"),
          link: "https://www.google.com",
          enabled: allowOpenItems,
        },
        {
          id: "sendOrdersTokitchenPrinters",
          title: t("features_settings.feature4"),
          label: t("features_settings.featurepara4"),
          link: "https://www.google.com",
          enabled: sendOrdersTokitchenPrinters,
        },
        {
          id: "allowCustomerDisplaySystems",
          title: t("features_settings.feature5"),
          label: t("features_settings.featurepara5"),
          link: "https://www.google.com",
          enabled: allowCustomerDisplaySystems,
        },
        {
          id: "allowDinningOptions",
          title: t("features_settings.feature6"),
          label: t("features_settings.featurepara6"),
          link: "https://www.google.com",
          enabled: allowDinningOptions,
        },
        {
          id: "lowStockNotifications",
          title: t("features_settings.feature7"),
          label: t("features_settings.featurepara7"),
          link: "https://www.google.com",
          enabled: lowStockNotifications,
        },
        {
          id: "allowNegativeStockAlerts",
          title: t("features_settings.feature8"),
          label: t("features_settings.featurepara8"),
          link: "https://www.google.com",
          enabled: allowNegativeStockAlerts,
        },
      ]);
    }
  }, [data, t]);

  // const saveFeatureSettings = () => {
  //   const variables = {};
  //   featureDetails.forEach(({ id, enabled }) => {
  //     variables[id] = enabled;
  //   });

  //   updateFeatureSettings({ variables });
  // };
  const saveFeatureSettings = () => {
    const variables: Record<string, number | boolean> = {};
    variables["storeId"] = +locationId;
    featureDetails.forEach(({ id, enabled }) => {
      variables[id] = enabled;
    });
    updateFeatureSettings({ variables });
    // Rest of your code using the variables object
  };
  return (
    <Box>
      <Box
        sx={{
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
            }}
          >
            {t("features_settings.features")}
          </Typography>
          <Typography
            sx={{
              color: "#707070",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontStyle: "normal",
              fontWeight: 400,
              lineHeight: "normal",
              marginTop: "4px",
            }}
          >
            {t("features_settings.enable_toggle")}
          </Typography>
        </Box>
        <Button
          onClick={() => saveFeatureSettings()}
          disabled={submitLoading}
          sx={{
            width: "94px",
            height: "44px",
            backgroundColor: "#F38B08",
            marginRight: "20px",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },

          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            {t("buttons.save")}
          </Typography>
        </Button>
      </Box>
      <Box
        sx={{
          width: "691px",
          background: "#FFF",
          display: "flex",
          flexDirection: "column",
          rowGap: "24px",
          margin: "auto",
          marginTop: "40px",
          marginBottom: "281px",
        }}
      >
        {featureDetails.map(({ title, label, enabled, link }, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  color: "#333",
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                }}
              >
                {title}
              </Typography>
              <Typography
                sx={{
                  color: "#707070",
                  fontFamily: "Poppins",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  lineHeight: "normal",
                  marginTop: "4px",
                }}
              >
                {label}
              </Typography>
            </Box>
            <Switch color="warning" onClick={() => {
              const currentFeatureDetails = featureDetails;
              currentFeatureDetails[index].enabled =
                !currentFeatureDetails[index].enabled;
              setFeatureDetails([...currentFeatureDetails]);
            }}
              checked={enabled}
            />

            {/* <Checkbox
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 50 },
              }}
              icon={<ToggleOffIcon sx={{ color: "#F38B08" }} />}
              checkedIcon={<ToggleOnIcon sx={{ color: "#F38B08" }} />}
              checked={enabled}
              tabIndex={-1}
              onClick={() => {
                const currentFeatureDetails = featureDetails;
                currentFeatureDetails[index].enabled =
                  !currentFeatureDetails[index].enabled;
                setFeatureDetails([...currentFeatureDetails]);
              }}
            /> */}
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default FeatureSettings;
