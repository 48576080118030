import React, { FC, useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { InputLabel, ListItemText } from "@mui/material";
import { EmplpoyeesList } from "../../../graphicalQl/usequery/userProfileUseQuery";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { setReportData } from "../../../pages/reduxReducers/repoertReducer";
import { useDispatch } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
// const Options = [
//   { value: 56, label: "Employee 56" },
//   { value: 68, label: "Employee 68" },
//   { value: 65, label: "Employee 65" },
//   { value: 103, label: "Employee 103" },
// ];

// interface Props {
//   employee?: string | null;
//   setEmployee: (a: string) => void;
//   locations: string
// }

interface Props {
  employeeList: any
}
const EmployeeSelection = (props: Props) => {

  const { employeeList } = props

  const businessId: string = sessionStorage.getItem("businessId") as string
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);


  // const { loading: employeesDataLoading, error: employeesDataError, data: employeesData } = useQuery(EmplpoyeesList, { context: { clientName: "userClient" }, variables: { businessId: +businessId, locationId: reportLocation && +reportLocation } });
  // const [employeeList, setEmployeeList] = useState<{ value: string, label: string }[]>([])
  // useEffect(() => {
  //   if (employeesData) {
  //     if (employeesData !== null) {

  //       const requiredDataList = employeesData.employees.edges.map((each: any) => ({
  //         value: each.node.uuid,
  //         label: `${each.node.firstName} ${each.node.lastName}`,

  //       }

  //       ))
  //       setEmployeeList(requiredDataList)
  //     }
  //   }
  // }, [employeesData]);

  const dispatch = useDispatch()

  const handleChange = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;
    let newValue: string;
    // if (typeof value === "string") {
    //   newValue = parseInt(value);
    // } else {
    //   newValue = value;
    // }
    const value2 = value === "null" ? null : value;


    dispatch(setReportData("reportEmployee", value2))
  };
  return (
    <div>
      <FormControl
        sx={{
          ml: 4,
          width: 180,
          height: 44,
          // position: "static",
          "& .MuiOutlinedInput-root": {
            "&:focused fieldset": { borderColor: "#f39f08" },
            "&.Mui-focused fieldset": { borderColor: "#f39f08" },
          },
        }}
      >
        <InputLabel id="demo-simple-select-label">All Employees</InputLabel>

        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reportEmployee}
          label="Location"
          onChange={handleChange}
          sx={{
            width: "180px",
            height: "44px",
          }}
        >
          <MenuItem value="null" key="null">None</MenuItem>
          {employeeList && employeeList.map((option: any) => (

            <MenuItem value={option.id} key={option.value}>{option.label}</MenuItem>
          ))}



        </Select>
      </FormControl>
    </div>
  );
};
export default EmployeeSelection;
