import React, { ChangeEvent, useState } from "react";
import uuid from "react-uuid";
import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  FormControlLabel,
  Typography,
  MenuItem,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  Chip,
  styled,
  InputAdornment,
  InputLabel,
  Select,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Checkbox from "@mui/material/Checkbox";
import "./createBundle.css";
import { useEffect } from "react";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Divider, List, ListItem } from "@mui/material";
import menuImage from "../../assets/menuSetup.png";
import "./createBundle.css";
import CloseIcon from "@mui/icons-material/Close";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import "../options/createProduct.css";
import { useQuery, useMutation } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { BUNDLE_LIST_DATA } from "../../graphicalQl/usequery/bundleQuery.tsx";
import { PRODUCT_LIST_IN_BUNDLE } from "../../graphicalQl/usequery/bundleQuery.tsx";
import { CREATE_BUNDLE } from "../../graphicalQl/mutation/bundleMutation.tsx";
import { EDIT_BUNDLE_DATA } from "../../graphicalQl/usequery/bundleQuery.tsx";
import { ADD_ITEM_BUNDLE } from "../../graphicalQl/mutation/bundleMutation.tsx";
import { ADD_GROUP_IN_BUNDLE } from "../../graphicalQl/mutation/bundleMutation.tsx";
import { UPADTE_BUNDLE_IN_EDIT } from "../../graphicalQl/mutation/bundleMutation.tsx";
import { DELETE_GROUP_IN_BUNDLE } from "../../graphicalQl/mutation/bundleMutation.tsx";
import DeleteDailougeInBundle from "./deleteGroupInBundle.tsx";
import DeleteProductDailougeInBundle from "./deleteproduct.tsx";
import DeleteVariationDailougeInBundle from "./deleteVariation.tsx";
import { toast } from "react-toastify";
import ImageDrawer from "../menu/image/imageDrawer.tsx";
import CurrencyDisplay from "../utils/currencySymbol.tsx";
import ErrorModal from "../errorHandling/errorModal.tsx";
import { useSelector } from "react-redux";
import LocationModal from "../product/locationModal.tsx";
import { useDispatch } from "react-redux";
import { resetState } from "../reduxReducers/categoryReducer";
import { setName } from "../../store.tsx";
import Stack from "@mui/material/Stack";
import BundleModal from "./bundlemodal.tsx";
import { useTranslation } from "react-i18next";
import BasicModal from "./errorInBundle.tsx";
import DelayedInput from "../../utils/delayedInput.tsx";
import CircularLoader from "../../loader/loader.tsx";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery.tsx";
import SuccessMessage from "./successMessageModal.tsx";

interface MenuItem {
  id: string;
  content: string;
  image: any;
  priceToDisplay: any;
  course: string;
  aditionalPrice: string;
  groupName: string[];
  variations: {
    id: string;
    variation: string;
    isSelected: boolean;
    aditionalPrice: string;
    price: string;
    variantGroup: string;
  }[];
}
interface MenuItem2 {
  id: string;
  content: string;
  image: any;
  priceToDisplay: any;
  course: string;
  group: string;
  aditionalPrice: string;
  isSelected: boolean;
  groupName: string[];
  variations: {
    id: string;
    variation: string;
    isSelected: boolean;
    aditionalPrice: string;
    price: string;
    variantGroup: string;
  }[];
}
interface Group {
  id: string;
  name: string;
  description: string;
  minimumSelect: string;
  maximumSelect: string;
  check: boolean;
  textCount: number;
}

interface State {
  name: string;

  description: string;
  isPos: boolean;
  isOnline: boolean;
  locations: any;
  group: Group[];
  image: string | null;
  leftItems: MenuItem2[];
  amount: string;
  discount: string;
  amountafterdiscount: string;
  radioValue: string;
}
interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}

const CreateBundle = () => {
  const { t } = useTranslation();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { id } = useParams();
  //group initial data
  const initialGroup = [
    {
      id: "0",
      name: "",
      description: "",
      minimumSelect: "",
      maximumSelect: "",
      check: false,
      textCount: 0,
    },
  ];
  const [group, setGroup] = useState<Group[]>(initialGroup);
  //left items are the dragged items in to groups
  const [leftItems, setLeftItems] = useState<MenuItem2[]>([]);

  const [searchValue, setSearchValue] = useState<string>("");
  const [modalState, setModalState] = useState(false);

  const initialState: State = {
    name: "",
    description: "",
    isPos: true,
    isOnline: true,
    locations: locationId,
    image: null,
    group: group,
    leftItems: leftItems,
    amount: "",
    discount: "",
    amountafterdiscount: "",
    radioValue: "combo",
  };

  useEffect(() => {
    formik.setFieldValue("locations", locationId);
    productDataRefetch();
  }, [locationId]);
  const validationSchemaPart1 = Yup.object({
    name: Yup.string().trim().required("Required"),
    // locations: Yup.array().min(1, "Required"),
    amount: Yup.number().required("Required"),
    group: Yup.array().of(
      Yup.object().shape({
        minimumSelect: Yup.number().typeError("*must be number"),
        maximumSelect: Yup.number().typeError("*must be number"),
        name: Yup.string().when("$radioValue", {
          is: (value: string) => {
            return value !== "combo";
          },
          then: (schema) =>
            schema
              .required("required")
              .test("is-unique", "Already Existed", function (value) {
                const str = this.path;
                const regex = /\[(\d+)\]/g;
                let match;
                const indices = [];

                while ((match = regex.exec(str)) !== null) {
                  indices.push(parseInt(match[1]));
                }
                const data = this.options.context && {
                  ...this.options.context,
                };
                let group;
                if (data) {
                  ({ group } = data);
                }

                const allVariantNames =
                  group &&
                  group.map((variant: any) =>
                    variant.name ? variant.name.trim().toLowerCase() : ""
                  );
                return (
                  allVariantNames &&
                  allVariantNames.filter(
                    (name: string) => name === value.trim().toLowerCase()
                  ).length <= 1
                );
              }),
          otherwise: (schema) => schema.notRequired(),
        }),
        // name: Yup.string()
        //   .required("*Required")
        //   .test("is-unique", "Already Existed", function (value) {
        //     const str = this.path;
        //     const regex = /\[(\d+)\]/g;
        //     let match;
        //     const indices = [];

        //     while ((match = regex.exec(str)) !== null) {
        //       indices.push(parseInt(match[1]));
        //     }
        //     const data = this.options.context && { ...this.options.context };
        //     let group;
        //     if (data) {
        //       ({ group } = data);
        //     }

        //     const allVariantNames =
        //       group &&
        //       group.map((variant: any) =>
        //         variant.name ? variant.name.trim().toLowerCase() : ""
        //       );
        //     return (
        //       allVariantNames &&
        //       allVariantNames.filter(
        //         (name: string) => name === value.trim().toLowerCase()
        //       ).length <= 1
        //     );
        //   }),
      })
    ),

    leftItems: Yup.array().of(
      Yup.object().shape({
        addPrice: Yup.array().of(
          Yup.object().shape({
            value: Yup.number().typeError("*must be number"),
          })
        ),
      })
    ),
  });
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (!resultObject.some((each: any) => each.result === false)) {
        createBundle({
          variables: filteredCategoryUpdate,
          onCompleted: (data) => {
            // Success callback
            dispatch(resetState());
            setModalState(true);
            // navigate("/bundle/bundle-listing");
            // toast.success(
            //   <div>
            //     <Typography>Create Bundle Successfully</Typography>
            //   </div>,
            //   {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: true,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            //     theme: "light",
            //     className: "custom_toast3",
            //   }
            // );
          },
          onError: (error) => {
            // Error callback
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
        });
      } else {
        sethandleModal(true);
        setExpandedPanel(resultObject[0].groupId);
      }
    },
  });
  //get product data query

  const variables1 = {
    businessId: +businessId,
    locationId: id === undefined ? formik.values.locations : locationId,
    name_Icontains: searchValue,
  };
  const {
    data,
    loading: productLoading,
    refetch: productDataRefetch,
  } = useQuery(PRODUCT_LIST_IN_BUNDLE, {
    context: { clientName: "categoryClient" },
    variables: variables1,
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error:", error);
    },
  });
  //create  bundle mutation
  const [createBundle, { loading: createLoading }] = useMutation(
    CREATE_BUNDLE,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [BUNDLE_LIST_DATA],
    }
  );

  // const [addItem, { loading: addItemLoading }] = useMutation(ADD_ITEM_BUNDLE, {
  //   context: { clientName: "categoryClient" },
  //   refetchQueries: [EDIT_BUNDLE_DATA],
  // });

  //save particular group mutation in the edit bundle

  const [addgroupInBundle, { loading: addGroupLoading }] = useMutation(
    ADD_GROUP_IN_BUNDLE,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [BUNDLE_LIST_DATA],
      onCompleted: (data) => {
        toast.success(
          <div>
            <Typography>{t("toast.added")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );
  const [updateBundle, { loading: updateBundleLoading }] = useMutation(
    UPADTE_BUNDLE_IN_EDIT,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [BUNDLE_LIST_DATA],
      onCompleted: (data) => {
        toast.success(
          <div>
            <Typography>{t("toast.updated")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
      onError: (error) => {
        // Error callback
        const graphqlError = error.graphQLErrors[0];

        if (
          graphqlError &&
          graphqlError.extensions &&
          graphqlError.extensions.status
        ) {
          // Extract the status code from the extensions property
          setCategoryStatusCode(
            parseInt(graphqlError.extensions.status, 10) || 500
          );
          setErrorModal(true);
        }
        const errorMessage = graphqlError.message || "An error occurred";
        console.error(
          `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
        );
      },
    }
  );
  //delte group mutation in edit bundle
  const [deleteGroup, { loading: deleteLoading }] = useMutation(
    DELETE_GROUP_IN_BUNDLE,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [EDIT_BUNDLE_DATA],
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );

  const navigate = useNavigate();

  const [pizza, setPizza] = useState<MenuItem[]>([]);

  useEffect(() => {
    if (data && data.productList) {
      const list = data.productList.edges.map((each: any) => ({
        id: each.node.productId,
        content: each.node.name || "",
        course: each.node.course && each.node.course.name,
        aditionalPrice: "",
        image: each.node.image || "",
        priceToDisplay: "",

        variations:
          each.node.variants &&
          each.node.variants.map((each1: any) => ({
            id: each1.variantId,
            variation: each1.name || "",
            isSelected: false,
            price: each1.price,
            aditionalPrice: "",
            variantGroup: each1.variantGroup,
          })),

        groupName: Array.from(
          new Set(
            each.node.variants &&
              each.node.variants.map((variant: any) => variant.variantGroup)
          )
        ),
      }));

      setPizza(list);
    }
  }, [data]);

  //Drag and drop functionality

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;

    const destinationList = leftItems; // Set the correct destination list
    const foundGroup = group.find((each) => each.id === destinationListId);
    const groupValue = foundGroup ? String(foundGroup.id) : "";
    // const groupName=foundGroup ? String(foundGroup.name) : '';
    if (formik.values.radioValue === "setMeal") {
      if (sourceListId !== "left" && sourceListId !== "right") {
        const draggedItem = result.draggableId;

        const resultValue = pizza.find(
          (each) =>
            each.variations &&
            each.variations.some((value) => value.id === draggedItem)
        );

        if (resultValue) {
          // If a matching variation was found, create a new array with just that variation
          const updatedVariations = resultValue.variations.filter(
            (value) => value.id === draggedItem
          );

          let updatedResultValue: MenuItem2 = {
            ...resultValue,
            variations: updatedVariations,
            group: groupValue,
            id: sourceListId.split("-")[1],

            isSelected: false,
          };

          // Now you can set this updated item in your state or use it as needed
          const superItem = leftItems.filter(
            (each) =>
              each.content === updatedResultValue.content &&
              each.group === updatedResultValue.group
          );

          const index = leftItems.findIndex(
            (each) =>
              each.content === updatedResultValue.content &&
              each.group === updatedResultValue.group
          );

          const allVariations = superItem.flatMap((item) =>
            item.variations.map((variation) => variation.variation)
          );

          // Find common variations with the second list
          const commonVariations = allVariations.includes(
            updatedResultValue.variations[0].variation
          );
          if (superItem.length >= 1) {
            if (superItem[0].variations.length >= 1) {
              updatedResultValue = {
                ...superItem[0], // Spread the first element of superItem
                variations: [...superItem[0].variations, ...updatedVariations],
                // id: sourceListId.split('-')[1],
              };
            }
          }

          const newDestinationList = Array.from(destinationList);
          if (index >= 0) {
            newDestinationList.splice(index, 1, updatedResultValue);
          } else {
            newDestinationList.splice(0, 0, updatedResultValue);
          }

          // newDestinationList.splice(result.destination.index, 0, updatedResultValue);
          const duplicate = leftItems.filter(
            (each) =>
              each.id === updatedResultValue.id &&
              each.group === updatedResultValue.group
          );
          if (!commonVariations) {
            setLeftItems(newDestinationList);
          } else {
            toast.error(
              <div>
                <Typography>variant already exists</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // className: 'custom_toast3',
              }
            );
          }
        }
      } else {
        const sourceList = pizza;
        const draggedItem = sourceList[result.source.index];

        const copiedItem: MenuItem2 = {
          ...draggedItem,
          id: draggedItem.id,
          group: groupValue,
          variations: draggedItem.variations ? draggedItem.variations : [],
          isSelected: false,
        };

        const newDestinationList = Array.from(destinationList);

        if (sourceListId === "right") {
          // newDestinationList.splice(0, 0, copiedItem);
          //  else if (destinationListId === 'left' && sourceListId === 'left') {
          //   const [removedItem] = newDestinationList.splice(result.source.index, 1);
          //   newDestinationList.splice(result.destination.index, 0, removedItem);
        }

        const duplicate = leftItems.filter(
          (each) => each.id === copiedItem.id && each.group === copiedItem.group
        );

        if (sourceListId !== "left") {
          const sameContentIndex = leftItems.findIndex(
            (each) =>
              each.content === copiedItem.content &&
              each.group === copiedItem.group
          );

          if (sameContentIndex >= 0) {
            // newDestinationList.splice(sameContentIndex, 1);
          }
        }

        if (duplicate.length === 0) {
          // if(groupIdListInEdit.includes(groupValue)){
          //   addItem({variables:{bundleId:id&&+id,isPreselected:false,productId:+draggedItem.id,groupName:groupName,additionalAmount:+1}})
          // }
          // else{

          newDestinationList.splice(0, 0, copiedItem);
          setLeftItems(newDestinationList);
          // }
        } else {
          const indexValue = leftItems.findIndex(
            (each) =>
              each.id === copiedItem.id && each.group === copiedItem.group
          );

          if (
            (copiedItem.variations && copiedItem.variations.length) ===
            (duplicate[0].variations && duplicate[0].variations.length)
          ) {
            return toast.error(
              <div>
                <Typography>Product already exists</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // className: 'custom_toast3',
              }
            );
          } else {
            newDestinationList.splice(indexValue, 1, copiedItem);
          }

          setLeftItems(newDestinationList);
        }
      }
    } else {
      if (sourceListId !== "left" && sourceListId !== "right") {
        const draggedItem = result.draggableId;

        const resultValue = pizza.find(
          (each) =>
            each.variations &&
            each.variations.some((value) => value.id === draggedItem)
        );

        if (resultValue) {
          // If a matching variation was found, create a new array with just that variation
          const superItem = leftItems.filter(
            (each) =>
              each.content === resultValue.content && each.group === groupValue
          );
          const updatedVariations = resultValue.variations.filter(
            (value) => value.id === draggedItem
          );
          let updatedResultValue: MenuItem2;

          if (superItem.length === 0) {
            updatedResultValue = {
              ...resultValue,
              variations: updatedVariations,
              group: groupValue,
              id: sourceListId.split("-")[1],

              isSelected: false,
            };
          } else {
            // const uniqueVariantGroups = Array.from(
            //   new Set(
            //     superItem[0].variations && superItem[0].variations.map((variant: any) => variant.variantGroup)
            //   )
            // );

            const superItemVariationsList = superItem[0].variations.filter(
              (each) => each.variantGroup !== updatedVariations[0].variantGroup
            );

            // const variationsList = superItem[0].variations.map((each) => {
     
            //   return each.variantGroup === updatedVariations[0].variantGroup ? updatedVariations[0] : each;
            // });

            const variationsList = [
              ...superItemVariationsList,
              updatedVariations[0],
            ];

            updatedResultValue = {
              ...resultValue,
              variations: variationsList,
              group: groupValue,
              id: sourceListId.split("-")[1],
              isSelected: false,
            };
          }

          const index = leftItems.findIndex(
            (each) =>
              each.content === updatedResultValue.content &&
              each.group === updatedResultValue.group
          );

          const newDestinationList = Array.from(destinationList);
          if (index >= 0) {
            newDestinationList.splice(index, 1, updatedResultValue);
          } else {
            newDestinationList.splice(0, 0, updatedResultValue);
          }

          setLeftItems(newDestinationList);

          // Now you can set this updated item in your state or use it as needed

          // const index = leftItems.findIndex(
          //   (each) =>
          //     each.content === updatedResultValue.content &&
          //     each.group === updatedResultValue.group
          // );

          // const allVariations = superItem.flatMap((item) =>
          //   item.variations.map((variation) => (
          //     {
          //       variationName: variation.variation,
          //       variationGroup: variation.variantGroup
          //     }))
          // );

          // Find common variations with the second list
          // const commonVariations = updatedResultValue.variations.filter((updatedVariation) =>
          //   allVariations.some(
          //     (variation) =>
          //       variation.variationName === updatedVariation.variation &&
          //       variation.variationGroup === updatedVariation.variantGroup
          //   )
          // );
        }
      } else {
        const sourceList = pizza;

        const draggedItem = sourceList[result.source.index];

        const copiedItem: MenuItem2 = {
          ...draggedItem,
          id: draggedItem.id,
          group: groupValue,
          variations: draggedItem.variations
            ? draggedItem.variations.reduce(
                (
                  uniqueVariations: {
                    id: string;
                    variation: string;
                    isSelected: boolean;
                    aditionalPrice: string;
                    price: string;
                    variantGroup: string;
                  }[],
                  currentVariation
                ) => {
                  // Check if the current variation's groupName is already in uniqueVariations
                  const isUnique = !uniqueVariations.some(
                    (v) => v.variantGroup === currentVariation.variantGroup
                  );

                  // If it's unique, add it to uniqueVariations
                  if (isUnique) {
                    uniqueVariations.push(currentVariation);
                  }

                  return uniqueVariations;
                },
                []
              )
            : [],
          isSelected: false,
        };
        const newDestinationList = Array.from(destinationList);

        if (sourceListId === "right") {
          // newDestinationList.splice(0, 0, copiedItem);
          //  else if (destinationListId === 'left' && sourceListId === 'left') {
          //   const [removedItem] = newDestinationList.splice(result.source.index, 1);
          //   newDestinationList.splice(result.destination.index, 0, removedItem);
        }

        const duplicate = leftItems.filter(
          (each) => each.id === copiedItem.id && each.group === copiedItem.group
        );

        if (sourceListId !== "left") {
          const sameContentIndex = leftItems.findIndex(
            (each) =>
              each.content === copiedItem.content &&
              each.group === copiedItem.group
          );

          if (sameContentIndex >= 0) {
            // newDestinationList.splice(sameContentIndex, 1);
          }
        }

        if (duplicate.length === 0) {
          // if(groupIdListInEdit.includes(groupValue)){
          //   addItem({variables:{bundleId:id&&+id,isPreselected:false,productId:+draggedItem.id,groupName:groupName,additionalAmount:+1}})
          // }
          // else{
          newDestinationList.splice(0, 0, copiedItem);

          setLeftItems(newDestinationList);
          // }
        } else {
          const index = leftItems.findIndex(
            (each) =>
              each.id === copiedItem.id && each.group === copiedItem.group
          );

          if (
            (copiedItem &&
              copiedItem.variations &&
              copiedItem.variations[0]?.variation) ===
            (duplicate[0].variations && duplicate[0].variations[0]?.variation)
          ) {
            return toast.error(
              <div>
                <Typography>Product already exists</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                // className: 'custom_toast3',
              }
            );
          } else {
            newDestinationList.splice(index, 1, copiedItem);
          }

          setLeftItems(newDestinationList);
        }
      }
    }
  };

  const handleAddgroupSave = (id1: string) => {
    const groupData = formik.values.group
      .filter((each1) => each1.id === id1)
      .map((each) => ({
        group_name: each.name,
        description: each.description,
        min_select: +each.minimumSelect,
        max_select: +each.maximumSelect,
        is_preselect_group: each.check,
        items: leftItems
          .filter((item) => each.id === item.group)
          .map((item) => {
            if (item.variations === null || item.variations.length === 0) {
              return {
                variant_id: null,
                product_id: +item.id,
                additional_amount: +item.aditionalPrice,
                is_preselected: item.isSelected,
              };
            } else {
              return item.variations.map((item2) => ({
                variant_id: item2.id,
                // product_id: +item.id,
                additional_amount: +item2.aditionalPrice,
                is_preselected: item2.isSelected,
              }));
            }
          })
          .flat(),
      }))[0];
    if (!resultObject.some((each: any) => each.result === false)) {
      addgroupInBundle({
        variables: { bundleId: id && +id, group: JSON.stringify(groupData) },
      });
    } else {
      sethandleModal(true);
      setExpandedPanel(resultObject[0].groupId);
    }
  };

  const [expandedPanel, setExpandedPanel] = useState<string | null>(null);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpandedPanel(isExpanded ? panel : null);
    };

  useEffect(() => {
    if (formik.values.group.length > 0) {
      setExpandedPanel(formik.values.group[0].id);
    }
  }, []);
  const addGroup = () => {
    const list = [
      {
        id: uuid(),
        name: "",
        description: "",
        minimumSelect: "",
        maximumSelect: "",
        check: false,
        textCount: 0,
      },
    ];
    setGroup((preValue) => [...list, ...preValue]);
    setExpandedPanel(list[0].id);
  };
  const handleGroupNameChange = (groupId: string, newName: string) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId ? { ...item, name: newName } : item
      )
    );
  };

  const handleGroupDescriptionChange = (
    groupId: string,
    newDescription: string
  ) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId ? { ...item, description: newDescription } : item
      )
    );
  };
  const handleGroupCheckChange = (groupId: string, newCheck: boolean) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId ? { ...item, check: newCheck } : item
      )
    );
    if (newCheck) {
      handleMinimumSelectChange(groupId, "1");
    } else {
      handleMinimumSelectChange(groupId, "");
    }
  };

  const handleMinimumSelectChange = (
    groupId: string,
    newMinimumSelect: string
  ) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId
          ? {
              ...item,
              minimumSelect: newMinimumSelect,
              check: +newMinimumSelect > 0 ? true : false,
            }
          : item
      )
    );
  };

  const handleMaximumSelectChange = (
    groupId: string,
    newMaximumSelect: string
  ) => {
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId
          ? { ...item, maximumSelect: newMaximumSelect }
          : item
      )
    );
  };

  const deleteItemInGroup = (id: string, groupName: string) => {
    const updatedLeftItems = leftItems.filter(
      (each) => !(each.id === id && each.group === groupName)
    );
    setLeftItems(updatedLeftItems);
  };
  const deleteVariation = (
    id: string,
    groupName: string,
    variationId: string
  ) => {
    const updatedVariation = leftItems.map((each) => {
      if (each.id === id && each.group === groupName) {
        each.variations = each.variations.filter(
          (item) => item.id !== variationId
        );
      }
      return each; // Return the modified 'each'
    });

    // const variantGroupName = leftItems
    //   .filter((item) => item.id === id && item.groupName.includes(groupName))
    //   .flatMap((item) => item.variations)
    //   .find((variation) => variation.id === variationId)?.variantGroup;
    const list = updatedVariation.map((item) => {
      // Check if the current item matches the provided itemId and group
      if (item.id === id && item.group === groupName) {
        // Initialize variables to track min and max prices
        let minPrice = Infinity;
        let maxPrice = -Infinity;

        // Iterate over variations to find min and max prices
        item.variations.forEach((variation) => {
          const aditionalPrice = +variation.aditionalPrice; // Convert aditionalPrice to number
          if (aditionalPrice !== 0) {
            minPrice = Math.min(minPrice, aditionalPrice);
          }
          if (aditionalPrice !== 0) {
            maxPrice = Math.max(maxPrice, aditionalPrice);
          }
        });

        // Handle edge cases where minPrice or maxPrice remain Infinity or -Infinity
        // if (minPrice === Infinity) {
        //   minPrice = 0;
        // }
        // if (maxPrice === -Infinity) {
        //   maxPrice = 0;
        // }

        // Calculate priceRange using minPrice and maxPrice
        const priceRange =
          minPrice !== Infinity && maxPrice !== -Infinity
            ? minPrice === 0
              ? `${maxPrice}`
              : minPrice === maxPrice
              ? `${minPrice}`
              : `${minPrice} - ${maxPrice}`
            : null;
        // Calculate priceToDisplay using minPrice and maxPrice

        // Return the updated item with the calculated priceToDisplay and priceRange
        return {
          ...item,
          priceToDisplay: priceRange,
        };
      }

      // If the item does not match, return it as it is
      return item;
    });

    const updatedList = list.map((item) => {
      if (
        item.id === id &&
        item.group === groupName &&
        item.variations &&
        item.variations.length === 0
      ) {
        return undefined; // returning undefined instead of null
      }
      return item;
    });

    const filteredList = updatedList.filter(
      (item) => item !== undefined
    ) as MenuItem2[]; // cast to MenuItem2[]

    setLeftItems(filteredList);
  };
  const UpdateAddPrice = (
    id: string,
    itemId: string,
    value: string,
    group: string
  ) => {
    const updatedItems = leftItems.map((each) => {
      if (each.id === itemId && each.group === group) {
        const updatedVariations = each.variations.map((variation) =>
          variation.id === id
            ? { ...variation, aditionalPrice: value }
            : variation
        );

        return {
          ...each,
          variations: updatedVariations,
        };
      }
      return each;
    });

    const list = updatedItems.map((item) => {
      // Check if the current item matches the provided itemId and group
      if (item.id === itemId && item.group === group) {
        // Initialize variables to track min and max prices
        let minPrice = Infinity;
        let maxPrice = -Infinity;

        // Iterate over variations to find min and max prices
        item.variations.forEach((variation) => {
          const aditionalPrice = +variation.aditionalPrice; // Convert aditionalPrice to number
          if (aditionalPrice !== 0) {
            minPrice = Math.min(minPrice, aditionalPrice);
          }
          if (aditionalPrice !== 0) {
            maxPrice = Math.max(maxPrice, aditionalPrice);
          }
        });

        // Handle edge cases where minPrice or maxPrice remain Infinity or -Infinity
        // if (minPrice === Infinity) {
        //   minPrice = 0;
        // }
        // if (maxPrice === -Infinity) {
        //   maxPrice = 0;
        // }

        // Calculate priceRange using minPrice and maxPrice
        const priceRange =
          minPrice !== Infinity && maxPrice !== -Infinity
            ? minPrice === 0
              ? `${maxPrice}`
              : minPrice === maxPrice
              ? `${minPrice}`
              : `${minPrice} - ${maxPrice}`
            : null;

        // Calculate priceToDisplay using minPrice and maxPrice

        // Return the updated item with the calculated priceToDisplay and priceRange
        return {
          ...item,
          priceToDisplay: priceRange,
        };
      }

      // If the item does not match, return it as it is
      return item;
    });

    setLeftItems(list);
  };

  const UpdateAddPrice2 = (id: string, itemId: string, value: string) => {
    setLeftItems((prevalue) => {
      const updatedItems = prevalue.map((each) => {
        if (each.id === itemId && each.group === id) {
          return {
            ...each,
            aditionalPrice: value,
            priceToDisplay: value,
          };
        }
        return each;
      });
      return updatedItems;
    });
  };

  const fiterPizza = pizza.filter((each) =>
    each.content.toLowerCase().includes(searchValue)
  );

  const [deletemodal, setDeleteModal] = useState<boolean>(false);

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const handleDeleteGroup = (id2: string, name: string) => {
    const updatedGroup = group.filter((each) => each.id !== id2);
    setGroup(updatedGroup);
  };

  const handleDeleteGroupInEdit = (name: string) => {
    if (id) {
      deleteGroup({ variables: { bundleId: +id, groupName: name } });
    }
  };

  const [deleteProductModal, setDeleteProductModal] = useState<boolean>(false);

  const handleDeleteProductModal = () => {
    setDeleteProductModal(!deleteProductModal);
  };

  const [deleteProductId, setdeleteProductId] = useState("");
  const [productGroupId, setProductGroupId] = useState("");

  const updateDeleteProductIds = (productId: string, groupId: string) => {
    setdeleteProductId(productId);
    setProductGroupId(groupId);
  };

  const [deletevariantId, setdeletevariantId] = useState("");
  const [variantGroupId, setVariantGroupId] = useState("");
  const [variantProductId, setVariantProductId] = useState("");

  const updateVariantProductIds = (
    productId: string,
    groupId: string,
    variantId: string
  ) => {
    setVariantProductId(productId);
    setVariantGroupId(groupId);
    setdeletevariantId(variantId);
  };

  const [deleteVariationModal, setDeleteVariationModal] =
    useState<boolean>(false);

  const handleDeletevarionModal = () => {
    setDeleteVariationModal(!deleteVariationModal);
  };

  const [openAccordionId1, setOpenAccordionId1] = useState<string | null>(null);

  const handleAccordionClick1 = (id: string) => {
    if (id === openAccordionId1) {
      setOpenAccordionId1(null);
    } else {
      setOpenAccordionId1(id);
    }
  };
  const { location } = useSelector((state: any) => state.app);

  // useEffect(() => {
  //   formik.setFieldValue("locations", location);
  // }, [location]);

  const [categoryStatusCode, setCategoryStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const [resultObject, setResultObject] = useState<any>([]);
  const [modal, sethandleModal] = useState(false);

 

  function arraysAreEqual(arr1: any[], arr2: any[]): boolean {
    if ((arr1 && arr1.length) !== (arr2 && arr2.length)) {
      return false;
    }
    const sortedArr1 = [...arr1].sort();
    const sortedArr2 = [...arr2].sort();
    return sortedArr1.every((value, index) => value === sortedArr2[index]);
  }

  useEffect(() => {
    if (leftItems.length > 0) {
      const list = leftItems.map((each: any) => {
        return {
          groupId: each.group,
          result: arraysAreEqual(
            each.groupName,
            Array.from(
              new Set(
                each.variations &&
                  each.variations.map((variant: any) => variant.variantGroup)
              )
            )
          ),
        };
      });

      // Filter list for unique groupId and result equal to false

      const filteredList = list.filter(
        (item, index, self) => item.result === false
        // &&
        //   index === self.findIndex((t) => t.groupId === item.groupId)
      );
      setResultObject(filteredList);
    }
  }, [leftItems]);

  // const groupData = formik.values.group.map((each) => ({
  //   group_name: each.name,
  //   description: each.description,
  //   min_select: +each.minimumSelect,
  //   max_select: +each.maximumSelect,
  //   is_preselected_group: each.check,
  //   items: leftItems.map((item) => {
  //     if (each.id === item.group) {
  //       if (item.variations === null) {
  //         return {
  //           variant_id: null,
  //           product_id: +item.id,
  //           additional_amount: +item.aditionalPrice,
  //           is_preselected: item.isSelected,

  //         };
  //       } else {
  //         return item.variations.map((item2) => ({
  //           variant_id: item2.id,
  //           product_id: +item.id,
  //           additional_amount: +item2.aditionalPrice,
  //           is_preselected: item2.isSelected,

  //         }));
  //       }
  //     }
  //     else {
  //       return null;
  //     }
  //   }).flat(),
  // }));
  const groupData = formik.values.group.map((each) => ({
    group_name: each.name,
    description: each.description,
    min_select: +each.minimumSelect,
    max_select: +each.maximumSelect,
    is_preselected_group: each.check,
    items: leftItems.reduce(
      (
        acc: Array<{
          variant_id: null | string;
          product_id: number;
          additional_amount: number;
          is_preselected: boolean;
        }>,
        item
      ) => {
        if (each.id === item.group) {
          if (item.variations.length === 0) {
            acc.push({
              variant_id: null,
              product_id: +item.id,
              additional_amount: +item.aditionalPrice,
              is_preselected: item.isSelected,
            });
          } else {
            acc.push(
              ...item.variations.map((item2) => ({
                variant_id: item2.id,
                product_id: +item.id,
                additional_amount: +item2.aditionalPrice,
                is_preselected: item2.isSelected,
              }))
            );
          }
        }
        return acc;
      },
      []
    ),
  }));

  useEffect(() => {
    // const value =
    //   (parseFloat (formik.values.amount) / 100) * (100 - parseFloat(formik.values.discount));
    const value = (
      (parseFloat(formik.values.amount) / 100) *
      (100 - parseFloat(formik.values.discount || "0"))
    ).toFixed(2);
    formik.setFieldValue("amountafterdiscount", value);

  }, [formik.values.amount, formik.values.discount]);
  useEffect(() => {
    formik.setFieldValue("leftItems", leftItems);
  }, [leftItems]);

  useEffect(() => {
    formik.setFieldValue("group", group);
  }, [group]);

  const [groupIdsByEdit, setGroupIdsByEdit] = useState<string[]>([]);

  const ordersListData = [
    {
      id: 1,
      name: t("menu.bundle.dineIn"),
      isChecked: false,
    },
    {
      id: 2,
      name: t("menu.bundle.delivery"),
      isChecked: false,
    },
    {
      id: 3,
      name: t("menu.bundle.shop"),
      isChecked: false,
    },
    {
      id: 4,
      name: t("menu.bundle.collection"),
      isChecked: false,
    },
  ];

  const [orderTypelist, setOrderType] = useState(ordersListData);
  const imageDrawerRef = React.useRef<any>();

  const handleCancelButton = () => {
    formik.setValues(initialState);
    dispatch(resetState());
    dispatch(setName("location", []));
    setLeftItems([]);
    setOrderType(ordersListData);
    if (imageDrawerRef.current) {
      imageDrawerRef.current.handleNull();
    }
    setUploadColor(null);
    setUploadImg(null);
  };

  useEffect(() => {
    setOrderType([
      {
        id: 1,
        name: t("menu.bundle.dineIn"),
        isChecked: orderTypelist[0].isChecked,
      },
      {
        id: 2,
        name: t("menu.bundle.delivery"),
        isChecked: orderTypelist[1].isChecked,
      },
      {
        id: 3,
        name: t("menu.bundle.shop"),
        isChecked: orderTypelist[2].isChecked,
      },
      {
        id: 4,
        name: t("menu.bundle.collection"),
        isChecked: orderTypelist[3].isChecked,
      },
    ]);
  }, [t]);

  const { loading: getDataLoading, data: editData } = useQuery(
    EDIT_BUNDLE_DATA,
    {
      context: { clientName: "categoryClient" },
      variables: { bundleId: id },
      fetchPolicy: "network-only",
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );
  useEffect(() => {
    if (editData && editData.bundle) {
      formik.setValues((prevValues) => ({
        ...prevValues,
        name: editData.bundle.name || "",
        description: editData.bundle.description || "",
        isPos: editData.bundle.isPos || true,
        isOnline: editData.bundle.isOnline || true,
        locations: [editData.bundle.locationId] || [],

        amount: editData.bundle.amount || "",
        discount: editData.bundle.discountPercent || "",
        amountafterdiscount: editData.bundle.amountAfterDiscount || "",

        image: editData.bundle.image || null,
        radioValue: editData.bundle.isSetMeals === true ? "setMeal" : "combo",
      }));

      setColorCodeFromAPi(editData.bundle.bgColourCode || null);
      if (editData.bundle.bundleGroups) {
        const groupList = JSON.parse(editData.bundle.bundleGroups).map(
          (each: any) => ({
            id: uuid(),
            name: each.group_name,
            description: each.description,
            minimumSelect: each.min_select,
            maximumSelect: each.max_select,
            check: each.is_preselect_group,
            TextCount: each.description.length,
          })
        );

        setGroup(groupList);

        const list = groupList.map((each: any) => each.name);

        setGroupIdsByEdit(list);

        const productData = JSON.parse(editData.bundle.bundleGroups).flatMap(
          (each: any) =>
            each.products.map((item: any) => {
              let minPrice = Infinity;
              let maxPrice = -Infinity;

              // Iterate over variations to find min and max prices
              if (item.variants && item.variants.length > 0) {
                item.variants.forEach((variation: any) => {
                  const aditionalPrice = +variation.additional_amount;
                  // Convert aditionalPrice to number{}
                  if (aditionalPrice > 0) {
                    minPrice = Math.min(minPrice, aditionalPrice);
                  }
                  if (aditionalPrice > 0) {
                    maxPrice = Math.max(maxPrice, aditionalPrice);
                  }
                });
              } else {
                const aditionalPrice = +item.additional_amount; // Convert aditionalPrice to number
                minPrice = aditionalPrice;
                maxPrice = aditionalPrice;
              }

              // Handle edge cases where minPrice or maxPrice remain Infinity or -Infinity
              // if (minPrice === Infinity) {
              //   minPrice = 0;
              // }
              // if (maxPrice === -Infinity) {
              //   maxPrice = 0;
              // }

              // Calculate priceRange using minPrice and maxPrice
              const priceRange =
                minPrice !== Infinity && maxPrice !== -Infinity
                  ? minPrice === 0
                    ? `${maxPrice}`
                    : minPrice === maxPrice
                    ? `${minPrice}`
                    : `${minPrice} - ${maxPrice}`
                  : null;

              return {
                id: item.product_id.toString() || "",
                content: item.product_name || "",
                image: item.product_image || "",
                course: "",
                group: groupList.filter(
                  (group: any) => group.name === each.group_name
                )[0]?.id,
                aditionalPrice: item.additional_amount || "",
                isSelected: item.is_preselected || false,
                priceToDisplay: priceRange,
                groupName: Array.from(
                  new Set(
                    item.variants &&
                      item.variants.map((variant: any) => variant.variant_group)
                  )
                ),
                variations: item.variants
                  ? item.variants.map((variation: any) => ({
                      id: variation.variant_id || "",
                      variation: variation.variant_name || "",
                      isSelected: variation.is_preselected || false,
                      aditionalPrice: variation.additional_amount || "",
                      variantGroup: variation.variant_group,
                      // groupName: Array.from(new Set(
                      //   item.variants && item.variants.map((variant: any) => variant.variant_group)
                      // )),
                      price: "",
                    }))
                  : [],
              };
            })
        );

        setLeftItems(productData);
        const list3 = orderTypelist.map((each) => {
          if (each.id === 1) {
            return { ...each, isChecked: editData.bundle.isDinein };
          } else if (each.id === 2) {
            return { ...each, isChecked: editData.bundle.isDelivery };
          } else if (each.id === 3) {
            return { ...each, isChecked: editData.bundle.isCarry };
          } else {
            return { ...each, isChecked: editData.bundle.isCollection }; // Return the original object if the ID doesn't match
          }
        });
        setOrderType(list3);
      }
    }
  }, [editData]);
  const [dailougeValue, setDailouge] = useState(false);

  // useEffect(() => {

  // }, [formik.values.radioValue])

  const handlePreSelectProduct = (
    e: ChangeEvent<HTMLInputElement>,
    productId: string,
    content: string
  ) => {
    const particularGroup = leftItems.filter(
      (each: any) => each.group === productId
    );
    const preSelectedProductLength = particularGroup.filter(
      (each) => each.isSelected === true
    ).length;

    const groupMinSelect = group.filter((each) => each.id === productId)[0]
      .minimumSelect;

    setLeftItems((prevGroup) =>
      prevGroup.map((item) =>
        (item.content === content &&
          +groupMinSelect > preSelectedProductLength &&
          item.group === productId) ||
        (e.target.checked === false &&
          item.content === content &&
          item.group === productId)
          ? { ...item, isSelected: e.target.checked }
          : { ...item }
      )
    );
  };

  const handleVariationPreSelect = (
    e: ChangeEvent<HTMLInputElement>,
    id: string,
    content: string,
    groupId: string
  ) => {
    const particularGroup = leftItems.filter(
      (each: any) => each.group === groupId
    );
    const preSelectedProductLength =
      particularGroup &&
      particularGroup
        .map((each: any) => {
          if (each.variations && Array.isArray(each.variations)) {
            return each.variations.filter(
              (each1: any) => each1.isSelected === true
            );
          } else {
            return [];
          }
        })
        .flat().length;

    const groupMinSelect = group.filter((each) => each.id === groupId)[0]
      .minimumSelect;

    const list = leftItems.map((each) =>
      (each.content === content &&
        +groupMinSelect > preSelectedProductLength &&
        each.group === groupId) ||
      (e.target.checked === false &&
        each.content === content &&
        each.group === groupId)
        ? {
            ...each,
            variations: each.variations.map((varItem: any) =>
              varItem.id === id
                ? { ...varItem, isSelected: e.target.checked }
                : varItem
            ),
          }
        : { ...each }
    );

    setLeftItems(list);
  };

  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadColor, setUploadColor] = useState<string | null>(null);

  const handleImage = (value: string | null) => {
    setUploadImg(value);
    formik.setFieldValue("image", value);
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
  };

  const [colorCodeFromApi, setColorCodeFromAPi] = useState("");

  const finalList = {
    businessId: +businessId,
    name: formik.values.name,
    amount: +formik.values.amount,
    description: formik.values.description,
    image: uploadImg,
    groups: JSON.stringify(groupData),
    discountPercent: +formik.values.discount,
    amountAfterDiscount: +formik.values.amountafterdiscount,
    isPos: formik.values.isPos,
    isOnline: formik.values.isOnline,
    locations: JSON.stringify([+formik.values.locations]),
    bgColourCode: uploadColor,
    isSetMeals: formik.values.radioValue === "setMeal",
    isDinein: orderTypelist[0].isChecked,
    isDelivery: orderTypelist[1].isChecked,
    isCarry: orderTypelist[2].isChecked,
    isCollection: orderTypelist[0].isChecked,
  };

  const filteredCategoryUpdate = Object.fromEntries(
    Object.entries(finalList).filter(
      ([_, value]) => value !== null && value !== ""
    )
  );

  const upDateList = {
    businessId: +businessId,
    name: formik.values.name,
    bundleId: id && +id,
    amount: +formik.values.amount,
    description: formik.values.description,
    image: uploadImg,
    bgColourCode: uploadColor,
    discountPercent: +formik.values.discount,
    amountAfterDiscount: +formik.values.amountafterdiscount,
    isPos: formik.values.isPos,
    isOnline: formik.values.isOnline,
    isSetMeals: formik.values.radioValue === "setMeal",
    isDinein: orderTypelist[0].isChecked,
    isDelivery: orderTypelist[1].isChecked,
    isCarry: orderTypelist[2].isChecked,
    isCollection: orderTypelist[0].isChecked,
  };

  const filteredUpdate = Object.fromEntries(
    Object.entries(upDateList).filter(
      ([_, value]) => value !== null && value !== ""
    )
  );

  const handleUpdateBundle = () => {
    updateBundle({ variables: filteredUpdate });
  };

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  const handleGroupDescriptionCount = (
    groupId: string,
    newDescription: string
  ) => {
    let count = newDescription.length;
    if (count > 500) {
      newDescription = newDescription.substring(0, 500);
      count = 500;
    }
    setGroup((prevGroup) =>
      prevGroup.map((item) =>
        item.id === groupId ? { ...item, textCount: count } : item
      )
    );
  };
  const CustomStyles = styled("div")`
    & > *:not(style) {
      margin: 4px;
    }
  `;

  
  const dispatch = useDispatch();
  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });

  const [locationsList, setLocationsList] = useState<Locations[]>([]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );

      // Updating state with the required data
      setLocationsList(requiredDataList);
    }
  }, [locationsData]);
  const handleOrderTypeChange = (id: number, value: boolean) => {
    const list = orderTypelist.map((each) =>
      each.id === id ? { ...each, isChecked: value } : each
    );
    setOrderType(list);
  };
  const handleModal = () => {
    if (formik.values.leftItems.length > 0) {
      setDailouge(true);
    } else {
      setGroup(initialGroup);
    }
  };

  const [locationName, setLocationName] = useState(
    locationsList.filter((each) => each.id === formik.values.locations)[0]
      ?.storeName
  );

  useEffect(() => {
    setLocationName(
      locationsList.filter(
        (each) => each.storeId === formik.values.locations
      )[0]?.storeName
    );
  }, [formik.values.locations]);

  const currencySymbol = sessionStorage.getItem("currencySymbol");
  if (getDataLoading) {
    return <CircularLoader />;
  }

  return (
    <Box sx={{ backgroundColor: "#FBFBFB" }}>
      <form onSubmit={formik.handleSubmit}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.625rem 0.875rem",
            backgroundColor: "#FFFFFF",
          }}
        >
          <IconButton
            className="create-bundle-exit"
            onClick={() => {
              navigate("/bundle/bundle-listing");
              dispatch(resetState());
            }}
          >
            <ArrowBackIcon className="create-bundle-exitIcon" />
          </IconButton>
          {id ? (
            <Typography className="create-bundle-head">
              {t("menu.bundle.edit_a_bundle")}
            </Typography>
          ) : (
            <Typography className="create-bundle-head">
              {t("menu.bundle.create_a_bundle")}
            </Typography>
          )}
          {/* <Typography className="create-bundle-head">Create a Bundle</Typography> */}
          {!id ? (
            <Box sx={{ display: "flex", gap: "1.5rem" }}>
              <Button
                className="create_bundle_clear_button"
                style={{ textTransform: "none" }}
                onClick={handleCancelButton}
              >
                {t("menu.bundle.cancel")}
              </Button>
              <Button
                className={
                  leftItems.length === 0
                    ? "main-button-disable"
                    : "create-bundle-button"
                }
                style={{ textTransform: "none" }}
                type="submit"
                disabled={leftItems.length === 0 || createLoading}
              >
                {t("menu.bundle.save")}
              </Button>
            </Box>
          ) : (
            <Typography></Typography>
          )}
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Box
            sx={{
              display: "flex",
              backgroundColor: "#FBFBFB",
              marginTop: "1.5rem",
              marginLeft: "1.6rem",
              gap: "2.875rem",
            }}
          >
            <Box
              sx={{
                width: "65%",
                height: "75vh",
                overflowY: "auto",
                WebkitOverflowScrolling: "touch",
                border: "1px solid #EEE",
                borderRadius: "4px",
              }}
            >
              <Paper
                elevation={1}
                style={{
                  padding: "0.75rem 1.25rem",
                  borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)",
                }}
              >
                <Typography className="create-bundle-details">
                  {t("menu.bundle.details")}
                </Typography>
                <div
                  style={{
                    display: "flex",
                    gap: "2.625rem",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "65%" }}>
                    <div
                      className="create-bundle-text-container"
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div style={{ width: "45%" }}>
                        <TextField
                          id="outlined-basic"
                          label={t("menu.bundle.bundle_name")}
                          variant="outlined"
                          name="name"
                          value={formik.values.name}
                          onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            if (e.target.value.length <= 25) {
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          color="warning"
                          sx={{ width: "100%" }}
                          error={
                            formik.touched.name && Boolean(formik.errors.name)
                          }
                          helperText={formik.touched.name && formik.errors.name}
                        />
                        <Box sx={{ width: "100%", textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.name.length}/ 25
                          </Typography>
                        </Box>
                        {/* {formik.touched.name && formik.errors.name && (
                          <div style={{ color: "red" }}>
                            {formik.errors.name as string}
                          </div>
                        )} */}
                      </div>
                      {!id && (
                        <FormControl
                          className="create_tax_dropDown"
                          style={{ margin: "0px 0px 0px 24px", width: "45%" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            {t("settings.select_location")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={formik.values.locations}
                            name="locations"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            label={t("settings.select_location")}
                            color="warning"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          >
                            {locationsList.map((each) => (
                              <MenuItem
                                key={each.storeId}
                                value={each.storeId}
                                sx={{ textTransform: "capitalize" }}
                              >
                                {each.storeName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        // <Box sx={{ width: "45%" }}>
                        //   <LocationModal formik={formik} />
                        //   {formik.touched.locations &&
                        //     formik.errors.locations && (
                        //       <Typography style={{ color: "red", fontSize: "12px" }}>
                        //         {formik.errors.locations as string}
                        //       </Typography>
                        //     )}
                        // </Box>
                      )}
                    </div>
                    <div style={{ marginTop: "18px" }}>
                      <div className="textarea-box" style={{ width: "100%" }}>
                        <textarea
                          id="textarea"
                          onChange={(e) => {
                            updateCharCount(e);
                            formik.handleChange(e);
                          }}
                          style={{ width: "100%" }}
                          name="description"
                          value={formik.values.description}
                          //@ts-ignore
                          placeholder={t("menu.bundle.enter_description")}
                        >
                          {formik.values.description}
                        </textarea>
                        <div id="charCount" className="char-count">
                          {charCount}/500
                        </div>
                      </div>
                    </div>
                  </div>
                  <Box
                    sx={{ height: "150px", width: "150px", padding: "2px" }}
                    className="create-product-img-con"
                  >
                    <ImageDrawer
                      handleImage={handleImage}
                      handleColor={handleColor}
                      colorCodeFromApi={colorCodeFromApi}
                      imageFromApi={formik.values.image}
                      heightValue={120}
                      ref={imageDrawerRef}
                    />
                  </Box>
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="warning"
                          name="isPos"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.handleChange(e)
                          }
                          checked={formik.values.isPos}
                        />
                      }
                      label={
                        <Typography className="create-product-switch-text">
                          {t("menu.bundle.show_pos")}
                        </Typography>
                      }
                      style={{ width: "40%" }}
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="warning"
                          name="isOnline"
                          onChange={(e: ChangeEvent<HTMLInputElement>) =>
                            formik.handleChange(e)
                          }
                          checked={formik.values.isOnline}
                        />
                      }
                      label={
                        <Typography className="create-product-switch-text">
                          {t("menu.bundle.show_online")}
                        </Typography>
                      }
                      style={{ width: "40%" }}
                    />
                  </div>

                  {/* <div style={{ display: "flex", alignItems: "center" }}>
                  <Button style={{ textTransform: "none", textDecoration: "underLine" }} className="create-bundle-cancel_Button">Cancel</Button>
                  <Button style={{ textTransform: "none" }} className="create-bundle-button">Save</Button>
                </div> */}
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "65%",
                      justifyContent: "space-between",
                      marginTop: "1%",
                    }}
                  >
                    <div style={{ width: "30%" }}>
                      <TextField
                        color="warning"
                        // type='number'
                        label={t("menu.bundle.before_discount")}
                        name="amount"
                        className="hide-number-input-icons"
                        size="small"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          const value = +e.target.value;

                          if (value >= 0) {
                            formik.handleChange(e);
                          }
                        }}
                        value={formik.values.amount}
                      />
                      {/* <Typography className='discountText'>before discount</Typography> */}
                      {formik.touched.amount && formik.errors.amount && (
                        <div style={{ color: "red" }}>
                          <Typography sx={{ fontSize: "12px" }}>
                            {formik.errors.amount as string}
                          </Typography>
                        </div>
                      )}
                    </div>
                    <div style={{ width: "30%" }}>
                      <TextField
                        color="warning"
                        label={t("menu.bundle.discount_%")}
                        name="discount"
                        className="hide-number-input-icons"
                        size="small"
                        onChange={(e) => {
                          const value = +e.target.value;

                          if (value >= 0 && value <= 100) {
                            formik.handleChange(e);
                          }
                        }}
                        value={formik.values.discount}
                      />
                    </div>
                    <div style={{ width: "30%" }}>
                      <TextField
                        color="warning"
                        type="number"
                        label={t("menu.bundle.after_discount")}
                        name="amountafterdiscount"
                        className="hide-number-input-icons"
                        size="small"
                        value={
                          formik.values.amountafterdiscount
                          // .toFixed(2)
                        }
                      />
                    </div>
                  </div>
                  {id && (
                    <Button
                      sx={{ marginRight: "10%", textTransform: "none" }}
                      className="create-bundle-button"
                      onClick={handleUpdateBundle}
                      disabled={updateBundleLoading}
                    >
                      {t("menu.bundle.save")}
                    </Button>
                  )}
                </div>
                <Box
                  className="product-order-con-inner"
                  sx={{ marginTop: "12px" }}
                >
                  <Typography className="create-bundle-text-2">
                    {t("menu.bundle.select_order_type")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "12px",
                      flexWrap: "wrap",
                      alignItems: "flex-start",
                      marginLeft: "12px",
                    }}
                  >
                    {orderTypelist &&
                      orderTypelist.map((each: any, index: number) => (
                        <FormControlLabel
                          control={
                            <Checkbox
                              color="warning"
                              checked={each.isChecked}
                              onChange={(e) =>
                                handleOrderTypeChange(each.id, e.target.checked)
                              }
                            />
                          }
                          label={
                            <Typography className="heavyHeader-para">
                              {each.name}
                            </Typography>
                          }
                          className="product-checkBox-con"
                          key={each.id}
                        />
                      ))}
                  </Box>
                </Box>
                <Box sx={{ marginTop: "12px" }}>
                  <FormControl>
                    <FormLabel
                      id="demo-radio-buttons-group-label"
                      className="create-bundle-text-2"
                    >
                      {t("menu.bundle.mark_bundle_as")}{" "}
                    </FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      value={formik.values.radioValue}
                      row
                      sx={{ marginTop: "12px" }}
                      name="radioValue"
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleModal();
                      }}
                    >
                      <FormControlLabel
                        value="combo"
                        control={
                          <Radio color="warning" disabled={id ? true : false} />
                        }
                        label={t("menu.bundle.combo")}
                        className="product-checkBox-con"
                      />
                      <FormControlLabel
                        value="setMeal"
                        control={
                          <Radio color="warning" disabled={id ? true : false} />
                        }
                        label={t("menu.bundle.setMeals")}
                        className="product-checkBox-con"
                        sx={{ marginLeft: "24px" }}
                      />
                    </RadioGroup>
                  </FormControl>
                </Box>
              </Paper>
              {/* <BundleDragDrop/> */}
              {formik.values.radioValue === "setMeal" ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "1%",
                  }}
                >
                  <Button
                    className="create-bundle-button2"
                    onClick={() => {
                      addGroup();
                    }}
                    sx={{ textTransform: "none" }}
                  >
                    {t("menu.bundle.add_group")}
                  </Button>
                  <List>
                    {formik.values.group.map((each: any, index: number) => (
                      <Paper
                        elevation={0}
                        style={{
                          marginTop: "2rem",
                          borderLeft:
                            "2px solid var(--stroke-color-1-violet, #AE9BE2)",
                        }}
                      >
                        <ListItem key={each.id} sx={{ padding: "0px" }}>
                          <Accordion
                            sx={{ width: "100%" }}
                            expanded={expandedPanel === each.id}
                            onChange={handleChange(each.id)}
                          >
                            <AccordionSummary
                              expandIcon={<ExpandMoreIcon />}
                              aria-controls={`panel-${each.id}-content`}
                              id={`panel-${each.id}-header`}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "space-between",
                                  width: "90%",
                                  marginLeft: "2%",
                                }}
                              >
                                <Typography>{each.name}</Typography>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {id && (
                                    <Button
                                      sx={{
                                        marginRight: "10%",
                                        textTransform: "none",
                                      }}
                                      className="create-bundle-button3"
                                      onClick={() => {
                                        handleAddgroupSave(each.id);
                                      }}
                                      disabled={addGroupLoading}
                                    >
                                      {t("menu.bundle.save")}
                                    </Button>
                                  )}
                                  <Button
                                    onClick={() => {
                                      if (groupIdsByEdit.includes(each.name)) {
                                        handleDeleteModal();
                                      } else {
                                        handleDeleteGroup(each.id, each.name);
                                      }
                                    }}
                                    sx={{
                                      marginRight: "10%",
                                      textTransform: "none",
                                    }}
                                    className="create-bundle-button3"
                                    disabled={deleteLoading}
                                  >
                                    {t("menu.bundle.delete")}
                                  </Button>
                                  <DeleteDailougeInBundle
                                    deletemodal={deletemodal}
                                    handleDleteByPopover={
                                      handleDeleteGroupInEdit
                                    }
                                    id={each.name}
                                    handleDeleteModal={handleDeleteModal}
                                  />
                                </Box>
                              </Box>
                            </AccordionSummary>
                            <StrictModeDroppable
                              droppableId={each.id}
                              type="list"
                            >
                              {(provided) => (
                                <ul
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{
                                    listStyle: "none",
                                    padding: "0",
                                    width: "100%",
                                  }}
                                >
                                  <AccordionDetails>
                                    <Box key={each.id} style={{}}>
                                      <Box
                                        sx={{
                                          width: "100%",
                                          display: "flex",
                                          flexDirection: "column",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            justifyContent: "space-between",
                                          }}
                                        >
                                          <Box sx={{ width: "30%" }}>
                                            <TextField
                                              label={t(
                                                "menu.bundle.group_name"
                                              )}
                                              color="warning"
                                              value={each.name}
                                              name={`group[${index}].name`}
                                              onChange={(e) => {
                                                if (
                                                  e.target.value.length <= 25
                                                ) {
                                                  handleGroupNameChange(
                                                    each.id,
                                                    e.target.value
                                                  );
                                                  formik.handleChange(e);
                                                }
                                              }}
                                              onBlur={formik.handleBlur}
                                              error={
                                                formik.touched.group &&
                                                formik.touched.group[index] &&
                                                formik.touched.group[index]
                                                  ?.name &&
                                                Boolean(
                                                  formik.errors.group &&
                                                    formik.errors.group[
                                                      index
                                                    ] &&
                                                    (
                                                      formik.errors
                                                        .group as FormikErrors<
                                                        Group[]
                                                      >
                                                    )[index]?.name
                                                )
                                              }
                                              helperText={
                                                formik.touched.group &&
                                                formik.touched.group[index] &&
                                                formik.touched.group[index]
                                                  ?.name &&
                                                formik.errors.group &&
                                                formik.errors.group[index] &&
                                                (
                                                  formik.errors
                                                    .group as FormikErrors<
                                                    Group[]
                                                  >
                                                )[index]?.name
                                              }
                                            />
                                            <Box
                                              sx={{
                                                width: "100%",
                                                textAlign: "right",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  fontSize: "12px",
                                                  color: "#707070",
                                                }}
                                              >
                                                {each.name.length}/ 25
                                              </Typography>
                                            </Box>
                                            {/* {formik.touched.group &&
                                        formik.touched.group[index] &&
                                        formik.touched.group[index]
                                          ?.name &&
                                        formik.errors.group &&
                                        formik.errors.group[index] &&
                                        (
                                          formik.errors
                                            .group as FormikErrors<
                                              Group[]
                                            >
                                        )[index]?.name && (
                                          <div style={{ color: "red" }}>
                                            {
                                              (
                                                formik.errors
                                                  .group as FormikErrors<
                                                    Group[]
                                                  >
                                              )[index]?.name
                                            }
                                          </div>
                                        )} */}

                                            {/* {each.name === "" ? (<Typography sx={{ color: "red" }}>*Enter the name</Typography>) : (null)} */}
                                          </Box>
                                          <Typography>{each.check}</Typography>
                                          <FormControlLabel
                                            control={
                                              <Checkbox
                                                color="warning"
                                                checked={each.check}
                                              />
                                            }
                                            label={
                                              <Typography className="mandiatory-group-text">
                                                {t(
                                                  "menu.bundle.mandiatory_group"
                                                )}
                                              </Typography>
                                            }
                                            onChange={(e) =>
                                              handleGroupCheckChange(
                                                each.id,
                                                (e.target as HTMLInputElement)
                                                  .checked
                                              )
                                            }
                                          />
                                        </Box>

                                        {/* <TextField label={t("menu.bundle.description")} color='warning' multiline sx={{ width: '90%', marginTop: "0.75rem" }} value={each.description}
                                        onChange={(e) => handleGroupDescriptionChange(each.id, e.target.value)} />
                                       */}
                                        <div
                                          className="textarea-box"
                                          style={{
                                            width: "70%",
                                            marginTop: "12px",
                                          }}
                                        >
                                          <textarea
                                            id="textarea1"
                                            onChange={(e) => {
                                              handleGroupDescriptionCount(
                                                each.id,
                                                e.target.value
                                              );
                                              handleGroupDescriptionChange(
                                                each.id,
                                                e.target.value
                                              );
                                            }}
                                            style={{ width: "100%" }}
                                            name="description"
                                            value={each.description}
                                            //@ts-ignore
                                            placeholder={t(
                                              "menu.bundle.enter_description"
                                            )}
                                          >
                                            {each.description}
                                          </textarea>
                                          <div
                                            id="charCount"
                                            className="char-count"
                                          >
                                            {each.textCount}/500
                                          </div>
                                        </div>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            gap: "4rem",
                                          }}
                                        >
                                          <div style={{ marginTop: "0.75rem" }}>
                                            <TextField
                                              label={t(
                                                "menu.bundle.min_select"
                                              )}
                                              color="warning"
                                              value={each.minimumSelect}
                                              name={`group[${index}].minimumSelect`}
                                              // type="number"
                                              className="hide-number-input-icons"
                                              onChange={(e) => {
                                                const value = +e.target.value;

                                                if (value >= 0) {
                                                  handleMinimumSelectChange(
                                                    each.id,
                                                    e.target.value
                                                  );
                                                  formik.handleChange(e);
                                                }
                                              }}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.group &&
                                              formik.touched.group[index] &&
                                              formik.touched.group[index]
                                                ?.minimumSelect &&
                                              formik.errors.group &&
                                              formik.errors.group[index] &&
                                              (
                                                formik.errors
                                                  .group as FormikErrors<
                                                  Group[]
                                                >
                                              )[index]?.minimumSelect && (
                                                <div style={{ color: "red" }}>
                                                  {
                                                    (
                                                      formik.errors
                                                        .group as FormikErrors<
                                                        Group[]
                                                      >
                                                    )[index]?.minimumSelect
                                                  }
                                                </div>
                                              )}
                                          </div>
                                          <div style={{ marginTop: "0.75rem" }}>
                                            <TextField
                                              label={t(
                                                "menu.bundle.max_select"
                                              )}
                                              color="warning"
                                              value={each.maximumSelect}
                                              name={`group[${index}].maximumSelect`}
                                              type="number"
                                              className="hide-number-input-icons"
                                              onChange={(e) => {
                                                const value = +e.target.value;

                                                if (value >= 0) {
                                                  handleMaximumSelectChange(
                                                    each.id,
                                                    e.target.value
                                                  );
                                                  formik.handleChange(e);
                                                }
                                              }}
                                              onBlur={formik.handleBlur}
                                            />
                                            {formik.touched.group &&
                                              formik.touched.group[index] &&
                                              formik.touched.group[index]
                                                ?.maximumSelect &&
                                              formik.errors.group &&
                                              formik.errors.group[index] &&
                                              (
                                                formik.errors
                                                  .group as FormikErrors<
                                                  Group[]
                                                >
                                              )[index]?.maximumSelect && (
                                                <div style={{ color: "red" }}>
                                                  {
                                                    (
                                                      formik.errors
                                                        .group as FormikErrors<
                                                        Group[]
                                                      >
                                                    )[index]?.maximumSelect
                                                  }
                                                </div>
                                              )}
                                          </div>
                                        </Box>

                                        {formik.values.leftItems.filter(
                                          (each1) => each1.group === each.id
                                        ).length > 0 ? (
                                          <Box sx={{ marginTop: "2%" }}>
                                            {/* <Typography>{leftItems.filter((each1)=>each1.group===each.id).length}</Typography> */}
                                            <List>
                                              {formik.values.leftItems.map(
                                                (item, index) => (
                                                  <ListItem
                                                    key={`${item.id}-${item.group}-${index}`}
                                                    sx={{ padding: 0 }}
                                                  >
                                                    {item.group === each.id ? ( // Check if item.group is equal to each.name
                                                      <Box
                                                        sx={{
                                                          width: "100%",
                                                          display: "flex",
                                                          flexDirection:
                                                            "column",
                                                        }}
                                                      >
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            justifyContent:
                                                              "space-between",
                                                            width: "100%",
                                                            gap: 0,
                                                            height: 50,
                                                          }}
                                                        >
                                                          <Box
                                                            style={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              width: "30%",
                                                            }}
                                                          >
                                                            {item.image && (
                                                              <img
                                                                src={item.image}
                                                                alt="add"
                                                                className="create-bundle-button-image"
                                                              />
                                                            )}
                                                            <Typography className="create-bundle-text-head">
                                                              {item.content}
                                                            </Typography>
                                                          </Box>

                                                          {!(
                                                            item.variations &&
                                                            item.variations
                                                              .length > 0
                                                          ) ? (
                                                            <TextField
                                                              label={t(
                                                                "menu.bundle.aditional_amount"
                                                              )}
                                                              type="number"
                                                              className="hide-number-input-icons"
                                                              value={
                                                                item.aditionalPrice
                                                              }
                                                              color="warning"
                                                              size="small"
                                                              onChange={(e) =>
                                                                UpdateAddPrice2(
                                                                  item.group,
                                                                  item.id,
                                                                  e.target.value
                                                                )
                                                              }
                                                            />
                                                          ) : null}

                                                          {!(
                                                            item.variations &&
                                                            item.variations
                                                              .length > 0
                                                          ) ? (
                                                            <FormControlLabel
                                                              control={
                                                                <Checkbox
                                                                  color="warning"
                                                                  checked={
                                                                    item.isSelected
                                                                  }
                                                                  onChange={(
                                                                    e: ChangeEvent<HTMLInputElement>
                                                                  ) =>
                                                                    handlePreSelectProduct(
                                                                      e,
                                                                      item.group,
                                                                      item.content
                                                                    )
                                                                  }
                                                                />
                                                              }
                                                              label={t(
                                                                "menu.bundle.pre_select_product"
                                                              )}
                                                            />
                                                          ) : null}
                                                          <Box
                                                            sx={{
                                                              display: "flex",
                                                              alignItems:
                                                                "center",
                                                              gap: "1rem",
                                                            }}
                                                          >
                                                            {item.priceToDisplay &&
                                                              item.priceToDisplay !==
                                                                0 && (
                                                                <Typography className="create-bundle-text-head">
                                                                  {" "}
                                                                  <CurrencyDisplay
                                                                    countryName={
                                                                      "UnitedKingdom"
                                                                    }
                                                                    amount={item.priceToDisplay.split(
                                                                      "-"
                                                                    )}
                                                                  />
                                                                </Typography>
                                                              )}

                                                            <IconButton
                                                              onClick={() => {
                                                                handleDeleteProductModal();
                                                                updateDeleteProductIds(
                                                                  item.id,
                                                                  item.group
                                                                );
                                                              }}
                                                            >
                                                              <CloseIcon />
                                                            </IconButton>
                                                          </Box>
                                                        </Box>
                                                        <Divider
                                                          sx={{ width: "100%" }}
                                                        />

                                                        <List
                                                          sx={{
                                                            padding: "0px",
                                                          }}
                                                        >
                                                          {item.variations &&
                                                            item.variations.map(
                                                              (
                                                                eachVariation,
                                                                index1
                                                              ) => (
                                                                <ListItem
                                                                  key={
                                                                    eachVariation.id
                                                                  }
                                                                  sx={{
                                                                    paddingLeft:
                                                                      "0px",
                                                                  }}
                                                                >
                                                                  <Box
                                                                    sx={{
                                                                      width:
                                                                        "100%",
                                                                      display:
                                                                        "flex",
                                                                      flexDirection:
                                                                        "column",
                                                                    }}
                                                                  >
                                                                    <Box
                                                                      sx={{
                                                                        display:
                                                                          "flex",
                                                                        justifyContent:
                                                                          "space-between",
                                                                        alignItems:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      <Box
                                                                        sx={{
                                                                          display:
                                                                            "flex",
                                                                          gap: "16px",
                                                                          alignItems:
                                                                            "center",
                                                                        }}
                                                                      >
                                                                        <Typography
                                                                          sx={{
                                                                            color:
                                                                              "#707070",
                                                                            fontFamily:
                                                                              "Poppins",
                                                                            fontSize:
                                                                              "14px",
                                                                            fontStyle:
                                                                              "normal",
                                                                            fontWeight: 500,
                                                                            lineHeight:
                                                                              "normal",
                                                                            textTransform:
                                                                              "capitalize",
                                                                          }}
                                                                        >
                                                                          {
                                                                            eachVariation.variantGroup
                                                                          }
                                                                          :{" "}
                                                                        </Typography>
                                                                        <Typography
                                                                          className="create-bundle-button-variation-text"
                                                                          sx={{
                                                                            textTransform:
                                                                              "capitalize",
                                                                          }}
                                                                        >
                                                                          {
                                                                            eachVariation.variation
                                                                          }
                                                                        </Typography>
                                                                      </Box>
                                                                      <TextField
                                                                        label={t(
                                                                          "menu.bundle.aditional_amount"
                                                                        )}
                                                                        color="warning"
                                                                        name={`leftItems[${index}].addPrice[${index1}].value`}
                                                                        type="number"
                                                                        size="small"
                                                                        className="hide-number-input-icons"
                                                                        value={
                                                                          eachVariation.aditionalPrice
                                                                        }
                                                                        onChange={(
                                                                          e
                                                                        ) => {
                                                                          UpdateAddPrice(
                                                                            eachVariation.id,
                                                                            item.id,
                                                                            e
                                                                              .target
                                                                              .value,
                                                                            item.group
                                                                          );
                                                                          formik.handleChange(
                                                                            e
                                                                          );
                                                                        }}
                                                                        onBlur={
                                                                          formik.handleBlur
                                                                        }
                                                                      />
                                                                      <div></div>
                                                                      <FormControlLabel
                                                                        control={
                                                                          <Checkbox
                                                                            color="warning"
                                                                            checked={
                                                                              eachVariation.isSelected
                                                                            }
                                                                            onChange={(
                                                                              e
                                                                            ) =>
                                                                              handleVariationPreSelect(
                                                                                e,
                                                                                eachVariation.id,
                                                                                item.content,
                                                                                item.group
                                                                              )
                                                                            }
                                                                          />
                                                                        }
                                                                        label={t(
                                                                          "menu.bundle.pre_select_product"
                                                                        )}
                                                                      />

                                                                      <IconButton
                                                                        onClick={() => {
                                                                          handleDeletevarionModal();
                                                                          updateVariantProductIds(
                                                                            item.id,
                                                                            item.group,
                                                                            eachVariation.id
                                                                          );
                                                                        }}
                                                                      >
                                                                        <CloseIcon />
                                                                      </IconButton>
                                                                    </Box>
                                                                    <Divider
                                                                      sx={{
                                                                        width:
                                                                          "100%",
                                                                        marginTop:
                                                                          "4px",
                                                                      }}
                                                                    />
                                                                  </Box>
                                                                </ListItem>
                                                              )
                                                            )}
                                                        </List>
                                                      </Box>
                                                    ) : null}
                                                  </ListItem>
                                                )
                                              )}
                                            </List>
                                          </Box>
                                        ) : (
                                          <Box sx={{ textAlign: "center" }}>
                                            <img src={menuImage} alt="bundle" />

                                            <Typography className="create-bundle-image-description">
                                              {t("menu.bundle.drag_text")}
                                            </Typography>
                                          </Box>
                                        )}
                                      </Box>
                                    </Box>
                                  </AccordionDetails>
                                  {provided.placeholder}
                                </ul>
                              )}
                            </StrictModeDroppable>
                          </Accordion>
                        </ListItem>
                      </Paper>
                    ))}
                  </List>
                </Box>
              ) : (
                <Box>
                  {formik.values.group.map((each: any, index: number) => (
                    <Paper
                      elevation={0}
                      style={{
                        marginTop: "2rem",
                        borderLeft:
                          "2px solid var(--stroke-color-1-violet, #AE9BE2)",
                      }}
                    >
                      <StrictModeDroppable droppableId={each.id} type="list">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              listStyle: "none",
                              padding: "0",
                              width: "100%",
                            }}
                          >
                            <ListItem key={each.id} sx={{ padding: "0px" }}>
                              <Accordion sx={{ width: "100%" }}>
                                {/* <AccordionSummary

                              >
                                
                              </AccordionSummary> */}

                                <AccordionDetails>
                                  <Box key={each.id} style={{}}>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        width: "90%",
                                        marginLeft: "2%",
                                      }}
                                    >
                                      <Box>
                                        {id && (
                                          <Button
                                            sx={{
                                              marginRight: "10%",
                                              textTransform: "none",
                                            }}
                                            className="create-bundle-button3"
                                            onClick={() => {
                                              handleAddgroupSave(each.id);
                                            }}
                                            disabled={addGroupLoading}
                                          >
                                            {t("menu.bundle.save")}
                                          </Button>
                                        )}
                                      </Box>
                                    </Box>
                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        flexDirection: "column",
                                      }}
                                    >
                                      {/* <TextField label={t("menu.bundle.description")} color='warning' multiline sx={{ width: '90%', marginTop: "0.75rem" }} value={each.description}
                                        onChange={(e) => handleGroupDescriptionChange(each.id, e.target.value)} />
                                       */}

                                      {formik.values.leftItems.filter(
                                        (each1) => each1.group === each.id
                                      ).length > 0 ? (
                                        <Box sx={{ marginTop: "2%" }}>
                                          {/* <Typography>{leftItems.filter((each1)=>each1.group===each.id).length}</Typography> */}
                                          <List>
                                            {formik.values.leftItems.map(
                                              (item, index) => (
                                                <ListItem
                                                  key={`${item.id}-${
                                                    item.group
                                                  }-${item.content}-${uuid()}`}
                                                  sx={{ padding: 0 }}
                                                >
                                                  {item.group === each.id ? ( // Check if item.group is equal to each.name
                                                    <Box
                                                      sx={{
                                                        width: "100%",
                                                        display: "flex",
                                                        flexDirection: "column",
                                                      }}
                                                    >
                                                      <Box
                                                        sx={{
                                                          display: "flex",
                                                          alignItems: "center",
                                                          justifyContent:
                                                            "space-between",
                                                          width: "100%",
                                                          gap: 0,
                                                          // height: 50,
                                                        }}
                                                      >
                                                        <Box
                                                          style={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            width: "30%",
                                                          }}
                                                        >
                                                          {item.image && (
                                                            <img
                                                              src={item.image}
                                                              alt="add"
                                                              className="create-bundle-button-image"
                                                            />
                                                          )}
                                                          <Typography
                                                            className="create-bundle-text-head"
                                                            sx={{
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                          >
                                                            {item.content}
                                                          </Typography>
                                                        </Box>

                                                        {item.variations &&
                                                        item.variations.length >
                                                          0 ? (
                                                          <Stack
                                                            direction="row"
                                                            spacing={3}
                                                            sx={{
                                                              display: "flex",
                                                              flexWrap: "wrap",
                                                              width: "80%",
                                                            }}
                                                          >
                                                            {item.variations.map(
                                                              (each2: any) => (
                                                                <CustomStyles>
                                                                  <Chip
                                                                    key={
                                                                      each2.id +
                                                                      each2.variation
                                                                    }
                                                                    label={
                                                                      <Typography className="chip-text">
                                                                        {
                                                                          each2.variation
                                                                        }
                                                                      </Typography>
                                                                    }
                                                                    onDelete={() => {
                                                                      handleDeletevarionModal();
                                                                      updateVariantProductIds(
                                                                        item.id,
                                                                        item.group,
                                                                        each2.id
                                                                      );
                                                                    }}
                                                                    sx={{
                                                                      marginTop:
                                                                        "8px",
                                                                    }}
                                                                  />
                                                                </CustomStyles>
                                                              )
                                                            )}
                                                          </Stack>
                                                        ) : null}

                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            alignItems:
                                                              "center",
                                                            gap: "1rem",
                                                          }}
                                                        >
                                                          <IconButton
                                                            onClick={() => {
                                                              handleDeleteProductModal();
                                                              updateDeleteProductIds(
                                                                item.id,
                                                                item.group
                                                              );
                                                            }}
                                                          >
                                                            <CloseIcon />
                                                          </IconButton>
                                                        </Box>
                                                      </Box>
                                                      <Divider
                                                        sx={{ width: "100%" }}
                                                      />
                                                    </Box>
                                                  ) : null}
                                                </ListItem>
                                              )
                                            )}
                                          </List>
                                        </Box>
                                      ) : (
                                        <Box sx={{ textAlign: "center" }}>
                                          <img src={menuImage} alt="bundle" />

                                          <Typography className="create-bundle-image-description">
                                            {t("menu.bundle.drag_text")}
                                          </Typography>
                                        </Box>
                                      )}
                                    </Box>
                                  </Box>
                                </AccordionDetails>
                              </Accordion>
                            </ListItem>
                            {provided.placeholder}
                          </ul>
                        )}
                      </StrictModeDroppable>
                    </Paper>
                  ))}
                </Box>
              )}

              <style>
                {`
      ::-webkit-scrollbar {
        display: none;
      }
    `}
              </style>
            </Box>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "30%" }}
            >
              {/* <IconButton sx={{ p: "10px" }} aria-label="menu">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search by name"
                  inputProps={{ "aria-label": "Search by name." }}
                  value={searchValue}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setSearchValue(event.target.value);
                  }} // Pass the event argument to handleSearch
                /> */}
              <Box sx={{ marginBottom: "10px" }}>
                <DelayedInput
                  setSearchValue={setSearchValue}
                  placeHolder={t("users.search_by_name")}
                  size={"medium"}
                  width={"100%"}
                />
              </Box>

              <StrictModeDroppable droppableId="right" type="list">
                {(provided) => (
                  <ul
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ listStyle: "none", padding: "0", width: "100%" }}
                  >
                    {productLoading ? (
                      <CircularLoader />
                    ) : (
                      <Box
                        sx={{
                          height: "65vh",
                          overflowY: "scroll",
                          width: "100%",
                        }}
                      >
                        {pizza.map((item, index) => (
                          <Draggable
                            key={item.id}
                            draggableId={item.id}
                            index={index}
                          >
                            {(provided) => (
                              <li
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{
                                  padding: "4px",
                                  borderRadius: "4px",
                                  ...provided.draggableProps.style,
                                }}
                              >
                                <Accordion
                                  expanded={
                                    item.variations
                                      ? openAccordionId1 === item.id
                                      : false
                                  }
                                  elevation={0}
                                  onChange={
                                    item.variations
                                      ? () => handleAccordionClick1(item.id)
                                      : undefined
                                  }
                                >
                                  <AccordionSummary
                                    expandIcon={
                                      item.variations && <ExpandMoreIcon />
                                    }
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                  >
                                    <Typography
                                      sx={{
                                        width: "100%",
                                        flexShrink: 0,
                                        textTransform: "capitalize",
                                      }}
                                      className="accordion-summary-text"
                                    >
                                      {item.content}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <StrictModeDroppable
                                      droppableId={`right-${item.id}`}
                                      type="list"
                                    >
                                      {(provided) => (
                                        <ul
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                          style={{
                                            listStyle: "none",
                                            padding: "0",
                                            width: "94%",
                                          }}
                                        >
                                          {item.variations &&
                                            item.variations.map(
                                              (variation, variationIndex) => (
                                                <Draggable
                                                  key={variation.id}
                                                  draggableId={variation.id.toString()}
                                                  index={variationIndex}
                                                >
                                                  {(provided) => (
                                                    <li
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                      style={{
                                                        width: "100%",
                                                        margin: "1%",
                                                        ...provided
                                                          .draggableProps.style,
                                                      }}
                                                    >
                                                      <Box className="create-bundle-accordionItem">
                                                        <Box
                                                          sx={{
                                                            display: "flex",
                                                            gap: "8px",
                                                            alignItems:
                                                              "center",
                                                          }}
                                                        >
                                                          <Typography
                                                            sx={{
                                                              color: "#707070",
                                                              fontFamily:
                                                                "Poppins",
                                                              fontSize: "12px",
                                                              fontStyle:
                                                                "normal",
                                                              fontWeight: 400,
                                                              lineHeight:
                                                                "normal",
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                          >
                                                            {
                                                              variation.variantGroup
                                                            }
                                                            :
                                                          </Typography>
                                                          <Typography
                                                            className="accordion-details-text"
                                                            sx={{
                                                              textTransform:
                                                                "capitalize",
                                                            }}
                                                          >
                                                            {
                                                              variation.variation
                                                            }
                                                          </Typography>
                                                        </Box>
                                                        <Typography>
                                                          {currencySymbol}{" "}
                                                          {variation.price}
                                                        </Typography>
                                                      </Box>
                                                    </li>
                                                  )}
                                                </Draggable>
                                              )
                                            )}
                                          {provided.placeholder}
                                        </ul>
                                      )}
                                    </StrictModeDroppable>
                                  </AccordionDetails>
                                </Accordion>
                              </li>
                            )}
                          </Draggable>
                        ))}
                      </Box>
                    )}
                    {provided.placeholder}
                  </ul>
                )}
              </StrictModeDroppable>
            </Box>
          </Box>
        </DragDropContext>
      </form>
      <DeleteProductDailougeInBundle
        id={deleteProductId}
        groupId={productGroupId}
        deletemodal={deleteProductModal}
        handleDleteProduct={deleteItemInGroup}
        handleDeleteModal={handleDeleteProductModal}
      />
      <DeleteVariationDailougeInBundle
        id={variantProductId}
        groupId={variantGroupId}
        variationId={deletevariantId}
        deletemodal={deleteVariationModal}
        handleDleteProduct={deleteVariation}
        handleDeleteModal={handleDeletevarionModal}
      />
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
      <BundleModal
        dailougeValue={dailougeValue}
        setDailouge={setDailouge}
        setLeftItems={setLeftItems}
        setGroup={setGroup}
        value={formik.values.radioValue}
        formik={formik}
      />
      <BasicModal modal={modal} setModal={sethandleModal} />
      <SuccessMessage
        setModalState={setModalState}
        modalState={modalState}
        location={
          locationsList.filter(
            (each) => each.storeId === formik.values.locations
          )[0]?.storeName
        }
      />
    </Box>
  );
};
export default CreateBundle;
