import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import MonthlyPackages from "./monthlyPackages";
import WeeklyPackages from "./WeeklyPackages";
import BgCircle from "../../../assets/Rectangle 34624207.png";
import RoundedBgCircle from "../../../assets/Rectangle 34624210.png";
import YearlyPackages from "./yearlyPackages";
import { styled } from "@mui/system";


const TabsWrapper = styled("div")({
  backgroundColor: "#FFF8F0",
  paddingTop: "2px",
  paddingBottom: "2px",
  borderRadius: "50px",
  display: "flex",
  alignItems: "center",
  paddingLeft: "12px",
  paddingRight: "12px",
  gap: "10px",
  maxWidth: "322px",
  width: "100%",
  justifyContent: "space-between",
  "@media screen and (max-width: 600px)": {
    maxWidth: "290px",
    paddingLeft: "8px",
    paddingRight: "8px",
    gap: "8px",
  },
  ".css-1ujnqem-MuiTabs-root": {
    width: "100%",
    alignItems: "center",
  },
  "button:hover": {
    backgroundColor: "#FFF8F0 !important",
  },
  ".css-jyq03p-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#F38B08",
    border: "1px solid #F38B08",
    backgroundColor: "#fff",
  },
  ".css-932g8m-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#F38B08",
    border: "1px solid #F38B08",
    backgroundColor: "#fff",
  },
  ".css-1y0e60l-MuiButtonBase-root-MuiTab-root.Mui-selected": {
    color: "#F38B08",
    border: "1px solid #F38B08",
    backgroundColor: "#fff",
  },
  ".css-1aquho2-MuiTabs-indicator": {
    backgroundColor: "transparent",
  },
  ".css-heg063-MuiTabs-flexContainer": {
    justifyContent: "space-between",
    alignItems: "center",
  },
  ".css-jyq03p-MuiButtonBase-root-MuiTab-root": {
    minHeight: "39px",
  },
  ".css-932g8m-MuiButtonBase-root-MuiTab-root": {
    minHeight: "39px",
  },
  ".css-1y0e60l-MuiButtonBase-root-MuiTab-root": {
    minHeight: "39px",
  },

});
const tabs = [
  {
    label: "Weekly",
    value: "1",
    backgroundColor: "#fff",
    color: "#F38B08",
    width: "102px",
  },
  {
    label: "Monthly",
    value: "2",
    backgroundColor: "transparent",
    color: "#333333",
    width: "109px",
  },
  {
    label: "Yearly",
    value: "3",
    backgroundColor: "transparent",
    color: "#333333",
    width: "93px",
  },
];

interface State{
  setAddOnsSelectors:any
}
function PaymentPlans(props:State) {
    const {setAddOnsSelectors}=props;
    const [value, setValue] = useState("1");

    const handleChange = (event:any, newValue:any) => {
      setValue(newValue);
    };
  return (
    <Box
      marginTop="16px"
      textAlign="center"
      paddingX={{ sm: "20px", xs: "12px" }}
    >
      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          paddingY: "24px",
          // paddingX: "6px",
          borderRadius: "8px",
          marginBottom:'75px',
        }}
      >
        <Box
          className="plansmallBall planlargball"
          sx={{
            maxWidth: "1388px",
            marginX: "auto",
            backgroundRepeat: "no-repeat",
          }}
        >
          <Box>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: {xs:'28px', sm:'32px'},
                fontWeight: "600",
                lineHeight: { md: "24px", xs: "32px" },
                letterSpacing: "0.5px",
                color: "#333333",
                textAlign: "center",
                marginTop:{xs:'20px', sm:'0px'}
              }}
            >
              Select Your Plan
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                fontWeight: "400",
                lineHeight: "24px",
                letterSpacing: "0.5px",
                color: "#707070",
                textAlign: "center",
                marginTop: "12px",
                marginBottom: "10px",
                padding:{xs:'6px', sm:'auto'}
              }}
            >
              Affordable Pricing Tailored to Support Your Expansion. Select a
              package which is suitable for your business needs..
            </Typography>
          </Box>
          <TabContext value={value}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "4px",
                flexWrap: { xs: "wrap", sm: "nowrap" },
              }}
            >
              <TabsWrapper>
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                >
                  {tabs.map((tab) => (
                    <Tab
                      key={tab.value}
                      label={tab.label}
                      sx={{
                        borderRadius: "20px",
                        paddingX: { xs: "14px", md: "18px" },
                        paddingY: "6px",
                        fontSize: { xs: "14px", md: "18px" },
                        lineHeight: { xs: "22px", md: "27px" },
                        fontWeight: "500",
                        fontFamily: "Poppins",
                        width: tab.width,
                        height: "39px",
                      }}
                      value={tab.value}
                    />
                  ))}
                </TabList>
              </TabsWrapper>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "400",
                  fontSize: "14px",
                  textAlign: "center",
                  lineHeight: "21px",
                  color: "#FFFFFF",
                  paddingX: "6px",
                  backgroundColor: "#F38B08",
                  borderRadius: "20px",
                  maxWidth: "62px",
                  "@media screen and (max-width: 392px)": {
                    marginTop: "20px",
                  },
                }}
              >
                20% off
              </Typography>
            </Box>
            <Box position={"relative"} overflow={"hidden"}>
              <Box
                position="absolute"
                top="1%"
                left="18.3%"
                zIndex={"9999"}
                maxWidth={"41.8%"}
              >
                <img src={RoundedBgCircle} alt="BgCircle" />
              </Box>
              <Box
                position="absolute"
                top="1.5%"
                right="2.3%"
                zIndex={"9999"}
                maxWidth={"41.8%"}
              >
                <img src={BgCircle} alt="BgCircle" />
              </Box>

              <TabPanel value="1">
                <MonthlyPackages setAddOnsSelectors={setAddOnsSelectors} />
              </TabPanel>

              <TabPanel value="2">
                <WeeklyPackages setAddOnsSelectors={setAddOnsSelectors} />
              </TabPanel>

              <TabPanel value="3">
                <YearlyPackages setAddOnsSelectors={setAddOnsSelectors} />
              </TabPanel>
            </Box>
          </TabContext>
        </Box>
      </Box>
    </Box>
  );
}

export default PaymentPlans;
