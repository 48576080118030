import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const MENU_LIST = gql`
query MenuList($businessId:ID,$locationId:ID,$first:Int,$after:String,$before:String,$last:Int){
    menuList(businessId:$businessId,locationId:$locationId,first:$first,after:$after,before:$before,last:$last) {
            totalCount   
  pageInfo{hasNextPage,startCursor,endCursor,hasPreviousPage}
            edges{node{menuId
                name
                version
                productCount
                createdOn
                menuStatus
                menuItems
				qrCode
            }
        
        
    }
    }}
`

// export const CATEGORY_LIST=gql`
// query {
// 	categoryListForMenu(businessId:5,locationId:6){
// 		edges{node{categoryId
// 		name

// 		products{productId
// 		name
// 			course{courseId
// 			name}
// 		variants{variantId
// 		name}}}

// 		}
// 	}
// }
// `

export const COURSE_LIST_IN_MENU = gql`
query courseList($businessId:ID){
	courseList(businessId:$businessId,courseStatus:ACTIVE){
	edges{
		node{courseId
		name
			productCourse{
				edges{
					node{
						name
						productId
						
						category{
							categoryId
							description
							name
						}
						variants{
							variantId
							name
						}
					}
				}
			}
			
		}
	}
		
	
	}
}
`

export const BUNDLE_LIST_IN_MENU = gql`
query bundleList($businessId:ID,$locationId:ID){
	bundleList(businessId:$businessId,locationId:$locationId,bundleStatus:"Active") {
			pageInfo{hasNextPage
			hasPreviousPage}
			edges{node{bundleId
				slugName
				amountAfterDiscount
				image
				bundleGroups
				name}
			
				}
			}
		
	}
`

export const MENU_DATA = gql`
query Menu($menuId:ID){
	menu(menuId:$menuId) {
		name
		image
		publishOn
	menuItems
	showOn
	}
			}
`

export const GET_MENU_DATA = gql`
query Menu($menuId:ID){
	menu(menuId:$menuId) {
		name
		image
	description
		isPos
		hideUntil
		showOn
		isCollection
	isCarry
	isOnline
		isDinein
		isDelivery
		channelTypes
		hbrMenuId
	}
			}
`

export const GET_SHOWON_DATA = gql`
query Menu($menuId:ID){
	menu(menuId:$menuId) {
		hideUntil
		showOn
	
		
	}
			}
`

export const MENU_HISTORY = gql`
query MenuHistory($menuId:ID){
	menuHistory(menuId:$menuId){
	edges{
		node{
			updatedBy 
		menuId
			version
			note
			publishOn
			isLatest
			createdOn
		}
	}
		
	
	}
}
`

