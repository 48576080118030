import { Box, Typography } from "@mui/material";
import LoyaltyIconsvg from "../../../assets/mianDashboard/Loyalty Icon.svg";
import LoyaltyArrowIcon from "../../../assets/mianDashboard/setUpGuaid-arrow-forward.svg";
import Link from "@mui/material/Link";
import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

const AddOns = () => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        backgroundColor: "white",
        borderRadius: "8px",
        // paddingTop:'26px',
        paddingLeft: "30px",
        paddingRight: "20px",
        // paddingBottom:'16px',
        width: { xs: "auto", md: "48%", lg: "92.3%", xl: "auto" },
        height: { xs: "auto", lg: "140px" },
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Typography
        sx={{
          fontFamily: "Poppins",
          fontSize: { xs: "14px", sm: "18px" },
          fontWeight: "500",
          lineHeight: "27px",
          textAlign: "left",
          color: "#000000",
          marginTop: "16px",
        }}
      >
        {t("dashboard.Recommended_Add_Ons")}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "16px",
          marginTop: "14px",
        }}
      >
        <Box
          sx={{
            width: "54px",
            height: "54px",
            borderRadius: "8px",
            backgroundColor: "#FFF8F0",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img src={LoyaltyIconsvg} alt="LoyaltyIconsvg" />
        </Box>
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontSize: { xs: "20px", sm: "24px" },
            fontWeight: "600",
            lineHeight: "36px",
            color: "#000000",
          }}
        >
          Loyalty
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "8px",
          justifyContent: "end",
          position: { lg: "relative" },
          bottom: "8px",
          marginTop: {
            xs: "8px",
            sm: "1px",
            position: "relative",
            top: "-10px",
          },
        }}
      >
        <Link
          sx={{
            fontFamily: "Poppins",
            fontSize: "16px",
            fontWeight: "500",
            lineHeight: "24px",
            color: "#000000",
            textDecoration: "none",
          }}
          component={RouterLink}
          to="#"
        >
          View more
        </Link>
        <img src={LoyaltyArrowIcon} alt="LoyaltyArrowIcon" />
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "12px",
          marginTop: { xs: "8px", sm: "1px" },
          position: "relative",
          top: "-16px",
        }}
      >
        <Box
          sx={{
            width: "28px",
            height: "8px",
            borderRadius: "20px",
            backgroundColor: "#F38B08",
          }}
        >
          {" "}
        </Box>
        <Box
          sx={{
            width: "8px",
            height: "8px",
            borderRadius: "20px",
            backgroundColor: "rgba(249, 197, 132, 0.6)",
          }}
        ></Box>
        <Box
          sx={{
            width: "8px",
            height: "8px",
            borderRadius: "20px",
            backgroundColor: "rgba(249, 197, 132, 0.6)",
          }}
        ></Box>
      </Box>
    </Box>
  );
};

export default AddOns;
