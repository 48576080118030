import { Sheet } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import { UPDATE_BLOCK_BOOKINGS } from "../../../graphicalQl/mutation/locationMutation";
import { GETTING_BULK_AND_RUSH_HOUR_SETTINGS } from "../../../graphicalQl/usequery/locationQuery";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface State {
  setOpenRushHour: any;
}

const initiallValues = {
  rushHourToggle: false,
  ordersReceived: "20",
  shippingrate: "0",
  deliveryTime: "0",
};

const validationSchema = Yup.object({
  ordersReceived: Yup.string().when("rushHourToggle", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
  }),
  shippingrate: Yup.string().when("rushHourToggle", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
  }),
  deliveryTime: Yup.string().when("rushHourToggle", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
  }),
});

const RushHourModal = (props: State) => {
  const { t } = useTranslation();
  const { setOpenRushHour } = props;
  const [updateBlockBookings] = useMutation(UPDATE_BLOCK_BOOKINGS, {
    onCompleted: (data) => {
      if (data) {
        const { branchSettings } = data;
        if (branchSettings) {
          const { success } = branchSettings;
          if (success) {
            setOpenRushHour(false);
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [],
  });
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const filteredCategory = {
    storeId: locationId,
  };
  const {
    loading: rushHourLoading,
    error: rushHourError,
    data: rushHourData,
  } = useQuery(GETTING_BULK_AND_RUSH_HOUR_SETTINGS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (rushHourData) {
      const { storeSettings } = rushHourData;
      if (storeSettings) {
        const { rushHourSettings } = storeSettings;
        const { is_rush_hour, no_of_orders, time, shipping_rate } =
          JSON.parse(rushHourSettings);
        formik.setValues({
          rushHourToggle: is_rush_hour,
          ordersReceived: no_of_orders,
          shippingrate: shipping_rate,
          deliveryTime: time,
        });
      }
    }
  }, [rushHourData]);

  const handleSaveButton = () => {
    const rushHourSettingsForTrue = {
      no_of_orders: formik.values.ordersReceived,
      shipping_rate: formik.values.shippingrate,
      time: formik.values.deliveryTime,
      is_rush_hour: formik.values.rushHourToggle,
      delivery_time: "increase",
    };
    const rushHourSettingsForFalse = {
      no_of_orders: 20,
      shipping_rate: 10,
      delivery_time: 1,
      is_rush_hour: false,
    };

    const variables = {
      storeId: +locationId,
      businessId: businessId,
      rushHourSettings: JSON.stringify(
        formik.values.rushHourToggle
          ? rushHourSettingsForTrue
          : rushHourSettingsForFalse
      ),
    };

    updateBlockBookings({
      variables: variables,
    });
  };
  const formik = useFormik({
    initialValues: initiallValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });
  return (
    <Sheet
      variant="outlined"
      sx={{
        width: "100%",
        maxWidth: "360px",
        marginTop: "86px",
        marginBottom: "20px",
        outline: "none",
        borderRadius: "8px",
        backgroundColor: "#FFFFFF",
        marginX: "auto",
      }}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ padding: "20px" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "24px",
                color: "#333333",
              }}
            >
              {t("dashboard.Rush_Hour")}
            </Typography>
            <FormControlLabel
              sx={{ margin: "0px" }}
              name="rushHourToggle"
              value={formik.values.rushHourToggle}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={(e) => formik.handleBlur(e)}
              control={
                <Switch
                  checked={formik.values.rushHourToggle}
                  color="warning"
                />
              }
              label=""
            />
          </Box>
          <Typography
            sx={{
              marginTop: "7px",
              display: "inline-block",
              fontFamily: "poppins",
              fontSize: "14px",
              fontWeight: "300",
              lineHeight: "21px",
              color: "#707070",
            }}
          >
            {t("dashboard.Apply_Rush_hour_settings_when")}
          </Typography>

          <Box sx={{ marginTop: "32px", width: "100%", maxWidth: "320px" }}>
            <TextField
              sx={{
                width: "100%",
                "& input": {
                  paddingTop: "15px",
                  paddingRight: "12px",
                  paddingBottom: "15px",
                  paddingLeft: "16px",
                  fontFamily: "poppins",
                  fontSize: "14px",
                  letterSpacing: "0.5px",
                  color: "#333333",
                },
              }}
              disabled={!formik.values.rushHourToggle}
              id="outlined-required"
              label={t("dashboard.Orders_Received")}
              name="ordersReceived"
              value={formik.values.ordersReceived || ""}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (!val || /^[0-9]+$/.test(val)) {
                  formik.handleChange(e);
                }
              }}
              type="text"
            />
            {formik.touched.ordersReceived && formik.errors.ordersReceived && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {formik.errors.ordersReceived}
                </Typography>
              </Box>
            )}
          </Box>

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "24px",
              gap: "20px",
            }}
          >
            <TextField
              sx={{
                maxWidth: "206px",
                width: "100%",
                backgroundColor: "white",
                "& label": {
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: "#333333",
                },
              }}
              disabled={!formik.values.rushHourToggle}
              id="outlined-basic"
              value={t("dashboard.Increase_with")}
              label={t("dashboard.Shipping_Rate")}
              color="warning"
              variant="outlined"
            />

            <FormControl
              sx={{
                m: 1,
                width: "94px",
                margin: "0px",
                paddingRight: "0px !important",
              }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{ paddingRight: "0px !important" }}
                // type='number'
                id="outlined-adornment-weight"
                endAdornment={
                  <InputAdornment
                    sx={{
                      backgroundColor: "#F9F9F9",
                      paddingX: "12.5px",
                      paddingY: "26px",
                    }}
                    position="end"
                  >
                    %
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                name="shippingrate"
                className="hide-number-input-icons"
                value={formik.values.shippingrate}
                onChange={(e) => {
                  const val = e.target.value;
                  if (!val || /^[0-9]+$/.test(val)) {
                    formik.handleChange(e);
                  }
                }}
                type="text"
                disabled={!formik.values.rushHourToggle}
              />
            </FormControl>
          </Box>
          {formik.touched.shippingrate && formik.errors.shippingrate && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {formik.errors.shippingrate}
              </Typography>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginTop: "24px",
              gap: "20px",
            }}
          >
            <TextField
              sx={{
                maxWidth: "206px",
                width: "100%",
                backgroundColor: "white",
                "& label": {
                  fontFamily: "poppins",
                  fontSize: "14px",
                  color: "#333333",
                },
              }}
              id="outlined-basic"
              label={t("dashboard.Delivery_Time")}
              color="warning"
              value={t("dashboard.Increase_with")}
              variant="outlined"
              disabled={!formik.values.rushHourToggle}
            />

            <FormControl
              sx={{ m: 1, width: "94px", margin: "0px" }}
              variant="outlined"
            >
              <OutlinedInput
                sx={{ paddingRight: "0px !important" }}
                id="outlined-adornment-weight"
                endAdornment={
                  <InputAdornment
                    sx={{
                      backgroundColor: "#F9F9F9 !important",
                      paddingY: "26px",
                      paddingX: "4px",
                    }}
                    position="end"
                  >
                    {t("dashboard.min")}
                  </InputAdornment>
                }
                aria-describedby="outlined-weight-helper-text"
                name="deliveryTime"
                className="hide-number-input-icons"
                value={formik.values.deliveryTime}
                onChange={(e) => {
                  const val = e.target.value;
                  if (!val || /^[0-9]+$/.test(val)) {
                    formik.handleChange(e);
                  }
                }}
                type="text"
                disabled={!formik.values.rushHourToggle}
              />
            </FormControl>
          </Box>
          {formik.touched.deliveryTime && formik.errors.deliveryTime && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {formik.errors.deliveryTime}
              </Typography>
            </Box>
          )}
          <Box sx={{ display: "flex" }}>
            <Button
              variant="contained"
              type="submit"
              sx={{
                marginTop: "32px",
                paddingY: "8.5px",
                paddingX: "16px",
                textTransform: "none",
                backgroundColor: "#F38B08",
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#FEFEFE",
                cursor: "pointer",
                marginLeft: "auto",
                hover: "none",
                boxShadow: "none !important",
                "&:hover": {
                  backgroundColor: "#F38B08", // Specify the hover color you want
                },
              }}
            >
              {t("dashboard.Save")}
            </Button>
          </Box>
        </Box>
      </form>
    </Sheet>
  );
};

export default RushHourModal;
