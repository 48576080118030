import { gql } from "@apollo/client";

export const DUPLICATE_MENU = gql`
mutation DuplicateMenu($menuId:ID!) {
    duplicateMenu(
        menuId:$menuId,
        
        
      ) {
            message
        
            
            success
            }
       
         
        
      }
    
`

export const DELETE_MENU = gql`
mutation DeleteMenus($menuIds:JSONString!){
    deleteMenus(
        menuIds:$menuIds,
        
    ) {
            message
            
        success
      }
    }
    
`

export const MENU_STATUS_CHANGE = gql`
mutation MenuStatusChange($menuId:ID!,$menuStatus:String) {
    menuStatusChange(
        menuId:$menuId,
        menuStatus:$menuStatus
    ) {
        menu{menuId
        menuStatus}
        success
        errorMessage
        
    }
        
    }
`

export const CREATE_MENU = gql`
mutation CreateMenu($name:String,$description:String,$showOn:String,$isHidden:Boolean,$isPos:Boolean,$isOnline:Boolean,$isCarry:Boolean,$isDinein:Boolean,
    $isDelivery:Boolean,$isCollection:Boolean,$channelTypes:JSONString,$image:String,$locations:JSONString,$businessId:Int!, $hideUntil:DateTime, $hbrMenuId:String){
    createMenu(
        businessId:$businessId,
        name:$name,
        description:$description
        showOn:$showOn
    
        isHidden:$isHidden
    isPos:$isPos
        isOnline:$isOnline
        isCarry:$isCarry
        isDinein:$isDinein
        isDelivery:$isDelivery
        isCollection:$isCollection
        channelTypes:$channelTypes
        
        image:$image,
        locations:$locations
        hideUntil:$hideUntil,
        hbrMenuId:$hbrMenuId
        
      ) {
            message
            menu{menuId
                name
            }
            success
            }
      }
`

export const MENU_SETUP = gql`
mutation AssignItemsToMenu($menuId:ID!,$items:JSONString,$publishOn:DateTime,$publishNow:Boolean,$publishNote:String){
    assignItemsToMenu(
        menuId:$menuId,
        publishOn:$publishOn
	publishNow:$publishNow
        items:$items
        publishNote:$publishNote
        
      ) {
            message
            menu{menuId
                name
            }
            success
            }
      }
`

export const UPDATE_MENU = gql`
mutation UpdateMenu($menuId:ID!,$name:String,$description:String,$image:String,$isPos:Boolean,$isOnline:Boolean,
    $isCarry:Boolean,$isDinein:Boolean,$showOn:JSONString,
    $isDelivery:Boolean,$isCollection:Boolean,$hideUntil:DateTime,$hbrMenuId:String
    $channelTypes:JSONString,){
        updateMenu(
            menuId:$menuId,
            hideUntil:$hideUntil
            name:$name,
            description:$description
            image:$image,
            isPos:$isPos
        isOnline:$isOnline
        channelTypes:$channelTypes
        isCarry:$isCarry
        isDinein:$isDinein
        isDelivery:$isDelivery
        isCollection:$isCollection
        showOn:$showOn
        hbrMenuId:$hbrMenuId
      ) {
            errorMessage
            menu{menuId
                name
            }
            success
            }
       
         
        
      }
    
`

export const BULK_PRICE_ADJUSTMENTS = gql`
mutation BulkAdjustMenuPrices($appliesFor:AppliesForOption!,$priceMode:PriceMode!,$rounding:String!,$menuId:ID!,$percentage:Int!,$updatedBy:String!){
    bulkAdjustMenuPrices(
        appliesFor:$appliesFor,
        priceMode:$priceMode,
        rounding:$rounding
        menuId:$menuId,
        percentage:$percentage
        updatedBy:$updatedBy
      ) {
            message
            menu{menuId
                name
            }
            success
            }
      }
`

export const USE_THIS = gql`
mutation UseThisMenu($menuId:ID!){
    useThisMenu(
        menuId:$menuId,
    ) {
            message
            
        success
      }
    }
`

export const PUBLISH_MENU_IN_LIST = gql`
mutation publishMenu($menuId:ID!){
publishMenu(
	
menuId:$menuId
	
  ) {
		message
	
		success
		}
}
`