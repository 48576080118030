import { gql } from "@apollo/client";

export const Delete_Category = gql`
mutation deleteCategoey($categoryIds:JSONString!) {
  deleteCategory(
    categoryIds:$categoryIds
    
  ) {
    category{categoryId}
    success
    errorMessage
    
  }
    
  }
    
`
export const CREATE_CATEGORY = gql`
  mutation createCategory(
    $name: String!,
    $description: String!,
    $businessId: Int!,
    $isPos: Boolean,
    $image: String,
    $isShow: Boolean,
    $bgColourCode:String
  ) {
    createCategory(
      name: $name,
      description: $description,
      businessId: $businessId,
      isPos: $isPos,
      image: $image,
      isCarry: $isShow,
      bgColourCode:$bgColourCode
    ) {
      errorMessage
      category {
        categoryId
      }
      success
    }
  }
`;

export const ADD_ADITIONAL_DETAILS = gql`
  mutation UpdateCategory(
    $categoryId: ID!
  $businessId: Int!,
    $metatagDescription: String
    $metatagKeywords: JSONString  
   $seoKeyword:JSONString
    $showOn: JSONString       
    $hideUntil: DateTime
    $isHidden:Boolean
  
  ) {
    updateCategory(
      categoryId: $categoryId
      businessId:$businessId
      metatagDescription: $metatagDescription
      metatagKeywords: $metatagKeywords
      seoKeyword:$seoKeyword
      showOn: $showOn
      hideUntil: $hideUntil
      isHidden:$isHidden
     
    ) {
      errorMessage
      category {
        categoryId
      }
      success
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $categoryId: ID!
    $description:String
  $name:String
  $businessId: Int!,
    $metatagDescription: String
    $metatagKeywords: JSONString  
   $seoKeyword:JSONString
    $showOn: JSONString       
    $hideUntil: DateTime
    $isPos:Boolean
    $isOnline:Boolean
    $image:String
    $isHidden:Boolean
   
  ) {
    updateCategory(
      
    
      categoryId: $categoryId
      name:$name
      description:$description
      isPos:$isPos
      businessId:$businessId
      metatagDescription: $metatagDescription
      metatagKeywords: $metatagKeywords
      isOnline:$isOnline
      showOn: $showOn
      seoKeyword:$seoKeyword
      hideUntil: $hideUntil
      isHidden:$isHidden
      image:$image
     
    ) {
      errorMessage
      category {
        categoryId
      }
      success
    }
  }
`;


export const SPECIALS_STATUS_CHANGE = gql`
mutation specialsStatusChange($specialId:Int!,$specialStatus:String!){
  specialsStatusChange(
    
    specialId:$specialId,
    specialStatus:$specialStatus
    
    
    
    
    ) {
      errorMessage
    special{specialId
    specialStatus}
      success
      }
  }`

export const UPDATE_CATEGORY_GENARAL_DETAILS = gql`
  mutation UpdateCategory(
    $categoryId: ID!
    $description:String
  $name:String
  $businessId: Int!,
    $isPos:Boolean
    $isOnline:Boolean
    $image:String
    $bgColourCode:String
  ) {
    updateCategory(
      categoryId: $categoryId
      name:$name
      description:$description
      isPos:$isPos
      businessId:$businessId
      isOnline:$isOnline
      image:$image
      bgColourCode:$bgColourCode
    ) {
      errorMessage
      category {
        categoryId
      }
      success
    }
  }
`;



export const ASSIGN_PRODUCTS = gql`
mutation assignProducts($categoryId:ID!,$products:JSONString){
  assignProducts(
    categoryId:$categoryId,
    products:$products,
    
    
    
  ) {
    category{categoryId
    name
    showOn}
    success
    message
    
      
  }
    
  }
  
`


export const ASSIGN_PRODUCTS_TO_SPECIALS = gql`
mutation assignProductsToSpecials($specialId:Int!,$productIds:JSONString) {
  assignProductsToSpecials(
    
    specialId:$specialId,
    productIds:$productIds,
    
    
    
    
    ) {
      message
    special{specialId
    products}
      success
      }
  }
  
`

export const ASSIGN_OPTIONGROUP = gql`
mutation  assignOptionGroupTocategory($optionGroupIds:JSONString,$optionIds:JSONString,$categoryId:ID!) {
  assignOptionGroupTocategory(
    optionGroupIds:$optionGroupIds,
    optionIds:$optionIds,
    categoryId:$categoryId,
    
    
  ) {
    
    success
    message
    
  }
    
  }
`

export const DELETE_OPTIONGROUP = gql`
mutation RemoveOptionFromCategory($categoryId:ID!,$optionGroupId: ID){
  removeOptionFromCategory(
    
  categoryId:$categoryId
     
    optionGroupId:$optionGroupId
  ) {
    
    success
    message
    
      
  }
    
  }
`
export const DELETE_OPTION = gql`
mutation RemoveOptionFromCategory($categoryId:ID!,$optionId: ID){
  removeOptionFromCategory(
    
  categoryId:$categoryId
     
  optionId:$optionId
  ) {
    
    success
    message
    
      
  }
    
  }
`
export const DELETE_OPTIONVALUE = gql`
mutation RemoveOptionFromCategory($categoryId:ID!,$optionValueId: ID){
  removeOptionFromCategory(
    
  categoryId:$categoryId
     
  optionValueId:$optionValueId
  ) {
    
    success
    message
    
      
  }
    
  }
`
export const DELTE_PRODUCT_FROM_CATEGORY = gql`
mutation deleteProductFromCategory($categoryId:Int!,$productId:Int!){
	deleteProductFromCategory(
		categoryId:$categoryId,
	productId:$productId,
	)
	{message
}
}
`

export const CATEGORY_STATUS_CHANGE = gql`
mutation categoryStatusChange($categoryId:ID!){
  categoryStatusChange(
    categoryId:$categoryId,
    categoryStatus:"Active"
  ) {
    category{categoryId
    categoryStatus}
    success
    errorMessage
    
      
  }
    
  }
`




