import { gql } from "@apollo/client";

export const DISCOUNTS_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationIds: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
       query DiscountReports {
        discountReports(
          fromDate: "${fromDate}"
          toDate: "${toDate}"
          fromTime:"${fromTime}"
          toTime:"${toTime}"
          locationId:${locationIds},
          ${employeeParam}
         
          
         )
       }
     `;
};
