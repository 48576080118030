import React, { useEffect, useState } from "react";
import { FormikErrors, useFormik } from "formik";
import * as Yup from "yup";
import {
  Box,
  Button,
  IconButton,
  FormGroup,
  TextField,
  FormControlLabel,
  Typography,
  FormControl,
  Switch,
  Divider,
} from "@mui/material";
import "../options/createProduct.css";
import "./product.css";
import { CloseTwoTone } from "@mui/icons-material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { setproductConfiguration } from "../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { UPDATE_SEQUENCE } from "../../graphicalQl/mutation/productMutation";
import { useMutation } from "@apollo/client";
import { UPDATE_SEQUENCE_IN_PRODUCT_UPDATE } from "../../graphicalQl/mutation/productMutation";
import { FILTERING_PRODUCTS_LIST } from "../../graphicalQl/usequery/productsListQuery";
import uuid from "react-uuid";

interface Row {
  id: number;
  price: string;
  sequence?: string;
}

interface AddSizes {
  id: number;
  size: string;
  data: Row[];
  aditionalPrice: string;
}

interface FormikType {
  sequenceValue: Row[];
  productSequenceValues: AddSizes[];
  aditionalPrice: string;
}

interface Props {
  handleBack: () => void;
  handleComplete: () => void;
}

const validationSchemaPart1 = Yup.object().shape({
  // sequenceValue: Yup.array().of(
  //   Yup.object().shape({

  //     price: Yup.number().typeError('*must be number'),

  //   })
  // ),
  // productSequenceValues: Yup.array().of(
  //   Yup.object().shape({

  //     data: Yup.array().of(
  //       Yup.object().shape({

  //         price: Yup.string().required('Price is required'),

  //       })
  //     ),
  //     aditionalPrice: Yup.string().required('Additional Price is required'),
  //   })
  // ),
  aditionalPrice: Yup.number().typeError("*must be a number"),
});
const ProductSequence = (props: Props) => {
  const { handleBack, handleComplete } = props;
  const { productId } = useSelector((state: any) => state.pricing);
  const dispatch = useDispatch();
  const { sequenceValue, productSequenceValues, aditionalPrice, size } =
    useSelector((state: any) => state.configuration);

  const { variationSizes } = useSelector((state: any) => state.pricing);

  const initialState: FormikType = {
    aditionalPrice: aditionalPrice,
    sequenceValue: sequenceValue,
    productSequenceValues: productSequenceValues,
  };
  const { productIdByEdit } = useSelector((state: any) => state.app);

  const formik = useFormik({
    initialValues: initialState,

    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleComplete();
      if (productIdByEdit) {
        updateSequenceInsingleId({
          variables: {
            productId: +productIdByEdit,
            sequence: JSON.stringify(dataToServer),
          },
          refetchQueries: [FILTERING_PRODUCTS_LIST],
        });
      } else {
        updateSequence({
          variables: {
            productIds: JSON.stringify(productId),
            sequence: JSON.stringify(dataToServer),
          },
          refetchQueries: [FILTERING_PRODUCTS_LIST],
        });
      }
    },
  });

  // useEffect(() => {
  //   formik.setFieldValue('productSequenceValues', productSequenceValues);
  // }, [productSequenceValues]);

  // useEffect(() => {
  //   formik.setFieldValue('aditionalPrice', aditionalPrice);
  // }, [aditionalPrice]);

  const [state, setState] = useState<string>(aditionalPrice);

  const handleInputChange2 = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setproductConfiguration(field, e.target.value));
  };

  // const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);
  // const handleExpandIconClick = (panel: string) => (event: React.MouseEvent<{}>) => {
  //   event.stopPropagation();
  //   setExpandedAccordion((prev) => (prev === panel ? null : panel));
  // };

  const [row2, setRow2] = useState<Row[]>(sequenceValue);
  useEffect(() => {
    // formik.setFieldValue('sequenceValue', sequenceValue);
    setRow2(sequenceValue);
  }, [sequenceValue]);
  const [nextSequence, setNextSequence] = useState<number>(4);
  // const sizes = ['small', 'medium', 'large'];

  const [sequenceInvariations, setSequenceInvariations] = useState<AddSizes[]>(
    []
  );
  // useEffect(() => {
  //   const addSizes = variationSizes && variationSizes.map((each: string) => ({
  //     id: uuid(),
  //     size: each,
  //     data: [
  //       {
  //         id: 1,
  //         price: "",
  //         sequence: "1st Selection",
  //       },
  //       {
  //         id: 2,
  //         price: "",
  //         sequence: "2nd Selection",
  //       },
  //       {
  //         id: 3,
  //         price: "",
  //         sequence: "3rd Selection",
  //       },
  //     ],
  //     aditionalPrice: '',
  //   }));

  //   setSequenceInvariations(addSizes)
  // }, [size])

  //   const {data:sequenceDataFromApi}=useQuery(SEQUENCE_DETAILS_IN_PRODUCT,{ context: { clientName: 'categoryClient' },variables:{productId:productIdByEdit} })
  // console.log(sequenceDataFromApi)

  // useEffect(()=>{
  // //   if(sequenceDataFromApi&& sequenceDataFromApi.product){
  // //     const convertSequenceInformat = sequenceDataFromApi.product.sequence&& sequenceDataFromApi.product.sequence.map((each:any) => ({
  // //       id:each.variant&&each.variant.variantId,
  // //       size: each.variant&&each.variant.name,
  // //       data:[
  // //         {
  // //           id:each.sequenceId,
  // //           price:each.amount,
  // //           sequence:each.sequenceName
  // //         },

  // //       ],
  // //       aditionalPrice:"" ,

  // //     }));

  // //     const convertWithOutSequenceInformat:Row[] = sequenceDataFromApi.product.sequence&& sequenceDataFromApi.product.sequence.map((each:any) => (
  // //       {
  // //           id:each.sequenceId,
  // //           price:each.amount,
  // //           sequence:each.sequenceName}
  // //     ));

  // // const aditionalValueFromApi=convertWithOutSequenceInformat&&convertWithOutSequenceInformat[convertWithOutSequenceInformat&&convertWithOutSequenceInformat.length-1].price
  // // dispatch(setproductConfiguration('aditionalPrice', aditionalValueFromApi))
  // //     const groupedData = convertSequenceInformat&&convertSequenceInformat.reduce((acc: any, item: any) => {
  // //       const { id, size, data,  } = item;

  // //       // If the id doesn't exist in the accumulator, create an object for it
  // //       if (!acc[id]) {
  // //         acc[id] = {
  // //           id: parseInt(id),
  // //           size,
  // //           data: [],
  // //           aditionalPrice:"",
  // //         };
  // //       }

  // //       // If data is an array of objects, push each object with non-"remaining" sequence
  // //       if (data && data.length > 0) {
  // //         const nonRemainingData = data.filter((d: any) => d.sequence !== "remaining");
  // //         acc[id].data.push(...nonRemainingData);

  // //         const remainingData = data.find((d: any) => d.sequence === "remaining");
  // //         if (remainingData) {
  // //           acc[id].aditionalPrice = remainingData.price;
  // //         }
  // //       }

  // //       return acc;
  // //     }, {});
  // // // const formatingNormalProductSequence=convertWithOutSequenceInformat.map((each:any)=>({
  // // // if(each.)
  // // // }))

  // //     // Convert the object into an array of objects
  // //     const resultArray:AddSizes[] =groupedData&& Object.values(groupedData);
  // //     console.log(convertWithOutSequenceInformat)
  // //     dispatch(setproductConfiguration('sequenceValue',convertWithOutSequenceInformat&&convertWithOutSequenceInformat.slice(0,convertWithOutSequenceInformat.length-1)));

  // //     dispatch(setproductConfiguration('productSequenceValues', resultArray))
  // //   }

  // },[sequenceDataFromApi])

  const addRow = () => {
    setRow2((prevRows) => [
      ...prevRows,
      {
        id: nextSequence,
        price: "",
        sequence: `${row2.length + 1}  ${row2.length === 0
          ? "st selection"
          : row2.length === 1
            ? "nd selection"
            : row2.length === 2
              ? "rd selection"
              : "th selection"
          }`,
      },
    ]);
    setNextSequence((prev) => prev + 1);
  };

  const handleDeleteRow2 = (id: number) => {
    const updatedRows = row2.filter((row) => row.id !== id);
    setRow2(updatedRows);
  };

  const handleRowInputChange2 = (
    id: number,
    field: keyof Row,
    value: string
  ) => {
    const updatedRows = row2.map((row) =>
      row.id !== id ? row : { ...row, [field]: value }
    );
    setRow2(updatedRows);
  };

  const addRowInVariants = (variation: string) => {
    setSequenceInvariations((prev: AddSizes[]) => {
      return prev.map((each) =>
        each.size === variation
          ? {
            ...each,
            data: [
              ...each.data,
              {
                id: Date.now(),
                price: "",
                sequence: `${each.data.length + 1}  ${each.data.length === 0
                  ? "st selection"
                  : each.data.length === 1
                    ? "nd selection"
                    : each.data.length === 2
                      ? "rd selection"
                      : "th selection"
                  }`,
              },
            ],
          }
          : each
      );
    });
  };

  const handleVariantsPrice = (
    variation: string,
    id: number,
    event: string
  ) => {
    setSequenceInvariations((prev: AddSizes[]) => {
      return prev.map((each) =>
        each.size === variation
          ? {
            ...each,
            data: each.data.map((each1) =>
              each1.id === id ? { ...each1, price: event } : each1
            ),
          }
          : each
      );
    });
  };

  const handleVariantsSequence = (
    variation: string,
    id: number,
    event: string
  ) => {
    setSequenceInvariations((prev: AddSizes[]) => {
      return prev.map((each) =>
        each.size === variation
          ? {
            ...each,
            data: each.data.map((each1) =>
              each1.id === id ? { ...each1, sequence: event } : each1
            ),
          }
          : each
      );
    });
  };

  const handleAditionalPrice = (variation: string, result: string) => {
    setSequenceInvariations((prev: AddSizes[]) => {
      return prev.map((each) =>
        each.size === variation
          ? {
            ...each,
            aditionalPrice: result,
          }
          : each
      );
    });
  };

  const sequence =
    sequenceInvariations &&
    sequenceInvariations.map((each) => ({
      variant: each.size,
      values: [
        ...each.data.map((item) => ({
          name: item.sequence,
          amount: +item.price,
        })),
        { name: "remaining", amount: +each.aditionalPrice },
      ],
    }));

  const productSequence =
    row2 &&
    row2.map((each) => ({
      name: each.sequence,
      amount: +each.price,
    }));



  productSequence &&
    productSequence.push({ name: "remaining", amount: +state });
  const dataToServer = size ? sequence : productSequence;

  const handleDeleteSequenceInVariation = (variation: string, id: number) => {
    setSequenceInvariations((prev: AddSizes[]) => {
      return prev.map((each) =>
        each.size === variation
          ? {
            ...each,
            data: each.data.filter((each1) => each1.id !== id),
          }
          : each
      );
    });
  };

  const handleStoreSequence = () => {
    dispatch(setproductConfiguration("sequenceValue", row2));
  };

  const handleStoreProductSequence = () => {
    // dispatch(setproductConfiguration('productSequenceValues', sequenceInvariations));
  };

  useEffect(() => {
    handleStoreSequence();
  }, [row2]);

  useEffect(() => {
    if (!productSequenceValues || productSequenceValues.length === 0) {
      const addSizes =
        variationSizes &&
        variationSizes.map((each: string) => ({
          id: uuid(),
          size: each,
          data: [
            {
              id: 1,
              price: "",
              sequence: "1st Selection",
            },
            {
              id: 2,
              price: "",
              sequence: "2nd Selection",
            },
            {
              id: 3,
              price: "",
              sequence: "3rd Selection",
            },
          ],
          aditionalPrice: "",
        }));

      setSequenceInvariations(addSizes);
      handleStoreProductSequence();
      dispatch(
        setproductConfiguration("productSequenceValues", sequenceInvariations)
      );
    } else {
      setSequenceInvariations(productSequenceValues);
    }
  }, []);

  useEffect(() => {
    dispatch(
      setproductConfiguration("productSequenceValues", sequenceInvariations)
    );
  }, [sequenceInvariations]);

  const handleInputChange3 = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setproductConfiguration(field, e.target.checked));
  };

  const [updateSequence, { loading: bulkLoading }] = useMutation(UPDATE_SEQUENCE, {
    context: { clientName: "categoryClient" },
  });
  const [updateSequenceInsingleId, { loading: updateLoading }] = useMutation(
    UPDATE_SEQUENCE_IN_PRODUCT_UPDATE,
    { context: { clientName: "categoryClient" } }
  );

  return (
    <Box sx={{ paddingBottom: "0.75rem" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ margin: "0px 18px" }} className="create-product-price-con">
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              padding: "1rem 1.25rem",
            }}
            className="create-product-base-size-con"
          >
            <Typography
              sx={{ width: "33%", flexShrink: 0, marginRight: "5%" }}
              className="create-product-details"
            >
              {t("product.sequence_pricing")}
            </Typography>
            <FormControl style={{ marginLeft: "0px" }}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={size}
                      onChange={(e) => {
                        handleInputChange3("size", e);
                      }}
                      name="Size"
                      color="warning"
                    />
                  }
                  label={
                    <Typography className="create-product-switch-text">
                      {t("product.size")}{" "}
                    </Typography>
                  }
                  labelPlacement="start"
                />
              </FormGroup>
            </FormControl>
          </Box>

          {!size ? (
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
                padding: "1.25rem",
                margin: "0.75rem 1.25rem",
              }}
            >
              <TableContainer>
                <Table aria-label="simple table">
                  <TableHead>
                    <TableRow style={{ borderBottom: "1px solid #e0e0e0" }}>
                      <TableCell style={{ paddingTop: "0px" }}>
                        <Typography className="headText-style">
                          {t("product.sequence")}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ paddingTop: "0px" }}>
                        {" "}
                        <Typography className="headText-style">
                          {t("product.price")}
                        </Typography>
                      </TableCell>
                      <TableCell style={{ paddingTop: "0px" }}> </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row2 &&
                      row2.map((each, index) => (
                        <TableRow
                          key={each.id}
                          style={{ borderBottom: "1px solid #e0e0e0" }}
                        >
                          <TableCell
                            style={{ padding: "8px" }}
                            className="create-product-base-size"
                          >
                            <TextField
                              value={each.sequence}
                              color="warning"
                              variant="standard"
                              onChange={(e) => {
                                formik.handleChange(e);
                                handleRowInputChange2(
                                  each.id,
                                  "sequence",
                                  e.target.value
                                );
                              }}
                              size="small"
                              InputProps={{
                                disableUnderline: true,
                              }}
                            />
                          </TableCell>
                          <TableCell
                            style={{ padding: "8px" }}
                            className="create-product-base-size"
                          >
                            <TextField
                              label={t("product.price")}
                              value={each.price}
                              type="text"
                              size="small"
                              onChange={(event) => {
                                const val = event.target.value;

                                if (
                                  (!val || /^[0-9.]+$/.test(val)) &&
                                  val.length <= 5
                                ) {
                                  handleRowInputChange2(
                                    each.id,
                                    "price",
                                    event.target.value
                                  );
                                  formik.handleChange(event);
                                }
                              }}
                              className="hide-number-input-icons"
                              color="warning"
                              onBlur={formik.handleBlur}
                            />{" "}
                            {formik.touched.sequenceValue &&
                              formik.touched.sequenceValue[index] &&
                              formik.touched.sequenceValue[index]?.price &&
                              formik.errors.sequenceValue &&
                              formik.errors.sequenceValue[index] &&
                              (
                                formik.errors.sequenceValue as FormikErrors<
                                  Row[]
                                >
                              )[index]?.price && (
                                <div style={{ color: "red" }}>
                                  {
                                    (
                                      formik.errors
                                        .sequenceValue as FormikErrors<Row[]>
                                    )[index]?.price
                                  }
                                </div>
                              )}
                          </TableCell>
                          <TableCell style={{ padding: "0px" }}>
                            <IconButton
                              onClick={() => handleDeleteRow2(each.id)}
                            >
                              <CloseTwoTone />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button className="create-product-button-text" onClick={addRow}>
                {t("product.add_level")}
              </Button>
              <Divider />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1%",
                  justifyContent: "space-between",
                  width: "90%",
                }}
              >
                <Typography className="additional-text">
                  {t("product.all_aditional")}
                </Typography>
                <div>
                  <TextField
                    color="warning"
                    style={{}}
                    label={t("product.price")}
                    value={aditionalPrice}
                    type="text"
                    size="small"
                    className="hide-number-input-icons"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      const val = e.target.value;

                      if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 5) {
                        formik.handleChange(e);
                        handleInputChange2("aditionalPrice", e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.aditionalPrice &&
                    formik.errors.aditionalPrice && (
                      <div style={{ color: "red", marginLeft: "22%" }}>
                        {formik.errors.aditionalPrice}
                      </div>
                    )}
                </div>
                <Typography></Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                borderRadius: "8px",
                padding: "1.25rem",
                margin: "0.75rem 1.25rem",
              }}
            >
              {sequenceInvariations &&
                sequenceInvariations.map((each: any, index) => (
                  <div>
                    <Typography className="sizeStyle">{each.size}</Typography>
                    <TableContainer>
                      <Table aria-label="simple table">
                        <TableHead>
                          <TableRow
                            style={{ borderBottom: "1px solid #e0e0e0" }}
                            key={each.id}
                          >
                            <TableCell style={{ paddingTop: "0px" }}>
                              <Typography className="headText-style">
                                {t("product.sequence")}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ paddingTop: "0px" }}>
                              {" "}
                              <Typography className="headText-style">
                                {t("product.price")}
                              </Typography>
                            </TableCell>
                            <TableCell style={{ paddingTop: "0px" }}>
                              {" "}
                            </TableCell>
                          </TableRow>
                        </TableHead>

                        <TableBody sx={{ marginTop: "2%" }}>
                          {each.data.map((each1: any, rowIndex: number) => (
                            <TableRow
                              key={each1.sequence}
                              style={{ borderBottom: "1px solid #e0e0e0" }}
                            >
                              <TableCell
                                style={{ padding: "5px" }}
                                className="create-product-base-size"
                              >
                                <TextField
                                  value={each1.sequence}
                                  color="warning"
                                  variant="standard"
                                  size="small"
                                  InputProps={{
                                    disableUnderline: true,
                                  }}
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) =>
                                    handleVariantsSequence(
                                      each.size,
                                      each1.id,
                                      event.target.value
                                    )
                                  }
                                />
                              </TableCell>
                              <TableCell
                                style={{ padding: "5px" }}
                                className="create-product-base-size"
                              >
                                <TextField
                                  label={t("product.price")}
                                  value={each1.price}
                                  type="text"
                                  size="small"
                                  className="hide-number-input-icons"
                                  onChange={(
                                    event: React.ChangeEvent<HTMLInputElement>
                                  ) => {
                                    const val = event.target.value;

                                    if (
                                      (!val || /^[0-9.]+$/.test(val)) &&
                                      val.length <= 5
                                    ) {
                                      handleVariantsPrice(
                                        each.size,
                                        each1.id,
                                        event.target.value
                                      );
                                      formik.handleChange(event);
                                    }
                                  }}
                                  color="warning"
                                />
                                {/* {formik.touched.productSequenceValues &&
  formik.touched.productSequenceValues[index]?.data &&
  (formik.touched.productSequenceValues[index]?.data as FormikTouched<Row[]>)[rowIndex]?.price &&
  formik.errors.productSequenceValues &&
  formik.errors.productSequenceValues[index]?.data &&
  (formik.errors.productSequenceValues[index]?.data as Row[])[rowIndex] &&
  (formik.errors.productSequenceValues[index]?.data as Row[])[rowIndex]?.price && (
    <div style={{ color: 'red' }}>
      {(formik.errors.productSequenceValues[index]?.data as Row[])[rowIndex]?.price}
    </div>
  )
} */}
                              </TableCell>
                              <TableCell style={{ padding: "0px" }}>
                                <IconButton
                                  onClick={() => {
                                    handleDeleteSequenceInVariation(
                                      each.size,
                                      each1.id
                                    );
                                  }}
                                >
                                  <CloseTwoTone />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: "1%",
                        justifyContent: "space-between",
                        width: "68%",
                      }}
                    >
                      <Typography className="additional-text">
                        {t("product.all_aditional")}
                      </Typography>
                      <TextField
                        color="warning"
                        label="price"
                        type="number"
                        size="small"
                        className="hide-number-input-icons"
                        value={each.aditionalPrice}
                        onChange={(e) => {
                          const val = e.target.value;

                          if (
                            (!val || /^[0-9.]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            handleAditionalPrice(each.size, e.target.value);
                          }
                        }}
                      />
                    </Box>
                    <Button
                      className="create-product-button-text"
                      onClick={() => {
                        addRowInVariants(each.size);
                      }}
                    >
                      {t("product.add_level")}
                    </Button>
                  </div>
                ))}
            </Box>
          )}
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", marginTop: "2%" }}>
          <Button className="create_category_BacK_button" onClick={handleBack}>
            {<ArrowBackIcon />} {t("product.back")}
          </Button>
          <Button
            className="createCategory-next-active"
            sx={{ marginLeft: "3px", textTransform: "none" }}
            type="submit"
            disabled={bulkLoading || updateLoading}
          >
            {" "}
            {t("product.Save_next")} {<ArrowForwardIcon color="warning" />}
          </Button>
        </Box>
      </form>
    </Box>
  );
};
export default ProductSequence;
