import React, { useState } from "react";
import { Menu, MenuItem, Checkbox, ListItemText } from "@mui/material";
import PlusMenu from "../../../../assets/plusMenu.svg";

interface Props {
  visibleColumns: any,
  setVisibleColumns: any
}

function ColumnVisibilityFilter(props: Props) {
  const { visibleColumns, setVisibleColumns } = props
  // open or close menu list to select or deselect columns for visibility
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // better display for users, also this makes the feature easily scalable than to maintain a mapping object.
  const camelCaseToWords = (s: string) => {
    const result = s.replace(/([A-Z])/g, " $1");
    return result.charAt(0).toUpperCase() + result.slice(1);
  };

  // checkbox change listener, removes or adds column to visible columns based on user selection
  const handleCheckboxChange = (option: any) => () => {
    const currentIndex = visibleColumns[option];
    const updatedCustomerList = { ...visibleColumns };

    if (currentIndex !== null) {
      updatedCustomerList[option] = !updatedCustomerList[option];
    }

    setVisibleColumns(updatedCustomerList);
  };

  return (
    <div>
      <img src={PlusMenu} alt="options for header" onClick={handleClick} />
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {Object.keys(visibleColumns).map((option, index) => (
          <MenuItem key={index}>
            <Checkbox
              sx={{
                "&.Mui-checked": {
                  color: "#F38B08",
                },
                marginLeft: "0px",
              }}
              checked={visibleColumns[option]}
              tabIndex={-1}
              onChange={handleCheckboxChange(option)}
            />
            <ListItemText
              primary={camelCaseToWords(option)}
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                textTransform: "capitalize",
                fontSize: "14px",
                fontWeight: "600",
              }}
            />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}

export default ColumnVisibilityFilter;
