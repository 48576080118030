import { gql } from "@apollo/client";

export const TOP_STORES = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  businessId: string
) => {
  return gql`
     query orderClient {
      topStores(
        businessId: "${businessId}"
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime:"${fromTime}"
        toTime:"${toTime}"
       )
     }
   `;
};

// export const TOP_STORES = gql`
//   query orderClient {
//     topStores(
//       businessId: 5
//       fromDate: "2024-02-21"
//       toDate: "2024-03-29"
//       fromTime: "05:00:00"
//       toTime: "23:00:00"
//     )
//   }
// `;

// export const STORE_REPORTS = gql`
//   query orderClient {
//     storeReports(
//       businessId: 5
//       fromDate: "2024-02-01"
//       toDate: "2024-03-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//     )
//   }
// `;

export const STORE_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  businessId: string
) => {
  return gql`
     query orderClient {
      storeReports(
        businessId: "${businessId}"
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime:"${fromTime}"
        toTime:"${toTime}"
       )
     }
   `;
};
