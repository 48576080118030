import { ApolloClient, HttpLink, InMemoryCache } from "@apollo/client";
import moment from "moment";
import { ADDRESS_GRAPHQL_BASE_URL, BUSINESS_GRAPHQL_BASE_URL, CUSTOMER_GRAPHQL_BASE_URL, ORDERING_GRAPHQL_BASE_URL } from "./constant";

export const getStatusColor = (status: string, type: string): string => {
  if (type === "border-color") {
    switch (status) {

      case "In Progress":
        return "#F5431C"; 
        case "Rejected":
          return "#F5431C"; 
      case "Accepted":
          return "#F38B08";
          case "Prepared":
            return "#F38B08";
      case "Completed":
        return "#00AE26";
        case "Preparing":
            return "#F38B08"; 
        case "cancelled":
          return "#F5431C"; 
          case "Void":
            return "#F5431C"; 
        
      case "Hold":
          return "#F38B08";// Green
      case "Pending":
        return "#F38B08"; // Orange
      default:
        return "gray";
    }
  } else if (type === "text-color") {
    switch (status) {
      case "In Progress":
        return "#F38B08"; // Yellow
        case "Rejected":
          return "#F5431C"; 
      case "Completed":
        return "#00AE26"; // Green
        case "Void":
          return "#F5431C"; 
        case "Accepted":
          return "#F38B08";
          case "cancelled":
          return "#F5431C";
      case "Prepared":
          return "#F38B08"; 
          case "Preparing":
            return "#F38B08"; 
      case "Pending":
        return "#F38B08"; // Orange
      default:
        return "gray";
    }
  } else {
    switch (status) {
      case "In Progress":
        return "white"; // Yellow
      case "Completed":
        return "#00AE261A"; // Green
      case "Pending":
        return "#F38B081A"; // Orange
      default:
        return "transparent";
    }
  }
};

export const DateFormatter = (dateString: any, format: any) => {
  // Parse the original date string
  const date = moment(dateString);
  const formattedDate = date.format(format);

  return formattedDate;
};


export const orderingClient = new ApolloClient({
  link: new HttpLink({ uri: ORDERING_GRAPHQL_BASE_URL }),
  cache: new InMemoryCache(),
});

export const businessClient = new ApolloClient({
  link: new HttpLink({ uri: BUSINESS_GRAPHQL_BASE_URL }),
  cache: new InMemoryCache(),
});

export const customerClient = new ApolloClient({
  link: new HttpLink({ uri: CUSTOMER_GRAPHQL_BASE_URL }),
  cache: new InMemoryCache(),
});
export const addressClient = new ApolloClient({
  link: new HttpLink({ uri: ADDRESS_GRAPHQL_BASE_URL }),
  cache: new InMemoryCache(),
});
