import React, { ChangeEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Typography, Divider, Switch } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import "./categoryListing.css";
import { useQuery, useMutation } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import { SPECIALS_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import {
  Delete_Category,
  SPECIALS_STATUS_CHANGE,
} from "../../graphicalQl/mutation/mutation";
import AssignProducts from "../category/assigngnProductsModal";

import ErrorModal from "../errorHandling/errorModal";
import { styled } from "@mui/system";
// import DeleteDailouge from './deleteDailouge';
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PopupWithTitle from "../popups/popupWithTitle";

interface Row {
  id: string;
  status: string;
  productCount: number;
  slugName: string;
  productIds: any;
}

function SpecialsListing() {
  const { t } = useTranslation();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [categoryList, setCategoryList] = useState<Row[]>([]);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const { locationId } = useSelector((state: any) => state.headerLocation);

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery(SPECIALS_LIST, {
    context: { clientName: "categoryClient" },
    fetchPolicy: "cache-and-network",
    variables: { businessId: +businessId, locationId: parseInt(locationId) },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        setErrorModal(true);
      }
      const errorMessage = graphqlError.message || "An error occurred";
      console.error(
        `GraphQL error: Status ${statusCode}, Message: ${errorMessage}`
      );
    },
  });
  const [deletemodal, setDeleteModal] = useState(false);

  // Toggle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  useEffect(() => {
    // Update category list when categoryData changes
    if (categoryData && categoryData.specialsList) {
      const list = categoryData.specialsList.edges.map(
        (item: any, index: number) => ({
          id: item.node.specialId,
          slugName: item.node.name,
          productIds: item.node.products ? JSON.parse(item.node.products) : [],
          productCount: item.node.products
            ? JSON.parse(item.node.products).length
            : 0,
          status: item.node.specialStatus === "Active" ? true : false,
        })
      );

      setCategoryList(list);
    }
  }, [categoryData]);

  const navigate = useNavigate();

  // Handle navigation to create category page
  const HandleNavigation = () => {
    navigate("/create/category");
  };

  const rows: Row[] = categoryList;
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteCategoryValue, { loading: deleteLoader }] = useMutation(
    Delete_Category,
    {
      onError: () => {
        // Handle any errors here, e.g., display an error message.
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [SPECIALS_LIST],
      onCompleted: () => {
        toast.success(
          <div>
            <Typography> {t("toast.removed")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
    }
  );
  const [categoryIdValue, setCategoryIdValue] = useState("");
  const getCategoryId = (id: string) => {
    setCategoryIdValue(id);
  };
  const handleDelete = (id: string) => {
    deleteCategoryValue({ variables: { categoryIds: JSON.stringify([+id]) } });
    setCategoryIdValue("");
  };

  // Handle bulk delete of categories

  // Handle edit in category
  const handleEditInCategory = (id: string) => {
    navigate(`/${id}/update/category`);
  };

  // Handle delete by popover

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoryId, setCategoryId] = useState("");
  const [productIds, setProductIds] = useState<string[]>([]);

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const filterRows = rows.filter((row) => rowSelectionModel.includes(row.id));
    const menuIds = filterRows.map((each) => +each.id);
    deleteCategoryValue({
      variables: { categoryIds: JSON.stringify(menuIds) },
    });
    setRowSelectionModel([]);
  };
  // Handle category ID selection
  const handleCategoryId = (category: any) => {
    setCategoryId(category);
    const list = categoryList.filter((each) => each.id === category)[0]
      .productIds;

    setProductIds(list);
  };

  const CustomStyles = styled("div")({
    "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
      outline: "solid #1976d2 0px !important",
    },
    "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
      {
        outline: "solid #1976d2 0px !important",
      },
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "500 !important",
    },
  });
  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [statusState, setStatusState] = useState<boolean>(false);

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setStatusId(id);
    setStatusState(event.target.checked);
  };

  const [menuStatusChange] = useMutation(SPECIALS_STATUS_CHANGE, {
    context: { clientName: "categoryClient" },
    refetchQueries: [SPECIALS_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleStatusCall = () => {
    const status = statusState ? "Active" : "InActive";
    menuStatusChange({
      variables: { specialId: statusId && +statusId, specialStatus: status },
    });
  };
  const columns: GridColDef[] = [
    {
      field: "slugName",
      //@ts-ignore
      headerName: t("menu.catageory.specials") || "",
      flex: 1,
      // columnWidth: "60%",
      headerClassName: "table-column-header ",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="dataGrid-table-cell">
              {params.row.slugName}
            </Typography>
            <Typography className="categories-table-text-para">
              {params.row.productCount} {t("menu.catageory.products")}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "status",

      headerName: t("users.status") || "",
      // flex: 0.5,
      width: 400,
      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "tableCellInCategory",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={(event) => {
              handleStatus(event, params.row.id);
              setIsModalOpen1(true);
            }}
            color="warning"
          />
        );
      },
    },
    {
      field: "er",
      headerName: "",
      // flex: 0.5,
      width: 400,
      headerClassName: "table-column-header ",
      align: "center",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              className="categories-button2"
              onClick={() => {
                handleOpen();
                handleCategoryId(params.row.id);
              }}
            >
              {t("menu.catageory.assignProducts")}
            </Button>
          </Box>
        );
      },
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "slugName",
      sort: "asc",
    },
  ]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const intProductIds = productIds.map((each) => parseInt(each));
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell";
  };

  if (categoryError) return <p>Error : {categoryError.message}</p>;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 20px",
        }}
      >
        <Box>
          <Typography className="main-head">
            {t("menu.catageory.specials")}
          </Typography>
          <Typography className="main-para">
            {t("menu.catageory.implement_special")}
          </Typography>
        </Box>

        {/* <Box sx={{ display: 'flex', justifyContent: "space-evenly" }}>
          <Button className="main-button" onClick={HandleNavigation} sx={{ marginRight: "0.6rem" }}> {t("menu.catageory.create_catageory")}</Button>
          {rowSelectionModel.length > 0 && (<Button className="main-button" onClick={handleDeleteModal} sx={{}}>{t("menu.catageory.delete")}</Button>)}
        </Box> */}
      </Box>
      <Divider sx={{ mt: "12px", mb: "12px" }} />
      {/* <TextField
        label="Search by category name"
        placeholder='Search by category name'
        id="outlined-start-adornment"
        size="small"
        sx={{ ml: "20px", width: '20%', mb: "4px" }}
        onChange={(e) => setSearchValue(e.target.value)}
        InputProps={{
          startAdornment: <InputAdornment position="start">{<SearchIcon />}</InputAdornment>,
        }}
      /> */}
      {categoryLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            height: "70vh",
            width: "100%",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            columnHeaderHeight={48}
            rowHeight={54}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            pageSizeOptions={[5, 15, 25]}
            // checkboxSelection
            disableRowSelectionOnClick
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(
              newRowSelectionModel: GridRowSelectionModel
            ) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              marginLeft: "20px",
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#f5f5f5",
              },
            }}
          />
        </Box>
      )}
      {/* <AssignProducts open={open} upDateOpen={handleClose} categoryId={categoryId} assigncategoryDataIds={intProductIds} /> */}
      <AssignProducts
        open={open}
        upDateOpen={handleClose}
        categoryId={categoryId}
        assigncategoryDataIds={intProductIds}
        section={"specials"}
      />

      <ErrorModal
        errorModal={errorModal}
        statusCode={statusCode}
        handleErrorModal={handleErrorModal}
      />

      {/* <DeleteDailouge deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDelete} id={categoryIdValue} handleDeleteClick={handleDeleteClick} type='category' /> */}
      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Menu?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => {
          setIsModalOpen1(false);
          handleStatusCall();
        }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
    </Box>
  );
}

export default SpecialsListing;
