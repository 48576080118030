import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Typography, IconButton } from "@mui/material";
import { Stepper, Step } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import "./createLocationChangedFormat.css";
import { CHECKBOX_LISTS } from "../../graphicalQl/usequery/deliveryManagementQuery";
import BasicDetails from "./basicDetails";
import LocationBusinessAddress from "./locationBusinessAddress";
import LocationBusinessHours from "./locationBusinessHours";
import LocationContactInformation from "./locationContactInformation";
import LocationOrderTypeAndPayments from "./locationOrderTypeAndPayments";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_STORE,
  DELETE_HOLIDAY,
  UPDATE_STORE_BASIC_DETAILS,
  UPDATE_STORE_CONTACT_IMFORMATION,
  UPDATE_STORE_ADDRESS,
  UPDATE_BUSINESS_HOURS,
  UPDATE_PAYMENT_TYPES,
} from "../../graphicalQl/mutation/locationMutation";
import {
  GETTING_LOCATIONS_LIST,
  HOLIDAYS_LIST,
  GET_UPDATING_STORE_DETAILS,
  GET_STORE_ADDRESS_DETAILS,
  ADDRESS_LIST,
  GETTING_API_KEYS,
} from "../../graphicalQl/usequery/locationQuery";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import DeletePopup from "../popups/deletePopup";
import { useTranslation } from "react-i18next";

const initialValuesOfCreateLocationFormBasicDetails = {
  branchName: null,
  localName: null,
  businessInfo: null,
  locationType: "PHYSICAL",
  image: null,
  color: null,
  isDuplicate: false,
};

interface ContactInfoState {
  email: string | null;
  phoneNumber: number | null;
  twitter: string | null;
  instagram: string | null;
  facebook: string | null;
  website: string | null;
}

const initialValuesOfCreateLocationFormContactImformation: ContactInfoState = {
  email: null,
  phoneNumber: null,
  twitter: null,
  instagram: null,
  facebook: null,
  website: null,
};

const initialValuesOfCreateLocationFormBusinessAddress = {
  address1: null,
  address2: null,
  postCode: null,
  country: null,
  city: null,
  state: null,
  lattitudeAndLongitude: { lat: null, lng: null },
};
const steps = [
  "basic_details",
  "business_address",
  "contact_information",
  "order_type_and_payments",
  "store_timings",
];

const validationSchemaBasicDetails = Yup.object({
  branchName: Yup.string().required("Branch Name is required"),
});
const validationSchemaBusinessAddress = Yup.object({
  address1: Yup.string().required("Address is required"),
});

const validationSchemaContactImformation = Yup.object({
  email: Yup.string()
    .required("Email is required")
    .email("Invalid email address"),
  phoneNumber: Yup.string()
    .required("Mobile is required")
    .matches(/^\d{10,11}$/, "Invalid Number"),
});

const CreateLocationChangedFormat = () => {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const filteredCategory = {
    businessId: businessId,
  };
  const [locationKey, setLocationKey] = useState<string | null>(null);
  const {
    loading: loading,
    error: error,
    data: data,
  } = useQuery(GETTING_API_KEYS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data) {
      const { business } = data;
      if (business) {
        const { apiKeys } = business;
        if (apiKeys) {
          const parsedApis = JSON.parse(apiKeys);
          const { google_maps_api_key } = parsedApis;
          setLocationKey(google_maps_api_key);
        }
      }
    }
  }, [data]);

  const { editId } = useParams();
  const { phoneCountryCode } = useSelector(
    (state: any) => state.headerLocation
  );
  const [createdStoreId, setCreatedStoreId] = useState<string | null>(null);
  const [errorState, updateErrorState] = useState<boolean>(false);

  const [
    comfirmationForClickOnAddHolidayButton,
    setComfirmationForClickOnAddHolidayButton,
  ] = useState<boolean>(false);

  const [addressId, setAddressId] = useState<string | null>(null);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [
    handleSaveButtonAfterStoreCreated,
    setHandleSaveButtonAfterStoreCreated,
  ] = useState<boolean>(false);

  const {
    loading: checkboxListLoadingForDineIn,
    error: checkboxListErrorForDineIn,
    data: checkboxListDataForDineIn,
  } = useQuery(CHECKBOX_LISTS, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId, orderType: "dinein" },
  });
  const {
    loading: checkboxListLoadingForDelivery,
    error: checkboxListErrorForDelivery,
    data: checkboxListDataForDelivery,
  } = useQuery(CHECKBOX_LISTS, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId, orderType: "delivery" },
  });
  const {
    loading: checkboxListLoadingForCollection,
    error: checkboxListErrorForCollection,
    data: checkboxListDataForCollection,
  } = useQuery(CHECKBOX_LISTS, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId, orderType: "collection" },
  });
  const {
    loading: checkboxListLoadingForShop,
    error: checkboxListErrorForShop,
    data: checkboxListDataForShop,
  } = useQuery(CHECKBOX_LISTS, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId, orderType: "shop" },
  });

  const {
    loading: holidaysListLoading,
    error: holidaysListError,
    data: holidaysListData,
  } = useQuery(HOLIDAYS_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      storeId:
        editId === undefined ? createdStoreId && +createdStoreId : +editId,
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: updatingStoreDetailsLoading,
    error: updatingStoreDetailsError,
    data: updatingStoreDetailsData,
  } = useQuery(GET_UPDATING_STORE_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { storeId: editId && +editId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (editId !== undefined) {
      setConditions([false, false, false]);
    }
  }, [editId]);

  const {
    loading: storeAddressLoading,
    error: storeAddressError,
    data: storeAddressData,
  } = useQuery(GET_STORE_ADDRESS_DETAILS, {
    context: { clientName: "addressClient" },
    variables: { addressId: addressId && +addressId },
    fetchPolicy: "network-only",
  });
  const [conditions, setConditions] = useState<boolean[]>([true, true, true]);
  const [checkboxEmptyalertPopup, setCheckBoxEmptysetAlertPopup] =
    useState<boolean>(false);
  useEffect(() => {
    if (storeAddressData && editId) {
      const { address } = storeAddressData;
      if (address) {
        const {
          address1,
          address2,
          city,
          country,
          latitude,
          longitude,
          postCode,
          state,
        } = address;
        formikForBusinessAddress.setValues({
          ...formikForBusinessAddress.values,
          address1: address1,
          address2: address2,
          postCode: postCode,
          country: country,

          city: city,
          state: state,
          lattitudeAndLongitude: { lat: latitude, lng: longitude },
        });
      }
    }
  }, [storeAddressData]);

  useEffect(() => {
    if (updatingStoreDetailsData) {
      const { store } = updatingStoreDetailsData;
      const {
        addressId,
        brandLogo,
        description,

        email,
        facebook,
        instagram,
        localName,
        locationType,
        mobile,

        paymentTypesForCollection,
        paymentTypesForDelivery,
        paymentTypesForDinein,
        paymentTypesForShop,

        shopWeekSchedule,
        collectionWeekSchedule,
        deliveryWeekSchedule,
        dineinWeekSchedule,

        isDinein,
        isShop,
        isCollection,
        isDelivery,

        storeName,
        twitter,
        website,
      } = store;

      const convertedMobile = mobile && mobile.replace(phoneCountryCode, "");

      setAddressId(addressId);
      setDineIncheckbox(isDinein);
      setDeliverycheckbox(isDelivery);
      setShopcheckbox(isShop);
      setCollectioncheckbox(isCollection);

      const filteredDineInPaymentList = dineInCheckboxList.map((each: any) => ({
        ...each,
        isChecked:
          paymentTypesForDinein &&
          JSON.parse(paymentTypesForDinein).includes(each.label),
      }));

      setDineInCheckboxList(filteredDineInPaymentList);

      const filteredCollectionPaymentList = collectionCheckboxList.map(
        (each: any) => ({
          ...each,
          isChecked:
            paymentTypesForCollection &&
            JSON.parse(paymentTypesForCollection).includes(each.label),
        })
      );

      setCollectionCheckboxList(filteredCollectionPaymentList);

      const filteredDeliveryPaymentList = deliveryCheckboxList.map(
        (each: any) => ({
          ...each,
          isChecked:
            paymentTypesForDelivery &&
            JSON.parse(paymentTypesForDelivery).includes(each.label),
        })
      );

      setDeliveryCheckboxList(filteredDeliveryPaymentList);

      const filteredShopPaymentList = shopCheckboxList.map((each: any) => ({
        ...each,
        isChecked:
          paymentTypesForShop &&
          JSON.parse(paymentTypesForShop).includes(each.label),
      }));

      setShopCheckboxList(filteredShopPaymentList);

      if (deliveryWeekSchedule) {
        setDeliveryDaysAndHours((prevState) => {
          return prevState.map((dayObj) => {
            const dayKey = dayObj.day;
            if (JSON.parse(deliveryWeekSchedule)[dayKey]) {
              return {
                ...dayObj,
                selected: JSON.parse(deliveryWeekSchedule)[dayKey].isOpen,
                sets: JSON.parse(deliveryWeekSchedule)[dayKey].timings,
              };
            }
            return dayObj;
          });
        });
      }

      if (shopWeekSchedule) {
        setStoreDaysAndHours((prevState) => {
          return prevState.map((dayObj) => {
            const dayKey = dayObj.day;
            if (JSON.parse(shopWeekSchedule)[dayKey]) {
              return {
                ...dayObj,
                selected: JSON.parse(shopWeekSchedule)[dayKey].isOpen,
                sets: JSON.parse(shopWeekSchedule)[dayKey].timings,
              };
            }
            return dayObj;
          });
        });
      }

      if (collectionWeekSchedule) {
        setCollectionDaysAndHours((prevState) => {
          return prevState.map((dayObj) => {
            const dayKey = dayObj.day;
            if (JSON.parse(collectionWeekSchedule)[dayKey]) {
              return {
                ...dayObj,
                selected: JSON.parse(collectionWeekSchedule)[dayKey].isOpen,
                sets: JSON.parse(collectionWeekSchedule)[dayKey].timings,
              };
            }
            return dayObj;
          });
        });
      }

      if (dineinWeekSchedule) {
        setDineInDaysAndHours((prevState) => {
          return prevState.map((dayObj) => {
            const dayKey = dayObj.day;
            if (JSON.parse(dineinWeekSchedule)[dayKey]) {
              return {
                ...dayObj,
                selected: JSON.parse(dineinWeekSchedule)[dayKey].isOpen,
                sets: JSON.parse(dineinWeekSchedule)[dayKey].timings,
              };
            }
            return dayObj;
          });
        });
      }

      formikForBasicdetails.setValues({
        ...formikForBasicdetails.values,
        branchName: storeName,
        localName: localName,
        businessInfo: description,
        locationType: locationType,
        image: brandLogo,
        color: null,
      });

      formikForContactImformation.setValues({
        ...formikForContactImformation.values,
        email: email,
        phoneNumber: convertedMobile,
        twitter: twitter,
        instagram: instagram,
        facebook: facebook,
        website: website,
      });
    }
  }, [updatingStoreDetailsData, t]);

  const [createStore] = useMutation(CREATE_STORE, {
    onCompleted: (data) => {
      const { createStore } = data;
      const { success, store } = createStore;
      if (success) {
        toast.success(
          <div>
            <Typography>{t("toast.created")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
        const { storeId } = store;
        setCreatedStoreId(storeId);
        setHandleSaveButtonAfterStoreCreated(true);
        if (comfirmationForClickOnAddHolidayButton) {
          setDrawerState(true);
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST, ADDRESS_LIST],
  });

  const [updateStoreBasicDetails] = useMutation(UPDATE_STORE_BASIC_DETAILS, {
    onCompleted: (data) => {
      if (data) {
        const { updateStore } = data;
        if (updateStore) {
          const { success } = updateStore;
          if (success) {
            setActiveStep(1);
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST, ADDRESS_LIST],
  });

  const [updateStoreContactImformation] = useMutation(
    UPDATE_STORE_CONTACT_IMFORMATION,
    {
      onCompleted: (data) => {
        if (data) {
          const { updateStore } = data;
          if (updateStore) {
            const { success } = updateStore;
            if (success) {
              setActiveStep(3);

              toast.success(
                <div>
                  <Typography>{t("toast.updated")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            }
          }
        }
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
      context: { clientName: "businessClient" },
      refetchQueries: [GETTING_LOCATIONS_LIST, ADDRESS_LIST],
    }
  );

  const [updateStoreBusinessHours] = useMutation(UPDATE_BUSINESS_HOURS, {
    onCompleted: (data) => {
      if (data) {
        const { updateStore } = data;
        if (updateStore) {
          const { success } = updateStore;
          if (success) {
            navigate("/location/locations-list");
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST, ADDRESS_LIST],
  });

  const [updateStorePaymentTypes] = useMutation(UPDATE_PAYMENT_TYPES, {
    onCompleted: (data) => {
      if (data) {
        const { updateStore } = data;
        if (updateStore) {
          const { success } = updateStore;
          if (success) {
            setActiveStep(4);

            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_LOCATIONS_LIST, ADDRESS_LIST],
  });

  const [updateStoreAddress] = useMutation(UPDATE_STORE_ADDRESS, {
    onCompleted: (data) => {
      if (data) {
        const { updateAddress } = data;
        if (updateAddress) {
          const { success } = updateAddress;
          if (success) {
            setActiveStep(2);

            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "addressClient" },
    refetchQueries: [ADDRESS_LIST],
  });

  const updateBasicDetails = () => {
    updateStoreBasicDetails({
      variables: {
        business: businessId && +businessId,
        storeId: editId && +editId,
        storeName:
          formikForBasicdetails.values.branchName &&
          (formikForBasicdetails.values.branchName as string)?.trim(),
        localName: formikForBasicdetails.values.localName,
        description: formikForBasicdetails.values.businessInfo,
        locationType: formikForBasicdetails.values.locationType,
        brandLogo: formikForBasicdetails.values.image,
      },
    });
  };

  const updateContactInformation = () => {
    updateStoreContactImformation({
      variables: {
        business: businessId && +businessId,
        storeId: editId && +editId,
        email: formikForContactImformation.values.email,
        mobile: `${phoneCountryCode}${formikForContactImformation.values.phoneNumber}`,
        twitter: formikForContactImformation.values.twitter,
        instagram: formikForContactImformation.values.instagram,
        facebook: formikForContactImformation.values.facebook,
        website: formikForContactImformation.values.website,
      },
    });
  };

  const updateAddress = () => {
    updateStoreAddress({
      variables: {
        business: businessId && +businessId,
        storeId: editId && +editId,
        address1: formikForBusinessAddress.values.address1,

        address2: formikForBusinessAddress.values.address2,
        postCode: formikForBusinessAddress.values.postCode,
        city: formikForBusinessAddress.values.city,
        state: formikForBusinessAddress.values.state,
        country: formikForBusinessAddress.values.country,
        addressId: addressId && +addressId,
        latitude: JSON.stringify(
          formikForBusinessAddress.values.lattitudeAndLongitude.lat
        ),
        longitude: JSON.stringify(
          formikForBusinessAddress.values.lattitudeAndLongitude.lng
        ),
      },
    });
  };

  const updateBusinessHours = () => {
    const filteredStoreListDayAndHours = storeDaysAndHours.filter(
      (each: any) => each.selected
    );
    const filteredDineInListDayAndHours = dineInDaysAndHours.filter(
      (each: any) => each.selected
    );
    const filteredCollectionListDayAndHours = collectionDaysAndHours.filter(
      (each: any) => each.selected
    );
    const filteredDeliveryListDayAndHours = deliveryDaysAndHours.filter(
      (each: any) => each.selected
    );

    const requiredFormatStoreList =
      filteredStoreListDayAndHours.length > 0
        ? filteredStoreListDayAndHours.reduce((result: any, each: any) => {
            result[each.day] = {
              isOpen: each.selected,
              timings: each.sets,
            };
            return result;
          }, {})
        : null;

    const requiredFormatDineList =
      filteredDineInListDayAndHours.length > 0
        ? filteredDineInListDayAndHours.reduce((result: any, each: any) => {
            result[each.day] = {
              isOpen: each.selected,
              timings: each.sets,
            };
            return result;
          }, {})
        : null;

    const requiredFormatCollection =
      filteredCollectionListDayAndHours.length > 0
        ? filteredCollectionListDayAndHours.reduce((result: any, each: any) => {
            result[each.day] = {
              isOpen: each.selected,
              timings: each.sets,
            };
            return result;
          }, {})
        : null;

    const requiredFormatDelivery =
      filteredDeliveryListDayAndHours.length > 0
        ? filteredDeliveryListDayAndHours.reduce((result: any, each: any) => {
            result[each.day] = {
              isOpen: each.selected,
              timings: each.sets,
            };
            return result;
          }, {})
        : null;

    const filteredDataFromNull = {
      business: businessId && +businessId,
      storeId: editId && +editId,
      shopWeekSchedule: requiredFormatStoreList
        ? JSON.stringify(requiredFormatStoreList)
        : null,
      collectionWeekSchedule: requiredFormatCollection
        ? JSON.stringify(requiredFormatCollection)
        : null,
      deliveryWeekSchedule: requiredFormatDelivery
        ? JSON.stringify(requiredFormatDelivery)
        : null,
      dineinWeekSchedule: requiredFormatDineList
        ? JSON.stringify(requiredFormatDineList)
        : null,
    };

    // const filteredCategory = Object.fromEntries(
    //   Object.entries(filteredDataFromNull).filter(
    //     ([_, value]) => value !== null
    //   )
    // );

    updateStoreBusinessHours({
      variables: filteredDataFromNull,
    });
  };

  const [deleteHoliday] = useMutation(DELETE_HOLIDAY, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [HOLIDAYS_LIST],
  });

  const handleClearButtonInBusinessAddress = () => {
    formikForBusinessAddress.resetForm();
  };

  const formikForBasicdetails = useFormik({
    initialValues: initialValuesOfCreateLocationFormBasicDetails,
    validationSchema: validationSchemaBasicDetails,
    onSubmit: (values) => {
      if (editId === undefined) {
        if (!formikForBasicdetails.values.isDuplicate) {
          setActiveStep(1);
          setConditions((prevConditions) => {
            const newConditions = [...prevConditions];
            newConditions[0] = false;
            return newConditions;
          });
        }
      } else {
        if (!formikForBasicdetails.values.isDuplicate) {
          updateBasicDetails();
        }
      }
    },
  });

  const formikForBusinessAddress = useFormik({
    initialValues: initialValuesOfCreateLocationFormBusinessAddress,
    validationSchema: validationSchemaBusinessAddress,
    onSubmit: (values) => {
      if (editId === undefined) {
        setActiveStep(2);
        setConditions((prevConditions) => {
          const newConditions = [...prevConditions];
          newConditions[1] = false;
          return newConditions;
        });
      } else {
        updateAddress();
      }
    },
  });

  const formikForContactImformation = useFormik({
    initialValues: initialValuesOfCreateLocationFormContactImformation,
    validationSchema: validationSchemaContactImformation,
    onSubmit: (values) => {
      if (editId === undefined) {
        setActiveStep(3);
        setConditions((prevConditions) => {
          const newConditions = [...prevConditions];
          newConditions[2] = false;
          return newConditions;
        });
      } else {
        updateContactInformation();
      }
    },
  });

  const customStyles = {
    "& .MuiStepConnector-line": {
      display: "block",
      borderColor: "#bdbdbd",
      borderLeftStyle: "none !important",
      borderLeftWidth: "1px",
      minHeight: "0px !important",
      maxHeight: "0px !important",
    },
    "& .MuiStepConnector-root": {
      WebkitFlex: "none !important",
      msFlex: "none !important",
      flex: "none !important",
      marginLeft: "none !important",
    },
    "& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed": {
      fontFamily: "Poppins !important",
      fontSize: "0.8rem !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "normal !important",
    },
    "& .MuiButtonBase-root.MuiStepButton-root": {
      display: "flex !important",
      padding: "10px 12px !important",
      alignItems: "center !important",
      alignSelf: "stretch !important",
      borderRadius: "4px 4px 0px 0px !important",
      borderLeft: "2px solid #14B8A6 !important",
      background: "#FFF !important",
      "&:hover": {
        background: "#FFF8F0 !important",
      },
      border: "1px solid #EEE !important",
    },
  };

  const [dineInCheckboxList, setDineInCheckboxList] = useState<any[]>([]);
  const [collectionCheckboxList, setCollectionCheckboxList] = useState<any[]>(
    []
  );
  const [deliveryCheckboxList, setDeliveryCheckboxList] = useState<any[]>([]);
  const [shopCheckboxList, setShopCheckboxList] = useState<any[]>([]);
  const [alertPopupOpen, setAlertPopupOpen] = useState<boolean>(false);

  const [storeDaysAndHours, setStoreDaysAndHours] = useState<any[]>([
    {
      selected: false,
      day: "Monday",
      text: t("devices.monday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Tuesday",
      text: t("devices.tuesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Wednesday",
      text: t("devices.wednesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Thursday",
      text: t("devices.thursday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Friday",

      text: t("devices.friday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Saturday",
      text: t("devices.saturday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Sunday",
      text: t("devices.sunday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
  ]);

  useEffect(() => {
    setStoreDaysAndHours([
      {
        selected: false,
        day: "Monday",
        text: t("devices.monday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Tuesday",
        text: t("devices.tuesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Wednesday",
        text: t("devices.wednesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Thursday",
        text: t("devices.thursday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Friday",

        text: t("devices.friday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Saturday",
        text: t("devices.saturday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Sunday",
        text: t("devices.sunday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
    ]);
    setDineInDaysAndHours([
      {
        selected: false,
        day: "Monday",
        text: t("devices.monday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Tuesday",
        text: t("devices.tuesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Wednesday",
        text: t("devices.wednesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Thursday",
        text: t("devices.thursday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Friday",

        text: t("devices.friday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Saturday",
        text: t("devices.saturday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Sunday",
        text: t("devices.sunday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
    ]);
    setCollectionDaysAndHours([
      {
        selected: false,
        day: "Monday",
        text: t("devices.monday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Tuesday",
        text: t("devices.tuesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Wednesday",
        text: t("devices.wednesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Thursday",
        text: t("devices.thursday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Friday",

        text: t("devices.friday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Saturday",
        text: t("devices.saturday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Sunday",
        text: t("devices.sunday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
    ]);
    setDeliveryDaysAndHours([
      {
        selected: false,
        day: "Monday",
        text: t("devices.monday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Tuesday",
        text: t("devices.tuesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Wednesday",
        text: t("devices.wednesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Thursday",
        text: t("devices.thursday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Friday",

        text: t("devices.friday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Saturday",
        text: t("devices.saturday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Sunday",
        text: t("devices.sunday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
    ]);
  }, [t]);

  const collectFilledTimeRanges = () => {
    const filledTimeRanges = [];

    // Iterate through each day in the storeDaysAndHours array
    for (const day of storeDaysAndHours) {
      // Iterate through each set of time ranges for the current day
      for (const set of day.sets) {
        // Check if both openTime and closingTime are not empty
        if (set.openTime !== "" && set.closingTime !== "") {
          filledTimeRanges.push({
            day: day.day,
            openTime: set.openTime,
            closingTime: set.closingTime,
          });
        }
      }
    }

    return filledTimeRanges;
  };

  // Example usage
  const filledTimeRanges = collectFilledTimeRanges();

  const [dineInDaysAndHours, setDineInDaysAndHours] = useState([
    {
      selected: false,
      day: "Monday",
      text: t("devices.monday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Tuesday",
      text: t("devices.tuesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Wednesday",
      text: t("devices.wednesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Thursday",
      text: t("devices.thursday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Friday",

      text: t("devices.friday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Saturday",
      text: t("devices.saturday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Sunday",
      text: t("devices.sunday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
  ]);
  const [collectionDaysAndHours, setCollectionDaysAndHours] = useState([
    {
      selected: false,
      day: "Monday",
      text: t("devices.monday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Tuesday",
      text: t("devices.tuesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Wednesday",
      text: t("devices.wednesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Thursday",
      text: t("devices.thursday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Friday",

      text: t("devices.friday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Saturday",
      text: t("devices.saturday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Sunday",
      text: t("devices.sunday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
  ]);
  const [deliveryDaysAndHours, setDeliveryDaysAndHours] = useState([
    {
      selected: false,
      day: "Monday",
      text: t("devices.monday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Tuesday",
      text: t("devices.tuesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Wednesday",
      text: t("devices.wednesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Thursday",
      text: t("devices.thursday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Friday",

      text: t("devices.friday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Saturday",
      text: t("devices.saturday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Sunday",
      text: t("devices.sunday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
  ]);

  const [dineInCheckbox, setDineIncheckbox] = useState<boolean>(true);
  const [collectionCheckbox, setCollectioncheckbox] = useState<boolean>(true);
  const [deliveryCheckbox, setDeliverycheckbox] = useState<boolean>(true);
  const [shopCheckbox, setShopcheckbox] = useState<boolean>(true);

  const [holidaysList, setHolidaysList] = useState<any[]>([]);
  const [deleteHolidayId, setDeleteHolidayId] = useState<string | null>(null);
  const [deleteHolidayPopupOpen, setDeleteHolidayPopupOpen] =
    useState<boolean>(false);
  const deleteHolidayHandleClose = () => {
    setDeleteHolidayPopupOpen(false);
    setDeleteHolidayId(null);
  };

  const deleteHolidayHandleOpen = () => {
    setDeleteHolidayPopupOpen(true);
  };

  useEffect(() => {
    if (checkboxListDataForDineIn) {
      const { paymentTypeList } = checkboxListDataForDineIn;
      const parsedCheckboxList = JSON.parse(paymentTypeList);
      const filteredCheckList = parsedCheckboxList.map((each: any) => ({
        id: each,
        label: each,
        isChecked: each === "Card" || each === "Cash" ? true : false,
      }));
      setDineInCheckboxList(filteredCheckList);
    }
  }, [checkboxListDataForDineIn]);

  useEffect(() => {
    if (checkboxListDataForCollection) {
      const { paymentTypeList } = checkboxListDataForCollection;
      const parsedCheckboxList = JSON.parse(paymentTypeList);
      const filteredCheckList = parsedCheckboxList.map((each: any) => ({
        id: each,
        label: each,
        isChecked: each === "Card" || each === "Cash" ? true : false,
      }));

      setCollectionCheckboxList(filteredCheckList);
    }
  }, [checkboxListDataForCollection]);

  useEffect(() => {
    if (checkboxListDataForDelivery) {
      const { paymentTypeList } = checkboxListDataForDelivery;
      const parsedCheckboxList = JSON.parse(paymentTypeList);
      const filteredCheckList = parsedCheckboxList.map((each: any) => ({
        id: each,
        label: each,
        isChecked: each === "Card" || each === "Cash" ? true : false,
      }));

      setDeliveryCheckboxList(filteredCheckList);
    }
  }, [checkboxListDataForDelivery]);

  useEffect(() => {
    if (checkboxListDataForShop) {
      const { paymentTypeList } = checkboxListDataForShop;
      const parsedCheckboxList = JSON.parse(paymentTypeList);
      const filteredCheckList = parsedCheckboxList.map((each: any) => ({
        id: each,
        label: each,
        isChecked: each === "Card" || each === "Cash" ? true : false,
      }));

      setShopCheckboxList(filteredCheckList);
    }
  }, [checkboxListDataForShop]);

  useEffect(() => {
    if (holidaysListData) {
      const { holidayList } = holidaysListData;
      const { edges } = holidayList;
      const filteredDataList =
        edges &&
        edges.map((each: any) => ({
          closeAllDay: each.node.closeAllDay,
          dateRange: each.node.dateRange && JSON.parse(each.node.dateRange),
          holidayId: each.node.holidayId,
          holidayName: each.node.name,
          note: each.node.note,
          timeRange: each.node.timeRange && JSON.parse(each.node.timeRange),
        }));
      setHolidaysList(filteredDataList);
    }
  }, [holidaysListData]);

  const { tabState } = useSelector((state: any) => state.app);

  const navigate = useNavigate();

  const handleNavigateTolisting = () => {
    // navigate('/locations/list')
    window.history.back();
  };

  const [activeStep, setActiveStep] = React.useState(+tabState || 0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  // const handleNext = () => {
  //   const newActiveStep =
  //     isLastStep() && !allStepsCompleted()
  //       ? // It's the last step, but not all steps have been completed,
  //         // find the first step that has been completed
  //         steps.findIndex((step, i) => !(i in completed))
  //       : activeStep + 1;
  //   setActiveStep(newActiveStep);
  //   // dispatch(setName("tabState", newActiveStep));
  // };

  const handleStep = (step: number) => {
    setActiveStep(step);
    // dispatch(setName("tabState", step));
  };

  const handleOrderPaymentsNextButton = () => {
    if (
      dineInCheckbox ||
      collectionCheckbox ||
      shopCheckbox ||
      deliveryCheckbox
    ) {
      if (editId === undefined) {
        setActiveStep(4);
      } else {
        const filteredPayemnstsData = {
          business: businessId && +businessId,
          storeId: editId && +editId,
          isShop: shopCheckbox,
          paymentTypesForShop: shopCheckbox
            ? JSON.stringify(
                shopCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isDinein: dineInCheckbox,
          paymentTypesForDinein: dineInCheckbox
            ? JSON.stringify(
                dineInCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isDelivery: deliveryCheckbox,
          paymentTypesForDelivery: deliveryCheckbox
            ? JSON.stringify(
                deliveryCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isCollection: collectionCheckbox,
          paymentTypesForCollection: collectionCheckbox
            ? JSON.stringify(
                collectionCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
        };

        const filteredCategory = Object.fromEntries(
          Object.entries(filteredPayemnstsData).filter(
            ([_, value]) => value !== null
          )
        );

        updateStorePaymentTypes({
          variables: filteredCategory,
        });
      }
    } else {
      setCheckBoxEmptysetAlertPopup(true);
    }
  };

  const handleCreateStoreSaveButtonWithNavigate = () => {
    handleCreateStoreSaveButton();
  };

  const handleCreateStoreSaveButton = () => {
    if (editId === undefined) {
      if (!errorState) {
        const filteredStoreListDayAndHours = storeDaysAndHours.filter(
          (each: any) => each.selected
        );
        const filteredDineInListDayAndHours = dineInDaysAndHours.filter(
          (each: any) => each.selected
        );
        const filteredCollectionListDayAndHours = collectionDaysAndHours.filter(
          (each: any) => each.selected
        );
        const filteredDeliveryListDayAndHours = deliveryDaysAndHours.filter(
          (each: any) => each.selected
        );

        const requiredFormatStoreList =
          filteredStoreListDayAndHours.length > 0
            ? filteredStoreListDayAndHours.reduce((result: any, each: any) => {
                result[each.day] = {
                  isOpen: each.selected,
                  timings: each.sets,
                };
                return result;
              }, {})
            : null;

        const requiredFormatDineList =
          filteredDineInListDayAndHours.length > 0
            ? filteredDineInListDayAndHours.reduce((result: any, each: any) => {
                result[each.day] = {
                  isOpen: each.selected,
                  timings: each.sets,
                };
                return result;
              }, {})
            : null;

        const requiredFormatCollection =
          filteredCollectionListDayAndHours.length > 0
            ? filteredCollectionListDayAndHours.reduce(
                (result: any, each: any) => {
                  result[each.day] = {
                    isOpen: each.selected,
                    timings: each.sets,
                  };
                  return result;
                },
                {}
              )
            : null;

        const requiredFormatDelivery =
          filteredDeliveryListDayAndHours.length > 0
            ? filteredDeliveryListDayAndHours.reduce(
                (result: any, each: any) => {
                  result[each.day] = {
                    isOpen: each.selected,
                    timings: each.sets,
                  };
                  return result;
                },
                {}
              )
            : null;

        const filteredAddresList = {
          address1: formikForBusinessAddress.values.address1,
          address2: formikForBusinessAddress.values.address2,
          postCode: formikForBusinessAddress.values.postCode,
          city: formikForBusinessAddress.values.city,
          country: formikForBusinessAddress.values.country,
          state: formikForBusinessAddress.values.state,
          latitude: JSON.stringify(
            formikForBusinessAddress.values.lattitudeAndLongitude["lat"]
          ),
          longitude: JSON.stringify(
            formikForBusinessAddress.values.lattitudeAndLongitude["lng"]
          ),
        };

        const requiredFormatAddress = Object.fromEntries(
          Object.entries(filteredAddresList).filter(
            ([_, value]) => value !== null
          )
        );

        const filteredDataFromNull = {
          business: +businessId,

          storeName:
            formikForBasicdetails.values.branchName &&
            (formikForBasicdetails.values.branchName as string)?.trim(),
          localName: formikForBasicdetails.values.localName,
          description: formikForBasicdetails.values.businessInfo,
          locationType: formikForBasicdetails.values.locationType,
          brandLogo:
            formikForBasicdetails.values.image ||
            formikForBasicdetails.values.color,
          addressDetails: requiredFormatAddress,
          email: formikForContactImformation.values.email,
          mobile: `${phoneCountryCode}${formikForContactImformation.values.phoneNumber}`,
          twitter: formikForContactImformation.values.twitter,
          website: formikForContactImformation.values.website,
          instagram: formikForContactImformation.values.instagram,
          facebook: formikForContactImformation.values.facebook,

          isShop: shopCheckbox,
          paymentTypesForShop: shopCheckbox
            ? JSON.stringify(
                shopCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isDinein: dineInCheckbox,
          paymentTypesForDinein: dineInCheckbox
            ? JSON.stringify(
                dineInCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isDelivery: deliveryCheckbox,
          paymentTypesForDelivery: deliveryCheckbox
            ? JSON.stringify(
                deliveryCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          isCollection: collectionCheckbox,
          paymentTypesForCollection: collectionCheckbox
            ? JSON.stringify(
                collectionCheckboxList
                  .filter((each: any) => each.isChecked)
                  .map((each: any) => each.label)
              )
            : null,
          shopWeekSchedule:
            requiredFormatStoreList && JSON.stringify(requiredFormatStoreList),
          collectionWeekSchedule:
            requiredFormatCollection &&
            JSON.stringify(requiredFormatCollection),
          deliveryWeekSchedule:
            requiredFormatDelivery && JSON.stringify(requiredFormatDelivery),
          dineinWeekSchedule:
            requiredFormatDineList && JSON.stringify(requiredFormatDineList),
        };

        const filteredCategory = Object.fromEntries(
          Object.entries(filteredDataFromNull).filter(
            ([_, value]) => value !== null
          )
        );

        createStore({
          variables: filteredCategory,
        });
      }
    } else {
      if (!errorState) {
        updateBusinessHours();
      }
    }
    setAlertPopupOpen(false);
  };

  const handleDeleteHolidayIcon = () => {
    deleteHoliday({
      variables: {
        holidayId: deleteHolidayId,
      },
    });
    setDeleteHolidayId(null);
    setDeleteHolidayPopupOpen(false);
  };

  useEffect(() => {
    console.log(formikForBusinessAddress, "formikForBusinessAddress");
  }, [formikForBusinessAddress]);

  return (
    <Box sx={{ padding: "1%" }}>
      <DeletePopup
        open={deleteHolidayPopupOpen}
        handleClose={deleteHolidayHandleClose}
        handleOpen={deleteHolidayHandleOpen}
        handleSaveButton={handleDeleteHolidayIcon}
        mainText={t("buttons.remove_holiday")}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="30%"
      />

      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "1%",
          backgroundColor: "white",
          width: "60%",
        }}
      >
        <IconButton
          className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          onClick={handleNavigateTolisting}
        >
          <ArrowBackIcon />
        </IconButton>
        {/* <Typography className="create-product-head">Edit Location</Typography> */}
        <Typography className="create-product-head">
          {editId !== undefined
            ? t("locations.edit_location")
            : t("locations.create_location")}
        </Typography>
      </div>
      <Box sx={{ width: "100%", display: "flex", padding: "1%" }}>
        <Stepper nonLinear orientation="vertical" sx={customStyles}>
          {steps.map((label, index) => (
            <Step key={index} sx={{ width: "100%" }}>
              <Typography
                sx={{ textTransform: "none", width: "250px", margin: "8px" }}
                onClick={() => handleStep(index)}
                className={index === activeStep ? "border" : ""}
              >
                <div style={{ position: "absolute", top: "-9999px" }}>
                  {index}
                </div>
                {index === activeStep ? (
                  <Typography
                    sx={{
                      color: "#F38B08",
                      borderLeft: "3px solid #F38B08",
                      padding: "0px 10px 0px 20px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide any overflow text
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t(`locations.${label}`)}
                  </Typography>
                ) : (
                  <Typography
                    sx={{
                      color: "grey",
                      padding: "0px 10px 0px 20px",
                      whiteSpace: "nowrap", // Prevent text wrapping
                      overflow: "hidden", // Hide any overflow text
                      textOverflow: "ellipsis",
                    }}
                  >
                    {t(`locations.${label}`)}
                  </Typography>
                )}
              </Typography>
            </Step>
          ))}
        </Stepper>

        <div style={{ width: "100%" }}>
          <React.Fragment>
            <Box>
              {activeStep === 0 && (
                <Box
                  sx={{
                    padding: "1%",
                    width: "100%",
                  }}
                >
                  <BasicDetails
                    setConditions={setConditions}
                    updateBasicDetails={updateBasicDetails}
                    formik={formikForBasicdetails}
                    editId={editId}
                  />
                </Box>
              )}

              {activeStep === 1 && (
                <Box
                  style={{
                    padding: "1%",
                    width: "100%",
                  }}
                >
                  <LocationBusinessAddress
                    setConditions={setConditions}
                    formikForBasicdetails={formikForBasicdetails}
                    locationKey={locationKey}
                    setActiveStep={setActiveStep}
                    conditions={conditions}
                    formik={formikForBusinessAddress}
                    editId={editId}
                    handleClearButtonInBusinessAddress={
                      handleClearButtonInBusinessAddress
                    }
                  />
                </Box>
              )}

              {activeStep === 2 && (
                <Box
                  style={{
                    padding: "1%",
                    width: "100%",
                  }}
                >
                  <LocationContactInformation
                    setConditions={setConditions}
                    formikForBusinessAddress={formikForBusinessAddress}
                    setActiveStep={setActiveStep}
                    conditions={conditions}
                    editId={editId}
                    formik={formikForContactImformation}
                  />
                </Box>
              )}
              {activeStep === 3 && (
                <Box
                  style={{
                    padding: "1% 1% 1% 2%",
                    width: "100%",
                  }}
                >
                  <LocationOrderTypeAndPayments
                    setConditions={setConditions}
                    formikForContactImformation={formikForContactImformation}
                    checkboxEmptyalertPopup={checkboxEmptyalertPopup}
                    setCheckBoxEmptysetAlertPopup={
                      setCheckBoxEmptysetAlertPopup
                    }
                    setActiveStep={setActiveStep}
                    conditions={conditions}
                    dineInCheckbox={dineInCheckbox}
                    collectionCheckbox={collectionCheckbox}
                    deliveryCheckbox={deliveryCheckbox}
                    shopCheckbox={shopCheckbox}
                    setDineIncheckbox={setDineIncheckbox}
                    setCollectioncheckbox={setCollectioncheckbox}
                    setDeliverycheckbox={setDeliverycheckbox}
                    setShopcheckbox={setShopcheckbox}
                    dineInCheckboxList={dineInCheckboxList}
                    collectionCheckboxList={collectionCheckboxList}
                    deliveryCheckboxList={deliveryCheckboxList}
                    shopCheckboxList={shopCheckboxList}
                    setDineInCheckboxList={setDineInCheckboxList}
                    setCollectionCheckboxList={setCollectionCheckboxList}
                    setDeliveryCheckboxList={setDeliveryCheckboxList}
                    setShopCheckboxList={setShopCheckboxList}
                    handleOrderPaymentsNextButton={
                      handleOrderPaymentsNextButton
                    }
                    editId={editId}
                  />
                </Box>
              )}
              {activeStep === 4 && (
                <Box
                  style={{
                    padding: "1% 1% 1% 2%",
                    width: "100%",
                  }}
                >
                  <LocationBusinessHours
                    checkboxEmptyalertPopup={checkboxEmptyalertPopup}
                    collectionCheckbox={collectionCheckbox}
                    dineInCheckbox={dineInCheckbox}
                    deliveryCheckbox={deliveryCheckbox}
                    shopCheckbox={shopCheckbox}
                    setCheckBoxEmptysetAlertPopup={
                      setCheckBoxEmptysetAlertPopup
                    }
                    setDrawerState={setDrawerState}
                    updateErrorState={updateErrorState}
                    drawerState={drawerState}
                    handleSaveButtonAfterStoreCreated={
                      handleSaveButtonAfterStoreCreated
                    }
                    handleCreateStoreSaveButtonWithNavigate={
                      handleCreateStoreSaveButtonWithNavigate
                    }
                    setActiveStep={setActiveStep}
                    conditions={conditions}
                    deleteHolidayHandleOpen={deleteHolidayHandleOpen}
                    setDeleteHolidayId={setDeleteHolidayId}
                    holidaysList={holidaysList}
                    dineIn={dineInDaysAndHours}
                    delivery={deliveryDaysAndHours}
                    setComfirmationForClickOnAddHolidayButton={
                      setComfirmationForClickOnAddHolidayButton
                    }
                    store={storeDaysAndHours}
                    collection={collectionDaysAndHours}
                    setCollection={setCollectionDaysAndHours}
                    setDelivery={setDeliveryDaysAndHours}
                    setStore={setStoreDaysAndHours}
                    setDineIn={setDineInDaysAndHours}
                    handleCreateStoreSaveButton={handleCreateStoreSaveButton}
                    createdStoreId={createdStoreId}
                    editId={editId}
                    setAlertPopupOpen={setAlertPopupOpen}
                    alertPopupOpen={alertPopupOpen}
                  />
                </Box>
              )}
            </Box>
          </React.Fragment>
        </div>
      </Box>
    </Box>
  );
};
export default CreateLocationChangedFormat;
