import React, { useEffect, useState } from "react";
import {
  Box,
  Divider,
  IconButton,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { Typography, Paper, Button, Switch, TextField } from "@mui/material";
import { FormikErrors, FormikTouched, useFormik } from "formik";
import * as Yup from "yup";

import { LOYALITY_POINTS_FOR_STORE } from "../../graphicalQl/mutation/locationMutation";
import "./loyalityPoints.css";
import DateRangeForLoyality from "./dateRangeForLoyality";
import dayjs from "dayjs";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import DeleteIcon from "@mui/icons-material/Delete";
import uuid from "react-uuid";
import { GET_LOYALITY_POINTS_DATA } from "../../graphicalQl/usequery/locationQuery";
import DeletePopup from "../popups/deletePopup";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader";
import { useTranslation } from "react-i18next";
interface DateType {
  startDate: string;
  endDate: string;
}

interface Tier {
  tierId: string;
  tierName: string;
  amountRequired: number;
  pointsForPounds: number;
  isShowIcon: boolean;
}
const validationSchemaPart1 = Yup.object({
  vipTiers: Yup.array().of(
    Yup.object().shape({
      tierName: Yup.string().required("Required"),
      amountRequired: Yup.number().required("Required"),
      pointsForPounds: Yup.number().required("Required"),
    })
  ),

  loyaltyExpiryPeriod: Yup.number().when("expiryForLoyalityPoints", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  // loyaltyExpiryPeriod: Yup.lazy((value, context) =>
  //   context.parent.expiryForLoyalityPoints
  //     ? Yup.number().required("Required")
  //     : Yup.mixed().notRequired()
  // ),

  timeAwayDance: Yup.string().when("timeAway", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  timeAwayPoints: Yup.string().when("timeAway", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  signUpPoints: Yup.string().when("signup", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  signUpAnniversaryPoints: Yup.string().when("signUpAnniversary", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),

  birthDayPoints: Yup.string().when("birthday", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  pointsForAccumalationEvery: Yup.number().required("Required"),
  pointsForRedemptionEvery: Yup.number().required("Required"),
  pointsForAccumalationLoyality: Yup.number().required("Required"),
  pointsForRedemptionLoyality: Yup.number().required("Required"),
});

interface State {
  allowLoyalityPoints: boolean;
  expiryForLoyalityPoints: boolean;
  loyaltyExpiryPeriod: string | null;
  pointsForAccumalationEvery: number | null;
  pointsForAccumalationLoyality: number | null;
  pointsForRedemptionEvery: number | null;
  pointsForRedemptionLoyality: number | null;
  vip: boolean;
  vipTiers: any[];
  timeAway: boolean;
  timeAwayDance: number | string;
  timeAwayPoints: number | string;
  signup: boolean;
  signUpPoints: number | string;
  signUpAnniversary: boolean;
  signUpAnniversaryPoints: number | string;
  birthday: boolean;
  expirationValueForLoyality: string;
  birthDayPoints: number | string;
}

const initialValuesOfTextFields: State = {
  allowLoyalityPoints: true,
  expiryForLoyalityPoints: true,
  loyaltyExpiryPeriod: null,
  pointsForAccumalationEvery: null,
  pointsForAccumalationLoyality: null,
  pointsForRedemptionEvery: null,
  pointsForRedemptionLoyality: null,
  vip: true,

  vipTiers: [
    {
      tierId: uuid(),
      tierName: "Sliver",
      amountRequired: 100,
      pointsForPounds: 20,
      isShowIcon: false,
    },
    {
      tierId: uuid(),
      tierName: "Gold",
      amountRequired: 50,
      pointsForPounds: 10,
      isShowIcon: false,
    },
  ],

  timeAway: true,
  timeAwayDance: "",
  timeAwayPoints: "",
  signup: true,
  signUpPoints: "",
  signUpAnniversary: true,
  signUpAnniversaryPoints: "",
  birthday: true,
  birthDayPoints: "",
  expirationValueForLoyality: "1-month",
};

const LoyalityPoints = () => {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const {
    loading: loyalityLoading,
    error: loyalityError,
    data: loyalityData,
  } = useQuery(GET_LOYALITY_POINTS_DATA, {
    context: { clientName: "businessClient" },
    variables: { storeId: +locationId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (loyalityData) {
      const { storeSettings } = loyalityData;
      if (storeSettings) {
        const {
          allowLoyaltyPoints,
          setExpiryForLoyalty,
          loyaltyExpiryPeriod,
          perPoundWhileAccumulation,
          loyaltyPointsWhileAccumulation,
          loyaltyPointsWhileRedumption,
          equalentAmountWhileRedumption,
          allowVipStatus,
          tiers,
          allowTimeAwayPoints,
          timeAwayPoints,
          timeAwayDays,
          allowSignupPoints,
          signupPoints,
          allowBirthdayPoints,
          birthdayPoints,
          allowAnniversaryPoints,
          anniversaryPoints,
        } = storeSettings;

        formik.setFieldValue("loyaltyExpiryPeriod", loyaltyExpiryPeriod);
        allowLoyaltyPoints !== null &&
          formik.setFieldValue("allowLoyalityPoints", allowLoyaltyPoints);
        setExpiryForLoyalty !== null &&
          formik.setFieldValue("expiryForLoyalityPoints", setExpiryForLoyalty);
        allowVipStatus !== null && formik.setFieldValue("vip", allowVipStatus);
        allowTimeAwayPoints !== null &&
          formik.setFieldValue("timeAway", allowTimeAwayPoints);
        allowSignupPoints !== null &&
          formik.setFieldValue("signup", allowSignupPoints);
        allowBirthdayPoints !== null &&
          formik.setFieldValue("birthday", allowBirthdayPoints);
        allowAnniversaryPoints !== null &&
          formik.setFieldValue("signUpAnniversary", allowAnniversaryPoints);
        timeAwayDays && formik.setFieldValue("timeAwayDance", timeAwayDays);
        timeAwayPoints &&
          formik.setFieldValue("timeAwayPoints", timeAwayPoints);
        signupPoints && formik.setFieldValue("signUpPoints", signupPoints);
        birthdayPoints &&
          formik.setFieldValue("birthDayPoints", birthdayPoints);
        anniversaryPoints &&
          formik.setFieldValue("signUpAnniversaryPoints", anniversaryPoints);
        formik.setFieldValue(
          "pointsForAccumalationEvery",
          perPoundWhileAccumulation
        );
        formik.setFieldValue(
          "pointsForAccumalationLoyality",
          loyaltyPointsWhileAccumulation
        );
        formik.setFieldValue(
          "pointsForRedemptionEvery",
          equalentAmountWhileRedumption
        );
        formik.setFieldValue(
          "pointsForRedemptionLoyality",
          loyaltyPointsWhileRedumption
        );
        if (tiers) {
          const parsedData = JSON.parse(tiers);
          const requiredFormat = parsedData.map((each: any) => {
            return {
              tierId: uuid(),
              tierName: each.tier_name,
              amountRequired: each.points_required,
              pointsForPounds: each.points_multiplier,
              isShowIcon: false,
            };
          });
          formik.setFieldValue("vipTiers", requiredFormat);
        } else {
          formik.setFieldValue("vipTiers", []);
        }
      }
    }
  }, [loyalityData]);

  const formik = useFormik({
    initialValues: initialValuesOfTextFields,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleSaveSubmitButton();
    },
  });

  const [updateLoyalityPoints] = useMutation(LOYALITY_POINTS_FOR_STORE, {
    onCompleted: (data) => {},
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [],
  });

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const [dateDropdownPopup, setDateDropdownLabelPopup] =
    useState<boolean>(false);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [deleteId, setDeleteId] = useState<string | null>(null);

  const handleDateDroPdownPopupClose = () => {
    setDateDropdownLabelPopup(false);
  };

  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const handleSaveSubmitButton = () => {
    const requiredTierFormat = formik.values.vipTiers.map((each: any) => {
      return {
        tier_name: each.tierName,
        points_required: each.amountRequired,
        points_multiplier: each.pointsForPounds,
      };
    });

    const loyalityPointsDataWhenAllowLoyalityPoints = {
      storeId: +locationId,
      allowLoyaltyPoints: true,

      setExpiryForLoyalty: formik.values.expiryForLoyalityPoints,
      loyaltyExpiryPeriod: formik.values.expiryForLoyalityPoints
        ? formik.values.loyaltyExpiryPeriod
        : null,

      perPoundWhileAccumulation: formik.values.pointsForAccumalationEvery,
      loyaltyPointsWhileAccumulation:
        formik.values.pointsForAccumalationLoyality,

      loyaltyPointsWhileRedumption: formik.values.pointsForRedemptionLoyality,
      equalentAmountWhileRedumption: formik.values.pointsForRedemptionEvery,

      allowVipStatus: formik.values.vip,
      tiers: formik.values.vip ? JSON.stringify(requiredTierFormat) : null,

      allowTimeAwayPoints: formik.values.timeAway,
      timeAwayPoints: formik.values.timeAway ? formik.values.timeAwayPoints : 0,
      timeAwayDays: formik.values.timeAway ? formik.values.timeAwayDance : 0,

      allowSignupPoints: formik.values.signup,
      signupPoints: formik.values.signup ? formik.values.signUpPoints : 0,

      allowBirthdayPoints: formik.values.birthday,
      birthdayPoints: formik.values.birthday ? formik.values.birthDayPoints : 0,

      allowAnniversaryPoints: formik.values.signUpAnniversary,
      anniversaryPoints: formik.values.signUpAnniversary
        ? formik.values.signUpAnniversaryPoints
        : 0,
    };
    const loyalityPointsDataWhenNotAllowLoyalityPoints = {
      storeId: +locationId,
      allowLoyaltyPoints: false,
      setExpiryForLoyalty: false,
      loyaltyExpiryPeriod: 0,
      perPoundWhileAccumulation: 0,
      loyaltyPointsWhileAccumulation: 0,
      loyaltyPointsWhileRedumption: 0,
      equalentAmountWhileRedumption: 0,
      allowVipStatus: false,
      tiers: null,
      allowTimeAwayPoints: false,
      timeAwayPoints: 0,
      timeAwayDays: 0,
      allowSignupPoints: false,
      signupPoints: 0,
      allowBirthdayPoints: false,
      birthdayPoints: 0,
      allowAnniversaryPoints: false,
      anniversaryPoints: 0,
    };

    updateLoyalityPoints({
      variables: formik.values.allowLoyalityPoints
        ? loyalityPointsDataWhenAllowLoyalityPoints
        : loyalityPointsDataWhenNotAllowLoyalityPoints,
    });
    toast.success(
      <div>
        <Typography>{t("toast.updated")}</Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
  };

  const handleAddNewTierButton = () => {
    const existedTiers = formik.values.vipTiers;
    const updatedTiers = [
      ...existedTiers,
      {
        tierId: uuid(),
        tierName: null,
        amountRequired: null,
        pointsForPounds: null,
        isShowIcon: false,
      },
    ];
    formik.setFieldValue("vipTiers", updatedTiers);
  };

  const handleDeleteThetier = () => {
    const existedTiers = formik.values.vipTiers;
    const updatedTiers = existedTiers.filter(
      (tier) => tier.tierId !== deleteId
    );
    formik.setFieldValue("vipTiers", updatedTiers);
    handleCloseDeletePopup();
  };

  const handleMouseEnter = (id: string) => {
    const existedTiers = formik.values.vipTiers;
    const updatedTiers = existedTiers.map((tier) =>
      tier.tierId === id ? { ...tier, isShowIcon: true } : tier
    );
    formik.setFieldValue("vipTiers", updatedTiers);
  };

  const handleMouseLeave = (id: string) => {
    const existedTiers = formik.values.vipTiers;
    const updatedTiers = existedTiers.map((tier) =>
      tier.tierId === id ? { ...tier, isShowIcon: false } : tier
    );
    formik.setFieldValue("vipTiers", updatedTiers);
  };

  const handleDeleteIcon = (id: string) => {
    setDeleteId(id);
    setDeletePopup(true);
  };

  const handleCloseDeletePopup = () => {
    setDeletePopup(false);
    setDeleteId(null);
  };



  return (
    <Box sx={{ width: "98%" }}>
      <DateRangeForLoyality
        open={dateDropdownPopup}
        handleClose={handleDateDroPdownPopupClose}
      />
      <DeletePopup
        open={deletePopup}
        handleClose={handleCloseDeletePopup}
        handleOpen={() => setDeletePopup(true)}
        handleSaveButton={handleDeleteThetier}
        mainText="Are you sure,you want to delete this Tier"
        button1Text="Yes' Remove"
        button2Text="Don't Remove"
        popUpwidth="45%"
      />
      {loyalityLoading ? (
        <CircularLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Paper elevation={1} sx={{ padding: "2%", margin: "1%" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography className="loyality-points">
                {t("loyalty.loyalty_points")}
              </Typography>
              <Button
                type="submit"
                color="warning"
                sx={{ textTransform: "none" }}
                variant="contained"
              >
                {t("buttons.save")}
              </Button>
            </Box>

            <Box
              sx={{
                marginTop: "1%",
                marginBottom: "1%",
                border: "0.5px solid #eeeeee",
                borderRadius: "5px",
                width: "40%",
                padding: "1%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography className="loyality-points-headers">
                  {t("loyalty.allow_loyalty_points")}{" "}
                </Typography>

                <Switch
                  color="warning"
                  name="allowLoyalityPoints"
                  checked={formik.values.allowLoyalityPoints}
                  onChange={formik.handleChange}
                />
              </Box>
              <Typography className="loyality-points-description">
                {t("loyalty.enable_this_toggle")}
              </Typography>
            </Box>
            <Divider sx={{ backgroundColor: " #D9D9D9" }} />
            {formik.values.allowLoyalityPoints && (
              <>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      marginTop: "1%",
                      marginBottom: "1%",
                      border: "0.5px solid #eeeeee",
                      borderRadius: "5px",
                      width: formik.values.expiryForLoyalityPoints
                        ? "60%"
                        : "40%",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      padding: "1.3%",
                      display: "flex",
                    }}
                  >
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="loyality-points-headers">
                          {t("loyalty.set_expiry")}
                        </Typography>

                        <Switch
                          onChange={formik.handleChange}
                          name="expiryForLoyalityPoints"
                          checked={formik.values.expiryForLoyalityPoints}
                          color="warning"
                        />
                      </Box>
                      <Typography className="loyality-points-description">
                        {t("loyalty.set_expiry_enable_this_toggle")}
                      </Typography>
                    </Box>
                    {formik.values.expiryForLoyalityPoints && (
                      <Box sx={{ display: "flex", flexDirection: "column" }}>
                        <TextField
                          label={t("loyalty.enter_days")}
                          id="filled-start-adornment"
                          onChange={(e) => {
                            const val = e.target.value;
                            if (
                              !val ||
                              (/^[0-9]+$/.test(val) &&
                                e.target.value.length <= 4)
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          type="text"
                          onBlur={(e) => formik.handleBlur(e)}
                          value={formik.values.loyaltyExpiryPeriod || ""}
                          name="loyaltyExpiryPeriod"
                          className="hide-number-input-icons"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {t("loyalty.days")}
                              </InputAdornment>
                            ),
                          }}
                          variant="outlined"
                        />
                        {formik.touched.loyaltyExpiryPeriod &&
                          formik.errors.loyaltyExpiryPeriod && (
                            <Box>
                              <Typography className="create-a-user-formik-label">
                                {t("error.required")}
                              </Typography>
                            </Box>
                          )}
                      </Box>
                    )}
                  </Box>
                </Box>
                <Divider sx={{ backgroundColor: " #eeeeee" }} />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "70%",
                    padding: "1%",
                  }}
                >
                  <Typography className="loyality-points-headers">
                    {t("loyalty.points_accumulation")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "70%",
                      padding: "1%",
                    }}
                  >
                    <Typography
                      sx={{ marginTop: "2%" }}
                      className="loyality-points-every"
                    >
                      {t("loyalty.every")}
                    </Typography>
                    <Box sx={{ width: "20%" }}>
                      <TextField
                        label={t("loyalty.amount")}
                        onBlur={formik.handleBlur}
                        color="warning"
                        sx={{ width: "100%" }}
                        name="pointsForAccumalationEvery"
                        value={formik.values.pointsForAccumalationEvery || ""}
                        className="hide-number-input-icons"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            !val ||
                            (/^[0-9]+$/.test(val) && e.target.value.length <= 3)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                      />
                      {formik.touched.pointsForAccumalationEvery &&
                        formik.errors.pointsForAccumalationEvery && (
                          <Box>
                            <Typography className="create-a-user-formik-label">
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                    <Typography
                      sx={{ marginTop: "2%" }}
                      className="loyality-points-every"
                    >
                      {t("loyalty.spent_is_equal_to")}
                    </Typography>
                    <Box sx={{ width: "20%" }}>
                      <TextField
                        label={t("loyalty.points")}
                        variant="outlined"
                        defaultValue=""
                        name="pointsForAccumalationLoyality"
                        value={
                          formik.values.pointsForAccumalationLoyality || ""
                        }
                        className="hide-number-input-icons"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            !val ||
                            (/^[0-9]+$/.test(val) && e.target.value.length <= 5)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                      {formik.touched.pointsForAccumalationLoyality &&
                        formik.errors.pointsForAccumalationLoyality && (
                          <Box>
                            <Typography className="create-a-user-formik-label">
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                    <Typography
                      sx={{ marginTop: "2%" }}
                      className="loyality-points-every"
                    >
                      {t("loyalty.loyality_point")}
                    </Typography>
                  </Box>
                </Box>

                <Divider sx={{ backgroundColor: " #eeeeee" }} />

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    flexDirection: "column",
                    width: "70%",
                    padding: "1%",
                  }}
                >
                  <Typography className="loyality-points-headers">
                    {t("loyalty.points_redemption")}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "flex-start",
                      width: "70%",
                      padding: "1%",
                    }}
                  >
                    <Typography
                      sx={{ marginTop: "2%" }}
                      className="loyality-points-every"
                    >
                      {t("loyalty.every")}
                    </Typography>
                    <Box sx={{ width: "20%" }}>
                      <TextField
                        label={t("loyalty.points")}
                        variant="outlined"
                        name="pointsForRedemptionEvery"
                        className="hide-number-input-icons"
                        onBlur={formik.handleBlur}
                        value={formik.values.pointsForRedemptionEvery || ""}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            !val ||
                            (/^[0-9]+$/.test(val) && e.target.value.length <= 5)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        sx={{ width: "100%" }}
                      />
                      {formik.touched.pointsForRedemptionEvery &&
                        formik.errors.pointsForRedemptionEvery && (
                          <Box>
                            <Typography className="create-a-user-formik-label">
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>

                    <Typography
                      sx={{ marginTop: "2%" }}
                      className="loyality-points-every"
                    >
                      {t("loyalty.loyalty_point_earned")}
                    </Typography>
                    <Box sx={{ width: "20%" }}>
                      <TextField
                        label={t("loyalty.amount")}
                        color="warning"
                        onBlur={formik.handleBlur}
                        sx={{ width: "100%" }}
                        name="pointsForRedemptionLoyality"
                        value={formik.values.pointsForRedemptionLoyality || ""}
                        className="hide-number-input-icons"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              {currencySymbol}
                            </InputAdornment>
                          ),
                        }}
                        onChange={(e) => {
                          const val = e.target.value;
                          if (
                            !val ||
                            (/^[0-9]+$/.test(val) && e.target.value.length <= 3)
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                      />
                      {formik.touched.pointsForRedemptionLoyality &&
                        formik.errors.pointsForRedemptionLoyality && (
                          <Box>
                            <Typography className="create-a-user-formik-label">
                              {t("error.required")}
                            </Typography>
                          </Box>
                        )}
                    </Box>
                  </Box>
                </Box>
                <Divider sx={{ backgroundColor: " #eeeeee" }} />

                <Box
                  sx={{
                    marginTop: "1%",
                    marginBottom: "1%",
                    border: "0.5px solid #eeeeee",
                    borderRadius: "5px",
                    width: "40%",
                    padding: "1%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography className="loyality-points-headers">
                      {t("loyalty.vip")}
                    </Typography>

                    <Switch
                      name="vip"
                      color="warning"
                      checked={formik.values.vip}
                      onChange={formik.handleChange}
                    />
                  </Box>
                  <Typography className="loyality-points-description">
                    {t("loyalty.every_1_loyalty")}
                  </Typography>
                </Box>

                {formik.values.vip && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "20px",
                        width: "95%",
                        overflowX: "auto",
                        "&::-webkit-scrollbar": {
                          display: "none",
                        },
                      }}
                    >
                      {formik.values.vipTiers.map(
                        (each: any, index: number) => (
                          <Box
                            onMouseEnter={() => handleMouseEnter(each.tierId)}
                            onMouseLeave={() => handleMouseLeave(each.tierId)}
                            sx={{
                              display: "flex",
                              backgroundColor: "#fcfcfc",
                              flexDirection: "column",
                              border: "0.5px solid #eeeeee",
                              padding: "45px 14px 6px 14px",
                              height: "auto",
                              borderRadius: "8px",
                              marginRight: "15px",
                              position: "relative",
                            }}
                          >
                            <Box
                              sx={{
                                height: "50px",
                                position: "absolute",
                                top: "0",
                                right: "0",
                              }}
                            >
                              {each.isShowIcon &&
                                formik.values.vipTiers.length > 1 && (
                                  <Box sx={{ textAlign: "right" }}>
                                    <Tooltip
                                      title={t("buttons.delete")}
                                      placement="top"
                                      arrow
                                    >
                                      <IconButton
                                        onClick={() =>
                                          handleDeleteIcon(each.tierId)
                                        }
                                      >
                                        <DeleteIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Box>
                                )}
                            </Box>

                            <Box
                              sx={{ display: "flex", flexDirection: "column" }}
                            >
                              <Box sx={{ marginBottom: "10px" }}>
                                <TextField
                                  name={`vipTiers[${index}].tierName`}
                                  variant="outlined"
                                  label={t("loyalty.tier_name")}
                                  onBlur={formik.handleBlur}
                                  onChange={formik.handleChange}
                                  value={each.tierName || ""}
                                  sx={{ width: "260px" }}
                                />

                                {formik.touched.vipTiers &&
                                  (
                                    formik.touched.vipTiers as FormikTouched<
                                      Tier[]
                                    >
                                  )[index] &&
                                  (
                                    formik.touched.vipTiers as FormikTouched<
                                      Tier[]
                                    >
                                  )[index]?.tierName &&
                                  formik.errors.vipTiers &&
                                  formik.errors.vipTiers[index] &&
                                  (
                                    formik.errors.vipTiers as FormikErrors<
                                      Tier[]
                                    >
                                  )[index]?.tierName && (
                                    <div
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {(
                                        formik.errors.vipTiers as FormikErrors<
                                          Tier[]
                                        >
                                      )[index]
                                        ? t("error.required")
                                        : ""}
                                    </div>
                                  )}
                              </Box>

                              <Box sx={{ marginBottom: "10px" }}>
                                <TextField
                                  name={`vipTiers[${index}].amountRequired`}
                                  variant="outlined"
                                  value={each.amountRequired || ""}
                                  label={t("loyalty.amount_required")}
                                  onBlur={formik.handleBlur}
                                  onChange={(e) => {
                                    const val = e.target.value;
                                    if (
                                      !val ||
                                      (/^[0-9]+$/.test(val) &&
                                        e.target.value.length <= 3)
                                    ) {
                                      formik.handleChange(e);
                                    }
                                  }}
                                  type="text"
                                  sx={{ width: "260px" }}
                                />
                                {formik.touched.vipTiers &&
                                  (
                                    formik.touched.vipTiers as FormikTouched<
                                      Tier[]
                                    >
                                  )[index] &&
                                  (
                                    formik.touched.vipTiers as FormikTouched<
                                      Tier[]
                                    >
                                  )[index]?.amountRequired &&
                                  formik.errors.vipTiers &&
                                  formik.errors.vipTiers[index] &&
                                  (
                                    formik.errors.vipTiers as FormikErrors<
                                      Tier[]
                                    >
                                  )[index]?.amountRequired && (
                                    <div
                                      style={{ color: "red", fontSize: "12px" }}
                                    >
                                      {(
                                        formik.errors.vipTiers as FormikErrors<
                                          Tier[]
                                        >
                                      )[index]
                                        ? t("error.required")
                                        : ""}
                                    </div>
                                  )}
                              </Box>

                              <TextField
                                variant="outlined"
                                value={each.pointsForPounds || ""}
                                name={`vipTiers[${index}].pointsForPounds`}
                                label={t("loyalty.points_per_pound_multiplier")}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (
                                    !val ||
                                    (/^[0-9]+$/.test(val) &&
                                      e.target.value.length <= 3)
                                  ) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                                onBlur={formik.handleBlur}
                                sx={{ width: "260px" }}
                              />
                              {formik.touched.vipTiers &&
                                (
                                  formik.touched.vipTiers as FormikTouched<
                                    Tier[]
                                  >
                                )[index] &&
                                (
                                  formik.touched.vipTiers as FormikTouched<
                                    Tier[]
                                  >
                                )[index]?.pointsForPounds &&
                                formik.errors.vipTiers &&
                                formik.errors.vipTiers[index] &&
                                (
                                  formik.errors.vipTiers as FormikErrors<Tier[]>
                                )[index]?.pointsForPounds && (
                                  <div
                                    style={{ color: "red", fontSize: "12px" }}
                                  >
                                    {(
                                      formik.errors.vipTiers as FormikErrors<
                                        Tier[]
                                      >
                                    )[index]
                                      ? t("error.required")
                                      : ""}
                                  </div>
                                )}
                            </Box>
                          </Box>
                        )
                      )}
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        marginBottom: "1%",
                        marginTop: "1%",
                        textAlign: "center",
                      }}
                    >
                      <Button
                        className="loyality-points-add-button"
                        color="warning"
                        sx={{
                          width: "95%",
                          padding: "10px 32px",
                          gap: "10px",
                          borderRadius: "4px",
                          opacity: 1,
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontWeight: 600,
                          lineHeight: "21px",
                          textAlign: "left",
                          backgroundColor: "#F5F5F5",
                          color: "#F6A846",
                          textTransform: "none",
                        }}
                        onClick={handleAddNewTierButton}
                      >
                        {t("loyalty.add_new_tier")}
                      </Button>
                    </Box>

                    <Divider sx={{ backgroundColor: " #eeeeee" }} />
                  </>
                )}

                <Typography
                  className="loyality-points-headers"
                  sx={{ marginTop: "1%", marginBottom: "1%" }}
                >
                  {t("loyalty.customer_loyalty")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "46%",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "1%",
                        border: "0.5px solid #eeeeee",
                        borderRadius: "5px",
                        width: "100%",
                        padding: "2%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="loyality-points-headers">
                          {t("loyalty.time_away")}
                        </Typography>
                        <Switch
                          name="timeAway"
                          onChange={formik.handleChange}
                          color="warning"
                          checked={formik.values.timeAway}
                        />
                      </Box>
                      <Typography className="loyality-points-description">
                        {t("loyalty.time_away_enable_this_toggle")}
                      </Typography>
                    </Box>

                    {formik.values.timeAway && (
                      <Box>
                        <Box sx={{ marginBottom: "10px" }}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "flex-start",
                              width: "100%",
                              marginTop: "1%",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "flex-start",
                                flexDirection: "column",
                              }}
                            >
                              <Typography
                                sx={{ marginBottom: "1%" }}
                                className="loyality-points-headers"
                              >
                                {t("loyalty.days_since")}
                              </Typography>
                              <Typography className="loyality-points-description">
                                {t("loyalty.enter_number")}
                              </Typography>
                            </Box>

                            <Box>
                              <TextField
                                label={t("loyalty.days")}
                                name="timeAwayDance"
                                variant="outlined"
                                className="hide-number-input-icons"
                                onBlur={formik.handleBlur}
                                value={formik.values.timeAwayDance || ""}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (
                                    !val ||
                                    (/^[0-9]+$/.test(val) &&
                                      e.target.value.length <= 4)
                                  ) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                                sx={{ width: "60%" }}
                              />
                              {formik.touched.timeAwayDance &&
                                formik.errors.timeAwayDance && (
                                  <Box>
                                    <Typography className="create-a-user-formik-label">
                                      {t("error.required")}
                                    </Typography>
                                  </Box>
                                )}
                            </Box>
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            marginBottom: "10px",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "100%",
                            marginTop: "1%",
                          }}
                        >
                          <Typography
                            sx={{ marginTop: "2%" }}
                            className="loyality-points-headers"
                          >
                            {t("loyalty.points")}
                          </Typography>
                          <Box>
                            <TextField
                              name="timeAwayPoints"
                              label={t("loyalty.points")}
                              className="hide-number-input-icons"
                              onBlur={formik.handleBlur}
                              variant="outlined"
                              value={formik.values.timeAwayPoints || ""}
                              onChange={(e) => {
                                const val = e.target.value;
                                if (
                                  !val ||
                                  (/^[0-9]+$/.test(val) &&
                                    e.target.value.length <= 5)
                                ) {
                                  formik.handleChange(e);
                                }
                              }}
                              type="text"
                              sx={{ width: "60%" }}
                            />
                            {formik.touched.timeAwayPoints &&
                              formik.errors.timeAwayPoints && (
                                <Box>
                                  <Typography className="create-a-user-formik-label">
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "1%",
                        border: "0.5px solid #eeeeee",
                        borderRadius: "5px",
                        width: "90%",
                        padding: "2%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="loyality-points-headers">
                          {t("loyalty.sign_up")}
                        </Typography>
                        <Switch
                          name="signup"
                          color="warning"
                          checked={formik.values.signup}
                          onChange={formik.handleChange}
                        />
                      </Box>
                      <Typography className="loyality-points-description">
                        {t("loyalty.sign_up_enable_this_toggle")}
                      </Typography>
                    </Box>
                    {formik.values.signup && (
                      <Box
                        sx={{
                          marginBottom: "1%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: "90%",
                          marginTop: "1%",
                        }}
                      >
                        <Typography
                          sx={{ marginTop: "2%" }}
                          className="loyality-points-headers"
                        >
                          {t("loyalty.points")}
                        </Typography>
                        <Box>
                          <TextField
                            name="signUpPoints"
                            label={t("loyalty.points")}
                            className="hide-number-input-icons"
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            value={formik.values.signUpPoints || ""}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                !val ||
                                (/^[0-9]+$/.test(val) &&
                                  e.target.value.length <= 5)
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            sx={{ width: "60%" }}
                          />
                          {formik.touched.signUpPoints &&
                            formik.errors.signUpPoints && (
                              <Box>
                                <Typography className="create-a-user-formik-label">
                                  {t("error.required")}
                                </Typography>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>

                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "46%",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "1%",
                        border: "0.5px solid #eeeeee",
                        borderRadius: "5px",
                        width: "100%",
                        padding: "1.8%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="loyality-points-headers">
                          {t("loyalty.sign_up_anniversary")}
                        </Typography>
                        <Switch
                          onChange={formik.handleChange}
                          name="signUpAnniversary"
                          color="warning"
                          checked={formik.values.signUpAnniversary}
                        />
                      </Box>
                      <Typography className="loyality-points-description">
                        {t("loyalty.sign_up_anniversary_enable_this_toggle")}
                      </Typography>
                    </Box>
                    <Box>
                      {formik.values.signUpAnniversary && (
                        <Box
                          sx={{
                            marginBottom: "1%",
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "flex-start",
                            width: "100%",
                            marginTop: "1%",
                          }}
                        >
                          <Typography
                            sx={{ marginTop: "2%" }}
                            className="loyality-points-headers"
                          >
                            {t("loyalty.points")}
                          </Typography>
                          <Box>
                            <TextField
                              name="signUpAnniversaryPoints"
                              variant="outlined"
                              label={t("loyalty.points")}
                              className="hide-number-input-icons"
                              onBlur={formik.handleBlur}
                              value={
                                formik.values.signUpAnniversaryPoints || ""
                              }
                              onChange={(e) => {
                                const val = e.target.value;
                                if (
                                  !val ||
                                  (/^[0-9]+$/.test(val) &&
                                    e.target.value.length <= 5)
                                ) {
                                  formik.handleChange(e);
                                }
                              }}
                              type="text"
                              sx={{ width: "60%" }}
                            />
                            {formik.touched.signUpAnniversaryPoints &&
                              formik.errors.signUpAnniversaryPoints && (
                                <Box>
                                  <Typography className="create-a-user-formik-label">
                                    {t("error.required")}
                                  </Typography>
                                </Box>
                              )}
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "45%",
                    }}
                  >
                    <Box
                      sx={{
                        marginBottom: "1%",
                        border: "0.5px solid #eeeeee",
                        borderRadius: "5px",
                        width: "90%",
                        padding: "2%",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography className="loyality-points-headers">
                          {t("loyalty.birthday")}
                        </Typography>
                        <Switch
                          name="birthday"
                          onChange={formik.handleChange}
                          color="warning"
                          checked={formik.values.birthday}
                        />
                      </Box>
                      <Typography className="loyality-points-description">
                        {t("loyalty.enable_this_toggle")}
                      </Typography>
                    </Box>

                    {formik.values.birthday && (
                      <Box
                        sx={{
                          marginBottom: "1%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "flex-start",
                          width: "90%",
                          marginTop: "1%",
                        }}
                      >
                        <Typography
                          sx={{ marginTop: "2%" }}
                          className="loyality-points-headers"
                        >
                          {t("loyalty.points")}
                        </Typography>
                        <Box>
                          <TextField
                            name="birthDayPoints"
                            label={t("loyalty.points")}
                            className="hide-number-input-icons"
                            onBlur={formik.handleBlur}
                            variant="outlined"
                            value={formik.values.birthDayPoints || ""}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                !val ||
                                (/^[0-9]+$/.test(val) &&
                                  e.target.value.length <= 5)
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            sx={{ width: "60%" }}
                          />
                          {formik.touched.birthDayPoints &&
                            formik.errors.birthDayPoints && (
                              <Box>
                                <Typography className="create-a-user-formik-label">
                                  {t("error.required")}
                                </Typography>
                              </Box>
                            )}
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </>
            )}
          </Paper>
        </form>
      )}
    </Box>
  );
};

export default LoyalityPoints;
