import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  List,
  Button,
  Link,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
} from "@mui/material";
import Logo from "../../../../assets/navBar/admin.png";
// import AdminLogo from "../../../../assets/adminLogo.png";
import styled from "styled-components";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DropdownIcon from "../../../../assets/dropdownIcon.svg";
import LanguageFlag from "../../../../assets/LanguageFlag.webp";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PlayForWorkIcon from "@mui/icons-material/PlayForWork";
import TypeSearch from "./SearchInput";
import "react-tooltip/dist/react-tooltip.css";
// import AccordionContent from "./sideBarContent";
import NotificationModal from "../../../mainDashboard/notifications";
import { useNavigate } from "react-router";
import i18n from "i18next";
import { useLocation, Link as RouterLink } from "react-router-dom";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";
import { useTranslation } from "react-i18next";
import StarIcon from "@mui/icons-material/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CategoryIcon from "@mui/icons-material/Category";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CreateIcon from "@mui/icons-material/Create";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Dashboard } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import CopyrightIcon from "@mui/icons-material/Copyright";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PaidIcon from "@mui/icons-material/Paid";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";

interface SideBarProps {
  setSideBar: React.Dispatch<React.SetStateAction<boolean>>;
  isOpenSideBar: boolean;
  setToolTipId: any;
  setNotificationsOpen: (open: boolean) => void;
  notificationOpen: boolean;
}
interface SideBarWrapperProps {
  isOpenSideBar: boolean;
}
// const SideBarWrapper = styled("div")<SideBarWrapperProps>({
//   width: `${(props: SideBarWrapperProps) => props.isOpenSideBar ? '250px' : '92px'}`,
//   marginLeft: "auto",
//   marginRight: "auto",
//   display: "flex",
//   alignItems: "center",
//   flexDirection: "column",
//   backgroundColor: "#fff",
//   height: "100%",
//   zIndex: 99,
//   position: "fixed",
//   boxShadow: "5px 0 3px -3px #00000030",
//   '@media (max-width: 600px)': {
//     width: `${(props: any) => props.isOpenSideBar ? '250px' : '0px'}`, // Set width to 0px on extra-small screens
//   },

//   ".MuiAccordionSummary-content": {
//     margin: "0px !important",
//   },
//   ".sideBarOpenSummary .css-o4b71y-MuiAccordionSummary-content": {
//     justifyContent: "center !important",
//   },
//   ".css-mhqshq-MuiPaper-root-MuiAccordion-root": {
//     "&::before": {
//       display: "none",
//     },
//   },
//   ".sideBarSummary svg ": {
//     height: "18px",
//     width: "18px",
//     color: "#455560",
//   },
//   ".MuiAccordion-region svg ": {
//     height: "18px",
//     width: "18px",
//     color: "#455560",
//   },
//   ".css-mhqshq-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
//     margin: "0px",
//   },
// });

const SideBar: React.FC<SideBarProps> = ({
  setSideBar,
  isOpenSideBar,
  setToolTipId,
  setNotificationsOpen,
  notificationOpen,
}) => {
  const SideBarWrapper = styled("div")({
    width: `${isOpenSideBar ? "250px" : "92px"}`,
    marginLeft: "auto",
    marginRight: "auto",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "#fff",
    height: "100%",
    position: "fixed",
    zIndex: 99,
    boxShadow: "5px 0 3px -3px #00000030",
    "@media (max-width: 600px)": {
      width: isOpenSideBar ? "250px" : "0px", // Set width to 0px on extra-small screens
    },

    ".MuiAccordionSummary-content": {
      margin: "0px !important",
    },
    ".sideBarOpenSummary .css-o4b71y-MuiAccordionSummary-content": {
      justifyContent: "center !important",
    },
    ".css-mhqshq-MuiPaper-root-MuiAccordion-root": {
      "&::before": {
        display: "none",
      },
    },
    ".sideBarSummary svg ": {
      height: "18px",
      width: "18px",
      color: "#455560",
    },
    ".MuiAccordion-region svg ": {
      height: "18px",
      width: "18px",
      color: "#455560",
    },
    ".css-mhqshq-MuiPaper-root-MuiAccordion-root.Mui-expanded": {
      margin: "0px",
    },
  });

  const { t } = useTranslation();

  const [AccordionContent, setAccordionContent] = useState<any[]>([
    {
      id: "1",
      status: "withoutPannel",
      icon: <Dashboard />,
      title: t("sidebar.dashboard"),
      url: "/",
    },
    {
      id: "panel2",
      icon: <MenuBookOutlinedIcon />,
      title: t("sidebar.menu"),

      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.menu_list"),
          url: "menu/menu-list",
          name: "menu",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.menu_setup"),
          url: "/menuSetup/menu-setup",
          name: "menuSetup",
        },
      ],
    },
    {
      id: "panel3",
      icon: <FastfoodOutlinedIcon />,
      title: t("sidebar.products"),
      items: [
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.products"),
          url: "/product/product-list",
          name: "product",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.categories"),
          url: "/category/category-listing",
          name: "category",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.options"),
          url: "/option/options-list",
          name: "option",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.bundles"),
          url: "bundle/bundle-listing",
          name: "bundle",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.bulk_products"),
          url: "/bulk/product",
          name: "bulk",
        },

        {
          icon: <StarBorderIcon />,
          text: t("sidebar.suggestive_sellings"),
          url: "/shopReviews",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.reporting_group"),
          url: "report/report-group-listing",
          name: "report",
        },
        {
          icon: <StarBorderIcon />,
          text: "Specials",
          url: "special/specials-list",
          name: "special",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.courses"),
          url: "course/courses-listing",
          name: "course",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.gift_cards"),
          url: "/shopReviews",
        },
      ],
    },

    {
      id: "panel4",
      icon: <LocalOfferOutlinedIcon />,
      title: t("sidebar.discounts"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.self_made_discounts"),
          url: "/discount/discount-list",
          name: "discount",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.pre_made_discounts"),
          url: "discounts/preMade-list",
          name: "discounts",
        },
        {
          icon: <AccessTimeIcon />,
          text: t("sidebar.discounts_reason"),
          url: "/reasons/discount-reasons",
          name: "reasons",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: "Epos Discount",
          url: "/epos/eposdiscount",
          name: "epos",
        },
      ],
    },

    {
      id: "panel5",
      icon: <DevicesOutlinedIcon />,
      title: t("sidebar.devices"),

      items: [
        {
          icon: <CategoryIcon />,
          text: t("sidebar.devices_setup"),
          url: "/device/device-setup",
          name: "device",
        },
        {
          icon: <EqualizerIcon />,
          text: t("sidebar.devices_listing"),
          url: "devices/device-list",
          name: "devices",
        },
        {
          icon: <EqualizerIcon />,
          text: t("online_ordering.online_ordering"),
          url: "/online/doamins-listing",
          name: "online",
        },
      ],
    },
    {
      id: "panel6",
      icon: <DeliveryDiningOutlinedIcon />,
      title: t("sidebar.deliveries"),
      items: [
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.delivery_list"),
          url: "/delivery/delivery-list",
          name: "delivery",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.driver_list"),
          url: "drivers/driver-list",
          name: "drivers",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.petty_cash"),
          url: "/petty/petty-cash",
          name: "petty",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.driver_commission"),
          url: "/driver/driver-commission",
          name: "driver",
        },
      ],
    },

    {
      id: "panel7",
      icon: <AddShoppingCartOutlinedIcon />,
      title: t("sidebar.inventory"),
      items: [
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_listing"),
          url: "/inventory/inventory-list",
          name: "inventory",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_history"),
          url: "/history/inventory-history",
          name: "history",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_reasons"),
          url: "reasons/inventory-reasons",
          name: "reasons",
        },
      ],
    },

    {
      id: "panel8",
      icon: <PeopleAltOutlinedIcon />,
      title: t("sidebar.customers"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.customers"),
          url: "customer/customer-list",
          name: "customer",
        },
      ],
    },

    {
      id: "panel9",
      icon: <ReceiptOutlinedIcon />,
      title: t("sidebar.orders"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.orders"),
          url: "/orders/orders-list",
          name: "orders",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_status"),
          url: "/status/order-status",
          name: "status",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_numbering"),
          url: "/numbering/order-numbering",
          name: "numbering",
        },
      ],
    },

    {
      id: "panel10",
      icon: <AssignmentIndOutlinedIcon />,
      title: t("sidebar.users"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_role"),
          url: "roles/user-roles",
          name: "roles",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_list"),
          url: "users/users-list",
          name: "users",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_access"),
          url: "access/user-access",
          name: "access",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_requests"),
          url: "requests/user-requests",
          name: "requests",
        },
      ],
    },

    {
      id: "panel11",
      icon: <AssessmentOutlinedIcon />,
      title: t("sidebar.reports"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_reports"),
          url: "/order/reports-orders",
          name: "order",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.sales_reports"),
          url: "/sale/reports-sales",
          name: "sale",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.discounts_reports"),
          url: "/discountReport/reports-discount",
          name: "discountReport",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.customer_reports"),
          url: "/customers/reports-customers",
          name: "customers",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.product_reports"),
          url: "/products/reports/products",
          name: "products",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.category_reports"),
          url: "/categories/reports-categories",
          name: "categories",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.payment_reports"),
          url: "/payments/reports-payments",
          name: "payments",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.store_reports"),
          url: "/stores/reports-stores",
          name: "stores",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.delivery_reports"),
          url: "/delivery/reports-delivery",
          name: "delivery",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.tax_reports"),
          url: "/tax/reports-tax",
          name: "tax",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.receipts_reports"),
          url: "/receipts/reports-receipts",
          name: "receipts",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.shifts_reports"),
          url: "",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.tips_reports"),
          url: "/employee/reports-employee",
          name: "employee",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_log_activity"),
          url: "/activityLog/reports/activityLog",
          name: "activityLog",
        },
      ],
    },

    {
      id: "panel12",
      icon: <AdminPanelSettingsOutlinedIcon />,
      title: t("sidebar.account_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.signin_security"),
          url: "/safety/signIn&&Security",
          name: "safety",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.prefereneces"),
          url: "/preferences",
          name: "preferences",
        },
        // { icon: <FlashOnIcon />, text: "Pickup orders" },
        // { icon: <AccessTimeIcon />, text: "Scheduled orders" },
        // { icon: <StarIcon />, text: "Reviews" },
        // { icon: <RestorePageIcon />, text: "Refunds" },
      ],
    },
    {
      id: "panel13",
      icon: <AddBusinessOutlinedIcon />,
      title: t("sidebar.business_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.about_my_business"),
          url: "/businessAccount",
          name: "businessAccount",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.locations_list"),
          url: "/location/locations-list",
          name: "location",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.sales_tax"),
          url: "sales/tax",
          name: "sales",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.service_charge"),
          url: "/service/charge",
          name: "service",
        },

        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.receipts"),
          url: "/reciept/reciept-list",
          name: "reciept",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.rounding"),
          url: "/rounding",
          name: "rounding",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.refunds_cancel"),
          url: "/refund/cancel",
          name: "refund",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.advance_bookings"),
          url: "/booking/advance-booking",
          name: "booking",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.kitchen_settings"),
          url: "/kitchen/kitchen-settings",
          name: "kitchen",
        },
      ],
    },
    {
      id: "panel14",
      icon: <SettingsOutlinedIcon />,
      title: t("sidebar.general_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.image_library"),
          url: "image/image-library",
          name: "image",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.api_keys"),
          url: "apiKeys/api-keys",
          name: "apiKeys",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.feature_settings"),
          url: "/featureSettings",
          name: "featureSettings",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.manage_tips"),
          url: "manage/manage-tips",
          name: "manage",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.managing_orders"),
          url: "managingOrder/manage-orders",
          name: "managingOrder",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.open_items"),
          url: "/open/items",
          name: "open",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.loyality"),
          url: "/loyality",
          name: "loyality",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.reviews_ratings"),
          url: "/reviews/ratings",
          name: "reviews",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.caller_id"),
          url: "/callerId",
          name: "callerId",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.log_time_entries"),
          url: "log/log-time-entries",
          name: "log",
        },
      ],
    },
  ]);

  useEffect(() => {
    const updatedAccordionContent = [
      {
        id: "1",
        status: "withoutPannel",
        icon: <Dashboard />,
        title: t("sidebar.dashboard"),
        url: "/",
      },
      {
        id: "panel2",
        icon: <MenuBookOutlinedIcon />,
        title: t("sidebar.menu"),

        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.menu_list"),
            url: "menu/menu-list",
            name: "menu",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.menu_setup"),
            url: "/menuSetup/menu-setup",
            name: "menuSetup",
          },
        ],
      },
      {
        id: "panel3",
        icon: <FastfoodOutlinedIcon />,
        title: t("sidebar.products"),
        items: [
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.products"),
            url: "/product/product-list",
            name: "product",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.categories"),
            url: "/category/category-listing",
            name: "category",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.options"),
            url: "/option/options-list",
            name: "option",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.bundles"),
            url: "bundle/bundle-listing",
            name: "bundle",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.bulk_products"),
            url: "/bulk/product",
            name: "bulk",
          },

          {
            icon: <StarBorderIcon />,
            text: t("sidebar.suggestive_sellings"),
            url: "/shopReviews",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.reporting_group"),
            url: "report/report-group-listing",
            name: "report",
          },
          {
            icon: <StarBorderIcon />,
            text: "Specials",
            url: "special/specials-list",
            name: "special",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.courses"),
            url: "course/courses-listing",
            name: "course",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.gift_cards"),
            url: "/shopReviews",
          },
        ],
      },

      {
        id: "panel4",
        icon: <LocalOfferOutlinedIcon />,
        title: t("sidebar.discounts"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.self_made_discounts"),
            url: "/discount/discount-list",
            name: "discount",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.pre_made_discounts"),
            url: "discounts/preMade-list",
            name: "discounts",
          },
          {
            icon: <AccessTimeIcon />,
            text: t("sidebar.discounts_reason"),
            url: "/reasons/discount-reasons",
            name: "reasons",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: "Epos Discount",
            url: "/epos/eposdiscount",
            name: "epos",
          },
        ],
      },

      {
        id: "panel5",
        icon: <DevicesOutlinedIcon />,
        title: t("sidebar.devices"),

        items: [
          {
            icon: <CategoryIcon />,
            text: t("sidebar.devices_setup"),
            url: "/device/device-setup",
            name: "device",
          },
          {
            icon: <EqualizerIcon />,
            text: t("sidebar.devices_listing"),
            url: "devices/device-list",
            name: "devices",
          },
          {
            icon: <EqualizerIcon />,
            text: t("online_ordering.online_ordering"),
            url: "/online/doamins-listing",
            name: "online",
          },
        ],
      },
      {
        id: "panel6",
        icon: <DeliveryDiningOutlinedIcon />,
        title: t("sidebar.deliveries"),
        items: [
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.delivery_list"),
            url: "/delivery/delivery-list",
            name: "delivery",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.driver_list"),
            url: "drivers/driver-list",
            name: "drivers",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.petty_cash"),
            url: "/petty/petty-cash",
            name: "petty",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.driver_commission"),
            url: "/driver/driver-commission",
            name: "driver",
          },
        ],
      },

      {
        id: "panel7",
        icon: <AddShoppingCartOutlinedIcon />,
        title: t("sidebar.inventory"),
        items: [
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_listing"),
            url: "/inventory/inventory-list",
            name: "inventory",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_history"),
            url: "/history/inventory-history",
            name: "history",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_reasons"),
            url: "reasons/inventory-reasons",
            name: "reasons",
          },
        ],
      },

      {
        id: "panel8",
        icon: <PeopleAltOutlinedIcon />,
        title: t("sidebar.customers"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.customers"),
            url: "customer/customer-list",
            name: "customer",
          },
        ],
      },

      {
        id: "panel9",
        icon: <ReceiptOutlinedIcon />,
        title: t("sidebar.orders"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.orders"),
            url: "/orders/orders-list",
            name: "orders",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_status"),
            url: "/status/order-status",
            name: "status",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_numbering"),
            url: "/numbering/order-numbering",
            name: "numbering",
          },
        ],
      },

      {
        id: "panel10",
        icon: <AssignmentIndOutlinedIcon />,
        title: t("sidebar.users"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_role"),
            url: "roles/user-roles",
            name: "roles",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_list"),
            url: "users/users-list",
            name: "users",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_access"),
            url: "access/user-access",
            name: "access",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_requests"),
            url: "requests/user-requests",
            name: "requests",
          },
        ],
      },

      {
        id: "panel11",
        icon: <AssessmentOutlinedIcon />,
        title: t("sidebar.reports"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_reports"),
            url: "/order/reports-orders",
            name: "order",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.sales_reports"),
            url: "/sale/reports-sales",
            name: "sale",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.discounts_reports"),
            url: "/discountReport/reports-discount",
            name: "discountReport",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.customer_reports"),
            url: "/customers/reports-customers",
            name: "customers",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.product_reports"),
            url: "/products/reports/products",
            name: "products",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.category_reports"),
            url: "/categories/reports-categories",
            name: "categories",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.payment_reports"),
            url: "/payments/reports-payments",
            name: "payments",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.store_reports"),
            url: "/stores/reports-stores",
            name: "stores",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.delivery_reports"),
            url: "/delivery/reports-delivery",
            name: "delivery",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.tax_reports"),
            url: "/tax/reports-tax",
            name: "tax",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.receipts_reports"),
            url: "/receipts/reports-receipts",
            name: "receipts",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.shifts_reports"),
            url: "",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.tips_reports"),
            url: "/employee/reports-employee",
            name: "employee",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_log_activity"),
            url: "/activityLog/reports/activityLog",
            name: "activityLog",
          },
        ],
      },

      {
        id: "panel12",
        icon: <AdminPanelSettingsOutlinedIcon />,
        title: t("sidebar.account_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.signin_security"),
            url: "/safety/signIn&&Security",
            name: "safety",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.prefereneces"),
            url: "/preferences",
            name: "preferences",
          },
          // { icon: <FlashOnIcon />, text: "Pickup orders" },
          // { icon: <AccessTimeIcon />, text: "Scheduled orders" },
          // { icon: <StarIcon />, text: "Reviews" },
          // { icon: <RestorePageIcon />, text: "Refunds" },
        ],
      },
      {
        id: "panel13",
        icon: <AddBusinessOutlinedIcon />,
        title: t("sidebar.business_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.about_my_business"),
            url: "/businessAccount",
            name: "businessAccount",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.locations_list"),
            url: "/location/locations-list",
            name: "location",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.sales_tax"),
            url: "sales/tax",
            name: "sales",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.service_charge"),
            url: "/service/charge",
            name: "service",
          },

          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.receipts"),
            url: "/reciept/reciept-list",
            name: "reciept",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.rounding"),
            url: "/rounding",
            name: "rounding",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.refunds_cancel"),
            url: "/refund/cancel",
            name: "refund",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.advance_bookings"),
            url: "/booking/advance-booking",
            name: "booking",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.kitchen_settings"),
            url: "/kitchen/kitchen-settings",
            name: "kitchen",
          },
        ],
      },
      {
        id: "panel14",
        icon: <SettingsOutlinedIcon />,
        title: t("sidebar.general_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.image_library"),
            url: "image/image-library",
            name: "image",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.api_keys"),
            url: "apiKeys/api-keys",
            name: "apiKeys",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.feature_settings"),
            url: "/featureSettings",
            name: "featureSettings",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.manage_tips"),
            url: "manage/manage-tips",
            name: "manage",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.managing_orders"),
            url: "managingOrder/manage-orders",
            name: "managingOrder",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.open_items"),
            url: "/open/items",
            name: "open",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.loyality"),
            url: "/loyality",
            name: "loyality",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.reviews_ratings"),
            url: "/reviews/ratings",
            name: "reviews",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.caller_id"),
            url: "/callerId",
            name: "callerId",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.log_time_entries"),
            url: "log/log-time-entries",
            name: "log",
          },
        ],
      },
    ];
    setAccordionContent(updatedAccordionContent);
  }, [t]);

  const [expanded, setExpanded] = useState<Array<number>>([]);
  const [sideBarNotification, setSideBarNotification] = useState(false);
  const currentLanguage = i18n.language;
  const [language, setLanguage] = useState<string>(currentLanguage);
  const handleChangeLanguage = (e: any) => {
    i18n.changeLanguage(e.target.value);
    setLanguage(e.target.value);
  };
  const location = useLocation();

  const nameList = location.pathname.split("/");

  const handleChange =
    (panel: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      if (expanded.includes(panel)) {
        const filtered = expanded?.filter((filter) => {
          return filter !== panel;
        });
        setExpanded(filtered);
      } else {
        setExpanded([...expanded, panel]);
      }

      setToolTipId(panel);
    };
  useEffect(() => {
    if (!isOpenSideBar) {
      setExpanded([]);
    }
  }, [isOpenSideBar]);
  const navigate = useNavigate();
  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("employeeId");

    sessionStorage.removeItem("refresh");
    sessionStorage.removeItem("userId");
    sessionStorage.removeItem("locationId");

    navigate("/sign-in");
  };

  const [search, setSearch] = useState("");

  const filteredAccordianContent = useMemo(() => {
    if (search !== "") {
      return AccordionContent.filter((item) => {
        return item?.title?.toLowerCase().includes(search);
      });
    }
    return AccordionContent;
  }, [search, AccordionContent]);

  function doesUrlExist(pathName: any, data: any) {
    for (let i = 0; i < data.length; i++) {
      if (nameList.includes(data[i].name)) {
        return true;
      }
    }
    return false;
  }

  return (
    <>
      <Box
        sx={{ zindex: 99 }}
        width={"100%"}
        position={"absolute"}
        top={"0px"}
        height={"100%"}
      >
        {/* <SideBarWrapper isOpenSideBar={isOpenSideBar}> */}
        <SideBarWrapper>
          <Box
            width={"-webkit-fill-available"}
            // display={` ${isOpenSideBar ? "flex" : "contents"} `,xs:"none"}
            // display= { xs: "none", md: isOpenSideBar ? "flex" : "contents" }
            sx={{
              display: {
                xs: isOpenSideBar ? "flex" : "none",
                md: isOpenSideBar ? "flex" : "contents",
              },
            }}
            justifyContent={"space-between"}
            paddingLeft={"16px"}
          >
            <img src={Logo} alt="Logo" width="100px" height="100px" />
            <Button
              sx={{
                height: 24,
                width: 24,
                marginTop: "17px",
                color: "#F38B08",
              }} // Add height and width styles here
              onClick={() => setSideBar((prev) => !prev)}
            >
              <MenuOpenIcon />
            </Button>
          </Box>

          <Box
            borderBottom={`${isOpenSideBar ? "1px solid" : ""}`}
            borderColor={"#d6dee3"}
            width={"100%"}
            mb={`${isOpenSideBar ? "0px" : ""}`}
            mt={3}
          >
            <Box
              display={`${isOpenSideBar ? "flex" : "block"}`}
              justifyContent={"space-between"}
              alignItems={"center"}
              width={"calc(100% - 48px)"}
              mt={"8px"}
              mb={`${isOpenSideBar ? "8px" : ""}`}
              height={"36px"}
              marginX={"auto"}
            >
              <Box
                display={`${isOpenSideBar ? "flex" : "none"}`}
                alignItems={"center"}
                width="45%"
              >
                <img
                  src={LanguageFlag}
                  alt="LanguageFlag"
                  height={"24px"}
                  width={"24px"}
                  style={{ borderRadius: "24px" }}
                />
                {/* <Typography color={"#455560"} ml={"12px"}>
                  EN
                </Typography> */}
                <FormControl
                  sx={{ ml: "12px", width: "80%", paddingRight: "0px" }}
                >
                  {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={language}
                    // label="Age"
                    onChange={handleChangeLanguage}
                    variant="standard"
                    disableUnderline={true}
                    sx={{ color: "#455560", width: "100%" }}
                  >
                    <MenuItem value="en">EN</MenuItem>
                    <MenuItem value="es">SP</MenuItem>
                    {/* <MenuItem value="hi">Thirty</MenuItem> */}
                  </Select>
                </FormControl>
                {/* <img src={DropdownIcon} alt="DropdownIcon" /> */}
              </Box>
              <Box
                sx={{
                  display: {
                    xs: isOpenSideBar ? "flex" : "none", // Always "none" on extra-small screens
                    md: isOpenSideBar ? "" : "flex", // Conditional on medium screens and above
                  },
                  justifyContent: "center",
                }}
                onClick={() => {
                  setNotificationsOpen(true);
                  setSideBarNotification(true);
                }}
              >
                <NotificationsIcon
                  height={20}
                  width={20}
                  sx={{ color: "#455560" }}
                />
              </Box>

              <NotificationModal
                open={notificationOpen}
                setOpen={setNotificationsOpen}
                sideBarNotification={sideBarNotification}
                setSideBarNotification={setSideBarNotification}
              />
            </Box>
          </Box>
          {isOpenSideBar ? (
            <TypeSearch search={search} searchHandler={setSearch} />
          ) : null}
          <Box
            className="scrollBarHidden"
            sx={{
              // marginTop: `${isOpenSideBar ? "12px" : "0px"}`,
              // display: "flex",
              // justifyContent: "center",
              // alignItems: "center",
              // flexDirection: "column",
              marginTop: `${isOpenSideBar ? "0px" : "0px"}`,
              // width: "100%",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
              // marginY: "16px",
              marginBottom: "16px",
            }}
          >
            {/* {isOpenSideBar ? <TypeSearch /> : null} */}
            <Box
              className="accordions"
              sx={{
                // display: { md: "flex", xs: "none" },
                flexDirection: "column",
                width: "100%",
                // marginTop: `${isOpenSideBar ? "12px" : "0px"}`,
                marginTop: `${isOpenSideBar ? "0px" : "0px"}`,
              }}
            >
              {filteredAccordianContent.map((item: any, index) => (
                <Box key={index}>
                  {item.status === "withoutPannel" ? (
                    <Link
                      component={RouterLink}
                      to={`${item.url}`}
                      data-tooltip-id={`${item.id}`}
                      sx={{
                        padding: "0px 24px",
                        marginBottom: "10px",
                        display: {
                          xs: isOpenSideBar ? "flex" : "none",
                          md: isOpenSideBar ? "flex" : "flex",
                        },
                        alignItems: "center",
                        textDecoration: "none",
                        height: "40px",
                        justifyContent: `${isOpenSideBar ? "unset" : "center"}`,
                        cursor: "pointer",

                        background: `${
                          location.pathname &&
                          item.url &&
                          location.pathname.trim() === item.url.trim()
                            ? "rgba(255, 188, 22, 0.1)"
                            : ""
                        }`,
                        borderRight: `${
                          location.pathname &&
                          item.url &&
                          location.pathname.trim() === item.url.trim()
                            ? "3px solid #F38B08"
                            : ""
                        }`,

                        svg: {
                          color: `${
                            location.pathname &&
                            item.url &&
                            location.pathname.trim() === item.url.trim()
                              ? "#F38B08 !important"
                              : "#455560"
                          }`,
                        },
                        p: {
                          color: `${
                            location.pathname &&
                            item.url &&
                            location.pathname.trim() === item.url.trim()
                              ? "#F38B08"
                              : "#455560"
                          }`,
                        },
                      }}
                      className="sideBarSummary"
                    >
                      {item.icon}

                      {isOpenSideBar && (
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontFamily: "Poppins",
                            marginLeft: "14px",
                          }}
                        >
                          {item.title}
                        </Typography>
                      )}
                    </Link>
                  ) : (
                    <Accordion
                      key={item.id}
                      expanded={expanded.includes(item.id)}
                      data-tooltip-id={`${item.id}`}
                      onChange={handleChange(item.id)}
                      sx={{ border: "none", boxShadow: "unset" }}
                    >
                      <AccordionSummary
                        expandIcon={isOpenSideBar && <ExpandMoreIcon />}
                        aria-controls={`${item.id}-content`}
                        id={`${item.id}-header`}
                        sx={{
                          padding: "0px 24px",
                          display: {
                            xs: isOpenSideBar ? "flex" : "none",
                            md: isOpenSideBar ? "flex" : "flex",
                          },

                          alignItems: "center",
                          minHeight: "40px !important",
                          margin: "2px 0px",
                          color: "#455560",
                          backgroundColor:
                            item.items &&
                            doesUrlExist(location.pathname, item.items) &&
                            "rgba(255, 188, 22, 0.1)",
                          p: {
                            color:
                              item.items &&
                              doesUrlExist(location.pathname, item.items) &&
                              "#F38B08",
                          },
                          svg: {
                            fill:
                              item.items &&
                              doesUrlExist(location.pathname, item.items) &&
                              "#F38B08",
                          },
                          ":hover": {
                            background: "rgba(255, 188, 22, 0.1)",
                            p: {
                              color: "#F38B08",
                            },
                            svg: {
                              color: "#F38B08",
                            },
                          },
                          ...(expanded.includes(item.id) ? {} : {}),
                        }}
                        className={`sideBarSummary ${
                          isOpenSideBar ? "" : "sideBarOpenSummary"
                        }`}
                      >
                        <IconButton
                          sx={{
                            display: {
                              xs: isOpenSideBar ? "flex" : "none",
                              md: isOpenSideBar ? "flex" : "flex",
                            },
                          }}
                        >
                          {item.icon}
                        </IconButton>
                        {isOpenSideBar && (
                          <Typography
                            sx={{
                              fontSize: "16px",
                              fontFamily: "Poppins",
                              marginLeft: "14px",
                            }}
                          >
                            {item.title}
                          </Typography>
                        )}
                      </AccordionSummary>

                      {isOpenSideBar ? (
                        <AccordionDetails
                          sx={{
                            padding: "0px",
                          }}
                        >
                          <List
                            sx={{
                              listStyle: "none",
                              paddingY: "0px",
                            }}
                          >
                            {item.items.map((subItem: any, index: any) => (
                              <Link
                                component={RouterLink}
                                to={`${subItem.url}`}
                                key={index}
                                sx={{
                                  display: "flex",
                                  gap: "8px",
                                  paddingY: "8px",
                                  textDecoration: "none",
                                  paddingLeft: "42px !important",
                                  paddingRight: "4px important",
                                  svg: {
                                    color: `${
                                      location.pathname &&
                                      subItem.url &&
                                      subItem.name &&
                                      nameList.includes(subItem.name)
                                        ? "#F38B08 !important"
                                        : "#455560"
                                    }`,
                                  },
                                  ":hover": {
                                    background: "rgba(255, 188, 22, 0.1)",
                                    p: {
                                      color: "#F38B08",
                                    },
                                    svg: {
                                      color: "#F38B08",
                                    },
                                  },

                                  background: `${
                                    location.pathname &&
                                    subItem.url &&
                                    subItem.url &&
                                    subItem.name &&
                                    nameList.includes(subItem.name)
                                      ? "rgba(255, 188, 22, 0.1)"
                                      : ""
                                  }`,
                                  borderRight: `${
                                    location.pathname &&
                                    subItem.url &&
                                    subItem.name &&
                                    nameList.includes(subItem.name)
                                      ? "3px solid #F38B08"
                                      : ""
                                  }`,
                                  color: `${
                                    location.pathname &&
                                    subItem.url &&
                                    subItem.name &&
                                    nameList.includes(subItem.name)
                                      ? "#F38B08"
                                      : "#455560"
                                  }`,
                                }}
                              >
                                {/* {subItem.icon} */}
                                <Typography
                                  sx={{
                                    width: "calc(100% - 20px)",
                                    fontSize: "14px",
                                    lineHeight: "21px",
                                    fontFamily: "Poppins",
                                  }}
                                >
                                  {subItem.text}
                                </Typography>
                              </Link>
                            ))}
                          </List>
                        </AccordionDetails>
                      ) : null}
                    </Accordion>
                  )}
                </Box>
              ))}

              <Box
                sx={{
                  padding: "0px 24px",
                  display: {
                    xs: isOpenSideBar ? "flex" : "none",
                    md: isOpenSideBar ? "flex" : "flex",
                  },
                  alignItems: "center",
                  height: "40px",
                  justifyContent: `${isOpenSideBar ? "unset" : "center"}`,
                  cursor: "pointer",
                }}
              >
                <PlayForWorkIcon
                  sx={{
                    height: "24px",
                    width: "24px",
                    color: "#455560",
                    rotate: "-90deg",
                  }}
                />
                {isOpenSideBar && (
                  <Button
                    sx={{
                      fontSize: "16px",
                      fontFamily: "Poppins",
                      marginLeft: "14px",
                      color: "#455560",
                      textTransform: "none",
                    }}
                    onClick={handleLogOut}
                  >
                    {t("sidebar.logout")}
                  </Button>
                )}
              </Box>
            </Box>
          </Box>
        </SideBarWrapper>
      </Box>
    </>
  );
};

export default SideBar;
