import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import "./optionsList.css";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, IconButton, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useEffect } from "react";
import { useMutation } from "@apollo/client";

import { t } from "i18next";
import { CREATE_OPTION_GROUP } from "../../graphicalQl/mutation/optionsMutation";
import { GET_OPTION_GROUP_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
import ErrorModal from "../errorHandling/errorModal";
import { toast } from "react-toastify";
const validationSchemaPart1 = Yup.object({
  name: Yup.string().required("Name is required"),
});

interface Props {
  addbutton: boolean;
  handleAddButton: () => void;
  handleGroupName: (data: string) => void;

  selectedDataIds: number[];
}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

export default function CreateOptionGroup(props: Props) {
  const { addbutton, handleAddButton, handleGroupName, selectedDataIds } =
    props;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [open, setOpen] = React.useState(addbutton);
  const handleClose = () => {
    setOpen(false);
    handleAddButton();
    handleGroupName(formik.values.name);
  };
  useEffect(() => {
    setOpen(addbutton);
  }, [addbutton]);
  const [categoryStatusCode, setCategoryStatusCode] = React.useState<number>(0);
  const [errorModal, setErrorModal] = React.useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const [createOptionGroup] = useMutation(CREATE_OPTION_GROUP, {
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        setCategoryStatusCode(
          parseInt(graphqlError.extensions.status, 10) || 500
        );
        setErrorModal(true);
      }
      const errorMessage = graphqlError.message || "An error occurred";
      console.error(
        `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
      );
    },
    onCompleted: (data) => {
      handleClose();
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      formik.setFieldValue("name", "");
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
  });

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      createOptionGroup({
        variables: {
          name: formik.values.name.trim(),
          options: JSON.stringify(selectedDataIds),
          businessId: +businessId,
        },
      });
    },
  });
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton className="exit-container" onClick={handleClose}>
                <CloseIcon className="exitIcon" />
              </IconButton>

              <Typography
                className="heavyHeader"
                sx={{ textTransform: "capitalize" }}
              >
                {t("options.create_group")}
              </Typography>
              <Typography></Typography>
            </Box>
            <Divider sx={{ marginTop: "12px", marginBottom: "12px" }} />
            <Box sx={{ textAlign: "center" }}>
              <Box>
                <TextField
                  color="warning"
                  label="Enter group name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  name="name"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
              </Box>
            </Box>

            <Box sx={{ textAlign: "right", width: "100%" }}>
              <Button
                className="main-button"
                sx={{
                  marginLeft: "3%",
                  textTransform: "none",
                  backgroundColor: "#F5431C",
                }}
                type="submit"
              >
                {t("options.save")}
              </Button>
            </Box>
          </form>
          <ErrorModal
            errorModal={errorModal}
            statusCode={categoryStatusCode}
            handleErrorModal={handleErrorModal}
          />
        </Box>
      </Modal>
    </div>
  );
}
