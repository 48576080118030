import React, { ChangeEvent, useEffect, useState } from 'react';
import {
    Typography, Paper, Button, Divider, Switch, Checkbox, TextField,
    FormControlLabel, FormGroup,
    Box,
    IconButton
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import './rounding.css';
import './refund.css';
import './manageOreders.css'
import { CREATE_MANAGING_ORDERS } from '../../graphicalQl/mutation/salesTaxMutation';
import { useMutation, useQuery } from '@apollo/client';
import { GET_ADAVANCE_BOOKING } from '../../graphicalQl/usequery/settingQuery';
import DeleteIcon from '@mui/icons-material/Delete';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const ManagingOrders = () => {
    const { t } = useTranslation()
    const [toggleState, setToggleState] = useState<boolean>(false);
    const { locationId } = useSelector((state: any) => state.headerLocation);
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleState(event.target.checked);
    };
    const [toggleStateReject, setToggleStateReject] = useState<boolean>(false);
    const handleToggleChangeReject = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleStateReject(event.target.checked);
    };

    const [toggleStateSnooze, setToggleStateSnooze] = useState<boolean>(false);

    const handleToggleChangeSnooze = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleStateSnooze(event.target.checked);
    };

    const [checkboxes, setCheckboxes] = useState<{ isChecked: boolean; label: string }[]>([
        { isChecked: false, label: 'Too Busy' },
        { isChecked: false, label: 'Unable to Deliver' },
        { isChecked: false, label: 'Food Unavailable' },
        { isChecked: false, label: "Don't Deliver" },
    ]);

    const [newLabel, setNewLabel] = useState<string>('');

    // Function to handle checkbox change
    const handleCheckboxChange = (index: number) => {
        const updatedCheckboxes = checkboxes.map((checkbox, i) =>
            i === index ? { ...checkbox, isChecked: !checkbox.isChecked } : checkbox
        );
        setCheckboxes(updatedCheckboxes);
    };

    const handleNewLabelChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabel(event.target.value);
        setEmptyCondition(false)
    };

    const [emptyCondition, setEmptyCondition] = useState(false)
    const handleAddCheckbox = () => {
        if (newLabel.trim() !== '') {
            setCheckboxes([...checkboxes, { isChecked: false, label: newLabel }]);
            setNewLabel('');
            setEmptyCondition(false)
            setAddValue(false)
        }
        setEmptyCondition(true)
    };

    const [addValue, setAddValue] = useState(false);

    const handleAdd = () => {
        setAddValue(true);
    };

    const [emptyCondition1, setEmptyCondition1] = useState(false)
    const handleAddCheckbox1 = () => {
        if (newLabel1.trim() !== '') {
            setCheckboxes1([...checkboxes1, { isChecked: false, label: newLabel1 }]);
            setNewLabel1('');
            setEmptyCondition1(false)
            setAddValue1(false)
        }
        else {
            setEmptyCondition1(true)
        }
    };

    const [addValue1, setAddValue1] = useState(false);

    const handleAdd1 = () => {
        setAddValue1(true);
    };

    const data = [
        { id: 1, modifications: t("settings.add_items"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 2, modifications: t("settings.changing_quantity"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 3, modifications: t("settings.add/remove"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 4, modifications: t("settings.request_sub"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 5, modifications: t("settings.cancel_item"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
        { id: 6, modifications: t("settings.pre/post"), preparationNotStarted: t("settings.not_allowed"), preparing: t("settings.not_allowed"), prepared: t("settings.not_allowed") },
    ];

    const columns = [
        { id: 'modifications', label: t("settings.modification") },
        { id: 'preparationNotStarted', label: t("settings.preparation_not_started") },
        { id: 'preparing', label: t("settings.preparing") },
        { id: 'prepared', label: t("settings.prepared") },
    ];

    const [tableData, setTableData] = useState<
        { id: number; modifications: string; preparationNotStarted: string; preparing: string; prepared: string }[]
    >(data);

    const handleValueChange = (
        rowIndex: number,
        columnName: keyof typeof data[0],
        newValue: string | number
    ) => {
        if (columnName !== "id") {
            const updatedData = [...tableData];
            updatedData[rowIndex][columnName] = String(newValue); // Convert to string
            setTableData(updatedData);
        }
    };

    const [checkboxes1, setCheckboxes1] = useState<{ isChecked: boolean; label: string }[]>([
        { isChecked: false, label: '5' },
        { isChecked: false, label: '12' },
        { isChecked: false, label: '10' },
        { isChecked: false, label: '11' },
    ]);

    const [newLabel1, setNewLabel1] = useState<string>('');

    // Function to handle snooze checkbox change
    const handleCheckboxChange1 = (index: number) => {
        const updatedCheckboxes1 = [...checkboxes1];
        updatedCheckboxes1[index].isChecked = !updatedCheckboxes1[index].isChecked;
        setCheckboxes1(updatedCheckboxes1);
    };

    const handleNewLabelChange1 = (event: React.ChangeEvent<HTMLInputElement>) => {
        setNewLabel1(event.target.value);
        setEmptyCondition1(false)
    };

    const [managingOrders, { loading }] = useMutation(CREATE_MANAGING_ORDERS, {
        context: { clientName: "businessClient" }, onCompleted: () => {

            toast.success(<div >
                <Typography>{t("toast.updated")}</Typography>
            </div>,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    className: 'custom_toast3',
                })
        },
        onError: (error) => {
            console.error('Error:', error);
        },
    })
    const handleCreateManageOrders = () => {
        managingOrders({
          variables: {
            storeId: locationId ? +locationId : null,
            ...(toggleState
              ? {}
              : {
                  showReasonForRejecting: toggleStateReject,
                  rejectingReasons: toggleStateReject ? JSON.stringify(checkboxes) : null,
                  showSnoozeForAccept: toggleStateSnooze,
                  snoozeTimings: toggleStateSnooze ? JSON.stringify(checkboxes1) : null,
                }),
            autoAcceptOrders: toggleState,
          },
        });
      };
      



    const { data: manageOrdersData } = useQuery(GET_ADAVANCE_BOOKING, {
        variables: { storeId: locationId && +locationId }, context: { clientName: "businessClient" }, fetchPolicy: "network-only",
        onError: (error) => {
            console.error('Error:', error);
        },
    })

    const handleData = () => {
        if (manageOrdersData && manageOrdersData.storeSettings) {
            manageOrdersData.storeSettings.rejectingReasons && setCheckboxes(JSON.parse(manageOrdersData.storeSettings.rejectingReasons))
            manageOrdersData.storeSettings.snoozeTimings && setCheckboxes1(JSON.parse(manageOrdersData.storeSettings.snoozeTimings))
            manageOrdersData.storeSettings.showReasonForRejecting && setToggleStateReject(manageOrdersData.storeSettings.showReasonForRejecting)
            manageOrdersData.storeSettings.showSnoozeForAccept && setToggleStateSnooze(manageOrdersData.storeSettings.showSnoozeForAccept)
            manageOrdersData.storeSettings.autoAcceptOrders && setToggleState(manageOrdersData.storeSettings.autoAcceptOrders)

        }
    }
    useEffect(() => {
        handleData()
    }, [manageOrdersData])
    const handleCancel = () => {

        setCheckboxes1(prevState => prevState.map(each => ({ ...each, isChecked: false })));
        setCheckboxes(prevState => prevState.map(each => ({ ...each, isChecked: false })));

        setToggleStateReject(false)
        setToggleStateSnooze(false)
        setAddValue(false)
        setAddValue1(false)
        setNewLabel("")
        setNewLabel1("")
        setEmptyCondition(false)
        setEmptyCondition1(false)

        // setToggleState(false)
        // handleData()
    }
    const [mouseEnter, setMouseEnter] = useState("")
    const [mouseEnter1, setMouseEnter1] = useState("")
    const handleDeletecheckboxes = (index: number) => {
        const updatedCheckboxes = [...checkboxes];
        updatedCheckboxes.splice(index, 1);
        setCheckboxes(updatedCheckboxes);
    };


    const handleDeletecheckboxes1 = (index: number) => {
        const updatedCheckboxes = [...checkboxes1];
        updatedCheckboxes.splice(index, 1);
        setCheckboxes1(updatedCheckboxes);
    };

    const dupliacteCondition1 = checkboxes.filter((each) => each.label.toLowerCase().trim() === newLabel.toLowerCase().trim()).length > 0
    const dupliacteCondition2 = checkboxes1.filter((each) => each.label.toLowerCase().trim() === newLabel1.toLowerCase().trim()).length > 0

    return (
        <Paper elevation={0} style={{ padding: "20px" }}>
            <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography className="rounding_titleValue">{t("settings.manage_orders")}</Typography>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <Button variant="contained" className="rounding_Button_clear" onClick={handleCancel} sx={{textTransform:"none"}}>
                        {t("settings.clear")}</Button>
                    <Button variant="contained" sx={{textTransform:"none"}} className="Rounding_save_button" style={{ marginLeft: "20px" }} onClick={handleCreateManageOrders} disabled={loading}>
                        {t("settings.save")}</Button>
                </div>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: "27px" }} />
            <div style={{ marginTop: "16px", marginBottom: "16px", marginLeft: "68px" }}>
                <div style={{ display: "flex", alignItems: "center" }}>

                    <Typography className="refund_head2" style={{ marginRight: "136px" }}>{t("settings.auto_accept_orders")}</Typography>

                    <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
                </div>
                <Typography className="refund_para">{t("settings.auto_accept_para")}</Typography>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />
            {!toggleState &&
                (<>
                    <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='refund_page'  >


                        <div style={{ display: 'flex', alignItems: "center" }}>

                            <Typography className="refund_head" style={{ marginRight: "60px" }}>{t("settings.show_reason")}</Typography>

                            <Switch checked={toggleStateReject} onChange={handleToggleChangeReject} color='warning' />

                        </div>
                        <Typography className='refund_para'>{t("settings.show_reasons_para")}</Typography>
                        {toggleStateReject &&
                            (<>
                                <div>

                                    <div style={{ display: "flex", flexDirection: "column" }}>

                                        <FormGroup>
                                            {checkboxes && checkboxes.map((checkbox, index) => (
                                                <Box className="rounding_chekbox_styling_orders" onMouseEnter={() => setMouseEnter(index.toString())} onMouseLeave={() => setMouseEnter("")} key={index} position="relative" sx={{ paddingLeft: "4px", }}>
                                                    <FormControlLabel
                                                        key={index}
                                                        control={
                                                            <Checkbox
                                                                checked={checkbox.isChecked}
                                                                onChange={() => handleCheckboxChange(index)}
                                                                color='warning'
                                                                sx={{ width: "24px", height: "24px", marginLeft: "12px" }}
                                                            />
                                                        }
                                                        label={<Typography className='refund_head4' sx={{ textTransform: "capitalize" }}>{checkbox.label}</Typography>}

                                                    />
                                                    {index.toString() === mouseEnter && index > 3 &&
                                                        <IconButton sx={{ position: 'absolute', top: 0, right: 0 }}
                                                            onClick={() => handleDeletecheckboxes(index)}
                                                        >
                                                            <DeleteIcon fontSize="small" />
                                                        </IconButton>
                                                    }
                                                </Box>
                                            ))}
                                        </FormGroup>

                                    </div>
                                    {!addValue && <Button onClick={handleAdd} style={{ marginTop: '10px', textTransform: "capitalize" }} className='refund_reason'>
                                        {t("settings.add_reason")}
                                    </Button>
                                    }

                                    <div>
                                        {addValue && (
                                            <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                                <Box>
                                                    <TextField
                                                        label={t("settings.enter_name")}
                                                        value={newLabel}
                                                        // onChange={handleNewLabelChange}
                                                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                            const val = e.target.value;
                                                            if (((!val || !(/^[0-9]+$/.test(val))) && val.length <= 25)) {
                                                                handleNewLabelChange(e);
                                                            }
                                                        }}
                                                        variant="outlined"
                                                        margin="dense"
                                                        style={{ marginTop: '10px' }}
                                                        color='warning'
                                                    />


                                                    {dupliacteCondition1 && <Typography style={{ color: "red", marginLeft: "10px", fontSize: '12px' }}>*Reason Already exist</Typography>}
                                                    {emptyCondition && <Typography style={{ color: "red", marginLeft: "10px", fontSize: '12px' }}>*Please enter value</Typography>}
                                                    <Box sx={{ textAlign: 'right' }}>
                                                        <Typography sx={{ fontSize: "12px", color: "#707070" }}>{newLabel ? newLabel.toString().length : 0}/25</Typography>
                                                    </Box>
                                                </Box>
                                                <Button onClick={() => { setAddValue(false); setNewLabel(""); setEmptyCondition(false) }} style={{ marginLeft: '10px', textTransform: "capitalize" }} sx={{ color: "#ABABAB" }}>
                                                    {t("settings.cancel")}                                                </Button>
                                                <Button onClick={handleAddCheckbox} style={{ marginLeft: '10px', display: "flex", alignItems: "center", textTransform: "capitalize" }} className={dupliacteCondition1 ? "refund_reason1" : "refund_reason"} disabled={dupliacteCondition1} >
                                                    {t("settings.add_reason")}
                                                </Button>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <Divider style={{ backgroundColor: ' #D9D9D9' }} />
                            </>)
                        }
                    </Paper>
                    <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }} className='refund_page'  >
                        <div>

                            <div style={{ display: "flex", alignItems: "center" }}>

                                <Typography className="refund_head" style={{ marginRight: "60px" }}>{t("settings.snooze")}</Typography>

                                <Switch checked={toggleStateSnooze} onChange={handleToggleChangeSnooze} color='warning' />
                            </div>
                            <Typography className="refund_para">{t("settings.snoze_para")}</Typography>

                            {toggleStateSnooze && (
                                <>
                                    <div>
                                        <div style={{ display: "flex", marginTop: '12px', width: '100%', flexWrap: 'wrap', gap: '12px' }}>
                                            {checkboxes1 && checkboxes1.map((checkbox, index) => (
                                                <div key={index}>
                                                    <div style={{}} >
                                                        <Box sx={{ position: "relative", display: 'flex', alignItems: 'center', marginRight: "20px" }} onMouseEnter={() => setMouseEnter1(index.toString())} onMouseLeave={() => setMouseEnter1("")} key={index} className='orders_checkBox2'>
                                                            <FormControlLabel
                                                                key={index}
                                                                control={
                                                                    <Checkbox
                                                                        checked={checkbox.isChecked}
                                                                        onChange={() => handleCheckboxChange1(index)}
                                                                        disabled={checkboxes1.filter((each) => each.isChecked).length === 3 && checkbox.isChecked === false}
                                                                        color='warning'
                                                                    />
                                                                }
                                                                label={<Typography className='refund_head4'>{checkbox.label} {t("settings.min")}</Typography>}
                                                            />
                                                            {index.toString() === mouseEnter1 &&
                                                                <IconButton sx={{ position: 'absolute', top: 0, right: 0 }} onClick={() => handleDeletecheckboxes1(index)}>
                                                                    <DeleteIcon fontSize="small" />
                                                                </IconButton>
                                                            }
                                                        </Box>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                        {!addValue1 &&
                                            <Button onClick={handleAdd1} style={{ marginTop: '10px', textTransform: 'capitalize' }} className='refund_reason'>
                                                {t("settings.add_snooze")}
                                            </Button>
                                        }

                                        <div>
                                            {addValue1 && (
                                                <div style={{ marginLeft: '10px', display: "flex", alignItems: "center" }}>
                                                    <Box>
                                                        <TextField
                                                            label={t("settings.value_in_min")}
                                                            value={newLabel1}
                                                            // onChange={handleNewLabelChange1}
                                                            variant="outlined"
                                                            // type="number"
                                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                                const val = e.target.value;
                                                                if (((!val || /^[0-9]+$/.test(val)) && val.length <= 5)) {
                                                                    handleNewLabelChange1(e)
                                                                }
                                                            }} type="text"
                                                            className="hide-number-input-icons"
                                                            margin="dense"
                                                            style={{ marginTop: '10px' }}
                                                        />
                                                        {dupliacteCondition2 && <Typography style={{ color: "red", marginLeft: "10px", fontSize: '12px' }}>*Time Already exist</Typography>}
                                                        {emptyCondition1 && <Typography style={{ color: "red", marginLeft: "10px", fontSize: '12px' }}>*Please enter value</Typography>}
                                                        <Box sx={{ textAlign: 'right' }}>
                                                            <Typography sx={{ fontSize: "12px", color: "#707070" }}>{newLabel1 ? newLabel1.toString().length : 0}/5</Typography>
                                                        </Box>
                                                    </Box>
                                                    <Button onClick={() => { setAddValue1(false); setNewLabel1(""); setEmptyCondition1(false) }} style={{ marginLeft: '10px', textTransform: 'capitalize' }} sx={{ color: "#ABABAB" }}>
                                                        {t("settings.cancel")}
                                                    </Button>
                                                    <Button onClick={handleAddCheckbox1} style={{ marginLeft: '10px', display: "flex", alignItems: "center", textTransform: 'capitalize' }} className={dupliacteCondition2 ? "refund_reason1" : 'refund_reason'} disabled={dupliacteCondition2}>
                                                        {t("settings.add1")}
                                                    </Button>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                </>
                            )}
                        </div>
                    </Paper>
                    {/* <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-3-green, #BAE29B)" }} className='refund_page'  >
                        <Typography className="refund_head2">{t("settings.modifying_orders")}</Typography>

                        <TableContainer component={Paper} elevation={1}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        {columns.map((column) => (
                                            <TableCell key={column.id} className='refund_head'>{column.label}</TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {tableData.map((row: any, rowIndex) => (
                                        <TableRow key={row.id} className='refund_head5'>
                                            {columns.map((column) => (
                                                <TableCell key={column.id} className='refund_head5'>
                                                    {column.id === 'modifications' ? (
                                                        row[column.id]
                                                    ) : (
                                                        <Select
                                                            value={row[column.id as keyof typeof data[0]] || "Not Allowed"} // Explicitly cast column.id
                                                            onChange={(e) => handleValueChange(rowIndex, column.id as keyof typeof data[0], e.target.value)} // Explicitly cast column.id
                                                            style={{ width: '130px' }}
                                                            color='warning'
                                                            sx={{
                                                                "&:hover": {
                                                                    "&& fieldset": {
                                                                        border: "2px solid #F9C584"
                                                                    }
                                                                }
                                                            }}
                                                        >
                                                            {[t("settings.allowed"), t("settings.not_allowed")].map((value) => (
                                                                <MenuItem key={value} value={value}>
                                                                    {value}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    )}
                                                </TableCell>
                                            ))}
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper> */}


                </>)
            }
        </Paper >
    )
}
export default ManagingOrders;