import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box, Typography } from '@mui/material';
import "./customers.css"
import moment from 'moment';





interface Props {
    ordersHistory: any
}

export default function OrderHistoryTable(props: Props) {
    const { ordersHistory } = props
    const currencySymbol = sessionStorage.getItem('currencySymbol')

    //convert snake case to camel case
    const convertSnakeToCamel = (str: string) => {
        return str.replace(/([-_]\w)/g, g => g[1].toUpperCase());
    }
    //convert snake case to like ashok_kumar to Ashok Kumar
    const convertSnakeToSpace = (str: string) => {
        return str.replace(/([-_])/g, ' ');
    }

    return (

        <Box>
            {ordersHistory.length > 0 ? <TableContainer sx={{ maxHeight: '400px' }}>
                <Table sx={{ minWidth: 650, }} aria-label="simple table">
                    <TableHead>
                        <TableRow>

                            <TableCell align="center" className='customer-inform2'>Date</TableCell>
                            <TableCell align="center" className='customer-inform2'>Order Details</TableCell>
                            <TableCell align="center" className='customer-inform2'>Items</TableCell>
                            <TableCell align="center" className='customer-inform2'>Order Status</TableCell>

                            <TableCell align="center" className='customer-inform2'>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ordersHistory && ordersHistory.map((row: any) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >

                                <TableCell align="center" className='customer-number '>{moment(row.date).format('MMM DD YYYY,h:mm A')}</TableCell>
                                <TableCell align="center">{
                                    <Box className='customer-number'>
                                        <Typography className='customer-number'>{row.orderId}</Typography>
                                        <Typography className='customer-number' sx={{ textTransform: "capitalize" }}>{convertSnakeToSpace(row.orderType)}</Typography>
                                    </Box>
                                }</TableCell>
                                <TableCell align="center" >{
                                    <Typography className='customer-number'>{row.items.length}</Typography>
                                    // <Box>
                                    //     {row.items && row.items.map((each: any) => (
                                    //         <Box sx={{ display: "flex", gap: '12px' }}>
                                    //             <Typography className='customer-number '>{each.product}</Typography>
                                    //             <Typography className='customer-number '>{each.price}</Typography>
                                    //         </Box>

                                    //     ))}
                                    // </Box>
                                }</TableCell>
                                <TableCell align="center">{

                                    <Typography className='customer-number' sx={{ textTransform: "capitalize" }}>{convertSnakeToSpace(row.orderStatus)}</Typography>

                                }</TableCell>
                                <TableCell align="center" className='customer-number '> {currencySymbol} {row.total}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer> : <Typography>NO Data Available</Typography>}
        </Box>

    );
}
