import { gql } from "@apollo/client";

export const GET_PROFILE_DATA = gql`
query Employee($employeeId:ID){
  employee(employeeId:$employeeId)
    {
    lastName
      firstName
      role {
        roleId
        roleName
      }
    email
      mobile
      image
      bgColourCode
      
    }
  }
`