import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useTranslation } from 'react-i18next';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 6,
};

interface Props {
    modal: boolean,
    setModal: any
}

export default function BasicModal(props: Props) {
    const { modal, setModal } = props
    const { t } = useTranslation()
    const handleClose = () => setModal(false);

    return (
        <div>

            <Modal
                open={modal}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, textAlign: "center" }}>
                    <Typography sx={{
                        color: '#333',
                        textAlign: 'center',
                        fontFamily: 'Poppins',
                        fontSize: '16px',
                        fontStyle: 'normal',
                        fontWeight: 400,
                        lineHeight: '160%', /* or '25.6px' */
                    }}>{t("menu.bundle.information_dailouge")}                    </Typography>
                    <Box sx={{ width: "100%", marginTop: '60px', display: "flex", justifyContent: "center" }}>
                        <Button sx={{
                            color: 'var(--prime-orange-900, #F38B08)',
                            fontFamily: 'Poppins',
                            fontSize: '16px',
                            fontStyle: 'normal',
                            fontWeight: 500,
                            lineHeight: 'normal',
                            display: 'flex',

                            height: '44px',
                            padding: '8px 16px',
                            justifyContent: 'center',
                            alignItems: 'center',
                            gap: '24px',
                            flexShrink: 0,
                            textTransform: 'capitalize',
                            borderRadius: '4px',
                            background: '#FFF',
                            boxShadow: '0px 4px 12px 0px rgba(0, 0, 0, 0.12)',
                            border: "1px solid var(--Primary-Color, #F38B08)",
                        }} onClick={handleClose}>
                            {t("menu.bundle.okay")}
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
