import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import english from './locales/en/translation.json';
import spanish from './locales/es/translation.json';
import hindi from './locales/hi/translation.json';
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        translation: english
      },
      es: {
        translation: spanish
      },
      hi: {
        translation: hindi
      }
    }
  });

export default i18n;