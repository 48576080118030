import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 600,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 6,
};

interface Props {
    error: boolean,
    handleError: () => void
}

export default function ItemErrorModal(props: Props) {
    const { error, handleError } = props

    const handleClose = () => {
        handleError()

    };

    return (
        <div>

            <Modal
                open={error}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, textAlign: "center" }}>
                    <Typography sx={{
                        color: "var(--Cerise-Red-600, #D42A5B)",
                        textAlign: "center",
                        fontFamily: "Poppins",
                        fontSize: "14px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "normal",
                    }}>
                        Please select at-least one product to apply discount
                    </Typography>
                    <Button className='main-button' sx={{ marginTop: '24px' }} onClick={handleClose}>Okay</Button>
                </Box>
            </Modal>
        </div>
    );
}