import { Sheet } from "@mui/joy";
import { Box, Button, Typography } from "@mui/material";
import profileNotificationIcon from "../../../assets/notifiactionIcons/profileNotificationIcon.svg";
import NotificationProfIcon from "../../../assets/notifiactionIcons/profileNotificationProfIcon.svg";
import ProfileNotificationSettingIcon from "../../../assets/notifiactionIcons/profileNotificationSettingIcon.svg";
import ProfileNotificationPricingIcon from "../../../assets/notifiactionIcons/profileNotificationPricingIcon.svg";
import ProfileNotificationBillingDetailsIco from "../../../assets/notifiactionIcons/profileNotificationBillingDetailsIcon.svg";
import ProfileNotificationBillingHistoryIcon from "../../../assets/notifiactionIcons/profileNotificationBillingHistoryIcon.svg";
import ProfileNotificationPrivacypolicyIcon from "../../../assets/notifiactionIcons/profileNotificationPrivacy&policyIcon.svg";
import ProfileNotificationHelpIcon from "../../../assets/notifiactionIcons/profileNotificationHelp.svg";
import ProfileNotificationLogOutIcon from "../../../assets/notifiactionIcons/profileNotificationLogOutIcon.svg";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useQuery } from "@apollo/client";
import { GET_UPDATE_USER_DETAILS } from "../../../graphicalQl/usequery/userProfileUseQuery";

interface ProfileMenuModalProps {
  handleCloseButton: () => void
}

const ProfileMenuModal = (props: ProfileMenuModalProps) => {
  const { handleCloseButton } = props;
  const employeeId = sessionStorage.getItem("employeeId");
  const { data: updateUserData, loading: updateUserLoading, error: updateUserError } = useQuery(GET_UPDATE_USER_DETAILS, { context: { clientName: "userClient" }, variables: { employeeId: employeeId } })
  const [ownerName, setOwnerName] = useState<string | null>(null)
  const [roleName, setRoleName] = useState<string | null>(null)
  useEffect(() => {
    if (updateUserData) {
      const { employee } = updateUserData
      if (employee) {
        const { firstName, lastName } = employee
        setOwnerName(`${firstName} ${lastName}`)
        setRoleName(employee.role.roleName)
      }
    }
  }, [updateUserData])
  const navigate = useNavigate()
  const handleLogOut = () => {
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("employeeId")
    sessionStorage.removeItem("business_id")
    sessionStorage.removeItem("refresh")
    sessionStorage.removeItem("userId")
    sessionStorage.removeItem("locationId")
    navigate("/sign-in")
  }
  return (
    <Sheet
      variant="outlined"
      className={'profileNotificationBoxShadow'}
      sx={{
        width: '100%',
        maxWidth: "320px",
        outline: "none",
        borderRadius: "8px",
        paddingY: '32px',
        border: '1px solid #EEEEEE',
        gap: '4px',
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', paddingX: '20px', paddingBottom: '24px', borderBottom: '1px solid rgba(238, 238, 238, 1)' }}>
        <img src={profileNotificationIcon} alt="profileNotificationIcon" />
        <Box>
          <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#333333', lineHeight: '24px', textTransform: 'capitalize' }}>{ownerName}</Typography>
          <Typography sx={{ fontSize: '14px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '21px', marginTop: '4px', textTransform: 'capitalize' }}>{roleName}</Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: '15px', display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: '#FFF9F3', cursor: 'pointer' }}>
        <img src={NotificationProfIcon} alt="NotificationProfIcon" />
        <Button sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', textTransform: "none" }} onClick={() => { navigate("/profileTab"); handleCloseButton() }}>
          Profile
        </Button>
      </Box>

      {/* <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white', cursor: 'pointer',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', }
      }}>
        <img src={ProfileNotificationSettingIcon} alt="SettingIcon" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px' }}>
          Settings
        </Typography>
      </Box> */}

      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white', cursor: 'pointer',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', }
      }}>
        <img src={ProfileNotificationPricingIcon} alt="PricingIcon" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px' }}>
          Pricing & Subscription
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white', cursor: 'pointer',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', }
      }}>
        <img src={ProfileNotificationBillingDetailsIco} alt="ProfileNotificationBillingDetailsIco" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px' }}>
          Billing details
        </Typography>
      </Box>

      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', cursor: 'pointer' }
      }}>
        <img src={ProfileNotificationBillingHistoryIcon} alt="HistoryIcon" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px' }}>
          Billings History
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '1px', backgroundColor: '#EEEEEE', marginY: '10px' }}></Box>

      {/* <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', cursor: 'pointer' }
      }}>
        <img src={ProfileNotificationPrivacypolicyIcon} alt="PrivacypolicyIcon" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px' }}>
          Privacy Policy
        </Typography>
      </Box> */}
      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', cursor: 'pointer' }
      }}>
        <img src={ProfileNotificationHelpIcon} alt="HelpIcon" />
        <Typography sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px' }}>
          Help
        </Typography>
      </Box>
      <Box sx={{ width: '100%', height: '1px', backgroundColor: '#EEEEEE', marginY: '10px' }}></Box>
      <Box sx={{
        display: 'flex', alignItems: 'center', gap: '20px', paddingX: '20px', height: '54px', backgroundColor: 'white',
        transition: 'background-color 0.1s', '&:hover': { backgroundColor: '#FFF9F3', cursor: 'pointer' }
      }} >
        <img src={ProfileNotificationLogOutIcon} alt="LogOutIcon" />
        <Button sx={{ fontSize: '16px', fontFamily: 'poppins', fontWeight: '500', color: '#707070', lineHeight: '24px', letterSpacing: '0.4px', textTransform: "none" }} onClick={handleLogOut}>
          Logout
        </Button>
      </Box>
    </Sheet>
  );
};

export default ProfileMenuModal;
