//add formik and yup for form validation

import React, { useState, useEffect } from "react";
import {
  Divider,
  FormControlLabel,
  InputAdornment,
  Tooltip,
} from "@mui/material";
import { CREATE_ADVANCE_BOOKINGS } from "../../graphicalQl/mutation/salesTaxMutation";
import {
  Paper,
  Typography,
  Button,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Switch,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Tab from "@mui/material/Tab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useNavigate, useParams } from "react-router-dom";
import Box from "@mui/material/Box";
import "./createAdvance.css";
import "./createSalesTax.css";
import ErrorIcon from "@mui/icons-material/Error";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { GET_ADAVANCE_BOOKING } from "../../graphicalQl/usequery/settingQuery";
import { toast } from "react-toastify";

import { useTranslation } from "react-i18next";

interface Option {
  value: string;
  label: string;
}

interface InitialValues {
  deleverySelectedOption1: string;
  deleverySelectedOption2: string;
  deleverySelectedOption3: string;
  deleveryInputValue1: string | number;
  deleveryInputValue2: string | number;
  deleveryInputValue3: string | number;
  collectionSelectedOption1: string;
  collectionSelectedOption2: string;
  collectionSelectedOption3: string;
  collectionInputValue1: string | number;
  collectionInputValue2: string | number;
  collectionInputValue3: string | number;
  dineSelectedOption1: string;
  dineSelectedOption2: string;
  dineSelectedOption3: string;
  dineInInputValue1: string | number;
  dineInInputValue2: string | number;
  dineInInputValue3: string | number;
  advanceInputValue1: string | number;
  advanceInputValue2: string | number;
  advanceInputValue3: string | number;
  advanceSelectedOption1: string;
  advanceSelectedOption2: string;
  advanceSelectedOption3: string;

  showTextField: boolean;
  notificationToggle: boolean;
  deliveryAdvanceDiposit: boolean;
  collectionAdvanceDiposit: boolean;
  dineInAdvanceDiposit: boolean;
  deliverySelect: string;
  collectionSelect: string;
  dineInSelect: string;
  deliveryAmount: string;
  collectionAmount: string;
  dineInAmount: string;
  defaultToggle: boolean;
}

const options: Option[] = [
  { value: "Minute", label: "Minute" },
  { value: "Hour", label: "Hour" },
  { value: "Day", label: "Day" },
];
const validationSchema = Yup.object({
  deleveryInputValue2: Yup.string().test(
    "is-others-included",
    "max should be greater than min",
    function (value) {
      const {
        deleverySelectedOption1,
        deleverySelectedOption2,
        deleveryInputValue1,
        deleveryInputValue2,
      } = this.parent;
      return (
        (deleverySelectedOption1 === "Day"
          ? deleveryInputValue1
            ? deleveryInputValue1 * 24 * 60
            : 0
          : deleverySelectedOption1 === "Hour"
          ? deleveryInputValue1
            ? deleveryInputValue1 * 60
            : 0
          : deleveryInputValue1
          ? deleveryInputValue1
          : 0) <=
        (deleverySelectedOption2 === "Day"
          ? deleveryInputValue2
            ? deleveryInputValue2 * 24 * 60
            : 0
          : deleverySelectedOption2 === "Hour"
          ? deleveryInputValue2
            ? deleveryInputValue2 * 60
            : 0
          : deleveryInputValue2
          ? deleveryInputValue2
          : 0)
      );
    }
  ),
  collectionInputValue2: Yup.string().test(
    "is-others-included",
    "max should be greater than min",
    function (value) {
      const {
        collectionSelectedOption1,
        collectionSelectedOption2,
        collectionInputValue1,
        collectionInputValue2,
      } = this.parent;
      return (
        (collectionSelectedOption1 === "Day"
          ? collectionInputValue1
            ? collectionInputValue1 * 24 * 60
            : 0
          : collectionSelectedOption1 === "Hour"
          ? collectionInputValue1
            ? collectionInputValue1 * 60
            : 0
          : collectionInputValue1
          ? collectionInputValue1
          : 0) <=
        (collectionSelectedOption2 === "Day"
          ? collectionInputValue2
            ? collectionInputValue2 * 24 * 60
            : 0
          : collectionSelectedOption2 === "Hour"
          ? collectionInputValue2
            ? collectionInputValue2 * 60
            : 0
          : collectionInputValue2
          ? collectionInputValue2
          : 0)
      );
    }
  ),
  dineInInputValue2: Yup.string().test(
    "is-others-included",
    "max should be greater than min",
    function (value) {
      const {
        dineSelectedOption1,
        dineSelectedOption2,
        dineInInputValue1,
        dineInInputValue2,
      } = this.parent;
      return (
        (dineSelectedOption1 === "Day"
          ? dineInInputValue1
            ? dineInInputValue1 * 24 * 60
            : 0
          : dineSelectedOption1 === "Hour"
          ? dineInInputValue1
            ? dineInInputValue1 * 60
            : 0
          : dineInInputValue1
          ? dineInInputValue1
          : 0) <=
        (dineSelectedOption2 === "Day"
          ? dineInInputValue2
            ? dineInInputValue2 * 24 * 60
            : 0
          : dineSelectedOption2 === "Hour"
          ? dineInInputValue2
            ? dineInInputValue2 * 60
            : 0
          : dineInInputValue2
          ? dineInInputValue2
          : 0)
      );
    }
  ),
  deliveryAmount: Yup.string().test(
    "is-others-included",
    "Requried",
    function (value) {
      return this.parent.deliveryAdvanceDiposit ? (value ? true : false) : true;
    }
  ),
  collectionAmount: Yup.string().test(
    "is-others-included",
    "Requried",
    function (value) {
      return this.parent.collectionAdvanceDiposit
        ? value
          ? true
          : false
        : true;
    }
  ),
  dineInAmount: Yup.string().test(
    "is-others-included",
    "Requried",
    function (value) {
      return this.parent.dineInAdvanceDiposit ? (value ? true : false) : true;
    }
  ),
});
const CreateAdvanceBooking: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const initialValues: InitialValues = {
    deleverySelectedOption1: "Minute",
    deleverySelectedOption2: "Minute",
    deleverySelectedOption3: "Minute",
    deleveryInputValue1: "",
    deleveryInputValue2: "",
    deleveryInputValue3: "",
    collectionSelectedOption1: "Minute",
    collectionSelectedOption2: "Minute",
    collectionSelectedOption3: "Minute",
    collectionInputValue1: "",
    collectionInputValue2: "",
    collectionInputValue3: "",
    dineSelectedOption1: "Minute",
    dineSelectedOption2: "Minute",
    dineSelectedOption3: "Minute",
    dineInInputValue1: "",
    dineInInputValue2: "",
    dineInInputValue3: "",
    advanceInputValue1: "",
    advanceInputValue2: "",
    advanceInputValue3: "",
    advanceSelectedOption1: "Minute",
    advanceSelectedOption2: "Minute",
    advanceSelectedOption3: "Minute",
    showTextField: false,
    notificationToggle: false,
    deliveryAdvanceDiposit: false,
    collectionAdvanceDiposit: false,
    dineInAdvanceDiposit: false,
    deliverySelect: "Flat",
    collectionSelect: "Flat",
    dineInSelect: "Flat",
    deliveryAmount: "",
    collectionAmount: "",
    dineInAmount: "",
    defaultToggle: false,
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      createAdvanceBooking({
        variables: {
          storeId: id && +id,
          deliveryMaxAdvanceTime:
            values.deleverySelectedOption1 === "Hour"
              ? +values.deleveryInputValue1! * 60
              : values.deleverySelectedOption1 === "Day"
              ? +values.deleveryInputValue1! * 1440
              : +values.deleveryInputValue1!,
          deliveryMinAdvanceTime:
            values.deleverySelectedOption2 === "Hour"
              ? +values.deleveryInputValue2! * 60
              : values.deleverySelectedOption2 === "Day"
              ? +values.deleveryInputValue2! * 1440
              : +values.deleveryInputValue2!,
          deliverySlotTime:
            values.deleverySelectedOption3 === "Hour"
              ? +values.deleveryInputValue3! * 60
              : values.deleverySelectedOption3 === "Day"
              ? +values.deleveryInputValue3! * 1440
              : +values.deleveryInputValue3! || 15,
          dineinMaxAdvanceTime:
            values.dineSelectedOption1 === "Hour"
              ? +values.dineInInputValue1! * 60
              : values.dineSelectedOption1 === "Day"
              ? +values.dineInInputValue1! * 1440
              : +values.dineInInputValue1!,
          dineinMinAdvanceTime:
            values.dineSelectedOption2 === "Hour"
              ? +values.dineInInputValue2! * 60
              : values.dineSelectedOption2 === "Day"
              ? +values.dineInInputValue2! * 1440
              : +values.dineInInputValue2!,
          dineinSlotTime:
            values.dineSelectedOption3 === "Hour"
              ? +values.dineInInputValue3! * 60
              : values.dineSelectedOption3 === "Day"
              ? +values.dineInInputValue3! * 1440
              : +values.dineInInputValue3! || 15,
          collectionMaxAdvanceTime:
            values.collectionSelectedOption1 === "Hour"
              ? +values.collectionInputValue1! * 60
              : values.collectionSelectedOption1 === "Day"
              ? +values.collectionInputValue1! * 1440
              : +values.collectionInputValue1!,
          collectionMinAdvanceTime:
            values.collectionSelectedOption2 === "Hour"
              ? +values.collectionInputValue2! * 60
              : values.collectionSelectedOption2 === "Day"
              ? +values.collectionInputValue2! * 1440
              : +values.collectionInputValue2!,
          collectionSlotTime:
            values.collectionSelectedOption3 === "Hour"
              ? +values.collectionInputValue3! * 60
              : values.collectionSelectedOption3 === "Day"
              ? +values.collectionInputValue3! * 1440
              : +values.collectionInputValue3! || 15, // replace with actual value
          acceptAdvanceOrders: formik.values.showTextField, // replace with actual value
          kitchenNotificationAfterAccept: formik.values.notificationToggle, // replace with actual value
          dineinNotificationTime:
            values.advanceSelectedOption1 === "Hour"
              ? +values.advanceInputValue1! * 60
              : values.advanceSelectedOption1 === "Day"
              ? +values.advanceInputValue1! * 1440
              : +values.advanceInputValue1!, // replace with actual value
          depositAmountForCollection: parseInt(formik.values.collectionAmount),
          depositAmountForDelivery: parseInt(formik.values.deliveryAmount),
          depositAmountForDinein: parseInt(formik.values.dineInAmount),
          depositTypeForCollection: formik.values.collectionSelect,
          depositTypeForDelivery: formik.values.deliverySelect,
          depositTypeForDinein: formik.values.dineInSelect,
          acceptDepositForCollection: formik.values.collectionAdvanceDiposit,
          acceptDepositForDelivery: formik.values.deliveryAdvanceDiposit,
          acceptDepositForDinein: formik.values.dineInAdvanceDiposit,
          applyDiscountsOnBookingDate: formik.values.defaultToggle,
          deliveryNotificationTime:
            values.advanceSelectedOption3 === "Hour"
              ? +values.advanceInputValue3! * 60
              : values.advanceSelectedOption3 === "Day"
              ? +values.advanceInputValue3! * 1440
              : +values.advanceInputValue3!, // replace with actual value

          // deliverySlotTime: values.advanceSelectedOption3 === "Hour" ? +values.advanceInputValue3! * 60 : values.advanceSelectedOption3 === "Day" ? +values.advanceInputValue3! * 1440 : +values.advanceInputValue3!, // replace with actual value
          collectionNotificationTime:
            values.advanceSelectedOption2 === "Hour"
              ? +values.advanceInputValue2! * 60
              : values.advanceSelectedOption2 === "Day"
              ? +values.advanceInputValue2! * 1440
              : +values.advanceInputValue2!, // replace with actual value
        },
        onCompleted: () => {
          navigate("/booking/advance-booking");
          toast.success(
            <div>
              <Typography> {t("toast.updated")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              className: "custom_toast3",
            }
          );
        },
      });
    },
  });

  const [createAdvanceBooking] = useMutation(CREATE_ADVANCE_BOOKINGS, {
    context: { clientName: "businessClient" },
  });
  const { data } = useQuery(GET_ADAVANCE_BOOKING, {
    variables: { storeId: id && +id },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  const handleGetDta = () => {
    if (data && data.storeSettings) {
      const initialValues = {
        deleverySelectedOption1:
          data.storeSettings.deliveryMaxAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.deliveryMaxAdvanceTime < 1440
            ? "Hour"
            : "Day",
        deleverySelectedOption2:
          data.storeSettings.deliveryMinAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.deliveryMinAdvanceTime < 1440
            ? "Hour"
            : "Day",
        deleverySelectedOption3:
          data.storeSettings.deliverySlotTime < 60
            ? "Minute"
            : data.storeSettings.deliverySlotTime < 1440
            ? "Hour"
            : "Day",
        deleveryInputValue1:
          data.storeSettings.deliveryMaxAdvanceTime > 1440
            ? data.storeSettings.deliveryMaxAdvanceTime / 1440
            : data.storeSettings.deliveryMaxAdvanceTime > 60
            ? data.storeSettings.deliveryMaxAdvanceTime / 60
            : data.storeSettings.deliveryMaxAdvanceTime,
        deleveryInputValue2:
          data.storeSettings.deliveryMinAdvanceTime > 1440
            ? data.storeSettings.deliveryMinAdvanceTime / 1440
            : data.storeSettings.deliveryMinAdvanceTime > 60
            ? data.storeSettings.deliveryMinAdvanceTime / 60
            : data.storeSettings.deliveryMinAdvanceTime,
        deleveryInputValue3:
          data.storeSettings.deliverySlotTime > 1440
            ? data.storeSettings.deliverySlotTime / 1440
            : data.storeSettings.deliverySlotTime > 60
            ? data.storeSettings.deliverySlotTime / 60
            : data.storeSettings.deliverySlotTime,
        collectionSelectedOption1:
          data.storeSettings.collectionMaxAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.collectionMaxAdvanceTime < 1440
            ? "Hour"
            : "Day",
        collectionSelectedOption2:
          data.storeSettings.collectionMinAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.collectionMinAdvanceTime < 1440
            ? "Hour"
            : "Day",
        collectionSelectedOption3:
          data.storeSettings.collectionSlotTime < 60
            ? "Minute"
            : data.storeSettings.collectionSlotTime < 1440
            ? "Hour"
            : "Day",
        collectionInputValue1:
          data.storeSettings.collectionMaxAdvanceTime > 1440
            ? data.storeSettings.collectionMaxAdvanceTime / 1440
            : data.storeSettings.collectionMaxAdvanceTime > 60
            ? data.storeSettings.collectionMaxAdvanceTime / 60
            : data.storeSettings.collectionMaxAdvanceTime,
        collectionInputValue2:
          data.storeSettings.collectionMinAdvanceTime > 1440
            ? data.storeSettings.collectionMinAdvanceTime / 1440
            : data.storeSettings.collectionMinAdvanceTime > 60
            ? data.storeSettings.collectionMinAdvanceTime / 60
            : data.storeSettings.collectionMinAdvanceTime,
        collectionInputValue3:
          data.storeSettings.collectionSlotTime > 1440
            ? data.storeSettings.collectionSlotTime / 1440
            : data.storeSettings.collectionSlotTime > 60
            ? data.storeSettings.collectionSlotTime / 60
            : data.storeSettings.collectionSlotTime,
        dineSelectedOption1:
          data.storeSettings.dineinMaxAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.dineinMaxAdvanceTime < 1440
            ? "Hour"
            : "Day",
        dineSelectedOption2:
          data.storeSettings.dineinMinAdvanceTime < 60
            ? "Minute"
            : data.storeSettings.dineinMinAdvanceTime < 1440
            ? "Hour"
            : "Day",
        dineSelectedOption3:
          data.storeSettings.dineinSlotTime < 60
            ? "Minute"
            : data.storeSettings.dineinSlotTime < 1440
            ? "Hour"
            : "Day",
        dineInInputValue1:
          data.storeSettings.dineinMaxAdvanceTime > 1440
            ? data.storeSettings.dineinMaxAdvanceTime / 1440
            : data.storeSettings.dineinMaxAdvanceTime > 60
            ? data.storeSettings.dineinMaxAdvanceTime / 60
            : data.storeSettings.dineinMaxAdvanceTime,
        dineInInputValue2:
          data.storeSettings.dineinMinAdvanceTime > 1440
            ? data.storeSettings.dineinMinAdvanceTime / 1440
            : data.storeSettings.dineinMinAdvanceTime > 60
            ? data.storeSettings.dineinMinAdvanceTime / 60
            : data.storeSettings.dineinMinAdvanceTime,
        dineInInputValue3:
          data.storeSettings.dineinSlotTime > 1440
            ? data.storeSettings.dineinSlotTime / 1440
            : data.storeSettings.dineinSlotTime > 60
            ? data.storeSettings.dineinSlotTime / 60
            : data.storeSettings.dineinSlotTime,
        advanceInputValue1:
          data.storeSettings.dineinNotificationTime > 1440
            ? data.storeSettings.dineinNotificationTime / 1440
            : data.storeSettings.dineinNotificationTime > 60
            ? data.storeSettings.dineinNotificationTime / 60
            : data.storeSettings.dineinNotificationTime,
        advanceInputValue2:
          data.storeSettings.collectionNotificationTime > 1440
            ? data.storeSettings.collectionNotificationTime / 1440
            : data.storeSettings.collectionNotificationTime > 60
            ? data.storeSettings.collectionNotificationTime / 60
            : data.storeSettings.collectionNotificationTime,
        advanceInputValue3:
          data.storeSettings.deliveryNotificationTime > 1440
            ? data.storeSettings.deliveryNotificationTime / 1440
            : data.storeSettings.deliveryNotificationTime > 60
            ? data.storeSettings.deliveryNotificationTime / 60
            : data.storeSettings.deliveryNotificationTime,
        advanceSelectedOption1:
          data.storeSettings.dineinNotificationTime < 60
            ? "Minute"
            : data.storeSettings.dineinNotificationTime < 1440
            ? "Hour"
            : "Day",
        advanceSelectedOption2:
          data.storeSettings.collectionNotificationTime < 60
            ? "Minute"
            : data.storeSettings.collectionNotificationTime < 1440
            ? "Hour"
            : "Day",
        advanceSelectedOption3:
          data.storeSettings.deliveryNotificationTime < 60
            ? "Minute"
            : data.storeSettings.deliveryNotificationTime < 1440
            ? "Hour"
            : "Day",
        showTextField: data.storeSettings.acceptAdvanceOrders,
        notificationToggle: data.storeSettings.kitchenNotificationAfterAccept,
        deliveryAdvanceDiposit: data.storeSettings.acceptDepositForDelivery,
        collectionAdvanceDiposit: data.storeSettings.acceptDepositForCollection,
        dineInAdvanceDiposit: data.storeSettings.acceptDepositForDinein,
        deliverySelect: data.storeSettings.depositTypeForDelivery,
        collectionSelect: data.storeSettings.depositTypeForCollection,
        dineInSelect: data.storeSettings.depositTypeForDinein,
        deliveryAmount: data.storeSettings.depositAmountForDelivery
          ? data.storeSettings.depositAmountForDelivery
          : "",
        collectionAmount: data.storeSettings.depositAmountForCollection
          ? data.storeSettings.depositAmountForCollection
          : "",
        dineInAmount: data.storeSettings.depositAmountForDinein
          ? data.storeSettings.depositAmountForDinein
          : "",
        defaultToggle: data.storeSettings.applyDiscountsOnBookingDate || false,
      };

      formik.setValues(initialValues);
      // const list = data.storeSettings.paymentTypesForAdvanceBooking && JSON.parse(data.storeSettings.paymentTypesForAdvanceBooking).map((item: any) => {
      //   return {
      //     selectType: item.type,
      //     amount: item.amount,
      //     orderType: item.order_type
      //   }
      // })

      // if (data.storeSettings.paymentTypesForAdvanceBooking) {
      //   setRows(list)
      // }
    }
  };
  useEffect(() => {
    handleGetDta();
  }, [data]);

  const navigate = useNavigate();

  const handleNavigateExit = () => {
    navigate("/booking/advance-booking");
  };
  // const initialData: RowData[] = [
  //   { selectType: 'Flat', amount: '', orderType: '' },
  // ];

  // const handleAddRow = () => {
  //   const lastRow = rows && rows[rows.length - 1]; // Get the last row in the array
  //   const newRow: RowData = {
  //     selectType: lastRow.selectType,
  //     amount: lastRow.amount,
  //     orderType: lastRow.orderType,
  //   };

  //   setRows([...rows, newRow]); // Add the new row to the rows array and update the state
  // };

  // const handleDeleteRow = (index: number) => {
  //   const updatedRows = rows.filter((_, rowIndex) => rowIndex !== index);
  //   setRows(updatedRows);
  // };
  // const options1 = [
  //   { value: 'delivery', label: 'Delivery' },
  //   { value: 'collection', label: 'Collection' },
  //   { value: 'dinIn', label: 'DineIn' },
  // ];

  const [value, setValue] = useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  // const [rows, setRows] = useState<RowData[]>(initialData);

  // const formatedRows = rows && rows.map((row) => {
  //   return {
  //     type: row && row.selectType,
  //     amount: row && +row.amount,
  //     order_type: row && row.orderType,
  //   }
  // })

  // const handleSelectChange = (
  //   e: SelectChangeEvent<string>,

  //   rowIndex: number,
  //   field: keyof RowData
  // ) => {
  //   const updatedRows = [...rows];
  //   updatedRows[rowIndex][field] = e.target.value as string;
  //   setRows(updatedRows);
  // };

  // const handleTextChange = (
  //   e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, // Use ChangeEvent<HTMLInputElement> here
  //   rowIndex: number,
  //   field: keyof RowData
  // ) => {
  //   const updatedRows = [...rows];
  //   updatedRows[rowIndex][field] = e.target.value;
  //   setRows(updatedRows);
  // };

  const handleCancel = () => {
    // handleGetDta()
    formik.resetForm();
  };

  const currencySymbol = sessionStorage.getItem("currencySymbol");

  // useEffect(() => {
  //   if (formik.values.deleveryInputValue1 !== null &&
  //     formik.values.deleveryInputValue2 !== null &&
  //     (
  //       formik.values.deleverySelectedOption1 === "Day"
  //         ? formik.values.deleveryInputValue1 * 24 * 60
  //         : formik.values.deleverySelectedOption1 === "Hour"
  //           ? formik.values.deleveryInputValue1 * 60
  //           : formik.values.deleveryInputValue1
  //     ) >=
  //     (
  //       formik.values.deleverySelectedOption2 === "Day"
  //         ? formik.values.deleveryInputValue2 * 24 * 60
  //         : formik.values.deleverySelectedOption2 === "Hour"
  //           ? formik.values.deleveryInputValue2 * 60
  //           : formik.values.deleveryInputValue2
  //     )) {
  //     formik.setFieldError(
  //       "deleveryInputValue1",
  //       "Min should be less than Max"
  //     )

  //   }
  //   else {
  //     formik.setFieldError(
  //       'deleveryInputValue1', ""
  //     )

  //   }

  // }, [formik.values])

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} style={{ paddingBottom: "10px" }}>
          <div
            className="create_tax_head_container"
            style={{ padding: "12px 20px 0px 12px" }}
          >
            <div className="create_exitIcon">
              <ArrowBackIcon
                onClick={handleNavigateExit}
                sx={{ height: "24px", width: "24px" }}
              />
            </div>
            <Typography className="create_tax_main">
              {t("settings.advanced_bookings")}
            </Typography>
            <div className="create_tax_button_con">
              <Button
                variant="contained"
                className="create_tax_button_style"
                onClick={handleCancel}
              >
                <Typography>{t("settings.clear")}</Typography>
              </Button>
              <Button
                variant="contained"
                className="create_tax_button_style1"
                type="submit"
              >
                <Typography>{t("settings.save")}</Typography>
              </Button>
            </div>
          </div>

          <Paper
            style={{
              marginLeft: "8%",
              marginTop: "44px",
              width: "83%",
              padding: "20px 12px 20px 12px",
              borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)",
            }}
            elevation={1}
            className="create_tax_page1"
          >
            <Typography className="craete_advance_head1">
              {t("settings.advanced_booking_para")}
              {/* <span className='create_advance_span'>{t('settings.advanced_span')}</span> */}
            </Typography>

            <Box
              sx={{ width: "98%", marginTop: "20px" }}
              className="border_style"
            >
              <TabContext value={value}>
                <Box>
                  <TabContext value={value}>
                    <TabList onChange={handleChange} className="tabColor">
                      <Tab
                        //  label={ Boolean(formik.errors.deleveryInputValue2)&& "ashok"}
                        label={
                          <Box sx={{ display: "flex", gap: "4px" }}>
                            <Typography>{t("settings.delivery")}</Typography>
                            <Typography>
                              {Boolean(
                                formik.errors.deleveryInputValue2 ||
                                  (formik.touched.deliveryAmount &&
                                    formik.errors.deliveryAmount)
                              ) ? (
                                <ErrorIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        }
                        value="1"
                        className={value === "1" ? "change_color" : "add_color"}
                      />
                      <Tab
                        // label={t("settings.collections")}
                        label={
                          <Box sx={{ display: "flex", gap: "4px" }}>
                            <Typography>{t("settings.collections")}</Typography>
                            <Typography>
                              {Boolean(
                                formik.errors.collectionInputValue2 ||
                                  (formik.touched.collectionAmount &&
                                    formik.errors.collectionAmount)
                              ) ? (
                                <ErrorIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        }
                        value="2"
                        className={value === "2" ? "change_color" : "add_color"}
                      />
                      <Tab
                        // label={t("settings.dine_in")}
                        label={
                          <Box sx={{ display: "flex", gap: "4px" }}>
                            <Typography>{t("settings.dine_in")}</Typography>
                            <Typography>
                              {Boolean(
                                formik.errors.dineInInputValue2 ||
                                  (formik.touched.dineInAmount &&
                                    formik.errors.dineInAmount)
                              ) ? (
                                <ErrorIcon sx={{ color: "red" }} />
                              ) : (
                                ""
                              )}
                            </Typography>
                          </Box>
                        }
                        value="3"
                        className={value === "3" ? "change_color" : "add_color"}
                      />
                    </TabList>
                  </TabContext>
                </Box>

                <TabPanel value="1" style={{ padding: "0px" }}>
                  <div
                    style={{ backgroundColor: "#FEFEFE", paddingLeft: "20px" }}
                  >
                    <Typography className="create_advance_tab_head">
                      {t("settings.advance_booking_time")}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginTop: "10px" }}
                        >
                          {t("settings.min_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            sx={{ width: "45%" }}
                            value={formik.values.deleveryInputValue1}
                            name="deleveryInputValue1"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{ marginLeft: "10px", marginTop: "15px" }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.deleverySelectedOption1}
                              name="deleverySelectedOption1"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.deleveryInputValue1
                              ? formik.values.deleveryInputValue1.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                      <div style={{ width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginTop: "10px", marginLeft: "10%" }}
                        >
                          {t("settings.max_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            marginLeft: "10%",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            value={formik.values.deleveryInputValue2}
                            name="deleveryInputValue2"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            onBlur={formik.handleBlur}
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{
                              marginLeft: "10px",
                              width: "45%",
                              marginTop: "15px",
                            }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.deleverySelectedOption2}
                              name="deleverySelectedOption2"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.deleveryInputValue2
                              ? formik.values.deleveryInputValue2.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                        {formik.errors.deleveryInputValue2 ? (
                          // &&
                          //  formik.touched.deleveryInputValue2
                          <div
                            style={{
                              color: "red",
                              marginLeft: "10%",
                              marginTop: "5px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.deleveryInputValue2}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ marginLeft: "10%", width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginTop: "10px" }}
                        >
                          {t("settings.time_slot")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            value={formik.values.deleveryInputValue3}
                            name="deleveryInputValue3"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{
                              marginLeft: "10px",
                              marginTop: "15px",
                              width: "45%",
                            }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.deleverySelectedOption3}
                              name="deleverySelectedOption3"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.deleveryInputValue3
                              ? formik.values.deleveryInputValue3.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                    </div>

                    <Divider style={{ marginTop: "20px" }} />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.deliveryAdvanceDiposit}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          name="deliveryAdvanceDiposit"
                          color="warning"
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography className="main-head16">
                          {t("settings.advance_deposit")}
                        </Typography>
                      }
                      style={{ marginTop: "20px" }}
                    />

                    {formik.values.deliveryAdvanceDiposit && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "48px",
                          width: "100%",
                          marginBottom: "12px",
                          mt: "12px",
                        }}
                      >
                        <FormControl
                          className="create_advance_select"
                          style={{ width: "30%" }}
                        >
                          <InputLabel className="create_label2">
                            {t("settings.select%")}
                          </InputLabel>
                          <Select
                            label={t("settings.select%")}
                            value={formik.values.deliverySelect}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            name="deliverySelect"
                            color="warning"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          >
                            <MenuItem value="PERCENTAGE">%</MenuItem>
                            <MenuItem value="Flat">
                              {t("settings.flat")}
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <Box>
                          <TextField
                            label={
                              <Typography className="create_label2">
                                {t("settings.amount")}
                              </Typography>
                            }
                            variant="outlined"
                            value={formik.values.deliveryAmount}
                            name="deliveryAmount"
                            onBlur={formik.handleBlur}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {formik.values.deliverySelect === "Flat"
                                    ? currencySymbol
                                    : "%"}
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                            className="hide-number-input-icons"
                            // style={{ width: '30%' }}
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          />
                          <Box sx={{ display: "flex" }}>
                            {formik.touched.deliveryAmount &&
                            formik.errors.deliveryAmount ? (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                }}
                              >
                                {formik.errors.deliveryAmount}
                              </div>
                            ) : null}
                            <Box sx={{ marginLeft: "auto", mt: "8px" }}>
                              <Typography
                                sx={{ fontSize: "12px", color: "#707070" }}
                              >
                                {formik.values.deliveryAmount
                                  ? formik.values.deliveryAmount.toString()
                                      .length
                                  : 0}
                                /5
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </div>
                </TabPanel>

                <TabPanel value="2" style={{ padding: "0px" }}>
                  <div
                    style={{ backgroundColor: "#FEFEFE", paddingLeft: "20px" }}
                  >
                    <Typography className="create_advance_tab_head">
                      {t("settings.advance_collection")}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("settings.min_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            sx={{ width: "45%" }}
                            value={formik.values.collectionInputValue1}
                            name="collectionInputValue1"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{ marginLeft: "10px", marginTop: "15px" }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.collectionSelectedOption1}
                              name="collectionSelectedOption1"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.collectionInputValue1
                              ? formik.values.collectionInputValue1.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                      <div style={{ width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginBottom: "10px", marginLeft: "10%" }}
                        >
                          {t("settings.max_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                            marginLeft: "10%",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            sx={{ width: "45%" }}
                            value={formik.values.collectionInputValue2}
                            name="collectionInputValue2"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{ marginLeft: "10px", marginTop: "15px" }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.collectionSelectedOption2}
                              name="collectionSelectedOption2"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.collectionInputValue2
                              ? formik.values.collectionInputValue2.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                        {formik.errors.collectionInputValue2 ? (
                          // &&
                          //  formik.touched.deleveryInputValue2
                          <div
                            style={{
                              color: "red",
                              marginLeft: "10%",
                              marginTop: "5px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.collectionInputValue2}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ width: "25%", marginLeft: "10%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginTop: "10px" }}
                        >
                          {t("settings.time_slot")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            sx={{ width: "45%" }}
                            value={formik.values.collectionInputValue3}
                            name="collectionInputValue3"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{ marginLeft: "10px", marginTop: "15px" }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.collectionSelectedOption3}
                              name="collectionSelectedOption3"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.collectionInputValue3
                              ? formik.values.collectionInputValue3.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                    </div>
                    <Divider style={{ marginTop: "20px" }} />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.collectionAdvanceDiposit}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          name="collectionAdvanceDiposit"
                          color="warning"
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography className="main-head16">
                          {t("settings.advance_deposit")}
                        </Typography>
                      }
                      style={{ marginTop: "20px" }}
                    />

                    {formik.values.collectionAdvanceDiposit && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "48px",
                          width: "100%",
                          marginBottom: "12px",
                          mt: "12px",
                        }}
                      >
                        <FormControl
                          className="create_advance_select"
                          style={{ width: "30%" }}
                        >
                          <InputLabel className="create_label2">
                            {t("settings.select%")}
                          </InputLabel>
                          <Select
                            label={t("settings.select%")}
                            value={formik.values.collectionSelect}
                            name="collectionSelect"
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            color="warning"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          >
                            <MenuItem value="PERCENTAGE">%</MenuItem>
                            <MenuItem value="Flat">
                              {t("settings.flat")}
                            </MenuItem>
                          </Select>
                        </FormControl>
                        <Box>
                          <TextField
                            label={
                              <Typography className="create_label2">
                                {t("settings.amount")}
                              </Typography>
                            }
                            variant="outlined"
                            value={formik.values.collectionAmount}
                            onBlur={formik.handleBlur}
                            name="collectionAmount"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {formik.values.collectionSelect === "Flat"
                                    ? currencySymbol
                                    : "%"}

                                  {/* {currencySymbol} */}
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          />
                          <Box sx={{ display: "flex" }}>
                            {formik.touched.collectionAmount &&
                            formik.errors.collectionAmount ? (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                }}
                              >
                                {formik.errors.collectionAmount}
                              </div>
                            ) : null}
                            <Box sx={{ marginLeft: "auto", mt: "8px" }}>
                              <Typography
                                sx={{ fontSize: "12px", color: "#707070" }}
                              >
                                {formik.values.collectionAmount
                                  ? formik.values.collectionAmount.toString()
                                      .length
                                  : 0}
                                /5
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </div>
                </TabPanel>

                <TabPanel value="3" style={{ padding: "0px" }}>
                  <div
                    style={{ backgroundColor: "#FEFEFE", paddingLeft: "20px" }}
                  >
                    <Typography className="create_advance_tab_head">
                      {t("settings.advance_dining")}
                    </Typography>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "flex-start",
                      }}
                    >
                      <div style={{ width: "25%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("settings.min_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            value={formik.values.dineInInputValue1}
                            name="dineInInputValue1"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{
                              marginLeft: "10px",
                              marginTop: "15px",
                              width: "45%",
                            }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.dineSelectedOption1}
                              name="dineSelectedOption1"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.dineInInputValue1
                              ? formik.values.dineInInputValue1.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                      <div style={{ width: "25%", marginLeft: "10%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginBottom: "10px" }}
                        >
                          {t("settings.max_time_advance")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            value={formik.values.dineInInputValue2}
                            name="dineInInputValue2"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{
                              marginLeft: "10px",
                              marginTop: "15px",
                              width: "45%",
                            }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.dineSelectedOption2}
                              name="dineSelectedOption2"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.dineInInputValue2
                              ? formik.values.dineInInputValue2.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                        {formik.errors.dineInInputValue2 ? (
                          // &&
                          //  formik.touched.deleveryInputValue2
                          <div
                            style={{
                              color: "red",
                              marginLeft: "10%",
                              marginTop: "5px",
                              textTransform: "capitalize",
                              fontSize: "12px",
                            }}
                          >
                            {formik.errors.dineInInputValue2}
                          </div>
                        ) : null}
                      </div>
                      <div style={{ width: "25%", marginLeft: "10%" }}>
                        <Typography
                          className="create_tax_exception"
                          style={{ marginTop: "10px" }}
                        >
                          {t("settings.time_slot")}
                        </Typography>
                        <div
                          style={{
                            display: "flex",
                            width: "100%",
                            height: "48px",
                          }}
                          className="refund_input_border"
                        >
                          <TextField
                            variant="standard"
                            value={formik.values.dineInInputValue3}
                            name="dineInInputValue3"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                            style={{
                              marginLeft: "10px",
                              marginTop: "15px",
                              width: "45%",
                            }}
                          />
                          <FormControl
                            style={{
                              width: "45%",
                              marginBottom: "5px",
                              marginTop: "12px",
                              marginRight: "10px",
                            }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.dineSelectedOption3}
                              name="dineSelectedOption3"
                              onChange={(e) => {
                                formik.handleChange(e);
                              }}
                              style={{ marginLeft: "10px" }}
                            >
                              {options.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right", mt: "8px" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.dineInInputValue3
                              ? formik.values.dineInInputValue3.toString()
                                  .length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </div>
                    </div>
                    <Divider style={{ marginTop: "20px" }} />

                    <FormControlLabel
                      control={
                        <Switch
                          checked={formik.values.dineInAdvanceDiposit}
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          name="dineInAdvanceDiposit"
                          color="warning"
                        />
                      }
                      labelPlacement="start"
                      label={
                        <Typography className="main-head16">
                          {t("settings.advance_deposit")}
                        </Typography>
                      }
                      style={{ marginTop: "20px" }}
                    />

                    {formik.values.dineInAdvanceDiposit && (
                      <Box
                        sx={{
                          display: "flex",
                          gap: "48px",
                          width: "100%",
                          marginBottom: "12px",
                          mt: "12px",
                        }}
                      >
                        <FormControl
                          className="create_advance_select"
                          style={{ width: "30%" }}
                        >
                          <InputLabel className="create_label2">
                            {t("settings.select%")}
                          </InputLabel>
                          <Select
                            label={t("settings.select%")}
                            value={formik.values.dineInSelect}
                            onChange={(e) => {
                              formik.handleChange(e);
                            }}
                            name="dineInSelect"
                            color="warning"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          >
                            <MenuItem value="PERCENTAGE">%</MenuItem>
                            <MenuItem value="Flat">
                              {t("settings.flat")}
                            </MenuItem>
                          </Select>
                        </FormControl>

                        <Box>
                          <TextField
                            label={
                              <Typography className="create_label2">
                                {t("settings.amount")}
                              </Typography>
                            }
                            variant="outlined"
                            onBlur={formik.handleBlur}
                            value={formik.values.dineInAmount}
                            name="dineInAmount"
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  {formik.values.dineInSelect === "Flat"
                                    ? currencySymbol
                                    : "%"}
                                </InputAdornment>
                              ),
                            }}
                            color="warning"
                            className="hide-number-input-icons"
                            sx={{
                              "&:hover": {
                                "&& fieldset": {
                                  border: "2px solid #F9C584",
                                },
                              },
                            }}
                          />
                          <Box sx={{ display: "flex" }}>
                            {formik.touched.dineInAmount &&
                            formik.errors.dineInAmount ? (
                              <div
                                style={{
                                  color: "red",
                                  marginTop: "5px",
                                  textTransform: "capitalize",
                                  fontSize: "12px",
                                }}
                              >
                                {formik.errors.dineInAmount}
                              </div>
                            ) : null}
                            <Box sx={{ marginLeft: "auto", mt: "8px" }}>
                              <Typography
                                sx={{ fontSize: "12px", color: "#707070" }}
                              >
                                {formik.values.dineInAmount
                                  ? formik.values.dineInAmount.toString().length
                                  : 0}
                                /5
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                    )}
                  </div>
                </TabPanel>
              </TabContext>
            </Box>

            {/* {modal && (
              <BasicModal />
            )} */}
          </Paper>

          <Paper
            elevation={1}
            className="create_tax_page1"
            style={{
              marginLeft: "8%",
              marginTop: "44px",
              width: "83%",
              padding: "20px 12px 20px 12px",
              borderLeft: "2px solid var(--stroke-color-3-green, #BAE29B)",
            }}
          >
            <Typography className="craete_advance_head1">
              {t("settings.advance_setting")}
            </Typography>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "24px",
              }}
            >
              <Typography
                className="craete_advance_head2"
                style={{ display: "flex", marginRight: "30px" }}
              >
                {t("settings.auto_accept")}
              </Typography>
              <Switch
                onClick={(e) => formik.handleChange(e)}
                checked={formik.values.showTextField}
                name="showTextField"
                color="warning"
              />
            </div>

            {formik.values.showTextField && (
              <div style={{ width: "70%" }}>
                {/* Notification Time Fields */}
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography className="craete_advance_head2">
                      {t("settings.notification_time")}
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                    }}
                  >
                    <Typography className="create_tax_exception">
                      {t("settings.enter_value")}
                    </Typography>
                    <div
                      style={{ display: "flex", width: "100%", height: "48px" }}
                      className="refund_input_border"
                    >
                      <TextField
                        variant="standard"
                        value={formik.values.advanceInputValue1}
                        name="advanceInputValue1"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        className="hide-number-input-icons create_advance_input"
                        placeholder="10"
                        InputProps={{
                          disableUnderline: true, // This removes the underline
                        }}
                        style={{
                          marginLeft: "10px",
                          marginTop: "15px",
                          width: "45%",
                        }}
                      />
                      <FormControl
                        style={{
                          width: "45%",
                          marginBottom: "5px",
                          marginTop: "12px",
                          marginRight: "10px",
                        }}
                        className="create_advance_select"
                        variant="standard"
                      >
                        <Select
                          value={formik.values.advanceSelectedOption1}
                          name="advanceSelectedOption1"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          style={{ marginLeft: "10px" }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography className="craete_advance_head2">
                      Notification time for Collection before
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                    }}
                  >
                    <Typography className="create_tax_exception">
                      {t("settings.enter_value")}
                    </Typography>
                    <div
                      style={{ display: "flex", width: "100%", height: "48px" }}
                      className="refund_input_border"
                    >
                      <TextField
                        variant="standard"
                        value={formik.values.advanceInputValue2}
                        name="advanceInputValue2"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        className="hide-number-input-icons create_advance_input"
                        placeholder="10"
                        InputProps={{
                          disableUnderline: true, // This removes the underline
                        }}
                        style={{
                          marginLeft: "10px",
                          marginTop: "15px",
                          width: "45%",
                        }}
                      />
                      <FormControl
                        style={{
                          width: "45%",
                          marginBottom: "5px",
                          marginTop: "12px",
                          marginRight: "10px",
                        }}
                        className="create_advance_select"
                        variant="standard"
                      >
                        <Select
                          value={formik.values.advanceSelectedOption2}
                          name="advanceSelectedOption2"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          style={{ marginLeft: "10px" }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "12px",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Typography className="craete_advance_head2">
                      Notification time for Delivery before
                    </Typography>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "30%",
                    }}
                  >
                    <Typography className="create_tax_exception">
                      {t("settings.enter_value")}
                    </Typography>
                    <div
                      style={{ display: "flex", width: "100%", height: "48px" }}
                      className="refund_input_border"
                    >
                      <TextField
                        variant="standard"
                        value={formik.values.advanceInputValue3}
                        name="advanceInputValue3"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;
                          if (
                            (!val || /^[0-9]+$/.test(val)) &&
                            val.length <= 5
                          ) {
                            formik.handleChange(e);
                          }
                        }}
                        type="text"
                        className="hide-number-input-icons create_advance_input"
                        placeholder="10"
                        InputProps={{
                          disableUnderline: true, // This removes the underline
                        }}
                        style={{
                          marginLeft: "10px",
                          marginTop: "15px",
                          width: "45%",
                        }}
                      />
                      <FormControl
                        style={{
                          width: "45%",
                          marginBottom: "5px",
                          marginTop: "12px",
                          marginRight: "10px",
                        }}
                        className="create_advance_select"
                        variant="standard"
                      >
                        <Select
                          value={formik.values.advanceSelectedOption3}
                          name="advanceSelectedOption3"
                          onChange={(e) => {
                            formik.handleChange(e);
                          }}
                          style={{ marginLeft: "10px" }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>
                {/* Repeat similar blocks for other notification time fields */}
              </div>
            )}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <Typography
                className="craete_advance_head2"
                style={{ marginRight: "30px" }}
              >
                {t("settings.notification_send")}
              </Typography>
              <Switch
                onClick={(e) => formik.handleChange(e)}
                checked={formik.values.notificationToggle}
                name="notificationToggle"
                color="warning"
              />
            </div>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "18px",
              }}
            >
              <Typography
                className="craete_advance_head2"
                style={{ marginRight: "30px" }}
              >
                By default, apply discounts that are currently active on the day
                of Collection, Delivery, or Dine In.
              </Typography>
              <Tooltip
                title={
                  <div>
                    <Typography className="manage_toolTip_head">
                      {/* {t("settings.customTips_para1")}<br /> */}
                      <span className="manageTips_toolTip_text">
                        By default, apply discounts that are currently active on
                        the day of Collection, Delivery, or Dine In.
                        <br />
                        Alternatively, consider discounts that are active at the
                        time of booking.
                        <br />
                        {/* {t("settings.customTips_para4")} */}
                      </span>
                    </Typography>
                  </div>
                }
                placement="bottom-start"
                classes={{ tooltip: "custom-tooltip" }}
                style={{ marginRight: "106px" }}
              >
                <Button sx={{ m: 1 }}>
                  <div
                    style={{ position: "relative", display: "inline-block" }}
                    className="custom-icon-container"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                    </svg>
                    <Typography
                      component="span"
                      style={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        color: "#ffffff",
                        fontWeight: "bold",
                        fontSize: "16px",
                      }}
                    >
                      !
                    </Typography>
                  </div>
                </Button>
              </Tooltip>{" "}
              <Switch
                onClick={formik.handleChange}
                checked={formik.values.defaultToggle}
                name="defaultToggle"
                color="warning"
              />
            </div>
          </Paper>
          <div></div>
        </Paper>
      </form>
    </div>
  );
};

export default CreateAdvanceBooking;
