import * as React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

export default function CircularLoader() {
  return (
    <Box sx={{ display: 'flex', justifyContent: "center", alignItems: "center", height: "80vh" }}>
      <CircularProgress color="warning" size={50} />
    </Box>
  );
}