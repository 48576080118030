import { gql } from "@apollo/client";

export const BUNDLE_LIST_DATA = gql`
query bundleList($name_Icontains:String,$productId:ID,$businessId:ID,$locationId:ID,$first:Int,$after:String,$before:String,$last:Int){
    bundleList(businessId:$businessId,name_Icontains:$name_Icontains,locationId:$locationId,productId:$productId,first:$first,after:$after,before:$before,last:$last) {
          totalCount   
  pageInfo{hasNextPage,startCursor,endCursor,hasPreviousPage}
            edges{node{bundleId
                amountAfterDiscount
                bundleStatus
				isSetMeals
				image
                noOfProducts
                name}
            
                }
            }
        
    }
`

export const PRODUCT_LIST_IN_BUNDLE = gql`
query productList($locationId:ID,$businessId:ID,$name_Icontains:String){
    productList(businessId:$businessId,locationId:$locationId,name_Icontains:$name_Icontains,productUnit:"per_item",productStatus:"Active") {
		pageInfo{hasNextPage
		hasPreviousPage}
		edges{node{
			productId
			name
			price
image

			course{
				name
			}
			image

			variants{variantId
			variantGroup
				price
				name}
		

		}
		}
	
}
	

        
    }
`

export const EDIT_BUNDLE_DATA = gql`
query Bundle($bundleId:ID){
	bundle(bundleId:$bundleId) {
		bundleId
				slugName
		noOfProducts
		description
		isPos
		isOnline
		locationId
	
	amount
	amountAfterDiscount
	discountPercent
		image
		bgColourCode
		bundleGroups
		isDinein
	isCarry
	isCollection
	isSetMeals
	isDelivery
				name}
			
				}
`
