import { gql } from "@apollo/client";

export const CREATE_REPORT_GROUP=gql`
mutation createReportingGroup($name:String,$businessId:Int!){
	createReportingGroup(
		name:$name,
		businessId:$businessId
		
	)
	{errorMessage
		success
		reportingGroup{reportingGroupId
		name
		businessId}
		
}
}
`

export const UPDATE_REPORTING_GROUP=gql`
mutation UpdateReportingGroup($name:String,$reportingGroupId:Int!){
	updateReportingGroup(
		name:$name,
		reportingGroupId:$reportingGroupId
		
	)
	{errorMessage
		success
		reportingGroup{reportingGroupId
		name
		businessId}
		
}
}
`

export const DELETE_REPORTING_GROUP=gql`
mutation DeleteReportingGroups($reportingGroupIds:JSONString!){
	deleteReportingGroups(
	
		reportingGroupIds:$reportingGroupIds
		
	)
	{message
		success
		
		
}
}
`