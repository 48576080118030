import { Box, Button, Checkbox, Switch, Typography } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CALLER_ID_SETTINGS } from "../../../../graphicalQl/mutation/customerDataMutation";
import { STORE_SETTINGS } from "../../../../graphicalQl/usequery/customerDataQuery"
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface Shifts {
  id: string;
  title: string;
  label: string;
  link: string;
  enabled: boolean;
}
const CallerId = () => {
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { t } = useTranslation();
  const { data, loading, error, refetch } = useQuery(STORE_SETTINGS, {
    variables: {
      storeId: +locationId,
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  const [featureDetails, setFeatureDetails] = useState<Shifts[]>([]);

  const [
    updateCallerIdSetting,
    {
      data: updateupdateCallerIdSettingData,
      error: updateupdateCallerIdSettingError,
      loading: submitLoading
    },
  ] = useMutation(UPDATE_CALLER_ID_SETTINGS, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(<div >

        <Typography>{t("toast.updated")}</Typography>
      </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          className: 'custom_toast3',
        })
    },
    onError: (error) => {
      console.error('Error:', error);
    },
  });

  //check for successfull updated and perform the required functions


  //check for successfull updated and perform the required functions
  useEffect(() => {
    if (data?.storeSettings) {
      setFeatureDetails([
        {
          id: "allowCustomerToOrderThroughCall",
          title: t("callerId.text1"),
          label: "Track cash that goes in and out of your drawer. Learn more",
          link: "https://www.google.com",
          enabled: data?.storeSettings?.allowCustomerToOrderThroughCall,
        },
        {
          id: "allowCallerIdNotifications",
          title: t("callerId.text2"),
          label:
            "Track employees' clock in/out time and calculate their total work hours. Learn more",
          link: "https://www.google.com",
          enabled: data?.storeSettings?.allowCallerIdNotifications,
        },
        {
          id: "showCustomerDetailsInCallerid",
          title: t("callerId.text3"),
          label:
            "Track employees' clock in/out time and calculate their total work hours. Learn more",
          link: "https://www.google.com",
          enabled: data?.storeSettings?.showCustomerDetailsInCallerid,
        },
      ]);
    }
  }, [data, t]);

  const saveCallerIdSettings = () => {
    const variables: { [key: string]: boolean | number } = {};
    // Add type annotation for variables object
    variables["storeId"] = +locationId;
    featureDetails.forEach(({ id, enabled }) => {
      variables[id] = enabled;
    });

    updateCallerIdSetting({ variables });
  };
  return (
    <Box>
      <Box
        sx={{
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          {t("callerId.caller_id")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "4px",
          }}
        >
          {t("callerId.callerId_prefernces")}
        </Typography>
      </Box>
      <Box
        sx={{
          width: "691px",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          padding: "20px 12px",
          boxSizing: "border-box",
          borderLeft: "2px solid #AE9BE2",
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
          marginLeft: "32px",
          marginTop: "32px",
        }}
      >
        {featureDetails.map(({ title, label, enabled, link }, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "681px",
              height: "30px",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {title}
            </Typography>

            {/* <Checkbox
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 50 },
              }}
              icon={<ToggleOffIcon sx={{ color: "#F38B08" }} />}
              checkedIcon={<ToggleOnIcon sx={{ color: "#F38B08" }} />}
              checked={enabled}
              tabIndex={-1}
              onClick={() => {
                const currentFeatureDetails = featureDetails;
                currentFeatureDetails[index].enabled =
                  !currentFeatureDetails[index].enabled;
                setFeatureDetails([...currentFeatureDetails]);
              }}
            /> */}
            <Switch color="warning" onClick={() => {
              const currentFeatureDetails = featureDetails;
              currentFeatureDetails[index].enabled =
                !currentFeatureDetails[index].enabled;
              setFeatureDetails([...currentFeatureDetails]);
            }}
              checked={enabled} />
          </Box>
        ))}

        <Button
          onClick={() => saveCallerIdSettings()}
          sx={{
            width: "30%",
            height: "44px",
            padding: "8px 16px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            borderRadius: "4px",
            background: "#F38B08",
            marginTop: "12px",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
          disabled={submitLoading}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              cursor: "pointer"
            }}
          >
            {t("buttons.save")}
          </Typography>
        </Button>
      </Box>
    </Box>
  );
};

export default CallerId;
