import React, { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import "./reportingGroup.css";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import { DELETE_REPORTING_GROUP } from "../../graphicalQl/mutation/reportingMutation";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDailouge from "../category/deleteDailouge";
import CreateReportingGroup from "./createReporting";
import { REPORTING_LIST } from "../../graphicalQl/usequery/reportingGroup";
import { toast } from "react-toastify";
interface Row {
  id: string;
  productCount: number;
  name: string;
}

function ReportListing() {
  const { t } = useTranslation();

  const [categoryList, setCategoryList] = useState<any>([]);

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { loading, data } = useQuery(REPORTING_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "cache-and-network",
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const [deletemodal, setDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    // setRowSelectionModel([])
  };

  useEffect(() => {
    if (data) {
      setCategoryList(data.reportingGroupList.edges);
    }
  }, [data]);

  const rows: Row[] = categoryList.map((item: any, index: number) => ({
    id: item.node.reportingGroupId,
    name: item.node.name,
    productCount: item.node.productCount,
  }));

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteReportValue, { loading: deleteLoader }] = useMutation(
    DELETE_REPORTING_GROUP,
    {
      onError: (error) => {
        console.error("Error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [
        // Specify the query using an object with a 'query' property
        REPORTING_LIST,
      ],
      onCompleted: () => {
        toast.success(
          <div>
            <Typography>{t("toast.removed")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
    }
  );
  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state

    const updatedRows = rows.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    const categoryValue = updatedRows.map((each) => +each.id);

    deleteReportValue({
      variables: { reportingGroupIds: JSON.stringify(categoryValue) },
    });
    setRowSelectionModel([]);
  };
  // const handleEdit=(id:string)=>{
  //   navigate(`/${id}/update/category` )
  // }

  const [categoryIdValue, setCategoryIdValue] = useState("");
  const getCategoryId = (id: string) => {
    setCategoryIdValue(id);
  };
  const handleDleteByPopover = (id: string) => {
    deleteReportValue({
      variables: { reportingGroupIds: JSON.stringify([+id]) },
    });
    setCategoryIdValue("");
  };

  // function CustomPopoverCell({ categoryId }: { categoryId: string }) {

  //   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  //   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
  //     setAnchorEl(event.currentTarget);
  //   };

  //   const handleClose = () => {
  //     setAnchorEl(null);
  //   };

  //   const navigate = useNavigate()
  //   const handleEdit = (id: string) => {
  //     navigate(`/${id}/update-category`)
  //   }
  //   const open = Boolean(anchorEl);
  //   const id = open ? categoryId : "";

  //   const popoverContent = (
  //     <List style={{ padding: '0px 0px 0px 0px' }}>
  //       <Button style={{ padding: '0px 0px 0px 0px', textTransform: "none" }} onClick={() => handleEdit(id)}>
  //         <ListItem style={{ width: '180px', height: '40px' }} className='courses-pover-text-style'>
  //           <ListItemText primary={t("menu.catageory.edit")} />
  //         </ListItem>
  //       </Button>
  //       <Divider />
  //       <Button style={{ padding: '0px 0px 0px 0px', textTransform: "none" }} onClick={() => handleDleteByPopover(id)}>
  //         <ListItem style={{ width: '180px', height: '40px' }} className='courses-pover-text-style'>
  //           <ListItemText primary={t("menu.catageory.delete")} />
  //         </ListItem>
  //       </Button>
  //       <Divider />
  //       <Button style={{ padding: '0px 0px 0px 0px', textTransform: "none" }}>
  //         <ListItem style={{ width: '180px', height: '40px' }} className='courses-pover-text-style'>
  //           <ListItemText primary="Duplicate" />
  //         </ListItem>
  //       </Button>
  //     </List>
  //   );

  //   return (
  //     <Box>
  //       <div>
  //         <IconButton onClick={handleClick}>
  //           <MoreVertSharp />
  //         </IconButton>

  //         <Popover
  //           id={id}
  //           open={open}
  //           anchorEl={anchorEl}
  //           onClose={handleClose}
  //           anchorOrigin={{
  //             vertical: 'bottom',
  //             horizontal: 'left',
  //           }}
  //           transformOrigin={{
  //             vertical: 'top',
  //             horizontal: 'right',
  //           }}
  //           style={{ width: '180px', height: '225px', paddingTop: '0px', paddingBottom: '0px' }}
  //           elevation={8}
  //         >
  //           {popoverContent}
  //         </Popover>
  //       </div>
  //     </Box>
  //   );
  // }

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell";
  };

  const columns: GridColDef[] = [
    {
      field: "name",
      //@ts-ignore
      headerName: t("reporting_group.groups"),
      flex: 1,
      headerClassName: "table-column-header",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography
              className="tableCellInCategory"
              sx={{ textTransform: "capitalize" }}
            >
              {params.row.name}
            </Typography>
            <Typography className="courses-table-text-para">
              {params.row.productCount} {t("menu.catageory.products")}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "product",
      headerName: "",
      width: 200,
      headerClassName: "table-column-header",
      editable: false,
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {/* <CustomPopoverCell
          categoryId={params.row.categoryId}
          
          /> */}
            <IconButton
              onClick={() => {
                handleDeleteModal();
                getCategoryId(params.row.id);
              }}
              sx={{ ":hover": { backgroundColor: "#EEE" } }}
              disabled={deleteLoader ? true : false}
            >
              <DeleteIcon sx={{ color: "grey" }} />
            </IconButton>
            {/* <IconButton sx={{':hover': { backgroundColor: '#EEE' }}}>
          <ContentCopyIcon sx={{ color:"grey", }}/>
          </IconButton> */}
            <IconButton
              onClick={() => handleEdit(params.row.id)}
              sx={{ color: "grey", ":hover": { backgroundColor: "#EEE" } }}
            >
              <EditIcon sx={{ color: "grey" }} />
            </IconButton>
          </>
        );
      },
    },
  ];
  const [reportId, setReportId] = useState<null | number>(null);

  const handleEdit = (id: string) => {
    setOpen(true);
    setReportId(+id);
  };

  const handleId = () => {
    setReportId(null);
  };

  if (loading) return <CircularLoader />;
  //  if (error) return <p>Error : {error.message}</p>;

  return (
    <Box>
      {/* <Box sx={{display:"flex",justifyContent:"space-between"}}>
            <Box>
            <Typography className="courses-head">Reporting Groups</Typography>
            <Typography className="courses-para">Enhance item management: Reporting Group categorises, tracks sales, and streamlines operations with ease.</Typography>
            </Box>
            <Box sx={{display:'flex',justifyContent:"space-evenly",width:"16%"}}>
            <Button className="courses-button"  onClick={handleOpen} sx={{textTransform:'none',marginRight:"0.6rem"}}>Creat Group  </Button>
            {rowSelectionModel.length>0 && (<Button className="courses-button" onClick={handleDeleteModal} sx={{textTransform:'none',padding:"0.6rem"}}>Delete</Button>)}
            </Box>
           
            </Box> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0.833% 0px 0.833% 1.389%",
        }}
      >
        <Box>
          <Typography className="courses-head">
            {t("reporting_group.reporting_group")}
          </Typography>
          <Typography className="courses-para">
            {t("reporting_group.text")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            className="main-button"
            onClick={handleOpen}
            sx={{ marginRight: "0.6rem" }}
          >
            {" "}
            {t("reporting_group.create_reporting_group")}{" "}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button className="main-button" onClick={handleDeleteModal}>
              {t("reporting_group.delet")}
            </Button>
          )}
        </Box>
      </Box>
      <CreateReportingGroup
        dailouge={open}
        handleComplete={handleClose}
        id={reportId}
        handleId={handleId}
      />
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDleteByPopover}
        id={categoryIdValue}
        handleDeleteClick={handleDeleteClick}
        type="reportingGroup"
      />
      <Box sx={{ height: "70vh", width: "100%", marginLeft: "1.389%" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columnHeaderHeight={48}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 5,
              },
            },
          }}
          pageSizeOptions={[5, 15, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          sx={{
            marginRight: "20px",
            ".MuiTablePagination-displayedRows": {
              display: "none", // 👈 to hide huge pagination number
            },
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
              {
                outline: "solid #1976d2 0px !important",
              },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
            // marginLeft: "20px",
          }}
        />
      </Box>
    </Box>
  );
}
export default ReportListing;
