import React, { FC, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Checkbox, InputLabel, ListItemText } from "@mui/material";
import { useState } from "react";
import { GETTING_LOCATIONS_LIST } from '../../../graphicalQl/usequery/locationQuery';
import { setReportData } from "../../../pages/reduxReducers/repoertReducer";



import { useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


interface Locations {
  id: string;
  status: string
  storeId: string
  storeName: string
  isChecked: boolean
};


// const Options = [
//   { value: "Select All", label: "Select All", checked: false },
//   { value: "6", label: "Location 6", checked: false },
//   { value: "43", label: "Location 43", checked: false },
// ];

interface Props {
  locationsList: Locations[]
  //setLocations: (a: string) => void;
}

const LocationSelection = (props: Props) => {
  const { locationsList } = props;
  const { reportLocation
  } = useSelector((state: any) => state.reportData);




  // const [checkedIds, setCheckedIds] = useState<number[]>([])
  // const [location, setLocation] = React.useState('');
  const dispatch = useDispatch();

  const handleChangeLocation = (event: SelectChangeEvent) => {
    // setLocation(event.target.value as string);
    // setLocations(event.target.value as string)
    dispatch(setReportData("reportLocation", event.target.value as string))
    dispatch(setReportData("reportEmployee", null))

  };

  // const [personName, setPersonName] = React.useState<string[]>([]);
  // const handleChange = (event: SelectChangeEvent<typeof personName>) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setPersonName(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  //   const list = locationsList.map(each => ({
  //     ...each,
  //     isChecked: value.includes(each.storeName)
  //   }));
  //   const checkedIds = list.filter((each) => (each.isChecked)).map((item) => +item.storeId)
  //   setCheckedIds(checkedIds)
  //   seLocationsList(list)
  //   setLocations(checkedIds)


  // };
  // useEffect(() => {
  //   if (checkedIds.length > 0) {
  //     setLocations(checkedIds)
  //   }

  // }, [checkedIds])



  // const handleChange = (values: string[]) => {
  //   // const newValues = values?.filter((val, index) => {
  //   //   return index !== 0;
  //   // });
  //   // setLocationsValue(values);
  //   const selectValue = values?.includes("Select All");

  //   const allSelected = locationsList.every((option) => option.isChecked);
  //   // const updatedOptions = locationsList.map((option) => ({
  //   //   ...option,
  //   //   isChecked: !allSelected,
  //   // }));
  //   // seLocationsList(updatedOptions);
  //   // setLocationsValue(["Select All"]);

  //   // const selectValue = value?.includes("Select All");
  //   // if (selectValue) {
  //   //   const allSelected = options.every((option) => option.checked);
  //   //   const updatedOptions = options.map((option) => ({
  //   //     ...option,
  //   //     checked: !allSelected,
  //   //   }));
  //   //   setOptions(updatedOptions);
  //   //   setLocations(["Select All"]);
  //   // } else {
  //   //   if (typeof value === "object") {
  //   //     const updatedOptions = options.map((option) => {
  //   //       // @ts-ignore
  //   //       const newValues = value?.find((val: string) => {
  //   //         return val === option.value;
  //   //       });
  //   //       return {
  //   //         ...option,
  //   //         checked: option.value === newValues,
  //   //       };
  //   //     });
  //   //     setOptions(updatedOptions);
  //   //   }
  //   //   setLocations(
  //   //     // On autofill we get a stringified value.
  //   //     typeof value === "string" ? value.split(",") : value
  //   //   );
  //   // }
  // };

  // const getValue = () => {
  //   let valueReturn: string[];
  //   locationsValue?.[0] === "Select All"
  //     ? (valueReturn = ["Select All"])
  //     : locationsValue?.length > 1
  //     ? (valueReturn = ["Multiple Locations"])
  //     : !locationsValue?.[0]
  //     ? (valueReturn = ["Location"])
  //     : (valueReturn = locationsValue);

  //   return valueReturn;
  // };
  const { locationId } = useSelector((state: any) => state.headerLocation);

  return (
    <div>
      <FormControl
        sx={{
          m: 0,
          width: '130px',
          // width: personName?.length >= 1 ? "auto" : 180,
          // height: 44,
          // position: "static",
          "& .MuiOutlinedInput-root": {
            "&:focused fieldset": { borderColor: "#f39f08" },
            "&.Mui-focused fieldset": { borderColor: "#f39f08" },
          },
        }}
      >
        {/* <Select
          labelId="demo-multiple-name-label"
          id="demo-multiple-name"
          multiple
          placeholder="Location"
          value={personName}
          renderValue={(selected) => (selected ? selected : "Location")}
          onChange={(e) => {
            if (typeof e.target.value === "object") {
              handleChange(e);
            }
          }}
          MenuProps={MenuProps}
          className="selectTag"
        >
          {locationsList?.map((option) => (
            <MenuItem
              key={option.storeId}
              value={option.storeName}
              sx={{
                left: "556px",
              }}
            >
              <Checkbox
                checked={option.isChecked}


                sx={{
                  padding: 0,
                }}
              />
              <ListItemText
                primary={option.storeName}
                sx={{ marginLeft: "10px", color: "#707070" }}
              />
            </MenuItem>
          ))}
        </Select> */}
        <InputLabel id="demo-simple-select-label">Location</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={reportLocation || locationId}
          label="Location"
          onChange={handleChangeLocation}
          sx={{
            width: "180px",
            height: "44px",
          }}
        >
          {locationsList && locationsList.map((each, index) => (
            <MenuItem value={each.storeId} key={index}>{each.storeName}</MenuItem>
          ))}


        </Select>
      </FormControl>
    </div>
  );
};
export default LocationSelection;
