import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "./createCategory.css";
import { useEffect } from "react";
import { Divider } from "primereact/divider";
import { useTranslation } from "react-i18next";

interface Props {
  id: string;
  deletemodal: boolean;
  handleDeleteModal: () => void;
  handleDleteByPopover: (id: string) => void;
  handleDeleteClick: () => void;
  type: string;
  text?: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

export default function DeleteDailouge(props: Props) {
  const { t } = useTranslation();

  const {
    deletemodal,
    handleDeleteModal,
    handleDleteByPopover,
    id,
    handleDeleteClick,
    type,
    text = t("menu.catageory.delete_message"),
  } = props;
  const [open, setOpen] = React.useState(deletemodal);

  const handleClose = () => {
    setOpen(false);
    handleDeleteModal();
  };

  const handleDelete = () => {
    if (id !== "") {
      handleDleteByPopover(id);
    } else {
      handleDeleteClick();
    }
  };

  useEffect(() => {
    setOpen(deletemodal);
  }, [deletemodal]);

  //  const handleOkayButton=()=>{
  //     handleStepByProductModal()
  //  }

  let name;

  switch (type) {
    case "category":
      name = t("menu.catageory.delete_category");

      break;
    case "product":
      name = t("menu.catageory.delete_product");

      break;
    case "bundle":
      name = t("menu.catageory.delete_bundle");

      break;
    case "option":
      name = t("menu.catageory.delete_option");

      break;
    case "optionValue":
      name = t("menu.catageory.delete_OprionValue");
      break;
    case "optionGroup":
      name = t("menu.catageory.delete_option_group");
      break;
    case "menu":
      name = t("menu.catageory.delete_menu");
      break;

    case "course":
      name = t("menu.catageory.delete_course");
      break;
    case "reportingGroup":
      name = t("menu.catageory.delete_reporting_group");
      break;
    case "Discount":
      name = t("menu.catageory.delete_discount");
      break;
    case "salesTax":
      name = t("menu.catageory.delete_sales_tax");
      break;
    case "serviceCharge":
      name = t("menu.catageory.delete_service_charge");
      break;
    // case "optionValue":
    //   name = "Delete Option Value"
    //   break;
    case "reciept":
      name = t("menu.catageory.delete_reciept");
      break;
    case "Variant":
      name = "Delete Variant";
      break;
    default:
      break;
  }
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Box
              className="exit-container"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              onClick={handleClose}
            >
              <CloseIcon className="exitIcon" />
            </Box>
            <Typography className="category-created">{name}</Typography>
            <Typography></Typography>
          </Box>
          <Divider style={{ marginTop: "1%" }} />
          <Box
            sx={{
              padding: "3% 1% 3% 1%",
              marginTop: "2%",
              marginBottom: "2%",
              textAlign: "center",
            }}
          >
            <Typography className="create_category_modal_text">
              {text}{" "}
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "2.08%",
            }}
          >
            <Button
              className="create_category_modal_delete_button1"
              sx={{ textTransform: "none", backgroundColor: "#F5431C" }}
              onClick={() => {
                handleClose();
                handleDelete();
              }}
            >
              {name}
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
