import React, { useRef, useState } from "react";
import { Box, Button, TableHead, Typography } from "@mui/material";
import DashBoardIcon from "../../../assets/dashboard.png";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import FilterDropdown from "./filterDropdown";
import Status from "../../constants/status";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import ExportButton from "./export";

interface RecentOrdersProps {
  headers: ReportsHeaderType[];
  options: ReportsHeaderType[];
  labels: string[];
  data: any[];
  phoneNumber?: boolean;
  title: string;
  setHeaders?: (a: ReportsHeaderType[]) => void;
  setLabels?: (b: string[]) => void;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    color: "#333333",
    fontSize: "14px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "24px",
    border: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "poppins",
    lineHeight: "24px",
    color: "#333333",
    border: "0px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    borderBottom: "0px solid white",
  },
  "& > *": {
    backgroundColor: "#fff",
    paddingY: "3.5px !important",
    border: "0px",
    borderRadius: "4px !important",
  },
}));

const ReportsTable: React.FC<RecentOrdersProps> = ({
  headers,
  data,
  title,
  labels,
  setHeaders,
  setLabels,
  options,
}) => {
  const [openDropdownOpen, setDropdownOpen] = useState(false);
  const tableRef = useRef(null);
  return (
    <Box
      sx={{
        width: { xs: "100%" },
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "8px",
          boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Box
          sx={{
            height: { sm: "58px", xs: "auto" },
            paddingY: { sm: "8px", xs: "16px" },
            background: "white",
            gap: "16px",
            flexWrap: "wrap",
            paddingLeft: "9px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            borderRadius: "8px",
          }}
        >
          {/* <img src={RecentOrderIcon} alt="recentOrderIcon" /> */}
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "20px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {title}
          </Typography>
          <Box
            display={"flex"}
            sx={{ padding: { md: "12px 32px", xs: "8px" } }}
          >
            <ExportButton data={data} contentRef={tableRef} />
            <Box position={"relative"}>
              <Button
                sx={{
                  border: "1px solid #F9C584",
                  borderRadius: "8px",
                  height: "44px",
                  width: "44px",
                  minWidth: "44px",
                  maxWidth: "44px",
                  padding: "0px",
                }}
                onClick={() => setDropdownOpen(!openDropdownOpen)}
              >
                <img src={DashBoardIcon} alt="DashBoardIcon" />
              </Button>
              {openDropdownOpen ? (
                <Box
                  sx={{
                    position: "absolute",
                    right: "0",
                    top: "50px",
                    background: "white",
                    paddingY: "10px",
                    paddingX: "8px",
                    boxShadow:
                      "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    border: "1px solid #EEEEEE",
                    width: "202px",
                  }}
                >
                  <FilterDropdown
                    options={options}
                    setHeaders={setHeaders}
                    setLabels={setLabels}
                    headers={headers}
                    labels={labels}
                  />
                </Box>
              ) : null}
            </Box>
          </Box>
        </Box>

        <TableContainer
          sx={{
            scrollbarWidth: "none",
            "&::-webkit-scrollbar": {
              display: "none",
            },
            "&-ms-overflow-style:": {
              display: "none",
            },
            boxShadow: "none !important",
            marginTop: "12px"
          }}
          component={Paper}
        >
          <div ref={tableRef}>
            <Table
              sx={{
                width: { lg: "100%", sm: "1060px" },
                overflow: "auto",
              }}
            >
              <TableHead sx={{ width: "100%" }}>
                <TableRow
                  sx={{
                    width: "100%",
                    height: "32px",
                    background: "#F5F5F5",
                  }}
                >
                  {headers.map((header, index) => {
                    return (
                      <StyledTableCell
                        sx={{
                          paddingY: "4px",
                          height: "32px",
                          border: "0px",
                        }}
                        key={index}
                      >
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontSize: "16px",
                            color: "#333333",
                            fontWeight: "500",
                            lineHeight: "24px",
                            textAlign: `${index === 0 ? "start" : "start"}`,
                          }}
                        >
                          {header.status || header.label}
                        </Typography>
                      </StyledTableCell>
                    );
                  })}
                </TableRow>
              </TableHead>

              <TableBody>
                {data?.map((row, rowIndex) => {
                  return (
                    <StyledTableRow key={rowIndex}>
                      {labels.map((cell, cellIndex) => {
                        return (
                          <StyledTableCell
                            sx={{
                              fontWeight: `${cellIndex === 0 ? "500" : ""}`,
                              textAlign: `${cellIndex === 0 ? "start" : "start"
                                }`,
                            }}
                            key={cellIndex}
                          >
                            <Status
                              text={row?.[cell]}
                              format={
                                (title === "Orders" && cell === "order_type") ||
                                (title === "Sales Summary" &&
                                  cell === "saleType")
                              }
                            />
                          </StyledTableCell>
                        );
                      })}
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default ReportsTable;
