import React from "react";
import Box from "@mui/material/Box";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import BusinessDetails from "./businessDetails";
import SelectPlan from "./selectPlan";
import AddOns from "./addOns";
import { useNavigation } from "./StepperNavigationContext/useNavigation";
import { styled } from "@mui/system";
import PaymentSuccess from "../../common/paymentsPlans/paymenySuccess";

const StepperWrapper = styled("div")({
  ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active": {
    color: "#F38B08",
  },
  // ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root":{
  //   color: "#FCE2C2",
  // },
  ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root": {
    height: "44px",
    width: "44px",
    color: "#FCE2C2",
  },
  ".css-117w1su-MuiStepIcon-text": {
    fontSize: { xs: '12px', sm: '16px' },
    fontFamily: 'poppins',
    fontWeight: '600',
    lineHeight: '24px',
    color: '#FFFFFF',
    textAlign: 'center',
  },
  ".css-zpcwqm-MuiStepConnector-root": {
    top: "22px",
    left: 'calc(-50% + 28px)',
    right: { sm: 'calc(50% + 20px)', xs: 'calc(50% + 23.5px) ' }
  },
  ".css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed": {
    color: "#F38B08",
  },
  ".css-1hv8oq8-MuiStepLabel-label.MuiStepLabel-alternativeLabel": {
    fontSize: "14px",
    fontFamily: 'poppins',
    lineHeight: '21px',
    color: "#707070",
    fontWeight: "500",
    marginTop: '16px',
  },

  ".MuiStepConnector-alternativeLabel.Mui-active .css-z7uhs0-MuiStepConnector-line":
  {
    borderColor: "#F38B08",
    borderTopWidth: "2px",
  },
  ".css-z7uhs0-MuiStepConnector-line": {
    borderTopWidth: "2px",
    width: "93%",
    position: 'relative',
    zIndex: '-1',
    padding: '0px',
  },
  ".MuiStepper-root.MuiStepper-horizontal.MuiStepper-alternativeLabel.css-10mg1vw-MuiStepper-root":
  {
    maxWidth: "510.91px",
    marginLeft: "auto",
    marginRight: "auto",

  },

  ".step-one .MuiStep-root:nth-child(2) .MuiStepConnector-line::after": {
    content: '""',
    position: "absolute",
    width: "0%",
    height: "2px",
    backgroundColor: "#F38B08",
    top: "-2px",
  },
  ".step-one.one .MuiStep-root:nth-child(2) .MuiStepConnector-line::after": {
    width: "25%",
  },
  ".step-one.two .MuiStep-root:nth-child(2) .MuiStepConnector-line::after": {
    width: "50%",
  },
  ".step-one.three .MuiStep-root:nth-child(2) .MuiStepConnector-line::after": {
    width: "75%",
  },
});

const steps = ["Business Details", "Select Plan",
  // "Add Ons",
  "Success"
];

const StepContent = (stepIndex: any) => {
  switch (stepIndex) {
    case 0:
      return <BusinessDetails />;
    case 1:
      return <SelectPlan />;
    // case 2:
    //   return <AddOns />;
    case 2:
      return <PaymentSuccess />;
    default:
      return null;
  }
};

const HorizontalLinearAlternativeLabelStepper = () => {
  const { activeStep, stepperCounter } = useNavigation();

  return (
    <StepperWrapper>
      <Box
        sx={{ width: "100%" }}
        maxWidth={"1440px"}
        marginX={"auto"}
        marginTop={"20px"}
      >
        <Stepper
          className={` step-one ${stepperCounter === 1 ? "one" : ""} ${stepperCounter === 2 ? "two" : ""
            } ${stepperCounter === 3 ? "three" : ""}`}
          activeStep={activeStep}
          alternativeLabel
        >
          {steps.map((label, index) => (
            <Step key={index}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <Box sx={{ marginTop: "18px" }}>{StepContent(activeStep)}</Box>
      </Box>
    </StepperWrapper>
  );
};

export default HorizontalLinearAlternativeLabelStepper;
