import {
  Box,
  AccordionDetails,
  Accordion,
  AccordionSummary,
  FormGroup,
  Checkbox,
  Typography,
  FormControlLabel,
  TextField,
  Button,
  IconButton,
  Paper,
  Divider,
} from "@mui/material";

import React, { useState } from "react";
import "./kdsSettings.css";
import SearchIcon from "@mui/icons-material/Search";
import { FormikErrors } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { t } from "i18next";
import "./kitchenSettings.css";

interface State1 {
  id: string;
  limitPerUser: string;
  item: string;
  fromTiime: string;
  collectedDays: any[];
  collectedProductIds: any[];
  startTime: string;
  endTime: string;
  toTime: string;
  day: {
    Monday: boolean;
    Tuesday: boolean;
    Wednesday: boolean;
    Thursday: boolean;
    Friday: boolean;
    Saturday: boolean;
    Sunday: boolean;
  };
}

interface KdsSettingsState {
  formik: any;
  productsListFor0thIndex: any[];
  handleProductIdsFor0thIndex: (id: string) => void;
  daysListFor0thIndex: any[];
  handleDaysFor0thIndex: (id: string) => void;
  limitOrderPerItemList: any[];
  allCheckboxesForLimitOrdersPerSlot: boolean;
  handleAddNewButton: () => void;
  handleLimitPerUserForIteration: (index: number, text: string) => void;
  handleDeleteOrderItem: (id: string) => void;
  allCheckboxCheckedFor0thIndex: boolean;
  handleAllProductsFor0thIndex: (checked: boolean) => void;
  allCheckboxesForDays0thIndex: boolean;
  handleAllDaysFor0thIndex: (controllers: any) => void;
  handleAllDaysForLimitOrdersPerSlot: (checked: boolean) => void;
  handleAllProductsForIteration: (index: number, checked: boolean) => void;
  handleAllDaysForIteration: (index: number, checked: boolean) => void;
  handleEndTimeForIteration: (index: number, time: string) => void;
  handleStartTimeForIteration: (index: number, time: string) => void;
  handleDaysForLimitOrdersPerSlot: (id: string) => void;
  daysListForLimitOrdersPerSlot: any[];
  handleIndividualForDays: (
    index: number,
    id: string,
    checked: boolean
  ) => void;
  handleCheckboxForIndividualProductsCheck: (
    index: number,
    id: string,
    checked: boolean
  ) => void;
}

function KdsSettingsOrders(props: KdsSettingsState) {
  const {
    formik,
    handleIndividualForDays,
    daysListForLimitOrdersPerSlot,
    handleAllDaysForLimitOrdersPerSlot,
    allCheckboxesForLimitOrdersPerSlot,
    handleLimitPerUserForIteration,
    productsListFor0thIndex,
    handleProductIdsFor0thIndex,
    handleCheckboxForIndividualProductsCheck,
    handleDaysForLimitOrdersPerSlot,
    daysListFor0thIndex,
    handleDaysFor0thIndex,
    limitOrderPerItemList,
    handleAllDaysForIteration,
    handleAddNewButton,
    handleDeleteOrderItem,
    allCheckboxCheckedFor0thIndex,
    handleAllProductsFor0thIndex,
    allCheckboxesForDays0thIndex,
    handleStartTimeForIteration,
    handleEndTimeForIteration,
    handleAllDaysFor0thIndex,
    handleAllProductsForIteration,
  } = props;

  const [searchInputFor0thIndex, setSearchInputFor0thIndex] =
    useState<string>("");

  const handleUserInputChangeFor0thIndex = (text: string) => {
    setSearchInputFor0thIndex(text);
  };

  const filteredProductsListFor0thIndex = productsListFor0thIndex.filter(
    (each: any) => each.name.includes(searchInputFor0thIndex)
  );

  const countingSelecetdProducts = (list: any[]) => {
    let productsDataText = "";
    if (list) {
      const checkdLength = list.filter((each: any) => each.isChecked);
      if (checkdLength.length === 0) {
        productsDataText = t("devices.select_product");
      } else if (checkdLength.length === 1) {
        productsDataText = t("devices.1_product");
      } else {
        productsDataText = `${checkdLength.length} ${t("devices.products")}`;
      }
    } else {
      productsDataText = t("devices.select_product");
    }

    return productsDataText;
  };

  const countingSelecetdDays = (list: any[]) => {
    let daysText = "";
    if (list) {
      const checkdLength = list.filter((each: any) => each.isChecked);
      if (checkdLength.length === 0) {
        daysText = t("devices.select_day");
      } else if (checkdLength.length === 1) {
        daysText = t("devices.1_day");
      } else {
        daysText = `${checkdLength.length} ${t("devices.days")}`;
      }
    } else {
      daysText = t("devices.select_day");
    }

    return daysText;
  };

  return (
    <Box sx={{ padding: "15px", width: "90%" }}>
      <Box>
        <Typography className="kds-settings-dinein-text">
          {t("devices.limit_item_per_slot")}
        </Typography>
        <div className="grid-container">
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item">
            <Typography>{t("devices.from_time")}</Typography>
          </div>
          <div className="grid-item">
            <Typography>{t("devices.to_time")}</Typography>
          </div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item">
            <TextField
              sx={{ width: "100%" }}
              color="warning"
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.LimitItemPerSlot0thIndex || ""}
              name="LimitItemPerSlot0thIndex"
              label={
                <Typography className="kds-settings-limit-per-label-text">
                  {t("devices.limit_per_slot")}
                </Typography>
              }
            />
          </div>
          <div className="grid-item">
            <Box sx={{ width: "100%", height: "55px" }}>
              <Accordion
                sx={{
                  width: "100%",
                  height: "53px",
                  border: "1px solid #d9d9d9",
                  zIndex: 999999999999,
                }}
                elevation={0}
              >
                <AccordionSummary
                  sx={{ width: "100%", marginBottom: 0 }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className="create-a-use-role-label"
                    sx={{ marginBottom: "0px" }}
                  >
                    {countingSelecetdProducts(filteredProductsListFor0thIndex)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    zIndex: 10000,
                    "&.MuiAccordionDetails-root": {
                      padding: "0px !important",
                    },
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "auto",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      maxHeight: "32vh",
                      padding: "10px 10px 10px 15px",
                      minHeight: "10vh",
                      zIndex: 10000,
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            onChange={(e) =>
                              handleAllProductsFor0thIndex(e.target.checked)
                            }
                            name="all"
                            checked={allCheckboxCheckedFor0thIndex}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "1.75rem",
                              },
                            }}
                            color="warning"
                          />
                        }
                        label={
                          <Typography className="driver-petty-cash-popup-checkbox">
                            {t("buttons.all")}
                          </Typography>
                        }
                      />
                      {filteredProductsListFor0thIndex.map((each: any) => (
                        <FormControlLabel
                          key={each.id}
                          control={
                            <Checkbox
                              sx={{
                                "& .MuiSvgIcon-root": {
                                  fontSize: "1.75rem",
                                },
                              }}
                              onChange={() =>
                                handleProductIdsFor0thIndex(each.id)
                              }
                              color="warning"
                              checked={each.isChecked}
                            />
                          }
                          label={
                            <Typography
                              sx={{ textTransform: "none" }}
                              className="create-a-user-label"
                            >
                              {each.name}
                            </Typography>
                          }
                        />
                      ))}
                    </FormGroup>
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>

          <div className="grid-item">
            <Box sx={{ width: "100%" }}>
              {/* <Typography>From Time</Typography> */}
              <TextField
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.startTimePerSlot0thIndex || ""}
                name="startTimePerSlot0thIndex"
                sx={{ width: "100%" }}
                type="time"
                color="warning"
              />
            </Box>
          </div>
          <div className="grid-item">
            <Box sx={{ width: "100%" }}>
              {/* <Typography>To Time</Typography> */}
              <TextField
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                value={formik.values.endTimePerSlot0thIndex || ""}
                name="endTimePerSlot0thIndex"
                sx={{ width: "100%" }}
                type="time"
                color="warning"
              />
            </Box>
          </div>
          <div className="grid-item">
            <Box sx={{ width: "%", height: "55px" }}>
              <Accordion
                sx={{
                  width: "100%",
                  height: "53px",
                  border: "1px solid #d9d9d9",
                  zIndex: 999999999999,
                }}
                elevation={0}
              >
                <AccordionSummary
                  sx={{
                    width: "100%",
                    "&.Mui-expanded": {
                      minHeight: "0px !important",
                    },
                  }}
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography
                    className="create-a-use-role-label"
                    sx={{ marginBottom: "0px" }}
                  >
                    {countingSelecetdDays(daysListFor0thIndex)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    zIndex: 10000,
                    "&.MuiAccordionDetails-root": {
                      padding: "0px !important",
                    },
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "auto",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      maxHeight: "32vh",
                      padding: "10px 10px 10px 15px",
                      minHeight: "10vh",
                      zIndex: 10000,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleAllDaysFor0thIndex(e.target.checked)
                          }
                          name="all"
                          checked={allCheckboxesForDays0thIndex}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "1.75rem",
                            },
                          }}
                          color="warning"
                        />
                      }
                      label={
                        <Typography className="create-a-user-label">
                          {t("devices.select_day")}
                        </Typography>
                      }
                    />
                    {daysListFor0thIndex.map((each: any) => (
                      <FormControlLabel
                        key={each.id}
                        control={
                          <Checkbox
                            onChange={() => {
                              handleDaysFor0thIndex(each.id);
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "1.75rem",
                              },
                            }}
                            color="warning"
                            checked={each.isChecked}
                          />
                        }
                        label={
                          <Typography
                            sx={{ textTransform: "none" }}
                            className="create-a-user-label"
                          >
                            {each.text}
                          </Typography>
                        }
                      />
                    ))}
                  </Paper>
                </AccordionDetails>
              </Accordion>
            </Box>
          </div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
          <div className="grid-item">
            <Typography>
              {formik.touched.endTimePerSlot0thIndex &&
                formik.errors.endTimePerSlot0thIndex && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {formik.errors.endTimePerSlot0thIndex}
                    </Typography>
                  </Box>
                )}
            </Typography>
          </div>
          <div className="grid-item"></div>
          <div className="grid-item"></div>
        </div>

        <Box>
          {limitOrderPerItemList.map((each, index) => (
            <>
              {/*  */}
              <div className="grid-container">
                <div className="grid-item"></div>
                <div className="grid-item"></div>
                <div className="grid-item">
                  <Typography>{t("devices.from_time")}</Typography>
                </div>
                <div className="grid-item">
                  <Typography>{t("devices.to_time")}</Typography>
                </div>
                <div className="grid-item"></div>
                <div className="grid-item"></div>
                <div className="grid-item">
                  <TextField
                    value={
                      (formik.values &&
                        formik.values.limitItemPerSlotList &&
                        formik.values.limitItemPerSlotList[index] &&
                        formik.values.limitItemPerSlotList[index]
                          .limitPerUser) ||
                      ""
                    }
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleLimitPerUserForIteration(index, e.target.value);
                    }}
                    onBlur={formik.handleBlur}
                    sx={{ width: "100%" }}
                    name={`limitItemPerSlotList[${index}].limitPerUser`}
                    color="warning"
                    label={
                      <Typography className="kds-settings-limit-per-label-text">
                        {t("devices.limit_per_slot")}
                      </Typography>
                    }
                  />
                </div>
                <div className="grid-item">
                  <Box sx={{ width: "100%", height: "55px" }}>
                    <Accordion
                      sx={{
                        width: "100%",
                        height: "53px",
                        border: "1px solid #d9d9d9",
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        sx={{
                          width: "100%",
                          "&.Mui-expanded": {
                            minHeight: "0px !important",
                          },
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          className="create-a-use-role-label"
                          sx={{ marginBottom: "0px" }}
                        >
                          {countingSelecetdProducts(each.productsList)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          zIndex: 10000,
                          "&.MuiAccordionDetails-root": {
                            padding: "0px !important",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: "37vh",

                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            zIndex: 10000,
                          }}
                        >
                          <Paper
                            elevation={2}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              overflowY: "auto",
                              WebkitOverflowScrolling: "touch",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                              maxHeight: "32vh",
                              padding: "10px 10px 10px 15px",
                              minHeight: "10vh",
                              zIndex: 10000,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleAllProductsForIteration(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                  name="all"
                                  checked={each.all}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "1.75rem",
                                    },
                                  }}
                                  color="warning"
                                />
                              }
                              label={
                                <Typography className="driver-petty-cash-popup-checkbox">
                                  {t("buttons.all")}
                                </Typography>
                              }
                            />
                            {each.productsList?.map((each: any) => (
                              <FormControlLabel
                                key={each.id}
                                control={
                                  <Checkbox
                                    // onChange={() => {
                                    //   handlePopupCheckbox(
                                    //     "locationsList",
                                    //     each.storeId,
                                    //     each.isChecked
                                    //   );
                                    // }}
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "1.75rem",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleCheckboxForIndividualProductsCheck(
                                        index,
                                        each.id,
                                        e.target.checked
                                      )
                                    }
                                    color="warning"
                                    checked={each.isChecked}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ textTransform: "none" }}
                                    className="create-a-user-label"
                                  >
                                    {each.name}
                                  </Typography>
                                }
                              />
                            ))}
                          </Paper>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </div>
                <div className="grid-item">
                  <Box sx={{ width: "100%" }}>
                    {/* <Typography>From Time</Typography> */}
                    <TextField
                      name={`limitItemPerSlotList[${index}].startTime`}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        handleStartTimeForIteration(index, e.target.value);
                        formik.handleChange(e);
                      }}
                      value={
                        formik.values &&
                        formik.values.limitItemPerSlotList &&
                        formik.values.limitItemPerSlotList[index] &&
                        formik.values.limitItemPerSlotList[index].startTime
                      }
                      sx={{ width: "100%" }}
                      type="time"
                      color="warning"
                    />
                  </Box>
                </div>
                <div className="grid-item">
                  <Box sx={{ width: "100%" }}>
                    {/* <Typography>To Time</Typography> */}
                    <TextField
                      name={`limitItemPerSlotList[${index}].endTime`}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleEndTimeForIteration(index, e.target.value);
                      }}
                      value={
                        formik.values &&
                        formik.values.limitItemPerSlotList &&
                        formik.values.limitItemPerSlotList[index] &&
                        formik.values.limitItemPerSlotList[index].endTime
                      }
                      sx={{ width: "100%" }}
                      type="time"
                      color="warning"
                    />
                  </Box>
                </div>
                <div className="grid-item">
                  <Box sx={{ width: "100%", height: "55px" }}>
                    <Accordion
                      sx={{
                        width: "100%",
                        height: "53px",
                        border: "1px solid #d9d9d9",
                      }}
                      elevation={0}
                    >
                      <AccordionSummary
                        sx={{
                          width: "100%",
                          "&.Mui-expanded": {
                            minHeight: "0px !important",
                          },
                        }}
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          className="create-a-use-role-label"
                          sx={{ marginBottom: "0px" }}
                        >
                          {countingSelecetdDays(each.daysList)}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails
                        sx={{
                          "&.MuiAccordionDetails-root": {
                            padding: "0px !important",
                          },
                        }}
                      >
                        <Box
                          sx={{
                            height: "37vh",

                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                            zIndex: 10000,
                          }}
                        >
                          <Paper
                            elevation={2}
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              flexDirection: "column",
                              alignItems: "flex-start",
                              overflowY: "auto",
                              WebkitOverflowScrolling: "touch",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                              maxHeight: "32vh",
                              padding: "10px 10px 10px 15px",
                              minHeight: "10vh",
                              zIndex: 100000,
                            }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  onChange={(e) =>
                                    handleAllDaysForIteration(
                                      index,
                                      e.target.checked
                                    )
                                  }
                                  name="all"
                                  checked={each.everyDay}
                                  sx={{
                                    "& .MuiSvgIcon-root": {
                                      fontSize: "1.75rem",
                                    },
                                  }}
                                  color="warning"
                                />
                              }
                              label={
                                <Typography className="create-a-user-label">
                                  {t("devices.every_day")}
                                </Typography>
                              }
                            />
                            {each.daysList?.map((each: any) => (
                              <FormControlLabel
                                key={each.id}
                                control={
                                  <Checkbox
                                    // onChange={() => {
                                    //   handlePopupCheckbox(
                                    //     "locationsList",
                                    //     each.storeId,
                                    //     each.isChecked
                                    //   );
                                    // }}
                                    sx={{
                                      "& .MuiSvgIcon-root": {
                                        fontSize: "1.75rem",
                                      },
                                    }}
                                    onChange={(e) =>
                                      handleIndividualForDays(
                                        index,
                                        each.id,
                                        e.target.checked
                                      )
                                    }
                                    color="warning"
                                    checked={each.isChecked}
                                  />
                                }
                                label={
                                  <Typography
                                    sx={{ textTransform: "none" }}
                                    className="create-a-user-label"
                                  >
                                    {each.text}
                                  </Typography>
                                }
                              />
                            ))}
                          </Paper>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  </Box>
                </div>
                <div className="grid-item">
                  <IconButton
                    onClick={() => handleDeleteOrderItem(each.id)}
                    sx={{ marginLeft: "15px", marginTop: "6px" }}
                    className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
                  >
                    <CloseIcon />
                  </IconButton>
                </div>
                <div className="grid-item">
                  {formik.touched?.limitItemPerSlotList &&
                    formik.errors?.limitItemPerSlotList &&
                    formik.touched.limitItemPerSlotList[index]?.limitPerUser &&
                    (
                      formik.errors
                        .limitItemPerSlotList as FormikErrors<State1>[]
                    )[index]?.limitPerUser && (
                      <Typography className="create-a-user-formik-label">
                        {(
                          formik.errors
                            .limitItemPerSlotList as FormikErrors<State1>[]
                        )[index]?.limitPerUser && "Required"}
                      </Typography>
                    )}
                </div>
                <div className="grid-item">
                  {formik.touched?.limitItemPerSlotList &&
                    formik.errors?.limitItemPerSlotList &&
                    formik.touched.limitItemPerSlotList[index]
                      ?.collectedProductIds &&
                    (
                      formik.errors
                        .limitItemPerSlotList as FormikErrors<State1>[]
                    )[index]?.collectedProductIds && (
                      <Typography className="create-a-user-formik-label">
                        {(
                          formik.errors
                            .limitItemPerSlotList as FormikErrors<State1>[]
                        )[index]?.collectedProductIds && "Required"}
                      </Typography>
                    )}
                </div>
                <div className="grid-item">
                  {formik.touched?.limitItemPerSlotList &&
                    formik.errors?.limitItemPerSlotList &&
                    formik.touched.limitItemPerSlotList[index]?.startTime &&
                    (
                      formik.errors
                        .limitItemPerSlotList as FormikErrors<State1>[]
                    )[index]?.startTime && (
                      <Typography className="create-a-user-formik-label">
                        {(
                          formik.errors
                            .limitItemPerSlotList as FormikErrors<State1>[]
                        )[index]?.startTime && "Required"}
                      </Typography>
                    )}
                </div>
                <div className="grid-item">
                  {formik.touched?.limitItemPerSlotList &&
                    formik.errors?.limitItemPerSlotList &&
                    formik.touched.limitItemPerSlotList[index]?.endTime &&
                    (
                      formik.errors
                        .limitItemPerSlotList as FormikErrors<State1>[]
                    )[index]?.endTime && (
                      <Typography className="create-a-user-formik-label">
                        {(
                          formik.errors
                            .limitItemPerSlotList as FormikErrors<State1>[]
                        )[index]?.endTime ===
                        "End time must be greater than start time"
                          ? "Invalid Time"
                          : "Required"}
                      </Typography>
                    )}
                </div>
                <div className="grid-item">
                  {formik.touched?.limitItemPerSlotList &&
                    formik.errors?.limitItemPerSlotList &&
                    formik.touched.limitItemPerSlotList[index]?.collectedDays &&
                    (
                      formik.errors
                        .limitItemPerSlotList as FormikErrors<State1>[]
                    )[index]?.collectedDays && (
                      <Typography className="create-a-user-formik-label">
                        {(
                          formik.errors
                            .limitItemPerSlotList as FormikErrors<State1>[]
                        )[index]?.collectedDays && "Required"}
                      </Typography>
                    )}
                </div>
                <div className="grid-item"></div>
              </div>
            </>
          ))}

          <Box>
            <Button
              onClick={handleAddNewButton}
              sx={{ marginBottom: "10px" }}
              className="kds-settings-add-new-button"
            >
              {t("devices.add_new")}
            </Button>
          </Box>
        </Box>
      </Box>

      <Divider />
      <Typography
        sx={{ marginTop: "10px" }}
        className="kds-settings-dinein-text"
      >
        {t("devices.limit_order_per_slot")}
      </Typography>
      <div className="grid-container-for-order-limit">
        <div className="grid-item"></div>
        <div className="grid-item">
          <Typography>{t("devices.from_time")}</Typography>
        </div>
        <div className="grid-item">
          <Typography>{t("devices.to_time")}</Typography>
        </div>

        <div className="grid-item"></div>

        <div className="grid-item">
          <TextField
            onBlur={formik.handleBlur}
            onChange={(e) => {
              const val = e.target.value;
              if (!val || (/^[0-9]+$/.test(val) && e.target.value.length < 5)) {
                formik.handleChange(e);
              }
            }}
            type="text"
            value={formik.values.limitOrdersPerSlot || ""}
            name="limitOrdersPerSlot"
            sx={{ width: "100%" }}
            color="warning"
            label={
              <Typography className="kds-settings-limit-per-label-text">
                {t("devices.limit_per_slot")}
              </Typography>
            }
          />
        </div>
        <div className="grid-item">
          <Box sx={{ width: "100%" }}>
            {/* <Typography>From Time</Typography> */}
            <TextField
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              name="limitOrdersPerSlotFromTime"
              value={formik.values.limitOrdersPerSlotFromTime || ""}
              sx={{ width: "100%" }}
              type="time"
              color="warning"
            />
          </Box>
        </div>

        <div className="grid-item">
          <Box sx={{ width: "100%" }}>
            {/* <Typography>To Time</Typography> */}
            <TextField
              onChange={(e) => formik.handleChange(e)}
              onBlur={formik.handleBlur}
              name="limitOrdersPerSlotToTime"
              value={formik.values.limitOrdersPerSlotToTime || ""}
              sx={{ width: "100%" }}
              type="time"
              color="warning"
            />
          </Box>
        </div>

        <div className="grid-item">
          <Box sx={{ width: "100%", height: "55px" }}>
            <Accordion
              sx={{
                width: "100%",
                height: "53px",
                border: "1px solid #d9d9d9",
              }}
              elevation={0}
            >
              <AccordionSummary
                sx={{
                  width: "100%",
                  "&.Mui-expanded": {
                    minHeight: "0px !important",
                  },
                }}
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography
                  className="create-a-use-role-label"
                  sx={{ marginBottom: "0px" }}
                >
                  {countingSelecetdDays(daysListForLimitOrdersPerSlot)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  zIndex: 10000,
                  "&.MuiAccordionDetails-root": {
                    padding: "0px !important",
                  },
                }}
              >
                <Box
                  sx={{
                    height: "37vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    zIndex: 99999999999,
                  }}
                >
                  <Paper
                    elevation={2}
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "auto",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                      maxHeight: "32vh",
                      padding: "10px 10px 10px 15px",
                      minHeight: "10vh",
                      zIndex: 10000,
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          onChange={(e) =>
                            handleAllDaysForLimitOrdersPerSlot(e.target.checked)
                          }
                          name="all"
                          checked={allCheckboxesForLimitOrdersPerSlot}
                          sx={{
                            "& .MuiSvgIcon-root": {
                              fontSize: "1.75rem",
                            },
                          }}
                          color="warning"
                        />
                      }
                      label={
                        <Typography className="create-a-user-label">
                          {t("devices.every_day")}
                        </Typography>
                      }
                    />
                    {daysListForLimitOrdersPerSlot.map((each: any) => (
                      <FormControlLabel
                        key={each.id}
                        control={
                          <Checkbox
                            onChange={() => {
                              handleDaysForLimitOrdersPerSlot(each.id);
                            }}
                            sx={{
                              "& .MuiSvgIcon-root": {
                                fontSize: "1.75rem",
                              },
                            }}
                            color="warning"
                            checked={each.isChecked}
                          />
                        }
                        label={
                          <Typography
                            sx={{ textTransform: "none" }}
                            className="create-a-user-label"
                          >
                            {each.text}
                          </Typography>
                        }
                      />
                    ))}
                  </Paper>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        </div>
        <div className="grid-item"></div>
        <div className="grid-item">
          {" "}
          <Typography>
            {formik.touched.limitOrdersPerSlotToTime &&
              formik.errors.limitOrdersPerSlotToTime && (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {formik.errors.limitOrdersPerSlotToTime}
                  </Typography>
                </Box>
              )}
          </Typography>
        </div>

        <div className="grid-item"></div>
        <div className="grid-item"></div>
      </div>
    </Box>
  );
}

export default KdsSettingsOrders;
