import React, { useState, useEffect } from "react";
import {
  Divider,
  Typography,
  Box,
  Button,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Popover,
  Tooltip,
} from "@mui/material";
import "./menuSetup.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BulkAdujustment from "./bulkAdjustment";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import { CloseOutlined } from "@mui/icons-material";
import image2 from "../../assets/menuSetup.png";
import { useDispatch, useSelector } from "react-redux";
import { menusetup } from "../../store";
import { useMutation, useQuery } from "@apollo/client";
import { PRODUCT_LIST_IN_MENU } from "../../graphicalQl/usequery/productsListQuery.tsx";
import {
  BUNDLE_LIST_IN_MENU,
  COURSE_LIST_IN_MENU,
} from "../../graphicalQl/usequery/menuQuery.tsx";
import {} from "../../graphicalQl/usequery/menuQuery.tsx";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery.tsx";
import { MENU_SETUP } from "../../graphicalQl/mutation/menuMutation.tsx";
import { MENU_DATA } from "../../graphicalQl/usequery/menuQuery.tsx";
import CircularLoader from "../../loader/loader.tsx";
import { MoreVert } from "@mui/icons-material";
import { DUPLICATE_MENU } from "../../graphicalQl/mutation/menuMutation";
import { DELETE_MENU } from "../../graphicalQl/mutation/menuMutation";
import DeleteDailouge from "../category/deleteDailouge";
import Publish from "./publish.tsx";
import DeleteDailougeInMenu from "./deleteDailouge.tsx";
import { styled } from "@mui/system";
import CurrencyDisplay from "../utils/currencySymbol";
import CourseDeleteDailougeInMenu from "./courseDeleteDailouge.tsx";
import DeleteCategoryDailouge from "./deleteCategoryModal.tsx";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import moment from "moment";

import { useTranslation } from "react-i18next";

interface MenuItem {
  id: string;
  content: string;
  price: string | null;
  category: string;
  course: string;
  categoryId: string;
  courseId: string;
  categoryImage: string;
  categoryDescription: string;
  variations: {
    id: number;
    variation: string;
    price: number;
    variantGroup: string;
  }[];
}
interface MenuItem2 {
  id: string;
  content: string;
  price: string | null;
  category: string;
  course: string;
  categoryId: string;

  categoryImage: string;
  categoryDescription: string;
  courseId: string;
  variations: {
    id: number;
    variation: string;
    price: number;
    variantGroup: string;
  }[];
  menu: string;
}
interface State {
  menu: string;
  id: string;
  draggedItems: MenuItem[];
  bundleList: any;
}
interface Variation {
  id: string;
  variation: string;
}
interface Product {
  category: string;
  content: string;
  courseId: string;
  course: string;
  id: string;
  variations?: Variation[];
}

interface FilteredProductData {
  courseId: string;
  course: string;
  // product?: Product[];
}

const MenuSetup = () => {
  const [leftItems, setLeftItems] = useState<MenuItem2[]>([]);
  const [bundleDraggedData, setBundleDraggedData] = useState<any>([]);
  const { menu } = useSelector((state: any) => state.menuState);
  const [bundleList, setBundleList] = useState<any[]>([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  // const locationId = sessionStorage.getItem("locationId")
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { t } = useTranslation();
  const CustomStyles = styled("div")({
    "& .MuiAccordion-region": {
      width: "100%",
    },
    "& .MuiCollapse-wrapperInner": {
      width: "100%",
    },
    // '& .MuiAccordionSummary-content.Mui-expanded': {
    //   margin: "0px !important"
    // }
  });
  const CustomStyles2 = styled("div")`
    & > *:not(style) {
      margin: 4px;
    }
  `;

  const [active, setActive] = useState<string>(menu);

  const [menuData, setMenuData] = React.useState<State[]>([]);
  //updating menuData draggedItems when leftItem changes
  useEffect(() => {
    const updatedList = menuData.map((each) => ({
      ...each,
      draggedItems: leftItems.filter((item) => item.menu === each.menu),
    }));
    setMenuData(updatedList);
  }, [leftItems]);

  //updating menuData bundlelist when bundleDraggedData changes
  useEffect(() => {
    const updatedList = menuData.map((each) => ({
      ...each,
      bundleList: bundleDraggedData.filter(
        (item: any) => item.menu === each.menu
      ),
    }));
    setMenuData(updatedList);
  }, [bundleDraggedData]);
  //menu list
  const { data: menuListData } = useQuery(MENU_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: locationId },
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (
      menuListData &&
      menuListData.menuList &&
      menuListData.menuList.edges.length > 0
    ) {
      const list = menuListData.menuList.edges.map((each: any) => ({
        menu: each.node.menuId,
        id: each.node.name,
        draggedItems: [],
        bundleList: [],
      }));
      setMenuData(list);
      setActive(list[0].menu);
    }
  }, [menuListData]);

  const [pizza, setPizza] = useState<MenuItem[]>([]);

  const {
    loading: productsDataLoading,
    error: productsDataError,
    data: productsData,
  } = useQuery(PRODUCT_LIST_IN_MENU, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: locationId },
    fetchPolicy: "network-only",
  });
  //product list query

  useEffect(() => {
    if (productsData) {
      if (productsData !== null) {
        const { edges } = productsData.productList;

        const list: MenuItem2[] = edges.map((each: any) => {
          let minPrice = Infinity;
          let maxPrice = -Infinity;

          const variations = each.node.variants
            ? each.node.variants.map((variant: any) => {
                const price = parseFloat(variant.additionalPrice || "0");
                minPrice = Math.min(minPrice, price);
                maxPrice = Math.max(maxPrice, price);

                return {
                  id: variant.variantId,
                  variation: variant.name,
                  variantGroup: variant.variantGroup,
                  price: price,
                };
              })
            : [];

          const priceRange =
            minPrice !== Infinity && maxPrice !== -Infinity
              ? `${minPrice} - ${maxPrice}`
              : "0";

          return {
            price: each.node.variants ? null : each.node.price,

            variations: variations,
            categoryId: each.node.category
              ? each.node.category.categoryId
              : "no category",
            category: each.node.category
              ? each.node.category.name
              : "no category",
            categoryImage: each.node.category ? each.node.category.image : "",
            categoryDescription: each.node.category
              ? each.node.category.description
              : "",
            content: each.node.name,
            id: each.node.productId,
            courseId: each.node.course && each.node.course.courseId,
            course: each.node.course && each.node.course.name,
          };
        });
        setPizza(list);
      }
    }
  }, [productsData]);

  //course list query
  const [course, setCourse] = useState<FilteredProductData[]>([]);
  const {
    loading: courseLoading,
    error: courseDataError,
    data: courseData,
  } = useQuery(COURSE_LIST_IN_MENU, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (courseData) {
      if (courseData.courseList) {
        const { edges } = courseData.courseList;
        const filteredProductsData = edges.map((each: any) => {
          return {
            courseId: each.node.courseId,
            course: each.node.name,
          };
        });
        // This will remove null values from the array
        setCourse(filteredProductsData);
      }
    }
  }, [courseData]);

  //bundle Query
  const {
    loading: bundleLoading,
    error: bundleDataError,
    data: bundleData,
  } = useQuery(BUNDLE_LIST_IN_MENU, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: locationId },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (bundleData && bundleData.bundleList) {
      const list = bundleData.bundleList.edges.map((bundle: any) => ({
        bundleId: bundle.node.bundleId,
        bundleName: bundle.node.name || "",
        description: bundle.node.description || "",
        image: bundle.node.image || null,
        price: bundle.node.amountAfterDiscount || "",
        groups:
          JSON.parse(bundle.node.bundleGroups) &&
          JSON.parse(bundle.node.bundleGroups).map((group: any) => ({
            groupName: group.group_name,
            products:
              group.products &&
              group.products.map((product: any) => ({
                productId: product.product_id,
                productName: product.product_name,
              })),
          })),
      }));
      setBundleList(list);
    }
  }, [bundleData]);

  const handleDeleteBundle = (id: number) => {
    const list = bundleDraggedData.filter((each: any) => +each.bundleId !== id);
    setBundleDraggedData(list);
  };

  const navigate = useNavigate();
  const currncySymbol = sessionStorage.getItem("currencySymbol");
  const addMenu = () => {
    // setCreateCondition(true)
    // setIsModalOpen(true);
    navigate("/menu/create-menu");
  };

  //   useEffect(()=>{
  // if (active==="0"){

  // }
  //   },[active])

  const addMenu2 = () => {
    // setIsModalOpen(true);
    // setMenuId(+active)
    navigate(`/menu/${+active}/menu-edit/`);
  };
  //set active menu
  const defaultMenu = menuData && menuData.length > 0 ? menuData[0].menu : "";
  const result = menu === "" ? defaultMenu : menu;

  const dispatch = useDispatch();
  useEffect(() => {
    setActive(result);

    dispatch(menusetup("menu", result));
  }, [result]);

  const [isModalOpenBulk, setIsModalOpenBulk] = React.useState<boolean>(false);
  const addModalBulk = () => {
    setIsModalOpenBulk(true);
  };

  const [pulishOpen, setPublishOpen] = React.useState<boolean>(false);
  const addModalPublish = () => {
    setPublishOpen(true);
  };
  //filtering unique courses
  const filteredLeftItems = leftItems.filter((each) => each.menu === active);
  const menuItems = [...filteredLeftItems];
  const uniqueCoursesSet = new Set();
  menuItems.forEach((item) => {
    uniqueCoursesSet.add(item.course);
  });
  const uniqueCourses = Array.from(uniqueCoursesSet);

  const uniqueCoursesIdSet = new Set();
  menuItems.forEach((item) => {
    uniqueCoursesIdSet.add(item.courseId);
  });
  const uniqueCoursesId: string[] = Array.from(uniqueCoursesIdSet) as string[];
  //right items filters
  const categories = [...pizza];
  const uniqueCatgorySet = new Set(["no category"]);
  categories.forEach((item) => {
    uniqueCatgorySet.add(item.category);
  });
  const uniqueCategory = Array.from(uniqueCatgorySet);

  const requriedMenuData: State[] = menuData.filter(
    (each) => each.menu === active
  );
  const requiredApiFormat =
    uniqueCoursesId &&
    uniqueCoursesId.map((course) => {
      if (course !== null) {
        return {
          course_id: course ? Number(course) : null,
          items:
            requriedMenuData[0] &&
            requriedMenuData[0].draggedItems
              .filter((item) => item.courseId === course)
              .map((each) => {
                if (each.variations && each.variations.length > 0) {
                  return each.variations.map((variation) => ({
                    variant_id: Number(variation.id),
                  }));
                } else {
                  return {
                    product_id: Number(each.id),
                  };
                }
              })
              .flat(), // Flatten the array of items
        };
      } else {
        const getCategorytype =
          requriedMenuData[0] &&
          requriedMenuData[0].draggedItems.filter(
            (course) => course.courseId === null
          );

        const uniqueCatgoryIdSet = new Set();
        getCategorytype &&
          getCategorytype.forEach((item) => {
            uniqueCatgoryIdSet.add(item.categoryId);
          });
        const uniqueCategoryIds = Array.from(uniqueCatgoryIdSet) as string[];

        const requiredCategoryApiFormat =
          uniqueCategoryIds &&
          uniqueCategoryIds.map((category) => {
            return {
              category_id: +category, // Assuming category_id is already a number
              items: getCategorytype
                ? getCategorytype
                    .filter((item) => item.categoryId === category)
                    .map((each) => {
                      if (each.variations && each.variations.length > 0) {
                        return each.variations.map((variation) => ({
                          variant_id: +variation.id, // Assuming variant_id is already a number
                        }));
                      } else {
                        return {
                          product_id: +each.id, // Assuming product_id is already a number
                        };
                      }
                    })
                    .flat()
                : [], // If getCategorytype is undefined, set items to an empty array
            };
          });
        return requiredCategoryApiFormat;
      }
    });

  const requriedBundleIds: number[] =
    requriedMenuData[0] &&
    requriedMenuData[0].bundleList.map((each: any) => ({
      bundle_id: +each.bundleId,
    }));
  const requiredMenuId = requriedMenuData[0] && requriedMenuData[0].menu;

  const finalList = JSON.stringify([
    ...(requiredApiFormat.flat() || []),
    ...(requriedBundleIds || []),
  ]);
  const [menuSetUp, { loading: saveLoading }] = useMutation(MENU_SETUP, {
    context: { clientName: "categoryClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [MENU_LIST],
  });
  const handlePublish = () => {
    menuSetUp({
      variables: { menuId: +requiredMenuId, items: finalList },
      refetchQueries: [MENU_DATA],
    });
  };

  //main drag and drop functionality
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    const sourceListId = result.source.droppableId;
    const destinationListId = result.destination.droppableId;
    const draggbleId = result.draggableId;
    let sourceList: MenuItem[] | any = [];
    switch (sourceListId) {
      case "pizza":
        sourceList = pizza;
        break;
      case "course":
        sourceList = course;
        break;
      case "bundle":
        sourceList = bundleList;
        break;
      // Add more cases for other lists as needed
      default:
        sourceList = [];
        break;
    }
    //for single product logic
    if (sourceListId === "pizza") {
      const destinationList = leftItems;
      const draggableIdList = draggbleId.split("-");
      if (draggableIdList[0] === "pizza") {
        const draggedItem = sourceList[result.source.index];
        const copiedItem = {
          ...draggedItem,
          id: draggableIdList[1],
          menu: active,
        };
        const newDestinationList = Array.from(destinationList);
        const requriedMenu = menuData.filter(
          (each: any) => each.menu === active
        );
        const leftItemsContent = requriedMenu[0].draggedItems.map(
          (each: any) => each.content
        );
        const similarItem = leftItemsContent.includes(copiedItem.content);
        if (destinationListId === "left" && !similarItem) {
          newDestinationList.splice(result.destination.index, 0, copiedItem);
        } else if (destinationListId === "left" && !similarItem) {
          const [removedItem] = newDestinationList.splice(
            result.source.index,
            1
          );

          newDestinationList.splice(result.destination.index, 0, removedItem);
        } else if (destinationListId === "left" && similarItem) {
          toast.error(
            <div>
              <Typography>{t("error.already_existed")}</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              // className: 'custom_toast3',
            }
          );
        }
        setLeftItems(newDestinationList);
      }
      //for total category logic
      else if (draggableIdList[0] === "accordion") {
        const draggedItem = sourceList.filter(
          (each: any) => each.category === draggableIdList[1]
        );
        // const copiedItem = { ...draggedItem, id: `${destinationListId}-${Date.now()}`,menu:active };
        const copiedItem =
          draggedItem &&
          draggedItem.map((item: any) => ({
            ...item,
            menu: active,
          }));
        const newDestinationList = Array.from(destinationList);
        if (destinationListId === "left" && result?.destination) {
          copiedItem?.forEach((each: any, index: number) => {
            const requriedMenu = menuData.filter(
              (each: any) => each.menu === active
            );
            const leftItemsContent = requriedMenu[0].draggedItems.map(
              (each: any) => each.content
            );
            if (!leftItemsContent.includes(each.content)) {
              newDestinationList.splice(
                result.destination!.index + index,
                0,
                each
              );
            }
          });
        } else if (destinationListId === "left") {
          const [removedItem] = newDestinationList.splice(
            result.source.index,
            1
          );
          newDestinationList.splice(result.destination.index, 0, removedItem);
        }
        setLeftItems(newDestinationList);
      }
    }

    //course logic
    else if (sourceListId === "course") {
      const destinationList = leftItems;
      const draggedItem = course[result.source.index];
      const copiedItem = pizza
        .filter((each) => each.course === draggedItem.course)
        .map((item: any) => ({
          ...item,
          menu: active,
        }));
      if (copiedItem.length === 0) {
        return toast.error(
          <div>
            <Typography>{t("error.this_course_have_no_products")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            // className: 'custom_toast3',
          }
        );
      }
      const newDestinationList = Array.from(destinationList);
      if (destinationListId === "left" && result?.destination) {
        copiedItem?.forEach((each, index) => {
          const requriedMenu = menuData.filter(
            (each: any) => each.menu === active
          );
          const leftItemsContent = requriedMenu[0].draggedItems.map(
            (each: any) => each.content
          );
          if (!leftItemsContent.includes(each.content)) {
            newDestinationList.splice(
              result.destination!.index + index,
              0,
              each
            );
          }
        });
      } else if (destinationListId === "left") {
        const [removedItem] = newDestinationList.splice(result.source.index, 1);
        newDestinationList.splice(result.destination.index, 0, removedItem);
      }
      setLeftItems(newDestinationList);
    }

    //bundle logic
    else if (sourceListId === "bundle") {
      const destinationList = bundleDraggedData;
      const draggedItem = bundleList[result.source.index];
      const copiedItem = { ...draggedItem, menu: active };
      const newDestinationList = Array.from(destinationList);
      if (destinationListId === "left" && result?.destination) {
        const requriedMenu = menuData.filter(
          (each: any) => each.menu === active
        );
        const filterBundle = requriedMenu[0].bundleList.map(
          (each: any) => each.bundleId
        );
        if (!filterBundle.includes(copiedItem.bundleId)) {
          newDestinationList.splice(result.destination!.index, 0, copiedItem);
        } else {
          toast.error(
            <div>
              <Typography>Bundle already exists</Typography>
            </div>,
            {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
              // className: 'custom_toast3',
            }
          );
        }
      } else if (destinationListId === "left") {
        const [removedItem] = newDestinationList.splice(result.source.index, 1);
        newDestinationList.splice(result.destination.index, 0, removedItem);
      }
      setBundleDraggedData(newDestinationList);
    }
  };

  const handleDeleteVariationItem = (
    itemId: string,
    variationId: number,
    event: any,
    variationGroup: string
  ) => {
    //if variation length is 0 and that variant id is variant id then delete that product
    const list = leftItems.filter((each) => each.id === itemId);
    const variationLength = list[0].variations.filter(
      (each) => each.variantGroup === variationGroup
    ).length;
    if (
      variationLength === 1
      //  && list[0].variations[0].id === variationId
    ) {
      handleDeleteItem(+itemId);
    }

    setLeftItems((prev) => {
      return prev.map((each) => {
        const variation = each.variations.filter(
          (variation) => variation.id !== variationId
        );
        const priceRange =
          each.variations.length > 0
            ? Math.min(...variation.map((variation) => variation.price)) !==
              Math.max(...variation.map((variation) => variation.price))
              ? `${Math.min(
                  ...variation.map((variation) => variation.price)
                )} - ${Math.max(
                  ...variation.map((variation) => variation.price)
                )}`
              : Math.max(
                  ...variation.map((variation) => variation.price)
                ).toString()
            : each.price;
        if (each.id === itemId) {
          return {
            ...each,
            variations: variation,
            price: priceRange,
          };
        }
        return each;
      });
    });
  };

  const handleDeleteItem = (id: number) => {
    const updatedItems = leftItems.filter(
      (each) => each.id.toString() !== id.toString()
    );
    setLeftItems(updatedItems);
  };

  const [startTime, setStartTime] = useState<string>("");
  const [EndTime, setEndTime] = useState<string>("");

  const [bundleIds, setBundleIds] = useState<number[]>([]);
  const [publishOn, setPublishOn] = useState<string | null>(null);
  const {
    data: menuApiData,
    refetch,
    loading: menuDataLoading,
  } = useQuery(MENU_DATA, {
    context: { clientName: "categoryClient" },
    variables: { menuId: +active },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (menuApiData) {
      if (menuApiData.menu) {
        if (menuApiData.menu.menuItems) {
          // const list: MenuItem2[] = JSON.parse(menuApiData.menu.menuItems).courses.map((each: any) => ({
          //   id: each.product_id,
          //   content: each.product_name,
          //   price: each.product_price || "0",
          //   courseId: each.course_id ? each.course_id : null,
          //   course: each.course_name ? each.course_name : null,
          //   category: each.category_name ? each.category_name : "",
          //   categoryId: each.category_id ? each.category_id : "",
          //   categoryImage: each.category_image ? each.category_image : "",
          //   categoryDescription: each.category_description ? each.category_description : "",
          //   menu: active,
          //   variations: each.variants ? each.variants.map((variant: any) => ({
          //     id: variant.variant_id,
          //     variation: variant.variant_name,
          //     price: variant.variant_price

          //   })) : [],
          // }))
          const list: MenuItem2[] = JSON.parse(
            menuApiData.menu.menuItems
          ).courses.map((each: any) => {
            let minPrice = Infinity;
            let maxPrice = -Infinity;

            const variations = each.variants
              ? each.variants.map((variant: any) => {
                  const price = parseFloat(variant.variant_price || "0");
                  minPrice = Math.min(minPrice, price);
                  maxPrice = Math.max(maxPrice, price);

                  return {
                    id: variant.variant_id,
                    variation: variant.variant_name,
                    variantGroup: variant.variant_group,
                    price: variant.variant_additional_price,
                  };
                })
              : [];

            const priceRange =
              minPrice !== Infinity && maxPrice !== -Infinity
                ? `${minPrice} - ${maxPrice}`
                : "0";

            return {
              id: each.product_id,
              content: each.product_name,
              price:
                each.variants && each.variants.length > 0
                  ? null
                  : each.product_price,
              courseId: each.course_id ? each.course_id : null,
              course: each.course_name ? each.course_name : null,
              category: each.category_name ? each.category_name : "",
              categoryId: each.category_id ? each.category_id : "",
              categoryImage: each.category_image ? each.category_image : "",
              categoryDescription: each.category_description
                ? each.category_description
                : "",
              menu: active,
              variations: variations,
            };
          });

          setLeftItems(list);
          setBundleIds(JSON.parse(menuApiData.menu.menuItems).bundles);
        }

        setPublishOn(menuApiData.menu.publishOn);
      }

      if (menuApiData && menuApiData.menu && menuApiData.menu.showOn) {
        setStartTime(
          JSON.parse(menuApiData.menu.showOn)[0]?.Monday?.openTime || null
        );
        setEndTime(
          JSON.parse(menuApiData.menu.showOn)[0]?.Monday?.closingTime || null
        );
      }
      // else {
      //   setLeftItems([])
      // }
    }
  }, [menuApiData]);

  const handleApiCall = () => {};
  useEffect(() => {
    refetch();
  }, [active]);

  useEffect(() => {
    const list =
      bundleList &&
      bundleList.filter((each: any) => bundleIds.includes(+each.bundleId));

    const addMenuInlist =
      list &&
      list.map((each: any) => ({
        ...each,
        menu: active,
      }));
    setBundleDraggedData(addMenuInlist);
  }, [bundleIds]);

  const handleActive = (value: string) => {
    setActive(value);
    dispatch(menusetup("menu", value));
    handleApiCall();
  };

  const [duplicateMenu] = useMutation(DUPLICATE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleDuplicate = (id: number) => {
    duplicateMenu({ variables: { menuId: id } });
  };
  const [delteBundleId, setDeleteBundleId] = useState("");
  const getDeleteMenuId = (id: string) => {
    setDeleteBundleId(id);
  };
  const [deletemodal, setDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    setDeleteBundleId("");
  };

  const [deletMenu] = useMutation(DELETE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const handleDeleteMenu = (id: string) => {
    deletMenu({ variables: { menuIds: JSON.stringify([+id]) } });
    setDeleteBundleId("");
  };

  const handleDeleteClick = () => {};
  function CustomPopoverCell({ menuId }: { menuId: number }) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const popoverContent = (
      <List style={{ padding: "0px 0px 0px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={addMenu2}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu.edit")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleDeleteModal();
            getDeleteMenuId(menuId.toString());
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu.delete")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={addModalBulk}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu.adjustPrices")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => handleDuplicate(menuId)}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("menu.duplicate")} />
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }
  const [bundleId, setBundleId] = useState<number>(0);
  const upDateBundleId = (id: number) => {
    setBundleId(id);
  };

  const [deletemodal1, setDeleteModal1] = useState<boolean>(false);

  const handleDeleteModal1 = () => {
    setDeleteModal1(!deletemodal1);
  };

  const [deleteCourseModal, setDeleteCourseModal] = useState<boolean>(false);

  const handleDeleteCourseModal = () => {
    setDeleteCourseModal(!deleteCourseModal);
  };

  const [courseId, setCourseId] = useState<string>("");
  const upDateCourseId = (id: string) => {
    setCourseId(id);
  };

  const handleDeleteCourse = (id: string) => {
    const updatedList = leftItems.filter((item) => item.course !== id);
    setLeftItems(updatedList);
  };

  const handleCategoryDelete = (id: string) => {
    const updatedList = leftItems.filter((item) => item.category !== id);
    setLeftItems(updatedList);
  };

  const [categoryDeleteId, setCategoryDeleteId] = useState<string>("");

  const [productId, setProductId] = useState<number>(0);
  const upDateProductId = (id: number) => {
    setProductId(id);
  };
  const [categoryDeleteModal, setCategoryDeleteModal] =
    useState<boolean>(false);
  const handleCategoryDeleteModal = () => {
    setCategoryDeleteModal(!categoryDeleteModal);
  };

  const [productDeletemodal, setProductDeleteModal] = useState<boolean>(false);

  const handleDeleteProductModal = () => {
    setProductDeleteModal(!productDeletemodal);
  };

  const [expandedAccordions, setExpandedAccordions] = useState<string[]>([]);
  const handleAccordionToggle = (panel: string) => () => {
    const isExpanded = expandedAccordions.includes(panel);
    const newExpandedAccordions = isExpanded
      ? expandedAccordions.filter((accordion) => accordion !== panel)
      : [...expandedAccordions, panel];

    setExpandedAccordions(newExpandedAccordions);
  };

  return (
    <Box sx={{ padding: "2%" }}>
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Typography className="heavyHeader">
          {t("menu_setUp.menu_manageMent")}
        </Typography>
        {active !== "" && (
          <Box
            sx={{
              marginLeft: "4%",
              display: "flex",
              alignItems: "center",
              width: "56%",
            }}
          >
            {menuData && menuData.length > 0 && (
              <Typography className="heavyHeader">
                {menuData.filter((each) => each.menu === active)[0]?.id}
              </Typography>
            )}
            {startTime && EndTime && (
              <Typography
                className="menuSetup-time-text"
                sx={{ marginLeft: "3%" }}
              >
                {t("menu_setUp.only")} {startTime} {t("menu_setUp.to")}{" "}
                {EndTime}
              </Typography>
            )}
            {/* {menuData.map((each) => {
           

               

             
              return null
            })} */}
            <Box sx={{ marginLeft: "auto", display: "flex" }}>
              {/* <Button className='menuSetup-button2' onClick={handlePublish}>Publish</Button> */}
              {publishOn && (
                <Tooltip
                  title={
                    <div>
                      <Typography className="menu-Listing-pover-text-style">
                        Publish On{" "}
                        {moment(publishOn).format("MM-DD-YYYY HH:mm")}
                      </Typography>
                    </div>
                  }
                  placement="top-start"
                  classes={{ tooltip: "custom-tooltip" }}
                >
                  <Button sx={{ m: 1 }}>
                    <div
                      style={{ position: "relative", display: "inline-block" }}
                      //  className="custom-icon-container"
                    >
                      {/* <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="var(--Text-Grey-1, #D9D9D9)">
                      <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                    </svg> */}
                      <Box
                        component="span"
                        style={{
                          position: "absolute",
                          top: "50%",
                          left: "50%",
                          transform: "translate(-50%, -50%)",
                          color: "black",
                          fontWeight: "bold",
                          fontSize: "16px",
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <g opacity="0.8">
                            <path
                              d="M14.0001 1H10.0001C9.4501 1 9.0001 1.45 9.0001 2C9.0001 2.55 9.4501 3 10.0001 3H14.0001C14.5501 3 15.0001 2.55 15.0001 2C15.0001 1.45 14.5501 1 14.0001 1ZM12.0001 14C12.5501 14 13.0001 13.55 13.0001 13V9C13.0001 8.45 12.5501 8 12.0001 8C11.4501 8 11.0001 8.45 11.0001 9V13C11.0001 13.55 11.4501 14 12.0001 14ZM19.0301 7.39L19.7801 6.64C20.1601 6.26 20.1701 5.63 19.7801 5.24L19.7701 5.23C19.3801 4.84 18.7601 4.85 18.3701 5.23L17.6201 5.98C16.0701 4.74 14.1201 4 12.0001 4C7.2001 4 3.1201 7.96 3.0001 12.76C2.8701 17.84 6.9401 22 12.0001 22C16.9801 22 21.0001 17.97 21.0001 13C21.0001 10.88 20.2601 8.93 19.0301 7.39ZM12.0001 20C8.1301 20 5.0001 16.87 5.0001 13C5.0001 9.13 8.1301 6 12.0001 6C15.8701 6 19.0001 9.13 19.0001 13C19.0001 16.87 15.8701 20 12.0001 20Z"
                              fill="#F38B08"
                            />
                          </g>
                        </svg>
                      </Box>
                    </div>
                  </Button>
                </Tooltip>
              )}
              <Button
                className="menuSetup-button2"
                sx={{ marginRight: "12px", textTransform: "none" }}
                onClick={handlePublish}
                disabled={saveLoading}
              >
                {t("buttons.save")}
              </Button>
              <Button
                sx={{ textTransform: "none" }}
                className="menuSetup-button2"
                onClick={addModalPublish}
              >
                {t("menu_setUp.publish")}
              </Button>
              {/* <IconButton onClick={addModalBulk} >
                <MoreVertOutlined />
              </IconButton> */}
              <CustomPopoverCell menuId={+active} />
            </Box>
          </Box>
        )}
      </Box>

      <Divider sx={{ margin: "12px 0% 12px 0px" }} />

      <BulkAdujustment
        openModal={isModalOpenBulk}
        handleClose={() => setIsModalOpenBulk(false)}
      />
      <Publish
        openModal={pulishOpen}
        handleClose={() => setPublishOpen(false)}
        finalList={finalList}
        active={active}
      />

      <Box sx={{ display: "flex" }}>
        <Box sx={{ display: "flex", flexDirection: "column", width: "16%" }}>
          <Button
            className="menuSetup-button"
            sx={{ width: "100%" }}
            onClick={addMenu}
          >
            {t("menu_setUp.add_menu")}
          </Button>
          <List sx={{ width: "100%" }}>
            {menuData.map((each) => {
              if (each.menu !== "") {
                return (
                  <ListItem
                    key={each.menu}
                    className={
                      active === each.menu
                        ? "menuSetup-menu2"
                        : "menuSetup-menu"
                    }
                    onClick={() => handleActive(each.menu)}
                  >
                    {active === each.menu ? (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          className="dataGrid-table-cell"
                          style={{ width: "80%" }}
                        >
                          {each.id}{" "}
                        </Typography>
                        <KeyboardArrowRightIcon />
                      </Box>
                    ) : (
                      <Typography className="dataGrid-table-cell-less-wt">
                        {each.id}
                      </Typography>
                    )}
                  </ListItem>
                );
              }
              return null;
            })}
          </List>
        </Box>
        {menuDataLoading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "80%",
            }}
          >
            <CircularLoader />
          </Box>
        ) : (
          menuData.map((each) => {
            return each.menu === active ? (
              <div style={{ display: "flex", width: "100%" }}>
                <DragDropContext onDragEnd={onDragEnd}>
                  <div
                    style={{
                      width: "80%",
                      overflowY: "scroll",
                      maxHeight: "70vh",
                      marginLeft: "2%",
                    }}
                  >
                    <StrictModeDroppable droppableId="left" type="list">
                      {(provided) => (
                        <ul
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            listStyle: "none",
                            padding: "0",
                            minHeight: "70vh",
                          }}
                        >
                          {(each.draggedItems &&
                            each.draggedItems.length > 0) ||
                          (each.bundleList && each.bundleList.length > 0) ||
                          menuDataLoading ? (
                            <>
                              {uniqueCourses.map((item, index) => {
                                const filteredItems = each.draggedItems.filter(
                                  (each) => each.course === item
                                );
                                const uniquePizza: { [key: string]: boolean } =
                                  {};
                                const deduplicatedPizza = filteredItems.filter(
                                  (item) => {
                                    if (!uniquePizza[item.content]) {
                                      uniquePizza[item.content] = true;
                                      return true;
                                    }
                                    return false;
                                  }
                                );
                                filteredItems.length = 0;
                                Array.prototype.push.apply(
                                  filteredItems,
                                  deduplicatedPizza
                                );

                                const menuItems = [...filteredItems];

                                const uniqueCategorySet = new Set();

                                menuItems.forEach((item) => {
                                  uniqueCategorySet.add(item.category);
                                });

                                const uniqueCategory =
                                  Array.from(uniqueCategorySet);

                                return (
                                  <div
                                    key={String(item)}
                                    style={{ marginTop: "12px" }}
                                  >
                                    {/* <Draggable
                                      draggableId={`left-${item}`} // Use a unique identifier
                                      index={index}
                                    >
                                      {(provided) => (
                                        <li
                                          key={`left-${item}`}
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            margin: "8px",
                                            padding: "8px",
                                            borderRadius: "4px",
                                            ...provided.draggableProps.style,
                                          }}
                                        > */}
                                    <CustomStyles>
                                      {item ? (
                                        <Accordion
                                          key={index}
                                          sx={{
                                            border: "1px solid #EEE",
                                          }}
                                          elevation={0}
                                          expanded={expandedAccordions.includes(
                                            `panel-${item}-header`
                                          )}
                                          onChange={handleAccordionToggle(
                                            `panel-${item}-header`
                                          )}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-${item}-content`}
                                            id={`panel-${item}-header`}
                                            key={each.id}
                                          >
                                            {String(item) !== "null" && (
                                              <Typography className="normalHeader">
                                                {String(item)}
                                              </Typography>
                                            )}
                                            {String(item) !== "null" && (
                                              <IconButton
                                                onClick={() => {
                                                  handleDeleteCourseModal();
                                                  upDateCourseId(String(item));
                                                }}
                                                sx={{
                                                  "&: hover": {
                                                    backgroundColor: "FFF8F0",
                                                  },
                                                  marginLeft: "auto",
                                                }}
                                              >
                                                <CloseOutlined />
                                              </IconButton>
                                            )}
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            {uniqueCategory.map(
                                              (filteredItem, index) => (
                                                <Accordion
                                                  key={String(filteredItem)}
                                                  sx={{
                                                    border: "1px solid #EEE",
                                                    marginBottom: "2%",
                                                  }}
                                                  elevation={0}
                                                  expanded={expandedAccordions.includes(
                                                    `nested-panel-${filteredItem}-${item}-header`
                                                  )}
                                                  onChange={handleAccordionToggle(
                                                    `nested-panel-${filteredItem}-${item}-header`
                                                  )}
                                                >
                                                  <AccordionSummary
                                                    expandIcon={
                                                      <ExpandMoreIcon />
                                                    }
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                  >
                                                    <Box
                                                      sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        width: "100%",
                                                        justifyContent:
                                                          "space-between",
                                                      }}
                                                    >
                                                      {each.draggedItems.filter(
                                                        (each) =>
                                                          each.category ===
                                                          String(filteredItem)
                                                      ).length > 0 &&
                                                        each.draggedItems.filter(
                                                          (each) =>
                                                            each.category ===
                                                            String(filteredItem)
                                                        )[0].categoryImage && (
                                                          <Box
                                                            className="dragDropImgBackground"
                                                            sx={{
                                                              marginRight:
                                                                "15px",
                                                            }}
                                                          >
                                                            <img
                                                              src={
                                                                each.draggedItems.filter(
                                                                  (each) =>
                                                                    each.category ===
                                                                    String(
                                                                      filteredItem
                                                                    )
                                                                )[0]
                                                                  .categoryImage
                                                              }
                                                              alt="product"
                                                              style={{
                                                                width: "18px",
                                                                height: "18px",
                                                              }}
                                                            />
                                                          </Box>
                                                        )}

                                                      <Box
                                                        sx={{
                                                          width: "80%",
                                                        }}
                                                      >
                                                        <Typography
                                                          className="table-column-header"
                                                          sx={{
                                                            textTransform:
                                                              "capitalize",
                                                          }}
                                                        >
                                                          {" "}
                                                          {String(filteredItem)}
                                                        </Typography>
                                                        {each.draggedItems.filter(
                                                          (each) =>
                                                            each.category ===
                                                            String(filteredItem)
                                                        ).length > 0 &&
                                                          each.draggedItems.filter(
                                                            (each) =>
                                                              each.category ===
                                                              String(
                                                                filteredItem
                                                              )
                                                          )[0]
                                                            .categoryDescription && (
                                                            <Typography className="normal-text">
                                                              {
                                                                each.draggedItems.filter(
                                                                  (each) =>
                                                                    each.category ===
                                                                    String(
                                                                      filteredItem
                                                                    )
                                                                )[0]
                                                                  .categoryDescription
                                                              }{" "}
                                                            </Typography>
                                                          )}
                                                      </Box>
                                                      <IconButton
                                                        onClick={() => {
                                                          setCategoryDeleteId(
                                                            String(filteredItem)
                                                          );
                                                          handleCategoryDeleteModal();
                                                        }}
                                                        sx={{
                                                          "&: hover": {
                                                            backgroundColor:
                                                              "FFF8F0",
                                                          },
                                                          marginLeft: "20%",
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </IconButton>
                                                    </Box>
                                                  </AccordionSummary>
                                                  <AccordionDetails>
                                                    <Divider />
                                                    {filteredItems &&
                                                      filteredItems.map(
                                                        (each) => {
                                                          if (
                                                            each.category ===
                                                            filteredItem
                                                          ) {
                                                            return (
                                                              <div
                                                                key={each.id}
                                                              >
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    marginTop:
                                                                      "2%",
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  <Typography
                                                                    className="dragDrop-text3"
                                                                    sx={{
                                                                      marginRight:
                                                                        "6%",

                                                                      textTransform:
                                                                        "capitalize",
                                                                    }}
                                                                  >
                                                                    {
                                                                      each.content
                                                                    }
                                                                  </Typography>
                                                                  <Box
                                                                    sx={{
                                                                      display:
                                                                        "flex",
                                                                      alignItems:
                                                                        "center",
                                                                      marginLeft:
                                                                        "auto",
                                                                      justifyContent:
                                                                        "space-between",
                                                                      width:
                                                                        "30%",
                                                                    }}
                                                                  >
                                                                    {each.price && (
                                                                      <CurrencyDisplay
                                                                        countryName={
                                                                          "UnitedKingdom"
                                                                        }
                                                                        amount={
                                                                          each.price &&
                                                                          each.price
                                                                            .toString()
                                                                            .split(
                                                                              "-"
                                                                            )
                                                                        }
                                                                      />
                                                                    )}
                                                                    <IconButton
                                                                      onClick={() => {
                                                                        handleDeleteProductModal();
                                                                        upDateProductId(
                                                                          +each.id
                                                                        );
                                                                      }}
                                                                      sx={{
                                                                        marginLeft:
                                                                          "auto",
                                                                      }}
                                                                    >
                                                                      <CloseOutlined />
                                                                    </IconButton>
                                                                  </Box>
                                                                </div>
                                                                <div
                                                                  style={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "start",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    // marginTop:
                                                                    //   "2%",
                                                                    width:
                                                                      "100%",
                                                                  }}
                                                                >
                                                                  <Box>
                                                                    {[
                                                                      "Size",
                                                                      "Base",
                                                                      "Preparation type",
                                                                    ].map(
                                                                      (
                                                                        value: string
                                                                      ) =>
                                                                        each.variations.filter(
                                                                          (
                                                                            item
                                                                          ) =>
                                                                            item.variantGroup ===
                                                                            value
                                                                        )
                                                                          .length >
                                                                          0 && (
                                                                          <Box
                                                                            sx={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              gap: "8px",
                                                                            }}
                                                                          >
                                                                            <Typography
                                                                              sx={{
                                                                                fontSize:
                                                                                  "12px",
                                                                                color:
                                                                                  "#707070",
                                                                                fontFamily:
                                                                                  "Poppins",
                                                                              }}
                                                                            >
                                                                              {
                                                                                value
                                                                              }{" "}
                                                                              :
                                                                            </Typography>
                                                                            <Stack
                                                                              direction="row"
                                                                              spacing={
                                                                                1
                                                                              }
                                                                              sx={{
                                                                                display:
                                                                                  "flex",
                                                                                flexWrap:
                                                                                  "wrap",
                                                                              }}
                                                                            >
                                                                              {each.variations &&
                                                                                each.variations
                                                                                  .filter(
                                                                                    (
                                                                                      item
                                                                                    ) =>
                                                                                      item.variantGroup ===
                                                                                      value
                                                                                  )
                                                                                  .map(
                                                                                    (
                                                                                      each1
                                                                                    ) => (
                                                                                      <CustomStyles2>
                                                                                        <Chip
                                                                                          sx={{
                                                                                            textTransform:
                                                                                              "capitalize",
                                                                                          }}
                                                                                          label={
                                                                                            <Typography
                                                                                              sx={{
                                                                                                fontSize:
                                                                                                  "12px",
                                                                                                fontFamily:
                                                                                                  "Poppins",
                                                                                              }}
                                                                                            >{`${each1.variation}   ${currncySymbol}${each1.price}`}</Typography>
                                                                                          }
                                                                                          key={
                                                                                            each1.id
                                                                                          }
                                                                                          onDelete={(
                                                                                            event: any
                                                                                          ) =>
                                                                                            handleDeleteVariationItem(
                                                                                              each.id,
                                                                                              each1.id,
                                                                                              event,
                                                                                              each1.variantGroup
                                                                                            )
                                                                                          }
                                                                                        />
                                                                                      </CustomStyles2>
                                                                                    )
                                                                                  )}
                                                                            </Stack>
                                                                          </Box>
                                                                        )
                                                                    )}
                                                                  </Box>
                                                                </div>
                                                                <Divider
                                                                  sx={{
                                                                    marginTop:
                                                                      "2%",
                                                                  }}
                                                                />
                                                              </div>
                                                            );
                                                          }
                                                          return null;
                                                        }
                                                      )}
                                                  </AccordionDetails>
                                                </Accordion>
                                              )
                                            )}
                                          </AccordionDetails>
                                        </Accordion>
                                      ) : (
                                        <>
                                          {/* <Accordion key={index} sx={{ border: "1px solid #EEE" }} elevation={0}
                                              expanded={expandedAccordions.includes(`panel-${item}-header`)}
                                              onChange={handleAccordionToggle(`panel-${item}-header`)}
                                            >
                                              <AccordionSummary
                                                expandIcon={<ExpandMoreIcon />}
                                                aria-controls={`panel-${item}-content`}
                                                id={`panel-${item}-header`}
                                                key={each.id}
                                              >

                                                {String(item) !== "null" && <Typography className='normalHeader'>{String(item)}</Typography>}
                                                {String(item) !== "null" &&
                                                  <IconButton onClick={() => { handleDeleteCourseModal(); upDateCourseId(String(item)) }} sx={{ "&: hover": { backgroundColor: "FFF8F0" }, marginLeft: "auto" }}>
                                                    <CloseOutlined />
                                                  </IconButton>}


                                              </AccordionSummary>
                                              <AccordionDetails> */}
                                          {uniqueCategory.map(
                                            (filteredItem) => (
                                              <Accordion
                                                key={String(filteredItem)}
                                                sx={{
                                                  border: "1px solid #EEE",
                                                  marginBottom: "2%",
                                                }}
                                                elevation={0}
                                                expanded={expandedAccordions.includes(
                                                  `nested-panel-${filteredItem}-header`
                                                )}
                                                onChange={handleAccordionToggle(
                                                  `nested-panel-${filteredItem}-header`
                                                )}
                                              >
                                                <AccordionSummary
                                                  expandIcon={
                                                    <ExpandMoreIcon />
                                                  }
                                                  aria-controls="panel1a-content"
                                                  id="panel1a-header"
                                                >
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      alignItems: "center",
                                                      width: "100%",
                                                      justifyContent:
                                                        "space-between",
                                                    }}
                                                  >
                                                    {each.draggedItems.filter(
                                                      (each) =>
                                                        each.category ===
                                                        String(filteredItem)
                                                    ).length > 0 &&
                                                      each.draggedItems.filter(
                                                        (each) =>
                                                          each.category ===
                                                          String(filteredItem)
                                                      )[0].categoryImage && (
                                                        <Box
                                                          className="dragDropImgBackground"
                                                          sx={{
                                                            marginRight: "15px",
                                                          }}
                                                        >
                                                          <img
                                                            src={
                                                              each.draggedItems.filter(
                                                                (each) =>
                                                                  each.category ===
                                                                  String(
                                                                    filteredItem
                                                                  )
                                                              )[0].categoryImage
                                                            }
                                                            alt="product"
                                                            style={{
                                                              width: "18px",
                                                              height: "18px",
                                                            }}
                                                          />
                                                        </Box>
                                                      )}

                                                    <Box
                                                      sx={{
                                                        width: "80%",
                                                      }}
                                                    >
                                                      <Typography className="table-column-header">
                                                        {" "}
                                                        {String(filteredItem)}
                                                      </Typography>
                                                      {each.draggedItems.filter(
                                                        (each) =>
                                                          each.category ===
                                                          String(filteredItem)
                                                      ).length > 0 &&
                                                        each.draggedItems.filter(
                                                          (each) =>
                                                            each.category ===
                                                            String(filteredItem)
                                                        )[0]
                                                          .categoryDescription && (
                                                          <Typography className="normal-text">
                                                            {
                                                              each.draggedItems.filter(
                                                                (each) =>
                                                                  each.category ===
                                                                  String(
                                                                    filteredItem
                                                                  )
                                                              )[0]
                                                                .categoryDescription
                                                            }{" "}
                                                          </Typography>
                                                        )}
                                                    </Box>
                                                    <IconButton
                                                      onClick={() => {
                                                        setCategoryDeleteId(
                                                          String(filteredItem)
                                                        );
                                                        handleCategoryDeleteModal();
                                                      }}
                                                      sx={{
                                                        "&: hover": {
                                                          backgroundColor:
                                                            "FFF8F0",
                                                        },
                                                        marginLeft: "20%",
                                                      }}
                                                    >
                                                      <CloseOutlined />
                                                    </IconButton>
                                                  </Box>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                  <Divider />
                                                  {filteredItems &&
                                                    filteredItems.map(
                                                      (each) => {
                                                        if (
                                                          each.category ===
                                                          filteredItem
                                                        ) {
                                                          return (
                                                            <div key={each.id}>
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "center",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  marginTop:
                                                                    "2%",
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <Typography
                                                                  className="dragDrop-text3"
                                                                  sx={{
                                                                    marginRight:
                                                                      "6%",

                                                                    textTransform:
                                                                      "capitalize",
                                                                  }}
                                                                >
                                                                  {each.content}
                                                                </Typography>
                                                                <Box
                                                                  sx={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                    marginLeft:
                                                                      "auto",
                                                                    justifyContent:
                                                                      "space-between",
                                                                    width:
                                                                      "30%",
                                                                  }}
                                                                >
                                                                  {each.price && (
                                                                    <CurrencyDisplay
                                                                      countryName={
                                                                        "UnitedKingdom"
                                                                      }
                                                                      amount={
                                                                        each.price &&
                                                                        each.price
                                                                          .toString()
                                                                          .split(
                                                                            "-"
                                                                          )
                                                                      }
                                                                    />
                                                                  )}
                                                                  <IconButton
                                                                    onClick={() => {
                                                                      handleDeleteProductModal();
                                                                      upDateProductId(
                                                                        +each.id
                                                                      );
                                                                    }}
                                                                    sx={{
                                                                      marginLeft:
                                                                        "auto",
                                                                    }}
                                                                  >
                                                                    <CloseOutlined />
                                                                  </IconButton>
                                                                </Box>
                                                              </div>
                                                              <div
                                                                style={{
                                                                  display:
                                                                    "flex",
                                                                  alignItems:
                                                                    "start",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  // marginTop:
                                                                  //   "2%",
                                                                  width: "100%",
                                                                }}
                                                              >
                                                                <Box>
                                                                  {[
                                                                    "Size",
                                                                    "Base",
                                                                    "Preparation type",
                                                                  ].map(
                                                                    (
                                                                      value: string
                                                                    ) =>
                                                                      each.variations.filter(
                                                                        (
                                                                          item
                                                                        ) =>
                                                                          item.variantGroup ===
                                                                          value
                                                                      ).length >
                                                                        0 && (
                                                                        <Box
                                                                          sx={{
                                                                            display:
                                                                              "flex",
                                                                            alignItems:
                                                                              "center",
                                                                            gap: "8px",
                                                                          }}
                                                                        >
                                                                          <Typography
                                                                            sx={{
                                                                              fontSize:
                                                                                "12px",
                                                                              color:
                                                                                "#707070",
                                                                              fontFamily:
                                                                                "Poppins",
                                                                            }}
                                                                          >
                                                                            {
                                                                              value
                                                                            }{" "}
                                                                            :
                                                                          </Typography>
                                                                          <Stack
                                                                            direction="row"
                                                                            spacing={
                                                                              1
                                                                            }
                                                                            sx={{
                                                                              display:
                                                                                "flex",
                                                                              flexWrap:
                                                                                "wrap",
                                                                            }}
                                                                          >
                                                                            {each.variations &&
                                                                              each.variations
                                                                                .filter(
                                                                                  (
                                                                                    item
                                                                                  ) =>
                                                                                    item.variantGroup ===
                                                                                    value
                                                                                )
                                                                                .map(
                                                                                  (
                                                                                    each1
                                                                                  ) => (
                                                                                    <CustomStyles2>
                                                                                      <Chip
                                                                                        sx={{
                                                                                          textTransform:
                                                                                            "capitalize",
                                                                                        }}
                                                                                        label={
                                                                                          <Typography
                                                                                            sx={{
                                                                                              fontSize:
                                                                                                "12px",
                                                                                              fontFamily:
                                                                                                "Poppins",
                                                                                            }}
                                                                                          >{`${each1.variation}    ${currncySymbol}${each1.price}`}</Typography>
                                                                                        }
                                                                                        key={
                                                                                          each1.id
                                                                                        }
                                                                                        onDelete={(
                                                                                          event: any
                                                                                        ) =>
                                                                                          handleDeleteVariationItem(
                                                                                            each.id,
                                                                                            each1.id,
                                                                                            event,
                                                                                            each1.variantGroup
                                                                                          )
                                                                                        }
                                                                                      />
                                                                                    </CustomStyles2>
                                                                                  )
                                                                                )}
                                                                          </Stack>
                                                                        </Box>
                                                                      )
                                                                  )}
                                                                </Box>
                                                              </div>

                                                              <Divider
                                                                sx={{
                                                                  marginTop:
                                                                    "2%",
                                                                }}
                                                              />
                                                            </div>
                                                          );
                                                        }
                                                        return null;
                                                      }
                                                    )}
                                                </AccordionDetails>
                                              </Accordion>
                                            )
                                          )}
                                          {/* </AccordionDetails>
                                            </Accordion> */}
                                        </>
                                      )}
                                    </CustomStyles>
                                    {/* </li>
                                      )}
                                    </Draggable> */}
                                  </div>
                                );
                              })}
                              {provided.placeholder}
                            </>
                          ) : (
                            <Box sx={{ textAlign: "center" }}>
                              <img src={image2} alt="menu" />
                              <Typography
                                className="dragDrop-text4"
                                sx={{ mt: "12px" }}
                              >
                                {t("menu_setUp.drag_text")}
                              </Typography>
                            </Box>
                          )}

                          <Box>
                            {each.bundleList.map((each: any) => (
                              <Box
                                sx={{
                                  marginTop: "8px",
                                  // padding: "8px",
                                  borderRadius: "4px",
                                }}
                              >
                                <CustomStyles>
                                  <Accordion
                                    sx={{
                                      border: "1px solid #EEE",
                                      //  marginLeft: "2%",
                                      //  marginRight: "2%",
                                    }}
                                    elevation={0}
                                  >
                                    <AccordionSummary
                                      expandIcon={<ExpandMoreIcon />}
                                      aria-controls="panel1-content"
                                      id="panel1-header"
                                    >
                                      <Divider />
                                      <Box
                                        sx={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          width: "100%",
                                        }}
                                      >
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                            width: "70%",
                                          }}
                                        >
                                          {each.image && (
                                            <Box className="dragDropImgBackground">
                                              <img
                                                src={each.image}
                                                alt="product"
                                                style={{
                                                  width: "18px",
                                                  height: "18px",
                                                }}
                                              />
                                            </Box>
                                          )}
                                          <Box
                                          // sx={{ marginLeft: "10%" }}
                                          >
                                            <Typography className="table-column-header">
                                              {each.bundleName}
                                            </Typography>
                                            <Typography className="normal-text">
                                              {each.description}
                                            </Typography>
                                          </Box>
                                        </Box>
                                        <Box
                                          sx={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                        >
                                          <Typography
                                            sx={{ marginRight: "10px" }}
                                            className="menuSetup-price-text"
                                          >
                                            <CurrencyDisplay
                                              countryName={"UnitedKingdom"}
                                              amount={
                                                each.price
                                                  ? each.price
                                                      .toString()
                                                      .split("-")
                                                  : "0"
                                              }
                                            />
                                          </Typography>
                                          <IconButton
                                            onClick={() => {
                                              upDateBundleId(+each.bundleId);
                                              handleDeleteModal1();
                                            }}
                                            sx={{
                                              "&: hover": {
                                                backgroundColor: "FFF8F0",
                                              },
                                            }}
                                          >
                                            <CloseOutlined />
                                          </IconButton>
                                        </Box>
                                      </Box>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      {each.groups.map((group: any) => (
                                        <Box>
                                          <div
                                            style={{
                                              display: "flex",
                                              alignItems: "center",
                                              marginTop: "2%",
                                            }}
                                          >
                                            <Typography
                                              className="dragDrop-text3"
                                              sx={{
                                                marginRight: "6%",
                                                textTransform: "capitalize",
                                              }}
                                            >
                                              {group.groupName}
                                            </Typography>
                                            <Stack
                                              direction="row"
                                              spacing={1}
                                              flexWrap="wrap"
                                            >
                                              {group.products &&
                                                group.products.map(
                                                  (eachProduct: any) => (
                                                    <CustomStyles2>
                                                      <Chip
                                                        sx={{
                                                          textTransform:
                                                            "capitalize",
                                                        }}
                                                        label={
                                                          eachProduct.productName
                                                        }
                                                        key={
                                                          eachProduct.productId
                                                        }
                                                      />
                                                    </CustomStyles2>
                                                  )
                                                )}
                                            </Stack>
                                          </div>
                                          <Divider sx={{ marginTop: "1%" }} />
                                        </Box>
                                      ))}
                                    </AccordionDetails>
                                  </Accordion>
                                </CustomStyles>
                              </Box>
                            ))}
                          </Box>
                        </ul>
                      )}
                    </StrictModeDroppable>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginLeft: "2%",
                      width: "34%",
                      height: "70vh",
                      overflowY: "scroll",
                      WebkitOverflowScrolling: "touch",
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <style>
                        {`
      ::-webkit-scrollbar {
        display: none;
      }
    `}
                      </style>
                      <StrictModeDroppable droppableId="course" type="list">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ listStyle: "none", padding: "0" }}
                          >
                            <div>
                              <CustomStyles>
                                <Accordion
                                  key="2"
                                  sx={{
                                    marginBottom: "12px",
                                    border: "1px solid #EEE",
                                  }}
                                  elevation={0}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className="normalHeader">
                                      {t("menu_setUp.course")}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {course.map((item: any, index) => {
                                      return (
                                        <Draggable
                                          draggableId={item.courseId} // Use a unique identifier
                                          index={index}
                                          key={item.courseId}
                                        >
                                          {(provided) => (
                                            <li
                                              key={item.courseId}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                padding: "0.75rem",
                                                border: "1px solid #EEE",
                                                margin: "2px",
                                                background: "#FFF",
                                                borderRadius: "4px",

                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                              className="accordionItem"
                                            >
                                              {item.course}
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </CustomStyles>
                            </div>
                            {provided.placeholder}
                          </ul>
                        )}
                      </StrictModeDroppable>
                    </div>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      <StrictModeDroppable droppableId="bundle" type="list">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ listStyle: "none", padding: "0" }}
                          >
                            <div>
                              <CustomStyles>
                                <Accordion
                                  key="2"
                                  sx={{ border: "1px solid #EEE" }}
                                  elevation={0}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                  >
                                    <Typography className="normalHeader">
                                      {t("menu_setUp.bundle")}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {bundleList.map((item: any, index) => {
                                      return (
                                        <Draggable
                                          draggableId={item.bundleId} // Use a unique identifier
                                          index={index}
                                          key={item.bundleId}
                                        >
                                          {(provided) => (
                                            <li
                                              key={item.bundleId}
                                              ref={provided.innerRef}
                                              {...provided.draggableProps}
                                              {...provided.dragHandleProps}
                                              style={{
                                                padding: "0.75rem",
                                                border: "1px solid #EEE",
                                                margin: "2px",
                                                background: "#FFF",
                                                borderRadius: "4px",

                                                ...provided.draggableProps
                                                  .style,
                                              }}
                                              className="accordionItem"
                                            >
                                              <Typography className="accordionItem">
                                                {item.bundleName}
                                              </Typography>
                                            </li>
                                          )}
                                        </Draggable>
                                      );
                                    })}
                                  </AccordionDetails>
                                </Accordion>
                              </CustomStyles>
                            </div>
                            {provided.placeholder}
                          </ul>
                        )}
                      </StrictModeDroppable>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginTop: "2%",
                      }}
                    >
                      <Typography
                        className="menu-setUp-normalHeader"
                        sx={{ marginTop: "3%", marginLeft: "1%" }}
                      >
                        {t("menu_setUp.category")}
                      </Typography>
                      <StrictModeDroppable droppableId="pizza" type="list">
                        {(provided) => (
                          <ul
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{ listStyle: "none", padding: "0" }}
                          >
                            <div>
                              {uniqueCategory.map((each, accordionIndex) => (
                                <Draggable
                                  key={`accordion-${each}`}
                                  draggableId={`accordion-${each}`}
                                  index={accordionIndex}
                                >
                                  {(accordionProvided) => (
                                    <li
                                      ref={accordionProvided.innerRef}
                                      {...accordionProvided.draggableProps}
                                      style={{
                                        marginTop: "8px",
                                        // Set the background color for the entire accordion
                                        ...accordionProvided.draggableProps
                                          .style,
                                      }}
                                    >
                                      <CustomStyles>
                                        <Accordion
                                          sx={{ border: "1px solid #EEE" }}
                                          elevation={0}
                                        >
                                          <AccordionSummary
                                            expandIcon={<ExpandMoreIcon />}
                                            aria-controls={`panel-${accordionIndex}-content`}
                                            id={`panel-${accordionIndex}-header`}
                                            {...accordionProvided.dragHandleProps} // Allow dragging by the handle
                                          >
                                            <Typography className="normalHeader">
                                              {String(each)}
                                            </Typography>
                                          </AccordionSummary>
                                          <AccordionDetails>
                                            {pizza.map((item, index) => {
                                              if (item.category === each) {
                                                return (
                                                  <Draggable
                                                    key={`pizza-${item.id}`}
                                                    draggableId={`pizza-${item.id}`}
                                                    index={index}
                                                  >
                                                    {(provided) => (
                                                      <li
                                                        key={item.id}
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={{
                                                          padding: "0.75rem",
                                                          border:
                                                            "1px solid #EEE",
                                                          margin: "2px",
                                                          background: "#FFF",
                                                          borderRadius: "4px",
                                                          textTransform:
                                                            "capitalize",

                                                          ...provided
                                                            .draggableProps
                                                            .style,
                                                        }}
                                                        className="accordionItem"
                                                      >
                                                        {item.content}
                                                      </li>
                                                    )}
                                                  </Draggable>
                                                );
                                              }
                                              return null;
                                            })}
                                          </AccordionDetails>
                                        </Accordion>
                                      </CustomStyles>
                                    </li>
                                  )}
                                </Draggable>
                              ))}
                            </div>
                            {provided.placeholder}
                          </ul>
                        )}
                      </StrictModeDroppable>
                    </div>
                  </Box>
                </DragDropContext>
              </div>
            ) : null;
          })
        )}
      </Box>
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDeleteMenu}
        id={delteBundleId}
        handleDeleteClick={handleDeleteClick}
        type="menu"
      />
      <DeleteDailougeInMenu
        deletemodal={deletemodal1}
        handleDleteByPopover={handleDeleteBundle}
        id={bundleId}
        handleDeleteModal={handleDeleteModal1}
        type="bundle"
      />
      <CourseDeleteDailougeInMenu
        deletemodal={deleteCourseModal}
        handleDleteByPopover={handleDeleteCourse}
        id={courseId}
        handleDeleteModal={handleDeleteCourseModal}
        type="course"
      />

      <DeleteDailougeInMenu
        deletemodal={productDeletemodal}
        handleDleteByPopover={handleDeleteItem}
        id={productId}
        handleDeleteModal={handleDeleteProductModal}
        type="product"
      />
      <DeleteCategoryDailouge
        deletemodal={categoryDeleteModal}
        handleDleteByPopover={handleCategoryDelete}
        id={categoryDeleteId}
        handleDeleteModal={handleCategoryDeleteModal}
        type="category"
      />
    </Box>
  );
};
export default MenuSetup;
