// ToastProvider.tsx
import React, { ReactNode } from "react";
import { useMediaQuery, useTheme } from "@mui/material";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

interface ToastProviderProps {
  children: ReactNode;
}

const ToastProvider: React.FC<ToastProviderProps> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery("(max-width:600px)");
  return (
    <>
      {children}
      <ToastContainer
        style={{ width: isMobile ? "100%" : "30%", height: "1px" }}
      />
    </>
  );
};

export default ToastProvider;
