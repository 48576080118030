import React, { createContext, useContext, useState } from "react";

interface NavigationContextProps {
  activeStep: number;
  handleNext: () => void;
  handleBack: () => void;
  setStepperCounter: any;
  stepperCounter: number;
}

const NavigationContext = createContext<NavigationContextProps | undefined>(undefined);

const NavigationProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [activeStep, setActiveStep] = useState(0);
  const [stepperCounter, setStepperCounter] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  return (
    <NavigationContext.Provider value={{ activeStep, handleNext, handleBack, setStepperCounter, stepperCounter }}>
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationProvider;
export const useNavigation = (): NavigationContextProps => {
  const context = useContext(NavigationContext);
  if (!context) {
    throw new Error("useNavigation must be used within a NavigationProvider");
  }
  return context;
};
