import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const CREATE_PRODUCT = gql`
  mutation createProduct($product: ProductInput!) {
    createProduct(product: $product) {
      errorMessage
      product
      success
    }
  }
`;

export const DELETE_MULTIPLE_SELECTED_ROWS = gql`
  mutation deleteProduct($productId: JSONString!) {
    deleteProduct(productId: $productId) {
      product {
        productId
      }
      success
      message
    }
  }
`;
export const PRODUCT_STATUS_CHANGE = gql`
  mutation ProductStatusChange($productId: ID!, $productStatus: String) {
    productStatusChange(productId: $productId, productStatus: $productStatus) {
      product {
        productId
        productStatus
      }
      success
      errorMessage
    }
  }
`;

export const PRODUCT_DUPLICATE_CHECK = gql`
  mutation duplicateProductNameCheck(
    $locationIds: JSONString
    $productName: String!
  ) {
    duplicateProductNameCheck(
      locationIds: $locationIds
      productName: $productName
    ) {
      message
      duplicate
      success
    }
  }
`;

export const VARIANT_STATUS_CHANGE = gql`
  mutation variantStatusChange($variantId: ID!, $variantStatus: String) {
    variantStatusChange(variantId: $variantId, variantStatus: $variantStatus) {
      variant {
        variantId
        variantStatus
      }
      success
      errorMessage
    }
  }
`;
export const SINGLE_DELETE_VARIANT = gql`
  mutation deleteVariant($variantId: ID!) {
    deleteVariant(variantId: $variantId) {
      message
      variant {
        variantId
      }
      success
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct(
    $productId: Int!
    $productType: String
    $allergens: JSONString
    $preparationTime: JSONString
    $nutritionalValues: JSONString
    $variants: JSONString
    $minQuantity: Int
    $maxNoOfSlices: Int
    $allowOpenPrice: Boolean
    $allowRefunds: Boolean
    $minDiscountAllowed: Decimal
    $maxDiscountAllowed: Decimal
  ) {
    updateProduct(
      productId: $productId
      productType: $productType
      allergens: $allergens

      preparationTime: $preparationTime
      nutritionalValues: $nutritionalValues
      variants: $variants
      minQuantity: $minQuantity
      maxNoOfSlices: $maxNoOfSlices
      allowOpenPrice: $allowOpenPrice
      allowRefunds: $allowRefunds
      maxDiscountAllowed: $maxDiscountAllowed
      minDiscountAllowed: $minDiscountAllowed
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;
export const UPDATE_GENERAL_DETAILS_IN_PRODUCT = gql`
  mutation UpdateProduct(
    $productId: Int!
    $businessId: Int
    $name: String
    $description: String
    $shortCode: String
    $category: Int
    $course: Int
    $isPos: Boolean
    $isOnline: Boolean
    $unit: String
    $reportingGroup: Int
    $bgColourCode: String
    $taxIds: JSONString
    $isNonTaxableProduct: Boolean
    $image: String
    $isShopProduct: Boolean
  ) {
    updateProduct(
      productId: $productId
      name: $name
      businessId: $businessId
      description: $description
      image: $image
      shortCode: $shortCode
      category: $category
      course: $course
      isPos: $isPos
      isOnline: $isOnline
      bgColourCode: $bgColourCode
      unit: $unit
      reportingGroup: $reportingGroup
      taxIds: $taxIds
      isNonTaxableProduct: $isNonTaxableProduct
      isShopProduct: $isShopProduct
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;
export const UPDATE_GENERAL_DETAILS_IN_BULK_UPDATE = gql`
  mutation BulkUpdateProduct(
    $productIds: JSONString!
    $bgColourCode: String
    $category: Int
    $image: String
    $description: String
    $course: Int
    $reportingGroup: Int
    $unit: String
    $shortCode: String
    $isPos: Boolean
    $isOnline: Boolean
    $isNonTaxableProduct: Boolean
    $taxIdsList: JSONString
    $name: String
    $locations: JSONString
    $businessId: Int
    $isShopProduct: Boolean
  ) {
    bulkUpdateProduct(
      businessId: $businessId
      name: $name
      locations: $locations
      productIds: $productIds
      bgColourCode: $bgColourCode
      category: $category
      image: $image
      description: $description
      course: $course
      reportingGroup: $reportingGroup
      unit: $unit

      shortCode: $shortCode

      isPos: $isPos
      isOnline: $isOnline
      isNonTaxableProduct: $isNonTaxableProduct
      taxIdsList: $taxIdsList

      isShopProduct: $isShopProduct
    ) {
      message

      success
    }
  }
`;
export const UPDATE_PRICE_IN_BULK_UPDATE = gql`
  mutation BulkUpdateProduct(
    $productIds: JSONString!
    $variants: JSONString
    $price: JSONString
    $allowSlices: Boolean
    $hasVariant: Boolean
  ) {
    bulkUpdateProduct(
      productIds: $productIds

      allowSlices: $allowSlices

      variants: $variants
      price: $price
      hasVariant: $hasVariant
    ) {
      message

      success
    }
  }
`;

export const UPDATE_PRICING_IN_PRODUCT_UPDATE = gql`
  mutation UpdateProduct(
    $productId: Int!
    $variants: JSONString
    $price: JSONString
    $allowSlices: Boolean
    $hasVariant: Boolean
  ) {
    updateProduct(
      allowSlices: $allowSlices
      productId: $productId
      variants: $variants
      price: $price
      hasVariant: $hasVariant
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;
export const ASSIGNING_OPTIONS_IDS_TO_THE_PRODUCT = gql`
  mutation assignOptionGroupToProduct($optionIds: JSONString, $productId: ID!) {
    assignOptionGroupToProduct(optionIds: $optionIds, productId: $productId) {
      success
      message
    }
  }
`;

export const UPDATE_PRODUCT_CONFIGURATION = gql`
  mutation UpdateProduct(
    $productId: Int!
    $isTrackStock: Boolean
    $lowStockAlertAt: Int
    $isTrackLowStock: Boolean
    $allowDiscounts: Boolean
    $minQuantity: Int
    $maxNoOfSlices: Int
    $allowOpenPrice: Boolean
    $allowRefunds: Boolean
    $minDiscountAllowed: Decimal
    $maxDiscountAllowed: Decimal
  ) {
    updateProduct(
      productId: $productId
      isTrackStock: $isTrackStock
      lowStockAlertAt: $lowStockAlertAt
      minQuantity: $minQuantity
      allowDiscounts: $allowDiscounts
      isTrackLowStock: $isTrackLowStock
      maxNoOfSlices: $maxNoOfSlices
      allowOpenPrice: $allowOpenPrice
      allowRefunds: $allowRefunds
      maxDiscountAllowed: $maxDiscountAllowed
      minDiscountAllowed: $minDiscountAllowed
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;

export const UPDATE_PRODUCT_SETTINGS = gql`
  mutation UpdateProduct(
    $productId: Int!
    $productType: String
    $allergens: JSONString
    $preparationTime: JSONString
    $nutritionalValues: JSONString
    $variants: JSONString
    $spiceIndicator: String
    $orderTypePriceVariable: JSONString
    $kdsStations: JSONString
    $ingredients: JSONString
  ) {
    updateProduct(
      productId: $productId
      productType: $productType
      allergens: $allergens
      spiceIndicator: $spiceIndicator
      preparationTime: $preparationTime
      nutritionalValues: $nutritionalValues
      variants: $variants
      orderTypePriceVariable: $orderTypePriceVariable
      kdsStations: $kdsStations
      ingredients: $ingredients
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;

export const UPDATE_SEQUENCE = gql`
  mutation BulkUpdateProduct($productIds: JSONString!, $sequence: JSONString) {
    bulkUpdateProduct(productIds: $productIds, sequence: $sequence) {
      message

      success
    }
  }
`;
export const UPDATE_SEQUENCE_IN_PRODUCT_UPDATE = gql`
  mutation UpdateProduct($productId: Int!, $sequence: JSONString) {
    updateProduct(productId: $productId, sequence: $sequence) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;

export const UPDATE_CONFIGURATION_IN_CREATION = gql`
  mutation bulkUpdateProduct(
    $productIds: JSONString!
    $isTrackStock: Boolean
    $isTrackLowStock: Boolean
    $allowDiscounts: Boolean
    $lowStockAlertAt: Int
    $minQuantity: Int
    $maxNoOfSlices: Int
    $allowOpenPrice: Boolean
    $allowRefunds: Boolean
    $minDiscountAllowed: Decimal
    $maxDiscountAllowed: Decimal
  ) {
    bulkUpdateProduct(
      productIds: $productIds
      minQuantity: $minQuantity
      maxNoOfSlices: $maxNoOfSlices
      allowOpenPrice: $allowOpenPrice
      allowRefunds: $allowRefunds
      allowDiscounts: $allowDiscounts
      minDiscountAllowed: $minDiscountAllowed
      maxDiscountAllowed: $maxDiscountAllowed
      isTrackStock: $isTrackStock
      isTrackLowStock: $isTrackLowStock
      lowStockAlertAt: $lowStockAlertAt
    ) {
      message

      success
    }
  }
`;
export const UPDATE_PRODUCT_SETTINGS_IN_CREATION = gql`
  mutation BulkUpdateProduct(
    $productIds: JSONString!
    $preparationTime: JSONString
    $nutritionalValues: JSONString
    $allergens: JSONString
    $productType: String
    $spiceIndicator: String
    $orderTypePriceVariable: JSONString
    $kdsStationsList: JSONString
    $ingredients: JSONString
  ) {
    bulkUpdateProduct(
      productIds: $productIds
      preparationTime: $preparationTime
      nutritionalValues: $nutritionalValues
      allergens: $allergens
      productType: $productType
      spiceIndicator: $spiceIndicator
      orderTypePriceVariable: $orderTypePriceVariable
      kdsStationsList: $kdsStationsList
      ingredients: $ingredients
    ) {
      message
      success
    }
  }
`;

export const UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_CREATION = gql`
  mutation BulkUpdateProduct(
    $productIds: JSONString!
    $metatagDescription: String
    $metatagKeywords: JSONString
    $seoKeyword: JSONString
  ) {
    bulkUpdateProduct(
      productIds: $productIds
      metatagDescription: $metatagDescription
      metatagKeywords: $metatagKeywords
      seoKeyword: $seoKeyword
    ) {
      message

      success
    }
  }
`;

export const UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_EDIT = gql`
  mutation UpdateProduct(
    $productId: Int!
    $metatagDescription: String
    $metatagKeywords: JSONString
    $seoKeyword: JSONString
  ) {
    updateProduct(
      productId: $productId
      metatagDescription: $metatagDescription
      metatagKeywords: $metatagKeywords
      seoKeyword: $seoKeyword
    ) {
      errorMessage
      product {
        productId
      }
      success
    }
  }
`;
export const ADD_OPTION_VALUE_TO_OPTION = gql`
  mutation AddOptionValueInProduct(
    $option: Int!
    $name: String
    $price: Float
  ) {
    addOptionValueInProduct(option: $option, name: $name, price: $price) {
      errorMessage
      optionValue {
        optionValueId
      }
      success
    }
  }
`;
export const UPDATE_OPTION_VALUE_TO_OPTION = gql`
  mutation UpdateOptionValue(
    $optionValueId: Int!
    $name: String
    $price: Float
  ) {
    updateOptionValue(
      optionValueId: $optionValueId
      name: $name
      price: $price
    ) {
      errorMessage
      optionValue {
        optionValueId
        name
        price
        option {
          optionId
        }
      }
      success
    }
  }
`;
export const EDIT_ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT = gql`
  mutation EditIncludedOptionGroupToProduct(
    $productId: Int!
    $optionsList: JSONString!
  ) {
    editIncludedOptionGroupToProduct(
      productId: $productId
      optionsList: $optionsList
    ) {
      message
      success
    }
  }
`;

export const ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT = gql`
  mutation addIncludedOptionGroupToProduct(
    $productIds: JSONString!
    $optionsList: JSONString!
  ) {
    addIncludedOptionGroupToProduct(
      productIds: $productIds
      optionsList: $optionsList
    ) {
      message
      success
    }
  }
`;

export const DELETE_OPTIONGROUP_IN_PRODUCT = gql`
  mutation RemoveOptionFromProduct(
    $optionGroupId: ID
    $productIds: JSONString!
    $optionId: ID
  ) {
    removeOptionFromProduct(
      optionGroupId: $optionGroupId
      productIds: $productIds
      optionId: $optionId
    ) {
      success
      message
    }
  }
`;

export const DELETE_OPTION_IN_PRODUCT = gql`
  mutation RemoveOptionFromProduct($optionId: ID, $productIds: JSONString!) {
    removeOptionFromProduct(optionId: $optionId, productIds: $productIds) {
      success
      message
    }
  }
`;

export const DUPLICATE_PRODUCT_IN_PRODUCT_LIST = gql`
  mutation DuplicateProduct($productId: ID!) {
    duplicateProduct(productId: $productId) {
      success
      message
    }
  }
`;
export const REMOVE_OPTION_FROM_VARIANT = gql`
  mutation removeOptionFromVariant(
    $optionId: ID
    $variantId: ID!
    $optionGroupId: ID
  ) {
    removeOptionFromVariant(
      optionId: $optionId
      variantId: $variantId
      optionGroupId: $optionGroupId
    ) {
      success
      message
    }
  }
`;

export const DUPLICATE_SKU = gql`
  mutation duplicateProductSkuCheck(
    $productSku: String!
    $productId: Int
    $variantId: Int
    $businessId: Int
  ) {
    duplicateProductSkuCheck(
      productSku: $productSku
      productId: $productId
      businessId: $businessId
      variantId: $variantId
    ) {
      message
      duplicate
      success
    }
  }
`;

export const DUPLICATE_BARCODE = gql`
  mutation duplicateProductBarcodeCheck(
    $productBarcode: String!
    $productId: Int
    $variantId: Int
    $businessId: Int
  ) {
    duplicateProductBarcodeCheck(
      productBarcode: $productBarcode
      productId: $productId
      variantId: $variantId
      businessId: $businessId
    ) {
      message
      duplicate
      success
    }
  }
`;
