//write basic structure of the component
import { Box, IconButton, Rating } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React from "react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useMutation } from "@apollo/client";
import { CHANGE_HIDE } from "../../graphicalQl/mutation/reviews";
import { GET_QUERY } from "./reviewData";
import { useTranslation } from "react-i18next";

interface Props {
  rows: any;
}
const ReviewsDataTable = (props: Props) => {
  const { rows } = props;
  const { t } = useTranslation()
  const [changeHideState] = useMutation(CHANGE_HIDE, {
    context: { clientName: "orderClient" },
    refetchQueries: [GET_QUERY],
  });

  const handleHide = (id: number, status: string) => {
    changeHideState({ variables: { orderId: id, status: "hide" } });
  };

  const handleShow = (id: number, status: string) => {
    changeHideState({ variables: { orderId: id, status: "show" } });
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    {
      field: "customerName",
      //@ts-ignore
      headerName: t("reviews.customer_name"),
      width: 200,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "rating",
      //@ts-ignore
      headerName: t("reviews.rating"),
      width: 200,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <Rating
            name="read-only"
            value={parseInt(params.row.rating)}
            readOnly
          />
        );
      },
    },
    {
      field: "review",
      //@ts-ignore
      headerName: t("reviews.review"),
      align: "left",
      flex: 1,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
    },
    {
      field: "date",
      //@ts-ignore
      headerName: t("reviews.date_added"),
      //@ts-ignore
      description: t("reviews.description"),
      sortable: false,
      width: 200,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      // renderCell: (params) => {
      //     const date = new Date(params.row.date)
      //     return (
      //         <div>
      //            moment(params.row.date).format(`${localeToFormat[userLang]}, `),
      //         </div>
      //     )
      // }
    },

    {
      field: "",
      headerName: "",
      width: 150,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      disableColumnMenu: true,
      hideSortIcons: true,
      sortable: false,
      renderCell: (params) => {
        return (
          <div>
            {params.row.hide ? (
              <IconButton onClick={() => handleShow(params.row.id, "show")}>
                <VisibilityIcon style={{ cursor: "pointer" }} />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleHide(params.row.id, "hide")}>
                <VisibilityOffIcon style={{ cursor: "pointer" }} />
              </IconButton>
            )}
          </div>
        );
      },
    },
  ];

  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell-less-wt";
  };
  return (
    <div>
      <Box sx={{ height: 400, width: "95%", marginLeft: "12px" }}>
        <DataGrid
          rows={rows}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columnHeaderHeight={48}
          rowHeight={48}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[5, 15, 25]}
          // checkboxSelection
          disableRowSelectionOnClick
          sx={{
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
            {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
          }}
        />
      </Box>
    </div>
  );
};
export default ReviewsDataTable;
