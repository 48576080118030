// CustomFooter.js
import React from 'react';
import { GridFooterContainer, GridFooter, GridPagination } from '@mui/x-data-grid';
import { Typography, Box } from '@mui/material';

const CustomFooter = (props: any) => {
    const { totalRowCount, page, ...other } = props;

    return (
        <GridFooterContainer>
            {/* <Box sx={{ display: 'flex', width: '100%', alignItems: "center" }}>
                <GridFooter {...props} />
                <Typography variant="body2">
                    {page + 1} of {totalRowCount}
                </Typography>


            </Box> */}

            <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', padding: '0 16px', justifyContent: "flex-end" }}>
                <Box sx={{ flexGrow: 1 }}>
                    <GridPagination {...other} />
                </Box>
                <Typography variant="body2" sx={{ marginLeft: 2, marginRight: 2 }}>
                    {page + 1} of {totalRowCount}
                </Typography>
            </Box>
        </GridFooterContainer>

    );
};

export default CustomFooter;
