import React, { ChangeEvent, useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  InputBase,
  IconButton,
  Divider,
} from "@mui/material";
import "../options/createProduct.css";
import "./createCategory.css";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useTranslation } from "react-i18next";
import { useMutation, useQuery } from "@apollo/client";
import { OPTIONS_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import Collapse from "@mui/material/Collapse";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { ASSIGN_OPTIONGROUP } from "../../graphicalQl/mutation/mutation";

import { GET_OPTIONS_IN_CATEGORY } from "../../graphicalQl/usequery/categoryListQuery";
import { GET_OPTIONS_IN_CATEGORY_FOR_LISTING } from "../../graphicalQl/usequery/categoryListQuery";
import CurrencyDisplay from "../utils/currencySymbol";

interface Props {
  categoryId: string;
  openOptions: boolean;
  handleOpenOptions: () => void;
  checkedIdsFromApi: number[];
}

type OptionType = {
  name: string;
  optionValueId: string;
  price: string;
  image: string | null;
};

type DataResult = {
  id: string;
  name: string;
  price: string;
  optionObjs: {
    name: string;
    optionId: string;
    optionValues: OptionType[];
    isChecked: boolean;
    price: string;
  }[];
  isChecked: boolean;
};

type OptionsList = {
  id: string;
  name: string;
  price: string;
  optionObjs: {
    name: string;
    optionId: string;
    isChecked: boolean;
    price: string;
  }[];
  isChecked: boolean;
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "70%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const OptionsTable = (props: Props) => {
  const { t } = useTranslation();

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { categoryId, openOptions, handleOpenOptions, checkedIdsFromApi } =
    props;

  const handleCloseOptions = () => {
    handleOpenOptions();
    setOptionListInGroup([]);
  };

  const { data: optionsData } = useQuery(OPTIONS_LIST, {
    context: { clientName: "categoryClient" },
    fetchPolicy: "network-only",
    variables: { businessId: +businessId },
    onError: (error) => {
      console.error('Error:', error);
    },
  });
  function Row(props: { row: OptionsList }) {
    const { row } = props;

    const [open, setOpen] = React.useState(false);

    return (
      <React.Fragment>
        <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
          <TableCell>
            {row.optionObjs && (
              <IconButton
                aria-label="expand row"
                size="small"
                onClick={() => setOpen(!open)}
              >
                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  color="warning"
                  checked={row.isChecked}
                  disabled={optionListInGroup.includes(+row.id)}
                  onChange={(e) =>
                    handleCheckboxChange(parseInt(row.id), e.target.checked)
                  }
                // sx={{marginLeft:"1%"}}
                />
              }
              label={
                <Typography
                  variant="body2"
                  className="dataGrid-table-cell"
                  sx={{ textTransform: "capitalize" }}
                >
                  {row.name}
                </Typography>
              }
            />
          </TableCell>
          <TableCell
            className="dataGrid-table-cell"
            sx={{ textTransform: "capitalize" }}
            align="right"
          >
            {row.price && (
              <CurrencyDisplay
                countryName={"UnitedKingdom"}
                amount={row.price ? row.price.split("-") : "0"}
              />
            )}
          </TableCell>
        </TableRow>
        {row.optionObjs && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  {/* <Typography variant="h6" gutterBottom component="div">
                {t("menu.catageory.options")}
                </Typography> */}
                  <Table size="medium" aria-label="purchases">
                    <TableHead>
                      <TableRow></TableRow>
                    </TableHead>
                    <TableBody>
                      {row.optionObjs.map((historyRow) => (
                        <TableRow key={historyRow.name}>
                          <TableCell component="th" scope="row">
                            <Typography
                              className="dataGrid-table-cell-less-wt "
                              sx={{
                                textTransform: "capitalize",
                                marginLeft: "44px",
                              }}
                            >
                              {" "}
                              {historyRow.name}
                            </Typography>
                          </TableCell>
                          <TableCell component="th" scope="row" align="right">
                            <Typography
                              className="dataGrid-table-cell-less-wt "
                              sx={{ textTransform: "capitalize" }}
                            >
                              {" "}
                              <CurrencyDisplay
                                countryName={"UnitedKingdom"}
                                amount={
                                  historyRow.price
                                    ? historyRow.price.split("-")
                                    : "0"
                                }
                              />
                            </Typography>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  }

  const [checkedRequiredOptions, setCheckedRequiredOptions] = useState<
    DataResult[]
  >([]);

  useEffect(() => {
    if (optionsData && optionsData.optionGroupList) {
      const requiredOptions = optionsData.optionGroupList.edges.map(
        (item: any) => ({
          id: item.node.optionGroupId,
          name: item.node.name,
          optionObjs:
            item.node.optionObjs &&
            item.node.optionObjs.map((each: any) => ({
              name: each.name,
              optionId: each.optionId,
              optionValues: each.optionValues,
              price: each.priceToDisplay,
              isChecked: false,
            })),
          isChecked: false,
        })
      );
      setCheckedRequiredOptions(requiredOptions);
    }
  }, [optionsData]);

  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const { data: optionsListData } = useQuery(
    GET_OPTIONS_IN_CATEGORY_FOR_LISTING,
    {
      context: { clientName: "categoryClient" },
      variables: { businessId: +businessId },
      fetchPolicy: "cache-and-network",
      onError: (error) => {
        console.error('Error:', error);
      },
    }
  );

  const [requiredListData, setRequiredListData] = useState<DataResult[]>([]);
  useEffect(() => {
    if (optionsListData) {
      const modifiedListData = optionsListData.optionList.edges.map(
        (each: any) => ({
          name: each.node.name,
          id: each.node.optionId,
          price: each.node.priceToDisplay,
          isChecked: false,
          optionObjs: null,
        })
      );

      setRequiredListData(modifiedListData);
    }
  }, [optionsListData]);

  // const totalOptionGroupAndOptions = [...checkedRequiredOptions, ...requiredListData]

  const [optionListInGroup, setOptionListInGroup] = useState<any[]>([]);

  const [optionsTotalData, setOptionsTotalData] = useState<DataResult[]>([]);

  useEffect(() => {
    if (openOptions) {
      setOptionsTotalData((prevProductData) =>
        prevProductData.map((item) =>
          checkedIdsFromApi.includes(+item.id)
            ? { ...item, isChecked: true }
            : { ...item, isChecked: false }
        )
      );

      const list = optionsTotalData.filter((each) =>
        checkedIdsFromApi.includes(+each.id)
      );
      const filteredList = list
        .map((each) => each.optionObjs?.map((item) => +item.optionId))
        .flat();
      setOptionListInGroup(filteredList);
    }
  }, [checkedIdsFromApi]);

  useEffect(() => {
    setOptionsTotalData([...checkedRequiredOptions, ...requiredListData]);
  }, [checkedRequiredOptions, requiredListData]);

  const searchedResult = optionsTotalData.filter((each) =>
    each.name.toLowerCase().includes(searchValue.toLowerCase())
  );

  const CheckedoptionsData = optionsTotalData.filter(
    (each) => each.isChecked === true
  );

  const [data, setData] = useState<DataResult[]>([]);

  const assingnOptionData = () => {
    setData(CheckedoptionsData);
  };

  const [assignOptionGroup] = useMutation(ASSIGN_OPTIONGROUP, {
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTIONS_IN_CATEGORY],
    onError: (error) => {
      console.error('Error:', error);
    },
  });

  const { checkedOptionsIds, checkedOptionGroupIds } =
    CheckedoptionsData.reduce(
      (accumulator, each) => {
        if (each.optionObjs === null) {
          accumulator.checkedOptionsIds.push(+each.id);
        } else {
          accumulator.checkedOptionGroupIds.push(+each.id);
        }
        return accumulator;
      },
      {
        checkedOptionsIds: [] as number[],
        checkedOptionGroupIds: [] as number[],
      }
    );

  const handleCheckboxChange = (id: number, value: boolean) => {
    const filteredList = optionsTotalData
      .filter((each) => +each.id === +id)[0]
      ?.optionObjs?.map((item) => +item.optionId);
    if (value && filteredList) {
      setOptionListInGroup((prevState: any) => [...prevState, ...filteredList]);
    } else {
      if (filteredList) {
        setOptionListInGroup((prevState: any) =>
          prevState.filter((item: any) => !filteredList.includes(item))
        );
      }
    }
    if (!optionListInGroup.includes(id)) {
      setOptionsTotalData((prevProductData) =>
        prevProductData.map((item) =>
          parseInt(item.id) === id
            ? { ...item, isChecked: !item.isChecked }
            : item
        )
      );
    }
    const optionsCommonList =
      filteredList &&
      filteredList.filter((value) => checkedOptionsIds.includes(value));
    if (filteredList) {
      setOptionsTotalData((prevProductData) =>
        prevProductData.map((item) =>
          parseInt(item.id) === id ? { ...item, isChecked: value } : item
        )
      );
      if (optionsCommonList.length > 0) {
        setOptionsTotalData((prevProductData) =>
          prevProductData.map((item) =>
            optionsCommonList.includes(parseInt(item.id))
              ? { ...item, isChecked: false }
              : item
          )
        );
      }
    }
    // if (filteredList.filter(value => checkedOptionsIds.includes(value)).length>0)
  };

  const assignselectedOptions = () => {
    assignOptionGroup({
      variables: {
        optionGroupIds: JSON.stringify(checkedOptionGroupIds),
        optionIds: JSON.stringify(checkedOptionsIds),
        categoryId: +categoryId,
      },
    });
  };

  return (
    <Box
      sx={{ marginTop: "12px", marginBottom: "12px" }}
      className="product-box-in-category"
    >
      <Box sx={{ width: "100%", display: "flex", flexDirection: "column" }}>
        <div>
          <Modal
            open={openOptions}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  margin: "0.7% 0px 0.833% 1.4%",
                  width: "100%",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  className="exit-container"
                  onClick={handleCloseOptions}
                >
                  <CloseOutlined className="exitIcon" />
                </IconButton>
                <Typography className="createCategory-select-product">
                  {t("menu.catageory.select_options")}
                </Typography>
                <Typography></Typography>
              </div>
              <Box
                sx={{
                  width: "100%",
                  backgroundColor: "#EEEEEE",
                  padding: "16px",
                  textAlign: "center",
                }}
              >
                <Typography className="product-modal-para">
                  {t("menu.catageory.the_selected_options")}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: "1.4%",
                  marginBottom: "1.4%",
                  marginLeft: "2.22%",
                }}
                className="product-search"
              >
                <IconButton type="button" aria-label="search">
                  <SearchIcon />
                </IconButton>
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  //@ts-ignore
                  placeholder={t("menu.catageory.searchByName")}
                  value={searchValue}
                  name="search"
                  onChange={handleSearch}
                  color="warning"
                />
              </Box>

              <Box
                sx={{
                  overflowY: "scroll",
                  maxHeight: "60vh",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                <TableContainer component={Paper}>
                  <Table aria-label="collapsible table">
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Typography className="normalHeader">
                            {t("menu.catageory.options")}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography className="normalHeader" align="right">
                            {t("menu.catageory.price")}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {searchedResult.map((row) => (
                        <Row key={row.name} row={row} />
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
              <Box sx={{ textAlign: "right", margin: "12px 0px 12px 0px " }}>
                <Button
                  className="main-button"
                  onClick={() => {
                    handleCloseOptions();
                    assingnOptionData();
                    assignselectedOptions();
                  }}
                  style={{ textTransform: "none" }}
                >
                  {t("menu.catageory.apply_options")}
                </Button>
              </Box>
            </Box>
          </Modal>
        </div>
      </Box>
    </Box>
  );
};

export default OptionsTable;
