import { configureStore, Reducer, combineReducers } from "@reduxjs/toolkit";
import {
  createCategoryReducer,
  CreateCategory,
  FormData,
} from "./pages/reduxReducers/categoryReducer";
import {
  visibilityReducer,
  VisibilityData,
  Visibility,
} from "./pages/reduxReducers/categoryReducer";
import {
  mainDataReducer,
  Requried,
  MainData,
} from "./pages/reduxReducers/mainReducer";
import {
  recieptDataReducer,
  RecieptData,
  MainRecieptData,
} from "./pages/reduxReducers/recieptReducer";

import {
  MainReportData,
  reportDataReducer,
  ReportData,
} from "./pages/reduxReducers/repoertReducer";
import { countries } from "country-data";

const locationIdFromSeesion = sessionStorage.getItem("locationId");
function getPhoneNumberCountryCode(
  isoCountryCode: string | null
): string | null {
  const country = countries[isoCountryCode !== null ? isoCountryCode : ""];
  if (country) {
    return `${country.countryCallingCodes[0]}`;
  } else {
    return null;
  }
}
const isoCountryCode1: string | null = sessionStorage.getItem("countryCode");

const phoneNumberCountryCode1 = getPhoneNumberCountryCode(isoCountryCode1);

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}

interface HeaderLocation {
  locationId: string | null;
  phoneCountryCode: string | null;
}

interface DeviceSetUp {
  tabId: string;
}

interface DeviceListing {
  tabId: string;
}

interface CreateUserType {
  firstName: string;
  lastName: string;
  email: string;
  mobile: string;
  roleId: string;
  locationsList: any[];
}

interface LocationsManagementState {
  latitude: string;
  longitude: string;
  addressLine1: string;
  addressLine2: string;
  street: string;
  city: string;
  state: string;
  postCode: string;
}

interface Modifiers {
  name: string;
  label: string;
  id: number;
  isChecked: boolean;
}

interface userAccessArrayState {
  arrayAreEqual: boolean;
  open: boolean;
}

interface AssignOptions {
  productOptionIds: any;
  productGroupId: any;
}

interface AppState {
  name: string;
  shortCode: string;
  description: string;
  isPos: boolean;
  isOnline: boolean;
  category: string | null;
  location: number[];
  unit: string;
  image: string | null;
  color: string | null;
  course: string | null;
  conditionValue: boolean;
  productIdByEdit: string | undefined;
  sideMenuLabel: string;
  reportingGroup: string | null;
  taxList: any | null;
  nonTaxable: boolean;
  shopProduct: boolean;
  tabState: number;
}
interface Row2 {
  id: number;
  name: string;
  price: string;
  slice: string;
  sku: string;
  barcode: string;
  is_preselected: boolean;
  groupName: string;
  skuDuplicate: boolean;
  barcodeDuplicate: boolean;
}
interface Pricing {
  price: string;
  sku: string;
  barcode: string;
  slice: boolean;
  noOfSlices: string;
  variations1: Row2[];
  productId: any[];
  variationSizes: string[];
  condition2: boolean;
  productOptionId: any[];
  productGroupId: any[];
  variantGroup: string;
  skuDuplicate: boolean;
  barcodeDuplicate: boolean;
  noVariantChange: boolean;
  dailougeCondition: boolean;
}

interface Row {
  id: number;
  price: string;
  sequence?: string;
}

interface AddSizes {
  id: number;
  size: string;
  data: Row[];
  aditionalPrice: string;
}

interface Configuration {
  minQuantity: number;
  maxSlices: string;
  openPrice: boolean;
  allowRefunds: boolean;
  allowPrice: boolean;
  minDiscountAllowed: string;
  maxDiscountAllowed: string;
  TrackStock: boolean;
  LowStockAlert: boolean;
  alertAt: string;
  metaTag: string;
  seoKeyWord: string[];
  metaKeyWord: string[];
  sequenceValue: Row[];
  productSequenceValues: Row[];
  aditionalPrice: string;
  size: boolean;
}

interface ProductSettings {
  ratingValue: string | null;
  selectedValue: string;
  dineIn: string;
  delivery: string;
  shop: string;
  collection: string;
  channel1: string;
  channel2: string;
  channel3: string;
  channel4: string;
  nutritionValue: { id: number; label: string; value: string }[];
  lowCalaries: boolean;
  lowCarboHydrates: boolean;
  perValue: string;
  nutritionList: { id: number; label: string; value: string }[];
  checkBoxList: List1[];
  oredrPricing: any;
  storeIds: string[];
  ingredients: any;
}

interface Menu {
  menu: string;
}

const HEADER_LOCATION = "HEADER_LOCATION";
const DEVICE_SETUP = "DEVICE_SETUP";
const DEVICE_LISTING = "DEVICE_LISTING";

const RESET_STATE = "RESET_STATE";
const LOCATIONS_MANAGEMENT = "LOCATIONS_MANAGEMENT";
const ASSIGN_OPTIONS = "ASSIGN_OPTIONS";
const USER_ACCESS_ARRAY_STATE = "USER_ACCESS_ARRAY_STATE";
const MENU_SETUP = "MENU_SETUP";

interface LocationsManagementAction {
  type: typeof LOCATIONS_MANAGEMENT;
  payload: {
    field: string;
    name: string;
  };
}

interface deviceSetupAction {
  type: typeof DEVICE_SETUP;
  payload: {
    field: string;
    name: string;
  };
}

interface deviceListingAction {
  type: typeof DEVICE_LISTING;
  payload: {
    field: string;
    name: string;
  };
}

interface HeaderLocationAction {
  type: typeof HEADER_LOCATION;
  payload: {
    field: string;
    name: string;
  };
}

interface UserAccessStateAction {
  type: typeof USER_ACCESS_ARRAY_STATE;
  payload: {
    field: string;
    name: string | Locations[] | boolean;
  };
}

interface AssignOptionsStateAction {
  type: typeof ASSIGN_OPTIONS;
  payload: {
    field: string;
    name: string;
  };
}

interface MenuSetUp {
  type: typeof MENU_SETUP;
  payload: {
    field: string;
    name: string;
  };
}

interface ResetStateAction {
  type: typeof RESET_STATE;
}

export const resetState = (): ResetStateAction => ({
  type: RESET_STATE,
});

const SET_NAME = "SET_NAME";

const CREATE_USER = "CREATE_USER";

const SET_CONFIGURATION = "SET_CONFIGURATION";

const SET_SETTINGS = "SET_SETTINGS";
const SET_PRICING = "SET_PRICING";

interface CreateUserAction {
  type: typeof CREATE_USER;
  payload: {
    field: string;
    name: string | Locations[] | boolean | number;
  };
}

interface SetNameAction {
  type: typeof SET_NAME;
  payload: {
    field: string;
    name: string | boolean | number[] | number | null | any;
  };
}

interface SetConfiguration {
  type: typeof SET_CONFIGURATION;
  payload: {
    field: string;
    name: string | string[] | Row[] | AddSizes[] | boolean;
  };
}
interface List1 {
  id: string;
  value: string;
  isChecked: boolean;
}
interface SetSettings {
  type: typeof SET_SETTINGS;
  payload: {
    field: string;
    name:
      | string
      | { id: number; label: string; value: string }[]
      | boolean
      | List1[]
      | number[]
      | null
      | any;
  };
}

interface SetPricing {
  type: typeof SET_PRICING;
  payload: {
    field: string;
    name: string | Row2[] | number[] | boolean | string[] | null | any;
  };
}

export const assignOptions = (
  field: string,
  name: any
): AssignOptionsStateAction => ({
  type: ASSIGN_OPTIONS,
  payload: { field, name },
});

export const deviceSetup = (
  field: string,
  name: string
): deviceSetupAction => ({
  type: DEVICE_SETUP,
  payload: { field, name },
});

export const deviceListing = (
  field: string,
  name: string
): deviceListingAction => ({
  type: DEVICE_LISTING,
  payload: { field, name },
});

export const headerLocation = (
  field: string,
  name: string
): HeaderLocationAction => ({
  type: HEADER_LOCATION,
  payload: { field, name },
});

export const locationManagement = (
  field: string,
  name: string
): LocationsManagementAction => ({
  type: LOCATIONS_MANAGEMENT,
  payload: { field, name },
});

export const setName = (
  field: string,
  name: string | boolean | number[] | number | null | any
): SetNameAction => ({
  type: SET_NAME,
  payload: { field, name },
});

export const setUserAccessStore = (
  field: string,
  name: boolean
): UserAccessStateAction => ({
  type: USER_ACCESS_ARRAY_STATE,
  payload: { field, name },
});

export const createUserStore = (
  field: string,
  name: string | any[] | boolean
): CreateUserAction => ({
  type: CREATE_USER,
  payload: { field, name },
});

export const setproductConfiguration = (
  field: string,
  name: string | string[] | Row[] | AddSizes[] | boolean
): SetConfiguration => ({
  type: SET_CONFIGURATION,
  payload: { field, name },
});

export const setsettings = (
  field: string,
  name:
    | string
    | { id: number; label: string; value: string }[]
    | boolean
    | List1[]
    | null
    | any
): SetSettings => ({
  type: SET_SETTINGS,
  payload: { field, name },
});

export const menusetup = (field: string, name: string): MenuSetUp => ({
  type: MENU_SETUP,
  payload: { field, name },
});

const initialValuesOfCreateUser: CreateUserType = {
  firstName: "",
  lastName: "",
  email: "",
  mobile: "",
  roleId: "",
  locationsList: [],
};

const initialIdofHeaderLocation: HeaderLocation = {
  locationId: locationIdFromSeesion,
  phoneCountryCode: phoneNumberCountryCode1,
};

const initialIdOfSetupTabId: DeviceSetUp = {
  tabId: "1",
};
const initialIdOfListingTabId: DeviceListing = {
  tabId: "1",
};

export const setPricing = (
  field: string,
  name: string | Row2[] | number[] | boolean | string[] | null | any
): SetPricing => ({
  type: SET_PRICING,
  payload: { field, name },
});

const geeralDetails: AppState = {
  name: "",
  shortCode: "",
  description: "",
  isPos: true,
  isOnline: true,
  category: null,
  unit: "",
  course: null,
  image: null,
  color: null,
  conditionValue: true,
  location: [],
  productIdByEdit: undefined,
  sideMenuLabel: "",
  reportingGroup: null,
  taxList: null,
  nonTaxable: false,
  shopProduct: false,
  tabState: 0,
};

const initialLocationsData = {
  latitude: "17.4484° N",
  longitude: "78.3915° E",
  addressLine1: "",
  addressLine2: "",
  street: "",
  city: "",
  state: "",
  postCode: "",
};

const initialPricing: Pricing = {
  price: "",
  sku: "",
  barcode: "",
  slice: false,
  noOfSlices: "",
  variations1: [],
  productId: [],
  variationSizes: [],
  condition2: true,
  productOptionId: [],
  productGroupId: [],
  variantGroup: "Size",
  barcodeDuplicate: false,
  skuDuplicate: false,
  noVariantChange: true,
  dailougeCondition: false,
};

const initialConfiguration: Configuration = {
  minQuantity: 1,
  maxSlices: "",
  openPrice: false,
  allowRefunds: true,
  allowPrice: true,
  minDiscountAllowed: "",
  maxDiscountAllowed: "",

  TrackStock: true,
  LowStockAlert: true,
  alertAt: "",
  metaTag: "",
  seoKeyWord: [],
  metaKeyWord: [],
  sequenceValue: [
    {
      id: 1,
      price: "",
      sequence: "1st Selection",
    },
    {
      id: 2,
      price: "",
      sequence: "2nd Selection",
    },
    {
      id: 3,
      price: "",
      sequence: "3rd Selection",
    },
  ],
  productSequenceValues: [],
  aditionalPrice: "",
  size: false,
};

const initalSettings: ProductSettings = {
  ratingValue: "1",
  selectedValue: "veg",
  dineIn: "",
  delivery: "",
  shop: "",
  collection: "",
  channel1: "",
  channel2: "",
  channel3: "",
  channel4: "",
  nutritionValue: [
    { id: 1, label: "Total Calories(kcal)", value: "" },
    { id: 2, label: "Carbohydrates(g)", value: "" },
    { id: 3, label: "Total Fat(g)", value: "" },
    { id: 4, label: "Saturated Fat(g)", value: "" },
    { id: 5, label: "Protein(g)", value: "" },
    { id: 6, label: "Added Sugar(g)", value: "" },
    { id: 7, label: "Salt(g)", value: "" },
    { id: 8, label: "Fiber(g)", value: "" },
  ],
  lowCalaries: false,
  lowCarboHydrates: false,
  perValue: "",
  nutritionList: [],
  checkBoxList: [
    { id: "milk", value: "Milk", isChecked: false },
    { id: "fish", value: "Fish", isChecked: false },
    { id: "soya", value: "Soya", isChecked: false },
    { id: "eggs", value: "Eggs", isChecked: false },
    { id: "peanuts", value: "Peanuts", isChecked: false },
    { id: "shellfish", value: "ShellFish", isChecked: false },
    { id: "nuts", value: "Nuts", isChecked: false },
    { id: "wheat", value: "Wheat", isChecked: false },
  ],
  oredrPricing: null,
  storeIds: [],
  ingredients: [],
};

const initialAssignOptions: AssignOptions = {
  productOptionIds: [],
  productGroupId: [],
};

const initialArraysAreEqual: userAccessArrayState = {
  arrayAreEqual: true,
  open: false,
};

const initialMenuSetup: Menu = {
  menu: "",
};

const userAccessReducer = (
  state = initialArraysAreEqual,
  action: UserAccessStateAction
): userAccessArrayState => {
  switch (action.type) {
    case USER_ACCESS_ARRAY_STATE:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const headerLocationReducer = (
  state = initialIdofHeaderLocation,
  action: HeaderLocationAction
): HeaderLocation => {
  switch (action.type) {
    case HEADER_LOCATION:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const deviceSetupReducer = (
  state = initialIdOfSetupTabId,
  action: deviceSetupAction
): DeviceSetUp => {
  switch (action.type) {
    case DEVICE_SETUP:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const deviceListingReducer = (
  state = initialIdOfListingTabId,
  action: deviceListingAction
): DeviceListing => {
  switch (action.type) {
    case DEVICE_LISTING:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const assignOptionsReducer = (
  state = initialAssignOptions,
  action: AssignOptionsStateAction
): AssignOptions => {
  switch (action.type) {
    case ASSIGN_OPTIONS:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const locationsManagementReducer = (
  state = initialLocationsData,
  action: LocationsManagementAction
): LocationsManagementState => {
  switch (action.type) {
    case LOCATIONS_MANAGEMENT:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const appReducer = (
  state = geeralDetails,
  action: SetNameAction | ResetStateAction
): AppState => {
  switch (action.type) {
    case SET_NAME:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    case RESET_STATE:
      return {
        ...geeralDetails,
      };
    default:
      return state;
  }
};

const createUserReducer = (
  state = initialValuesOfCreateUser,
  action: CreateUserAction
): CreateUserType => {
  switch (action.type) {
    case CREATE_USER:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    default:
      return state;
  }
};

const configureReducer = (
  state = initialConfiguration,
  action: SetConfiguration | ResetStateAction
): Configuration => {
  switch (action.type) {
    case SET_CONFIGURATION:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    case RESET_STATE:
      return {
        ...initialConfiguration,
      };
    default:
      return state;
  }
};
const settingsReducer = (
  state = initalSettings,
  action: SetSettings | ResetStateAction
): ProductSettings => {
  switch (action.type) {
    case SET_SETTINGS:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    case RESET_STATE:
      return {
        ...initalSettings,
      };
    default:
      return state;
  }
};

const PricingReducer = (
  state = initialPricing,
  action: SetPricing | ResetStateAction
): Pricing => {
  switch (action.type) {
    case SET_PRICING:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    case RESET_STATE:
      return {
        ...initialPricing,
      };
    default:
      return state;
  }
};

const MenuSetupReducer = (
  state = initialMenuSetup,
  action: MenuSetUp | ResetStateAction
): Menu => {
  switch (action.type) {
    case MENU_SETUP:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };
    case RESET_STATE:
      return {
        ...initialMenuSetup,
      };
    default:
      return state;
  }
};

const rootReducer: Reducer<
  {
    app: AppState;
    deviceSetup: DeviceSetUp;
    deviceListing: DeviceListing;
    AssignOptions: AssignOptions;
    headerLocation: HeaderLocation;
    LocationsManagement: LocationsManagementState;
    configuration: Configuration;
    settings: ProductSettings;
    UserAccess: userAccessArrayState;
    pricing: Pricing;
    users: CreateUserType;
    creatCategory: FormData;
    categoryVisibilty: VisibilityData;
    menuState: Menu;
    mainData: Requried;
    recieptName: RecieptData;
    reportData: ReportData;
  },
  | SetNameAction
  | HeaderLocationAction
  | UserAccessStateAction
  | deviceSetupAction
  | deviceListingAction
  | LocationsManagementAction
  | AssignOptionsStateAction
  | SetConfiguration
  | SetSettings
  | SetPricing
  | CreateUserAction
  | CreateCategory
  | Visibility
  | ResetStateAction
  | MenuSetUp
  | MainData
  | MainRecieptData
  | MainReportData
> = combineReducers({
  app: appReducer,
  LocationsManagement: locationsManagementReducer,
  headerLocation: headerLocationReducer,
  configuration: configureReducer,
  settings: settingsReducer,
  pricing: PricingReducer,
  users: createUserReducer,
  creatCategory: createCategoryReducer,
  categoryVisibilty: visibilityReducer,
  UserAccess: userAccessReducer,
  AssignOptions: assignOptionsReducer,
  menuState: MenuSetupReducer,
  mainData: mainDataReducer,
  recieptName: recieptDataReducer,
  deviceSetup: deviceSetupReducer,
  deviceListing: deviceListingReducer,
  reportData: reportDataReducer,
});

const store = configureStore({
  reducer: rootReducer,
});

export default store;
