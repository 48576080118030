import dayjs from "dayjs";

export const getGraphLabels = (startDate: string, endDate: string) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const labels = [];
  // const months = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  if (start.month() === end.month() && start.year() === end.year()) {
    const month = start.format("MMMM");
    for (let i = start.date(); i <= end.date(); i++) {
      labels.push(
        `${i}${
          i === 1 ? "st" : i === 2 ? "nd" : i === 3 ? "rd" : "th"
        } ${month}`
      );
    }
  } else {
    let currentMonth = start;
    while (currentMonth.isBefore(end) || currentMonth.isSame(end, "month")) {
      labels.push(currentMonth.format("MMM"));
      currentMonth = currentMonth.add(1, "month");
    }
  }

  return labels;
};
