import { Box, Button, Sheet, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/close2.png";
import { useMutation } from "@apollo/client";
import { UPDATE_BUSINESS } from "../../../../../graphicalQl/mutation/customerDataMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  twoFactorAuthenticationModal: any;
  setTwoFactorAuthenticationModal: any;

}

const TwoFactorAuthenticationModal = (props: Props) => {
  const {
    twoFactorAuthenticationModal,
    setTwoFactorAuthenticationModal,
  } = props
  const { t } = useTranslation()
  const [
    updateBusinessMutation,
    { data: updateBusinessMutationData, error: updateBusinessMutationError },
  ] = useMutation(UPDATE_BUSINESS, {
    context: { clientName: "businessClient" },
  });

  useEffect(() => {
    if (updateBusinessMutationData?.updateBusiness?.success) {
      twoFactorAuthenticationModal?.data?.refetchBusinessDetails();
      setTwoFactorAuthenticationModal({
        state: false,
        data: {},
      });
    }
  }, [updateBusinessMutationData]);

  const disableTwoFactorAuthentication = () => {
    updateBusinessMutation({
      variables: {
        businessId: twoFactorAuthenticationModal.data.businessId,
        addressDetails: {},
        enableTwoStepVerification: false,
      },
      onCompleted: () => {
        toast.success("Two Factor Authentication Turned Off Successfully");
      }
    });
  };

  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "720px",
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() => {
            setTwoFactorAuthenticationModal(false);
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          <Button
            onClick={() => setTwoFactorAuthenticationModal(false)}
            sx={{
              width: "97px",
              height: "44px",
              backgroundColor: "#EEE",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#F38B08",
                textTransform: "capitalize",
              }}
            >
              {t("two_factor.back")}
            </Typography>
          </Button>
          <Button
            onClick={() => disableTwoFactorAuthentication()}
            sx={{
              width: "155px",
              height: "44px",
              backgroundColor: "#F5431C",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F5431C",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#FEFEFE",
                textTransform: "capitalize",
              }}
            >
              {t("two_factor.turn_off")}
            </Typography>
          </Button>
        </Box>
      </Box>

      <Box
        sx={{
          marginLeft: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          {t("two_factor.verification_text")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "32px",
          }}
        >
          {t("two_factor.turn_off_text")}
        </Typography>
      </Box>
    </Sheet>
  );
};

export default TwoFactorAuthenticationModal;
