import { Box } from "@mui/material";
import "chart.js/auto";
import {
  Chart as ChartJS,
  LinearScale,
  PieController,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import React, { FC } from "react";
import { Pie } from "react-chartjs-2";

interface Props {
  datapoints: number[];
  labels: string[];
}

ChartJS.register(PieController, LineElement, PointElement, LinearScale, Title);

const PieChart: FC<Props> = ({ datapoints, labels }) => {
  const getOrCreateLegendList = (id: any) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "column";
      listContainer.style.margin = "0";
      listContainer.style.padding = "0";
      listContainer.style.gap = "26px";
      listContainer.style.justifyContent = "center";

      legendContainer && legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList("legend-container");

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";

        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box

        let boxSpan = null;
        boxSpan = document.createElement("span");
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "18px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "18px";

        // TextPayment Types
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.fontSize = "16px";
        textContainer.style.fontWeight = "500";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };
  const currencySymbol = sessionStorage.getItem("currencySymbol")

  const data = {
    labels: labels,
    datasets: [
      {
        label: `Amount (${currencySymbol})`,
        data: datapoints,
        borderColor: ["#AE9BE2", "#F6C65B", "#BAE29B", "#E2A39B", "#8ACDEA"],
        backgroundColor: [
          "#AE9BE2",
          "#F6C65B",
          "#BAE29B",
          "#E2A39B",
          "#8ACDEA",
        ],
        fill: true,
        hoverOffset: 1,
      },
    ],
  };
  const options: any = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Payment Types",
      },


      tooltip: {
        displayColors: false,
        bodySpacing: 10,
        enabled: true,
        backgroundColor: "#FFF8F0",
        bodyColor: "#F38B08",
        titleColor: "#F38B08",
        title: false,
        callbacks: {
          title: () => null,
        },
        padding: {
          x: 20,
          y: 10,
        },
        bodyFont: {
          weight: 700,
        },
      },
      legend: {
        display: false,
        boxWidth: 120,
        padding: -10,
        position: "left",
        align: "center",
        fullSize: true,
        title: {
          padding: 0,
          display: true,
        },
        labels: {
          boxWidth: 18,
          boxHeight: 18,
          paddingLeft: 20,
          marginLeft: 20,
          useBorderRadius: true,
        },
      },
    },
    interaction: {
      intersect: false,
    },
    fill: true,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: { xs: "column", sm: "row" },
        gap: { xs: "50px", sm: "100px" },
        marginTop: "20px",
        width: "48%",
      }}
    >
      <Box id="legend-container"></Box>
      <Box
        sx={{
          maxWidth: "600px",
          width: "75%",
          height: "75%",
          marginTop: "12px",
        }}
      >
        <Pie
          height={600}
          width={600}
          style={{ width: "100%", height: "100%" }}
          options={options}
          data={data}
          plugins={[htmlLegendPlugin]}
        />
      </Box>
    </Box>
  );
};

export default PieChart;
