import { gql } from "@apollo/client";

export const GET_SALES_TAX = gql`
query taxesList($storeIds:JSONString){
    taxesList(storeIds:$storeIds) {
        edges{node{taxId
        taxName
            items
            taxPercentage
            taxStatus
            store{storeId
                storeName
            }
            
    
        }}
    }
            }
`

export const GET_TOOGLE_STATUS = gql`
query storeSettings($storeId:ID){
	storeSettings(storeId:$storeId){
	
	allowSalesTax
		business{businessId
		brandName}
		}
	}
`

export const GET_SERVICE_TOOGLE_STATUS = gql`
query storeSettings($storeId:ID){
	storeSettings(storeId:$storeId){
	
        allowServiceTax
		business{businessId
		brandName}
		}
	}
`
export const TAX_EDIT_DATA = gql`
query Tax($taxId:ID){
    tax(taxId:$taxId) {
        taxId
    taxName
        taxPercentage
        store{
            storeId
        }
        items
        roundingRule
        includeTaxInItemPrice
    }}
`

export const GET_CHARGES_LIST = gql`
query ChargesList($storeIds:JSONString){
    chargesList(storeIds:$storeIds) {
        edges{node{chargeId
            chargeStatus
		chargePercentage
        chargeName}}
    }
            }
`

export const GET_SERVICE_CHARGES = gql`
query charge($chargeId:ID){
    charge(chargeId:$chargeId) {
        chargeId
        chargeName
        store{
            storeId
        }
        chargePercentage
            
        chargePercentage
        noOfCustomers
        totalCartAmount
        extraCharge
        applicableTo
        allowCustomSettings
	extraChargeType
    }}
`

export const GET_ADAVANCE_BOOKING = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
		kdsSettings
		depositAmountForCollection
depositAmountForDelivery
depositAmountForDinein
depositTypeForCollection
depositTypeForDelivery
depositTypeForDinein
acceptDepositForCollection
acceptDepositForDelivery
acceptDepositForDinein
applyDiscountsOnBookingDate
		deliveryMinAdvanceTime
		dineinMaxAdvanceTime
		deliveryMaxAdvanceTime
		deliveryNotificationTime
		dineinMinAdvanceTime
		dineinNotificationTime
		collectionMinAdvanceTime
		collectionMaxAdvanceTime
		collectionNotificationTime
        collectionSlotTime
		dineinSlotTime
		acceptAdvanceOrders
		kitchenNotificationAfterAccept
        showReasonForRejecting
	rejectingReasons
autoAcceptOrders
	snoozeTimings
	showSnoozeForAccept
    roundingRule
    deliverySlotTime
	dineinSlotTime
	}
	}

`

export const GET_ROUNDING = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
	
    roundingRule
	
	}
	}

`

export const GET_REFUND_CANCELATION = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
        refundTimeLimit
        refundWith
        allowRefund
        refundReasons
        cancellationBasedOnStatus
        allowRefundReasons
        noOfMinsToAcceptCancellationForDelivery
        noOfMinsToAcceptCancellationForDinein
        noOfMinsToAcceptCancellationForCollection
	}
	}

`
export const MANAGE_TIPS = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
        collectTips
        percentageTips
        flatAmountTips
        allowSmartTips
        setCartAmountForSmartTips
        calculateTipAfterTaxes
        allowCustomTips
        separateTippingScreen
        percentageTipDetails
        flatAmountTipDetails
	}
	}
`

export const OPEN_ITEMS = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
        allowPosUserAdhocItem
        adhocItemMaxPrice
        allowDiscountOnAdhoc
        allowRewardOnAdhoc
        allowStockDeductionOnAdhoc
	}
	}
`
export const GET_CUSTOM_DISCOUNTS = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
		allowPercentageDiscountsForPos
discountPercentagesForPos
allowFlatDiscountsForPos
flatDiscountsForPos
flatAmountTipDetails
flatDiscountsDetailsForPos	
		business{businessId
		brandName}
		}
	}
`

export const GET_PREFENCES = gql`
query StoreSettings($storeId:ID){
	storeSettings(storeId:$storeId){
	
		timeZone
		languages
		currency
	}
	}`

