import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Box, Typography, Paper, IconButton } from "@mui/material";
import { Stepper, Step, StepButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "../options/createProduct.css";
import "../category/createCategory.css";
import CreateGeneralDetails from "./generalDetails";
import { PRODUCT_DATA } from "../../graphicalQl/usequery/productsListQuery";
import { useQuery } from "@apollo/client";
import ProductPricing from "./pricing";
import ProductConfiguration from "./configuration";
import ProductSequence from "./sequence";
import ProductSettings from "./productSettings";
import ProductAditionalDetails from "./productAditionalDetails";
import { useSelector } from "react-redux";
import ProductPricingDailouge from "./pricingDailouge";
import IdDailouge from "./WithoutIdDailougeBox";
import { useDispatch } from "react-redux";
import {
  resetState,
  setName,
  setPricing,
  setproductConfiguration,
  setsettings,
} from "../../store";
import OptionsModal from "./optionInProduct";
import { orderType } from "./productSettings";
import { useTranslation } from "react-i18next";

interface Row {
  id: number;
  price: string;
  sequence?: string;
}

interface AddSizes {
  id: number;
  size: string;
  data: Row[];
  aditionalPrice: string;
}

const CreateAproduct = () => {
  const customStyles = {
    "& .MuiStepConnector-line": {
      display: "block",
      borderColor: "#bdbdbd",
      borderLeftStyle: "none !important",
      borderLeftWidth: "1px",
      minHeight: "0px !important",
      maxHeight: "0px !important",
    },
    "& .MuiStepConnector-root": {
      WebkitFlex: "none !important",
      msFlex: "none !important",
      flex: "none !important",
      marginLeft: "none !important",
    },
    "& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed": {
      fontFamily: "Poppins !important",
      fontSize: "1rem !important",
      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "normal !important",
    },
    "& .MuiButtonBase-root.MuiStepButton-root": {
      display: "flex !important",
      padding: "10px 12px !important",
      alignItems: "center !important",
      alignSelf: "stretch !important",
      borderRadius: "4px 4px 0px 0px !important",
      borderLeft: "2px solid #14B8A6 !important",
      background: "#FFF !important",
      "&:hover": {
        background: "#FFF8F0 !important",
      },
      border: "1px solid #EEE !important",
    },
  };
  const { product } = useParams();
  const dispatch = useDispatch();

  const { t } = useTranslation();
  const [steps, setSteps] = useState([
    t("product.general_details"),
    t("product.pricing"),
    t("product.assign_options"),
    t("product.sequence2"),
    t("product.configuration"),
    t("product.product_settings2"),
    t("product.additional_settings"),
  ]);

  useEffect(() => {
    const list = [
      t("product.general_details"),
      t("product.pricing"),
      t("product.assign_options"),
      t("product.sequence2"),
      t("product.configuration"),
      t("product.product_settings2"),
      t("product.additional_settings"),
    ];
    setSteps(list);
  }, [t]);
  useEffect(() => {
    if (product && product !== "") {
      dispatch(setName("productIdByEdit", product));
    }
  }, [product]);

  const { tabState } = useSelector((state: any) => state.app);
  const { noVariantChange } = useSelector((state: any) => state.pricing);

  const { checkBoxList } = useSelector((state: any) => state.settings);

  const { data: productDataFromApi } = useQuery(PRODUCT_DATA, {
    context: { clientName: "categoryClient" },
    variables: { productId: product && +product },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (productDataFromApi && productDataFromApi.product) {
      dispatch(
        setName(
          "name",
          productDataFromApi.product.name && productDataFromApi.product.name
        )
      );
      dispatch(
        setName(
          "shortCode",
          productDataFromApi.product.shortCode &&
            productDataFromApi.product.shortCode
        )
      );
      dispatch(
        setName(
          "category",
          productDataFromApi.product.category &&
            productDataFromApi.product.category.categoryId
        )
      );
      dispatch(
        setName(
          "unit",
          productDataFromApi.product.unit && productDataFromApi.product.unit
        )
      );
      dispatch(
        setName(
          "description",
          productDataFromApi.product.description &&
            productDataFromApi.product.description
        )
      );
      dispatch(
        setName(
          "isPos",
          productDataFromApi.product.isPos && productDataFromApi.product.isPos
        )
      );
      dispatch(
        setName(
          "isOnline",
          productDataFromApi.product.isOnline &&
            productDataFromApi.product.isOnline
        )
      );
      dispatch(
        setName(
          "image",
          productDataFromApi.product.image && productDataFromApi.product.image
        )
      );
      dispatch(
        setName(
          "color",
          productDataFromApi.product.bgColourCode &&
            productDataFromApi.product.bgColourCode
        )
      );
      dispatch(
        setName("nonTaxable", productDataFromApi.product.isNonTaxableProduct)
      );
      if (
        productDataFromApi.product.taxIds &&
        JSON.parse(productDataFromApi.product.taxIds)
      ) {
        const list = JSON.parse(productDataFromApi.product.taxIds).map(
          (each: any) => ({ tax_id: +each })
        );
        dispatch(setName("taxList", list));
      }

      dispatch(
        setName("shopProduct", productDataFromApi.product.isShopProduct)
      );
      if (productDataFromApi.product.locationId) {
        dispatch(setName("location", [productDataFromApi.product.locationId]));
      }
      if (productDataFromApi.product.course) {
        dispatch(setName("course", productDataFromApi.product.course.courseId));
      }

      if (productDataFromApi.product.reportingGroup) {
        dispatch(
          setName(
            "reportingGroup",
            productDataFromApi.product.reportingGroup.reportingGroupId
          )
        );
      }
      if (productDataFromApi.product.price) {
        dispatch(setPricing("price", productDataFromApi.product.price));
      }

      dispatch(
        setPricing(
          "sku",
          productDataFromApi.product.sku ? productDataFromApi.product.sku : ""
        )
      );
      dispatch(
        setPricing(
          "barcode",
          productDataFromApi.product.barcode
            ? productDataFromApi.product.barcode
            : ""
        )
      );
      dispatch(
        setPricing(
          "slice",
          productDataFromApi.product.allowSlices
            ? productDataFromApi.product.allowSlices
            : false
        )
      );

      if (productDataFromApi.product.variants) {
        const list = productDataFromApi.product.variants.map((each: any) => ({
          id: +each.variantId,
          name: each.name,
          price: each.additionalPrice || 0,
          slice: each.maxNoOfSlices,
          sku: each.sku || "",
          barcode: each.barcode || "",
          is_preselected: each.isPreselected,
          groupName: each.variantGroup,
          skuDuplicate: false,
          barcodeDuplicate: false,
        }));
        dispatch(setPricing("variations1", list));
        // const variationSizes = list.map((each: any) => each.name);

        // dispatch(setPricing("variationSizes", variationSizes));
        const variationSizes = list
          .filter((item: any) => item.groupName === "Size")
          .map((each: any) => each.name);

        dispatch(setPricing("variationSizes", variationSizes));
      }

      if (productDataFromApi.product.metatagKeywords) {
        dispatch(
          setproductConfiguration(
            "metaKeyWord",
            JSON.parse(productDataFromApi.product.metatagKeywords)
          )
        );
      }

      if (productDataFromApi.product.seoKeyword) {
        dispatch(
          setproductConfiguration(
            "seoKeyWord",
            JSON.parse(productDataFromApi.product.seoKeyword)
          )
        );
      }
      if (productDataFromApi.product.metatagDescription) {
        dispatch(
          setproductConfiguration(
            "metaTag",
            productDataFromApi.product.metatagDescription
          )
        );
      }

      if (productDataFromApi.product.minQuantity) {
        dispatch(
          setproductConfiguration(
            "minQuantity",
            productDataFromApi.product.minQuantity
          )
        );
      }
      if (productDataFromApi.product.maxNoOfSlices) {
        dispatch(
          setproductConfiguration(
            "minQuantity",
            productDataFromApi.product.maxNoOfSlices
          )
        );
      }
      if (productDataFromApi.product.allowOpenPrice) {
        dispatch(
          setproductConfiguration(
            "openPrice",
            productDataFromApi.product.allowOpenPrice
          )
        );
      }
      if (productDataFromApi.product.allowRefunds) {
        dispatch(
          setproductConfiguration(
            "allowRefunds",
            productDataFromApi.product.allowRefunds
          )
        );
      }
      if (productDataFromApi.product.allowDiscounts) {
        dispatch(
          setproductConfiguration(
            "allowPrice",
            productDataFromApi.product.allowDiscounts
          )
        );
      }
      if (productDataFromApi.product.lowStockAlertAt) {
        dispatch(
          setproductConfiguration(
            "alertAt",
            productDataFromApi.product.lowStockAlertAt
          )
        );
      }
      if (productDataFromApi.product.minDiscountAllowed) {
        dispatch(
          setproductConfiguration(
            "minDiscountAllowed",
            productDataFromApi.product.minDiscountAllowed
          )
        );
      }
      if (productDataFromApi.product.maxDiscountAllowed) {
        dispatch(
          setproductConfiguration(
            "maxDiscountAllowed",
            productDataFromApi.product.maxDiscountAllowed
          )
        );
      }
      if (productDataFromApi.product.isTrackStock) {
        dispatch(
          setproductConfiguration(
            "TrackStock",
            productDataFromApi.product.isTrackStock
          )
        );
      }
      if (productDataFromApi.product.isTrackLowStock) {
        dispatch(
          setproductConfiguration(
            "LowStockAlert",
            productDataFromApi.product.isTrackLowStock
          )
        );
      }
      if (productDataFromApi.product.maxNoOfSlices) {
        dispatch(
          setproductConfiguration(
            "maxSlices",
            productDataFromApi.product.maxNoOfSlices
          )
        );
      }

      const convertSequenceInformat: any =
        productDataFromApi.product.sequence &&
        productDataFromApi.product.sequence.map((each: any) => ({
          id: each.variant && each.variant.variantId,
          size: each.variant && each.variant.name,
          data: [
            {
              id: each.sequenceId,
              price: each.amount,
              sequence: each.sequenceName,
            },
          ],
          aditionalPrice: "",
        }));

      const convertWithOutSequenceInformat: Row[] =
        productDataFromApi.product.sequence &&
        productDataFromApi.product.sequence.map((each: any) => ({
          id: each.sequenceId,
          price: each.amount,
          sequence: each.sequenceName,
        }));

      const aditionalValueFromApi =
        convertWithOutSequenceInformat &&
        convertWithOutSequenceInformat[
          convertWithOutSequenceInformat &&
            convertWithOutSequenceInformat.length - 1
        ].price;

      if (aditionalValueFromApi) {
        dispatch(
          setproductConfiguration("aditionalPrice", aditionalValueFromApi)
        );
      }
      const groupedData =
        convertSequenceInformat &&
        convertSequenceInformat.reduce((acc: any, item: any) => {
          const { id, size, data } = item;
          if (!acc[id]) {
            acc[id] = {
              id: parseInt(id),
              size,
              data: [],
              aditionalPrice: "",
            };
          }

          // If data is an array of objects, push each object with non-"remaining" sequence
          if (data && data.length > 0) {
            const nonRemainingData = data.filter(
              (d: any) => d.sequence !== "remaining"
            );
            acc[id].data.push(...nonRemainingData);

            const remainingData = data.find(
              (d: any) => d.sequence === "remaining"
            );
            if (remainingData) {
              acc[id].aditionalPrice = remainingData.price;
            }
          }

          return acc;
        }, {});
      // const formatingNormalProductSequence=convertWithOutSequenceInformat.map((each:any)=>({
      // if(each.)
      // }))

      // Convert the object into an array of objects
      const resultArray: AddSizes[] = groupedData && Object.values(groupedData);
      if (
        productDataFromApi.product.sequence &&
        productDataFromApi.product.sequence[0].variant === null
      ) {
        dispatch(
          setproductConfiguration(
            "sequenceValue",
            convertWithOutSequenceInformat &&
              convertWithOutSequenceInformat.slice(
                0,
                convertWithOutSequenceInformat.length - 1
              )
          )
        );
      }
      // (resultArray && resultArray.length > 0)
      else {
        dispatch(setproductConfiguration("size", true));
        dispatch(setproductConfiguration("productSequenceValues", resultArray));
      }

      if (productDataFromApi.product.spiceIndicator) {
        let value;
        switch (productDataFromApi.product.spiceIndicator) {
          case "very_low":
            value = "1";
            break;
          case "low":
            value = "2";
            break;
          case "medium":
            value = "3";
            break;
          case "high":
            value = "4";
            break;
          case "very_high":
            value = "5";
            break;
          default:
            value = "";
            break;
        }
        dispatch(setsettings("ratingValue", value));
      }
      if (checkBoxList && productDataFromApi.product.allergens) {
        const updateCheckBoxList = checkBoxList.map((each: any) =>
          JSON.parse(productDataFromApi.product.allergens).includes(each.value)
            ? { ...each, isChecked: true }
            : each
        );
        dispatch(setsettings("checkBoxList", updateCheckBoxList));
      }

      if (productDataFromApi.product.productType) {
        dispatch(
          setsettings("selectedValue", productDataFromApi.product.productType)
        );
      }
      if (productDataFromApi.product.kdsStations) {
        dispatch(
          setsettings(
            "storeIds",
            JSON.parse(productDataFromApi.product.kdsStations)
          )
        );
      }
      if (productDataFromApi.product.ingredients) {
        dispatch(
          setsettings(
            "ingredients",
            JSON.parse(productDataFromApi.product.ingredients)
          )
        );
      }

      if (productDataFromApi.product.preparationTime) {
        dispatch(
          setsettings(
            "channel1",
            JSON.parse(productDataFromApi.product.preparationTime).channel[
              "channel-1"
            ].toString()
          )
        );
        dispatch(
          setsettings(
            "channel2",
            JSON.parse(productDataFromApi.product.preparationTime).channel[
              "channel-2"
            ].toString()
          )
        );
        dispatch(
          setsettings(
            "channel3",
            JSON.parse(productDataFromApi.product.preparationTime).channel[
              "channel-3"
            ].toString()
          )
        );
        dispatch(
          setsettings(
            "channel4",
            JSON.parse(productDataFromApi.product.preparationTime).channel[
              "channel-4"
            ].toString()
          )
        );
        dispatch(
          setsettings(
            "dineIn",
            JSON.parse(
              productDataFromApi.product.preparationTime
            ).order_type.dine_in.toString()
          )
        );
        dispatch(
          setsettings(
            "delivery",
            JSON.parse(
              productDataFromApi.product.preparationTime
            ).order_type.delivery.toString()
          )
        );
        dispatch(
          setsettings(
            "shop",
            JSON.parse(
              productDataFromApi.product.preparationTime
            ).order_type.shop.toString()
          )
        );
        dispatch(
          setsettings(
            "collection",
            JSON.parse(
              productDataFromApi.product.preparationTime
            ).order_type.collection.toString()
          )
        );
      }

      if (productDataFromApi) {
        dispatch(
          setsettings(
            "oredrPricing",
            JSON.parse(productDataFromApi.product.orderTypePriceVariable)
          )
        );
        const list =
          JSON.parse(productDataFromApi.product.orderTypePriceVariable) &&
          orderType.map((each: any) =>
            JSON.parse(productDataFromApi.product.orderTypePriceVariable) &&
            JSON.parse(productDataFromApi.product.orderTypePriceVariable)[
              each.value
            ] !== null
              ? {
                  ...each,
                  price: +JSON.parse(
                    productDataFromApi.product.orderTypePriceVariable
                  )[each.value],
                  isChecked: true,
                }
              : each
          );
        dispatch(setsettings("oredrPricing", list));
      }

      if (
        productDataFromApi.product.nutritionalValues &&
        JSON.parse(productDataFromApi.product.nutritionalValues).low_calorie
      ) {
        dispatch(
          setsettings(
            "lowCalaries",
            JSON.parse(productDataFromApi.product.nutritionalValues).low_calorie
          )
        );
      }
      if (
        productDataFromApi.product.nutritionalValues &&
        JSON.parse(productDataFromApi.product.nutritionalValues)
          .low_carbohydrates
      ) {
        dispatch(
          setsettings(
            "lowCarboHydrates",
            JSON.parse(productDataFromApi.product.nutritionalValues)
              .low_carbohydrates
          )
        );
      }

      if (
        productDataFromApi.product.nutritionalValues &&
        JSON.parse(productDataFromApi.product.nutritionalValues).per_serving ===
          true
      ) {
        dispatch(setsettings("perValue", "perServing"));
        // console.log(JSON.parse(productDataFromApi.product.nutritionalValues).per_serving)
      } else {
        if (productDataFromApi.product.nutritionalValues) {
          dispatch(setsettings("perValue", "per100g"));
        }

        // console.log(JSON.parse(productDataFromApi.product.nutritionalValues).per_serving)
      }

      if (
        productDataFromApi.product.nutritionalValues &&
        JSON.parse(productDataFromApi.product.nutritionalValues).values
      ) {
        const list = JSON.parse(
          productDataFromApi.product.nutritionalValues
        ).values.map((entry: any, index: any) => {
          const label = Object.keys(entry)[0];
          const value = entry[label] === null ? "" : entry[label];

          return { id: index + 1, label, value };
        });

        dispatch(setsettings("nutritionValue", list));
      }
    }
  }, [productDataFromApi]);

  const navigate = useNavigate();

  const handleNavigateTolisting = () => {
    navigate("/product/product-list");
    dispatch(setPricing("productId", []));
    dispatch(resetState());
  };

  const [activeStep, setActiveStep] = React.useState(+tabState || 0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
    dispatch(setName("tabState", newActiveStep));
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
    dispatch(setName("tabState", activeStep - 1));
  };

  const handleStep = (step: number) => {
    if (noVariantChange) {
      setActiveStep(step);
      dispatch(setName("tabState", step));
    } else {
      dispatch(setPricing("dailougeCondition", true));
    }
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleStepByProductPricingModal = () => {
    setActiveStep(0);
  };

  return (
    <Box>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0.75rem 1rem",
          marginBottom: "2.5rem",
          backgroundColor: "white",
          width: "60%",
        }}
      >
        <IconButton
          className="exit-container"
          onClick={handleNavigateTolisting}
        >
          <ArrowBackIcon className="exitIcon" />
        </IconButton>
        {product ? (
          <Typography className="create-product-head">
            {" "}
            {t("product.edit_product")}
          </Typography>
        ) : (
          <Typography className="create-product-head">
            {t("product.create_product")}
          </Typography>
        )}
      </div>
      <Box sx={{ width: "100%", display: "flex", paddingLeft: "20px" }}>
        <Stepper
          nonLinear
          activeStep={activeStep}
          orientation="vertical"
          sx={customStyles}
        >
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton
                onClick={() => handleStep(index)}
                key={label}
                icon={
                  completed[index] ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                        fill="#14B8A6"
                      />
                    </svg>
                  ) : index === activeStep ? (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z"
                        fill="#F38B08"
                      />
                    </svg>
                  ) : (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                    >
                      <path
                        d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z"
                        fill="#ABABAB"
                      />
                    </svg>
                  )
                }
              >
                {completed[index] ? (
                  <Typography sx={{ color: "#14B8A6" }}>{label}</Typography>
                ) : index === activeStep ? (
                  <Typography sx={{ color: "#F38B08" }}>{label}</Typography>
                ) : (
                  <Typography sx={{ color: "grey" }}>{label}</Typography>
                )}
              </StepButton>
            </Step>
          ))}
        </Stepper>

        <div style={{ width: "80%" }}>
          {allStepsCompleted() ? (
            <React.Fragment></React.Fragment>
          ) : (
            <React.Fragment>
              <Box>
                {activeStep === 0 && (
                  <Paper
                    elevation={1}
                    style={{
                      marginLeft: "4%",
                      borderLeft:
                        "2px solid var(--stroke-color-1-violet, #AE9BE2)",
                    }}
                  >
                    <CreateGeneralDetails
                      handleNext={handleComplete}
                      productId={product || ""}
                    />
                  </Paper>
                )}
                {activeStep === 1 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      padding: " 12px 20px 20px 20px",
                      borderLeft:
                        "2px solid  var(--stroke-color-2-tale, #9BDAE2)",
                    }}
                  >
                    <ProductPricing
                      handleComplete={handleComplete}
                      handleBack={handleBack}
                    />

                    <ProductPricingDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                    />
                  </Paper>
                )}

                {activeStep === 2 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      borderLeft:
                        "2px solid  var(--stroke-color-2-tale, #9BDAE2)",
                      padding: " 12px 20px 20px 20px",
                    }}
                  >
                    <Box sx={{ textAlign: "center" }}>
                      <Typography className="assign_product_text">
                        {t("product.click_on")}{" "}
                        <span className="assign_product_text2">
                          {t("product.assign_button")}
                        </span>{" "}
                        {t("product.add_option")}
                      </Typography>
                    </Box>
                    <OptionsModal />
                    <IdDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                      data={"options"}
                    />
                  </Paper>
                )}

                {activeStep === 3 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      paddingTop: "24px",
                      borderLeft:
                        "2px solid  var(--stroke-color-4-brown, #E2A39B)",
                    }}
                  >
                    {
                      <ProductSequence
                        handleBack={handleBack}
                        handleComplete={handleComplete}
                      />
                    }

                    <IdDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                      data={"sequence"}
                    />
                  </Paper>
                )}

                {activeStep === 4 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      padding: "1.5rem 1.12rem",
                      borderLeft:
                        "2px solid  var(--stroke-color-4-brown, #E2A39B)",
                    }}
                  >
                    {
                      <ProductConfiguration
                        handleBack={handleBack}
                        handleComplete={handleComplete}
                      />
                    }

                    <IdDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                      data={"configuration"}
                    />
                  </Paper>
                )}

                {activeStep === 5 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      padding: "1.5rem 1.12rem",
                      borderLeft:
                        "2px solid  var(--stroke-color-4-brown, #E2A39B)",
                    }}
                  >
                    {
                      <ProductSettings
                        handleBack={handleBack}
                        handleComplete={handleComplete}
                      />
                    }

                    <IdDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                      data={"settings"}
                    />
                  </Paper>
                )}

                {activeStep === 6 && (
                  <Paper
                    elevation={2}
                    style={{
                      marginLeft: "4%",
                      padding: "1.5rem 1.12rem",
                      borderLeft:
                        "2px solid  var(--stroke-color-4-brown, #E2A39B)",
                    }}
                  >
                    {
                      <ProductAditionalDetails
                        handleBack={handleBack}
                        handleComplete={handleComplete}
                      />
                    }

                    <IdDailouge
                      handleStepByProductModal={handleStepByProductPricingModal}
                      data={"aditionDetails"}
                    />
                  </Paper>
                )}
              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>
    </Box>
  );
};
export default CreateAproduct;
