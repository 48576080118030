import { Box } from "@mui/material";
import StoresReport from "../../components/reports/stores";
import React from "react";

const StoresReportPage = () => {
  return (
    <Box>
      <StoresReport />
    </Box>
  );
};
export default StoresReportPage;
