import React from "react";
import { Outlet } from "react-router-dom";
// import Footer from "../../footer";
// import Navbar from "../../navbar";
// import Newslatter from "../../dashboard/newsletter";

const WithOutMainLayout = () => {
  return (
    <div className="flex flex-col min-h-screen" >
      {/* <Navbar /> */}
      <Outlet />
     
    </div>
  );
};
export default WithOutMainLayout;
