import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { Box, Button, Typography, IconButton, Divider } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import "./categoryListing.css";
import { useQuery, useMutation } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import { CATEGORY_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import { Delete_Category } from "../../graphicalQl/mutation/mutation";
import AssignProducts from "./assigngnProductsModal";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import Tooltip from "@mui/material/Tooltip";
import ErrorModal from "../errorHandling/errorModal";
import DeleteDailouge from "./deleteDailouge";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomFooter from "./footer";
import DelayedInput from "../../utils/delayedInput";
// import { set } from 'react-hook-form';

interface Row {
  id: number;
  categoryId: string;
  productCount: number;
  slugName: string;
  productIds: any;
}

function CategoryListing() {
  const { t } = useTranslation();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [categoryList, setCategoryList] = useState<Row[]>([]);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const [hasNextPage, setHasNextPage] = useState(false);
  const [hasPreviousPage, setHasPreviousPage] = useState(false);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });

  // const handlePageChange = (newPage: any) => {
  //   setPaginationModel(prevModel => ({
  //     ...prevModel,
  //     page: newPage
  //   }));
  // };

  // const handlePageSizeChange = (newPageSize: any) => {
  //   setPaginationModel(prevModel => ({
  //     ...prevModel,
  //     pageSize: newPageSize
  //   }));
  // };
  const [searchValue, setSearchValue] = useState("");

  const {
    loading: categoryLoading,
    error: categoryError,
    data: categoryData,
  } = useQuery(CATEGORY_LIST, {
    context: { clientName: "categoryClient" },
    fetchPolicy: "cache-and-network",
    variables: {
      businessId: +businessId,
      name_Icontains: searchValue,
      first: !startCursor
        ? paginationModel.pageSize
        : startCursor === null && endCursor === null
        ? paginationModel.pageSize
        : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (
        graphqlError &&
        graphqlError.extensions &&
        graphqlError.extensions.status
      ) {
        // Extract the status code from the extensions property
        setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        setErrorModal(true);
      }
      const errorMessage = graphqlError.message || "An error occurred";
      console.error(
        `GraphQL error: Status ${statusCode}, Message: ${errorMessage}`
      );
    },
  });

  // useEffect(() => {
  //   setEndCursor(categoryData?.categoryList?.pageInfo.hasNextPage ? categoryData?.categoryList?.pageInfo.endCursor : "")
  // }, [paginationModel])

  const handleEndCursor = () => {
    setStartCursor(null);

    setEndCursor(categoryData?.categoryList?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(categoryData?.categoryList?.pageInfo.startCursor || null);
  };

  const [deletemodal, setDeleteModal] = useState(false);

  // Toggle delete modal
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const { locationId } = useSelector((state: any) => state.headerLocation);

  useEffect(() => {
    // Update category list when categoryData changes
    if (categoryData && categoryData.categoryList) {
      const list = categoryData.categoryList.edges.map(
        (item: any, index: number) => ({
          id: item.node.categoryId,
          categoryId: item.node.categoryId,
          slugName: item.node.name,
          productCount: item.node.productCount,
          productIds: item.node.productCategory
            ? item.node.productCategory.edges
                .map((each: any) =>
                  locationId &&
                  +locationId === each.node.locationId &&
                  each.node.isDeleted === false &&
                  each.node.productStatus === "Active"
                    ? +each.node.productId
                    : null
                )
                .filter((id: any) => id !== null)
            : [],
        })
      );

      setCategoryList(list);
      setHasNextPage(categoryData?.categoryList?.pageInfo.hasNextPage);
      setHasPreviousPage(categoryData?.categoryList?.pageInfo.hasPreviousPage);

      // setEndCursor(categoryData?.categoryList?.pageInfo.hasNextPage ? categoryData?.categoryList?.pageInfo.endCursor : "")
    }
  }, [categoryData, locationId]);

  const navigate = useNavigate();

  // Handle navigation to create category page
  const HandleNavigation = () => {
    navigate("/category/create-category");
  };

  const rows: Row[] = categoryList;
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteCategoryValue, { loading: deleteLoader }] = useMutation(
    Delete_Category,
    {
      onError: (error) => {
        console.error("Error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [CATEGORY_LIST],
      onCompleted: () => {
        toast.success(
          <div>
            <Typography>{t("toast.removed")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
    }
  );
  const [categoryIdValue, setCategoryIdValue] = useState("");
  const getCategoryId = (id: string) => {
    setCategoryIdValue(id);
  };
  const handleDelete = (id: string) => {
    deleteCategoryValue({ variables: { categoryIds: JSON.stringify([+id]) } });
    setCategoryIdValue("");
  };

  const handleEditInCategory = (id: string) => {
    navigate(`/category/${id}/update-category`);
  };

  // Handle delete by popover

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [categoryId, setCategoryId] = useState("");
  const [productIds, setProductIds] = useState<string[]>([]);

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state
    const filterRows = rows.filter((row) => rowSelectionModel.includes(row.id));
    const menuIds = filterRows.map((each) => +each.categoryId);
    deleteCategoryValue({
      variables: { categoryIds: JSON.stringify(menuIds) },
    });
    setRowSelectionModel([]);
  };
  // Handle category ID selection
  const handleCategoryId = (category: any) => {
    setCategoryId(category);
    const list = categoryList
      .filter((each) => each.categoryId === category)
      .map((item) => item.productIds);
    const idsList = list && list[0].map((each: any) => each);

    if (idsList) {
      setProductIds(idsList);
    }
  };
  // const CustomStyles = styled("div")({
  //   "&.MuiDataGrid-columnHeaderTitle": {
  //     fontWeight: "500",
  //   },
  //   "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
  //     outline: "solid #1976d2 0px !important",
  //   },
  //   "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
  //   {
  //     outline: "solid #1976d2 0px !important",
  //   },
  //   "& .MuiDataGrid-columnHeader": {
  //     background: "#f5f5f5",
  //   },
  // });

  const columns: GridColDef[] = [
    {
      field: "slugName",
      //@ts-ignore
      headerName: t("menu.catageory.categories"),
      flex: 3,
      // columnWidth: "60%",
      headerClassName: "table-column-header ",
      editable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography className="dataGrid-table-cell">
              {params.row.slugName}
            </Typography>
            <Typography className="categories-table-text-para">
              {params.row.productIds.length} {t("menu.catageory.products")}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "er",
      headerName: "",
      flex: 2,
      // width: 400,
      headerClassName: "table-column-header ",
      align: "center",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Button
              className="categories-button2"
              onClick={() => {
                handleOpen();
                handleCategoryId(params.row.categoryId);
              }}
            >
              {t("menu.catageory.assignProducts")}
            </Button>
          </Box>
        );
      },
    },
    {
      field: "product",
      //@ts-ignore
      headerName: t("menu.catageory.actions"),
      flex: 2,
      // width: 200,
      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "tableCellInCategory",
      align: "center",
      headerAlign: "center",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            <Tooltip title={t("buttons.delete")} placement="top-start" arrow>
              <IconButton
                onClick={() => {
                  handleDeleteModal();
                  getCategoryId(params.row.categoryId);
                }}
                sx={{ ":hover": { backgroundColor: "#EEE" } }}
                disabled={deleteLoader ? true : false}
              >
                <DeleteIcon sx={{ color: "grey" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("buttons.edit")} placement="top" arrow>
              <IconButton
                onClick={() => handleEditInCategory(params.row.categoryId)}
                sx={{ color: "grey", ":hover": { backgroundColor: "#EEE" } }}
              >
                <EditIcon sx={{ color: "grey" }} />
              </IconButton>
            </Tooltip>
          </>
        );
      },
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "slugName",
      sort: "asc",
    },
  ]);

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  const intProductIds = productIds.map((each) => +each);
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell";
  };
  const [currentPage, setCurrentPage] = React.useState(0);

  if (categoryError) return <p>Error : {categoryError.message}</p>;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "12px 20px",
        }}
      >
        <Box>
          <Typography className="main-head">
            {t("menu.catageory.categories")}
          </Typography>
          <Typography className="main-para">
            {t("menu.catageory.categoriesPara")}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", justifyContent: "space-evenly" }}>
          <Button
            className="main-button"
            onClick={HandleNavigation}
            sx={{ marginRight: "0.6rem" }}
          >
            {" "}
            {t("menu.catageory.create_category")}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button className="main-button" onClick={handleDeleteModal} sx={{}}>
              {t("menu.catageory.delete")}
            </Button>
          )}
        </Box>
      </Box>
      <Divider sx={{ mt: "12px", mb: "12px" }} />
      {/* <FormControl sx={{ m: 1, width: '20%' }} variant="outlined">
        <InputLabel htmlFor="outlined-adornment-password">Search by category name</InputLabel> */}

      {/* <TextField
        label={t("menu.catageory.search_by_category")}
        autoComplete="off"
        // placeholder='Search by category name'
        id="outlined-start-adornment"
        size="small"
        sx={{ ml: "20px", width: "20%", mb: "24px" }}
        onChange={(e) => setSearchValue(e.target.value)}

        InputProps={{
          startAdornment: (
            <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
          ),
        }}
      /> */}
      <Box sx={{ ml: "20px" }}>
        <DelayedInput
          setSearchValue={setSearchValue}
          placeHolder={t("users.search_by_name")}
          size={"small"}
        />
      </Box>
      {/* </FormControl> */}
      {categoryLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            height: "70vh",
            width: "100%",
            mt: "2vh",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            columnHeaderHeight={48}
            rowHeight={54}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: paginationModel.pageSize,
                },
              },
            }}
            pageSizeOptions={[5, 10, 25]}
            rowCount={categoryData?.categoryList?.totalCount || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              let shouldUpdatePaginationModel = true;

              if (newModel.pageSize !== paginationModel.pageSize) {
                setStartCursor(null);
                setEndCursor(null);
                setCurrentPage(0);
                setPaginationModel({
                  ...newModel,
                  page: 0,
                });
                shouldUpdatePaginationModel = false; // Prevent further updates within this call
              } else if (newModel.page > currentPage) {
                handleEndCursor();
              } else if (newModel.page < currentPage) {
                handleStartCursor();
              }

              // Update the current page

              // Only update paginationModel here if it wasn't updated above
              if (shouldUpdatePaginationModel) {
                setPaginationModel(newModel);
                setCurrentPage(newModel.page);
              }
            }}
            paginationMode="server"
            components={{
              Footer: (props) => (
                <CustomFooter
                  totalRowCount={Math.ceil(
                    (categoryData?.categoryList?.totalCount || 0) /
                      paginationModel.pageSize
                  )}
                  {...props}
                  page={paginationModel.page}
                />
              ),
            }}
            checkboxSelection
            disableRowSelectionOnClick
            onSortModelChange={handleSortModelChange}
            onRowSelectionModelChange={(newRowSelectionModel) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              marginLeft: "20px",
              marginRight: "20px",
              ".MuiTablePagination-displayedRows": {
                display: "none", // 👈 to hide huge pagination number
              },
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#f5f5f5",
              },
              // marginLeft: "20px",
            }}
          />
        </Box>
      )}
      <AssignProducts
        open={open}
        upDateOpen={handleClose}
        categoryId={categoryId}
        assigncategoryDataIds={intProductIds}
      />
      <ErrorModal
        errorModal={errorModal}
        statusCode={statusCode}
        handleErrorModal={handleErrorModal}
      />

      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDelete}
        id={categoryIdValue}
        handleDeleteClick={handleDeleteClick}
        type="category"
      />
    </Box>
  );
}

export default CategoryListing;
