import { Sheet } from "@mui/joy";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../assets/close2.png";
import EditIcon from "../../../../assets/edit.svg";
import { CUSTOMER_GROUP_LIST } from "../../../../graphicalQl/usequery/customerDataQuery";
import { useMutation, useQuery } from "@apollo/client";
import { EDIT_CUSTOMER_GROUP } from "../../../../graphicalQl/mutation/customerDataMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  setViewCustomerGroupModal: any;
  setAddCustomerGroupModal: any;
  refetchCustomerListings: any;
}

const ViewCustomerGroup = (props: Props) => {
  const {
    setViewCustomerGroupModal,
    setAddCustomerGroupModal,
    refetchCustomerListings,
  } = props;
  // state to store customer list after fetching from query
  const [customerGroups, setCustomerGroups] = useState([]);
  const { t } = useTranslation();
  // state to store new group name when trying to edit customer group name
  const [editCustomerGroupName, setEditCustomerGroupName] = useState("");
  const businessId: string = sessionStorage.getItem("businessId") as string;

  // state to control modal for editing  customer group name and passing required groupId to query
  const [editCustomerGroupNameModal, setEditCustomerGroupNameModal] = useState({
    state: false,
    groupId: null,
  });

  // query to fetch customer group list
  const {
    data,
    loading,
    error,
    refetch: refetchCustomerGroupList,
  } = useQuery(CUSTOMER_GROUP_LIST, {
    variables: { businessId: +businessId },
    context: { clientName: "customerClient" },
    fetchPolicy: "network-only",
  });

  // mustation to edit customer group name
  const [
    updateGroupName,
    { data: updateGroupNameData, error: updateGroupNameError },
  ] = useMutation(EDIT_CUSTOMER_GROUP, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const onChangeGroupName = (event: any) => {
    setEditCustomerGroupName(event.target.value);
  };

  useEffect(() => {
    if (updateGroupNameData?.editCustomerGroup.success) {
      // fetch new list  of groups after updating the group name
      refetchCustomerGroupList();
      setEditCustomerGroupName("");
      setEditCustomerGroupNameModal({
        state: false,
        groupId: null,
      });
    }
  }, [updateGroupNameData]);

  useEffect(() => {
    if (data) {
      const parsedCustomerGroupData = data?.customerGroupList?.edges?.map(
        (each: any) => ({
          name: each.node.groupName,
          groupId: each.node.groupId,
          customerIds: JSON.parse(each.node.customerGroup),
          customerCount: JSON.parse(each.node.customerGroup).length,
        })
      );
      setCustomerGroups(parsedCustomerGroupData);
    }
  }, [data]);

  const updateCustomerGroupName = () => {
    updateGroupName({
      variables: {
        groupId: editCustomerGroupNameModal.groupId,
        groupName: editCustomerGroupName,
      },
    });

    if (updateGroupNameError) {
    }
  };

  return (
    <>
      <Sheet
        variant="outlined"
        className="shortCutModalBoxShadow"
        sx={{
          width: "720px",
          outline: "none",
          borderRadius: "8px",
          border: "1px solid #EEEEEE",
          padding: "20px 32px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginBottom: "24px",
            borderBottom: "1px solid #D9D9D9",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "20px",
          }}
        >
          <img
            src={CloseIcon}
            alt="close modal"
            onClick={() => setViewCustomerGroupModal(false)}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "normal",
              color: "#333333",
            }}
          >
            {t("customer.customer_group")}
          </Typography>

          <Button
            onClick={() => {
              setViewCustomerGroupModal(false);
              setAddCustomerGroupModal({
                state: true,
                mode: "add",
                customerGroupDetails: {},
                refetchCustomerGroupList,
              });
            }}
            sx={{
              width: "155px",
              height: "44px",
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
                textTransform: "capitalize",
              }}
            >
              {t("customer.create_group")}
            </Typography>
          </Button>
        </Box>
        <Typography
          sx={{
            fontSize: "16px",
            lineHeight: "normal",
            fontWeight: "500",
            fontFamily: "Poppins",
            color: "#707070",
            marginBottom: "20px",
          }}
        >
          {customerGroups.length} {t("customer.customer_para")}
        </Typography>
        <Box
          sx={{
            maxHeight: "279px",
            overflow: "scroll",
          }}
        >
          {customerGroups.map((group: any) => {
            return (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "20px 12px",
                  ":hover": {
                    backgroundColor: "#FFF8F0",
                  },
                  borderTop: "2px solid #EEE",
                  ":nth-child(1)": {
                    borderTop: "none",
                  },
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "normal",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#333",
                      textTransform: "capitalize",
                    }}
                  >
                    {group.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "14px",
                      lineHeight: "normal",
                      fontWeight: "400",
                      fontFamily: "Poppins",
                      color: "#707070",
                    }}
                  >
                    {group.customerCount} {t("customer.customer")}
                  </Typography>
                </Box>
                <img
                  style={{
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    setAddCustomerGroupModal({
                      state: true,
                      mode: "update",
                      customerGroupDetails: group,
                      refetchCustomerGroupList,
                    })
                  }
                  src={EditIcon}
                  alt="edit customer group name"
                />
              </Box>
            );
          })}
        </Box>
      </Sheet>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={editCustomerGroupNameModal.state}
        onClose={(event, reason) => {
          setEditCustomerGroupNameModal({
            state: false,
            groupId: null,
          });
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "360px",
          top: "50px",
          opacity: 1,
        }}
      >
        <Sheet
          variant="outlined"
          className="shortCutModalBoxShadow"
          sx={{
            width: "720px",
            outline: "none",
            borderRadius: "8px",
            border: "1px solid #EEEEEE",
            padding: "20px 32px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "20px",
                lineHeight: "normal",
                color: "#333333",
              }}
            >
              {t("customer.edit_group_name")}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Button
                onClick={() =>
                  setEditCustomerGroupNameModal({
                    state: false,
                    groupId: null,
                  })
                }
                sx={{
                  width: "97px",
                  height: "44px",
                  backgroundColor: "#EEE",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#707070",
                    textTransform: "capitalize",
                  }}
                >
                  {t("butoons.cancel")}
                </Typography>
              </Button>
              <Button
                onClick={() => updateCustomerGroupName()}
                sx={{
                  width: "155px",
                  height: "44px",
                  backgroundColor: "#F38B08",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FEFEFE",
                    textTransform: "capitalize",
                  }}
                >
                  {t("butoons.save")}
                </Typography>
              </Button>
            </Box>
          </Box>

          <TextField
            type="text"
            fullWidth
            sx={{
              margin: "32px 0px",
            }}
            label="Group Name"
            placeholder="Group Name"
            id="outlined-size-normal"
            onChange={onChangeGroupName}
          />
        </Sheet>
      </Modal>
    </>
  );
};

export default ViewCustomerGroup;
