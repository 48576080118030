import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Paper,
  Divider,
  Grid,
  Button,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
// import {  unstable_useBlocker } from 'react-router-dom';
import _ from "lodash";
import {
  DragDropContext,
  DraggableProvided,
  Draggable,
  DropResult,
  DraggableStateSnapshot,
} from "react-beautiful-dnd";
import { CREATE_OPTION } from "../../graphicalQl/mutation/optionsMutation.tsx";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import "./drag.css";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { DELETE_MODIFIER } from "../../graphicalQl/mutation/optionsMutation.tsx";
import { ADD_MODIFIER } from "../../graphicalQl/mutation/optionsMutation.tsx";
import { EDIT_OPTION } from "../../graphicalQl/mutation/optionsMutation.tsx";
import { UPDATE_OPTION } from "../../graphicalQl/mutation/optionsMutation.tsx";
import { MODIFIERS_LIST } from "../../graphicalQl/usequery/optionsQuery";
import { GET_OPTIONS_LIST_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";
import { OPTIONS_LIST } from "../../graphicalQl/usequery/categoryListQuery.tsx";
import EditDailouge from "./editDailouge.tsx";
import { useFormik, useFormikContext } from "formik";
import * as Yup from "yup";
import { useLocation } from "react-router-dom";
import ErrorModal from "../errorHandling/errorModal.tsx";
import ImageDrawer from "../menu/image/imageDrawer.tsx";
import DeleteDailouge from "../category/deleteDailouge.tsx";
import { OPTIONS_WITHOUT_GROUP } from "../../graphicalQl/usequery/categoryListQuery.tsx";
import { useEffectOnce } from "../utils/useEffectOnce.tsx";

interface DataItem {
  optionValueId: number;
  name: string;
  price: number;
  image: string | null;
  bgColourCode: string | null;
}

interface CheckboxOption {
  name: string;
  label: string;
  id: number;
  isChecked: boolean;
}

const validationSchemaPart1 = Yup.object({
  optionName: Yup.string().required("Required"),
  // minSelect: Yup.number().typeError("* Must be a number"),
  // maxSelect: Yup.number().typeError("* Must be a number"),
  // optionPrice: Yup.number().typeError("* Must be a number"),
});

interface CreateOptions1 {
  searchedName: string | null;
  handleCloseFromAssign: () => void;
  fromAssignOptions: boolean;
  setSearchedName: React.Dispatch<React.SetStateAction<string | null>>;
}

export default function CreateOptions(props: CreateOptions1) {
  const {
    searchedName,
    handleCloseFromAssign,
    fromAssignOptions,
    setSearchedName,
  } = props;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { id } = useParams();
  const { t } = useTranslation();
  const initialState = {
    optionName: searchedName ? searchedName : "",
    modifier: false,
    minSelect: "",
    maxSelect: "",
    optionValue: "",
    optionPrice: 0,
  };
  const [categoryStatusCode, setCategoryStatusCode] = React.useState<number>(0);
  const [errorModal, setErrorModal] = React.useState<boolean>(false);
  const [resultObject,setResultObject]=useState<any>(null);

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,

    onSubmit: (values) => {
      // if (fromAssignOptions) {
      //   // handleCloseFromAssign();
      //   // setSearchedName("");
      // } else {
      // }
      if (id) {
        const finalValue=generateOptionObject()
        updateOption({
          variables: {
            optionId: +id || "",
            name: formik.values.optionName,
            minSelect: +formik.values.minSelect,
            maxSelect: +formik.values.maxSelect,
            isConversationalModifier: formik.values.modifier,
            modifiers:
              checkedIds.length > 0 ? JSON.stringify(checkedIds) : null,
            optionValues: JSON.stringify(finalValue),
            businessId: +businessId,
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
          onCompleted: (data) => {
            navigate("/option/options-list");
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
        });
      } else {
        createOption({
          variables: {
            name: values.optionName,
            minSelect: +values.minSelect,
            maxSelect: +values.maxSelect,
            isConversationalModifier: values.modifier,
            modifiers:
              checkedIds.length > 0 ? JSON.stringify(checkedIds) : null,
            optionValues: JSON.stringify(finalOptionData),
            businessId: +businessId,
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
          onCompleted: (data) => {
            if (fromAssignOptions) {
              handleCloseFromAssign();
              toast.success(
                <div>
                  <Typography>{t("toast.created")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
              setSearchedName("");
            } else {
              if (fromAssignOptions) {
                handleCloseFromAssign();
                setSearchedName("");
              } else {
                navigate("/option/options-list");
              }
            }

            toast.success(
              <div>
                <Typography>{t("toast.created")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
        });
      }
    },
  });


const generateOptionObject=()=>{
  const optionObject: {
    new_option_values?: any;
    updated_option_values?: any;
    removable_option_values?: any;
  } = {};

  if (resultObject && resultObject.deleted) {
    optionObject.new_option_values =
      resultObject.deleted &&
      resultObject.deleted.map((each: any) => ({
        name: each.name,
        price: +each.price || 0,
        image: each.image,
        bg_colour_code: each.bgColourCode,
       
      }));
  }
  if (resultObject && resultObject.changed) {
    optionObject.updated_option_values =
      resultObject.changed &&
      resultObject.changed.map((each: any) => {
        let updatedVariant: any = {
          name: each.old.name,
          price: +each.old.price || 0,
          image: each.old.image,
          bg_colour_code: each.old.bgColourCode,
        };

        if (id) {
          updatedVariant.option_value_id = +each.old.optionValueId;
        }

        return updatedVariant;
      });
  }
  if (resultObject && resultObject.added) {
    optionObject.removable_option_values =
      resultObject.added &&
      resultObject.added.map((each: any) => {
        if (id) {
          return +each.optionValueId;
        } else {
          return each.name;
        }
      });
  }
  return optionObject;
}




 
  

  const navigate = useNavigate();
  const [dataList, setDataList] = useState<DataItem[]>([]);
  const [initialDataList,setInitialDataList]=useState<DataItem[]>([]);
  const [modifierIds, setModifierIds] = useState<string[]>([]);
  const [checkboxOptions, setCheckboxOptions] = useState<CheckboxOption[]>([]);
  const { data: optionData, refetch: editDataRefetch } = useQuery(EDIT_OPTION, {
    context: { clientName: "categoryClient" },
    variables: { optionId: id && +id },
  });
  const { data: modifiersData, refetch: modifierRefetch } = useQuery(
    MODIFIERS_LIST,
    {
      context: { clientName: "categoryClient" },
      variables: { businessId: +businessId },
      fetchPolicy: "network-only",
    }
  );
  // const handleBeforeUnload = (event: any) => {
  //   if (formik.isValid) {
  //     // For older browsers
  //     setModalState(true);
  //     event.preventDefault();
  //     event.returnValue = null
  //   }
  // };

  // useEffectOnce(() => {
  //   window.addEventListener('beforeunload', handleBeforeUnload);
  //   console.log("mounting")

  //   return () => {

  //     window.removeEventListener('beforeunload', handleBeforeUnload);

  //     console.log("unmounting")
  //     setModalState(true);

  //   };
  // });

  function deepEqual(obj1: DataItem, obj2: DataItem): boolean {
    if (obj1 === obj2) return true;
  
    if (
      typeof obj1 !== "object" ||
      obj1 === null ||
      typeof obj2 !== "object" ||
      obj2 === null
    ) {
      return false;
    }
  
    const keys1 = Object.keys(obj1) as (keyof DataItem)[];
    const keys2 = Object.keys(obj2) as (keyof DataItem)[];
  
    if (keys1.length !== keys2.length) return false;
  
    for (let key of keys1) {
      // Directly compare the values without recursively calling deepEqual
      if (obj1[key] !== obj2[key]) {
        return false;
      }
    }
  
    return true;
  }
  
  function deepArrayEqualWithUnmatched(
    arr1: DataItem[],
    arr2: DataItem[]
  ): {
    areEqual: boolean;
    changed: { old: DataItem; new: DataItem }[];
    added: DataItem[];
    deleted: DataItem[];
  } {
    const changed: { old: DataItem; new: DataItem }[] = [];
    const added: DataItem[] = [];
    const deleted: DataItem[] = [];
  
    const arr1Checked = new Set<number>();
    const arr2Checked = new Set<number>();
  
    // Identify added and changed items
    arr2.forEach((element2, i) => {
      const matchingIndex = arr1.findIndex(
        (element1) => element1.optionValueId === element2.optionValueId
      );
  
      if (matchingIndex !== -1) {
        // Check if the objects are deeply equal
        if (!deepEqual(arr1[matchingIndex], element2)) {
          changed.push({ old: arr1[matchingIndex], new: element2 });
        }
        arr1Checked.add(matchingIndex);
      } else {
        // If no match is found, it's an addition
        added.push(element2);
      }
      arr2Checked.add(i);
    });
  
    // Identify deleted items
    arr1.forEach((element1, i) => {
      const matchingIndex = arr2.findIndex(
        (element2) => element2.optionValueId === element1.optionValueId
      );
  
      if (matchingIndex === -1) {
        deleted.push(element1);
      }
      arr1Checked.add(i);
    });
  
    return {
      areEqual:
        changed.length === 0 && added.length === 0 && deleted.length === 0,
      changed,
      added,
      deleted,
    };
  }

  useEffect(() => {
    const result = deepArrayEqualWithUnmatched(
dataList,      
initialDataList
    );
    setResultObject(result);
   
  }, [dataList]);

  // useEffect(()=>{
  //   const finalValue=generateOptionObject()
  //   console.log(finalValue)
  // },[resultObject])
  useEffect(() => {
    if (optionData) {
      if (optionData.option) {
        formik.setValues({
          ...formik.values,
          optionName: optionData.option.name || "",
          minSelect: optionData.option.minSelect || "",
          maxSelect: optionData.option.maxSelect || "",
          modifier: optionData.option.isConversationalModifier || false,
        });
        if (optionData.option.optionValues) {
          setInitialDataList(optionData.option.optionValues);

          setDataList(optionData.option.optionValues);
        }
        setModifierIds(
          optionData.option.modifiers &&
            optionData.option.modifiers.map((each: any) => each.modifierId)
        );
      }
    }
  }, [optionData]);

  useEffect(() => {
    const modifiersList = modifiersData?.modifiersList?.edges || [];

    const list: CheckboxOption[] = modifiersList.map((each: any) => ({
      id: each.node.modifierId,
      name: each.node.name,
      label: each.node.name,
      isChecked: false,
    }));

    setCheckboxOptions(list);
  }, [modifiersData, modifierIds]);

  const handleCheckboxChange = (name: string, isChecked: boolean) => {
    setCheckboxOptions((prevOptions) =>
      prevOptions.map((option) =>
        option.name === name ? { ...option, isChecked } : option
      )
    );
  };

  const handleSave = () => {
    const newItem: DataItem = {
      optionValueId: new Date().getTime(),
      name: formik.values.optionValue,
      price: +formik.values.optionPrice,
      image: null,
      bgColourCode: null,
    };
    setDataList((prevDataList) => [...prevDataList, newItem]);
  };

  useEffect(() => {
    if (modifierIds && modifierIds.length > 0) {
      setCheckboxOptions((prevOptions) =>
        prevOptions.map((each) => ({
          ...each,
          isChecked: modifierIds.includes(each.id.toString()),
        }))
      );
    }
  }, [modifierIds, modifiersData]);

  const onDragEnd = (result: DropResult) => {
    // Check if the drag operation was completed successfully
    if (!result.destination) {
      return;
    }

    const updatedDataList = Array.from(dataList);
    const [draggedItem] = updatedDataList.splice(result.source.index, 1);
    updatedDataList.splice(result.destination.index, 0, draggedItem);
    setDataList(updatedDataList);
  };

  const [optionValueField, setOptionValueField] = useState<boolean>(true);

  const handleOptionValueField = () => {
    setOptionValueField(true);
  };

  const [imageId, setImageId] = useState<number>(0);

  const getId = (id: number) => {
    setImageId(id);
  };

  const checkedIds = checkboxOptions
    .filter((each) => each.isChecked === true)
    .map((each) => +each.id);

  const finalOptionData =
    dataList &&
    dataList.map((each) => ({
      name: each.name,
      price: each.price,
      image: each.image,
      bg_colour_code: each.bgColourCode ? each.bgColourCode : null,
    }));

  const [createOption, { data, loading: createLoading }] = useMutation(
    CREATE_OPTION,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [
        GET_OPTIONS_LIST_IN_OPTIONS,
        OPTIONS_LIST,
        OPTIONS_WITHOUT_GROUP,
      ],
      // onError: (error) => {
      //   //   toast.error(
      //   //   <div style={{ display: 'flex', alignItems: 'center' }}>
      //   //     <Typography>{error.message}</Typography>
      //   //   </div>,
      //   //   {
      //   //     position: 'top-center',
      //   //     autoClose: 5000,
      //   //     hideProgressBar: true,
      //   //     closeOnClick: true,
      //   //     pauseOnHover: true,
      //   //     draggable: true,
      //   //     progress: undefined,
      //   //     theme: 'light',
      //   //     className: 'custom_toast2',
      //   //   }
      //   // )
      //   const graphqlError = error.graphQLErrors[0];

      // if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
      //   // Extract the status code from the extensions property
      //   setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
      // }

      // },
    }
  );
  const [modalState, setModalState] = useState(false);
  // const handleNavigation = () => {
  //   navigate("/options");
  // };

  // useEffect(() => {
  //   const handleRouteChange = () => {
  //     if (formik.dirty) {
  //       // Perform additional checks if needed

  //     }
  //   };

  //   console.log(location);

  //     handleRouteChange();

  // }, [ location.hash]);

  // useEffect(() => {
  //   const handleBeforeUnload = (event: any) => {
  //     if (formik.dirty) {
  //       // For older browsers
  //       setModalState(true);
  //       event.preventDefault();
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [formik.dirty]);

  // Additional logic for navigating to another screen
  const handleNavigate = () => {
    // Check if there are unsaved changes

    // Navigate to another screen if there are no unsaved changes or user confirmed

    // if (formik.dirty) {
    //   setModalState(true);
    // } else {
    // If no unsaved changes, navigate directly
    if (
      formik.dirty &&
      formik.isValid &&
      !_.isEqual(formik.values, formik.initialValues)
    ) {
      setModalState(true);
    } else {
      if (fromAssignOptions) {
        handleCloseFromAssign();
        setSearchedName("");
      } else {
        navigate("/option/options-list");
      }
    }

    // }
  };

  // useEffect(() => {
  //   const handleBeforeUnload = (event: BeforeUnloadEvent) => {
  //     if (!formik.dirty) {
  //       event.preventDefault();
  //       event.returnValue = 'You have unsaved changes. Are you sure you want to leave?';
  //     }
  //   }})

  const [addModifier, setModifier] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState("");
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };
  const handleDeleteOption = (id: string) => {
    const updatedDataList = dataList.filter(
      (each) => each.optionValueId.toString() !== id.toString()
    );
    setDataList(updatedDataList);
  };
  const [modifierNewId, setModifierNewId] = useState<string>("");
  const [createModifier] = useMutation(ADD_MODIFIER, {
    context: { clientName: "categoryClient" },
    onCompleted: (data) => {
      setModifierNewId(data.addModifier.modifier.modifierId);
    },
    refetchQueries: [GET_OPTIONS_LIST_IN_OPTIONS],
    onError: () => {
      toast.error(
        <div>
          <Typography>Modifier Already Created</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast2",
        }
      );
    },
  });
  const handleSaveClick = async () => {
    try {
      // Make the mutation call
      await createModifier({
        variables: { businessId: +businessId, name: inputValue },
      });

      // Use the updated modifierNewId after the mutation is completed
    } catch (error) {
      console.error("Mutation failed with error:", error);
    }
  };

  useEffect(() => {
    const newCheckboxOption: CheckboxOption = {
      name: inputValue.toLowerCase(), // Use lowercase for consistency
      label: inputValue,
      isChecked: false,
      id: +modifierNewId, // Make sure the conversion is appropriate
    };

    setCheckboxOptions([...checkboxOptions, newCheckboxOption]);

    setInputValue("");
  }, [modifierNewId]);

  const [deleteModifier] = useMutation(DELETE_MODIFIER, {
    context: { clientName: "categoryClient" },
    refetchQueries: [EDIT_OPTION, GET_OPTIONS_LIST_IN_OPTIONS],
  });
  const handleDeleteModifier = (id: number) => {
    deleteModifier({ variables: { businessId: +businessId, modifierId: +id } });

    const list = checkboxOptions.filter((each) => each.id !== id);

    setCheckboxOptions(list);
  };

  useEffect(() => {
    if (id) {
      editDataRefetch();
    }
  }, [id, editDataRefetch]);

  useEffect(() => {
    if (id) {
      modifierRefetch();
    }
  }, [id, modifierRefetch]);
  useEffect(() => {
    modifierRefetch();
  }, []);
  const handleModalState = () => {
    setModalState(false);
  };

  const [updateOption, { loading: updateLoading }] = useMutation(
    UPDATE_OPTION,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [
        GET_OPTIONS_LIST_IN_OPTIONS,
        OPTIONS_WITHOUT_GROUP,
        // OPTIONS_LIST,
      ],
      onCompleted: (data) => {
        toast.success(
          <div>
            <Typography>{t("toast.updated")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
      onError: (error) => {
        console.log(error.message);
      },
    }
  );
  const handleOptionValuePrice = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const list = dataList.map((each: any) =>
      each.optionValueId === id ? { ...each, price: event.target.value } : each
    );
    setDataList(list);
  };

  const handleOptionValueName = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const list = dataList.map((each) =>
      each.optionValueId === id ? { ...each, name: event.target.value } : each
    );
    setDataList(list);
  };

  const handleImage = (value: string | null) => {
    const list2 = dataList.map((each) =>
      each.optionValueId === imageId ? { ...each, image: value } : each
    );

    setDataList(list2);
  };

  const handleColor = (value: string | null) => {
    const list = dataList.map((each) =>
      each.optionValueId === imageId ? { ...each, bgColourCode: value } : each
    );

    setDataList(list);
  };
  const [deletemodal, setDeleteModal] = useState<boolean>(false);

  const handleDeleteModal = () => {
    setDeleteModal(false);
  };

  const handleDeleteClick = () => {};

  const [optionValue, setOptionValue] = useState<string>("");

  const DataListNames = dataList.map((each) =>
    each.name.toLocaleLowerCase().trim()
  );
  const [duplicateError, setDuplicateError] = useState<boolean>(false);
  const handleDuplicate = (value: any) => {
    setDuplicateError(DataListNames.includes(value.toLowerCase().trim()));
  };
  const [duplicateError2, setDuplicateError2] = useState<boolean>(false);

  const [rowId, setRowId] = useState<any>(0);
  const handleDuplicate2 = (value: any, id: number) => {
    const DataListNames2 = dataList
      .filter((each) => each.optionValueId !== id)
      .map((each) => each.name.toLocaleLowerCase().trim());
    setRowId(id);
    setDuplicateError2(DataListNames2.includes(value.toLowerCase().trim()));
  };

  const handleClear = () => {
    formik.setValues(initialState);
    setDataList([]);
    setCheckboxOptions((prevOptions) =>
      prevOptions.map((option) => ({ ...option, isChecked: false }))
    );
    setDuplicateError(false);
    setDuplicateError2(false);
  };

  const currencySymbol = sessionStorage.getItem("currencySymbol");

  return (
    <Box sx={{ width: "100%" }}>
      <Paper className="paper" sx={{ mb: 2, width: "100%" }}>
        <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
          {/* <EditDailouge modalState={modalState} handleModalState={handleModalState} handleUpdateCategoryData={}/> */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div className="create_exitIcon" onClick={handleNavigate}>
              <ArrowBackIcon sx={{ width: "60%" }} />
            </div>

            {id ? (
              <Typography className="create-option-head">
                {t("options.edit_option")}
              </Typography>
            ) : (
              <Typography className="create-option-head">
                {t("options.create_option")}
              </Typography>
            )}
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                className="create-option-cancel-button"
                sx={{ marginRight: "5%", textTransform: "none" }}
                onClick={handleClear}
              >
                {t("settings.clear")}
              </Button>
              <Button
                variant="contained"
                className={
                  duplicateError2 ? "main-button-disable" : "main-button"
                }
                disabled={duplicateError2 || createLoading || updateLoading}
                sx={{ textTransform: "none" }}
                type="submit"
              >
                {t("settings.save")}
              </Button>
            </div>
          </div>
          <Divider className="divider" />
          <div className="card">
            <Typography className="details_button" sx={{ marginLeft: "6%" }}>
              {t("options.details")}
            </Typography>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <FormControl>
                  <div>
                    <TextField
                      label={
                        <Typography>{t("options.option_name")}</Typography>
                      }
                      variant="outlined"
                      name="optionName"
                      color="warning"
                      value={formik.values.optionName}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={formik.handleBlur}
                      sx={{
                        marginTop: "3%",
                        marginLeft: "6%",
                        // "&:hover": {
                        //   "&& fieldset": {
                        //     border: "2px solid #F9C584"
                        //   }
                        // }
                      }}
                      error={
                        formik.touched.optionName &&
                        Boolean(formik.errors.optionName)
                      }
                      helperText={
                        formik.touched.optionName && formik.errors.optionName
                      }
                    />
                    {/* {formik.touched.optionName && formik.errors.optionName && (
                      <Typography style={{ color: "red", fontSize: "12px" }}>
                        {formik.errors.optionName}
                      </Typography>
                    )} */}
                  </div>
                </FormControl>
                <div
                  style={{ display: "flex", marginTop: "3%", marginLeft: "2%" }}
                >
                  <FormControl>
                    <div>
                      <TextField
                        label={
                          <Typography>{t("options.min_select")}</Typography>
                        }
                        variant="outlined"
                        name="minSelect"
                        value={formik.values.minSelect}
                        color="warning"
                        // type="number"
                        // onChange={(e) => {
                        //   formik.handleChange(e);
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;

                          if (
                            (!val || /^[0-9.]+$/.test(val)) &&
                            val.length <= 6
                          ) {
                            // handleInputChange2("price", e.target.value);
                            formik.handleChange(e);
                          }
                        }}
                        // className="hide-number-input-icons"
                        onBlur={formik.handleBlur}
                      />
                      {/* {
                        // formik.touched.minSelect &&
                        formik.errors.minSelect && (
                          <div style={{ color: "red" }}>
                            {formik.errors.minSelect}
                          </div>
                        )
                      } */}
                    </div>
                  </FormControl>
                  <FormControl>
                    <div>
                      <TextField
                        label={
                          <Typography>{t("options.max_select")}</Typography>
                        }
                        variant="outlined"
                        name="maxSelect"
                        value={formik.values.maxSelect}
                        color="warning"
                        // type="number"
                        // onChange={(e) => {
                        //   formik.handleChange(e);
                        // }}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          const val = e.target.value;

                          if (
                            (!val || /^[0-9.]+$/.test(val)) &&
                            val.length <= 6
                          ) {
                            //handleInputChange2("price", e.target.value);
                            formik.handleChange(e);
                          }
                        }}
                        onBlur={formik.handleBlur}
                        className="hide-number-input-icons"
                        sx={{
                          marginLeft: "2%",
                        }}
                      />
                      {/* {
                        // formik.touched.maxSelect &&
                        formik.errors.maxSelect && (
                          <div style={{ color: "red" }}>
                            {formik.errors.maxSelect}
                          </div>
                        )
                      } */}
                    </div>
                  </FormControl>
                </div>
                <div>
                  <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
                    <FormGroup style={{ marginTop: "5%" }}>
                      <FormControlLabel
                        label={
                          <Typography className="details_button">
                            {t("options.conversional_modifier")}
                          </Typography>
                        }
                        control={
                          <Switch
                            color="warning"
                            name="modifier"
                            checked={formik.values.modifier}
                            onChange={(
                              e: React.ChangeEvent<HTMLInputElement>
                            ) => {
                              formik.setFieldValue(
                                "modifier",
                                e.target.checked
                              );
                            }}
                          />
                        }
                        labelPlacement="start"
                        sx={{ width: "100%" }}
                      />
                    </FormGroup>
                  </Box>
                  <Typography
                    className="options-para"
                    style={{ marginLeft: "4%" }}
                  >
                    {t("options.modifier_para")}
                  </Typography>
                </div>
                {formik.values.modifier && (
                  <>
                    <Box>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "space-evenly",
                          width: "60%",
                        }}
                      >
                        {checkboxOptions.map(
                          (option, index) =>
                            option.name !== "" && (
                              <FormControl
                                className="options-checkbox"
                                sx={{ marginTop: "10px", position: "relative" }}
                                key={option.id}
                              >
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <FormControlLabel
                                    key={option.name}
                                    control={
                                      <Checkbox
                                        color="warning"
                                        checked={option.isChecked}
                                        onChange={(e) =>
                                          handleCheckboxChange(
                                            option.name,
                                            e.target.checked
                                          )
                                        }
                                      />
                                    }
                                    label={
                                      <Typography className="normalHeader">
                                        {option.label}
                                      </Typography>
                                    }
                                    labelPlacement="start"
                                  />
                                </Box>
                                {index > 4 && (
                                  <IconButton
                                    onClick={() =>
                                      handleDeleteModifier(option.id)
                                    }
                                    sx={{
                                      position: "absolute",
                                      top: 0,
                                      right: 0,
                                      padding: "0px",
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{ color: "grey", fontSize: "medium" }}
                                    />
                                  </IconButton>
                                )}
                              </FormControl>
                            )
                        )}
                        {addModifier && (
                          <Box>
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                marginTop: "3%",
                              }}
                            >
                              <TextField
                                label="Enter Value"
                                color="warning"
                                value={inputValue}
                                onChange={handleInputChange}
                              />
                              <Button
                                className="create-option-cancel-button"
                                sx={{ textTransform: "none", marginLeft: "1%" }}
                                onClick={() => setModifier(false)}
                              >
                                {t("options.cancel")}
                              </Button>
                              <Button
                                className="main-button"
                                sx={{ textTransform: "none", marginLeft: "1%" }}
                                onClick={() => {
                                  handleSaveClick();
                                  setModifier(false);
                                }}
                              >
                                Save
                              </Button>
                            </Box>
                          </Box>
                        )}
                      </div>
                      <Button
                        className="add-option-button"
                        onClick={() => setModifier(true)}
                        sx={{ textTransform: "none", marginTop: "2%" }}
                      >
                        {t("options.add_modifier")}
                      </Button>
                    </Box>
                  </>
                )}
              </Grid>
              <Box sx={{ width: "50%" }}>
                {dataList && dataList.length > 0 && (
                  <DragDropContext onDragEnd={onDragEnd}>
                    <StrictModeDroppable droppableId="rows">
                      {(provided) => (
                        <TableContainer
                          component={Paper}
                          sx={{ width: "100%", maxHeight: "45vh" }}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          <Table aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                <TableCell
                                  align="left"
                                  sx={{ width: "50%", marginLeft: "5%" }}
                                >
                                  <Typography className="details_button">
                                    {t("options.option_values")}
                                  </Typography>
                                </TableCell>
                                <TableCell align="left">
                                  <Typography className="details_button">
                                    {t("options.price")}
                                  </Typography>
                                </TableCell>
                                <TableCell align="center">
                                  <Typography></Typography>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {dataList.map((row, index) => (
                                <Draggable
                                  key={row.optionValueId}
                                  draggableId={row.optionValueId.toString()}
                                  index={index}
                                >
                                  {(
                                    provided: DraggableProvided,
                                    snapshot: DraggableStateSnapshot
                                  ) => (
                                    <TableRow
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      key={row.optionValueId}
                                      sx={{
                                        ...provided.draggableProps.style,
                                        ...(snapshot.isDragging
                                          ? {
                                              width: "100%",
                                              display: "flex",
                                              justifyContent: "space-between",
                                              alignItems: "center",
                                              padding: "8px", // Adjust padding as needed
                                              margin: "8px", // Adjust margin as needed
                                              border: "1px solid #ddd", // Add border when dragging
                                              borderRadius: "4px", // Add border radius for better visual
                                              // boxShadow: '0 0 10px rgba(0,0,0,0.5)',
                                            }
                                          : {}),
                                      }}
                                    >
                                      <TableCell
                                        align="left"
                                        sx={{ paddingLeft: "5%" }}
                                      >
                                        <div
                                          style={{
                                            display: "flex",
                                            alignItems: "center",
                                          }}
                                          onMouseEnter={() =>
                                            getId(row.optionValueId)
                                          }
                                        >
                                          {!fromAssignOptions && (
                                            <Box
                                              sx={{
                                                width: "12%",
                                                height: "36px",
                                              }}
                                              className="create-product-img-con"
                                            >
                                              <ImageDrawer
                                                handleImage={handleImage}
                                                handleColor={handleColor}
                                                colorCodeFromApi={
                                                  row.bgColourCode &&
                                                  row.bgColourCode
                                                }
                                                imageFromApi={
                                                  row.image && row.image
                                                }
                                                heightValue={50}
                                                textCondition={false}
                                              />
                                            </Box>
                                          )}
                                          <Box>
                                            <TextField
                                              value={row.name}
                                              onChange={(
                                                e: React.ChangeEvent<HTMLInputElement>
                                              ) => {
                                                handleOptionValueName(
                                                  e,
                                                  row.optionValueId
                                                );
                                                handleDuplicate2(
                                                  e.target.value,
                                                  row.optionValueId
                                                );
                                              }}
                                              variant="standard"
                                              InputProps={{
                                                disableUnderline: true,
                                              }}
                                              sx={{
                                                textTransform: "capitalize",
                                                marginLeft: "8px",
                                              }}
                                              onBlur={(e) => {
                                                if (e.target.value) {
                                                  // yourFunction(); // Call your function here
                                                  // handleDuplicate2(e.target.value, row.optionValueId)
                                                }
                                              }}
                                            />
                                            {duplicateError2 &&
                                              rowId === row.optionValueId && (
                                                <div
                                                  style={{
                                                    color: "red",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  option value Existed
                                                </div>
                                              )}
                                          </Box>{" "}
                                          {/* <Typography sx={{ marginLeft: "5%" }} className="row-text"> {row.name}</Typography> */}
                                        </div>
                                      </TableCell>
                                      <TableCell align="left">
                                        {/* <Typography className="row-text"> {row.price}</Typography> */}
                                        <TextField
                                          value={row.price}
                                          // type="number"
                                          // onChange={(
                                          //   e: React.ChangeEvent<HTMLInputElement>
                                          // ) => {
                                          //   handleOptionValuePrice(
                                          //     e,
                                          //     row.optionValueId
                                          //   );
                                          // }}
                                          onChange={(
                                            e: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            const val = e.target.value;
                                            if (
                                              (!val || /^[0-9.]+$/.test(val)) &&
                                              val.length <= 6
                                            ) {
                                              handleOptionValuePrice(
                                                e,
                                                row.optionValueId
                                              );
                                            }
                                          }}
                                          variant="standard"
                                          InputProps={{
                                            disableUnderline: true,
                                            startAdornment: (
                                              <InputAdornment position="start">
                                                {currencySymbol}
                                              </InputAdornment>
                                            ),
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell align="center">
                                        {
                                          <IconButton
                                            sx={{
                                              "&:hover": {
                                                background:
                                                  "var(--prime-orange-100, #FFF8F0)",
                                              },
                                            }}
                                            onClick={() => {
                                              setOptionValue(
                                                row.optionValueId.toString()
                                              );
                                              setDeleteModal(true);
                                            }}
                                          >
                                            <CloseIcon />
                                          </IconButton>
                                        }
                                      </TableCell>
                                    </TableRow>
                                  )}
                                </Draggable>
                              ))}
                              {provided.placeholder}
                            </TableBody>
                          </Table>
                        </TableContainer>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                )}
                {(optionValueField || dataList.length === 0) && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      marginTop: "24px",
                    }}
                  >
                    <Box>
                      <TextField
                        id="optionValue"
                        label={t("options.option_values")}
                        color="warning"
                        name="optionValue"
                        value={formik.values.optionValue}
                        onChange={(e) => {
                          formik.handleChange(e);
                          handleDuplicate(e.target.value);
                        }}
                        onBlur={(e) => {
                          formik.handleBlur(e);
                          if (e.target.value) {
                            // yourFunction(); // Call your function here
                            // handleDuplicate(e.target.value)
                          }
                        }}
                      />
                      {duplicateError && (
                        <div style={{ color: "red", fontSize: "12px" }}>
                          option value Existed
                        </div>
                      )}
                    </Box>
                    <FormControl>
                      <div>
                        <TextField
                          id="price"
                          label={t("options.price")}
                          type="text"
                          color="warning"
                          name="optionPrice"
                          value={formik.values.optionPrice}
                          onChange={(e) => {
                            const val = e.target.value;
                            if (
                              (!val || /^[0-9.]+$/.test(val)) &&
                              val.length <= 6
                            ) {
                              formik.handleChange(e);
                            }
                          }}
                          onBlur={formik.handleBlur}
                          className="hide-number-input-icons"
                        />
                        {
                          // formik.touched.optionPrice &&
                          formik.errors.optionPrice && (
                            <div style={{ color: "red" }}>
                              {formik.errors.optionPrice}
                            </div>
                          )
                        }
                      </div>
                    </FormControl>
                    <Button
                      onClick={() => {
                        setOptionValueField(false);
                        formik.setFieldValue("optionPrice", "");
                        formik.setFieldValue("optionValue", "");
                      }}
                      className="add-option-button"
                      sx={{
                        "&:hover": {
                          background: "var(--prime-orange-100, #FFF8F0)",
                        },
                        textTransform: "none",
                        height: "48px",
                      }}
                    >
                      {t("options.cancel")}
                    </Button>
                    <Button
                      onClick={() => {
                        handleSave();
                        setOptionValueField(false);
                        formik.setFieldValue("optionPrice", "");
                        formik.setFieldValue("optionValue", "");
                      }}
                      className={
                        duplicateError || formik.values.optionValue.length === 0
                          ? "main-button-disable"
                          : "main-button"
                      }
                      disabled={
                        duplicateError || formik.values.optionValue.length === 0
                      }
                      sx={{
                        textTransform: "none",
                        "&:hover": {
                          background: "var(--prime-orange-100, #FFF8F0)",
                        },
                        height: "48px",
                      }}
                    >
                      {t("options.save")}
                    </Button>
                  </Box>
                )}
                {!optionValueField && (
                  <Button
                    className="add-option-button"
                    onClick={handleOptionValueField}
                    sx={{
                      margin: "12px 0% 2% 0%",
                      "&:hover": {
                        background: "var(--prime-orange-100, #FFF8F0)",
                      },
                      textTransform: "none",
                    }}
                  >
                    {t("options.add_option_value")}
                  </Button>
                )}
              </Box>
            </Grid>
          </div>
        </form>
        <EditDailouge
          fromAssignOptions={fromAssignOptions}
          handleCloseFromAssign={handleCloseFromAssign}
          modalState={modalState}
          handleModalState={handleModalState}
          handleSubmit={formik.handleSubmit}
          isValid={formik.isValid}
        />
        <ErrorModal
          errorModal={errorModal}
          statusCode={categoryStatusCode}
          handleErrorModal={handleErrorModal}
        />
        <DeleteDailouge
          deletemodal={deletemodal}
          handleDleteByPopover={handleDeleteOption}
          id={optionValue}
          handleDeleteModal={handleDeleteModal}
          type="optionValue"
          handleDeleteClick={handleDeleteClick}
        />
      </Paper>
    </Box>
  );
}
