import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Paper,
  InputBase,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  CREATE_DRIVER_PETTY_CASH,
  UPDATE_DRIVER_PETTY_CASH,
} from "../../graphicalQl/mutation/deliveryManagementMutation";
import {
  DRIVER_PETTY_CASH_LIST,
  GETTING_DRIVER_DETAILS_IN_PETTY_CASH_FOR_UPDATE,
} from "../../graphicalQl/usequery/deliveryManagementQuery";
import { EmplpoyeesList } from "../../graphicalQl/usequery/userProfileUseQuery";
import CircularLoader from "../../loader/loader";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";

import { useTranslation } from "react-i18next";

import "./driverPettyCash.css";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Modal from "@mui/material/Modal";
import { useMutation, useQuery } from "@apollo/client";
import CurrencyDisplay from "../utils/currencySymbol";
import CustomFooter from "../category/footer";
import { localeToFormat } from "../../utils/dateFormat";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 562,
  bgcolor: "background.paper",
  borderRadius: "8px",
  boxShadow: 24,
  p: 3,
};

interface State {
  drivers: string[];
  pettyCash: number | null;
  driverName: string | null;
}

const initiallValues: State = {
  drivers: [],
  pettyCash: null,
  driverName: null,
};

function DriverPettyCash() {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const employeeIdFromSession = sessionStorage.getItem("employeeId");
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [pettyCashId, setPettyCashId] = React.useState<string | undefined>(
    undefined
  );
  const [employeeId, setEmployeeId] = React.useState<string | undefined>(
    undefined
  );
  const todayDate = moment().format("YYYY-MM-DD");

  const [selectedDate, setSelectedDate] = React.useState<string | null>(
    todayDate
  );
  const [selectedDateForTextField, setSelectedDateForTextField] =
    React.useState<string | null>(todayDate);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const filteredDataFromNull = {
    businessId: +businessId,
    query: searchInput,
    date: selectedDate,
    locationId: locationId ? +locationId : null,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };

  const validationSchema = Yup.object({
    pettyCash: Yup.number().required("Required"),
    drivers: Yup.array()
      .min(1, "At least one driver must be selected")
      .required("At least one driver must be selected"),
  });

  const formik = useFormik({
    initialValues: initiallValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const filteredCategory = Object.fromEntries(
    Object.entries(filteredDataFromNull).filter(([_, value]) => value !== null)
  );
  const {
    loading: driverPettyLoading,
    error: driverPettyError,
    data: driverPettyData,
  } = useQuery(DRIVER_PETTY_CASH_LIST, {
    context: { clientName: "userClient" },
    variables: filteredDataFromNull,
    fetchPolicy: "network-only",
  });

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(
      driverPettyData?.driverPettycashList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(
      driverPettyData?.driverPettycashList?.pageInfo.startCursor || null
    );
  };

  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(EmplpoyeesList, {
    context: { clientName: "userClient" },

    variables: {
      businessId: +businessId,
      locationId: +locationId,
      excludedEmployeeId: employeeIdFromSession && +employeeIdFromSession,
    },
  });
  const {
    data: updateDriverData,
    loading: updateDriverLoading,
    error: updateDriverError,
  } = useQuery(GETTING_DRIVER_DETAILS_IN_PETTY_CASH_FOR_UPDATE, {
    context: { clientName: "userClient" },
    variables: { id: pettyCashId && pettyCashId },
    fetchPolicy: "network-only",
  });
  const userLang = navigator.language;
  const [rows, setRows] = React.useState<any[]>([]);
  const [initialRows, setInitialRows] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const [driversList, setDriversList] = React.useState<any[]>([]);
  const [popupSearch, setPopupSearch] = useState("");
  const checkedCount = driversList.filter(
    (location: any) => location.isChecked
  ).length;
  const [noOfLocationsSelected, setNoOfLocationsSelected] =
    React.useState(checkedCount);

  const [allCheckboxChecked, setAllCheckboxChecked] =
    React.useState<any>(false);
  const [editDate, setEditDate] = useState<string>("");
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setPettyCashId(undefined);
    setEmployeeId(undefined);
    formik.resetForm();
    setDriversList(initialRows);
    setNoOfLocationsSelected(0);
    setAllCheckboxChecked(false);
  };

  useEffect(() => {
    if (updateDriverData) {
      const { driverPettycash } = updateDriverData;
      if (driverPettycash) {
        const { employee, pettycash, date } = driverPettycash;
        const driverName = `${employee && employee.firstName} ${
          employee && employee.lastName
        }`;
        formik.setFieldValue("driverName", driverName);
        formik.setFieldValue("pettyCash", pettycash);
        formik.setFieldValue("drivers", [+employee?.employeeId]);
        const requiredDate = moment(date).format("YYYY-MM-DD");
        setEditDate(requiredDate);
      }
    }
  }, [updateDriverData]);
  useEffect(() => {
    if (driverPettyData) {
      const { driverPettycashList } = driverPettyData;
      const { edges } = driverPettycashList;
      const filteredData = edges.map((each: any) => ({
        id: each.node.employeeId,
        pettycashId: each.node.pettycashId,
        employee: each.node.employee && each.node.employee,
        date: each.node.date,
        pettycash: each.node.pettycash,
        locationId: each.node.locationId,
      }));
      setRows(filteredData);
    }
  }, [driverPettyData]);
  const [updatePettyCash] = useMutation(UPDATE_DRIVER_PETTY_CASH, {
    onCompleted: (data) => {},
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [
      EmplpoyeesList,
      DRIVER_PETTY_CASH_LIST,
      GETTING_DRIVER_DETAILS_IN_PETTY_CASH_FOR_UPDATE,
    ],
  });

  const [createPettyCash] = useMutation(CREATE_DRIVER_PETTY_CASH, {
    onCompleted: (data) => {},
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [
      EmplpoyeesList,
      DRIVER_PETTY_CASH_LIST,
      GETTING_DRIVER_DETAILS_IN_PETTY_CASH_FOR_UPDATE,
    ],
  });
  useEffect(() => {
    if (employeesData) {
      if (employeesData !== null) {
        const requiredDataList = employeesData.employees.edges.map(
          (each: any) => ({
            id: each.node.employeeId,
            commissionId: each.node.commissionId,
            driverName: `${each.node.firstName} ${each.node.lastName}`,
            permissions:
              each.node.permissions && JSON.parse(each.node.permissions),
            isChecked: false,
          })
        );

        const filteredDriverList =
          requiredDataList &&
          requiredDataList.filter(
            (each: any) => each.permissions && each.permissions.includes(44)
          );
        setDriversList(filteredDriverList);
        setInitialRows(filteredDriverList);
      }
    }
  }, [employeesData]);

  const handlePopupCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { checked } = event.target;
    const filteredList = driversList.map((each) => {
      if (each.id === id) {
        return { ...each, isChecked: checked };
      }
      return each;
    });
    const checkedCount = filteredList.filter(
      (location: any) => location.isChecked
    ).length;

    setAllCheckboxChecked(checkedCount === filteredList.length);
    setDriversList(filteredList);
    setNoOfLocationsSelected(checkedCount);
    const collectedIds = filteredList
      .filter((each: any) => each.isChecked)
      .map((eachDriver: any) => +eachDriver.id);
    formik.setFieldValue("drivers", collectedIds);
  };

  const handlePopupCheckbo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const filteredList = driversList.map((each: any) => ({
      ...each,
      isChecked: checked,
    }));
    setNoOfLocationsSelected(checked ? filteredList.length : 0);
    setAllCheckboxChecked(checked);
    setDriversList(filteredList);
    const collectedIds = filteredList
      .filter((each: any) => each.isChecked)
      .map((eachDriver: any) => +eachDriver.id);
    formik.setFieldValue("drivers", collectedIds);
  };

  const handleEditClick = (employeeId: string, pettyCashId: string) => () => {
    setPettyCashId(pettyCashId);
    setEmployeeId(employeeId);
    setOpen(true);
  };
  const handleCloseIcon = () => {
    handleClose();
    setEmployeeId(undefined);
    setPettyCashId(undefined);
  };

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleSaveButton = () => {
    if (employeeId !== undefined) {
      updatePettyCash({
        variables: {
          pettycash: formik.values.pettyCash && +formik.values.pettyCash,
          employeeIds: JSON.stringify([+employeeId]),
          date: editDate,
          businessId: +businessId,
          locationId: +locationId,
        },
      });
    } else {
      // const filteredDriverList = driversList
      //   .filter((eachDrivr: any) => eachDrivr.isChecked)
      //   .map((each: any) => +each.id);
      createPettyCash({
        variables: {
          pettycash: formik.values.pettyCash && +formik.values.pettyCash,
          employeeIds: JSON.stringify(formik.values.drivers),
          date: todayDate,
          businessId: +businessId,
          locationId: +locationId,
        },
      });
    }

    handleClose();
  };

  const handleTimeChange = (date: string) => {
    setSelectedDateForTextField(date);
    if (date) {
      setSelectedDate(date);
    }
  };

  const handleSearchIcon = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPopupSearch(event.target.value);
  };
  const filteredDriversList = driversList.filter(
    (each) =>
      each.driverName &&
      each.driverName.toLocaleLowerCase().includes(popupSearch)
  );

  const columns: GridColDef[] = [
    {
      field: "employee",
      //@ts-ignore
      headerName: t("deliveries.driver"),
      headerClassName: "driver-commission-table-header-name",
      flex: 1,

      renderCell: (params) => (
        <Box>
          <Typography
            sx={{ textTransform: "capitalize" }}
            className="user-list-table-row"
          >
            {params.value.firstName} {params.value.lastName}
          </Typography>
        </Box>
      ),
    },

    {
      field: "pettycash",
      //@ts-ignore
      headerName: t("deliveries.petty_cash"),
      headerClassName: "driver-commission-table-header-name",
      flex: 1,
      align: "left",

      renderCell: (params) => (
        <Box>
          <Typography className="user-list-table-row">
            {
              <CurrencyDisplay
                countryName={"UnitedKingdom"}
                amount={params.value}
              />
            }
          </Typography>
        </Box>
      ),
    },

    {
      field: "date",
      //@ts-ignore
      headerName: t("deliveries.date"),
      headerClassName: "driver-commission-table-header-name",
      type: "datetime",
      flex: 1,

      renderCell: (params) => (
        <Box sx={{ display: "flex", justifyContent: "", alignItems: "center" }}>
          <Typography className="user-list-table-row">
            {params.value
              ? moment(params.value).format(`${localeToFormat[userLang]}`)
              : "N/A"}
          </Typography>
        </Box>
      ),
    },
    {
      field: t("buttons.actions"),
      //@ts-ignore
      headerClassName: "user-role-header-names",
      width: 100,
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => (
        <Box className="user-roles-header-hover" sx={{ marginLeft: "15px" }}>
          <IconButton
            onClick={handleEditClick(params.row.id, params.row.pettycashId)}
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
    },
  ];
  let locationsText;
  if (noOfLocationsSelected === 0) {
    locationsText = t("deliveries.select_driver");
  } else if (noOfLocationsSelected === 1) {
    locationsText = t("deliveries.driver");
  } else {
    locationsText = t("deliveries.drivers");
  }

  if (driverPettyError) return <p>Error : {driverPettyError.message}</p>;

  const handleUserInputChange = (text: string) => {
    setSearchInput(text);
  };

  const today = new Date().toISOString().split("T")[0];
  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography className="driver-commission-header-text">
          {t("deliveries.driver_petty_cash")}
        </Typography>
        <Button
          onClick={handleOpen}
          className="driver-commission-header-button"
          variant="contained"
        >
          {t("deliveries.add_petty_cash")}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "60%",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={handleCloseIcon}
                  className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
                >
                  <CloseIcon />
                </IconButton>

                <Typography className="driver-commission-popup-header">
                  {t("deliveries.petty_cash")}
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "15px", marginTop: "15px" }} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "60%",
                  }}
                >
                  {employeeId === undefined && (
                    <Box sx={{ width: "100%", height: "7vh", zIndex: "10" }}>
                      <Accordion
                        sx={{ width: "100%", border: "2px solid #d9d9d9" }}
                        elevation={0}
                      >
                        <AccordionSummary
                          sx={{ width: "100%", marginBottom: 0 }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            className="create-a-use-role-label"
                            sx={{ marginBottom: "0px" }}
                          >
                            {noOfLocationsSelected === 0
                              ? locationsText
                              : `${noOfLocationsSelected} ${locationsText}`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <Box sx={{ width: "100%" }}>
                              <TextField
                                onChange={(e) => handleSearchIcon(e)}
                                //@ts-ignore
                                placeholder={t("users.search_by_name")}
                                value={searchInput}
                                id="outlined-start-adornment"
                                sx={{ width: "95%" }}
                                color="warning"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              height: "30vh",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                overflowY: "auto",
                                maxHeight: "32vh",
                                marginTop: "10px",
                                minHeight: "10vh",
                                WebkitOverflowScrolling: "touch",
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                              }}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="all"
                                      checked={allCheckboxChecked}
                                      onChange={(e) => handlePopupCheckbo(e)}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "1.75rem",
                                        },
                                      }}
                                      color="warning"
                                    />
                                  }
                                  label={
                                    <Typography className="driver-commission-driver-label">
                                      {t("buttons.all")}
                                    </Typography>
                                  }
                                />
                                {filteredDriversList.map((each: any) => (
                                  <FormControlLabel
                                    key={each.id}
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          handlePopupCheckbox(e, each.id);
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: "1.75rem",
                                          },
                                        }}
                                        color="warning"
                                        checked={each.isChecked}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{ textTransform: "capitalize" }}
                                        className="driver-commission-driver-label"
                                      >
                                        {each.driverName}
                                      </Typography>
                                    }
                                  />
                                ))}
                              </FormGroup>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                  {formik.touched.drivers && formik.errors.drivers && (
                    <Box>
                      <Typography
                        sx={{ marginTop: "8px" }}
                        className="create-a-user-formik-label"
                      >
                        {t("error.atleast_one_driver")}
                      </Typography>
                    </Box>
                  )}

                  {employeeId !== undefined && (
                    <TextField
                      color="warning"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        width: "100%",
                      }}
                      value={formik.values.driverName || ""}
                      label={t("deliveries.driver_name")}
                      variant="outlined"
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  {/* <FormControl
                    sx={{ margin: "20px 0px 5px 0px ", width: "100%" }}
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      Amount
                    </InputLabel>
                    <OutlinedInput
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      onBlur={(e) => formik.handleBlur(e)}
                      name="pettyCash"
                      value={formik.values.pettyCash || ""}
                      color="warning"
                      sx={{ width: "100%" }}
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          {currencySymbol}
                        </InputAdornment>
                      }
                      label="Amount"
                    />
                  </FormControl> */}

                  <TextField
                    id="price"
                    onChange={(e) => {
                      const val = e.target.value;
                      if (!val || /^[0-9]+$/.test(val)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    name="pettyCash"
                    value={formik.values.pettyCash || ""}
                    color="warning"
                    sx={{ width: "100%", margin: "20px 0px 5px 0px " }}
                    className="hide-number-input-icons"
                    InputProps={{
                      // Note the capital 'P' in 'InputProps'
                      startAdornment: (
                        <InputAdornment position="start">
                          {currencySymbol}
                        </InputAdornment>
                      ),
                    }}
                    label={t("inventory.amount")}
                  />

                  {formik.touched.pettyCash && formik.errors.pettyCash && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "20px" }}>
                <Button
                  className="driver-commission-popup-button"
                  variant="contained"
                  color="warning"
                  type="submit"
                >
                  {t("buttons.save")}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
        }}
      >
        <DelayedInput
          width="30%"
          margin="10px 0px 10px 8px"
          setSearchValue={setSearchInput}
          placeHolder={t("users.search_by_name")}
          size={"medium"}
        />

        <TextField
          color="warning"
          type="Date"
          label={t("dashboard.Select_Date")}
          value={selectedDateForTextField}
          onChange={(e) => handleTimeChange(e.target.value)}
          sx={{ width: "14%" }}
          inputProps={{ max: today }}
        />
      </Box>

      {driverPettyLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            padding: "10px",
            height: "70vh",
            width: "100%",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={rows}
            columns={columns}
            getRow={getRow}
            totalCount={driverPettyData?.driverPettycashList?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}

export default DriverPettyCash;
