import { Box, Button, Divider, Typography } from "@mui/material";
import React from "react";
import DevicesIcon from '@mui/icons-material/Devices';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import GamepadIcon from '@mui/icons-material/Gamepad';
import LocalPlayIcon from '@mui/icons-material/LocalPlay';
import Image from "../../assets/menuQr/setupimg.png"
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";
const SetUpOnline = () => {
    const { t } = useTranslation();

    const navigate = useNavigate()
    return (
        <Box>
            <Box sx={{ padding: "22px 32px" }}>
                <Typography className="main-head">{t("online_ordering.online_ordering")}</Typography>
            </Box>
            <Divider />
            <Box sx={{ marginTop: "24px", width: "100%", textAlign: 'center' }} >
                <Typography className="odering-text" sx={{ textTransform: 'capitalize' }}>{t("online_ordering.set_up_line1")}<br /> {t("online_ordering.set_up_line2")}</Typography>
                <Button className="main-button" sx={{ marginTop: '24px' }}
                    onClick={() => { navigate("/online/start-setUp") }}
                >{t("online_ordering.button_text1")}</Button>
            </Box>
            <Box sx={{ marginTop: "24px", width: "100%", display: "flex", gap: '12px' }}>
                <Box sx={{ width: "50%", display: 'flex', flexDirection: "column", paddingLeft: '15%' }}>
                    <Typography className="main-head"></Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '20px' }}>
                        <DevicesIcon sx={{ color: "#707070" }} />
                        <Typography className="heavy-para-text2 ">{t("online_ordering.set_up_line3")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '20px' }}>
                        <PhoneAndroidIcon sx={{ color: "#707070" }} />
                        <Typography className="heavy-para-text2 ">{t("online_ordering.set_up_line4")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '20px' }}>
                        <GamepadIcon sx={{ color: "#707070" }} />
                        <Typography className="heavy-para-text2 ">{t("online_ordering.set_up_line5")}</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '16px', mt: '20px' }}>
                        <LocalPlayIcon sx={{ color: "#707070" }} />
                        <Typography className="heavy-para-text2 ">{t("online_ordering.set_up_line6")}</Typography>
                    </Box>


                </Box>
                <Box sx={{ width: '40%' }}>
                    <img src={Image} alt="setUp" />
                </Box>
            </Box >

        </Box>
    )
}


export default SetUpOnline