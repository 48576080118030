import { gql } from "@apollo/client";

export const ONLINE_ORDERING_SETTINGS = gql`
      mutation updateBusiness($businessId:ID!,$onlineSettings:JSONString,$domain:String,$subDomain:String){
  updateBusiness(	
			businessId:$businessId
      domain:$domain,
		subDomain:$subDomain,
		onlineSettings:$onlineSettings
  ) {
    errorMessage
		success
    business{
			businessId
			
		}
		
	}
	
}

`

export const GET_ONLINE_ORDERING_SETTINGS = gql`
query business($businessId:ID){
business(
	businessId:$businessId
  ) {
		onlineSettings
		domain
subDomain
domainSslType
subDomainSslType

  }
}
`