import React from "react";
import NodataImage from "./nodataImage";
import CustomFooter from "../pages/category/footer";
import {
  DataGrid,
  GridColDef,
  GridRowModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";

interface State {
  paginationModel: any;
  rows: any[];
  columns: any[];
  getRow: any;
  totalCount: any;
  handleEndCursor: () => void;
  handleStartCursor: () => void;
  currentPage: any;
  imageHeight: string;
  imageWidth: string;
  heightForContainer: string;
  shouldUpdatePaginationModelIsTrue: (newModal: any) => void;
  handleUpdatingValuesToNull: (newModel: any) => void;
  rowSelectionModel?: any;
  setRowSelectionModel?: any;
  checkboxSelection?: any;
  fontSizeForNoDataText?: string;
}

const DataGridTable = (props: State) => {
  const { t } = useTranslation();
  const {
    imageHeight,
    heightForContainer,
    fontSizeForNoDataText,
    imageWidth,
    paginationModel,
    shouldUpdatePaginationModelIsTrue,
    handleUpdatingValuesToNull,
    rows,
    columns,
    getRow,
    totalCount,
    handleEndCursor,
    handleStartCursor,
    currentPage,
    rowSelectionModel,
    setRowSelectionModel,
    checkboxSelection,
  } = props;

  const localeText = {
    // Override specific text strings with translations
    noRowsLabel: t("users.no_rows"),
    // footerRowSelected: count =>
    //   count === 1 ? t('users.footer_row_selected_single') : t('users.footer_row_selected_multiple', { count }),
    toolbarColumns: t("users.columns"),
    toolbarDensity: t("users.density"),
    // Override the "Rows per page" text
    toolbarPagination: "Hari",
  };

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      getRowClassName={getRow}
      disableRowSelectionOnClick
      pageSizeOptions={[25, 50, 100]}
      pagination
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: paginationModel.pageSize,
          },
        },
      }}
      checkboxSelection={checkboxSelection}
      onRowSelectionModelChange={(
        newRowSelectionModel: GridRowSelectionModel | string[]
      ) => {
        setRowSelectionModel(newRowSelectionModel);
      }}
      rowSelectionModel={rowSelectionModel}
      rowCount={totalCount}
      paginationModel={paginationModel}
      onPaginationModelChange={(newModel) => {
        let shouldUpdatePaginationModel = true;
        if (newModel.pageSize !== paginationModel.pageSize) {
          handleUpdatingValuesToNull(newModel);
          shouldUpdatePaginationModel = false;
        } else if (newModel.page > currentPage) {
          handleEndCursor();
        } else if (newModel.page < currentPage) {
          handleStartCursor();
        }

        if (shouldUpdatePaginationModel) {
          shouldUpdatePaginationModelIsTrue(newModel);
        }
      }}
      paginationMode="server"
      components={{
        NoRowsOverlay: () => (
          <NodataImage
            height={imageHeight}
            width={imageWidth}
            heightForContainer={heightForContainer}
            fontSizeForNoDataText={fontSizeForNoDataText}
          />
        ),

        Footer: (props) => (
          <CustomFooter
            totalRowCount={Math.ceil(totalCount / paginationModel.pageSize)}
            {...props}
            page={paginationModel.page}
          />
        ),
      }}
      localeText={localeText}
      sx={{
        "&.MuiDataGrid-columnHeaderTitle": {
          fontWeight: "500",
        },
        "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
          outline: "solid #1976D2 0px !important",
        },
        "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
          {
            outline: "solid #1976D2 0px !important",
          },
        "& .MuiDataGrid-columnHeader": {
          background: "#F5F5F5",
        },

        ".MuiTablePagination-displayedRows": {
          display: "none", // :point_left: to hide huge pagination number
        },
      }}
    />
  );
};

export default DataGridTable;
