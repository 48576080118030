import { gql } from "@apollo/client";

export const ADD_CUSTOMER_GROUP = gql`
  mutation AddCustomerGroup(
    $businessId: Int!
    $groupName: String
    $customerGroup: JSONString
  ) {
    addCustomerGroup(
      businessId: $businessId
      groupName: $groupName
      customerGroup: $customerGroup
    ) {
      success
      message
      group {
        groupId
        groupName
        customerGroup
      }
    }
  }
`;

export const DELETE_CUSTOMER_GROUP = gql`
  mutation DeleteCustomerGroup($groupIds: JSONString!, $businessId: Int!) {
    deleteCustomerGroup(groupIds: $groupIds, businessId: $businessId) {
      success
    }
  }
`;

export const DUPLICATE_CHECK_FOR_EMAIL_AND_MOBILE = gql`
  mutation duplicateCheck(
    $emailId: String
    $mobile: String
    $businessId: Int
    $userId: UUID
  ) {
    duplicateCheck(
      emailId: $emailId
      mobile: $mobile
      businessId: $businessId
      userId: $userId
    ) {
      message
      duplicate
      success
    }
  }
`;

export const CREATE_CUSTOMER = gql`
  mutation CreateCustomer(
    $businessId: Int!
    $firstName: String!
    $lastName: String!
    $email: String!
    $mobile: String!
    $addressDetails: AddressInput!
    $dateOfBirth: Date
    $anniversary: Date
    $companyName: String
    $taxIdentifier: String
  ) {
    createCustomer(
      businessId: $businessId
      firstName: $firstName
      lastName: $lastName
      email: $email
      mobile: $mobile
      addressDetails: $addressDetails
      dateOfBirth: $dateOfBirth
      anniversary: $anniversary
      companyName: $companyName
      taxIdentifier: $taxIdentifier
    ) {
      errorMessage
      success
      customer {
        customerId
      }
    }
  }
`;

export const UPDATE_CUSTOMER = gql`
  mutation UpdateCustomer(
    $customerId: Int!
    $businessId: Int
    $firstName: String
    $lastName: String
    $email: String
    $mobile: String
    $companyName: String
    $taxIdentifier: String
    $dateOfBirth: Date
    $anniversary: Date
  ) {
    updateCustomer(
      customerId: $customerId
      businessId: $businessId
      firstName: $firstName
      lastName: $lastName
      email: $email
      mobile: $mobile
      companyName: $companyName
      taxIdentifier: $taxIdentifier
      dateOfBirth: $dateOfBirth
      anniversary: $anniversary
    ) {
      errorMessage
      success
      customer {
        customerId
      }
    }
  }
`;
export const UPDATE_CUSTOMER2 = gql`
  mutation UpdateCustomer($customerId: Int!, $businessId: Int, $image: String) {
    updateCustomer(
      customerId: $customerId
      businessId: $businessId

      image: $image
    ) {
      errorMessage
      success
      customer {
        customerId
      }
    }
  }
`;

export const ADD_ADDRESS = gql`
  mutation AddAddress(
    $businessId: Int!
    $customerId: Int!
    $addressDetails: AddressInput!
  ) {
    addAddress(
      businessId: $businessId
      customerId: $customerId
      addressDetails: $addressDetails
    ) {
      errorMessage
      success
    }
  }
`;

export const DELETE_ADDRESS = gql`
  mutation DeleteAddress($addressId: ID!) {
    deleteAddress(addressId: $addressId) {
      success
      result
      errorMessage
    }
  }
`;

export const UPDATE_ADDRESS_IN_EDIT = gql`
  mutation updateAddress(
    $addressId: ID!
    $address1: String
    $address2: String
    $city: String
    $country: String
    $postCode: String
    $addressType: String
    $isDefault: Boolean
    $latitude: String
    $longitude: String
    $receiverName: String
    $receiverMobile: String
  ) {
    updateAddress(
      addressId: $addressId
      address1: $address1
      address2: $address2
      city: $city
      country: $country
      postCode: $postCode
      addressType: $addressType
      isDefault: $isDefault
      latitude: $latitude
      longitude: $longitude

      receiverName: $receiverName
      receiverMobile: $receiverMobile
    ) {
      errorMessage
      success
      address {
        addressId
        address1
        address2
        landMark
      }
    }
  }
`;
export const UPDATE_ADDRESS_IN_EDIT2 = gql`
  mutation updateAddress(
    $addressId: ID!
    $isDefault: Boolean
    $customerId: Int
  ) {
    updateAddress(
      addressId: $addressId

      customerId: $customerId
      isDefault: $isDefault
    ) {
      errorMessage
      success
      address {
        addressId
        address1
        address2
        landMark
      }
    }
  }
`;

export const EDIT_CUSTOMER_GROUP = gql`
  mutation EditCustomer(
    $groupId: Int!
    $groupName: String
    $customerGroup: JSONString
  ) {
    editCustomerGroup(
      groupId: $groupId
      groupName: $groupName
      customerGroup: $customerGroup
    ) {
      success
      group {
        groupId
        groupName
        customerGroup
      }
    }
  }
`;

export const CUSTOMER_STATUS_CHANGE = gql`
  mutation customerStatusChange($customerId: ID!, $status: String) {
    customerStatusChange(customerId: $customerId, customerStatus: $status) {
      success
      customer {
        customerId
        customerStatus
      }
    }
  }
`;

export const DELETE_CUSTOMERS = gql`
  mutation DeleteCustomers($customerIds: JSONString!, $businessId: Int!) {
    deleteCustomers(customerIds: $customerIds, businessId: $businessId) {
      message
      success
    }
  }
`;

export const UPDATE_FEATURE_SETTINGS = gql`
  mutation BranchSettings(
    $enableShifts: Boolean
    $enableClockinClockout: Boolean
    $allowOpenItems: Boolean
    $sendOrdersTokitchenPrinters: Boolean
    $allowCustomerDisplaySystems: Boolean
    $allowDinningOptions: Boolean
    $lowStockNotifications: Boolean
    $allowNegativeStockAlerts: Boolean
    $storeId: Int!
  ) {
    branchSettings(
      storeId: $storeId
      enableShifts: $enableShifts
      enableClockinClockout: $enableClockinClockout
      allowOpenItems: $allowOpenItems
      sendOrdersTokitchenPrinters: $sendOrdersTokitchenPrinters
      allowCustomerDisplaySystems: $allowCustomerDisplaySystems
      allowDinningOptions: $allowDinningOptions
      lowStockNotifications: $lowStockNotifications
      allowNegativeStockAlerts: $allowNegativeStockAlerts
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const UPDATE_ACCOUNT_PREFERENCES = gql`
  mutation BranchSettings(
    $timeZone: String
    $currency: String
    $languages: JSONString
    $storeId: Int!
  ) {
    branchSettings(
      storeId: $storeId
      timeZone: $timeZone
      currency: $currency
      languages: $languages
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const UPDATE_BUSINESS_MAIL = gql`
  mutation UpdateBusiness($businessId: ID!, $email: String) {
    updateBusiness(businessId: $businessId, email: $email) {
      errorMessage
      success
      business {
        businessId
      }
    }
  }
`;
export const UPDATE_BUSINESS_MOBILE = gql`
  mutation UpdateBusiness($businessId: ID!, $mobile: String) {
    updateBusiness(businessId: $businessId, mobile: $mobile) {
      errorMessage
      success
      business {
        businessId
      }
    }
  }
`;

export const UPDATE_CALLER_ID_SETTINGS = gql`
  mutation BranchSettings(
    $allowCustomerToOrderThroughCall: Boolean
    $allowCallerIdNotifications: Boolean
    $showCustomerDetailsInCallerid: Boolean
    $storeId: Int!
  ) {
    branchSettings(
      storeId: $storeId
      allowCustomerToOrderThroughCall: $allowCustomerToOrderThroughCall
      allowCallerIdNotifications: $allowCallerIdNotifications
      showCustomerDetailsInCallerid: $showCustomerDetailsInCallerid
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const GENERATE_OTP = gql`
  mutation CustomerOtpGeneration(
    $mobile: String
    $email: String
    $businessId: Int
  ) {
    customerOtpGeneration(
      mobile: $mobile
      email: $email
      businessId: $businessId
    ) {
      authenticate {
        logId
        otp
        email
        generatedAt
      }
    }
  }
`;

export const VALIDATE_OTP = gql`
  mutation CustomerAuthentication(
    $mobile: String
    $email: String
    $businessId: Int!
    $otp: String
    $isAdminVerification: Boolean
  ) {
    customerAuthentication(
      mobile: $mobile
      email: $email
      businessId: $businessId
      otp: $otp
      isAdminVerification: $isAdminVerification
    ) {
      success
      errorMessage
      authenticated
    }
  }
`;

export const DEACTIVATE_BUSINESS = gql`
  mutation DeactivateBusinessRequest($businessId: ID!) {
    deactivateBusinessRequest(businessId: $businessId) {
      errorMessage
      result
      success
    }
  }
`;

export const UPDATE_BUSINESS = gql`
  mutation UpdateBusiness(
    $businessId: ID!
    $legalBusinessName: String
    $addressDetails: AddressInput!
    $brandLogo: String
    $favIcon: String
    $enableTwoStepVerification: Boolean
  ) {
    updateBusiness(
      brandLogo: $brandLogo
      favIcon: $favIcon
      businessId: $businessId
      legalBusinessName: $legalBusinessName
      addressDetails: $addressDetails
      enableTwoStepVerification: $enableTwoStepVerification
    ) {
      errorMessage
      success
      business {
        businessId
      }
    }
  }
`;

export const UPDATE_COMPLAINT_STATUS = gql`
  mutation ComplaintStatusChange($complaintId: Int, $complaintStatus: String) {
    complaintStatusChange(
      complaintId: $complaintId
      complaintStatus: $complaintStatus
    ) {
      complaint {
        complaintId
        complaintStatus
        customer {
          customerId
        }
      }
      success
    }
  }
`;

export const UPDATE_ADDRESS = gql`
  mutation UpdateAddress(
    $addressId: ID!
    $address1: String
    $address2: String
    $country: String
    $postCode: String
    $city: String
    $state: String
  ) {
    updateAddress(
      addressId: $addressId
      address1: $address1
      address2: $address2
      country: $country
      postCode: $postCode
      city: $city
      state: $state
    ) {
      errorMessage
      success
      address {
        addressId
        address1
        address2
        landMark
      }
    }
  }
`;

export const DELETE_ATTENDANCE_RECORD = gql`
  mutation DeleteEmployeeAttendance($attendanceId: Int) {
    deleteEmployeeAttendance(attendanceId: $attendanceId) {
      message
      success
    }
  }
`;

export const EDIT_LOG_ENTRY = gql`
  mutation EditEmployeeClockin(
    $attendanceId: Int
    $clockinTime: Time
    $clockoutTime: Time
    $dateOf: Date
    $note: String
  ) {
    editEmployeeClockin(
      attendanceId: $attendanceId
      clockinTime: $clockinTime
      clockoutTime: $clockoutTime
      dateOf: $dateOf
      note: $note
    ) {
      errorMessage
      success
      attendance {
        employeeId
        clockIn
        clockOut
        dateOf
      }
    }
  }
`;

export const ADD_LOG_ENTRY = gql`
  mutation EmployeeClockIn(
    $businessId: Int!
    $employeeId: Int!
    $clockinTime: Time
    $clockoutTime: Time
    $dateOf: Date
    $note: String
    $locationId: Int
  ) {
    employeeClockIn(
      businessId: $businessId
      employeeId: $employeeId
      locationId: $locationId
      clockinTime: $clockinTime
      clockoutTime: $clockoutTime
      dateOf: $dateOf
      note: $note
    ) {
      errorMessage
      success
      attendance {
        attendanceId
        employee {
          employeeId
        }
        clockIn
        dateOf
      }
    }
  }
`;

export const UPDATE_ORDER_STATUS = gql`
  mutation BranchSettings($storeId: Int!, $orderStatusLookup: JSONString) {
    branchSettings(storeId: $storeId, orderStatusLookup: $orderStatusLookup) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const UPDATE_ORDER_NUMBERING = gql`
  mutation BranchSettings($storeId: Int!, $orderNumberingSettings: JSONString) {
    branchSettings(
      storeId: $storeId
      orderNumberingSettings: $orderNumberingSettings
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;
