import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CloseIcon from '@mui/icons-material/Close';
import '../category/createCategory.css'
import { useEffect } from 'react';
import { Divider } from 'primereact/divider';
import { useTranslation } from "react-i18next";


interface Props {
  id: string;
  deletemodal: boolean;
  handleDeleteModal: () => void;
  handleDleteByPopover: (id: string) => void;

}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function DeleteDailougeInBundle(props: Props) {
  const { deletemodal, handleDleteByPopover, id, handleDeleteModal } = props
  const { t } = useTranslation();


  const [open, setOpen] = React.useState(deletemodal);


  const handleClose = () => {
    setOpen(false)
    handleDeleteModal()
  };

  const handleDelete = () => {

    handleDleteByPopover(id)
    handleDeleteModal()
  }


  useEffect(() => {

    setOpen(deletemodal)


  }, [deletemodal])


  return (
    <div>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", width: "60%", justifyContent: "space-between" }}>
            <Box className="exit-container" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }} onClick={handleClose}>
              <CloseIcon className="exitIcon" />

            </Box>
            <Typography className='category-created'>{t("menu.bundle.delete_bundle_group")}</Typography>

          </Box>
          <Divider />
          <Box sx={{ padding: "3% 1% 3% 1%", marginTop: '1%', textAlign: 'center' }}>


            <Typography className='create_category_modal_text'>
              {t("menu.bundle.delete_text")}
            </Typography>
          </Box>
          <Divider />
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>

            <Button className='create_category_modal_delete_button1' sx={{ marginLeft: "3%", textTransform: "none", backgroundColor: "#F5431C" }} onClick={() => { handleClose(); handleDelete() }}>{t("menu.bundle.delete_bundle_group")}</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}










