import React, { useEffect, useState } from "react";
import { Box, Button, Typography, IconButton } from "@mui/material";
import "./courseListing.css";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import DeleteDailouge from "../category/deleteDailouge";
import CreateCourse from "./createCourse";
import { COURSE_LIST } from "../../graphicalQl/usequery/courseQuery";
import { DELETE_COURSE } from "../../graphicalQl/mutation/courseMutation";
import { toast } from "react-toastify";

interface Row2 {
  id: string;

  name: string;
  productCount: string;
}

function CourseListing() {
  const [courseList, setCourseList] = useState<Row2[]>([]);

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { t } = useTranslation();

  const { loading, error, data } = useQuery(COURSE_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  useEffect(() => {
    if (data && data.courseList) {
      const list = data.courseList.edges.map((each: any) => ({
        id: each.node.courseId,
        name: each.node.name,
        productCount: each.node.productCount,
      }));
      setCourseList(list);
    }
  }, [data]);
  const [deletemodal, setDeleteModal] = useState(false);

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
  };

  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const [deleteCourses, { loading: deleteLoader }] = useMutation(
    DELETE_COURSE,
    {
      context: { clientName: "categoryClient" },
      refetchQueries: [COURSE_LIST],
      onCompleted: () => {
        toast.success(
          <div>
            <Typography>{t("toast.removed")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      },
      onError: (error) => {
        console.error("Error:", error);
      },
    }
  );
  const handleDeleteClick = () => {
    const updatedRows = courseList.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    const courseId = updatedRows.map((each) => +each.id);
    deleteCourses({
      variables: {
        courseIds: JSON.stringify(courseId),
        businessId: +businessId,
      },
    });

    setRowSelectionModel([]);
  };

  const [courseId, setCourseId] = useState<null | number>(null);

  const handleEdit = (id: string) => {
    setOpen(true);
    setCourseId(+id);
  };

  const handleId = () => {
    setCourseId(null);
  };

  const [categoryIdValue, setCategoryIdValue] = useState("");
  const getCategoryId = (id: string) => {
    setCategoryIdValue(id);
  };
  const handleDleteByPopover = (id: string) => {
    deleteCourses({
      variables: { courseIds: JSON.stringify([+id]), businessId: +businessId },
    });
    setCategoryIdValue("");
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);
  const columns: GridColDef[] = [
    {
      field: "name",
      //@ts-ignore
      headerName: t("courses.courses"),
      flex: 1,
      headerClassName: "table-column-header",
      editable: false,
      hideSortIcons: true,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box>
            <Typography
              className="tableCellInCategory"
              sx={{ textTransform: "capitalize" }}
            >
              {params.row.name}
            </Typography>
            <Typography className="courses-table-text-para">
              {params.row.productCount} {t("menu.catageory.products")}
            </Typography>
          </Box>
        );
      },
    },

    {
      field: "product",
      headerName: "",
      width: 200,
      headerClassName: "table-column-header ",
      editable: false,
      cellClassName: "tableCell",
      disableColumnMenu: true,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <>
            {/* <CustomPopoverCell
          categoryId={params.row.categoryId}
          
          /> */}
            <IconButton
              onClick={() => {
                handleDeleteModal();
                getCategoryId(params.row.id);
              }}
              sx={{ ":hover": { backgroundColor: "#EEE" } }}
              disabled={deleteLoader ? true : false}
            >
              <DeleteIcon sx={{ color: "grey" }} />
            </IconButton>
            {/* <IconButton sx={{':hover': { backgroundColor: '#EEE' }}}>
          <ContentCopyIcon sx={{ color:"grey", }}/>
          </IconButton> */}
            <IconButton
              onClick={() => handleEdit(params.row.id)}
              sx={{ color: "grey", ":hover": { backgroundColor: "#EEE" } }}
            >
              <EditIcon />
            </IconButton>
          </>
        );
      },
    },
  ];
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell";
  };

  if (loading) return <CircularLoader />;
  //  if (error) return <p>Error : {error.message}</p>;
  // const CustomStyles = styled('div')({
  //   '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
  //     outline: 'solid #1976d2 0px !important',
  //   },
  //   '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
  //     outline: 'solid #1976d2 0px !important',
  //   },
  //   '& .MuiDataGrid-columnHeaderTitle': {

  //     fontWeight: "500 !important",

  //   },
  // });

  return (
    <Box sx={{ padding: "1%" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <Box>
          <Typography className="courses-head">
            {t("courses.courses")}
          </Typography>
          <Typography className="courses-para">
            {t("courses.courses_help_you")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            className="courses-button"
            onClick={handleOpen}
            sx={{
              textTransform: "none",
              padding: "0.6rem",
              marginRight: "0.6rem",
            }}
          >
            {t("courses.create_a_course")}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button
              className="courses-button"
              onClick={handleDeleteModal}
              sx={{ textTransform: "none", padding: "0.6rem" }}
            >
              Delete
              {/* {t("courses.delete")} */}
            </Button>
          )}
        </Box>
      </Box>
      <CreateCourse
        dailouge={open}
        handleComplete={handleClose}
        id={courseId}
        handleId={handleId}
      />
      {/* <CreateCourse dailouge={open} handleComplete={handleClose} id={courseId}/> */}

      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDleteByPopover}
        id={categoryIdValue}
        handleDeleteClick={handleDeleteClick}
        type="course"
      />
      <Box sx={{ height: "70vh", width: "100%", marginTop: "2%" }}>
        {/* <div style={{width:"100%",textAlign:"right"}}>
      <Delete onClick={handleDeleteClick}/>
      </div> */}
        <DataGrid
          rows={courseList}
          columns={columns}
          getRowClassName={handleRowClass}
          getCellClassName={handleCellClass}
          columnHeaderHeight={48}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          pageSizeOptions={[5, 15, 25]}
          checkboxSelection
          disableRowSelectionOnClick
          onRowSelectionModelChange={(
            newRowSelectionModel: GridRowSelectionModel
          ) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          sx={{
            marginRight: "20px",
            ".MuiTablePagination-displayedRows": {
              display: "none", // 👈 to hide huge pagination number
            },
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
              {
                outline: "solid #1976d2 0px !important",
              },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
            // marginLeft: "20px",
          }}
        />
      </Box>
    </Box>
  );
}
export default CourseListing;
