import { Autocomplete, Button, Paper, Typography } from "@mui/material";
import React, { useState, ChangeEvent, useEffect } from "react";
import { TextField, Box } from "@mui/material";
import "./optionsList.css";
import { useTranslation } from "react-i18next";

import { useQuery } from "@apollo/client";
// import EditIcon from '@mui/icons-material/Edit';
// import Accordion from '@mui/material/Accordion';
// import AccordionSummary from '@mui/material/AccordionSummary';
// import AccordionDetails from '@mui/material/AccordionDetails';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router";
import OptionsGridTable from "./optionTableGrid";
import CreateOptionGroup from "./createOptionGroupModule";
import {
  GET_OPTIONS_LIST_IN_OPTIONS,
  GET_OPTION_GROUP_IN_OPTIONS,
} from "../../graphicalQl/usequery/optionsQuery";
// import { DELETE_OPTIONGROUP_OPTION } from '../../graphicalQl/mutation/optionsMutation';
// import CloseIcon from '@mui/icons-material/Close';
// import { REMOVE_OPTION_FROM_OPTIONGROUP } from '../../graphicalQl/mutation/optionsMutation';
// import { ADD_OPTIONS_TO_OPTIONGROUP } from '../../graphicalQl/mutation/optionsMutation';
// import Popover from '@mui/material/Popover';
// import DeleteDailouge from './deleteDailougeOptionGroup';
import { GET_PRODUCTS_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";

// import {

//   MenuItem,
// } from '@mui/material';
// import { UPDATE_OPTION_GROUP_NAME } from '../../graphicalQl/mutation/optionsMutation';
// import ErrorModal from '../errorHandling/errorModal';
// import { t } from 'i18next';
// import SimpleSnackbar from '../utils/snackBar';
// import { toast } from 'react-toastify';
import { useSelector } from "react-redux";
import OptionGroupAccordion from "./optionGroupAccordion";
import DelayedInput from "../../utils/delayedInput";
interface Row2 {
  id: string;
  name: string;
  value: any;
}

const styles = {
  paper: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  typography: {
    color: "#333",
    fontFamily: "Poppins",
    fontSize: "112.5%",
    fontWeight: 600,
  },
  button: {
    color: "var(--base-base-100, #FEFEFE)",
    fontFamily: "Poppins",
    fontSize: "112.5%",
    fontWeight: 500,
    display: "inline-flex",
    height: "25%",
    padding: "8px 16px",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    borderRadius: "4px",
    backgroundColor: "#F38B08",
    cursor: "pointer",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#E5850B",
    },
  },
  selectBox: {
    display: "flex",
    padding: "0px 12px",
    justifyContent: "space-between",
    alignItems: "center",
    flex: "1 0 0",
    alignSelf: "stretch",
    borderRadius: "4px",
  },
};
interface Data {
  id: string;
  name: string;
  value: string;
}

interface OptionValue {
  name: string;
  optionId: string;
  isChecked: boolean;
}

interface OptionGroup {
  groupName: string;
  groupId: string;
  ids: number[];
  optionsObj: OptionValue[];
}

interface Product {
  id: string;
  name: string;
}

const Options: React.FC = () => {
  const navigate = useNavigate();
  const handleNavigate = () => {
    navigate("/option/create-option");
  };
  const { t } = useTranslation();
  const [filter, setfilter] = useState<string>(""); // You can set the initial value here

  // const handleChangeFilter = (event: SelectChangeEvent) => {
  //   setfilter(event.target.value);
  // };

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  // const [addGroup, setAddGroup] = useState<string>(''); // Initialize the state with an empty string

  // const handleAddGroup = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setAddGroup(event.target.value); // Update the state with the new value
  // };
  const [addbutton, setAddButton] = useState<boolean>(false); // Initialize the state with an empty string
  const [optionsList, setOptionsList] = useState<Row2[]>([]);

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const {
    loading,
    error,
    data: optionData,
    refetch,
  } = useQuery(GET_OPTIONS_LIST_IN_OPTIONS, {
    context: { clientName: "categoryClient" },
    variables: {
      name_Icontains: inputValue,
      productId: filter !== "" ? filter : null,
      businessId: +businessId,
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    fetchPolicy: "network-only",
  });

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(optionData?.optionList?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(optionData?.optionList?.pageInfo.startCursor || null);
  };

  useEffect(() => {
    if (optionData && optionData.optionList) {
      const updatedData = optionData.optionList.edges.map((item: any) => ({
        id: item.node.optionId,
        name: item.node.name,
        value: item.node.optionValues,
        produts: item.node.products && JSON.parse(item.node.products),
      }));
      setOptionsList(updatedData);
    }
  }, [optionData]);

  const handleAddButton = () => {
    setAddButton(!addbutton); // Update the state with the new value
  };

  const [optionGroupName, setOptionGroupName] = useState<string>("");

  const handleGroupName = (data: string) => {
    setOptionGroupName(data);
  };

  // const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP_OPTION, {
  //   onError: (error) => {
  //     // Handle any errors here, e.g., display an error message.

  //   },
  //   context: { clientName: "categoryClient" },
  //   refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS],
  //   onCompleted: (data) => {
  //     toast.success(<div >
  //       <Typography>Deleted Option Group Sucessfully</Typography>
  //     </div>,
  //       {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         className: 'custom_toast3',
  //       })
  //   }
  // });

  const [selectedData, setSelectedData] = useState<Data[]>([]);

  const handleSelectedData = (data: Data[]) => {
    setSelectedData(data);
  };

  const selectedDataIds = selectedData.map((each) => +each.id);
  const [optionGroupData, setOptionGroup] = useState<OptionGroup[]>([]);
  const { data } = useQuery(GET_OPTION_GROUP_IN_OPTIONS, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });
  const [productFromApi, setProductFromApi] = useState<Product[]>([]);
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { data: productData } = useQuery(GET_PRODUCTS_IN_OPTIONS, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: +locationId },
  });
  useEffect(() => {
    if (productData && productData.productList) {
      const productList = productData.productList.edges.map((each: any) => ({
        id: each.node.productId,
        name: each.node.name,
      }));

      setProductFromApi(productList);
    }
  }, [productData]);

  // const [groupAccordinId, setGroupAccordionId] = useState<string>("")
  // const handleDeleteAccordion = (accordionId: string) => {
  //   deleteOptionGroup({ variables: { optionGroupId: +accordionId }, refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS] })
  // };
  useEffect(() => {
    if (data) {
      if (data.optionGroupList) {
        const formatedOptionData = data.optionGroupList.edges.map(
          (each: any) => ({
            groupName: each.node.name,
            groupId: each.node.optionGroupId,
            ids:
              each.node.optionObjs &&
              each.node.optionObjs.map((item: any) => +item.optionId),
            optionsObj:
              each.node.optionObjs &&
              each.node.optionObjs.map((item: any) => ({
                name: item.name,
                optionId: item.optionId,
                isChecked: false,
              })),
          })
        );

        setOptionGroup(formatedOptionData);
      }
    }
  }, [data]);

  // const handleMouseEnter = (index: string) => {
  //   const hoverData = optionGroupData.map((each) => ({
  //     ...each,
  //     optionsObj: each.optionsObj && each.optionsObj.map((item) =>
  //       item.optionId === index ? { ...item, isChecked: true } : { ...item, isChecked: false }
  //     ),
  //   }));

  //   setOptionGroup(hoverData)
  // };
  // const handleMouseLeave = (index: string) => {
  //   const hoverData = optionGroupData.map((each) => ({
  //     ...each,
  //     optionsObj: each.optionsObj && each.optionsObj.map((item) =>
  //       item.optionId === index ? { ...item, isChecked: false } : item
  //     ),
  //   }));
  //   setOptionGroup(hoverData)
  // }

  // const [removeOption] = useMutation(REMOVE_OPTION_FROM_OPTIONGROUP, {
  //   context: { clientName: "categoryClient" },
  //   onCompleted: (data) => {
  //     toast.success(<div >
  //       <Typography>Deleted Option Sucessfully</Typography>
  //     </div>,
  //       {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         className: 'custom_toast3',
  //       })
  //   }
  // })

  // const handelDeleteOptionFromGroup = (optionGroupId: string, id: string) => {
  //   removeOption({ variables: { optionGroupId: +optionGroupId, optionId: +id }, refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS] })
  // }

  // const [addOptions] = useMutation(ADD_OPTIONS_TO_OPTIONGROUP, {
  //   context: { clientName: "categoryClient" },
  //   onCompleted: (data) => {
  //     toast.success(<div >
  //       <Typography>Added Option Sucessfully</Typography>
  //     </div>,
  //       {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         className: 'custom_toast3',
  //       })
  //   }

  // })
  // const [snackBar, setSnackBar] = useState<boolean>(false)
  // const [snackBarText, setSnackBarText] = useState<string>("")
  // const handelAddOptionsToOptiinGroup = (ids: any, optionGroupId: string, idsList: number[]) => {
  //   //write intersction of ids and idsList
  //   const intersection = ids.filter((value: any) => idsList.includes(value));
  //   if (intersection.length === 0) {
  //     setSnackBar(true)
  //     addOptions({ variables: { optionGroupId: +optionGroupId, optionIds: JSON.stringify(ids) }, refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS] })
  //     setSnackBarText("Options added to the group succesfully")
  //   }
  //   else {
  //     setSnackBar(true)
  //     if (intersection.length < ids.length) {
  //       setSnackBarText("Some of the options are already added to the group")
  //       const nonIntersection = ids.filter((value: any) => !idsList.includes(value));

  //       addOptions({ variables: { optionGroupId: +optionGroupId, optionIds: JSON.stringify(nonIntersection) }, refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS] })

  //     }
  //     if (intersection.length === ids.length) {
  //       setSnackBarText("All the options are already added to the group")
  //     }

  //   }
  // }

  // const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  // const [popoverId, setPopoverId] = useState<string>("")

  // const handleClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   setAnchorEl(event.currentTarget);
  //   // setPopoverId(idValue)
  // };

  // const handleClose = () => {
  //   setAnchorEl(null);
  // };

  // const open = Boolean(anchorEl);
  // const id = open ? popoverId : undefined;

  // const [expandedAccordion, setExpandedAccordion] = useState<string | null>(null);

  // const handleExpandIconClick = (panel: string) => (event: React.MouseEvent<{}>) => {
  //   event.stopPropagation();
  //   setExpandedAccordion((prev) => (prev === panel ? null : panel));
  // };

  // const [optionGroupNameChange] = useMutation(UPDATE_OPTION_GROUP_NAME, {
  //   context: { clientName: "categoryClient" },
  //   onCompleted: (data) => {
  //     toast.success(<div >
  //       <Typography>Updated Group Name Sucessfully</Typography>
  //     </div>,
  //       {
  //         position: 'top-right',
  //         autoClose: 5000,
  //         hideProgressBar: true,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: 'light',
  //         className: 'custom_toast3',
  //       })
  //   }

  // })

  // const handelUpdateOptionGroup = (name: String, optionGroupId: string) => {

  //   optionGroupNameChange({ variables: { optionGroupId: +optionGroupId, name: name }, refetchQueries: [GET_OPTION_GROUP_IN_OPTIONS] })
  //   setGroupNameChange("")
  // }

  const [optionsChecked, setOptionsChecked] = useState(false);

  const [deleteId, setDeleteId] = useState(false);

  const handleSetDelete = (value: boolean) => {
    setDeleteId(value);
  };

  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Paper elevation={0} style={{ width: "100%", padding: "10px" }}>
      <Box style={styles.paper}>
        <Typography
          // variant="h6" sx={styles.typography}
          className="main-head"
        >
          {t("options.option")}
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Button
            variant="contained"
            sx={styles.button}
            onClick={handleNavigate}
            style={{ marginRight: "2%" }}
          >
            {t("options.create_option")}
          </Button>
          {selectedDataIds.length > 0 && (
            <Button
              variant="contained"
              sx={styles.button}
              onClick={() => {
                handleSetDelete(true);
              }}
            >
              {t("options.delete")}
            </Button>
          )}
        </Box>
      </Box>
      <Box style={{ margin: "1%", display: "flex" }}>
        {/* <FormControl style={{ width: "14%" }} >
        <InputLabel id="demo-simple-select-label"> {t("options.filter_by")}</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={filter}
          label='Filter'
          onChange={handleChangeFilter}
          color='warning'
          style={styles.selectBox}
        >
          {menuItems}
        </Select>

      </FormControl> */}
        <Autocomplete
          id="combo-box-demo"
          options={productFromApi}
          getOptionLabel={(option) =>
            capitalizeFirstLetter(option.name.toString())
          }
          sx={{ width: "14%" }}
          // onChange={handleChangeFilter}
          onChange={(event, newValue) => {
            setfilter(newValue?.id.toString() || ""); // Update the state when the value changes
          }}
          renderInput={(params) => (
            <TextField {...params} label={t("options.filter_by")} />
          )}
        />
        {/* <TextField
          id="outlined-basic"
          label={t("menu.bundle.search")}

          variant="outlined"
          color='warning'
          value={inputValue}
          onChange={handleInputChange}
          style={{ marginLeft: "1.5%", width: "30%" }}
        /> */}
        <Box style={{ marginLeft: "1.5%", width: "30%" }}>
          <DelayedInput
            setSearchValue={setInputValue}
            placeHolder={t("users.search_by_name")}
            size={"medium"}
          />
        </Box>
      </Box>
      <CreateOptionGroup
        addbutton={addbutton}
        handleAddButton={handleAddButton}
        handleGroupName={handleGroupName}
        selectedDataIds={selectedDataIds}
      />
      {selectedData.length <= 0 ? (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            borderLeft: "2px solid var(--states-green, #00AE26)",
            background: "#F1FFF4",
            width: "100%",
            padding: "1%",
            marginTop: "1%",
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z"
              fill="#00AE26"
            />
          </svg>
          <Typography className="options_table_head">
            {" "}
            {t("options.option_para")}
          </Typography>
        </Box>
      ) : (
        <Box
          style={{
            background: "var(--prime-orange-100, #FFF8F0)",
            padding: "1%",
            marginTop: "1%",
            width: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Typography className="options_table_head" sx={{ marginLeft: "4%" }}>
            {selectedData.length} {t("options.options_select")}
          </Typography>
          <Typography className="options_table_para" sx={{ marginLeft: "20%" }}>
            {t("options.options_para2")}
          </Typography>
          <Button
            className="group-button"
            onClick={handleAddButton}
            sx={{ marginLeft: "1%", textTransform: "none" }}
          >
            {t("options.create_group")}
          </Button>
        </Box>
      )}

      <Box style={{ display: "flex", gap: "12px" }}>
        <Box style={{ width: "70%" }}>
          <OptionsGridTable
            handleSelectedData={handleSelectedData}
            optionGroupName={optionGroupName}
            optionsChecked={optionsChecked}
            inputValue={inputValue}
            filter={filter}
            deleteId={deleteId}
            handleSetDelete={handleSetDelete}
            optionsList={optionsList}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            paginationModel={paginationModel}
            setPaginationModel={setPaginationModel}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            optionData={optionData}
            setStartCursor={setStartCursor}
            setEndCursor={setEndCursor}
            loading={loading}
          />
        </Box>

        <Box
          sx={{
            padding: "1%",
            width: "30%",
            height: "100%",
            backgroundColor: "#FEFEFE",
            mt: "24px",
          }}
        >
          <OptionGroupAccordion
            selectedDataIds={selectedDataIds}
            optionsChecked={optionsChecked}
            setOptionsChecked={setOptionsChecked}
          />
        </Box>
        {/* <DeleteDailouge deletemodal={deletemodal} handleDleteByPopover={handleDeleteAccordion} id={groupAccordinId} handleDeleteModal={handleDeleteModal} /> */}

        {/* <SimpleSnackbar snackBar={snackBar} setSnackBar={setSnackBar} snackBarText={snackBarText} /> */}
      </Box>
    </Paper>
  );
};

export default Options;
