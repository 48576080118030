import MainLayout from "../pages/common/layouts/mainLayout";
import SignIn from "../pages/user-authentication/SignIn";
import SignUp from "../pages/user-authentication/SignUp";
import SignInOld from "../pages/loginFolders/signIn";
import WithOutMainLayout from "../pages/common/layouts/withOutMainLayout";
import BusinessOnboarding from "../pages/businessOnboarding";
import SetupGuide from "../pages/SetupGuide";
import Profile from "../pages/profile";
import MainDashboard from "../pages/mainDashboard";
import MenuListing from "../pages/menu/menuListing";
import SideProfile2 from "../pages/profile/profileInDashBoard";

import CreateUser from "../pages/userManagement/createUser.tsx";
import EditZone from "../pages/product/editZone.tsx";
import UserList from "../pages/userManagement/userList.tsx";
// import AssignOptionsToProducts from '../pages/options/assignOptionsToProducts.tsx';
import AssignOptionsToProduct from "../pages/product/assignOptionsToproduct.tsx";
import CustomerGroups from "../pages/customerGroups/customerGroups.jsx";
import CreateGroup from "../pages/customerGroups/createGroup.jsx";
import EditCustomerGroups from "../pages/customerGroups/editCustomerGroup.jsx";
import Customers from "../pages/customers/customers.jsx";
import CreateCustomer from "../pages/customers/createCustomer.jsx";
import ImportCustomers from "../pages/customers/importCustomers.jsx";
import CustomerProfile from "../pages/customers/customerProfile.tsx";
import EditCustomer from "../pages/customers/editCustomer.jsx";
import AddAddress from "../pages/customers/addAddress.jsx";
import EditAddress from "../pages/customers/editAddress.jsx";
import LocationList from "../pages/locations/locationsList.tsx";
import CreateLocation from "../pages/locations/createLocation.jsx";
import AboutMyBusiness from "../pages/aboutBusiness/aboutMyBusiness.tsx";
import CreateOptions from "../pages/options/createOptions.tsx";
import SalesTax from "../pages/accountSettings/salesTax.tsx";
import CreateSalesTax from "../pages/accountSettings/createSalesTax.tsx";
import Rounding from "../pages/accountSettings/rounding.tsx";
import ServiceCharges from "../pages/accountSettings/serviceCharges.tsx";
import CreateServiceCharge from "../pages/accountSettings/createServiceCharge.tsx";
import AdvanceBooking from "../pages/accountSettings/advance_booking.tsx";
import CreateAdvanceBooking from "../pages/accountSettings/createAdvanceBooking.tsx";
import ManageTips from "../pages/accountSettings/manageTips.tsx";
import ManagingOrders from "../pages/accountSettings/managingOrders.tsx";
import OpenItems from "../pages/accountSettings/openItems.tsx";
import LoyalityPoints from "../pages/accountSettings/loyalityPoints.tsx";
import RefundCancel from "../pages/accountSettings/refunds&cancel.tsx";
import ColorDiagram from "../pages/accountSettings/colorPicker.jsx";
import CreateDiscountExactQty from "../pages/discount/createDiscountExactQty.tsx";
import CreateDiscountBuy1Get from "../pages/discount/createDiscountBuy1Get.tsx";
import CreateDiscount from "../pages/discount/createDiscount.tsx";
import DiscountSelfMade from "../pages/discount/discountSelfListing.tsx";
import DiscountPreMade from "../pages/discount/discountPreMadeDiscount.tsx";
import DiscountReason from "../pages/discount/discountReason.tsx";
import CustomizedSwitches from "../pages/accountSettings/antSwitch.jsx";
import Receipts from "../pages/accountSettings/reciepts.jsx";
import UserRole from "../pages/userManagement/userRole.tsx";
import CreateUserRole from "../pages/userManagement/createUserRole.tsx";
import UserAccess from "../pages/userManagement/userAccess.tsx";
import UserRequests from "../pages/userManagement/userRequests.tsx";
import InventoryLists from "../pages/inventory/inventoryListMain.tsx";
import EditInventory from "../pages/inventory/editInventory.tsx";
import DriverList from "../pages/deliveryListing/driverList.tsx";
import StockAdjustments from "../pages/inventory/stockAdjustments.tsx";
import RecieptBuilder from "../pages/reciepts/recieptBuilder.tsx";
import StockAdjustmentReasons from "../pages/inventory/stockAdjustmentReasons.tsx";
import TemporaryDrawer from "../pages/leftMenuBar/drawer.tsx";
import DriverCommission from "../pages/deliveryListing/driverCommission.tsx";
import ProductTable from "../pages/options/productTable.tsx";
import DriverPettyCash from "../pages/deliveryListing/driverPettyCash.tsx";
import Options from "../pages/options/optionsList.tsx";
import DeliveryList from "../pages/deliveryListing/deliveryList.tsx";
import DeliveryZone from "../pages/deliveryListing/deliveryZone.tsx";
import CategoryListing from "../pages/category/categoryListing.tsx";
import AreaWise from "../pages/deliveryListing/areaWise.tsx";
// import BulkProduct from "../pages/options/BulkProducts.tsx";
import DistanceOrZoneWise from "../pages/deliveryListing/distanceOrZoneWise.tsx";
// import MenuSetup from '../pages/menu/MenuSetup.1.tsx';
import MenuSetup from "../pages/menu/menuSetup.tsx";
import DeviceSetUp from "../pages/deviceSetUp/deviceSetUp.tsx";
import DeviceListing from "../pages/deviceSetUp/deviceListing.tsx";
import KdsSetupCreate from "../pages/deviceSetUp/kdsSetupCreate.tsx";
import KdsEditAssignedItems from "../pages/deviceSetUp/kdsEditAssignedItems.tsx";
import MenuHistory from "../pages/menu/menuHistory.tsx";
import BundleListing from "../pages/bundle/bundleListing.tsx";
import CreateBundle from "../pages/bundle/createBundle.tsx";
// import BundleDragDrop from "../pages/bundle/bundleDragDrop.tsx";
import KdsSettings from "../pages/deviceSetUp/kdsSettings.tsx";
import PosSettings from "../pages/deviceSetUp/posSettings.tsx";
import CartCrossSellList from "../pages/suggestiveSelling/cartCrossSellList.tsx";
import CartCrossSellingCreate from "../pages/suggestiveSelling/cartCrossSellingCreate.tsx";

import ItemUpSelling from "../pages/suggestiveSelling/itemUpSellList.tsx";
import ItemCrossSelling from "../pages/suggestiveSelling/itemCrossSellList.tsx";
import KitchenCapacityQuotingTime from "../pages/deviceSetUp/kitchenCapacityQuotingTime.tsx";
import ItemCrossSellingCreate from "../pages/suggestiveSelling/itemCrossSellingCreate.tsx";
import ItemUpSellingCreate from "../pages/suggestiveSelling/itemUpSellingCreate.tsx";
import CategoryMain from "../pages/category/createCategoryMain.tsx";
import ProductsList from "../pages/category/productList.tsx";
import Protected from "../pages/protectedRoute/protectedRoute.tsx";
import CreateAproduct from "../pages/product/createProduct.tsx";
import CreateLocationByHari from "../pages/locations/createLocationByHari.tsx";
import { Outlet } from "react-router-dom";
import ReceiptListing from "../pages/reciepts/recieptListing.tsx";
import Header from "../pages/header/header.tsx";
import HeaderTop from "../pages/header/headerTop.tsx";
// import TestingMaps from "../pages/locations/testingMaps.tsx";
import { Box } from "@mui/material";
import CourseListing from "../pages/course/courseListing.tsx";
import ReportListing from "../pages/ReportiongGroup/reportGroupListing.tsx";
import ImageDrawer from "../pages/menu/image/imageDrawer.tsx";
import ErrorModal from "../pages/errorHandling/errorModal.tsx";
import BulkProduct2 from "../pages/options/bulkNewTable.tsx";
import CreateLocationChangedFormat from "../pages/locations/createLocationChangedFormat.tsx";
import WeeklyStatus from "../pages/locations/weekklyStatus.tsx";
import EposDiscount from "../pages/discount/eposDiscount.tsx";
import InventoryHistory from "../pages/inventory/inventoryHistory";
import PosSettingsGeneralSettings from "../pages/deviceSetUp/posSettingsGeneralSettings.tsx";
import KioskSettings from "../pages/deviceSetUp/kioskSettings.tsx";
import Orders from "../pages/screens/orders/index.tsx";
import OrderSummary from "../pages/screens/orderSummary";
import MenuCreate from "../pages/menu/menuCreate.tsx";
// import CreateProduct from "../pages/options/createProduct.tsx";
import Image from "../pages/menu/image/image .tsx";
import CustomersReportPage from "../pages/reports/customersReport";
import StoresReportPage from "../pages/reports/storesReport";
import SalesReportPage from "../pages/reports/salesReport";
import OrdersReportPage from "../pages/reports/ordersReport";
import ProductKds from "../pages/product/productKds.tsx";
import CustomerListing from "../components/customerListing/index.tsx";
import BusinessAccount from "../pages/businessSettings/businessAccount.tsx";
import Backups from "../pages/businessSettings/backups.tsx";
import RegisteredComplaints from "../pages/businessSettings/registeredComplaints.tsx";
import SignInAndSecurity from "../pages/businessSettings/signInAndSecurity.tsx";
import FeatureSettings from "../pages/businessSettings/featureSetting.tsx";
import Preferences from "../pages/businessSettings/preferences.tsx";
import CallerId from "../pages/businessSettings/callerId.tsx";
import ClockInAndOut from "../pages/businessSettings/clockInAndOut.tsx";
import LogTimeEntries from "../pages/businessSettings/logTimeEntries.tsx";
import OrderStatus from "../pages/businessSettings/orderStatus.tsx";
import OrderNumbering from "../pages/businessSettings/orderNumbering.tsx";
import GeneralSettingsFromDevice from "../pages/deviceSetUp/generalSettingsFromDevice.tsx";
import SpecialsListing from "../pages/specials/categoryListing.tsx";
import ApiKeys from "../pages/apiKeys/apiKeys.tsx";
import ProductsReportPage from "../pages/reports/productReports.tsx";
import PaymentReportPage from "../pages/reports/paymentReports";
import CategoriesReportPage from "../pages/reports/categoryReports";
import DeliveryReportPage from "../pages/reports/deliveryReports";
import DiscountsReportPage from "../pages/reports/discountReports";
import EmployeeReportPage from "../pages/reports/employeeReports";
import TaxReportPage from "../pages/reports/taxReports";
import ActivityLogReportPage from "../pages/reports/activityLogReport";
import ReceiptReportPage from "../pages/reports/receiptReports";
import InternalServerError from "../graphiqlErrors/500Error.tsx";
import BadRequestError from "../graphiqlErrors/400Error.tsx";
import ServiceUnavaible from "../graphiqlErrors/503Error.tsx";
import PermissionsDenied from "../graphiqlErrors/403Error.tsx";
import NotFoundError from "../graphiqlErrors/404Error.tsx";
import ReviewsAndRatings from "../pages/reviews&ratings/index.tsx";
import DiscountHistory from "../pages/discount/history.tsx";
// import GenerateQrCode from "../pages/menu/genarateQrcode.tsx";
import DigitalMenu from "../pages/menu/digitalMenu.tsx";
import SetUpOnline from "../pages/onlineOrdering/setupOnline.tsx";
import GetStart from "../pages/onlineOrdering/getStarted.tsx";
import OnlineOderingSettings from "../pages/onlineOrdering/settings.tsx";
import CustomizeDomain from "../pages/onlineOrdering/customizeDomain.tsx";
import DomainsList from "../pages/onlineOrdering/domainsList.tsx";

// import SignUp from "../pages/businessOnboarding/signUp.tsx";

const routes = [
  {
    layout: MainLayout,
    name: "Main Layout",
    routes: [
      {
        path: "/setupGuide",
        name: "setupGuide",
        component: SetupGuide,
      },
      {
        path: "/profile",
        name: "profile",
        component: Profile,
      },
      {
        path: "/",
        name: "Dashboard",
        component: MainDashboard,
      },
      {
        path: "/menu/menu-list",
        name: "Menu List",
        component: MenuListing,
      },
      {
        path: "/menuSetup/menu-setup",
        name: "Menu Setup",
        component: MenuSetup,
      },
      {
        path: "/users/users-list",
        name: "User List",
        component: UserList,
      },
      {
        path: "/roles/user-roles",
        name: "User Roles",
        component: UserRole,
      },
      {
        path: "/access/user-access",
        name: "User Access",
        component: UserAccess,
      },
      {
        path: "/requests/user-requests",
        name: "User Requests",
        component: UserRequests,
      },
      {
        path: "/users/create-user",
        name: "Create user",
        component: CreateUser,
      },
      {
        path: "/users/:editId/update-user",
        name: "Create user",
        component: CreateUser,
      },
      {
        path: "/roles/:editId/update-role",
        name: "Create user",
        component: CreateUserRole,
      },
      {
        path: "/roles/create-role",
        name: "Create user",
        component: CreateUserRole,
      },
      {
        path: "/inventory/inventory-list",
        name: "Inventory List",
        component: InventoryLists,
      },
      {
        path: "/reasons/inventory-reasons",
        name: "Inventory Reasons",
        component: StockAdjustmentReasons,
      },
      {
        path: "/history/inventory-history",
        name: "Inventory History",
        component: InventoryHistory,
      },
      {
        path: "inventory/stock-adjustments",
        name: "Stock Adjustments",
        component: StockAdjustments,
      },
      {
        path: "/delivery/delivery-list",
        name: "Delivery Lists",
        component: DeliveryList,
      },
      {
        path: "/drivers/driver-list",
        name: "Driver Lists",
        component: DriverList,
      },
      {
        path: "/petty/petty-cash",
        name: "Driver Petty Cash",
        component: DriverPettyCash,
      },
      {
        path: "/driver/driver-commission",
        name: "Driver Commission",
        component: DriverCommission,
      },
      {
        path: "device/device-setup",
        name: "Device Setup",
        component: DeviceSetUp,
      },
      {
        path: "devices/device-list",
        name: "Device List",
        component: DeviceListing,
      },
      {
        path: "/product/product-list",
        name: "Product",
        component: ProductTable,
      },
      {
        path: "/product/create-product",
        name: "Product",
        component: CreateAproduct,
      },
      {
        path: "/product/:product/update-product",
        name: "Product",
        component: CreateAproduct,
      },
      {
        path: "/category/category-listing",
        name: "Category Listing",
        component: CategoryListing,
      },
      {
        path: "/category/create-category",
        name: "Create Category",
        component: CategoryMain,
      },
      {
        path: "/category/:id/update-category",
        name: "Update Category",
        component: CategoryMain,
      },
      {
        path: "/option/options-list",
        name: "Options",
        component: Options,
      },
      {
        path: "/option/create-option",
        name: "Create Option",
        component: CreateOptions,
      },
      {
        path: "/option/:id/update-option",
        name: "Update Option",
        component: CreateOptions,
      },
      {
        path: "bundle/bundle-listing",
        name: "Bundle List",
        component: BundleListing,
      },
      {
        path: "/bundle/create-bundle",
        name: "Create Bundle",
        component: CreateBundle,
      },
      {
        path: "/bulk/product",
        name: "Bulk Product",
        component: BulkProduct2,
      },
      {
        path: "/menu/:id/menu-history",
        name: "Menu History",
        component: MenuHistory,
      },
      {
        path: "/bundle/:id/update-bundle",
        name: "Update Bundle",
        component: CreateBundle,
      },

      {
        path: "report/report-group-listing",
        name: "Report Group",
        component: ReportListing,
      },
      {
        path: "course/courses-listing",
        name: "Courses List",
        component: CourseListing,
      },
      {
        path: "/discount/discount-list",
        name: "Discount Selfmade",
        component: DiscountSelfMade,
      },
      {
        path: "/discount/discount",
        name: "Create Discount",
        component: CreateDiscount,
      },
      {
        path: "/discount/create-discount",
        name: "Create Dicounts",
        component: CreateDiscountBuy1Get,
      },
      {
        path: "/discount/:id/update-discounts",
        name: "Update Dicounts",
        component: CreateDiscountBuy1Get,
      },
      {
        path: "discounts/preMade-list",
        name: "Premade Dicounts",
        component: DiscountPreMade,
      },
      {
        path: "/reasons/discount-reasons",
        name: "Dicounts Reasons",
        component: DiscountReason,
      },
      {
        path: "/reciept/reciept-list",
        name: "Receipts List",
        component: ReceiptListing,
      },
      {
        path: "reciept/reciept-builder",
        name: "Receipts Builder",
        component: RecieptBuilder,
      },
      {
        path: "reciept/:id/update-reciept-builder",
        name: "Receipts Builder",
        component: RecieptBuilder,
      },
      {
        path: "/service/charge",
        name: "Service Charge",
        component: ServiceCharges,
      },
      {
        path: "service/create-service-charge",
        name: " Create Service Charge",
        component: CreateServiceCharge,
      },
      {
        path: "service/:id/update-service-charge",
        name: " Create Service Charge",
        component: CreateServiceCharge,
      },
      {
        path: "sales/tax",
        name: " Sales Tax",
        component: SalesTax,
      },
      {
        path: "/sales/create-sales-tax",
        name: " Create Sales Tax",
        component: CreateSalesTax,
      },
      {
        path: "sales/:id/update-sales-tax",
        name: " Create Sales Tax",
        component: CreateSalesTax,
      },
      {
        path: "/rounding",
        name: " Rounding",
        component: Rounding,
      },
      {
        path: "/refund/cancel",
        name: " Refunds & Cancel",
        component: RefundCancel,
      },
      {
        path: "booking/advance-booking",
        name: " Advance Booking",
        component: AdvanceBooking,
      },
      {
        path: "booking/:id/update-advance-booking",
        name: "Create  Advance Booking",
        component: CreateAdvanceBooking,
      },
      {
        path: "manage/manage-tips",
        name: " Manage Tips",
        component: ManageTips,
      },
      {
        path: "managingOrder/manage-orders",
        name: " Managing Orders",
        component: ManagingOrders,
      },
      {
        path: "open/items",
        name: " Open Items",
        component: OpenItems,
      },
      {
        path: "/loyality",
        name: " Loyality Points",
        component: LoyalityPoints,
      },
      {
        path: "/editZone",
        name: "Edit Zone",
        component: EditZone,
      },
      {
        path: "/inventory/:editId/update-inventory",
        name: "Edit Inventory",
        component: EditInventory,
      },
      {
        path: "/:editId/kds/setup/create",
        name: "Kds Setup",
        component: KdsSetupCreate,
      },
      {
        path: "devices/assigning-products-to-kds",
        name: "Kds edit",
        component: KdsEditAssignedItems,
      },
      {
        path: "/devices/:editId/update-kds",
        name: "Kds Settings",
        component: KdsSettings,
      },
      {
        path: "/assign/options/to/product",
        name: "assign oprions to product",
        component: AssignOptionsToProduct,
      },
      {
        path: "/devices/:editId/update-pos",
        name: "Pos Edit",
        component: PosSettingsGeneralSettings,
      },
      {
        path: "/devices/:editId/update-kiosk",
        name: "kiosk settings",
        component: KioskSettings,
      },
      {
        path: "/location/:editId/update-location",
        name: "Update Location",
        component: CreateLocationChangedFormat,
      },
      {
        path: "/location/create-location",
        name: "Create Location",
        component: CreateLocationChangedFormat,
      },
      {
        path: "/location/locations-list",
        name: "Location List",
        component: LocationList,
      },
      {
        path: "/:editId/delivery/zone",
        name: "Delivery Zone",
        component: DeliveryZone,
      },

      {
        path: "orders/orders-list",
        name: "Orders",
        component: Orders,
      },
      {
        path: "/orders/:id/order-summary",
        name: "Orders Summary",
        component: OrderSummary,
      },
      {
        path: "/menu/create-menu",
        name: "Menu Create",
        component: MenuCreate,
      },
      {
        path: "menu/:id/menu-edit",
        name: "Menu Edit",
        component: MenuCreate,
      },
      {
        path: "/product/kds",
        name: "product kds",
        component: ProductKds,
      },
      {
        path: "image/image-library",
        name: "image",
        component: Image,
      },
      {
        path: "/customers/reports-customers",
        name: "Customers Report",
        component: CustomersReportPage,
      },
      {
        path: "/stores/reports-stores",
        name: "Stores Report",
        component: StoresReportPage,
      },
      {
        path: "/sale/reports-sales",
        name: "Sales Report",
        component: SalesReportPage,
      },
      {
        path: "/order/reports-orders",
        name: "Orders Report",
        component: OrdersReportPage,
      },
      {
        path: "customer/customer-list",
        name: "customerListing",
        component: CustomerListing,
      },
      {
        path: "customer/:id/customerProfile",
        name: "customerProfile",
        component: CustomerProfile,
      },
      {
        path: "/addAddress",
        name: "EditAddress",
        component: AddAddress,
      },
      {
        path: "/editAddress",
        name: "EditAddress",
        component: EditAddress,
      },
      {
        path: "/businessAccount",
        name: "businessAccount",
        component: BusinessAccount,
      },
      {
        path: "/backups",
        name: "backups",
        component: Backups,
      },
      {
        path: "/registeredComplaints",
        name: "registeredComplaints",
        component: RegisteredComplaints,
      },
      {
        path: "/safety/signIn&&Security",
        name: "signInAndSecurity",
        component: SignInAndSecurity,
      },
      {
        path: "/featureSettings",
        name: "featureSettings",
        component: FeatureSettings,
      },
      {
        path: "/preferences",
        name: "preferences",
        component: Preferences,
      },
      {
        path: "/callerId",
        name: "callerId",
        component: CallerId,
      },
      {
        path: "/clockInAndOut",
        name: "clockInAndOut",
        component: ClockInAndOut,
      },
      {
        path: "/log/log-time-entries",
        name: "logTimeEntries",
        component: LogTimeEntries,
      },
      {
        path: "/status/order-status",
        name: "orderStatus",
        component: OrderStatus,
      },
      {
        path: "numbering/order-numbering",
        name: "orderNumbering",
        component: OrderNumbering,
      },
      {
        path: "/profileTab",
        name: "Profile",
        component: SideProfile2,
      },

      {
        path: "/epos/eposdiscount",
        name: "Epos Discount",
        component: EposDiscount,
      },
      {
        path: "/kitchen/kitchen-settings",
        name: "Kitchen Settings",
        component: GeneralSettingsFromDevice,
      },
      {
        path: "special/specials-list",
        name: "Specials Listing",
        component: SpecialsListing,
      },
      {
        path: "apiKeys/api-keys",
        name: "apiKeys",
        component: ApiKeys,
      },
      {
        path: "/edit",
        name: "Edit",
        component: EditZone,
      },

      // {
      //   path: "/qrCode",
      //   name: "qrCode",
      //   component: GenerateQrCode,
      // },

      {
        path: "/receipts/reports-receipts",
        name: "Customers Report",
        component: ReceiptReportPage,
      },

      {
        path: "/products/reports/products",
        name: "Products Report",
        component: ProductsReportPage,
      },
      {
        path: "/payments/reports-payments",
        name: "Payments Report",
        component: PaymentReportPage,
      },
      {
        path: "/categories/reports-categories",
        name: "Category Report",
        component: CategoriesReportPage,
      },
      {
        path: "/delivery/reports-delivery",
        name: "Delivery Report",
        component: DeliveryReportPage,
      },
      {
        path: "/discountReport/reports-discount",
        name: "Discount Report",
        component: DiscountsReportPage,
      },

      {
        path: "/employee/reports-employee",
        name: "Employee Report",
        component: EmployeeReportPage,
      },
      {
        path: "/tax/reports-tax",
        name: "Tax Report",
        component: TaxReportPage,
      },
      {
        path: "/activityLog/reports/activityLog",
        name: "Activity Log",
        component: ActivityLogReportPage,
      },
      {
        path: "/discount/:id/discount-history",
        name: "Discount History",
        component: DiscountHistory,
      },
      {
        path: "/menu/:id/digital-menu",
        name: "Digital Menu",
        component: DigitalMenu,
      },
      {
        path: "/online/ordering-setUp",
        name: "order setup",
        component: SetUpOnline,
      },
      {
        path: "/online/start-setUp",
        name: "order start",
        component: GetStart,
      },
      {
        path: "/online/order-settings",
        name: "order settings",
        component: OnlineOderingSettings,
      },
      {
        path: "/online/order-customize-domain",
        name: "order settings",
        component: CustomizeDomain,
      },
      {
        path: "/online/doamins-listing",
        name: "domains listing",
        component: DomainsList,
      },
      {
        path: "/reviews/ratings",
        name: "Reviews and Ratings",
        component: ReviewsAndRatings,
      },
      {
        path: "/internal-server-error",
        name: "Server Error",
        component: InternalServerError,
      },
      {
        path: "/bad/request",
        name: "Server Error",
        component: BadRequestError,
      },

      {
        path: "/service-unavailable",
        name: "Server Error",
        component: ServiceUnavaible,
      },

      {
        path: "/permission-denied",
        name: "Server Error",
        component: PermissionsDenied,
      },

      {
        path: "*",
        name: "Server Error",
        component: NotFoundError,
      },
    ],
  },

  {
    layout: WithOutMainLayout,
    name: "with Out MainLayout",
    routes: [
      {
        path: "/sign-in",
        name: "SignIn",
        component: SignIn,
      },
      // {
      //   path: "/sign-up",
      //   name: "SignUp",
      //   component: SignUp,
      // },

      // {
      //   path: "/businessOnboarding",
      //   name: "businessOnboarding",
      //   component: BusinessOnboarding,
      // },
    ],
  },
];

export default routes;
