import React from "react";

import { GoogleMap, MarkerF, useJsApiLoader } from "@react-google-maps/api";

import { useEffect } from "react";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { useQuery } from "@apollo/client";
import { GETTING_API_KEYS } from "../../graphicalQl/usequery/locationQuery";

interface mapsState {
  height: string;
  width: string;
  formik: any;
  locationKey: string | null;
}

const GoogleMapForDistance = (props: mapsState) => {
  const { height, width, locationKey } = props;
  const containerStyle = {
    height: height,
    width: width,
  };

  const { formik } = props;
  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: locationKey !== null ? locationKey : "",
  });

  const searchLatAndLngByAddress = (address: string) => {
    // Check if the Google Maps API is available
    if (!window.google || !window.google.maps || !window.google.maps.Geocoder) {
      console.error("Google Maps API not available");
      return;
    }

    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ address: address }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        const latitude = results && results[0].geometry.location.lat();
        const longitude = results && results[0].geometry.location.lng();
        formik.setFieldValue("lattitudeAndLongitude", {
          lat: latitude,
          lng: longitude,
        });
      } else if (status === window.google.maps.GeocoderStatus.ZERO_RESULTS) {
        console.error(
          "Geocode was not successful. No results found for the provided address."
        );
      } else if (status === window.google.maps.GeocoderStatus.REQUEST_DENIED) {
        console.error(
          "Geocode request was denied. Check your API key or quota limits."
        );
      } else if (status === window.google.maps.GeocoderStatus.INVALID_REQUEST) {
        console.error(
          "Geocode request was invalid. Check your request parameters."
        );
      } else {
        console.error(
          "Geocode was not successful for the following reason:",
          status
        );
      }
    });
  };

  // Assuming searchLatAndLngByPlaceId remains unchanged

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.postCode}&key=${locationKey}`
        );
        const data = await response.json();
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();

    const loadGoogleMapsScript = () => {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${locationKey}&sensor=false&callback=initMap`;
      script.async = true;
      script.onload = () => {
        // Once the script is loaded, call the function that uses the Google Maps API
        searchLatAndLngByAddress(
          `${formik.values.address1},${formik.values.city},${formik.values.postCode}`
        );
        // Example using placeId (you need to get the placeId from the Google Places API)
        // searchLatAndLngByPlaceId("Your Place ID");
      };
      document.head.appendChild(script);
    };

    // Check if Google Maps API is already loaded
    if (!window.google || !window.google.maps) {
      loadGoogleMapsScript();
    } else {
      // Call the function directly if the API is already loaded
      searchLatAndLngByAddress(
        `${formik.values.address1},${formik.values.city},${formik.values.postCode}`
      );
      // Or use placeId if you have it
      // searchLatAndLngByPlaceId("Your Place ID");
    }

    return () => {
      // Clean up script tag when component unmounts
      const scriptTag = document.getElementById("google-maps-api");
      if (scriptTag) {
        scriptTag.parentNode?.removeChild(scriptTag);
      }
    };
  }, [formik.values.address1, formik.values.postCode, formik.values.city]);

  const handleMarkerDragEnd = async (e: any) => {
    const { latLng } = e;
    const latitude = latLng.lat();
    const longitude = latLng.lng();
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${locationKey}`
    );
    if (response.ok) {
      const data = await response.json();
      const getAddressComponent = (type: any) => {
        const component = data.results[0].address_components.find((c: any) =>
          c.types.includes(type)
        );
        return component ? component.long_name : "";
      };

      // const address1 = getAddressComponent("route");
      const address2 = getAddressComponent("sublocality_level_1");
      const city = getAddressComponent("locality");
      const street = getAddressComponent("street_number");
      const state = getAddressComponent("administrative_area_level_1");
      const pincode = getAddressComponent("postal_code");
      const country = getAddressComponent("country");

      const { geometry } = data && data.results[0];
      const { location } = geometry && geometry;

      formik.setValues({
        ...formik.values,
        address2: address2,
        city: city,
        country: country,
        street: street,
        state: state,
        postCode: pincode,
        lattitudeAndLongitude: {
          lat: location.lat,
          lng: location.lng,
        },
      });
    }
  };
  const calculateZoomLevel = (meters: any) => {
    const MAX_ZOOM = 16; // Maximum available zoom level
    const MIN_ZOOM = 0; // Minimum available zoom level

    const zoom = MAX_ZOOM - Math.floor(Math.log2(meters / 260)); // Adjust the divisor as needed

    return Math.max(Math.min(zoom, MAX_ZOOM), MIN_ZOOM);
  };
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          fetch(
            `https://api.openweathermap.org/data/2.5/weather?lat=${position.coords.latitude}&lon=${position.coords.longitude}&appid=${locationKey}&units=metric`
          )
            .then((response) => response.json())

            .then((data) => {
            })
            .catch((error) => console.log(error));
          formik.setFieldValue("lattitudeAndLongitude", {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);

  // const handleOnclickFunction = () => {
  //   if (navigator.geolocation) {
  //     navigator.geolocation.getCurrentPosition(
  //       (position) => {
  //         formik.setFieldValue("lattitudeAndLongitude", {
  //           lat: position.coords.latitude,
  //           lng: position.coords.longitude,
  //         });
  //       },
  //       (error) => {
  //         console.error("Error getting current location:", error);
  //       }
  //     );
  //   } else {
  //     console.error("Geolocation is not supported by this browser.");
  //   }
  // };

  const handleOnclickFunction = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const { latitude, longitude } = position.coords;
          formik.setFieldValue("lattitudeAndLongitude", {
            lat: latitude,
            lng: longitude,
          });

          function getCurrentLocation(addressComponents: any) {
            let currentLocation = "";

            const lanmarkComponrnt = addressComponents.find((component: any) =>
              component.types.includes("landmark")
            );
            if (lanmarkComponrnt) {
              currentLocation += lanmarkComponrnt.long_name + ", ";
            }

            // Look for the locality (city)
            const localityComponent = addressComponents.find(
              (component: any) =>
                component.types.includes("political") ||
                component.types.includes("sublocality") ||
                component.types.includes("sublocality_level_2")
            );

            if (localityComponent) {
              currentLocation += localityComponent.long_name + ", ";
            }

            const cityComponent = addressComponents.find((component: any) =>
              component.types.includes("locality")
            );

            if (cityComponent) {
              currentLocation += cityComponent.long_name + ", ";
            }

            const stateComponent = addressComponents.find((component: any) =>
              component.types.includes("administrative_area_level_1")
            );
            if (stateComponent) {
              currentLocation += stateComponent.long_name + ", ";
            }

            // If neither city nor state is available, use country

            const countryComponent = addressComponents.find((component: any) =>
              component.types.includes("country")
            );
            if (countryComponent) {
              currentLocation += countryComponent.long_name + ", ";
            }

            const postalCodeComponent = addressComponents.find(
              (component: any) => component.types.includes("postal_code")
            );
            if (postalCodeComponent) {
              currentLocation += postalCodeComponent.long_name;
            }

            return currentLocation;
          }

          try {
            // Fetch address using reverse geocoding
            const response = await fetch(
              `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${locationKey}`
            );
            const data = await response.json();
            if (data) {
              if (data.status === "OK") {
                const { results } = data;
                if (results) {
                  const { address_components } = results[0];
                  const currentLocation =
                    getCurrentLocation(address_components);

                  formik.setFieldValue("address2", currentLocation);
                  const extractCityAndState = (addressComponents: any) => {
                    let city = "";
                    let state = "";
                    let postalCode = "";
                    let country = "";

                    addressComponents.forEach((component: any) => {
                      if (
                        component.types.includes("postal_town") ||
                        component.types.includes("locality")
                      ) {
                        city = component.long_name;
                      } else if (
                        component.types.includes("administrative_area_level_1")
                      ) {
                        state = component.long_name;
                      } else if (component.types.includes("postal_code")) {
                        postalCode = component.long_name;
                      } else if (component.types.includes("country")) {
                        country = component.long_name;
                      }
                    });

                    return { city, state, postalCode, country };
                  };

                  const { city, state, postalCode, country } =
                    extractCityAndState(address_components);
                  formik.setFieldValue("city", city);
                  formik.setFieldValue("state", state);
                  formik.setFieldValue("postCode", postalCode);
                  formik.setFieldValue("country", country);
                }
              }
            }
          } catch (error) {
            console.error("Error getting address:", error);
          }
        },
        (error) => {
          console.error("Error getting current location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  return locationKey !== null ? (
    isLoaded && formik.values.lattitudeAndLongitude ? (
      <Box sx={{ position: "relative" }}>
        <div
          style={{
            position: "absolute",
            bottom: "10px",
            left: "10px",
            zIndex: 1,
          }}
        >
          <Button
            onClick={handleOnclickFunction}
            sx={{
              backgroundColor: "#d9d9d9",
              ":hover": "black",
              textTransform: "none",
              fontFamily: "poppins",
              fontWeight: 550,
              fontSize: "1rem",
            }}
            color="warning"
          >
            <IconButton>
              <MyLocationIcon color="warning" />
            </IconButton>
            Current Location
          </Button>
        </div>

        <GoogleMap
          mapContainerStyle={containerStyle}
          center={formik.values.lattitudeAndLongitude}
          zoom={calculateZoomLevel(400)}
        >
          <MarkerF
            position={formik.values.lattitudeAndLongitude}
            draggable={true}
            onDragEnd={handleMarkerDragEnd}
          />
        </GoogleMap>
      </Box>
    ) : (
      <div style={{ color: "red" }}>Loading</div>
    )
  ) : (
    <Box className="dummy-map-image-container">
      <Box>
        <Typography className="dummy-map-image-text-order">
          We are unable to provide Google Map features because the location key
          is missing.
        </Typography>
      </Box>
    </Box>
  );
};

export default GoogleMapForDistance;
