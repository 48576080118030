import React, { useEffect, useState } from "react";
import {
  Paper,
  Divider,
  Chip,
  IconButton,
  Box,
  Button,
  Drawer,
} from "@mui/material";
import Typography from "@mui/material/Typography";

import { useTranslation } from "react-i18next";

import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import { useNavigate, useParams } from "react-router-dom";
import CardActions from "@mui/material/CardActions";

import { CUSTOMER_DATA } from "../../graphicalQl/usequery/customerDataQuery";
import { useMutation, useQuery } from "@apollo/client";
import AddressDrawer from "./addressDrawer";
import { ADDRESS_LIST } from "../../graphicalQl/usequery/customerDataQuery";
import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import LocalPhoneOutlinedIcon from "@mui/icons-material/LocalPhoneOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import BusinessCenterOutlinedIcon from "@mui/icons-material/BusinessCenterOutlined";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import ViewComfyOutlinedIcon from "@mui/icons-material/ViewComfyOutlined";
import TodayOutlinedIcon from "@mui/icons-material/TodayOutlined";
import PlusIcon from "../../assets/plusIcon.png";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import CustomerTabs from "./customerProfileTab";
import "./customers.css";
import EditIcon from "@mui/icons-material/Edit";
import {
  DELETE_ADDRESS,
  UPDATE_ADDRESS_IN_EDIT,
  UPDATE_ADDRESS_IN_EDIT2,
  UPDATE_CUSTOMER2,
} from "../../graphicalQl/mutation/customerDataMutation";
import { CUSTOMER_DASHBOARD } from "../../graphicalQl/usequery/categoryListQuery";
import AddCustomer from "../../components/customerListing/addCustomer";
// import ImageDrawer2 from "../menu/image/imageDrawerForProfile";
import { UPDATE_CUSTOMER } from "../../graphicalQl/mutation/customerDataMutation";
import { toast } from "react-toastify";
import DeleteAddress from "./deleteAddress";
import ImageDrawer from "../menu/image/imageDrawer";
import ProfileImg from "../../assets/profileImg.png";

const CustomerProfile = () => {
  const { id } = useParams();

  const [addCustomerModal, setAddCustomerModal] = useState({
    state: false, //modal open or close state
    mode: "add", //modal to be opened for adding new customer or updating existing one.
    customerDetails: {}, //details of the selected customer to be updated
  });

  const { data: customerData } = useQuery(CUSTOMER_DATA, {
    context: { clientName: "customerClient" },
    variables: { customerId: id && +id },
    fetchPolicy: "network-only",
  });

  const { t } = useTranslation();

  const { data: addressList, refetch } = useQuery(ADDRESS_LIST, {
    context: { clientName: "addressClient" },
    variables: { customerId: id && +id },
  });

  const [addresses, setAddress] = useState<any>([]);

  useEffect(() => {
    if (addressList && addressList.addressList) {
      const list = addressList.addressList.edges.map((each: any) => ({
        type: each.node.addressType,
        address1: each.node.address1,
        address2: each.node.address2,
        city: each.node.city,
        postcode: each.node.postCode,
        country: each.node.country,
        isDefault: each.node.isDefault,
        addressId: each.node.addressId,
      }));

      //in this list i want isDefault to be true at the position of index 0
      const defaultIndex =
        list && list.findIndex((each: any) => each.isDefault === true);
      if (defaultIndex > 0) {
        const defaultAddress = list && list[defaultIndex];
        list.splice(defaultIndex, 1);
        if (defaultAddress) list.unshift(defaultAddress);
      }

      // list.unshift(defaultAddress)
      if (list) {
        setAddress(list);
      }
    }
  }, [addressList]);

  const addressTypes =
    addresses &&
    addresses.map(
      (each: any) => each && each.type && each.type.toLowerCase().trim()
    );

  const [customer, setCustomer] = useState<any>({});

  const [uuid, setUuid] = useState(null);
  useEffect(() => {
    if (customerData && customerData.customer) {
      const list = {
        firstName: customerData.customer.firstName,
        lastName: customerData.customer.lastName,
        email: customerData.customer.email,
        phone: customerData.customer.mobile,
        birthday: customerData.customer.dateOfBirth,
        anniversary: customerData.customer.anniversary,
        company: customerData.customer.companyName,
        tax: customerData.customer.taxIdentifier,
        image: customerData.customer.image,
        userAccount: customerData.customer.userAccount,
      };

      setCustomer(list);

      setUuid(customerData.customer.uuid);
    }
  }, [customerData]);

  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const handledrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const [mouseEnter, setMouseEnter] = useState("");
  const [editId, setEditId] = useState("");
  const [deleteAddress] = useMutation(DELETE_ADDRESS, {
    context: { clientName: "addressClient" },
    refetchQueries: [ADDRESS_LIST],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleDelete = (id: number) => {
    deleteAddress({ variables: { addressId: id } });
  };

  const [editData] = useMutation(UPDATE_ADDRESS_IN_EDIT2, {
    context: { clientName: "addressClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [ADDRESS_LIST],
  });
  const [updateImage] = useMutation(UPDATE_CUSTOMER2, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleSetDefault = (id2: string) => {
    editData({
      variables: {
        isDefault: true,
        addressId: +id2,
        customerId: id && +id,
      },
    });
  };

  const navigate = useNavigate();

  const editCustomerDetails = () => {
    const customerDetails = {
      id: id && +id,
      customerName: `${customer.firstName} ${customer.lastName}`,
      phone: customer.phone,
      mail: customer.email,
      customerGroup: null,
      status: "Active",
      loyaltyPoints: 0,
      userAccount: customer.userAccount,
      customerId: id && +id,
      dateOfBirth: customer.birthday,
      anniversary: customer.anniversary,
      company: customer.company,
      taxIdentifier: customer.tax,
    };
    setAddCustomerModal({
      state: true,
      mode: "update",
      customerDetails,
    });
  };

  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadColor, setUploadColor] = useState<string | null>(null);

  const handleUplaodImage = () => {};
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const handleImage = (value: string | null) => {
    setUploadImg(value);
    updateImage({
      variables: {
        customerId: id && +id,
        image: value,
        businessId: +businessId,
      },
    });
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
  };

  const [deletemodal, setDeleteModal] = useState(false);
  const [delteAddressId, setDeleteAddressId] = useState<any>("");
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    setDeleteAddressId("");
  };

  return (
    <div className="dashboard">
      <Paper className="paper">
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "8px 20px 8px 20px",
          }}
        >
          <IconButton
            className="exit-container"
            onClick={() => {
              navigate("/customer/customer-list");
            }}
          >
            <ArrowBackIcon className="exitIcon" />
          </IconButton>

          <Typography className="customerHeader">Customer Profile</Typography>
          <Typography></Typography>
        </Box>

        <Box sx={{ display: "flex", gap: "5%", width: "100%" }}>
          <Box
            sx={{
              backgroundColor: "#FFFFFF",
              border: "1px solid #EEEEEE",
              borderRadius: "8px",
              width: "25%",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#FFFFFF",
                border: "1px solid #EEEEEE",
                padding: "20px",
                borderRadius: "8px",
              }}
            >
              <Box sx={{ display: "flex", alignItems: "center", gap: "20px" }}>
                <Box
                  sx={{
                    height: "75px",
                    padding: "2px",
                    border: "1px solid #EEEEEE",
                    borderRadius: "50%",
                    width: "75px",
                  }}
                  // className="create-product-img-con"
                >
                  <ImageDrawer
                    handleImage={handleImage}
                    handleColor={handleColor}
                    colorCodeFromApi={null}
                    imageFromApi={customer.image}
                    heightValue={75}
                    borderRadius="50%"
                    textCondition={false}
                    width={"75px"}
                    image={ProfileImg}
                    imageType={"customer"}
                    customerId={id}
                  />
                </Box>
                <Typography className="customerName">{`${customer.firstName} ${customer.lastName}`}</Typography>
              </Box>
              <Button
                sx={{
                  marginTop: "24px",
                  backgroundColor: "#F2F2F2",
                  padding: "4px",
                  gap: "10px",
                  borderRadius: "8px",
                  fontSize: "16px",
                  fontWeight: 500,
                  lineHeight: "24px",
                  color: "#707070",
                  marginLeft: "80%",
                  textTransform: "none",
                }}
                onClick={editCustomerDetails}
              >
                Edit
                <EditIcon fontSize="small" color="warning" />
              </Button>
            </Box>

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
                padding: "20px",
              }}
            >
              <Typography className="customer-inform">
                Contact Information
              </Typography>
              <Box sx={{ display: "flex", gap: "12px" }}>
                <LocalPhoneOutlinedIcon />
                <Typography className="customer-number ">
                  {customer.phone}
                </Typography>
              </Box>
              <Box sx={{ display: "flex", gap: "12px" }}>
                <EmailOutlinedIcon />
                <Typography className="customer-number ">
                  {customer.email}
                </Typography>
              </Box>
              <Divider />
              <Typography className="customer-inform">Other</Typography>
              <Box>
                <Typography className="customer-company">Company</Typography>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <BusinessCenterOutlinedIcon />
                  {customer.company ? (
                    <Typography className="customer-para">
                      {customer.company}
                    </Typography>
                  ) : (
                    <Typography className="customer-para">not added</Typography>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography className="customer-company">Birthday</Typography>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <CakeOutlinedIcon />
                  {customer.birthday ? (
                    <Typography className="customer-para">
                      {" "}
                      {customer.birthday}
                    </Typography>
                  ) : (
                    <Typography className="customer-para">
                      {" "}
                      not added
                    </Typography>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography className="customer-company">
                  Tax Identifier
                </Typography>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <ViewComfyOutlinedIcon />
                  {customer.tax ? (
                    <Typography className="customer-para">
                      {customer.tax}
                    </Typography>
                  ) : (
                    <Typography className="customer-para">not added</Typography>
                  )}
                </Box>
              </Box>
              <Box>
                <Typography className="customer-company">
                  Anniversary
                </Typography>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <TodayOutlinedIcon />
                  {customer.anniversary ? (
                    <Typography className="customer-para">
                      {" "}
                      {customer.anniversary}
                    </Typography>
                  ) : (
                    <Typography className="customer-para">
                      {" "}
                      not added
                    </Typography>
                  )}
                </Box>
              </Box>
              {/* <Box>
                <Typography className="customer-company">Group</Typography>
                <Box sx={{ display: "flex", gap: "12px" }}>
                  <GroupIcon />
                  <Typography className="customer-para">Add Group</Typography>
                </Box>
              </Box> */}
            </Box>
          </Box>
          <Box sx={{ width: "70%" }}>
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                gap: "10px",
                padding: "10px",
                backgroundColor: "#FFFFFF",
                borderRadius: "8px",
                height: "300px",
                width: "100%",
              }}
            >
              <Typography className="customerName">Address</Typography>
              <Box
                sx={{
                  gap: "20px",
                  padding: "20px, 12px, 20px, 12px",
                  display: "flex",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#ECF7EE",
                    paddig: "20px",
                    borderRadius: "8px",
                    width: "130px",
                    height: "200px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    flexDirection: "column",
                  }}
                  onClick={handledrawer}
                >
                  <img src={PlusIcon} alt="plus" />
                  <Typography>Add Address</Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                    width: "85%",
                    overflowX: "auto",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {addresses &&
                    addresses.length > 0 &&
                    addresses.map((addr: any) => {
                      return (
                        <Box>
                          <Card
                            className="addresscard"
                            variant="outlined"
                            sx={{
                              position: "relative",
                              width: "278px",
                              height: "200px",
                              padding: "20px",
                            }}
                            onMouseEnter={() => {
                              setMouseEnter(addr.addressId);
                            }}
                            onMouseLeave={() => {
                              setMouseEnter("");
                            }}
                          >
                            <CardContent className="addAddress address">
                              <div className="flex-between">
                                {addr && addr.type && (
                                  <Chip
                                    color={
                                      addr.isDefault ? "warning" : "default"
                                    }
                                    label={addr.type}
                                    size="small"
                                  />
                                )}
                              </div>
                              <Box
                                sx={{
                                  overflow: "hidden",
                                  maxHeight: "150px",
                                  // textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Typography className="customer-address">
                                  {addr && addr.address1},{" "}
                                  {addr && addr.address2}, <br />
                                  {addr && addr.city}, {addr && addr.postcode},{" "}
                                  <br />
                                  {addr && addr.country}
                                </Typography>
                              </Box>
                            </CardContent>

                            <CardActions
                              className="flex-between"
                              sx={{ mt: "12px" }}
                            >
                              <IconButton
                                sx={{ color: "#FF856A" }}
                                onClick={() => {
                                  // handleDelete(+addr.addressId) ;
                                  handleDeleteModal();
                                  setDeleteAddressId(addr.addressId);
                                }}
                              >
                                <DeleteOutlinedIcon />
                              </IconButton>
                              {addr && !addr.isDefault ? (
                                <Button
                                  sx={{
                                    color: "#F38B08",
                                    fontWeight: "500",
                                    fontSize: "14px",
                                    textTransform: "none",
                                  }}
                                  onClick={() => {
                                    handleSetDefault(addr.addressId);
                                  }}
                                >
                                  Set as default
                                </Button>
                              ) : (
                                <Typography>Default</Typography>
                              )}
                            </CardActions>
                            {mouseEnter === addr.addressId && (
                              <Box
                                sx={{ position: "absolute", top: 0, right: 0 }}
                              >
                                <IconButton onClick={handledrawer}>
                                  <ModeEditOutlineOutlinedIcon
                                    onClick={() => setEditId(addr.addressId)}
                                  />
                                </IconButton>
                              </Box>
                            )}
                          </Card>
                        </Box>
                      );
                    })}
                </Box>
              </Box>
            </Box>
            <Box sx={{ marginTop: "20px", width: "100%" }}>
              <CustomerTabs id={id && id} uuid={uuid} />
            </Box>
          </Box>
        </Box>
        <Drawer
          anchor="right"
          open={addCustomerModal.state}
          onClose={() => {
            setAddCustomerModal({
              mode: "add",
              state: false,
              customerDetails: {},
            });
          }}
        >
          <AddCustomer
            setAddCustomerModal={setAddCustomerModal}
            addCustomerModal={addCustomerModal}
            refetch={refetch}
          />
        </Drawer>
        <AddressDrawer
          handledrawer={handledrawer}
          drawerOpen={drawerOpen}
          id={id}
          editId={editId}
          addressTypes={addressTypes}
          setEditId={setEditId}
        />
        <DeleteAddress
          deletemodal={deletemodal}
          handleDeleteModal={handleDeleteModal}
          id={delteAddressId}
          handleDleteProduct={handleDelete}
        />
      </Paper>
    </div>
  );
};

export default CustomerProfile;
