import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { Box, Typography, Button, Paper, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import '../options/createProduct.css'
import './createCategory.css'
import { useTranslation } from "react-i18next";

import { Visibility } from './visibility';
import { Stepper, Step, StepButton } from '@mui/material';
import CategoryDetails from './categoryDetails';
import CategoryAssignProduct from './categoryAssignProduct';
import OptionsModal from './optionsGropModal';
import { CategoryAditionalDetails } from './categoryAditionalDetalis';
import { resetState, setCreateCategory, setVisibility } from "../reduxReducers/categoryReducer";

import OptionsDailouge from './optionsDailougeBox';
import { Divider } from 'primereact/divider';
import AditionalDetailsDailouge from './categoryAdvanceDetailsDailouge';
import { ADD_ADITIONAL_DETAILS } from '../../graphicalQl/mutation/mutation';
import { useMutation, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useDispatch, } from 'react-redux';
import { styled } from '@mui/system';
import { EDIT_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import uuid from 'react-uuid';
import moment from 'moment';





const CategoryMain = () => {
  const { t } = useTranslation();
  const stepsData = [
    t("menu.catageory.generalDetails"),
    t("menu.catageory.assignProduct"),
    t("menu.catageory.assignOptions"),
    t("menu.catageory.advancedSettings")
  ]
  const [steps, setSteps] = useState(stepsData);


  useEffect(() => {
    setSteps(stepsData)
  }, [t])

  const CustomStyles = styled('div')({
    '& .MuiStepConnector-line': {
      display: 'block',
      borderColor: '#bdbdbd',
      borderLeftStyle: 'none !important',
      borderLeftWidth: '1px',
      minHeight: '0px !important',
      maxHeight: '0px !important',
    },
    '& .MuiStepConnector-root': {
      WebkitFlex: 'none !important',
      msFlex: 'none !important',
      flex: 'none !important',
      marginLeft: 'none !important',
    },
    '& .MuiStepLabel-label.Mui-active, & .MuiStepLabel-label.Mui-completed': {
      fontFamily: 'Poppins !important',
      fontSize: '1rem !important',
      fontStyle: 'normal !important',
      fontWeight: '500 !important',
      lineHeight: 'normal !important',
    },
    '& .MuiButtonBase-root.MuiStepButton-root': {
      display: 'flex !important',
      padding: '10px 12px !important',
      alignItems: 'center !important',
      alignSelf: 'stretch !important',
      borderRadius: '4px 4px 0px 0px !important',
      borderLeft: '2px solid #14B8A6 !important',
      background: '#FFF !important',
      '&:hover': {
        background: '#FFF8F0 !important',
      },
      border: '1px solid #EEE !important',
    },
  });



  const { id } = useParams();
  const dispatch = useDispatch();
  const { metaTag, seoKeyWord, metaKeyWord } = useSelector((state: any) => state.creatCategory);



  const { radioValue,
    startTime,
    endTime,
    weekDays,
    selectDate, } = useSelector((state: any) => state.categoryVisibilty);


  const navigate = useNavigate()

  const handleNavigateTolisting = () => {
    navigate('/category/category-listing')
    dispatch(resetState())


  }



  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState<{
    [k: number]: boolean;
  }>({});

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step: number) => {
    setActiveStep(step);
  };

  const handleStepByProductModal = () => {
    setActiveStep(0)
  }
  const handleStepByOptionModal = () => {
    setActiveStep(1)
  }
  const handleStepByProductSetting = () => {
    setActiveStep(0)
  }
  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();



  };



  const [categoryId, setCategoryId] = useState<string>('');

  const getCategoryId = (newId: string) => {
    setCategoryId(newId);
  };


  const {
    showOnCheckBox,
    hideUntilCheckBox,
    hideMenuCheckBox } = useSelector((state: any) => state.categoryVisibilty);

  const { data: editData } = useQuery(EDIT_CATEGORY, {
    variables: { categoryId: id ? +id : "" }, context: { clientName: 'categoryClient' }, fetchPolicy: 'cache-and-network',
    onError: (error) => {
      console.error('Error:', error);
    },
  })

  useEffect(() => {

    if (editData) {

      if (editData.category) {
        editData.category.hideUntil
          && dispatch(setVisibility("selectDate", editData.category.hideUntil))

        editData.category.hideUntil && dispatch(setVisibility("hideUntilCheckBox", true))
        editData.category.showOn && (


          dispatch(setVisibility("weekDays", [
            { id: 2, day: "Monday", isChecked: JSON.parse(editData.category.showOn)[0]?.Monday?.isOpen || false },
            { id: 3, day: "Tuesday", isChecked: JSON.parse(editData.category.showOn)[1]?.Tuesday?.isOpen || false },
            { id: 4, day: "Wednesday", isChecked: JSON.parse(editData.category.showOn)[2]?.Wednesday?.isOpen || false },
            { id: 5, day: "Thursday", isChecked: JSON.parse(editData.category.showOn)[3]?.Thursday?.isOpen || false },
            { id: 6, day: "Friday", isChecked: JSON.parse(editData.category.showOn)[4]?.Friday?.isOpen || false },
            { id: 7, day: "Saturday", isChecked: JSON.parse(editData.category.showOn)[5]?.Saturday?.isOpen || false },
            { id: 8, day: "Sunday", isChecked: JSON.parse(editData.category.showOn)[6]?.Sunday?.isOpen || false },]))

        )
        editData.category.showOn && (
          dispatch(setVisibility("startTime", JSON.parse(editData.category.showOn)[0]?.Monday?.openTime || "1"))

        )
        editData.category.showOn && (
          dispatch(setVisibility("showOnCheckBox", true))
        )

        editData.category.showOn && (
          dispatch(setVisibility("endTime", JSON.parse(editData.category.showOn)[0]?.Monday?.closingTime || "1"))
        )

        editData.category.isHidden &&
          dispatch(setVisibility("hideMenuCheckBox", editData.category.isHidden))
        editData.category.metatagDescription && dispatch(setCreateCategory("metaTag", editData.category.metatagDescription));
        editData.category.seoKeyword && dispatch(setCreateCategory("seoKeyWord", JSON.parse(editData.category.seoKeyword).map((each: string) => {
          return { id: uuid(), name: each }
        })));
        editData.category.metatagKeywords && dispatch(setCreateCategory("metaKeyWord", JSON.parse(editData.category.metatagKeywords).map((each: string) => {
          return { id: uuid(), name: each }
        })));
      }
    }

  }, [editData])

  const formattedData = weekDays.map((each: any) => ({
    [each.day]: {
      isOpen: each.isChecked,
      openTime: moment(startTime, 'h:mm A').format('HH:mm'),
      closingTime: moment(endTime, 'h:mm A').format('HH:mm'),
    }
  }));
  const businessId: string = sessionStorage.getItem("businessId") as string

  const [addAditionalDetails, { loading: updateLoading }] = useMutation(ADD_ADITIONAL_DETAILS, {
    context: { clientName: 'categoryClient' },
    onCompleted: () => {
      toast.success(
        <div >
          <Typography>{t("dailouge.changes_saved_succesfully")}</Typography>
        </div>,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
          className: 'custom_toast3',
        }
      );
      navigate('/category/category-listing')
      dispatch(resetState());
    },
    onError: (error) => {
      console.error('Error:', error);
    },

  })

  const aditionalDetailsList = {
    categoryId: +categoryId || (id && +id),
    businessId: +businessId,
    metatagDescription: metaTag,
    metatagKeywords: (metaKeyWord && metaKeyWord.length > 0) ? JSON.stringify(metaKeyWord.map((each: any) => each.name)) : null,
    seoKeyword: (seoKeyWord && seoKeyWord.length > 0) ? JSON.stringify(seoKeyWord.map((each: any) => each.name)) : null,
    showOn: showOnCheckBox ? JSON.stringify(formattedData) : null,
    hideUntil: hideUntilCheckBox ? selectDate : null,
    isHidden: hideMenuCheckBox
  }

  //remove null values from above object
  const filteredCategoryUpdate = Object.fromEntries(
    Object.entries(aditionalDetailsList).filter(([_, value]) => value !== null)
  ); const handleFinishButton = () => {
    //   addAditionalDetails({ variables: { categoryId: +categoryId || (id && +id), businessId: +businessId, metatagDescription: metaTag, metatagKeywords: JSON.stringify(metaKeyWord), seoKeyword: JSON.stringify(seoKeyWord), showOn: showOnCheckBox ? JSON.stringify(formattedData) : null, hideUntil: hideUntilCheckBox ? selectDate : null, isHidden: hideMenuCheckBox }
    //  })
    addAditionalDetails({ variables: aditionalDetailsList })


  }



  return (
    <Box>
      <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", padding: "12px 20px 20px 20px", backgroundColor: "white", width: "60%", marginBottom: "12px" }}>
        <IconButton className="exit-container" onClick={handleNavigateTolisting}>
          <ArrowBackIcon className="exitIcon" />
        </IconButton>
        {id ? (<Typography className="create-product-head">{t("menu.catageory.edit_category")}</Typography>
        ) : (<Typography className="create-product-head">{t("menu.catageory.createACategory")}</Typography>
        )}



      </div>
      <Box sx={{ width: '100%', display: "flex", gap: "24px", paddingLeft: "16px " }}>
        <CustomStyles>
          <Stepper nonLinear activeStep={activeStep} orientation="vertical" >
            {steps.map((label, index) => (
              <Step key={label} completed={completed[index]}

              >

                <StepButton
                  onClick={() => handleStep(index)}
                  sx={{
                    '& .MuiButtonBase-root-MuiStepButton-root': {
                      display: 'flex !important',
                      padding: '10px 12px !important',
                      alignItems: 'center !important',
                      alignSelf: 'stretch !important',
                      borderRadius: '4px 4px 0px 0px !important',

                      background: '#FFF !important',
                      '&:hover': {
                        background: '#FFF8F0 !important',
                      },
                      border: '1px solid #EEE !important',
                    },
                  }}

                  icon={
                    completed[index] ? (
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="#14B8A6" />
                      </svg>
                    ) : (
                      index === activeStep ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM9.29 16.29L5.7 12.7C5.31 12.31 5.31 11.68 5.7 11.29C6.09 10.9 6.72 10.9 7.11 11.29L10 14.17L16.88 7.29C17.27 6.9 17.9 6.9 18.29 7.29C18.68 7.68 18.68 8.31 18.29 8.7L10.7 16.29C10.32 16.68 9.68 16.68 9.29 16.29Z" fill="#F38B08" />
                        </svg>
                      ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                          <path d="M16.59 7.58L10 14.17L6.41 10.59L5 12L10 17L18 9L16.59 7.58ZM12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 20C7.58 20 4 16.42 4 12C4 7.58 7.58 4 12 4C16.42 4 20 7.58 20 12C20 16.42 16.42 20 12 20Z" fill="#ABABAB" />
                        </svg>
                      )
                    )
                  }
                >
                  {completed[index] ? (
                    <Typography sx={{ color: "#14B8A6" }}>{label}</Typography>
                  ) : (
                    index === activeStep ? (
                      <Typography sx={{ color: "#F38B08" }}>{label}</Typography>
                    ) : (
                      <Typography sx={{ color: "grey" }}>{label}</Typography>
                    )
                  )}
                </StepButton>

              </Step>
            ))}
          </Stepper>
        </CustomStyles>
        <div style={{ width: "90%" }}>
          {allStepsCompleted() ? (
            <React.Fragment>
              {/* <Typography sx={{ mt: 2, mb: 1 }}>
              All steps completed - you&apos;re finished
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Box sx={{ flex: '1 1 auto' }} />
              <Button onClick={handleReset}>Reset</Button>
            </Box> */}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Box >
                {activeStep === 0 && (<Paper elevation={0} style={{ marginLeft: "2.5%", marginRight: "14%", padding: " 12px 20px 20px 20px", borderLeft: "2px solid var(--stroke-color-1-violet, #AE9BE2)" }} className='paper-style'><CategoryDetails getCategoryId={getCategoryId} handleComplete={handleComplete} categoryId2={id || categoryId} />

                </Paper>)}
                {activeStep === 1 && (<Paper elevation={0} style={{ marginLeft: "2.5%", marginRight: "14%", padding: " 12px 20px 20px 20px", borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }} className='paper-style'>
                  <Box sx={{
                    maxHeight: "70vh", overflowY: "scroll", WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}>
                    <Box className="create_category_message_box" sx={{ display: 'flex', alignItems: "center" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" fill="#00AE26" />
                      </svg>
                      <Typography className='create_category_message' sx={{ marginLeft: "4%" }}>{t("menu.catageory.click_text")}<span className='create_category_message2'>{t("menu.catageory.assign_options")}</span>{t("menu.catageory.screen_back")}</Typography>
                    </Box>
                    <CategoryAssignProduct id={id || categoryId} handleStepByProductModal={handleStepByProductModal} />
                    {/* <Divider style={{ marginTop: '24px' }} /> */}
                    <Box sx={{ display: "flex", alignItems: "center", gap: '10px' }}>
                      {id && <Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon />} {t("menu.catageory.back")}</Button>}
                      <Button className='createCategory-next-active' onClick={handleComplete}>{t("menu.catageory.next")} {<ArrowForwardIcon color='warning' />}</Button>
                    </Box>
                  </Box>
                </Paper>)}
                {activeStep === 2 && (<Paper elevation={0} style={{ marginLeft: "2.5%", marginRight: "14%", padding: "12px 20px ", borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)" }} className='paper-style'>
                  <Box sx={{
                    overflowY: "scroll", WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}>
                    <Box className="create_category_message_box" sx={{ display: 'flex', alignItems: "center" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" fill="#00AE26" />
                      </svg>
                      <Typography className='create_category_message' sx={{ marginLeft: "4%" }}>{t("menu.catageory.click_text")} <span className='create_category_message2'>{t("menu.catageory.advanced_settings")}</span> {t("menu.catageory.screen_back2")}</Typography>
                    </Box>

                    <OptionsDailouge categoryId={id || categoryId} handleStepByOptionModal={handleStepByOptionModal} />
                    <OptionsModal categoryId={id || categoryId} />


                    <Box sx={{ display: "flex", alignItems: "center", }}>
                      <Button className='create_category_BacK_button' onClick={handleBack} >{<ArrowBackIcon />} {t("menu.catageory.back")}</Button>
                      <Button className='createCategory-next-active' sx={{ marginLeft: "2.6%" }} onClick={handleComplete}>{t("menu.catageory.next")} {<ArrowForwardIcon color='warning' />}</Button>
                    </Box>
                  </Box>
                </Paper>)}

                {activeStep === 3 && (<Paper elevation={0} style={{
                  marginLeft: "2.5%", marginRight: "3%", padding: "12px 20px 20px 20px", borderLeft: "2px solid  var(--stroke-color-4-brown, #E2A39B)",
                }} className='paper-style'>
                  <Box sx={{
                    height: "65vh", overflowY: "scroll", WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
                      display: 'none',
                    },
                  }}>
                    <Box className="create_category_message_box" sx={{ display: 'flex', alignItems: "center", marginBottom: "2%" }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM12 17C11.45 17 11 16.55 11 16V12C11 11.45 11.45 11 12 11C12.55 11 13 11.45 13 12V16C13 16.55 12.55 17 12 17ZM13 9H11V7H13V9Z" fill="#00AE26" />
                      </svg>
                      <Typography className='create_category_message' sx={{ marginLeft: "4%", }}>{t("menu.catageory.click_msg_3")}</Typography>
                    </Box>
                    <Visibility categoryId={id || categoryId} />

                    {<CategoryAditionalDetails categoryId={id || categoryId} />}
                    <AditionalDetailsDailouge categoryId={id || categoryId} handleStepByProductSetting={handleStepByProductSetting} />
                    <Divider />
                    <Box sx={{ display: "flex", alignItems: "center", marginTop: '12px' }}>
                      <Button className='create_category_BacK_button' onClick={handleBack}>{<ArrowBackIcon />} {t("menu.catageory.back")}</Button>
                      <Button className='main-button' sx={{ marginLeft: "3px" }} onClick={() => { handleFinishButton() }} disabled={updateLoading}>{t("menu.catageory.finish")} </Button>
                    </Box>
                  </Box>
                </Paper>)}



              </Box>
            </React.Fragment>
          )}
        </div>
      </Box>

    </Box>
  )
}
export default CategoryMain