import { Box, Typography } from "@mui/material";
import PopularItemIcon from "../../../assets/mianDashboard/popularItemIcon.svg";
import PopularItemBurgerImg from "../../../assets/mianDashboard/PopularItemsBurger.svg";
// import PopularItemsChocoImg from "../../../assets/mianDashboard/PopularItemsChoco.svg";
// import PopularItemsPizzaImg from "../../../assets/mianDashboard/PopularItemsPizza.svg";
// import PopularItemsChickenImg from "../../../assets/mianDashboard//PopularItems-chicken.svg";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import React, { useEffect, useState } from "react";
import { PopularItem } from "../../../types/dashboard";
import DashboardApi from "../../../services/http/dashboard";
import { useTranslation } from "react-i18next";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F6F6F6",
    border: "0px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: "14px",
    fontFamily: "poppins",
    fontWeight: "500",
    lineHeight: "24px",
    color: "#000000",
    paddingLeft: "12px",
    paddingRight: "12px",
    height: "54px !important",
  },
}));
const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    borderBottom: "8px solid white",
  },
  "& > *": {
    backgroundColor: "#FBFBFB",
    borderRadius: "4px !important",
  },
}));
function createData(name: any, count: any, sale: any, image: any) {
  return { name, count, sale, image };
}

interface State {
  popularItems: any[];
}
const PopularItems = (props: State) => {
  const { t } = useTranslation();
  const { popularItems } = props;
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const mappedData = popularItems?.map((item: PopularItem) => {
    return createData(item.name, item.count, item.sale, item.image);
  });

  

  return (
    <Box
      sx={{
        overflow: "hidden",
        overflowX: "auto",
        width: { xs: "100%", md: "47%" },
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          minWidth: "591px",
          height: "362px",
          backgroundColor: "white",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            height: "78px",
            background: "white",
            gap: "16px",
            paddingX: "32px",
            display: "flex",
            alignItems: "center",
            borderRadius: "8px",
          }}
        >
          <img src={PopularItemIcon} alt="PopularItemIcon" />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "24px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {t("dashboard.Popular_Items")}
          </Typography>
        </Box>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: "none !important",
          }}
        >
          <div
            style={{
              maxHeight: "274px",
              overflow: "hidden",
            }}
          >
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <StyledTableCell
                    sx={{
                      width: "50%",
                      textAlign: "center",
                      height: "32px",
                      paddingY: "4px",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        position: "relative",
                        right: { xs: "25px", xl: "70px" },
                        fontFamily: "poppins",
                        fontSize: "16px",
                        color: "#333333",
                        fontWeight: "500",
                        lineHeight: "24px",
                      }}
                    >
                      {t("dashboard.Item_Name")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      width: "25%",
                      height: "32px",
                      paddingY: "4px",
                    }}
                    align="center"
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        color: "#333333",
                        fontWeight: "500",
                        lineHeight: "24px",
                        flex: 1,
                      }}
                    >
                      {t("dashboard.Qty")}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    sx={{
                      width: "20%",
                      height: "32px",
                      paddingY: "4px",
                    }}
                    align="right"
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontSize: "16px",
                        color: "#333333",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "center",
                      }}
                    >
                      {t("dashboard.Sale")}
                    </Typography>
                  </StyledTableCell>
                </TableRow>
              </TableHead>
            </Table>
            <div
              style={{
                maxHeight: "224px",
                overflowY: "auto",
                marginTop: "12px",
                maxWidth: "87.29%",
                marginLeft: "auto",
                marginRight: "auto",
              }}
            >
              <Table>
                <TableBody>
                  {mappedData?.map((row, index) => (
                    <StyledTableRow
                      sx={{
                        maxWidth: "60%",
                      }}
                      key={index}
                    >
                      <StyledTableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "12px",
                          paddingY: "0px",
                          border: "0px",
                        }}
                        component="th"
                        scope="row"
                      >
                        {row.image && (
                          <Box
                            sx={{
                              width: "52px",
                              height: "53px",
                              borderRadius: "12px",
                              border: "1px solid",
                              borderColor: "rgba(246, 168, 70, 0.5)",
                              display: "flex",

                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            <img
                              height={"44px"}
                              width={"44px"}
                              src={row.image}
                              alt="img"
                            />
                          </Box>
                        )}
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {" "}
                          {row.name}
                        </Typography>
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{
                          paddingY: "0px",
                          border: "0px",
                          width: "45%",
                          marginLeft: "50px",
                        }}
                        align="right"
                      >
                        {row.count}
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ paddingY: "0px", border: "0px", width: "30%" }}
                        align="right"
                      >
                        {currencySymbol} {row.sale ? Math.floor(row.sale) : 0}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          </div>
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PopularItems;
