// src/components/Orders/Filters.tsx
import React from "react";
import { Box, TextField, MenuItem } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { useTranslation } from "react-i18next";
import DelayedInput from "../../../utils/delayedInput";

const Filters = (props: any) => {
  const { t } = useTranslation();
  const {
    handleFilterStatusChange,
    handleBByorders,
    byOrders,
    statusFilter,
    ordertypeFilter,
    setSearchQuery,
    handleFilterOrderTypeChange,
  } = props;

  return (
    <Box display="flex" gap="10px" padding="10px" flexWrap={"wrap"}>
      <DelayedInput
        width="30%"
        // margin="10px 0px 10px 8px"
        setSearchValue={setSearchQuery}
        placeHolder={t("users.search_by_name")}
        size={"medium"}
      />

      <TextField
        id="order-type"
        select
        label={t("orders.order_type")}
        variant="outlined"
        sx={{ width: "15%" }}
        value={ordertypeFilter}
        onChange={handleFilterOrderTypeChange}
      >
        <MenuItem value="">{t("orders.order_type")}</MenuItem>
        <MenuItem value="delivery">{t("orders.delivery")}</MenuItem>
        <MenuItem value="dine_in">{t("orders.dine_in")}</MenuItem>
        <MenuItem value="shop">{t("orders.shop")}</MenuItem>
        <MenuItem value="collection">{t("orders.collection")}</MenuItem>
      </TextField>
      <TextField
        id="order-type"
        select
        label={t("orders.by_status")}
        variant="outlined"
        sx={{ width: "15%" }}
        value={statusFilter}
        onChange={handleFilterStatusChange}
      >
        <MenuItem value="">{t("orders.by_status")}</MenuItem>
        <MenuItem value="Pending">{t("orders.pending")}</MenuItem>
        <MenuItem value="Cancelled">{t("orders.cancelled")}</MenuItem>
        <MenuItem value="Preparing">{t("orders.preparing")}</MenuItem>
        <MenuItem value="Prepared">{t("orders.prepared")}</MenuItem>
        <MenuItem value="Dispatched">{t("orders.dispatched")}</MenuItem>
        <MenuItem value="Hold">{t("orders.hold")}</MenuItem>
        <MenuItem value="Completed">{t("orders.completed")}</MenuItem>
        <MenuItem value="Void">{t("orders.void")}</MenuItem>
        <MenuItem value="Accepted">{t("orders.accepted")}</MenuItem>
        <MenuItem value="Rejected">{t("orders.rejected")}</MenuItem>
      </TextField>
      <TextField
        id="order-type"
        select
        label={t("orders.by_orders")}
        value={byOrders}
        variant="outlined"
        onChange={handleBByorders}
        sx={{ width: "15%" }}
      >
        <MenuItem value="">{t("orders.by_orders")}</MenuItem>
        <MenuItem value="as_soon_as_possible">
          {t("orders.asap_orders")}
        </MenuItem>
        <MenuItem value="advanced_orders">
          {t("orders.advanced_orders")}
        </MenuItem>
      </TextField>
    </Box>
  );
};

export default Filters;
