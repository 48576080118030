import { Box } from "@mui/material";
import OrdersReport from "../../components/reports/orders";
import React from "react";

const OrdersReportPage = () => {
  return (
    <Box>
      <OrdersReport />
    </Box>
  );
};
export default OrdersReportPage;
