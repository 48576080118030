import React, { FC, useContext, useEffect, useRef, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Box } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import BasicTimePicker from "../../common/timePicker/timepicker";
import styled from "styled-components";
import { GraphContext } from "../../../Contexts/GraphContext";
import moment from "moment";
import { convertTimeToUTC } from "../../../utils/utcformat";

const SelectWrapper = styled("div")({
  ".css-6hp17o-MuiList-root-MuiMenu-list": {
    padding: "12px",
  },
  ".css-1vdqqoz": {
    gap: "10px",
  },
  ".css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root": {
    width: "100%",
  },
  ".css-10hburv-MuiTypography-root": {
    fontSize: "14px !important",
    lineHeight: "16px !important",
  },
  ".css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root": {
    paddingBottom: "12px !important",
  },
  ".css-4jnixx-MuiStack-root": {
    paddingTop: "0px !important",
  },
});

const Options = [
  { value: "AllDay", label: "All Day", checked: false },
  { value: "Custom", label: "Custom", checked: false },
];

const DaysSelectionTest = () => {
  //const [type, setType] = useState(null);
  //const [options, setOptions] = useState(Options);
  // const [open, setOpen] = useState<boolean>(false);

  const {
    fromTime,
    toTime,
    setFromTime,
    setToTime,
    type,
    setType,
    options,
    setOptions,
    open,
    setOpen,
    openCustom,
    setOpenCustom,
  } = useContext(GraphContext);

  const fromRef = useRef<any>();
  const toRef = useRef<any>();

  const handleChange = (value: string) => {
    const selectedValue = value;
    const selectedOption = options.find(
      (option: any) => option.value === selectedValue
    );

    const updatedOptions = options.map((option: any) => ({
      ...option,
      checked: option.value === selectedValue,
    }));

    if (selectedOption.value === "AllDay") {
      setOpen(false);

      setFromTime("00:00");
      setToTime("23:59");
    }

    setOptions(updatedOptions);
    setType(selectedOption.label);
    if (selectedOption.label === "Custom") setOpenCustom(true);
  };

  const renderValue = () => {
    if (type === "Custom") {
      if (fromTime && toTime) {
        return fromTime + " - " + toTime;
      } else {
        if (fromTime && !toTime) {
          return fromTime + " - 23:59";
        } else if (!fromTime && toTime) {
          return "00:00 - " + toTime;
        }
      }
    } else {
      if (type === "All Day" || !type) return "All Day";
    }
  };

  const handleClose = () => {
    if (fromRef.current) {
      setFromTime(fromRef.current.value);
    }
    if (toRef.current) {
      setToTime(toRef.current.value);
    }
  };

  return (
    <FormControl
      sx={{
        width: "190px",
        height: "44px",
        marginRight: "30px",
        position: "static",
        "& .MuiOutlinedInput-root": {
          "&:focused fieldset": { borderColor: "#f39f08" },
          "&.Mui-focused fieldset": { borderColor: "#f39f08" },
        },
      }}
    >
      <SelectWrapper>
        <Select
          sx={{ height: "44px", width: "100%" }}
          displayEmpty
          value={type || ""}
          onClick={(e) => {
            if (e.target.toString().indexOf("HTMLDivElement") !== -1) {
              setOpen(!open);
              setOpenCustom(!openCustom);
            }
          }}
          onClose={handleClose}
          renderValue={(selected) => renderValue()}
          className="selectTag"
          open={type === "Custom" ? openCustom : open}
          // open={type === "Custom" ? true : open}
        >
          {options.map((option: any, index: number) => (
            <Box key={index}>
              <MenuItem
                value={option.value}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  handleChange(option.value);
                }}
              >
                <Checkbox
                  icon={<RadioButtonUncheckedIcon />}
                  checkedIcon={
                    <RadioButtonCheckedIcon sx={{ color: "#F38B08" }} />
                  }
                  checked={option.checked}
                  value={option.value}
                />
                <ListItemText
                  primary={option.label}
                  sx={{
                    marginLeft: "10px",
                    fontSize: "14px",
                    lineHeight: "16px",
                    color: "#707070",
                  }}
                />
              </MenuItem>
              {index === 0 ? (
                <Box
                  sx={{
                    height: "1px",
                    width: "250px",
                    backgroundColor: "#D9D9D9",
                    mx: "auto",
                    my: "4px",
                  }}
                ></Box>
              ) : null}
            </Box>
          ))}
          {type === "Custom" ? (
            <Box
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setOpenCustom(true);
              }}
              sx={{
                display: "flex",
                gap: "10px",
                width: "250px",
                px: "12px",
                py: "4px",
              }}
            >
              <BasicTimePicker
                setTime={setFromTime}
                timeValue={fromTime}
                setOpen={setOpenCustom}
                label="Start"
                timeRef={fromRef}
              />
              <BasicTimePicker
                setTime={setToTime}
                timeValue={toTime}
                setOpen={setOpenCustom}
                label="End"
                timeRef={toRef}
              />
            </Box>
          ) : null}
        </Select>
      </SelectWrapper>
    </FormControl>
  );
};

export default DaysSelectionTest;
