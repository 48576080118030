import { gql } from "@apollo/client";

export const CATEGORY_LIST = gql`
  query categoryList(
    $businessId: Int
    $name_Icontains: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    categoryList(
      businessId: $businessId
      name_Icontains: $name_Icontains
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          categoryId

          name

          description
          productCount

          productCategory {
            edges {
              node {
                productId
                locationId
                isDeleted
                productStatus
                variants {
                  variantId
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const SPECIALS_LIST = gql`
  query specialList($businessId: Int, $locationId: Int) {
    specialsList(businessId: $businessId, locationId: $locationId) {
      edges {
        node {
          specialId
          specialStatus
          products
          name
        }
      }
    }
  }
`;
export const GET_PRODUCT = gql`
  query ProductList($businessId: ID, $locationId: ID, $productUnit: String) {
    productList(
      businessId: $businessId
      locationId: $locationId
      productUnit: $productUnit
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name
          price
          category {
            name
            categoryId
          }
        }
      }
    }
  }
`;

export const OPTIONS_LIST = gql`
  query optionGroupList($businessId: ID) {
    optionGroupList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionGroupId
          slugName
          options

          optionObjs {
            name
            priceToDisplay
            optionId
            optionValues {
              optionValueId
              name
              price
              image
            }
            name
          }
          name
        }
      }
    }
  }
`;
export const CUSTOMER_DASHBOARD = gql`
  query CustomerDashboard($customerId: Int, $storeId: Int) {
    customerDashboard(customerId: $customerId, storeId: $storeId)
  }
`;

export const CUSTOMER_ORDER_DASHBOARD = gql`
  query CustomerOrdersDashboard(
    $customerId: UUID
    $businessId: ID
    $storeId: Int
  ) {
    customerOrdersDashboard(
      customerId: $customerId
      businessId: $businessId
      storeId: $storeId
    )
  }
`;

export const OPTIONS_WITHOUT_GROUP = gql`
  query optionList($businessId: ID) {
    optionList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionId
          priceToDisplay
          optionId
          name

          optionValues {
            optionValueId
            name
            price
            image
            isSwapValue
          }
        }
      }
    }
  }
`;
export const EDIT_CATEGORY = gql`
  query Category($categoryId: ID!) {
    category(categoryId: $categoryId) {
      categoryId
      slugName
      description
      image
      name
      bgColourCode
      productCount
      isPos
      metatagKeywords
      productCount
      metatagDescription
      showOn
      hideUntil
      isHidden
      isOnline
      isCarry
      seoKeyword
      isDinein
      categoryStatus
      isDeleted
    }
  }
`;

export const GET_OPTIONS_IN_CATEGORY = gql`
  query categoryOptionValueListing($categoryId: Int) {
    categoryOptionValueListing(categoryId: $categoryId)
  }
`;

export const GET_OPTIONS_IN_CATEGORY_FOR_LISTING = gql`
  query optionList($businessId: ID) {
    optionList(businessId: $businessId) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          optionId

          name
          priceToDisplay
        }
      }
    }
  }
`;
export const GETTING_OPTIONS_LIST_FOR_ASSIGNING_OPTIONS = gql`
  query SelectedOptionDetails($options: JSONString) {
    selectedOptionDetails(options: $options)
  }
`;

export const GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP = gql`
  query SelectedOptionDetails($options: JSONString) {
    selectedOptionDetails(options: $options)
  }
`;

export const EDIT_ASSIGN_OPTIONS_TO_PRODUCT = gql`
  query ProductOptionValueDetailedListing($productId: Int) {
    productOptionValueDetailedListing(productId: $productId)
  }
`;

export const GETTING_VARIANT_IDS_AND_NAMES_FROM_PRODUCT_LISTS = gql`
  query SelectedProductList($productIds: JSONString) {
    selectedProductList(productIds: $productIds) {
      edges {
        node {
          productId
          name
          version
          variants {
            variantId
            name
            variantGroup
          }
          isLatest
          createdOn
          locationId
        }
      }
    }
  }
`;

export const GETTING_PRODUCT_AND_VARIANTS_FROM_EDIT_ID = gql`
  query ProductDetails($productId: ID) {
    product(productId: $productId) {
      productId
      name
      variants {
        variantId
        name
        variantGroup
      }
    }
  }
`;

export const PRODUCT_DATA_IN_CATEGORY = gql`
  query productList(
    $businessId: ID
    $categoryIds: JSONString
    $locationId: ID
  ) {
    productList(
      businessId: $businessId
      categoryIds: $categoryIds
      locationId: $locationId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          productId
          name

          price
        }
      }
    }
  }
`;
