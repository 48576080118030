import { Box, Typography, Button } from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import React from "react";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useTranslation } from "react-i18next";

import "./deliveryZone.css";

import { useNavigate, useParams } from "react-router";
import DeliveryRulesAndMap from "./deliveryRulesAndMap";
import DeliverySettings from "./deliverySettings";
import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { useQuery } from "@apollo/client";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {
  GETTING_API_KEYS,
  GET_STORE_ADDRESS_DETAILS,
  GET_UPDATING_STORE_DETAILS,
} from "../../graphicalQl/usequery/locationQuery";

interface Location {
  lat: number;
  lng: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

function DeliveryZone() {
  const { t } = useTranslation();
  const [addressId, setAddressId] = useState<null | string>(null);
  const [storeLocation, setStoreLocation] = useState<Location | null>(null);
  const [locationKey, setLocationKey] = useState<string | null>(null);

  const dataFromUrl = useParams();
  const { editId } = dataFromUrl;
  const {
    loading: updatingStoreDetailsLoading,
    error: updatingStoreDetailsError,
    data: updatingStoreDetailsData,
  } = useQuery(GET_UPDATING_STORE_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { storeId: editId && +editId },
    fetchPolicy: "network-only",
  });

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const filteredCategory = {
    businessId: businessId,
  };

  const {
    loading: loading,
    error: error,
    data: data,
  } = useQuery(GETTING_API_KEYS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  const {
    loading: storeAddressLoading,
    error: storeAddressError,
    data: storeAddressData,
  } = useQuery(GET_STORE_ADDRESS_DETAILS, {
    context: { clientName: "addressClient" },
    variables: { addressId: addressId && +addressId },
    fetchPolicy: "network-only",
  });
  const [deliverySettingId, setDeliverySettingId] = React.useState<
    string | null
  >(null);

  const navigate = useNavigate();
  const handleBackArrowButton = () => {
    navigate("/delivery/delivery-list");
  };
  const [value, setValue] = React.useState("basicDetails");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    if (!deliverySettingId) {
      setShowPopup(true);
    } else {
      setValue(newValue);
    }
  };
  const [showPopup, setShowPopup] = useState<boolean>(false);

  const handleClose = () => {
    setShowPopup(false);
    setValue("basicDetails");
  };

  const handleGotItButton = () => {
    handleClose();
    setValue("basicDetails");
  };

  useEffect(() => {
    if (updatingStoreDetailsData) {
      const { store } = updatingStoreDetailsData;
      if (store) {
        setAddressId(store.addressId);
      }
    }
  }, [updatingStoreDetailsData]);

  useEffect(() => {
    if (data) {
      const { business } = data;
      if (business) {
        const { apiKeys } = business;
        if (apiKeys) {
          const parsedApis = JSON.parse(apiKeys);
          const { google_maps_api_key } = parsedApis;
          setLocationKey(google_maps_api_key);
        }
      }
    }
  }, [data]);

  useEffect(() => {
    if (storeAddressData) {
      const { address } = storeAddressData;
      if (address) {
        setStoreLocation({ lat: +address.latitude, lng: +address.longitude });
      }
    }
  }, [storeAddressData]);

  return (
    <Box sx={{ background: "#fbfbfb" }}>
      <Modal
        open={showPopup}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box>
            <IconButton
              onClick={handleClose}
              className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{ marginTop: "5px", marginBottom: "5px", width: "100%" }}
          />
          <Box
            sx={{
              textAlign: "center",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            <Typography>{t("deliveries.save_the_delivery")}</Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleGotItButton}
              color="warning"
              variant="contained"
              sx={{ textTransform: "none", marginRight: "10px" }}
            >
              {t("deliveries.ok_gotit")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          margin: "10px",
          width: "50%",
        }}
      >
        <IconButton
          onClick={handleBackArrowButton}
          className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography className="edit-inventory-header-name">
          {t("deliveries.delivery")}
        </Typography>
      </Box>

      <Box sx={{ display: "flex", flexDirection: "column", padding: "15px" }}>
        <TabContext value={value}>
          <Box sx={{ marginLeft: "32px" }}>
            <TabList
              sx={{
                "& .MuiTabs-indicator": {
                  backgroundColor: "#f38bo8 !important", // Color for the tab indicator
                },
              }}
              onChange={handleChange}
              aria-label="lab API tabs example"
            >
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#f38b08 !important", // Color for active tab text
                  },
                }}
                label={
                  <Typography
                    sx={{
                      textTransform: "none",
                      fontFamily: "Poppins",
                    }}
                  >
                    {t("deliveries.basic_details")}
                  </Typography>
                }
                value="basicDetails"
              />
              <Tab
                sx={{
                  "&.Mui-selected": {
                    color: "#F38B08 !important",
                  },
                }}
                label={
                  <Typography sx={{ textTransform: "none" }}>
                    {t("deliveries.delivery_types")}
                  </Typography>
                }
                value="deliveryTypes"
              />
            </TabList>
          </Box>
          <TabPanel value="basicDetails">
            <DeliverySettings
              setValue={setValue}
              deliverySettingId={deliverySettingId}
              setDeliverySettingId={setDeliverySettingId}
              id={editId}
            />
          </TabPanel>
          <TabPanel value="deliveryTypes">
            <DeliveryRulesAndMap
              locationKey={locationKey}
              storeLocation={storeLocation}
              editId={editId}
              deliverySettingId={deliverySettingId}
            />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
}

export default DeliveryZone;
