import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const COURSE_LIST=gql`
query courseList($businessId:ID){
	courseList(businessId:$businessId){
	edges{
		node{courseId
		name
		productCount
		}
	}
		
	
	}
}
`

export const GET_COURSE_DATA=gql`
query Course($courseId:ID){
	course(courseId:$courseId) {
		courseId
		name
	}
			}
`