import {
  Box,
  Button,
  IconButton,
  Typography,
  Autocomplete,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import DeletePopup from "../popups/deletePopup";
import "./kdsSetupCreate.css";
import { useNavigate, useParams } from "react-router";
import { COURSE_LIST } from "../../graphicalQl/usequery/courseQuery";
import { useMutation, useQuery } from "@apollo/client";
import {
  FILTERED_STORE_LIST,
  GETTING_PAIRED_DEVICES_FOR_KDS,
  GET_UPDATED_DATA_FOR_KDS,
} from "../../graphicalQl/usequery/deviceManagementQuery";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";

import { UPATE_KDS_SETUP } from "../../graphicalQl/mutation/deviceMangementMutation";
import { SelectChangeEvent } from "@mui/material/Select";
import { useTranslation } from "react-i18next";

interface State {
  pairingId: string;
  kdsStationName: string;
  deviceId: string;
  paired_kds_device: {};
  selectedCourses: any[];
}

function KdsSetupCreate() {
  const {t}=useTranslation()
  const { editId } = useParams();
  const navigate = useNavigate();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [courseList, setCourseList] = useState<any[]>([]);
  const [storeName, setStoreName] = useState<string>("");
  const [kdsList, setKdsList] = useState<any[]>([]);
  const [pairedDevicesList, setPairedDevicesList] = useState<any[]>([]);
  const [disableAddKdsButton, setdisableAddKdsButton] =
    useState<boolean>(false);
  const [profileId, setProfileId] = useState<number | null>(null);

  const [newRow, setNewRow] = useState<State>({
    pairingId: "",
    kdsStationName: "",
    deviceId: "",
    paired_kds_device: {},
    selectedCourses: [],
  });

  const [updateKds] = useMutation(UPATE_KDS_SETUP, {
    onCompleted: (data) => {
      if(data){
        const {branchSettings}=data 
        if(branchSettings){
          const {success}=branchSettings
          if(success){
            navigate("/device/device-setup");

          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [FILTERED_STORE_LIST],
  });

  const filteredDataFromNullForKds = {
    businessId: +businessId,
    storeId: editId === undefined ? null : editId,
    deviceType: "KDS",
  };

  const filteredIdsFromKds = Object.fromEntries(
    Object.entries(filteredDataFromNullForKds).filter(
      ([_, value]) => value !== null
    )
  );

  const filteredDataFromNullforGettingData = {
    storeId: editId === undefined ? null : editId,
  };

  const filteredIdsFromfilteredDataFromNullforGettingData = Object.fromEntries(
    Object.entries(filteredDataFromNullforGettingData).filter(
      ([_, value]) => value !== null
    )
  );

  const {
    loading: courseListdataLoading,
    error: courseListError,
    data: courseListdata,
  } = useQuery(COURSE_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  const {
    loading: pairedDevicesLoading,
    error: pairedDevicesError,
    data: pairedDevicesdata,
  } = useQuery(GETTING_PAIRED_DEVICES_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKds,
  });
  const {
    loading: pairedKdsLoading,
    error: pairedKdsError,
    data: pairedKdsdata,
  } = useQuery(GET_UPDATED_DATA_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromfilteredDataFromNullforGettingData,
  });

  useEffect(() => {
    if (pairedKdsdata) {
      const { storeSettings } = pairedKdsdata;
      if (storeSettings) {
        const { kdsSettings } = storeSettings;
        const parsedData = JSON.parse(kdsSettings);
        const { courses_sequence, kds_station_data } = parsedData;
        if (kds_station_data) {
          const filteredData = kds_station_data.map((each: any) => ({
            kdsStationName: each["kds_station_name"],
            deviceId: each["paired_kds_device"]["device_id"],
            paired_kds_device: {
              deviceId: each["paired_kds_device"]["device_id"],
              deviceName: each["paired_kds_device"]["device_name"],
            },
            selectedCourses:
              each["courses"] &&
              each["courses"].map((each: any) => ({
                courseId: each["course_id"],
                courseName: each["course_name"],
              })),
          }));

          setPairedDevicesList(filteredData);
        }
        if (courses_sequence) {
          const filteredData = courses_sequence.map((each: any) => ({
            courseId: each.course_id,
            courseName: each.course_name,
          }));
          setCourseList(filteredData);
        }
      }
    }
  }, [pairedKdsdata]);
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
        })
      );
      const filterdDatatByEditId = requiredDataList.filter(
        (each: any) => each.storeId === editId
      );

      setStoreName(
        filterdDatatByEditId &&
          filterdDatatByEditId[0] &&
          filterdDatatByEditId[0].storeName
      );
    }
  }, [locationsData]);

  useEffect(() => {
    if (courseListdata) {
      const { courseList } = courseListdata;
      if (courseList) {
        const { edges } = courseList;
        const list =
          edges &&
          edges.map((each: any) => ({
            courseId: each.node.courseId,
            courseName: each.node.name,
          }));
        setCourseList(list);
      }
    }
  }, [courseListdata]);

  useEffect(() => {
    if (pairedDevicesdata) {
      const { pairedDevicesList } = pairedDevicesdata;
      if (pairedDevicesList) {
        const { edges } = pairedDevicesList;
        const list =
          edges &&
          edges.map((each: any) => ({
            pairingId: each.node.pairingId,
            deviceName: each.node.deviceName,
          }));
        setKdsList(list);
      }
    }
  }, [pairedDevicesdata]);

  const [addingKdsToggle, setAddingKdsToggle] = useState(false);
  const [deletePopupOpen, setDeletePopupOpen] = useState(false);

  // course functions

  // Function to handle the drag-and-drop reordering
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedItems = [...courseList];
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setCourseList(reorderedItems);
  };

  const handleCloseIcon = () => {
    navigate("/device/device-setup");
  };

  const handleAddKdsButton = () => {
    setAddingKdsToggle(true);
    setdisableAddKdsButton(true);
    setNewRow({
      pairingId: JSON.stringify(pairedDevicesList.length + 1),
      kdsStationName: "",
      deviceId: kdsList && kdsList[0] && kdsList[0].pairingId,
      paired_kds_device: {
        device_id: kdsList && kdsList[0] && kdsList[0].pairingId,
        device_name: kdsList && kdsList[0] && kdsList[0].deviceName,
      },
      selectedCourses: [courseList && courseList[0]],
    });
  };

  const handleDeleteIcon = (id: number) => {
    setDeletePopupOpen(true);
    setProfileId(id);
  };

  const handleDontRemoveButton = () => {
    setDeletePopupOpen(false);
    setProfileId(null);
  };

  const deleteYesRemoveButton = () => {
    setDeletePopupOpen(false);
    const filteredData = pairedDevicesList.filter(
      (_, index) => index !== profileId
    );
    setPairedDevicesList(filteredData);
    setProfileId(null); // Clearing the profileIndex after deletion if necessary
  };

  const handleAutocompleteChange = (
    index: number,
    event: React.ChangeEvent<{}>,
    newValue: any[]
  ) => {
    const updatedList = pairedDevicesList.map((each: any, idx: number) => {
      if (idx === index) {
        return { ...each, selectedCourses: newValue };
      }
      return each;
    });
    setPairedDevicesList(updatedList);
  };

  const handleKdsDeviceChange = (e: SelectChangeEvent<any>, index: number) => {
    const filteredItem = kdsList.find(
      (each: any) => each.pairingId === e.target.value
    );
    const updated = {
      device_id: filteredItem ? filteredItem.pairingId : "",
      device_name: filteredItem ? filteredItem.deviceName : "",
    };
    const filteredList = pairedDevicesList.map((each: any, idx: number) => {
      if (idx === index) {
        return {
          ...each,
          paired_kds_device: updated,
          deviceId: e.target.value,
        };
      }
      return each;
    });
    setPairedDevicesList(filteredList);
  };

  const handleKdsStationName = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    id: number
  ) => {
    const updatedList = pairedDevicesList.map((each: any, index: number) => {
      if (index === id) {
        return { ...each, kdsStationName: event.target.value };
      }
      return each;
    });
    setPairedDevicesList(updatedList);
  };

  const handleNewKdsName = (text: string) => {
    setNewRow({
      ...newRow,
      kdsStationName: text,
    });
  };

  const handleNewKdsDevice = (text: string) => {
    const filteredItem = kdsList.find((each: any) => each.pairingId === text);
    const updated = {
      device_id: filteredItem ? filteredItem.pairingId : "",
      device_name: filteredItem ? filteredItem.deviceName : "",
    };
    setNewRow({
      ...newRow,
      deviceId: text,
      paired_kds_device: updated,
    });
  };

  const handleNewRowCourseChange = (newValue: any[]) => {
    setNewRow({
      ...newRow,
      selectedCourses: newValue,
    });
  };

  const handleNewRowClearButton = () => {
    setNewRow({
      pairingId: "",
      kdsStationName: "",
      deviceId: "",
      paired_kds_device: {},
      selectedCourses: [],
    });
    setAddingKdsToggle(false);
    setdisableAddKdsButton(false);
  };

  const handleNewRowSaveButton = () => {
    setPairedDevicesList((prev) => [...prev, newRow]);
    setNewRow({
      pairingId: "",
      kdsStationName: "",
      deviceId: "",
      paired_kds_device: {},
      selectedCourses: [],
    });
    setAddingKdsToggle(false);
    setdisableAddKdsButton(false);
  };

  const hanldeTotalSaveButton = () => {
    const collectedData = {
      kds_station_data: pairedDevicesList.map((each: any) => ({
        kds_station_name: each.kdsStationName,
        paired_kds_device: each["paired_kds_device"],
        courses:
          each.selectedCourses &&
          each.selectedCourses.map((each: any) => ({
            course_id: each.courseId,
            course_name: each.courseName,
          })),
      })),
      courses_sequence: courseList.map((each: any) => ({
        course_id: each.courseId,
        course_name: each.courseName,
      })),
    };
    const filteredDataFromNullForKiosk = {
      storeId: editId && +editId,
      kdsSettings: JSON.stringify(collectedData),
    };
    const filteredIdsFromKiosk = Object.fromEntries(
      Object.entries(filteredDataFromNullForKiosk).filter(
        ([_, value]) => value !== null
      )
    );
    updateKds({ variables: filteredIdsFromKiosk });
   
  };

  return (
    <Box sx={{ padding: "15px" }}>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleDontRemoveButton}
        handleOpen={() => setDeletePopupOpen(true)}
        handleSaveButton={deleteYesRemoveButton}
        mainText="Are you sure,You want to Remove this Kds Station?"
        button1Text="Yes Remove"
        button2Text="Don't Remove"
        popUpwidth="35%"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          padding: "10px",
          marginBottom: "15px",
        }}
      >
        <IconButton
          onClick={handleCloseIcon}
          className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
        >
          <CloseIcon />
        </IconButton>
        <Typography className="kds-setup-create-header">
          {t("devices.KDS")} {t("devices.set_up")}
          <span className="kds-setup-create-sub-header">({storeName})</span>
        </Typography>
        <Box>
          <Button
            onClick={hanldeTotalSaveButton}
            sx={{ textTransform: "none" }}
            color="warning"
            variant="contained"
          >
            {t("devices.save")}
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          padding: "1%",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <Paper
          sx={{
            width: "85%",
            marginLeft: "4%",
            padding: "15px",
            borderLeft: "2px solid #AE9BE2",
          }}
        >
          <Typography className="kds-setup-create-arrange-text">
            {t("devices.arrange_the_following")}
          </Typography>
          <Box
            sx={{
              padding: "5px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <TableContainer sx={{ width: "90%", border: "2px solid #EEE" }}>
              <Table sx={{ width: "100%" }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{ flex: 1 }}
                      className="kds-setup-create-table-header"
                      align="center"
                    >
                      {t("devices.kds_station_name")}
                    </TableCell>
                    <TableCell
                      sx={{ flex: 1 }}
                      className="kds-setup-create-table-header"
                      align="center"
                    >
                      {t("devices.paired_kds_device")}
                    </TableCell>
                    <TableCell
                      sx={{ flex: 1 }}
                      className="kds-setup-create-table-header"
                      align="center"
                    >
                      {t("devices.select_courses")}
                    </TableCell>
                    <TableCell
                      sx={{ flex: 1 }}
                      className="kds-setup-create-table-header"
                      align="center"
                    ></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {pairedDevicesList.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    >
                      <TableCell
                        className="kds-setup-create-table-row"
                        align="center"
                        component="th"
                        scope="row"
                      >
                        <TextField
                          color="warning"
                          onChange={(e) => handleKdsStationName(e, index)}
                          value={row.kdsStationName}
                          id="outlined-basic"
                          variant="outlined"
                        />
                      </TableCell>
                      <TableCell
                        className="kds-setup-create-table-row"
                        align="center"
                        component="th"
                        scope="row"
                      >
                        <FormControl sx={{ width: "60%" }}>
                          <Select
                            color="warning"
                            labelId="assign-role-label"
                            onChange={(e) => handleKdsDeviceChange(e, index)}
                            value={row.deviceId}
                          >
                            {kdsList &&
                              kdsList.map((each: any) => (
                                <MenuItem
                                  key={each.pairingId}
                                  value={each.pairingId}
                                >
                                  {each.deviceName}
                                </MenuItem>
                              ))}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        className="kds-setup-create-table-row"
                        align="center"
                      >
                        <Autocomplete
                          sx={{ maxWidth: "40vw" }}
                          multiple
                          id="tags-outlined"
                          value={row.selectedCourses}
                          options={courseList && courseList}
                          getOptionLabel={(option) => option.courseName}
                          onChange={(e, newValue) =>
                            handleAutocompleteChange(index, e, newValue)
                          }
                          filterSelectedOptions
                          renderInput={(params) => (
                            <TextField
                              color="warning"
                              {...params}
                              label={t("devices.select_courses")}
                              //@ts-ignore
                              placeholder={t("sidebar.courses")} 
                            />
                          )}
                        />
                      </TableCell>
                      <TableCell>
                      <Tooltip title={t("buttons.delete")} placement="top" arrow>
                        <IconButton onClick={() => handleDeleteIcon(index)}>
                          <DeleteIcon />
                        </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>

          {addingKdsToggle && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "8px",
                marginTop: "8px",
                marginLeft: "5%",
                width: "100%",
              }}
            >
              <TextField
                color="warning"
                sx={{ marginRight: "1%", width: "25%" }}
                name="kdsName"
                onChange={(e) => handleNewKdsName(e.target.value)}
                value={newRow.kdsStationName}
                //@ts-ignore
                placeholder={t("devices.select_kds")}
              />
              <FormControl sx={{ width: "26%", marginRight: "1%" }}>
                <InputLabel
                  className="create-a-use-role-label"
                  id="assign-role-label"
                >
                  {t("devices.select_kds")}
                </InputLabel>
                <Select
                  sx={{ marginRight: "2%" }}
                  color="warning"
                  labelId="assign-role-label"
                  label={<Typography>{t("devices.select_kds")}</Typography>}
                  value={newRow.deviceId}
                  onChange={(e) => handleNewKdsDevice(e.target.value)}
                >
                  {kdsList.map((each: any) => (
                    <MenuItem key={each.pairingId} value={each.pairingId}>
                      {each.deviceName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <Autocomplete
                sx={{ width: "25%" }}
                multiple
                id="tags-outlined"
                options={courseList || []}
                getOptionLabel={(option) => option?.courseName || ""}
                filterSelectedOptions
                value={newRow.selectedCourses.filter(
                  (course) => course && course.courseName
                )}
                onChange={(e, newValue) =>
                  handleNewRowCourseChange(
                    newValue.filter((course) => course && course.courseName)
                  )
                }
                renderInput={(params) => (
                  <TextField
                    color="warning"
                    {...params}
                    label={t("devices.select_courses")}
                    //@ts-ignore
                    placeholder={t("sidebar.courses")}
                  />
                )}
              />

              <Box
                sx={{
                
                  display: "flex",
                  gap:"10px",
                  alignItems: "center",
                  marginLeft: "1%",
                }}
              >
                <Button
                  onClick={handleNewRowClearButton}
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="outlined"
                >
                  {t("buttons.clear")}
                </Button>
                <Button
                  onClick={handleNewRowSaveButton}
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Box>
            </Box>
          )}

          <Button
            disabled={disableAddKdsButton}
            onClick={handleAddKdsButton}
            className={
              !disableAddKdsButton
                ? "kds-setup-create-active kds-setup-create-table-add-new"
                : "kds-setup-create-inactive kds-setup-create-table-add-new"
            }
            sx={{ textTransform: "none", marginLeft: "4.5%", marginTop: "1%" }}
            color="warning"
          >
            {t("devices.add_new_kds_station")}
          </Button>
        </Paper>
        <Paper
          sx={{
            width: "50%",
            marginLeft: "4%",
            marginTop: "2%",
            padding: "20px",
            borderLeft: "2px solid #9BDAE2",
          }}
        >
          <Typography className="kds-setup-create-table-header">
            {t("devices.sequence_wise")}{" "}
          </Typography>
          <Typography className="kds-setup-create-set-sequence-text">
            {t("devices.set_the_sequence")}
          </Typography>

          <Box
            sx={{ padding: "1%", marginTop: "2%", border: "2px solid #EEE" }}
          >
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="list">
                {(provided) => (
                  <Box {...provided.droppableProps} ref={provided.innerRef}>
                    {courseList.map((item, index) => (
                      <Draggable
                        key={item.courseId}
                        draggableId={item.courseId}
                        index={index}
                      >
                        {(provided) => (
                          <Typography
                            className="kds-setup-create-drag-text"
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            {item.courseName}
                          </Typography>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Paper>
      </Box>
      <Box
        sx={{
          marginTop: 5,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></Box>
      {/* <Box sx={{marginTop:5,display:"flex",justifyContent:"center",alignItems:"center"}}>
    <Paper sx={{width:"70%",padding:"20px"}}>
      <Typography className='kds-setup-create-table-header'>{t("devices.prioritisation")} </Typography>
      <Typography className='kds-setup-create-set-sequence-text'>{t("devices.admin_can_set")}</Typography>
      <Paper   sx={{padding:"20px",marginTop:"15px"}} elevation={3}>
      <DragDropContext onDragEnd={handleDragEndOfOrders}>
      <Droppable droppableId="list">
        {(provided) => (
          <Box {...provided.droppableProps} ref={provided.innerRef}>
            {orders.map((item, index) => (
              <Draggable key={item.id} draggableId={item.order} index={index}>
                {(provided) => (
                  <Box
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    ref={provided.innerRef}
                  >
                    <Box sx={{display:"flex",alignItems:"center",width:"80%"}}>
                    <Typography sx={{width:"30%"}} className='kds-setup-create-drag-text'>{item.order}</Typography>
                    <Typography className='kds-setup-create-drag-text2'>{item.description}</Typography>
                    </Box>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </Box>
        )}
      </Droppable>
    </DragDropContext>      
       </Paper>
    </Paper>
   </Box> */}
    </Box>
  );
}

export default KdsSetupCreate;
