import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const CREATE_BUNDLE = gql`
mutation CreateBundle($businessId:Int!,$name:String,$amount:Float!,$locations:JSONString,$description:String,$image:String,$groups:JSONString,$discountPercent:Int,$amountAfterDiscount:Float,$isPos:Boolean,$isOnline:Boolean,
  $bgColourCode:String,
  $isSetMeals:Boolean,
	$isDinein:Boolean,
	$isDelivery:Boolean,
	$isCarry:Boolean,
	$isCollection:Boolean,
){
  createBundle(
    businessId:$businessId,
    name:$name,
    amount:$amount,
    description:$description,
    image:$image,
    bgColourCode:$bgColourCode,
    groups:$groups,
    locations:$locations
    discountPercent:$discountPercent
    amountAfterDiscount:$amountAfterDiscount
       isPos:$isPos
       isOnline:$isOnline
       isSetMeals:$isSetMeals,
	isDinein:$isDinein,
	isDelivery:$isDelivery,
	isCarry:$isCarry,
	isCollection:$isCollection,
    ) {
      message
      bundle{bundleId
        name
      }
      success
      }
     
       
      
    }
  
    `


export const DELETE_BUNDLE = gql`
    mutation ($bundleIds:JSONString!){
      deleteBundles(
        bundleIds:$bundleIds,
        
      ) {
          message
          
          success
        }
      }`

export const DUPLICATE_BUNDLE = gql`
   mutation($bundleId:ID!) {
    duplicateBundle(
      bundleId:$bundleId,
      
      ) {
        message
      
        success
        }
      }
   `

export const BUNDLE_STATUS_CHANGE = gql`
mutation BundleStatusChange($bundleId:ID!,$bundleStatus:String) {
  bundleStatusChange(
    bundleId:$bundleId,
    bundleStatus:$bundleStatus
  ) {
    bundle{bundleId
    bundleStatus}
    success
    errorMessage
    
  }
    
  }
`

export const ADD_ITEM_BUNDLE = gql`
mutation  AddItemToBundle($bundleId:Int!,$isPreselected:Boolean,$productId:Int,$groupName:String,$additionalAmount:Float){
  addItemToBundle(
    bundleId:$bundleId,
    isPreselected:$isPreselected,
    productId:$productId
    
    groupName:$groupName
    additionalAmount:$additionalAmount
  ) {
      message
      
      success
    }
  }
`

export const ADD_GROUP_IN_BUNDLE = gql`
mutation AddGroupToBundle($bundleId:Int!,$group:JSONString){
  addGroupToBundle(
    bundleId:$bundleId, 
    group:$group
    
    ) {
    message
    success	
      
      }
    
      }
`

export const UPADTE_BUNDLE_IN_EDIT = gql`
mutation UpdateBundle($bundleId:ID!,$name:String,$discountPercent:Int,$amount:Float,$image:String,$amountAfterDiscount:Float,$isPos:Boolean,$isOnline:Boolean,$description:String,$bgColourCode:String,
  $isSetMeals:Boolean,
	$isDinein:Boolean,
	$isDelivery:Boolean,
	$isCarry:Boolean,
	$isCollection:Boolean,
){
  updateBundle(
    bundleId:$bundleId,
    name:$name,
    discountPercent:$discountPercent,
    amount:$amount
    image:$image,
    bgColourCode:$bgColourCode,
    amountAfterDiscount:$amountAfterDiscount,
    isPos:$isPos,
    isOnline:$isOnline,
    description:$description,
    isSetMeals:$isSetMeals,
    isDinein:$isDinein,
    isDelivery:$isDelivery,
    isCarry:$isCarry,
    isCollection:$isCollection,
  ) {
      errorMessage
      bundle{bundleId}
      success
      }
     
       
      
    }
`

export const DELETE_GROUP_IN_BUNDLE = gql`
mutation RemoveGroupFromBundle($bundleId:Int!,$groupName:String){
  removeGroupFromBundle(
    bundleId:$bundleId,
    
    groupName:$groupName
    
  ) {
      message
      
      success
    }
  }
`