import * as React from "react";
import { useImperativeHandle, forwardRef } from "react";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import addImage from "../../../assets/image-avatar.png";
import image2 from "../../../assets/cross.png";
// import EditIcon from "@mui/icons-material/Edit";

import EditIcon from "../../../../src/assets/edit.svg";
import Trash from "../../../../src/assets/trash.svg";

import uuid from "react-uuid";
import "./image.css";
import { useEffect } from "react";
import { t } from "i18next";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import { useMutation, useQuery } from "@apollo/client";
import { UPLOAD_IMAGE } from "../../../graphicalQl/mutation/imageMutation";
import { GET_IMAGE } from "../../../graphicalQl/usequery/imageQuery";
interface ImageData {
  id: string;
  img: string;
}

interface Colour {
  id: number;
  color: string;
}

interface Props {
  handleColor: (value: string | null) => void;
  handleImage: (value: string | null) => void;
  colorCodeFromApi: string | null;
  imageFromApi: string | null;
  heightValue: number;
  imageType?: string;
  textCondition?: boolean;
  borderRadius?: string;
  width?: string;
  image?: string;
  isColorNotAvailable?: boolean;
  customerId?: string;
}
const ImageDrawer = forwardRef((props: Props, ref) => {
  const {
    handleColor,
    isColorNotAvailable,
    handleImage,
    customerId,
    colorCodeFromApi,
    imageFromApi,
    imageType = "catlog",
    textCondition = true,
    borderRadius = "4px",
    width = "100%",
    image = addImage,
  } = props;

  const [state, setState] = React.useState({
    right: false,
  });
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, right: open });
    };

  const [imageValue, setImageValue] = React.useState<string | null>(null);
  const [imageData, setImageData] = React.useState<ImageData[]>([]);

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageValue(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const { data: getImage } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: {
      businessId: +businessId,
      imageType: imageType,
      customerId: customerId && +customerId,
    },
  });

  useEffect(() => {
    if (getImage && getImage.imageList) {
      const list = JSON.parse(getImage.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData(list);
    }
  }, [getImage]);
  // const getImageList = () => {
  //   const apiUrl = `http://51.75.170.243:5010/media/get_images?business=business-${businessId}`;
  //   axios.get(apiUrl)
  //     .then(response => {
  //       // Handle the response data here

  //       if (response.data && response.data.images) {
  //         const list = response.data.images.map((each: any) => ({
  //           img: each,
  //           id: uuid(),
  //         }));

  //         setImageData(list)
  //       }

  //     })
  //     .catch(error => {
  //       // Handle errors here
  //       //console.error('Error:', error);
  //     });
  // }
  // useEffect(() => {
  //   getImageList()
  // }, [])
  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    context: { clientName: "mediaContext" },
  });

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const apiUrl = 'http://51.75.170.243:5010/media/base64_image_upload';
  //     const requestData = {
  //       "image": imageValue,
  //       "business_id": businessId,
  //     };

  //     try {
  //       const response: AxiosResponse = await axios.post(apiUrl, requestData, {

  //       });

  //       if (response.data) {
  //         const { access } = response.data;

  //       }

  //       setImageUrl(response.data && response.data.image_url)
  //       getImageList();
  //     }
  //     catch (error) {

  //       if (axios.isAxiosError(error)) {

  //         const axiosError: AxiosError = error;
  //         if (axiosError.response) {

  //           console.error('Server responded with an error:', axiosError.response.data);
  //         } else if (axiosError.request) {

  //           console.error('No response received from the server');
  //         } else {

  //         }
  //       }
  //     }
  //   };
  //   if (imageValue !== null) {
  //     fetchData();
  //   }

  // }, [imageValue]);

  const fetchData = () => {
    // const apiUrl = 'http://51.75.170.243:5010/media/base64_image_upload';
    // const requestData = {
    //   "image":imageValue,
    //   "business_id":businessId,
    // };

    // try {
    //   const response: AxiosResponse = await axios.post(apiUrl, requestData, {

    //   });

    //   if (response.data) {
    //     const { access } = response.data;

    //   }

    //   setImageUrl(response.data&& response.data.image_url)
    //   getImageData()
    // }
    // catch (error) {

    //   if (axios.isAxiosError(error)) {

    //     const axiosError: AxiosError = error;
    //     if (axiosError.response) {

    //       console.error('Server responded with an error:', axiosError.response.data);
    //     } else if (axiosError.request) {

    //       console.error('No response received from the server');
    //     } else {

    //     }
    //   }
    // }
    uploadImage({
      variables: {
        businessId: +businessId,
        image: imageValue,
        imageType: imageType,
        customerId: customerId && +customerId,
      },
      onCompleted: (data) => {
        if (data) {
          const { uploadImage } = data;
          if (uploadImage) {
            const { success } = uploadImage;
            if (success) {
            }
          }
        }
      },
      refetchQueries: [GET_IMAGE],
    });
  };
  React.useEffect(() => {
    if (imageValue !== null) {
      fetchData();
    }
  }, [imageValue]);

  const [selectImg, setSelectImag] = React.useState<string | null>(null);
  const [selectColor, setSelectColor] = React.useState<string | null>(null);

  const handleSelectImg = (id: string) => {
    const image = imageData.filter((each) => each.id === id)[0].img;

    setSelectImag(image);
    setSelectColor(null);
  };

  const colorList: Colour[] = [
    { id: 1, color: "#D9D9D9" },
    { id: 2, color: "#A56D66" },
    { id: 3, color: "#F6C65B" },
    { id: 4, color: "#00AE26" },
    { id: 5, color: "#009BDE" },
    { id: 6, color: "#5546CB" },
    { id: 7, color: "#DA4453" },
  ];

  const handleColorId = (color: string) => {
    setSelectColor(color);
    setSelectImag(null);
  };

  const [finalColor, setFinalColor] = React.useState<string | null>(null);
  const [finalImage, setFinalImage] = React.useState<string | null>(null);

  const handleNull = () => {
    setFinalColor(null);
    setFinalImage(null);
    setSelectImag(null);
    setSelectColor(null);
  };

  const handleDoneButton = () => {
    setFinalColor(selectColor);
    setFinalImage(selectImg);

    if (selectImg) {
      handleImage(selectImg);
    } else {
      handleColor(selectColor);
    }
  };

  const deletefinalImg = () => {
    setFinalImage(null);
    handleImage(null);
    setSelectColor(null);
    setSelectImag(null);
  };

  const deletefinalcolor = () => {
    setFinalColor(null);
    handleColor(null);
    setSelectColor(null);
    setSelectImag(null);
  };

  useEffect(() => {
    if (colorCodeFromApi) {
      setFinalColor(colorCodeFromApi);
      setFinalImage(null);
      handleColor(colorCodeFromApi);
    }
  }, [colorCodeFromApi]);

  useEffect(() => {
    if (imageFromApi) {
      setFinalImage(imageFromApi);
      setFinalColor(null);
      handleImage(imageFromApi);
    }
  }, [imageFromApi]);

  useImperativeHandle(ref, () => ({
    handleNull,
  }));
  const [showDelete, setShowDelete] = React.useState(false);
  return (
    <div style={{ width: width, height: "100%", borderRadius: borderRadius }}>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          {finalColor ? (
            <Box
              sx={{
                width: width,
                height: "100%",
                textAlign: "center",
                borderRadius: borderRadius,
              }}
              onMouseEnter={() => {
                setShowDelete(true);
              }}
              onMouseLeave={() => {
                setShowDelete(false);
              }}
            >
              <Box
                sx={{
                  width: width,
                  height: textCondition ? "83%" : "100%",
                  backgroundColor: finalColor,
                  opacity: "0.6",
                  borderRadius: borderRadius,
                  backgroundSize: "cover",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "flex-end",
                  position: "relative",
                }}
                onClick={toggleDrawer(true)}
              ></Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                  marginTop: "3px",
                }}
              >
                {textCondition &&
                  (finalColor ? (
                    // <IconButton
                    //   className="edit-text"
                    //   onClick={toggleDrawer(true)}
                    //   sx={{ cursor: "pointer", marginLeft: "10px" }}
                    // >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#f5f5f5", // Light grey background
                        border: "1px solid #d3d3d3", // Light border
                        borderRadius: "4px", // Slight rounding of the edges
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center", // Center icon vertically
                        justifyContent: "center", // Center icon horizontally
                      }}
                      onClick={toggleDrawer(true)}
                    >
                      <img
                        alt="edit"
                        src={EditIcon}
                        style={{ width: "12px", height: "12px" }}
                      />
                    </Box>
                  ) : (
                    <Typography className="edit-text"></Typography>
                  ))}
                {finalColor && (
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#f5f5f5", // Light grey background
                      border: "1px solid #d3d3d3", // Light border
                      borderRadius: "4px", // Slight rounding of the edges
                      display: "flex",
                      alignItems: "center", // Center icon vertically
                      justifyContent: "center", // Center icon horizontally
                      cursor: "pointer",
                    }}
                    onClick={deletefinalcolor}
                  >
                    <img
                      src={Trash}
                      style={{ width: "12px", height: "12px" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            // <Box
            //   sx={{
            //     height: "100%",
            //     textAlign: "center",
            //     // backgroundSize: "cover",
            //     position: "relative",
            //     borderRadius: borderRadius,
            //     paddingBottom: "10px",
            //     // overflow: "hidden",
            //   }}
            //   onMouseEnter={() => {
            //     setShowDelete(true);
            //   }}
            //   onMouseLeave={() => {
            //     setShowDelete(false);
            //   }}
            // >
            //   <img
            //     src={finalImage || image}
            //     alt="product"
            //     style={{
            //       width: width,
            //       height: !textCondition ? "100%" : "80%",
            //       // backgroundSize: "cover",
            //       borderRadius: borderRadius,
            //       // Ensure the image width is 100% of the Box
            //       // Ensure the image height is 100% of the Box
            //       objectFit: "contain",
            //     }}
            //     onClick={toggleDrawer(true)}
            //   />

            //   <Box
            //     sx={{
            //       display: "flex",
            //       justifyContent: "flex-end",
            //     }}
            //   >
            //     {textCondition &&
            //       (finalImage ? (
            //         <IconButton
            //           className="edit-text"
            //           onClick={toggleDrawer(true)}
            //           sx={{ cursor: "pointer", marginLeft: "10px" }}
            //         >
            //           {/* <EditIcon /> */}
            //           <img src={EditIcon} />
            //         </IconButton>
            //       ) : (
            //         <Typography className="edit-text"></Typography>
            //       ))}
            //     {finalImage && (
            //       <Box
            //       // sx={{
            //       //   position: "absolute",
            //       //   top: 0,
            //       //   bottom: 0,
            //       //   right: 0,
            //       //   display: "flex",
            //       //   justifyContent: "flex-end",
            //       //   alignItems: "flex-start",

            //       //   cursor: "pointer",
            //       //   backgroundColor: "#FFFFFF",
            //       // }}
            //       >
            //         {/* Add your icon here */}
            //         {/* <IconButton color="warning" onClick={deletefinalImg}>
            //           <DeleteIcon
            //             fontSize={textCondition ? "small" : "small"}
            //           />
            //         </IconButton> */}
            //         <img src={Trash} />
            //       </Box>
            //     )}
            //   </Box>
            // </Box>
            <Box
              sx={{
                height: "100%",
                textAlign: "center",
                position: "relative",
                borderRadius: borderRadius,
                padding: "0  2px 0 0",
              }}
              onMouseEnter={() => {
                setShowDelete(true);
              }}
              onMouseLeave={() => {
                setShowDelete(false);
              }}
            >
              <img
                src={finalImage || image}
                alt="product"
                style={{
                  width: width,
                  height: !textCondition ? "100%" : "80%",
                  objectFit: "contain",
                  borderRadius: borderRadius,
                }}
                onClick={toggleDrawer(true)}
              />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: "5px",
                }}
              >
                {textCondition &&
                  (finalImage ? (
                    // <IconButton
                    //   className="edit-text"
                    //   onClick={toggleDrawer(true)}
                    //   sx={{ cursor: "pointer", marginLeft: "10px" }}
                    // >
                    <Box
                      sx={{
                        width: "20px",
                        height: "20px",
                        backgroundColor: "#f5f5f5", // Light grey background
                        border: "1px solid #d3d3d3", // Light border
                        borderRadius: "4px", // Slight rounding of the edges
                        display: "flex",
                        cursor: "pointer",
                        alignItems: "center", // Center icon vertically
                        justifyContent: "center", // Center icon horizontally
                      }}
                      onClick={toggleDrawer(true)}
                    >
                      <img
                        alt="edit"
                        src={EditIcon}
                        style={{ width: "12px", height: "12px" }}
                      />
                    </Box>
                  ) : (
                    <Typography className="edit-text"></Typography>
                  ))}
                {finalImage && (
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      backgroundColor: "#f5f5f5", // Light grey background
                      border: "1px solid #d3d3d3", // Light border
                      borderRadius: "4px", // Slight rounding of the edges
                      display: "flex",
                      alignItems: "center", // Center icon vertically
                      justifyContent: "center", // Center icon horizontally
                      cursor: "pointer",
                    }}
                    onClick={deletefinalImg}
                  >
                    <img
                      src={Trash}
                      style={{ width: "12px", height: "12px" }}
                    />
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Drawer anchor={anchor} open={state[anchor]}>
            {/* {list(anchor)} */}
            <Box sx={{ width: "45.07vw" }}>
              <Box sx={{ padding: "12px 0.83vw", backgroundColor: "#F5F5F5" }}>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <IconButton
                    className="exit-container"
                    onClick={toggleDrawer(false)}
                  >
                    <CloseIcon className="exitIcon" />
                  </IconButton>
                  <Box sx={{ width: "74%", textAlign: "center" }}>
                    <Typography className="image-text-style">
                      {t("image.upload_img")}
                    </Typography>
                    <Typography className="image-para">
                      {t("image.image_text_2")}
                    </Typography>
                  </Box>
                  <Button
                    className="done-button"
                    sx={{ textTransform: "none" }}
                    onClick={() => {
                      handleDoneButton();
                      setState({ ...state, right: false });
                    }}
                  >
                    {t("image.done")}
                  </Button>
                </Box>
              </Box>
              <Box
                sx={{
                  maxHeight: "85vh",
                  overflowY: "scroll",
                  WebkitOverflowScrolling: "touch",
                  "&::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {!isColorNotAvailable && (
                  <>
                    <Box className="color-container">
                      <Typography className="color-text">
                        {t("image.color")}
                      </Typography>

                      <List className="colour-list-box">
                        {colorList.map((each) => (
                          <ListItem
                            key={each.id}
                            sx={{
                              height: "64px",
                              width: "64px",
                              backgroundColor: each.color,
                              opacity: "0.6",
                              borderRadius: "4px",
                            }}
                            onClick={() => handleColorId(each.color)}
                          >
                            {/* <Box ></Box> */}
                            {each.color === selectColor && (
                              <div
                                style={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  width: "100%",
                                  height: "100%", // Adjusted height to cover the ListItem
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                {/* Add your right icon here */}
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                  fill="none"
                                >
                                  <path
                                    d="M16.5 29.6469L10.1383 23.2852C9.42334 22.5702 8.26834 22.5702 7.55334 23.2852C6.83834 24.0002 6.83834 25.1552 7.55334 25.8702L15.2167 33.5335C15.9317 34.2485 17.0867 34.2485 17.8017 33.5335L37.1983 14.1369C37.9133 13.4219 37.9133 12.2669 37.1983 11.5519C36.4833 10.8369 35.3283 10.8369 34.6133 11.5519L16.5 29.6469Z"
                                    fill="#F9F9F9"
                                  />
                                </svg>
                              </div>
                            )}
                          </ListItem>
                        ))}
                      </List>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        alignitems: "center",
                        justifyContent: "center",
                        gap: "10px",
                        alignSelf: "stretch",
                      }}
                    >
                      <Divider sx={{ width: "40%" }} />
                      <Typography>or</Typography>
                      <Divider sx={{ width: "40%" }} />
                    </Box>
                  </>
                )}

                <link
                  rel="icon"
                  href="https://res.cloudinary.com/dkn4dqhma/image/upload/v1726037360/bx-12/business_logo/rqv6yscb4vflnte6sghw.ico"
                  type="image/x-icon"
                />

                <Box sx={{ marginTop: "12px" }} className="imageList-container">
                  <Typography className="color-text">
                    {t("image.image")}
                  </Typography>

                  <ImageList
                    sx={{
                      width: "100%",

                      overflowY: "scroll",
                      WebkitOverflowScrolling: "touch",
                      "&::-webkit-scrollbar": {
                        display: "none",
                      },
                    }}
                    cols={5}
                  >
                    <ImageListItem
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "8px",
                        height: "104px",
                        border: " 1px dashed var(--prime-orange-500, #FCE2C2)",
                        background: "rgba(251, 251, 251, 0.70)",
                      }}
                    >
                      <div style={{ width: "30%" }}>
                        <img
                          src={image2}
                          alt="icon"
                          style={{ backgroundSize: "cover" }}
                          onClick={() => {
                            const input =
                              document.getElementById("image-upload-input");
                            if (input) {
                              input.click();
                            }
                          }}
                        />
                        {/* {
                 imageValue===null ? (<Typography className="create-product-img-text">{t("menus.create_menu.add_img")}</Typography>):("")
               } */}
                      </div>

                      <input
                        type="file"
                        accept="image/png"
                        hidden
                        id="image-upload-input"
                        onChange={handleImageUpload}
                      />
                    </ImageListItem>
                    {imageData &&
                      imageData.map((item) => (
                        <ImageListItem
                          key={item.img}
                          onClick={() => handleSelectImg(item.id)}
                          sx={{
                            height: "104px !important",
                            borderRadius: "8px",
                            position: "relative",
                          }}
                        >
                          {/* <img
                            srcSet={`${item.img}?w=104&h=104&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item.img}?w=104&h=104&fit=crop&auto=format`}
                            alt={item.id}
                            loading="lazy"
                            style={{
                              borderRadius: "8px",
                              height: "104px",
                              objectFit: "contain",
                              position: "relative",
                            }}
                          />
                           */}

                          <Box
                            sx={{
                              position: "relative",
                              width: "104px",
                              height: "104px",
                            }}
                          >
                            <img
                              src={item.img}
                              alt={item.id}
                              loading="lazy"
                              style={{
                                borderRadius: "8px",
                                objectFit: "contain",
                                width: "100%",
                                aspectRatio: "1 / 1",
                                maxWidth: "104px",
                                transition: "transform 0.3s ease",
                              }}
                            />
                            {selectImg === item.img ? (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  width: "100%",
                                  height: "104px",
                                  display: "flex",
                                  padding: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  background: "rgba(0, 0, 0, 0.30)",
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="44"
                                  height="44"
                                  viewBox="0 0 44 44"
                                  fill="none"
                                >
                                  <path
                                    d="M16.5 29.6469L10.1383 23.2852C9.42334 22.5702 8.26834 22.5702 7.55334 23.2852C6.83834 24.0002 6.83834 25.1552 7.55334 25.8702L15.2167 33.5335C15.9317 34.2485 17.0867 34.2485 17.8017 33.5335L37.1983 14.1369C37.9133 13.4219 37.9133 12.2669 37.1983 11.5519C36.4833 10.8369 35.3283 10.8369 34.6133 11.5519L16.5 29.6469Z"
                                    fill="#F9F9F9"
                                  />
                                </svg>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  position: "absolute",
                                  top: 0,
                                  right: 0,
                                  width: "100%",
                                  height: "104px",
                                  display: "flex",
                                  padding: "10px",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  gap: "10px",
                                  borderRadius: "8px",
                                  cursor: "pointer",
                                  transition: "background-color 0.3s ease",
                                  "&:hover": {
                                    background: "rgba(0, 0, 0, 0.30)",
                                  },
                                }}
                                onMouseEnter={(e) => {
                                  const img = e.currentTarget
                                    .previousElementSibling as HTMLImageElement | null;
                                  if (img && img instanceof HTMLImageElement) {
                                    img.style.transform = "scale(1.05)";
                                  }
                                }}
                                onMouseLeave={(e) => {
                                  const img = e.currentTarget
                                    .previousElementSibling as HTMLImageElement | null;
                                  if (img && img instanceof HTMLImageElement) {
                                    img.style.transform = "scale(1)";
                                  }
                                }}
                              />
                            )}
                          </Box>
                        </ImageListItem>
                      ))}
                  </ImageList>
                </Box>
              </Box>
            </Box>
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
});
export default ImageDrawer;
