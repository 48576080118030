import { styled } from "@mui/system";
import CustomCard from "../customCard";
import { Monthlyplans } from "../../paymentCardInfo";
import React from "react";

const CardsWrapper = styled("div")({
  marginTop: "30px",
  padding: "10px",
  display: "flex",
  gap: "24px",
  justifyContent: "space-between",
  position: "relative",
  zIndex: 9999,
});

interface State{
  setAddOnsSelectors:any
}

const MonthlyPackages = (props:State) => {
  const {setAddOnsSelectors}=props;
  return (
    <CardsWrapper
      sx={{
        flexWrap: { xs: "wrap", lg: "nowrap" },
        justifyContent: "center",
      }}
    >
      {Monthlyplans?.map((plan:any, index:any) => (
        <CustomCard setAddOnsSelectors={setAddOnsSelectors} key={index} plan={plan} />
      ))}
    </CardsWrapper>
  );
};

export default MonthlyPackages;
