import * as React from "react";
import Modal from "@mui/material/Modal";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useDispatch, useSelector } from "react-redux";
import { setMainData } from "../reduxReducers/recieptReducer";
import { useNavigate } from "react-router";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  RECIEPT_LIST_QUERY,
  RECIEPT_NAME_QUERY,
} from "../../graphicalQl/usequery/recieptQuery";
import { RECIEPT_UPDATE_MUTATION } from "../../graphicalQl/mutation/recieptMutation";
import { RECIEPT_DUPLICATE } from "../../graphicalQl/mutation/recieptMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 720,
  bgcolor: "background.paper",
  boxShadow: 24,
  padding: "20px 32px",
  gap: "20px",
  display: "flex",
  flexDirection: "column",
};

interface Props {
  modalOpen: boolean;
  handleClose: () => void;
  editId: string | null;
}
//write validation schema using yup
const validationSchemaPart1 = Yup.object({
  recieptName: Yup.string().required("Name is required"),
  recieptType: Yup.string().required("Type is required"),
});

interface ModalUiProps {
  onClose: () => void;
}

export default function RecieptNameModal(props: Props) {
  const { modalOpen, handleClose, editId } = props;
  const dispatch = useDispatch();

  const businessId = sessionStorage.getItem("businessId");
  // const [open, setOpen] = React.useState(modalOpen);

  const navigate = useNavigate();
  const initialState = {
    recieptName: "",
    recieptType: "billTemplate",
    recieptInUse: "INUSE",
  };

  const [duplicate, setDuplicate] = React.useState(false);
  const { t } = useTranslation();

  const handleExit = () => {
    formik.resetForm();
  };

  const [checkDuplicate] = useMutation(RECIEPT_DUPLICATE, {
    context: { clientName: "businessClient" },
    onCompleted: (data) => {
      if (data) {
        setDuplicate(data.duplicateReceiptNameCheck.duplicate);
      }
    },
  });

  // function ModalUi({ onClose }: ModalUiProps) {
  //     const [editMode, setEditMode] = React.useState(false);

  //     const formik = useFormik({
  //         initialValues: initialState,
  //         validationSchema: validationSchemaPart1,
  //         onSubmit: (values) => {
  //             if (editId) {
  //                 if (editMode) {
  //                     dispatch(setMainData("recieptInUse", values.recieptInUse));
  //                     dispatch(setMainData("recieptType", values.recieptType));
  //                     dispatch(setMainData("recieptName", values.recieptName));
  //                     navigate(`/${editId}/edit/reciept/builder`)
  //                     updateReciept({ variables: { receiptId: editId && +editId, receiptType: formik.values.recieptType, receiptName: formik.values.recieptName, receiptStatus: formik.values.recieptInUse } })

  //                 }
  //                 else {
  //                     updateReciept({ variables: { receiptId: editId && +editId, receiptType: formik.values.recieptType, receiptName: formik.values.recieptName, receiptStatus: formik.values.recieptInUse } })
  //                     handleClose();
  //                 }
  //             }
  //             else {
  //                 dispatch(setMainData("recieptInUse", values.recieptInUse));
  //                 dispatch(setMainData("recieptType", values.recieptType));
  //                 dispatch(setMainData("recieptName", values.recieptName));
  //                 navigate("/reciept/builder")

  //             }

  //         },
  //     })

  //     const handleDuplicate = () => {
  //         checkDuplicate({ variables: { businessId: businessId && +businessId, receiptName: formik.values.recieptName } })
  //     }

  //     const { data: recieptData } = useQuery(RECIEPT_NAME_QUERY, {
  //         variables: { receiptId: editId && +editId },
  //         context: { clientName: "businessClient" },
  //         fetchPolicy: "cache-and-network"
  //     });

  //     React.useEffect(() => {

  //         if (recieptData && recieptData.receipt) {

  //             formik.setValues({
  //                 recieptName: recieptData.receipt.receiptName,
  //                 recieptType: recieptData.receipt.receiptType,
  //                 recieptInUse: recieptData.receipt.receiptStatus

  //             })

  //         }

  //     }, [recieptData])

  //     React.useEffect(() => {
  //         console.log("formik", formik.values)
  //     }, [formik.values])

  //     const [updateReciept] = useMutation(RECIEPT_UPDATE_MUTATION, {
  //         context: { clientName: 'businessClient' }, refetchQueries: [RECIEPT_LIST_QUERY], onCompleted: () => {
  //             toast.success(<div >
  //                 <Typography>Reciept Updated Sucessfully</Typography>
  //             </div>,
  //                 {
  //                     position: 'top-right',
  //                     autoClose: 5000,
  //                     hideProgressBar: true,
  //                     closeOnClick: true,
  //                     pauseOnHover: true,
  //                     draggable: true,
  //                     progress: undefined,
  //                     theme: 'light',
  //                     className: 'custom_toast3',
  //                 })
  //         }
  //     })

  //     return (
  //         <form style={{ display: "flex", flexDirection: 'column', gap: "20px" }} onSubmit={formik.handleSubmit}>
  //             <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", }}>

  //                 <IconButton onClick={() => onClose()}>
  //                     <ClearIcon />
  //                 </IconButton>
  //                 {editId ? <Typography className='reciept-modal-head'>Edit Receipt Name</Typography> : <Typography className='reciept-modal-head'>Add Receipt Name</Typography>}
  //                 {!editId ?
  //                     <Button className="main-button" type="submit">Continue</Button> :
  //                     <Box sx={{ display: "flex", gap: "20px" }}>
  //                         <Button className="reciept-edit-button" onClick={() => { setEditMode(true) }} type='submit'>Save & Edit Template</Button>
  //                         <Button className="main-button" type='submit'>Save</Button>
  //                     </Box>
  //                 }
  //             </Box>
  //             <Divider sx={{ backgroundColor: "#D9D9D9" }} />
  //             <Box sx={{ display: "flex", flexDirection: 'column', gap: "20px", }}>
  //                 <TextField fullWidth label="Receipt Name" placeholder='Enter Name'
  //                     onChange={(e) => {

  //                         formik.handleChange(e);

  //                     }}
  //                     name='recieptName'
  //                     onBlur={(e) => { formik.handleBlur(e) }}
  //                     value={formik.values.recieptName} />
  //                 {typeof formik.errors.recieptName === 'string' && (
  //                     <div style={{ color: "red" }}>
  //                         <React.Fragment><Typography sx={{ fontSize: "12px" }}>{formik.errors.recieptName}</Typography></React.Fragment>
  //                     </div>

  //                 )}
  //                 {duplicate && <Typography sx={{ fontSize: "12px", color: "red" }}>Name already exists</Typography>}

  //                 <Box sx={{ display: "flex", gap: "20px" }}>
  //                     <FormControl sx={{ width: "48%" }}>
  //                         <InputLabel id="demo-simple-select-label">Receipt Type</InputLabel>
  //                         <Select
  //                             labelId="demo-simple-select-label"
  //                             id="demo-simple-select"
  //                             onChange={(e) => {
  //                                 //
  //                                 formik.handleChange(e);
  //                             }}
  //                             label="Receipt Type"
  //                             value={formik.values.recieptType}
  //                             name='recieptType'
  //                             onBlur={formik.handleBlur}

  //                         >
  //                             <MenuItem value="billTemplate">Bill Template</MenuItem>
  //                             <MenuItem value='kotTemplate'>KOT Template</MenuItem>

  //                         </Select>
  //                     </FormControl>
  //                     <FormControl sx={{ width: "48%" }}>
  //                         <InputLabel id="demo-simple-select-label">Reciept In Use</InputLabel>
  //                         <Select
  //                             labelId="demo-simple-select-label"
  //                             id="demo-simple-select"
  //                             onChange={(e) => {
  //                                 //
  //                                 formik.handleChange(e);
  //                             }}
  //                             value={formik.values.recieptInUse}
  //                             label="Reciept In Use"
  //                             name='recieptInUse'
  //                             onBlur={formik.handleBlur}

  //                         >
  //                             <MenuItem value="INUSE">In Use</MenuItem>
  //                             <MenuItem value="NOTINUSE">Not In Use</MenuItem>

  //                         </Select>
  //                     </FormControl>
  //                 </Box>
  //             </Box>
  //         </form>
  //     );
  // }

  const [editMode, setEditMode] = React.useState(false);

  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (!duplicate) {
        if (editId) {
          if (editMode) {
            dispatch(setMainData("recieptInUse", values.recieptInUse));
            dispatch(setMainData("recieptType", values.recieptType));
            dispatch(setMainData("recieptName", values.recieptName));
            navigate(`/reciept/${editId}/update-reciept-builder`);
            updateReciept({
              variables: {
                receiptId: editId && +editId,
                receiptType: formik.values.recieptType,
                receiptName: formik.values.recieptName,
                receiptStatus: formik.values.recieptInUse,
              },
            });
          } else {
            updateReciept({
              variables: {
                receiptId: editId && +editId,
                receiptType: formik.values.recieptType,
                receiptName: formik.values.recieptName,
                receiptStatus: formik.values.recieptInUse,
              },
            });
            handleClose();
          }
        } else {
          dispatch(setMainData("recieptInUse", values.recieptInUse));
          dispatch(setMainData("recieptType", values.recieptType));
          dispatch(setMainData("recieptName", values.recieptName));
          navigate("/reciept/reciept-builder");
        }
      }
    },
  });

  const handleDuplicate = (value: string) => {
    checkDuplicate({
      variables: {
        businessId: businessId && +businessId,
        receiptName: value,
        receiptId: editId && +editId,
      },
    });
  };

  const { data: recieptData } = useQuery(RECIEPT_NAME_QUERY, {
    variables: { receiptId: editId && +editId },
    context: { clientName: "businessClient" },
    fetchPolicy: "cache-and-network",
  });

  React.useEffect(() => {
    if (recieptData && recieptData.receipt) {
      formik.setValues({
        recieptName: recieptData.receipt.receiptName,
        recieptType: recieptData.receipt.receiptType,
        recieptInUse: recieptData.receipt.receiptStatus,
      });
      handleDuplicate(recieptData.receipt.receiptName);
    }
  }, [recieptData]);

  const [updateReciept] = useMutation(RECIEPT_UPDATE_MUTATION, {
    context: { clientName: "businessClient" },
    refetchQueries: [RECIEPT_LIST_QUERY],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  return (
    <div>
      <Modal
        open={modalOpen}
        // onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <ModalUi onClose={handleClose} /> */}
          <form
            style={{ display: "flex", flexDirection: "column", gap: "20px" }}
            onSubmit={formik.handleSubmit}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton
                onClick={() => {
                  handleClose();
                  setDuplicate(false);
                  handleExit();
                }}
              >
                <ClearIcon />
              </IconButton>
              {editId ? (
                <Typography className="reciept-modal-head">
                  Edit Receipt Name
                </Typography>
              ) : (
                <Typography className="reciept-modal-head">
                  Add Receipt Name
                </Typography>
              )}
              {!editId ? (
                <Button className="main-button" type="submit">
                  Continue
                </Button>
              ) : (
                <Box sx={{ display: "flex", gap: "20px" }}>
                  <Button
                    className="reciept-edit-button"
                    onClick={() => {
                      setEditMode(true);
                    }}
                    type="submit"
                  >
                    Save & Edit Template
                  </Button>
                  <Button className="main-button" type="submit">
                    Save
                  </Button>
                </Box>
              )}
            </Box>
            <Divider sx={{ backgroundColor: "#D9D9D9" }} />
            <Box sx={{ display: "flex", flexDirection: "column", gap: "20px" }}>
              <TextField
                fullWidth
                label="Receipt Name"
                placeholder="Enter Name"
                onChange={(e) => {
                  formik.handleChange(e);
                  handleDuplicate(e.target.value);
                }}
                name="recieptName"
                onBlur={(e) => {
                  formik.handleBlur(e);
                }}
                value={formik.values.recieptName}
              />
              {typeof formik.errors.recieptName === "string" && (
                <div style={{ color: "red" }}>
                  <React.Fragment>
                    <Typography sx={{ fontSize: "12px" }}>
                      {formik.errors.recieptName}
                    </Typography>
                  </React.Fragment>
                </div>
              )}
              {duplicate && (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  Name already exists
                </Typography>
              )}

              <Box sx={{ display: "flex", gap: "20px" }}>
                <FormControl sx={{ width: "48%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Receipt Type
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      //
                      formik.handleChange(e);
                    }}
                    label="Receipt Type"
                    value={formik.values.recieptType}
                    name="recieptType"
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="billTemplate">Bill Template</MenuItem>
                    <MenuItem value="kotTemplate">KOT Template</MenuItem>
                  </Select>
                </FormControl>
                <FormControl sx={{ width: "48%" }}>
                  <InputLabel id="demo-simple-select-label">
                    Reciept In Use
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    onChange={(e) => {
                      //
                      formik.handleChange(e);
                    }}
                    value={formik.values.recieptInUse}
                    label="Reciept In Use"
                    name="recieptInUse"
                    onBlur={formik.handleBlur}
                  >
                    <MenuItem value="INUSE">In Use</MenuItem>
                    <MenuItem value="NOTINUSE">Not In Use</MenuItem>
                  </Select>
                </FormControl>
              </Box>
            </Box>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
