import { gql } from "@apollo/client";

export const UPDATE_CUSTOMER = gql`
mutation UpdateEmployee($businessId:Int!,$employeeId:Int!,$firstName:String,$lastName:String,$email:String,$mobile:String,$image:String,$bgColourCode:String){
    updateEmployee(
        businessId:$businessId,
        employeeId:$employeeId,
        firstName:$firstName,
        lastName:$lastName,
        email:$email,
        mobile:$mobile,
        
    image:$image,
        bgColourCode:   $bgColourCode
        
        
      ) {
            errorMessage
            employee{employeeId}
            success
            }
      }
`