import React, { useEffect, useState } from "react";
import {
  Box,
  Modal,
  Paper,
  Typography,
  TextField,
  InputBase,
  IconButton,
  Button,
  Divider,
  Switch,
} from "@mui/material";
import {
  GridColDef,
  GridRowModel,
  GridRowSelectionModel,
} from "@mui/x-data-grid";

import "react-circular-progressbar/dist/styles.css";
import "./inventoryLists.css";
import CloseIcon from "@mui/icons-material/Close";
import { useFormik } from "formik";
import * as Yup from "yup";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router";
import { UPDATE_RECURRING_STOCK_TO_PRODUCT } from "../../graphicalQl/mutation/inventoryListsMutation";
import DeletePopup from "../popups/deletePopup";
import { INVENTORY_LIST } from "../../graphicalQl/usequery/inventoryListsQuery";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import RecurringStockSettings from "./recurringStockSettings";
import CircularLoader from "../../loader/loader";
import { useTranslation } from "react-i18next";
import AutoCompleteDropdown from "../popups/autoCompleteDropdown";
import { CATEGORY_LIST_DROPDOWN } from "../../graphicalQl/usequery/productsListQuery";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";

const InventoryPopUpStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

const alertPopUpStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 3,
};

interface State {
  setSearchInput: React.Dispatch<React.SetStateAction<string | null>>;
  searchInput: string | null;
}

interface InitialState {
  recurringQuantity: null | string;
}

const InventoryListSub = (props: State) => {
  const { t } = useTranslation();
  const { setSearchInput, searchInput } = props;

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [recurringStockPopup, setRecurringStockPopup] =
    useState<boolean>(false);
  const [removingRecurringStockPopup, setRemovingRecurringStockPopup] =
    useState<boolean>(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [drawerState, setDrawerState] = React.useState(false);

  // const handleOpen = () => setDrawerState(true);
  const handleDrawerClose = () => setDrawerState(false);

  const handleRemoveRecurringPopupClose = () => {
    setRemovingRecurringStockPopup(false);
    setRemovingProductFromRecurring(null);
  };

  const navigate = useNavigate();
  const filteringObject = {
    locationId: locationId && +locationId,
    businessId: +businessId,
    categoryId: categoryId !== null ? +categoryId : null,
    search: searchInput,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };
  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const [updateRecurringStockToProduct] = useMutation(
    UPDATE_RECURRING_STOCK_TO_PRODUCT,
    {
      onCompleted: (data) => {
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [INVENTORY_LIST],
    }
  );

  const {
    loading: productsDataLoadingByCategoryId,
    error: productsDataErrorByCategoryId,
    data: productsDataByFilteringAllFilters,
  } = useQuery(INVENTORY_LIST, {
    context: { clientName: "categoryClient" },
    variables: requiredFormatAddress,
    fetchPolicy: "network-only",
  });

  function collectVariantsName(data: any[]): string {
    let names: string[] = [];

    data.forEach((item) => {
      names.push(item.name);
    });

    return names.join(",");
  }

  const {
    loading: categoryDataLoading,
    error: categoryDataError,
    data: categoryData,
  } = useQuery(CATEGORY_LIST_DROPDOWN, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (productsDataByFilteringAllFilters) {
      const { inventoryList } = productsDataByFilteringAllFilters;
      if (inventoryList) {
        const { edges } = inventoryList;
        const filteredData = edges.map((each: any) => ({
          id: each.node.inventoryId,
          productName: each.node.product?.name,
          variantName: each.node.variantDetails
            ? collectVariantsName(JSON.parse(each.node.variantDetails))
            : null,

          recurringSetting: each.node.recurringSetting,
          price: each.node.price,
          recurring: each.node.isRecurring,
          stockCount: each.node.stockCount,
        }));
        setProductsList(filteredData);
      }
    }
  }, [productsDataByFilteringAllFilters]);
  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(
      productsDataByFilteringAllFilters?.inventoryList?.pageInfo.endCursor ||
        null
    );
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(
      productsDataByFilteringAllFilters?.inventoryList?.pageInfo.startCursor ||
        null
    );
  };

  const [inventoryId, setInventoryId] = useState<string | null>(null);

  const [recurringStockPopupToggle, setRecurringStockPopupToggle] =
    useState<boolean>(false);

  const [removingProductFromRecurring, setRemovingProductFromRecurring] =
    useState<string | null>(null);

  const [rowSelectionModel, setRowSelectionModel] = useState<
    GridRowSelectionModel | string[]
  >([]);

  const [alertPopupForSelectedCheckboxes, setAlertPopupForSelectedCheckboxes] =
    useState<boolean>(false);
  const [recurringSettingAlertOpen, setRecurringSettingAlertOpen] =
    useState<boolean>(false);

  const initialValuesOfCollectingRecurringQuantity: InitialState = {
    recurringQuantity: "",
  };

  const validationSchemaForCollectingRecurringQuantity = Yup.object({
    recurringQuantity: Yup.number().required("Quantity is required"),
  });

  const formik = useFormik({
    initialValues: initialValuesOfCollectingRecurringQuantity,
    validationSchema: validationSchemaForCollectingRecurringQuantity,
    onSubmit: (values) => {
      handleRecurringPopupSaveButton();
    },
  });

  //handle delete icon pop-up's
  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [categoryList, setCategoryList] = useState([]);
  const [wantToDeleteRow, setWantToDeleteRow] = React.useState<GridRowModel>(
    {}
  );
  const handleDeletePopupOpen = () => setDeletePopupOpen(true);
  const handleDeletePopupClose = () => {
    setDeletePopupOpen(false);
    setWantToDeleteRow({});
  };

  const handleEditIcon = (id: string) => {
    navigate(`/inventory/${id}/update-inventory`);
  };

  const handleCombinedClick = (
    inventoryId: string,

    isChecked: boolean,
    productName: string,
    recurringSetting: boolean
  ) => {
    if (recurringSetting) {
      if (isChecked) {
        setRemovingProductFromRecurring(productName);
        setRemovingRecurringStockPopup(true);
      } else {
        setRecurringStockPopup(true);
      }
    } else {
      setRecurringSettingAlertOpen(true);
    }
    setInventoryId(inventoryId);
  };

  const columns: GridColDef[] = [
    {
      field: "id",
      //@ts-ignore
      headerName: t("inventory.ID"),
      headerClassName: "inventory-list-table-column",
      width: 100,
    },
    {
      field: "productName",
      //@ts-ignore
      headerName: t("inventory.product_name"),
      headerClassName: "inventory-list-table-column",
      flex: 2,
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.row.productName}
        </Typography>
      ),
    },
    {
      field: "variantName",
      //@ts-ignore
      headerName: t("inventory.variation"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
      renderCell: (params) => (
        <div className="">
          {params.row.variantName === null ? (
            "N/A"
          ) : (
            <Typography sx={{ textTransform: "capitalize" }}>
              {params.row.variantName}
            </Typography>
          )}
        </div>
      ),
    },
    {
      field: "stockCount",
      //@ts-ignore
      headerName: t("inventory.quantity"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
    },
    {
      field: "recurring",
      //@ts-ignore
      headerName: t("inventory.recurring"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
      renderCell: (params) => {
        return (
          <IconButton>
            <Switch
              checked={params.row.recurring}
              onChange={(e) => {
                handleCombinedClick(
                  params.row.id,
                  params.row.recurring,
                  params.row.productName,
                  params.row.recurringSetting
                );
              }}
              color="warning"
            />
          </IconButton>
        );
      },
    },
    // {
    //   field: "price",
    //   //@ts-ignore
    //   headerName: t("inventory_lists.price"),
    //   headerClassName: "inventory-list-table-column",
    //   renderCell: (params) => (
    //     <div>
    //       {params.value === null ? (
    //         "NA"
    //       ) : (
    //         <CurrencyDisplay
    //           countryName={"UnitedKingdom"}
    //           amount={params.value ? params.value : "0"}
    //         />
    //       )}
    //     </div>
    //   ),
    // },
    {
      field: ".",
      //@ts-ignore
      // headerName: t("inventory_lists.actions"),
      // headerClassName: "inventory-list-table-column",
      disableColumnMenu: true,
      renderCell: (params) => (
        <IconButton onClick={() => handleEditIcon(params.row.id)}>
          <EditIcon />
        </IconButton>
      ),
    },
  ];

  const getFontSize = () => {
    return "stock-adjustments-table-row inventory-list-table-row-hover";
  };

  const handleChange = (seachedText: string) => {
    setSearchInput(seachedText);
  };

  const handleRecurringStockPopupClose = () => {
    setRecurringStockPopup(false);
    // formik.setValues(initialValuesOfCollectingRecurringQuantity)
    formik.resetForm({
      values: initialValuesOfCollectingRecurringQuantity,
      errors: {},
      touched: {},
      isSubmitting: false,
    });
  };

  const handleRecurringPopupSaveButton = () => {
    const collectedImformationForUpdateRecurring = {
      businessId: +businessId,
      inventoryId: inventoryId && +inventoryId,
      locationId: +locationId,
      isRecurring: true,
      recurringQuantity: formik.values.recurringQuantity
        ? +formik.values.recurringQuantity
        : null,
    };

    const filteredDataFromNull = Object.fromEntries(
      Object.entries(collectedImformationForUpdateRecurring).filter(
        ([_, value]) => value !== null
      )
    );

    updateRecurringStockToProduct({ variables: filteredDataFromNull });
    handleRecurringStockPopupClose();
  };

  useEffect(() => {
    if (categoryData) {
      const filteredCategoryList = categoryData.categoryList.edges.map(
        (eachCategory: any) => ({
          id: eachCategory.node.categoryId,
          label: eachCategory.node.name,
        })
      );
      setCategoryList(filteredCategoryList);
    }
  }, [categoryData]);

  const handleCategory = (newData: any) => {
    if (newData) {
      const { id, label } = newData;
      setCategoryId(id);
      setCategoryName(label);
    } else {
      setCategoryId(null);
      setCategoryName(null);
    }
  };

  const handleRecurringPopupYesRemoveButton = () => {
    const collectedImformationForUpdateRecurring = {
      businessId: +businessId,
      inventoryId: inventoryId && +inventoryId,
      locationId: +locationId,
      isRecurring: false,
    };

    const filteredDataFromNull = Object.fromEntries(
      Object.entries(collectedImformationForUpdateRecurring).filter(
        ([_, value]) => value !== null
      )
    );

    updateRecurringStockToProduct({ variables: filteredDataFromNull });
    handleRemoveRecurringPopupClose();
  };

  const handleBulkAdjustmentsButton = () => {
    if (rowSelectionModel.length > 0) {
      let intList: number[] = rowSelectionModel.map(Number);
      sessionStorage.setItem("inventoryIds", JSON.stringify(intList));
      navigate("/inventory/stock-adjustments");
    } else {
      setAlertPopupForSelectedCheckboxes(true);
    }
  };

  const hanldeOkButtonInPopup = () => {
    setAlertPopupForSelectedCheckboxes(false);
  };

  const handleRecurringPopupCloseButton = () => {
    setRecurringSettingAlertOpen(false);
  };

  const handleAlertRecurringSettingAddSettingsButton = () => {
    setRecurringSettingAlertOpen(false);
    setDrawerState(true);
  };

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Modal
        open={recurringSettingAlertOpen}
        onClose={handleRecurringPopupCloseButton}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={alertPopUpStyle}>
          <Box>
            <IconButton
              onClick={handleRecurringPopupCloseButton}
              className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Divider
            sx={{ marginTop: "5px", marginBottom: "5px", width: "100%" }}
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              marginBottom: "5px",
            }}
          >
            <Typography
              className="comfirmation-popup-text"
              sx={{ color: "#707070" }}
            >
              {t("inventory.recurring_settings_are_missing")}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
              alignItems: "center",
              marginTop: "20px",
            }}
          >
            <Button
              onClick={handleRecurringPopupCloseButton}
              color="warning"
              variant="outlined"
              sx={{ textTransform: "none", marginRight: "10px" }}
            >
              {t("buttons.cancel")}
            </Button>
            <Button
              onClick={handleAlertRecurringSettingAddSettingsButton}
              color="warning"
              variant="contained"
              sx={{ textTransform: "none" }}
            >
              {t("inventory.add_settings")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <RecurringStockSettings
        handleDrawerClose={handleDrawerClose}
        drawerState={drawerState}
      />
      <DeletePopup
        open={removingRecurringStockPopup}
        handleClose={handleRemoveRecurringPopupClose}
        handleOpen={() => setRemovingRecurringStockPopup(true)}
        handleSaveButton={handleRecurringPopupYesRemoveButton}
        mainText={`${t(
          "buttons.are_you_sure_delete"
        )} "${removingProductFromRecurring}" ${t("inventory.from_recurring")} `}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />

      <Modal
        open={recurringStockPopup}
        onClose={handleRecurringStockPopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={InventoryPopUpStyle}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "70%",
              }}
            >
              <IconButton
                onClick={handleRecurringStockPopupClose}
                className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
              >
                <CloseIcon />
              </IconButton>
              <Typography className="inventory-header-name">
                {t("inventory.add_recurring_quantity")}
              </Typography>
            </Box>
            <Box>
              <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            </Box>
            <Typography className="delete-pop-up-text">
              {t("inventory.every_day")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                marginTop: "15px",
              }}
            >
              <Box>
                <TextField
                  id="outlined-basic"
                  label="Quantity"
                  color="warning"
                  type="text"
                  onChange={(e) => {
                    const val = e.target.value;
                    if (!val || /^[0-9]+$/.test(val)) {
                      formik.handleChange(e);
                    }
                  }}
                  value={formik.values.recurringQuantity}
                  onBlur={formik.handleBlur}
                  defaultValue="recurringQuantity"
                  name="recurringQuantity"
                  variant="outlined"
                />

                {formik.touched.recurringQuantity &&
                  formik.errors.recurringQuantity && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Box>
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                color="warning"
                variant="contained"
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>

      <Modal
        open={alertPopupForSelectedCheckboxes}
        onClose={hanldeOkButtonInPopup}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={alertPopUpStyle}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "65%",
            }}
          >
            <IconButton
              onClick={hanldeOkButtonInPopup}
              className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
            >
              <CloseIcon />
            </IconButton>
          </Box>
          <Box>
            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
          </Box>
          <Typography className="delete-pop-up-text">
            {t("inventory.please_select_products")}
          </Typography>

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              justifyContent: !recurringStockPopupToggle
                ? "center"
                : "flex-end",
              width: !recurringStockPopupToggle ? "100%" : "100%",
            }}
          >
            <Button
              sx={{ textTransform: "none" }}
              onClick={hanldeOkButtonInPopup}
              color="warning"
              variant="contained"
            >
              {t("buttons.ok")}
            </Button>
          </Box>
        </Box>
      </Modal>

      <Box>
        <Box className="inventory-header-buttons-order">
          <Typography className="inventory-header-name">
            {t("inventory.inventory_lists")}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <Button
              onClick={handleBulkAdjustmentsButton}
              variant="outlined"
              className="inventory-list-header-buttons-hover inventory-header-buttons"
            >
              {t("inventory.bulk_stock_adjustments")}
            </Button>
            <Button
              onClick={() => setDrawerState(true)}
              variant="outlined"
              className="inventory-list-header-buttons-hover inventory-header-buttons"
            >
              {t("inventory.recurring_stock_settings")}
            </Button>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            gap: "1%",
            alignItems: "center",
          }}
        >
          <DelayedInput
            width="30%"
            margin="10px 0px 10px 8px"
            setSearchValue={setSearchInput}
            placeHolder={t("inventory.Search by name/description")}
            size={"medium"}
          />

          <AutoCompleteDropdown
            handleChange={handleCategory}
            data={categoryList}
            width="30%"
            label={t("inventory.all_category")}
            value={{ id: categoryId || "", label: categoryName || "" }}
          />
        </Box>

        {productsDataLoadingByCategoryId ? (
          <CircularLoader />
        ) : (
          <Box sx={{ height: "70vh", width: "100%", padding: "10px" }}>
            {/* <DataGrid
              rows={productsList}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: {
                    page: 0,
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              checkboxSelection
              onRowSelectionModelChange={(
                newRowSelectionModel: GridRowSelectionModel | string[]
              ) => {
                setRowSelectionModel(newRowSelectionModel);
              }}
              rowSelectionModel={rowSelectionModel}
              disableRowSelectionOnClick
              rowCount={
                productsDataByFilteringAllFilters?.inventoryList?.totalCount ||
                0
              }
              paginationModel={paginationModel}
              onPaginationModelChange={(newModel) => {
                let shouldUpdatePaginationModel = true;
                if (newModel.pageSize !== paginationModel.pageSize) {
                  setStartCursor(null);
                  setEndCursor(null);
                  setCurrentPage(0);
                  setPaginationModel({
                    ...newModel,
                    page: 0,
                  });
                  shouldUpdatePaginationModel = false;
                } else if (newModel.page > currentPage) {
                  handleEndCursor();
                } else if (newModel.page < currentPage) {
                  handleStartCursor();
                }

                if (shouldUpdatePaginationModel) {
                  setPaginationModel(newModel);
                  setCurrentPage(newModel.page);
                }
              }}
              paginationMode="server"
              components={{
                Footer: (props) => (
                  <CustomFooter
                    totalRowCount={Math.ceil(
                      (productsDataByFilteringAllFilters?.inventoryList
                        ?.totalCount || 0) / paginationModel.pageSize
                    )}
                    {...props}
                    page={paginationModel.page}
                  />
                ),
              }}
              getRowClassName={getFontSize}
              sx={{
                "&.MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                  outline: "solid #1976D2 0px !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                  {
                    outline: "solid #1976D2 0px !important",
                  },
                "& .MuiDataGrid-columnHeader": {
                  background: "#F5F5F5",
                },

                ".MuiTablePagination-displayedRows": {
                  display: "none", // :point_left: to hide huge pagination number
                },
              }}
            /> */}

            <DataGridTable
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrue
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNull}
              paginationModel={paginationModel}
              rows={productsList}
              columns={columns}
              getRow={getFontSize}
              totalCount={
                productsDataByFilteringAllFilters?.inventoryList?.totalCount ||
                0
              }
              checkboxSelection={true}
              setRowSelectionModel={setRowSelectionModel}
              handleEndCursor={handleEndCursor}
              handleStartCursor={handleStartCursor}
              currentPage={currentPage}
              imageHeight="250px"
              imageWidth="300px"
              heightForContainer="50vh"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default InventoryListSub;
