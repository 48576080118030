import React, { useEffect, useState } from "react";
import {
  Typography,
  Popover,
  Button,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Divider,
  Modal,
} from "@mui/material";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import { useNavigate } from "react-router-dom";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useQuery, useMutation } from "@apollo/client";
import {
  User_Roles,
  USER_ROLES_WIITH_LOCATION,
} from "../../graphicalQl/usequery/userProfileUseQuery";
import CircularLoader from "../../loader/loader";
import {
  DELETE_ROLE,
  DUPLICATE_USER_ROLE,
} from "../../graphicalQl/mutation/userProfileMutation";
import { useTranslation } from "react-i18next";
import "./userRole.css";
import { toast } from "react-toastify";
import DeletePopup from "../popups/deletePopup";
import styled from "styled-components";
import CustomFooter from "../category/footer";
import NodataImage from "../../utils/nodataImage";
import { useSelector } from "react-redux";
import DataGridTable from "../../utils/dataGridTable";
interface UserRow {
  role: string;
  access: string;
  user: number;
  noOfUsers: number;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
};

const UserRole = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [userRoleList, setUserRoleList] = useState<UserRow[]>([]);
  const [profileId, setProfileId] = React.useState<null | string>(null);
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [noOfUsers, setNoOfUsers] = useState<null | number>(null);
  const [role, setRole] = useState<string | null>(null);
  const [usersCount, setUsersCount] = useState<null | string>(null);

  const [popupForNotAssignedUsers, setPopupForNotAssignedUsers] =
    useState<boolean>(false);

  // const { data, loading, error } = useQuery(User_Roles, {
  //   context: { clientName: "userClient" },
  //   variables: {
  //     businessId: +businessId,
  //     first: !startCursor ? paginationModel.pageSize : null,
  //     after: endCursor,
  //     before: startCursor,
  //     last: startCursor && paginationModel.pageSize,
  //   },
  // });

  const { data, loading, error } = useQuery(USER_ROLES_WIITH_LOCATION, {
    context: { clientName: "userClient" },
    variables: {
      businessId: +businessId,
      locationId: +locationId,
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
  });


  //delete role mutation

  const [deleteRole] = useMutation(DELETE_ROLE, {
    onCompleted: (data) => {
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [User_Roles],
  });

  const [duplicateRole] = useMutation(DUPLICATE_USER_ROLE, {
    onCompleted: (data) => {
      if (data) {
        const { duplicateRole } = data;
        if (duplicateRole) {
          const { success } = duplicateRole;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.duplicated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [User_Roles],
    fetchPolicy: "network-only",
  });

  //poopover functions
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const PopoverOpen = Boolean(anchorEl);
  const id = PopoverOpen ? "simple-popover" : undefined;
  const handleThreedotsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    noOfUsers: string,
    role: string
  ) => {
    setAnchorEl(event.currentTarget);
    setProfileId(id);
    setNoOfUsers(noOfUsers ? parseInt(noOfUsers) : 0);
    setRole(role);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.roles?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.roles?.pageInfo.startCursor || null);
  };

  //Edit User Functions

  const handleEditUserButton = () => {
    navigate(`/roles/${profileId}/update-role`);
    setAnchorEl(null);
  };

  const handleAddUserButton = () => {
    navigate("/roles/create-role");
  };

  const handleDeletePopupClose = () => {
    setDeletePopup(false);
    setProfileId(null);
  };

  const columns: GridColDef[] = [
    {
      field: "role",
      //@ts-ignore
      headerName: t("users.role"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 2,
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "user",
      //@ts-ignore
      headerName: t("users.users"),
      headerClassName: "user-role-header-names",
      flex: 1,
      disableColumnMenu: true,

      renderCell: (params) => (
        <div
          className="user-roles-header-hover"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "32px",
          }}
        >
          {params.value}
        </div>
      ),
    },

    {
      field: ".",
      //@ts-ignore
      headerName: t("users.actions"),
      width: 150,
      disableColumnMenu: true,
      headerClassName: "user-role-header-names",
      renderCell: (params) => (
        <div
          className="user-roles-header-hover"
          style={{ display: "flex", alignItems: "center", height: "32px" }}
        >
          <IconButton
            onClick={(event) =>
              handleThreedotsClick(
                event,
                params.row.id,
                params.row.user,
                params.row.role
              )
            }
            sx={{ marginLeft: "20px" }}
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {role?.trim() === "Admin" ? popoverContentForAdmin : popoverContent}
          </Popover>
        </div>
      ),
    },
  ];

  useEffect(() => {
    if (data) {
      const { locationBasedRoles } = data;
      const parsedData = JSON.parse(locationBasedRoles);

      const requiredDataList = parsedData?.map((each: any) => ({
        id: each["role_id"],
        role: each["role_name"],
        user: each["user_count"],
      }));
      setUserRoleList(requiredDataList);
    }
  }, [data]);

  const handlePoppoverDelete = () => {
    deleteRole({ variables: { roleId: profileId && +profileId } });
    setAnchorEl(null);
    handleDeletePopupClose();
  };

  const getRowClassNames = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleNotassignedpopupClose = () => {
    setPopupForNotAssignedUsers(false);
    setNoOfUsers(null);
    setProfileId(null);
  };
  const handleDeletePopupOpen = () => {
    if (noOfUsers === 0) {
      setDeletePopup(true);
      setAnchorEl(null);
    } else {
      setPopupForNotAssignedUsers(true);
      setAnchorEl(null);
    }
  };

  const handleDuplicateRoleButton = () => {
    setAnchorEl(null);
    duplicateRole({ variables: { roleId: profileId && +profileId } });
  };

  const handleViewForAdmin = () => {
    navigate(`/roles/${profileId}/update-role`);
    setAnchorEl(null);
  };

  const popoverContent = (
    <List>
      <Button
        onClick={handleDuplicateRoleButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.duplicate")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleEditUserButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDeletePopupOpen}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.delete")} />
        </ListItem>
      </Button>
    </List>
  );

  const popoverContentForAdmin = (
    <List>
      <Button
        onClick={handleViewForAdmin}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "120px", height: "20px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.view")} />
        </ListItem>
      </Button>
    </List>
  );
  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };
  return (
    <Box
      sx={{
        margin: "1%",
      }}
    >
      <Modal
        open={popupForNotAssignedUsers}
        onClose={handleNotassignedpopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography
            className="comfirmation-popup-text"
            sx={{ color: "#707070", marginBottom: "2%" }}
          >
            At present, there are {noOfUsers} users allocated to this user role.
            To proceed with deleting the role, kindly assign these users to
            alternative roles.
          </Typography>
          <Box sx={{ textAlign: "center" }}>
            <Button
              color="warning"
              variant="contained"
              onClick={handleNotassignedpopupClose}
              sx={{ marginTop: "4%", textTransform: "none" }}
            >
              {t("buttons.ok")}
            </Button>
          </Box>
        </Box>
      </Modal>
      <DeletePopup
        open={deletePopup}
        handleClose={handleDeletePopupClose}
        handleOpen={() => setDeletePopup(true)}
        handleSaveButton={handlePoppoverDelete}
        mainText={`${t("buttons.are_you_sure_delete")} ${t("users.user")}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />
      <Box className="user-requests-header-order">
        <Typography className="user-role-title">
          {t("users.user_role")}
        </Typography>
        <Button
          sx={{ textTransform: "none" }}
          onClick={handleAddUserButton}
          className="create-user-create-new-role-button"
          variant="contained"
        >
          {t("users.create_a_new_role")}
        </Button>
      </Box>

      {loading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            height: "70vh",
            width: "100%",
            marginTop: "5px",
            padding: "10px",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={userRoleList}
            columns={columns}
            getRow={getRowClassNames}
            totalCount={data?.roles?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
};

export default UserRole;
