import { Typography, Box, Button } from "@mui/material";
import React, { useState } from "react";
import CountrySelect from "../../../businessOnboarding/Steper/businessDetails/countriesField";

//Icons
import ShortcutIcon from "../../../../assets/header/shortcot.svg";
import ShortcutIconActive from "../../../../assets/header/shortcut-active.svg";
import NotesIcon from "../../../../assets/header/notes.svg";
import NotificationIcon from "../../../../assets/header/notes.svg";
import NotificationIconActive from "../../../../assets/header/notes.svg";
import ProfileIcon from "../../../../assets/header/person.svg";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import NoteEllipse from "../../../../assets/noteellipse.png";
import NotificationModal from "../../../mainDashboard/notifications";
import menuIcon from "../../../../assets/mianDashboard/menuIcon.svg"
import ShortCuts from "../../../mainDashboard/shortCuts";
import LanguageSelect from "../../../businessOnboarding/Steper/businessDetails/languageField";
import ProfileMenu from "../../../mainDashboard/profileMenu";

const HeaderWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginLeft: "auto",
  alignItems: "center",
  height: "77px",
  borderBottom: "1px solid #EEEEEE",
  paddingLeft: "16px",
  paddingRight: "12px",
  '@media (max-width: 600px)': {
          height: '50px',
          paddingLeft: "0px", // Set width to 0px on extra-small screens
        },
  
});
interface State{
  setSideBarOpen:React.Dispatch<React.SetStateAction<boolean>>
  sideBarOpen:boolean
}
function Header(props:State) {
  const {setSideBarOpen,sideBarOpen}=props
  const { t } = useTranslation();
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openShortcuts, setOpenShortcuts] = useState(false);
  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const handleMenuIcon=()=>{
    setSideBarOpen(!sideBarOpen)
    
  }
  return (
    <HeaderWrapper>
        <Button onClick={handleMenuIcon} sx={{display:{
            md:"none",xs:"flex"
        }}}>
            <img src={menuIcon} alt="Notes" />
        </Button>
      <Box>
        <Typography
          sx={{
            fontSize: "18px",
            lineHeight: "23px",
            fontWeight: "600",
            color: "#333333",
            fontFamily: "Quicksand",
            display: { sm: "block", xs: "none" },
          }}
        >
          {t("dashboard.title")}{" "}
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: { lg: "24px", xs: "16px" },
          alignItems: "center",
        }}
      >
        <Box
          width={{ lg: "305px", md: "180px" }}
          height={{ lg: "54px", md: "48px" }}
          borderRadius={"8px"}
          className="headermenuBg"
          sx={{
            display: { md: "block", xs: "none" },
            // '.css-1k268rx-MuiAutocomplete-root .MuiOutlinedInput-root':{
            //   paddingTop: '7px !important',
            //   paddingBottom: '9px !important',
            // },
            // '& .MuiOutlinedInput-notchedOutline': {
            //     border: '1px solid #EEEEEE',
            //     borderImageSource: 'linear-gradient(112.27deg, #FFFFFF 9.49%, #EEEEEE 99.91%)',
            //     '&.Mui-focused fieldset': {
            //       borderColor: '#D9D9D9',
            //     },
            // },
            // '& .MuiOutlinedInput-root': {
            //   // '& fieldset': {
            //   //   borderColor: '#D9D9D9',
            //   //   borderRadius:'4px',
            //   // },
            //   // '&:hover fieldset': {
            //   //   borderColor: '#D9D9D9',
            //   // },

            // },
            // '& .css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root':{
            //   fontSize:'18px',
            //   fontWeight:'600',
            //   fontFamily:'Quicksand',
            //   color:'#333333',
            // }
          }}
        >
          <CountrySelect
            //@ts-ignore
            label={t("dashboard.location")}
            width={{ lg: "220px", md: "180px", xs: "180px" }}
          />
        </Box>
        <Box
          onClick={() => {
            setOpenShortcuts(true);
          }}
          className="headermenuBg"
          sx={{
            width: { lg: "54px", md: "48px" },
            height: { lg: "54px", md: "48px" },
            borderRadius: "8px",
            display: { md: "flex", xs: "none" },
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            background: `${openShortcuts ? "#FFFBF6" : ""} `,
          }}
        >
          <img
            style={{ width: "32px", height: "36px" }}
            src={openShortcuts ? ShortcutIconActive : ShortcutIcon}
            alt="ShortcutIcon"
          />
        </Box>
        <NotificationModal
          open={openNotifications}
          setOpen={setOpenNotifications}
        />
        <ShortCuts open={openShortcuts} setOpen={setOpenShortcuts} />
        <ProfileMenu open={openProfileMenu} setOpen={setOpenProfileMenu} />
        {/* <Box
          className="headermenuBg"
          sx={{
            width: { lg: "200px", md: "180px" },
            height: { lg: "54px", md: "48px" },
            borderRadius: "8px",
            display: { md: "flex", xs: "none" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LanguageSelect width={"200px"}  />
        </Box> */}

        <Box
          className="headermenuBg"
          sx={{
            width: { lg: "54px", sm: "48px", xs: "40px" },
            height: { lg: "54px", sm: "48px", xs: "40px" },
            borderRadius: "8px",
            display:{xs:"none",md:"flex"},
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <img src={NotesIcon} alt="Notes" />
          <Box
            sx={{
              position: "absolute",
              top: { sm: "8px", xs: "2px" },
              right: { sm: "8px", xs: "2px" },
            }}
          >
            <img src={NoteEllipse} alt="NoteEllipse" />
          </Box>
        </Box>

        {/* <Box
          onClick={() => {
            setOpenNotifications(true);
          }}
          className="headermenuBg"
          sx={{
            width: { lg: "54px", sm: "48px", xs: "40px" },
            height: { lg: "54px", sm: "48px", xs: "40px" },
            borderRadius: "8px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            background: `${openNotifications ? "#FFFBF6" : ""} `,
          }}
        >
          <Box sx={{ width: "30px", height: "30px" }}>
            <img
              height={30}
              width={30}
              src={
                openNotifications ? NotificationIconActive : NotificationIcon
              }
              alt="Notification"
            />
          </Box>
        </Box> */}

        <Box
          onClick={() => {
            setOpenProfileMenu(true);
          }}
          sx={{
            width: { lg: "54px", sm: "48px", xs: "32px" },
            height: { lg: "54px", sm: "48px", xs: "32px" },
            borderRadius: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            border: "2px solid #F9C584",
            cursor: "pointer",
          }}
        >
          <img width={"54px"} src={ProfileIcon} alt="ProfileIcon" />
        </Box>
      </Box>
    </HeaderWrapper>
  );
}

export default Header;