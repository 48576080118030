import { gql } from "@apollo/client";

export const PAIR_A_NEW_DEVICE = gql`
  mutation CreateDevicePairing(
    $deviceCode: String!
    $deviceName: String
    $storeId: ID!
    $businessId: ID!
    $kdsStationName: String
  ) {
    createDevicePairing(
      deviceCode: $deviceCode
      deviceName: $deviceName
      storeId: $storeId
      businessId: $businessId
      kdsStationName: $kdsStationName
    ) {
      devicePairing {
        deviceCode
        deviceName
        deviceType
      }
      success
      errorMessage
    }
  }
`;

export const UPATE_KDS_SETUP = gql`
  mutation UpdateBranchSettings($storeId: Int!, $kdsSettings: JSONString) {
    branchSettings(storeId: $storeId, kdsSettings: $kdsSettings) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const UPDATE_STATUS_DEVICE = gql`
  mutation DeviceStatusChange($deviceId: ID!, $status: String) {
    deviceStatusChange(deviceId: $deviceId, status: $status) {
      devicePairing {
        deviceName
        kdsStationName
        pairingId
      }
    }
  }
`;

export const DELETE_PRODUCT_FROM_KDS = gql`
  mutation removeProductFromKds($productId: Int!, $deviceId: String) {
    removeProductFromKds(productId: $productId, deviceId: $deviceId) {
      message
      success
    }
  }
`;

export const ADD_PRODUCT_TO_KDS = gql`
  mutation addProductToKds($deviceId: String, $productId: Int!) {
    addProductToKds(deviceId: $deviceId, productId: $productId) {
      message
      success
    }
  }
`;

export const UPDATE_DEVICE_IN_KDS = gql`
  mutation updateDevice(
    $deviceId: ID!
    $kdsStationName: String
    $deviceName: String
  ) {
    updateDevice(
      deviceId: $deviceId
      kdsStationName: $kdsStationName
      deviceName: $deviceName
    ) {
      devicePairing {
        deviceName
        kdsStationName
        pairingId
      }
    }
  }
`;

export const UPDATE_DEVICE_IN_POS = gql`
  mutation DeviceSettings(
    $businessId: ID!
    $deviceId: ID!
    $settings: JSONString
  ) {
    deviceSettings(
      businessId: $businessId
      deviceId: $deviceId
      settings: $settings
    ) {
      message
    }
  }
`;

export const DELETE_DEVICE = gql`
  mutation deleteDevice($serialNumber: String!) {
    deleteDevice(serialNumber: $serialNumber) {
      message
      success
    }
  }
`;

export const IS_VALID_FOR_CONNECT = gql`
  mutation deviceCodeValidityCheck($deviceCode: String!, $deviceType: String) {
    deviceCodeValidityCheck(deviceCode: $deviceCode, deviceType: $deviceType) {
      message
      valid
      success
    }
  }
`;
