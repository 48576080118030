import { Box } from "@mui/material";
import "chart.js/auto";
import {
  Chart as ChartJS,
  LinearScale,
  PieController,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import React from "react";
import { Pie } from "react-chartjs-2";
import { useTranslation } from "react-i18next";

ChartJS.register(PieController, LineElement, PointElement, LinearScale, Title);

interface Props {
  label?: string[];
  value?: number[];
}

const PaymentTypeGraph = (props: Props) => {
  const { t } = useTranslation();

  const { label = [], value = [] } = props;
  const getOrCreateLegendList = (id: any) => {
    const legendContainer = document.getElementById(id);
    let listContainer = legendContainer?.querySelector("ul");

    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "column";
      listContainer.style.margin = "0";
      listContainer.style.padding = "0";
      listContainer.style.gap = "26px";
      listContainer.style.justifyContent = "center";

      legendContainer && legendContainer.appendChild(listContainer);
    }

    return listContainer;
  };

  const htmlLegendPlugin = {
    id: "htmlLegend",
    afterUpdate(chart: any) {
      const ul = getOrCreateLegendList("legend-container");

      // Remove old legend items
      while (ul.firstChild) {
        ul.firstChild.remove();
      }

      const items = chart.options.plugins.legend.labels.generateLabels(chart);

      items.forEach((item: any) => {
        const li = document.createElement("li");
        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";

        li.onclick = () => {
          const { type } = chart.config;
          if (type === "pie" || type === "doughnut") {
            chart.toggleDataVisibility(item.index);
          } else {
            chart.setDatasetVisibility(
              item.datasetIndex,
              !chart.isDatasetVisible(item.datasetIndex)
            );
          }
          chart.update();
        };

        // Color box

        let boxSpan = null;
        boxSpan = document.createElement("span");
        boxSpan.style.background = item.strokeStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "20px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "20px";

        // TextPayment Types
        const textContainer = document.createElement("p");
        textContainer.style.color = item.fontColor;
        textContainer.style.margin = "0";
        textContainer.style.padding = "0";
        textContainer.style.fontSize = "16px";
        textContainer.style.fontWeight = "500";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    },
  };

  // const labels=["card","cash"]
  // const values=[400,500]

  const getTranslatedLabels = (): string[] => {
    return label.map((key) => t(`dashboard.${key}`));
  };

  const data = {
    labels: getTranslatedLabels(),
    datasets: [
      {
        label: `${t("inventory.amount")}`,
        data: value,
        borderColor: ["#AE9BE2", "#F6C65B", "#BAE29B"],
        backgroundColor: ["#AE9BE2", "#F6C65B", "#BAE29B"],
        fill: true,
        hoverOffset: 1,
      },
    ],
  };
  const options: any = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Payment Types",
      },
      legend: {
        display: false,
        boxWidth: 120,
        padding: -10,
        position: "left",
        align: "center",
        fullSize: true,
        title: {
          padding: 0,
          display: true,
        },
        labels: {
          boxWidth: 18,
          boxHeight: 18,
          paddingLeft: 20,
          marginLeft: 20,
          useBorderRadius: true,
        },
      },
    },
    interaction: {
      intersect: false,
    },
    fill: true,
  };

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        flexDirection: { xs: "column", sm: "row" },
        gap: { xs: "50px", sm: "100px" },
        marginTop: "45px",
      }}
    >
      <Box id="legend-container"></Box>
      <Box
        sx={{
          maxWidth: "220px",
          maxHeight: "220px",
          width: "100%",
          height: "100%",
        }}
      >
        <Pie
          height={400}
          width={400}
          style={{ width: "100%", height: "100%" }}
          options={options}
          data={data}
          plugins={[htmlLegendPlugin]}
        />
      </Box>
    </Box>
  );
};

export default PaymentTypeGraph;
