import React, { FC, useEffect, useState } from "react";

import { CustomerGraphType } from "../../../../types/customerReports";
import CustomerGraphMain from "./customerGraph";

interface Props {
  customerReportsGraph: CustomerGraphType[];
}

const CustomerGraph: FC<Props> = ({ customerReportsGraph }) => {
  const [labels, setLabels] = useState<Array<string>>([]);
  const [ordersGraphData, setOrdersGraphData] = useState<Array<number>>([]);
  const [customersGraphData, setCustomersGraphData] = useState<Array<number>>(
    []
  );

  useEffect(() => {
    const tempLabels = customerReportsGraph?.map((cus) => {
      return cus.date;
    });
    const tempOrders = customerReportsGraph?.map((cus) => {
      return cus.orders;
    });
    const tempCustomers = customerReportsGraph?.map((cus) => {
      return cus.customers;
    });
    setLabels(tempLabels);
    setCustomersGraphData(tempCustomers);
    setOrdersGraphData(tempOrders);
  }, [customerReportsGraph]);

  return (
    <>
      <CustomerGraphMain
        labels={labels}
        ordersGraphData={ordersGraphData}
        customersGraphData={customersGraphData}
      />
    </>
  );
};

export default CustomerGraph;
