import {
  Box,
  Drawer,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { DataGrid, GridColDef, GridEventListener } from "@mui/x-data-grid";
import moment from "moment";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import ComplaintsResolutionPanel from "./complaintsResolutionPanel";
import { useQuery } from "@apollo/client";
import {
  EMPLOYEE_DETAILS,
  LOCATION_LIST,
  REGISTERED_COMPLAINTS,
} from "../../../graphicalQl/usequery/customerDataQuery";

// import { LOCATION_LIST ,REGISTERED_COMPLAINTS} from "../../../graphicalQl/usequery/customerDataQuery";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    color: "#333333",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    backgroundColor: "#F5F5F5",
    border: "none",
  },
  "& .MuiDataGrid-cell": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    border: "none",
  },
  "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
}));

const DrawerStyle = styled(Drawer)({
  "& .MuiBackdrop-root": {
    top: "unset",
    height: "77%",
  },
  "& .MuiPaper-root": {
    bottom: "0",
    top: "unset",
    height: "77%",
  },
});

const RegisteredComplaints = () => {
  const [daysFilter, setDaysFilter] = useState("");
  const [filters, setFilters] = useState({
    locationId: "",
    fromDate: "2024-03-01",
    toDate: "2024-05-30",
    employeeName: "",
  });

  const [complaintsResolutionPanel, setComplaintsResolutionPanel] = useState({
    state: false,
    rowData: {},
  });

  const [customerComplaints, setCustomerComplaints] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);

  const { data, loading, error, refetch, networkStatus } = useQuery(
    REGISTERED_COMPLAINTS,
    {
      variables: {
        businessId: 5,
        ...filters,
        locationId: parseInt(filters.locationId),
      },
      context: { clientName: "customerClient" },
      fetchPolicy: "network-only",
    }
  );

  const { data: locationDropdown } = useQuery(LOCATION_LIST, {
    variables: {
      businessId: 5,
    },
    context: { clientName: "businessClient" },
  });

  // query to fetch  all employees
  const {
    data: employeeDetails,
    loading: employeeDetailsLoading,
    error: employeeDetailsError,
  } = useQuery(EMPLOYEE_DETAILS, {
    variables: {
      businessId: 5,
    },
    context: { clientName: "userClient" },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (employeeDetails) {
      const parsedData = employeeDetails?.employees?.edges.map((each: any) => ({
        employeeId: each.node?.employeeId,
        name: each.node?.firstName + " " + each.node?.lastName,
      }));
      setEmployeeList(parsedData);
    }
  }, [employeeDetails]);

  useEffect(() => {
    if (data) {
      const parsedData = data?.customerComplaintList?.edges?.map(
        (each: any) => ({
          id: each.node.id,
          customerName:
            each.node.customer.firstName + " " + each.node.customer.lastName,
          reason: each.node.reason,
          date: moment(each.node.createdOn).format("HH:MM; DD-MM-YYYY"),
          complaint: each.node.complaint,
          complaintId: each.node.complaintId,
          status: each.node.complaintStatus,
          customerEmail: each.node.customer.email,
          customerMobile: each.node.customer.mobile,
        })
      );

      setCustomerComplaints(parsedData);
    }
  }, [data, networkStatus]);

  useEffect(() => {
    refetch();
  }, [filters]);

  // column definition for  Data Grid component
  const columns: GridColDef[] = [
    {
      field: "customerName",
      headerName: "Customer Nam",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "date",
      headerName: "Date",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "reason",
      headerName: "Reason",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "complaint",
      headerName: "Complaint",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <div
          className={`customer-listing-status ${
            String(params.value) === "Resolved"
              ? "customer-listing-status--resolved"
              : ""
          }`}
        >
          {params.value}
        </div>
      ),
    },
  ];

  const handleComplaintResolution: GridEventListener<"rowClick"> = (
    params,
    events,
    details
  ) => {
    setComplaintsResolutionPanel({
      state: true,
      rowData: {
        ...params.row,
        refetchRegisteredComplaints: refetch,
      },
    });
  };

  return (
    <div
      style={{
        backgroundColor: "#FBFBFB",
      }}
    >
      <Box
        sx={{
          marginLeft: "41px",
          paddingTop: "24px",
          "& .customer-listing-status": {
            color: "#F6A846",
            fontFamily: "Poppins",
            fontSize: "14px",
            textTransform: "capitalize",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            display: "inline-flex !important",
            padding: "4px 16px !important",
            justifyContent: "center !important",
            alignItems: "center",
            gap: "10px",
            boxSizing: "border-box !important",
            borderRadius: "20px",
            background: "rgba(246, 168, 70, 0.10)",
          },
          "& .customer-listing-status--resolved": {
            color: "#00AE26",
            background: "rgba(0, 174, 38, 0.10)",
          },
        }}
      >
        <Box
          sx={{
            marginBottom: "30px",
            display: "flex",
            flexDirection: "row",
            columnGap: "35px",
          }}
        >
          <Select
            value={daysFilter}
            label="Customer Group"
            onChange={(event: SelectChangeEvent) => {
              switch (event.target.value) {
                case "last7Days": {
                  setFilters((prev) => ({
                    ...prev,
                    toDate: moment().format("YYYY-MM-DD"),
                    fromDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
                  }));
                  setDaysFilter("Last 7 Days");
                  break;
                }
                case "last1Month": {
                  setFilters((prev) => ({
                    ...prev,
                    toDate: moment().format("YYYY-MM-DD"),
                    fromDate: moment()
                      .subtract(1, "month")
                      .format("YYYY-MM-DD"),
                  }));
                  setDaysFilter("Last 1 Month");
                  break;
                }
                case "": {
                  setFilters((prev) => ({
                    ...prev,
                    toDate: "",
                    fromDate: "",
                  }));
                  setDaysFilter("All");
                  break;
                }
              }
            }}
            displayEmpty
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333",
              width: "180px",
              height: "40px",
              marginTop: "8px",
              background: "white",
            }}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              // show placeholder text when no option is selected
              return selected !== "" ? selected : "Days";
            }}
          >
            {/* {customerGroupLists?.customerGroupList?.edges?.map(({ node }) => (
            <MenuItem value={node.groupName}>{node.groupName}</MenuItem>
          ))} */}
            <MenuItem value={"last7Days"}>Last 7 Days</MenuItem>
            <MenuItem value={"last1Month"}>Last 1 month</MenuItem>
            <MenuItem value={""}>All</MenuItem>
          </Select>

          <Select
            value={filters.locationId}
            label="Customer Group"
            onChange={(event: SelectChangeEvent) =>
              setFilters((prev) => ({
                ...prev,
                locationId: event.target.value,
              }))
            }
            displayEmpty
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333",
              width: "180px",
              height: "40px",
              marginTop: "8px",
              background: "white",
            }}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              // show placeholder text when no option is selected
              return selected !== "" ? selected : "Location";
            }}
          >
            {locationDropdown?.storeList?.edges?.map((each: any) => (
              <MenuItem value={each.node.storeId}>
                {each.node.storeName}
              </MenuItem>
            ))}
            <MenuItem value={""}>All</MenuItem>
          </Select>
          <Select
            value={filters.employeeName}
            label="Customer Group"
            onChange={(event: SelectChangeEvent) =>
              setFilters((prev) => ({
                ...prev,
                employeeName: event.target.value as string,
              }))
            }
            displayEmpty
            sx={{
              fontSize: "16px",
              fontWeight: 500,
              color: "#333",
              width: "180px",
              height: "40px",
              marginTop: "8px",
              background: "white",
            }}
            input={<OutlinedInput />}
            renderValue={(selected) => {
              // show placeholder text when no option is selected
              return selected !== "" ? selected : "Employee";
            }}
          >
            {employeeList &&
              employeeList?.map((each: any) => (
                <MenuItem value={each.node.name} id={each.node.employeeId}>
                  {each.node.name}
                </MenuItem>
              ))}
            <MenuItem value={""}>All</MenuItem>
          </Select>
        </Box>
        <div style={{ width: "100%" }}>
          <StyledDataGrid
            rows={customerComplaints}
            columns={columns}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 25, page: 0 },
              },
            }}
            disableColumnMenu
            onRowClick={(params, event, details) =>
              handleComplaintResolution(params, event, details)
            }
          />
        </div>
      </Box>

      <DrawerStyle
        anchor="right"
        open={complaintsResolutionPanel.state}
        onClose={() =>
          setComplaintsResolutionPanel({
            state: false,
            rowData: {},
          })
        }
      >
        <ComplaintsResolutionPanel
          complaintsResolutionPanel={complaintsResolutionPanel}
          setComplaintsResolutionPanel={setComplaintsResolutionPanel}
        />
      </DrawerStyle>
    </div>
  );
};

export default RegisteredComplaints;
