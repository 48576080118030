import { gql } from "@apollo/client";

export const REPORTING_LIST=gql`
query ReportingGroupList($businessId:ID){
    reportingGroupList(businessId:$businessId) {
        edges{node{reportingGroupId
        name
            productCount
        }}
    }
            }
`

export const GET_REPORTING_DATA=gql`
query ReportingGroup($reportingGroupId:ID){
    reportingGroup(reportingGroupId:$reportingGroupId) {
        
        name
       
    }
            }
`