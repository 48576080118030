"use client";
import React, { FC, createContext, useReducer, useState } from "react";


export interface IGraphContext {
  fromTime: string,
  toTime: string,
  open: boolean,
  setFromTime: (fromTime: string) => void,
  setToTime: (toTime: string) => void,
  setOpen: (open: boolean) => void
  type: string,
  setType: (type: string) => void
  options: any,
  setOptions: Function
  openCustom: boolean,
  setOpenCustom: (open: boolean) => void
  localTime: string,
  setLocalTime: (time: string) => void
}


const Options = [
  { value: "AllDay", label: "All Day", checked: true },
  { value: "Custom", label: "Custom", checked: false },
];


export const GraphContext = createContext<IGraphContext>(null!);

const GraphProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
  const [fromTime, setFromTime] = useState<string>("00:00");
  const [toTime, setToTime] = useState<string>("23:59");
  const [open, setOpen] = useState<boolean>(false);
  const [type, setType] = useState<string>("");
  const [options, setOptions] = useState(Options);
  const [openCustom, setOpenCustom] = useState<boolean>(false);
  const [localTime, setLocalTime] = React.useState("");




  return (
    <GraphContext.Provider value={{
      open,
      setOpen,
      fromTime,
      setFromTime,
      toTime,
      setToTime,
      type,
      setType,
      options,
      setOptions,
      openCustom,
      setOpenCustom,
      localTime,
      setLocalTime
    }}>
      {children}

    </GraphContext.Provider>
  );
};

export default GraphProvider;
