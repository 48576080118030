import { Box, Typography } from "@mui/material";
import "chart.js/auto";
import {
  Chart as ChartJS,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Title,
} from "chart.js";
import React from "react";
import { Line } from "react-chartjs-2";
import TrendingUp from "../../../assets/trending-up.svg";
import { Order } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

ChartJS.register(LineController, LineElement, PointElement, LinearScale, Title);

interface Props {
  orders: Order[];
}

const OrderRate = ({ orders }: Props) => {
  const { t } = useTranslation();

  const labels = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  const datapoints = [
    500, 390, 480, 220, 208, 55, 200, 145, 300, 250, 20, 270, 430, 240,
  ];

  const mappedDataLabels = orders?.map((item) => item?.date);
  const mappedDataValues = orders?.map((item) => item?.orders);

  const data = {
    labels: mappedDataLabels || labels,
    datasets: [
      {
        label: "Orders",
        data: mappedDataValues || datapoints,
        borderColor: "#f38d37",
        backgroundColor: (context: any) => {
          if (!context.chart.chartArea) {
            return;
          }
          const {
            ctx,
            chartArea: { top, bottom },
          } = context.chart;
          const gradientBg = ctx.createLinearGradient(0, top, 0, bottom);
          gradientBg.addColorStop(0, "#fce8af");
          gradientBg.addColorStop(1, "#FFF");

          return gradientBg;
        },
        fill: true,
        tension: 0.5,
      },
    ],
  };
  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
        text: "Order Rate",
      },
      legend: {
        display: false,
      },
    },
    interaction: {
      intersect: false,
    },
    scales: {
      x: {
        grid: { drawOnChartArea: false },
        display: true,
        title: {
          display: true,
        },
      },
      y: {
        grid: { drawOnChartArea: false },
        display: true,
        title: {
          display: true,
        },
        suggestedMax: 500,
      },
    },
  };

  return (
    <Box
      sx={{
        maxWidth: { xs: "100%", md: "50%", lg: "52.03%" },
        height: { xs: "320px", md: "407px", xl: "408px" },
        width: "100%",
        background: "#FFF",
        borderRadius: "8px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        paddingTop: "12px",
        paddingLeft: { xs: "0px", md: "12px" },
        paddingRight: "6px",
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "16px",
          alignItems: "center",
        }}
      >
        <img
          height={44}
          width={44}
          src={TrendingUp}
          alt="trending"
          style={{ height: "44px", width: "44px" }}
        />
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: { xs: "20px", sm: "24px" },
            lineHeight: "36px",
            fontWeight: "500",
            color: "#000000",
          }}
        >
          {t("dashboard.Order_Rate")}
        </Typography>
      </Box>
      <Box sx={{ maxHeight: "300px", height: "100%", width: "100%" }}>
        <Line
          height={113}
          style={{ width: "100%", height: "80%" }}
          options={options}
          data={data}
        />
      </Box>
    </Box>
  );
};

export default OrderRate;
