import React, { useState, useEffect, ChangeEvent } from 'react';
import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import {

  Typography,
  Switch,
  Divider,
  Box,
  Button,
  IconButton,
  Tooltip,
  styled,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './salesTax.css';
import { useMutation, useQuery } from '@apollo/client';
import { GET_CHARGES_LIST, GET_SERVICE_TOOGLE_STATUS, } from '../../graphicalQl/usequery/settingQuery';
import { ALLOW_SERVICE_TAX, CHARGE_STATUS_CHANGE, DELETE_SERVICE_CHARGE } from '../../graphicalQl/mutation/salesTaxMutation';
import DeleteDailouge from '../category/deleteDailouge';
import { useSelector } from 'react-redux';
import { useTranslation } from "react-i18next";
import PopupWithTitle from '../popups/popupWithTitle';



interface UserData {
  id: number;
  chargeName: string;

  status: string;
  chargeRate: number;
}

const ServiceCharge: React.FC = () => {
  const { t } = useTranslation();

  const CustomStyles = styled('div')({
    '& .css-t89xny-MuiDataGrid-columnHeaderTitle': {
      color: "#333 !important",
      fontFamily: "Poppins, sans-serif !important",
      fontSize: "14px !important",

      fontStyle: "normal !important",
      fontWeight: "500 !important",
      lineHeight: "21px !important",

    },
    '& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: "solid trasperent 0px !important",
    },
    '& .css-1xrn952-MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within, .css-1xrn952-MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: "solid trasperent 0px !important",
    }
  });

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate('/service/create-service-charge');
  };

  const [taxData, setTaxData] = useState<UserData[]>([]);
  const [toggleState, setToggleState] = useState(false);

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setToggleState(event.target.checked);
    allowSalesTax({ variables: { storeId: locationId && +locationId, allowServiceTax: event.target.checked } })

  };
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { data: toggleData } = useQuery(GET_SERVICE_TOOGLE_STATUS, { variables: { storeId: locationId && +locationId }, context: { clientName: "businessClient" }, fetchPolicy: 'network-only' })

  useEffect(() => {
    if (toggleData && toggleData.storeSettings) {
      setToggleState(toggleData.storeSettings.allowServiceTax)
    }
  }
    , [toggleData])

  // const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {


  //   const status = event.target.checked ? "Active" : "InActive"
  //   salesTaxStatusChange({ variables: { chargeId: +id, status: status } })

  // };
  const [statusId, setStatusId] = useState<number | null>(null)
  const [statusState, setStatusState] = useState<boolean>(false)

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {

    setStatusId(id)
    setStatusState(event.target.checked)


  };

  const handleStatusCall = () => {
    const status = statusState ? "Active" : "InActive"
    salesTaxStatusChange({ variables: { chargeId: statusId && +statusId, status: status } })
  }

  const handleEditNavigate = (id: string) => [
    navigate(`/service/${id}/update-service-charge`)
  ]





  const [deletemodal, setDeleteModal] = useState(false)

  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal)
    // setRowSelectionModel([])

  }
  const [taxValue, setTaxValue] = useState("")
  const getTaxId = (id: string) => {
    setTaxValue(id)
  }

  const handleDleteByPopover = (id: string) => {

    deleteServiceCharge({ variables: { chargeIds: JSON.stringify([+id]) } })
    setTaxValue("")
  }

  const handleDeleteBulkCategories = () => {
    // Filter out the selected rows from the current rows state
    const updatedRows = taxData.filter((row) => rowSelectionModel.includes(row.id));
    const taxIdValues = updatedRows.map((each) => +each.id);
    deleteServiceCharge({ variables: { chargeIds: JSON.stringify(taxIdValues) } })
    setRowSelectionModel([]);


  }

  const [salesTaxStatusChange] = useMutation(CHARGE_STATUS_CHANGE, { context: { clientName: "businessClient", }, refetchQueries: [GET_CHARGES_LIST] });

  const [deleteServiceCharge] = useMutation(DELETE_SERVICE_CHARGE, { context: { clientName: "businessClient", }, refetchQueries: [GET_CHARGES_LIST] });
  const [allowSalesTax] = useMutation(ALLOW_SERVICE_TAX, { context: { clientName: "businessClient" } })


  const columns: GridColDef[] = [

    {
      field: 'chargeName',
      //@ts-ignore
      headerName: t("settings.service_charge_name"),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
    },
    {
      field: 'status',
      //@ts-ignore
      headerName: t('settings.status'),
      flex: 1,
      editable: false,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: "center",
      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={(event) => { handleStatus(event, params.row.id); setIsModalOpen1(true) }}
            color='warning'
          />
        )
      }
    },

    {
      field: 'chargeRate',
      //@ts-ignore
      headerName: t('settings.charge_rate'),
      sortable: false,
      flex: 1,
      disableColumnMenu: true,
      align: 'center',
      headerAlign: "center",
    },
    {
      field: 'charge',
      //@ts-ignore
      headerName: t('settings.action'),

      disableColumnMenu: true,
      headerAlign: "center",
      flex: 1,
      filterable: false,
      align: 'center',
      hideSortIcons: true,


      renderCell: (params) => {
        return (
          // <CustomPopoverCell
          //   value={params.row.category}
          //   bundleId={params.row.id}
          // />
          <>
            <Tooltip title="Delete" placement="top-start" arrow>
              <IconButton onClick={() => { handleDeleteModal(); getTaxId(params.row.id) }} sx={{ ':hover': { backgroundColor: '#EEE' } }} >
                <DeleteIcon sx={{ color: "grey", }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Edit" placement="top" arrow>
              <IconButton onClick={() => handleEditNavigate(params.row.id)} sx={{ color: "grey", ':hover': { backgroundColor: '#EEE' } }}>
                <EditIcon sx={{ color: "grey", }} />
              </IconButton>
            </Tooltip></>
        );

      },

    },
  ];
  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);


  const { data, } = useQuery(GET_CHARGES_LIST, { variables: { storeIds: JSON.stringify([locationId && +locationId]) }, context: { clientName: 'businessClient' }, fetchPolicy: "network-only" })
  useEffect(() => {
    if (data && data.chargesList) {
      const list = data.chargesList.edges.map((each: any) => ({
        id: each.node.chargeId,
        chargeName: each.node.chargeName,

        status: each.node.chargeStatus === "Active" ? true : false,
        chargeRate: each.node.chargePercentage ? each.node.chargePercentage + "%" : "0%",
      }))
      setTaxData(list)
    }
  }, [data])

  const getRowClass = () => {
    return "sales-rowClass"
  }

  return (
    <div>
      <Box >
        <div style={{ paddingLeft: '12px', marginBottom: '8px', marginTop: '8px' }}>
          <div className='sales_head_container'>
            <div className='sales_head_center'>
              <Typography className='sales_head'>{t('settings.service_charge')}</Typography>
            </div>
            <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
          </div>
          <Typography className='sales_para'>{t('settings.service_para')}</Typography>
        </div>
        <Divider style={{ backgroundColor: ' #D9D9D9', marginTop: '15px' }} />
        {toggleState && (
          <>
            <div style={{ display: 'flex', width: "100%", }}>

              <Box sx={{ width: '100%', textAlign: 'right' }}>
                <Button variant='contained' onClick={handleNavigate} className='salesButton' sx={{ textTransform: "none", }}>
                  {t('settings.new_charge')}
                </Button>
                {rowSelectionModel.length > 0 && (<Button className="main-button" onClick={handleDeleteModal} sx={{}}>Delete</Button>)}

              </Box>
            </div>
            <Divider style={{ backgroundColor: ' #D9D9D9' }} />

            <Box sx={{ height: " 55vh", width: '100%' }}>
              {/* <CustomStyles> */}
              <DataGrid
                rows={taxData}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                pageSizeOptions={[5, 15, 25]}
                columnHeaderHeight={48}
                getRowClassName={getRowClass}
                rowHeight={48}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                  setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
                sx={{
                  '&.MuiDataGrid-columnHeaderTitle': {
                    fontWeight: '500',
                  },
                  '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                    outline: 'solid #1976d2 0px !important',
                  },
                  '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
                    outline: 'solid #1976d2 0px !important',
                  },
                  "& .MuiDataGrid-columnHeader": {

                    background: "#f5f5f5",

                  },
                }}
              />
              {/* </CustomStyles> */}
            </Box>
          </>
        )}
      </Box>
      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Sales Tax?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => { setIsModalOpen1(false); handleStatusCall() }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
      <DeleteDailouge deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={handleDleteByPopover} id={taxValue} handleDeleteClick={handleDeleteBulkCategories} type="serviceCharge" />

    </div>
  );
};

export default ServiceCharge;
