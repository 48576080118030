import { Box } from "@mui/material";
import React from "react";
import ReceiptReport from "../../components/reports/receipts";

const ReceiptReportPage = () => {
  return (
    <Box>
      <ReceiptReport />
    </Box>
  );
};
export default ReceiptReportPage;
