import React, { useEffect, useState } from "react";
import Header from "../../components/Header";
import "./styles.css";
import OrdersSummary from "./OrderSummary";
import { useLocation, useParams } from "react-router-dom";
import {
  GET_STORE,
  GET_ORDER_DETAIL,
  ORDER_STATUS_CHANGE,
  GET_ADDRESS,
  DELIVERY_MAN,
  GET_ORDERS_LIST,
} from "../../orderUtils/graphqlQueries";
import Loader from "../../components/Loader";
import { useFormik } from "formik";
import * as Yup from "yup";
import StatusModal from "../../components/StatusModal";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import { Typography } from "@mui/material";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const stepsForDelivery = [
  "Pending",
  "Accepted",
  "Preparing",
  "Prepared",
  "On the way",
  "Completed",
];

const stepsForCollection = [
  "Pending",
  "Accepted",
  "Preparing",
  "Prepared",
  "Completed",
];

const stepsForDineIn = [
  "Pending",
  "Accepted",
  "Preparing",
  "Prepared",
  "Completed",
];

interface State {
  name: string;
  email: string;
  phone: string;
}
interface State1 {
  status: string;
  reason: string;
}
const initialValues: State1 = {
  status: "",
  reason: "",
};

const reasonsListForHidingTextfield = ["Cancelled"];

const validationSchemaBasicDetails = Yup.object({
  status: Yup.string(),
  reason: Yup.string().when("status", {
    is: (value: string) => reasonsListForHidingTextfield.includes(value),
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const Orders = () => {
  const { t } = useTranslation();

  const [stepsForCollectionForDropDown, setStepsForCollectionForDropDown] =
    useState<any[]>([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  const [stepsForDeliveryForDropDown, setStepsForDeliveryForDropDown] =
    useState<any[]>([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Dispatched",
        text: t("orders.dispatched"),
      },
      {
        value: "OnTheWay",
        text: t("orders.on_the_way"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  useEffect(() => {
    setStepsForDeliveryForDropDown([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Dispatched",
        text: t("orders.dispatched"),
      },
      {
        value: "OnTheWay",
        text: t("orders.on_the_way"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
    setStepsForCollectionForDropDown([
      {
        value: "Pending",
        text: t("orders.pending"),
      },
      {
        value: "Accepted",
        text: t("orders.accepted"),
      },
      {
        value: "Preparing",
        text: t("orders.preparing"),
      },
      {
        value: "Prepared",
        text: t("orders.prepared"),
      },
      {
        value: "Completed",
        text: t("orders.completed"),
      },
      {
        value: "Hold",
        text: t("orders.hold"),
      },
      {
        value: "Void",
        text: t("orders.void"),
      },
      {
        value: "Cancelled",
        text: t("orders.cancelled"),
      },
    ]);
  }, [t]);
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaBasicDetails,
    onSubmit: (values) => {
      handleSavePress();
    },
  });

  const location = useParams();
  const { id } = location;
  const [deliveryAddressId, setDeliveryAddressId] = useState<null | string>(
    null
  );

  const [locationId, setLocationId] = useState<null | string>(null);
  const variables = {
    addressId: deliveryAddressId,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => value !== null)
  );

  const [assignedDriver, setAssignedDriver] = useState<string | null>(null);
  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(DELIVERY_MAN, {
    context: { clientName: "userClient" },
    variables: { uuid: assignedDriver && assignedDriver },
  });
  const {
    loading: storeLoading,
    error: storeDataError,
    data: storeDetailsData,
  } = useQuery(GET_STORE, {
    context: { clientName: "businessClient" },
    variables: { storeId: locationId && +locationId },
  });
  const {
    loading: orderDetailsLoading,
    error: orderDetailsError,
    data: orderDetailsData,
  } = useQuery(GET_ORDER_DETAIL, {
    context: { clientName: "orderClient" },
    variables: { id: id },
  });

  const {
    loading: addressLoading,
    error: addressError,
    data: addressDetailsData,
  } = useQuery(GET_ADDRESS, {
    context: { clientName: "addressClient" },
    variables: filteredCategory,
  });

  const [statusChange] = useMutation(ORDER_STATUS_CHANGE, {
    onCompleted: (data) => {
      if (data) {
        const { orderStatusChange } = data;
        if (orderStatusChange) {
          const { success } = orderStatusChange;
          if (success) {
            setOpen(false);
          }
        }
      }
    },
    onError: (error) => {
      if (
        error.message.trim() ===
        "Order has pending Balance amount, Can't complete order status!"
      ) {
        toast.error(
          <div>
            <Typography>{t("error.order_pending")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      } else {
        console.log(error.message);
      }
    },
    context: { clientName: "orderClient" },
    refetchQueries: [
      DELIVERY_MAN,
      GET_ADDRESS,
      GET_ORDER_DETAIL,
      GET_STORE,
      GET_ORDERS_LIST,
    ],
    fetchPolicy: "network-only",
  });

  const [detailData, setDetailData]: any = useState();
  const [orderResponse, setorderResponse]: any = useState();
  const [storeData, setStoreData]: any = useState();
  const [addressData, setAddressData]: any = useState();
  const [status, setStatus] = useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [deliveryManDetails, setDeliveryManDetails] = useState<State | null>(
    null
  );
  const [activeSteps, setActiveSteps] = React.useState<any[]>([]);
  const [activeStepsForDropdown, setActiveStepsForDropdown] = React.useState<
    any[]
  >([]);

  const [orderType, setOrderType] = useState<null | string>(null);

  useEffect(() => {
    if (addressDetailsData) {
      setAddressData(addressDetailsData?.address);
    }
  }, [addressDetailsData]);

  useEffect(() => {
    if (storeDetailsData) {
      setStoreData(storeDetailsData);
    }
  }, [storeDetailsData]);

  useEffect(() => {
    if (orderDetailsData) {
      setAssignedDriver(orderDetailsData?.order?.assignedDriver);
      setDetailData(orderDetailsData?.order);
      setOrderType(orderDetailsData?.order?.orderType);
      setStatus(orderDetailsData?.order?.orderStatus);
      setLocationId(orderDetailsData?.order?.storeId);
      formik.setFieldValue("status", orderDetailsData?.order?.orderStatus);
      setorderResponse(orderDetailsData);
      setDeliveryAddressId(orderDetailsData?.order?.deliveryAddressId);
    }
  }, [orderDetailsData, t]);
  React.useEffect(() => {
    if (orderType === "delivery") {
      if (status === "Completed") {
        setActiveSteps(stepsForDelivery);
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveSteps(stepsForDelivery);
        setActiveStepsForDropdown(stepsForDeliveryForDropDown);
      }
    } else if (orderType === "collection") {
      if (status === "Completed") {
        setActiveSteps(stepsForCollection);
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveSteps(stepsForCollection);
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    } else if (orderType === "dine_in") {
      if (status === "Completed") {
        setActiveSteps(stepsForDineIn);
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveSteps(stepsForDineIn);
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    } else if (orderType === "shop") {
      if (status === "Completed") {
        setActiveSteps(stepsForDineIn);
        setActiveStepsForDropdown(["Cancelled", "Completed"]);
      } else {
        setActiveSteps(stepsForDineIn);
        setActiveStepsForDropdown(stepsForCollectionForDropDown);
      }
    }
  }, [
    orderType,
    status,
    stepsForDeliveryForDropDown,
    stepsForCollectionForDropDown,
  ]);

  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    if (employeesData) {
      const { employee } = employeesData;
      const { email, firstName, lastName, mobile } = employee;
      setDeliveryManDetails({
        name: `${firstName} ${lastName}`,
        email: email,
        phone: mobile,
      });
    }
  }, [employeesData]);

  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
  };
  const handleStatusChange = (event: any) => {
    if (reasonsListForHidingTextfield.includes(event.target.value)) {
      formik.setFieldValue("status", event.target.value);
    } else {
      formik.resetForm();
      formik.setFieldValue("status", event.target.value);
    }
  };

  const handleSavePress = async () => {
    statusChange({
      variables: {
        orderId: id && +id,
        orderStatus: formik.values.status,
        cancellationReason: formik.values.reason,
      },
    });
  };

  return (
    <div>
      {/* <Header /> */}
      <OrdersSummary
        detailData={detailData ? detailData : {}}
        storeData={storeData ? storeData : {}}
        addressData={addressData ? addressData : null}
        handleOpen={handleOpen}
        setOpen={setOpen}
        deliveryDetails={deliveryManDetails}
        activeSteps={activeSteps}
      />
      <StatusModal
        formik={formik}
        open={open}
        activeSteps={activeStepsForDropdown}
        status={status}
        handleClose={handleClose}
        handleStatusChange={handleStatusChange}
        handleSavePress={handleSavePress}
      />
      {/* <Loader loading={orderResponse?.loading} /> */}
    </div>
  );
};

export default Orders;
