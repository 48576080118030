import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputBase,
  Paper,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";
import { useQuery } from "@apollo/client";
import { setName } from "../../store";
import { useDispatch } from "react-redux";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}

interface Props {
  formik: any;
}

export default function LocationModal(props: Props) {
  const { formik } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleClick = () => {
    handleOpen();
  };
  const { t } = useTranslation();
  const [locationsList, seLocationsList] = React.useState<Locations[]>([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { location } = useSelector((state: any) => state.app);

  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  const dispatch = useDispatch();
  React.useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );
      if (
        formik.values.locations
        // && formik.values.locations.length > 0
      ) {
        const updatedList = requiredDataList.map((each) =>
          formik.values.locations.includes(+each.storeId)
            ? { ...each, isChecked: true }
            : each
        );
        seLocationsList(updatedList);
        setNoOfLocationsSelected(formik.values.locations.length);
        setAllCheckboxChecked(
          formik.values.locations.length === requiredDataList.length
        );
      } else {
        seLocationsList(requiredDataList);
      }
    }
  }, [locationsData, formik.values.locations]);

  const checkedCount = locationsList.filter(
    (location) => location.isChecked
  ).length;
  const [noOfLocationsSelected, setNoOfLocationsSelected] =
    React.useState(checkedCount);
  const [allCheckboxChecked, setAllCheckboxChecked] = React.useState(false);
  const handleSearchIcon = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchInput(e.target.value);
  };

  const [searchInput, setSearchInput] = React.useState("");
  const searchResults = locationsList.filter((eachLocation) =>
    eachLocation.storeName.toLowerCase().includes(searchInput)
  );
  const handlePopupCheckbox = (id: string, isChecked: boolean) => {
    const filteredList = locationsList.map((each) => {
      if (each.storeId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });
    const checkedCount = filteredList.filter(
      (location) => location.isChecked
    ).length;
    setNoOfLocationsSelected(checkedCount);
    setAllCheckboxChecked(checkedCount === filteredList.length);

    const locationListIds = filteredList
      .filter((each) => each.isChecked === true)
      .map((each1) => +each1.storeId);

    dispatch(setName("location", locationListIds));

    seLocationsList(filteredList);
  };
  const handlePopupCheckbo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const filteredList = locationsList.map((each) => ({
      ...each,
      isChecked: checked,
    }));
    setNoOfLocationsSelected(checked ? filteredList.length : 0);
    setAllCheckboxChecked(checked);
    seLocationsList(filteredList);
    const locationListIds = filteredList
      .filter((each) => each.isChecked === true)
      .map((each1) => +each1.storeId);

    dispatch(setName("location", locationListIds));
  };

  let locationsText;
  if (noOfLocationsSelected === 0) {
    locationsText = t("product.add_location");
  } else if (noOfLocationsSelected === 1) {
    locationsText = t("product.location");
  } else {
    locationsText = t("product.location");
  }

  return (
    <Box>
      <Box
        onClick={handleClick}
        className="tax-container"
        sx={{ width: "100%", justifyContent: "space-between", height: "48px" }}
      >
        <Box sx={{ display: "flex", flexDirection: "column" }}>
          {noOfLocationsSelected === 0 ? (
            <Typography className="normal-text-for-textField">
              {" "}
              {t("product.select_location")}
            </Typography>
          ) : (
            <Typography className="normal-text" sx={{ marginBottom: "0px" }}>
              {noOfLocationsSelected === 0
                ? locationsText
                : `${noOfLocationsSelected} ${locationsText}`}
            </Typography>
          )}
        </Box>

        <ArrowDropDownIcon sx={{ color: "grey" }} />
      </Box>
      <div>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box>
              <Box>
                <IconButton className="exit-container" onClick={handleClose}>
                  <CloseIcon className="exitIcon" />
                </IconButton>
                <Paper
                  elevation={0}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    border: "1px solid #eee",
                    marginTop: "12px",
                  }}
                >
                  <IconButton type="button" sx={{ p: "10px" }}>
                    <SearchIcon />
                  </IconButton>
                  <InputBase
                    value={searchInput}
                    onChange={(e) => handleSearchIcon(e)}
                    sx={{ ml: 1, flex: 1 }}
                    //@ts-ignore
                    placeholder={t("product.search_by_location")}
                    //@ts-ignore
                    // placeholder={t("driver_commission.search_by_driver_name")}
                    inputProps={{ "aria-label": "search google maps" }}
                  />
                </Paper>
              </Box>
              {searchResults.length > 0 ? (
                <Box
                  sx={{
                    height: "37vh",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    zIndex: "10",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      overflowY: "auto",
                      maxHeight: "32vh",
                      marginTop: "10px",
                      minHeight: "10vh",
                      padding: "12px",
                    }}
                  >
                    <FormGroup>
                      <FormControlLabel
                        key={"all"}
                        control={
                          <Checkbox
                            checked={allCheckboxChecked}
                            onChange={(e) => handlePopupCheckbo(e)}
                            // sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                            color="warning"
                          />
                        }
                        label={<Typography>{t("buttons.all")}</Typography>}
                      />
                      {searchResults.map((each: any) => (
                        <FormControlLabel
                          key={each.storeId}
                          control={
                            <Checkbox
                              onChange={() => {
                                handlePopupCheckbox(
                                  each.storeId,
                                  each.isChecked
                                );
                              }}
                              // sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              color="warning"
                              checked={each.isChecked}
                            />
                          }
                          label={<Typography>{each.storeName}</Typography>}
                        />
                      ))}
                    </FormGroup>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "30vh",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ABABAB",
                      textAlign: "center",
                      fontFamily: "Poppins",
                      fontSize: "14px",
                      fontStyle: "normal",
                      fontWeight: 400,
                      lineHeight: "normal",
                    }}
                  >
                    No Locations have been listed
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </Modal>
      </div>
    </Box>
  );
}
