import React, { useEffect, useState } from 'react';
import {
    Typography, Paper, Button, Switch, TextField,
    InputAdornment,
    Box
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useFormik } from "formik";
import * as Yup from "yup";
import './rounding.css';
import { OPEN_ITEMS_CREATE } from '../../graphicalQl/mutation/salesTaxMutation';
import { useMutation, useQuery } from '@apollo/client';
import { OPEN_ITEMS } from '../../graphicalQl/usequery/settingQuery';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const validationSchema = Yup.object({
    value: Yup.string().required("Value required"),
    // description: Yup.string().max(100, 'Maximum of 100 characters allowed'),
});
const OpenItems = () => {
    const [toggleState, setToggleState] = useState<boolean>(false);
    const [discountsEnabled, setDiscountsEnabled] = useState<boolean>(false);
    const [rewardPointsEnabled, setRewardPointsEnabled] = useState<boolean>(false);
    const [stockReductionEnabled, setStockReductionEnabled] = useState<boolean>(false);
    const [inputValue, setInputValue] = useState<string>('');
    const currencySymbol = sessionStorage.getItem('currencySymbol')
    const { t } = useTranslation()
    const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setToggleState(event.target.checked);
    };

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            value: 0

        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            handleSave()
        },
    });


    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(event.target.value);
    };

    const handleDiscountsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setDiscountsEnabled(event.target.checked);
    };

    const handleRewardPointsChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRewardPointsEnabled(event.target.checked);
    };

    const handleStockReductionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setStockReductionEnabled(event.target.checked);
    };
    const [openItems, { loading }] = useMutation(OPEN_ITEMS_CREATE, {
        context: { clientName: "businessClient" },
        onCompleted: () => {
            toast.success(<div >
                <Typography>{t("toast.updated")}</Typography>
            </div>,
                {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'light',
                    className: 'custom_toast3',
                })
        },
        onError: (error) => {
            console.error('Error:', error);
        },
    })
    const { locationId } = useSelector((state: any) => state.headerLocation);
    const handleSave = () => {
        openItems({
            variables: {
                storeId: locationId && +locationId,
                allowPosUserAdhocItem: toggleState,
                adhocItemMaxPrice: +inputValue,
                allowDiscountOnAdhoc: discountsEnabled,
                allowRewardOnAdhoc: rewardPointsEnabled,
                allowStockDeductionOnAdhoc: stockReductionEnabled,
            }
        })
    }

    const { data } = useQuery(OPEN_ITEMS, {
        variables: { storeId: locationId && +locationId }, context: { clientName: "businessClient" }, fetchPolicy: "network-only",
        onError: (error) => {
            console.error('Error:', error);
        },
    })
    useEffect(() => {
        if (data && data.storeSettings) {
            setToggleState(data.storeSettings.allowPosUserAdhocItem)
            setInputValue(data.storeSettings.adhocItemMaxPrice)
            formik.setFieldValue("value", data.storeSettings.adhocItemMaxPrice)
            setDiscountsEnabled(data.storeSettings.allowDiscountOnAdhoc)
            setRewardPointsEnabled(data.storeSettings.allowRewardOnAdhoc)
            setStockReductionEnabled(data.storeSettings.allowStockDeductionOnAdhoc)


        }
    }, [data])


    return (
        <div>
            <form onSubmit={formik.handleSubmit}>
                <Paper elevation={0} style={{ padding: "20px" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <Typography className="rounding_titleValue">{t("settings.adoc_title")}</Typography>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            {/* <Button variant="contained" className="rounding_Button_clear">
                            {t("settings.clear")}
                        </Button> */}
                            <Button variant="contained" className="Rounding_save_button" style={{ marginLeft: "20px" }} type="submit" disabled={loading}>
                                {t("settings.save")}
                            </Button>
                        </div>
                    </div>
                    <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)" }} className='refund_page'>
                        <div>
                            <div style={{ display: "flex", alignItems: "center" }}>
                                <Typography className="refund_head" style={{ marginRight: "60px" }}>{t("settings.adhoc_para")}</Typography>
                                <Switch checked={toggleState} onChange={handleToggleChange} color='warning' />
                            </div>
                            <Typography className="refund_para">{t("settings.adoc_para2")}</Typography>
                        </div>
                    </Paper>

                    {toggleState && (
                        <>
                            <div>
                                <Paper style={{ marginLeft: '8%', marginTop: '44px', width: "73%", padding: "8px 16px 8px 16px", borderLeft: "2px solid   var(--stroke-color-2-tale, #9BDAE2)" }} className='refund_page'>
                                    <Typography className="refund_head">{t("settings.adoc_para3")}</Typography>
                                    <Box>
                                        <TextField
                                            style={{ marginLeft: "10px", marginTop: '10px' }}
                                            label={t("settings.max_price")}
                                            variant="outlined"
                                            color='warning'
                                            value={inputValue}
                                            onBlur={formik.handleBlur}
                                            error={Boolean(formik.touched.value && formik.errors.value)}
                                            name="value"
                                            // type="number"
                                            // className="hide-number-input-icons"
                                            // onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                            //     if (e.target.value.length < 7) {
                                            //         handleInputChange(e)
                                            //     }

                                            // }

                                            // }

                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                                const val = e.target.value;
                                                if (((!val || /^[0-9]+$/.test(val)) && val.length <= 5)) {
                                                    handleInputChange(e);
                                                    formik.handleChange(e)
                                                }
                                            }} type="text"
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">{currencySymbol}</InputAdornment>,
                                            }}
                                            helperText={
                                                formik.touched.value && typeof formik.errors.value === 'string' ? formik.errors.value : ""
                                            }
                                        />
                                        <Box sx={{ textAlign: 'right' }}>
                                            <Typography sx={{ fontSize: "12px", color: "#707070" }}>{inputValue ? inputValue.toString().length : 0}/5</Typography>
                                        </Box>
                                    </Box>
                                    <Typography className='refund_para' style={{ marginTop: "12px" }}>{t("settings.adoc_para4")}</Typography>

                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className='refund_head4' style={{ marginRight: "60px" }}>{t("settings.adoc_para5")}</Typography>
                                        <Switch
                                            checked={discountsEnabled}
                                            onChange={handleDiscountsChange}
                                            color='warning'
                                        />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className='refund_head4' style={{ marginRight: "27px" }}>{t("settings.adoc_para6")}</Typography>
                                        <Switch
                                            checked={rewardPointsEnabled}
                                            onChange={handleRewardPointsChange}
                                            color='warning'
                                        />
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center" }}>
                                        <Typography className='refund_head4' style={{ marginRight: "18px" }}>{t("settings.adoc_para7")}</Typography>
                                        <Switch
                                            checked={stockReductionEnabled}
                                            onChange={handleStockReductionChange}
                                            color='warning'
                                        />
                                    </div>
                                </Paper>
                            </div>
                        </>
                    )}

                </Paper>
            </form>
        </div>
    )
}

export default OpenItems
