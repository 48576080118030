import {
  Box,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Button,
  Divider,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { UPDATE_STOCK_ADJUSTMENT_REASONS } from "../../graphicalQl/mutation/inventoryListsMutation";
import { SELECTED_REASONS_LIST } from "../../graphicalQl/usequery/inventoryListsQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import { INVENTORY_PRODUCT_LIST } from "../../graphicalQl/usequery/productsListQuery";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import "./stockAdjustmentReasons.css";
import CircularLoader from "../../loader/loader";

function StockAdjustmentReasons() {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [reasonsFromServer, setReasonsFromServer] = useState<any[]>([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState<boolean>(true);
  const [checkedReasons, setCheckedReasons] = useState<any[]>([
    "stock_received",
  ]);

  const variables = {
    locationId: locationId ? +locationId : null,
  };

  const filteredData = Object.fromEntries(
    Object.entries(variables).filter(([_, value]) => value !== null)
  );

  const {
    data: selectedReasonsdata,
    loading,
    error,
  } = useQuery(SELECTED_REASONS_LIST, {
    context: { clientName: "categoryClient" },
    variables: filteredData,
    fetchPolicy: "network-only",
  });

  const [updateStockAdjustmentReasons] = useMutation(
    UPDATE_STOCK_ADJUSTMENT_REASONS,
    {
      onCompleted: (data) => {
        if (data?.addInventoryReasons?.success) {
          setIsButtonDisabled(true);
          toast.success(<Typography>{t("toast.updated")}</Typography>, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          });
        }
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [INVENTORY_PRODUCT_LIST, SELECTED_REASONS_LIST],
    }
  );

  const [reasonsList, setReasonsList] = useState<any[]>([
    { value: "stock_received", label: "Stock Received" },
    {
      value: "inventory_recount",
      label: "Inventory Recount",
    },
    { value: "damage", label: "Damage" },
    { value: "lost", label: "Lost" },
    { value: "restock_return", label: "Restock Return" },
    { value: "item_purchased", label: "Item Purchased" },
    { value: "order_rejected", label: "Order Rejected" },
  ]);

  useEffect(() => {
    if (selectedReasonsdata) {
      const { inventoryReasons } = selectedReasonsdata;
      if (inventoryReasons) {
        const { reasons } = inventoryReasons;
        if (reasons) {
          const parsedData = JSON.parse(reasons);
          setCheckedReasons(parsedData);
          setReasonsFromServer(parsedData);
        }
      }
    }
  }, [selectedReasonsdata]);

  useEffect(() => {
    const areArraysEqual = (arr1: any[], arr2: any[]) =>
      arr1.length === arr2.length &&
      arr1.sort().every((value, index) => value === arr2.sort()[index]);

    setIsButtonDisabled(areArraysEqual(checkedReasons, reasonsFromServer));
  }, [checkedReasons, reasonsFromServer]);

  const handleCheckbox = (value: string) => {
    if (value === "stock_received") {
      return checkedReasons;
    } else {
      if (checkedReasons.includes(value)) {
        const removedData = checkedReasons.filter(
          (reason: any) => reason !== value
        );
        setCheckedReasons(removedData);
      } else {
        setCheckedReasons([...checkedReasons, value]);
      }
    }
  };

  const handleSaveButton = () => {
    const variables = {
      locationId: locationId && +locationId,
      businessId: +businessId,
      reasons: JSON.stringify(checkedReasons),
    };
    updateStockAdjustmentReasons({ variables });
  };

  return (
    <Box sx={{ padding: "20px" }}>
      <Box
        sx={{
          padding: "5px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography className="stock-adjustment-reasons-header">
          {t("inventory.stock_adjustment_reasons")}
        </Typography>
        <Button
          disabled={isButtonDisabled}
          onClick={handleSaveButton}
          className="stock-adjustment-reasons-header-save-button"
          color="warning"
          variant="contained"
        >
          {t("inventory.save")}
        </Button>
      </Box>
      <Divider sx={{ marginBottom: "15px", marginTop: "10px" }} />
      {loading ? (
        <CircularLoader />
      ) : (
        <Paper
          elevation={1}
          sx={{
            width: "60%",
            padding: "20px",
            borderLeft: "2px solid #AE9BE2",
          }}
        >
          <Box
            sx={{
              width: "70%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="stock-adjustment-reasons-heading">
              {t("inventory.show_reasons_for_inventory_adjustments")}
            </Typography>
          </Box>
          <Typography
            sx={{ marginTop: "1%" }}
            className="stock-adjustment-reasons-paragraph"
          >
            {t("inventory.display_reasons_inventory_adjustments")}
          </Typography>
          <FormGroup sx={{ margin: "10px" }}>
            {reasonsList.map((eachReason, index) => (
              <FormControlLabel
                key={index}
                sx={{ marginBottom: "2px" }}
                control={
                  <Checkbox
                    checked={checkedReasons.includes(eachReason.value)}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                    onChange={() => handleCheckbox(eachReason.value)}
                    color="warning"
                    className="stock-adjustment-reasons-checkbox-size"
                  />
                }
                label={
                  <Typography className="stock-adjustment-reasons-checkbox-label">
                    {eachReason.label}
                  </Typography>
                }
                className="stock-adjustment-reasons-checkbox"
              />
            ))}
          </FormGroup>
        </Paper>
      )}
    </Box>
  );
}

export default StockAdjustmentReasons;
