import * as React from "react";
import "../options/createProduct.css";
import { useEffect } from "react";
import {
  Box,
  Button,
  Typography,
  TextField,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Visibility } from "./visibility";
import "./menuModal.css";
import { useMutation, useQuery } from "@apollo/client";
import { useDispatch } from "react-redux";
import { setName } from "../../store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_MENU } from "../../graphicalQl/mutation/menuMutation";
import { useSelector } from "react-redux";
import { GET_MENU_DATA, MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { UPDATE_MENU } from "../../graphicalQl/mutation/menuMutation";
import { toast } from "react-toastify";
import { resetState, setVisibility } from "../reduxReducers/categoryReducer";
import CircularLoader from "../../loader/loader";
import LocationModal from "../product/locationModal";
import ImageDrawer from "./image/imageDrawer";
import { useNavigate, useParams } from "react-router";
import ErrorModal from "../errorHandling/errorModal";
import { useTranslation } from "react-i18next";
import moment from "moment";

const validationSchemaPart1 = Yup.object({
  menu: Yup.string().trim().required("Required"),
  // locations:Yup.string().required('location is required'),
  locations: Yup.array().min(1, "At least one location is required"),
});

interface Channel {
  id: number;
  name: string;
  isChecked: boolean;
}
interface Menu {
  menu: string;
  description: string;
  showPOS: boolean;
  showOnline: boolean;
  hubriseKey: string;
  locations: any; // Assuming locations are represented as strings (IDs) in your JSON
  channles: Channel[]; // Assuming you have a separate type or interface for channels
  orderType: Channel[]; // Assuming you have a separate type or interface for order types
}

export default function MenuCreate() {
  const { id } = useParams();
  const { t } = useTranslation();

  const [channles, setChannels] = React.useState([
    { id: 1, name: "zomato", isChecked: false },
    { id: 2, name: "swiggy", isChecked: false },
    { id: 3, name: "uberEats", isChecked: false },
    { id: 4, name: "zepto", isChecked: false },
  ]);

  const [orderType, setOrderType] = React.useState([
    { id: 1, name: t("menus.create_menu.delivery"), isChecked: false },
    { id: 2, name: t("menus.create_menu.DineIn"), isChecked: false },
    { id: 3, name: t("menus.create_menu.Shop"), isChecked: false },
    { id: 4, name: t("menus.create_menu.Collection"), isChecked: false },
  ]);

  useEffect(() => {
    setOrderType([
      {
        id: 1,
        name: t("menus.create_menu.delivery"),
        isChecked: orderType[0].isChecked,
      },
      {
        id: 2,
        name: t("menus.create_menu.DineIn"),
        isChecked: orderType[1].isChecked,
      },
      {
        id: 3,
        name: t("menus.create_menu.Shop"),
        isChecked: orderType[2].isChecked,
      },
      {
        id: 4,
        name: t("menus.create_menu.Collection"),
        isChecked: orderType[3].isChecked,
      },
    ]);
  }, [t]);

  const [categoryStatusCode, setCategoryStatusCode] = React.useState<number>(0);
  const [errorModal, setErrorModal] = React.useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const {
    startTime,
    endTime,
    weekDays,
    selectDate,
    showOnCheckBox,
    hideUntilCheckBox,
  } = useSelector((state: any) => state.categoryVisibilty);

  const formattedData = weekDays.reduce((acc: any, each: any) => {
    acc[each.day] = {
      isOpen: each.isChecked,
      timings: [
        {
          openTime:
            startTime !== ""
              ? moment(startTime, "h:mm A").format("HH:mm")
              : null,
          closingTime:
            endTime !== "" ? moment(endTime, "h:mm A").format("HH:mm") : null,
        },
      ],
    };
    return acc;
  }, {});

  const { location } = useSelector((state: any) => state.app);

  const [state, setState] = React.useState<Menu>({
    menu: "",
    description: "",
    showPOS: true,
    showOnline: true,
    locations: location,
    hubriseKey: "",
    channles: channles,
    orderType: orderType,
  });

  useEffect(() => {
    formik.setFieldValue("locations", location);
  }, [location]);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const checkedChannels = channles
    .filter((each) => each.isChecked === true)
    .map((item) => item.name);

  const formik = useFormik({
    initialValues: state,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (id) {
        updateMenu({
          variables: {
            menuId: id && +id,
            name: formik.values.menu,
            description: formik.values.description,
            // image:imageUrl,
            isPos: formik.values.showPOS,
            isOnline: formik.values.showOnline,
            channelTypes: JSON.stringify(checkedChannels),
            isCarry: formik.values.orderType[2].isChecked,
            isDinein: formik.values.orderType[1].isChecked,
            isDelivery: formik.values.orderType[0].isChecked,
            isCollection: formik.values.orderType[3].isChecked,
            showOn: showOnCheckBox ? JSON.stringify(formattedData) : null,
            hideUntil: hideUntilCheckBox ? selectDate : null,
            hbrMenuId: formik.values.hubriseKey,
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
        });
      } else {
        createMenu({
          variables: {
            name: formik.values.menu,
            locations: JSON.stringify(formik.values.locations),
            description: formik.values.description,
            // image:imageValue,
            isPos: formik.values.showPOS,
            isOnline: formik.values.showOnline,
            channelTypes: JSON.stringify(checkedChannels),
            isCarry: formik.values.orderType[2].isChecked,
            isDinein: formik.values.orderType[1].isChecked,
            isDelivery: formik.values.orderType[0].isChecked,
            isCollection: formik.values.orderType[3].isChecked,
            showOn: showOnCheckBox ? JSON.stringify(formattedData) : null,
            businessId: +businessId,
            hideUntil: hideUntilCheckBox ? selectDate : null,
            hbrMenuId: formik.values.hubriseKey,
          },
          onError: (error) => {
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
        });
      }
    },
  });

  const navigate = useNavigate();
  const handlExit = () => {
    // navigate("/menu/menu-list")
    window.history.back();
    formik.resetForm({ values: state });

    dispatch(resetState());
  };

  const {
    data: menuData,
    refetch,
    loading: menuDataLoading,
  } = useQuery(GET_MENU_DATA, {
    context: { clientName: "categoryClient" },
    variables: { menuId: id && +id },
    fetchPolicy: "network-only",
  });
  const [showOnData, setShowOndata] = React.useState<any>();
  useEffect(() => {
    if (menuData) {
      const { menu } = menuData;
      if (menu) {
        formik.setFieldValue("menu", menu.name ? menu.name : "");
        formik.setFieldValue(
          "description",
          menu.description ? menu.description : ""
        );
        formik.setFieldValue("showPOS", menu.isPos);
        formik.setFieldValue("showOnline", menu.isOnline);
        formik.setFieldValue("locations", [9]);
        formik.setFieldValue("hubriseKey", menu.hbrMenuId);
        if (menu.channelTypes) {
          const list = channles.map((each) =>
            JSON.parse(menu.channelTypes).includes(each.name)
              ? { ...each, isChecked: true }
              : each
          );
          formik.setFieldValue("channles", list);
        }

        const list = orderType.map((each) =>
          each.name === t("menus.create_menu.delivery")
            ? { ...each, isChecked: menu.isDelivery ? menu.isDelivery : false }
            : each.name === t("menus.create_menu.DineIn")
            ? { ...each, isChecked: menu.isDinein ? menu.isDinein : false }
            : each.name === t("menus.create_menu.Shop")
            ? { ...each, isChecked: menu.isCarry ? menu.isCarry : false }
            : each.name === t("menus.create_menu.Collection")
            ? {
                ...each,
                isChecked: menu.isCollection ? menu.isCollection : false,
              }
            : each
        );
        formik.setFieldValue("orderType", list);

        if (menu.showOn) {
          setShowOndata(menu.showOn);
        }
        if (menu.hideUntil) {
          dispatch(setName("selectDate", menu.hideUntil));
        }
      }
    }
  }, [menuData]); // Fix the dependency array

  const dispatch = useDispatch();

  const handleChannels = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const updatedList = channles.map((each) =>
      each.id === id ? { ...each, isChecked: e.target.checked } : each
    );

    setChannels(updatedList);
  };

  useEffect(() => {
    formik.setFieldValue("channles", channles);
  }, [channles]);

  const handleOrderType = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const updatedList = orderType.map((each) =>
      each.id === id ? { ...each, isChecked: e.target.checked } : each
    );

    setOrderType(updatedList);
  };

  useEffect(() => {
    formik.setFieldValue("orderType", orderType);
  }, [orderType]);

  const [createMenu] = useMutation(CREATE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      handlExit();
    },
  });
  const [updateMenu] = useMutation(UPDATE_MENU, {
    context: { clientName: "categoryClient" },
    refetchQueries: [MENU_LIST],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      handlExit();
    },
  });
  const [charCount, setCharCount] = React.useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };
  const [uploadImg, setUploadImg] = React.useState<string | null>(null);
  const [uploadColor, setUploadColor] = React.useState<string | null>(null);

  const handleImage = (value: string | null) => {
    setUploadImg(value);
    dispatch(setName("image", value));
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
    dispatch(setName("color", value));
  };
  const imageDrawerRef = React.useRef<any>();

  if (menuDataLoading) return <CircularLoader />;
  return (
    <Box sx={{ padding: "12px 18px" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <IconButton onClick={handlExit} className="exit-container">
            <CloseIcon className="exitIcon" />
          </IconButton>
          {!id ? (
            <Typography className="menuModal1">
              {t("menus.create_menu.create_menus")}
            </Typography>
          ) : (
            <Typography className="menuModal1">
              {t("menus.create_menu.edit_menu")}
            </Typography>
          )}

          <Typography></Typography>
        </Box>
        <div style={{ display: "flex", marginTop: "24px" }}>
          <div style={{ width: "60%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "start",
                justifyContent: "space-between",
              }}

              //  className="create-product-text-container"
            >
              <Box sx={{ width: "45%" }}>
                <TextField
                  id="outlined-basic"
                  label={t("menus.create_menu.menu_name")}
                  variant="outlined"
                  name="menu"
                  value={formik.values.menu}
                  error={formik.touched.menu && Boolean(formik.errors.menu)}
                  helperText={formik.touched.menu && t("error.required")}
                  onChange={(e) => {
                    if (Number(e.target.value.length) < 26) {
                      formik.handleChange(e);
                    }
                  }}
                  onBlur={formik.handleBlur}
                  color="warning"
                  sx={{ width: "100%" }}
                />
                <Box sx={{ width: "100%", textAlign: "right" }}>
                  <Typography sx={{ fontSize: "12px", color: "#707070" }}>
                    {formik.values.menu.length}/ 25
                  </Typography>
                </Box>
                {/* {formik.touched.menu && formik.errors.menu && (
                  <div style={{ color: "red" }}>{formik.errors.menu as string}</div>
                )} */}
              </Box>
              {!id && (
                <Box sx={{ width: "45%" }}>
                  <LocationModal formik={formik} />
                  {formik.touched.locations && formik.errors.locations && (
                    <Typography
                      style={{
                        color: "red",
                        fontSize: "12px",
                        marginTop: "4px",
                      }}
                    >
                      {t("error.atleast_one_location") as string}
                    </Typography>
                  )}
                </Box>
              )}
            </Box>

            <div
              className="textarea-box"
              style={{ width: "100%", marginTop: "24px" }}
            >
              <textarea
                id="textarea"
                onChange={(e) => {
                  updateCharCount(e);
                  formik.handleChange(e);
                }}
                style={{ width: "100%" }}
                name="description"
                value={formik.values.description}
                //@ts-ignore
                placeholder={t("menu_setUp.enter_description")}
              >
                {formik.values.description}
              </textarea>
              <div id="charCount" className="char-count">
                {charCount}/500
              </div>
            </div>
            <TextField
              id="outlined-basic"
              label={t("menu_setUp.hubrise_key")}
              variant="outlined"
              name="hubriseKey"
              value={formik.values.hubriseKey}
              onChange={(e) => {
                formik.handleChange(e);
              }}
              onBlur={formik.handleBlur}
              color="warning"
              sx={{ width: "100%" }}
            />
          </div>

          <Box
            sx={{
              marginLeft: "20%",
              height: "150px",
              width: "150px",
              padding: "2px",
            }}
            className="create-product-img-con"
          >
            <ImageDrawer
              handleImage={handleImage}
              ref={imageDrawerRef}
              handleColor={handleColor}
              colorCodeFromApi={null}
              imageFromApi={null}
              heightValue={120}
            />
          </Box>
        </div>

        <div
          style={{
            marginLeft: "1%",
            marginTop: "1%",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ width: "51%" }}>
            <FormControlLabel
              control={
                <Checkbox
                  color="warning"
                  name="showPOS"
                  checked={formik.values.showPOS}
                  onChange={(e) => formik.handleChange(e)}
                />
              }
              label={
                <Typography className="create-product-switch-text">
                  {t("menus.create_menu.show_pos")}
                </Typography>
              }
              style={{ width: "40%" }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  color="warning"
                  name="showOnline"
                  checked={formik.values.showOnline}
                  onChange={(e) => formik.handleChange(e)}
                />
              }
              label={
                <Typography className="create-product-switch-text">
                  {t("menus.create_menu.show_online")}
                </Typography>
              }
              style={{ width: "40%" }}
            />
          </div>
        </div>

        <div style={{ marginTop: "2%" }}>
          <Visibility categoryId={id && +id} showOnData={showOnData} />
        </div>
        <Typography className="menuModal" sx={{ marginTop: "2%" }}>
          {t("menus.create_menu.channel_type")}
        </Typography>
        <div>
          {formik.values.channles.map((each: any) => (
            <FormControlLabel
              key={each.id}
              control={
                <Checkbox
                  color="warning"
                  name="channels"
                  checked={each.isChecked}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleChannels(e, each.id);
                  }}
                  key={each.id}
                />
              }
              label={
                <Typography className="create-product-switch-text">
                  {each.name}
                </Typography>
              }
              style={{ width: "20%" }}
            />
          ))}
        </div>

        <Typography className="menuModal" sx={{ marginTop: "2%" }}>
          {t("menus.create_menu.order_type")}
        </Typography>
        <div style={{ display: "flex", marginTop: "1%" }}>
          {formik.values.orderType.map((each: any) => (
            <FormControlLabel
              key={each.id}
              control={
                <Checkbox
                  color="warning"
                  name="channels"
                  checked={each.isChecked}
                  onChange={(e) => {
                    formik.handleChange(e);
                    handleOrderType(e, each.id);
                  }}
                  key={each.id}
                />
              }
              label={
                <Typography className="create-product-switch-text">
                  {each.name}
                </Typography>
              }
              style={{ width: "20%" }}
              className="menuOrderType"
            />
          ))}
        </div>
        <Box
          sx={{
            width: "100%",
            marginTop: "12px",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Button
              style={{ textTransform: "none", textDecoration: "underline" }}
              className="create-product-cancel_Button"
              onClick={() => {
                formik.resetForm();
                dispatch(resetState());
                if (imageDrawerRef.current) {
                  imageDrawerRef.current.handleNull();
                }
                setUploadColor(null);
                setUploadImg(null);
              }}
            >
              {t("menus.create_menu.Cancel")}
            </Button>
            <Button
              style={{ textTransform: "none" }}
              className="create-product-button"
              type="submit"
            >
              {t("menus.create_menu.save")}
            </Button>
          </div>
        </Box>
      </form>

      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
    </Box>
  );
}
