import { Box, Button, Divider, IconButton, Modal, TextField, Typography } from '@mui/material'
import React from 'react'
import CloseIcon from '@mui/icons-material/Close';


import "./forgetPasswordPopup.css"
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "45%",
    height:"50vh",
    p:2,
    bgcolor: 'background.paper',
    borderRadius:"8px"
   
  };

  interface Props{
    open:boolean
    handleClose:()=>void
    formik:any
  }


const ForgetPasswordPopup = (props:Props) => {
    const {open,handleClose,formik}=props

  
    
    
  return (
   <Box>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <Box sx={{width:"100%"}}>
                    <Box sx={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                    <IconButton className="exit-container" onClick={handleClose}>
                            <CloseIcon className="exitIcon" />
                        </IconButton>

                        <Button type='submit' sx={{textTransform:"none"}} color='warning' variant='contained'>Continue</Button>
                      
                    </Box>
                    <Divider sx={{marginTop:"2%",marginBottom:"1%"}}/>
                    <Box sx={{padding:"2%"}}>
                        <Typography sx={{marginBottom:"1%"}} className='forget-password-text'>Forgot Password</Typography>
                        <Typography sx={{marginBottom:"2%"}} className='forget-password-description'>Please provide your email address, and we'll send you a password code to sign in to your account</Typography>
                        <Box sx={{display:"flex",flexDirection:"column"}}>
                        <TextField
                         sx={{marginTop:"3%",width:"100%"}}
                          id="outlined-basic"
                          onChange={(e) => {formik.handleChange(e) }}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                          name="email"
                          color='warning'
                          label="Enter your email"
                          variant="outlined" />
                          {formik.touched.email && formik.errors.email && (
                            <Box>
                            <Typography className='create-a-user-formik-label'>{formik.errors.email}</Typography>
                            </Box>
                          )}
                        </Box>
                       
                    </Box>


                </Box>
            </form>
           
        </Box>
      </Modal>
   </Box>
  )
}

export default ForgetPasswordPopup
