import {
  Box,
  Divider,
  FormControl,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "8px",
};

interface State {
  open: boolean;
  handleClose: () => void;
}
const DateRangeForLoyality = (props: State) => {
  const { open, handleClose } = props;
  const options = [
    { value: "week", label: "Week" },
    { value: "Month", label: "Month" },
    { value: "Days", label: "Days" },
  ];
  return (
    <Box sx={{ width: "100%" }}>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "70%",
              alignItems: "center",
              marginBottom: "1%",
            }}
          >
            <IconButton className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover">
              <CloseIcon />
            </IconButton>
            <Typography className="loyality-points-date-range-header">
              Expiry Date for loyalty points
            </Typography>
          </Box>

          <Divider />
          <Box sx={{ marginTop: "1%", width: "100%", textAlign: "center" }}>
            <Typography className="loyality-points-date-range-description">
              Set a custom deadline for customers to redeem their accrued
              loyalty points earned through ordering.
            </Typography>
          </Box>
          <Box
            sx={{ display: "flex", justifyContent: "center", marginTop: "2%" }}
          >
            <Box
              style={{ display: "flex", width: "45%", height: "55px" }}
              className="refund_input_border"
            >
              <TextField
                variant="standard"
                name="refundInputValue"
                // onChange={(e) => formik.handleChange(e)}
                type="number"
                className="hide-number-input-icons create_advance_input"
                placeholder="Enter"
                InputProps={{
                  disableUnderline: true, // This removes the underline
                }}
                style={{ marginLeft: "10px", marginTop: "15px" }}
              />
              <FormControl
                style={{
                  width: "20%",
                  marginBottom: "0px",
                  marginTop: "15px",
                  marginRight: "10px",
                }}
                className="create_advance_select"
                variant="standard"
              >
                <Select
                  // value={formik.values.refundSelectedOption}
                  name="refundSelectedOption"
                  // onChange={(e) => formik.handleChange(e)}
                  style={{ marginLeft: "10px" }}
                >
                  {options.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default DateRangeForLoyality;
