import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import styled from "styled-components";
import TypeSearch from "../../common/layouts/SideBar/SearchInput";
import AddCustomer from "../addCustomer";
import ViewCustomerGroup from "../customerGroup/viewCustomerGroup";
import AddCustomerGroup from "../customerGroup/addCustomerGroup";
import ColumnVisibilityFilter from "./columnVisibilityFilter";
import { useMutation, useQuery } from "@apollo/client";
import {
  CUSTOMER_GROUP_LIST,
  SEARCH_CUSTOMERS,
} from "../../../graphicalQl/usequery/customerDataQuery";
import {
  CUSTOMER_STATUS_CHANGE,
  DELETE_CUSTOMERS,
} from "../../../graphicalQl/mutation/customerDataMutation";
import DeleteIcon from "../../../assets/delete.svg";
import InfoIcon from "../../../assets/info.svg";
import CloseIcon from "../../../assets/close2.png";
import { Sheet } from "@mui/joy";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CustomFooter from "../../../pages/category/footer";
import DelayedInput from "../../../utils/delayedInput";

const CustomerListingTable = () => {
  // add customer modal state management
  const [addCustomerModal, setAddCustomerModal] = useState({
    state: false, //modal open or close state
    mode: "add", //modal to be opened for adding new customer or updating existing one.
    customerDetails: {}, //details of the selected customer to be updated
  });

  // add customer group modal state management
  const [addCustomerGroupModal, setAddCustomerGroupModal] = useState({
    state: false, //modal open or close state
    mode: "add", //modal to be opened for adding new customer group or updating existing one.
    customerGroupDetails: {}, //details of the selected customer group to be updated
  });

  // view customer group details modal state management
  const [viewCustomerGroupModal, setViewCustomerGroupModal] = useState(false);

  // search from all customers
  const [customerSearchQuery, setCustomerSearchQuery] = useState("");

  // confirmation modal for deletion of customers
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<any>({
    state: false,
    customerTobeDeleted: [],
  });

  const { t } = useTranslation();
  const [selectedRows, setSelectionRows] = useState<GridRowSelectionModel>([]);

  // state to visible columns in data grid
  const [visibleColumns, setVisibleColumns] = useState({
    customerName: true,
    phone: true,
    mail: true,
    customerGroup: true,
    status: true,
    loyaltyPoints: true,
    // lastVisited: true,
  });

  // state to search for customers based on customer group
  const [customerGroupSearch, setCustomerGroupSearch] = useState("");

  // search customer based on customer status
  const [customerStatusSearch, setCustomerStatusSearch] = useState("");

  // state management for customer data
  const [customerData, setCustomerData] = useState([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.customerList?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.customerList?.pageInfo.startCursor || null);
  };

  // query to fetch customer data
  const { data, loading, error, refetch } = useQuery(SEARCH_CUSTOMERS, {
    variables: {
      businessId: +businessId,
      search: customerSearchQuery,
      status: customerStatusSearch,
      customerGroup: customerGroupSearch,
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    context: { clientName: "customerClient" },
    fetchPolicy: "network-only",
  });

  // query to fetch  customer groups to show in select dropdown
  const { data: customerGroupLists } = useQuery(CUSTOMER_GROUP_LIST, {
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
    context: { clientName: "customerClient" },
  });

  // mutation to toggle customer status
  const [
    customerStatusChange,
    { data: customerStatusChangeData, error: customerStatusChangeError },
  ] = useMutation(CUSTOMER_STATUS_CHANGE, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  // mutation to toggle customer status
  const [
    deleteCustomersMutation,
    { data: deleteCustomersData, error: deleteCustomersError },
  ] = useMutation(DELETE_CUSTOMERS, {
    context: { clientName: "customerClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const handleSearch = (searchTerm: string) => {
    setCustomerSearchQuery(searchTerm);
    refetch({ search: searchTerm });
  };

  useEffect(() => {
    if (data) {
      // parse fetched data and assign it to customerData state variable
      const parsedCustomerData = data?.customerList?.edges?.map(
        (
          //   {
          //   node: {
          //     uuid,
          //     firstName,
          //     lastName,
          //     mobile,
          //     email,
          //     customerStatus,
          //     loyaltyPoints,
          //     customerGroup,
          //     customerId,
          //   },
          // }
          each: any
        ) => ({
          id: each.node.customerId,
          customerName: each.node.firstName + " " + each.node.lastName,
          phone: each.node.mobile,
          mail: each.node.email,
          customerGroup: each.node.customerGroup?.groupName || "-",
          status: each.node.customerStatus,
          loyaltyPoints: each.node.loyaltyPoints,
          lastVisited: "23/04/2023",
          userAccount: each.node.userAccount,
          customerId: each.node.customerId,
          dateOfBirth: each.node.dateOfBirth,
          anniversary: each.node.anniversary,
          company: each.node.companyName,
          taxIdentifier: each.node.taxIdentifier,
        })
      );

      setCustomerData(parsedCustomerData);
    }
  }, [data]);

  useEffect(() => {
    if (deleteCustomersData?.deleteCustomers?.success) {
      setDeleteConfirmationModal({
        state: false,
        customerTobeDeleted: [],
      });
      refetch();
    }
  }, [deleteCustomersData]);

  useEffect(() => {
    if (customerStatusChangeData?.customerStatusChange?.success) {
      refetch();
    }
  }, [customerStatusChangeData]);

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-columnHeader": {
      color: "#333333",
      background: "#f5f5f5",
      fontFamily: "Poppins",
      fontSize: "16px",
      fontStyle: "normal",
      fontWeight: 500,
      lineHeight: "normal",
    },
    "& .MuiDataGrid-cell": {
      color: "#333333",
      fontFamily: "Poppins",
      fontSize: "14px",
      fontStyle: "normal",
      fontWeight: 400,
      lineHeight: "normal",
    },
  }));

  // function to be called  when a row is clicked to edit.
  const editCustomerDetails = (params: any) => {
    const customerDetails = params.row;
    setAddCustomerModal({
      state: true,
      mode: "update",
      customerDetails,
    });
  };

  // function to be called when option to change customer status is clicked.
  const toggleCustomerStatus = (params: any) => {
    const customerDetails = params.row;
    customerStatusChange({
      variables: {
        customerId: customerDetails.customerId,
        status: customerDetails.status === "Active" ? "InActive" : "Active",
      },
    });
  };

  // function to be called when option to delete customer.
  const deleteCustomerFromRow = (params: any) => {
    const customerDetails = params.row;
    setDeleteConfirmationModal({
      state: true,
      customerTobeDeleted: [customerDetails.customerId],
    });
  };
  // function to be called when customers deleted from table selection.
  const deleteCustomers = () => {
    deleteCustomersMutation({
      variables: {
        businessId: +businessId,
        customerIds: JSON.stringify(
          deleteConfirmationModal.customerTobeDeleted
        ),
      },
    });
  };

  const navigate = useNavigate();
  const navigateToUserProfile = (id: string) => {
    navigate(`/customer/${id}/customerProfile`);
  };

  // column definition for  Data Grid component
  const columns: GridColDef[] = [
    {
      field: "customerName",
      //@ts-ignore
      headerName: t("customer.customer_name"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <div style={{ textTransform: "capitalize" }}>{params.value}</div>
      ),
    },
    {
      field: "phone",
      //@ts-ignore
      headerName: t("customer.phone"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "mail",
      //@ts-ignore
      headerName: t("customer.mail"),
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "customerGroup",
      //@ts-ignore
      headerName: t("customer.customer_group"),
      description: "This column has a value getter and is not sortable.",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      filterable: false,
      renderCell: (params) => (
        <div style={{ textTransform: "capitalize" }}>{params.value}</div>
      ),
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("customer.status"),
      headerAlign: "center",
      description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
      renderCell: (params) => (
        <div
          className={`customer-listing-status ${
            String(params.value) === "InActive"
              ? "customer-listing-status--active"
              : "customer-listing-status--inactive"
          }`}
        >
          {params.value}
        </div>
      ),
    },
    {
      field: "loyaltyPoints",
      headerAlign: "center",
      //@ts-ignore
      headerName: t("customer.loyality_points"),
      // description: "This column has a value getter and is not sortable.",
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
    },
    // {
    //   field: "lastVisited",
    //   headerAlign: "center",
    //   //@ts-ignore
    //   headerName: t("customer.last_visit"),
    //   description: "This column has a value getter and is not sortable.",
    //   sortable: false,
    //   filterable: false,
    //   align: "center",
    //   flex: 1,
    // },
    {
      field: "actions",
      type: "actions",
      renderHeader: (params) => (
        <ColumnVisibilityFilter
          visibleColumns={visibleColumns}
          setVisibleColumns={setVisibleColumns}
        />
      ),
      flex: 0.3,
      getActions: (params) => [
        <GridActionsCellItem
          label="Edit"
          onClick={() => editCustomerDetails(params)}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
              backgroundColor: "#FFF8F0",
            },
          }}
        />,
        <GridActionsCellItem
          label="View Customer"
          onClick={() => navigateToUserProfile(params.row.id)}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
              backgroundColor: "#FFF8F0",
            },
          }}
        />,
        <GridActionsCellItem
          label={`${
            params.row?.status === "Active" ? "Block" : "Unblock"
          } Customer`}
          onClick={() => toggleCustomerStatus(params)}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
              backgroundColor: "#FFF8F0",
            },
          }}
        />,
        <GridActionsCellItem
          label="Delete customer"
          onClick={() => deleteCustomerFromRow(params)}
          showInMenu
          sx={{
            "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
              backgroundColor: "#FFF8F0",
            },
          }}
        />,
      ],
    },
  ];

  return (
    <React.Fragment>
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          width: { xs: "100%", md: "100%" },
          boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
          "& .customer-listing-status": {
            color: "#00AE26",
            fontFamily: "Poppins",
            fontSize: "14px",
            textTransform: "capitalize",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
            display: "inline-flex !important",
            padding: "4px 16px !important",
            justifyContent: "center !important",
            alignItems: "center",
            gap: "10px",
            boxSizing: "border-box !important",
            borderRadius: "8px",
            background: "rgba(0, 174, 38, 0.10)",
          },
          "& .customer-listing-status--active": {
            color: "#F5431C",
            background: "rgba(245, 67, 28, 0.10)",
          },
        }}
      >
        <Box
          sx={{
            height: "67px",
            paddingX: "21px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "20px",
              fontWeight: "500",
              color: "#333",
            }}
          >
            {t("customer.customer_listing")}
          </Typography>

          <Button
            onClick={() =>
              setAddCustomerModal({
                state: true,
                mode: "add",
                customerDetails: {},
              })
            }
            sx={{
              width: "164px",
              height: "44px",
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
                textTransform: "capitalize",
              }}
            >
              {t("customer.add_customer")}
            </Typography>
          </Button>
        </Box>
        <Box
          sx={{
            height: "67px",
            paddingX: "21px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",

              width: "60%",
              gap: "15px",
            }}
          >
            {/* <TypeSearch
              handleClearIconClick={() => setCustomerSearchQuery("")}
              //@ts-ignore
              placeholder={t("customer.search_customer")}
              handleTextChange={handleSearch}
              value={customerSearchQuery}
            /> */}

            <DelayedInput
              width="40%"
              margin="10px 0px 10px 8px"
              setSearchValue={setCustomerSearchQuery}
              placeHolder={t("users.search_by_name")}
              size={"medium"}
            />

            <Select
              value={customerGroupSearch}
              //@ts-ignore
              label={t("customer.customer_group")}
              onChange={(event: SelectChangeEvent) =>
                setCustomerGroupSearch(event.target.value as string)
              }
              displayEmpty
              sx={{
                fontSize: "14px",
                width: "180px",
                height: "52px",

                background: "white",
                color: "#ABABAB",
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                // show placeholder text when no option is selected
                return selected !== ""
                  ? selected
                  : t("customer.customer_group");
              }}
            >
              {customerGroupLists?.customerGroupList?.edges?.map(
                (each: any) => (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    value={each.node.groupName}
                  >
                    {each.node.groupName}
                  </MenuItem>
                )
              )}
            </Select>

            <Select
              value={customerStatusSearch}
              label="Status"
              onChange={(event: SelectChangeEvent) =>
                setCustomerStatusSearch(event.target.value as string)
              }
              displayEmpty
              sx={{
                fontSize: "14px",

                width: "180px",
                height: "52px",
                background: "white",
                color: "#ABABAB",
              }}
              input={<OutlinedInput />}
              renderValue={(selected) => {
                return selected !== "" ? selected : t("customer.all_status");
              }}
            >
              <MenuItem value={"Active"}>{t("customer.active")}</MenuItem>
              <MenuItem value={"InActive"}>{t("customer.inactive")}</MenuItem>
              <MenuItem value={""}>{t("customer.all")}</MenuItem>
            </Select>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "333px",
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={() => setViewCustomerGroupModal(true)}
              sx={{
                width: "140px",
                height: "44px",
                backgroundColor: "#FFF8F0",
                display: "flex",
                justifyContent: "center",
                borderRadius: "4px",
                border: "1px solid #F38B08",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#F38B08",
                  textTransform: "capitalize",
                }}
              >
                {t("customer.view_group")}
              </Typography>
            </Button>

            <Button
              onClick={() =>
                setAddCustomerGroupModal({
                  state: true,
                  mode: "add",
                  customerGroupDetails: {},
                })
              }
              sx={{
                width: "173px",
                height: "44px",
                backgroundColor: "#F38B08",
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#F38B08",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#fefefe",
                  textTransform: "capitalize",
                }}
              >
                + {t("customer.create_group")}
              </Typography>
            </Button>
          </Box>
        </Box>
        <div
          style={{
            width: "100%",
            height: "70vh",
            padding: "10px 20px 0px 27px",
          }}
        >
          <DataGrid
            rows={customerData}
            columns={columns}
            columnVisibilityModel={visibleColumns}
            pageSizeOptions={[25, 50, 100]}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: paginationModel.pageSize,
                },
              },
            }}
            rowCount={data?.customerList?.totalCount || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              let shouldUpdatePaginationModel = true;

              if (newModel.pageSize !== paginationModel.pageSize) {
                setStartCursor(null);
                setEndCursor(null);
                setCurrentPage(0);
                setPaginationModel({
                  ...newModel,
                  page: 0,
                });
                shouldUpdatePaginationModel = false; // Prevent further updates within this call
              } else if (newModel.page > currentPage) {
                handleEndCursor();
              } else if (newModel.page < currentPage) {
                handleStartCursor();
              }

              // Update the current page

              // Only update paginationModel here if it wasn't updated above
              if (shouldUpdatePaginationModel) {
                setPaginationModel(newModel);
                setCurrentPage(newModel.page);
              }
            }}
            paginationMode="server"
            components={{
              Footer: (props) => (
                <CustomFooter
                  totalRowCount={Math.ceil(
                    (data?.customerList?.totalCount || 0) /
                      paginationModel.pageSize
                  )}
                  {...props}
                  page={paginationModel.page}
                />
              ),
            }}
            checkboxSelection
            disableColumnMenu
            disableRowSelectionOnClick
            rowSelectionModel={selectedRows}
            onRowSelectionModelChange={(newSelectionModel) => {
              setSelectionRows(newSelectionModel);
            }}
            sx={{
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                color: "#333333",
                background: "#f5f5f5",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              },
              "& .MuiDataGrid-cell": {
                color: "#333333",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
              },
              ".MuiTablePagination-displayedRows": {
                display: "none", // 👈 to hide huge pagination number
              },
            }}
          />
        </div>
      </Box>
      {selectedRows.length ? (
        <Box
          sx={{
            position: "fixed",
            height: "54px",
            bottom: "0",
            width: "100%",
            left: "0",
            backgroundColor: "#FFF8F0",
            opacity: 1,
            boxShadow: "0px -2px 12px 0px rgba(0, 0, 0, 0.08)",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "20px",
            }}
          >
            <img src={InfoIcon} alt="info" style={{ marginLeft: "38px" }} />
            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: 400,
                lineHeight: "16px",
              }}
            >
              {selectedRows.length} customers selected
            </Typography>
            <Typography
              onClick={() => setSelectionRows([])}
              sx={{
                color: "#EB0029",
                fontFamily: "Poppins",
                fontSize: "16px",
                fontWeight: "500",
                lineHeight: "16px",
                cursor: "pointer",
              }}
            >
              Deselect all
            </Typography>
          </Box>
          <img
            src={DeleteIcon}
            alt="delete rows"
            onClick={() =>
              setDeleteConfirmationModal({
                state: true,
                customerTobeDeleted: selectedRows,
              })
            }
            style={{ marginRight: "13px", cursor: "pointer" }}
          />
        </Box>
      ) : null}
      <Drawer
        anchor="right"
        open={addCustomerModal.state}
        onClose={() => {
          setAddCustomerModal({
            mode: "add",
            state: false,
            customerDetails: {},
          });
        }}
      >
        <AddCustomer
          setAddCustomerModal={setAddCustomerModal}
          addCustomerModal={addCustomerModal}
          refetch={refetch}
        />
      </Drawer>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={viewCustomerGroupModal}
        onClose={() => {
          setViewCustomerGroupModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "360px",
          top: "50px",
          opacity: 1,
        }}
      >
        <ViewCustomerGroup
          setViewCustomerGroupModal={setViewCustomerGroupModal}
          setAddCustomerGroupModal={setAddCustomerGroupModal}
          refetchCustomerListings={refetch}
        />
      </Modal>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={addCustomerGroupModal.state}
        onClose={() => {
          setAddCustomerGroupModal({
            state: false,
            mode: "add",
            customerGroupDetails: {},
          });
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "360px",
          top: "50px",
          opacity: 1,
        }}
      >
        <AddCustomerGroup
          addCustomerGroupModal={addCustomerGroupModal}
          setAddCustomerGroupModal={setAddCustomerGroupModal}
        />
      </Modal>

      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={deleteConfirmationModal.state}
        onClose={() => {
          setDeleteConfirmationModal({
            state: false,
            customerTobeDeleted: [],
          });
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "360px",
          top: "50px",
          opacity: 1,
        }}
      >
        <Sheet
          variant="outlined"
          className="shortCutModalBoxShadow"
          sx={{
            width: "720px",
            outline: "none",
            borderRadius: "8px",
            border: "1px solid #EEEEEE",
            padding: "20px 32px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "20px",
            }}
          >
            <img
              src={CloseIcon}
              alt="close modal"
              onClick={() =>
                setDeleteConfirmationModal({
                  state: false,
                  customerTobeDeleted: [],
                })
              }
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "20px",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "normal",
                color: "#707070",
                marginBottom: "50px",
              }}
            >
              Are you sure you want to Delete the selected Customers ?
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Button
                onClick={() =>
                  setDeleteConfirmationModal({
                    state: false,
                    customerTobeDeleted: [],
                  })
                }
                sx={{
                  width: "130px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  border: "1px solid #F38B08",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#F38B08",
                    textTransform: "capitalize",
                  }}
                >
                  Don't Delete
                </Typography>
              </Button>
              <Button
                onClick={() => deleteCustomers()}
                sx={{
                  width: "116px",
                  height: "44px",
                  backgroundColor: "#F5431C",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FFF",
                    textTransform: "capitalize",
                  }}
                >
                  Yes Delete
                </Typography>
              </Button>
            </Box>
          </Box>
        </Sheet>
      </Modal>
    </React.Fragment>
  );
};

export default CustomerListingTable;
