/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import Statistics from "./paymentsGraph/statistics";
import TrendingUp from "../../../assets/bar-chart.png";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { PaymentStatisticsData } from "../../constants/reportStatistics";
import PieChart from "../pieChartReports";
import {
  PaymentReportType,
  TopPaymentType,
} from "../../../types/paymentReports";
import {
  PAYMENT_METADATA_REPORTS,
  PAYMENT_REPORTS,
} from "../../../graphicalQl/usequery/paymentReport";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { localeToFormat } from "../../../utils/dateFormat";
import moment from "moment";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Payment Type", value: "transaction_for" },
  { label: "Transaction", value: "no_of_transactions" },
  { label: "Amount", value: "amount" },
  { label: "Refunds", value: "no_of_refunds" },
  { label: "Refund Amount", value: "refund_amount" },
  { label: "Total Amount", value: "total_amount" },
];

const PaymentReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Payment Type", value: "transaction_for" },
    { label: "Transaction", value: "no_of_transactions" },
    { label: "Amount", value: "amount" },
    { label: "Refunds", value: "no_of_refunds" },
    { label: "Refund Amount", value: "refund_amount" },
    { label: "Total Amount", value: "total_amount" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "transaction_for",
    "no_of_transactions",
    "amount",
    "no_of_refunds",
    "refund_amount",
    "total_amount",
  ]);
  const [paymentReports, setProductReports] = useState<
    Array<PaymentReportType>
  >([]);
  const [topPayments, setTopPayments] = useState<Array<TopPaymentType>>([]);
  // const [locationId, setLocationId] = useState<number>(44);
  const [graphLabels, setGraphLabels] = useState<Array<string>>([]);
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)

  const [employee, setEmployee] = useState<number>();
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { loading: topPaymentsLoading, data: topPaymentsData } = useQuery(
    PAYMENT_METADATA_REPORTS(date.startDate, date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      parseInt(reportLocation) || parseInt(locationId),
      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const { loading: paymentReportsLoading, data: paymentReportsData } = useQuery(
    PAYMENT_REPORTS(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), parseInt(reportLocation) || parseInt(locationId), reportEmployee),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const currencySymbol = sessionStorage.getItem("currencySymbol")
  const userLang = navigator.language;
  const capitalizeFirstLetter = (str: string) => {
    if (!str) {
      return '';
    }

    return str
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  const getStoreReports = () => {
    if (!paymentReportsLoading) {
      const paymentReports = paymentReportsData?.paymentReports;
      const newStoreReports = paymentReports && JSON.parse(paymentReports);
      const list = newStoreReports && newStoreReports.map((item: any) => {
        return {
          ...item,
          amount: ` ${currencySymbol} ${item.amount.toFixed(2)}`,
          refund_amount: ` ${currencySymbol} ${item.refund_amount.toFixed(2)}`,
          total_amount: ` ${currencySymbol} ${item.total_amount.toFixed(2)}`,
          transaction_for: capitalizeFirstLetter(item.transaction_for)

        };
      }
      );
      setProductReports(list);
    }
  };

  const getTopPayments = () => {
    if (!topPaymentsLoading) {
      const tempLabels: any = [];
      const tempData: any = [];
      const topPayments = topPaymentsData?.paymentMetadata;
      const newtopPayments = JSON.parse(topPayments || "[]");
      newtopPayments?.map((payment: any) => {
        tempLabels.push(capitalizeFirstLetter(payment?.transaction_for));
        tempData.push(payment?.sales);
        return "";
      });
      const list = newtopPayments && newtopPayments.map((each: any) => {
        return {
          ...each,
          transaction_for: capitalizeFirstLetter(each.transaction_for),
          sales: `${currencySymbol} ${each.sales.toFixed(2)}`
        }
      })
      setTopPayments(list);
      setGraphLabels(tempLabels);
      setGraphData(tempData);
    }
  };
  useEffect(() => {
    getStoreReports();
  }, [paymentReportsLoading]);

  useEffect(() => {
    getTopPayments();
  }, [topPaymentsLoading]);
  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box
        mt={"18px"}
        p={3}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "9px",
            alignItems: "center",
          }}
        >
          <img
            height={44}
            width={44}
            src={TrendingUp}
            alt="trending"
            style={{ height: "44px", width: "44px" }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "20px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
              marginTop: "9px",
            }}
          >
            Payments
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          {topPayments.length ? (
            <Statistics
              headers={PaymentStatisticsData.headers}
              topPayments={topPayments}
            />
          ) : (
            <></>
          )}
          {graphData.length ? (
            <PieChart datapoints={graphData} labels={graphLabels} />
          ) : (
            <></>
          )}
        </Box>
      </Box>
      {paymentReports && paymentReports.length ? (
        <Box mt={0}>
          <ReportsTable
            labels={labels}
            headers={headers}
            options={options}
            setLabels={setLabels}
            setHeaders={setHeaders}
            data={paymentReports}
            title={"Payments"}
          />
        </Box>
      ) : (
        <></>
      )}
    </Box>
  );
};

export default PaymentReport;
