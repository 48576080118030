/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useContext, useEffect, useState } from "react";
import OrderGraph from "./ordersGraph";
import ReportsTable from "../ReportsTable";
import { gql, useQuery } from "@apollo/client";
import {
  ORDER_GRAPH,
  ORDER_REPORTS,
} from "../../../graphicalQl/usequery/ordersReport";
import { OrderGraphType, OrderReportType } from "../../../types/orderReport";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { useSelector } from "react-redux";
import moment from 'moment-timezone';
import 'moment/locale/ur'; // Urdu
import 'moment/locale/ja'; // Japanese
import 'moment/locale/en-gb'; // English (UK)
import 'moment/locale/en-au'
import { GraphContext } from "../../../Contexts/GraphContext";
import { localeToFormat } from "../../../utils/dateFormat"
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Order Id", value: "order_id" },
  { label: "Time & Date", value: "created_on_str" },
  { label: "Type", value: "order_type" },
  { label: "Customer", value: "customer_details__customer_name" },
  { label: "Total", value: "total_amount" },
  { label: "Status", value: "order_status", status: "Status" },
];

type LocaleFormat = {
  [key: string]: string;
  "en-US": string;
  "en-GB": string;
  "de-DE": string;
  "fr-FR": string;
  "ja-JP": string;
  "zh-CN": string;
  "ru-RU": string;
};


const OrdersReports = () => {
  // const [employee, setEmployee] = useState<string | null>(null);
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Order Number", value: "order_no" },
    { label: "Time & Date", value: "created_on_str" },
    { label: "Type", value: "order_type" },
    { label: "Customer", value: "customer_name" },
    { label: "Total", value: "total_amount" },
    { label: "Status", value: "order_status", status: "Status" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "order_no",
    "created_on_str",
    "order_type",
    "customer_name",
    "total_amount",
    "order_status",
  ]);

  const [orderReports, setOrderReports] = useState<Array<OrderReportType>>([]);
  const [ordersGraphData, setOrderGraphData] = useState<Array<OrderGraphType>>(
    []
  );

  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("23:59:00");
  const { fromTime, toTime } = useContext(GraphContext)


  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);

  // const { locationId } = useSelector((state: any) => state.headerLocation);

  // const [location, setLocation] = useState(locationId)

  // useEffect(() => {
  //   if (reportLocation !== null) {
  //     setLocation(reportLocation)
  //   }


  // }, reportLocation)



  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { loading: orderGraphLoading, data: orderGraphData } = useQuery(
    ORDER_GRAPH(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), parseInt(reportLocation) || parseInt(locationId), reportEmployee),
    {
      fetchPolicy: "no-cache",
    }
  );
  const { loading: orderReportsLoading, data: orderReportsData } = useQuery(
    ORDER_REPORTS(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), parseInt(reportLocation) || parseInt(locationId), reportEmployee) ?? gql``,
    {
      fetchPolicy: "no-cache",
    }
  );
  const currencySymbol = sessionStorage.getItem('currencySymbol')
  // capitalize first letter of each word
  const capitalizeFirstLetter = (str: string) => {
    if (!str) {
      return '';
    }

    return str
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  const userLang = navigator.language;
  // const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  
  // moment.locale(userLang);
  // moment.tz.setDefault(userTimezone);
  // const timezoneToLocale = {
  //   "Asia/Karachi": "ur",
  //   "America/New_York": "en-US",
  //   "Europe/London": "en-GB",
  //   "Asia/Tokyo": "ja",
  //   "Australia/Sydney": "en-AU"
  // };


  // const localeToFormat: LocaleFormat = {
  //   "en-US": "MMM/DD/YYYY", // United States
  //   "en-GB": "DD/MM/YYYY", // United Kingdom
  //   "de-DE": "DD.MM.YYYY", // Germany
  //   "fr-FR": "DD/MM/YYYY", // France
  //   "ja-JP": "YYYY/MM/DD", // Japan
  //   "zh-CN": "YYYY/MM/DD", // China
  //   "ru-RU": "DD.MM.YYYY"  // Russia
  // };
  const getOrderReports = () => {
    if (!orderReportsLoading && orderReportsData) {
      const orderReports = orderReportsData && orderReportsData.orderReports;
      const newOrderReports = JSON.parse(orderReports);
      const list = newOrderReports && newOrderReports.map((item: any) => {
        return {
          ...item,
          created_on_str: moment(item.created_on_str).format(`${localeToFormat[userLang]}, hh:mm A`),
          total_amount: `${currencySymbol} ${item.total_amount.toFixed(2)}`,
          customer_name: capitalizeFirstLetter(item.customer_name),
        };
      });
      setOrderReports(list);
    }
  };

  const getOrderGraph = () => {
    if (!orderGraphLoading && orderGraphData) {
      const orderGraphs = orderGraphData && orderGraphData.orderGraph;
      const newOrderGraphs = JSON.parse(orderGraphs);
      setOrderGraphData(newOrderGraphs);
    }
  };
  useEffect(() => {
    getOrderReports();
  }, [orderReportsLoading]);

  useEffect(() => {
    getOrderGraph();
  }, [orderGraphLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        employee={true}
        setDate={setDate}
        date={date}
      // setFromTime={setFromTime}
      // setToTime={setToTime}

      />
      <Box
        mt={1}
        pb={1}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <OrderGraph ordersGraphData={ordersGraphData} />
      </Box>
      <Box mt={5}>
        <ReportsTable
          labels={labels}
          options={options}
          headers={headers}
          setHeaders={setHeaders}
          setLabels={setLabels}
          data={orderReports}
          title={"Orders"}
        />
      </Box>
    </Box>
  );
};

export default OrdersReports;
