import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  TextField,
  Stack,
  Chip,
  FormControl,
  Divider,
  Checkbox,
  Typography,
  IconButton,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import ErrorIcon from "@mui/icons-material/Error";
import { useFormik } from "formik";
import * as Yup from "yup";

import EditIcon from "@mui/icons-material/Edit";
import Modal from "@mui/material/Modal";
import { useMutation } from "@apollo/client";
import {
  ADD_OPTION_VALUE_TO_OPTION,
  UPDATE_OPTION_VALUE_TO_OPTION,
} from "../../graphicalQl/mutation/productMutation";
import { GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP } from "../../graphicalQl/usequery/categoryListQuery";
import "./assignOptionsToproduct.css";

interface OptionValue {
  optionValueName: string | null;
  optionValuePrice: string | null;
  newRowToggle: boolean;
  disable: boolean;
}

const validationSchemaPart1 = Yup.object({
  optionValueName: Yup.string().when("newRowToggle", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
  optionValuePrice: Yup.string().when("newRowToggle", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 4,
};

interface AssignOptionToProductByWithouGroup {
  optionValues: any[];
  activeModifiers: any[];
  activeOptionId: string;
  deleteOptionFromProductWithoutGroup: (optionId: string) => void;
  deleteOptionValueFromoptionWithoutGroup: (
    optionId: string,
    optionValueId: string
  ) => void;
  handleUpdateOptionValueWithoutGroup: (
    value: any,
    e: React.ChangeEvent<HTMLInputElement>,
    optionId: string
  ) => void;
  setIteratingOptionsWithoutGroupExistingData: React.Dispatch<
    React.SetStateAction<any[]>
  >;
  handleChipsDeleteWithoutGroup: (modifier: any) => void;
}

const AssignOptionToProductByWithouGroup = (
  props: AssignOptionToProductByWithouGroup
) => {
  const {
    activeOptionId,
    setIteratingOptionsWithoutGroupExistingData,
    activeModifiers,
    handleChipsDeleteWithoutGroup,
    optionValues,
    deleteOptionFromProductWithoutGroup,
    deleteOptionValueFromoptionWithoutGroup,
    handleUpdateOptionValueWithoutGroup,
  } = props;
  const [optionValueId, setOptionValueId] = useState<string | undefined>(
    undefined
  );
  const [isAlreadyExisted, setIsAlreadyExisted] = useState<boolean>(false);

  const [renderedOptionValues, setRenderedOptionValues] = useState<any[]>([]);

  useEffect(() => {
    const filteredData =
      optionValues &&
      optionValues.map((each: any) => ({
        ...each,
        isEdited: false,
      }));
    setRenderedOptionValues(filteredData);
  }, [optionValues]);
  const [addOptionValueToOption] = useMutation(ADD_OPTION_VALUE_TO_OPTION, {
    onCompleted: (data) => {
      if (data) {
        const { addOptionValueInProduct } = data;
        const { optionValue } = addOptionValueInProduct;
        const { optionValueId } = optionValue;

        setIteratingOptionsWithoutGroupExistingData((prev) =>
          prev.map((eachGroup: any) => ({
            ...eachGroup,
            optionObjects:
              eachGroup.optionObjects &&
              eachGroup.optionObjects.map((eachOption: any) =>
                eachOption.optionId === activeOptionId
                  ? {
                      ...eachOption,
                      optionValues: [
                        ...eachOption.optionValues,
                        {
                          image: null,
                          isChecked: false,
                          optionValueId: optionValueId,
                          optionValueName: createdOptionaValueName, // Fix the variable name here
                          price:
                            createdOptionaValuePrice &&
                            +createdOptionaValuePrice,
                        },
                      ],
                    }
                  : eachOption
              ),
          }))
        );
        setRenderedOptionValues((prev) => [
          ...prev,
          {
            image: null,
            isChecked: false,
            optionValueId: optionValueId,
            optionValueName: createdOptionaValueName,
            price: createdOptionaValuePrice,
          },
        ]);
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP],
  });

  const [updateOptionValueToOption] = useMutation(
    UPDATE_OPTION_VALUE_TO_OPTION,
    {
      onCompleted: (data) => {
        if (data) {
          const { updateOptionValue } = data;
          const { optionValue } = updateOptionValue;

          const { name, optionValueId, price, image } = optionValue;

          setIteratingOptionsWithoutGroupExistingData((prev) =>
            prev.map((eachGroup: any) => ({
              ...eachGroup,
              optionObjects:
                eachGroup.optionObjects &&
                eachGroup.optionObjects.map((eachOption: any) =>
                  parseInt(eachOption.optionId) === parseInt(activeOptionId)
                    ? {
                        ...eachOption,
                        optionValues: eachOption.optionValues.map(
                          (value: any) =>
                            parseInt(value.optionValueId) ===
                            parseInt(optionValueId)
                              ? {
                                  image: image && image,
                                  isChecked: false,
                                  optionValueId: optionValueId,
                                  optionValueName: name, // Fix the variable name here
                                  price: price,
                                }
                              : value
                        ),
                      }
                    : eachOption
                ),
            }))
          );

          setRenderedOptionValues((prev) =>
            prev.map((item: any) => {
              if (parseInt(item.optionValueId) === parseInt(optionValueId)) {
                return {
                  ...item,
                  optionValueName: name,
                  price: price,
                };
              } else {
                return item;
              }
            })
          );
          setOptionValueId(undefined);
        }
      },
      onError: (error) => {
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [
        GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP,
      ],
    }
  );
  // const [optionValues, setOptionValues] = React.useState(rows)

  const [showCloseIcon, setShowCloseIcon] = React.useState(
    renderedOptionValues && renderedOptionValues.map((each: any) => false)
  );
  const handleMouseEnter = (index: number) => {
    const updatedShowData =
      renderedOptionValues && renderedOptionValues.map((each: any) => false);
    updatedShowData[index] = true;
    setShowCloseIcon(updatedShowData);
  };

  const handleMouseLeave = (index: number) => {
    const updatedShowData = [...showCloseIcon];
    updatedShowData[index] = false;
    setShowCloseIcon(updatedShowData);
  };
  const initialValues: OptionValue = {
    optionValueName: null,
    optionValuePrice: null,
    newRowToggle: false,
    disable: false,
  };

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (optionValueId !== undefined) {
        handleOpen();

        // handleEditOptionValueButton()
      } else {
        handleAddOptionSaveButton();
      }
    },
  });

  // const [newRowToggle, setNewRowToggle] = React.useState(false);
  const handleAddOptionValueButton = () => {
    // setNewRowToggle(!newRowToggle);
    formik.setFieldValue("newRowToggle", true);
    formik.setFieldValue("disable", true);
  };
  const handleAddOptionValuesToOptionButton = () => {
    // formik.setValues({
    //   ...formik.values,
    //   optionValueName: "",
    //   optionValuePrice: "",
    // });
    formik.resetForm();
    setIsAlreadyExisted(false);
  };
  const handleEditOptionValueButton = () => {
    setRenderedOptionValues((prev: any) =>
      prev.map((each: any) => ({ ...each, isEdited: false }))
    );

    updateOptionValueToOption({
      variables: {
        optionValueId: optionValueId && +optionValueId,
        name: formik.values.optionValueName,
        price:
          formik.values.optionValuePrice &&
          parseFloat(formik.values.optionValuePrice),
      },
    });

    // formik.setValues({
    //   ...formik.values,
    //   optionValueName: "",
    //   optionValuePrice: "",
    // });
    formik.resetForm();
    setIsAlreadyExisted(false);

    handleClose();
  };

  const [createdOptionaValueName, setCreatedOptionaValueName] = useState<
    string | null
  >(null);
  const [createdOptionaValuePrice, setCreatedOptionaValuePrice] = useState<
    string | null
  >(null);
  const handleAddOptionSaveButton = () => {
    // setNewRowToggle(false);
    formik.setFieldValue("newRowToggle", false);

    addOptionValueToOption({
      variables: {
        option: +activeOptionId,
        name: formik.values.optionValueName,
        price:
          formik.values.optionValuePrice &&
          parseFloat(formik.values.optionValuePrice),
      },
    });
    setCreatedOptionaValueName(formik.values.optionValueName);
    setCreatedOptionaValuePrice(formik.values.optionValuePrice);
    // formik.setValues({
    //   ...formik.values,
    //   optionValueName: "",
    //   optionValuePrice: "",
    // });
    formik.resetForm();
    setIsAlreadyExisted(false);
  };

  const handleEditOptionValueIcon = (
    optionName: string,
    optionPrice: string,
    optionValueId: string
  ) => {
    const filteredData = renderedOptionValues.map((eachOption: any) => {
      if (eachOption.optionValueId === optionValueId) {
        return { ...eachOption, isEdited: true };
      } else {
        return { ...eachOption, isEdited: false };
      }
    });
    setRenderedOptionValues(filteredData);
    setOptionValueId(optionValueId);
    formik.setValues({
      ...formik.values,
      optionValueName: optionName,
      optionValuePrice: optionPrice,
      disable: true,
    });
  };
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handlePopupCloseIcon = () => {
    handleClose();
  };

  const handleDontSaveButton = () => {
    setRenderedOptionValues((prev: any) =>
      prev.map((each: any) => ({ ...each, isEdited: false }))
    );
    handleClose();
    setOptionValueId(undefined);
    // formik.setValues({
    //   ...formik.values,
    //   optionValueName: "",
    //   optionValuePrice: "",
    // });
    // setNewRowToggle(false);
    formik.resetForm();
    setIsAlreadyExisted(false);
  };

  const handleCancelButton = () => {
    setRenderedOptionValues((prev: any) =>
      prev.map((each: any) => ({ ...each, isEdited: false }))
    );
    setOptionValueId(undefined);
    // formik.setValues({
    //   ...formik.values,
    //   optionValueName: "",
    //   optionValuePrice: "",
    // });
    // setNewRowToggle(false);
    formik.resetForm();
    setIsAlreadyExisted(false);
  };

  const handleChipsDelete = (id1: string, id: string) => {};

  const handleUpdateOptionValueInLocal = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const filteredData = renderedOptionValues.map((each: any) => {
      if (each.optionValueId === id) {
        return { ...each, isChecked: e.target.checked };
      }
      return each;
    });
    setRenderedOptionValues(filteredData);
  };

  const handleOptionValueName = (name: string) => {
    const collectedNames = renderedOptionValues
      .filter((each: any) => each.optionValueId)
      .map((eachValue: any) => eachValue.optionValueName);

    const trimmedName = name.trim();
    if (collectedNames.includes(trimmedName) && trimmedName !== "") {
      setIsAlreadyExisted(true);
    } else {
      setIsAlreadyExisted(false);
    }
  };

  const handleOptionValueNameForEdit = (name: string, id: string) => {
    const collectedNames = renderedOptionValues
      .filter((each: any) => each.optionValueId !== id)
      .map((eachValue: any) => eachValue.optionValueName);

    const trimmedName = name.trim();
    if (collectedNames.includes(trimmedName) && trimmedName !== "") {
      setIsAlreadyExisted(true);
    } else {
      setIsAlreadyExisted(false);
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        {activeModifiers &&
          activeModifiers.map((eachModifier: any) => (
            <Box
              sx={{
                marginRight: "5px",
              }}
              key={eachModifier.modifierId}
            >
              <Stack direction="row" spacing={1}>
                <Chip
                  label={
                    <Typography sx={{ textTransform: "capitalize" }}>
                      {eachModifier.modifierName}
                    </Typography>
                  }
                  onDelete={() => handleChipsDeleteWithoutGroup(eachModifier)}
                />
              </Stack>
            </Box>
          ))}
      </Box>

      <Box sx={{ border: "1px solid #eee", margin: "0px 10px 10px 0px" }}>
        <Box sx={{ margin: "10px" }}>
          <TableContainer>
            <Table sx={{ minWidth: "100%" }} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{ width: "1%" }}
                    className="assign-options-to-products-veg-toppings-text"
                  ></TableCell>
                  <TableCell
                    sx={{ width: "25%" }}
                    className="assign-options-to-products-veg-toppings-text"
                    align="left"
                  >
                    Option Values
                  </TableCell>
                  <TableCell
                    sx={{ width: "25%" }}
                    className="assign-options-to-products-veg-toppings-text"
                    align="center"
                  >
                    Price
                  </TableCell>
                  <TableCell
                    sx={{ width: "25%" }}
                    className="assign-options-to-products-veg-toppings-text"
                    align="center"
                  >
                    Mark as included
                  </TableCell>
                  <TableCell
                    sx={{ width: "13%" }}
                    className="assign-options-to-products-veg-toppings-text"
                    align="right"
                  >
                    <IconButton
                      onClick={() =>
                        deleteOptionFromProductWithoutGroup(activeOptionId)
                      }
                    >
                      <CloseIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {renderedOptionValues &&
                  renderedOptionValues.map((row: any, index: number) => (
                    <TableRow
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                      className="assign-options-to-products-table-row"
                      key={row.optionValueId}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        padding: "0px",
                        height: "75px",
                      }}
                    >
                      <TableCell sx={{ padding: "0px 0px 0px 10px" }}>
                        {/* {showCloseIcon[index] && <DragHandleIcon />} */}
                      </TableCell>
                      <TableCell
                        sx={{ padding: "5px 0px 5px 16px" }}
                        align="left"
                        component="th"
                        scope="row"
                      >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <img
                            height="35px"
                            src={row.image}
                            style={{ marginRight: "7px" }}
                          />

                          {row.isEdited ? (
                            <Box>
                              <Box
                                sx={{
                                  width: "100%",
                                  flexDirection: "column",
                                  alignItems: "flex-start",
                                  height: "45px",
                                }}
                              >
                                <FormControl>
                                  <TextField
                                    className=""
                                    color="warning"
                                    label={
                                      <Typography className="rendering-options-text-field-label">
                                        Option Name
                                      </Typography>
                                    }
                                    sx={{
                                      width: "100%",
                                      // marginBottom: "10px",
                                      "& .MuiInputBase-input": {
                                        height: "14px",
                                      },
                                    }}
                                    id="filled-basic"
                                    size="medium"
                                    variant="outlined"
                                    value={formik.values.optionValueName}
                                    name="optionValueName"
                                    onBlur={formik.handleBlur}
                                    onChange={(e) => {
                                      formik.handleChange(e);
                                      handleOptionValueNameForEdit(
                                        e.target.value,
                                        row.optionValueId
                                      );
                                    }}
                                  />
                                </FormControl>
                              </Box>
                              <Box
                                sx={{
                                  height: "10px",
                                  // marginTop: "5px",
                                }}
                              >
                                {isAlreadyExisted && (
                                  <Typography
                                    sx={{
                                      marginLeft: "3%",
                                      marginTop: "3px",
                                    }}
                                    className="error-text"
                                  >
                                    Name Already Existed
                                  </Typography>
                                )}
                              </Box>
                            </Box>
                          ) : (
                            <Box sx={{ marginRight: "10px" }}>
                              <Typography
                                sx={{ textTransform: "capitalize" }}
                                className="assign-options-to-products-table-row"
                              >
                                {row.optionValueName}
                              </Typography>
                            </Box>
                          )}
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{ padding: "5px 0px 5px 16px" }}
                        className="assign-options-to-products-table-row"
                        align="center"
                      >
                        {row.isEdited ? (
                          <Box
                            sx={{
                              width: "80%",
                              flexDirection: "column",
                              alignItems: "flex-start",
                            }}
                          >
                            <FormControl>
                              <TextField
                                color="warning"
                                label={
                                  <Typography className="rendering-options-text-field-label">
                                    Option Price
                                  </Typography>
                                }
                                sx={{
                                  width: "100%",
                                  marginBottom: "7px",
                                  "& .MuiInputBase-input": {
                                    height: "14px",
                                  },
                                }}
                                id="filled-basic"
                                variant="outlined"
                                value={formik.values.optionValuePrice}
                                name="optionValuePrice"
                                onBlur={formik.handleBlur}
                                onChange={(e) => {
                                  const val = e.target.value;
                                  if (!val || /^[0-9]+$/.test(val)) {
                                    formik.handleChange(e);
                                  }
                                }}
                                type="text"
                              />
                            </FormControl>
                          </Box>
                        ) : (
                          <Box sx={{ marginRight: "10px" }}>
                            <Typography className="assign-options-to-products-table-row">
                              {row.price}
                            </Typography>
                          </Box>
                        )}
                      </TableCell>
                      {!row.isEdited && (
                        <TableCell
                          sx={{ padding: "5px 0px 5px 16px" }}
                          align="center"
                        >
                          <Checkbox
                            onChange={(e) => {
                              handleUpdateOptionValueWithoutGroup(
                                row,
                                e,
                                activeOptionId
                              );
                              handleUpdateOptionValueInLocal(
                                e,
                                row.optionValueId
                              );
                            }}
                            checked={row.isChecked}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: "1.95rem" },
                            }}
                            color="success"
                          />
                        </TableCell>
                      )}
                      {!row.isEdited ? (
                        <TableCell align="center">
                          {showCloseIcon[index] && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <IconButton
                                onClick={() =>
                                  handleEditOptionValueIcon(
                                    row.optionValueName,
                                    row.price,
                                    row.optionValueId
                                  )
                                }
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteOptionValueFromoptionWithoutGroup(
                                    activeOptionId,
                                    row.optionValueId
                                  )
                                }
                                sx={{ marginLeft: "10px" }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </Box>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell>
                          <form onSubmit={formik.handleSubmit}>
                            <Box sx={{ marginLeft: "10px", width: "100%" }}>
                              <Button
                                onClick={handleCancelButton}
                                color="warning"
                                sx={{ textTransform: "none" }}
                                variant="outlined"
                              >
                                Cancel
                              </Button>
                              <Button
                                type="submit"
                                style={{
                                  backgroundColor:
                                    formik.values.optionValueName === "" ||
                                    formik.values.optionValuePrice === ""
                                      ? "#ababab"
                                      : "#F38B08",
                                  textTransform: "none",
                                  marginLeft: "10px",
                                }}
                                variant="contained"
                                disabled={
                                  formik.values.optionValueName === "" ||
                                  formik.values.optionValuePrice === ""
                                }
                              >
                                Save
                              </Button>
                            </Box>
                          </form>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {/* {formik.values.newRowToggle && (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "80%",
                margin: "10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControl>
                    <TextField
                      color="warning"
                      label={
                        <Typography className="rendering-options-text-field-label">
                          Option Name
                        </Typography>
                      }
                      sx={{ width: "100%" }}
                      id="filled-basic"
                      variant="outlined"
                      value={formik.values.optionValueName}
                      name="optionValueName"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </FormControl>
                  {formik.touched.optionValueName &&
                    formik.errors.optionValueName && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formik.errors.optionValueName}
                        </Typography>
                      </Box>
                    )}
                </Box>

                <Box
                  sx={{
                    width: "40%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControl>
                    <TextField
                      color="warning"
                      label={
                        <Typography className="rendering-options-text-field-label">
                          Option Price
                        </Typography>
                      }
                      id="filled-basic"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      name="optionValuePrice"
                      value={formik.values.optionValuePrice}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!val || /^[0-9]+$/.test(val)) {
                          formik.handleChange(e);
                        }
                      }}
                      type="text"
                    />
                  </FormControl>
                  {formik.touched.optionValuePrice &&
                    formik.errors.optionValuePrice && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formik.errors.optionValuePrice}
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>

              <Box sx={{ marginLeft: "10px", width: "40%" }}>
                <Button
                  onClick={handleCancelButton}
                  color="warning"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="warning"
                  sx={{ textTransform: "none", marginLeft: "10px" }}
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )} */}
        {formik.values.newRowToggle && (
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                alignItems: "center",
                width: "80%",
                margin: "10px 10px 0px 10px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "40%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    height: "45px",
                  }}
                >
                  <FormControl>
                    <TextField
                      color="warning"
                      label={
                        <Typography className="rendering-options-text-field-label">
                          Option Name
                        </Typography>
                      }
                      sx={{ width: "100%" }}
                      id="filled-basic"
                      variant="outlined"
                      value={formik.values.optionValueName}
                      name="optionValueName"
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleOptionValueName(e.target.value);
                      }}
                    />
                  </FormControl>
                  {formik.touched.optionValueName &&
                    formik.errors.optionValueName && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formik.errors.optionValueName}
                        </Typography>
                      </Box>
                    )}
                  {isAlreadyExisted && (
                    <Typography
                      sx={{ marginLeft: "2%", marginBottom: "3px" }}
                      className="create-a-user-formik-label"
                    >
                      Name already Existed
                    </Typography>
                  )}
                </Box>

                <Box
                  sx={{
                    width: "40%",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControl>
                    <TextField
                      color="warning"
                      label={
                        <Typography className="rendering-options-text-field-label">
                          Option Price
                        </Typography>
                      }
                      id="filled-basic"
                      sx={{ width: "100%" }}
                      variant="outlined"
                      name="optionValuePrice"
                      value={formik.values.optionValuePrice}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!val || /^[0-9]+$/.test(val)) {
                          formik.handleChange(e);
                        }
                      }}
                      type="text"
                    />
                  </FormControl>
                  {formik.touched.optionValuePrice &&
                    formik.errors.optionValuePrice && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {formik.errors.optionValuePrice}
                        </Typography>
                      </Box>
                    )}
                </Box>
              </Box>

              <Box sx={{ marginLeft: "10px", width: "40%" }}>
                <Button
                  onClick={handleCancelButton}
                  color="warning"
                  sx={{ textTransform: "none" }}
                  variant="outlined"
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isAlreadyExisted}
                  color="warning"
                  sx={{ textTransform: "none", marginLeft: "10px" }}
                  variant="contained"
                >
                  Save
                </Button>
              </Box>
            </Box>
          </form>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "0px 0px 5px 20px",
        }}
      >
        <Button
          className="rendering-options-add-button-text"
          onClick={handleAddOptionValueButton}
          disabled={formik.values.disable}
          sx={{
            textTransform: "none",
            color: formik.values.disable ? "#ababab" : "#F38B08",
          }}
          color="warning"
        >
          Add Option Value
        </Button>
        <IconButton>
          <ErrorIcon sx={{ color: "#d9d9d9" }} />
        </IconButton>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            margin: "0px 0px 5px 20px",
          }}
        >
          <Box>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Box>
                  <IconButton
                    onClick={handlePopupCloseIcon}
                    className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
                  >
                    <CloseIcon />
                  </IconButton>
                </Box>
                <Divider sx={{ margin: "10px 0px 10px 0px" }} />
                <Typography
                  className="rendering-options-popup-header"
                  id="modal-modal-title"
                  variant="h6"
                  component="h2"
                >
                  Are you sure you want to edit the option value ?
                </Typography>
                <Typography
                  className="rendering-options-popup-description"
                  id="modal-modal-description"
                  sx={{ mt: 2 }}
                >
                  Changes made will have a{" "}
                  <span className="rendering-options-popup-span">
                    system-wide impact
                  </span>
                  , automatically{" "}
                  <span className="rendering-options-popup-span">
                    updating other products
                  </span>{" "}
                  with the same edited option value for uniformity.
                </Typography>
                <Box
                  sx={{
                    margin: "10px",
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <Button
                    onClick={handleDontSaveButton}
                    className="rendering-options-popup-discard-changes"
                  >
                    No, Discard Changes
                  </Button>
                  <Button
                    onClick={handleEditOptionValueButton}
                    className="rendering-options-popup-save-changes"
                  >
                    Yes, Save Changes
                  </Button>
                </Box>
              </Box>
            </Modal>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AssignOptionToProductByWithouGroup;
