import React, { useState, useEffect } from 'react';
import {
  Box, Button, TextField, FormControlLabel, Typography, FormControl, Select, MenuItem, InputLabel, SelectChangeEvent, Accordion, AccordionDetails, AccordionSummary, IconButton,
  InputBase, FormGroup,
  Switch,
  Popover
} from "@mui/material"
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { t } from 'i18next';
import '../options/createProduct.css'
import Checkbox from '@mui/material/Checkbox';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import { GETTING_LOCATIONS_LIST } from '../../graphicalQl/usequery/locationQuery';
import { useMutation, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { setName } from '../../store'
import { CATEGORY_LIST_IN_PRODUCT, FILTERING_PRODUCTS_LIST } from '../../graphicalQl/usequery/productsListQuery';
import { UPDATE_GENERAL_DETAILS_IN_PRODUCT } from '../../graphicalQl/mutation/productMutation';
import { COURSE_LIST } from '../../graphicalQl/usequery/productsListQuery';
import { REPORTING_LIST } from '../../graphicalQl/usequery/reportingGroup';
import ImageDrawer from '../menu/image/imageDrawer';
import TaxElementModal from './taxelemetModal';
import LocationModal from './locationModal';
import ErrorModal from '../errorHandling/errorModal';
import { UPDATE_GENERAL_DETAILS_IN_BULK_UPDATE } from '../../graphicalQl/mutation/productMutation';


interface State {
  name: string;
  shortCode: string;
  description: string;
  isPos: boolean;
  isOnline: boolean;
  locations: number[];
  category: string;
  unit: string;
  course: string;
  reportingGroup: string;
  unitValue: string;
  taxList: any;
  nonTaxable: boolean;
  shopProduct: boolean
}
interface Locations {
  id: string;
  status: string
  storeId: string
  storeName: string
  isChecked: boolean
};

interface Row {

  id: string
  productCount: number
  name: string

}

interface Props {

  handleNext: () => void;
  productId: string

}

const validationSchemaPart1 = Yup.object({
  name: Yup.string().required('Required'),
  locations: Yup.array().min(1, 'At least one location is required'),
});

const CreateGeneralDetails = (props: Props) => {

  const { name, shortCode, description, isPos, isOnline, category, unit, location, course, productIdByEdit, reportingGroup, image, color, nonTaxable, taxList, shopProduct } = useSelector((state: any) => state.app);

  const { handleNext } = props
  const { productId } = useSelector((state: any) => state.pricing);

  const initialState: State = {
    name: name || "",
    shortCode: shortCode || "",
    description: description || "",
    isPos: isPos || true,
    isOnline: isOnline || true,
    locations: location,
    category: category,
    unit: unit,
    course: '',
    reportingGroup: "",
    unitValue: "",
    taxList: null,
    nonTaxable: false,
    shopProduct: shopProduct
  };

  const categoryOptions = [
    { value: 'per_item', label: t("product.per_item"), id: '1' },
    { value: "per_gm", label: t("product.per_gram"), id: '2' },
    { value: "per_ml", label: "Per Ml", id: '3' },
    { value: "per_ounce", label: t("product.per_ounce"), id: '4' },
    { value: "per_kg", label: "Per Kg", id: '5' },
    { value: "per_litre", label: t("product.per_litre"), id: '5' },
    // { value: "perYard", label: t("product.per_yard"), id: '6' },

  ];
  const businessId: string = sessionStorage.getItem("businessId") as string
  const [statusCode, setStatusCode] = useState<number>(0)
  const [errorModal, setErrorModal] = useState<boolean>(false)

  const handleErrorModal = () => {
    setErrorModal(false)
  }

  const [updateProduct, { loading: updateLoading }] = useMutation(UPDATE_GENERAL_DETAILS_IN_PRODUCT, {
    context: { clientName: 'categoryClient' },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
        // Extract the status code from the extensions property
        setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        setErrorModal(true)
      }
      const errorMessage = graphqlError.message || 'An error occurred';
      console.error(`GraphQL error: Status ${statusCode}, Message: ${errorMessage}`);


    },
  })
  const [updateProductInBulk, { loading: updateBulkLoading }] = useMutation(UPDATE_GENERAL_DETAILS_IN_BULK_UPDATE, {
    context: { clientName: 'categoryClient' },
    onError: (error) => {
      const graphqlError = error.graphQLErrors[0];

      if (graphqlError && graphqlError.extensions && graphqlError.extensions.status) {
        // Extract the status code from the extensions property
        setStatusCode(parseInt(graphqlError.extensions.status, 10) || 500);
        setErrorModal(true)
      }
      const errorMessage = graphqlError.message || 'An error occurred';
      console.error(`GraphQL error: Status ${statusCode}, Message: ${errorMessage}`);


    },
  })

  // const [updateProductInBulk] = useMutation(UPDATE_GENERAL_DETAILS_IN_BULK_UPDATE, { context: { clientName: "categoryClient" } })


  const formik = useFormik({
    initialValues: initialState,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {


      if (productIdByEdit) {
        updateProduct({
          variables: filteredProduct, refetchQueries: [FILTERING_PRODUCTS_LIST], onCompleted: (data) => {
            handleNext()
            dispatch(setName("conditionValue", false));
          }
        })

      }
      else if (productId.length > 0) {

        updateProductInBulk({
          variables: product2, refetchQueries: [FILTERING_PRODUCTS_LIST], onCompleted: (data) => {
            handleNext()
            dispatch(setName("conditionValue", false));
          }
        })
      }
      else {
        handleNext()
      }
    },
  })
  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  const product = {
    businessId: +businessId,
    productId: +productIdByEdit,
    name: name.trim(),
    description,
    shortCode,
    image: image,
    bgColourCode: color,
    category: +category || null,
    course: +course || null,
    isPos,
    isOnline,
    unit,
    reportingGroup: parseInt(reportingGroup),
    isNonTaxableProduct: nonTaxable && nonTaxable,
    taxIds: taxList ? taxList.length > 0 ? JSON.stringify(taxList.map((each: any) => each.tax_id)) : JSON.stringify([]) : JSON.stringify([]),
    isShopProduct: shopProduct
  };
  const product2 = {
    businessId: +businessId,
    productIds: JSON.stringify(productId),
    name: name.trim(),
    description,
    shortCode,
    image: image,
    bgColourCode: color,
    category: +category || null,
    course: +course || null,
    isPos,
    isOnline,
    unit,
    reportingGroup: parseInt(reportingGroup),
    isNonTaxableProduct: nonTaxable && nonTaxable,
    taxIdsList: taxList && taxList.length > 0 ? JSON.stringify(taxList) : JSON.stringify([]),
    locations: JSON.stringify(location),
    isShopProduct: shopProduct
  };

  // Filter out properties with null values
  const filteredProduct = Object.fromEntries(
    Object.entries(product).filter(([_, value]) => value !== null)
  );




  // const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
  //   context: { clientName: "businessClient" }, variables: { businessId: +businessId }
  // })

  const { data: categoryData } = useQuery(CATEGORY_LIST_IN_PRODUCT, {
    context: { clientName: "categoryClient" }, variables: { businessId: +businessId },
    onError: (error) => {
      console.error('Error:', error);
    },

  });
  const { data: courseData } = useQuery(COURSE_LIST, {
    context: { clientName: "categoryClient" }, variables: { businessId: +businessId },
    onError: (error) => {
      console.error('Error:', error);
    },
  });

  const [courseList, setCourseList] = useState<{ id: string, name: string }[]>([])

  useEffect(() => {
    if (courseData && courseData.courseList) {
      const list = courseData.courseList.edges.map((each: any) => ({
        id: each.node.courseId,
        name: each.node.name
      }))
      setCourseList(list)
    }

  }, [courseData])


  const [categoryList, setCategoryList] = useState<{ id: string, name: string }[]>()
  useEffect(() => {
    if (categoryData) {
      const list = categoryData.categoryList.edges.map((each: any) => ({
        id: each.node.categoryId,
        name: each.node.name
      }))
      setCategoryList(list)
    }
  }, [categoryData])


  // const { data: productDataFromApi } = useQuery(GENERAL_DETAILS_IN_PRODUCT, {
  //   context: { clientName: "categoryClient" },variables:{productId:productId}

  // });

  // useEffect(()=>{
  // if(productDataFromApi&&productDataFromApi.product){
  //   dispatch(setName( "name", productDataFromApi.product.name ));
  //   dispatch(setName( "shortCode", productDataFromApi.product.shortCode ));
  //   dispatch(setName( "category", productDataFromApi.product.category.categoryId ));
  //   dispatch(setName( "unit", productDataFromApi.product.unit ));
  //   dispatch(setName( "description", productDataFromApi.product.description ));
  //   dispatch(setName( "isPos", productDataFromApi.product.isPos ));
  //   dispatch(setName( "isOnline", productDataFromApi.product.isOnline ));
  //   dispatch(setName( "location", productDataFromApi.product.locations ));
  //   dispatch(setName( "course", productDataFromApi.product.course ));
  // }
  // },[productDataFromApi])

  useEffect(() => {
    formik.setValues({

      ...formik.values, name, shortCode, category, unit, description, isPos, isOnline, locations: location, course: course, reportingGroup: reportingGroup, shopProduct
    })
  }, [name, shortCode, category, unit, description, isPos, isOnline, location, course, reportingGroup, shopProduct])



  // const [locationsList, seLocationsList] = useState<Locations[]>([]);
  // useEffect(() => {
  //   if (locationsData) {
  //     const requiredDataList: Locations[] = locationsData.storeList.edges.map((each: any) => ({
  //       storeId: each.node.storeId,
  //       storeName: each.node.storeName,
  //       isChecked: false
  //     }
  //     ))
  //     if (formik.values.locations && formik.values.locations.length > 0) {
  //       const updatedList = requiredDataList.map((each) =>
  //         formik.values.locations.includes(+each.storeId)
  //           ? { ...each, isChecked: true }
  //           : each
  //       );
  //       seLocationsList(updatedList)
  //       setNoOfLocationsSelected(formik.values.locations.length);
  //       setAllCheckboxChecked(formik.values.locations.length === requiredDataList.length);
  //     }
  //     else {
  //       seLocationsList(requiredDataList)
  //     }




  //   }
  // }, [locationsData])




  // const checkedCount = locationsList.filter((location) => location.isChecked).length;
  // const [noOfLocationsSelected, setNoOfLocationsSelected] = React.useState(checkedCount);
  // const [allCheckboxChecked, setAllCheckboxChecked] = React.useState(false);
  // const handleSearchIcon = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   setSearchInput(e.target.value)
  // }



  // const [searchInput, setSearchInput] = React.useState("")
  // const searchResults = locationsList.filter((eachLocation) => eachLocation.storeName.toLowerCase().includes(searchInput))
  // const handlePopupCheckbox = (id: string, isChecked: boolean) => {
  //   const filteredList = locationsList.map((each) => {
  //     if (each.storeId === id) {
  //       return { ...each, isChecked: !each.isChecked };
  //     }
  //     return each;
  //   });
  //   const checkedCount = filteredList.filter((location) => location.isChecked).length;
  //   setNoOfLocationsSelected(checkedCount);
  //   setAllCheckboxChecked(checkedCount === filteredList.length);

  //   const locationListIds = filteredList.filter((each) => each.isChecked === true).map((each1) => +each1.storeId);


  //   dispatch(setName("location", locationListIds))



  //   seLocationsList(filteredList);
  // };
  // const handlePopupCheckbo = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { checked } = e.target;
  //   const filteredList = locationsList.map((each) => ({ ...each, isChecked: checked }));
  //   setNoOfLocationsSelected(checked ? filteredList.length : 0);
  //   setAllCheckboxChecked(checked);
  //   seLocationsList(filteredList);
  //   const locationListIds = filteredList.filter((each) => each.isChecked === true).map((each1) => +each1.storeId);


  //   dispatch(setName("location", locationListIds))
  // };

  // let locationsText;
  // if (noOfLocationsSelected === 0) {
  //   locationsText = "Add Location"
  // } else if (noOfLocationsSelected === 1) {
  //   locationsText = "Location"
  // }
  // else {
  //   locationsText = "Locations"
  // }

  const dispatch = useDispatch();

  const handleInputChange2 = (field: string, value: string | boolean) => {
    dispatch(setName(field, value));
  };

  const handleInputChange3 = (field: string, value: string) => {
    dispatch(setName(field, value));
  };




  const [reportingList, setReportingList] = useState<any>([])

  const { data: reportingData } = useQuery(REPORTING_LIST, {
    context: { clientName: "categoryClient" }, variables: { businessId: +businessId },
    onError: (error) => {
      console.error('Error:', error);
    },
  });
  useEffect(() => {

    if (reportingData) {
      setReportingList(reportingData.reportingGroupList.edges)
    }

  }, [reportingData]);


  const reportedGroudformatData: Row[] = reportingList.map((item: any, index: number) => ({

    id: item.node.reportingGroupId,
    name: item.node.name,
    productCount: item.node.productCount,
  }));


  // const [colorCodeFromApi,setColorCodeFromAPi]=useState("")
  //   const [imageFromApi,setImageFromAPi]=useState("")

  // const [uploadImg, setUploadImg] = useState<string | null>(null)
  // const [uploadColor, setUploadColor] = useState<string | null>(null)



  const handleImage = (value: string | null) => {
    dispatch(setName("image", value))
    dispatch(setName("color", null))
  }
  const handleColor = (value: string | null) => {
    // setUploadColor(value)
    dispatch(setName("color", value))
    dispatch(setName("image", null))


  }


  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);



  return (
    <Box sx={{ paddingBottom: "12px" }}>
      <form onSubmit={formik.handleSubmit}>
        {/* <Typography className="create-product-details">{t("product.details")}</Typography> */}
        <div style={{ padding: "1.5rem 1.12rem", display: 'flex', }}>
          <div style={{ width: "90%" }}>
            <div style={{ display: "flex", alignItems: "flex-start" }}>
              <div style={{ width: '45%', marginRight: '1.5rem', }}>
                <TextField id="outlined-basic" label={t("product.product_name")} variant="outlined"
                  name="name"
                  value={formik.values.name || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange2("name", e.target.value); formik.handleChange(e) }}
                  color="warning"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  onBlur={formik.handleBlur}
                  sx={{ width: "100%" }}

                />
                {/* {formik.touched.name && formik.errors.name && (
                  <div style={{ color: 'red' }}>{formik.errors.name}</div>
                )} */}
              </div>
              {!productIdByEdit &&
                // <Box sx={{ position: "absolute", right: 350 }}>
                //   <Accordion elevation={0} sx={{ width: "19vw", zIndex: 10, border: "1px solid #d9d9d9" }}>
                //     <AccordionSummary
                //       sx={{ width: "100%", marginBottom: 0, zIndex: 100 }}
                //       expandIcon={<ExpandMoreIcon />}
                //       aria-controls="panel1a-content"
                //       id="panel1a-header"
                //     >
                //       <Typography className='create-a-use-role-label' sx={{ marginBottom: "0px" }}>{noOfLocationsSelected === 0 ? locationsText : `${noOfLocationsSelected} ${locationsText}`}</Typography>
                //     </AccordionSummary>
                //     <AccordionDetails sx={{ zIndex: 10 }}>
                //       <Box>
                //         <Paper
                //           elevation={1}

                //           sx={{
                //             display: "flex",
                //             alignItems: "center",
                //             width: "100%",
                //             zIndex: 10

                //           }}
                //         >
                //           <IconButton type="button" sx={{ p: "10px" }}>
                //             <SearchIcon />
                //           </IconButton>
                //           <InputBase
                //             value={searchInput}
                //             onChange={(e) => handleSearchIcon(e)}
                //             sx={{ ml: 1, flex: 1 }}
                //             //@ts-ignore
                //             // placeholder={t("driver_commission.search_by_driver_name")}
                //             inputProps={{ "aria-label": "search google maps" }}
                //           />
                //         </Paper>
                //       </Box>
                //       <Box sx={{ height: "37vh", display: "flex", flexDirection: "column", justifyContent: "space-between", zIndex: "10" }}>
                //         <Box
                //           sx={{
                //             display: "flex",
                //             justifyContent: "flex-start",
                //             flexDirection: "column",
                //             alignItems: "flex-start",
                //             overflowY: "auto",
                //             maxHeight: "32vh",
                //             marginTop: "10px",
                //             minHeight: "10vh"
                //           }}
                //         >
                //           <FormGroup>
                //             <FormControlLabel
                //               key={"all"}
                //               control={
                //                 <Checkbox
                //                   checked={allCheckboxChecked}
                //                   onChange={(e) => handlePopupCheckbo(e)}
                //                   // sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                //                   color="warning"
                //                 />
                //               }
                //               label={
                //                 <Typography >
                //                   All
                //                 </Typography>
                //               }
                //             />
                //             {searchResults.map((each: any) => (
                //               <FormControlLabel
                //                 key={each.storeId}
                //                 control={
                //                   <Checkbox
                //                     onChange={() => {
                //                       handlePopupCheckbox(each.storeId, each.isChecked);
                //                     }}
                //                     // sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                //                     color="warning"
                //                     checked={each.isChecked}
                //                   />
                //                 }
                //                 label={
                //                   <Typography >
                //                     {each.storeName}
                //                   </Typography>
                //                 }
                //               />
                //             ))}
                //           </FormGroup>
                //         </Box>
                //       </Box>
                //     </AccordionDetails>
                //   </Accordion>
                //   {formik.touched.locations && formik.errors.locations && (
                //     <div style={{ color: "red" }}>{formik.errors.locations as string}</div>
                //   )}
                // </Box>
                <Box sx={{ width: "50%", }}>
                  <LocationModal formik={formik} />
                  {formik.touched.locations && formik.errors.locations && (
                    <Typography style={{ color: "red", fontSize: "12px", marginTop: "8px" }}>{formik.errors.locations as string}</Typography>
                  )}
                </Box>
              }

            </div>


            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "1.5rem", gap: "1.5rem" }} >
              <div style={{ width: '45%' }}>
                <TextField id="outlined-basic" label={t("product.short_code")} variant="outlined"
                  name="shortCode"
                  value={formik.values.shortCode || ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange2("shortCode", e.target.value); formik.handleChange(e) }}
                  color="warning" style={{ width: '100%' }} />
                {formik.errors.shortCode && (
                  <div style={{ color: "red" }}>{formik.errors.shortCode}</div>
                )}
              </div>
              <FormControl style={{ width: "50%" }}>
                <InputLabel id="demo-simple-select-label">{t("product.select_category")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  color="warning"
                  label={t("product.select_category")}
                  name='category'
                  value={formik.values.category || ""}
                  onChange={(e: SelectChangeEvent) => { handleInputChange3("category", e.target.value); formik.handleChange(e) }}

                >
                  <MenuItem key="none" value="none">{t("product.none")}</MenuItem>
                  {categoryList && categoryList.map((option) => (
                    <MenuItem key={option.id} value={option.id} sx={{ textTransform: "capitalize" }}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "1.5rem", gap: "1.5rem" }} >
              <FormControl style={{ width: "45%" }}>
                <InputLabel id="demo-simple-select-label">{t("product.unit")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  color="warning"
                  label={t("product.unit")}
                  name='unit'
                  value={formik.values.unit || ""}
                  onChange={(e: SelectChangeEvent) => { handleInputChange3("unit", e.target.value); formik.handleChange(e) }}
                >
                  <MenuItem key="none" value="null">{t("product.none")}</MenuItem>
                  {categoryOptions.map((option) => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {/* <div style={{ width: '50%' }}>
                <TextField id="outlined-basic" label="Enter Unit Value" variant="outlined"
                  name="unitValue"
                  value={formik.values.unitValue || ""}
                  disabled={formik.values.unit === "perItem" || formik.values.unit === ""}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => { handleInputChange2("unitValue", e.target.value); formik.handleChange(e) }}
                  color="warning" style={{ width: '100%' }} />

              </div> */}
              <FormControl style={{ width: "50%" }}>
                <InputLabel id="demo-simple-select-label">{t("product.select_course")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='course'
                  label={t("product.select_course")}
                  color="warning"
                  value={formik.values.course || ""}
                  onChange={(e: SelectChangeEvent) => { handleInputChange3("course", e.target.value); formik.handleChange(e) }}

                >
                  <MenuItem key="none" value="none">{t("product.none")}</MenuItem>
                  {courseList && courseList.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "1.5rem", gap: "1.5rem" }} >

              <FormControl style={{ width: "45%" }}>
                <InputLabel id="demo-simple-select-label">{t("product.select_report")}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name='reportingGroup'
                  label={t("product.select_report")}
                  color="warning"
                  value={formik.values.reportingGroup || ""}
                  onChange={(e: SelectChangeEvent) => { handleInputChange3("reportingGroup", e.target.value); formik.handleChange(e) }}

                >
                  <MenuItem key="null" value="none">{t("product.none")}</MenuItem>
                  {reportedGroudformatData && reportedGroudformatData.map((option) => (
                    <MenuItem key={option.name} value={option.id}>
                      {option.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <Box sx={{ width: "50%" }}>
                <TaxElementModal formik={formik} />
              </Box>
            </div>
            <div style={{ display: "flex", alignItems: "flex-start", marginTop: "1.5rem", gap: "1.5rem" }} >

            </div>
          </div>

          {/* <Box sx={{ marginLeft: '86px', height: "150px", width: "150px", padding: "4px" }} className="create-product-img-con" >
            <ImageDrawer handleImage={handleImage} handleColor={handleColor} colorCodeFromApi={color} imageFromApi={image} heightValue={150} />
          </Box> */}

          <Box sx={{ marginLeft: '86px', height: "150px", width: "150px", padding: "2px" }}
            className="create-product-img-con"
          >
            <ImageDrawer handleImage={handleImage} handleColor={handleColor} colorCodeFromApi={color} imageFromApi={image} heightValue={120} />
          </Box>
        </div>

        <div className="textarea-box" style={{ width: "72%", paddingLeft: '20px' }}>
          <textarea id="textarea" onChange={(e) => { updateCharCount(e); formik.handleChange(e); handleInputChange2("description", e.target.value) }} style={{ width: '100%' }} name='description'
            value={formik.values.description}
            //@ts-ignore
            placeholder={t("product.enter_description")}>{formik.values.description}</textarea>
          <div id="charCount" className="char-count" >{charCount}/500</div>
        </div>

        <div style={{ marginLeft: '1.25rem', marginTop: "1.5rem", display: "flex", alignItems: "center", gap: "1.5rem" }}>
          <div style={{ width: "50%" }}>
            <FormControlLabel
              control={<Checkbox color="warning" name='isPos' checked={formik.values.isPos} key={"pos"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange2("isPos", e.target.checked)}
              />}
              label={<Typography className='create-product-switch-text'>{t("product.show_pos")}</Typography>}
              style={{ width: "40%" }}
            />
            <FormControlLabel
              control={<Checkbox color="warning" name='isOnline' checked={formik.values.isOnline} key={"online"} onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleInputChange2("isOnline", e.target.checked)} />}
              label={<Typography className='create-product-switch-text'>{t("product.show_online")}</Typography>}
              style={{ width: "40%" }}
            />
          </div>

        </div>
        <div style={{ marginLeft: '1.25rem', marginTop: "1.5rem", display: "flex", alignItems: "center", gap: "1.5rem" }}>
          <FormControlLabel
            value="top"
            control={<Switch color="warning" name="shopProduct" onChange={(e) => {
              formik.handleChange(e); handleInputChange2("shopProduct", e.target.checked)
            }}
              checked={formik.values.shopProduct}
            />}
            label={<Typography className="main-head12">{t("product.shop_product")}</Typography>}
            labelPlacement="end"
          />
          <div>
            <Typography
              aria-owns={open ? 'mouse-over-popover' : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <path d="M9.99984 1.66602C5.39984 1.66602 1.6665 5.39935 1.6665 9.99935C1.6665 14.5993 5.39984 18.3327 9.99984 18.3327C14.5998 18.3327 18.3332 14.5993 18.3332 9.99935C18.3332 5.39935 14.5998 1.66602 9.99984 1.66602ZM9.99984 14.166C9.5415 14.166 9.1665 13.791 9.1665 13.3327V9.99935C9.1665 9.54102 9.5415 9.16602 9.99984 9.16602C10.4582 9.16602 10.8332 9.54102 10.8332 9.99935V13.3327C10.8332 13.791 10.4582 14.166 9.99984 14.166ZM10.8332 7.49935H9.1665V5.83268H10.8332V7.49935Z" fill="#ABABAB" />
              </svg>
            </Typography>
            <Popover
              id="mouse-over-popover"
              sx={{
                pointerEvents: 'none',
              }}
              open={open}
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              onClose={handlePopoverClose}
              disableRestoreFocus
            >
              <Typography sx={{
                padding: "8px",
                color: '#ABABAB',
                textAlign: 'center',
                fontFamily: 'Poppins',
                fontSize: 12,
                fontStyle: 'normal',
                fontWeight: 400,
                lineHeight: 'normal',
              }}>
                {t("product.shop_item")}
              </Typography>
            </Popover>
          </div>
        </div>

        <Button className='createCategory-next-active' type='submit' sx={{ marginTop: " 1rem ", marginLeft: "1.25rem", marginBottom: "12px" }} disabled={updateLoading || updateBulkLoading}>{t("product.next")} {<ArrowForwardIcon color='warning' />}</Button>
      </form>
      <ErrorModal errorModal={errorModal} statusCode={statusCode} handleErrorModal={handleErrorModal} />

    </Box>
  )
}
export default CreateGeneralDetails