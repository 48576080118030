import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, IconButton, InputAdornment } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import Modal from "@mui/material/Modal";
import React, { useRef } from "react";

import ChildComponent, { ChildRef } from "./renderingOptions.tsx";
import "./assignOptionsToproduct.css";
import { toast } from "react-toastify";
import {
  ACCESSING_OPTIONS_IDS_TO_THE_PRODUCT,
  ACCESSING_OPTION_GROUP_IDS_TO_THE_PRODUCT,
  OPTION_GROUPS_IN_PRODUCT,
  OVERALL_PRODUCTS,
} from "../../graphicalQl/usequery/productsListQuery";
import {
  GETTING_OPTIONS_LIST_FOR_ASSIGNING_OPTIONS,
  GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP,
} from "../../graphicalQl/usequery/categoryListQuery";
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Paper,
  TextField,
  Button,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CloseIcon from "@mui/icons-material/Close";
import CreateOptions from "../options/createOptions.tsx";
import {
  EDIT_ASSIGN_OPTIONS_TO_PRODUCT,
  GETTING_VARIANT_IDS_AND_NAMES_FROM_PRODUCT_LISTS,
  GETTING_PRODUCT_AND_VARIANTS_FROM_EDIT_ID,
} from "../../graphicalQl/usequery/categoryListQuery";

import SearchIcon from "@mui/icons-material/Search";
import {
  OPTIONS_LIST,
  OPTIONS_WITHOUT_GROUP,
} from "../../graphicalQl/usequery/categoryListQuery.tsx";
import {
  EDIT_ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT,
  ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT,
} from "../../graphicalQl/mutation/productMutation.tsx";
import RenderingOptions from "../product/renderingOptions";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setName } from "../../store.tsx";
import { useTranslation } from "react-i18next";
import { text } from "body-parser";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  bgcolor: "background.paper",
  p: 2,
  borderRadius: "8px",
};

const AssignOptionsToProduct = () => {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const create = params.get("create");
  const edit = params.get("edit");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // useEffect(() => {
  //   if ((edit !== null) && (edit !== 'null') && (edit !== undefined) && edit) {
  //     dispatch(setName("productIdByEdit", edit));
  //     dispatch(setName("tabState", 2))
  //   }

  // }, [edit, dispatch]);

  // if (edit !== undefined) {
  //   dispatch(setName("productIdByEdit", edit))

  // }

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { productId } = useSelector((state: any) => state.pricing);

  const { productIdByEdit } = useSelector((state: any) => state.app);
  const [optionGroupsList, setOptionGroupList] = useState<any[]>([]);
  const [initialOptionGroupsList, setInitialOptionGroupList] = useState<any[]>(
    []
  );
  const [activeProductId, setActiveProductId] = useState<string | null>(null);
  const [activeVariantId, setActiveVariantId] = useState<string | null>(null);

  const {
    loading: optionsListLoading,
    error: optionsListError,
    data: optionsListData,
  } = useQuery(OPTIONS_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });
  const {
    loading: optionsListWithoutGroupLoading,
    error: optionsListWithoutGroupError,
    data: optionsListWithoutData,
  } = useQuery(OPTIONS_WITHOUT_GROUP, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
    fetchPolicy: "network-only",
  });
  const {
    data: updateOptionsData,
    loading: updateUserLoading,
    error: updateUserError,
  } = useQuery(EDIT_ASSIGN_OPTIONS_TO_PRODUCT, {
    context: { clientName: "categoryClient" },
    variables: {
      productId:
        productIdByEdit !== undefined
          ? +productIdByEdit
          : productId && +productId[0],
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: productNameAndVariantIdsFromProductIdsLoading,
    error: productNameAndVariantIdsFromProductIdsError,
    data: productNameAndVariantIdsFromProductIdsData,
  } = useQuery(GETTING_VARIANT_IDS_AND_NAMES_FROM_PRODUCT_LISTS, {
    context: { clientName: "categoryClient" },
    variables: { productIds: productId && JSON.stringify(productId) },
  });
  const {
    error: productNameAndVariantIdsFromEditIdsError,
    data: productNameAndVariantIdsFromEditIdData,
  } = useQuery(GETTING_PRODUCT_AND_VARIANTS_FROM_EDIT_ID, {
    context: { clientName: "categoryClient" },
    variables: { productId: productIdByEdit !== undefined && +productIdByEdit },
  });

  const [productIdsList, setProductsList] = useState<any[]>([]);
  const [updatedGroupIds, setUpdatedGroupIds] = useState<any[]>([]);
  const [updatedOptionIds, setUpdatedOptionIds] = useState<any[]>([]);
  const [optionIdsWithoutgroup, setOptionIdsWithoutGroup] = useState<any[]>([]);
  const [optionsListByGroupId, setOptionsListByGroupId] = useState<any[]>([]);
  const [optionsListByOptionId, setOptionsListByOptionId] = useState<any[]>([]);
  const [updatedOptionIdsWithoutGroup, setUpdatedOptionIdsWithoutGroup] =
    useState<any[]>([]);
  const [existingOptionsList, setExistingOptionsList] = useState<any[]>([]);
  const [groupsDataFromParent, setGroupsDataFromParent] = useState<any[]>([]);
  const [groupsDataWithGroupFromParent, setGroupsDataWithGroupFromParent] =
    useState<any[]>([]);
  const [withoutGroupsDataFromParent, setWithoutGroupsDataFromParent] =
    useState<any[]>([]);
  const [forInitialRenderingOptionGroups, setInitialRenderingOptionGroups] =
    useState<any[]>([]);
  const [forInitialRenderingWithoutGroups, setInitialRenderingWithoutGroups] =
    useState<any[]>([]);
  const [optionsListWithoutGroup, setOptionsListWithoutGroup] = useState<any[]>(
    []
  );
  const [initialOptionsListWithoutGroup, setInitialOptionsListWithoutGroup] =
    useState<any[]>([]);

  const {
    loading: optionGroupListLoading,
    error: optionGroupListError,
    data: optionGroupListdata,
  } = useQuery(ACCESSING_OPTION_GROUP_IDS_TO_THE_PRODUCT, {
    context: { clientName: "categoryClient" },
    variables: {
      groupIds: JSON.stringify(optionsListByGroupId),
      businessId: businessId,
    },
  });
  const {
    loading: optionsListWithGroupLoading,
    error: optionsLisWithGrouptError,
    data: optionsListWithGroupData,
  } = useQuery(GETTING_OPTIONS_LIST_FOR_ASSIGNING_OPTIONS, {
    context: { clientName: "categoryClient" },
    variables: {
      options: JSON.stringify(optionsListByOptionId),
      businessId: businessId,
    },
    fetchPolicy: "network-only",
  });
  const {
    loading: optionsListLoadingWithoutGroup,
    error: optionsListErrorWithoutGroup,
    data: optionsListdataWithoutGroup,
  } = useQuery(GETTING_OPTIONS_LIST_FOR_ASSIGNING_PRODUCT_WITHOUT_GROUP, {
    context: { clientName: "categoryClient" },
    variables: {
      options: JSON.stringify([
        {
          option_ids: optionIdsWithoutgroup.length > 0 && optionIdsWithoutgroup,
        },
      ]),
    },
    fetchPolicy: "network-only",
  });

  const handleNavigate = () => {
    if (productIdByEdit) {
      navigate(`/product/${productIdByEdit}/update-product`);
    } else {
      navigate("/product/create-product");
    }
  };

  useEffect(() => {
    if (optionGroupListdata) {
      if (optionGroupListdata.optionGroupRequiredList) {
        const { optionGroupRequiredList } = optionGroupListdata;
        const { edges } = optionGroupRequiredList;
        const filteredDataList = edges.map((each: any) => ({
          optionGroupName: each.node.name,
          productId: activeProductId && activeProductId,
          variantId: activeVariantId && activeVariantId,
          optionGroupId: String(each.node.optionGroupId),
          optionObjects: each.node.optionObjs
            ? each.node.optionObjs.map((eachObject: any) => ({
                optionName: eachObject.name,
                optionId: String(eachObject.optionId),
                modifiers:
                  eachObject.modifiers &&
                  eachObject.modifiers.map((eachModifier: any) => ({
                    modifierId: String(eachModifier.modifierId),
                    modifierName: eachModifier.name,
                  })),
                optionValues: eachObject.optionValues
                  ? eachObject.optionValues.map((eachOption: any) => ({
                      image: eachOption.image,
                      optionValueName: eachOption.name,
                      optionValueId: String(eachOption.optionValueId),
                      price: eachOption.price,
                      isChecked: false,
                      isEdited: false,
                    }))
                  : [],
              }))
            : [],
          options: each.node.options,
          slugName: each.node.slugName,
        }));
        setInitialRenderingOptionGroups(filteredDataList);

        const optionIdCount = filteredDataList.reduce(
          (acc: any, curr: any) => acc + curr.optionObjects.length,
          0
        );
        setselecetdOptionsAndGroups(optionIdCount);
        setGroupsDataFromParent(filteredDataList);
      }
    }
  }, [optionGroupListdata]);

  useEffect(() => {
    if (optionsListWithGroupData) {
      const { selectedOptionDetails } = optionsListWithGroupData;
      const parsedSelectedOptionsList = JSON.parse(selectedOptionDetails);
      const filteredOptionsList =
        parsedSelectedOptionsList.length > 0
          ? parsedSelectedOptionsList.map((each: any) => ({
              optionGroupId: JSON.stringify(each.option_group_id),
              optionGroupName: each.name,
              productId: activeProductId,
              variantId: activeVariantId,
              optionObjects: each["option_objs"]
                ? each["option_objs"].map((eachOption: any) => ({
                    optionId: String(eachOption.option_id),
                    optionName: eachOption.name,
                    modifiers: eachOption["modifiers"]
                      ? eachOption["modifiers"].map((eachModifer: any) => ({
                          modifierId: String(eachModifer.modifier_id),
                          modifierName: eachModifer.modifier__name,
                        }))
                      : null,
                    optionValues: eachOption.option_values
                      ? eachOption.option_values.map(
                          (eachOptionValue: any) => ({
                            optionValueId: String(
                              eachOptionValue.option_value_id
                            ),
                            optionValueName: eachOptionValue.name,
                            price: eachOptionValue.price,
                            image: eachOptionValue.image,
                            isChecked: false,
                            isEdited: false,
                          })
                        )
                      : [],
                  }))
                : [],
            }))
          : [];
      setInitialRenderingOptionGroups(filteredOptionsList);
      setGroupsDataWithGroupFromParent(filteredOptionsList);
      const optionIdCount = filteredOptionsList.reduce(
        (acc: any, curr: any) => acc + curr.optionObjects.length,
        0
      );
      setselecetdOptionsWithGroups(optionIdCount);
    }
  }, [optionsListWithGroupData]);

  useEffect(() => {
    if (optionsListdataWithoutGroup) {
      const { selectedOptionDetails } = optionsListdataWithoutGroup;
      const parsedSelectedOptionDetails = JSON.parse(selectedOptionDetails);

      const filtredOptionsListWithoutGroup =
        parsedSelectedOptionDetails &&
        parsedSelectedOptionDetails[0] &&
        parsedSelectedOptionDetails[0]["option_objs"].map(
          (eachOption: any) => ({
            optionId: String(eachOption.option_id),
            optionName: eachOption.name,
            modifiers: eachOption["modifiers"]
              ? eachOption["modifiers"].map((eachModifer: any) => ({
                  modifierId: String(eachModifer.modifier_id),
                  modifierName: eachModifer.modifier__name,
                }))
              : null,
            optionValues: eachOption["option_values"]
              ? eachOption["option_values"].map((eachOptionValue: any) => ({
                  optionValueId: String(eachOptionValue.option_value_id),
                  optionValueName: eachOptionValue.name,
                  price: eachOptionValue.price,
                  image: eachOptionValue.image,
                  isChecked: false,
                  isEdited: false,
                }))
              : [],
          })
        );
      setWithoutGroupsDataFromParent(filtredOptionsListWithoutGroup);
      setInitialRenderingWithoutGroups(filtredOptionsListWithoutGroup);
      const count = filtredOptionsListWithoutGroup.length;
      setselecetdOptionsWithoutGroups(count);
    } else {
      setWithoutGroupsDataFromParent([]);
    }
  }, [optionsListdataWithoutGroup, activeProductId, activeVariantId]);

  useEffect(() => {
    if (productNameAndVariantIdsFromProductIdsData) {
      const { selectedProductList } =
        productNameAndVariantIdsFromProductIdsData;
      if (selectedProductList) {
        const { edges } = selectedProductList;
        const filteredProductList = edges.map((eachProduct: any) => ({
          productId: eachProduct.node.productId,
          productName: eachProduct.node.name,
          variants: eachProduct.node.variants
            ?.filter((eachVariant: any) => eachVariant.variantGroup === "Size")
            .map((eachVariant: any) => ({
              variantId: eachVariant.variantId,
              variantName: eachVariant.name,
              variantGroup: eachVariant.variantGroup,
            })),
          // variants:
          //   eachProduct.node.variants &&
          //   eachProduct.node.variants.map((eachVariant: any) => ({
          //     variantId: eachVariant.variantId,
          //     variantName: eachVariant.name,
          //   })),
        }));

        setProductsList(filteredProductList);
      }
    }
  }, [productNameAndVariantIdsFromProductIdsData]);

  const [assignOptionGroupsAndOptionsToProduct] = useMutation(
    ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT,
    {
      onCompleted: (data) => {
        if (data) {
          const { addIncludedOptionGroupToProduct } = data;
          if (addIncludedOptionGroupToProduct) {
            const { success } = addIncludedOptionGroupToProduct;
            if (success) {
              handleNavigate();
              dispatch(setName("tabState", 2));
              toast.success(
                <div>
                  <Typography>{t("toast.assigned")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            }
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [OPTION_GROUPS_IN_PRODUCT],
    }
  );

  const [editAssignOptionGroupsAndOptionsToProduct] = useMutation(
    EDIT_ASSIGNED_OPTIONS_GROUPS_AND_OPTIONS_TO_THE_PRODUCT,
    {
      onCompleted: (data) => {
        dispatch(setName("tabState", 2));

        if (data) {
          const { editIncludedOptionGroupToProduct } = data;
          if (editIncludedOptionGroupToProduct) {
            const { success } = editIncludedOptionGroupToProduct;
            if (success) {
              handleNavigate();
              toast.success(
                <div>
                  <Typography>{t("toast.updated")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            }
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [OPTION_GROUPS_IN_PRODUCT],
    }
  );
  useEffect(() => {
    if (updateOptionsData) {
      const { productOptionValueDetailedListing } = updateOptionsData;
      const parsedProductOptions = JSON.parse(
        productOptionValueDetailedListing
      );

      const filteredOptionsList = parsedProductOptions
        ? parsedProductOptions.map((eachId: any) => ({
            productId: eachId["product_id"]
              ? String(eachId["product_id"])
              : null,
            variantId: eachId["variant_id"]
              ? String(eachId["variant_id"])
              : null,
            productName: eachId["product_name"] || eachId["variant_name"],
            data: eachId["data"]
              ? eachId["data"].map((eachGroup: any) => {
                  const checkinOptionGroupId = eachGroup["option_group_id"];

                  if (checkinOptionGroupId) {
                    return {
                      optionGroupId: String(eachGroup["option_group_id"]),
                      optionGroupName: eachGroup["option_group_name"],
                      optionObjects: eachGroup["options"]
                        ? eachGroup["options"].map((eachOption: any) => ({
                            optionId: String(eachOption["option_id"]),
                            optionName: eachOption["option_name"],
                            modifiers: eachOption["modifiers"]
                              ? eachOption["modifiers"].map((each: any) => ({
                                  modifierId: String(each["modifier_id"]),
                                  modifierName: each["modifier__name"],
                                }))
                              : [],
                            optionValues: eachOption["option_values"]
                              ? eachOption["option_values"].map(
                                  (eachOptionValue: any) => ({
                                    optionValueId: String(
                                      eachOptionValue["option_value_id"]
                                    ),
                                    optionValueName:
                                      eachOptionValue["option_value_name"],
                                    price:
                                      eachOptionValue["option_value_price"],
                                    isChecked:
                                      eachOptionValue["mark_as_ingredient"],
                                  })
                                )
                              : null,
                          }))
                        : null,
                    };
                  } else {
                    return {
                      optionGroupId: null,
                      optionObjects: eachGroup
                        ? {
                            optionId:
                              eachGroup["option_id"] &&
                              String(eachGroup["option_id"]),
                            optionName:
                              eachGroup["option_name"] &&
                              eachGroup["option_name"],
                            modifiers: eachGroup["modifiers"]
                              ? eachGroup["modifiers"].map((each: any) => ({
                                  modifierId: String(each["modifier_id"]),
                                  modifierName: each["modifier__name"],
                                }))
                              : [],
                            optionValues: eachGroup["option_values"]
                              ? eachGroup["option_values"].map(
                                  (eachOptionValue: any) => ({
                                    optionValueId: String(
                                      eachOptionValue["option_value_id"]
                                    ),
                                    optionValueName:
                                      eachOptionValue["option_value_name"],
                                    price:
                                      eachOptionValue["option_value_price"],
                                    isChecked:
                                      eachOptionValue["mark_as_ingredient"],
                                    image:
                                      eachOptionValue["option_value_image"],
                                  })
                                )
                              : null,
                          }
                        : null,
                    };
                  }
                })
              : [],
          }))
        : [];
      setExistingOptionsList(filteredOptionsList);
    }
  }, [updateOptionsData]);
  useEffect(() => {
    if (optionsListData) {
      const { optionGroupList } = optionsListData;
      const { edges } = optionGroupList;
      const filteredDataList = edges.map((each: any) => ({
        optionGroupName: each.node.name,
        optionGroupId:
          each.node.optionGroupId && String(each.node.optionGroupId),
        isChecked: false,
        optionObjects: each.node.optionObjs
          ? each.node.optionObjs.map((eachObject: any) => ({
              name: eachObject.name,
              isChecked: false,

              optionId: String(eachObject.optionId),
              optionValues: eachObject.optionValues
                ? eachObject.optionValues.map((eachOption: any) => ({
                    image: eachOption.image,
                    name: eachOption.name,
                    optionValueId: String(eachOption.optionValueId),
                    price: eachOption.price,
                    isChecked: false,
                  }))
                : [],
            }))
          : [],
        options: each.node.options,
        slugName: each.node.slugName,
      }));

      setOptionGroupList(filteredDataList);
      setInitialOptionGroupList(filteredDataList);
    }
  }, [optionsListData]);

  useEffect(() => {
    if (optionsListWithoutData) {
      const { optionList } = optionsListWithoutData;
      const { edges } = optionList;

      const filteredDataList = edges.map((each: any) => ({
        optionId: String(each.node.optionId),
        name: each.node.name,
        isChecked: false,
        optionValues: each.node.optionValues
          ? each.node.optionValues.map((eachOption: any) => ({
              image: eachOption.image,
              name: eachOption.name,
              optionValueId: String(eachOption.optionValueId),
              price: eachOption.price,
              isChecked: false,
            }))
          : [],
      }));
      setOptionsListWithoutGroup(filteredDataList);
      setInitialOptionsListWithoutGroup(filteredDataList);
    }
  }, [optionsListWithoutData]);

  const handleAddButtonWhenClick = () => {
    setOptionsListWithoutGroup(initialOptionsListWithoutGroup);
  };
  const [searchInput, setSearchInput] = useState<string | null>("");

  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [addButtonClickText, setAddButtonClickText] = useState<string>("");
  const [popperTrue, setPopperTrue] = useState(false);
  const [selecetdOptionsAndGroups, setselecetdOptionsAndGroups] =
    useState<number>(0);
  const [selecetdOptionsWithGroups, setselecetdOptionsWithGroups] =
    useState<number>(0);
  const [selecetdOptionsWithoutGroup, setselecetdOptionsWithoutGroups] =
    useState<number>(0);
  const [enableAddButton, setEnableButton] = useState<boolean>(false);
  const [searchedName, setSearchedName] = useState<string | null>(null);
  const [open, setOpen] = React.useState(false);
  const [totalModifiedOptions, setTotalModifiedOptions] = useState<any[]>([]);
  const [producImfo, setProducImfo] = useState<any>({});

  const inputClasses = searchInput
    ? "assign-options-to-products-search-label"
    : "assign-options-to-products-search-label";

  const handleChange = (id: string) => {
    setExpanded((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleGroupCheckbox = (
    groupId: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptionGroupList((prev: any) => {
      return prev.map((group: any) => {
        if (group.optionGroupId === groupId) {
          const isGroupChecked = e.target.checked;

          const selectedOptionIds = isGroupChecked
            ? group.optionObjects
                .flatMap((obj: any) => obj.optionValues)
                .filter((value: any) => value.isChecked)
                .map((value: any) => value.optionValueId)
            : [];

          const updatedObject = group.optionObjects.map((obj: any) => ({
            ...obj,
            isChecked: isGroupChecked,
            optionValues: obj.optionValues.map((value: any) => ({
              ...value,
              isChecked: isGroupChecked,
            })),
          }));

          return {
            ...group,
            optionObjects: updatedObject,
            isChecked: isGroupChecked,
            selectedOptionIds,
          };
        }
        return group;
      });
    });
  };

  // const handleNestedCheckbox = (
  //   groupId: string,
  //   objectId: string,
  //   e: React.ChangeEvent<HTMLInputElement>
  // ) => {
  //   setOptionGroupList((prev: any) => {
  //     return prev.map((group: any) => {
  //       if (group.optionGroupId === groupId) {
  //         const updatedObject = group.optionObjects.map((obj: any) => {
  //           if (obj.optionId === objectId) {
  //             const isObjectChecked = e.target.checked;
  //             const updatedValues = obj.optionValues.map((value: any) => ({
  //               ...value,
  //               isChecked: isObjectChecked,
  //             }));
  //             return {
  //               ...obj,
  //               optionValues: updatedValues,
  //               isChecked: isObjectChecked,
  //             };
  //           }
  //           return obj;
  //         });
  //         const isGroupChecked = updatedObject.every(
  //           (obj: any) => obj.isChecked
  //         );
  //         const selectedOptionIds = isGroupChecked
  //           ? updatedObject
  //               .flatMap((obj: any) => obj.optionValues)
  //               .filter((value: any) => value.isChecked)
  //               .map((value: any) => value.optionValueId)
  //           : [];

  //         return {
  //           ...group,
  //           optionObjects: updatedObject,
  //           isChecked: isGroupChecked,
  //           selectedOptionIds,
  //         };
  //       }
  //       return group;
  //     });
  //   });
  // };

  useEffect(() => {
    const checkedOptionGroupIds = optionGroupsList
      .filter((group: any) => group.isChecked)
      .map((group: any) => +group.optionGroupId);
    const selectedOptionIdsWithGroupIds = optionGroupsList
      .filter((group: any) => !group.isChecked)
      .map((group: any) => ({
        option_group_id: group.optionGroupId,
        option_ids: group.optionObjects
          .filter((option: any) => option.isChecked)
          .map((option: any) => +option.optionId),
      }))
      .filter((obj: any) => obj["option_ids"].length > 0);

    setOptionsListByGroupId(checkedOptionGroupIds);
    setOptionsListByOptionId(selectedOptionIdsWithGroupIds);
  }, [optionGroupsList]);

  const handleCloseIcon = () => {
    setPopperTrue(false);
  };

  useEffect(() => {
    if (
      optionsListByGroupId.length > 0 ||
      optionsListByOptionId.length > 0 ||
      optionIdsWithoutgroup.length > 0
    ) {
      setPopperTrue(true);
      setEnableButton(true);
    } else {
      setPopperTrue(false);
      setEnableButton(false);
    }
  }, [optionsListByGroupId, optionsListByOptionId, optionsListWithoutGroup]);

  const handleSearchedNameAndPopup = () => {
    setOpen(true);
    setSearchedName(searchInput);
  };

  const handleCloseFromAssign = () => setOpen(false);

  const filteredOptionsAndOptionGroups = optionGroupsList.filter(
    (eachGroup: any) =>
      eachGroup.optionGroupName.toLowerCase().includes(searchInput)
  );

  const filteredOptionsWithoutOptionGroups = optionsListWithoutGroup.filter(
    (eachOption: any) => eachOption.name.toLowerCase().includes(searchInput)
  );

  const handleOptionsCheckboxWithoutGroup = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const checkedCheckboxes = optionsListWithoutGroup.map((each: any) => {
      if (each.optionId === id) {
        return { ...each, isChecked: e.target.checked };
      }
      return each;
    });

    setOptionsListWithoutGroup(checkedCheckboxes);

    setOptionIdsWithoutGroup((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(+id)) {
        return [...prevIds, +id];
      } else if (!e.target.checked && prevIds.includes(+id)) {
        return prevIds.filter((prevId: any) => prevId !== +id);
      }
      return prevIds;
    });
  };

  const handleTotalSaveButton = () => {
    if (productIdByEdit === undefined) {
      const collectingAssignedData = totalModifiedOptions.filter(
        (each: any) => each.list.length > 0
      );
      const collectedLists = collectingAssignedData
        .filter((each: any) => each.variantName === null)
        .flatMap((each: any) => each.list);

      const collectedProductsList = productIdsList
        .filter((each: any) => each.productId)
        .map((each: any) => +each.productId);
      const addingProductIdsToData = collectedLists.map((each: any) => ({
        ...each,
        productId: collectedProductsList,
      }));
      const filteredVariantName = totalModifiedOptions
        .filter((each) => each.variantName !== null)
        .map((each) => each.variantName);

      const filteredVariantIds = productIdsList.reduce(
        (result: any, eachProduct: any) => {
          eachProduct.variants &&
            eachProduct.variants.forEach((eachVariant: any) => {
              if (filteredVariantName.includes(eachVariant.variantName)) {
                if (!result[eachVariant.variantName]) {
                  result[eachVariant.variantName] = [];
                }
                result[eachVariant.variantName].push(+eachVariant.variantId);
              }
            });
          return result;
        },
        {}
      );

      const addingVariantIdsToVariantId = collectingAssignedData.map(
        (each: any) => {
          if (filteredVariantIds[each.variantName]) {
            each.list = each.list.map((eachData: any) => {
              if (eachData.variantId) {
                eachData.variantId = filteredVariantIds[each.variantName];
                delete eachData.variant;
              }
              return eachData;
            });
          }
          return each;
        }
      );

      const collectingVariantData = addingVariantIdsToVariantId
        .filter((each) => each.variantName !== null)
        .map((each) => each.list)
        .flat();

      const finalMixingData = [
        ...addingProductIdsToData,
        ...collectingVariantData,
      ];

      const convertingDataIntoRequiredFormat = finalMixingData.map(
        (eachOptionGroup: any) => ({
          product_id: eachOptionGroup.productId
            ? eachOptionGroup.productId
            : null,
          variant_id: eachOptionGroup.variantId
            ? eachOptionGroup.variantId
            : null,
          option_group_id: eachOptionGroup.optionGroupId
            ? eachOptionGroup.optionGroupId
            : null,
          option_objs: eachOptionGroup.optionObjects
            ? eachOptionGroup.optionObjects.map((eachOption: any) => ({
                option_id: eachOption.optionId,

                modifiers:
                  eachOption.modifiers && eachOption.modifiers.length > 0
                    ? eachOption.modifiers.map(
                        (eachModifier: any) => +eachModifier.modifierId
                      )
                    : null,

                list:
                  eachOption.optionValues &&
                  eachOption.optionValues.length > 0 &&
                  eachOption.optionValues.map((eachOptionValue: any) => ({
                    option_value_id: eachOptionValue.optionValueId,
                    mark_as_ingredient: eachOptionValue.isChecked,
                  })),
              }))
            : null,
        })
      );

      assignOptionGroupsAndOptionsToProduct({
        variables: {
          productIds: JSON.stringify(collectedProductsList),
          optionsList: JSON.stringify(convertingDataIntoRequiredFormat),
        },
      });
    } else {
      const editedDataList = totalModifiedOptions.flatMap((eachObject) =>
        eachObject.list.length > 0 ? eachObject.list : []
      );
      const convertingEditedDataRequiredFormat: any = editedDataList.map(
        (eachOptionGroup: any) => ({
          product_id: eachOptionGroup.productId
            ? eachOptionGroup.productId
            : null,
          variant_id: eachOptionGroup.variantId
            ? eachOptionGroup.variantId
            : null,
          option_group_id: eachOptionGroup.optionGroupId || null,
          option_objs: eachOptionGroup.optionObjects
            ? eachOptionGroup.optionObjects.map((eachOption: any) => ({
                option_id: eachOption.optionId,
                modifiers:
                  eachOption.modifiers && eachOption.modifiers.length > 0
                    ? eachOption.modifiers.map(
                        (eachModifier: any) => +eachModifier.modifierId
                      )
                    : null,
                list: eachOption.optionValues
                  ? eachOption.optionValues.map((eachOptionValue: any) => ({
                      option_value_id: eachOptionValue.optionValueId,
                      mark_as_ingredient: eachOptionValue.isChecked,
                    }))
                  : null,
              }))
            : null,
        })
      );

      editAssignOptionGroupsAndOptionsToProduct({
        variables: {
          productId: +productIdByEdit,
          optionsList: JSON.stringify(convertingEditedDataRequiredFormat),
        },
      });
    }

    setUpdatedGroupIds([]);
    setUpdatedOptionIds([]);
    setUpdatedOptionIdsWithoutGroup([]);
  };

  var productImfo: any;
  if (productIdsList.length > 0) {
    productImfo = productIdsList[0];
  } else {
    productImfo = producImfo;
  }

  useEffect(() => {
    if (
      productNameAndVariantIdsFromEditIdData &&
      productIdByEdit !== undefined
    ) {
      const { product } = productNameAndVariantIdsFromEditIdData;
      const productDetails = {
        productId: product.productId,
        productName: product.name,
        variants:
          product.variants &&
          product.variants
            .filter((eachVariant: any) => eachVariant.variantGroup === "Size")
            .map((eachVariant: any) => ({
              variantId: eachVariant.variantId,
              variantName: eachVariant.name,
              variantGroup: eachVariant.variantGroup,
            })),
      };
      const a = productDetails;
      setProducImfo(a);
    }
  }, [productNameAndVariantIdsFromEditIdData]);

  const updatedDataList = (
    id: any,
    name: any,
    typeName: string,
    newData: any[]
  ) => {
    setTotalModifiedOptions((prevData) => {
      const newDataArray = [...prevData];
      const existingDataIndex = newDataArray.findIndex(
        (item) => item[`${typeName}Id`] === id
      );

      if (existingDataIndex !== -1) {
        // If data with the same id exists, update it
        newDataArray[existingDataIndex] = {
          ...newDataArray[existingDataIndex],
          list: newData,
        };
      } else {
        // If data with the same id doesn't exist, add it
        const newItem = {
          [`${typeName}Id`]: id,
          variantName: name,
          list: newData,
        };
        newDataArray.push(newItem);
      }

      return newDataArray;
    });
  };

  const handleClearButton = () => {
    setUpdatedGroupIds([]);
    setUpdatedOptionIds([]);
    setUpdatedOptionIdsWithoutGroup([]);
    setExistingOptionsList([]);
    setGroupsDataFromParent([]);
    clearAllChildData();
  };

  const childRefs = useRef<Array<ChildRef | null>>([]);

  const clearAllChildData = () => {
    childRefs.current.forEach((childRef) => {
      if (childRef) {
        childRef.clearData();
      }
    });
  };
  const addChildRef = (ref: ChildRef | null) => {
    childRefs.current.push(ref);
  };

  const { t } = useTranslation();

  return (
    <Box sx={{ padding: "1%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          marginBottom: "2%",
        }}
      >
        <IconButton
          className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          onClick={handleNavigate}
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography className="create-a-use-role-heading">
          {t("product.assign_options_to")}{" "}
          {productImfo && productImfo.productName}
        </Typography>
        <Box
          sx={{
            display: "flex",
            gap: "10%",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <Button
            onClick={handleClearButton}
            sx={{ textTransform: "none" }}
            className="create-a-use-role-clear-button"
            variant="outlined"
            color="warning"
          >
            {t("buttons.clear")}
          </Button>
          <Button
            onClick={handleTotalSaveButton}
            sx={{ textTransform: "none" }}
            variant="contained"
            color="warning"
          >
            {t("buttons.save")}
          </Button>
        </Box>
      </Box>
      {popperTrue && (
        <Paper
          elevation={2}
          className="rendering-options-popver"
          sx={{
            padding: "10px",
            backgroundColor: "",
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            <Typography className="rendering-options-options-selected-text">
              {selecetdOptionsAndGroups +
                selecetdOptionsWithGroups +
                selecetdOptionsWithoutGroup}{" "}
            </Typography>
          </Box>
          <Box>
            <Typography className="rendering-options-assign-text">
              {t("product.assigned_selected_options")}{" "}
              <span className="rendering-options-quotes">"</span>
              <span className="rendering-options-add-text">
                {t("buttons.add")}
              </span>
              <span className="rendering-options-quotes">"</span>{" "}
              {t("product.button")}
            </Typography>
          </Box>
          <Box>
            <IconButton onClick={handleCloseIcon}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Paper>
      )}
      <Box
        sx={{
          display: "flex",
          width: "100%",
          marginTop: "15px",
          alignItems: "flex-start",
        }}
      >
        <Box
          sx={{
            width: "65%",
            border: "1px solid #EEE",
            maxHeight: "75vh",
            overflowY: "scroll",
            WebkitOverflowScrolling: "touch",
            "&::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {
            <Box>
              {productImfo && (
                <RenderingOptions
                  ref={(ref: ChildRef | null) => addChildRef(ref)}
                  forInitialRenderingOptionGroups={
                    forInitialRenderingOptionGroups
                  }
                  forInitialRenderingWithoutGroups={
                    forInitialRenderingWithoutGroups
                  }
                  setWithoutGroupsDataFromParent={
                    setWithoutGroupsDataFromParent
                  }
                  setGroupsDataFromParent={setGroupsDataFromParent}
                  withoutGroupsDataFromParent={withoutGroupsDataFromParent}
                  groupsDataFromParent={groupsDataFromParent}
                  groupsDataWithGroupFromParent={groupsDataWithGroupFromParent}
                  handleClearButton={handleClearButton}
                  updatedGroupIds={
                    activeProductId === productImfo.productId
                      ? updatedGroupIds
                      : []
                  }
                  updatedOptionIds={
                    activeProductId === productImfo.productId
                      ? updatedOptionIds
                      : []
                  }
                  updatedOptionIdsWithoutGroup={
                    activeProductId === productImfo.productId
                      ? updatedOptionIdsWithoutGroup
                      : []
                  }
                  setUpdatedGroupIds={setUpdatedGroupIds}
                  setUpdatedOptionIds={setUpdatedOptionIds}
                  setUpdatedOptionIdsWithoutGroup={
                    setUpdatedOptionIdsWithoutGroup
                  }
                  updatedDataList={updatedDataList}
                  existingList={existingOptionsList ? existingOptionsList : []}
                  activeVariantId={activeVariantId}
                  activeProductId={activeProductId}
                  setActiveVariantId={setActiveVariantId}
                  setActiveProductId={setActiveProductId}
                  variantId={null}
                  variantName={null}
                  setTotalModifiedOptions={setTotalModifiedOptions}
                  initialOptionsListWithoutGroup={
                    initialOptionsListWithoutGroup
                  }
                  setOptionIdsWithoutGroup={setOptionIdsWithoutGroup}
                  key={productImfo.productId}
                  handleAddButtonWhenClick={handleAddButtonWhenClick}
                  optionIdsWithoutgroup={optionIdsWithoutgroup}
                  initialOptionGroupsList={initialOptionGroupsList}
                  setOptionGroupList={setOptionGroupList}
                  setOptionsListByOptionId={setOptionsListByOptionId}
                  setOptionsListByGroupId={setOptionsListByGroupId}
                  enableAddButton={enableAddButton}
                  setAddButtonClickText={setAddButtonClickText}
                  accessText="product"
                  productName={productImfo.productName}
                  setPopperTrue={setPopperTrue}
                  productId={productImfo.productId}
                  setEnableButton={setEnableButton}
                  optionsListByOptionId={optionsListByOptionId}
                  optionsListByGroupId={optionsListByGroupId}
                />
              )}
              {productImfo.variants &&
                productImfo.variants.map((each: any) => (
                  <RenderingOptions
                    ref={(ref: ChildRef | null) => addChildRef(ref)}
                    key={each.variantId}
                    forInitialRenderingOptionGroups={
                      forInitialRenderingOptionGroups
                    }
                    forInitialRenderingWithoutGroups={
                      forInitialRenderingWithoutGroups
                    }
                    groupsDataWithGroupFromParent={
                      groupsDataWithGroupFromParent
                    }
                    setWithoutGroupsDataFromParent={
                      setWithoutGroupsDataFromParent
                    }
                    setGroupsDataFromParent={setGroupsDataFromParent}
                    withoutGroupsDataFromParent={withoutGroupsDataFromParent}
                    groupsDataFromParent={groupsDataFromParent}
                    handleClearButton={handleClearButton}
                    updatedGroupIds={
                      activeVariantId === each.variantId ? updatedGroupIds : []
                    }
                    updatedOptionIds={
                      activeVariantId === each.variantId ? updatedOptionIds : []
                    }
                    updatedOptionIdsWithoutGroup={
                      activeVariantId === each.variantId
                        ? updatedOptionIdsWithoutGroup
                        : []
                    }
                    setUpdatedGroupIds={setUpdatedGroupIds}
                    setUpdatedOptionIds={setUpdatedOptionIds}
                    setUpdatedOptionIdsWithoutGroup={
                      setUpdatedOptionIdsWithoutGroup
                    }
                    updatedDataList={updatedDataList}
                    existingList={
                      existingOptionsList ? existingOptionsList : []
                    }
                    activeVariantId={activeVariantId}
                    activeProductId={activeProductId}
                    setActiveVariantId={setActiveVariantId}
                    setActiveProductId={setActiveProductId}
                    variantId={each.variantId}
                    variantName={each.variantName}
                    setTotalModifiedOptions={setTotalModifiedOptions}
                    initialOptionsListWithoutGroup={
                      initialOptionsListWithoutGroup
                    }
                    setOptionIdsWithoutGroup={setOptionIdsWithoutGroup}
                    handleAddButtonWhenClick={handleAddButtonWhenClick}
                    optionIdsWithoutgroup={optionIdsWithoutgroup}
                    initialOptionGroupsList={initialOptionGroupsList}
                    setOptionGroupList={setOptionGroupList}
                    setOptionsListByOptionId={setOptionsListByOptionId}
                    setOptionsListByGroupId={setOptionsListByGroupId}
                    enableAddButton={enableAddButton}
                    setAddButtonClickText={setAddButtonClickText}
                    accessText="variant"
                    productName={each.variantName}
                    setPopperTrue={setPopperTrue}
                    productId={null}
                    setEnableButton={setEnableButton}
                    optionsListByOptionId={optionsListByOptionId}
                    optionsListByGroupId={optionsListByGroupId}
                  />
                ))}
            </Box>
          }

          {}
        </Box>
        <Box
          style={{
            display: "flex",
            width: "35%",

            flexDirection: "column",
          }}
        >
          <Box sx={{ padding: "1.5%", border: "1px solid #EEE" }}>
            <Typography className="assign-options-to-products-option-groups-text">
              {t("product.option_or_group")}
            </Typography>
          </Box>
          <Box sx={{ padding: "20px", border: "1px solid #EEE" }}>
            <TextField
              onChange={(e) => setSearchInput(e.target.value)}
              placeholder={t("product.search_by_option") || ""}
              id="outlined-start-adornment"
              value={searchInput}
              sx={{ margin: "", width: "100%" }}
              color="warning"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box
            sx={{
              border: "1px solid #EEE",
              maxHeight: "55vh",
              overflowY: "scroll",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            {filteredOptionsAndOptionGroups.length !== 0 ||
            filteredOptionsWithoutOptionGroups.length !== 0 ? (
              <Box>
                {filteredOptionsAndOptionGroups.map((each: any) => (
                  <Box key={each.optionGroupId}>
                    <Paper
                      elevation={1}
                      sx={{ margin: "10px", borderRadius: "8px" }}
                    >
                      <Accordion expanded={expanded === each.optionGroupId}>
                        <AccordionSummary
                          aria-controls="panel2d-content"
                          id="panel2d-header"
                        >
                          <Box>
                            {each.optionObjects.length > 0 && (
                              <IconButton
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                onClick={() => handleChange(each.optionGroupId)}
                              >
                                {expanded === each.optionGroupId ? (
                                  <ArrowDropDownIcon />
                                ) : (
                                  <ArrowRightIcon />
                                )}
                              </IconButton>
                            )}
                          </Box>
                          <FormControlLabel
                            key={each.optionGroupId}
                            control={
                              <Checkbox
                                checked={each.isChecked}
                                onChange={(e) => {
                                  handleGroupCheckbox(each.optionGroupId, e);
                                }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                                color="warning"
                              />
                            }
                            label={
                              <Typography
                                sx={{ textTransform: "capitalize" }}
                                className="driver-petty-cash-popup-checkbox"
                              >
                                {each.optionGroupName}
                              </Typography>
                            }
                          />
                        </AccordionSummary>
                        <AccordionDetails>
                          <FormGroup sx={{ padding: "5px" }}>
                            {each.optionObjects.map((eachOption: any) => (
                              // <FormControlLabel
                              //   key={eachOption.optionId}
                              //   sx={{ marginLeft: "27px" }}
                              //   control={
                              //     <Checkbox
                              //       checked={eachOption.isChecked}
                              //       onChange={(e) => {
                              //         handleNestedCheckbox(
                              //           each.optionGroupId,
                              //           eachOption.optionId,
                              //           e
                              //         );
                              //       }}
                              //       sx={{
                              //         "& .MuiSvgIcon-root": { fontSize: 28 },
                              //       }}
                              //       color="warning"
                              //     />
                              //   }
                              //   label={

                              //   }
                              // />
                              <Typography
                                sx={{
                                  marginLeft: "17%",
                                  marginBottom: "3%",
                                  textTransform: "capitalize",
                                }}
                                className="driver-petty-cash-popup-checkbox"
                              >
                                {eachOption.name}
                              </Typography>
                            ))}
                          </FormGroup>
                        </AccordionDetails>
                      </Accordion>
                    </Paper>
                  </Box>
                ))}
                {filteredOptionsWithoutOptionGroups.map((each: any) => (
                  <Box>
                    <Paper
                      elevation={2}
                      sx={{
                        margin: "10px",
                        padding: "12px",
                        borderRadius: "7px",
                      }}
                    >
                      <Box sx={{ marginLeft: "20px" }}>
                        <FormControlLabel
                          key={each.optionId}
                          control={
                            <Checkbox
                              checked={each.isChecked}
                              onChange={(e) => {
                                handleOptionsCheckboxWithoutGroup(
                                  each.optionId,
                                  e
                                );
                              }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                              color="warning"
                            />
                          }
                          label={
                            <Typography
                              sx={{ textTransform: "capitalize" }}
                              className="driver-petty-cash-popup-checkbox"
                            >
                              {each.name}
                            </Typography>
                          }
                        />
                      </Box>
                    </Paper>
                  </Box>
                ))}
              </Box>
            ) : (
              <Box>
                <Button
                  onClick={handleSearchedNameAndPopup}
                  sx={{ textTransform: "none" }}
                  variant="text"
                  className="rendering-options-search-results-text"
                >
                  {searchInput} {t("product.create_a_new_option")}
                </Button>
                <Modal
                  open={open}
                  onClose={handleCloseFromAssign}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <CreateOptions
                      fromAssignOptions={true}
                      searchedName={searchedName}
                      handleCloseFromAssign={handleCloseFromAssign}
                      setSearchedName={setSearchInput}
                    />
                  </Box>
                </Modal>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default AssignOptionsToProduct;
