import { Box, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import BgImage from "../../assets/menuQr/getstartedbg.png"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Image from "../../assets/menuQr/getstartedimg.png"
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import DiningIcon from '@mui/icons-material/Dining';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import DomainMOdal from "./domainModal";
import DomainFinalModal from "./domianFinalModal";
import CustomDomainModal from "./customDomainFinalModal";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

const GetStart = () => {

    const [modal, setModal] = useState(false)
    const [finalModal, setFinalModal] = useState(false)
    const [customModal, setCustomModal] = useState(false)
    const navigate = useNavigate()

    const [buttonValue, setButtonValue] = useState(1)
    const { t } = useTranslation()

    return (
        <Box>
            <Box sx={{
                backgroundImage: `url(${BgImage})`,
                height: "40vh",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                position: "relative"
            }}>
                <Box sx={{ display: "flex", alignItems: "center", gap: '24px', padding: "16px" }}>
                    <ArrowBackIcon onClick={() => { navigate("/online/ordering-setUp") }} />
                    <Typography className="main-head">{t("online_ordering.ordering_setUp")}</Typography>
                </Box>
                <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "12px" }}>
                    <Button className="main-button" onClick={() => { navigate("/online/order-customize-domain") }}>{t("online_ordering.get_started")}{<ArrowForwardIcon />} </Button>
                </Box>
                {/* <Box sx={{ display: "flex", justifyContent: "center", width: "100%", marginTop: "12px", gap: '24px' }}>
                    <Button className={buttonValue === 1 ? "main-button" : "main-button-disable"} onClick={() => { setButtonValue(1) }}> {<DiningIcon />} Restaurant  </Button>
                    <Button className={buttonValue === 2 ? "main-button" : "main-button-disable"} onClick={() => { setButtonValue(2) }}> {<StoreMallDirectoryIcon />} Retail  </Button>

                </Box> */}
                <DomainMOdal modal={modal} setModal={setModal} setFinalModal={setFinalModal} setCustomModal={setCustomModal} />
                <DomainFinalModal finalModal={finalModal} setFinalModal={setFinalModal} />
                <CustomDomainModal customModal={customModal} setCustomModal={setCustomModal} setModal={setModal} />
                <Box sx={{ position: 'absolute', top: '30vh', left: '30vh', height: "80vh", width: "60vw" }}>
                    <img src={Image} alt="setUp" style={{ height: "100%", width: "100%" }} />
                </Box>
            </Box>

        </Box >
    )
}

export default GetStart