import React, { useEffect, useState } from "react";
import {
  Typography,
  Paper,
  Button,
  Divider,
  TextField,
  Switch,
  Checkbox,
  Box,
} from "@mui/material";
import { FormControl, Select, MenuItem } from "@mui/material";
import { Radio, RadioGroup, FormControlLabel } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as Yup from "yup";
import "./rounding.css";
import "./salesTax.css";
import "./refund.css";
import "./createAdvance.css";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useFormik } from "formik";
import { CREATE_REFUND_CANCEL } from "../../graphicalQl/mutation/salesTaxMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_REFUND_CANCELATION } from "../../graphicalQl/usequery/settingQuery";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
interface ConvertedFlow {
  [key: string]: {
    dine_in: string;
    delivery: string;
    collection: string;
  };
}

const validationSchema = Yup.object({});
const RefundCancel: React.FC = () => {
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const initialValues = {
    refundInputValue: "",
    refundSelectedOption: "Minute",
    toggleState: false,
    selectedValue: "tax",
    showRefund: false,
    cancelSelectedValue: "option1",
    cancelTime: "",
    cancelTime1: "",
    cancelTime2: "",
    acceptCancel: "Minute",
    acceptCancel1: "Minute",
    acceptCancel2: "Minute",
  };

  const { t } = useTranslation();
  const [checkBoxList, setCheckBoxList] = useState<any[]>([
    {
      id: 1,
      value: "allowRefundOn",
      label: "Allow Refund on Item",
      isChecked: true,
    },
    { id: 2, value: "taxes", label: "Taxes", isChecked: true },
    { id: 3, value: "charges", label: "Charges", isChecked: true },
    { id: 4, value: "deliveryFee", label: "Delivery Fee", isChecked: true },
    // { id: 5, value: "custom", label: "Custom", isChecked: true },
  ]);

  const selectedList = checkBoxList
    .filter((item) => item.isChecked === true)
    .map((each) => each.value);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: initialValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      refund({
        variables: {
          storeId: locationId && +locationId,
          refundTimeLimit:
            values.refundSelectedOption === "Hours"
              ? +values.refundInputValue! * 60
              : values.refundSelectedOption === "Days"
              ? +values.refundInputValue! * 1440
              : +values.refundInputValue!,
          refundWith: JSON.stringify(selectedList),
          allowRefundReasons: values.showRefund,
          allowRefund: formik.values.toggleState,
          refundReasons: JSON.stringify(items),
          cancellationBasedOnStatus:
            values.cancelSelectedValue === "option1"
              ? JSON.stringify(convertedFlow)
              : null,
          noOfMinsToAcceptCancellationForDelivery:
            values.cancelSelectedValue === "option2"
              ? values.acceptCancel === "Hours"
                ? +values.cancelTime! * 60
                : values.acceptCancel === "Days"
                ? +values.cancelTime! * 1440
                : +values.cancelTime!
              : null,
          noOfMinsToAcceptCancellationFordine_in:
            values.cancelSelectedValue === "option2"
              ? values.acceptCancel1 === "Hours"
                ? +values.cancelTime1! * 60
                : values.acceptCancel1 === "Days"
                ? +values.cancelTime1! * 1440
                : +values.cancelTime1!
              : null,
          noOfMinsToAcceptCancellationForCollection:
            values.cancelSelectedValue === "option2"
              ? values.acceptCancel2 === "Hours"
                ? +values.cancelTime2! * 60
                : values.acceptCancel2 === "Days"
                ? +values.cancelTime2! * 1440
                : +values.cancelTime2!
              : null,
        },
      });
    },
  });

  const { data } = useQuery(GET_REFUND_CANCELATION, {
    variables: { storeId: locationId && +locationId },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  const handleData2 = () => {
    if (data && data.storeSettings) {
      const list = {
        refundInputValue:
          data.storeSettings.refundTimeLimit > 1440
            ? data.storeSettings.refundTimeLimit / 1440
            : data.storeSettings.refundTimeLimit > 60
            ? data.storeSettings.refundTimeLimit / 60
            : data.storeSettings.refundTimeLimit,
        refundSelectedOption:
          data.storeSettings.refundTimeLimit < 60
            ? "Minute"
            : data.storeSettings.refundTimeLimit < 1440
            ? "Hours"
            : "Days",
        selectedValue: data.storeSettings.refundWith,
        toggleState: data.storeSettings.allowRefund,
        showRefund: data.storeSettings.allowRefundReasons || false,
        cancelSelectedValue: data.storeSettings.cancellationBasedOnStatus
          ? "option1"
          : "option2",
        cancelTime:
          data.storeSettings.noOfMinsToAcceptCancellationForDelivery > 1440
            ? data.storeSettings.noOfMinsToAcceptCancellationForDelivery / 1440
            : data.storeSettings.noOfMinsToAcceptCancellationForDelivery > 60
            ? data.storeSettings.noOfMinsToAcceptCancellationForDelivery / 60
            : data.storeSettings.noOfMinsToAcceptCancellationForDelivery,
        acceptCancel:
          data.storeSettings.noOfMinsToAcceptCancellationForDelivery < 60
            ? "Minute"
            : data.storeSettings.noOfMinsToAcceptCancellationForDelivery < 1440
            ? "Hours"
            : "Days",
        cancelTime1:
          data.storeSettings.noOfMinsToAcceptCancellationFordine_in > 1440
            ? data.storeSettings.noOfMinsToAcceptCancellationFordine_in / 1440
            : data.storeSettings.noOfMinsToAcceptCancellationFordine_in > 60
            ? data.storeSettings.noOfMinsToAcceptCancellationFordine_in / 60
            : data.storeSettings.noOfMinsToAcceptCancellationFordine_in,
        acceptCancel1:
          data.storeSettings.noOfMinsToAcceptCancellationFordine_in < 60
            ? "Minute"
            : data.storeSettings.noOfMinsToAcceptCancellationFordine_in < 1440
            ? "Hours"
            : "Days",
        cancelTime2:
          data.storeSettings.noOfMinsToAcceptCancellationForCollection > 1440
            ? data.storeSettings.noOfMinsToAcceptCancellationForCollection /
              1440
            : data.storeSettings.noOfMinsToAcceptCancellationForCollection > 60
            ? data.storeSettings.noOfMinsToAcceptCancellationForCollection / 60
            : data.storeSettings.noOfMinsToAcceptCancellationForCollection,
        acceptCancel2:
          data.storeSettings.noOfMinsToAcceptCancellationForCollection < 60
            ? "Minute"
            : data.storeSettings.noOfMinsToAcceptCancellationForCollection <
              1440
            ? "Hours"
            : "Days",
      };
      formik.setValues(list);
      if (data.storeSettings.refundReasons) {
        setItems(JSON.parse(data.storeSettings.refundReasons));
      }
      if (data.storeSettings.cancellationBasedOnStatus) {
        const updatedFlow = flow.map((item) => {
          const status = JSON.parse(
            data.storeSettings.cancellationBasedOnStatus
          )[item.name];
          if (status) {
            return {
              ...item,
              dine_in: status.dine_in,
              delivery: status.delivery,
              collection: status.collection,
            };
          }
          return item;
        });
        // Set the updated flow
        setFlow(updatedFlow);
      }

      if (data.storeSettings.refundWith) {
        const updatedCheckBoxList = checkBoxList.map((item) => {
          if (data.storeSettings.refundWith.includes(item.value)) {
            return { ...item, isChecked: true };
          }
          return { ...item, isChecked: false };
        });
        setCheckBoxList(updatedCheckBoxList);
      }
    }
  };
  useEffect(() => {
    handleData2();
  }, [data]);

  const handleCancle = () => {
    handleData2();
  };

  const [addValue, setAddValue] = useState(false);

  const [refund] = useMutation(CREATE_REFUND_CANCEL, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [items, setItems] = useState([
    {
      id: "1",
      title: t("settings.order_error"),
      content: [
        t("settings.order_error"),
        t("settings.orders1"),
        t("settings.orders2"),
      ],
    },
    {
      id: "2",
      title: t("settings.food_quality"),
      content: [t("settings.food1"), t("settings.food2"), t("settings.food3")],
    },
    {
      id: "3",
      title: t("settings.delivery_issue"),
      content: [
        t("settings.delivery1"),
        t("settings.delivery2"),
        t("settings.delivery3"),
      ],
    },
    {
      id: "4",
      title: t("settings.payment_errors"),
      content: [
        t("settings.payment1"),
        t("settings.payment2"),
        t("settings.payment3"),
      ],
    },

    {
      id: "5",
      title: t("settings.service_issues"),
      content: [
        // "Incorrect or missing items in the order.",
        // "Late delivery beyond the estimated time.",
        // "Poor food quality or incorrect temperature upon arrival.",
        t("settings.service1"),
        t("settings.service2"),
        t("settings.service3"),
      ],
    },
  ]);

  const [inputValue1, setInputValue1] = useState("");
  const [inputValue2, setInputValue2] = useState("");
  const [textList, setTextList] = useState<string[]>([]);
  const [editId, setEditId] = useState<string | null>(null);
  const handleAddValue = () => {
    setAddValue(true);
    setInputValue1("");
    setInputValue2("");
    setTextList([]);
    setEditId(null);
  };

  const handleCancelValue = () => {
    setAddValue(false);
    setInputValue1("");
    setInputValue2("");
    setTextList([]);
    setEditId(null);
  };

  const [errorCondition, setErrorCondition] = useState(false);

  const handleSaveValue = () => {
    if (inputValue1 !== "" && textList.length > 0) {
      if (!editId) {
        const newItem = {
          id: Date.now().toString(), // Convert to string
          title: inputValue1,
          content: textList,
        };
        setItems([...items, newItem]);
        handleCancelValue();
      } else {
        const updatedItems = items.map((item) => {
          if (item.id === editId) {
            return {
              id: item.id,
              title: inputValue1,
              content: textList,
            };
          }
          return item;
        });
        setItems(updatedItems);
        handleCancelValue();
      }
      setErrorCondition(false);
    } else {
      setErrorCondition(true);
    }
  };

  const handleDelete = (id: string) => {
    const updatedItems = items.filter((item) => item.id !== id);
    setItems(updatedItems);
  };

  const handleTextareaChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = e.target.value;
    const lines = newText.split("\n").filter((line) => line.trim() !== "");
    setTextList(lines);
    setInputValue2(newText);
  };

  const handleEditRefundReason = (id: any) => {
    const updatedItems = items.filter((item) => item.id === id);

    setEditId(id);
    setAddValue(true);
    setInputValue2(updatedItems[0].content.join("\n"));
    setInputValue1(updatedItems[0].title);
    setTextList(updatedItems[0].content);
  };

  const [flow, setFlow] = useState<
    {
      id: string;
      label: string;
      name: string;
      dine_in: string;
      delivery: string;
      collection: string;
    }[]
  >([
    {
      id: "1",
      label: "Order Placed",
      name: "Pending",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "2",
      label: "Accepted",
      name: "Accepted",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "3",
      label: "Hold",
      name: "Hold",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "4",
      label: "Yet to Prepare",
      name: "YetToPrepare",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "5",
      label: "Preparing",
      name: "Preparing",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "6",
      label: "Prepared",
      name: "Prepared",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "7",
      label: "On the way to delivery",
      name: "OnTheWay",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
    {
      id: "8",
      label: "Delivered",
      name: "Completed",
      dine_in: "allow",
      delivery: "allow",
      collection: "allow",
    },
  ]);

  const [convertedFlow, setConvertedFlow2] = useState<any>();

  const handleData = () => {
    const convertedFlow = flow.reduce((acc: ConvertedFlow, status) => {
      acc[status.name] = {
        dine_in: status.dine_in,
        delivery: status.delivery,
        collection: status.collection,
      };
      return acc;
    }, {});
    setConvertedFlow2(convertedFlow);
  };
  useEffect(() => {
    handleData();
  }, [flow]);

  const options = [
    { value: "Minute", label: t("settings.minute") },
    { value: "Hours", label: t("settings.hour") },
    { value: "Days", label: t("settings.day") },
  ];

  const handleDropdownChange = (id: any, statusType: any, value: any) => {
    setFlow((prevFlow) =>
      prevFlow.map((status) =>
        status.id === id ? { ...status, [statusType]: value } : status
      )
    );
  };

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <Paper elevation={0} style={{ padding: "20px" }}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography className="rounding_titleValue">
              {t("settings.refund&cancel")}
            </Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button
                variant="contained"
                className="rounding_Button_clear"
                onClick={handleCancle}
              >
                {t("settings.clear")}
              </Button>
              <Button
                variant="contained"
                className="Rounding_save_button"
                style={{ marginLeft: "20px" }}
                type="submit"
              >
                {t("settings.save")}
              </Button>
            </div>
          </div>
          <Paper
            style={{
              marginLeft: "68px",
              marginTop: "44px",
              width: "80%",
              padding: "20px 12px 20px 12px",
              borderLeft: "2px solid  var(--stroke-color-2-tale, #9BDAE2)",
            }}
            className="refund_page"
          >
            <Typography className="refund_head2">Refund Types</Typography>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Typography
                className="refund_head"
                style={{ marginRight: "10px" }}
              >
                Allow Refunds
              </Typography>
              <Switch
                checked={formik.values.toggleState}
                name="toggleState"
                onChange={(e) => formik.handleChange(e)}
                color="warning"
              />
            </div>
            {formik.values.toggleState ? (
              <div>
                <Typography className="refund_para">
                  Allow refunds to your customers
                </Typography>
              </div>
            ) : (
              <div>
                <Typography className="refund_para">
                  Allow refunds to your customers
                </Typography>
              </div>
            )}
            {formik.values.toggleState && (
              <div
                style={{
                  marginTop: "12px",
                  marginLeft: "12px",
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  gap: "18px",
                }}
              >
                {/* <RadioGroup value={formik.values.selectedValue} name="selectedValue" onChange={(e) => formik.handleChange(e)} sx={{ display: "flex", gap: "24px", flexDirection: "row" }}>
                                    <FormControlLabel
                                        value="tax"
                                        control={<Radio color='warning' />}
                                        label={<Typography className='refund_check_text'>{t("settings.tax_only")}</Typography>}
                                        className='refund_rounding_box'
                                    />
                                    <FormControlLabel
                                        value="CustomAmount"
                                        control={<Radio color='warning' />}
                                        label={<Typography className='refund_check_text'>{t("settings.custom_amount")}</Typography>}
                                        className='refund_rounding_box'
                                    />
                                </RadioGroup> */}
                {checkBoxList.map((item) => (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="warning"
                          checked={item.isChecked}
                          onChange={(e) => {
                            const isChecked = e.target.checked;
                            setCheckBoxList((prevState) =>
                              prevState.map((list) => {
                                if (list.id === item.id) {
                                  return { ...list, isChecked: isChecked };
                                } else {
                                  return list;
                                }
                              })
                            );
                          }}
                        />
                      }
                      label={
                        <Typography className="refund_check_text">
                          {item.label}
                        </Typography>
                      }
                    />
                  </div>
                ))}
              </div>
            )}
          </Paper>
          <Paper
            style={{
              marginLeft: "68px",
              marginTop: "44px",
              width: "80%",
              padding: "8px 16px 8px 16px",
              borderLeft: "2px solid  var(--stroke-color-1-violet, #AE9BE2)",
            }}
            className="refund_page"
          >
            <div
              style={{
                padding: "16px 0px 16px 12px",
                display: "flex",
                alignItems: "center",
                width: "100%",
              }}
            >
              <div style={{ width: "50%", marginRight: "32px" }}>
                <Typography className="refund_head">
                  {t("settings.time_limit_refund")}
                </Typography>
                <Typography className="refund_para">
                  {" "}
                  {t("settings.limit_refund_para")}
                </Typography>
              </div>
              <div>
                <Typography
                  className="create_tax_exception"
                  style={{ marginBottom: "10px" }}
                >
                  {t("settings.enter_value")}
                </Typography>
                <div
                  style={{ display: "flex", width: "100%", height: "48px" }}
                  className="refund_input_border"
                >
                  <TextField
                    variant="standard"
                    value={formik.values.refundInputValue}
                    name="refundInputValue"
                    // onChange={(e) => formik.handleChange(e)}
                    onChange={(e) => {
                      const val = e.target.value;
                      if ((!val || /^[0-9]+$/.test(val)) && val.length <= 5) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    className="hide-number-input-icons create_advance_input"
                    placeholder="10"
                    InputProps={{
                      disableUnderline: true, // This removes the underline
                    }}
                    style={{ marginLeft: "10px", marginTop: "15px" }}
                  />
                  <FormControl
                    style={{
                      width: "40%",
                      marginBottom: "5px",
                      marginTop: "12px",
                      marginRight: "10px",
                    }}
                    className="create_advance_select"
                    variant="standard"
                  >
                    <Select
                      value={formik.values.refundSelectedOption}
                      name="refundSelectedOption"
                      onChange={(e) => formik.handleChange(e)}
                      style={{ marginLeft: "10px" }}
                    >
                      {options.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </div>
                <Box sx={{ textAlign: "right" }}>
                  <Typography sx={{ fontSize: "12px", color: "#707070" }}>
                    {formik.values.refundInputValue
                      ? formik.values.refundInputValue.toString().length
                      : 0}
                    /5
                  </Typography>
                </Box>
              </div>
            </div>
          </Paper>

          <Paper
            style={{
              marginLeft: "68px",
              marginTop: "44px",
              width: "80%",
              padding: "20px 12px 20px 12px",
              borderLeft: "2px solid  var(--stroke-color-3-green, #BAE29B)",
            }}
            className="refund_page"
          >
            <div style={{ width: "90%" }}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <Typography
                  className="refund_head2"
                  style={{ marginRight: "80px" }}
                >
                  {t("settings.show_refund")}
                </Typography>

                <Switch
                  checked={formik.values.showRefund}
                  name="showRefund"
                  onChange={(e) => formik.handleChange(e)}
                  color="warning"
                />
              </div>
              <Typography
                className="refund_head3"
                style={{ marginTop: "21px", marginBottom: "8px" }}
              >
                {t("settings.refund_reason")}
              </Typography>
              {/* <Typography className='refund_para'>{t("settings.reason_para")}</Typography> */}
              <Typography className="refund_para">
                The following are the refund reasons; you may customise and add
                more according to your restaurant's needs.
              </Typography>

              <div className="refund_accordion" style={{ width: "90%" }}>
                {formik.values.showRefund && (
                  <div style={{ width: "90%", padding: "20px 12px" }}>
                    {items.map((item) => (
                      <Accordion key={item.id}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                          <Typography
                            className="refund_head"
                            sx={{ textTransform: "capitalize" }}
                          >
                            {item.title}
                          </Typography>
                          {+item.id > 5 && (
                            <Box
                              sx={{
                                dispaly: "flex",
                                gap: "12px",
                                width: "80px",
                                position: "absolute",
                                top: 2,
                                right: 25,
                              }}
                            >
                              <IconButton
                                aria-label="edit"
                                onClick={() => handleEditRefundReason(item.id)}
                                // style={{ position: 'absolute', top: 0, right: 0, marginRight: '50px', marginTop: "6px" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                aria-label="delete"
                                onClick={() => handleDelete(item.id)}
                                // style={{ position: 'absolute', top: 0, right: 0, marginRight: '50px', marginTop: "6px" }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            </Box>
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Divider style={{ marginBottom: "12px" }} />
                          <ul>
                            {item.content.map((line, index) => (
                              <li
                                key={index}
                                className="refund_para"
                                style={{ marginLeft: "10px" }}
                              >
                                {line}
                              </li>
                            ))}
                          </ul>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                    {addValue && (
                      <div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginTop: "12px",
                          }}
                        >
                          <div style={{ display: "flex" }}>
                            <Box>
                              <TextField
                                style={{}}
                                label="Add Reason"
                                variant="outlined"
                                value={inputValue1}
                                onChange={(e) => setInputValue1(e.target.value)}
                              />

                              {items.map(
                                (item) =>
                                  item.title.toLowerCase().trim() ===
                                    inputValue1.toLowerCase().trim() &&
                                  item.id !== editId && (
                                    <Typography
                                      style={{
                                        color: "red",
                                        marginLeft: "10px",
                                        fontSize: "12px",
                                        marginBottom: "12px",
                                        marginTop: "8px",
                                      }}
                                    >
                                      Reason already exists
                                    </Typography>
                                  )
                              )}
                              {errorCondition === true &&
                                inputValue1 === "" && (
                                  <Typography
                                    style={{
                                      color: "red",
                                      marginLeft: "10px",
                                      fontSize: "12px",
                                      marginBottom: "12px",
                                      marginTop: "8px",
                                    }}
                                  >
                                    Requried
                                  </Typography>
                                )}
                            </Box>
                          </div>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start",
                            }}
                          >
                            <Button
                              onClick={handleCancelValue}
                              className="refund_cancel"
                              style={{
                                marginRight: "20px",
                                textTransform: "none",
                              }}
                            >
                              {t("settings.cancel")}
                            </Button>
                            <Button
                              onClick={handleSaveValue}
                              className={
                                items.some(
                                  (item) =>
                                    item.title.toLowerCase().trim() ===
                                      inputValue1.toLowerCase().trim() &&
                                    item.id !== editId
                                )
                                  ? "main-button-disable"
                                  : "refund_save"
                              }
                              style={{
                                marginRight: "20px",
                                textTransform: "none",
                              }}
                              disabled={items.some(
                                (item) =>
                                  item.title.toLowerCase().trim() ===
                                    inputValue1.toLowerCase().trim() &&
                                  item.id !== editId
                              )}
                            >
                              {t("settings.add1")}
                            </Button>{" "}
                          </div>
                        </div>
                        <Divider style={{ marginBottom: "12px" }} />
                        {/* <TextareaAutosize
                                                style={{ marginBottom: '20px', width: '100%', padding: '10px', border: '1px solid #ccc', borderRadius: '4px' }}
                                                minRows={3}
                                                placeholder="Description supporting reason"
                                                value={inputValue2}
                                                onChange={handleTextareaChange}
                                            /> */}

                        <div className="textarea-box" style={{ width: "100%" }}>
                          <textarea
                            id="textarea"
                            onChange={(e) => {
                              updateCharCount(e);
                              handleTextareaChange(e);
                            }}
                            style={{ width: "100%" }}
                            name="description"
                            placeholder="Description supporting reason"
                          >
                            {inputValue2}
                          </textarea>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            {errorCondition === true && inputValue2 === "" && (
                              <Typography
                                style={{
                                  color: "red",
                                  marginLeft: "10px",
                                  fontSize: "12px",
                                  marginBottom: "12px",
                                  width: "50%",
                                }}
                              >
                                Description required
                              </Typography>
                            )}
                            <div id="charCount" className="char-count">
                              {charCount}/500
                            </div>
                          </Box>
                        </div>
                      </div>
                    )}

                    {!addValue && (
                      <Button
                        onClick={handleAddValue}
                        className="refund_reason"
                        style={{ marginTop: "10px" }}
                      >
                        Add Reason
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </Paper>

          <Paper
            style={{
              marginLeft: "68px",
              marginTop: "44px",
              width: "80%",
              padding: "20px 12px 20px 12px",
              borderLeft: "2px solid   var(--stroke-color-4-brown, #E2A39B)",
            }}
            className="refund_page"
          >
            <div style={{ width: "100%" }}>
              <FormControl component="fieldset" sx={{ width: "100%" }}>
                <RadioGroup
                  aria-label="options"
                  value={formik.values.cancelSelectedValue}
                  name="cancelSelectedValue"
                  onChange={(e) => formik.handleChange(e)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    marginLeft: "10px",
                    width: "100%",
                  }}
                >
                  <FormControlLabel
                    value="option1"
                    control={<Radio color="warning" />}
                    label={
                      <div>
                        <Typography className="refund_head">
                          {t("settings.cancel_policy")}
                        </Typography>
                        <Typography className="refund_para">
                          {t("settings.set_quote")}
                        </Typography>
                      </div>
                    }
                    className="refund_radio"
                  />
                  <FormControlLabel
                    value="option2"
                    control={<Radio color="warning" />}
                    label={
                      <div>
                        <Typography className="refund_head">
                          {t("settings.accept_cancelation")}
                        </Typography>
                        <Typography className="refund_para">
                          {t("settings.before_delivery")}
                        </Typography>
                      </div>
                    }
                    className="refund_radio"
                    style={{ marginLeft: "20px" }}
                  />
                </RadioGroup>
              </FormControl>

              {formik.values.cancelSelectedValue === "option1" ? (
                <div style={{ marginTop: "20px", width: "90%" }}>
                  <TableContainer component={Paper} sx={{ padding: "12px" }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell className="refund_head4" align="left">
                            Cancellation Period
                          </TableCell>
                          <TableCell className="refund_head4" align="left">
                            Dine-In
                          </TableCell>
                          <TableCell className="refund_head4" align="left">
                            Delivery
                          </TableCell>
                          <TableCell className="refund_head4" align="left">
                            Collection
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {flow.map((status) => (
                          <TableRow key={status.id}>
                            <TableCell className="refund_head5">
                              {status.label}
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ width: "70%" }}>
                                <Select
                                  value={status.dine_in}
                                  onChange={(event) =>
                                    handleDropdownChange(
                                      status.id,
                                      "dine_in",
                                      event.target.value
                                    )
                                  }
                                >
                                  <MenuItem value="allow">Allow</MenuItem>
                                  <MenuItem value="not_allow">
                                    Not Allow
                                  </MenuItem>
                                  {/* <MenuItem value="na">N/A</MenuItem> */}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ width: "70%" }}>
                                <Select
                                  value={status.delivery}
                                  onChange={(event) =>
                                    handleDropdownChange(
                                      status.id,
                                      "delivery",
                                      event.target.value
                                    )
                                  }
                                >
                                  <MenuItem value="allow">Allow</MenuItem>
                                  <MenuItem value="not_allow">
                                    Not Allow
                                  </MenuItem>
                                  {/* <MenuItem value="na">N/A</MenuItem> */}
                                </Select>
                              </FormControl>
                            </TableCell>
                            <TableCell>
                              <FormControl sx={{ width: "70%" }}>
                                <Select
                                  value={status.collection}
                                  onChange={(event) =>
                                    handleDropdownChange(
                                      status.id,
                                      "collection",
                                      event.target.value
                                    )
                                  }
                                >
                                  <MenuItem value="allow">Allow</MenuItem>
                                  <MenuItem value="not_allow">
                                    Not Allow
                                  </MenuItem>
                                  {/* <MenuItem value="na">N/A</MenuItem> */}
                                </Select>
                              </FormControl>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              ) : (
                <div>
                  <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop: "10px",
                      }}
                    >
                      <div className="sales_head_center">
                        <Typography className="refund_head4">
                          {t("settings.cancelation_para")}
                        </Typography>
                      </div>
                      <Box>
                        <div
                          style={{ display: "flex", width: "254px" }}
                          className="refund_input_border"
                        >
                          <TextField
                            // label={<Typography className='create_label'>{t("settings.enter_value")}</Typography>}
                            variant="standard"
                            value={formik.values.cancelTime}
                            name="cancelTime"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            placeholder="10"
                            type="text"
                            sx={{ mt: "15px", ml: "10px", width: "120px" }}
                            className="hide-number-input-icons create_advance_input"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                          />
                          <FormControl
                            style={{ width: "220px", marginTop: "16px" }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.acceptCancel}
                              name="acceptCancel"
                              onChange={(e) => formik.handleChange(e)}
                              style={{ marginLeft: "10px" }}
                              disableUnderline={true}
                            >
                              <MenuItem value="Minute">
                                {t("settings.minutes")}
                              </MenuItem>
                              <MenuItem value="Hours">
                                {t("settings.hours")}
                              </MenuItem>
                              <MenuItem value="Days">
                                {t("settings.days")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.cancelTime
                              ? formik.values.cancelTime.toString().length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="sales_head_center">
                        <Typography className="refund_head4">
                          {t("settings.before_dine_in")}
                        </Typography>
                      </div>
                      <Box>
                        <div
                          style={{ display: "flex", width: "254px" }}
                          className="refund_input_border"
                        >
                          <TextField
                            // label={<Typography className='create_label'>{t("settings.enter_value")}</Typography>}
                            variant="standard"
                            value={formik.values.cancelTime1}
                            name="cancelTime1"
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            sx={{ mt: "15px", ml: "10px", width: "120px" }}
                            placeholder="10"
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                          />
                          <FormControl
                            style={{ width: "220px", marginTop: "16px" }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.acceptCancel1}
                              name="acceptCancel1"
                              onChange={(e) => formik.handleChange(e)}
                              style={{ marginLeft: "10px" }}
                              disableUnderline={true}
                            >
                              <MenuItem value="Minute">
                                {t("settings.minutes")}
                              </MenuItem>
                              <MenuItem value="Hours">
                                {t("settings.hours")}
                              </MenuItem>
                              <MenuItem value="Days">
                                {t("settings.days")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.cancelTime1
                              ? formik.values.cancelTime1.toString().length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                  <div style={{ marginLeft: "20px", marginBottom: "10px" }}>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginBottom: "10px",
                      }}
                    >
                      <div className="sales_head_center">
                        <Typography className="refund_head4">
                          No. of hours/Minutes before the Collection to accept
                          cancellation
                        </Typography>
                      </div>
                      <Box>
                        <div
                          style={{ display: "flex", width: "254px" }}
                          className="refund_input_border"
                        >
                          <TextField
                            // label={<Typography className='create_label'>{t("settings.enter_value")}</Typography>}
                            variant="standard"
                            value={formik.values.cancelTime2}
                            name="cancelTime2"
                            sx={{ mt: "15px", ml: "10px", width: "120px" }}
                            onChange={(e) => {
                              const val = e.target.value;
                              if (
                                (!val || /^[0-9]+$/.test(val)) &&
                                val.length <= 5
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            type="text"
                            className="hide-number-input-icons create_advance_input"
                            placeholder="10"
                            InputProps={{
                              disableUnderline: true, // This removes the underline
                            }}
                          />
                          <FormControl
                            style={{ width: "220px", marginTop: "16px" }}
                            className="create_advance_select"
                            variant="standard"
                          >
                            <Select
                              value={formik.values.acceptCancel2}
                              name="acceptCancel2"
                              onChange={(e) => formik.handleChange(e)}
                              style={{ marginLeft: "10px" }}
                              disableUnderline={true}
                            >
                              <MenuItem value="Minute">
                                {t("settings.minutes")}
                              </MenuItem>
                              <MenuItem value="Hours">
                                {t("settings.hours")}
                              </MenuItem>
                              <MenuItem value="Days">
                                {t("settings.days")}
                              </MenuItem>
                            </Select>
                          </FormControl>
                        </div>
                        <Box sx={{ textAlign: "right" }}>
                          <Typography
                            sx={{ fontSize: "12px", color: "#707070" }}
                          >
                            {formik.values.cancelTime2
                              ? formik.values.cancelTime2.toString().length
                              : 0}
                            /5
                          </Typography>
                        </Box>
                      </Box>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </Paper>
        </Paper>
      </form>
    </div>
  );
};

export default RefundCancel;
