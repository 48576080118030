import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SuccessImg from "../../assets/menuQr/sucessImg.png"
import Image from "../../assets/menuQr/getstartedimg.png"
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

interface Props {
    finalModal: boolean;
    setFinalModal: any
}

export default function DomainFinalModal(props: Props) {
    const { finalModal, setFinalModal } = props
    const { t } = useTranslation()
    const handleClose = () => setFinalModal(false);
    const navigate = useNavigate()

    return (
        <div>

            <Modal
                open={finalModal}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box >
                        <IconButton className="exit-container" onClick={handleClose}>
                            <CloseIcon className="exitIcon" />
                        </IconButton>
                    </Box>
                    <Divider sx={{ marginTop: '12px' }} />
                    <Box sx={{ display: "flex", justifyContent: "center", alignItems: 'center', width: "100%", marginTop: '24px' }}>
                        <Box sx={{ display: 'flex', gap: '12px', alignItems: "center", width: "70%" }}>
                            <img src={SuccessImg} alt="success" />
                            <Typography className='odering-text'>{t("online_ordering.live")}</Typography>
                        </Box>
                    </Box>
                    <Box sx={{ textAlign: 'center', marginTop: "24px", marginBottom: "24px" }}>
                        <img src={Image} alt="main-img" style={{ width: '70%' }} />
                    </Box>
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        <Button className="main-button" >{t("online_ordering.preview")}<ArrowOutwardIcon /> </Button>
                        <Button className="main-button" onClick={() => { navigate("/online/order-settings") }} sx={{ ml: '20px' }}>{t("online_ordering.domain_listing")}</Button>

                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
