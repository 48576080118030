import {
  Box,
  Button,
  Drawer,
  IconButton,
  Select,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useFormik } from "formik";
import * as Yup from "yup";
import styled from "styled-components";
import Entries from "./entries";
import CircularLoader from "../../../../loader/loader";
import { useMutation, useQuery } from "@apollo/client";
import {
  EMPLOYEE_ATTENDANCE_LIST,
  EMPLOYEE_DETAILS,
} from "../../../../graphicalQl/usequery/customerDataQuery";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import moment from "moment";
import {
  DELETE_ATTENDANCE_RECORD,
  ADD_LOG_ENTRY,
  EDIT_LOG_ENTRY,
} from "../../../../graphicalQl/mutation/customerDataMutation";
import BasicRangeShortcuts from "../../../reports/customers/filters/dateRangeFilter";
import { useSelector } from "react-redux";
import { localeToFormat } from "../../../../utils/dateFormat";
import AutoCompleteDropdown from "../../../../pages/popups/autoCompleteDropdown";
import { toast } from "react-toastify";

const DrawerStyle = styled(Drawer)({
  // "& .MuiBackdrop-root": {
  //   top: "unset",
  //   height: "77%",
  // },
  // "& .MuiPaper-root": {
  //   bottom: "0",
  //   top: "unset",
  //   height: "77%",
  // },
});
export interface DateType {
  startDate: string;
  endDate: string;
}

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    color: "#333333",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    background: "#f5f5f5",
  },
  "& .MuiDataGrid-cell": {
    color: "#333333",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
  },
  "&.MuiDataGrid-columnHeaderTitle": {
    fontWeight: "500",
  },
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
    outline: "solid #1976d2 0px !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "solid #1976d2 0px !important",
  },

  // marginLeft: "20px",
  ".MuiTablePagination-displayedRows": {
    display: "none", // 👈 to hide huge pagination number
  },
}));

const LogTimeEntries = () => {
  const { t } = useTranslation();
  const [timeLogEntries, setTimeLogEntries] = useState([]);
  const [employeeList, setEmployeeList] = useState<any>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });

  const businessId = sessionStorage.getItem("businessId");
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState<string | null>(null);

  const [categoryIdForAdding, setCategoryIdForAdding] = useState<string | null>(
    null
  );

  const validationSchemaPart1 = Yup.object({
    fromTime: Yup.string().required(" Requried"),
    employeeName: Yup.string().required("Requried"),
    date: Yup.string().required("Requried"),
    toTime: Yup.string()
      .test(
        "is-greater",
        "End time must be greater than start time",
        function (value) {
          const { fromTime } = this.parent;

          if (fromTime && value) {
            return (
              moment(value, "hh:mm a").format("HH:mm") >
              moment(fromTime, "hh:mm a").format("HH:mm")
            );
          }

          return true;
        }
      )
      .required("Time Requried"),
  });

  const initialValuesOfTextFields = {
    attendanceId: null,
    employeeName: "",
    date: "",
    fromTime: "",
    toTime: "",
    note: "",
    employeeId: null,
    state: false,
    employeeRole: null,
    employee: null,
  };

  const formik = useFormik({
    initialValues: initialValuesOfTextFields,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleFormSubmit(values);
    },
  });

  const { data, loading, error, refetch } = useQuery(EMPLOYEE_ATTENDANCE_LIST, {
    variables: {
      businessId: businessId && +businessId,
      locationId: +locationId,
      employeeId: categoryId ? parseInt(categoryId) : null,
      fromDate: date.startDate,
      toDate: date.endDate,
    },
    context: { clientName: "userClient" },
    fetchPolicy: "network-only",
  });

  // query to fetch  all employees
  const {
    data: employeeDetails,
    loading: employeeDetailsLoading,
    error: employeeDetailsError,
  } = useQuery(EMPLOYEE_DETAILS, {
    variables: {
      businessId: businessId && +businessId,
      locationId: +locationId,
    },
    context: { clientName: "userClient" },
    fetchPolicy: "network-only",
  });

  // mutation to delete attendace instances
  const [
    deleteAttendaceMutation,
    { data: deleteAttendaceMutationData, error: deleteAttendaceMutationError },
  ] = useMutation(DELETE_ATTENDANCE_RECORD, {
    context: { clientName: "userClient" },
  });

  const handleCategory = (newData: any) => {
    if (newData) {
      const { id, label } = newData;
      setCategoryId(id);
      setCategoryName(label);
    } else {
      setCategoryId(null);
      setCategoryName(null);
    }
  };

  useEffect(() => {
    if (deleteAttendaceMutationData?.deleteEmployeeAttendance.success) {
      refetch();
    }
  }, [deleteAttendaceMutationData]);

  useEffect(() => {
    if (employeeDetails) {
      const parsedData = employeeDetails?.employees?.edges.map((each: any) => ({
        id: each.node?.employeeId,
        label: each.node?.firstName + " " + each.node?.lastName,
      }));

      setEmployeeList(parsedData);
    }
  }, [employeeDetails]);
  const userLang = navigator.language;

  useEffect(() => {
    if (data) {
      const { employeeAttendanceList } = data;
      if (employeeAttendanceList) {
        const { edges } = employeeAttendanceList;

        if (edges) {
          const filteredData = edges?.map((each: any) => ({
            id: each.node.attendanceId,
            employeeId: each.node.employee.employeeId,
            employee:
              each.node.employee.firstName + " " + each.node.employee.lastName,
            employeeRole: each.node.employee.role.roleName,
            date: each.node.dateOf,
            clockIn: each.node.clockIn,
            clockOut: each.node.clockOut,
            breaks: 5,
            note: each.node.note,
            totalHours: each.node.totalHours,
          }));

          setTimeLogEntries(filteredData);
        }
      }
    }
  }, [data]);

  const [dateDropdownLabel, setDateDropdownLabel] = useState<string>("");

  const handleEditIcon = (data: any) => {
    formik.setFieldValue("attendanceId", data.id);
    formik.setFieldValue("date", data.date);
    if (data.clockIn) {
      formik.setFieldValue("fromTime", moment(data.clockIn).format("hh:mm"));
    } else {
      formik.setFieldValue("fromTime", "");
    }

    if (data.clockOut) {
      formik.setFieldValue("toTime", moment(data.clockOut).format("hh:mm"));
    } else {
      formik.setFieldValue("toTime", "");
    }

    // formik.setFieldValue("fromTime", data.clockIn);
    // formik.setFieldValue("toTime", data.clockOut);
    formik.setFieldValue("employeeId", data.employeeId);
    formik.setFieldValue("employeeName", data.employee);
    setCategoryIdForAdding(data.employeeId);
    formik.setFieldValue("note", data.note);
    formik.setFieldValue("employeeId", data.id);
    formik.setFieldValue("state", true);
    formik.setFieldValue("employeeRole", data.employeeRole);
    formik.setFieldValue("employee", data.employee);
  };

  const [
    addLogEntryMutation,
    { data: addLogEntryMutationData, error: addLogEntryMutationError },
  ] = useMutation(ADD_LOG_ENTRY, {
    context: { clientName: "userClient" },
    refetchQueries: [EMPLOYEE_ATTENDANCE_LIST],
    onCompleted: (data) => {
      if (data) {
        const { employeeClockIn } = data;
        if (employeeClockIn) {
          const { success } = employeeClockIn;
          if (success) {
            formik.resetForm();
            toast.success(
              <div>
                <Typography>{t("toast.added")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
  });

  const [
    editLogEntryMutation,
    { data: editLogEntryMutationData, error: editLogEntryMutationError },
  ] = useMutation(EDIT_LOG_ENTRY, {
    context: { clientName: "userClient" },
    onCompleted: (data) => {
      if (data) {
        const { editEmployeeClockin } = data;
        if (editEmployeeClockin) {
          const { success } = editEmployeeClockin;
          if (success) {
            formik.resetForm();
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
  });

  const handleFormSubmit = (values: any) => {
    if (formik.values.attendanceId !== null) {
      const variables = {
        attendanceId: parseInt(formik.values.attendanceId),
        clockinTime: dayjs.isDayjs(formik.values.fromTime)
          ? moment(formik.values.fromTime, "h:mm A").format("HH:mm")
          : moment(formik.values.fromTime, "h:mm A").format("HH:mm"),
        clockoutTime: dayjs.isDayjs(values.toTime)
          ? moment(formik.values.toTime, "h:mm A").format("HH:mm")
          : moment(formik.values.toTime, "h:mm A").format("HH:mm"),
        dateOf: formik.values.date
          ? dayjs(formik.values.date).format("YYYY-MM-DD")
          : null,
        note: formik.values.note,
      };

      const filterdVariables = Object.fromEntries(
        Object.entries(variables).filter(([_, value]) => value !== null)
      );

      editLogEntryMutation({
        variables: filterdVariables,
      });
    } else {
      const createvariable = {
        businessId: businessId && +businessId,
        employeeId: formik.values.employeeId ? +formik.values.employeeId : null,
        clockinTime: formik.values.fromTime,
        clockoutTime: formik.values.toTime,

        dateOf: formik.values.date
          ? dayjs(formik.values.date).format("YYYY-MM-DD")
          : null,
        note: formik.values.note,
        locationId: +locationId,
      };

      const filterdVariables = Object.fromEntries(
        Object.entries(createvariable).filter(([_, value]) => value !== null)
      );

      addLogEntryMutation({
        variables: filterdVariables,
      });
    }
  };

  // column definition for  Data Grid component
  const columns: GridColDef[] = [
    {
      field: "employee",
      //@ts-ignore
      headerName: t("log_tome.employee"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => (
        <Typography className="dataGrid-table-row">{params.value}</Typography>
      ),
    },
    {
      field: "date",
      //@ts-ignore
      headerName: t("log_tome.date"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => {
        // Ensure params.value is defined and is a date object
        return (
          <Typography>
            {params.value
              ? moment(params.value).format(`${localeToFormat[userLang]}`)
              : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "clockIn",
      //@ts-ignore
      headerName: t("log_tome.clock_in"),
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
      renderCell: (params) => {
        // Ensure params.value is defined and is a date object
        return (
          <Typography>
            {params.value ? moment(params.value).format("hh:mm A") : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "clockOut",
      //@ts-ignore
      headerName: t("log_tome.clock_out"),
      flex: 1,
      headerAlign: "center",
      sortable: false,
      align: "center",
      filterable: false,
      renderCell: (params) => {
        // Ensure params.value is defined and is a date object
        return (
          <Typography>
            {params.value ? moment(params.value).format("hh:mm A") : "N/A"}
          </Typography>
        );
      },
    },
    // {
    //   field: "breaks",
    //   //@ts-ignore
    //   headerName: t("log_tome.breaks"),
    //   headerAlign: "center",
    //   sortable: false,
    //   filterable: false,
    //   align: "center",
    //   flex: 1,
    // },
    {
      field: "totalHours",
      headerAlign: "center",
      //@ts-ignore
      headerName: t("log_tome.total_hours"),
      sortable: false,
      filterable: false,
      align: "center",
      flex: 1,
    },
    {
      field: "actions",
      type: "actions",
      headerName: t("buttons.actions") || "",
      flex: 0.5,
      renderCell: (params) => (
        <Box>
          <IconButton
            onClick={
              () => handleEditIcon(params.row)
              // () =>
              //   setAddEntrySidePanel({
              //     state: true,
              //     mode: "edit",
              //     data: {
              //       ...params.row,
              //       deleteAttendanceEntry,
              //       employeeList,
              //       refetchLogEntries: refetch,
              //     },
              //   })
              // setFromTime(params.row.clockIn)
              // setToTime(params.row.clockOut)
            }
          >
            <EditIcon />
          </IconButton>
        </Box>
      ),
      // getActions: (params) => [
      //   <GridActionsCellItem
      //     label="Edit"
      //     onClick={
      //       () =>
      //         setAddEntrySidePanel({
      //           state: true,
      //           mode: "edit",
      //           data: {
      //             ...params.row,
      //             deleteAttendanceEntry,
      //             employeeList,
      //             refetchLogEntries: refetch,
      //           },
      //         })
      //       // setFromTime(params.row.clockIn)
      //       // setToTime(params.row.clockOut)
      //     }
      //     showInMenu
      //     sx={{
      //       "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
      //         backgroundColor: "#FFF8F0",
      //       },
      //     }}
      //   />,
      //   <GridActionsCellItem
      //     label="Delete"
      //     onClick={() => deleteAttendanceEntry(parseInt(params.row.id))}
      //     showInMenu
      //     sx={{
      //       "&.MuiButtonBase-root.MuiMenuItem-root:hover": {
      //         backgroundColor: "#FFF8F0",
      //       },
      //     }}
      //   />,
      // ],
    },
  ];
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell-less-wt";
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          overflow: "hidden",
          overflowX: "auto",
          width: { xs: "100%", md: "100%" },
          boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
        }}
      >
        <Typography
          sx={{
            paddingLeft: "24px",
            marginTop: "24px",
            fontFamily: "poppins",
            fontSize: "18px",
            fontWeight: "600",
            color: "#333",
            paddingBottom: "17px",
          }}
        >
          {t("log_tome.log_time_entries")}
        </Typography>
        <Box
          sx={{
            height: "67px",
            paddingX: "21px",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "40%",
              gap: "20px",
            }}
          >
            {/* <Select
              value={employeeSearch}
              label="Select Employee"
              onChange={(event: SelectChangeEvent) =>
                setEmployeeSearch(event.target.value as string)
              }
              displayEmpty
              sx={{
                fontSize: "14px",
                width: "180px",
                height: "40px",
                marginTop: "8px",
                background: "white",
                color: "#ABABAB",
              }}
              input={<OutlinedInput />}
            // renderValue={(value) => {
            //   return value !== ""
            //     ? employeeList.find((employeeId: any) => employeeId === value).name
            //     : "Select an employee";
            // }}
            >
              {employeeList?.map((each: any) => (
                <MenuItem value={each.employeeId} id={each.employeeId}>
                  {each.name}
                </MenuItem>
              ))}
            </Select> */}

            <AutoCompleteDropdown
              handleChange={handleCategory}
              data={employeeList}
              width="50%"
              label={t("log_tome.search_by_employee")}
              value={{ id: categoryId || "", label: categoryName || "" }} // Correct structure
            />

            {/* <Autocomplete
              id="combo-box-demo"
              options={employeeList}
              getOptionLabel={(option: any) =>
                capitalizeFirstLetter(option.name.toString())
              }
              sx={{ width: "50%" }}
              // onChange={handleChangeFilter}
              onChange={(event, newValue) => {
                setEmployeeSearch(newValue?.employeeId.toString() || "");
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("log_tome.search_by_employee")}
                />
              )}
            /> */}

            <Select
              displayEmpty
              sx={{
                width: dateDropdownLabel.length > 10 ? 260 : 250,
                height: "52px",
                marginRight: "30px",
              }}
              value={dateDropdownLabel}
              renderValue={(selected) => (selected ? selected : "Date")}
            >
              <BasicRangeShortcuts
                setDate={setDate}
                date={date}
                setDateDropdownLabel={setDateDropdownLabel}
              />
            </Select>
          </Box>

          <Button
            onClick={() => {
              formik.setFieldValue("state", true);
            }}
            sx={{
              width: "173px",
              height: "44px",
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
                textTransform: "capitalize",
              }}
            >
              + {t("log_tome.add_entry")}
            </Typography>
          </Button>
        </Box>
        <div
          style={{
            height: "65vh",
            width: "100%",
            padding: "10px 20px 10px 20px",
          }}
        >
          {loading ? (
            <CircularLoader />
          ) : (
            <StyledDataGrid
              rows={timeLogEntries}
              columns={columns}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 25, page: 0 },
                },
              }}
              disableColumnMenu
              getRowClassName={handleRowClass}
              getCellClassName={handleCellClass}
            />
          )}
        </div>
      </Box>
      <DrawerStyle
        anchor="right"
        open={formik.values.state}
        onClose={() => {
          formik.resetForm();
          setCategoryIdForAdding(null);
        }}
      >
        <form onSubmit={formik.handleSubmit}>
          <Entries
            categoryIdForAdding={categoryIdForAdding}
            employeeList={employeeList}
            formik={formik}
            setCategoryIdForAdding={setCategoryIdForAdding}
          />
        </form>
      </DrawerStyle>
    </React.Fragment>
  );
};

export default LogTimeEntries;
