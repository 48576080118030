import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import "./customers.css";
import { useQuery } from "@apollo/client";
import { CUSTOMER_DASHBOARD } from "../../graphicalQl/usequery/categoryListQuery";
import { CUSTOMER_ORDER_DASHBOARD } from "../../graphicalQl/usequery/categoryListQuery";
import { CUSTOMER_ORDER_HISTORY } from "../../graphicalQl/usequery/customerDataQuery";
import OrderHistoryTable from "./orderHistoryDataTable";
import CurrencyDisplay from "../utils/currencySymbol";
import { useSelector } from "react-redux";
interface Props {
  id: string | undefined;
  uuid: string | null;
}
export default function CustomerTabs({ id, uuid }: Props) {
  const [value, setValue] = React.useState("1");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            borderBottom: "2px solid #F38B08",
          },
        },
      },
    },
  });

  const { locationId } = useSelector((state: any) => state.headerLocation);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { data: dashboardData } = useQuery(CUSTOMER_DASHBOARD, {
    context: { clientName: "customerClient" },
    variables: { customerId: id && +id, storeId: locationId && +locationId },
  });
  const [loyalityData, setLoyalityData] = React.useState<any>({});
  React.useEffect(() => {
    if (dashboardData && dashboardData.customerDashboard) {
      setLoyalityData(JSON.parse(dashboardData.customerDashboard));
    }
  }, [dashboardData]);

  const { data: OrderData } = useQuery(CUSTOMER_ORDER_DASHBOARD, {
    context: { clientName: "orderClient" },
    variables: {
      customerId: uuid && uuid,
      storeId: locationId && +locationId,
      businessId: +businessId,
    },
  });
  const [orderList, setOrderList] = React.useState<any>({});
  React.useEffect(() => {
    if (OrderData && OrderData.customerOrdersDashboard) {
      setOrderList(JSON.parse(OrderData.customerOrdersDashboard));
    }
  }, [OrderData]);

  const { data: customerOrderHistory } = useQuery(CUSTOMER_ORDER_HISTORY, {
    context: { clientName: "orderClient" },
    variables: {
      customerId: uuid && uuid,
      storeId: locationId && +locationId,
      businessId: +businessId,
    },
  });

  const [ordersHistory, setOrdersHistory] = React.useState<any>([]);

  React.useEffect(() => {
    if (customerOrderHistory && customerOrderHistory.orders) {
      const list = customerOrderHistory.orders.edges.map((each: any) => ({
        date: each.node.createdOn,
        orderId: each.node.orderNo,
        total: each.node.totalAmount,
        orderType: each.node.orderType,
        orderStatus: each.node.orderStatus,
        items: each.node.cart?.cartItems
          ? JSON.parse(each.node.cart.cartItems).map((item: any) => ({
            product: item.product_name,
            price: item.quantity,
          }))
          : [],
      }));
      setOrdersHistory(list);
    }
  }, [customerOrderHistory]);

  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <ThemeProvider theme={theme}>
        <Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
                backgroundColor: "#F9F9F9",
                border: "1px solid #EEEEEE",
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  label="Dashboard"
                  value="1"
                  className={
                    value === "1"
                      ? "create-product-change_color"
                      : "create-product-add_color"
                  }
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Order History"
                  value="2"
                  className={
                    value === "2"
                      ? "create-product-change_color"
                      : "create-product-add_color"
                  }
                  sx={{ textTransform: "none" }}
                />
                <Tab
                  label="Loyalty Points"
                  value="3"
                  className={
                    value === "3"
                      ? "create-product-change_color"
                      : "create-product-add_color"
                  }
                  sx={{ textTransform: "none" }}
                />
              </TabList>
            </Box>
            <TabPanel
              value="1"
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "20px 3px 20px 3px",
                gap: "20px",
                border: "1px solid #EEEEEE",
              }}
            >
              <Box>
                <div className="tab tabFlex">
                  <div className="tabCard yellow">
                    <h2>{orderList.total_orders || 0} </h2>
                    <p>Total Orders</p>
                  </div>
                  <div className="tabCard green">
                    <CurrencyDisplay
                      countryName={"UnitedKingdom"}
                      amount={orderList.total_spent || 0}
                    />

                    {/* <h2>{orderList.total_spent || 0}</h2> */}
                    <p>Total Spent</p>
                  </div>
                  <div className="tabCard yellow">
                    {/* <h2>{orderList.total_tips || 0}</h2> */}
                    <CurrencyDisplay
                      countryName={"UnitedKingdom"}
                      amount={orderList.total_tips || 0}
                    />

                    <p>Total Tips</p>
                  </div>
                  <div className="tabCard green">
                    <h2>{orderList.points_spent || 0}</h2>

                    <p>Points spent</p>
                  </div>
                  {/* <div className="tabCard yellow">
                                        <h2>1152</h2>
                                        <p>Loyalty Points</p>
                                    </div> */}
                </div>
              </Box>
            </TabPanel>
            <TabPanel
              value="2"
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "20px 3px 20px 3px",
                gap: "20px",
                border: "1px solid #EEEEEE",
              }}
            >
              <OrderHistoryTable ordersHistory={ordersHistory} />
            </TabPanel>
            <TabPanel
              value="3"
              sx={{
                backgroundColor: "#FFFFFF",
                padding: "20px 3px 20px 3px",
                gap: "20px",
                border: "1px solid #EEEEEE",
              }}
            >
              <Box>
                <div className="tab tabFlex">
                  <div className="tabCard yellow">
                    <h2>{loyalityData.available_points || 0}</h2>
                    <p>Available Points</p>
                  </div>
                  <div className="tabCard green">
                    <h2>{loyalityData.life_time_points || 0}</h2>
                    <p>Life Time Points</p>
                  </div>
                  <div className="tabCard yellow">
                    <h2>{loyalityData.redeemed_points || 0}</h2>
                    <p>Redeemed Points</p>
                  </div>
                  <div className="tabCard green">
                    <h2>{loyalityData.redemptions || 0}</h2>
                    <p>Redemptions</p>
                  </div>
                  <div className="tabCard yellow">
                    <h2>{loyalityData.last_redemption || 0}</h2>
                    <p>Last Redemption</p>
                  </div>
                  {/* <div className="tabCard green">
                                        <h2>607</h2>
                                        <p>Qualified Rewards</p>
                                    </div> */}
                </div>
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </ThemeProvider>
    </Box>
  );
}
