import React, { useContext, useState, useEffect } from "react";
import {
  GoogleMap,
  MarkerF,
  CircleF,
  useJsApiLoader,
} from "@react-google-maps/api";
import UseLocationData from "../../context/UseLocationData";
import mapDummyImage from "../../assets/mapDummyImage.png";
import { Box, Button, Typography } from "@mui/material";
import "./googleMaps.css";
import { useTranslation } from "react-i18next";
const containerStyle = {
  height: "395px",
  width: "100%",
};

interface Location {
  lat: number;
  lng: number;
}

interface RenderGoogleMapsProps {
  rows: any;
  setRows: React.Dispatch<React.SetStateAction<any[]>>;
  storeLocation: Location | null;
  locationKey: string | null;
}

const RenderGoogleMap = ({
  rows,
  setRows,
  locationKey,
  storeLocation,
}: RenderGoogleMapsProps) => {
  const { t } = useTranslation();
  const [updatedRows, setUpdatedRows] = useState<any[]>([]);
  useEffect(() => {
    setUpdatedRows(rows);
  }, [rows]);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: locationKey !== null ? locationKey : "",
  });

  const calculateZoomLevel = (maxDistance: number) => {
    const WORLD_DIM = { height: 250, width: 256 };
    const ZOOM_MAX = 15;

    const metersPerPixel = maxDistance / WORLD_DIM.width;
    const zoom = Math.floor(
      Math.log2((1609.34 * WORLD_DIM.width) / metersPerPixel)
    );

    return Math.min(zoom, ZOOM_MAX);
  };

  // const calculateZoomLevel = (meters: any, numRows: number) => {
  //   const MAX_ZOOM = 14; // Maximum available zoom level
  //   const MIN_ZOOM = 0; // Minimum available zoom level
  //   // Adjust the divisor based on the number of rows with a positive multiplier
  //   // const zoom = MAX_ZOOM - Math.floor(Math.log2(meters / (50 + numRows * 10)));
  //   // Alternatively, you can add a constant to increase the zoom level
  //   const zoom = MAX_ZOOM - numRows / 2;
  //   // return Math.max(Math.min(zoom, MAX_ZOOM), MIN_ZOOM);
  //   return zoom;
  // };

  // const fetchPostcodesForCircle = async (circleCenter: Location, circleRadius: number) => {
  //   if (storeLocation === null) {
  //     console.error('Current location is null');
  //     return [];
  //   }

  //   const nearby = zipcodesNearby.near({ longitude: circleCenter.lng, latitude: circleCenter.lat }, circleRadius);
  //   const uniquePostcodesSet = new Set();

  //   const postcodesPromises = rows
  //     .filter((row: any) => {
  //       const rowZipcode = row.zipcode; // Assuming your row object has a property for the zipcode
  //       return nearby.includes(rowZipcode);
  //     })
  //     .map((row: any) =>
  //       new Promise<any>((resolve) => {
  //         resolve({ postal_code: row.zipcode }); // Using the row's zipcode directly
  //       })
  //     );

  //   const geocodingResults = await Promise.all(postcodesPromises);

  //   geocodingResults.forEach((result: any) => {
  //     if (result.postal_code) {
  //       uniquePostcodesSet.add(result.postal_code);
  //     }
  //   });

  //   const uniquePostcodes = Array.from(uniquePostcodesSet);

  //   return uniquePostcodes;
  // };

  const fetchPostcodesForCircle = async (
    circleCenter: Location,
    circleRadius: number
  ) => {
    const geocoder = new window.google.maps.Geocoder();
    if (storeLocation === null) {
      return [];
    }

    const uniquePostcodesSet = new Set();

    const postcodesPromises = updatedRows
      .filter((row: any) => {
        const distanceBetweenCircleAndRow =
          window.google.maps.geometry.spherical.computeDistanceBetween(
            new window.google.maps.LatLng(circleCenter.lat, circleCenter.lng),
            circleCenter
          );
        return distanceBetweenCircleAndRow <= circleRadius;
      })
      .map(
        (row: any) =>
          new Promise<any>((resolve) => {
            geocoder.geocode(
              {
                location: new window.google.maps.LatLng(
                  circleCenter.lat,
                  circleCenter.lng
                ),
              },
              (results: any, status: string) => {
                if (status === "OK") {
                  resolve(results);
                } else {
                  resolve([]);
                }
              }
            );
          })
      );

    const geocodingResults = await Promise.all(postcodesPromises);
    geocodingResults.forEach((results: any) => {
      results.forEach((result: any) => {
        result.address_components.forEach((component: any) => {
          if (component.types.includes("postal_code")) {
            uniquePostcodesSet.add(component.short_name);
          }
        });
      });
    });

    const uniquePostcodes = Array.from(uniquePostcodesSet);
    return uniquePostcodes;
  };

  const imperialCountries: string[] = ["US", "LR", "MM", "GB"];

  function getDistanceUnit(countryCode: string): string {
    if (imperialCountries.includes(countryCode.toUpperCase())) {
      return "miles"; // Imperial units (miles)
    }

    // Default to metric units (kilometers)
    return "km";
  }

  // Example usage

  const countryCode: string | null = sessionStorage.getItem("country");
  const distanceUnit: string = getDistanceUnit(
    countryCode === null ? "" : countryCode
  );

  useEffect(() => {
    const updateRows = async () => {
      if (!storeLocation) return;
      const circleCenter = {
        lat: 17.4486,
        lng: 78.3908,
      };
      for (const each of updatedRows) {
        const circleRadius = 500000;
        const postcodes = await fetchPostcodesForCircle(
          circleCenter,
          circleRadius
        );

        const index = updatedRows.findIndex(
          (row: any) => row.deliveryRuleId === each.deliveryRuleId
        );
        // if (index !== -1) {
        //   setRows((prevRows) => {
        //     const updatedRows = [...prevRows];
        //     updatedRows[index] = { ...each, postCodesForZone: postcodes };
        //     return updatedRows;
        //   });
        // }
      }
    };

    updateRows();
  }, [rows]);

  // const fetchPostcodesForCircle = async (circleCenter: Location, circleRadius: number) => {
  //   if (storeLocation === null) {
  //     console.error('Current location is null');
  //     return [];
  //   }

  //   const nearby = zipcodesNearby.near({ longitude: circleCenter.lng, latitude: circleCenter.lat }, circleRadius);

  //   const uniquePostcodesSet = new Set();

  //   const postcodesPromises = rows
  //     .filter((row: any) => nearby.includes(row.zipcode))
  //     .map((row: any) => new Promise<any>((resolve) => {
  //       resolve({ postal_code: row.zipcode });
  //     }));

  //   const geocodingResults = await Promise.all(postcodesPromises);

  //   geocodingResults.forEach((result: any) => {
  //     if (result.postal_code) {
  //       uniquePostcodesSet.add(result.postal_code);
  //     }
  //   });

  //   const uniquePostcodes = Array.from(uniquePostcodesSet);

  //   return uniquePostcodes;
  // };

  // useEffect(() => {
  //   const updateRows = async () => {
  //     if (!storeLocation) return;

  //     const circleCenter: Location = storeLocation;

  //     for (const each of updatedRows) {
  //       const circleRadius = parseInt(each.distance) * 1609.34;
  //       const postcodes = await fetchPostcodesForCircle(
  //         circleCenter,
  //         circleRadius
  //       );

  //       // Uncomment this block if you want to update the rows with postcodes
  //       // const index = updatedRows.findIndex((row: any) => row.deliveryRuleId === each.deliveryRuleId);
  //       // if (index !== -1) {
  //       //   setRows((prevRows) => {
  //       //     const updatedRows = [...prevRows];
  //       //     updatedRows[index] = { ...each, postCodesForZone: postcodes };
  //       //     return updatedRows;
  //       //   });
  //       // }

  //     }
  //   };

  //   updateRows();
  // }, [rows]);
  const getMaxDistance = (rows: any[]) => {
    return rows.reduce((max, row) => {
      const distanceInMeters = parseInt(row.distance) * 1609.34;
      return distanceInMeters > max ? distanceInMeters : max;
    }, 0);
  };

  return (
    <Box>
      {locationKey !== null ? (
        isLoaded && storeLocation ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            center={storeLocation}
            zoom={calculateZoomLevel(getMaxDistance(updatedRows))}
          >
            <MarkerF
              position={storeLocation}
              // draggable={true}
              // onDragEnd={handleMarkerDragEnd}
            />

            {updatedRows?.map((each: any, index: number) => (
              <CircleF
                key={index}
                center={storeLocation}
                radius={
                  parseInt(each.distance) *
                  (distanceUnit === "miles" ? 1609.34 : 1000)
                }
                options={{
                  strokeColor: each.color,
                  strokeOpacity: 1,
                  strokeWeight: 2,
                  fillColor: each.color,
                  fillOpacity: 0.15,
                }}
              />
            ))}
          </GoogleMap>
        ) : (
          <div style={{ color: "red" }}>Loading</div>
        )
      ) : (
        <Box className="dummy-map-image-container">
          <Box>
            <Typography className="dummy-map-image-text-order">
              We are unable to provide Google Map features because the location
              key is missing.
            </Typography>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default RenderGoogleMap;
