import React, { useState } from "react";
import { Divider, TextField } from "@mui/material";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { CloseOutlined } from "@mui/icons-material";
import "./bulkAdjustment.css";
import { IconButton } from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { BULK_PRICE_ADJUSTMENTS } from "../../graphicalQl/mutation/menuMutation";
import { useSelector } from "react-redux";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";

import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { useDispatch } from "react-redux";
import { menusetup } from "../../store";
import { GET_PROFILE_DATA } from "../../graphicalQl/usequery/profileQuery";
import { useFormik } from "formik";
import * as Yup from "yup";
interface Props {
  openModal: boolean;
  handleClose: () => void;
}
// interface Radio{
//     id:number
//     value:string;
// }
interface RadioValue {
  id: number;
  value: string;
  name: string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  percentageValue: Yup.string().required("*Value Required"),
  // description: Yup.string().max(100, 'Maximum of 100 characters allowed'),
});

export default function BulkAdujustment(props: Props) {
  const { t } = useTranslation();
  const { menu } = useSelector((state: any) => state.menuState);

  const { openModal, handleClose } = props;
  const [open, setOpen] = React.useState(false);
  const radioGroup: RadioValue[] = [
    { id: 1, value: t("menu.bulk_adjustment.all"), name: "ALL" },
    { id: 2, value: t("menu.bulk_adjustment.category"), name: "CATEGORIES" },
    { id: 3, value: t("menu.bulk_adjustment.bundles"), name: "BUNDLES" },
    { id: 4, value: t("menu.bulk_adjustment.products"), name: "COURSES" },
    { id: 5, value: t("menu.bulk_adjustment.options"), name: "OPTIONS" },
  ];

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  // const [selectedValue, setSelectedValue] = useState<string>(
  //   "ALL"
  // );

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      selectedValue: "ALL",
      valueChange: "INCREASE",
      percentageValue: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      bulkPrice({
        variables: {
          appliesFor: values.selectedValue,
          priceMode: values.valueChange,
          rounding: "upwards_to_.99",
          percentage: +values.percentageValue,
          menuId: +menu,
          updatedBy: employeeName,
        },
      });
    },
  });
  // const handleRadioChange = (
  //   event: SelectChangeEvent<HTMLInputElement>
  // ) => {
  //   setSelectedValue(event.target.value as string);
  // };

  // const [selectedRound, setSelectedRound] = useState<string>(
  //   "none"
  // );

  // const handleRadioRound = (
  //   event: SelectChangeEvent<HTMLInputElement>
  // ) => {
  //   setSelectedRound(event.target.value as string);
  // };

  // const [valueChange, setValueChange] = React.useState('INCREASE');

  // const handleChange = (event: SelectChangeEvent) => {
  //   setValueChange(event.target.value as string);
  // };
  // const [percentageValue, setPercentageValue] = React.useState<number | string>("");

  // const handlePercentageValue = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setPercentageValue(+event.target.value);
  // };
  const dispatch = useDispatch();

  const handlecancel = () => {
    handleClose();
    formik.resetForm();
    dispatch(menusetup("menu", ""));
  };
  const [bulkPrice, { loading: adjustmentLoading }] = useMutation(
    BULK_PRICE_ADJUSTMENTS,
    {
      context: { clientName: "categoryClient" },
      onCompleted: () => {
        toast.success(
          <div>
            <Typography>{t("toast.updated")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
        handleClose();
        formik.resetForm();
        dispatch(menusetup("menu", ""));
      },
      refetchQueries: [MENU_LIST],
    }
  );
  const [employeeName, setEmployeeName] = useState<string>("");

  const employeeId = sessionStorage.getItem("employeeId");

  // const businessId = sessionStorage.getItem("businessId") as string
  const { data } = useQuery(GET_PROFILE_DATA, {
    context: { clientName: "userClient" },
    variables: {
      employeeId: employeeId && +employeeId,
    },
  });

  useEffect(() => {
    if (data && data.employee) {
      const list2 = {
        firstName: data.employee.firstName,

        lastName: data.employee.lastName,
        email: data.employee.email,
        mobile: data.employee.mobile,
      };

      setEmployeeName(list2.firstName + " " + list2.lastName);
    }
  }, [data]);

  // const handleEmployeeNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setEmployeeName(event.target.value);
  // };
  // const handleSubmit = () => {

  // }

  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={style}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                background: "#F9F9F9",
                padding: "1%",
              }}
            >
              <IconButton
                className="bulk-adjustment-exit-icon"
                onClick={handlecancel}
              >
                <CloseOutlined />
              </IconButton>
              <Typography className="bulk-adjustment-text">
                {t("menu.bulk_adjustment.bulk_adjustment_prices")}
              </Typography>
              <Button
                className="bulk-adjustment-button"
                // onClick={() => { handleSubmit(); handleClose() }}
                type="submit"
                disabled={adjustmentLoading}
              >
                {t("menu.bulk_adjustment.save")}
              </Button>
            </Box>
            <Box sx={{ display: "inline-flex", padding: "2%", width: "80%" }}>
              <Typography
                sx={{ width: "20%" }}
                className="bulk-adjustment-text4"
              >
                {t("menu.bulk_adjustment.apply")}
              </Typography>
              <FormControl sx={{ marginLeft: "2%", width: "100%" }}>
                <RadioGroup
                  value={formik.values.selectedValue}
                  // onChange={(event) => handleRadioChange(event)}
                  onChange={formik.handleChange}
                  name="selectedValue"
                >
                  {radioGroup.map((each) => (
                    <FormControlLabel
                      value={each.name}
                      key={each.id}
                      control={<Radio color="warning" />}
                      label={
                        <Typography className="bulk-adjustment-text2">
                          {each.value}
                        </Typography>
                      }
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            </Box>

            <Divider sx={{ marginTop: "2%" }} />

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                marginTop: "1%",
                paddingRight: "20%",
                marginBottom: "1%",
              }}
            >
              <Typography className="bulk-adjustment-text4">
                {t("menu.bulk_adjustment.pricing")}
              </Typography>
              <FormControl sx={{ width: "30%" }}>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formik.values.valueChange}
                  name="valueChange"
                  onChange={formik.handleChange}
                  color="warning"
                >
                  <MenuItem value={"INCREASE"}>
                    {t("menu.bulk_adjustment.increase")}
                  </MenuItem>
                  <MenuItem value={"DECREASE"}>
                    {t("menu.bulk_adjustment.decrease")}
                  </MenuItem>
                </Select>
              </FormControl>
              <Box>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <TextField
                    label={t("menu.bulk_adjustment.enter")}
                    variant="outlined"
                    sx={{ width: "50%" }}
                    color="warning"
                    value={formik.values.percentageValue}
                    name="percentageValue"
                    onChange={(e) => {
                      const val = e.target.value;
                      if ((!val || /^[0-9]+$/.test(val)) && val.length < 4) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                  />
                  <Typography className="bulk-adjustment-text3">%</Typography>
                </Box>
                {formik.touched.percentageValue &&
                formik.errors.percentageValue ? (
                  <Typography sx={{ fontSize: "12px", color: "red" }}>
                    {formik.errors.percentageValue}
                  </Typography>
                ) : null}
              </Box>
            </Box>
            <Divider />
            {/* <Box sx={{display:"inline-flex",padding:"2%",width:"80%",marginTop:'1%'}}>
          <Typography sx={{width:"20%"}}  className='bulk-adjustment-text4'>Rounding</Typography>
            <FormControl sx={{marginLeft:"2%",width:"100%"}}>
    
      <RadioGroup
       value={selectedRound}
       onChange={(event)=>handleRadioRound(event)} 
        name="radio-buttons-group"
      >
       
 <FormControlLabel value="none" control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>None</Typography>} />
 <FormControlLabel value="half_up"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Half Up<span className='bulk-adjustment-text5'>( if 7.5 ==8.00)</span></Typography>} />
 <FormControlLabel value="half_down" control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Half Down<span className='bulk-adjustment-text5'>(if 7.5==7.00)</span></Typography>} />
 <FormControlLabel value="upWards"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Upwards <span className='bulk-adjustment-text5'>( if 7.62 ==8.00)</span></Typography>} />
 <FormControlLabel value="downWards"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Downwards <span className='bulk-adjustment-text5'>(if 7.62==7.00)</span></Typography>} />
 <FormControlLabel value="upWardsTo"  control={<Radio color='warning'/>} label={<Typography className='bulk-adjustment-text2'>Upwards to .99<span className='bulk-adjustment-text5'>( if 7.62 ==7.99)</span></Typography>} />     
        
      
      </RadioGroup>
    </FormControl>
          </Box> */}
          </Box>
        </form>
      </Modal>
    </div>
  );
}
