import * as React from "react";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box } from "@mui/material";
import styled from "styled-components";
import { GraphContext } from "../../../Contexts/GraphContext";
import dayjs from "dayjs";

const TimePickerWrapper = styled("div")({
  //  maxWidth: "188px",
  width: "48%",
  border: "0px",
  ".css-z3c6am-MuiFormControl-root-MuiTextField-root": {
    widh: "100%",
  },
  ".css-14s5rfu-MuiFormLabel-root-MuiInputLabel-root": {
    top: "-16px",
    left: "-12px",
  },
  ".css-o9k5xi-MuiInputBase-root-MuiOutlinedInput-root": {
    height: "48px",
  },

  ".css-1yq5fb3-MuiButtonBase-root-MuiIconButton-root": {
    position: "absolute",
    right: "14px",
    bottom: "2px",
    padding: "0px",
  },
  ".css-nxo287-MuiInputBase-input-MuiOutlinedInput-input": {
    padding: "0px",
  },
  ".css-1d3z3hw-MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  ".css-4jnixx-MuiStack-root": {
    overFlow: "unset !important",
  },
});

interface Props {
  label?: string;
  setTime: (e: string) => void;
  setOpen: (e: boolean) => void;
  timeRef: any;
  timeValue: string;
}

const BasicTimePicker: React.FC<Props> = ({
  label,
  setTime,
  setOpen,
  timeRef,
  timeValue,
}) => {
  const [localTime, setLocalTime] = React.useState("");
  const [selectedTime, setSelectedTime] = React.useState<any>(null);
  const { setType } = React.useContext(GraphContext);

  const timeChangeHandler = (event: unknown) => {
    const d = event as string;
    const date = new Date(d);

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    const formattedTime = `${hours}:${minutes}`;
    if (formattedTime.includes("NaN")) {
      return null;
    } else {
      setLocalTime(formattedTime);
    }
  };

  const handleClose = () => {
    //setTime(localTime);
    setType("Custom");
  };

  React.useEffect(() => {
    const parsedTime = dayjs(timeValue, "HH:mm");
    setSelectedTime(parsedTime); // Set the time
  }, []);

  return (
    <TimePickerWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DemoContainer
          sx={{
            overflow: "unset", // Override overflow here directly
          }}
          components={["TimePicker"]}
        >
          <Box
            sx={{
              width: "250px",
            }}
          >
            <TimePicker
              value={selectedTime}
              onChange={(e) => {
                //setOpen(true);
                timeChangeHandler(e);
              }}
              onClose={handleClose}
              format="HH:mm"
              slots={"12"}
              inputRef={timeRef}
            />
            <Box
              sx={{
                height: "1px",
                width: "59px",
                backgroundColor: "#D9D9D9",
                marginTop: "-3px",
              }}
            ></Box>
          </Box>
        </DemoContainer>
      </LocalizationProvider>
    </TimePickerWrapper>
  );
};

export default BasicTimePicker;
