import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./popupWithTitle.css";

interface Props {
  open: boolean;
  mainText: string;
  button1Text: string;
  button2Text: string;
  popUpwidth: string;
  functionForButton1: () => void;
  functionForButton2: () => void;
  functionForCloseIcon: () => void;
  widthForTitle: string;
  title: string;
  button1Variant: "text" | "outlined" | "contained" | undefined;
  button2Variant: "text" | "outlined" | "contained" | undefined;
  widthForButtons: string;
  button1Style: string;
  button2Style: string;
  titleStyle: string;
}

const PopupWithTitle = (props: Props) => {
  const {
    open,
    functionForButton1,
    titleStyle,
    button1Style,
    button2Style,
    functionForCloseIcon,
    widthForButtons,
    button1Variant,
    button2Variant,
    functionForButton2,
    widthForTitle,
    title,
    popUpwidth,
    mainText,
    button1Text,
    button2Text,
  } = props;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: popUpwidth,
    bgcolor: "background.paper",
    borderRadius: "8px",
    p: 2,
  };
  return (
    <Modal
      open={open}
      onClose={functionForCloseIcon}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            position: "relative",
            textAlign: "center",
          }}
        >
          <Box className="close-icon-for-popup">
            <IconButton
              onClick={functionForCloseIcon}
              className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
            >
              <CloseIcon />
            </IconButton>
          </Box>

          <Typography
            className={titleStyle}
            sx={{ textTransform: "capitalize" }}
          >
            {title}
          </Typography>
        </Box>

        <Divider
          sx={{ marginTop: "12px", marginBottom: "12px", width: "100%" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          <Typography
            className="comfirmation-popup-text"
            sx={{ color: "#707070" }}
          >
            {mainText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: widthForButtons,
            alignItems: "center",
            textAlign: "center",
            marginTop: "40px",
            gap: "2%",
          }}
        >
          <Button
            onClick={functionForButton1}
            className={button1Style}
            color="warning"
            variant={button1Variant}
            sx={{ textTransform: "none" }}
          >
            {button1Text}
          </Button>
          <Button
            onClick={functionForButton2}
            className={button2Style}
            color="warning"
            variant={button2Variant}
            sx={{ textTransform: "none" }}
          >
            {button2Text}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default PopupWithTitle;
