import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import CustomImg from '../../assets/menuQr/customImg.png';
import DefaultImg from '../../assets/menuQr/defaultImg.png'
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

import CloseIcon from '@mui/icons-material/Close';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

interface Props {
    modal: boolean,
    setModal: any,
    setFinalModal: any,
    setCustomModal: any
}

export default function DomainMOdal(props: Props) {
    const { modal, setModal, setFinalModal, setCustomModal } = props
        ;
    const handleClose = () => setModal(false);
    const [list, setList] = React.useState([
        { id: 1, name: "Default Domain", text: "This domain is offered by us, like", url: 'yourbusiness.company.com', image: DefaultImg, isChecked: true },
        { id: 2, name: "Custom Domain", text: "Optimise Your Domain, like", url: 'mystorename.com, .', image: CustomImg, isChecked: false }

    ])

    const [selectedId, setSelectedId] = React.useState(1)

    const handleOnclick = (id: number) => {
        const result = list.map((each) => each.id === id ? { ...each, isChecked: true } : { ...each, isChecked: false })
        setList(result)
        setSelectedId(id)
    }

    const handlemodals = () => {
        if (selectedId === 1) {
            setFinalModal(true)
        }
        else {
            setCustomModal(true)
        }


    }


    return (
        <div>
            <Modal
                open={modal}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', gap: "30%", alignItems: 'center' }}>
                        <IconButton className="exit-container" onClick={handleClose}>
                            <CloseIcon className="exitIcon" />
                        </IconButton>
                        <Typography className="main-head" sx={{ textTransform: 'capitalize' }}>Select your Domain type</Typography>
                    </Box>
                    <Divider sx={{ marginTop: '12px' }} />

                    <Box sx={{ display: "flex", alignItems: "center", marginTop: '32px', gap: "24px" }}>
                        {list.map((each) => (
                            <Box
                                key={each.id} // Added key prop for unique identification
                                sx={{
                                    width: '45%',

                                    display: "flex", // Fixed typo from 'dispaly' to 'display'
                                    flexDirection: 'column',
                                    gap: '16px',
                                    position: 'relative',
                                    alignItems: "center"
                                }}
                                className={each.isChecked ? "select-domain-box" : "unSelect-domain-box"}
                                onClick={() => { handleOnclick(each.id) }}
                            >
                                <img src={each.image} alt={each.name} style={{ width: '84px', height: "84px" }} />
                                <Typography className="modal-head-text">{each.name}</Typography>
                                <Typography className="main-para">{each.text}</Typography>
                                <Typography className='url-text-style'>{each.url}</Typography>
                                {each.isChecked && (
                                    <Box sx={{ position: "absolute", top: 0, right: 0, padding: '8px' }}>
                                        <CheckCircleOutlineIcon sx={{ color: "#F38B08" }} />
                                    </Box>
                                )}
                            </Box>
                        ))}
                    </Box>
                    <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "24px" }}>
                        <Button className="main-button" onClick={() => { setModal(false); handlemodals() }}>Next {<ArrowForwardIcon />} </Button>
                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
