import { Box, Typography } from '@mui/material'
import React from 'react'
import CheackCircle from "../../../assets/check-circleTwo.png";

const PaymentSuccess = () => {
    return (
        <Box sx={{ marginBottom: '6vh' }}>
            <Box sx={{ padding: "2vh 20px", backgroundColor: '#FBFBFB', display: "flex", justifyContent: "center", alignItems: "center" }}>
                <Typography sx={{
                    fontSize: '24px',
                    fontWeight: "500px"
                }}>Success Page</Typography>
            </Box>
            <Box sx={{ width: "100%", textAlign: "center", mt: '2vh' }}>
                <img
                    width={"33.33px"}
                    height={"33.33px"}
                    src={CheackCircle}
                    alt="CheackCircle"
                />
                <Typography sx={{
                    mt: '2vh',
                    color: "#333333",
                    fontSize: "20px",
                    fontWeight: "600px",
                    fontFamily: "Poppins"
                }}

                >Congratulations! You've successfully subscribed to the Monthly Gold Plan.</Typography>
                <Typography sx={{
                    mt: '4vh',
                    color: "#5E5F63",
                    fontSize: "16px",
                    fontWeight: "600px",
                    fontFamily: "Poppins"
                }}>You will find your login details and password information in the email we sent to your provided email address.</Typography>
                <Typography
                    sx={{
                        mt: '4vh',
                        color: "#333333",
                        fontSize: "14px",
                        fontWeight: "500", // Should be 500 instead of "500px"
                        fontFamily: "Poppins"
                    }}
                >
                    You can access your account by clicking on the <a href="https://admin.bayforall.co.uk/">URL link</a> given here.
                </Typography>
            </Box>



        </Box>
    )
}

export default PaymentSuccess