import {
  Box,
  Typography,
  Button,
  Tooltip,
  IconButton,
  Paper,
  InputBase,
  Drawer,
  TextField,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import "./driverList.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";

import VisibilityIcon from "@mui/icons-material/Visibility";
import { useQuery } from "@apollo/client";
import {
  DRIVERS_LIST,
  DRIVERS_ASSIGNED_ORDERS_COUNT,
} from "../../graphicalQl/usequery/deliveryManagementQuery";
import SearchIcon from "@mui/icons-material/Search";
import DriverProfile from "../userManagement/userProfile";
import CircularLoader from "../../loader/loader";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import moment from "moment";

import { useTranslation } from "react-i18next";

import { DataGrid, GridColDef } from "@mui/x-data-grid";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";

function DriverList() {
  const { t } = useTranslation();
  const employeeId = sessionStorage.getItem("employeeId");
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [drawerState, setDrawerState] = useState<boolean>(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const navigate = useNavigate();
  const [profileId, setProfileId] = React.useState<null | string>("");
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [collectedUUids, setCollectedUUids] = React.useState<any[]>([]);
  const [mergedDriversList, setMergedDriversList] = useState<any[]>([]);
  const [assignedOrdersCountList, setAssignedOrdersCountList] = React.useState<
    any[]
  >([]);
  const filteredDataFromNull = {
    businessId: +businessId,
    query: searchInput,
    locationId: +locationId,
    excludedEmployeeId: employeeId && +employeeId,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(filteredDataFromNull).filter(([_, value]) => value !== null)
  );

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    setProfileId(null);
  };

  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(DRIVERS_LIST, {
    context: { clientName: "userClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  const filteredDataForAssignedOrders = {
    employeeList: JSON.stringify(collectedUUids),
  };

  const filteredDataForAssignedOrdersFromNUll = Object.fromEntries(
    Object.entries(filteredDataForAssignedOrders).filter(
      ([_, value]) => value !== null
    )
  );

  const {
    loading: assignedOrdersLoading,
    error: assignedOrdersError,
    data: assignedOrdersData,
  } = useQuery(DRIVERS_ASSIGNED_ORDERS_COUNT, {
    context: { clientName: "orderClient" },
    variables: filteredDataForAssignedOrdersFromNUll,
    fetchPolicy: "network-only",
  });

  const handleEditClick = (id: string) => {
    // setDrawerState(true)
    setOpen(true);
    setProfileId(id);
  };

  const handleNavigationIconClick = (id: string) => {};
  useEffect(() => {
    if (employeesData) {
      const { employees } = employeesData;
      if (employees) {
        const { edges } = employees;
        if (edges) {
          const requiredDataList = edges.map((each: any) => ({
            id: each.node.employeeId,
            uuid: each.node.uuid,
            driverName: `${each.node.firstName} ${each.node.lastName}`,
            clockInAt: each.node.clockInAt,
            clockOutAt: each.node.clockOutAt,
            permissions:
              each.node.permissions && JSON.parse(each.node.permissions),
          }));

          const filteredDriverList = requiredDataList
            ? requiredDataList.filter(
                (each: any) => each.permissions && each.permissions.includes(44)
              )
            : [];

          const collectedDriverUUids = filteredDriverList
            .filter((each: any) => each.uuid)
            .map((eachDriver: any) => eachDriver.uuid);

          setCollectedUUids(collectedDriverUUids);
          setRows(filteredDriverList);
        } else {
          setCollectedUUids([]);
          setRows([]);
        }
      }
    }
  }, [employeesData]);

  useEffect(() => {
    if (assignedOrdersData) {
      const { driverCurrentOrderDetails } = assignedOrdersData;
      if (driverCurrentOrderDetails) {
        const parsedData = JSON.parse(driverCurrentOrderDetails);

        const data = parsedData.map((each: any) => ({
          employeeUuid: each["employee_uuid"],
          assignedCount: each["assigned_count"],
          currentOrderStatus: each["current_order_status"],
        }));
        setAssignedOrdersCountList(data);
      } else {
        setAssignedOrdersCountList([]);
      }
    }
  }, [assignedOrdersData]);

  useEffect(() => {
    if (rows.length && assignedOrdersCountList.length) {
      const rowsMap = new Map(rows.map((each: any) => [each.uuid, each]));
      const merged = assignedOrdersCountList
        .map((each: any) => {
          const correspondingData = rowsMap.get(each.employeeUuid);
          if (correspondingData) {
            return {
              ...correspondingData, // data from rows
              ...each, // data from assignedOrdersCountList
            };
          }
          return null; // If no match found, return null
        })
        .filter((each: any) => each !== null); // Filter out null values
      setMergedDriversList(merged);
    } else {
      setMergedDriversList([]);
    }
  }, [rows, assignedOrdersCountList]);

  const getRowClassname = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleUserInputChange = (text: string) => {
    setSearchInput(text);
  };

  const columns: GridColDef[] = [
    {
      field: "driverName",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.driver_name"),
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ textTransform: "capitalize" }}>
            {params.row.driverName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "currentOrderStatus",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.current_order_status"),
      flex: 1.5,
      renderCell: (params) => (
        <Box>
          <Typography className="user-list-table-row">
            {params.value ? params.value : "N/A"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "assignedCount",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.assigned_orders"),
      flex: 1.5,
    },
    {
      field: "clockInAt",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.clock_in"),
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography className="user-list-table-row">
            {params.value
              ? moment(params.value).local().format("hh:mm A")
              : "N/A"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "clockOutAt",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.clock_out"),
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography className="user-list-table-row">
            {params.value
              ? moment(params.value).local().format("hh:mm A")
              : "N/A"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Locations",
      headerClassName: "driver-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.view_location"),
      flex: 1,
      renderCell: (params) => {
        return (
          <Box>
            <Tooltip
              title={t("deliveries.view_live_location")}
              placement="top"
              arrow
            >
              <IconButton
                onClick={() => handleNavigationIconClick(params.row.id)}
              >
                <LocationOnIcon sx={{ color: "#ABABAB" }} />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
    {
      field: "",
      headerName: t("buttons.view") || "",
      headerClassName: "driver-list-table-header-name",
      disableColumnMenu: true,
      width: 150,
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <Box>
            <Box>
              <Tooltip
                title={t("deliveries.view_profile")}
                placement="top"
                arrow
              >
                <IconButton onClick={() => handleEditClick(params.row.id)}>
                  <VisibilityIcon sx={{ color: "#ABABAB" }} />
                </IconButton>
              </Tooltip>
            </Box>
          </Box>
        );
      },
    },
  ];

  const handleAddNewDriverButton = () => {
    navigate("/users/create-user");
  };

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(employeesData?.employees?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(employeesData?.employees?.pageInfo.startCursor || null);
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Drawer anchor={"right"} open={open} onClose={handleClose}>
        <Box width={600}>
          <DriverProfile handlePopupClose={handleClose} profileId={profileId} />
        </Box>
      </Drawer>

      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: "10px",
          }}
        >
          <Typography className="driver-list-header-text">
            {t("deliveries.drivers")}
          </Typography>

          <Button
            onClick={handleAddNewDriverButton}
            variant="contained"
            className="driver-list-header-button"
          >
            {t("deliveries.add_new_driver")}
          </Button>
        </Box>
      </Box>
      <Box sx={{ width: "100%" }}>
        <DelayedInput
          width="30%"
          margin="10px 0px 10px 8px"
          setSearchValue={setSearchInput}
          placeHolder={t("users.search_by_name")}
          size={"medium"}
        />
      </Box>
      {employeesDataLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            height: "70vh",
            padding: "10px",
            width: "100%",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={mergedDriversList}
            columns={columns}
            getRow={getRowClassname}
            totalCount={mergedDriversList ? mergedDriversList.length : 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}

export default DriverList;
