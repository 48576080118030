import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import Link from "@mui/material/Link";
import CompanyInfoIcon from "../../../assets/companyInfo.png";
import "./index.css";
import { Link as RouterLink } from "react-router-dom";
import Logo from "../../../assets/navBar/admin.png";

const CompneyInfo = () => {
  return (
    <Box
      width={"60%"}
      className="coloredBg"
      sx={{
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center",
        display: { md: "grid", xs: "none" },
        position: "relative",
        backgroundColor: "#F38B08",
        height: "90vh",
        padding: "4% 4% 0% 4%",
        borderRadius: "0 6px 6px 0",
      }}
    >
      <Box></Box>
      <img
        src={Logo}
        style={{
          width: "200px",
          height: "200px",
        }}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "45vh",
        }}
      >
        <Box>
          <Typography
            sx={{ marginTop: "2%", marginBottom: "4%" }}
            className="company-info-welcome-text"
          >
            Welcome to
          </Typography>
          <Typography
            sx={{ marginTop: "1%", marginBottom: "4%" }}
            className="company-info-smart-serve-text"
          >
            Smart Serve Solutions
          </Typography>
          <Typography
            sx={{ marginTop: "5%", marginBottom: "1%" }}
            className="company-info-restaurant-text"
          >
            Transform Your Restaurant Management Experience
          </Typography>
          <Typography
            sx={{ marginTop: "2%", marginBottom: "4%" }}
            className="company-info-restaurant-text-description"
          >
            Log in or sign up to access the ultimate suite of tools designed to
            streamline your restaurant operations, enhance customer
            satisfaction, and drive growth. With Smart Serve Solutions, managing
            your restaurant has never been easier.
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "2%",
          }}
        >
          <Typography className="company-info-support-text">
             © 2024, Smart Serve Solutions{" "}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "4%",
              alignItems: "center",
              width: "40%",
            }}
          >
            <Typography className="company-info-support-text">
              <a
                className="company-info-support-text"
                href="/support"
                target="_blank"
              >
                Support
              </a>
            </Typography>
            <Typography className="company-info-support-text">
              <a
                className="company-info-support-text"
                href="/about"
                target="_blank"
              >
                About
              </a>
            </Typography>
            <Typography className="company-info-support-text">
              <a
                className="company-info-support-text"
                href="/terms"
                target="_blank"
              >
                Terms
              </a>
            </Typography>
            <Typography className="company-info-support-text">
              <a
                className="company-info-support-text"
                href="/privacy"
                target="_blank"
              >
                Privacy
              </a>
            </Typography>
            {/* <Typography className="company-info-support-text">About</Typography>
            <Typography className="company-info-support-text">Terms</Typography>
            <Typography className="company-info-support-text">Privacy</Typography> */}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompneyInfo;
