import { Box } from "@mui/material";
import SalesReport from "../../components/reports/sales";
import React from "react";

const SalesReportPage = () => {
  return (
    <Box>
      <SalesReport />
    </Box>
  );
};
export default SalesReportPage;
