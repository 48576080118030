import React, { useState, useRef } from "react";
import { useNavigate } from "react-router";
import Box from "@mui/material/Box";

import {
  IconButton,
  Button,
  Typography,
  List,
  Popover,
  ListItem,
  ListItemText,
  Divider,
} from "@mui/material";
import { SelectChangeEvent } from "@mui/material";

import "./productTable.css";
import TableOfProduct from "./tableOfProducts";
import { useQuery, useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Stack from "@mui/material/Stack";
import Snackbar from "@mui/material/Snackbar";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {
  CATEGORY_LIST_DROPDOWN,
  OPTIONS_GROUP_LIST,
} from "../../graphicalQl/usequery/productsListQuery";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  FILTERING_PRODUCTS_LIST,
  OPTIONS_LIST,
} from "../../graphicalQl/usequery/productsListQuery";
import {
  DELETE_MULTIPLE_SELECTED_ROWS,
  DUPLICATE_PRODUCT_IN_PRODUCT_LIST,
  PRODUCT_STATUS_CHANGE,
  VARIANT_STATUS_CHANGE,
} from "../../graphicalQl/mutation/productMutation";
import "react-toastify/dist/ReactToastify.css";
import TablePagination from "@mui/material/TablePagination";
import CircularLoader from "../../loader/loader";
import DeletePopup from "../popups/deletePopup";
import PopupWithTitle from "../popups/popupWithTitle";
import FiltersAndSearchForProductList from "./filtersAndSearchForProductList";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

export default function ProductTable() {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [productsList, setProductsList] = useState<any[]>([]);
  const [initialProductsList, setInitialProductsList] = useState([]);
  const [optionsList, setOptionsList] = useState<any[]>([]);
  const [optionsGroupsList, setOptionsGroupsList] = useState<any[]>([]);
  const [deletePopupOpen, setDeletePopupOpen] = React.useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [categoryId, setCategoryId] = useState<string | null>(null);
  const [categoryName, setCategoryName] = useState<string | null>(null);
  const [optionId, setOptionId] = useState<any[]>([]);

  const [popupOpenForStatusChange, setPopupOpenForStatusChange] =
    useState<boolean>(false);
  const [
    popupOpenForStatusChangeForVariant,
    setPopupOpenForStatusChangeForVariant,
  ] = useState<boolean>(false);

  const [profileIdForStatusChange, setProfileIdForStatusChange] = useState<
    string | null
  >(null);

  const [
    profileIdForStatusChangeForVariant,
    setProfileIdForStatusChangeVariant,
  ] = useState<string | null>(null);

  const [expandedForGroup, setExpandedForGroup] = React.useState<
    string | false
  >(false);
  const [collectedGroupIds, setCollectedGroupIds] = useState<any[]>([]);
  const [startCursor, setStartCursor] = useState(null);
  const [endCursor, setEndCursor] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [page, setPage] = React.useState(0);

  const filteringObject = {
    first: !startCursor
      ? rowsPerPage
      : startCursor === null && endCursor === null
      ? rowsPerPage
      : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && rowsPerPage,

    locationId: locationId && +locationId,
    businessId: +businessId,
    categoryIds:
      categoryId === "none" || categoryId === null
        ? null
        : JSON.stringify([categoryId && +categoryId]),
    optionGroupIds:
      collectedGroupIds.length > 0 ? JSON.stringify(collectedGroupIds) : null,
    optionIds: optionId.length > 0 ? JSON.stringify(optionId) : null,
    query: searchInput,
  };

  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const {
    loading: optionsGroupListLoading,
    error: optionsGroupsListError,
    data: optionsGroupsListData,
  } = useQuery(OPTIONS_GROUP_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  const {
    loading: productsDataLoadingByCategoryId,
    error: productsDataErrorByCategoryId,
    data: productsDataByFilteringAllFilters,
  } = useQuery(FILTERING_PRODUCTS_LIST, {
    context: { clientName: "categoryClient" },
    variables: requiredFormatAddress,
    fetchPolicy: "network-only",
  });

  const {
    loading: optionsListLoading,
    error: optionsListError,
    data: optionsListData,
  } = useQuery(OPTIONS_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  useEffect(() => {
    if (optionsGroupsListData) {
      const { optionGroupList } = optionsGroupsListData;
      const { edges } = optionGroupList;
      if (edges.length > 0) {
        const filteredList = edges.map((eachGroup: any) => ({
          groupName: eachGroup.node.name,
          groupId: String(eachGroup.node.optionGroupId),
          isChecked: false,
          optionObjects: eachGroup.node.optionObjs
            ? eachGroup.node.optionObjs.map((eachObject: any) => ({
                name: eachObject.name,
                isChecked: false,
                optionId: String(eachObject.optionId),
              }))
            : [],
        }));

        setOptionsGroupsList(filteredList);
      }
    }
  }, [optionsGroupsListData]);

  useEffect(() => {
    if (optionsListData) {
      const { optionList } = optionsListData;
      const { edges } = optionList;
      const filteredList = edges.map((each: any) => ({
        optionId: each.node.optionId,
        name: each.node.name,
        isChecked: false,
      }));

      setOptionsList(filteredList);
    }
  }, [optionsListData]);
  useEffect(() => {
    if (productsDataByFilteringAllFilters) {
      const { productList } = productsDataByFilteringAllFilters;
      if (productList) {
        const { edges } = productList;
        if (edges.length > 0) {
          const filteredProductsData = edges.map((each: any) => ({
            category: each.node.category,
            description: each.node.description,
            image: each.node.image,
            productStatus: each.node.productStatus,
            unit: each.node.unit,
            sku: each.node.sku,
            name: each.node.name,
            stockCount: each.node.stockCount,
            price: each.node.price,
            productId: each.node.productId,
            priceToDisplay: each.node.priceToDisplay,
            sequence: each.node.sequence
              ? each.node.sequence.map((each: any) => ({
                  amount: each.amount,
                  product: each.product,
                  variantId: each.variant ? each.variant.variantId : "",
                }))
              : [],
            slugName: each.node.slugName,
            variants:
              each.node.variants &&
              each.node.variants.map((eachVariant: any) => ({
                variantId: eachVariant.variantId,
                name: eachVariant.name,
                variantStatus: eachVariant.variantStatus,
                price: eachVariant.price,
                stockCount: eachVariant.stockCount,
                additionalPrice: eachVariant.additionalPrice,
              })),
            minQunatity: each.node.minQunatity,
            isChecked: false,
          }));

          setInitialProductsList(filteredProductsData);
          setProductsList(filteredProductsData);
        } else {
          setInitialProductsList([]);
          setProductsList([]);
        }
      }
      setTotalCount(productsDataByFilteringAllFilters.productList.totalCount);
    }
  }, [
    productsDataByFilteringAllFilters,
    startCursor,
    endCursor,
    page,
    rowsPerPage,
  ]);

  const [categoryList, setCategoryList] = useState<any[]>([]);
  const [checkboxIds, setCheckboxIds] = useState<any[]>([]);

  const {
    loading: categoryDataLoading,
    error: categoryDataError,
    data: categoryData,
  } = useQuery(CATEGORY_LIST_DROPDOWN, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId },
  });

  useEffect(() => {
    if (categoryData) {
      const filteredCategoryList = categoryData.categoryList.edges.map(
        (eachCategory: any) => ({
          id: eachCategory.node.categoryId,
          label: eachCategory.node.name,
        })
      );
      setCategoryList(filteredCategoryList);
    }
  }, [categoryData]);

  const handleCategory = (newData: any) => {
    if (newData) {
      const { id, label } = newData;
      setCategoryId(id);
      setCategoryName(label);
    } else {
      setCategoryId(null);
      setCategoryName(null);
    }
  };

  const [productStatusChange] = useMutation(PRODUCT_STATUS_CHANGE, {
    onCompleted: (data) => {
      if (data) {
        const { productStatusChange } = data;
        if (productStatusChange) {
          const { success } = productStatusChange;
          if (success) {
            handleCloseForStatusChangePopupForProduct();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [FILTERING_PRODUCTS_LIST],
  });

  const [duplicateTheProduct] = useMutation(DUPLICATE_PRODUCT_IN_PRODUCT_LIST, {
    onCompleted: (data) => {
      if (data) {
        const { duplicateProduct } = data;
        if (duplicateProduct) {
          const { success } = duplicateProduct;
          if (success) {
            setPopoverAnchorEl(null);
            toast.success(
              <div>
                <Typography>{t("toast.created")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [FILTERING_PRODUCTS_LIST],
  });

  const [variantStatusChange] = useMutation(VARIANT_STATUS_CHANGE, {
    onCompleted: (data) => {
      if (data) {
        const { variantStatusChange } = data;
        if (variantStatusChange) {
          const { success } = variantStatusChange;
          if (success) {
            handleCloseForStatusChangePopupForVariant();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [FILTERING_PRODUCTS_LIST],
  });

  const [productStatus, setProductStatus] = useState<string>("");
  const [variantStatus, setVariantStatus] = useState<string>("");

  const [deleteMultipleSelectedRows] = useMutation(
    DELETE_MULTIPLE_SELECTED_ROWS,
    {
      onCompleted: (data) => {
        if (data) {
          const { deleteProduct } = data;
          if (deleteProduct) {
            const { success } = deleteProduct;
            if (success) {
              toast.success(
                <div>
                  <Typography>{t("toast.removed")}</Typography>
                </div>,
                {
                  position: "top-right",
                  autoClose: 5000,
                  hideProgressBar: true,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                  className: "custom_toast3",
                }
              );
            }
          }
        }
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [FILTERING_PRODUCTS_LIST],
    }
  );

  const [actions, setActions] = useState<string | undefined>("");

  const handleActions = (event: SelectChangeEvent<string>) => {
    setActions(event.target.value);
  };

  const handleDeselectAllButton = () => {
    setProductsList(initialProductsList);
    setCheckboxIds([]);
  };

  const navigate = useNavigate();

  const handleNavigation = () => {
    navigate("/product/create-product");
  };

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setPopoverAnchorElHeader(event.currentTarget);
  };

  const handleMultipleDeleteIcon = () => {
    deleteMultipleSelectedRows({
      variables: { productId: JSON.stringify(checkboxIds) },
    });
    setCheckboxIds([]);
    setDeletePopupOpen(false);
  };

  const handleSearchIconOptions = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setSearchInputOptions(e.target.value);
  };

  const [searchInputOptions, setSearchInputOptions] = React.useState("");
  const [productName, setProductName] = React.useState<string | null>(null);
  const [variantName, setVariantName] = useState<string | null>(null);
  const [activateOrInActivateText, setActivateOrInActivateText] = useState<
    string | null
  >(null);
  const [
    activateOrInActivateTextForVariant,
    setActivateOrInActivateTextForVariant,
  ] = useState<string | null>(null);

  const [profileId, setProfileId] = useState<string | null>(null);
  const [popoverAnchorEl, setPopoverAnchorEl] =
    useState<HTMLButtonElement | null>(null);

  const [popoverAnchorElForHeader, setPopoverAnchorElHeader] =
    useState<HTMLButtonElement | null>(null);

  const [deleteProductPopupOpen, setDeleteProductPopupOpen] =
    useState<boolean>(false);

  const searchResults =
    optionsList.length > 0
      ? optionsList.filter((eachLocation) =>
          eachLocation.name.toLowerCase().includes(searchInputOptions)
        )
      : [];
  const searchGroupsResults = optionsGroupsList
    ? optionsGroupsList.filter((eachLocation: any) =>
        eachLocation.groupName.toLowerCase().includes(searchInputOptions)
      )
    : [];

  const handlePopupCheckbox = (
    id: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setOptionId((prevIds: any) => {
      if (e.target.checked && !prevIds.includes(+id)) {
        return [...prevIds, +id];
      } else if (!e.target.checked && prevIds.includes(+id)) {
        return prevIds.filter((prevId: any) => prevId !== +id);
      }
      return prevIds;
    });
    const filteredList = optionsList.map((each) => {
      if (each.optionId === id) {
        return { ...each, isChecked: !each.isChecked };
      }
      return each;
    });

    setOptionsList(filteredList);
  };

  const handleOptionGroup = (id: string, isChecked: boolean) => {
    setCollectedGroupIds((prevIds: any) => {
      if (isChecked && !prevIds.includes(+id)) {
        return [...prevIds, +id];
      } else if (!isChecked && prevIds.includes(+id)) {
        return prevIds.filter((prevId: any) => prevId !== +id);
      }
      return prevIds;
    });

    if (isChecked) {
      const filteredData = optionsGroupsList.map((each: any) => {
        if (each.groupId === id) {
          const filteredOptions = each.optionObjects.map((eachOption: any) => {
            return {
              ...eachOption,
              isChecked: isChecked,
            };
          });
          return {
            ...each,
            optionObjects: filteredOptions,
          };
        }
        return each;
      });
      setOptionsGroupsList(filteredData);
    } else {
      const filteredData = optionsGroupsList.map((each: any) => {
        if (each.groupId === id) {
          const filteredOptions = each.optionObjects.map((eachOption: any) => {
            return {
              ...eachOption,
              isChecked: isChecked,
            };
          });
          return {
            ...each,
            optionObjects: filteredOptions,
          };
        }
        return each;
      });
      setOptionsGroupsList(filteredData);
    }
  };

  const handleOptionId = (
    optionId: string,
    GroupId: string,
    isChecked: boolean
  ) => {
    setOptionId((prevIds: any) => {
      if (isChecked && !prevIds.includes(+optionId)) {
        return [...prevIds, +optionId];
      } else if (!isChecked && prevIds.includes(+optionId)) {
        return prevIds.filter((prevId: any) => prevId !== +optionId);
      }
      return prevIds;
    });

    const updatedList = optionsGroupsList.map((each: any) => {
      if (each.groupId === GroupId) {
        const updatedOptions = each.optionObjects.map((option: any) => {
          if (option.optionId === optionId) {
            return {
              ...option,
              isChecked: isChecked,
            };
          }
          return option;
        });
        return {
          ...each,
          optionObjects: updatedOptions,
        };
      }
      return each;
    });
    setOptionsGroupsList(updatedList);
  };
  const handleChange = (id: string) => {
    setExpandedForGroup((prevExpanded) => (prevExpanded === id ? false : id));
  };

  const handleChangePage = (event: any, newPage: any) => {
    setCheckboxIds([]);
    if (newPage > page) {
      setPage(newPage);
      setStartCursor(null);
      setEndCursor(
        productsDataByFilteringAllFilters.productList.pageInfo.endCursor
      );
    } else {
      setPage(newPage);
      setEndCursor(null);
      setStartCursor(
        productsDataByFilteringAllFilters.productList.pageInfo.startCursor
      );
    }
  };

  const handleChangeRowsPerPage = (event: any) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    setStartCursor(null);
    setEndCursor(null);
  };

  const handleEditBulkProducts = () => {
    if (checkboxIds.length === 1) {
      navigate(`/product/${checkboxIds[0]}/update-product`);
    } else {
      navigate("/bulk/product");
      sessionStorage.setItem("editBulkProductIds", JSON.stringify(checkboxIds));
    }
  };

  const handleDeleteClose = () => {
    setDeletePopupOpen(false);
    setCheckboxIds([]);
  };

  const handleDeleteOpen = () => {
    setDeletePopupOpen(true);
  };

  const [expanded, setExpanded] = useState<boolean>(false);
  const accordionRef = useRef<HTMLDivElement | null>(null);

  const openForPoppover = Boolean(popoverAnchorEl);
  const id = openForPoppover ? "simple-popover" : undefined;

  const openForPoppoverForHeader = Boolean(popoverAnchorElForHeader);
  const idForHeader = openForPoppoverForHeader ? "simple-popover" : undefined;

  const handleClickOutside = (event: MouseEvent) => {
    if (
      accordionRef.current &&
      !accordionRef.current.contains(event.target as Node)
    ) {
      setExpanded(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleChangeForAccordian = (isExpanded: boolean) => {
    setExpanded(isExpanded);
  };

  const handleDeleteProductPopupClose = () => {
    setDeleteProductPopupOpen(false);
    setProfileId(null);
    setPopoverAnchorEl(null);
  };

  const handleProductPoppoverDelete = () => {
    deleteMultipleSelectedRows({
      variables: { productId: JSON.stringify([profileId && +profileId]) },
    });
    handleDeleteProductPopupClose();
  };
  const handleDeleteProductPopupOpen = () => {
    setDeleteProductPopupOpen(true);
    setPopoverAnchorEl(null);
  };

  const handleThreedotsClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    name: string
  ) => {
    setPopoverAnchorEl(event.currentTarget);
    setProfileId(id);
    setProductName(name);
  };

  const handlePopoverCloseForRow = () => {
    setPopoverAnchorEl(null);
  };

  const handleEditUserButton = () => {
    setPopoverAnchorEl(null);
    navigate(`/product/${profileId}/update-product`);
  };

  const handleDuplicateUserButton = () => {
    duplicateTheProduct({ variables: { productId: profileId && +profileId } });
  };

  const handleCloseForStatusChangePopupForProduct = () => {
    setPopupOpenForStatusChange(false);
    setProfileIdForStatusChange(null);
    setProductName(null);
    setActivateOrInActivateText(null);
  };

  const handleCloseForStatusChangePopupForVariant = () => {
    setPopupOpenForStatusChangeForVariant(false);
    setProfileIdForStatusChangeVariant(null);
    setVariantName(null);
    setActivateOrInActivateTextForVariant(null);
  };

  const handleProductStatusChange = (
    productId: string,
    productName: string,
    status: string
  ) => {
    setProfileIdForStatusChange(productId);
    setProductStatus(status);
    setProductName(productName);
    if (status === "Active") {
      setActivateOrInActivateText(t("buttons.de_activate"));
    } else {
      setActivateOrInActivateText(t("buttons.re_activate"));
    }

    setPopupOpenForStatusChange(true);
  };

  const handleProductStatusChangeForVariant = (
    variantId: string,
    varinatName: string,
    status: string
  ) => {
    setProfileIdForStatusChangeVariant(variantId);
    setVariantStatus(status);
    setVariantName(varinatName);
    if (status === "Active") {
      setActivateOrInActivateTextForVariant(t("buttons.de_activate"));
    } else {
      setActivateOrInActivateTextForVariant(t("buttons.re_activate"));
    }

    setPopupOpenForStatusChangeForVariant(true);
  };

  const handleProductStatusChangeButton = () => {
    productStatusChange({
      variables: {
        productId: profileIdForStatusChange,
        productStatus: productStatus === "Active" ? "InActive" : "Active",
      },
    });
  };

  const handleProductStatusChangeButtonForVariant = () => {
    variantStatusChange({
      variables: {
        variantId: profileIdForStatusChangeForVariant,
        variantStatus: variantStatus === "Active" ? "InActive" : "Active",
      },
    });
  };

  const popoverContent = (
    <List style={{ padding: "0px 0px 0px 0px" }}>
      <Button
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
        onClick={handleEditUserButton}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDuplicateUserButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.duplicate")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDeleteProductPopupOpen}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.delete")} />
        </ListItem>
      </Button>
    </List>
  );

  const hanldeBulkAddProducts = () => {
    navigate("/bulk/product");
  };

  const handleCloseForPoppover = () => {
    setPopoverAnchorElHeader(null);
  };
  const [visibleColumns, setVisibleColumns] = useState([
    "Status",
    "Sku",
    "Unit",
  ]);

  const [collectingColumn, setCollectingColums] = useState<any[]>([
    "Status",
    "Sku",
    "Unit",
  ]);

  const handleApplyButton = () => {
    setPopoverAnchorElHeader(null);
    setVisibleColumns(collectingColumn);
  };

  const handlecancelButton = () => {
    setPopoverAnchorElHeader(null);
    setVisibleColumns(visibleColumns);
    setCollectingColums(visibleColumns);
  };

  const toggleColumnVisibility = (columnName: any) => {
    if (collectingColumn.includes(columnName)) {
      const filterData = collectingColumn.filter((col) => col !== columnName);
      setCollectingColums(filterData);
    } else {
      const filtered = [...collectingColumn, columnName];
      setCollectingColums(filtered);
    }
  };

  const handleRowCheckBox = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const filteredList = productsList.map((each: any) => {
      if (each.productId === id) {
        return { ...each, isChecked: e.target.checked };
      }
      return each;
    });

    setProductsList(filteredList);

    setCheckboxIds((prev: any) => {
      if (!prev.includes(+id)) {
        return [...prev, +id];
      } else {
        return prev.filter((checkboxId: number) => checkboxId !== +id);
      }
    });
  };

  const handleAllCheckbox = (isChecked: boolean) => {
    const filteredList = productsList
      .filter((each) => each.productId)
      .map((each) => +each.productId);
    const collected = [...checkboxIds, ...filteredList];
    const uniqueCollectedSet = new Set(collected);

    // Convert the Set back to an array
    const uniqueCollected = Array.from(uniqueCollectedSet);

    if (isChecked) {
      setCheckboxIds(uniqueCollected);
    } else {
      const removedData = checkboxIds.filter(
        (item: any) => !filteredList.includes(item)
      );
      setCheckboxIds(removedData);
    }
  };

  return (
    <Box>
      <DeletePopup
        open={deletePopupOpen}
        handleClose={handleDeleteClose}
        handleOpen={() => setDeletePopupOpen(true)}
        handleSaveButton={handleMultipleDeleteIcon}
        mainText={`${t("buttons.are_you_sure_delete")} ${
          checkboxIds.length === 1
            ? t("product.this_product")
            : t("product.these_product")
        }`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="40%"
      />

      <PopupWithTitle
        open={popupOpenForStatusChange}
        mainText={`${t(
          "product.are_you_sure_title_popup"
        )} ${activateOrInActivateText} ${t("product.the")} "${productName}" ${t(
          "product.product"
        )} ?`}
        button1Text={`${activateOrInActivateText} ${t("product.product")}`}
        button2Text={t("buttons.no_idontwant")}
        popUpwidth="45%"
        functionForButton1={handleProductStatusChangeButton}
        functionForButton2={handleCloseForStatusChangePopupForProduct}
        functionForCloseIcon={handleCloseForStatusChangePopupForProduct}
        widthForTitle="40%"
        title={`${activateOrInActivateText} ${t("product.product")}`}
        button1Variant="outlined"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="kds-listing-deactivate-button-1"
        button2Style="kds-listing-deactivate-button-2"
        titleStyle="kds-listing-deactivate-title"
      />

      <PopupWithTitle
        open={popupOpenForStatusChangeForVariant}
        mainText={`${t(
          "product.are_you_sure_title_popup"
        )} ${activateOrInActivateTextForVariant} ${t(
          "product.the"
        )} "${variantName}" ${t("inventory.variation")} ?`}
        button1Text={`${activateOrInActivateTextForVariant} ${t(
          "inventory.variation"
        )}`}
        button2Text={t("buttons.no_idontwant")}
        popUpwidth="45%"
        functionForButton1={handleProductStatusChangeButtonForVariant}
        functionForButton2={handleCloseForStatusChangePopupForVariant}
        functionForCloseIcon={handleCloseForStatusChangePopupForVariant}
        widthForTitle="40%"
        title={`${activateOrInActivateTextForVariant} ${t(
          "inventory.variation"
        )}`}
        button1Variant="outlined"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="kds-listing-deactivate-button-1"
        button2Style="kds-listing-deactivate-button-2"
        titleStyle="kds-listing-deactivate-title"
      />

      <DeletePopup
        handleSaveButton={handleProductPoppoverDelete}
        popUpwidth="35%"
        open={deleteProductPopupOpen}
        handleClose={handleDeleteProductPopupClose}
        handleOpen={handleDeleteProductPopupOpen}
        mainText={`${t("product.are_you_sure_in_product")} ${productName}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
      />

      <FiltersAndSearchForProductList
        handleCategory={handleCategory}
        categoryList={categoryList}
        categoryName={categoryName}
        categoryId={categoryId}
        accordionRef={accordionRef}
        expanded={expanded}
        handleChangeForAccordian={handleChangeForAccordian}
        handleSearchIconOptions={handleSearchIconOptions}
        searchInputOptions={searchInputOptions}
        searchGroupsResults={searchGroupsResults}
        expandedForGroup={expandedForGroup}
        handleChange={handleChange}
        handleOptionGroup={handleOptionGroup}
        handleOptionId={handleOptionId}
        searchResults={searchResults}
        handlePopupCheckbox={handlePopupCheckbox}
        setSearchInput={setSearchInput}
        handleNavigation={handleNavigation}
        actions={actions}
        handleActions={handleActions}
      />

      {productsDataLoadingByCategoryId ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            zIndex: "-10",
            width: "100%",
            padding: "10px 15px 0px 15px",
          }}
        >
          <TableOfProduct
            idForHeader={idForHeader}
            openForPoppoverForHeader={openForPoppoverForHeader}
            handleAllCheckbox={handleAllCheckbox}
            handleRowCheckBox={handleRowCheckBox}
            openForPoppover={openForPoppover}
            handleCloseForPoppover={handleCloseForPoppover}
            visibleColumns={visibleColumns}
            collectingColumn={collectingColumn}
            handlecancelButton={handlecancelButton}
            handleClick={handleClick}
            toggleColumnVisibility={toggleColumnVisibility}
            handleApplyButton={handleApplyButton}
            hanldeBulkAddProducts={hanldeBulkAddProducts}
            handleProductStatusChangeForVariant={
              handleProductStatusChangeForVariant
            }
            handleProductStatusChange={handleProductStatusChange}
            handleThreedotsClick={handleThreedotsClick}
            popoverAnchorElForHeader={popoverAnchorElForHeader}
            productsList={productsList}
            checkboxIds={checkboxIds}
          />

          <Popover
            id={id}
            open={openForPoppover}
            anchorEl={popoverAnchorEl}
            onClose={handlePopoverCloseForRow}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>

          <Box sx={{ display: "flex", width: "100%" }}>
            <Stack spacing={2} sx={{ width: "100%" }}>
              <Box sx={{ textAlign: "center" }}>
                <Snackbar
                  sx={{
                    width: "50%",
                    marginLeft: "54px",
                    marginBottom: "-18px",
                  }}
                  open={checkboxIds.length !== 0}
                >
                  <Box
                    className="product-edit-delete-notification"
                    sx={{
                      display: "flex",
                      width: "100%",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "8px 10px 8px 10px",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton>
                        <ErrorOutlineIcon color="warning" />
                      </IconButton>
                      <Typography className="product-variations-text">
                        {checkboxIds.length}{" "}
                        {checkboxIds.length !== 1
                          ? t("product.variations")
                          : t("product.variation")}{" "}
                        {t("bulk_product.selected")}
                      </Typography>
                      <Button
                        onClick={handleDeselectAllButton}
                        className="product-deselect-all-button"
                        sx={{ textTransform: "none", marginLeft: "10px" }}
                      >
                        {t("bulk_product.deselect_all")}
                      </Button>
                    </Box>
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <IconButton
                        onClick={handleDeleteOpen}
                        sx={{ marginRight: "15px" }}
                        className="product-delete-icon"
                      >
                        <DeleteIcon />
                      </IconButton>
                      <Button
                        onClick={handleEditBulkProducts}
                        sx={{ textTransform: "none" }}
                        variant="contained"
                        color="warning"
                      >
                        {t("buttons.edit")}
                      </Button>
                    </Box>
                  </Box>
                </Snackbar>
              </Box>
            </Stack>

            <Box sx={{ width: "50%" }}>
              <TablePagination
                rowsPerPageOptions={[25, 50, 100]}
                component="div"
                count={totalCount}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
}
