import React, { useEffect, useState } from 'react';
import TextField from '@mui/material/TextField';
import { useDebounce } from 'use-debounce';
import { InputAdornment } from '@mui/material';
import SearchIcon from "@mui/icons-material/Search";

interface Props {
    setSearchValue: React.Dispatch<React.SetStateAction<any>>;
    placeHolder: string;
    size: "small" | "medium";
    margin?:string
    width?:string
}


const DelayedInput: React.FC<Props> = ({ setSearchValue, placeHolder, size,margin,width }) => {
    const [value, setValue] = useState<string>("");
    const [value2] = useDebounce(value, 1000);

    useEffect(() => {

        setSearchValue(value2)
    }, [value2])
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = event.target.value;
        setValue(newValue);

    };

    return (
        <TextField
            placeholder={placeHolder}
            variant="outlined"
            value={value}
            sx={{
                margin:margin,
                width:width
            }}
            size={size}
            onChange={handleChange}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">{<SearchIcon />}</InputAdornment>
                ),
            }}

        />
    );
};

export default DelayedInput;
