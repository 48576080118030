import { Box, Button, Checkbox, TextField, Typography } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import React, { useEffect, useState } from "react";
import BackIcon from "../../../../assets/back.png";
import MobileIcon from "../../../../assets/phone-android.svg";
import PersonIcon from "../../../../assets/person-outline.svg";
import EmailIcon from "../../../../assets/mail.svg";
import InboxIcon from "../../../../assets/inbox.svg";
import styled from "styled-components";
import { useMutation, useQuery } from "@apollo/client";
import { COMPLAINTS_DETAILS } from "../../../../graphicalQl/usequery/customerDataQuery";
import { UPDATE_COMPLAINT_STATUS } from "../../../../graphicalQl/mutation/customerDataMutation";

interface Props {
  setComplaintsResolutionPanel: any,
  complaintsResolutionPanel: any,
}

const ComplaintsResolutionPanel = (props: Props) => {
  const {
    setComplaintsResolutionPanel,
    complaintsResolutionPanel,
  } = props
  const complaintData = complaintsResolutionPanel.rowData;
  const [customerNote, setCustomerNote] = useState("");

  const [complaintStatus, setComplaintStatus] = useState({
    pending: complaintData.status === "Pending",
    resolved: !(complaintData.status === "Pending"),
  });
  const [customerData, setCustomerData] = useState<any>(null);

  const { data, loading, error } = useQuery(COMPLAINTS_DETAILS, {
    variables: {
      complaintId: complaintData.complaintId,
    },
    context: { clientName: "customerClient" },
    fetchPolicy: "network-only",
  });

  const [
    updateComplaintStatusMutation,
    { data: updateComplaintStatusData, error: updateComplaintStatusError },
  ] = useMutation(UPDATE_COMPLAINT_STATUS, {
    context: { clientName: "customerClient" },
  });

  useEffect(() => {
    if (updateComplaintStatusData?.complaintStatusChange?.success) {
      complaintData?.refetchRegisteredComplaints();
      setComplaintsResolutionPanel({
        state: false,
        rowData: {},
      });
    }
  }, [updateComplaintStatusData]);

  useEffect(() => {
    if (data) {
      const parsedData = data?.customerComplaint;

      setCustomerData({
        name: complaintData.customerName,
        email: complaintData.customerEmail,
        employee: parsedData?.employeeName || "",
        type: parsedData?.orderType || "",
        note: parsedData?.note || "",
      });
      setCustomerNote(parsedData?.note || "");
    }
  }, [data]);

  const updateComplaintStatus = () => {
    updateComplaintStatusMutation({
      variables: {
        complaintId: complaintData.complaintId,
        complaintStatus: complaintStatus.resolved ? "Resolved" : "Pending",
      },
    });
  };

  const ImageAndTextStyledBox = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    columnGap: "12px",
  }));

  return customerData ? (
    <Box sx={{ width: "520px" }}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          margin: "12px 16px",
          columnGap: "12px",
        }}
      >
        <img
          src={BackIcon}
          alt="close side panel"
          onClick={() =>
            setComplaintsResolutionPanel({
              state: false,
              rowData: {},
            })
          }
        />
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
          }}
        >
          Registered Complaint
        </Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          rowGap: "9px",
          marginLeft: "20px",
          marginTop: "27px",
        }}
      >
        <ImageAndTextStyledBox>
          <img src={PersonIcon} alt="user name" />
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {customerData.name}
          </Typography>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              marginLeft: "auto",
            }}
          >
            Employee
          </Typography>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
              marginRight: "57px",
            }}
          >
            {customerData.employee}
          </Typography>
        </ImageAndTextStyledBox>
        <ImageAndTextStyledBox>
          <img src={MobileIcon} alt="user name" />
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {complaintData.date}
          </Typography>
        </ImageAndTextStyledBox>
        <ImageAndTextStyledBox>
          <img src={EmailIcon} alt="user name" />
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {customerData.email}
          </Typography>
        </ImageAndTextStyledBox>
        <ImageAndTextStyledBox>
          <img src={InboxIcon} alt="user name" />
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 500,
            }}
          >
            {customerData.type}
          </Typography>
        </ImageAndTextStyledBox>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "29px",
            paddingBottom: "20px",
            borderBottom: "1px solid #EEE",
          }}
        >
          <Box>
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 400,
              }}
            >
              Complaint
            </Typography>
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: 500,
              }}
            >
              {complaintData.reason}
            </Typography>
          </Box>
          <Box
            sx={{
              borderRadius: "4px",
              border: "1px solid #EEE",
              background: "#FBFBFB",
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              width: "282px",
              height: "76px",
              padding: "12px",
              marginRight: "63px",
            }}
          >
            {complaintData.complaint}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          marginLeft: "29px",
          marginTop: "24px",
          "& .customer-listing-status": {
            color: "#F6A846",
            fontFamily: "Poppins",
            fontSize: "14px",
            textTransform: "capitalize",
            fontStyle: "normal",
            fontWeight: "400",
            lineHeight: "normal",
            display: "inline-flex !important",
            padding: "4px 16px !important",
            justifyContent: "center !important",
            alignItems: "center",
            gap: "10px",
            boxSizing: "border-box !important",
            borderRadius: "20px",
            background: "rgba(246, 168, 70, 0.10)",
          },
          "& .customer-listing-status--resolved": {
            color: "#00AE26",
            background: "rgba(0, 174, 38, 0.10)",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", columnGap: "21px" }}>
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Status
          </Typography>
          <div
            className={`customer-listing-status ${String(complaintData.status) === "resolved"
              ? "customer-listing-status--resolved"
              : ""
              }`}
          >
            {complaintData.status}
          </div>
        </Box>
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            columnGap: "32px",
          }}
        >
          <div
            className="customer-listing-status"
            style={{ borderRadius: "20px", height: "40px" }}
          >
            Pending
            <Checkbox
              icon={<RadioButtonUncheckedIcon sx={{ color: "#F6A846" }} />}
              checkedIcon={<CheckCircleOutlineIcon sx={{ color: "#F6A846" }} />}
              checked={complaintStatus.pending}
              tabIndex={-1}
              onClick={() =>
                setComplaintStatus({
                  pending: !complaintStatus.pending,
                  resolved: complaintStatus.pending,
                })
              }
            />
          </div>
          <div
            className="customer-listing-status customer-listing-status--resolved"
            style={{ borderRadius: "20px", height: "40px" }}
          >
            Resolved
            <Checkbox
              icon={<RadioButtonUncheckedIcon sx={{ color: "#00AE26" }} />}
              checkedIcon={<CheckCircleOutlineIcon sx={{ color: "#00AE26" }} />}
              checked={complaintStatus.resolved}
              tabIndex={-1}
              onClick={() =>
                setComplaintStatus({
                  pending: complaintStatus.resolved,
                  resolved: !complaintStatus.resolved,
                })
              }
            />
          </div>
        </Box>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "10px",
            marginRight: "63px",
          }}
        >
          If the problem discussed above has been rectified, kindly change the
          status to 'resolved.
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
            }}
          >
            Note
          </Typography>
          <TextField
            sx={{
              borderRadius: "4px",
              background: "#FBFBFB",
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "14px",
              fontWeight: 400,
              width: "282px",
              padding: "12px",
              marginRight: "63px",
            }}
            fullWidth
            multiline
            rows={4}
            value={customerNote}
            onChange={(e) => setCustomerNote(e.target.value)}
          />
        </Box>
        <Button
          onClick={() => updateComplaintStatus()}
          sx={{
            width: "146px",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
            marginTop: "71px",
            marginBottom: "117px",
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            Save
          </Typography>
        </Button>
      </Box>
    </Box>
  ) : (
    <></>
  );
};

export default ComplaintsResolutionPanel;
