import React from 'react'
import { Box , Typography } from '@mui/material'

function Plans() {
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "space-between",
          padding: "12px",
          flexDirection: { xs: "column", md: "row" },
          borderBottom: "1px solid #eeeeee",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "baseline",
              gap: "4px",
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "24px",
                color: "#333333",
              }}
            >
              Next Payment
            </Typography>

            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "12px",
                lineHeight: "18px",
                color: "#707070",
              }}
            >
              (£25/month)
            </Typography>
          </Box>
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "14px",
              lineHeight: "21px",
              color: "#707070",
              marginTop: "8px",
            }}
          >
            on 25 Jan, 2024
          </Typography>
        </Box>
        <Box
          sx={{
            width: { xs: "100%", md: "342px" },
            marginTop: { xs: "40px", md: "0px" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
              }}
            >
              Days
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                fontWeight: "500",
                lineHeight: "20px",
                letterSpacing: "0em",
                textAlign: "left",
                paddingRight: { xs: "0px", md: "22.62px" },
              }}
            >
              26 of 30 days
            </Typography>
          </Box>
          <Box
            sx={{
              paddingRight: { xs: "0px", md: "22.62px" },
              marginTop: "8px",
              position: "relative",
            }}
          >
            <Box
              sx={{
                width: { xs: "100%", md: "319.69px" },
                height: "10px",
                backgroundColor: "#FEF1E1",
                borderRadius: "10px",
              }}
            ></Box>
            <Box
              sx={{
                width: { xs: "60%", md: "227.15px" },
                height: "8px",
                backgroundColor: "#F38B08",
                borderRadius: "10px",
                position: "absolute",
                left: "2.18px",
                top: "1px",
              }}
            ></Box>
          </Box>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "14px",
              lineHeight: "20px",
              color: "#707070",
              whiteSpace: { xs: "wrap", md: "nowrap" },
              marginTop: "12px",
            }}
          >
            4 days remaining until your plan requires update
          </Typography>
        </Box>
      </Box>
       {/* <PaymentPlans  /> */}
    </div>
  )
}

export default Plans