/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useContext, useEffect, useState } from "react";
import DeliveryGraph from "./deliveryGraph";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";

import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import Statistics from "./deliveryGraph/statistics";
import {
  DeliveryGraphType,
  DeliveryReportType,
  TopDeliveryType,
} from "../../../types/deliveryReports";
import { DeliveryReportsStatisticsData } from "../../constants/reportStatistics";
import {
  DELIVERY_GRAPH,
  DELIVERY_REPORTS,
  TOP_DELIVERY_REPORTS,
} from "../../../graphicalQl/usequery/deliveryReport";
import { convertSnakeCaseToPascalCase } from "../../../utils/stringUtils";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { localeToFormat } from "../../../utils/dateFormat";
import moment from "moment";
import { capitalizeFirstLetter } from "../../../pages/utils/capitalizeWord";
import { convertTimeToUTC } from "../../../utils/utcformat";
const options = [
  { label: "Location", value: "store_name" },
  { label: "Date", value: "date" },
  { label: "Driver", value: "driver" },
  { label: "Customer", value: "customer" },
  { label: "Channel", value: "channel" },
  { label: "Total", value: "total" },
];

const DeliveryReport = () => {
  const [employee, setEmployee] = useState<number>();
  // const [locationId, setLocationId] = useState<number>(44);
  const [topDelivery, setTopDelivery] = useState<Array<TopDeliveryType>>([]);
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Location", value: "store_name" },
    { label: "Date", value: "date" },
    { label: "Driver", value: "driver" },
    { label: "Customer", value: "customer" },
    { label: "Channel", value: "channel" },
    { label: "Total", value: "total" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "store_name",
    "date",
    "driver",
    "customer",
    "channel",
    "total",
  ]);
  const [deliveryGraph, setDeliveryGraph] = useState<Array<DeliveryGraphType>>(
    []
  );
  const [tableData, setTableData] = useState<Array<DeliveryReportType>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { loading: deliveryReportLoading, data: deliveryReportData } = useQuery(
    DELIVERY_REPORTS(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportLocation || +locationId,
      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const { loading: deliveryGraphLoading, data: deliveryGraphData } = useQuery(
    DELIVERY_GRAPH(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), reportLocation || +locationId, reportEmployee),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );

  const { loading: topDeliveryReportLoading, data: topDeliveryData } = useQuery(
    TOP_DELIVERY_REPORTS(
      date.startDate, date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),

      reportLocation || +locationId,
      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );

  const getDeliveryGraph = () => {
    if (!deliveryGraphLoading) {
      const graphDeliveriesReports = deliveryGraphData?.graphDeliveriesReports;
      const newSalesSummaryReport = JSON.parse(graphDeliveriesReports);
      setDeliveryGraph(newSalesSummaryReport);
    }
  };
  const getTopDelivery = () => {
    if (!topDeliveryReportLoading) {
      const topDelivery = topDeliveryData?.topDeliveriesReports;
      const newSalesSummaryReport = topDelivery && JSON.parse(topDelivery);
      const list = newSalesSummaryReport && newSalesSummaryReport.map((item: any) => {
        return {
          ...item,
          sales: `${currencySymbol} ${item.sales.toFixed(2)}`,
        };
      });
      setTopDelivery(list);
    }
  };
  const userLang = navigator.language;
  const currencySymbol = sessionStorage.getItem("currencySymbol")
  const getDeliveryReport = () => {
    if (!deliveryReportLoading) {
      const deliveriesReports = deliveryReportData?.deliveriesReports;
      const newSalesSummary = deliveriesReports && JSON.parse(deliveriesReports);

      const processedDeliveryReports = newSalesSummary?.map((delivery: any) => {
        return {
          store_name: delivery?.store_name ?? "",
          driver: delivery?.driver ?? "",
          customer: capitalizeFirstLetter(delivery?.customer_name),
          channel: convertSnakeCaseToPascalCase(delivery?.order_source),
          date: moment(delivery?.date_time).format(`${localeToFormat[userLang]}, hh:mm A`),
          total: `${currencySymbol} ${delivery?.total_amount.toFixed(2)}`,
        };
      });
      setTableData(processedDeliveryReports);
    }
  };
  useEffect(() => {
    getDeliveryGraph();
  }, [deliveryGraphLoading]);

  useEffect(() => {
    getTopDelivery();
  }, [topDeliveryReportLoading]);

  useEffect(() => {
    getDeliveryReport();
  }, [deliveryReportLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        employee={true}
        // setEmployee={setEmployee}
        setDate={setDate}
        date={date}
      // setFromTime={setFromTime}
      // setToTime={setToTime}
      />
      <Box
        mt={"20px"}
        pb={1}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <DeliveryGraph deliveryGraph={deliveryGraph} date={date} />

        <Statistics
          headers={DeliveryReportsStatisticsData.headers}
          topDelivery={topDelivery}
        />
      </Box>
      <Box mt={5}>
        <ReportsTable
          options={options}
          labels={labels}
          headers={headers}
          setHeaders={setHeaders}
          setLabels={setLabels}
          data={tableData}
          title={"Delivery Summary"}
        />
      </Box>
    </Box>
  );
};

export default DeliveryReport;
