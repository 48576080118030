import { Sheet } from "@mui/joy";
import { Box, Typography } from "@mui/material";
import DashboardShortCutIcon from "../../../assets/notifiactionIcons/DashboardShortCutIcon.svg";
import dashBoardShortCutBlockBooking from "../../../assets/notifiactionIcons/dashBoardShortCutBlockBooking.svg";
import DashboardShortCutIconRushHour from "../../../assets/notifiactionIcons/dashboardRushHour.svg";
import ReviewsAndRatingsIcon from "../../../assets/notifiactionIcons/reviewsRatingsIcon.svg";
import OrdersIcon from "../../../assets/notifiactionIcons/ordersIcon.svg";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
import React from "react";
import { useNavigate } from "react-router";
import { useTranslation } from "react-i18next";

interface State {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenBlockBookings: any;
  setOpenRushHour: any;
}

const ShortCutsModal = (props: State) => {
  const { t } = useTranslation();
  const { setOpen, setOpenBlockBookings, setOpenRushHour } = props;
  const navigate = useNavigate();
  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "100%",
        maxWidth: { xs: "70%", sm: "346.42px" },
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        paddingY: { xs: "20px", sm: "0px" },
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "12px",
          paddingRight: "15px",
          marginY: "12px",
        }}
      >
        <Typography
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            fontFamily: "poppins",
            fontWeight: "500",
            fontSize: "18px",
            lineHeight: "27px",
            color: "#333333",
            overflow: "hidden",
          }}
        >
          {t("dashboard.shortcuts")}
        </Typography>
        <img
          width={"18px"}
          height={"18px"}
          src={DashboardShortCutIcon}
          alt="DashboardShortCutIcon"
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: { xs: "360px", sm: "auto" },
          overflow: "auto",
          marginY: { sx: "20px", sm: "0px" },
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: {
              xs: "20px",
              sm: "0px",
              marginTop: { xs: "20px", sm: "0px" },
            },
          }}
        >
          <Box
            onClick={() => {
              setOpenBlockBookings(true);
              setOpen(false);
            }}
            sx={{
              width: "170px",
              border: "1px solid #EEEEEE",
              borderLeft: { xs: "1px solid #EEEEEE", md: "0px" },
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingY: "12px",
              backgroundColor: "white !important",
              "&:hover": {
                backgroundColor: "#FFF8F0 !important",
              },
            }}
          >
            <img
              src={dashBoardShortCutBlockBooking}
              alt="dashBoardShortCutBlockBooking"
            />
            <Box sx={{ marginTop: "23px" }}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                {t("dashboard.blockbookings")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#707070",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                {t("dashboard.rushhoursettings")}
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => {
              setOpenRushHour(true);
              setOpenBlockBookings(false);
              setOpen(false);
            }}
            sx={{
              width: "170px",
              border: "1px solid #EEEEEE",
              borderRight: { xs: "1px solid #EEEEEE", sm: "0px" },
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingY: "12px",
              backgroundColor: "white !important",
              "&:hover": {
                backgroundColor: "#FFF8F0 !important",
              },
            }}
          >
            <img
              src={DashboardShortCutIconRushHour}
              alt="DashboardShortCutIconRushHour"
            />
            <Box sx={{ marginTop: "23px" }}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                {t("dashboard.rushhour")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#707070",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                {t("dashboard.rushhoursettings")}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: { xs: "column", sm: "row" },
            gap: { xs: "20px", sm: "0px" },
          }}
        >
          <Box
            onClick={() => {
              setOpenBlockBookings(false);
              setOpen(false);
              navigate("/orders/orders-list");
            }}
            sx={{
              width: "170px",
              border: "1px solid #EEEEEE",
              borderRight: { xs: "1px solid #EEEEEE", sm: "0px" },
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingY: "12px",
              marginTop: { xs: "23px", sm: "0px" },
              backgroundColor: "white !important",
              "&:hover": {
                backgroundColor: "#FFF8F0 !important",
              },
            }}
          >
            <img src={OrdersIcon} alt="DashboardShortCutIconRushHour" />

            <Box sx={{ marginTop: "23px" }}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                {t("dashboard.orders")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#707070",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                {t("dashboard.orderslisting")}
              </Typography>
            </Box>
          </Box>
          <Box
            onClick={() => {
              setOpenBlockBookings(false);
              setOpen(false);
              navigate("/");
            }}
            sx={{
              width: "170px",
              border: "1px solid #EEEEEE",
              borderRight: { xs: "1px solid #EEEEEE", sm: "0px" },
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
              paddingY: "12px",
              backgroundColor: "white !important",
              "&:hover": {
                backgroundColor: "#FFF8F0 !important",
              },
            }}
          >
            <img
              src={ReviewsAndRatingsIcon}
              alt="DashboardShortCutIconRushHour"
            />
            <Box sx={{ marginTop: "23px" }}>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontWeight: "500",
                  fontSize: "16px",
                  lineHeight: "24px",
                  color: "#333333",
                  textAlign: "center",
                }}
              >
                {t("dashboard.reviewsratings")}
              </Typography>
              <Typography
                sx={{
                  fontFamily: "poppins",
                  fontSize: "14px",
                  lineHeight: "21px",
                  color: "#707070",
                  textAlign: "center",
                  marginTop: "3px",
                }}
              >
                {t("dashboard.Customer_Reviews")}
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Sheet>
  );
};

export default ShortCutsModal;
