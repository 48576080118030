import { Box } from "@mui/material";
import React from "react";
import EmployeeReport from "../../components/reports/employee";

const EmployeeReportPage = () => {
  return (
    <Box>
      <EmployeeReport />
    </Box>
  );
};
export default EmployeeReportPage;
