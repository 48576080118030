import { gql } from "@apollo/client";
// const businessId:string=sessionStorage.getItem("businessId") as string

export const DRIVER_COMMISSIONS_LIST = gql`
  query driverCommissions(
    $businessId: ID!
    $query: String
    $locationId: ID
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    driverCommissions(
      businessId: $businessId
      query: $query
      locationId: $locationId
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          commissionId
          date
          employeeId
          commissionId
          employee {
            firstName
            lastName
            id
          }
          commission
          locationId
        }
      }
    }
  }
`;

export const GETTING_DRIVER_DETAILS_FOR_UPDATE = gql`
  query DriverCommission($id: ID) {
    driverCommission(id: $id) {
      commission
      locationId
      employeeId
      employee {
        employeeId
        firstName
        lastName
      }
    }
  }
`;

export const DRIVER_PETTY_CASH_LIST = gql`
  query DriverPettycashList(
    $businessId: ID
    $locationId: ID
    $date: Date
    $query: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    driverPettycashList(
      businessId: $businessId
      locationId: $locationId
      date: $date
      query: $query
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          employeeId
          pettycashId
          employee {
            firstName
            lastName
          }
          date
          pettycash
          locationId
        }
      }
    }
  }
`;

export const DRIVERS_COMMISSIONS_HISTORY = gql`
  query driverCommissionHistory(
    $employeeId: ID
    $locationId: ID
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    driverCommissionHistory(
      employeeId: $employeeId
      locationId: $locationId
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          id
          commissionId
          employeeId
          date
          locationId
          employee {
            employeeId
            firstName
            lastName
          }
          commission
          locationId
        }
      }
    }
  }
`;

export const GETTING_DRIVER_DETAILS_IN_PETTY_CASH_FOR_UPDATE = gql`
  query DriverPettycash($id: ID) {
    driverPettycash(id: $id) {
      employeeId
      pettycash
      date
      locationId
      employeeId
      employee {
        employeeId
        firstName
        lastName
      }
    }
  }
`;

export const DELIVERY_LIST = gql`
  query storeList(
    $businessId: Int
    $query: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    storeList(
      businessId: $businessId
      query: $query
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          storeId
          storeName
          brandLogo

          id
        }
      }
    }
  }
`;
export const DRIVERS_LIST = gql`
  query Employees(
    $businessId: ID
    $query: String
    $locationId: ID
    $excludedEmployeeId: Int
  ) {
    employees(
      businessId: $businessId
      query: $query
      locationId: $locationId
      excludedEmployeeId: $excludedEmployeeId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          employeeId
          uuid
          firstName
          lastName
          mobile
          clockInAt
          clockOutAt
          permissions
          employeeStatus
          pin
          createdOn
          email
          role {
            roleId
            roleName
          }
          businessId
        }
      }
    }
  }
`;

export const DRIVERS_ASSIGNED_ORDERS_COUNT = gql`
  query driverCurrentOrderDetails($employeeList: JSONString) {
    driverCurrentOrderDetails(employeeList: $employeeList)
  }
`;

export const ASSIGNED_ORDERS_IN_DRIVERS_LIST = gql`
  query assignedOrders($businessId: ID) {
    assignedOrders(businessId: $businessId)
  }
`;

export const DELIVERY_RULES_LIST = gql`
  query DeliveryRules($settingId: ID, $distanceOrder: Boolean) {
    deliveryRules(settingId: $settingId, distanceOrder: $distanceOrder) {
      edges {
        node {
          deliveryRuleId
          zoneName
          areaName
          zoneShape
          colourCode
          postCode
          distance
          postCodesForZone
          cartAmount
          extraShippingRate
          shippingRate
          timeTaken
          deliveryTime
          zoneType
        }
      }
    }
  }
`;

export const GET_ACTIVE_ZONE = gql`
  query DeliverySettings($storeId: ID) {
    deliverySettings(storeId: $storeId) {
      store {
        storeId
      }
      paymentType
      deliverySettingId
      maximumDistance
      extraMileShippingRate
      rateForExtraMiles
      ordersPerHour
    }
  }
`;

export const CHECKBOX_LISTS = gql`
  query PaymentTypeList($businessId: ID, $orderType: String) {
    paymentTypeList(businessId: $businessId, orderType: $orderType)
  }
`;

export const DEFAULT_ZONE = gql`
  query getDefaultName($settingId: ID) {
    getDefaultName(settingId: $settingId)
  }
`;
