import {
  Box,
  Button,
  IconButton,
  Paper,
  TextField,
  Typography,
  Switch,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { useFormik } from "formik";
import * as Yup from "yup";
import "./editInventory.css";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  SELECTED_REASONS_LIST,
  GET_SINGLE_INVENTORY_DETAILS,
} from "../../graphicalQl/usequery/inventoryListsQuery";
import { LOCATIONS_LIST } from "../../graphicalQl/usequery/userProfileUseQuery";
import { UPDATE_SINGLE_INVENTORY } from "../../graphicalQl/mutation/inventoryListsMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  STOCK_HISTORY_LIST,
  INVENTORY_LIST,
} from "../../graphicalQl/usequery/inventoryListsQuery";
import CircularLoader from "../../loader/loader";

const ReasonsList = [
  {
    value: "stock_received",
    label: "Stock Received",
  },
  {
    value: "inventory_recount",
    label: "Inventory Recount",
  },
  {
    value: "damage",
    label: "Damage",
  },
  {
    value: "lost",
    label: "Lost",
  },
  {
    value: "restock_return",
    label: "Restock Return",
  },
  {
    value: "item_purchased",
    label: "Item Purchased",
  },
  {
    value: "order_rejected",
    label: "Order Rejected",
  },
];

const initialValuesOfCreateLocationFormContactImformation: State = {
  productName: null,
  variantName: null,
  sku: null,
  location: null,
  unit: null,
  price: null,
  barcode: null,
  reason: "stock_received",
  adjustment: null,
  stockCount: null,
  variantId: null,
  productId: null,
  isRecurringQuantity: false,
  recurringQuantity: null,
};

interface State {
  productName: string | null;
  variantName: string | null;
  sku: string | null;
  location: string | null;
  unit: string | null;
  price: string | null;
  barcode: string | null;
  reason: string;
  adjustment: number | null;
  stockCount: number | null;
  variantId: string | null;
  productId: string | null;
  isRecurringQuantity: boolean;
  recurringQuantity: string | null;
}

const validationSchemaContactImformation = Yup.object({
  adjustment: Yup.number().required("Quantity is required"),
  recurringQuantity: Yup.string().when("isRecurringQuantity", {
    is: (value: boolean) => value === true,
    then: (schema) => schema.required("Required"),
    otherwise: (schema) => schema.notRequired(),
  }),
});

const EditInventory = () => {
  const { t } = useTranslation();
  const { editId } = useParams();

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [reasonsList, setReasonsList] = useState<any[]>([]);
  const [locationsList, setLocationsList] = useState<any[]>([]);

  const [updateSingleStockkAdjustment] = useMutation(UPDATE_SINGLE_INVENTORY, {
    onCompleted: (data) => {
      if (data) {
        const { editInventory } = data;
        if (editInventory) {
          const { success } = editInventory;
          if (success) {
            navigate("/inventory/inventory-list");
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [STOCK_HISTORY_LIST, INVENTORY_LIST],
  });

  function collectVariantsName(data: any[]): string {
    // Initialize an array to collect names
    let names: string[] = [];

    // Iterate over the input array
    data.forEach((item) => {
      names.push(item.name);
    });

    // Join the collected names with commas and return

    return names.join(",");
  }

  const {
    loading: selectedReasonsDataError,
    error: selectedReasonsError,
    data: selectedReasonsdata,
  } = useQuery(SELECTED_REASONS_LIST, {
    context: { clientName: "categoryClient" },
    variables: { locationId: locationId && +locationId },
  });
  const {
    data: locationsData,
    loading: locationsLoading,
    error: locationsError,
  } = useQuery(LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });

  const {
    loading: singleInventoryLoading,
    error: singleInventoryError,
    data: singleInventoryData,
  } = useQuery(GET_SINGLE_INVENTORY_DETAILS, {
    context: { clientName: "categoryClient" },
    variables: { inventoryId: editId && +editId },
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (singleInventoryData) {
      const { inventory } = singleInventoryData;
      if (inventory) {
        const {
          barcode,
          isRecurring,
          product,

          sku,
          stockCount,
          variantDetails,
          locationId,
        } = inventory;
        if (variantDetails) {
          const parsedData = JSON.parse(variantDetails);
          const variantName = collectVariantsName(parsedData);
          formik.setFieldValue("variantName", variantName);
        } else {
          formik.setFieldValue("variantName", "N/A");
        }
        formik.setFieldValue(
          "recurringQuantity",
          inventory && inventory.recurringQuantity
        );
        if (product) {
          const { name, unit } = product;
          formik.setFieldValue("productName", name);
          formik.setFieldValue("unit", unit || "N/A");
        }
        formik.setFieldValue("sku", sku || "N/A");
        formik.setFieldValue("isRecurringQuantity", isRecurring);
        formik.setFieldValue("stockCount", stockCount || "0");
        formik.setFieldValue("adjustment", stockCount || "0");
        formik.setFieldValue("barcode", barcode || "N/A");
        const filterdList =
          locationsList &&
          locationsList.filter((each: any) => +each.storeId === locationId);
        formik.setFieldValue(
          "location",
          filterdList && filterdList[0] && filterdList[0].storeName
        );
      }
    }
  }, [singleInventoryData]);

  useEffect(() => {
    if (locationsData) {
      const requiredDataList = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
        })
      );
      setLocationsList(requiredDataList);
    }
  }, [locationsData]);

  useEffect(() => {
    if (selectedReasonsdata) {
      const { inventoryReasons } = selectedReasonsdata;
      if (inventoryReasons) {
        const { reasons } = inventoryReasons;
        const parsedReasons = JSON.parse(reasons);
        const filteredData = ReasonsList.filter((each: any) =>
          parsedReasons.includes(each.value)
        );
        setReasonsList(filteredData);
      }
    }
  }, [selectedReasonsdata]);

  const handleSaveButton = () => {
    const collectedData = {
      businessId: +businessId,
      locationId: locationId && +locationId,
      inventoryId: editId && +editId,
      stockQuantity: formik.values.adjustment && +formik.values.adjustment,
      // reason:formik.values.reason,
      reason: "inventory_recount",
      isRecurring: formik.values.isRecurringQuantity,
      recurringQuantity: formik.values.isRecurringQuantity
        ? formik.values.recurringQuantity && +formik.values.recurringQuantity
        : 0,
    };
    const filteredDataFromNull = Object.fromEntries(
      Object.entries(collectedData).filter(([_, value]) => value !== null)
    );

    updateSingleStockkAdjustment({ variables: filteredDataFromNull });
  };

  const formik = useFormik({
    initialValues: initialValuesOfCreateLocationFormContactImformation,
    validationSchema: validationSchemaContactImformation,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const navigate = useNavigate();

  const handleArrowBackIcon = () => {
    navigate("/inventory/inventory-list");
  };

  return (
    <Box sx={{ backgroundColor: "#f9f9f9", padding: "20px", height: "auto" }}>
      {singleInventoryLoading ? (
        <CircularLoader />
      ) : (
        <form onSubmit={formik.handleSubmit}>
          <Box sx={{ width: "100%", padding: "8px" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                margin: "10px",
              }}
            >
              <IconButton
                onClick={handleArrowBackIcon}
                className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
              >
                <ArrowBackIcon />
              </IconButton>
              <Typography className="edit-inventory-header-name">
                {t("inventory.edit_inventory")}
              </Typography>

              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                color="warning"
                variant="contained"
              >
                {t("inventory.save")}
              </Button>
            </Box>
          </Box>
          {/* <Divider/> */}
          <Box
            sx={{
              marginTop: "15px",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Paper elevation={1} sx={{ padding: "15px", width: "80%" }}>
              <Typography
                className="edit-inventory-details-header"
                sx={{ margin: "10px" }}
              >
                {t("inventory.details")}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "70%",
                  margin: "15px",
                }}
              >
                <TextField
                  color="warning"
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.product_name")}
                    </Typography>
                  }
                  name="productName"
                  value={formik.values.productName || ""}
                  disabled
                  sx={{ width: "35%" }}
                />

                <TextField
                  color="warning"
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.variant_name")}
                    </Typography>
                  }
                  name="variantName"
                  value={formik.values.variantName || "N/A"}
                  disabled
                  sx={{ width: "35%" }}
                />
                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.sku")}
                    </Typography>
                  }
                  name="sku"
                  value={formik.values.sku || "N/A"}
                  sx={{ width: "20%" }}
                  color="warning"
                  disabled
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "70%",
                  margin: "15px",
                }}
              >
                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.location")}
                    </Typography>
                  }
                  name="location"
                  value={formik.values.location || ""}
                  sx={{ width: "35%" }}
                  color="warning"
                  disabled
                />

                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.unit_per_item")}
                    </Typography>
                  }
                  name="unit"
                  value={formik.values.unit || ""}
                  sx={{ width: "35%" }}
                  color="warning"
                  disabled
                />

                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      Price
                    </Typography>
                  }
                  name="price"
                  value={formik.values.price}
                  sx={{ width: "20%" }}
                  color="warning"
                  disabled
                />
              </Box>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  width: "75%",
                  margin: "15px",
                }}
              >
                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.barcode")}
                    </Typography>
                  }
                  name="barcode"
                  value={formik.values.barcode || ""}
                  sx={{ width: "33%" }}
                  color="warning"
                  disabled
                />
                <TextField
                  id="outlined-required"
                  label={
                    <Typography className="edit-inventory-input-label">
                      {t("inventory.reason")}
                    </Typography>
                  }
                  defaultValue="Inventory Recount"
                  // onChange={(e) => {formik.handleChange(e) }}
                  // onBlur={formik.handleBlur}
                  name="stockCount"
                  // value={formik.values.stockCount}

                  sx={{ width: "33%" }}
                  color="warning"
                  disabled
                />

                <Box sx={{ width: "25.5%" }}>
                  <TextField
                    id="outlined-basic"
                    label={
                      <Typography className="edit-inventory-active-value-in-row">
                        {t("inventory.quantity")}
                      </Typography>
                    }
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (!val || /^[0-9]+$/.test(val)) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    placeholder="Quantity"
                    name="adjustment"
                    value={formik.values.adjustment || ""}
                    sx={{ width: "75%" }}
                    color="warning"
                  />
                  {formik.touched.adjustment && formik.errors.adjustment && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
                {/* <FormControl sx={{ width: '35%' }}>
              <InputLabel className='stock-adjustments-active-value-in-row' id="assign-role-label">Reason</InputLabel>
              <Select
                color='warning'
                labelId="assign-role-label"
                label={<Typography className='edit-inventory-active-value-in-row'>Reason</Typography>}
                defaultValue="stock_received"
                onChange={(e) => {formik.handleChange(e) }}
                onBlur={formik.handleBlur}
                name="reason"
                value={formik.values.reason}  >
                {reasonsList.map((each: any) => (
                  <MenuItem key={each.value} value={each.value}>
                    <Typography className='edit-inventory-active-value-in-row'>{each.label}</Typography>
                  </MenuItem>
                ))}
              </Select>
              </FormControl> */}
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "20%",
                    margin: "15px",
                  }}
                >
                  <Typography className="edit-inventory-recurring-text">
                    {t("inventory.recurring_stock")}
                  </Typography>
                  <Switch
                    checked={formik.values.isRecurringQuantity}
                    name="isRecurringQuantity"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    color="warning"
                  />
                </Box>
              </Box>
              {formik.values.isRecurringQuantity && (
                <Box sx={{ marginTop: "10px", marginLeft: "8px" }}>
                  <Typography
                    sx={{ marginLeft: "20px" }}
                    className="edit-inventory-paragraph-text"
                  >
                    {t("inventory.every_day")}
                  </Typography>
                  <TextField
                    id="outlined-required"
                    label={t("inventory.quantity")}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (!val || /^[0-9]+$/.test(val)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    type="text"
                    value={formik.values.recurringQuantity || ""}
                    name="recurringQuantity"
                    color="warning"
                    sx={{ width: "18%", marginLeft: "10px" }}
                  />
                  {formik.touched.recurringQuantity &&
                    formik.errors.recurringQuantity && (
                      <Box>
                        <Typography
                          sx={{ marginLeft: "10px" }}
                          className="create-a-user-formik-label"
                        >
                          {t("inventory.required")}

                        </Typography>
                      </Box>
                    )}
                </Box>
              )}
            </Paper>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default EditInventory;
