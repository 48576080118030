import {
  Box,
  Button,
  Typography,
  IconButton,
  Tooltip,
  Drawer,
  FormControlLabel,
  Checkbox,
  Paper,
  TextField,
  Modal,
  Divider,
} from "@mui/material";
import WeeklyStatus from "./weekklyStatus";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import DateRangeIcon from "@mui/icons-material/DateRange";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import LocationAlertPopup from "./locationComformationPopup";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_HOLIDAY,
  UPDATE_HOLIDAY,
} from "../../graphicalQl/mutation/locationMutation";
import {
  HOLIDAYS_LIST,
  GET_UPDATED_EDITED_DETAILS,
} from "../../graphicalQl/usequery/locationQuery";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface BusinessHoursState {
  dineIn: any[];
  store: any[];
  delivery: any[];
  collection: any[];
  setDeleteHolidayId: React.Dispatch<React.SetStateAction<string | null>>;
  setCollection: React.Dispatch<React.SetStateAction<any[]>>;
  setDineIn: React.Dispatch<React.SetStateAction<any[]>>;
  setDelivery: React.Dispatch<React.SetStateAction<any[]>>;
  setStore: React.Dispatch<React.SetStateAction<any[]>>;
  setAlertPopupOpen: React.Dispatch<React.SetStateAction<boolean>>;
  handleCreateStoreSaveButton: () => void;
  handleCreateStoreSaveButtonWithNavigate: () => void;
  deleteHolidayHandleOpen: () => void;
  editId: string | undefined;
  holidaysList: any[];
  createdStoreId: string | null;
  alertPopupOpen: boolean;
  conditions: boolean[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  setDrawerState: React.Dispatch<React.SetStateAction<boolean>>;
  drawerState: boolean;
  setCheckBoxEmptysetAlertPopup: React.Dispatch<React.SetStateAction<boolean>>;
  setComfirmationForClickOnAddHolidayButton: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  handleSaveButtonAfterStoreCreated: boolean;
  updateErrorState: React.Dispatch<React.SetStateAction<boolean>>;
  collectionCheckbox: boolean;
  deliveryCheckbox: boolean;
  dineInCheckbox: boolean;
  shopCheckbox: boolean;
  checkboxEmptyalertPopup: boolean;
}

const initialValuesOfAddHoliday = {
  holidayName: null,
  note: null,
  closeAllDay: true,
  fromTime: null,
  endTime: null,
  startDate: null,
  endDate: null,
  requiredStartDate: null,
  requiredEndDate: null,
};

const LocationBusinessHours = (props: BusinessHoursState) => {
  const {
    handleSaveButtonAfterStoreCreated,
    setComfirmationForClickOnAddHolidayButton,
    drawerState,
    dineInCheckbox,
    checkboxEmptyalertPopup,
    collectionCheckbox,
    deliveryCheckbox,
    shopCheckbox,
    setCheckBoxEmptysetAlertPopup,
    setDrawerState,
    conditions,
    handleCreateStoreSaveButtonWithNavigate,
    setActiveStep,
    alertPopupOpen,
    setAlertPopupOpen,
    createdStoreId,
    dineIn,
    store,
    editId,
    delivery,
    collection,
    deleteHolidayHandleOpen,
    setCollection,
    setDelivery,
    setDineIn,
    setStore,
    handleCreateStoreSaveButton,
    holidaysList,
    setDeleteHolidayId,
    updateErrorState,
  } = props;
  const [editHolidayId, setEditHolidayId] = useState<string | null>(null);
  const {
    loading: updatingHolidayDetailsLoading,
    error: updatingHolidayError,
    data: updatingHolidayDetailsData,
  } = useQuery(GET_UPDATED_EDITED_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { holidayId: editHolidayId && +editHolidayId },
    fetchPolicy: "network-only",
  });

  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { t } = useTranslation();
  const [createHoliday] = useMutation(CREATE_HOLIDAY, {
    onCompleted: (data) => {
      if (data) {
        const { addStoreHoliday } = data;
        if (addStoreHoliday) {
          const { success } = addStoreHoliday;
          if (success) {
            handleDrawerClose();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [HOLIDAYS_LIST],
  });

  const [alertPopup, setAlertPopup] = useState<boolean>(false);
 
  useEffect(() => {
    if (
      conditions[0] === true ||
      conditions[1] === true ||
      conditions[2] === true
    ) {
      setAlertPopup(true);
    } else if (
      !dineInCheckbox &&
      !collectionCheckbox &&
      !shopCheckbox &&
      !deliveryCheckbox
    ) {
      setCheckBoxEmptysetAlertPopup(true);
    }
  }, []);

  const index = conditions.findIndex((condition) => condition === true);
  let text = "";
  if (index === 0) {
    text = t("locations.basic_details");
  } else if (index === 1) {
    text = t("locations.business_address");
  } else if (index === 2) {
    text = t("locations.contact_information");
  }
  const handleAlertPopupCloseForSteps = () => {
    setAlertPopup(false);
    setActiveStep(index);
  };

  const [updateHoliday] = useMutation(UPDATE_HOLIDAY, {
    onCompleted: (data) => {
      if (data) {
        const { editStoreHoliday } = data;
        if (editStoreHoliday) {
          const { success } = editStoreHoliday;
          if (success) {
            handleDrawerClose();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [HOLIDAYS_LIST],
  });

  const handleAlertPopupClose = () => {
    setAlertPopupOpen(false);
    setComfirmationForClickOnAddHolidayButton(false);
  };

  const validationSchemaAddHoliday = Yup.object({
    holidayName: Yup.string().required("Holiday Name is required"),
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date must be greater than From Date"),
  });

  const handleCreateHolidayButton = () => {
    const createHolidayData = {
      storeId:
        editId === undefined
          ? createdStoreId && +createdStoreId
          : editId && +editId,
      business: +businessId,
      name: formikForAddHoliday.values.holidayName,
      dateRange: JSON.stringify({
        from_date: formikForAddHoliday.values.startDate,
        to_date: formikForAddHoliday.values.endDate,
      }),
      note: formikForAddHoliday.values.note,
      closeAllDay: formikForAddHoliday.values.closeAllDay,
      timeRange:
        formikForAddHoliday.values.fromTime &&
        formikForAddHoliday.values.endTime
          ? JSON.stringify({
              from_time: formikForAddHoliday.values.fromTime,
              to_time: formikForAddHoliday.values.endTime,
            })
          : null,
    };

    const filteredCreateData = Object.fromEntries(
      Object.entries(createHolidayData).filter(([_, value]) => value !== null)
    );

    createHoliday({
      variables: filteredCreateData,
    });
  };

  const handleEditHolidayButton = () => {
    const editHolidayData = {
      storeId:
        editId === undefined
          ? createdStoreId && +createdStoreId
          : editId && +editId,
      holidayId: editHolidayId,
      business: +businessId,
      name: formikForAddHoliday.values.holidayName,
      dateRange: JSON.stringify({
        from_date: formikForAddHoliday.values.startDate,
        to_date: formikForAddHoliday.values.endDate,
      }),

      note: formikForAddHoliday.values.note,
      closeAllDay: formikForAddHoliday.values.closeAllDay,
      timeRange:
        formikForAddHoliday.values.fromTime &&
        formikForAddHoliday.values.endTime
          ? JSON.stringify({
              from_time: formikForAddHoliday.values.fromTime,
              to_time: formikForAddHoliday.values.endTime,
            })
          : null,
    };

    const filteredEditData = Object.fromEntries(
      Object.entries(editHolidayData).filter(([_, value]) => value !== null)
    );

    updateHoliday({
      variables: filteredEditData,
    });
  };

  const formikForAddHoliday = useFormik({
    initialValues: initialValuesOfAddHoliday,
    validationSchema: validationSchemaAddHoliday,
    onSubmit: (values) => {
      if (editHolidayId) {
        handleEditHolidayButton();
      } else {
        handleCreateHolidayButton();
      }
    },
  });

  useEffect(() => {
    if (updatingHolidayDetailsData) {
      const { holiday } = updatingHolidayDetailsData;
      if (holiday) {
        const { closeAllDay, dateRange, name, note, timeRange } = holiday;
        formikForAddHoliday.setFieldValue("holidayName", name);
        formikForAddHoliday.setFieldValue("note", note);
        formikForAddHoliday.setFieldValue("closeAllDay", closeAllDay);

        if (timeRange) {
          const parsedTime = JSON.parse(timeRange);
          formikForAddHoliday.setFieldValue(
            "fromTime",
            parsedTime["from_time"]
          );
          formikForAddHoliday.setFieldValue("endTime", parsedTime["to_time"]);
          // formikForAddHoliday.setValues({...formikForAddHoliday.values,closeAllDay:closeAllDay,note,holidayName:name,endTime:parsedTime["to_time"],fromTime:parsedTime["from_time"]})
        }
        if (dateRange) {
          const parsedDate = JSON.parse(dateRange);
          formikForAddHoliday.setFieldValue(
            "startDate",
            parsedDate["from_date"]
          );
          formikForAddHoliday.setFieldValue("endDate", parsedDate["to_date"]);
        }
      }
    }
  }, [updatingHolidayDetailsData]);

  const CustomStyles = {
    "&.css-1aquho2-MuiTabs-indicator": {
      backgroundColor: "#F38B08",
    },

    "&.css-c1s559-MuiButtonBase-root-MuiTab-root.Mui-selected": {
      color: "#F38B08",
    },
  };

  const handleDrawerClose = () => {
    setDrawerState(false);
    setEditHolidayId(null);
    formikForAddHoliday.resetForm();
  };

  const [value, setValue] = React.useState("Store");

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleAddHolidayButton = () => {
    if (editId === undefined && !createdStoreId) {
      setAlertPopupOpen(true);
      setComfirmationForClickOnAddHolidayButton(true);
    } else {
      setDrawerState(true);
    }
  };

  const handleCloseIcon = () => {
    setDrawerState(false);
    setEditHolidayId(null);
    formikForAddHoliday.resetForm();
  };
  const handleEditIcon = (holidayId: string) => {
    setDrawerState(true);
    setEditHolidayId(holidayId);
  };

  const handleDeleteHolidayIcon = (holidayId: string) => {
    setDeleteHolidayId(holidayId);
    deleteHolidayHandleOpen();
  };
  const handleCheckEmptyPopupClose = () => {
    setCheckBoxEmptysetAlertPopup(false);
    setActiveStep(3);
  };

  return (
    <Box
      sx={{
        overflowY: "scroll",
        height: "67vh",
        WebkitOverflowScrolling: "touch",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
    >
      <LocationAlertPopup
        spantext={t("orders.payment_type")}
        beforeSpanText={t("locations.add_a")}
        afterSpanText={t("locations.to_the_orders")}
        handleClose={handleCheckEmptyPopupClose}
        open={checkboxEmptyalertPopup}
      />
      <LocationAlertPopup
        spantext={text}
        beforeSpanText={t("locations.please_enter")}
        afterSpanText={t("locations.to_continue_next")}
        handleClose={handleAlertPopupCloseForSteps}
        open={alertPopup}
      />
      <Box>
        <Modal
          open={alertPopupOpen}
          onClose={handleAlertPopupClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box
              sx={{
                position: "relative",
                textAlign: "center",
              }}
            >
              <Box className="close-icon-for-popup">
                <IconButton
                  onClick={handleAlertPopupClose}
                  className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
                >
                  <CloseIcon />
                </IconButton>
              </Box>

              <Typography className="save-store-to-add">
                {t("locations.save_location")}
              </Typography>
            </Box>

            <Divider
              sx={{ marginTop: "15px", marginBottom: "10px", width: "100%" }}
            />
            <Box
              sx={{
                textAlign: "center",
                marginTop: "15px",
                marginBottom: "15px",
                padding: "10px",
              }}
            >
              <Typography
                className="comfirmation-popup-text"
                sx={{ color: "#707070" }}
              >
                {t("locations.you_mmust_save")}
              </Typography>
            </Box>

            <Box sx={{ textAlign: "right", marginTop: "10px", padding: "5px" }}>
              <Button
                onClick={handleCreateStoreSaveButton}
                color="warning"
                variant="contained"
                sx={{ textTransform: "none" }}
              >
                {t("buttons.save")}
              </Button>
            </Box>
          </Box>
        </Modal>
      </Box>
      <Paper
        sx={{ borderLeft: "2px solid #E2A39B", padding: "15px", width: "90%" }}
        elevation={2}
      >
        <Box>
          <Typography
            sx={{ marginLeft: "15px" }}
            className="basic-details-heading"
          >
            {t("locations.business_hours")}
          </Typography>
          <Typography sx={{ marginLeft: "15px" }}>
            {t("locations.let_your_customers")}
          </Typography>
        </Box>

        <Box sx={{ width: "100%", typography: "body1", marginTop: "15px" }}>
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList
                sx={CustomStyles}
                onChange={handleChange}
                aria-label="lab API tabs example"
              >
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#F38B08 !important",
                    },
                  }}
                  label={t("locations.store")}
                  value="Store"
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#F38B08 !important",
                    },
                  }}
                  label={t("locations.dine_in")}
                  value="Dine In/Shop"
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#F38B08 !important",
                    },
                  }}
                  label={t("locations.collection")}
                  value="Collection"
                />
                <Tab
                  sx={{
                    textTransform: "none",
                    fontSize: "16px",
                    "&.Mui-selected": {
                      color: "#F38B08 !important",
                    },
                  }}
                  label={t("locations.delivery")}
                  value="Delivery"
                />
              </TabList>
            </Box>
            <TabPanel value="Store">
              {value === "Store" && (
                <WeeklyStatus
                  updateErrorState={updateErrorState}
                  data={store}
                  updateState={setStore}
                />
              )}
            </TabPanel>
            <TabPanel value="Delivery">
              {value === "Delivery" && (
                <WeeklyStatus
                  updateErrorState={updateErrorState}
                  data={delivery}
                  updateState={setDelivery}
                />
              )}
            </TabPanel>
            <TabPanel value="Collection">
              {value === "Collection" && (
                <WeeklyStatus
                  updateErrorState={updateErrorState}
                  data={collection}
                  updateState={setCollection}
                />
              )}
            </TabPanel>
            <TabPanel value="Dine In/Shop">
              {value === "Dine In/Shop" && (
                <WeeklyStatus
                  updateErrorState={updateErrorState}
                  data={dineIn}
                  updateState={setDineIn}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
        <Box sx={{ textAlign: "right", margin: "10px 5px 10px 5px" }}>
          {editId === undefined && (
            <Button
              sx={{ textTransform: "none" }}
              className="create-a-use-role-clear-button"
              variant="outlined"
              color="warning"
            >
              {t("buttons.clear")}
            </Button>
          )}
          <Button
            disabled={
              handleSaveButtonAfterStoreCreated ||
              !store.some((item) => item.selected === true)
            }
            onClick={handleCreateStoreSaveButtonWithNavigate}
            sx={{ textTransform: "none", marginLeft: "10px" }}
            variant="contained"
            color="warning"
          >
            {t("buttons.save")}
          </Button>
        </Box>
      </Paper>

      <Paper
        sx={{
          borderLeft: "2px solid #AE9BE2",
          marginTop: "15px",
          marginBottom: "10px",
          padding: "15px",
          width: "90%",
        }}
        elevation={2}
      >
        <Typography className="create-location-add-holiday">
          {t("locations.add_holiday")}
        </Typography>
        <Typography className="create-location-add-holiday-description">
          {t("locations.enable_this_toggle")}
        </Typography>

        <Box sx={{ marginTop: "20px" }}>
          {holidaysList &&
            holidaysList.map((eachHoliday: any) => (
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "space-between",
                  backgroundColor: "#fcfcfc",
                  borderRadius: "8px",
                  alignItems: "center",
                  padding: "8px",
                  border: "2px solid #EEEEEE",
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
              >
                <Box>
                  <Typography
                    sx={{ marginBottom: "6px", textTransform: "capitalize" }}
                    className="create-location-holiday-name"
                  >
                    {eachHoliday.holidayName}
                  </Typography>
                  <Typography
                    sx={{ marginBottom: "12px", textTransform: "capitalize" }}
                    className="create-location-holiday-description"
                  >
                    {eachHoliday.note}
                  </Typography>
                  <Box sx={{ display: "flex" }}>
                    <DateRangeIcon
                      sx={{ color: "#ababab", marginRight: "8px" }}
                    />
                    <Typography sx={{ marginLeft: "2px" }}>
                      {eachHoliday.dateRange &&
                        moment(eachHoliday.dateRange["from_date"])
                          .local()
                          .format("D - MMM - YYYY")}{" "}
                      to{" "}
                      {eachHoliday.dateRange &&
                        moment(eachHoliday.dateRange["to_date"])
                          .local()
                          .format("D - MMM - YYYY")}
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography className="business-hours-all-day-text">
                    {eachHoliday.closeAllDay
                      ? t("locations.all_day")
                      : eachHoliday.timeRange &&
                        `${eachHoliday.timeRange["from_time"]} to ${eachHoliday.timeRange["to_time"]}`}
                  </Typography>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <Tooltip title={t("buttons.delete")} placement="top" arrow>
                      <IconButton
                        onClick={() =>
                          handleDeleteHolidayIcon(eachHoliday.holidayId)
                        }
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>

                    <Tooltip title={t("buttons.edit")} placement="top" arrow>
                      <IconButton
                        onClick={() => handleEditIcon(eachHoliday.holidayId)}
                      >
                        <EditIcon />
                      </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              </Box>
            ))}
        </Box>

        <Box sx={{ textAlign: "right" }}>
          <Button
            disabled={!store.some((item) => item.selected === true)}
            onClick={handleAddHolidayButton}
            variant="contained"
            color="warning"
            sx={{ textTransform: "none" }}
          >
            {t("locations.add_holiday")}
          </Button>
        </Box>
        <Drawer anchor={"right"} open={drawerState} onClose={handleDrawerClose}>
          <form onSubmit={formikForAddHoliday.handleSubmit}>
            <Box sx={{ width: 415, padding: "20px" }}>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={handleCloseIcon}
                  className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
                >
                  <CloseIcon />
                </IconButton>
                <Typography className="create-location-add-holiday-heading-name">
                  {editHolidayId === null
                    ? t("locations.add_holiday")
                    : t("locations.edit_holiday")}
                </Typography>
                <Button
                  type="submit"
                  variant="contained"
                  color="warning"
                  sx={{ textTransform: "none" }}
                >
                  {editHolidayId === null
                    ? t("locations.add")
                    : t("buttons.save")}
                </Button>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "25px",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  sx={{ width: "92%" }}
                  name="holidayName"
                  value={formikForAddHoliday.values.holidayName || ""}
                  onChange={(e) => {
                    formikForAddHoliday.handleChange(e);
                  }}
                  onBlur={formikForAddHoliday.handleBlur}
                  label={t("locations.name")}
                  color="warning"
                />
                {formikForAddHoliday.touched.holidayName &&
                  formikForAddHoliday.errors.holidayName && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}

                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer
                    sx={{ width: "90%", marginTop: "10px" }}
                    components={["SingleInputDateRangeField"]}
                  >
                    <DateRangePicker
                      onChange={handleDateChange}
                      value={dateRange}
                      slots={{ field: SingleInputDateRangeField }}
                      slotProps={{
                        textField: {
                          InputProps: {
                            placeholder: "Enter date range",
                            endAdornment: <Calendar color="warning" />, // Add placeholder text
                          },
                        },
                      }}
                    />
                  </DemoContainer>
                </LocalizationProvider> */}

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "15px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "50%",
                    }}
                  >
                    <Typography>{t("locations.start_date")}</Typography>
                    <TextField
                      color="warning"
                      type="date"
                      name="startDate"
                      value={formikForAddHoliday.values.startDate || ""}
                      onChange={(e) => {
                        formikForAddHoliday.handleChange(e);
                      }}
                      onBlur={formikForAddHoliday.handleBlur}
                      sx={{ width: "90%" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "50%",
                    }}
                  >
                    <Typography>{t("locations.end_date")}</Typography>
                    <TextField
                      color="warning"
                      type="date"
                      name="endDate"
                      value={formikForAddHoliday.values.endDate || ""}
                      onChange={(e) => {
                        formikForAddHoliday.handleChange(e);
                      }}
                      onBlur={formikForAddHoliday.handleBlur}
                      sx={{ width: "90%" }}
                    />
                  </Box>
                </Box>
                {(formikForAddHoliday.touched.startDate &&
                  formikForAddHoliday.errors.startDate) ||
                (formikForAddHoliday.touched.endDate &&
                  formikForAddHoliday.errors.endDate) ? (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {formikForAddHoliday.errors.startDate ||
                        formikForAddHoliday.errors.endDate}
                    </Typography>
                  </Box>
                ) : null}

                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formikForAddHoliday.values.closeAllDay}
                        name="closeAllDay"
                        value={formikForAddHoliday.values.closeAllDay}
                        onChange={(e) => {
                          formikForAddHoliday.handleChange(e);
                        }}
                        onBlur={formikForAddHoliday.handleBlur}
                        color="warning"
                        sx={{ "& .MuiSvgIcon-root": { fontSize: "1.7rem" } }}
                      />
                    }
                    label={t("locations.close_all_days")}
                  />
                </Box>

                <Box
                  sx={{
                    width: "96%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "50%",
                    }}
                  >
                    <Typography>{t("locations.start_time")}</Typography>
                    <TextField
                      color="warning"
                      disabled={formikForAddHoliday.values.closeAllDay}
                      type="time"
                      name="fromTime"
                      value={formikForAddHoliday.values.fromTime || ""}
                      onChange={(e) => {
                        formikForAddHoliday.handleChange(e);
                      }}
                      onBlur={formikForAddHoliday.handleBlur}
                      sx={{ width: "90%" }}
                    />
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      width: "50%",
                    }}
                  >
                    <Typography>{t("locations.end_time")}</Typography>
                    <TextField
                      color="warning"
                      type="time"
                      name="endTime"
                      disabled={formikForAddHoliday.values.closeAllDay}
                      value={formikForAddHoliday.values.endTime || ""}
                      onChange={(e) => {
                        formikForAddHoliday.handleChange(e);
                      }}
                      onBlur={formikForAddHoliday.handleBlur}
                      sx={{ width: "90%" }}
                    />
                  </Box>
                </Box>

                <Box sx={{ width: "100%", marginTop: "15px" }}>
                  <TextField
                    color="warning"
                    name="note"
                    value={formikForAddHoliday.values.note || ""}
                    onChange={(e) => {
                      formikForAddHoliday.handleChange(e);
                    }}
                    onBlur={formikForAddHoliday.handleBlur}
                    placeholder={t("locations.add_note") || ""}
                    multiline
                    sx={{ width: "92%" }}
                    rows={3}
                  />
                </Box>
              </Box>
            </Box>
          </form>
        </Drawer>
      </Paper>
    </Box>
  );
};

export default LocationBusinessHours;
