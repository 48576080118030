import { gql } from "@apollo/client";
export const GETTING_API_KEYS=gql`
query business($businessId:ID){
business(
	businessId:$businessId
  ) {
businessId
				apiKeys
        
		  }
}

`
export const GETTING_LOCATIONS_LIST = gql`
  query (
    $businessId: Int
    $storeName_Icontains: String
    $storeStatus: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    storeList(
      businessId: $businessId
      storeName_Icontains: $storeName_Icontains
      storeStatus: $storeStatus
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          storeId
          locationType
          address
          mobile
          email
          addressId

          storeName
          brandLogo
          storeStatus
        }
      }
    }
  }
`;

export const GET_STORE_ADDRESS = gql`
  query addressList($locationId: ID) {
    addressList(locationId: $locationId) {
      edges {
        node {
          addressId
          address1
          address2
          addressType
          state
          landMark
          latitude
          longitude
          city
          country
        }
      }
    }
  }
`;

export const HOLIDAYS_LIST = gql`
  query holidayList($storeId: Int) {
    holidayList(storeId: $storeId) {
      edges {
        node {
          holidayId
          closeAllDay
          name
          dateRange
          timeRange
          note
        }
      }
    }
  }
`;

export const GET_UPDATED_EDITED_DETAILS = gql`
  query holiday($holidayId: ID) {
    holiday(holidayId: $holidayId) {
      closeAllDay
      note
      name
      dateRange
      timeRange
    }
  }
`;

export const GET_UPDATING_STORE_DETAILS = gql`
  query store($storeId: ID) {
    store(storeId: $storeId) {
      storeId
      addressId
      storeName
      localName
      brandLogo
      description
      locationType

      address

      email
      mobile
      twitter
      instagram
      website
      isDinein
      isShop
      isCollection
      isDelivery
      facebook

      paymentTypesForDinein
      paymentTypesForShop
      paymentTypesForDelivery
      paymentTypesForCollection

      dineinWeekSchedule
      shopWeekSchedule
      deliveryWeekSchedule
      collectionWeekSchedule
    }
  }
`;

export const GET_STORE_ADDRESS_DETAILS = gql`
  query address($addressId: ID) {
    address(addressId: $addressId) {
      addressId
      isDeleted
      address1
      address2
      addressType
      postCode
      latitude
      longitude
      state
      city
      country
    }
  }
`;

export const ADDRESS_LIST = gql`
  query addressList($businessId: ID) {
    addressList(businessId: $businessId) {
      edges {
        node {
          addressId
          address1
          addressId
          address2
          addressType
          state
          landMark
          latitude
          longitude
          city
          country
        }
      }
    }
  }
`;

export const GET_LOYALITY_POINTS_DATA = gql`
  query storeSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      allowLoyaltyPoints
      setExpiryForLoyalty
      loyaltyExpiryPeriod
      perPoundWhileAccumulation
      loyaltyPointsWhileAccumulation
      loyaltyPointsWhileRedumption
      equalentAmountWhileRedumption
      allowVipStatus
      tiers
      allowTimeAwayPoints
      timeAwayPoints
      timeAwayDays
      allowSignupPoints
      signupPoints
      allowBirthdayPoints
      birthdayPoints
      allowAnniversaryPoints
      anniversaryPoints
    }
  }
`;

export const GETTING_BULK_AND_RUSH_HOUR_SETTINGS = gql`
  query storeSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      rushHourSettings
      blockBookings
    }
  }
`;

export const GET_UPDATED_DATA_FOR_KITCHEN_SETTINGS = gql`
  query storeSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      kitchenCapacitySettings
      kdsSettings
      discountPercentagesForPos
      flatAmountTips
      percentageTips
      currency
      timeZone
      orderNumberingSettings
      orderStatusLookup
      cancellationBasedOn
      cancellationBasedOnStatus
      store {
        storeId
        deliveryWeekSchedule
        deliverySlotTime

        storeName
      }
      business {
        businessId
        brandName
      }
    }
  }
`;
