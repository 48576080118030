import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
  Box,
  IconButton,
  Typography,
  Button,
  Paper,
  Autocomplete,
  TextField,
  Switch,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
} from "@mui/material";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import React, { useEffect, useState } from "react";
import { ADD_DELIVERY_SETTINGS } from "../../graphicalQl/mutation/deliveryManagementMutation";

import {
  GET_ACTIVE_ZONE,
  CHECKBOX_LISTS,
} from "../../graphicalQl/usequery/deliveryManagementQuery";
import { useMutation, useQuery } from "@apollo/client";
// import RenderGoogleMap from "../utils/googleMaps";

import { t } from "i18next";
import "./deliveryZone.css";
import { useNavigate, useParams } from "react-router";
import { toast } from "react-toastify";
const initialValues = {
  extraMileShippingERates: false,
  rate: null,
  distance: null,
  limitPerOrder: null,
};

interface State {
  id: string | undefined;
  setDeliverySettingId: React.Dispatch<React.SetStateAction<string | null>>;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  deliverySettingId: string | null;
}
const DeliverySettings = (props: State) => {
  const { id, setDeliverySettingId, setValue, deliverySettingId } = props;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [updatedData, setUpdatedData] = useState<any>({});
  const {
    loading: deliveryRulesListLoading,
    error: deliveryRulesListError,
    data: deliveryRulesListData,
  } = useQuery(GET_ACTIVE_ZONE, {
    context: { clientName: "businessClient" },
    variables: { storeId: id && +id },
  });
  const {
    loading: checkboxListLoading,
    error: checkboxListError,
    data: checkboxListData,
  } = useQuery(CHECKBOX_LISTS, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  useEffect(() => {
    if (checkboxListData) {
      const { paymentTypeList } = checkboxListData;
      const parsedCheckboxList = JSON.parse(paymentTypeList);
      const filteredCheckList = parsedCheckboxList.map((each: any) => ({
        id: each,
        label: each,
        isChecked: false,
      }));
      setCheckboxList(filteredCheckList);
    }
  }, [checkboxListData]);

  // const [switchState, setSwitchState] = useState(false);
  const [checkboxList, setCheckboxList] = useState<any[]>([]);

  const [addDeliverySettings] = useMutation(ADD_DELIVERY_SETTINGS, {
    onCompleted: (data) => {
      if (data) {
        setUpdatedData(data);

        setValue("deliveryTypes");
        toast.success(
          <div>
            <Typography>
              {deliverySettingId === null
                ? t("toast.saved") 
                : t("toast.updated")}
            </Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GET_ACTIVE_ZONE],
  });

  useEffect(() => {
    if (updatedData) {
      const { addDeliverySettings } = updatedData;
      if (addDeliverySettings) {
        const { deliverySettings } = addDeliverySettings;
        if (deliverySettings) {
          const {
            extraMileShippingRate,
            maximumDistance,
            ordersPerHour,
            rateForExtraMiles,
          } = deliverySettings;
          // setSwitchState(extraMileShippingRate)
          formik.setValues({
            ...formik.values,
            extraMileShippingERates: extraMileShippingRate,
            distance: maximumDistance,
            rate: rateForExtraMiles,
            limitPerOrder: ordersPerHour,
          });
        }
      }
    }
  }, [updatedData]);

  useEffect(() => {
    if (deliveryRulesListData) {
      const { deliverySettings } = deliveryRulesListData;
      if (deliverySettings) {
        const {
          deliveryrulesSet,
          extraMileShippingRate,
          maximumDistance,
          deliverySettingId,
          ordersPerHour,
          paymentType,
          rateForExtraMiles,
        } = deliverySettings;
        const parsedCheckbox = JSON.parse(paymentType);
        setDeliverySettingId(deliverySettingId);
        const filteredCheckboxList: any = checkboxList.map(
          (eachCheckbox: any) => {
            if (parsedCheckbox.includes(eachCheckbox.label)) {
              return { ...eachCheckbox, isChecked: true };
            } else {
              return eachCheckbox;
            }
          }
        );
        setCheckboxList(filteredCheckboxList);
        // setSwitchState(extraMileShippingRate)
        formik.setValues({
          ...formik.values,
          extraMileShippingERates: extraMileShippingRate,
          distance: maximumDistance,
          rate: rateForExtraMiles,
          limitPerOrder: ordersPerHour,
        });
      }
    }
  }, [deliveryRulesListData]);

  const validationSchemaPart1 = Yup.object({
    rate: Yup.string().when("extraMileShippingERates", {
      is: (value: boolean) => value === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    distance: Yup.string().when("extraMileShippingERates", {
      is: (value: boolean) => value === true,
      then: (schema) => schema.required("Required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleFormSaveButton();
    },
  });
  const handleFormSaveButton = () => {
    const filteredDataFromNull = {
      businessId: +businessId,
      storeId: id && +id,
      rateForExtraMiles: formik.values.extraMileShippingERates
        ? formik.values.rate && +formik.values.rate
        : 0,
      maximumDistance: formik.values.extraMileShippingERates
        ? formik.values.distance && +formik.values.distance
        : 0,
      ordersPerHour: formik.values.limitPerOrder
        ? +formik.values.limitPerOrder
        : null,
      extraMileShippingRate: formik.values.extraMileShippingERates,
    };

    const filteredCategory = Object.fromEntries(
      Object.entries(filteredDataFromNull).filter(
        ([_, value]) => value !== null
      )
    );

    addDeliverySettings({
      variables: filteredCategory,
    });
  };

  // const handleSwitch=(e:React.ChangeEvent<HTMLInputElement>)=>{
  //     setSwitchState(e.target.checked)
  // }

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Paper
          elevation={2}
          sx={{
            width: "70%",
            padding: "15px",
            marginLeft: "20px",
            borderLeft: "2px solid #AE9BE2",
          }}
        >
          <Typography className="delivery-zone-details-text">
            {t("deliveries.details")}
          </Typography>
          <Box
            sx={{
              width: "28%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography className="delivery-zone-details-paragraph">
              {t("deliveries.extra_mile_shipping_rates")}
            </Typography>
            <Switch
              name="extraMileShippingERates"
              onChange={(e) => formik.handleChange(e)}
              checked={formik.values.extraMileShippingERates}
              color="warning"
            />
          </Box>
          {formik.values.extraMileShippingERates && (
            <Box
              sx={{
                marginTop: "15px",
                marginBottom: "15px",
                width: "75%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  <TextField
                    name="rate"
                    onBlur={formik.handleBlur}
                    label={t("deliveries.rate")}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.rate}
                    id="outlined-start-adornment"
                    sx={{ width: "85%" }}
                    color="warning"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">£</InputAdornment>
                      ),
                    }}
                  />
                </Box>

                {formik.touched.rate && formik.errors.rate && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {t("error.required")}
                    </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{ display: "flex", flexDirection: "column", width: "100%" }}
              >
                <Box sx={{ width: "100%" }}>
                  <TextField
                    name="distance"
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    value={formik.values.distance}
                    label={t("deliveries.maximum_distance")}
                    id="outlined-start-adornment"
                    sx={{ width: "85%" }}
                    color="warning"
                  />
                </Box>

                {formik.touched.distance && formik.errors.distance && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                    {t("error.required")}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          )}
          

          <Box
            sx={{
              marginTop: "10px",
              marginBottom: "10px",
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Typography
              sx={{ width: "25%" }}
              className="delivery-zone-details-paragraph"
            >
              {t("deliveries.limit_orders_per_hour")}
            </Typography>
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <TextField
                name="limitPerOrder"
                value={formik.values.limitPerOrder}
                onBlur={formik.handleBlur}
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                sx={{ width: "17%" }}
                color="warning"
                variant="outlined"
              />
              {/* {formik.touched.distance && formik.errors.limitPerOrder && (
                            <Box>
                                <Typography className='create-a-user-formik-label'>{formik.errors.limitPerOrder}</Typography>
                            </Box>
                            )} */}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              type="submit"
              sx={{ marginLeft: "10px", textTransform: "none" }}
              color="warning"
              variant="contained"
            >
              {t("buttons.save")}
            </Button>
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default DeliverySettings;
