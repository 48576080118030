import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import './error.css'
import ReportProblemSharpIcon from '@mui/icons-material/ReportProblemSharp';

interface Props {
  errorModal: boolean,
  statusCode: number,
  handleErrorModal: () => void,
}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function ErrorModal(props: Props) {
  const { errorModal, statusCode, handleErrorModal } = props
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    setOpen(errorModal)
  }, [errorModal])
  const handleClose = () => {
    handleErrorModal()
    setOpen(false)
  };

  let text;
  switch (statusCode) {
    case 409:
      text = "Oops!. Looks like a user with this info already exists on our server. Check your data and consider using unique details"
      break;
    case 401:
      text = "Oops! Access Denied. Double-check your username and password. If the issue persists, it might be a permission problem."
      break;
    case 500:
      text = "Oops! Internal Server Glitch. Our server ran into a hiccup. Check your request data and try again."
      break;
    case 404:
      text = "Oops! Can't Find What You're Looking For. Double-check the URL and make sure your request is spot on."
      break;
  };




  return (
    <div>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between" }}>
            <IconButton onClick={handleClose} className='exit-container '>
              <CloseIcon className='exitIcon' />
            </IconButton>
            <Typography className='menuModal1'>Warning</Typography>

            <Typography></Typography>
          </Box>
          <Divider sx={{ marginTop: "2%" }} />
          <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>

            <Box>

              <Box className="messageBox" sx={{ marginTop: "5%", alignItems: "center", }}>
                <ReportProblemSharpIcon color='error' />
                <Typography className='messageText'>{text}</Typography>
              </Box>
              <Box className="button-box" sx={{ marginTop: "5%" }}>
                <Button className="create-product-button" onClick={handleClose}>Close</Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}