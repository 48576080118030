import { useMutation, useQuery } from '@apollo/client'
import { Box, Button, Divider, IconButton, List, ListItem, ListItemText, Popover, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { GET_ONLINE_ORDERING_SETTINGS, ONLINE_ORDERING_SETTINGS } from '../../graphicalQl/mutation/onlineOrdering'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { useNavigate } from 'react-router';
import { MoreVert } from '@mui/icons-material';
import WebImage from '../../assets/menuQr/webImg.png'
import ArrowOutwardIcon from '@mui/icons-material/ArrowOutward';
import { useTranslation } from 'react-i18next';

const DomainsList = () => {
    const businessId = sessionStorage.getItem("businessId") as string

    const [domainsList, setDomainsList] = useState<any[]>([])

    const { data: settingsData } = useQuery(GET_ONLINE_ORDERING_SETTINGS, {
        context: { clientName: "businessClient" }, variables: {
            businessId: parseInt(businessId)
        }
    })

    const { t } = useTranslation()
    const navigate = useNavigate()
    useEffect(() => {
        if (settingsData && settingsData.business) {
            const list = [{
                id: 1, name: settingsData.business.domain, type: 'Primary Domain', sslType: settingsData.business.domainSslType
            },
            {
                id: 2, name: settingsData.business.subDomain, type: 'Secondary Domain', sslType: settingsData.business.subDomainSslType
            },
            ]

            if (!settingsData.business.domain) {
                navigate("/online/ordering-setUp")
            }

            const filterdlist = list.filter((each) => each.name !== null)
            setDomainsList(filterdlist)
        }
    }, [settingsData])
    const [upateDomain] = useMutation(ONLINE_ORDERING_SETTINGS, { context: { clientName: "businessClient" }, refetchQueries: [GET_ONLINE_ORDERING_SETTINGS] })

    function CustomPopoverCell({ menuId, }: { menuId: number, }) {
        const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

        const handleClick = (event: React.MouseEvent<HTMLElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const businessId = sessionStorage.getItem("businessId") as string

        const handleHistoryNavigate = () => {
            navigate(`/online/order-customize-domain`)
        }

        const handleDelete = () => {

            upateDomain({
                variables: {
                    businessId: parseInt(businessId),
                    ...(menuId === 1 ? { domain: null } : { subDomain: null })
                }
            });

        }




        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const popoverContent = (
            <List style={{ padding: '12px 0px 12px 0px', }}>

                <Button style={{ padding: '0px 0px 0px 0px', textTransform: "none" }} onClick={handleHistoryNavigate}>
                    <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
                        <ListItemText primary={t("online_ordering.edit")} />
                    </ListItem>
                </Button>
                <Divider />

                {menuId === 1 &&
                    <Box>
                        <Button style={{ padding: '0px 0px 0px 0px', textTransform: "none" }} onClick={handleDelete}>
                            <ListItem style={{ width: '180px', height: '40px' }} className='menu-Listing-pover-text-style'>
                                <ListItemText primary={t("online_ordering.delete")} />
                            </ListItem>
                        </Button>
                    </Box>}

            </List>
        );

        return (
            <Box>
                <div>
                    <IconButton onClick={handleClick}>
                        <MoreVert />
                    </IconButton>

                    <Popover
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handleClose}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        style={{ width: '200px', height: '340px', paddingTop: '0px', paddingBottom: '0px' }}
                        elevation={8}
                    >
                        {popoverContent}
                    </Popover>
                </div>
            </Box>
        );
    }

    return (
        <Box>
            <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "space-between", padding: "12px 24px" }}>
                <Typography className="main-head">{t("online_ordering.ordering_setUp")}</Typography>
                <Button sx={{
                    display: 'inline-flex',
                    height: '44px',
                    padding: '8px 16px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '24px',
                    flexShrink: 0,
                    borderRadius: '4px',
                    border: '1px solid var(--prime-orange-900, #F38B08)',
                    color: 'var(--prime-orange-900, #F38B08)',
                    fontFamily: 'Poppins',
                    fontSize: '16px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                    textTransform: "none"
                }} onClick={() => { navigate("/online/order-settings") }}>{t("online_ordering.ordering_settings")}</Button>
            </Box>

            <Divider />
            <Box sx={{ display: "flex", gap: "24px" }}>

                <TableContainer sx={{ width: '65%', margin: "24px", maxHeight: "180px", border: " 1px solid #EEE" }}>
                    <Table sx={{ width: "100%" }} aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell className='main-head16'>{t("online_ordering.domain_type")}</TableCell>
                                <TableCell align="center" className='main-head16'>{t("online_ordering.ssl_type")}</TableCell>
                                <TableCell align="center" className='main-head16'>{t("online_ordering.actions")}</TableCell>

                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {domainsList.map((row: any) => (
                                <TableRow
                                    key={row.name}
                                    sx={{ '&:last-child td, &:last-child th': { border: 0 }, height: '60px' }}
                                >
                                    <TableCell component="th" scope="row" sx={{ padding: "12px" }}>
                                        {
                                            <Box sx={{ display: "flex", gap: "8px" }}>
                                                <Typography className='url-text-style-grey ' > {row.name}</Typography>
                                                <Typography className={row.id === 2 ? "subDomain-background" : "domain-background"}> {row.type}</Typography>
                                            </Box>
                                        }

                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: "12px" }}

                                    >
                                        <Box className={row.id === 2 ? "sub-cloud-background" : "domain-cloud-background"}>
                                            <Typography> {row.sslType}</Typography>
                                        </Box>

                                    </TableCell>
                                    <TableCell align="center" sx={{ padding: "12px" }}>{
                                        <CustomPopoverCell
                                            menuId={row.id}


                                        />
                                    }</TableCell>

                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    sx={{
                        display: 'inline-flex',
                        padding: '24px',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        gap: '20px',
                        background: '#FBFBFB',
                        width: "25%"
                    }}
                >
                    <Typography className='basePrice-2'>{t("online_ordering.shop_ready")}</Typography>
                    <img src={WebImage} alt="webImg" style={{ marginTop: '12px', width: "100%" }} />
                    <Box sx={{ width: "100%", textAlign: "center" }}>
                        <Button className="main-button" >{t("online_ordering.live")}<ArrowOutwardIcon /> </Button>
                    </Box>
                </Box>
            </Box>

        </Box>
    )
}

export default DomainsList