import React, { useEffect, useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import ListItemText from "@mui/material/ListItemText";
import styled from "styled-components";
import { ReportsHeaderType } from "../../../types/reportsHeader";

interface Props {
  options: ReportsHeaderType[];
  headers: ReportsHeaderType[];
  labels?: string[];
  setHeaders?: (a: ReportsHeaderType[]) => void;
  setLabels?: (b: string[]) => void;
}

const ListWrapper = styled("div")({
  ".ListItem span": {
    color: "#333333",
    fontSize: "14px",
    marginLeft: "10px",
  },
  ".checkBox": {
    color: "#F38B08 !important",
  },
});

const FilterDropdown: React.FC<Props> = ({
  headers,
  setHeaders,
  setLabels,
  options,
}) => {
  const [dates, setDates] = useState<string[]>([]);
  const [optionsMain, setOptionsMain] = useState<any>([]);

  useEffect(() => {
    const tempOptions = options?.map((option) => {
      return {
        value: option.value,
        label: option.label,
        status: option.status,

        checked: headers.some((header) => header.value === option.value),
      };
    });
    setOptionsMain(tempOptions);
  }, [headers, options]);

  const handleChange = (isChecked: boolean, selectedValue: string) => {
    if (isChecked) {
      setDates([...dates, selectedValue]);
    } else {
      setDates(dates.filter((date) => date !== selectedValue));
    }
    const updatedOptions = optionsMain && optionsMain.map((option: any) => ({
      ...option,
      checked: option.value === selectedValue ? isChecked : option.checked,
    }));

    const updatedHeader = optionsMain?.filter((header: any) => {
      const checked =
        header.value === selectedValue ? isChecked : header.checked;

      return checked;
    });

    const newUpdatedHeader: ReportsHeaderType[] = updatedHeader?.map(
      (header: any) => {
        return {
          value: header.value,
          label: header.label,
        };
      }
    );
    const updatedLabels = newUpdatedHeader.map((option) => {
      return option.value;
    });
    setOptionsMain(updatedOptions);

    if (setHeaders) setHeaders(newUpdatedHeader);
    if (setLabels) setLabels(updatedLabels);

  };

  return (
    <ListWrapper>
      {optionsMain && optionsMain.map((option: any) => (
        <MenuItem
          key={option.value}
          value={option.value}
          sx={{ borderBottom: "1px solid #D9D9D9", paddingY: "10px" }}
          onClick={() => {
            handleChange(!option.checked, option.value);
          }}
        >
          <Checkbox
            checked={option.checked}
            onChange={(e) => handleChange(e.target.checked, option.value)}
            value={option.value}
            className="checkBox"
          />
          <label style={{ cursor: "pointer" }}>
            <ListItemText className="ListItem" primary={option.label} />
          </label>
        </MenuItem>
      ))}
    </ListWrapper>
  );
};

export default FilterDropdown;
