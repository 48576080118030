import { Box } from "@mui/material";
import React from "react";
import PaymentReport from "../../components/reports/payments";

const PaymentReportPage = () => {
  return (
    <Box>
      <PaymentReport />
    </Box>
  );
};
export default PaymentReportPage;
