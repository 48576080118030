import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import "../course/courseListing.css";
import { useEffect } from "react";
import { Divider } from "primereact/divider";
import { IconButton, TextField } from "@mui/material";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CREATE_REPORT_GROUP } from "../../graphicalQl/mutation/reportingMutation";
import { useMutation, useQuery } from "@apollo/client";
import { toast } from "react-toastify";
import CircularLoader from "../../loader/loader";
import { REPORTING_LIST } from "../../graphicalQl/usequery/reportingGroup";
import { GET_REPORTING_DATA } from "../../graphicalQl/usequery/reportingGroup";
import { UPDATE_REPORTING_GROUP } from "../../graphicalQl/mutation/reportingMutation";
import { useTranslation } from "react-i18next";
import ErrorModal from "../errorHandling/errorModal";

interface Props {
  dailouge: boolean;
  id: number | null;
  handleComplete: () => void;
  handleId: () => void;
}

const validationSchema = Yup.object({
  name: Yup.string().required("*Required"),
});
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40%",
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 1,
};

export default function CreateReportingGroup(props: Props) {
  const { dailouge, handleComplete, id, handleId } = props;
  const [open, setOpen] = React.useState(dailouge);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { t } = useTranslation();
  const [categoryStatusCode, setCategoryStatusCode] = React.useState<number>(0);
  const [errorModal, setErrorModal] = React.useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      if (id) {
        updateGroup({
          variables: { reportingGroupId: +id, name: values.name },
          onError: (error) => {
            // Error callback
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
        });
      } else {
        createReport({
          variables: { businessId: +businessId, name: values.name },
          onError: (error) => {
            // Error callback
            const graphqlError = error.graphQLErrors[0];

            if (
              graphqlError &&
              graphqlError.extensions &&
              graphqlError.extensions.status
            ) {
              // Extract the status code from the extensions property
              setCategoryStatusCode(
                parseInt(graphqlError.extensions.status, 10) || 500
              );
              setErrorModal(true);
            }
            const errorMessage = graphqlError.message || "An error occurred";
            console.error(
              `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
            );
          },
        });
      }
    },
  });

  const handleClose = () => {
    setOpen(false);
    handleComplete();
    formik.resetForm();
    handleId();
  };

  useEffect(() => {
    setOpen(dailouge);
  }, [dailouge]);

  const [createReport] = useMutation(CREATE_REPORT_GROUP, {
    context: { clientName: "categoryClient" },
    refetchQueries: [REPORTING_LIST],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      handleClose();
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });
  const [updateGroup] = useMutation(UPDATE_REPORTING_GROUP, {
    context: { clientName: "categoryClient" },
    refetchQueries: [REPORTING_LIST],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      handleClose();
    },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const { loading, data: reportData } = useQuery(GET_REPORTING_DATA, {
    context: { clientName: "categoryClient" },
    fetchPolicy: "network-only",
    variables: { reportingGroupId: id },
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  useEffect(() => {
    if (reportData && reportData.reportingGroup) {
      formik.setFieldValue("name", reportData.reportingGroup.name);
      setCharCount1(reportData.reportingGroup.name.length);
    }
  }, [reportData]);

  const [charCount1, setCharCount1] = React.useState(0);

  const updateCharCount1 = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 25) {
      event.target.value = event.target.value.substring(0, 25);
      count = 25;
    }
    // Update the character count state
    setCharCount1(count);
  };

  if (loading) {
    <CircularLoader />;
  }
  return (
    <div>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <IconButton className="exit-container" onClick={handleClose}>
                <CloseIcon className="exitIcon" />
              </IconButton>
              {id ? (
                <Typography className="course-modat-head">
                  {t("reporting_group.edit_a_reporting_group")}
                </Typography>
              ) : (
                <Typography className="course-modat-head">
                  {t("reporting_group.create_reporting_group")}
                </Typography>
              )}

              <Typography></Typography>
            </Box>
            <Divider />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "8%",
              }}
            >
              <div>
                <TextField
                  color="warning"
                  name="name"
                  onChange={(e) => {
                    if (e.target.value.length <= 25) {
                      formik.handleChange(e);

                      updateCharCount1(e);
                    }
                  }}
                  autoComplete="off"
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  value={formik.values.name}
                  label={t("reporting_group.name")}
                />
                <div
                  id="charCount"
                  className="char-count1"
                  style={{ width: "100%", textAlign: "right" }}
                >
                  {charCount1}/25
                </div>
                {/* {formik.touched.name && formik.errors.name && (
                  <div style={{ color: "red" }}>{formik.errors.name as string}</div>
                )} */}
              </div>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "2%",
              }}
            >
              <Button
                className="courses-button"
                sx={{ marginLeft: "auto" }}
                type="submit"
              >
                {t("reporting_group.save")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
    </div>
  );
}
