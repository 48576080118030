/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import Statistics from "./ordersGraph/statistics";
import { StatisticsData } from "../../constants/orderStatistics";
import TrendingUp from "../../../assets/bar-chart.png";
import OrdersGraph from "./ordersGraph";
import { useQuery } from "@apollo/client";
import {
  STORE_REPORTS,
  TOP_STORES,
} from "../../../graphicalQl/usequery/storesReport";
import { StoreReportType } from "../../../types/storeReports";
import { TopStoreType } from "../../../types/topStores";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { useSelector } from "react-redux";
import { convertTimeToUTC } from "../../../utils/utcformat";
import { GraphContext } from "../../../Contexts/GraphContext";

const options = [
  { label: "Store Name", value: "saleTstore_nameype" },
  { label: "New Customers", value: "customer_count" },
  { label: "Orders", value: "order_count" },
  { label: "Return/Refunded", value: "cancelled_orders_count" },
  { label: "Sales", value: "sales" },
];

const StoresReport = () => {

  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Store Name", value: "saleTstore_nameype" },
    { label: "New Customers", value: "customer_count" },
    { label: "Orders", value: "order_count" },
    { label: "Return/Refunded", value: "cancelled_orders_count" },
    { label: "Sales", value: "sales" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "store_name",
    "customer_count",
    "order_count",
    "cancelled_orders_count",
    "sales",
  ]);
  const [storeReports, setStoreReports] = useState<Array<StoreReportType>>([]);
  const [topStores, setTopStores] = useState<Array<TopStoreType>>([]);
  const [graphLabels, setGraphLabels] = useState<Array<string>>([]);
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  const { fromTime, toTime } = useContext(GraphContext);
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [locations, setLocations] = useState<string>("")

  useEffect(() => {
    if (locationId !== null) {
      setLocations(locationId);
    }
  }, [locationId]);
  const businessId: string = sessionStorage.getItem("businessId") as string
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);

  const { loading: topStoresLoading, data: topStoresData } = useQuery(
    TOP_STORES(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), businessId),
    {
      fetchPolicy: "no-cache",
    }
  );
  const { loading: storeReportsLoading, data: storeReportsData } = useQuery(
    STORE_REPORTS(date.startDate, date.endDate, fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime), businessId),
    {
      fetchPolicy: "no-cache",
    }
  );

  const currencySymbol = sessionStorage.getItem("currencySymbol");

  const getStoreReports = () => {
    if (!storeReportsLoading) {
      const storeReports = storeReportsData?.storeReports;
      const newStoreReports = JSON.parse(storeReports || "[]");
      const list = newStoreReports && newStoreReports.map((each: any) => {
        return {
          ...each,
          sales: `${currencySymbol} ${each.sales.toFixed(2)}`
        }
      })

      setStoreReports(list);
    }
  };

  const getTopStores = () => {
    if (!topStoresLoading) {
      const tempLabels: any = [];
      const tempData: any = [];
      const topStores = topStoresData?.topStores;
      const newtopStores = JSON.parse(topStores || "[]");
      const list = newtopStores && newtopStores.map((each: any) => {
        return {
          ...each,
          sales: `${currencySymbol} ${each.sales.toFixed(2)}`
        }
      })
      newtopStores?.map((store: TopStoreType) => {
        tempLabels.push(store?.store_name || store?.store_id);
        tempData.push(store?.sales);
        return "";
      });
      setTopStores(list);
      setGraphLabels(tempLabels);
      setGraphData(tempData);
    }
  };
  useEffect(() => {
    getStoreReports();
  }, [storeReportsLoading]);

  useEffect(() => {
    getTopStores();
  }, [topStoresLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
      // setLocations={setLocations}
      // locations={locations}
      />
      <Box
        mt={"18px"}
        p={3}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "9px",
            alignItems: "center",
          }}
        >
          <img
            height={44}
            width={44}
            src={TrendingUp}
            alt="trending"
            style={{ height: "44px", width: "44px" }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "20px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
              marginTop: "9px",
            }}
          >
            Stores
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Statistics headers={StatisticsData.headers} topStores={topStores} />
          <OrdersGraph datapoints={graphData} labels={graphLabels} />
        </Box>
      </Box>
      <Box mt={0}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={storeReports}
          title={"Stores"}
        />
      </Box>
    </Box>
  );
};

export default StoresReport;
