import * as React from "react";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import VerificationInput from "react-verification-input";
import MessageIcon from "@mui/icons-material/Message";
import CallIcon from "@mui/icons-material/Call";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
// import ReplayIcon from '@material-ui/icons/Replay';
import RefreshIcon from "@mui/icons-material/Refresh";
import CrossIcon from "../../../../assets/Frame 427319601.png";
import CheckIcon from "../../../../assets/check-circle.png";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { SENT_MOBILE_OTP, VERIFY_OTP } from "../../../../graphicalQl/mutation/signUp";
import { useMutation } from "@apollo/client";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";

interface Props {
  details: any
  verificationUser: any
  setMobileError: any
  setEmailError: any
}

const VarificationWrapper = styled("div")({
  position: "relative",
  ".container": {
    border: "1px solid #F38B08",
    borderRadius: "4px",
    paddingRight: "50px",
    maxWidth: "360px",
    width: "100%",
  },
  ".character": {
    border: "none",
    backgroundColor: "transparent",
    fontSize: "20px",
    width: "40px",
    color: "#707070",
  },
  "character--inactive": {
    backgroundColor: "transparent",
  },
  character: {},
});
export default function Verification(props: Props) {
  const { details, verificationUser, setEmailError, setMobileError } = props
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [submitMobileVarification, setSubmitMobileVarification] = React.useState("");
  const [submitEmailVarification, setSubmitEmailVarification] = React.useState("");

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [smsCounter, setSmsCounter] = React.useState(59);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [callCounter, setCallCounter] = React.useState(59);
  // eslint-disable-next-line react-hooks/rules-of-hooks

  const [mobileOtp, setMobileOtp] = React.useState("")
  const handleMobileOtpChange = (newOtp: any) => {
    setMobileOtp(newOtp);
  }

  const [emailOtp, setEmailOtp] = React.useState("")
  const handleEmailOtpChange = (newOtp: any) => {
    setEmailOtp(newOtp);
  }

  const [setMobileOtps] = useMutation(SENT_MOBILE_OTP, { context: { clientName: "userClient" } })
  React.useEffect(() => {
    const smsTimer = setInterval(() => {
      setSmsCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
    }, 1000);

    const callTimer = setInterval(() => {
      setCallCounter((prevCounter) => (prevCounter > 0 ? prevCounter - 1 : 0));
    }, 1000);

    return () => {
      clearInterval(smsTimer);
      clearInterval(callTimer);
    };
  }, []);

  const handleResendSMS = () => {
    setSmsCounter(59);
    setMobileOtps({
      variables: {
        mobile: `+${details.mobile}`,
        // businessId: parseInt(businessId),


      }
    })
  };

  const handleResendEmailOtp = () => {
    setMobileOtps({
      variables: {
        email: details.email,
        // businessId: parseInt(businessId),


      }
    })
  }

  const handleResendCall = () => {
    setCallCounter(59);
  };
  const { phoneCountryCode } = useSelector(
    (state: any) => state.headerLocation
  );
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
  };

  const [CheckMobileOtp] = useMutation(VERIFY_OTP, { context: { clientName: "userClient" } })
  const [CheckEmailOtp] = useMutation(VERIFY_OTP, { context: { clientName: "userClient" } })
  const businessId = sessionStorage.getItem("businessId") as string
  const handleCheckMobileOtp = (value: any) => {
    CheckMobileOtp({
      variables: {
        mobile: `+${details.mobile}`,
        // businessId: parseInt(businessId),
        otp: value

      },
      onCompleted: () => {
        setSubmitMobileVarification("yes")
      },
      onError: () => {
        setSubmitMobileVarification("no")
      }
    })
  }
  const handleCheckemailOtp = (value: any) => {
    CheckEmailOtp({
      variables: {
        email: details.email,

        // businessId: parseInt(businessId),
        otp: value

      },
      onCompleted: () => {
        setSubmitEmailVarification("yes")
      },
      onError: () => {
        setSubmitEmailVarification("no")
      }
    })
  }

  const navigate = useNavigate()

  const handleDataInSeesionStoarge = () => {
    sessionStorage.setItem("mobile", `+${details.mobile}`)
    sessionStorage.setItem("email", details.email)
    sessionStorage.setItem("name", details.name)
  }

  return (
    <Grid
      item
      width={"100%"}

      maxWidth={{ md: "45% !important", xs: "680px" }}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        paddingX: { xs: "20px", lg: "0px" },
        marginX: { xs: "auto", lg: "0" },
        height: '100vh',
        overflowY: "auto",
        "&::-webkit-scrollbar": {
          display: "none",
        },
      }}
      position={"relative"}
    >
      <Link
        sx={{
          borderRadius: "50px",
          backgroundColor: "#EEEEEE",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "44px",
          height: "44px",
          position: "absolute",
          top: "22px",
          left: { xs: "20px", md: "22px" },
          // marginLeft: "20px",
        }}
        onClick={() => {
          verificationUser(false);
          setEmailError(false);
          setMobileError(false);
        }}
      >
        <ArrowBackSharpIcon
          sx={{ width: "24px", height: "24px", color: "#707070" }}
        ></ArrowBackSharpIcon>
      </Link>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyItems: "center",
          width: "100%",
          maxWidth: { md: "463px", xs: "100%" },
          margin: "auto",
          boxShadow: "0",
        }}
      >
        <Box borderBottom={"1px solid #EEEEEE"}>
          <Typography
            component="h1"
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "28px", sm: "28px" },
              lineHeight: "24px",
              letterSpacing: "0.5px",
              color: "#333333",
              fontWeight: "600",
              padding: "0px",
              marginTop: { lg: "70px", md: "25px", xs: "120px" },
            }}
          >
            Verify Email
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "14px", sm: "16px" },
              lineHeight: "24px",
              letterSpacing: "0.5px",
              color: "#707070",
              fontWeight: "400",
              marginTop: "12px",
            }}
          >
            We’ve snt you a 4 digit code. Please check your e-mail and enter the
            code here to comlete the verification.
          </Typography>
          <Box



            sx={{ mt: "2vh" }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: "#333",
                  marginBottom: "1vh",
                }}
              >
                Enter verification code
              </Typography>
              <VarificationWrapper sx={{ maxWidth: "360px", width: "100%" }}>
                <VerificationInput
                  validChars="z0-9"
                  placeholder="-"
                  autoFocus={true}
                  length={4}
                  onComplete={handleCheckemailOtp}
                  onChange={handleEmailOtpChange}
                  classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",
                  }}
                ></VerificationInput>
                <button
                  className="refreshBtn"
                  style={{
                    backgroundColor: `${submitEmailVarification === "yes" ? "transparent" : "#eeeeee"
                      }`,
                  }}
                >
                  {submitEmailVarification === "yes" ? (
                    <div>
                      <img src={CheckIcon} alt="CrossIcon" />
                    </div>
                  ) : submitEmailVarification === "no" ? (
                    <div>
                      <img src={CrossIcon} alt="CrossIcon" />
                    </div>
                  ) : (
                    <RefreshIcon
                      sx={{ width: "24px", height: "24px", color: "#ABABAB" }}
                    />
                  )}
                </button>
              </VarificationWrapper>
            </Box>

            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "14px",
                lineHeight: "24px",
                letterSpacing: "0.5px",
                fontWeight: "400",
                marginTop: "2vh",
                marginBottom: "3vh",
                color: "#707070",
              }}
            >
              Didn’t receive verification code?
              <Link
                sx={{
                  fontFamily: "poppins",
                  fontSize: "16px",
                  paddingLeft: { xs: "1px", sm: "5px" },
                  lineHeight: "24px",
                  fontWeight: "600",
                  color: "#F38B08",
                  underline: "none",
                  textDecoration: "none",
                  cursor: "pointer",
                }}
                onClick={handleResendEmailOtp}
              >
                Resend code
              </Link>
            </Typography>
          </Box>
        </Box>

        <Box mt={"5vh"}>
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "Poppins",
              fontSize: { xs: "24px", sm: "28px" },
              lineHeight: "24px",
              letterSpacing: "0.5px",
              color: "#333333",
              fontWeight: "600",
            }}
          >
            Verify Phone Number
          </Typography>
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontSize: "16px",
              lineHeight: "24px",
              letterSpacing: "0.5px",
              color: "#707070",
              marginTop: "12px",
            }}
          >
            enter the code sent to your device for verification
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: "2vh" }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "16px",
                  color: "#333",
                  marginBottom: "1vh",
                }}
              >
                Enter verification code
              </Typography>

              <VarificationWrapper sx={{ maxWidth: "360px", width: "100%" }}>
                <VerificationInput
                  validChars="z0-9"
                  placeholder="-"

                  onComplete={handleCheckMobileOtp}
                  length={4}
                  onChange={handleMobileOtpChange}
                  classNames={{
                    container: "container",
                    character: "character",
                    characterInactive: "character--inactive",
                    characterSelected: "character--selected",
                    characterFilled: "character--filled",

                  }}

                />
                <button
                  className="refreshBtn"
                  style={{
                    backgroundColor: `${submitMobileVarification === "yes" ? "transparent" : "#eeeeee"
                      }`,
                  }}
                >
                  {submitMobileVarification === "yes" ? (
                    <div>
                      <img src={CheckIcon} alt="CrossIcon" />
                    </div>
                  ) : submitMobileVarification === "no" ? (
                    <div>
                      <img src={CrossIcon} alt="CrossIcon" />
                    </div>
                  ) : (
                    <RefreshIcon
                      sx={{ width: "24px", height: "24px", color: "#ABABAB" }}
                    />
                  )}
                </button>
              </VarificationWrapper>
            </Box>
          </Box>

          <Box sx={{ marginTop: "2vh", maxWidth: "420px", width: "100%" }}>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              borderBottom={"1px solid #eeeeee"}
              padding={"10px"}
            >
              <Box display={"flex"}>
                <MessageIcon
                  sx={{ width: "24px", height: "24px", color: "#ABABAB" }}
                ></MessageIcon>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: { xs: "12px", sm: "16px" },
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    color: "#707070",
                    paddingLeft: "10px",
                  }}
                >
                  Resend SMS
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: { xs: "12px", sm: "16px" },
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#707070",
                }}
              >
                {smsCounter === 0 ? (
                  <Link
                    className={` ${smsCounter === 0 ? "ResendCode" : ""} `}
                    onClick={handleResendSMS}
                  >
                    Resend Code
                  </Link>
                ) : (
                  `00:${smsCounter.toString().padStart(2, "0")}`
                )}
              </Typography>
            </Box>
            <Box
              display={"flex"}
              justifyContent={"space-between"}
              padding={"10px"}
            >
              <Box display={"flex"}>
                <CallIcon
                  sx={{ width: "24px", height: "24px", color: "#ABABAB" }}
                ></CallIcon>
                <Typography
                  sx={{
                    fontFamily: "Poppins",
                    fontSize: "16px",
                    lineHeight: "24px",
                    letterSpacing: "0.5px",
                    color: "#707070",
                    paddingLeft: "10px",
                  }}
                >
                  Call me
                </Typography>
              </Box>
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  fontSize: "16px",
                  lineHeight: "24px",
                  letterSpacing: "0.5px",
                  color: "#707070",
                }}
              >
                {callCounter === 0 ? (
                  <Link
                    className={` ${callCounter === 0 ? "ResendCode" : ""} `}
                    onClick={handleResendCall}
                  >
                    Call me
                  </Link>
                ) : (
                  `00:${callCounter.toString().padStart(2, "0")}`
                )}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box>
          <Button
            type="submit"
            fullWidth
            // variant="contained"
            // onClick={() => setSubmitVarification(true)}
            disabled={submitMobileVarification !== "yes" && submitEmailVarification !== "yes"}
            sx={{
              marginTop: "4vh",
              fontFamily: "poppins",
              fontSize: "18px",
              lineHeight: "27px",
              color: "#ffffff",
              fontWeight: "500",
              height: "48px",
              backgroundColor: (submitMobileVarification === "yes" && submitEmailVarification === "yes") ? "#F38B08" : "#D9D9D9",
              boxShadow: "none !important",
              ":hover": {
                backgroundColor: (submitMobileVarification === "yes" && submitEmailVarification === "yes") ? "#F38B08" : "#D9D9D9",

              },
            }}
            // className="butun"
            onClick={() => {
              navigate("/businessOnboarding");
              handleDataInSeesionStoarge()
            }}
          >
            Sign Up
          </Button>
          <Grid
            item
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "Poppins",
              fontSize: "14px",
              lineHeight: "24px",
              letterSpacing: "0.5px",
              gap: "5px",
              marginTop: "12px",
              color: "#707070",
            }}
          >
            Complete email and phone number verification to sign up
          </Grid>
          <Grid
            item
            sx={{
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
              justifyContent: "center",
              gap: "5px",
              marginTop: "4vh",
              paddingBottom: "20px",
            }}
          >
            <Typography
              sx={{
                fontfamily: "Poppins",
                fontSize: "12px",
                lineHeight: "24px",
                color: "#707070",
              }}
            >
              Need assistance or have questions?
            </Typography>
            <Link
              component={RouterLink}
              to="#"
              variant="body2"
              sx={{
                fontfamily: "Poppins",
                fontSize: "12px",
                lineHeight: "24px",
                color: "#F5431C",
                fontWeight: "500",
                textDecoration: "none",
              }}
            >
              {"Contact Support"}
            </Link>
          </Grid>
        </Box>
      </Box>
    </Grid>
  );
}
