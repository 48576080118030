/* eslint-disable react-hooks/exhaustive-deps */

import { Box, Typography } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import Statistics from "./categoriesGraph/statistics";
import TrendingUp from "../../../assets/bar-chart.png";
import { useQuery } from "@apollo/client";

import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { CategoryStatisticsData } from "../../constants/reportStatistics";
import PieChart from "../pieChartReports";
import {
  CategoryReportType,
  TopCategoryType,
} from "../../../types/categoryReports";
import {
  CATEGORY_REPORTS,
  TOP_CATEGORY_REPORTS,
} from "../../../graphicalQl/usequery/categoryReport";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Category Name", value: "category_name" },
  { label: "Menu", value: "menu" },
  { label: "Quantity", value: "quantity" },
  { label: "Return/Refunded", value: "refund" },
  { label: "Amount", value: "amount" },
];

const CategoryReport = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Category Name", value: "category_name" },
    // { label: "Menu", value: "menu" },
    { label: "Quantity", value: "quantity" },
    { label: "Return/Refunded", value: "refund" },
    { label: "Amount", value: "amount" },
  ]);

  const [labels, setLabels] = useState<Array<string>>([
    "category_name",
    // "menu",
    "quantity",
    "refund",
    "amount",
  ]);
  const [categoryReports, setProductReports] = useState<
    Array<CategoryReportType>
  >([]);
  // const [locationId, setLocationId] = useState<number>(6);
  const [topCategories, setTopCategories] = useState<Array<TopCategoryType>>(
    []
  );
  const [graphLabels, setGraphLabels] = useState<Array<string>>([]);
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)

  const [employee, setEmployee] = useState<number>();
  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { loading: topCategoryLoading, data: topCategoryData } = useQuery(
    TOP_CATEGORY_REPORTS(
      date.startDate,
      date.endDate,
      fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
      reportLocation || +locationId,
      reportEmployee
    ),
    {
      fetchPolicy: "no-cache",
      context: { clientName: "orderClient" },
    }
  );
  const { loading: categoryReportsLoading, data: categoryReportsData } =
    useQuery(
      CATEGORY_REPORTS(
        date.startDate,
        date.endDate,
        fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),
        reportLocation || +locationId,
        reportEmployee
      ),
      {
        fetchPolicy: "no-cache",
        context: { clientName: "orderClient" },
      }
    );
  const currencySymbol = sessionStorage.getItem("currencySymbol")

  const getCategoryReports = () => {
    if (!categoryReportsLoading) {
      const categoryReports = categoryReportsData?.categoriesReports;
      const newCategoryReports = JSON.parse(categoryReports || "[]");
      const list = newCategoryReports && newCategoryReports.map((each: any) => {
        return {
          ...each,
          amount: `${currencySymbol} ${each.amount}`
        }
      })
      setProductReports(list);
    }
  };

  const getTopCategory = () => {
    if (!topCategoryLoading) {
      const tempLabels: any = [];
      const tempData: any = [];
      const topCategories = topCategoryData?.topCategoriesReports;
      const newtopCategory = JSON.parse(topCategories || "[]");
      newtopCategory?.map((store: any) => {
        tempLabels.push(store?.category_name);
        tempData.push(store?.sales);
        return "";
      });
      const list = newtopCategory && newtopCategory.map((each: any) => {
        return {
          ...each,
          sales: `${currencySymbol} ${each.sales}`
        }
      })
      setTopCategories(list);
      setGraphLabels(tempLabels);
      setGraphData(tempData);
    }
  };
  useEffect(() => {
    getCategoryReports();
  }, [categoryReportsLoading]);

  useEffect(() => {
    getTopCategory();
  }, [topCategoryLoading]);
  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box
        mt={"18px"}
        p={3}
        sx={{
          boxShadow: "0px 2px 12px 0px #0000000A",
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            gap: "9px",
            alignItems: "center",
          }}
        >
          <img
            height={44}
            width={44}
            src={TrendingUp}
            alt="trending"
            style={{ height: "44px", width: "44px" }}
          />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: { xs: "20px", sm: "24px" },
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
              marginTop: "9px",
            }}
          >
            Categories
          </Typography>
        </Box>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Statistics
            headers={CategoryStatisticsData.headers}
            topCategories={topCategories}
          />
          <PieChart datapoints={graphData} labels={graphLabels} />
        </Box>
      </Box>

      <Box mt={4}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={categoryReports}
          title={"Categories"}
        />
      </Box>
    </Box>
  );
};

export default CategoryReport;
