import { Box, Button, Typography, Paper, TextField } from "@mui/material";
import GoogleMapForDistance from "../utils/googleMapForDistance";
import LocationAlertPopup from "./locationComformationPopup";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

interface BusinessHoursState {
  formik: any;
  editId: string | undefined;
  conditions: boolean[];
  setActiveStep: React.Dispatch<React.SetStateAction<number>>;
  handleClearButtonInBusinessAddress: () => void;
  locationKey: string | null;
  formikForBasicdetails: any;
  setConditions: React.Dispatch<React.SetStateAction<any[]>>;
}
const LocationBusinessAddress = (props: BusinessHoursState) => {
  const {
    formik,
    locationKey,
    editId,
    conditions,
    setActiveStep,
    handleClearButtonInBusinessAddress,
    formikForBasicdetails,
    setConditions,
  } = props;

  const [alertPopup, setAlertPopup] = useState<boolean>(false);
  const { t } = useTranslation();
  useEffect(() => {
    const controller = new AbortController();
    const getPinData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.postCode}&key=${locationKey}`
        );
        const data = await response.json();

        if (data) {
          if (data.status === "OK") {
            const { results } = data;
            if (results) {
              const { address_components } = results[0];
              console.log(address_components, "address_components");
              const extractCityAndState = (addressComponents: any) => {
                let city = "";
                let state = "";
                let country = "";

                addressComponents.forEach((component: any) => {
                  if (
                    component.types.includes("postal_town") ||
                    component.types.includes("locality")
                  ) {
                    city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    state = component.long_name;
                  } else if (component.types.includes("country")) {
                    country = component.long_name;
                  }
                });

                return { city, state, country };
              };

              const { city, state, country } =
                extractCityAndState(address_components);
              formik.setFieldValue("city", city);
              formik.setFieldValue("state", state);
              formik.setFieldValue("country", country);
            }
          }
        }
      } catch (error) {
        console.log(error);
      }
    };

    getPinData();

    return () => {
      controller.abort();
    };
  }, [formik.values.postCode]);

  useEffect(() => {
    if (
      (conditions[0] === true &&
        formikForBasicdetails.values.branchName === "") ||
      (conditions[0] === true &&
        formikForBasicdetails.values.branchName === null)
    ) {
      setAlertPopup(true);
    } else {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[0] = false;
        return updatedConditions;
      });
    }
  }, []);

  const handleAlertPopupClose = () => {
    setAlertPopup(false);
    setActiveStep(0);
  };

  const handleAddressLine1 = (name: string) => {
    if (name === "") {
      setConditions((prevConditions) => {
        const updatedConditions = [...prevConditions];
        updatedConditions[1] = true;
        return updatedConditions;
      });
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <LocationAlertPopup
        spantext={t("locations.basic_details")}
        beforeSpanText={t("locations.please_enter")}
        afterSpanText={t("locations.to_continue_next")}
        handleClose={handleAlertPopupClose}
        open={alertPopup}
      />
      <Paper
        elevation={2}
        sx={{
          width: "90%",
          borderLeft: "2px solid #9BDAE2",
          padding: "15px",
          // marginTop: "10px",
        }}
      >
        <Typography className="basic-details-heading">
          {t("locations.business_address")}
        </Typography>
        <form onSubmit={formik.handleSubmit}>
          <Box className="form-and-map-order">
            <Box
              sx={{ display: "flex", flexDirection: "column", width: "100%" }}
            >
              <TextField
                id="outlined-basic"
                color="warning"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.postCode || ""}
                name="postCode"
                sx={{ width: "80%", margin: "10px 0px 10px 0px" }}
                label={<Typography>{t("locations.post_code")}</Typography>}
                variant="outlined"
              />

              <TextField
                onChange={(e) => {
                  formik.handleChange(e);
                  handleAddressLine1(e.target.value);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.address1 || ""}
                color="warning"
                name="address1"
                sx={{ width: "80%", margin: "" }}
                label={<Typography>{t("locations.address_line_1")}</Typography>}
                placeholder={t("locations.house_or_flat") || ""}
                variant="outlined"
              />
              {formik.touched.address1 && formik.errors.address1 && (
                <Box>
                  <Typography className="create-a-user-formik-label">
                    {t("error.required")}
                  </Typography>
                </Box>
              )}
              <TextField
                id="outlined-basic"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.address2 || ""}
                name="address2"
                color="warning"
                placeholder={t("locations.apartment_or_road") || ""}
                sx={{ width: "80%", margin: "20px 0px 10px 0px" }}
                label={<Typography>{t("locations.address_line_2")}</Typography>}
                variant="outlined"
              />

              <TextField
                id="outlined-basic"
                color="warning"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                label={t("locations.city")}
                onBlur={formik.handleBlur}
                value={formik.values.city || ""}
                name="city"
                sx={{ width: "80%", margin: "10px 0px 10px 0px" }}
                variant="outlined"
              />

              <TextField
                id="outlined-basic"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                onBlur={formik.handleBlur}
                value={formik.values.state || ""}
                label={t("locations.state")}
                name="state"
                sx={{ width: "80%", margin: "10px 0px 10px 0px" }}
                color="warning"
                variant="outlined"
              />
            </Box>

            <Box>
              <div className="mapContainer" id="map-canvas">
                <GoogleMapForDistance
                  locationKey={locationKey}
                  formik={formik}
                  height="350px"
                  width="100%"
                />
              </div>
            </Box>
          </Box>
          <Box sx={{ textAlign: "right", margin: "10px 5px 10px 5px" }}>
            {editId === undefined && (
              <Button
                sx={{ textTransform: "none" }}
                onClick={handleClearButtonInBusinessAddress}
                className="create-a-use-role-clear-button"
                variant="outlined"
                color="warning"
                type="button"
              >
                {t("buttons.clear")}
              </Button>
            )}
            <Button
              type="submit"
              sx={{ textTransform: "none", marginLeft: "10px" }}
              variant="contained"
              color="warning"
            >
              {editId === undefined ? t("product.next") : t("buttons.save")}
            </Button>
          </Box>
        </form>
      </Paper>
    </Box>
  );
};

export default LocationBusinessAddress;
