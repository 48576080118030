import { Box, Paper, Typography, Button } from "@mui/material";
import React from "react";

import { useTranslation } from "react-i18next";

import { useState } from "react";
import AreaWise from "./areaWise";
import DistanceOrZoneWise from "./distanceOrZoneWise";
import DistanceWise from "./distanceWise";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import {
  DEFAULT_ZONE,
  DELIVERY_RULES_LIST,
} from "../../graphicalQl/usequery/deliveryManagementQuery";
import { toast } from "react-toastify";
import {
  ADD_DELIVERY_RULE,
  UPDATE_DELIVERY_RULE,
} from "../../graphicalQl/mutation/deliveryManagementMutation";

interface Location {
  lat: number;
  lng: number;
}

interface State {
  deliverySettingId: string | null;
  editId: undefined | string;
  storeLocation: Location | null;
  locationKey: string | null;
}

const DeliveryRulesAndMap = (props: State) => {
  const { t } = useTranslation();
  const { deliverySettingId, editId, storeLocation, locationKey } = props;
  const [rowsForArea, setRowsForArea] = useState<any[]>([]);
  const [rowsForDistance, setRowsForDistance] = useState<any[]>([]);
  const [rowsForZone, setRowsForZone] = useState<any[]>([]);
  const [handleEditFlowBoolean, setHandleEditFlowBoolean] =
    useState<boolean>(true);
  const [updatedId, setUpdatedId] = useState<string | null>(null);
  const [editRowId, setEditRowId] = useState<string | null>(null);

  const {
    loading: defaultZoneLoading,
    error: defaultZoneError,
    data: defaultZone,
  } = useQuery(DEFAULT_ZONE, {
    context: { clientName: "businessClient" },
    variables: { settingId: deliverySettingId && +deliverySettingId },
  });
  const {
    loading: deliveryRulesListLoading,
    error: deliveryRulesListError,
    data: deliveryRulesListData,
  } = useQuery(DELIVERY_RULES_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      settingId: deliverySettingId && +deliverySettingId,
      distanceOrder: true,
    },
  });

  useEffect(() => {
    if (deliveryRulesListData) {
      const { deliveryRules } = deliveryRulesListData;
      const { edges } = deliveryRules;
      const filteredData =
        edges &&
        edges.length > 0 &&
        edges.map((eachRule: any) => ({
          areaName: eachRule.node.areaName,
          cartAmount: eachRule.node.cartAmount,
          color: eachRule.node.colourCode,
          deliveryRuleId: eachRule.node.deliveryRuleId,
          deliveryTime: JSON.parse(eachRule.node.deliveryTime),
          startTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["from_time"],
          endTime:
            JSON.parse(eachRule.node.deliveryTime) &&
            JSON.parse(eachRule.node.deliveryTime)["to_time"],
          distance: eachRule.node.distance,
          originalDistance: eachRule.node.distance,
          extraShippingRate: eachRule.node.extraShippingRate,
          postCode: eachRule.node.postCode,
          postCodesForZone: eachRule.node.postCodesForZone
            ? eachRule.node.postCodesForZone
            : [],
          shippingRate: eachRule.node.shippingRate,
          timeTaken: eachRule.node.timeTaken,
          zoneName: eachRule.node.zoneName,
          zoneShape: eachRule.node.zoneShape,
          zoneType: eachRule.node.zoneType,
          isEdited: false,
          isNew: false,
          isVisible: false,
        }));

      const filteredZonesList =
        filteredData &&
        filteredData.filter((eachZone: any) => eachZone.zoneType === "zone");
      setRowsForZone(filteredZonesList);
    }
  }, [deliveryRulesListData]);

  const {
    loading: deliveryRulesListLoadingForDistance,
    error: deliveryRulesListErrorForDistance,
    data: deliveryRulesListDataForDistace,
  } = useQuery(DELIVERY_RULES_LIST, {
    context: { clientName: "businessClient" },
    variables: {
      settingId: deliverySettingId && +deliverySettingId,
      distanceOrder: true,
    },
  });
  useEffect(() => {
    if (deliveryRulesListDataForDistace) {
      const { deliveryRules } = deliveryRulesListDataForDistace;
      const { edges } = deliveryRules;
      const filteredData =
        edges &&
        edges.length > 0 &&
        edges.map((eachRule: any) => ({
          areaName: eachRule.node.areaName,
          cartAmount: eachRule.node.cartAmount,
          color: eachRule.node.colourCode,
          deliveryRuleId: eachRule.node.deliveryRuleId,
          deliveryTime: JSON.parse(eachRule.node.deliveryTime),
          distance: eachRule.node.distance,
          extraShippingRate: eachRule.node.extraShippingRate,
          postCode: eachRule.node.postCode,
          postCodesForZone: eachRule.node.postCodesForZone,
          shippingRate: eachRule.node.shippingRate,
          timeTaken: eachRule.node.timeTaken,
          zoneName: eachRule.node.zoneName,
          zoneShape: eachRule.node.zoneShape,
          zoneType: eachRule.node.zoneType,
          isEdited: false,
          isNew: false,
        }));
      const filteredZonesList =
        filteredData &&
        filteredData.filter(
          (eachZone: any) => eachZone.zoneType === "distance"
        );
      setRowsForDistance(filteredZonesList);
    }
  }, [deliveryRulesListDataForDistace]);

  const [updatedDefaultZone, setUpdatedDefaultZone] = useState<string | "">("");
  const [updatedDefaultZoneForStar, setUpdatedDefaultZoneForStar] = useState<
    string | ""
  >("");

  const buttonsList = [
    { id: "area", buttonText: t("deliveries.area_wise") },
    {
      id: "distance",
      buttonText: t("deliveries.distance_wise"),
    },
    {
      id: "zone",
      buttonText: t("deliveries.zone/radius"),
    },
  ];

  useEffect(() => {
    if (defaultZone) {
      const { getDefaultName } = defaultZone;
      setUpdatedDefaultZone(getDefaultName);
      setUpdatedDefaultZoneForStar(getDefaultName);
    }
  }, [defaultZone]);
  const [activeButton, setActiveButton] = useState<string | null>(null);
  useEffect(() => {
    setActiveButton(updatedDefaultZone);
  }, [updatedDefaultZone]);

  const handleButton = (id: string) => {
    setActiveButton(id);
  };

  const renderMappingComponents = () => {
    switch (activeButton) {
      case "distance":
        return (
          <DistanceWise
            locationKey={locationKey}
            editRowId={editRowId}
            setEditRowId={setEditRowId}
            storeLocation={storeLocation}
            editId={editId}
            updatedDefaultZoneForStar={updatedDefaultZoneForStar}
            updatedDefaultZone={updatedDefaultZone}
            deliverySettingId={deliverySettingId}
          />
        );
      case "zone":
        return (
          <DistanceOrZoneWise
            locationKey={locationKey}
            storeLocation={storeLocation}
            editId={editId}
            updatedDefaultZoneForStar={updatedDefaultZoneForStar}
            updatedDefaultZone={updatedDefaultZone}
            deliverySettingId={deliverySettingId}
          />
        );
      default:
        return (
          <AreaWise
            locationKey={locationKey}
            editRowId={editRowId}
            setEditRowId={setEditRowId}
            storeLocation={storeLocation}
            editId={editId}
            updatedDefaultZoneForStar={updatedDefaultZoneForStar}
            updatedDefaultZone={updatedDefaultZone}
            deliverySettingId={deliverySettingId}
          />
        );
    }
  };

  return (
    <Box>
      <Paper
        sx={{
          padding: "15px",
          width: "100%",
          marginLeft: "20px",
          borderLeft: "2px solid #3ee0af",
        }}
        elevation={2}
      >
        <Typography className="delivery-zone-details-text">
          {t("deliveries.select_type")}
        </Typography>
        <Box
          sx={{
            width: "60%",
            display: "flex",
            justifyContent: "space-between",
            marginTop: "15px",
            gap: "10px",
            marginLeft: "18px",
          }}
        >
          {buttonsList.map((button) => (
            <Button
              onClick={() => handleButton(button.id)}
              sx={{ textTransform: "none", width: "40%" }}
              className={
                button.id === activeButton
                  ? "delivery-zone-area-wise-text"
                  : "delivery-zone-area-wise-button delivery-zone-area-wise-text-in-active"
              }
              color="warning"
            >
              {button.buttonText}{" "}
              {button.id === updatedDefaultZoneForStar && (
                <CheckCircleIcon sx={{ marginLeft: "10px" }} />
              )}
            </Button>
          ))}
        </Box>
        {renderMappingComponents()}
      </Paper>
    </Box>
  );
};

export default DeliveryRulesAndMap;
