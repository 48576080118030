import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Paper,
  Typography,
  TextField,
  Autocomplete,
  Tooltip,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import "./kdsEditAssignedItems.css";
import { useNavigate } from "react-router";
import DeletePopup from "../popups/deletePopup";
import {
  PRODUCT_LIST_FOR_KDS,
  DEVICE_LIST_FOR_KDS,
  DEVICES_AND_RELAVANT_PRODUCTS,
} from "../../graphicalQl/usequery/deviceManagementQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import {
  ADD_PRODUCT_TO_KDS,
  DELETE_PRODUCT_FROM_KDS,
} from "../../graphicalQl/mutation/deviceMangementMutation";
import { toast } from "react-toastify";
import NodataImage from "../../utils/nodataImage";
import { useTranslation } from "react-i18next";

function KdsEditAssignedItems() {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [collectedDeviceIdsAndNames, setCollectedDeviceIdsAndNames] = useState<
    any[]
  >([]);

  const navigate = useNavigate();
  const [kdsList, setKdsList] = useState<any[]>([]);
  const [mixedProductsAndKdsList, setMixedProductsAndKdsList] = useState<any[]>(
    []
  );

  const [productsList, setProductsList] = useState<any[]>([]);
  const [deleteProductPopup, setDeleteProductPopup] = useState<boolean>(false);
  const [deletingProductId, setDeletingProductId] = useState<string | null>(
    null
  );
  const [deviceId, setDeviceId] = useState<string | null>(null);

  const [productName, setProductName] = useState<string | null>(null);
  const [deviceName, setDeviceName] = useState<string | null>(null);

  const [addProductToKds] = useMutation(ADD_PRODUCT_TO_KDS, {
    onCompleted: (data) => {
      if (data) {
        const { addProductToKds } = data;
        if (addProductToKds) {
          const { success } = addProductToKds;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.added")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [
      PRODUCT_LIST_FOR_KDS,
      DEVICE_LIST_FOR_KDS,
      DEVICES_AND_RELAVANT_PRODUCTS,
    ],
  });

  const [deleteProductFromKds] = useMutation(DELETE_PRODUCT_FROM_KDS, {
    onCompleted: (data) => {
      if (data) {
        const { removeProductFromKds } = data;
        if (removeProductFromKds) {
          const { success } = removeProductFromKds;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.removed")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [
      PRODUCT_LIST_FOR_KDS,
      DEVICE_LIST_FOR_KDS,
      DEVICES_AND_RELAVANT_PRODUCTS,
    ],
  });

  const filteredDataFromNullForKiosk = {
    businessId: +businessId,
    deviceType: "KDS",
    storeId: locationId,
  };
  const filteredIdsFromKiosk = Object.fromEntries(
    Object.entries(filteredDataFromNullForKiosk).filter(
      ([_, value]) => value !== null
    )
  );

  const filteredIdsFromKioskForRelantProducts = {
    locationId: +locationId,
    deviceIds: JSON.stringify(collectedDeviceIdsAndNames),
  };

  const filteredIdsFromKioskForRelantProductsFromNUll = Object.fromEntries(
    Object.entries(filteredIdsFromKioskForRelantProducts).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: deviceListDataForKiosk,
    loading: deviceListLoadingKisok,
    error: deviceListErrorKiosk,
  } = useQuery(DEVICE_LIST_FOR_KDS, {
    context: { clientName: "businessClient" },
    variables: filteredIdsFromKiosk,
    fetchPolicy: "network-only",
  });

  const {
    data: devicesAndrelavantProductsData,
    loading: devicesAndrelavantProductsLoading,
    error: devicesAndrelavantProductsError,
  } = useQuery(DEVICES_AND_RELAVANT_PRODUCTS, {
    context: { clientName: "categoryClient" },
    variables: filteredIdsFromKioskForRelantProductsFromNUll,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (devicesAndrelavantProductsData) {
      const { kdsProductsList } = devicesAndrelavantProductsData;
      if (kdsProductsList) {
        const parsedData = JSON.parse(kdsProductsList);
        if (parsedData) {
          const fileterdata = parsedData.map((each: any) => ({
            deviceId: each.device_id,
            deviceName: each.device_name,
            products: each.products?.map((EachProduct: any) => ({
              productId: EachProduct.product_id,
              productName: EachProduct.name,
              isHover: false,
            })),
          }));
          setKdsList(fileterdata);
        }
      }
    }
  }, [devicesAndrelavantProductsData]);

  useEffect(() => {
    if (deviceListDataForKiosk) {
      const { pairedDevicesList } = deviceListDataForKiosk;
      if (pairedDevicesList) {
        const { edges } = pairedDevicesList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => ({
            device_id: eachStore.node.serialNumber,
            device_name: eachStore.node.deviceName,
          }));
          setCollectedDeviceIdsAndNames(filteredList);
        }
      }
    }
  }, [deviceListDataForKiosk]);

  useEffect(() => {
    // const filteredData = kdsList.map((eachDevice: any) => {
    //   eachDevice.existedProducts = []; // Initialize existedProducts as an empty array
    //   productsList.forEach((eachProduct: any) => {
    //     if (eachProduct.kdsStations) {
    //       if (!eachProduct.kdsStations.includes(eachDevice.deviceId)) {
    //         eachDevice.existedProducts.push(eachProduct);
    //       }
    //     } else {
    //       eachDevice.existedProducts.push(eachProduct);
    //     }
    //   });
    //   return eachDevice; // Return eachDevice after processing
    // });

    const filteredData = kdsList.map((each: any) => ({
      ...each,
      existedProducts: productsList,
    }));

    setMixedProductsAndKdsList(filteredData);
  }, [kdsList, productsList]);

  const filteredDataFromNullForProducts = {
    businessId: +businessId,
    locationId: +locationId,
  };
  const filteredIdsForProducts = Object.fromEntries(
    Object.entries(filteredDataFromNullForProducts).filter(
      ([_, value]) => value !== null
    )
  );
  const {
    data: productsData,
    loading: productsLoading,
    error: productsError,
  } = useQuery(PRODUCT_LIST_FOR_KDS, {
    context: { clientName: "categoryClient" },
    variables: filteredIdsForProducts,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (productsData) {
      const { productList } = productsData;
      if (productList) {
        const { edges } = productList;
        if (edges.length > 0) {
          const filteredList = edges.map((eachStore: any) => ({
            kdsStations: eachStore.node.kdsStations,
            productId: eachStore.node.productId,
            productName: eachStore.node.name,
          }));

          const collectedProductList = filteredList.filter(
            (each: any) =>
              each.kdsStations === null || each.kdsStations === "[]"
          );

          setProductsList(collectedProductList);
        }
      }
    }
  }, [productsData]);

  const handleArrowbackButton = () => {
    navigate("/devices/device-list");
  };

  const handleMouseEnter = (kdsId: string, productId: string) => {
    const updatedData = kdsList.map((kds) => {
      if (kds.deviceId === kdsId) {
        return {
          ...kds,
          products: kds.products.map((product: any) => {
            if (product.productId === productId) {
              return {
                ...product,
                isHover: true,
              };
            } else {
              return {
                ...product,
                isHover: false,
              };
            }
          }),
        };
      }
      return kds;
    });

    setKdsList(updatedData);
  };
  const handleMouseLeave = (kdsId: string, productId: string) => {
    const updatedData = kdsList.map((kds) => {
      if (kds.deviceId === kdsId) {
        return {
          ...kds,
          products: kds.products.map((product: any) => {
            if (product.productId === productId) {
              return {
                ...product,
                isHover: false,
              };
            } else {
              return product;
            }
          }),
        };
      }
      return kds;
    });

    setKdsList(updatedData);
  };

  const handleDeleteProductIcon = (
    id: string,
    product: string,
    device: string,
    deviceId: string
  ) => {
    setDeleteProductPopup(true);
    setDeletingProductId(id);
    setProductName(product);
    setDeviceName(device);
    setDeviceId(deviceId);
  };
  const deleteProductPopupClose = () => {
    setDeleteProductPopup(false);
    setDeletingProductId(null);
    setProductName(null);
    setDeviceName(null);
    setDeviceId(null);
  };

  const handleYesRemoveButton = () => {
    deleteProductPopupClose();
    deleteProductFromKds({
      variables: {
        productId: deletingProductId && +deletingProductId,
        deviceId: deviceId,
      },
    });
  };

  const mainText = `${t("buttons.are_you_sure_delete")} "${productName}" ${t(
    "devices.from"
  )} "${deviceName}" ?`;

  const handleAddIcon = (
    kdsId: string,
    productId: string,
    productName: string
  ) => {
    addProductToKds({
      variables: {
        productId: +productId,
        deviceId: kdsId,
      },
    });
  };

  return (
    <Box sx={{ padding: "15px" }}>
      <DeletePopup
        open={deleteProductPopup}
        handleClose={deleteProductPopupClose}
        handleOpen={() => setDeleteProductPopup(true)}
        handleSaveButton={handleYesRemoveButton}
        mainText={mainText}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="35%"
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <IconButton
          onClick={handleArrowbackButton}
          sx={{ marginRight: "50px" }}
          className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
        >
          <ArrowBackIcon />
        </IconButton>
        <Typography className="kds-edit-assigned-items-header">
          {t("devices.edit_assigned_items")}
        </Typography>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* <Button sx={{ textTransform: "none" }} color='warning' variant='outlined'>{t("kds_edit_assignment_items.clear")}</Button> */}
          {/* <Button
            sx={{ textTransform: "none" }}
            color="warning"
            variant="contained"
          >
            {t("kds_edit_assignment_items.save")}
          </Button> */}
        </Box>
      </Box>

      <Box sx={{ padding: "1%", marginLeft: "1%" }}>
        <Typography className="kds-edit-assigned-items-basic-description">
          {t("devices.swiftly_modify")}
        </Typography>
      </Box>

      <Box
        sx={{
          width: "100%",
          display: "flex",
          alignItems: "flex-start",
          marginLeft: "1%",
          padding: "1%",
          flexWrap: "wrap",
          flexGrow: 1,
        }}
      >
        {mixedProductsAndKdsList.length > 0 ? (
          mixedProductsAndKdsList.map((eachList: any, index: number) => (
            <Box
              key={eachList.deviceId}
              sx={{ marginTop: "1%", marginRight: "3%", width: "30%" }}
            >
              <Typography
                sx={{ marginBottom: "15px" }}
                className="kds-edit-assigned-items-basic-text"
              >
                {eachList.deviceName}
              </Typography>
              <Paper
                elevation={2}
                sx={{ padding: "10px", width: "100%", height: "400px" }}
              >
                <Autocomplete
                  id="country-select-demo"
                  sx={{ width: "100%" }}
                  options={eachList.existedProducts}
                  autoHighlight
                  getOptionLabel={(option: any) => option.productName}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      <Box
                        className="user-role-user-names-hover"
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Typography sx={{ textTransform: "capitalize" }}>
                          {option.productName}
                        </Typography>
                        <Tooltip title={t("buttons.add")} placement="top" arrow>
                          <IconButton
                            onClick={() =>
                              handleAddIcon(
                                eachList.deviceId,
                                option.productId,
                                option.productName
                              )
                            }
                            className="kds-edit-assigned-items-add-icon"
                          >
                            <AddIcon />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("users.search_by_name")}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: "new-password", // disable autocomplete and autofill
                      }}
                    />
                  )}
                />

                <Box
                  sx={{
                    padding: "12px",
                    marginTop: "15px",
                    // border: "2px solid #EEE",
                    borderRadius: "8px",
                    backgroundColor: "#fcfcfc",
                    height: "310px",
                    overflowY: "scroll",
                    WebkitOverflowScrolling: "touch",
                    "&::-webkit-scrollbar": {
                      display: "none",
                    },
                  }}
                >
                  {eachList.products.map((item: any, index: any) => (
                    <Box
                      className="user-role-user-names-hover"
                      onMouseEnter={() =>
                        handleMouseEnter(eachList.deviceId, item.productId)
                      }
                      onMouseLeave={() =>
                        handleMouseLeave(eachList.deviceId, item.productId)
                      }
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                        padding: "10px 0px 10px 0px",
                        height: "40px",
                      }}
                    >
                      <Typography
                        key={index}
                        sx={{ textTransform: "capitalize" }}
                        className="kds-edit-assigned-items-drag-item"
                      >
                        {item.productName}
                      </Typography>
                      <Tooltip
                        title={t("buttons.delete")}
                        placement="top"
                        arrow
                      >
                        {item.isHover && (
                          <IconButton
                            onClick={() =>
                              handleDeleteProductIcon(
                                item.productId,
                                item.productName,
                                eachList.deviceName,
                                eachList.deviceId
                              )
                            }
                          >
                            <DeleteIcon />
                          </IconButton>
                        )}
                      </Tooltip>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Box>
          ))
        ) : (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "60vh",
            }}
          >
            <NodataImage
              height="250px"
              width="250px"
              heightForContainer="40vh"
              fontSizeForNoDataText="14px"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default KdsEditAssignedItems;
