import React from "react";
import { FormControl, InputAdornment, TextField, Box } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import { useTranslation } from "react-i18next";

interface Props {
  search: string;
  searchHandler: (input: string) => void;
}

const TypeSearch = ({ search, searchHandler }: Props) => {
  const {t}=useTranslation()
  const handleChange = (event: any) => {
    const value = event.target.value;
    searchHandler(value.trim());
  };

  const handleClick = () => {
    searchHandler('');
  };

  return (
    <Box
      sx={{
        position: "sticky",
        left: "0px",
        paddingX: "16px",
        marginTop: "4px",
        background: "white",
        paddingBottom: "12px",
      }}
    >
      <FormControl>
        <TextField
          autoFocus={true}
          size="small"
          name={"search"}
          variant="outlined"
          //@ts-ignore
          placeholder={t("sidebar.search")}
          sx={{ fontSize: "14px", borderRadius: "10px" }}
          onInput={handleChange}
          value={search}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon sx={{ height: "18px", width: "18px" }} />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment
                position="end"
                style={{ display: search.length > 0 ? "flex" : "none" }}
                onClick={handleClick}
              >
                <ClearIcon sx={{ height: "18px", width: "18px", cursor: 'pointer' }} />
              </InputAdornment>
            ),
          }}
        />
      </FormControl>
    </Box>
  );
};

export default TypeSearch;
