import { Box, Typography } from "@mui/material";
import CustomersIcon from "../../../assets/mianDashboard/CustomerIcon.svg";
import List from "@mui/material/List";
import React from "react";
import { Customers } from "../../../types/dashboard";
import { useTranslation } from "react-i18next";

interface Props {
  customers: Customers;
}

const CustomersComp = ({ customers }: Props) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        minWidth: "240px",
        width: { xs: "100%", md: "53%" },
        backgroundColor: "white",
        borderRadius: "8px",
        boxShadow: "0px 2px 12px 0px rgba(0, 0, 0, 0.04)",
      }}
    >
      <Box
        sx={{
          padding: "20px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "21px",
            marginBottom: "33px",
          }}
        >
          <img src={CustomersIcon} alt="recentOrderIcon" />
          <Typography
            sx={{
              fontFamily: "poppins",
              fontSize: "24px",
              lineHeight: "36px",
              fontWeight: "500",
              color: "#000000",
            }}
          >
            {t("dashboard.Customers")}
          </Typography>
        </Box>
        <List
          sx={{
            minWidth: "249px",
            width: "89.1%",
            display: "flex",
            flexDirection: "column",
            gap: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "12px",
              paddingY: "9.5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {t("dashboard.Total")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              {customers?.totalCustomers || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "12px",
              paddingY: "9.5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {t("dashboard.New_Customers")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              {customers?.newCustomers || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "12px",
              paddingY: "9.5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {t("dashboard.Return_Customers")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              {customers?.returningCustomers || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "12px",
              paddingY: "9.5px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {t("dashboard.Inactive_Customers")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#000000",
              }}
            >
              {customers?.inActiveCustomers || 0}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              paddingX: "12px",
              paddingY: "9.5px",
              gap: "40px",
            }}
          >
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "16px",
                lineHeight: "26px",
                fontWeight: "500",
                color: "#000000",
              }}
            >
              {t("dashboard.Feedback")}
            </Typography>
            <Typography
              sx={{
                fontFamily: "poppins",
                fontSize: "18px",
                lineHeight: "27px",
                color: "#707070",
                textAlign: "right",
              }}
            >
              {customers?.positiveFeedBack} {t("dashboard.positive")},{" "}
              {customers?.negitiveFeedBack} {t("dashboard.negative")}
            </Typography>
          </Box>
        </List>
      </Box>
    </Box>
  );
};

export default CustomersComp;
