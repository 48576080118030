import React, { useState } from "react";
import styled from "styled-components";
import { Box, Button, Typography } from "@mui/material";
import DefaultData from "../../../assets/system-update-alt.png";
import ChcekMark from "../../../assets/yellowcheckmark.png";
import SyncProblem from "../../../assets/sync-problem.png";
import ForwardArrow from "../../../assets/arrow-forward.png";
import ApplicationSettings from "./applicationSettings";

const ApplicationWrapper = styled("div")({
  marginTop: "45px",
  marginBottom: "300px",
  border: "1px solid #eeeeee",
  borderRadius: "8px",
  maxWidth: "820px",
  // width: { sm: "calc(100% - 40px)", xs: "100%" },
  marginLeft: "auto",
  marginRight: "auto",
});
function Application() {
  const [applicationSettings, setApplicationSettings] = useState(false);
  return (
    <Box
      sx={{
        // width: {lg:"calc(100% - 92px)", xs:"calc(100% - 100px)"},
        // marginLeft: "auto",
         // marginRight: {xs:'10px', md:"auto"}
        paddingX:{xs:'20px', lg:'auto'}
      }}
    >
      {applicationSettings ? (
        <ApplicationSettings />
      ) : (
        <ApplicationWrapper>
          <Box
            sx={{
              paddingY: "20px",
              paddingX: { sm: "32px", xs: "16px" },
            }}
          >
            <Typography
              sx={{
                fontSize: { sm: "20px", xs: "16px" },
                lineHeight: "24px",
                fontWeight: "600",
                fontFamily: "Poppins",
                letterSpacing: "0.5px",
                textAlign: "center",
                color: "#333333",
                paddingTop: "10px",
                paddingBottom: "30px",
                borderBottom: "1px solid #eeeeee",
              }}
            >
              Initiate Your Application
            </Typography>
            <Box
              sx={{
                marginY: { sm: "54px", xs: "30px" },
                display: "flex",
                gap: "20px",
                flexWrap: { md: "nowrap", xs: "wrap" },
                justifyContent: { md: "space-between", xs: "center" },
              }}
            >
              <Box
                sx={{
                  maxWidth: "368px",
                  width: "100%",
                  padding: "8px",
                  backgroundColor: "#FFF8F0",
                  border: "1px solid #F38B08",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "10px",
                    textAlign: "center",
                  }}
                >
                  <img src={DefaultData} alt="DefaultData" />
                </Box>
                <Box
                  sx={{
                    marginTop: "28px",
                    marginBottom: "8px",
                    display: "flex",
                    gap: { sm: "20px", xs: "10px" },
                  }}
                >
                  <Box sx={{paddingTop:'8px'}}>
                    <img src={ChcekMark} alt="ChcekMark" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "14px" },
                        lineHeight: "27px",
                        fontWeight: "500",
                        color: "#333333",
                        fontFamily: "Poppins",
                        paddingTop:'8px',
                      }}
                    >
                      Start with Default Data
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        fontWeight:'400',
                        marginTop: "8px",
                        color: "#ababab",
                        width:'100%'
                      }}
                    >
                     <Typography sx={{
                        whiteSpace:{xs:'wrap', md:'nowrap'}, 
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        fontWeight:'400',}}> We suggest initiating your application setup</Typography>
                       with default data, meticulously crafted 
                       based on your restaurant selection type. 
                       Seamlessly customize and edit as needed.
                    </Typography>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  maxWidth: "368px",
                  width: "100%",
                  paddingX: "8px",
                  paddingY:'8.5px',
                  // backgroundColor: "#FFF8F0",
                  border: "1px solid #eeeeee",
                  borderRadius: "8px",
                }}
              >
                <Box
                  sx={{
                    paddingTop: "10px",
                    textAlign: "center",
                  }}
                >
                  <img src={SyncProblem} alt="DefaultSyncProblemData" />
                </Box>
                <Box
                  sx={{
                    marginTop: "30px",
                    marginBottom: "8px",
                    display: "flex",
                    gap: { sm: "20px", xs: "10px" },
                    paddingTop:'8px',
                  }}
                >
                  <Box
                    sx={{
                      width: "20px",
                      height: "20px",
                      border: "1px solid #eeeeee",
                      borderRadius: "50%",
                    }}
                  >
                    {/* <img src={ChcekMark} alt="ChcekMark" /> */}
                  </Box>
                  <Box
                    sx={{
                      width: "calc(100% - 20px)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: { sm: "18px", xs: "14px" },
                        lineHeight: "27px",
                        fontWeight: "500",
                        color: "#333333",
                        fontFamily: "Poppins",
                      }}
                    >
                      Nah, I'll do it myself.
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: { sm: "14px", xs: "12px" },
                        lineHeight: "21px",
                        color: "#333333",
                        fontFamily: "Poppins",
                      }}
                    >
                      Manual data entry for me:
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontFamily: "Poppins",
                        marginTop: "8px",
                        color: "#ababab",
                      }}
                    >
                      Shape your application according to your preferences,
                      entering the essential data in a way that suits your
                      needs.
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: { sm: "end", xs: "center" },
              }}
            >
              <Button
                onClick={() => setApplicationSettings(true)}
                sx={{
                  width: "220px",
                  height: "48px",
                  backgroundColor: "#F38B08",
                  display: "flex",
                  gap: "24px",
                  justifyContent: "center",
                  '&:hover':{
                    backgroundColor: "#F38B08",
                  }
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#fefefe",
                    textTransform:'capitalize'
                  }}
                >
                  Continue
                </Typography>
                <img src={ForwardArrow} alt="ForwardArrow" />
              </Button>
            </Box>
          </Box>
        </ApplicationWrapper>
      )}
    </Box>
  );
}

export default Application;
