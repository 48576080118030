import {
  Box,
  Typography,
  TextField,
  Button,
  IconButton,
  Paper,
} from "@mui/material";
import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./kdsSettings.css";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate, useParams } from "react-router";
import { GET_PAIRED_DEVICE_DETAILS } from "../../graphicalQl/usequery/deviceManagementQuery";
import { UPDATE_DEVICE_IN_KDS } from "../../graphicalQl/mutation/deviceMangementMutation";
import { useMutation, useQuery } from "@apollo/client";
import { DEVICE_LIST_FOR_KDS } from "../../graphicalQl/usequery/deviceManagementQuery";
import { useTranslation } from "react-i18next";

interface State {
  stationName: string | null;
  pairedDevice: string | null;
  storeName: string | null;
  headerDeviceName: string | null;
}

const validationSchemaPart1 = Yup.object({
  pairedDevice: Yup.string().required(" Device Name required"),
});

const initialValues: State = {
  pairedDevice: null,
  stationName: null,
  storeName: null,
  headerDeviceName: null,
};

function KdsSettings() {
  const { t } = useTranslation();
  const { editId } = useParams();
  const navigate = useNavigate();

  const [updateDevice] = useMutation(UPDATE_DEVICE_IN_KDS, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });

  const {
    data: deviceData,
    loading: deviceLoading,
    error: deviceError,
  } = useQuery(GET_PAIRED_DEVICE_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { deviceId: editId && +editId },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (deviceData) {
      const { pairedDevice } = deviceData;
      if (pairedDevice) {
        const { deviceName, kdsStationName, store } = pairedDevice;
        formik.setValues({
          ...formik.values,
          pairedDevice: deviceName,
          headerDeviceName: deviceName,
          stationName: kdsStationName,
          storeName: store && store.storeName,
        });
      }
    }
  }, [deviceData]);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const handleSaveButton = () => {
    const filteredDataFromNullForKds = {
      deviceId: editId && +editId,
      kdsStationName: formik.values.stationName,
      deviceName: formik.values.pairedDevice,
    };
    const filteredIdsFromKds = Object.fromEntries(
      Object.entries(filteredDataFromNullForKds).filter(
        ([_, value]) => value !== null
      )
    );

    updateDevice({
      variables: filteredIdsFromKds,
    });

    navigate("/devices/device-list");
  };

  const handleCloseIcon = () => {
    navigate("/devices/device-list");
  };

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            padding: "2%",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <IconButton
            onClick={handleCloseIcon}
            className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
          >
            <CloseIcon />
          </IconButton>
          <Typography className="kds-settings-header">
            {formik.values.headerDeviceName} {t("devices.settings")}
          </Typography>
          <Button
            type="submit"
            sx={{ marginLeft: "15px", textTransform: "none" }}
            color="warning"
            variant="contained"
          >
            {t("buttons.save")}
          </Button>
        </Box>

        <Box sx={{ padding: "20px", width: "100%" }}>
          <Typography
            sx={{ margin: "20px", marginBottom: "10px" }}
            className="kds-settings-king-text"
          >
            {formik.values.storeName} {t("devices.branch")}
          </Typography>
          <Paper
            sx={{
              padding: "15px",
              width: "60%",
              margin: "20px",
              borderLeft: "2px solid #AE9BE2",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "60%",
              }}
            >
              <Typography className="kds-settings-general-text">
                {t("devices.food_station_name")}
              </Typography>
              <TextField
                name="stationName"
                value={formik.values.stationName}
                onChange={(e) => formik.handleChange(e)}
                variant="outlined"
              />
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "1%",
                width: "60%",
              }}
            >
              <Typography className="kds-settings-general-text">
                {t("devices.paired_kds_device")}
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                <TextField
                  name="pairedDevice"
                  value={formik.values.pairedDevice}
                  onChange={(e) => formik.handleChange(e)}
                  onBlur={formik.handleBlur}
                  variant="outlined"
                />
                {formik.touched.pairedDevice && formik.errors.pairedDevice && (
                  <Box>
                    <Typography className="create-a-user-formik-label">
                      {formik.errors.pairedDevice}
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Paper>
        </Box>
      </form>
    </Box>
  );
}

export default KdsSettings;
