import { gql } from "@apollo/client";

export const CUSTOMER_LIST = gql`
  query CustomerList($businessId: ID) {
    customerList(businessId: $businessId) {
      edges {
        node {
          customerId
          uuid
          lastName
          firstName
          email
          mobile
          uuid
          customerStatus
          customerGroup {
            groupName
            groupId
          }
          loyaltyPoints
          anniversary
          dateOfBirth
        }
      }
    }
  }
`;

export const ADDRESS_LIST = gql`
  query AddressList($customerId: ID) {
    addressList(customerId: $customerId) {
      edges {
        node {
          addressId
          address1
          address2

          addressType

          addressId

          isDefault
          postCode
          city
          country
        }
      }
    }
  }
`;

export const GET_ADDRESS = gql`
  query address($addressId: ID) {
    address(addressId: $addressId) {
      addressId
      isDeleted
      address1
      address2
      addressType
      receiverName
      receiverMobile
      isDefault
      city
      country
      postCode
    }
  }
`;

export const CUSTOMER_DATA = gql`
  query Customer($customerId: ID) {
    customer(customerId: $customerId) {
      customerId
      lastName
      email
      firstName
      mobile
      status
      uuid
      loyaltyPoints
      favouriteItems
      dateOfBirth
      anniversary
      userAccount
      companyName
      taxIdentifier
      image
    }
  }
`;

export const CUSTOMER_ORDER_HISTORY = gql`
  query Orders($customerId: UUID, $businessId: ID, $storeId: ID) {
    orders(
      customerId: $customerId
      businessId: $businessId
      storeId: $storeId
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          orderId
          orderType
          orderNo
          totalAmount
          createdOn
          orderStatus
          cart {
            cartItems
          }
        }
      }
    }
  }
`;
export const CUSTOMER_GROUP_LIST = gql`
  query CustomerGroupList($businessId: ID) {
    customerGroupList(businessId: $businessId) {
      edges {
        node {
          groupId
          groupName
          customerGroup
        }
      }
    }
  }
`;

export const SEARCH_CUSTOMERS = gql`
  query CustomerList(
    $businessId: ID
    $search: String
    $status: String
    $customerGroup: String
    $first: Int
    $after: String
    $before: String
    $last: Int
  ) {
    customerList(
      businessId: $businessId
      search: $search
      status: $status
      customerGroup: $customerGroup
      first: $first
      after: $after
      before: $before
      last: $last
    ) {
      totalCount
      pageInfo {
        hasNextPage
        startCursor
        endCursor
        hasPreviousPage
      }
      edges {
        node {
          customerId
          uuid
          lastName
          firstName
          companyName
          userAccount
          taxIdentifier
          email
          mobile
          uuid
          customerStatus
          customerGroup {
            groupName
            groupId
          }
          loyaltyPoints
          dateOfBirth
          anniversary
        }
      }
    }
  }
`;

export const REGISTERED_COMPLAINTS = gql`
  query CustomerComplaintList(
    $businessId: Int
    $fromDate: Date
    $toDate: Date
    $employeeName: String
    $locationId: Int
  ) {
    customerComplaintList(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
      employeeName: $employeeName
      locationId: $locationId
    ) {
      edges {
        node {
          id
          complaint
          complaintId
          complaintStatus
          reason
          createdOn
          customer {
            lastName
            firstName
            email
            mobile
          }
        }
      }
    }
  }
`;

export const COMPLAINTS_DETAILS = gql`
  query CustomerComplaint($complaintId: ID) {
    customerComplaint(complaintId: $complaintId) {
      note
      id
      orderType
      createdOn
      employeeName
    }
  }
`;

export const LOCATION_LIST = gql`
  query ($businessId: Int) {
    storeList(businessId: $businessId) {
      edges {
        node {
          storeId
          storeName
        }
      }
    }
  }
`;

export const BUSINESS_DETAILS = gql`
  query ($businessId: ID) {
    business(businessId: $businessId) {
      businessId
      brandLogo
      favIcon
      legalBusinessName
      email
      mobile
      addressId
      emailVerified
      mobileVerified
      enableTwoStepVerification
      emailUpdatedOn
      mobileUpdatedOn
      passwordUpdatedOn
    }
  }
`;

export const ADDRESS_DETAILS = gql`
  query Address($addressId: ID) {
    address(addressId: $addressId) {
      addressId
      isDeleted
      address1
      address2
      addressType
      postCode
      country
    }
  }
`;

export const EMPLOYEE_ATTENDANCE_LIST = gql`
  query EmployeeAttendanceList(
    $businessId: ID
    $employeeId: ID
    $fromDate: Date
    $toDate: Date
    $locationId: ID
  ) {
    employeeAttendanceList(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
      employeeId: $employeeId
      locationId: $locationId
    ) {
      edges {
        node {
          attendanceId
          dateOf
          clockIn
          totalHours
          clockOut
          note
          employee {
            employeeId
            firstName
            lastName
            email
            role {
              roleName
            }
          }
        }
      }
    }
  }
`;

export const EMPLOYEE_DETAILS = gql`
  query Employees($businessId: ID, $locationId: ID) {
    employees(businessId: $businessId, locationId: $locationId) {
      edges {
        node {
          employeeId
          firstName
          lastName
          email
          mobile
          isDeleted
          employeeStatus
          role {
            roleName
            id
            noOfUsers
          }
          id
        }
      }
    }
  }
`;

export const STORE_SETTINGS = gql`
  query StoreSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      orderStatusLookup
      kdsSettings
      enableShifts
      allowCustomerToOrderThroughCall
      allowCallerIdNotifications
      showCustomerDetailsInCallerid
      allowCustomerDisplaySystems
      allowDinningOptions
      allowNegativeStockAlerts
      allowOpenItems
      enableClockinClockout
      lowStockNotifications
      sendOrdersTokitchenPrinters
      currency
      timeZone
      orderNumberingSettings
      store {
        storeId
        deliveryWeekSchedule
        deliverySlotTime
        storeName
      }
      business {
        businessId
        brandName
      }
    }
  }
`;

export const ORDER_NUMBERING = gql`
  query StoreSettings($storeId: ID) {
    storeSettings(storeId: $storeId) {
      orderNumberingSettings
      store {
        storeId
        deliveryWeekSchedule
        deliverySlotTime
        storeName
      }
      business {
        businessId
        brandName
      }
    }
  }
`;

export const CUSTOMERS_IN_DASHBOARD1 = gql`
  query customerReports($businessId: Int, $fromDate: Date, $toDate: Date) {
    customerReports(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;

export const CUSTOMERS_IN_DASHBOARD2 = gql`
  query customerDashboard($businessId: ID, $fromDate: Date, $toDate: Date) {
    customerDashboard(
      businessId: $businessId
      fromDate: $fromDate
      toDate: $toDate
    )
  }
`;
