import {
  Box,
  Button,
  Drawer,
  IconButton,
  Modal,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import success from "../../assets/sucess img.png";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const deletePopupStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface State {
  handleSuccessClose: () => void;
  open: boolean;
  successText: string;
  handleNextButton: () => void;
}

const ScanSuccessPopup = (props: State) => {
  const { t } = useTranslation();
  const { handleSuccessClose, open, successText, handleNextButton } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const renderContent = () => (
    <Box className="scan-success-popup-container">
      <Box sx={{ alignSelf: "flex-start" }}>
        <IconButton
          onClick={handleSuccessClose}
          className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
        >
          <CloseIcon />
        </IconButton>
      </Box>

      <Box sx={{ textAlign: "center" }}>
        <img src={success} alt="success" style={{ objectFit: "contain" }} />
        <Typography
          className="success-popup-success-text"
          sx={{ marginTop: "20px" }}
        >
          {successText}
        </Typography>
      </Box>

      <Button
        className="scan-success-popup-next-button"
        onClick={handleNextButton}
        sx={{}}
        variant="contained"
      >
        {t("devices.next")}
        <KeyboardArrowRightIcon />
      </Button>
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleSuccessClose}
          sx={{
            "& .MuiDrawer-paper": {
              height: "100vh", // Set drawer height to 50% of screen height
              // borderRadius: "8px 8px 0 0",
              padding: 2,
              // borderRadius: "8px 8px 0 0",
            },
          }}
        >
          {renderContent()}
        </Drawer>
      ) : (
        <Modal
          open={open}
          onClose={handleSuccessClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={deletePopupStyle}>{renderContent()}</Box>
        </Modal>
      )}
    </Box>
  );
};

export default ScanSuccessPopup;
