import { Box } from "@mui/material";
import React from "react";
import ProductsReport from "../../components/reports/products";

const ProductsReportPage = () => {
  return (
    <Box>
      <ProductsReport />
    </Box>
  );
};
export default ProductsReportPage;
