const RESET_STATE = 'RESET_STATE';
interface ResetStateAction {
  type: typeof RESET_STATE;
}
export const resetState = (): ResetStateAction => ({
  type: RESET_STATE,
});

export interface FormData {

  metaTag: string;
  seoKeyWord: any[];
  metaKeyWord: any[];

}

export interface VisibilityData {
  radioValue: string;
  startTime: string;
  endTime: string;
  weekDays: any
  selectDate: any;
  showOnCheckBox: boolean;
  hideUntilCheckBox: boolean,
  hideMenuCheckBox: boolean

}

const CREATE_CATEGORY = 'CREATE_CATEGORY';

const VISIBILITY_DATA = "VISIBILITY_DATA";

export interface CreateCategory {
  type: typeof CREATE_CATEGORY;
  payload: {
    field: string;
    name: string | any;
  };
}

export interface Visibility {
  type: typeof VISIBILITY_DATA;
  payload: {
    field: string;
    name: string | any;
  };
}



const categoryData: FormData = {
  metaTag: "",
  seoKeyWord: [],
  metaKeyWord: [],


};

const visibilityInitialData: VisibilityData = {
  radioValue: '',
  startTime: '',
  endTime: '',
  weekDays: [
    { id: 2, day: "Monday", isChecked: false },
    { id: 3, day: "Tuesday", isChecked: false },
    { id: 4, day: "Wednesday", isChecked: false },
    { id: 5, day: "Thursday", isChecked: false },
    { id: 6, day: "Friday", isChecked: false },
    { id: 7, day: "Saturday", isChecked: false },
    { id: 8, day: "Sunday", isChecked: false },],
  selectDate: new Date().toISOString(),
  showOnCheckBox: false,
  hideUntilCheckBox: false,
  hideMenuCheckBox: false

}

export const setCreateCategory = (field: string, name: string | any): CreateCategory => ({
  type: CREATE_CATEGORY,
  payload: {
    field,
    name
  },
});


export const setVisibility = (field: string, name: string | any): Visibility => ({
  type: VISIBILITY_DATA,
  payload: { field, name }
});


export const createCategoryReducer = (state = categoryData, action: CreateCategory | ResetStateAction): FormData => {
  switch (action.type) {
    case CREATE_CATEGORY:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };


    case RESET_STATE:
      return {
        ...categoryData,
      };

    default:
      return state;
  }
};

export const visibilityReducer = (state = visibilityInitialData, action: Visibility | ResetStateAction): VisibilityData => {
  switch (action.type) {
    case VISIBILITY_DATA:
      return {
        ...state,
        [action.payload.field]: action.payload.name,
      };

    case RESET_STATE:
      return {
        ...visibilityInitialData,
      };


    default:
      return state;
  }
};
