import React, { useEffect, useState } from 'react';
import { Box, Typography, Button, IconButton, Divider } from "@mui/material";
import '../options/createProduct.css'
import './createCategory.css'
import { CloseOutlined } from '@mui/icons-material';

import { useTranslation } from "react-i18next";
import image from '../../assets/createProduct.png';
import { useMutation, useQuery } from '@apollo/client';
import { OPTIONS_LIST } from '../../graphicalQl/usequery/categoryListQuery';

import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import { DELETE_OPTIONGROUP } from '../../graphicalQl/mutation/mutation';
import { DELETE_OPTIONVALUE } from '../../graphicalQl/mutation/mutation';
import { DELETE_OPTION } from '../../graphicalQl/mutation/mutation';
import { GET_OPTIONS_IN_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import CircularLoader from '../../loader/loader';
import DeleteDailouge from './deleteDailouge';
import { styled } from '@mui/system';
import OptionsTable from './assignOptionsModal';

interface Props {
  categoryId: string;

}


type OptionType = {
  name: string;
  optionValueId: string;
  price: string;
  image: string | null;
};





type DataResult = {
  id: string;
  name: string;
  price: string;
  optionObjs: {
    name: string;
    optionId: string;
    optionValues: OptionType[];
    isChecked: boolean;
    price: string
  }[];
  isChecked: boolean;

}













const CustomStyles = styled('div')`
  & > *:not(style) {
    margin: 4px;
  }
`;

const OptionsModal = (props: Props) => {

  const businessId: string = sessionStorage.getItem("businessId") as string
  const { t } = useTranslation();

  const { categoryId } = props

  const [openOptions, setOpenOptions] = React.useState(false);
  const handleOpenOptions = () => setOpenOptions(!openOptions);


  const { data: optionsData } = useQuery(OPTIONS_LIST, {
    context: { clientName: 'categoryClient' }, fetchPolicy: "network-only", variables: { businessId: +businessId }, onError: (error) => {
      console.error('Error:', error);
    },
  })



  const [checkedRequiredOptions, setCheckedRequiredOptions] = useState<DataResult[]>([]);

  useEffect(() => {
    if (optionsData && optionsData.optionGroupList) {
      const requiredOptions = optionsData.optionGroupList.edges.map((item: any) => ({
        id: item.node.optionGroupId,
        name: item.node.name,
        optionObjs: item.node.optionObjs && item.node.optionObjs.map((each: any) => ({
          name: each.name,
          optionId: each.optionId,
          optionValues: each.optionValues,
          price: each.priceToDisplay,
          isChecked: false
        })),
        isChecked: false
      }));
      setCheckedRequiredOptions(requiredOptions);
    }



  }, [optionsData]);



  const [productId, setProductId] = useState<string>("")
  const [type, setType] = useState<string>("")
  const handleGetProductId = (productId: string, value: string) => {
    setProductId(productId)
    setType(value)
  }








  const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP, {
    context: { clientName: 'categoryClient' }, onError: (error) => {
      console.error('Error:', error);
    },
  })


  const handleDelteOptionGroup = (optionGroupId: string) => {
    deleteOptionGroup({
      variables: { optionGroupId: +optionGroupId, categoryId: categoryId }, refetchQueries: [GET_OPTIONS_IN_CATEGORY], onError: (error) => {
        console.error('Error:', error);
      },
    })
  }
  const [deleteOptionValue] = useMutation(DELETE_OPTIONVALUE, {
    context: { clientName: 'categoryClient' }, refetchQueries: [GET_OPTIONS_IN_CATEGORY], onError: (error) => {
      console.error('Error:', error);
    },
  })


  const handleDeleteOptionValue = (optionValueId: string) => {
    deleteOptionValue({ variables: { optionValueId: +optionValueId, categoryId: categoryId } })
  }

  const [deleteOption] = useMutation(DELETE_OPTION, {
    context: { clientName: 'categoryClient' }, refetchQueries: [GET_OPTIONS_IN_CATEGORY], onError: (error) => {
      console.error('Error:', error);
    },
  })


  const handleDeleteOption = (optionId: string) => {
    deleteOption({ variables: { optionId: +optionId, categoryId: categoryId } })
  }

  let finalFunction = handleDeleteOption;

  switch (type) {
    case "option":
      finalFunction = handleDeleteOption;
      break;
    case "optionGroup":
      finalFunction = handleDelteOptionGroup;
      break;
    case "optionValue":
      finalFunction = handleDeleteOptionValue;
      break;
    default:
      // Handle the default case
      break;
  }

  // Now you can use finalFunction as needed.


  const [selectedOptions, setSelectedOptions] = useState<any>([])

  const { data: selectedoptionList, loading: optionsLoading } = useQuery(GET_OPTIONS_IN_CATEGORY, {
    context: { clientName: 'categoryClient' }, variables: { categoryId: +categoryId }, fetchPolicy: "network-only", onError: (error) => {
      console.error('Error:', error);
    },
  })

  useEffect(() => {
    if (selectedoptionList) {
      if (selectedoptionList.categoryOptionValueListing) {
        setSelectedOptions(JSON.parse(selectedoptionList.categoryOptionValueListing))

      }

    }
  }, [selectedoptionList])


  const checkedIdsFromApi = selectedOptions.map((each: any) => each.option_group_id || each.option_id)











  const [deletemodal, setDeleteModal] = useState<boolean>(false)
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal)
  }
  const handleDeleteClick = () => {

  }

  if (optionsLoading) {
    return (
      <CircularLoader />
    )

  }
  return (
    <Box  >

      <Box sx={{ width: "100%", display: 'flex', flexDirection: "column", marginTop: '12px' }}>

        {selectedOptions.length > 0 ? (
          <Box sx={{ maxHeight: "55vh", overflowY: 'scroll' }}>

            {selectedOptions.map((item: any) => (
              <Box key={item.option_group_id}>

                {item.option_group_id ? (<Box key={item.option_group_id} >
                  <Box sx={{ display: "flex", ':hover': { background: '#FFF8F0' } }}>

                    <Typography className="normalHeader" sx={{ width: '64%', mt: "8px" }}>{item.option_group_name}</Typography>


                    <IconButton sx={{ marginLeft: "auto", ':hover': { background: '#FFF8F0' }, }} onClick={() => { handleGetProductId(item.option_group_id, "optionGroup"); handleDeleteModal() }} >
                      <CloseOutlined />
                    </IconButton>
                  </Box>
                  <Divider sx={{ margin: "4px" }} />

                  {item.options.map((each: any) => (
                    <Box key={each.option_id} sx={{ paddingLeft: '24px' }}>
                      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                        <Typography className="dataGrid-table-cell" sx={{ width: '20%', mt: '8px' }}>
                          {each.option_name}
                        </Typography>

                        <Stack direction="row" spacing={3} sx={{ display: 'flex', flexWrap: 'wrap', width: '80%' }}>
                          {each.option_values.map((each2: any) => (
                            <CustomStyles key={each2.option_value_id}>
                              <Chip
                                key={each2.option_value_id + each2.option_value_name}
                                label={<Typography className="chip-text">{each2.option_value_name}</Typography>}
                                onDelete={() => {
                                  handleGetProductId(each2.option_value_id, 'optionValue');
                                  handleDeleteModal();
                                }}
                              />
                            </CustomStyles>
                          ))}
                        </Stack>

                        <IconButton
                          sx={{ marginLeft: 'auto', ':hover': { background: '#FFF8F0' } }}
                          onClick={() => {
                            handleGetProductId(each.option_id, 'option');
                            handleDeleteModal();
                          }}
                        >
                          <CloseOutlined />
                        </IconButton>
                      </div>
                      <Divider sx={{ margin: '4px' }} />
                    </Box>
                  ))}

                </Box>) : (<Box key={item.option_id} >
                  <Box sx={{ display: "flex", width: "100%" }}>


                    <div key={item.option_id} style={{ width: "100%" }}>
                      <div style={{ display: "flex", marginLeft: "2%", alignItems: "flex-start" }}>

                        <Typography sx={{ width: '20%', mt: "8px", fontSize: "16px", fontWeight: "500", color: "#333333" }}>{item.option_name}</Typography>
                        <Divider sx={{ margin: "4px" }} />
                        {/* <CustomStyles> */}
                        <Stack direction="row" spacing={3} sx={{ display: "flex", flexWrap: "wrap", width: "80%" }}>
                          {item.option_values.map((each2: any) => (
                            <CustomStyles>
                              <Chip
                                key={each2.option_value_id + each2.option_value_name}
                                label={<Typography className='chip-text' >{each2.option_value_name}</Typography>}
                                onDelete={() => { handleGetProductId(each2.option_value_id, "optionValue"); handleDeleteModal() }}
                                sx={{ marginTop: "8px" }}
                              />
                            </CustomStyles>
                          ))}
                        </Stack>
                        {/* </CustomStyles> */}
                        <IconButton sx={{ marginLeft: "auto", ':hover': { background: '#FFF8F0' } }} onClick={() => { handleGetProductId(item.option_id, "option"); handleDeleteModal() }}>
                          <CloseOutlined />
                        </IconButton>
                      </div>
                      <Divider sx={{ margin: "4px" }} />
                    </div>



                  </Box>

                </Box>)}

              </Box>
            ))}

          </Box>) : (<div style={{ textAlign: 'center' }}>
            <Typography className='assign_product_text' sx={{ mt: "12px" }}>{t("menu.catageory.click_on")} <span className='assign_product_text2'>{t("menu.catageory.assign_option_button")}</span>{t("menu.catageory.to_add_product")}</Typography>

            <img src={image} alt="product" style={{ width: "180px", height: '180px' }} /></div>)}
        <Box sx={{ width: "100%", display: "flex", alignItems: "center", justifyContent: 'center', }}>
          <Button className='main-button' onClick={handleOpenOptions} sx={{ textTransform: 'none' }}>
            {t("menu.catageory.assign_option")}
          </Button>
        </Box>

        <div>


        </div>
      </Box>


      <OptionsTable categoryId={categoryId} openOptions={openOptions} handleOpenOptions={handleOpenOptions} checkedIdsFromApi={checkedIdsFromApi} />
      <DeleteDailouge id={productId} deletemodal={deletemodal} handleDeleteModal={handleDeleteModal} handleDleteByPopover={finalFunction} handleDeleteClick={handleDeleteClick} type={type} />

    </Box>
  );
}

export default OptionsModal;
