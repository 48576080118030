import { gql } from "@apollo/client";

export const UPDATE_API_KEYS = gql`
  mutation updateBusiness($businessId: ID!, $settings: SettingsInput) {
    updateBusiness(businessId: $businessId, settings: $settings) {
      errorMessage
      success
      business {
        businessId
      }
    }
  }
`;

export const CREATE_STORE = gql`
  mutation CreateStore(
    $business: Int!
    $storeName: String!
    $localName: String
    $brandLogo: String
    $locationType: String
    $description: String
    $addressDetails: AddressInput!
    $email: String
    $mobile: String
    $twitter: String
    $website: String
    $instagram: String
    $facebook: String
    $isShop: Boolean
    $paymentTypesForShop: JSONString
    $isDinein: Boolean
    $paymentTypesForDinein: JSONString
    $isDelivery: Boolean
    $paymentTypesForDelivery: JSONString
    $isCollection: Boolean
    $paymentTypesForCollection: JSONString
    $dineinWeekSchedule: JSONString
    $collectionWeekSchedule: JSONString
    $deliveryWeekSchedule: JSONString
    $shopWeekSchedule: JSONString
  ) {
    createStore(
      business: $business
      storeName: $storeName
      localName: $localName
      brandLogo: $brandLogo
      locationType: $locationType
      description: $description
      addressDetails: $addressDetails
      email: $email
      mobile: $mobile
      twitter: $twitter
      website: $website
      instagram: $instagram
      facebook: $facebook

      isShop: $isShop
      paymentTypesForShop: $paymentTypesForShop
      isDinein: $isDinein
      paymentTypesForDinein: $paymentTypesForDinein
      isDelivery: $isDelivery
      paymentTypesForDelivery: $paymentTypesForDelivery
      isCollection: $isCollection
      paymentTypesForCollection: $paymentTypesForCollection
      dineinWeekSchedule: $dineinWeekSchedule
      collectionWeekSchedule: $collectionWeekSchedule
      deliveryWeekSchedule: $deliveryWeekSchedule
      shopWeekSchedule: $shopWeekSchedule
    ) {
      errorMessage
      success
      store {
        storeId
        isDeleted
      }
    }
  }
`;

export const UPDATE_STORE_BASIC_DETAILS = gql`
  mutation updateStore(
    $business: Int
    $storeId: ID!
    $storeName: String!
    $localName: String
    $brandLogo: String
    $locationType: String
    $description: String
  ) {
    updateStore(
      business: $business
      storeId: $storeId
      storeName: $storeName
      localName: $localName
      brandLogo: $brandLogo
      locationType: $locationType
      description: $description
    ) {
      errorMessage
      success
      store {
        storeId
        isDeleted
      }
    }
  }
`;

export const UPDATE_STORE_CONTACT_IMFORMATION = gql`
  mutation updateStore(
    $business: Int
    $storeId: ID!
    $email: String
    $mobile: String
    $twitter: String
    $website: String
    $instagram: String
    $facebook: String
  ) {
    updateStore(
      business: $business
      storeId: $storeId

      email: $email
      mobile: $mobile
      twitter: $twitter
      website: $website
      instagram: $instagram
      facebook: $facebook
    ) {
      errorMessage
      success
      store {
        storeId
        isDeleted
      }
    }
  }
`;

export const UPDATE_STORE_ADDRESS = gql`
  mutation UpdateAddress(
    $addressId: ID!
    $address1: String
    $address2: String
    $city: String
    $latitude: String
    $longitude: String
    $country: String
    $postCode: String
    $state: String
  ) {
    updateAddress(
      addressId: $addressId
      country: $country
      address1: $address1
      address2: $address2
      city: $city
      latitude: $latitude
      longitude: $longitude
      postCode: $postCode
      state: $state
    ) {
      errorMessage
      success
      address {
        addressId
        address1
        address2
        landMark
      }
    }
  }
`;

export const UPDATE_BUSINESS_HOURS = gql`
  mutation updateStore(
    $business: Int
    $storeId: ID!
    $dineinWeekSchedule: JSONString
    $collectionWeekSchedule: JSONString
    $deliveryWeekSchedule: JSONString
    $shopWeekSchedule: JSONString
  ) {
    updateStore(
      business: $business
      storeId: $storeId

      dineinWeekSchedule: $dineinWeekSchedule
      collectionWeekSchedule: $collectionWeekSchedule
      deliveryWeekSchedule: $deliveryWeekSchedule
      shopWeekSchedule: $shopWeekSchedule
    ) {
      errorMessage
      success
      store {
        storeId
        isDeleted
      }
    }
  }
`;

export const UPDATE_PAYMENT_TYPES = gql`
  mutation updateStore(
    $business: Int
    $storeId: ID!
    $isShop: Boolean
    $paymentTypesForShop: JSONString
    $isDinein: Boolean
    $paymentTypesForDinein: JSONString
    $isDelivery: Boolean
    $paymentTypesForDelivery: JSONString
    $isCollection: Boolean
    $paymentTypesForCollection: JSONString
  ) {
    updateStore(
      business: $business
      storeId: $storeId
      isShop: $isShop
      paymentTypesForShop: $paymentTypesForShop
      isDinein: $isDinein
      paymentTypesForDinein: $paymentTypesForDinein
      isDelivery: $isDelivery
      paymentTypesForDelivery: $paymentTypesForDelivery
      isCollection: $isCollection
      paymentTypesForCollection: $paymentTypesForCollection
    ) {
      errorMessage
      success
      store {
        storeId
        isDeleted
      }
    }
  }
`;

export const CREATE_HOLIDAY = gql`
  mutation ddStoreHoliday(
    $storeId: Int!
    $business: Int!
    $name: String
    $dateRange: JSONString
    $closeAllDay: Boolean
    $timeRange: JSONString
    $note: String
  ) {
    addStoreHoliday(
      storeId: $storeId
      business: $business
      name: $name
      dateRange: $dateRange
      closeAllDay: $closeAllDay
      timeRange: $timeRange
      note: $note
    ) {
      success
      holiday {
        holidayId
        dateRange
        name
        timeRange
      }
      errorMessage
    }
  }
`;

export const UPDATE_HOLIDAY = gql`
  mutation EditStoreHoliday(
    $holidayId: ID!
    $name: String
    $dateRange: JSONString
    $closeAllDay: Boolean
    $timeRange: JSONString
    $note: String
  ) {
    editStoreHoliday(
      holidayId: $holidayId
      name: $name
      dateRange: $dateRange
      closeAllDay: $closeAllDay
      timeRange: $timeRange
      note: $note
    ) {
      success
      holiday {
        holidayId
        dateRange
        name
        timeRange
      }
      errorMessage
    }
  }
`;
export const DELETE_HOLIDAY = gql`
  mutation DeleteStoreHoliday($holidayId: ID!) {
    deleteStoreHoliday(holidayId: $holidayId) {
      success
      errorMessage
    }
  }
`;

export const CLOSE_THE_STORE = gql`
  mutation CloseTheStore($storeId: ID!) {
    deleteStore(storeId: $storeId) {
      success
      result
      errorMessage
    }
  }
`;

export const STATUS_CHANGE_FOR_STORE = gql`
  mutation StatusChangeForStore($storeId: ID!, $status: String) {
    statusChange(storeId: $storeId, status: $status) {
      success
      result
      errorMessage
    }
  }
`;

export const LOYALITY_POINTS_FOR_STORE = gql`
  mutation BranchSettings(
    $storeId: Int!
    $allowLoyaltyPoints: Boolean
    $setExpiryForLoyalty: Boolean
    $loyaltyExpiryPeriod: Int
    $perPoundWhileAccumulation: Float
    $loyaltyPointsWhileAccumulation: Int
    $loyaltyPointsWhileRedumption: Int
    $equalentAmountWhileRedumption: Float
    $allowVipStatus: Boolean
    $tiers: JSONString
    $allowTimeAwayPoints: Boolean
    $timeAwayPoints: Int
    $timeAwayDays: Int
    $allowSignupPoints: Boolean
    $signupPoints: Int
    $allowBirthdayPoints: Boolean
    $birthdayPoints: Int
    $allowAnniversaryPoints: Boolean
    $anniversaryPoints: Int
  ) {
    branchSettings(
      storeId: $storeId
      allowLoyaltyPoints: $allowLoyaltyPoints
      setExpiryForLoyalty: $setExpiryForLoyalty
      loyaltyExpiryPeriod: $loyaltyExpiryPeriod
      perPoundWhileAccumulation: $perPoundWhileAccumulation
      loyaltyPointsWhileAccumulation: $loyaltyPointsWhileAccumulation
      loyaltyPointsWhileRedumption: $loyaltyPointsWhileRedumption
      equalentAmountWhileRedumption: $equalentAmountWhileRedumption
      allowVipStatus: $allowVipStatus
      tiers: $tiers
      allowTimeAwayPoints: $allowTimeAwayPoints
      timeAwayPoints: $timeAwayPoints
      timeAwayDays: $timeAwayDays
      allowSignupPoints: $allowSignupPoints
      signupPoints: $signupPoints
      allowBirthdayPoints: $allowBirthdayPoints
      birthdayPoints: $birthdayPoints
      allowAnniversaryPoints: $allowAnniversaryPoints
      anniversaryPoints: $anniversaryPoints
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const UPDATE_BLOCK_BOOKINGS = gql`
  mutation branchSettings(
    $storeId: Int!
    $businessId: ID
    $rushHourSettings: JSONString
    $blockBookings: JSONString
  ) {
    branchSettings(
      storeId: $storeId
      businessId: $businessId
      rushHourSettings: $rushHourSettings
      blockBookings: $blockBookings
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;

export const DUPLICATE_LOCATION_CHECK = gql`
  mutation duplicateLocationNameCheck(
    $businessId: Int
    $locationId: Int
    $locationName: String!
  ) {
    duplicateLocationNameCheck(
      businessId: $businessId
      locationName: $locationName
      locationId: $locationId
    ) {
      message
      duplicate
      success
    }
  }
`;

export const UPDATE_KITCHEN_CAPACITY_SETTINGS = gql`
  mutation branchSettings(
    $storeId: Int!
    $kitchenCapacitySettings: JSONString
  ) {
    branchSettings(
      storeId: $storeId
      kitchenCapacitySettings: $kitchenCapacitySettings
    ) {
      errorMessage
      settings {
        id
        store {
          storeId
        }
      }
      success
    }
  }
`;
