import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";

import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  List,
  ListItem,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useFormik } from "formik";
import * as Yup from "yup";
import "./customers.css";
import { ADD_ADDRESS } from "../../graphicalQl/mutation/customerDataMutation";
import { useMutation, useQuery } from "@apollo/client";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import {
  ADDRESS_LIST,
  GET_ADDRESS,
} from "../../graphicalQl/usequery/customerDataQuery";
import { UPDATE_ADDRESS_IN_EDIT } from "../../graphicalQl/mutation/customerDataMutation";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import { MuiTelInput } from "mui-tel-input";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { GETTING_API_KEYS } from "../../graphicalQl/usequery/locationQuery";
import { useTranslation } from "react-i18next";
interface Props {
  handledrawer: () => void;
  drawerOpen: boolean;
  id: string | undefined;
  editId: string;
  setEditId: any;
  addressTypes: string[];
}

// const validationSchemaPart1 = Yup.object({
//   // others: Yup.string().when(("others","addressTypes"), {
//   //     is: (value: string,value2:string[]) => value2.includes(value),
//   //     then: (schema) => schema.required("Required"),
//   // }),
//   others: Yup.string().test(
//     "is-others-included",
//     "is currently in use add another tag",
//     function (value) {
//       const { addressTypes } = this.parent;
//       //return addressTypes.filter((each: any) => each === (value && value.toLowerCase().trim())).length <= 1
//       return !addressTypes.includes(value && value.toLowerCase().trim());
//     }
//   ),
// });

export default function AddressDrawer(props: Props) {
  const { t } = useTranslation();
  const { handledrawer, drawerOpen, id, editId, addressTypes, setEditId } =
    props;

  const [editId2, setEditId2] = React.useState("");

  React.useEffect(() => {
    setEditId2(editId);
  }, [editId]);
  const [state, setState] = React.useState({
    right: false,
  });

  const validationSchemaPart1 = Yup.object({
    // others: Yup.string().when(("others","addressTypes"), {
    //     is: (value: string,value2:string[]) => value2.includes(value),
    //     then: (schema) => schema.required("Required"),
    // }),
    address1: Yup.string().required("Address 1 is required"),
    address2: Yup.string().required("Address 2 is required"),
    country: Yup.string().required("Country is required"),
    pinCode: Yup.string().required("Pin Code is required"),
    city: Yup.string().required("City is required"),
    recieverName: Yup.string().required("Receiver Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    addressValue: Yup.string().required("Address Type is required"),
    others: Yup.string()
      .when("addressValue", {
        is: (value: string) => value === "Others",
        then: (schema) => schema.required("value required"),
      })
      .test(
        "is-others-included",
        "is currently in use, add another tag",
        function (value) {
          const { addressTypes } = this.parent;
          return !addressTypes.includes(value?.toLowerCase().trim());
        }
      ),
  });
  const validationSchemaPart2 = Yup.object({
    // others: Yup.string().when(("others","addressTypes"), {
    //     is: (value: string,value2:string[]) => value2.includes(value),
    //     then: (schema) => schema.required("Required"),
    // }),
    address1: Yup.string().required("Address 1 is required"),
    address2: Yup.string().required("Address 2 is required"),
    country: Yup.string().required("Country is required"),
    pinCode: Yup.string().required("Pin Code is required"),
    city: Yup.string().required("City is required"),
    recieverName: Yup.string().required("Receiver Name is required"),
    mobile: Yup.string().required("Mobile is required"),
    others: Yup.string()
      .when("addressValue", {
        is: (value: string) => value === "Others",
        then: (schema) => schema.required("value required"),
      })
      .test(
        "is-others-included",
        "is currently in use, add another tag",
        function (value) {
          const { addressTypes } = this.parent;
          return !addressTypes.includes(value?.toLowerCase().trim());
        }
      ),
  });

  const [list, setList] = React.useState([
    {
      id: 1,
      name: "Home",
      isChecked: false,
      icon: HomeOutlinedIcon,
      disabled: addressTypes.includes("home"),
    },
    {
      id: 2,
      name: "Office",
      isChecked: false,
      icon: WorkOutlineOutlinedIcon,
      disabled: addressTypes.includes("office"),
    },
    {
      id: 3,
      name: "Others",
      isChecked: false,
      icon: LocationOnOutlinedIcon,
      disabled: false,
    },
  ]);

  React.useEffect(() => {
    setList([
      {
        id: 1,
        name: "Home",
        isChecked: false,
        icon: HomeOutlinedIcon,
        disabled: addressTypes.includes("home"),
      },
      {
        id: 2,
        name: "Office",
        isChecked: false,
        icon: WorkOutlineOutlinedIcon,
        disabled: addressTypes.includes("office"),
      },
      {
        id: 3,
        name: "Others",
        isChecked: false,
        icon: LocationOnOutlinedIcon,
        disabled: false,
      },
    ]);
    // const list = addressTypes.map((each) => each && each.toLowerCase().trim());
    // formik.setFieldValue("addressTypes", list);
  }, [addressTypes]);

  const initialValues = {
    address1: null,
    address2: null,
    country: null,
    pinCode: null,
    city: null,
    isDefault: false,
    others: "",
    recieverName: "",
    addressTypes: addressTypes,
    mobile: "",
    addressValue: "",
  };

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: editId ? validationSchemaPart2 : validationSchemaPart1,
    onSubmit: (values) => {
      if (editId !== "") {
        editData({
          variables: {
            addressId: +editId,
            city: values.city,
            address1: values.address1,
            address2: values.address2,
            postCode: values.pinCode,
            country: values.country,
            isDefault: values.isDefault,
            latitude: latitude && latitude.toString(),
            longitude: longitude && longitude.toString(),
            receiverName: values.recieverName,
            receiverMobile: values.mobile,
            addressType:
              list.filter((each) => each.isChecked)[0].name === "Others"
                ? values.others
                : list.filter((each) => each.isChecked)[0].name,
          },
        });
      } else {
        addData({
          variables: {
            businessId: +businessId,
            customerId: id && +id,
            addressDetails: {
              city: values.city,
              address1: values.address1,
              address2: values.address2,
              postCode: values.pinCode,
              country: values.country,
              isDefault: values.isDefault,
              latitude: latitude.toString(),
              longitude: longitude.toString(),
              receiverName: values.recieverName,
              receiverMobile: values.mobile,
              addressType:
                list.filter((each) => each.isChecked)[0].name === "Others"
                  ? values.others
                  : list.filter((each) => each.isChecked)[0].name,
            },
          },
        });
      }
    },
  });

  const [addData] = useMutation(ADD_ADDRESS, {
    context: { clientName: "customerClient" },
    onCompleted: () => {
      handleExit();
      toast.success(
        <div>
          <Typography>{t("toast.added")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [ADDRESS_LIST],
  });
  const [editData] = useMutation(UPDATE_ADDRESS_IN_EDIT, {
    context: { clientName: "addressClient" },
    onCompleted: () => {
      handleExit();
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [ADDRESS_LIST],
  });

  const { data: getAddress, refetch } = useQuery(GET_ADDRESS, {
    context: { clientName: "addressClient" },
    variables: { addressId: +editId },
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (getAddress && getAddress.address) {
      const initialValues = {
        address1: getAddress.address.address1,
        address2: getAddress.address.address2,
        country: getAddress.address.country,
        pinCode: getAddress.address.postCode,
        city: getAddress.address.city,
        isDefault: getAddress.address.isDefault,
        addressValue: "",
        others:
          getAddress.address.addressType !== "Office" &&
          getAddress.address.addressType !== "Home"
            ? getAddress.address.addressType
            : "",
        addressTypes: addressTypes,
        recieverName: getAddress.address.receiverName || "",
        mobile: getAddress.address.receiverMobile || "",
      };
      setLatitude(getAddress.address.latitude);
      setLongitude(getAddress.address.longitude);
      formik.setValues(initialValues);

      const data = list.map((each) => ({
        ...each,
        isChecked: each.name === getAddress.address.addressType,
      }));

      const checkedLength = data.filter((each) => each.isChecked).length;
      if (checkedLength > 0) {
        setList(data);
      } else {
        const data = list.map((each) => ({
          ...each,
          isChecked: each.name === "Others",
        }));
        setList(data);
      }
    }
  }, [getAddress]);

  const toggleDrawer = (open: boolean) => {
    setState({ ...state, right: open });
  };

  React.useEffect(() => {
    toggleDrawer(drawerOpen);
    // refetch()
  }, [drawerOpen]);

  const [longitude, setLongitude] = React.useState("");
  const [latitude, setLatitude] = React.useState("");
  function getCurrentLocation(addressComponents: any) {
    let currentLocation = "";
    const lanmarkComponrnt = addressComponents.find((component: any) =>
      component.types.includes("landmark")
    );
    if (lanmarkComponrnt) {
      currentLocation += lanmarkComponrnt.long_name + ", ";
    }
    // Look for the locality (city)
    const localityComponent = addressComponents.find(
      (component: any) =>
        // component.types.includes("political") ||
        component.types.includes("sublocality") ||
        component.types.includes("sublocality_level_2")
    );
    if (localityComponent) {
      currentLocation += localityComponent.long_name + ", ";
    }
    const cityComponent = addressComponents.find((component: any) =>
      component.types.includes("locality")
    );
    if (cityComponent) {
      currentLocation += cityComponent.long_name + ", ";
    }
    const stateComponent = addressComponents.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );
    if (stateComponent) {
      currentLocation += stateComponent.long_name + ", ";
    }
    // If neither city nor state is available, use country
    const countryComponent = addressComponents.find((component: any) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      currentLocation += countryComponent.long_name + ", ";
    }
    const postalCodeComponent = addressComponents.find((component: any) =>
      component.types.includes("postal_code")
    );
    if (postalCodeComponent) {
      currentLocation += postalCodeComponent.long_name;
    }
    return currentLocation;
  }
  // const locationKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE";
  const filteredCategory = {
    businessId: businessId,
  };
  const [locationKey, setLocationKey] = React.useState<string | null>(null);
  const {
    loading: loading,
    error: error,
    data: googleData,
  } = useQuery(GETTING_API_KEYS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });
  React.useEffect(() => {
    if (googleData) {
      const { business } = googleData;
      if (business) {
        const { apiKeys } = business;
        if (apiKeys) {
          const parsedApis = JSON.parse(apiKeys);
          const { google_maps_api_key } = parsedApis;
          setLocationKey(google_maps_api_key);
        }
      }
    }
  }, [googleData]);

  React.useEffect(() => {
    const controller = new AbortController();
    const getPinData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.pinCode}&key=${locationKey}`
        );
        const data = await response.json();
        if (data) {
          if (data.status === "OK") {
            const { results } = data;
            if (results) {
              const { address_components, geometry } = results[0];
              const currentLocation = getCurrentLocation(address_components);
              formik.setFieldValue("address2", currentLocation);
              const { location } = geometry;
              const { lat, lng } = location;
              setLatitude(lat);
              setLongitude(lng);
              const extractCityAndState = (addressComponents: any) => {
                let city = "";
                let state = "";
                addressComponents.forEach((component: any) => {
                  if (
                    component.types.includes("postal_town") ||
                    component.types.includes("locality")
                  ) {
                    city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    state = component.long_name;
                  }
                });
                return { city, state };
              };
              const { city, state } = extractCityAndState(address_components);
              formik.setFieldValue("city", city);
              formik.setFieldValue("country", state);
            }
          }
        }
        // fetch(
        //   `https://api.postalpincode.in/pincode/${
        //     formik.values.postCode && formik.values.postCode
        //   }`
        // )
        //   .then((response) => response.json())
        //   .then((data) => {
        //     if (data) {
        //       const { PostOffice } = data[0];
        //       if (PostOffice) {
        //         const { State, Division } = PostOffice[0];
        //         formik.setFieldValue("state", State);
        //         formik.setFieldValue("city", Division);
        //       }
        //     }
        //   });
      } catch (error) {
        console.log(error);
      }
    };
    getPinData();
    return () => {
      controller.abort();
    };
  }, [formik.values.pinCode]);

  const date = new Date(); // your date

  const dateString = date.toLocaleDateString("gbp");
  const timeString = date.toLocaleTimeString("gbp");

  const handleExit = () => {
    toggleDrawer(false);
    handledrawer();
    setEditId2("");
    setEditId("");
    formik.resetForm();

    formik.setValues(initialValues);
  };

  const handleCheck = (id: number, value: any) => {
    const row = list.map((each) => ({
      ...each,
      isChecked: each.id === id ? true : false,
    }));
    if (!value) {
      setList(row);
      // formik.setFieldValue("addressValue", row.filter((each) => each.isChecked)[0].name)
    }
  };
  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            // onClose={handleExit}
          >
            {
              <form onSubmit={formik.handleSubmit}>
                <Box
                  sx={{ width: "30vw", padding: "12px", textAlign: "center" }}
                >
                  <Box sx={{ display: "flex", gap: "10vw" }}>
                    <IconButton
                      className="exit-container "
                      onClick={handleExit}
                    >
                      <CloseIcon className="exitIcon" />
                    </IconButton>
                    {editId ? (
                      <Typography
                        sx={{ mt: "12px,", mb: "12px", fontSize: "16px" }}
                      >
                        Edit Address
                      </Typography>
                    ) : (
                      <Typography
                        sx={{ mt: "12px,", mb: "12px", fontSize: "16px" }}
                      >
                        Add Address
                      </Typography>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "24px",
                      marginTop: "12px",
                    }}
                  >
                    <TextField
                      label="Reciever Name"
                      value={formik.values.recieverName || ""}
                      name="recieverName"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.recieverName &&
                        Boolean(formik.errors.recieverName)
                      }
                      helperText={
                        formik.touched.recieverName &&
                        formik.errors.recieverName
                          ? formik.errors.recieverName
                          : ""
                      }
                    />
                    <MuiPhoneNumber
                      sx={{ width: "100%" }}
                      defaultCountry={"in"}
                      countryCodeEditable={true}
                      disableCountryCode={false}
                      id="mobile"
                      name="mobile"
                      label="Phone"
                      variant="outlined"
                      placeholder="Phone"
                      InputLabelProps={{ shrink: true }}
                      value={formik.values.mobile || ""}
                      onChange={(value: any) =>
                        formik.setFieldValue("mobile", value, true)
                      }
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.mobile && Boolean(formik.errors.mobile)
                      }
                      helperText={
                        formik.touched.mobile && formik.errors.mobile
                          ? formik.errors.mobile
                          : ""
                      }
                    />{" "}
                    <TextField
                      label="Post Code"
                      value={formik.values.pinCode || ""}
                      name="pinCode"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.pinCode && Boolean(formik.errors.pinCode)
                      }
                      helperText={
                        formik.touched.pinCode && formik.errors.pinCode
                          ? formik.errors.pinCode
                          : ""
                      }
                    />
                    <TextField
                      label="Address1"
                      value={formik.values.address1 || ""}
                      name="address1"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address1 &&
                        Boolean(formik.errors.address1)
                      }
                      helperText={
                        formik.touched.address1 && formik.errors.address1
                          ? formik.errors.address1
                          : ""
                      }
                      variant="outlined"
                    />
                    <TextField
                      label="Address2"
                      value={formik.values.address2 || ""}
                      name="address2"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      variant="outlined"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.address2 &&
                        Boolean(formik.errors.address2)
                      }
                      helperText={
                        formik.touched.address2 && formik.errors.address2
                          ? formik.errors.address2
                          : ""
                      }
                    />
                    <TextField
                      label="City"
                      value={formik.values.city || ""}
                      name="city"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      onBlur={formik.handleBlur}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={
                        formik.touched.city && formik.errors.city
                          ? formik.errors.city
                          : ""
                      }
                    />
                    <TextField
                      label="Country"
                      value={formik.values.country || ""}
                      name="country"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                      id="outlined-basic"
                      onBlur={formik.handleBlur}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                          ? formik.errors.country
                          : ""
                      }
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="warning"
                          checked={formik.values.isDefault}
                        />
                      }
                      label="Is Default"
                      name="isDefault"
                      onChange={(e) => {
                        formik.handleChange(e);
                      }}
                    />
                  </Box>
                  <List
                    sx={{ display: "flex", flexDirection: "row", gap: "12px" }}
                  >
                    {/* {list.map((each) => (
                      <ListItem
                        key={each.id}
                        sx={{ width: "30%", display: "flex" }}
                        onClick={() => {
                          handleCheck(each.id, each.disabled);
                        }}
                        className={
                          each.isChecked
                            ? "radioButton"
                            : each.disabled
                              ? "radioButton1"
                              : "radioButton2"
                        }
                      >
                        <each.icon />
                        <Typography>{each.name}</Typography>
                      </ListItem>
                    ))} */}
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-controlled-radio-buttons-group"
                        //  value={value}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="addressValue"
                      >
                        {list.map((each) => (
                          <FormControlLabel
                            control={
                              <Radio
                                sx={{
                                  "& .MuiSvgIcon-root": {
                                    fontSize: 0,
                                  },
                                }}
                                value={each.name}
                                disabled={each.disabled}
                              />
                            }
                            label={
                              <ListItem
                                key={each.id}
                                sx={{ width: "100%", display: "flex" }}
                                onClick={() => {
                                  handleCheck(each.id, each.disabled);
                                }}
                                className={
                                  each.isChecked
                                    ? "radioButton"
                                    : each.disabled
                                    ? "radioButton1"
                                    : "radioButton2"
                                }
                              >
                                <each.icon />
                                <Typography>{each.name}</Typography>
                              </ListItem>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  </List>
                  {formik.errors.addressValue &&
                    formik.touched.addressValue && (
                      <Typography
                        sx={{
                          color: "red",
                          marginTop: "12px",
                          fontSize: "12px",
                        }}
                      >
                        {formik.values.addressValue}{" "}
                        {formik.errors.addressValue}{" "}
                      </Typography>
                    )}

                  {list[2].isChecked && (
                    <Box sx={{ textAlign: "start" }}>
                      <TextField
                        fullWidth
                        label="Others"
                        value={formik.values.others}
                        name="others"
                        onChange={(e) => formik.handleChange(e)}
                      />
                      {formik.errors.others && formik.touched.others && (
                        <Typography
                          sx={{
                            color: "red",
                            marginTop: "12px",
                            fontSize: "12px",
                          }}
                        >
                          {formik.values.others} {formik.errors.others}{" "}
                        </Typography>
                      )}
                    </Box>
                  )}
                  <Button
                    className="main-button"
                    type="submit"
                    sx={{ mt: "12px" }}
                  >
                    Save
                  </Button>
                </Box>
              </form>
            }
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
