function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;

    if (
        typeof obj1 !== "object" ||
        obj1 === null ||
        typeof obj2 !== "object" ||
        obj2 === null
    ) {
        return false;
    }

    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) return false;

    for (let key of keys1) {
        if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) return false;
    }

    return true;
}

export const deepArrayEqualWithUnmatched = (
    arr1: any[],
    arr2: any[]
): {
    areEqual: boolean;
    changed: any[];
    added: any[];
    deleted: any[];
} => {
    const changed: any[] = [];
    const added: any[] = [];
    const deleted: any[] = [];

    const arr1Checked = new Set<number>();
    const arr2Checked = new Set<number>();

    // Identify added and changed items
    arr2 && arr2.forEach((element2, i) => {
        const matchingIndex = arr1.findIndex(element1 => element1.id === element2.id);
        if (matchingIndex !== -1) {
            if (arr1[matchingIndex].id !== element2.id) {
                added.push(element2);
            } else {
                if (!deepEqual(arr1[matchingIndex], element2)) {
                    changed.push({ old: arr1[matchingIndex], new: element2 });
                }
            }
            arr1Checked.add(matchingIndex);
        } else {
            added.push(element2);
        }
        arr2Checked.add(i);
    });

    // Identify deleted items
    arr1 && arr1.forEach((element1, i) => {
        const matchingIndex = arr2 ? arr2.findIndex(element2 => element2.id === element1.id) : -1;
        if (matchingIndex === -1) {
            deleted.push(element1);
        } else if (arr2[matchingIndex].id !== element1.id) {
            deleted.push(element1);
        }
        arr1Checked.add(i);
    });

    return { areEqual: changed.length === 0 && added.length === 0 && deleted.length === 0, changed, added, deleted };
}

// Combine logic
export const processVariants = (
    bulkRows: any[],
    initilaGetData: any[],

    productIdByEdit?: true
) => {
    const updatedProductList = bulkRows.map((each) => {
        const bulkRowProduct = bulkRows.find((item) => each.id === item.id);
        const initialDataProduct = initilaGetData.find((item: any) => each.id === item.id);

        const resultObject = bulkRowProduct && initialDataProduct
            ? deepArrayEqualWithUnmatched(
                bulkRowProduct.variations,
                initialDataProduct.variations
            )
            : {
                areEqual: false,
                changed: [],
                added: bulkRowProduct ? bulkRowProduct.variations : [],
                deleted: initialDataProduct ? initialDataProduct.variations : []
            };

        const variantObject: {
            new_variants?: any;
            updated_variants?: any;
            removable_variants?: any;
        } = {};

        if (resultObject.deleted) {
            variantObject.new_variants = resultObject.deleted.map((each: any) => ({
                name: each.product,
                additional_price: +each.price || 0,
                sku: each.sku,


                variant_group: each.variantGroup
            }));
        }

        if (resultObject.changed) {
            variantObject.updated_variants = resultObject.changed.map((each: any) => {
                let updatedVariant: any = {
                    name: each.old.product,
                    additional_price: +each.old.price || 0,
                    sku: each.old.sku,



                    variant_group: each.old.variantGroup
                };

                if (productIdByEdit) {
                    updatedVariant.variant_id = +each.old.id;
                }

                return updatedVariant;
            });
        }

        if (resultObject.added) {
            variantObject.removable_variants = resultObject.added.map((each: any) => {
                if (productIdByEdit) {
                    return +each.id;
                } else {
                    return each.name;
                }
            });
        }

        return {
            ...each,
            variationsList: resultObject,
            variantObject
        };
    });

    return updatedProductList;
}
