const RESET_STATE = 'RESET_STATE';
interface ResetStateAction {
    type: typeof RESET_STATE;
  }
  export const resetState = (): ResetStateAction => ({
    type: RESET_STATE,
  });
  
export interface Requried {
    
    location:number|null,
    businessId:number|null
    
  }

  
  
  const MAIN_DETAILS = 'MAIN_DETAILS'; 

  
  
  export interface MainData {
    type: typeof MAIN_DETAILS;
    payload: {
        field: string;
        name: number;   
      };
  }

 

  
  const categoryData: Requried = {
   
    location:null,
    businessId:null
   
  };

  
  export const setMainData = (field:string,name:number|any): MainData => ({
    type: MAIN_DETAILS,
    payload: {
        field,
        name  
      },
  });
  

  


  export const mainDataReducer = (state = categoryData, action: MainData|ResetStateAction): Requried => {
    switch (action.type) {
      case MAIN_DETAILS:
        return {
            ...state,
            [action.payload.field]: action.payload.name,
          };

          
          case RESET_STATE:
            return {
              ...categoryData, 
            };
        
      default:
        return state;
    }
  };

  