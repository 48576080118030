import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import '../category/createCategory.css'
import { useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { resetState, setPricing } from '../../store';
import { Divider } from '@mui/material';

interface Props {
  dailouge: boolean
  handleUpdateDailougeState: () => void;
  handleComplete: () => void;
  categoryId: number[]
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function AlertCreateDialog(props: Props) {
  const { dailouge, handleUpdateDailougeState, handleComplete, categoryId } = props
  const [open, setOpen] = React.useState(dailouge);
  const { name } = useSelector((state: any) => state.app);
  const { condition2 } = useSelector((state: any) => state.pricing);



  const dispatch = useDispatch()
  const handleClose = () => {
    setOpen(false);
    handleUpdateDailougeState()
  };

  useEffect(() => {
    if (categoryId.length > 0 && condition2)
      setOpen(true);
  }, [categoryId]);


  const navigate = useNavigate()

  const handleSkipNavigate = () => {
    navigate('/product/product-list')
    dispatch(setPricing("productId", []));
    dispatch(resetState())

  }

  const handleNextButton = () => {
    handleComplete()
    dispatch(setPricing("condition2", false))
  }

  return (
    <div>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ textAlign: 'center' }}>
            {/* <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
  </Box> */}

            <Typography className='category-created'>Product Created</Typography>
          </Box>
          <Divider sx={{ mt: "8px", mb: "12px", width: "100%" }} />
          <Box sx={{ padding: "3% 1% 3% 1%", marginTop: '1%' }}>


            <Typography className='create_category_modal_text'>

              <span className='createCategory-table-head'> Do you want to continue to assign options to the product?</span>  then <span className='create_category_modal_next_text'>click Next</span> or you can skip and go to product listing.</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>
            <Button className='create_category_modal_skip_button' onClick={() => { handleClose(); handleSkipNavigate() }}>Skip</Button>
            <Button className='create_category_modal_next_button' sx={{ marginLeft: "3%" }} onClick={() => { handleClose(); handleNextButton() }}>Next {<ArrowForwardIcon />}</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}










