import {
  Box,
  InputLabel,
  Typography,
  OutlinedInput,
  InputAdornment,
  ToggleButton,
  ToggleButtonGroup,
  FormControl,
  Switch,
  Divider,
} from "@mui/material";
import React from "react";

import "./kdsSettings.css";

import "./kitchenSettings.css";
import { useTranslation } from "react-i18next";

interface KdsSettingsState {
  formik: any;
}

function ManualQuotingTime(props: KdsSettingsState) {
  const { t } = useTranslation();
  const { formik } = props;
  const [alignment, setAlignment] = React.useState("orderType");
  const currencySymbol = sessionStorage.getItem("currencySymbol");

  //Textfield functions

  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string
  ) => {
    if (newAlignment) {
      setAlignment(newAlignment);
    } else {
      setAlignment("orderType");
    }
  };

  return (
    <Box>
      <Box>
        <Box sx={{ marginBottom: "20px", marginTop: "20px", width: "25%" }}>
          <ToggleButtonGroup
            value={alignment}
            exclusive
            fullWidth
            onChange={handleChange}
          >
            <ToggleButton
              className={`distance-wise-circle-button ${
                alignment === "orderType" && "distance-wise-group-circle-button"
              }`}
              sx={{ textTransform: "none" }}
              value="orderType"
            >
              <Typography className="distance-wise-circle-button">
                {t("devices.order_type")}
              </Typography>
            </ToggleButton>
            <ToggleButton
              className={`distance-wise-circle-button ${
                alignment === "channels" && "distance-wise-group-circle-button"
              }`}
              sx={{ textTransform: "none" }}
              value="channels"
            >
              <Typography className="distance-wise-circle-button">
                {t("devices.channels")}
              </Typography>
            </ToggleButton>
          </ToggleButtonGroup>
        </Box>
      </Box>

      {alignment === "orderType" ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
              }}
            >
              <Typography className="kds-settings-dinein-text">
                {t("locations.dine_in")}
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.dineInHr || ""}
                    name="dineInHr"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.hr")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>

                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.dineInMin || ""}
                    name="dineInMin"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.min")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
              }}
            >
              <Typography className="kds-settings-dinein-text">
                {t("devices.collection")}
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.collectionHr || ""}
                    name="collectionHr"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.hr")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.collectionMin || ""}
                    name="collectionMin"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.min")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "45%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography className="kds-settings-dinein-text">
              {t("devices.delivery")}
            </Typography>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: "45%" }} variant="outlined">
                <OutlinedInput
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (
                      !val ||
                      (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  type="text"
                  value={formik.values.deliveryHr || ""}
                  name="deliveryHr"
                  color="warning"
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        <Typography className="kds-settings-hr-label-text">
                          {t("devices.hr")}
                        </Typography>
                      }
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "45%" }} variant="outlined">
                <OutlinedInput
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (
                      !val ||
                      (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  type="text"
                  value={formik.values.deliveryMin || ""}
                  name="deliveryMin"
                  color="warning"
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        <Typography className="kds-settings-hr-label-text">
                          {t("devices.min")}
                        </Typography>
                      }
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
      ) : (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
              }}
            >
              <Typography className="kds-settings-dinein-text">
                {t("devices.channel_1")}
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.channel1Hr || ""}
                    name="channel1Hr"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.hr")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.channel1Min || ""}
                    name="channel1Min"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.min")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Box>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "45%",
              }}
            >
              <Typography className="kds-settings-dinein-text">
                {t("devices.channel_2")}
              </Typography>
              <Box
                sx={{
                  width: "60%",
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.channel2Hr || ""}
                    name="channel2Hr"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.hr")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
                <FormControl sx={{ width: "45%" }} variant="outlined">
                  <OutlinedInput
                    onBlur={formik.handleBlur}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (
                        !val ||
                        (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                      ) {
                        formik.handleChange(e);
                      }
                    }}
                    type="text"
                    value={formik.values.channel2Min || ""}
                    name="channel2Min"
                    color="warning"
                    endAdornment={
                      <InputAdornment position="end">
                        {
                          <Typography className="kds-settings-hr-label-text">
                            {t("devices.min")}
                          </Typography>
                        }
                      </InputAdornment>
                    }
                    aria-describedby="outlined-weight-helper-text"
                    inputProps={{
                      "aria-label": "weight",
                    }}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "45%",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <Typography className="kds-settings-dinein-text">
              {t("devices.channel_3")}
            </Typography>
            <Box
              sx={{
                width: "60%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <FormControl sx={{ width: "45%" }} variant="outlined">
                <OutlinedInput
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (
                      !val ||
                      (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  type="text"
                  value={formik.values.channel3Hr || ""}
                  name="channel3Hr"
                  color="warning"
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        <Typography className="kds-settings-hr-label-text">
                          {t("devices.hr")}
                        </Typography>
                      }
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
              <FormControl sx={{ width: "45%" }} variant="outlined">
                <OutlinedInput
                  onBlur={formik.handleBlur}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (
                      !val ||
                      (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                    ) {
                      formik.handleChange(e);
                    }
                  }}
                  type="text"
                  value={formik.values.channel3Min || ""}
                  name="channel3Min"
                  color="warning"
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        <Typography className="kds-settings-hr-label-text">
                          {t("devices.min")}
                        </Typography>
                      }
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": "weight",
                  }}
                />
              </FormControl>
            </Box>
          </Box>
        </Box>
      )}

      <Divider />

      <Box sx={{ marginTop: "15px", width: "70%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "35%",
            marginTop: "15px",
          }}
        >
          <Typography
            sx={{
              whiteSpace: "nowrap", // Prevents text from wrapping
              overflow: "hidden", // Hides any overflow text
              textOverflow: "ellipsis",
            }}
            className="kds-settings-dinein-text"
          >
            {t("devices.order_pricing_rules")}
          </Typography>
          <Switch
            name="orderPricingRules"
            checked={formik.values.orderPricingRules}
            color="warning"
            onChange={formik.handleChange}
          />
        </Box>

        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-accommodate-label-text"
        >
          {t("devices.to_accommodate_large")}
        </Typography>
      </Box>

      {/* <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          marginTop: "15px",
        }}
      >
        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("devices.if_an_order")}
        </Typography>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              className="kds-settings-amt-text"
              htmlFor="outlined-adornment-amount"
            >
              {t("devices.amount")}
            </InputLabel>
            <OutlinedInput
              disabled={!formik.values.orderPricingRules}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingAmount || ""}
              name="orderPricingAmount"
              color="warning"
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: formik.values.orderPricingRules
                        ? "#333"
                        : "#ababab",
                    }}
                  >
                    {currencySymbol}
                  </Typography>
                </InputAdornment>
              }
              label="Amount"
            />
          </FormControl>
          {formik.touched.orderPricingAmount &&
            formik.errors.orderPricingAmount && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {t("error.required")}
                </Typography>
              </Box>
            )}
        </Box>

        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("buttons.add")}
        </Typography>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <OutlinedInput
              disabled={!formik.values.orderPricingRules}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingHr || ""}
              name="orderPricingHr"
              color="warning"
              endAdornment={
                <InputAdornment position="end">
                  {
                    <Typography
                      sx={{
                        color: formik.values.orderPricingRules
                          ? "#333"
                          : "#ababab",
                      }}
                      className="kds-settings-hr-label-text"
                    >
                      {t("devices.hr")}
                    </Typography>
                  }
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          {formik.touched.orderPricingHr && formik.errors.orderPricingHr && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {t("error.required")}
              </Typography>
            </Box>
          )}
        </Box>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <OutlinedInput
              onBlur={formik.handleBlur}
              disabled={!formik.values.orderPricingRules}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingMin || ""}
              name="orderPricingMin"
              color="warning"
              endAdornment={
                <InputAdornment position="end">
                  {
                    <Typography
                      sx={{
                        color: formik.values.orderPricingRules
                          ? "#333"
                          : "#ababab",
                      }}
                      className="kds-settings-hr-label-text"
                    >
                      {t("devices.min")}
                    </Typography>
                  }
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          {formik.touched.orderPricingMin && formik.errors.orderPricingMin && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {t("error.required")}
              </Typography>
            </Box>
          )}
        </Box>

        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("devices.to_the_order")}
        </Typography>
      </Box> */}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: "20px",
          marginTop: "15px",
        }}
      >
        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("devices.if_an_order")}
        </Typography>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel
              className="kds-settings-amt-text"
              htmlFor="outlined-adornment-amount"
            >
              {t("devices.amount")}
            </InputLabel>
            <OutlinedInput
              disabled={!formik.values.orderPricingRules}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingAmount || ""}
              name="orderPricingAmount"
              color="warning"
              id="outlined-adornment-amount"
              startAdornment={
                <InputAdornment position="start">
                  <Typography
                    sx={{
                      color: formik.values.orderPricingRules
                        ? "#333"
                        : "#ababab",
                    }}
                  >
                    {currencySymbol}
                  </Typography>
                </InputAdornment>
              }
              label="Amount"
            />
          </FormControl>
          <Box sx={{ minHeight: "15px" }}>
            {formik.touched.orderPricingAmount &&
              formik.errors.orderPricingAmount && (
                <Typography className="create-a-user-formik-label">
                  {t("error.required")}
                </Typography>
              )}
          </Box>
        </Box>

        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("buttons.add")}
        </Typography>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <OutlinedInput
              disabled={!formik.values.orderPricingRules}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingHr || ""}
              name="orderPricingHr"
              color="warning"
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      color: formik.values.orderPricingRules
                        ? "#333"
                        : "#ababab",
                    }}
                    className="kds-settings-hr-label-text"
                  >
                    {t("devices.hr")}
                  </Typography>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Box sx={{ minHeight: "15px" }}>
            {formik.touched.orderPricingHr && formik.errors.orderPricingHr && (
              <Typography className="create-a-user-formik-label">
                {t("error.required")}
              </Typography>
            )}
          </Box>
        </Box>

        <Box sx={{ width: "15%" }}>
          <FormControl sx={{ width: "100%" }} variant="outlined">
            <OutlinedInput
              onBlur={formik.handleBlur}
              disabled={!formik.values.orderPricingRules}
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 5)
                ) {
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.orderPricingMin || ""}
              name="orderPricingMin"
              color="warning"
              endAdornment={
                <InputAdornment position="end">
                  <Typography
                    sx={{
                      color: formik.values.orderPricingRules
                        ? "#333"
                        : "#ababab",
                    }}
                    className="kds-settings-hr-label-text"
                  >
                    {t("devices.min")}
                  </Typography>
                </InputAdornment>
              }
              aria-describedby="outlined-weight-helper-text"
              inputProps={{
                "aria-label": "weight",
              }}
            />
          </FormControl>
          <Box sx={{ minHeight: "15px" }}>
            {formik.touched.orderPricingMin &&
              formik.errors.orderPricingMin && (
                <Typography className="create-a-user-formik-label">
                  {t("error.required")}
                </Typography>
              )}
          </Box>
        </Box>

        <Typography
          sx={{
            color: formik.values.orderPricingRules ? "#333" : "#ababab",
          }}
          className="kds-settings-if-order-text"
        >
          {t("devices.to_the_order")}
        </Typography>
      </Box>
    </Box>
  );
}

export default ManualQuotingTime;
