import {
  Autocomplete,
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { Dayjs } from "dayjs";
import * as Yup from "yup";

import CloseIcon from "../../../../../assets/close2.png";
import FramedDeleteIcon from "../../../../../assets/FramedDelete.svg";
import React, { useEffect } from "react";
import { Form, Formik } from "formik";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import * as yup from "yup";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { useTranslation } from "react-i18next";
import {
  DatePicker,
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from "@mui/x-date-pickers";
import moment from "moment";
import { useMutation } from "@apollo/client";

import dayjs from "dayjs";
import { useSelector } from "react-redux";
import AutoCompleteDropdown from "../../../../../pages/popups/autoCompleteDropdown";

interface Props {
  formik: any;
  employeeList: any[];
  categoryIdForAdding: string | null;
  setCategoryIdForAdding: React.Dispatch<React.SetStateAction<string | null>>;
}

type EmployeeData = {
  "Employee Name": any;
  "Employee Role": any;
  Date: string;
};

const Entries = (props: Props) => {
  const { formik, employeeList } = props;

  const today = new Date().toISOString().split("T")[0];

  const handleAutoComplete = (newData: any) => {
    if (newData) {
      const { id, label } = newData;
      formik.setFieldValue("employeeId", id);
      formik.setFieldValue("employeeName", label);
    } else {
      formik.setFieldValue("employeeId", null);
      formik.setFieldValue("employeeName", null);
    }
  };

  const { t } = useTranslation();

  const employeeData: EmployeeData = {
    "Employee Name": formik.values.employee,
    "Employee Role": formik.values.employeeRole,
    Date: formik.values.date,
  };

  return (
    <Box sx={{ width: "460px" }}>
      <Box
        sx={{
          display: "flex",
          padding: "32px 20px 7px 20px",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "24px",
          background: "#FBFBFB",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() => {
            formik.resetForm();
          }}
        />
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "normal",
            color: "#333333",
          }}
        >
          {formik.values.attendanceId !== null
            ? t("log_tome.edit_time_log")
            : t("log_tome.add_time_log")}
        </Typography>

        <Box
          sx={{
            width: "fit-content",
            visibility: "hidden",
            height: "44px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              lineHeight: "27px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
              textTransform: "capitalize",
            }}
          >
            {formik.values.attendanceId !== null
              ? t("log_tome.save")
              : t("log_tome.add_entree")}
          </Typography>
        </Box>
      </Box>

      {formik.values.attendanceId !== null ? (
        <Box
          sx={{
            borderRadius: "4px",
            border: "1px solid #EEE",
            width: "400px",
            marginLeft: "20px",
            padding: "12px",
            marginBottom: "20px",
            display: "flex",
            flexDirection: "column",
            rowGap: "12px",
          }}
        >
          {Object.keys(employeeData).map((entry: any) => (
            <Box
              sx={{
                display: "flex",
                fontFamily: "Poppins",
                fontWeight: "400",
                fontSize: "14px",
                lineHeight: "normal",
                color: "#333333",
              }}
            >
              <Box sx={{ width: "200px" }}>{entry}:</Box>
              {employeeData && (
                <Box sx={{ fontWeight: "500" }}>
                  {employeeData[entry as keyof EmployeeData]}
                </Box>
              )}
            </Box>
          ))}
        </Box>
      ) : null}

      <Button
        type="submit"
        sx={{
          width: "fit-content",
          position: "absolute",
          textTransform: "capitalize",
          top: "32px",
          left: `${formik.values.attendanceId !== null ? "380px" : "330px"}`,
          height: "44px",
          color: "#ffffff",
          backgroundColor: "#F38B08",
          display: "flex",
          justifyContent: "center",
          "&:hover": {
            backgroundColor: "#F38B08",
          },
        }}
      >
        {formik.values.attendanceId !== null
          ? t("log_tome.save")
          : t("log_tome.add_entree")}
      </Button>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginLeft: "20px",
          gap: "10px",
        }}
      >
        {formik.values.attendanceId === null && (
          <Box>
            <AutoCompleteDropdown
              handleChange={handleAutoComplete}
              data={employeeList}
              width="400px"
              label={t("log_tome.search_by_employee")}
              value={{
                id: formik.values.employeeId || "",
                label: formik.values.employeeName || "",
              }} // Correct structure
            />

            {formik.touched.employeeName && formik.errors.employeeName && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {t("error.required")}
                </Typography>
              </Box>
            )}
          </Box>
        )}

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            width: "400px",
          }}
        >
          <Typography>{t("inventory.date")}</Typography>
          <TextField
            color="warning"
            type="date"
            name="date"
            value={formik.values.date}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.handleChange(e)}
            sx={{ width: "100%" }}
            inputProps={{ max: today }}
          />
          {formik.touched.date && formik.errors.date && (
            <Box>
              <Typography className="create-a-user-formik-label">
                {t("error.required")}
              </Typography>
            </Box>
          )}
        </Box>

        <Box
          sx={{
            display: "flex",
            columnGap: "12px",
          }}
        >
          {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <TimePicker
                label="From Time"
                value={
                  formik.values.fromTime
                    ? moment(formik.values.fromTime, "hh:mm a")
                    : null
                }
                onChange={(newValue: any) => {
                  const timeValue = newValue ? newValue.format("HH:mm") : "";
                  formik.setFieldValue("", timeValue);
                  // props.setFieldTouched("fromTime", true);
                  // formik.handleChange({
                  //   target: {
                  //     name: 'startTime',
                  //     value: timeValue,
                  //   },
                  // });
                  // dispatch(setVisibility("startTime", timeValue));
                  // formik.setFieldTouched('startTime', true);
                }}
                sx={{
                  width: "195px",
                  "&.MuiFormControl-root.MuiTextField-root": {
                    minWidth: "unset",
                  },
                  "&.MuiFormLabel-root.Mui-error": {
                    color: "inherit",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["TimePicker", "TimePicker"]}>
              <TimePicker
                label="To Time"
                value={
                  formik.values.toTime
                    ? moment(formik.values.toTime, "hh:mm a")
                    : null
                }
                onChange={(newValue: any) => {
                  const timeValue = newValue ? newValue.format("HH:mm") : "";

                  formik.setFieldValue("toTime", timeValue);
                  // props.setFieldTouched("toTime", true);
                  // formik.handleChange({
                  //   target: {
                  //     name: 'startTime',
                  //     value: timeValue,
                  //   },
                  // });
                  // dispatch(setVisibility("startTime", timeValue));
                  // formik.setFieldTouched('startTime', true);
                }}
                sx={{
                  width: "195px",
                  "&.MuiFormControl-root.MuiTextField-root": {
                    minWidth: "unset",
                  },
                  "&.MuiFormLabel-root.Mui-error": {
                    color: "inherit",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider> */}

          <Box sx={{ width: "195px" }}>
            <Typography>{t("devices.from_time")}</Typography>
            <TextField
              color="warning"
              type="time"
              value={formik.values.fromTime}
              name="fromTime"
              onChange={(e) => formik.handleChange(e)}
              sx={{ width: "100%" }}
            />
          </Box>

          <Box sx={{ width: "195px" }}>
            <Typography>{t("devices.to_time")}</Typography>
            <TextField
              color="warning"
              type="time"
              value={formik.values.toTime}
              name="toTime"
              onChange={(e) => formik.handleChange(e)}
              // onChange={(e) => handleTimeChange(e.target.value)}
              sx={{ width: "100%" }}
            />
          </Box>
        </Box>
        {formik.touched.toTime && formik.errors.toTime && (
          <Box>
            <Typography className="create-a-user-formik-label">
              {formik.errors.toTime === "Required"
                ? t("error.required")
                : "End time must be greater than start time"}
            </Typography>
          </Box>
        )}
        {/* {errors.toTime && (
                <div style={{ color: "red", fontSize: "12px" }}>
                  {errors.toTime as string}
                </div>
              )} */}
      </Box>

      <TextField
        sx={{
          width: "400px",
          height: "74px",
          marginLeft: "20px",
          marginTop: "20px",
        }}
        id="note"
        name="note"
        variant="outlined"
        placeholder="Add note"
        value={formik.values.note}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        // error={touched?.note && Boolean(errors?.note)}
        // helperText={touched?.note && errors?.note}
      />
    </Box>
  );
};

export default Entries;
