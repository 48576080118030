import React, { useState } from "react";
import {
  Typography,
  Switch,
  IconButton,
  Checkbox,
  Collapse,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CurrencyDisplay from "../utils/currencySymbol";

function createData(
  name: string,
  locationFilterVariants: [],
  productStatus: string,
  image: string,
  productId: string,
  unit: string,
  sku: string,
  stockCount: string,
  slugName: string,
  price: string,
  additionalPrice: string,
  priceToDisplay: string,
  isChecked: boolean,
  minQunatity: number
) {
  return {
    name,
    slugName,
    productId,
    unit,
    stockCount,
    locationFilterVariants,
    productStatus,
    image,
    sku,
    isChecked,
    additionalPrice,
    priceToDisplay,
    category: {
      name,
    },
    minQunatity,
    inventory: [],
    price,
    variants: [{}],
  };
}

interface Row {
  handleProductStatusChangeForVariant: (
    variantId: string,
    variantName: string,
    status: string
  ) => void;
  handleProductStatusChange: (
    productId: string,
    productName: string,
    status: string
  ) => void;
  handleThreedotsClick: (
    event: any,
    productId: string,
    productName: string
  ) => void;

  checkboxIds: any[];

  handleRowCheckBox: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  row: ReturnType<typeof createData>;
  visibleColumns: string[];
}

const Row = (props: Row) => {
  const {
    handleThreedotsClick,
    handleProductStatusChange,
    row,
    handleProductStatusChangeForVariant,
    visibleColumns,
    handleRowCheckBox,
    checkboxIds,
  } = props;

  const locationFilterVariants = row.variants || [];

  const [open, setOpen] = React.useState(false);

  function toPascalCase(str: any) {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  }
  return (
    <>
      <div
        style={{ border: "2px solid #eee", margin: "5px", padding: "6px" }}
        className="header-wrapper"
      >
        <div
          className="table-cell products-list-main-row"
          style={{ display: "flex", alignItems: "center", gap: "10px" }}
        >
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {locationFilterVariants.length !== 0 &&
              (open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />)}
          </IconButton>
          <Checkbox
            checked={checkboxIds.includes(+row.productId)}
            onChange={(e) => handleRowCheckBox(e, row.productId)}
            sx={{
              "& .MuiSvgIcon-root": { fontSize: "1.9rem" },
              marginLeft: locationFilterVariants.length === 0 ? "22px" : "",
            }}
            color="warning"
          />
          {row.image && (
            <img
              style={{
                width: "25px",
                height: "25px",
                borderRadius: "5px",
              }}
              alt="img"
              src={row.image}
            />
          )}
          <Typography
            style={{ textTransform: "capitalize" }}
            className="products-list-main-row"
          >
            {row.name}
          </Typography>
        </div>

        <div className="table-cell products-list-main-row">
          <Typography
            style={{ textTransform: "capitalize", paddingTop: "15px" }}
            className="products-list-main-row"
          >
            {row.category ? row.category.name : "N/A"}
          </Typography>
        </div>

        {visibleColumns.includes("Status") ? (
          <div className="table-cell products-list-main-row">
            <IconButton>
              <Switch
                color="warning"
                checked={row.productStatus === "Active"}
                onChange={(e) =>
                  handleProductStatusChange(
                    row.productId,
                    row.name,
                    row.productStatus
                  )
                }
              />
            </IconButton>
          </div>
        ) : (
          <div className="table-cell products-list-main-row"></div>
        )}

        {visibleColumns.includes("Sku") ? (
          <div
            className="table-cell products-list-main-row"
            style={{ paddingTop: "15px" }}
          >
            {row.sku ? row.sku : "N/A"}
          </div>
        ) : (
          <div className="table-cell products-list-main-row"></div>
        )}

        {visibleColumns.includes("Unit") ? (
          <div
            className="table-cell products-list-main-row"
            style={{ paddingTop: "15px" }}
          >
            {row.unit ? toPascalCase(row.unit) : "0"}
          </div>
        ) : (
          <div className="table-cell products-list-main-row"></div>
        )}

        <div
          className="table-cell products-list-main-row"
          style={{ paddingTop: "15px" }}
        >
          {row.price && (
            <CurrencyDisplay
              countryName={"UnitedKingdom"}
              amount={row.price ? row.price : ""}
            />
          )}
        </div>

        <div className="table-cell" style={{ paddingTop: "12px" }}>
          <IconButton
            onClick={(event) =>
              handleThreedotsClick(event, row.productId, row.name)
            }
          >
            <MoreVertIcon />
          </IconButton>
        </div>
      </div>

      <div className="">
        <Collapse in={open} timeout="auto" unmountOnExit>
          <div className="collapsible-content">
            {locationFilterVariants.length !== 0 &&
              row.variants.map((historyRow: any) => (
                <div
                  style={{
                    padding: "6px",
                    border: "1px solid #eee",
                    // marginBottom: "1px",
                  }}
                  className="header-wrapper"
                  key={historyRow.variantId}
                >
                  <div className="table-cell products-list-sub-row">
                    <Typography
                      style={{
                        textTransform: "capitalize",
                        marginLeft: "10%",
                        paddingTop: "15px",
                      }}
                      className="products-list-sub-row"
                    >
                      {historyRow.name}
                    </Typography>

                    <div>
                      <div className="table-cell products-list-main-row">
                        {row.additionalPrice && (
                          <CurrencyDisplay
                            countryName={"UnitedKingdom"}
                            amount={
                              row.additionalPrice ? row.additionalPrice : ""
                            }
                          />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="table-cell products-list-main-row"></div>
                  <div className="table-cell products-list-main-row">
                    {visibleColumns.includes("Status") && (
                      <IconButton>
                        <Switch
                          checked={historyRow.variantStatus === "Active"}
                          onChange={(e) =>
                            handleProductStatusChangeForVariant(
                              historyRow.variantId,
                              historyRow.name,
                              historyRow.variantStatus
                            )
                          }
                          color="warning"
                        />
                      </IconButton>
                    )}
                  </div>

                  <div className="table-cell products-list-main-row"></div>
                  <div className="table-cell products-list-main-row"></div>

                  <div className="table-cell products-list-sub-row">
                    <Typography
                      style={{ paddingTop: "15px" }}
                      className="products-list-sub-row"
                    >
                      <CurrencyDisplay
                        countryName={"UnitedKingdom"}
                        amount={
                          historyRow.additionalPrice
                            ? historyRow.additionalPrice
                            : 0
                        }
                      />
                    </Typography>
                  </div>
                  <div className="table-cell products-list-main-row"></div>
                </div>
              ))}
          </div>
        </Collapse>
      </div>
    </>
  );
};

export default Row;
