import * as React from "react";
import Modal from "@mui/joy/Modal";
import ProfileMenuModal from "./profileMenuModal";

interface State {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}



const ProfileMenu = (props: State) => {
  const { open, setOpen } = props;
  const handleCloseButton = () => {
    setOpen(false);
  }
  return (
    <React.Fragment>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={open}
        onClose={(event, reason) => {
          setOpen(false);
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          position: "fixed",
          top: "90px",
          right: "12px",
          left: { xs: '12px', sm: '0px' },
          opacity: 1,
        }}
      >
        <ProfileMenuModal handleCloseButton={handleCloseButton} />
      </Modal>
    </React.Fragment>
  );
};

export default ProfileMenu;
