import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useRef, useState } from "react";

import * as yup from "yup";
import { countries } from "../../common/countries";
import { useTranslation } from "react-i18next";
import {
  DEACTIVATE_BUSINESS,
  UPDATE_ADDRESS,
  UPDATE_BUSINESS,
} from "../../../graphicalQl/mutation/customerDataMutation";
import { useMutation, useQuery } from "@apollo/client";
import { ADDRESS_DETAILS } from "../../../graphicalQl/usequery/customerDataQuery";
import { toast } from "react-toastify";
import ImageDrawer from "../../../pages/menu/image/imageDrawer";

interface Props {
  businessDetails: any;
  refetchBusinessDetails: any;
}

const BusinessAccount = (props: Props) => {
  const { businessDetails, refetchBusinessDetails } = props;
  // const validationSchema = yup.object({
  //   email: yup
  //     .string()
  //     .email("Enter a valid email")
  //     .required("Please enter the email"),
  //   firstName: yup.string().required("Please enter the first name"),
  //   lastName: yup.string().required("Please enter the last name"),
  //   mobile: yup.string().required("Please enter a mobile number"),
  // });
  const { t } = useTranslation();
  const formik = useFormik({
    // validationSchema,
    initialValues: {
      //editable if account details are passed
      brandLogo: businessDetails.brandLogo,
      brandColor: "",
      businessAddress: "",
      postCode: "",
      country: "",
      businessName: businessDetails.businessName as string,
      city: "",
      state: "",
      favIcon: businessDetails.favIcon,
      favColor: businessDetails.favColor,
    },
    onSubmit: (values) => {
      updateBusinessMutation({
        variables: {
          brandLogo: formik.values.brandLogo,
          favIcon: formik.values.favIcon,
          businessId: businessDetails.businessId,
          legalBusinessName: values.businessName,
          addressDetails: {
            address1: String(values.businessAddress).split(",")[0],
            address2: String(values.businessAddress).split(",")[1],
            postCode: values.postCode,
            country: values.country,
            city: values.city,
            state: values.state,
          },
        },
      });

      updateAddressMutation({
        variables: {
          addressId: businessDetails.addressId,
          address1: String(values.businessAddress).split(",")[0],
          address2: String(values.businessAddress).substring(
            String(values.businessAddress).indexOf(",") + 1
          ),
          postCode: values.postCode,
          country: values.country,
          city: values.city,
          state: values.state,
        },
      });
    },
  });


  // mutation to deactivate business
  const [
    deactivateBusinessMutation,
    { data: deactivateBusinessData, error: deactivateBusinessError },
  ] = useMutation(DEACTIVATE_BUSINESS, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.de_activated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [
    updateBusinessMutation,
    { data: updateBusinessMutationData, error: updateBusinessMutationError },
  ] = useMutation(UPDATE_BUSINESS, {
    context: { clientName: "businessClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [
    updateAddressMutation,
    { data: updateAddressMutationData, error: updateAddressMutationError },
  ] = useMutation(UPDATE_ADDRESS, {
    context: { clientName: "addressClient" },
  });

  const {
    data: addressDetails,
    loading: addressDetailsLoading,
    error: addressDetailsError,
    refetch,
    networkStatus,
  } = useQuery(ADDRESS_DETAILS, {
    variables: { addressId: businessDetails.addressId },
    context: { clientName: "addressClient" },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  useEffect(() => {
    if (deactivateBusinessData?.deactivateBusinessRequest?.success) {
    }
    if (updateAddressMutationData?.updateAddress?.success) {
    }
  }, [deactivateBusinessData, updateAddressMutationData]);

  useEffect(() => {
    if (updateBusinessMutationData?.updateBusiness?.success) {
      refetchBusinessDetails();
      refetch();
    }
  }, [updateBusinessMutationData]);

  useEffect(() => {
    if (addressDetails) {
      const parsedData = addressDetails.address;
      formik.setFieldValue(
        "businessAddress",
        parsedData.address1 + "," + parsedData.address2
      );
      formik.setFieldValue("postCode", parsedData.postCode);
      formik.setFieldValue("country", parsedData.country);
    }
  }, [addressDetails, networkStatus]);

  const deactivateBusiness = () => {
    deactivateBusinessMutation({
      variables: {
        businessId: businessDetails.businessId,
      },
    });
  };

  const locationKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE";

  function getCurrentLocation(addressComponents: any) {
    let currentLocation = "";
    const lanmarkComponrnt = addressComponents.find((component: any) =>
      component.types.includes("landmark")
    );
    if (lanmarkComponrnt) {
      currentLocation += lanmarkComponrnt.long_name + ", ";
    }
    // Look for the locality (city)
    const localityComponent = addressComponents.find(
      (component: any) =>
        // component.types.includes("political") ||
        component.types.includes("sublocality") ||
        component.types.includes("sublocality_level_2")
    );
    if (localityComponent) {
      currentLocation += localityComponent.long_name + ", ";
    }
    const cityComponent = addressComponents.find((component: any) =>
      component.types.includes("locality")
    );

    if (cityComponent) {
      currentLocation += cityComponent.long_name + ", ";
    }
    const stateComponent = addressComponents.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );
    if (stateComponent) {
      currentLocation += stateComponent.long_name + ", ";
    }
    // If neither city nor state is available, use country
    const countryComponent = addressComponents.find((component: any) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      currentLocation += countryComponent.long_name + ", ";
    }
    const postalCodeComponent = addressComponents.find((component: any) =>
      component.types.includes("postal_code")
    );
    if (postalCodeComponent) {
      currentLocation += postalCodeComponent.long_name;
    }
    return currentLocation;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getPinData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.postCode}&key=${locationKey}`
        );
        const data = await response.json();
        if (data) {
          if (data.status === "OK") {
            const { results } = data;
            if (results) {
              const { address_components, geometry } = results[0];
              const currentLocation = getCurrentLocation(address_components);
              formik.setFieldValue("address2", currentLocation);
              const { location } = geometry;
              const { lat, lng } = location;

              const extractCityAndState = (addressComponents: any) => {
                let city = "";
                let state = "";
                let country = "";
                addressComponents.forEach((component: any) => {
                  if (
                    component.types.includes("postal_town") ||
                    component.types.includes("locality")
                  ) {
                    city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    state = component.long_name;
                  } else if (component.types.includes("country")) {
                    country = component.long_name;
                  }
                });
                return { city, state, country };
              };
              const { city, state, country } =
                extractCityAndState(address_components);
              formik.setFieldValue("country", country);
              formik.setFieldValue("city", city);
              formik.setFieldValue("state", state);
            }
          }
        }
        // fetch(
        //   `https://api.postalpincode.in/pincode/${
        //     formik.values.postCode && formik.values.postCode
        //   }`
        // )
        //   .then((response) => response.json())
        //   .then((data) => {
        //     if (data) {
        //       const { PostOffice } = data[0];
        //       if (PostOffice) {
        //         const { State, Division } = PostOffice[0];
        //         formik.setFieldValue("state", State);
        //         formik.setFieldValue("city", Division);
        //       }
        //     }
        //   });
      } catch (error) {
        console.log(error);
      }
    };
    getPinData();
    return () => {
      controller.abort();
    };
  }, [formik.values.postCode]);

  const imageDrawerRef = useRef<any>();
  const handleImage = (value: string | null) => {
    formik.setFieldValue("brandLogo", value);
  };

  const handleColor = (value: string | null) => {
    formik.setFieldValue("brandColor", value);
  };

  const handleImageForFavicon = (value: string | null) => {
    formik.setFieldValue("favIcon", value);
  };

  const handleColorForFavIcon = (value: string | null) => {
    formik.setFieldValue("favColor", value);
  };

  return (
    <div style={{ backgroundColor: "#FBFBFB", paddingLeft: "20%" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              color: "#333",
              fontFamily: "Poppins",
              fontSize: "24px",
              fontStyle: "normal",
              fontWeight: 600,
              lineHeight: "normal",
              marginTop: "24px",
            }}
          >
            {t("business_account.business_account")}
          </Typography>
          <Button
            type="submit"
            sx={{
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              marginRight: "12px",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
                textTransform: "capitalize",
              }}
            >
              {t("buttons.save")}
            </Typography>
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            rowGap: "32px",
            marginTop: "44px",
          }}
        >
          <Box
            sx={{
              width: "80%",

              borderRadius: "4px",
              border: "1px solid #EEE",
              background: "#FFF",
              padding: "12px 20px 15px 20px",
              boxSizing: "border-box",
              borderLeft: "2px solid #AE9BE2",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "10px",
              }}
            >
              {t("business_account.about_my_business")}
            </Typography>
            <Typography
              sx={{
                color: "#707070",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 400,
                lineHeight: "normal",
                marginBottom: "24px",
              }}
            >
              {t("business_account.about_your_business")}
            </Typography>
            <TextField
              fullWidth
              sx={{ width: "80%", marginBottom: "24px" }}
              id="businessName"
              name="businessName"
              label={t("business_account.business_name")}
              variant="outlined"
              placeholder="Business Name"
              InputLabelProps={{ shrink: true }}
              value={formik.values.businessName}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.businessName &&
                Boolean(formik.errors.businessName)
              }
              helperText={
                formik.touched.businessName && formik.errors.businessName
              }
            />

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "80%",
              }}
            >
              <Box></Box>
              <Box></Box>

              <Box sx={{ width: "20%" }}>
                <Typography sx={{ textAlign: "center", marginBottom: "8px" }}>
                  Business Logo
                </Typography>
                <Box
                  sx={{
                    // marginLeft: "86px",
                    width: "100%",
                    height: "150px",
                    padding: "2px",
                  }}
                  className="create-product-img-con"
                >
                  <ImageDrawer
                    ref={imageDrawerRef}
                    handleImage={handleImage}
                    handleColor={handleColor}
                    colorCodeFromApi={formik.values.brandColor}
                    imageFromApi={formik.values.brandLogo}
                    heightValue={140}
                    imageType={"business_logo"}
                    isColorNotAvailable={true}
                  />
                </Box>
              </Box>
              <Box sx={{ width: "20%" }}>
                <Typography sx={{ textAlign: "center", marginBottom: "8px" }}>
                  Favourite Icon
                </Typography>
                <Box
                  sx={{
                    // marginLeft: "86px",
                    width: "100%",
                    height: "150px",
                    padding: "2px",
                  }}
                  className="create-product-img-con"
                >
                  <ImageDrawer
                    ref={imageDrawerRef}
                    handleImage={handleImageForFavicon}
                    handleColor={handleColorForFavIcon}
                    colorCodeFromApi={formik.values.favColor}
                    imageFromApi={formik.values.favIcon}
                    heightValue={120}
                    imageType={"favicon"}
                    isColorNotAvailable={true}
                  />
                </Box>
              </Box>

              <Box></Box>
            </Box>
          </Box>

          <Box
            sx={{
              width: "80%",
              borderRadius: "4px",
              border: "1px solid #EEE",
              background: "#FFF",
              padding: "12px 20px 20px 20px",
              boxSizing: "border-box",
              borderLeft: "2px solid #9BDAE2",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
                marginBottom: "24px",
              }}
            >
              {t("business_account.business_address")}
            </Typography>
            <TextField
              fullWidth
              sx={{ width: "90%", marginBottom: "24px" }}
              id="businessAddress"
              name="businessAddress"
              label={t("business_account.business_address")}
              variant="outlined"
              placeholder="Comma separated Business Address"
              InputLabelProps={{ shrink: true }}
              value={formik.values.businessAddress}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.businessAddress &&
                Boolean(formik.errors.businessAddress)
              }
              helperText={
                formik.touched.businessAddress && formik.errors.businessAddress
              }
            />
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
                width: "90%",
              }}
            >
              <TextField
                sx={{ width: "45%" }}
                id="postCode"
                name="postCode"
                label={t("business_account.post_code")}
                variant="outlined"
                placeholder="Post Code"
                InputLabelProps={{ shrink: true }}
                value={formik.values.postCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postCode && Boolean(formik.errors.postCode)
                }
                helperText={formik.touched.postCode && formik.errors.postCode}
              />
              <FormControl sx={{ width: "45%" }}>
                <InputLabel id="Country">
                  {" "}
                  {t("business_account.country")}
                </InputLabel>
                <Select
                  id="country"
                  name="country"
                  label={t("business_account.country")}
                  renderValue={(selected) => {
                    // show placeholder text when no option is selected
                    return selected !== ""
                      ? selected
                      : t("business_account.country");
                  }}
                  value={formik.values.country}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  error={
                    formik.touched.country && Boolean(formik.errors.country)
                  }
                >
                  {countries.map((each: any) => (
                    <MenuItem value={each.label}>
                      {each.label + ` (${each.code})`}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>
                  {formik.touched.country && formik.errors.country}
                </FormHelperText>
              </FormControl>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
                width: "90%",
              }}
            >
              <TextField
                sx={{ width: "45%" }}
                id="language"
                name="state"
                label={t("customer.state")}
                variant="outlined"
                placeholder={t("customer.state") || ""}
                InputLabelProps={{ shrink: true }}
                value={formik.values.state}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
              <TextField
                sx={{ width: "45%" }}
                id="employeeRange"
                name="city"
                label={t("customer.city") || ""}
                variant="outlined"
                placeholder={t("customer.city") || ""}
                InputLabelProps={{ shrink: true }}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={formik.touched.city && formik.errors.city}
              />
            </Box>
          </Box>
        </Box>
      </form>
      <Box
        sx={{
          width: "80%",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          padding: "12px 20px 20px 20px",
          boxSizing: "border-box",
          borderLeft: "2px solid #F5431C",
          marginTop: "24px",
          marginBottom: "24px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "10px",
          }}
        >
          {t("business_account.deactivate_your_business")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginBottom: "24px",
          }}
        >
          {t("business_account.deactivate_your_business_para")}
        </Typography>
        <Box
          sx={{
            height: "48px",
            padding: "8px 16px",
            borderRadius: "4px",
            background: "#EEE",
            width: "fit-content",
            cursor: "pointer",
          }}
          onClick={() => deactivateBusiness()}
        >
          <Typography
            sx={{
              color: "#F5431C",
              fontFamily: "Poppins",
              fontSize: "18px",
              fontStyle: "normal",
              fontWeight: 500,
              lineHeight: "normal",
            }}
          >
            {t("business_account.deactivate_your_business")}
          </Typography>
        </Box>
      </Box>
    </div>
  );
};

export default BusinessAccount;
