import { Box } from "@mui/material";
import React from "react";
import TaxReport from "../../components/reports/taxes";

const TaxReportPage = () => {
  return (
    <Box>
      <TaxReport />
    </Box>
  );
};
export default TaxReportPage;
