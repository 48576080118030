import {
  Box,
  Breadcrumbs,
  Button,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import React from "react";
import { t } from "i18next";

interface DeviceDetails {
  deviceName: null | string;
  storeId: string;
  stationName: null | string;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45%",
  minHeight: "30vh", // Adjusted from 40vh to 30vh
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 3,
};

interface State {
  open: boolean;
  formik: any;
  handleDeviceDetailsPopupClose: () => void;
  locationsList: any[];
  handleDoneButtonInDeviceDetailsPopup: () => void;
  headerText: string;
  stationName: boolean;
  deviceName: boolean;
}

const DeviceDetailsPopup = (props: State) => {
  const {
    open,
    handleDeviceDetailsPopupClose,
    stationName,
    deviceName,
    headerText,
    locationsList,
    handleDoneButtonInDeviceDetailsPopup,
    formik,
  } = props;

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const breadcrumbs = [
    <Typography
      className="device-set-up-first-pop-up-header-bredcrump2"
      key="2"
    >
      {t("devices.generate_device_code")}
    </Typography>,
    <Typography
      className="device-set-up-first-pop-up-header-bredcrump1"
      key="1"
    >
      {t("devices.device_details")}
    </Typography>,
  ];

  const renderContent = () => (
    <Box className="device-setup-device-details-popup-container">
      <Box className="device-setup-device-details-popup-headers-order">
        <IconButton
          onClick={handleDeviceDetailsPopupClose}
          className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
        >
          <CloseIcon />
        </IconButton>
        <Box sx={{ textAlign: "center" }}>
          <Typography className="device-set-up-first-pop-up-header">
            {t("devices.new")} {headerText} {t("devices.set_up")}
          </Typography>
          <Breadcrumbs
            sx={{
              "& ol": {
                justifyContent: "center",
              },
            }}
            separator={<NavigateNextIcon fontSize="small" />}
            aria-label="breadcrumb"
          >
            {breadcrumbs}
          </Breadcrumbs>
        </Box>
      </Box>
      <Divider sx={{ marginBottom: "20px", marginTop: "10px" }} />

      <form onSubmit={formik.handleSubmit}>
        <Box className="device-setup-popup-textfields-order">
          {deviceName && (
            <Box className="device-popup-textfield-width">
              <TextField
                value={formik.values.deviceName}
                onBlur={formik.handleBlur}
                name="deviceName"
                onChange={(e) => {
                  formik.handleChange(e);
                }}
                label={t("devices.enter_device_name")}
                sx={{ width: "100%", borderColor: "warning" }}
                color="warning"
                //@ts-ignore
                placeholder={t("devices.enter_device_name")}
              />
              {(formik.touched.deviceName && formik.errors.deviceName) ||
              formik.values.isError ? (
                <Typography className="create-a-user-formik-label">
                  {(formik.errors.deviceName && t("error.required")) ||
                    (formik.values.isError && t("error.already_existed"))}
                </Typography>
              ) : null}
            </Box>
          )}

          <FormControl
            className="device-popup-textfield-width"
            sx={{ marginTop: "15px" }}
          >
            <InputLabel
              className="create-a-use-role-label"
              id="assign-role-label"
            >
              {t("inventory.select_location")}
            </InputLabel>
            <Select
              color="warning"
              name="storeId"
              labelId="assign-role-label"
              label={<Typography>{t("inventory.select_location")}</Typography>}
              value={formik.values.storeId}
              onBlur={formik.handleBlur}
              onChange={(e) => {
                formik.handleChange(e);
              }}
            >
              {locationsList &&
                locationsList.map((each: any) => (
                  <MenuItem key={each.storeId} value={each.storeId}>
                    {each.storeName}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>

          {stationName && (
            <TextField
              value={formik.values.stationName}
              onBlur={formik.handleBlur}
              name="stationName"
              onChange={(e) => {
                formik.handleChange(e);
              }}
              label={t("devices.kds_station_name")}
              className="device-popup-textfield-width"
              sx={{
                borderColor: "warning",
                marginTop: "15px",
                marginBottom: "15px",
              }}
              color="warning"
              //@ts-ignore
              placeholder={t("devices.kds_station_name")}
            />
          )}
          <Button
            type="submit"
            className="device-setup-popup-device-details-done-button"
            variant="contained"
          >
            {t("devices.done")}
          </Button>
        </Box>
      </form>
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <Drawer
          anchor="bottom"
          open={open}
          onClose={handleDeviceDetailsPopupClose}
          sx={{
            "& .MuiDrawer-paper": {
              height: stationName ? "57%" : "40%", // Set drawer height to 50% of screen height
            },
          }}
        >
          {renderContent()}
        </Drawer>
      ) : (
        <Modal
          open={open}
          // onClose={handleSecondPopUpClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>{renderContent()}</Box>
        </Modal>
      )}
    </Box>
  );
};

export default DeviceDetailsPopup;
