import { Outlet, Link as RouterLink } from "react-router-dom";
import { Box } from "@mui/system";
import Link from "@mui/material/Link";
import Header from "./Header";
import SideBar from "./SideBar";
import { useEffect, useState } from "react";
import { Tooltip as ReactTooltip } from "react-tooltip";
// import AccordionContent from "./SideBar/sideBarContent";
import { Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import React from "react";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import DepartureBoardIcon from "@mui/icons-material/DepartureBoard";

import StarIcon from "@mui/icons-material/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FlashOnIcon from "@mui/icons-material/FlashOn";
import RestorePageIcon from "@mui/icons-material/RestorePage";
import StorefrontIcon from "@mui/icons-material/Storefront";
import CategoryIcon from "@mui/icons-material/Category";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import AcUnitIcon from "@mui/icons-material/AcUnit";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import CreateIcon from "@mui/icons-material/Create";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import { Dashboard } from "@mui/icons-material";
import SettingsIcon from "@mui/icons-material/Settings";
import CopyrightIcon from "@mui/icons-material/Copyright";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import PaidIcon from "@mui/icons-material/Paid";

import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";
import MenuBookOutlinedIcon from "@mui/icons-material/MenuBookOutlined";
import FastfoodOutlinedIcon from "@mui/icons-material/FastfoodOutlined";
import DeliveryDiningOutlinedIcon from "@mui/icons-material/DeliveryDiningOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import LocalOfferOutlinedIcon from "@mui/icons-material/LocalOfferOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import AddBusinessOutlinedIcon from "@mui/icons-material/AddBusinessOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import ReceiptOutlinedIcon from "@mui/icons-material/ReceiptOutlined";
// import { ThemeProvider } from "styled-components";

import {
  Tooltip as MuiTooltip,
  createTheme,
  ThemeProvider,
} from "@mui/material";
import AccordionContent from "./SideBar/sideBarContent";

const MainLayout = () => {
  const { t } = useTranslation();
  const [sideBarOpen, setSideBarOpen] = useState(false);
  const [, setToolTipId] = useState("");

  const [notificationOpen, setNotificationsOpen] = useState(false);
  const [AccordionContent, setAccordionContent] = useState<any[]>([
    {
      id: "1",
      status: "withoutPannel",
      icon: <Dashboard />,
      title: t("sidebar.dashboard"),
      url: "/",
    },
    {
      id: "panel2",
      icon: <MenuBookOutlinedIcon />,
      title: t("sidebar.menu"),

      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.menu_list"),
          url: "menu/menu-list",
          name: "menu",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.menu_setup"),
          url: "/menuSetup/menu-setup",
          name: "menuSetup",
        },
      ],
    },
    {
      id: "panel3",
      icon: <FastfoodOutlinedIcon />,
      title: t("sidebar.products"),
      items: [
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.products"),
          url: "/product/product-list",
          name: "product",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.categories"),
          url: "/category/category-listing",
          name: "category",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.options"),
          url: "/option/options-list",
          name: "option",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.bundles"),
          url: "bundle/bundle-listing",
          name: "bundle",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.bulk_products"),
          url: "/bulk/product",
          name: "bulk",
        },

        {
          icon: <StarBorderIcon />,
          text: t("sidebar.suggestive_sellings"),
          url: "/shopReviews",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.reporting_group"),
          url: "report/report-group-listing",
          name: "report",
        },
        {
          icon: <StarBorderIcon />,
          text: "Specials",
          url: "special/specials-list",
          name: "special",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.courses"),
          url: "course/courses-listing",
          name: "course",
        },
        {
          icon: <StarBorderIcon />,
          text: t("sidebar.gift_cards"),
          url: "/shopReviews",
        },
      ],
    },

    {
      id: "panel4",
      icon: <LocalOfferOutlinedIcon />,
      title: t("sidebar.discounts"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.self_made_discounts"),
          url: "/discount/discount-list",
          name: "discount",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.pre_made_discounts"),
          url: "discounts/preMade-list",
          name: "discounts",
        },
        {
          icon: <AccessTimeIcon />,
          text: t("sidebar.discounts_reason"),
          url: "/reasons/discount-reasons",
          name: "reasons",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: "Epos Discount",
          url: "/epos/eposdiscount",
          name: "epos",
        },
      ],
    },

    {
      id: "panel5",
      icon: <DevicesOutlinedIcon />,
      title: t("sidebar.devices"),

      items: [
        {
          icon: <CategoryIcon />,
          text: t("sidebar.devices_setup"),
          url: "/device/device-setup",
          name: "device",
        },
        {
          icon: <EqualizerIcon />,
          text: t("sidebar.devices_listing"),
          url: "devices/device-list",
          name: "devices",
        },
        {
          icon: <EqualizerIcon />,
          text: t("online_ordering.online_ordering"),
          url: "/online/doamins-listing",
          name: "online",
        },
      ],
    },
    {
      id: "panel6",
      icon: <DeliveryDiningOutlinedIcon />,
      title: t("sidebar.deliveries"),
      items: [
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.delivery_list"),
          url: "/delivery/delivery-list",
          name: "delivery",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.driver_list"),
          url: "drivers/driver-list",
          name: "drivers",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.petty_cash"),
          url: "/petty/petty-cash",
          name: "petty",
        },
        {
          icon: <StorefrontIcon />,
          text: t("sidebar.driver_commission"),
          url: "/driver/driver-commission",
          name: "driver",
        },
      ],
    },

    {
      id: "panel7",
      icon: <AddShoppingCartOutlinedIcon />,
      title: t("sidebar.inventory"),
      items: [
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_listing"),
          url: "/inventory/inventory-list",
          name: "inventory",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_history"),
          url: "/history/inventory-history",
          name: "history",
        },
        {
          icon: <CategoryIcon />,
          text: t("sidebar.inventory_reasons"),
          url: "reasons/inventory-reasons",
          name: "reasons",
        },
      ],
    },

    {
      id: "panel8",
      icon: <PeopleAltOutlinedIcon />,
      title: t("sidebar.customers"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.customers"),
          url: "customer/customer-list",
          name: "customer",
        },
      ],
    },

    {
      id: "panel9",
      icon: <ReceiptOutlinedIcon />,
      title: t("sidebar.orders"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.orders"),
          url: "/orders/orders-list",
          name: "orders",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_status"),
          url: "/status/order-status",
          name: "status",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_numbering"),
          url: "/numbering/order-numbering",
          name: "numbering",
        },
      ],
    },

    {
      id: "panel10",
      icon: <AssignmentIndOutlinedIcon />,
      title: t("sidebar.users"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_role"),
          url: "roles/user-roles",
          name: "roles",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_list"),
          url: "users/users-list",
          name: "users",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_access"),
          url: "access/user-access",
          name: "access",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_requests"),
          url: "requests/user-requests",
          name: "requests",
        },
      ],
    },

    {
      id: "panel11",
      icon: <AssessmentOutlinedIcon />,
      title: t("sidebar.reports"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.order_reports"),
          url: "/order/reports-orders",
          name: "order",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.sales_reports"),
          url: "/sale/reports-sales",
          name: "sale",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.discounts_reports"),
          url: "/discountReport/reports-discount",
          name: "discountReport",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.customer_reports"),
          url: "/customers/reports-customers",
          name: "customers",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.product_reports"),
          url: "/products/reports/products",
          name: "products",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.category_reports"),
          url: "/categories/reports-categories",
          name: "categories",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.payment_reports"),
          url: "/payments/reports-payments",
          name: "payments",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.store_reports"),
          url: "/stores/reports-stores",
          name: "stores",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.delivery_reports"),
          url: "/delivery/reports-delivery",
          name: "delivery",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.tax_reports"),
          url: "/tax/reports-tax",
          name: "tax",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.receipts_reports"),
          url: "/receipts/reports-receipts",
          name: "receipts",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.shifts_reports"),
          url: "",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.tips_reports"),
          url: "/employee/reports-employee",
          name: "employee",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.user_log_activity"),
          url: "/activityLog/reports/activityLog",
          name: "activityLog",
        },
      ],
    },

    {
      id: "panel12",
      icon: <AdminPanelSettingsOutlinedIcon />,
      title: t("sidebar.account_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.signin_security"),
          url: "/safety/signIn&&Security",
          name: "safety",
        },
        {
          icon: <DepartureBoardIcon />,
          text: t("sidebar.prefereneces"),
          url: "/preferences",
          name: "preferences",
        },
        // { icon: <FlashOnIcon />, text: "Pickup orders" },
        // { icon: <AccessTimeIcon />, text: "Scheduled orders" },
        // { icon: <StarIcon />, text: "Reviews" },
        // { icon: <RestorePageIcon />, text: "Refunds" },
      ],
    },
    {
      id: "panel13",
      icon: <AddBusinessOutlinedIcon />,
      title: t("sidebar.business_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.about_my_business"),
          url: "/businessAccount",
          name: "businessAccount",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.locations_list"),
          url: "/location/locations-list",
          name: "location",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.sales_tax"),
          url: "sales/tax",
          name: "sales",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.service_charge"),
          url: "/service/charge",
          name: "service",
        },

        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.receipts"),
          url: "/reciept/reciept-list",
          name: "reciept",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.rounding"),
          url: "/rounding",
          name: "rounding",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.refunds_cancel"),
          url: "/refund/cancel",
          name: "refund",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.advance_bookings"),
          url: "/booking/advance-booking",
          name: "booking",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.kitchen_settings"),
          url: "/kitchen/kitchen-settings",
          name: "kitchen",
        },
      ],
    },
    {
      id: "panel14",
      icon: <SettingsOutlinedIcon />,
      title: t("sidebar.general_settings"),
      items: [
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.image_library"),
          url: "image/image-library",
          name: "image",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.api_keys"),
          url: "apiKeys/api-keys",
          name: "apiKeys",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.feature_settings"),
          url: "/featureSettings",
          name: "featureSettings",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.manage_tips"),
          url: "manage/manage-tips",
          name: "manage",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.managing_orders"),
          url: "managingOrder/manage-orders",
          name: "managingOrder",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.open_items"),
          url: "/open/items",
          name: "open",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.loyality"),
          url: "/loyality",
          name: "loyality",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.reviews_ratings"),
          url: "/reviews/ratings",
          name: "reviews",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.caller_id"),
          url: "/callerId",
          name: "callerId",
        },
        {
          icon: <AddShoppingCartIcon />,
          text: t("sidebar.log_time_entries"),
          url: "log/log-time-entries",
          name: "log",
        },
      ],
    },
  ]);

  useEffect(() => {
    const updatedAccordionContent = [
      {
        id: "1",
        status: "withoutPannel",
        icon: <Dashboard />,
        title: t("sidebar.dashboard"),
        url: "/",
      },
      {
        id: "panel2",
        icon: <MenuBookOutlinedIcon />,
        title: t("sidebar.menu"),

        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.menu_list"),
            url: "menu/menu-list",
            name: "menu",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.menu_setup"),
            url: "/menuSetup/menu-setup",
            name: "menuSetup",
          },
        ],
      },
      {
        id: "panel3",
        icon: <FastfoodOutlinedIcon />,
        title: t("sidebar.products"),
        items: [
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.products"),
            url: "/product/product-list",
            name: "product",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.categories"),
            url: "/category/category-listing",
            name: "category",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.options"),
            url: "/option/options-list",
            name: "option",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.bundles"),
            url: "bundle/bundle-listing",
            name: "bundle",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.bulk_products"),
            url: "/bulk/product",
            name: "bulk",
          },

          {
            icon: <StarBorderIcon />,
            text: t("sidebar.suggestive_sellings"),
            url: "/shopReviews",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.reporting_group"),
            url: "report/report-group-listing",
            name: "report",
          },
          {
            icon: <StarBorderIcon />,
            text: "Specials",
            url: "special/specials-list",
            name: "special",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.courses"),
            url: "course/courses-listing",
            name: "course",
          },
          {
            icon: <StarBorderIcon />,
            text: t("sidebar.gift_cards"),
            url: "/shopReviews",
          },
        ],
      },

      {
        id: "panel4",
        icon: <LocalOfferOutlinedIcon />,
        title: t("sidebar.discounts"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.self_made_discounts"),
            url: "/discount/discount-list",
            name: "discount",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.pre_made_discounts"),
            url: "discounts/preMade-list",
            name: "discounts",
          },
          {
            icon: <AccessTimeIcon />,
            text: t("sidebar.discounts_reason"),
            url: "/reasons/discount-reasons",
            name: "reasons",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: "Epos Discount",
            url: "/epos/eposdiscount",
            name: "epos",
          },
        ],
      },

      {
        id: "panel5",
        icon: <DevicesOutlinedIcon />,
        title: t("sidebar.devices"),

        items: [
          {
            icon: <CategoryIcon />,
            text: t("sidebar.devices_setup"),
            url: "/device/device-setup",
            name: "device",
          },
          {
            icon: <EqualizerIcon />,
            text: t("sidebar.devices_listing"),
            url: "devices/device-list",
            name: "devices",
          },
          {
            icon: <EqualizerIcon />,
            text: t("online_ordering.online_ordering"),
            url: "/online/doamins-listing",
            name: "online",
          },
        ],
      },
      {
        id: "panel6",
        icon: <DeliveryDiningOutlinedIcon />,
        title: t("sidebar.deliveries"),
        items: [
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.delivery_list"),
            url: "/delivery/delivery-list",
            name: "delivery",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.driver_list"),
            url: "drivers/driver-list",
            name: "drivers",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.petty_cash"),
            url: "/petty/petty-cash",
            name: "petty",
          },
          {
            icon: <StorefrontIcon />,
            text: t("sidebar.driver_commission"),
            url: "/driver/driver-commission",
            name: "driver",
          },
        ],
      },

      {
        id: "panel7",
        icon: <AddShoppingCartOutlinedIcon />,
        title: t("sidebar.inventory"),
        items: [
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_listing"),
            url: "/inventory/inventory-list",
            name: "inventory",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_history"),
            url: "/history/inventory-history",
            name: "history",
          },
          {
            icon: <CategoryIcon />,
            text: t("sidebar.inventory_reasons"),
            url: "reasons/inventory-reasons",
            name: "reasons",
          },
        ],
      },

      {
        id: "panel8",
        icon: <PeopleAltOutlinedIcon />,
        title: t("sidebar.customers"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.customers"),
            url: "customer/customer-list",
            name: "customer",
          },
        ],
      },

      {
        id: "panel9",
        icon: <ReceiptOutlinedIcon />,
        title: t("sidebar.orders"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.orders"),
            url: "/orders/orders-list",
            name: "orders",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_status"),
            url: "/status/order-status",
            name: "status",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_numbering"),
            url: "/numbering/order-numbering",
            name: "numbering",
          },
        ],
      },

      {
        id: "panel10",
        icon: <AssignmentIndOutlinedIcon />,
        title: t("sidebar.users"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_role"),
            url: "roles/user-roles",
            name: "roles",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_list"),
            url: "users/users-list",
            name: "users",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_access"),
            url: "access/user-access",
            name: "access",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_requests"),
            url: "requests/user-requests",
            name: "requests",
          },
        ],
      },

      {
        id: "panel11",
        icon: <AssessmentOutlinedIcon />,
        title: t("sidebar.reports"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.order_reports"),
            url: "/order/reports-orders",
            name: "order",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.sales_reports"),
            url: "/sale/reports-sales",
            name: "sale",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.discounts_reports"),
            url: "/discountReport/reports-discount",
            name: "discountReport",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.customer_reports"),
            url: "/customers/reports-customers",
            name: "customers",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.product_reports"),
            url: "/products/reports/products",
            name: "products",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.category_reports"),
            url: "/categories/reports-categories",
            name: "categories",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.payment_reports"),
            url: "/payments/reports-payments",
            name: "payments",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.store_reports"),
            url: "/stores/reports-stores",
            name: "stores",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.delivery_reports"),
            url: "/delivery/reports-delivery",
            name: "delivery",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.tax_reports"),
            url: "/tax/reports-tax",
            name: "tax",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.receipts_reports"),
            url: "/receipts/reports-receipts",
            name: "receipts",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.shifts_reports"),
            url: "",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.tips_reports"),
            url: "/employee/reports-employee",
            name: "employee",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.user_log_activity"),
            url: "/activityLog/reports/activityLog",
            name: "activityLog",
          },
        ],
      },

      {
        id: "panel12",
        icon: <AdminPanelSettingsOutlinedIcon />,
        title: t("sidebar.account_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.signin_security"),
            url: "/safety/signIn&&Security",
            name: "safety",
          },
          {
            icon: <DepartureBoardIcon />,
            text: t("sidebar.prefereneces"),
            url: "/preferences",
            name: "preferences",
          },
          // { icon: <FlashOnIcon />, text: "Pickup orders" },
          // { icon: <AccessTimeIcon />, text: "Scheduled orders" },
          // { icon: <StarIcon />, text: "Reviews" },
          // { icon: <RestorePageIcon />, text: "Refunds" },
        ],
      },
      {
        id: "panel13",
        icon: <AddBusinessOutlinedIcon />,
        title: t("sidebar.business_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.about_my_business"),
            url: "/businessAccount",
            name: "businessAccount",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.locations_list"),
            url: "/location/locations-list",
            name: "location",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.sales_tax"),
            url: "sales/tax",
            name: "sales",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.service_charge"),
            url: "/service/charge",
            name: "service",
          },

          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.receipts"),
            url: "/reciept/reciept-list",
            name: "reciept",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.rounding"),
            url: "/rounding",
            name: "rounding",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.refunds_cancel"),
            url: "/refund/cancel",
            name: "refund",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.advance_bookings"),
            url: "/booking/advance-booking",
            name: "booking",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.kitchen_settings"),
            url: "/kitchen/kitchen-settings",
            name: "kitchen",
          },
        ],
      },
      {
        id: "panel14",
        icon: <SettingsOutlinedIcon />,
        title: t("sidebar.general_settings"),
        items: [
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.image_library"),
            url: "image/image-library",
            name: "image",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.api_keys"),
            url: "apiKeys/api-keys",
            name: "apiKeys",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.feature_settings"),
            url: "/featureSettings",
            name: "featureSettings",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.manage_tips"),
            url: "manage/manage-tips",
            name: "manage",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.managing_orders"),
            url: "managingOrder/manage-orders",
            name: "managingOrder",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.open_items"),
            url: "/open/items",
            name: "open",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.loyality"),
            url: "/loyality",
            name: "loyality",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.reviews_ratings"),
            url: "/reviews/ratings",
            name: "reviews",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.caller_id"),
            url: "/callerId",
            name: "callerId",
          },
          {
            icon: <AddShoppingCartIcon />,
            text: t("sidebar.log_time_entries"),
            url: "log/log-time-entries",
            name: "log",
          },
        ],
      },
    ];
    setAccordionContent(updatedAccordionContent);
  }, [t]);

  const muiTooltipTheme = createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          tooltip: {
            fontSize: "16px",
            color: "white",
            backgroundColor: "#000000BF",
            padding: "0px 16px",
            height: "40px",
            display: "flex",
            justifyContent: "center",
            borderRadius: "10px",
            alignItems: "center",
            ".css-kudwh-MuiTooltip-arrow::before": {
              color: "#000000BF",
            },
          },
        },
      },
    },
  });

  return (
    <div className="sideBar">
      <SideBar
        setSideBar={setSideBarOpen}
        isOpenSideBar={sideBarOpen}
        setToolTipId={setToolTipId}
        setNotificationsOpen={setNotificationsOpen}
        notificationOpen={notificationOpen}
      />
      <Box
        // sx={{
        //   width: ` ${
        //     sideBarOpen ? "calc(100% - 250px)" : "calc(100% - 94px)"
        //   } `,
        //   ml: "auto",
        //   position: "relative",
        //   zIndex: `${notificationOpen ? -1 : 9}`,
        // }}
        sx={{
          width: {
            xs: "100%", // Set width to 100% on extra-small screens
            sm: sideBarOpen ? "calc(100% - 250px)" : "calc(100% - 94px)", // Conditionally set width for small screens and above
          },
          ml: "auto",
          position: "relative",
          zIndex: notificationOpen ? -1 : 9,
        }}
      >
        <Header sideBarOpen={sideBarOpen} setSideBarOpen={setSideBarOpen} />
        <Outlet />
      </Box>
      {!sideBarOpen ? (
        <>
          {AccordionContent.map((item, index) => (
            <ReactTooltip
              key={index}
              className={`sideBarTooltip opacity1 ${
                item.status === "withoutPannel" ? "example-diff-arrow" : ""
              } `}
              classNameArrow="example-arrow"
              style={{
                zIndex: 99999,
                minWidth:
                  item.status === "withoutPannel" ? "fit-content" : "200px",
                boxShadow:
                  item.status === "withoutPannel"
                    ? "none"
                    : "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                background:
                  item.status === "withoutPannel" ? "transparent" : "white",
                overflow: "inherit",
              }}
              id={`${item.id}`}
              place="right"
              clickable
              opacity={1}
            >
              {item.status === "withoutPannel" ? (
                <Link
                  component={RouterLink}
                  to={`${item.url}`}
                  sx={{
                    cursor: "pointer",
                    color: "#455560",
                    textDecoration: "none",
                  }}
                >
                  <Typography
                    height={"40px"}
                    display={"flex"}
                    alignItems={"center"}
                    px={"16px"}
                    sx={{
                      backgroundColor: "#000000BF",
                      color: "#FFFFFF",
                      borderRadius: "10px",
                    }}
                  >
                    {item.title}
                  </Typography>
                </Link>
              ) : (
                <>
                  {item?.items?.map((subItem: any, index: any) => (
                    <ThemeProvider key={index} theme={muiTooltipTheme}>
                      {/* <MuiTooltip title={subItem.text} placement="right" arrow> */}
                      <Link
                        component={RouterLink}
                        to={`${subItem.url}`}
                        sx={{
                          cursor: "pointer",
                          color: "#455560",
                          textDecoration: "none",
                        }}
                      >
                        <Typography
                          height={"40px"}
                          display={"flex"}
                          alignItems={"center"}
                          paddingLeft={"16px"}
                          sx={[
                            {
                              "&:hover": {
                                backgroundColor: "#FFF9F3",
                                color: "#F38B08",
                              },
                            },
                          ]}
                        >
                          {subItem.text}
                        </Typography>
                      </Link>
                      {/* </MuiTooltip> */}
                    </ThemeProvider>
                  ))}
                </>
              )}
            </ReactTooltip>
          ))}
        </>
      ) : null}
    </div>
  );
};
export default MainLayout;
