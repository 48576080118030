import { gql } from "@apollo/client";

export const UPDATE_RECURRING_STOCK_TO_PRODUCT = gql`
  mutation EditRecurringStock(
    $businessId: Int!
    $inventoryId: Int
    $locationId: Int
    $isRecurring: Boolean
    $recurringQuantity: Int
  ) {
    editRecurringStock(
      businessId: $businessId
      inventoryId: $inventoryId

      locationId: $locationId
      isRecurring: $isRecurring
      recurringQuantity: $recurringQuantity
    ) {
      message
      inventory {
        inventoryId
        isRecurring
        recurringQuantity
        days
      }
      success
    }
  }
`;

export const UPDATE_STOCK_ADJUSTMENT_REASONS = gql`
  mutation AddInventoryReasons(
    $locationId: Int
    $businessId: Int!
    $reasons: JSONString
  ) {
    addInventoryReasons(
      locationId: $locationId
      businessId: $businessId
      reasons: $reasons
    ) {
      success
      reasons {
        reasonId
        locationId
        reasons
      }
      message
    }
  }
`;

export const UPDATE_BULK_STOCK_ADJUSMENTS = gql`
  mutation AddBulkInventory(
    $businessId: Int!
    $locationId: Int!
    $inventory: JSONString
  ) {
    addBulkInventory(
      businessId: $businessId
      locationId: $locationId
      inventory: $inventory
    ) {
      message
      inventory {
        inventoryId
        isRecurring
        recurringQuantity
        days
      }
      success
    }
  }
`;

export const UPDATE_SINGLE_INVENTORY = gql`
  mutation EditInventory(
    $businessId: Int
    $locationId: Int
    $inventoryId: Int
    $stockQuantity: Int
    $reason: String
    $isRecurring: Boolean
    $recurringQuantity: Int
  ) {
    editInventory(
      businessId: $businessId
      locationId: $locationId
      inventoryId: $inventoryId

      stockQuantity: $stockQuantity
      reason: $reason
      isRecurring: $isRecurring
      recurringQuantity: $recurringQuantity
    ) {
      message
      inventory {
        inventoryId
      }
      success
    }
  }
`;

export const UPDATE_RECURRING_SETTINGS = gql`
  mutation RecurringSettings(
    $locationId: Int
    $businessId: Int!
    $recurringDays: JSONString
  ) {
    recurringSettings(
      locationId: $locationId
      businessId: $businessId
      recurringDays: $recurringDays
    ) {
      success
      recurringSettings {
        businessId
        settingId
        recurringDays
      }
      message
    }
  }
`;
