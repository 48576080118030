import React, { useEffect, useState } from "react";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Box,
  InputAdornment,
} from "@mui/material";
import { Paper, Typography, TextField } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./createDiscount.css";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  FormGroup,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Switch } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import WeeklyStatus from "../locations/weekklyStatus";
import { useFormik } from "formik";
import * as Yup from "yup";
import ImageDrawer from "../menu/image/imageDrawer";
import CreateDiscountExactQty from "./createDiscountExactQty";
import {
  CREATE_DISCOUNT,
  CUPON_DUPLICATE,
} from "../../graphicalQl/mutation/discountMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GETTING_LOCATIONS_LIST } from "../../graphicalQl/usequery/locationQuery";

import { DISCOUNT_REASONS } from "../../graphicalQl/usequery/discountQuery";
import { DISCOUNT_EDIT_DATA } from "../../graphicalQl/usequery/discountQuery";
import { UPDATE_DISCOUNT } from "../../graphicalQl/mutation/discountMutation";
import { toast } from "react-toastify";
import { PRODUCTS_LIST_IN_DISCOUNT } from "../../graphicalQl/usequery/productsListQuery";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import ItemErrorModal from "./itemsError";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ErrorModal from "../errorHandling/errorModal";
import { convertTimeToUTC } from "../../utils/utcformat";

interface CheckboxList {
  name: string;
  isChecked: boolean;
  value?: string;
}

interface SelectedDateTimesState {
  [type: string]: Record<number, string>;
}
interface Tooltips {
  title: string;
  content: string;
}

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}
interface Row {
  id: string;
  productCount: number;
  slugName: string;
  menuItems?: any;
}

interface ProductItem {
  productId?: number[];
  variantIds?: number[];
}

interface IdsList {
  categoryIds: { productIds: number[]; variantIds: number[] };
  menuIds: { productIds: number[]; variantIds: number[] };
  bundleIds: { productIds: number[]; variantIds: number[] };
  productIds: { productIds: number[]; variantIds: number[] };
}

const validationSchemaPart1 = Yup.object({
  discountName: Yup.string().required("Discount name required"),
  location: Yup.string().required("Location required"),
  promotion: Yup.string().required("Promotion required"),
  // amount: Yup.number().required("Amount required"),
  customCuponCode: Yup.string().when("cuponRadio", {
    is: (value: string) => value === "COUPON_CODE",
    then: (schema) => schema.required("Cupon code required"),
  }),
  itemQuantity: Yup.number().when("discount", {
    is: (value: string) =>
      value === "minQty" || value === "exactQty" || value === "buy1Get1",
    then: (schema) =>
      schema
        .min(1, "Item Quantity must be greater than 0")
        .required("Item Quantity required"),
  }),
  eligibleItemsQty: Yup.number().when("discount", {
    is: (value: string) => value === "buy1Get1",
    then: (schema) =>
      schema
        .min(1, "Item Quantity must be greater than 0")
        .required("Item Quantity required"),
  }),
  amount: Yup.number().when("discount", {
    is: (value: string) => value !== "freeDiscount",
    then: (schema) => schema.required("Amount required"),
  }),
});
const CreateDiscountBuy1Get: React.FC = () => {
  const { id } = useParams();
  const { t } = useTranslation();

  const [store, setStore] = useState<any[]>([
    {
      selected: false,
      day: "Monday",
      text: t("devices.monday"),
      sets: [{ openTime: "", closingTime: "" }],
    },

    {
      selected: false,
      day: "Tuesday",
      text: t("devices.tuesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Wednesday",
      text: t("devices.wednesday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Thursday",
      text: t("devices.thursday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Friday",

      text: t("devices.friday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Saturday",
      text: t("devices.saturday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
    {
      selected: false,
      day: "Sunday",
      text: t("devices.sunday"),
      sets: [{ openTime: "", closingTime: "" }],
    },
  ]);

  useEffect(() => {
    setStore([
      {
        selected: false,
        day: "Monday",
        text: t("devices.monday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Tuesday",
        text: t("devices.tuesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Wednesday",
        text: t("devices.wednesday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Thursday",
        text: t("devices.thursday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Friday",

        text: t("devices.friday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Saturday",
        text: t("devices.saturday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
      {
        selected: false,
        day: "Sunday",
        text: t("devices.sunday"),
        sets: [{ openTime: "", closingTime: "" }],
      },
    ]);
  }, [t]);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const idsListData = {
    categoryIds: { productIds: [], variantIds: [] },
    menuIds: { productIds: [], variantIds: [] },
    bundleIds: { productIds: [], variantIds: [] },
    productIds: { productIds: [], variantIds: [] },
  };
  const [idsList, setIdsList] = useState<IdsList>(idsListData);
  const idsListIndiscountData = {
    categoryIds: { productIds: [], variantIds: [] },
    menuIds: { productIds: [], variantIds: [] },
    bundleIds: { productIds: [], variantIds: [] },
    productIds: { productIds: [], variantIds: [] },
  };
  const [idsListInDiscount, setIdsListInDiscount] = useState<IdsList>(
    idsListIndiscountData
  );

  const itemdataList = {
    category: [],
    product: [],
    bundles: [],
    menu: [],
    variantsIds: [],
  };
  const [itemData, setItemsData] = useState<{ [key: string]: string[] }>(
    itemdataList
  );
  const eligibleItemList = {
    category: [],
    product: [],
    bundles: [],
    menu: [],
    variantsIds: [],
  };
  const [eligibleItemData, setEligibleItemData] = useState<{
    [key: string]: string[];
  }>(eligibleItemList);

  const convertedData = {
    menu_ids: itemData.menu && itemData.menu.map((item: any) => +item),
    bundle_ids: itemData.bundles && itemData.bundles.map((item: any) => +item),
    product_ids: itemData.product && itemData.product.map((item: any) => +item),
    variant_ids:
      itemData.variantsIds && itemData.variantsIds.map((item: any) => +item),
    category_ids:
      itemData.category && itemData.category.map((item: any) => +item),
  };

  const { locationId } = useSelector((state: any) => state.headerLocation);
  const { data: productFilterdata, refetch } = useQuery(
    PRODUCTS_LIST_IN_DISCOUNT,
    {
      // { variables: { businessId: +businessId, categoryIds: JSON.stringify(selectCategoryIds), locationId: locationId }

      context: { clientName: "categoryClient" },
    }
  );
  const [categoryProductData, setCategoryProductData] = useState<any>({
    productId: [],
    variantIds: [],
  });
  useEffect(() => {
    if (productFilterdata && productFilterdata.productList) {
      const list: ProductItem[] = productFilterdata.productList.edges.map(
        (item: any) => ({
          productId: item.node.variants ? undefined : +item.node.productId,
          variantIds: item.node.variants
            ? item.node.variants.map((each: any) => +each.variantId)
            : undefined,
        })
      );

      const productIds = list
        .map((item) => item.productId)
        .filter((productId) => productId !== undefined);
      const variantIds = list
        .flatMap((item) => (item.variantIds ? item.variantIds : []))
        .filter((variantId) => variantId !== undefined);

      setCategoryProductData([{ productId: productIds, variantIds }]);
    }
  }, [productFilterdata]);

  useEffect(() => {
    if (itemData && itemData.category && itemData.category.length > 0) {
      refetch({
        businessId: +businessId,
        categoryIds: JSON.stringify(
          itemData.category && itemData.category.map((each) => +each)
        ),
        locationId: locationId && +locationId,
      });
    } else {
      setIdsList((prevState: IdsList) => ({
        ...prevState,
        categoryIds: {
          productIds: [],
          variantIds: [],
        },
      }));
    }
  }, [itemData.category]);

  useEffect(() => {
    setIdsList((prevState: IdsList) => ({
      ...prevState,
      categoryIds: {
        productIds: [
          ...(categoryProductData && categoryProductData[0]?.productId
            ? categoryProductData[0].productId
            : []),
        ],
        variantIds: [
          ...(categoryProductData && categoryProductData[0]?.variantIds
            ? categoryProductData[0].variantIds
            : []),
        ],
      },
    }));
  }, [categoryProductData]);

  const { data: productFilterdata2, refetch: refetch2 } = useQuery(
    PRODUCTS_LIST_IN_DISCOUNT,
    {
      // { variables: { businessId: +businessId, categoryIds: JSON.stringify(selectCategoryIds), locationId: locationId }

      context: { clientName: "categoryClient" },
    }
  );
  const [categoryProductData2, setCategoryProductData2] = useState<any>({
    productId: [],
    variantIds: [],
  });
  useEffect(() => {
    if (productFilterdata2 && productFilterdata2.productList) {
      const list: ProductItem[] = productFilterdata2.productList.edges.map(
        (item: any) => ({
          productId: item.node.variants ? undefined : +item.node.productId,
          variantIds: item.node.variants
            ? item.node.variants.map((each: any) => +each.variantId)
            : undefined,
        })
      );

      const productIds = list
        .map((item) => item.productId)
        .filter((productId) => productId !== undefined);
      const variantIds = list
        .flatMap((item) => (item.variantIds ? item.variantIds : []))
        .filter((variantId) => variantId !== undefined);

      setCategoryProductData2([{ productId: productIds, variantIds }]);
    }
  }, [productFilterdata2]);

  useEffect(() => {
    if (
      eligibleItemData &&
      eligibleItemData.category &&
      eligibleItemData.category.length > 0
    ) {
      refetch2({
        businessId: +businessId,
        categoryIds: JSON.stringify(
          eligibleItemData.category &&
            eligibleItemData.category.map((each) => +each)
        ),
        locationId: locationId && +locationId,
      });
    } else {
      setIdsListInDiscount((prevState: IdsList) => ({
        ...prevState,
        categoryIds: {
          productIds: [
            ...(categoryProductData2 && categoryProductData2[0]?.productId
              ? categoryProductData2[0].productId
              : []),
          ],
          variantIds: [
            ...(categoryProductData2 && categoryProductData2[0]?.variantIds
              ? categoryProductData2[0].variantIds
              : []),
          ],
        },
      }));
    }
  }, [eligibleItemData.category]);

  useEffect(() => {
    setIdsListInDiscount((prevState: IdsList) => ({
      ...prevState,
      categoryIds: {
        productIds: [
          ...(categoryProductData2 && categoryProductData2[0]?.productId
            ? categoryProductData2[0].productId
            : []),
        ],
        variantIds: [
          ...(categoryProductData2 && categoryProductData2[0]?.variantIds
            ? categoryProductData2[0].variantIds
            : []),
        ],
      },
    }));
  }, [categoryProductData2]);

  const [menuList, setMenuList] = React.useState<Row[]>([]);

  const { data } = useQuery(MENU_LIST, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: locationId },
  });
  React.useEffect(() => {
    if (data && data.menuList) {
      const list = data.menuList.edges.map((each: any) => ({
        id: each.node.menuId,
        slugName: each.node.name,
        productCount: each.node.productCount,
        menuItems: each.node.menuItems && JSON.parse(each.node.menuItems),
      }));
      setMenuList(list);
    }
  }, [data]);

  useEffect(() => {
    if (itemData && itemData.menu) {
      const menuIds = itemData.menu.map((each) => +each);

      const menuFilterList = menuList.filter((each) =>
        menuIds.includes(+each.id)
      );

      const menuProductIds = menuFilterList.flatMap(
        (each: any) => each.menuItems.courses
      );
      const menuProductIdsList = menuProductIds
        .map((each: any) =>
          each.variants.length > 0 ? null : +each.product_id
        )
        .filter((id: any): id is number => id !== null);

      const menuVariantIdsList = menuProductIds
        .map(
          (each: any) =>
            each.variants &&
            each.variants.map((variant: any) => +variant.variant_id)
        )
        .flat();

      setIdsList((prevState: IdsList) => ({
        ...prevState,
        menuIds: {
          productIds: menuProductIdsList,
          variantIds: menuVariantIdsList,
        },
      }));
    }
  }, [itemData.menu, menuList]);

  useEffect(() => {
    if (eligibleItemData && eligibleItemData.menu) {
      const menuIds = eligibleItemData.menu.map((each) => +each);

      const menuFilterList = menuList.filter((each) =>
        menuIds.includes(+each.id)
      );

      const menuProductIds = menuFilterList.flatMap(
        (each: any) => each.menuItems.courses
      );
      const menuProductIdsList = menuProductIds
        .map((each: any) =>
          each.variants.length > 0 ? null : +each.product_id
        )
        .filter((id: any): id is number => id !== null);

      const menuVariantIdsList = menuProductIds
        .map(
          (each: any) =>
            each.variants &&
            each.variants.map((variant: any) => +variant.variant_id)
        )
        .flat();

      setIdsListInDiscount((prevState: IdsList) => ({
        ...prevState,
        menuIds: {
          productIds: menuProductIdsList,
          variantIds: menuVariantIdsList,
        },
      }));
    }
  }, [eligibleItemData.menu, menuList]);

  useEffect(() => {
    if (itemData && itemData.bundles) {
      const bundleIds = itemData.bundles.map((each) => +each);
      setIdsList((prevState: IdsList) => ({
        ...prevState,
        bundleIds: {
          productIds: bundleIds,
          variantIds: [],
        },
      }));
    }
  }, [itemData.bundles]);

  useEffect(() => {
    if (eligibleItemData && eligibleItemData.bundles) {
      const bundleIds = eligibleItemData.bundles.map((each) => +each);
      setIdsListInDiscount((prevState: IdsList) => ({
        ...prevState,
        bundleIds: {
          productIds: bundleIds,
          variantIds: [],
        },
      }));
    }
  }, [eligibleItemData.bundles]);

  useEffect(() => {
    if (itemData && itemData.product) {
      const productIds = itemData.product.map((each) => +each);
      const variantIds =
        itemData.variantsIds && itemData.variantsIds.map((each) => +each);
      setIdsList((prevState: IdsList) => ({
        ...prevState,
        productIds: {
          productIds: productIds,
          variantIds: variantIds,
        },
      }));
    }
  }, [itemData.product, itemData.variantsIds]);

  useEffect(() => {
    if (eligibleItemData && eligibleItemData.product) {
      const productIds = eligibleItemData.product.map((each) => +each);
      const variantIds =
        eligibleItemData.variantsIds &&
        eligibleItemData.variantsIds.map((each) => +each);
      setIdsListInDiscount((prevState: IdsList) => ({
        ...prevState,
        productIds: {
          productIds: productIds,
          variantIds: variantIds,
        },
      }));
    }
  }, [eligibleItemData.product, eligibleItemData.variantsIds]);

  const convertedEligibleData = {
    menu_ids:
      eligibleItemData.menu && eligibleItemData.menu.map((item: any) => +item),
    bundle_ids:
      eligibleItemData.bundles &&
      eligibleItemData.bundles.map((item: any) => +item),
    product_ids:
      eligibleItemData.product &&
      eligibleItemData.product.map((item: any) => +item),
    variant_ids:
      eligibleItemData.variantsIds &&
      eligibleItemData.variantsIds.map((item: any) => +item),
    category_ids:
      eligibleItemData.category &&
      eligibleItemData.category.map((item: any) => +item),
  };

  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });

  const [locationsList, setLocationsList] = useState<Locations[]>([]);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );

      // Updating state with the required data
      setLocationsList(requiredDataList);
    }
  }, [locationsData]);

  const handleDataFromSubChild = (data: any) => {
    setItemsData(data);
  };

  const handleEligibleDataFromSubChild = (data: any) => {
    setEligibleItemData(data);
  };

  const filteredStoreListDayAndHours = store.filter(
    (each: any) => each.selected
  );

  const requiredFormatStoreList = store.length > 0
  ? store.reduce((result: any, each: any) => {
      result[each.day] = {
        isOpen: each.selected,
        timings: each.sets.map((each1: any) => ({
          openTime: convertTimeToUTC(each1.openTime),
          closingTime: convertTimeToUTC(each1.closingTime),
        })),
      };
      return result;
    }, {})
  : null;

  const initialValues = {
    discountName: "",
    discountType: "FLAT_AMOUNT",
    amount: "",
    description: "",
    promotion: "",
    setMinSpend: false,
    setDateRange: false,
    setMaxSpend: false,
    setMaxDiscount: false,
    selectMark: "EXCLUSIVE",
    cuponRadio: "COUPON_CODE",
    customCuponCode: "",
    limitedStockDiscount: null,
    customerGroup: [],
    minAmount: "",
    maxDiscountAmount: "",
    location: locationId,
    allItems: true,
    allItemInBuy1: false,
    itemQuantity: "1",
    eligibleItemsQty: "1",
    discount: "",
  };
  const [error, setError] = useState(false);
  const handleError = () => {
    setError(false);
  };
  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      if (!duplicateCuponCode) {
        if (
          ((itemData.product && itemData.product.length > 0) ||
            (itemData.menu && itemData.menu.length > 0) ||
            (itemData.bundles && itemData.bundles.length > 0) ||
            (itemData.category && itemData.category.length > 0) ||
            (itemData.variantsIds && itemData.variantsIds.length > 0) ||
            (values && values.allItems === true)) &&
          !errorState
        ) {
          const modifiedidsListInDiscount= {
            bundle_ids: [...idsListInDiscount.bundleIds.productIds],
          product_ids: [
            ...idsListInDiscount.categoryIds.productIds,...idsListInDiscount.menuIds.productIds,...idsListInDiscount.productIds.productIds
          ],
          variant_ids: [
            ...idsListInDiscount.categoryIds.variantIds,...idsListInDiscount.menuIds.variantIds,...idsListInDiscount.productIds.variantIds
            ,
          ]
            }
            const modifiedidsListInItems= {
              bundle_ids: [...idsList.bundleIds.productIds],
            product_ids: [
              ...idsList.categoryIds.productIds,...idsList.menuIds.productIds,...idsList.productIds.productIds
            ],
            variant_ids: [
              ...idsList.categoryIds.variantIds,...idsList.menuIds.variantIds,...idsList.productIds.variantIds
              ,
            ]
              }
          if (id) {
            updateDiscount({
              variables: {
                discountId: id && +id,
                businessId: +businessId,
                name: values.discountName.trim(),
                description: values.description,
                ...(values.discount !== "freeDiscount" && {
                  discountType: values.discountType,
                  amount: parseFloat(values.amount)
              }),
                reason: +values.promotion,
                locationId: values.location && +values.location,
                image: uploadImg,
                orderType: JSON.stringify(checkedCheckBoxes),
                orderAvailability: JSON.stringify(checkedCheckBoxes1),
                deliveryZone: JSON.stringify(checkedCheckBoxes2),
                paymentType: JSON.stringify(checkedCheckBoxes3),
                discountUsageType: selectedValue,
                bgColourCode: uploadColor,
                startDate: values.setDateRange
                  ? selectedDateTimes.date && selectedDateTimes.date[0]
                  : null,
                endDate: values.setDateRange
                  ? selectedDateTimes.date && selectedDateTimes.date[1]
                  : null,
                startTime: values.setDateRange
                  ? selectedDateTimes.time && selectedDateTimes.time[0]
                  : null,
                endTime: values.setDateRange
                  ? selectedDateTimes.time && selectedDateTimes.time[1]
                  : null,
                minTotalCartAmount: values.setMaxSpend
                  ? values.minAmount && +values.minAmount
                  : null,
                maxDiscountAmount: values.setMaxDiscount
                  ? values.maxDiscountAmount && +values.maxDiscountAmount
                  : null,
                markDiscountAs: values.selectMark,
                discountApplicableAs: values.cuponRadio,
                limitedStockQuantity: values.limitedStockDiscount
                  ? String(values.limitedStockDiscount)
                  : null,
                couponCode:
                  values.cuponRadio === "COUPON_CODE"
                    ? values.customCuponCode
                    : null,
                discountSchedule: values.setMinSpend
                  ? JSON.stringify(requiredFormatStoreList)
                  : null,
                eligibleItems: values.allItems
                  ? null
                  : JSON.stringify(convertedData),
                items: values.allItems ? null : JSON.stringify(modifiedidsListInItems),
                // isExactItemQuantity: true,
                minNoOfItemsRequired:
                  formik.values.itemQuantity &&
                  formik.values.itemQuantity.toString().length > 0
                    ? +formik.values.itemQuantity
                    : null,
                applyDiscountTo: discountTypeFormat,
                applyToAllItems: values.allItems,
                customerGroup:
                  values.customerGroup && values.customerGroup.length > 0
                    ? JSON.stringify(values.customerGroup)
                    : null,
                eligibleDiscountItems: JSON.stringify({
                  quantity: values.eligibleItemsQty,
                  items: values.allItemInBuy1 ? null : convertedEligibleData,
                  applyToAllItems: values.allItemInBuy1,
                }),
                discountItems: JSON.stringify({
                  quantity: values.eligibleItemsQty,
                  // items: values.allItemInBuy1 ? null : idsListInDiscount,
                                    items: values.allItemInBuy1 ? null : modifiedidsListInDiscount,

                  applyToAllItems: values.allItemInBuy1,
                }),
                enableDateRange: values.setDateRange,
                enableDiscountSchedule: values.setMinSpend,
                enableMinTotalCartAmount: values.setMaxSpend,
              },
            });
          } else {
            createDiscount({
              variables: {
                businessId: +businessId,
                name: values.discountName.trim(),
                description: values.description,
                
                ...(values.discount !== "freeDiscount" && {
                  discountType: values.discountType,
                  amount: parseFloat(values.amount)
              }),
                reason: +values.promotion,
                locationId: values.location && +values.location,
                image: uploadImg,
                bgColourCode: uploadColor,
                orderType: JSON.stringify(checkedCheckBoxes),
                orderAvailability: JSON.stringify(checkedCheckBoxes1),
                deliveryZone: JSON.stringify(checkedCheckBoxes2),
                paymentType: JSON.stringify(checkedCheckBoxes3),
                discountUsageType: selectedValue,
                startDate: values.setDateRange
                  ? selectedDateTimes.date && selectedDateTimes.date[0]
                  : null,
                endDate: values.setDateRange
                  ? selectedDateTimes.date && selectedDateTimes.date[1]
                  : null,
                startTime: values.setDateRange
                  ? selectedDateTimes.time && selectedDateTimes.time[0]
                  : null,
                endTime: values.setDateRange
                  ? selectedDateTimes.time && selectedDateTimes.time[1]
                  : null,
                minTotalCartAmount: values.setMaxSpend
                  ? values.minAmount && +values.minAmount
                  : null,
                maxDiscountAmount: values.setMaxDiscount
                  ? values.maxDiscountAmount && +values.maxDiscountAmount
                  : null,
                markDiscountAs: values.selectMark,
                discountApplicableAs: values.cuponRadio,
                limitedStockQuantity: values.limitedStockDiscount
                  ? +values.limitedStockDiscount
                  : null,
                couponCode:
                  values.cuponRadio === "COUPON_CODE"
                    ? values.customCuponCode
                    : null,
                discountSchedule: values.setMinSpend
                  ? JSON.stringify(requiredFormatStoreList)
                  : null,
                eligibleItems: values.allItems
                  ? null
                  : JSON.stringify(convertedData),
                items: values.allItems ? null : JSON.stringify(modifiedidsListInItems),
                // isExactItemQuantity: true,
                enableDateRange: values.setDateRange,
                enableDiscountSchedule: values.setMinSpend,
                enableMinTotalCartAmount: values.setMaxSpend,
                minNoOfItemsRequired:
                  formik.values.itemQuantity &&
                  formik.values.itemQuantity.toString().length > 0
                    ? +formik.values.itemQuantity
                    : null,
                applyDiscountTo: discountTypeFormat,
                applyToAllItems: values.allItems,
                customerGroup:
                  values.customerGroup && values.customerGroup.length > 0
                    ? JSON.stringify(values.customerGroup)
                    : null,
                eligibleDiscountItems: JSON.stringify({
                  quantity: values.eligibleItemsQty,
                  items: values.allItemInBuy1 ? null : convertedEligibleData,
                  applyToAllItems: values.allItemInBuy1,
                }),
                discountItems: JSON.stringify({
                  quantity: values.eligibleItemsQty,
                  // items: values.allItemInBuy1 ? null : idsListInDiscount,
                  items: values.allItemInBuy1 ? null : modifiedidsListInDiscount,

                  applyToAllItems: values.allItemInBuy1,
                }),
                isPremadeDiscount: false,
              },
            });
          }
        } else {
          setError(true);
        }
      }
    },
  });

  const discountType = sessionStorage.getItem("discountType") as string;

  useEffect(() => {
    formik.setFieldValue("discount", discountType);
  }, [discountType]);

  const [reasons, setReasons] = useState<
    { id: string; reason: string; checked: boolean }[]
  >([]);

  const { data: discountReasons } = useQuery(DISCOUNT_REASONS, {
    context: { clientName: "discountClient" },
    variables: { reasonType: "Discount", businessId: +businessId },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });

  React.useEffect(() => {
    if (discountReasons && discountReasons.discountReasons) {
      const list: { id: string; reason: string; checked: boolean }[] =
        discountReasons.discountReasons.edges.map((each: any) => ({
          id: each.node.reasonId,
          reason: each.node.reason,
          checked: each.node.isSelected,
        }));

      const checkedList = list.filter((each) => each.checked === true);
      setReasons(checkedList);
    }
  }, [discountReasons]);

  const [checkboxes, setCheckboxes] = useState<CheckboxList[]>([
    {
      name: t("createDiscount.all"),
      isChecked: true,
      value: "all",
    },
    { name: t("createDiscount.dineIn"), isChecked: true, value: "dine_in" },
    {
      name: t("createDiscount.collection"),
      isChecked: true,
      value: "collection",
    },
    { name: t("createDiscount.delivery"), isChecked: true, value: "delivery" },
    { name: t("createDiscount.shop"), isChecked: true, value: "shop" },
  ]);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;

    if (name === "All") {
      // If "All" checkbox is clicked, update all checkboxes accordingly
      const updatedCheckboxes = checkboxes.map((checkbox) => ({
        ...checkbox,
        isChecked: checked,
      }));
      setCheckboxes(updatedCheckboxes);
    } else {
      // Update the individual checkbox
      const updatedCheckboxes = checkboxes.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      );
      setCheckboxes(updatedCheckboxes);

      // Check if all individual checkboxes other than "All" are checked
      const allIndividualChecked = updatedCheckboxes
        .filter((checkbox) => checkbox.name !== "All")
        .every((checkbox) => checkbox.isChecked);

      // Update the "All" checkbox based on the individual checkboxes
      const allCheckboxIndex = updatedCheckboxes.findIndex(
        (checkbox) => checkbox.name === "All"
      );
      if (allCheckboxIndex !== -1) {
        const updatedAllCheckbox = [...updatedCheckboxes];
        updatedAllCheckbox[allCheckboxIndex].isChecked = allIndividualChecked;
        setCheckboxes(updatedAllCheckbox);
      }
    }
  };

  const [checkboxes1, setCheckboxes1] = useState<CheckboxList[]>([
    {
      name: "All",
      isChecked: true,
    },
    { name: "Epos", isChecked: true },
    { name: "Online", isChecked: true },
    { name: "Mobile", isChecked: true },
  ]);

  const handleCheckboxChange1 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (name === "All") {
      // If "All" checkbox is clicked, update all checkboxes accordingly
      const updatedCheckboxes = checkboxes1.map((checkbox) => ({
        ...checkbox,
        isChecked: checked,
      }));
      setCheckboxes1(updatedCheckboxes);
    } else {
      // Update the individual checkbox
      const updatedCheckboxes = checkboxes1.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      );
      setCheckboxes1(updatedCheckboxes);

      // Check if all individual checkboxes other than "All" are checked
      const allIndividualChecked = updatedCheckboxes
        .filter((checkbox) => checkbox.name !== "All")
        .every((checkbox) => checkbox.isChecked);

      // Update the "All" checkbox based on the individual checkboxes
      const allCheckboxIndex = updatedCheckboxes.findIndex(
        (checkbox) => checkbox.name === "All"
      );
      if (allCheckboxIndex !== -1) {
        const updatedAllCheckbox = [...updatedCheckboxes];
        updatedAllCheckbox[allCheckboxIndex].isChecked = allIndividualChecked;
        setCheckboxes1(updatedAllCheckbox);
      }
    }
  };

  const checkedCheckBoxes = checkboxes
    .filter((each) => each.isChecked === true && each.name !== "All")
    .map((item) => item.value);

  const [checkboxes2, setCheckboxes2] = useState<CheckboxList[]>([
    {
      name: "All",
      isChecked: true,
    },
    { name: "Zone1", isChecked: true },
    { name: "Zone2", isChecked: true },
    { name: "Zone3", isChecked: true },
  ]);

  const checkedCheckBoxes1 = checkboxes1
    .filter((each) => each.isChecked === true && each.name !== "All")
    .map((item) => item.name);

  const handleCheckboxChange2 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (name === "All") {
      // If "All" checkbox is clicked, update all checkboxes accordingly
      const updatedCheckboxes = checkboxes2.map((checkbox) => ({
        ...checkbox,
        isChecked: checked,
      }));
      setCheckboxes2(updatedCheckboxes);
    } else {
      // Update the individual checkbox
      const updatedCheckboxes = checkboxes2.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      );
      setCheckboxes2(updatedCheckboxes);

      // Check if all individual checkboxes other than "All" are checked
      const allIndividualChecked = updatedCheckboxes
        .filter((checkbox) => checkbox.name !== "All")
        .every((checkbox) => checkbox.isChecked);

      // Update the "All" checkbox based on the individual checkboxes
      const allCheckboxIndex = updatedCheckboxes.findIndex(
        (checkbox) => checkbox.name === "All"
      );
      if (allCheckboxIndex !== -1) {
        const updatedAllCheckbox = [...updatedCheckboxes];
        updatedAllCheckbox[allCheckboxIndex].isChecked = allIndividualChecked;
        setCheckboxes2(updatedAllCheckbox);
      }
    }
  };

  const checkedCheckBoxes2 = checkboxes2
    .filter((each) => each.isChecked === true && each.name !== "All")
    .map((item) => item.name);
  const [checkboxes3, setCheckboxes3] = useState<CheckboxList[]>([
    {
      name: "All",
      isChecked: true,
    },
    { name: "Card", isChecked: true },
    { name: "Cash", isChecked: true },
    { name: "Online Pay", isChecked: true },
  ]);

  const handleCheckboxChange3 = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;

    if (name === "All") {
      // If "All" checkbox is clicked, update all checkboxes accordingly
      const updatedCheckboxes = checkboxes3.map((checkbox) => ({
        ...checkbox,
        isChecked: checked,
      }));
      setCheckboxes3(updatedCheckboxes);
    } else {
      // Update the individual checkbox
      const updatedCheckboxes = checkboxes3.map((checkbox) =>
        checkbox.name === name ? { ...checkbox, isChecked: checked } : checkbox
      );
      setCheckboxes3(updatedCheckboxes);

      // Check if all individual checkboxes other than "All" are checked
      const allIndividualChecked = updatedCheckboxes
        .filter((checkbox) => checkbox.name !== "All")
        .every((checkbox) => checkbox.isChecked);

      // Update the "All" checkbox based on the individual checkboxes
      const allCheckboxIndex = updatedCheckboxes.findIndex(
        (checkbox) => checkbox.name === "All"
      );
      if (allCheckboxIndex !== -1) {
        const updatedAllCheckbox = [...updatedCheckboxes];
        updatedAllCheckbox[allCheckboxIndex].isChecked = allIndividualChecked;
        setCheckboxes3(updatedAllCheckbox);
      }
    }
  };

  const checkedCheckBoxes3 = checkboxes3
    .filter((each) => each.isChecked === true && each.name !== "All")
    .map((item) => item.name);

  const [selectedValue, setSelectedValue] = useState("SINGLE_USE");

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const datePeriod = [
    t("createDiscount.start_date"),
    t("createDiscount.end_date"),
  ];
  const timePeriod = [
    t("createDiscount.start_time"),
    t("createDiscount.end_time"),
  ];

  const [selectedDateTimes, setSelectedDateTimes] =
    useState<SelectedDateTimesState>({});
  const handleDateTimeChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    type: string
  ) => {
    const { value } = event.target;

    setSelectedDateTimes((prevDateTimes) => ({
      ...prevDateTimes,
      [type]: {
        ...(prevDateTimes[type] || {}),
        [index]: value,
      },
    }));
  };

  const tooltips: Tooltips[] = [
    {
      title: t("createDiscount.exclusive"),
      content: t("createDiscount.exclusive_para"),
    },
    {
      title: t("createDiscount.competive"),
      content: t("createDiscount.competive_para"),
    },
    {
      title: t("createDiscount.stackable"),
      content: t("createDiscount.stackable_para"),
    },
  ];

  const handleNavigate = () => {
    if (id) {
      navigate("/discount/discount-list");
    } else {
      navigate("/discount/discount");
    }
  };
  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadColor, setUploadColor] = useState<string | null>(null);
  const [categoryStatusCode, setCategoryStatusCode] = React.useState<number>(0);
  const [errorModal, setErrorModal] = React.useState<boolean>(false);

  const handleErrorModal = () => {
    setErrorModal(false);
  };

  const handleImage = (value: string | null) => {
    setUploadImg(value);
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
  };
  const navigate = useNavigate();
  const [createDiscount, { loading: createLoading }] = useMutation(
    CREATE_DISCOUNT,
    {
      context: { clientName: "discountClient" },
      onCompleted: (data) => {
        toast.success(
          <div>
            <Typography>{t("toast.created")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
        navigate("/discount/discount-list");
      },
      onError: (error) => {
        // Error callback
        const graphqlError = error.graphQLErrors[0];

        if (
          graphqlError &&
          graphqlError.extensions &&
          graphqlError.extensions.status
        ) {
          // Extract the status code from the extensions property
          setCategoryStatusCode(
            parseInt(graphqlError.extensions.status, 10) || 500
          );
          setErrorModal(true);
        }
        const errorMessage = graphqlError.message || "An error occurred";
        console.error(
          `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
        );
      },
    }
  );

  const [updateDiscount, { loading: updateLoading }] = useMutation(
    UPDATE_DISCOUNT,
    {
      context: { clientName: "discountClient" },
      onCompleted: (data) => {
        toast.success(
          <div>
            <Typography>{t("toast.updated")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast3",
          }
        );
        navigate("/discount/discount-list");
      },
      onError: (error) => {
        // Error callback
        const graphqlError = error.graphQLErrors[0];

        if (
          graphqlError &&
          graphqlError.extensions &&
          graphqlError.extensions.status
        ) {
          // Extract the status code from the extensions property
          setCategoryStatusCode(
            parseInt(graphqlError.extensions.status, 10) || 500
          );
          setErrorModal(true);
        }
        const errorMessage = graphqlError.message || "An error occurred";
        console.error(
          `GraphQL error: Status ${categoryStatusCode}, Message: ${errorMessage}`
        );
      },
    }
  );

  let discountTypeFormat: string | null;
  // const discountType = sessionStorage.getItem("discountType")
  switch (discountType) {
    case "customerGroup":
      discountTypeFormat = "CUSTOMER_GROUP";
      break;
    case "buy1Get1":
      discountTypeFormat = "BUY_N_GET_M";
      break;
    case "exactQty":
      discountTypeFormat = "EXACT_QUANTITY";
      break;
    case "forItem":
      discountTypeFormat = "ITEM_OR_CATEGORY";
      break;
    case "freeDiscount":
      discountTypeFormat = "FREE_DELIVERY";
      break;
    case "minQty":
      discountTypeFormat = "MINIMUM_QUANTITY";
      break;
    default:
      discountTypeFormat = null;
      break;
  }

  const { data: discountEditData } = useQuery(DISCOUNT_EDIT_DATA, {
    context: { clientName: "discountClient" },
    variables: { discountId: id && +id },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });

  const [itemsList, setItemsList] = useState<any>({});
  const [eligibleItemsList, setEligibleItemsList] = useState<any>({});

  const [imageFromApi, setImageFromAPi] = useState<string | null>(null);
  const [colorFromApi, setColorFromAPi] = useState<string | null>(null);
  const [errorState, updateErrorState] = useState<boolean>(false);
  useEffect(() => {
    let editStore2 = [];
    if (discountEditData && discountEditData.discount) {
      if (discountEditData.discount.discountSchedule) {
        editStore2 = store.map((dayObj) => {
          const dayKey = dayObj.day;
          if (JSON.parse(discountEditData.discount.discountSchedule)[dayKey]) {
            return {
              ...dayObj,
              selected: JSON.parse(discountEditData.discount.discountSchedule)[
                dayKey
              ].isOpen,
              sets: JSON.parse(discountEditData.discount.discountSchedule)[
                dayKey
              ].timings,
            };
          }
          return dayObj;
        });

        setStore(editStore2);
      }

      const list = {
        discountName: discountEditData.discount.name,
        discountType: discountEditData.discount.discountType,
        amount: discountEditData.discount.amount,
        description: discountEditData.discount.description,
        promotion:
          discountEditData.discount.reason &&
          discountEditData.discount.reason.reasonId,
        setMinSpend: discountEditData.discount.discountSchedule ? true : false,
        setDateRange:
          discountEditData.discount.startDate ||
          discountEditData.discount.endDate ||
          discountEditData.discount.startTime ||
          discountEditData.discount.endTime
            ? true
            : false,
        setMaxSpend: discountEditData.discount.minTotalCartAmount
          ? true
          : false,
        setMaxDiscount: discountEditData.discount.maxDiscountAmount
          ? true
          : false,
        selectMark: discountEditData.discount.markDiscountAs,
        cuponRadio: discountEditData.discount.discountApplicableAs,
        customCuponCode: discountEditData.discount.couponCode || "",

        limitedStockDiscount: discountEditData.discount.limitedStockQuantity,
        itemQuantity: discountEditData.discount.minNoOfItemsRequired
          ? discountEditData.discount.minNoOfItemsRequired
          : "",
        minAmount: discountEditData.discount.minTotalCartAmount,
        maxDiscountAmount: discountEditData.discount.maxDiscountAmount,
        location:
          discountEditData.discount.locationId &&
          discountEditData.discount.locationId.toString(),
        allItems: discountEditData.discount.applyToAllItems,
        allItemInBuy1:
          discountEditData.discount.eligibleDiscountItems &&
          JSON.parse(discountEditData.discount.eligibleDiscountItems)
            .applyToAllItems,
        customerGroup:
          discountEditData.discount.customerGroup &&
          JSON.parse(discountEditData.discount.customerGroup),
        eligibleItemsQty:
          discountEditData.discount.eligibleDiscountItems &&
          JSON.parse(discountEditData.discount.eligibleDiscountItems).quantity,
        discount: discountType,
      };
      setCharCount(discountEditData.discount.description?.length);
      formik.setValues({
        ...formik.values,
        ...list,
      });
      if (discountEditData.discount.couponCode) {
        checkCuponCode({
          variables: {
            couponCode: discountEditData.discount.couponCode,
            locationId: +formik.values.location,
          },
        });
      }

      setSelectedDateTimes((prevDateTimes) => ({
        ...prevDateTimes,
        date: {
          ...prevDateTimes.date,
          0: discountEditData.discount.startDate,
        },
      }));
      setSelectedDateTimes((prevDateTimes) => ({
        ...prevDateTimes,
        date: {
          ...prevDateTimes.date,
          1: discountEditData.discount.endDate,
        },
      }));

      setSelectedDateTimes((prevDateTimes) => ({
        ...prevDateTimes,
        time: {
          ...prevDateTimes.time,
          0: discountEditData.discount.startTime,
        },
      }));
      setSelectedDateTimes((prevDateTimes) => ({
        ...prevDateTimes,
        time: {
          ...prevDateTimes.time,
          1: discountEditData.discount.endTime,
        },
      }));
      setCheckboxes((prevState) =>
        prevState.map((each) => {
          if (
            discountEditData.discount.orderType &&
            JSON.parse(discountEditData.discount.orderType).length === 4
          ) {
            return { ...each, isChecked: true };
          } else {
            return discountEditData.discount.orderType &&
              discountEditData.discount.orderType.includes(each.value)
              ? { ...each, isChecked: true }
              : { ...each, isChecked: false };
          }
        })
      );

      setCheckboxes1((prevState) =>
        prevState.map((each) => {
          if (
            discountEditData.discount.orderAvailability &&
            JSON.parse(discountEditData.discount.orderAvailability).length === 3
          ) {
            return { ...each, isChecked: true };
          } else {
            return discountEditData.discount.orderAvailability &&
              discountEditData.discount.orderAvailability.includes(each.name)
              ? { ...each, isChecked: true }
              : { ...each, isChecked: false };
          }
        })
      );
      setCheckboxes2((prevState) =>
        prevState.map((each) => {
          if (
            discountEditData.discount.deliveryZone &&
            JSON.parse(discountEditData.discount.deliveryZone).length === 3
          ) {
            return { ...each, isChecked: true };
          } else {
            return discountEditData.discount.deliveryZone &&
              discountEditData.discount.deliveryZone.includes(each.name)
              ? { ...each, isChecked: true }
              : { ...each, isChecked: false };
          }
        })
      );
      setCheckboxes3((prevState) =>
        prevState.map((each) => {
          if (
            discountEditData.discount.paymentType &&
            JSON.parse(discountEditData.discount.paymentType).length === 3
          ) {
            return { ...each, isChecked: true };
          } else {
            return discountEditData.discount.paymentType &&
              discountEditData.discount.paymentType.includes(each.name)
              ? { ...each, isChecked: true }
              : { ...each, isChecked: false };
          }
        })
      );

      setSelectedValue(discountEditData.discount.discountUsageType);
      discountEditData.discount.eligibleItems &&
        setItemsList(JSON.parse(discountEditData.discount.eligibleItems));
      discountEditData.discount.items &&
        setIdsList(JSON.parse(discountEditData.discount.items));

      setImageFromAPi(discountEditData.discount.image);
      setColorFromAPi(discountEditData.discount.bgColourCode);

      discountEditData.discount.eligibleDiscountItems &&
        setEligibleItemsList(
          JSON.parse(discountEditData.discount.eligibleDiscountItems).items
        );
      discountEditData.discount.discountItems &&
        setIdsListInDiscount(
          JSON.parse(discountEditData.discount.discountItems).items
        );
    }
  }, [discountEditData]);

  const handleCancelButton = () => {
    formik.setValues(initialValues);

    // setUploadImg(null)
    // setUploadColor(null)
    // setEligibleItemData(eligibleItemList)
    // setItemsData(itemdataList)
    // setIdsListInDiscount(idsListIndiscountData)
    // setIdsList(idsListData)
    // setItemsList({})
    // setEligibleItemsList({})
  };

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  const [duplicateCuponCode, setDuplicateCuponCode] = useState(false);

  const [checkCuponCode, { loading: cuponLoading }] = useMutation(
    CUPON_DUPLICATE,
    {
      context: { clientName: "discountClient" },
      onCompleted: (data) => {
        setDuplicateCuponCode(data.couponCodeDuplicateCheck.duplicate);
      },
    }
  );

  const handleCuponCodeCheck = (value: string) => {
    checkCuponCode({
      variables: {
        couponCode: value,
        locationId: +formik.values.location,
      },
    });
  };

  const currencySymbol = sessionStorage.getItem("currencySymbol");
  return (
    <div style={{ backgroundColor: "#Fafafa" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            padding: "0.62rem 1rem",
          }}
        >
          <div className="createDiscount_closeIcon_con">
            <CloseIcon
              className="createDiscount_closeIcon"
              onClick={handleNavigate}
            />
          </div>
          {id ? (
            <Typography className="createDiscount_head">
              {t("createDiscount.edit_discount")}
            </Typography>
          ) : (
            <Typography className="createDiscount_head">
              {t("createDiscount.create_discount")}
            </Typography>
          )}
          <Box sx={{ dispaly: "flex" }}>
            {/* <Button className='main-button' sx={{ marginRight: '12px' }} onClick={handleCancelButton}>Cancel</Button> */}

            <Button
              className="main-button"
              style={{}}
              type="submit"
              disabled={cuponLoading || createLoading || updateLoading}
            >
              {t("createDiscount.save")}
            </Button>
          </Box>
        </Box>

        <div
          className="createDiscount_discount_table"
          style={{ marginTop: "1.25rem" }}
        >
          <Box
            sx={{
              width: "80%",
              height: "75vh",
              overflowY: "scroll",
              display: "flex",
              flexDirection: "column",
              gap: "32px",
              WebkitOverflowScrolling: "touch",
              "&::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <Paper
              elevation={0}
              style={{
                width: "100%",
                borderLeft: "2px solid #AE9BE2 ",
                borderBottom: "1px solid #EEE",
                borderRight: "1px solid #EEE",
                borderTop: "1px solid #EEE",
                padding: "12px 48px 12px 18px",
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <Typography className="createDiscount_table_head">
                {t("createDiscount.details")}
              </Typography>
              <div
                style={{ display: "flex", width: "100%", alignItems: "start" }}
              >
                <div
                  style={{
                    width: "76%",
                    display: "flex",
                    flexDirection: "column",
                    gap: "24px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      gap: "3.2%",
                    }}
                  >
                    <Box sx={{ width: "44%" }}>
                      <TextField
                        label={
                          <Typography className="createDiscount_label">
                            {t("createDiscount.discount_name")}
                          </Typography>
                        }
                        variant="outlined"
                        color="warning"
                        sx={{ width: "100%" }}
                        // className='createDiscount_textField'
                        value={formik.values.discountName}
                        name="discountName"
                        onBlur={formik.handleBlur}
                        onChange={(e) => formik.handleChange(e)}
                      />
                      {formik.touched.discountName &&
                        formik.errors.discountName && (
                          <div style={{ color: "red" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              {formik.errors.discountName as string}
                            </Typography>
                          </div>
                        )}
                    </Box>
                    {!id && (
                      <Box style={{ width: "44%" }}>
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel
                            id="demo-simple-select-label"
                            className="createDiscount_label"
                          >
                            {t("createDiscount.select_location")}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Select Location"
                            name="location"
                            color="warning"
                            value={formik.values.location}
                            onBlur={formik.handleBlur}
                            // onChange={handleLocationChange}
                            onChange={(e) => formik.handleChange(e)}
                          >
                            {locationsList.map((each) => (
                              <MenuItem key={each.storeId} value={each.storeId}>
                                {each.storeName}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        {formik.touched.location && formik.errors.location && (
                          <div style={{ color: "red" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              {formik.errors.location as string}
                            </Typography>
                          </div>
                        )}
                      </Box>
                    )}
                  </div>
                  {formik.values.discount !== "freeDiscount" && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "3.2%",
                      }}
                    >
                      <FormControl variant="outlined" sx={{ width: "44%" }}>
                        <InputLabel
                          id="demo-simple-select-label1"
                          className="createDiscount_label"
                        >
                          {t("createDiscount.discount_type")}(%/{currencySymbol}
                          )
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label1"
                          id="demo-simple-select1"
                          label={t("createDiscount.discount_type")}
                          color="warning"
                          value={formik.values.discountType}
                          name="discountType"
                          onChange={(e) => formik.handleChange(e)}
                        >
                          <MenuItem value="FLAT_AMOUNT">
                            {t("createDiscount.flat")}
                          </MenuItem>
                          <MenuItem value="PERCENTAGE">%</MenuItem>
                        </Select>
                      </FormControl>
                      <Box style={{ width: "44%" }}>
                        <TextField
                          label={
                            <Typography className="createDiscount_label">
                              {formik.values.discountType === "FLAT_AMOUNT"
                                ? t("createDiscount.enter_amount")
                                : t("createDiscount.enter_percentage")}
                            </Typography>
                          }
                          type="text"
                          variant="outlined"
                          value={formik.values.amount}
                          name="amount"
                          onChange={(e) => {
                            const val = e.target.value;
                            if (!val || /^[0-9]+$/.test(val)) {
                              formik.handleChange(e);
                            }
                          }}
                          color="warning"
                          style={{ width: "100%" }}
                          onBlur={formik.handleBlur}
                          className="hide-number-input-icons"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                {formik.values.discountType === "FLAT_AMOUNT"
                                  ? currencySymbol
                                  : "%"}
                              </InputAdornment>
                            ),
                          }}
                        />
                        {formik.touched.amount && formik.errors.amount && (
                          <div style={{ color: "red" }}>
                            <Typography sx={{ fontSize: "12px" }}>
                              {formik.errors.amount as string}
                            </Typography>
                          </div>
                        )}
                      </Box>
                    </div>
                  )}
                  <div className="textarea-box" style={{ width: "100%" }}>
                    <textarea
                      id="textarea"
                      onChange={(e) => {
                        updateCharCount(e);
                        formik.handleChange(e);
                      }}
                      style={{ width: "100%" }}
                      value={formik.values.description}
                      name="description"
                      placeholder="Enter Description"
                    >
                      {formik.values.description}
                    </textarea>
                    <div id="charCount" className="char-count">
                      {charCount}/500
                    </div>
                  </div>
                </div>

                <Box
                  sx={{ marginLeft: "10%", width: "150px", padding: "2px" }}
                  className="create-product-img-con"
                >
                  <ImageDrawer
                    handleImage={handleImage}
                    handleColor={handleColor}
                    colorCodeFromApi={colorFromApi}
                    imageFromApi={imageFromApi}
                    heightValue={120}
                  />
                </Box>
              </div>

              {/* <div style={{ display: "flex", justifyContent: "flex-end", marginRight: "40px",  }}>
                                <Button className='createDiscount_clear_button' style={{ margin: '7px' }}>{t("createDiscount.clear")}</Button>
                            </div> */}
            </Paper>
            {
              <CreateDiscountExactQty
                handleDataFromSubChild={handleDataFromSubChild}
                formik={formik}
                itemsList={itemsList}
                handleEligibleDataFromSubChild={handleEligibleDataFromSubChild}
                eligibleItemsList={eligibleItemsList}
              />
            }
            {/* {error && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "50px", width: "100%", backgroundColor: "red", color: "white" }}>Please Select Atleast One Item</div>} */}
            <Paper
              elevation={0}
              style={{
                padding: "20px 12px",
                borderLeft: "2px solid #E19BE2 ",
                borderBottom: "1px solid #EEE",
                borderRight: "1px solid #EEE",
                borderTop: "1px solid #EEE",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
              }}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className="createDiscount_discount_mark">
                    {t("createDiscount.mark_discount")}
                  </Typography>
                  <Tooltip
                    title={
                      <div>
                        <Typography className="createDiscount_toolTip_head">
                          {tooltips[0].title}:{" "}
                          <span className="createDiscount_toolTip_text">
                            {" "}
                            {tooltips[0].content}
                          </span>
                        </Typography>
                        <Typography className="createDiscount_toolTip_head">
                          {tooltips[1].title}:{" "}
                          <span className="createDiscount_toolTip_text">
                            {" "}
                            {tooltips[1].content}
                          </span>
                        </Typography>
                        <Typography className="createDiscount_toolTip_head">
                          {" "}
                          {tooltips[2].title}:{" "}
                          <span className="createDiscount_toolTip_text">
                            {" "}
                            {tooltips[2].content}
                          </span>
                        </Typography>
                      </div>
                    }
                    placement="bottom-start"
                    classes={{ tooltip: "custom-tooltip" }}
                  >
                    <Button sx={{ m: 1 }}>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        className="custom-icon-container"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="var(--Text-Grey-1, #D9D9D9)"
                        >
                          <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                        </svg>
                        <Typography
                          component="span"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          !
                        </Typography>
                      </div>
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ width: "60%", marginLeft: "3%" }}>
                  <FormControl style={{ marginLeft: "20px", width: "32%" }}>
                    <InputLabel>{t("createDiscount.select")}</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Select"
                      value={formik.values.selectMark}
                      name="selectMark"
                      onChange={(e) => formik.handleChange(e)}
                      color="warning"
                    >
                      <MenuItem value="EXCLUSIVE">
                        <Typography>{t("createDiscount.exclusive")}</Typography>
                      </MenuItem>
                      <MenuItem value="COMPETITIVE">
                        <Typography>{t("createDiscount.competive")}</Typography>
                      </MenuItem>
                      <MenuItem value="STACKABLE">
                        <Typography>{t("createDiscount.stackable")}</Typography>
                      </MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </div>
              <FormControl component="fieldset">
                <RadioGroup
                  aria-label="options"
                  value={formik.values.cuponRadio}
                  name="cuponRadio"
                  onChange={(e) => formik.handleChange(e)}
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    gap: "6%",
                    alignItems: "center",
                  }}
                >
                  <FormControlLabel
                    value="COUPON_CODE"
                    control={<Radio color="warning" />}
                    label={
                      <Typography className="createDiscount_discount_mark">
                        {t("createDiscount.use_custom_cupon")}
                      </Typography>
                    }
                  />
                  <FormControlLabel
                    value="AUTOMATIC"
                    control={<Radio color="warning" />}
                    label={
                      <Typography className="createDiscount_discount_mark">
                        {t("createDiscount.automatically")}
                      </Typography>
                    }
                  />
                </RadioGroup>
              </FormControl>
              {formik.values.cuponRadio === "COUPON_CODE" && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography
                    className="createDiscount_discount_mark"
                    style={{ marginRight: "10%" }}
                  >
                    {t("createDiscount.cupon_code")}
                  </Typography>
                  <Box sx={{ width: "20%" }}>
                    <TextField
                      variant="outlined"
                      sx={{ width: "100%" }}
                      value={formik.values.customCuponCode}
                      name="customCuponCode"
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleCuponCodeCheck(e.target.value);
                      }}
                      onBlur={(e) => {
                        formik.handleBlur(e);
                      }}
                      label={t("createDiscount.enter")}
                      color="warning"
                    />
                    {formik.touched.customCuponCode &&
                      formik.errors.customCuponCode && (
                        <div style={{ color: "red" }}>
                          <Typography sx={{ fontSize: "12px" }}>
                            {formik.errors.customCuponCode as string}
                          </Typography>
                        </div>
                      )}
                    {duplicateCuponCode && (
                      <div style={{ color: "red", fontSize: "12px" }}>
                        <Typography sx={{ fontSize: "12px" }}>
                          Cupon Code Already Exists
                        </Typography>
                      </div>
                    )}
                  </Box>
                </div>
              )}
              <div style={{ display: "flex", alignItems: "center" }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <Typography className="createDiscount_discount_mark">
                    {t("createDiscount.limited_stock")}
                  </Typography>
                  <Tooltip
                    title={
                      <div>
                        <Typography className="createDiscount_toolTip_head">
                          Limited stock discount:{" "}
                          <span className="createDiscount_toolTip_text">
                            Special price reduction offered for a limited
                            quantity of a menu item, such as “Half-price sushi
                            rolls for the first 20 customers.”
                          </span>
                        </Typography>
                        {/* <Typography className='createDiscount_toolTip_head'>{tooltips[1].title}: <span className='createDiscount_toolTip_text'> {tooltips[1].content}</span></Typography>
                                        <Typography className='createDiscount_toolTip_head'> {tooltips[2].title}: <span className='createDiscount_toolTip_text'> {tooltips[2].content}</span></Typography> */}
                      </div>
                    }
                    placement="bottom-start"
                    classes={{ tooltip: "custom-tooltip" }}
                  >
                    <Button sx={{ m: 1 }}>
                      <div
                        style={{
                          position: "relative",
                          display: "inline-block",
                        }}
                        className="custom-icon-container"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <circle cx="12" cy="12" r="12" fill="#D9D9D9" />
                        </svg>
                        <Typography
                          component="span"
                          style={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                            color: "black",
                            fontWeight: "bold",
                            fontSize: "16px",
                          }}
                        >
                          !
                        </Typography>
                      </div>
                    </Button>
                  </Tooltip>
                </div>
                <div style={{ marginLeft: "1%" }}>
                  <TextField
                    variant="outlined"
                    value={formik.values.limitedStockDiscount}
                    name="limitedStockDiscount"
                    onChange={(e) => {
                      const val = e.target.value;
                      if ((!val || /^[0-9.]+$/.test(val)) && val.length <= 6) {
                        formik.handleChange(e);
                      }
                    }}
                    label={t("createDiscount.enter")}
                    // // type="number"
                    // className="hide-number-input-icons"
                    color="warning"
                  />
                </div>
              </div>
            </Paper>

            <Paper
              elevation={0}
              style={{
                padding: "20px 12px",
                borderLeft: "2px solid #E2A39B ",
                borderBottom: "1px solid #EEE",
                borderRight: "1px solid #EEE",
                borderTop: "1px solid #EEE",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
              }}
            >
              <Paper
                elevation={0}
                // style={{ border: "1px solid #EEE", borderRadius: "8px" }}
              >
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.setMinSpend}
                        name="setMinSpend"
                        onChange={(e) => formik.handleChange(e)}
                        color="warning"
                      />
                    }
                    label={
                      <div style={{ marginLeft: "3.4%", width: "max-content" }}>
                        <Typography className="createMinimum_head">
                          {t("createDiscount.set_time")}
                        </Typography>
                        <Typography className="createMinimum_para">
                          {t("createDiscount.set_time_para")}
                        </Typography>
                      </div>
                    }
                    sx={{ marginLeft: "1.3%", width: "100%" }}
                  />
                </div>
                {formik.values.setMinSpend && (
                  <WeeklyStatus
                    updateErrorState={updateErrorState}
                    data={store}
                    updateState={setStore}
                  />
                )}
              </Paper>
              <Paper
                elevation={0}
                // style={{ border: "1px solid #EEE", borderRadius: "8px" }}
              >
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.setDateRange}
                        name="setDateRange"
                        onChange={(e) => formik.handleChange(e)}
                        color="warning"
                      />
                    }
                    label={
                      <div style={{ marginLeft: "3%", width: "max-content" }}>
                        <Typography className="createMinimum_head">
                          {t("createDiscount.set_date_range")}
                        </Typography>
                        <Typography className="createMinimum_para">
                          {t("createDiscount.set_date_range_para")}
                        </Typography>
                      </div>
                    }
                    sx={{ marginLeft: "1.3%", width: "100%" }}
                  />
                </div>
                {formik.values.setDateRange && (
                  <div style={{ marginTop: "6px", width: "100%" }}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow></TableRow>
                        </TableHead>
                        <div style={{ display: "flex" }}>
                          <TableBody style={{ width: "50%" }}>
                            {datePeriod.map((date, index) => (
                              <React.Fragment key={index}>
                                <TableRow sx={{ width: "100%" }}>
                                  <TableCell
                                    className="createDiscount_row_head"
                                    style={{ width: "60%" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography className="createMinimum_head">
                                        {date}
                                      </Typography>
                                    </div>
                                  </TableCell>
                                  <TableCell sx={{ width: "50%" }}>
                                    <FormControl>
                                      <TextField
                                        variant="standard"
                                        type="date"
                                        value={
                                          selectedDateTimes["date"]?.[index] ||
                                          ""
                                        }
                                        onChange={(event) =>
                                          handleDateTimeChange(
                                            event,
                                            index,
                                            "date"
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          disableUnderline: true, // This removes the underline
                                        }}
                                        inputProps={{
                                          min: !id
                                            ? new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            : undefined, // This disables past dates
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                          <TableBody sx={{ width: "50%" }}>
                            {timePeriod.map((date, index) => (
                              <React.Fragment key={index}>
                                {/* <TableRow >
                                                                    <TableCell className='createDiscount_row_head' style={{width:"50%"  }}>
                                                                        <div style={{ display: "flex", alignItems: "center" }}>

                                                                            <Typography className='createMinimum_head'>{date}</Typography>
                                                                        </div>
                                                                    </TableCell>
                                                                    <TableCell style={{width:"50%"  }}>
                                                                        <FormControl>
                                                                            <TextField
                                                                                variant="standard"
                                                                                type="time"
                                                                                value={selectedDateTimes['time']?.[index] || ''}
                                                                                onChange={(event) => handleDateTimeChange(event, index, 'time')}
                                                                                InputLabelProps={{
                                                                                    shrink: true,
                                                                                }}
                                                                                InputProps={{
                                                                                    disableUnderline: true, // This removes the underline
                                                                                }}
                                                                            />
                                                                        </FormControl>

                                                                    </TableCell>
                                                                </TableRow> */}
                                <TableRow>
                                  <TableCell
                                    className="createDiscount_row_head"
                                    style={{ width: "60%" }}
                                    align="center"
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <Typography className="createMinimum_head">
                                        {date}
                                      </Typography>
                                    </div>
                                  </TableCell>
                                  <TableCell
                                    sx={{ width: "50%" }}
                                    align="center"
                                  >
                                    <FormControl>
                                      <TextField
                                        variant="standard"
                                        type="time"
                                        value={
                                          selectedDateTimes["time"]?.[index] ||
                                          ""
                                        }
                                        onChange={(event) =>
                                          handleDateTimeChange(
                                            event,
                                            index,
                                            "time"
                                          )
                                        }
                                        InputLabelProps={{
                                          shrink: true,
                                        }}
                                        InputProps={{
                                          disableUnderline: true, // This removes the underline
                                        }}
                                      />
                                    </FormControl>
                                  </TableCell>
                                </TableRow>
                              </React.Fragment>
                            ))}
                          </TableBody>
                        </div>
                      </Table>
                    </TableContainer>
                  </div>
                )}
              </Paper>
              <Paper
                elevation={0}
                // style={{ border: "1px solid #EEE" }}
              >
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.setMaxSpend}
                        name="setMaxSpend"
                        onChange={(e) => formik.handleChange(e)}
                        color="warning"
                      />
                    }
                    label={
                      <div style={{ width: "max-content" }}>
                        <Typography className="createMinimum_head">
                          {t("createDiscount.set_minimum")}
                        </Typography>
                        <Typography className="createMinimum_para">
                          {t("createDiscount.set_minimum_para")}
                        </Typography>
                      </div>
                    }
                    sx={{ marginLeft: "1.3%", width: "100%" }}
                  />
                </div>

                {formik.values.setMaxSpend && (
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      border: "1px solid #EEE",
                    }}
                  >
                    <div>
                      <Typography className="createDiscount_maxdiscount_head">
                        {t("createDiscount.subtotal")}
                      </Typography>
                    </div>
                    <div className="createDiscount_textField style_input ">
                      <TextField
                        fullWidth
                        variant="standard"
                        placeholder="£0.00"
                        value={formik.values.minAmount}
                        name="minAmount"
                        onChange={(e) => formik.handleChange(e)}
                        type="number"
                        className="hide-number-input-icons"
                        InputProps={{
                          disableUnderline: true, // This removes the underline
                        }}
                      />
                    </div>
                  </div>
                )}
              </Paper>
              <Paper
                elevation={0}
                // style={{ border: "1px solid #EEE" }}
              >
                <div style={{ width: "100%" }}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={formik.values.setMaxDiscount}
                        name="setMaxDiscount"
                        onChange={(e) => formik.handleChange(e)}
                        color="warning"
                      />
                    }
                    label={
                      <div style={{ marginLeft: "3.4%", width: "max-content" }}>
                        <Typography className="createMinimum_head">
                          {t("createDiscount.maximum_discount")}
                        </Typography>
                        <Typography className="createMinimum_para">
                          {t("createDiscount.discount_para")}
                        </Typography>
                      </div>
                    }
                    sx={{ marginLeft: "1.3%", width: "100%" }}
                  />
                </div>
                {formik.values.setMaxDiscount && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        border: "1px solid #EEE",
                      }}
                    >
                      <div>
                        <Typography className="createDiscount_maxdiscount_head">
                          {t("createDiscount.max_value")}
                        </Typography>
                      </div>
                      <div className="createDiscount_textField style_input ">
                        <TextField
                          fullWidth
                          variant="standard"
                          placeholder="£0.00"
                          value={formik.values.maxDiscountAmount}
                          name="maxDiscountAmount"
                          onChange={(e) => formik.handleChange(e)}
                          type="number"
                          className="hide-number-input-icons"
                          InputProps={{
                            disableUnderline: true, // This removes the underline
                          }}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </Paper>

              <Typography
                className="createMinimum_para"
                sx={{ marginBottom: "20px", marginTop: "8px" }}
              >
                {t("createDiscount.maxDiscount_para")}
              </Typography>
            </Paper>
            <Paper
              elevation={0}
              style={{
                padding: "20px",
                borderLeft: "2px solid #BAE29B ",
                borderBottom: "1px solid #EEE",
                borderRight: "1px solid #EEE",
                borderTop: "1px solid #EEE",
                display: "flex",
                flexDirection: "column",
                gap: "1.25rem",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "10%" }}
              >
                <Typography className="createDiscount_table_head">
                  {t("createDiscount.discount_reason")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "44%",
                  }}
                >
                  <FormControl style={{}} className="createDiscount_textField">
                    <InputLabel
                      id="demo-simple-select-label2"
                      className="createDiscount_label"
                    >
                      {t("createDiscount.promotion_offer")}
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label2"
                      id="demo-simple-selec2t"
                      label={t("createDiscount.promotion_offer")}
                      value={formik.values.promotion}
                      name="promotion"
                      color="warning"
                      onChange={(e) => formik.handleChange(e)}
                      onBlur={formik.handleBlur}
                    >
                      {reasons.map((each) => (
                        <MenuItem key={each.id} value={each.id}>
                          <Typography sx={{ textTransform: "capitalize" }}>
                            {" "}
                            {each.reason}
                          </Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  {formik.touched.promotion && formik.errors.promotion && (
                    <div style={{ color: "red" }}>
                      <Typography sx={{ fontSize: "12px" }}>
                        {formik.errors.promotion as string}
                      </Typography>
                    </div>
                  )}
                </Box>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "1.25rem",
                }}
              >
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                  }}
                >
                  <Typography className="createDiscount_table_head2">
                    {t("createDiscount.select_order_type")}{" "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "28px",
                      alignItems: "center",
                    }}
                  >
                    {checkboxes.map((each, index) => (
                      <FormGroup sx={{ width: "16%" }} key={each.name}>
                        <FormControlLabel
                          key={each.name + index} // Ensure each checkbox has a unique key
                          control={
                            <Checkbox
                              name={each.name}
                              checked={each.isChecked}
                              onChange={handleCheckboxChange}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="discount-check-text">
                              {each.name}
                            </Typography>
                          }
                          className="discount-all-box"
                        />
                      </FormGroup>
                    ))}
                  </div>
                </Box>
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                  }}
                >
                  <Typography className="createDiscount_table_head2">
                    {t("createDiscount.oredr_availability")}{" "}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "28px",
                      alignItems: "center",
                    }}
                  >
                    {checkboxes1.map((each) => (
                      <FormGroup sx={{ width: "16%" }} key={each.name}>
                        <FormControlLabel
                          key={each.name} // Ensure each checkbox has a unique key
                          control={
                            <Checkbox
                              name={each.name}
                              checked={each.isChecked}
                              onChange={handleCheckboxChange1}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="discount-check-text">
                              {each.name}
                            </Typography>
                          }
                          className="discount-all-box"
                        />
                      </FormGroup>
                    ))}
                  </div>
                </Box>
                {/* <Box style={{ display: 'flex', flexDirection: "column", gap: "0.75rem" }}>
                                    <Typography className='createDiscount_table_head2' >{t("createDiscount.select_zones")} </Typography>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', }}>
                                        {checkboxes2.map(each => (
                                            <FormGroup sx={{ width: "16%", }} key={each.name}>

                                                <FormControlLabel
                                                    key={each.name} // Ensure each checkbox has a unique key
                                                    control={
                                                        <Checkbox
                                                            name={each.name}
                                                            checked={each.isChecked}
                                                            onChange={handleCheckboxChange2}
                                                            color='warning'
                                                        />
                                                    }
                                                    label={<Typography className='discount-check-text'>{each.name}</Typography>}
                                                    className='discount-all-box'
                                                />
                                            </FormGroup>
                                        ))}



                                    </div>
                                </Box> */}
                <Box
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "0.75rem",
                  }}
                >
                  <Typography className="createDiscount_table_head2">
                    {t("createDiscount.payment_type")}
                  </Typography>
                  <div
                    style={{
                      display: "flex",
                      gap: "28px",
                      alignItems: "center",
                    }}
                  >
                    {checkboxes3.map((each) => (
                      <FormGroup sx={{ width: "16%" }} key={each.name}>
                        <FormControlLabel
                          key={each.name} // Ensure each checkbox has a unique key
                          control={
                            <Checkbox
                              name={each.name}
                              checked={each.isChecked}
                              onChange={handleCheckboxChange3}
                              color="warning"
                            />
                          }
                          label={
                            <Typography className="discount-check-text">
                              {each.name}
                            </Typography>
                          }
                          className="discount-all-box"
                        />
                      </FormGroup>
                    ))}
                  </div>
                </Box>

                <Typography className="createDiscount_table_head2">
                  {t("createDiscount.discount_selection")}
                </Typography>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="options"
                    name="options"
                    value={selectedValue}
                    onChange={handleRadioChange}
                    style={{
                      display: "flex",
                      flexDirection: "row", // Display in a row

                      alignItems: "center",
                    }}
                  >
                    <FormControlLabel
                      value="SINGLE_USE"
                      control={<Radio color="warning" />}
                      label={
                        <Typography className="createDiscount_check_text">
                          {t("createDiscount.singleUse")}
                        </Typography>
                      }
                      className="discount_checkBox"
                    />
                    <FormControlLabel
                      value="MULTIPLE_USE"
                      control={<Radio color="warning" />}
                      label={
                        <Typography className="createDiscount_check_text">
                          {t("createDiscount.multiple_use")}
                        </Typography>
                      }
                      className="discount_checkBox"
                    />
                    <FormControlLabel
                      value="FIRST_TIME_USE"
                      control={<Radio color="warning" />}
                      label={
                        <Typography className="createDiscount_check_text">
                          {t("createDiscount.first_time")}
                        </Typography>
                      }
                      className="discount_checkBox"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </Paper>
          </Box>
        </div>
      </form>
      <ItemErrorModal error={error} handleError={handleError} />
      <ErrorModal
        errorModal={errorModal}
        statusCode={categoryStatusCode}
        handleErrorModal={handleErrorModal}
      />
    </div>
  );
};

export default CreateDiscountBuy1Get;
