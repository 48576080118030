// import "./App.css";
// import "primereact/resources/themes/lara-light-indigo/theme.css";
// import "primereact/resources/primereact.min.css";
// import "primeicons/primeicons.css";
// import { Route, Routes, useLocation } from "react-router";
// import CreateUser from "./pages/userManagement/createUser.tsx";
// import EditZone from "./pages/product/editZone.tsx";
// import Dashboard from "./pages/dashboard/dashboard.jsx";
// import { useState, useEffect } from "react";
// import UserList from "./pages/userManagement/userList.tsx";
// // import AssignOptionsToProducts from './pages/options/assignOptionsToProducts.tsx';
// import AssignOptionsToProduct from "./pages/product/assignOptionsToproduct.tsx";
// import CustomerGroups from "./pages/customerGroups/customerGroups.jsx";
// import CreateGroup from "./pages/customerGroups/createGroup.jsx";
// import EditCustomerGroups from "./pages/customerGroups/editCustomerGroup.jsx";
// import Customers from "./pages/customers/customers.jsx";
// import CreateCustomer from "./pages/customers/createCustomer.jsx";
// import ImportCustomers from "./pages/customers/importCustomers.jsx";
// import CustomerProfile from "./pages/customers/customerProfile.jsx";
// import EditCustomer from "./pages/customers/editCustomer.jsx";
// import AddAddress from "./pages/customers/addAddress.jsx";
// import EditAddress from "./pages/customers/editAddress.jsx";
// import LocationList from "./pages/locations/locationsList.tsx";
// import CreateLocation from "./pages/locations/createLocation.jsx";
// import AboutMyBusiness from "./pages/aboutBusiness/aboutMyBusiness.tsx";
// import CreateOptions from "./pages/options/createOptions.tsx";
// import SalesTax from "./pages/accountSettings/salesTax.tsx";
// import CreateSalesTax from "./pages/accountSettings/createSalesTax.tsx";
// import Rounding from "./pages/accountSettings/rounding.tsx";
// import ServiceCharges from "./pages/accountSettings/serviceCharges.tsx";
// import CreateServiceCharge from "./pages/accountSettings/createServiceCharge.tsx";
// import AdvanceBooking from "./pages/accountSettings/advance_booking.tsx";
// import CreateAdvanceBooking from "./pages/accountSettings/createAdvanceBooking.tsx";
// import ManageTips from "./pages/accountSettings/manageTips.tsx";
// import ManagingOrders from "./pages/accountSettings/managingOrders.tsx";
// import OpenItems from "./pages/accountSettings/openItems.tsx";
// import LoyalityPoints from "./pages/accountSettings/loyalityPoints.jsx";
// import RefundCancel from "./pages/accountSettings/refunds&cancel.tsx";
// import ColorDiagram from "./pages/accountSettings/colorPicker.jsx";
// import CreateDiscountExactQty from "./pages/discount/createDiscountExactQty.tsx";
// import CreateDiscountBuy1Get from "./pages/discount/createDiscountBuy1Get.tsx";
// import CreateDiscount from "./pages/discount/createDiscount.tsx";
// import DiscountSelfMade from "./pages/discount/discountSelfListing.tsx";
// import DiscountPreMade from "./pages/discount/discountPreMadeDiscount.tsx";
// import DiscountReason from "./pages/discount/discountReason.tsx";
// import CustomizedSwitches from "./pages/accountSettings/antSwitch.jsx";
// import Receipts from "./pages/accountSettings/reciepts.jsx";
// import UserRole from "./pages/userManagement/userRole.tsx";
// import CreateUserRole from "./pages/userManagement/createUserRole.tsx";
// import UserAccess from "./pages/userManagement/userAccess.tsx";
// import UserRequests from "./pages/userManagement/userRequests.tsx";
// import InventoryLists from "./pages/inventory/inventoryLists.tsx";
// import EditInventory from "./pages/inventory/editInventory.tsx";
// import DriverList from "./pages/deliveryListing/driverList.tsx";
// import StockAdjustments from "./pages/inventory/stockAdjustments.tsx";
// import InventoryHistory from "./pages/inventory/inventoryHistory.tsx";
// import StockAdjustmentReasons from "./pages/inventory/stockAdjustmentReasons.tsx";
// import TemporaryDrawer from "./pages/leftMenuBar/drawer.tsx";
// import DriverCommission from "./pages/deliveryListing/driverCommission.tsx";
// import ProductTable from "./pages/options/productTable.tsx";
// import DriverPettyCash from "./pages/deliveryListing/driverPettyCash.tsx";
// import Options from "./pages/options/optionsList.tsx";
// import DeliveryList from "./pages/deliveryListing/deliveryList.tsx";
// import DeliveryZone from "./pages/deliveryListing/deliveryZone.tsx";
// import CategoryListing from "./pages/category/categoryListing.tsx";
// import AreaWise from "./pages/deliveryListing/areaWise.tsx";
// import BulkProduct from "./pages/options/BulkProducts.tsx";
// import DistanceOrZoneWise from "./pages/deliveryListing/distanceOrZoneWise.tsx";
// import MenuListing from "./pages/menu/menuListing.tsx";
// import MenuSetup from "./pages/menu/menuSetup.tsx";
// import DeviceSetUp from "./pages/deviceSetUp/deviceSetUp.tsx";
// import DeviceListing from "./pages/deviceSetUp/deviceListing.tsx";
// import KdsSetupCreate from "./pages/deviceSetUp/kdsSetupCreate.tsx";
// import KdsEditAssignedItems from "./pages/deviceSetUp/kdsEditAssignedItems.tsx";
// import MenuHistory from "./pages/menu/menuHistory.tsx";
// import BundleListing from "./pages/bundle/bundleListing.tsx";
// import CreateBundle from "./pages/bundle/createBundle.tsx";
// import BundleDragDrop from "./pages/bundle/bundleDragDrop.tsx";
// import KdsSettings from "./pages/deviceSetUp/kdsSettings.tsx";
// import PosSettings from "./pages/deviceSetUp/posSettings.tsx";
// import Profile from "./pages/profile/profile.tsx";
// import CartCrossSellList from "./pages/suggestiveSelling/cartCrossSellList.tsx";
// import CartCrossSellingCreate from "./pages/suggestiveSelling/cartCrossSellingCreate.tsx";
// import RecieptBuilder from "./pages/reciepts/recieptBuilder.tsx";
// import ItemUpSelling from "./pages/suggestiveSelling/itemUpSellList.tsx";
// import ItemCrossSelling from "./pages/suggestiveSelling/itemCrossSellList.tsx";
// import KitchenCapacityQuotingTime from "./pages/deviceSetUp/kitchenCapacityQuotingTime.tsx";
// import SignIn from "./pages/loginFolders/signIn.tsx";
// import ItemCrossSellingCreate from "./pages/suggestiveSelling/itemCrossSellingCreate.tsx";
// import ItemUpSellingCreate from "./pages/suggestiveSelling/itemUpSellingCreate.tsx";
// import CategoryMain from "./pages/category/createCategoryMain.tsx";
// import ProductsList from "./pages/category/productList.tsx";
// import Protected from "./pages/protectedRoute/protectedRoute.tsx";
// import CreateAproduct from "./pages/product/createProduct.tsx";
// import CreateLocationByHari from "./pages/locations/createLocationByHari.tsx";
// import { Outlet } from "react-router-dom";
// import Header from "./pages/header/header.tsx";
// import HeaderTop from "./pages/header/headerTop.tsx";
// import TestingMaps from "./pages/locations/testingMaps.tsx";
// import { Box } from "@mui/material";
// import CourseListing from "./pages/course/courseListing.tsx";
// import ReportListing from "./pages/ReportiongGroup/reportGroupListing.tsx";
// import ImageDrawer from "./pages/menu/image/imageDrawer.tsx";
// import ErrorModal from "./pages/errorHandling/errorModal.tsx";
// import Image from "./pages/menu/image/image .tsx";
// import BulkProduct2 from "./pages/options/bulkNewTable.tsx";
// import CreateLocationChangedFormat from "./pages/locations/createLocationChangedFormat.tsx";
// import WeeklyStatus from "./pages/locations/weekklyStatus.tsx";
// import EposDiscount from "./pages/discount/eposDiscount.tsx";
// import DiscountOfferHistory from "./pages/discount/discountOfferHistory.tsx";
// import SideBar from "../src/pages/sideBar/sideBar.tsx";
// const DefaultLayout = ({ children }) => (
//   <Box
//     style={{
//       display: "grid",
//       width: "100%",
//       gridTemplateColumns: "6.4% 93.6%",
//     }}
//   >
//     <SideBar />
//     <div>
//       <Box sx={{}}>
//         <HeaderTop />
//       </Box>

//       {children}
//     </div>
//   </Box>
// );

// function App() {
//   const [shouldRenderApp, setShouldRenderApp] = useState(
//     window.innerWidth >= 900
//   );

//   useEffect(() => {
//     const handleResize = () => {
//       setShouldRenderApp(window.innerWidth >= 600);
//     };

//     // Initial setup and event listener for window resize
//     handleResize();
//     window.addEventListener("resize", handleResize);

//     // Cleanup the event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   const location = useLocation();
//   useEffect(() => {
//     console.log("Location changed!", location.pathname);
//   }, [location]);

//   const urlSearchString = window.location.search;
//   console.log(urlSearchString);
//   const params = new URLSearchParams(urlSearchString);
//   const businessId = params.get("business_id");
//   // const businessId=params.get("id")

//   console.log("business Id:", businessId);

//   if (businessId !== null) {
//     sessionStorage.setItem("businessId", businessId);
//   }

//   return (
//     <>
//       {shouldRenderApp ? (
//         <Box>
//           {/* <BrowserRouter> */}
//           <Routes>
//             <Route strict exact path="/sign-in" Component={SignIn} />

//             <Route
//               path="/"
//               element={
//                 <DefaultLayout>
//                   <Outlet />
//                 </DefaultLayout>
//               }
//             >
//               <Route
//                 index
//                 path="/"
//                 element={
//                   <Protected>
//                     <Dashboard />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="user/list"
//                 element={
//                   <Protected>
//                     <UserList />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="/editZone"
//                 element={
//                   <>
//                     <EditZone />
//                   </>
//                 }
//               />
//               <Route
//                 path="create/user"
//                 element={
//                   <Protected>
//                     <CreateUser />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:editId/create/user"
//                 element={
//                   <Protected>
//                     <CreateUser />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/user/role"
//                 element={
//                   <Protected>
//                     <UserRole />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/create/role"
//                 element={
//                   <Protected>
//                     <CreateUserRole />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path=":editId/create/role"
//                 element={
//                   <Protected>
//                     <CreateUserRole />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/user/access"
//                 element={
//                   <Protected>
//                     <UserAccess />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/user/requests"
//                 element={
//                   <Protected>
//                     <UserRequests />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="products/list"
//                 element={
//                   <Protected>
//                     <ProductsList />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="customer/groups"
//                 element={
//                   <Protected>
//                     <CustomerGroups />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/group"
//                 element={
//                   <Protected>
//                     <CreateGroup />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="edit/group"
//                 element={
//                   <Protected>
//                     <EditCustomerGroups />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="edit/customer"
//                 element={
//                   <Protected>
//                     <EditCustomer />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/customer"
//                 element={
//                   <Protected>
//                     <CreateCustomer />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="customers"
//                 element={
//                   <Protected>
//                     <Customers />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/course/listing"
//                 element={
//                   <>
//                     <CourseListing />
//                   </>
//                 }
//               />
//               <Route
//                 path="/report-group/listing"
//                 element={
//                   <>
//                     <ReportListing />
//                   </>
//                 }
//               />
//               <Route
//                 path="import/customers"
//                 element={
//                   <Protected>
//                     <ImportCustomers />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="customer/profile"
//                 element={
//                   <Protected>
//                     <CustomerProfile />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="add/address"
//                 element={
//                   <Protected>
//                     <AddAddress />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="edit/address"
//                 element={
//                   <Protected>
//                     <EditAddress />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="store/list"
//                 element={
//                   <>
//                     <LocationList />
//                   </>
//                 }
//               />
//               {/* <Route
//                 path="create/store"
//                 element={
//                   <>
//                     <CreateLocation />
//                   </>
//                 }
//               /> */}
//               <Route
//                 path="about/mybusiness"
//                 element={
//                   <Protected>
//                     <AboutMyBusiness />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/option"
//                 element={
//                   <Protected>
//                     <CreateOptions />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/update/option"
//                 element={
//                   <Protected>
//                     <CreateOptions />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/create/store"
//                 element={
//                   <>
//                     <CreateLocationChangedFormat />
//                   </>
//                 }
//               />
//               <Route
//                 path="/:editId/create/store"
//                 element={
//                   <>
//                     <CreateLocationChangedFormat />
//                   </>
//                 }
//               />
//               {/* <Route strict exact path="create/option" Component={CreateOptions}/> */}
//               {/* <Route strict exact path="/:id/update/option" Component={CreateOptions}/> */}
//               {/* <Route strict exact path="options" Component={Options}/> */}
//               <Route
//                 path="options"
//                 element={
//                   <Protected>
//                     <Options />
//                   </Protected>
//                 }
//               />
//               {/* <Route path="/:id/update-option" element={<Protected><EditOptions/></Protected>} /> */}
//               <Route
//                 path="sales/tax"
//                 element={
//                   <Protected>
//                     <SalesTax />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/sales/tax"
//                 element={
//                   <Protected>
//                     <CreateSalesTax />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="/:id/edit/sales/tax"
//                 element={
//                   <Protected>
//                     <CreateSalesTax />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="rounding"
//                 element={
//                   <Protected>
//                     <Rounding />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/location"
//                 element={
//                   <>
//                     <CreateLocationByHari />
//                   </>
//                 }
//               />
//               <Route
//                 path="service/charges"
//                 element={
//                   <Protected>
//                     <ServiceCharges />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/service/charges"
//                 element={
//                   <Protected>
//                     <CreateServiceCharge />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="advance/booking"
//                 element={
//                   <Protected>
//                     <AdvanceBooking />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/advance/booking"
//                 element={
//                   <Protected>
//                     <CreateAdvanceBooking />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="manage/tips"
//                 element={
//                   <Protected>
//                     <ManageTips />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="managing/orders"
//                 element={
//                   <Protected>
//                     <ManagingOrders />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="open/items"
//                 element={
//                   <Protected>
//                     <OpenItems />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="loyality/points"
//                 element={
//                   <Protected>
//                     <LoyalityPoints />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="refund/cancel"
//                 element={
//                   <Protected>
//                     <RefundCancel />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="color"
//                 element={
//                   <Protected>
//                     <ColorDiagram />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/discount"
//                 element={
//                   <Protected>
//                     <CreateDiscount />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="discount/exact/quantity"
//                 element={
//                   <Protected>
//                     <CreateDiscountExactQty />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="create/discounts"
//                 element={
//                   <Protected>
//                     <CreateDiscountBuy1Get />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/edit/discounts"
//                 element={
//                   <Protected>
//                     <CreateDiscountBuy1Get />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="discount/list"
//                 element={
//                   <Protected>
//                     <DiscountSelfMade />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="discount/preMade/list"
//                 element={
//                   <Protected>
//                     <DiscountPreMade />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="discount/reasons"
//                 element={
//                   <Protected>
//                     <DiscountReason />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="discount/value"
//                 element={
//                   <Protected>
//                     <CustomizedSwitches />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="receipts"
//                 element={
//                   <Protected>
//                     <Receipts />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="inventory/lists"
//                 element={
//                   <Protected>
//                     <InventoryLists />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:editId/edit/inventory"
//                 element={
//                   <Protected>
//                     <EditInventory />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="stock/adjustments"
//                 element={
//                   <Protected>
//                     <StockAdjustments />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="inventory/history"
//                 element={
//                   <Protected>
//                     <InventoryHistory />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="stock/adjustment/reasons"
//                 element={
//                   <Protected>
//                     <StockAdjustmentReasons />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="product"
//                 element={
//                   <Protected>
//                     <ProductTable />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="drawer"
//                 element={
//                   <Protected>
//                     <TemporaryDrawer />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="update/product"
//                 element={
//                   <Protected>
//                     <editProduct />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="driver/commission"
//                 element={
//                   <Protected>
//                     <DriverCommission />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="driver/petty/cash"
//                 element={
//                   <Protected>
//                     <DriverPettyCash />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="driver/list"
//                 element={
//                   <Protected>
//                     <DriverList />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="delivery/list"
//                 element={
//                   <Protected>
//                     <DeliveryList />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/delivery/zone"
//                 element={
//                   <Protected>
//                     <DeliveryZone />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="bulk/product2"
//                 element={
//                   <>
//                     <BulkProduct />
//                   </>
//                 }
//               />
//               <Route
//                 path="bulk/product"
//                 element={
//                   <Protected>
//                     <BulkProduct2 />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="Category/listing"
//                 element={
//                   <Protected>
//                     <CategoryListing />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create/category"
//                 element={
//                   <Protected>
//                     <CategoryMain />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/update/category"
//                 element={
//                   <Protected>
//                     <CategoryMain />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="menu"
//                 element={
//                   <Protected>
//                     <MenuListing />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="menu/setup"
//                 element={
//                   <Protected>
//                     <MenuSetup />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="areaWise"
//                 element={
//                   <Protected>
//                     <AreaWise />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="distance/or/zonewise"
//                 element={
//                   <Protected>
//                     <DistanceOrZoneWise />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="device/setUp"
//                 element={
//                   <Protected>
//                     <DeviceSetUp />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="device/listing"
//                 element={
//                   <Protected>
//                     <DeviceListing />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="kds/setup/create"
//                 element={
//                   <Protected>
//                     <KdsSetupCreate />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="kds/edit/assigned/items"
//                 element={
//                   <Protected>
//                     <KdsEditAssignedItems />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/menu/history"
//                 element={
//                   <Protected>
//                     <MenuHistory />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="bundle-listing"
//                 element={
//                   <Protected>
//                     <BundleListing />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="create-bundle"
//                 element={
//                   <Protected>
//                     <CreateBundle />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:id/update-bundle"
//                 element={
//                   <Protected>
//                     <CreateBundle />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="bundle"
//                 element={
//                   <Protected>
//                     <BundleDragDrop />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="kds/settings"
//                 element={
//                   <Protected>
//                     <KdsSettings />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="pos/settings"
//                 element={
//                   <Protected>
//                     <PosSettings />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="user/profile"
//                 element={
//                   <Protected>
//                     <Profile />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="cart/cross/sell/list"
//                 element={
//                   <Protected>
//                     <CartCrossSellList />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="item/cross/sel/list"
//                 element={
//                   <Protected>
//                     <ItemCrossSelling />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="item/up/sell/list"
//                 element={
//                   <Protected>
//                     <ItemUpSelling />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="cart/cross/selling/create"
//                 element={
//                   <Protected>
//                     <CartCrossSellingCreate />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="reciept/builder"
//                 element={
//                   <Protected>
//                     <RecieptBuilder />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="item/cross/selling/create"
//                 element={
//                   <Protected>
//                     <ItemCrossSellingCreate />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="item/up/selling/create"
//                 element={
//                   <Protected>
//                     <ItemUpSellingCreate />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="kitchen"
//                 element={
//                   <Protected>
//                     <KitchenCapacityQuotingTime />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="assign/options/to/product"
//                 element={
//                   <Protected>
//                     <AssignOptionsToProduct />
//                   </Protected>
//                 }
//               />
//               {/* <Route path="/options/table/grid" element={<Protected><OptionsGridTable/></Protected>} /> */}
//               <Route
//                 path="create/product"
//                 element={
//                   <Protected>
//                     <CreateAproduct />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/:product/update/product"
//                 element={
//                   <Protected>
//                     <CreateAproduct />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="/maps"
//                 element={
//                   <>
//                     <TestingMaps />
//                   </>
//                 }
//               />
//               <Route
//                 path="/image"
//                 element={
//                   <Protected>
//                     <ImageDrawer />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/error"
//                 element={
//                   <Protected>
//                     <ErrorModal />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/image-library"
//                 element={
//                   <Protected>
//                     <Image />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/week"
//                 element={
//                   <Protected>
//                     <WeeklyStatus />
//                   </Protected>
//                 }
//               />
//               <Route
//                 path="/offer/History"
//                 element={
//                   <Protected>
//                     <DiscountOfferHistory />
//                   </Protected>
//                 }
//               />

//               <Route
//                 path="/epos/discount"
//                 element={
//                   <>
//                     <EposDiscount />
//                   </>
//                 }
//               />
//             </Route>
//           </Routes>
//           {/* <UnsavedChangesPopup/> */}
//           {/* </BrowserRouter> */}
//         </Box>
//       ) : (
//         <h1 style={{ color: "red" }}>the Ui not available below 900px</h1>
//       )}
//     </>
//   );
// }

// export default App;

import "./App.css";
import { Routes, Route, BrowserRouter } from "react-router-dom";
import routes from "../src/config/routes";
import SignIn from "../src/pages/user-authentication/SignIn";
import Protected from "./pages/protectedRoute/protectedRoute";
import SignUp from "./pages/user-authentication/SignUp";
import BusinessOnboarding from "./pages/businessOnboarding";
import About from "./pages/user-authentication/compneyInfo/termsAndConditions/about";
import Support from "./pages/user-authentication/compneyInfo/termsAndConditions/support";
import Privacy from "./pages/user-authentication/compneyInfo/termsAndConditions/privacy";
import Terms from "./pages/user-authentication/compneyInfo/termsAndConditions/terms";
import React from "react";
import NotFoundError from "./graphiqlErrors/404Error";
import BadRequestError from "./graphiqlErrors/400Error";
import ServiceUnavaible from "./graphiqlErrors/503Error";
import InternalServerError from "./graphiqlErrors/500Error";
import PermissionsDenied from "./graphiqlErrors/403Error";

function App() {
  const urlSearchString = window.location.search;
  const params = new URLSearchParams(urlSearchString);
  const businessId = params.get("business_id");
  // const businessId=params.get("id")




  if (businessId !== null) {
    sessionStorage.setItem("businessId", businessId);
  }
  return (
    <div className="App">

      <Routes>
      <Route path="/businessOnboarding" element={<BusinessOnboarding />} />
        <Route path="/support" element={<Support />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/about" element={<About />} />
        <Route path="/privacy" element={<Privacy />} />
        {routes?.map((route: any, index: any) => {
          const { layout: Layout } = route;
          return (
            <Route element={<Layout />} key={`${route.layout}${index}`}>

              {route.routes.map((newRoute: any) => {
                const { name, path, component: Component } = newRoute;
                return (
                  <Route
                    key={`${newRoute.path}${index}`}
                    path={path}
                    element={
                      <Protected><Component name={name} {...route.props} /></Protected>

                    }
                  />
                );
              })}
            </Route>
          );
        })}
        {/* <Route path="/bad/request" element={<BadRequestError />} />
        <Route path="/service-unavailable" element={<ServiceUnavaible />} />
        <Route path="/internal-server-error" element={<InternalServerError />} />
        <Route path="/permission-denied" element={<PermissionsDenied />} />
        <Route path="*" element={<NotFoundError />} /> */}
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/businessOnboarding" element={<BusinessOnboarding />} />
  




      </Routes>

    </div>
  );
}

export default App;



