import { Box, Button, IconButton, Typography } from "@mui/material"
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import './menuHistory.css'
import { DataGrid, GridColDef, } from '@mui/x-data-grid';
import { useTranslation } from "react-i18next";
import { MENU_HISTORY } from "../../graphicalQl/usequery/menuQuery";
import { useMutation, useQuery } from "@apollo/client";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { USE_THIS } from "../../graphicalQl/mutation/menuMutation";
import Publish from "./publish";
import moment from "moment";
import { localeToFormat } from "../../utils/dateFormat";

const MenuHistory = () => {
    const { id } = useParams()
    const { t } = useTranslation();

    const navigate = useNavigate()

    const handleNavigate = () => {
        navigate("/menu/menu-list")
    }
    const { data: menuData } = useQuery(MENU_HISTORY, { context: { clientName: "categoryClient" }, variables: { menuId: id && +id }, fetchPolicy: "network-only" })

    const [getValue] = useMutation(USE_THIS, { context: { clientName: "categoryClient" }, refetchQueries: [MENU_HISTORY] });

    const handleUseThis = (id: number) => {

        getValue({ variables: { menuId: +id } })
            .then((result) => {
                // Handle the result if needed
            })
            .catch((error) => {
                // Handle the error if needed
                console.error('Mutation error:', error);
            });
    };
    const userLang = navigator.language;


    const columns: GridColDef[] = [
        {
            field: 'variations',
            //@ts-ignore
            headerName: t("menu.menuHistory.version"),
            flex: 1,
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
            disableColumnMenu: true,


        },

        {
            field: 'note',
            //@ts-ignore
            headerName: t("menu.menuHistory.note"),
            flex: 1,
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
            disableColumnMenu: true,

        },
        {
            field: 'publishOn',
            //@ts-ignore
            headerName: 'Publish On',
            flex: 1,
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    params.value ? moment(params.row.updatedOn).format(`${localeToFormat[userLang]}`) : "N/A"
                )
                // <Typography className="tableCellInCategory">{params.value ? moment(params.value).format("MM-DD-YYYY HH:mm") : "N/A"}</Typography>

            }



        },
        {
            field: 'date',
            //@ts-ignore
            headerName: t("menu.menuHistory.date"),
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
            flex: 1,
            disableColumnMenu: true,
            renderCell: (params) => {
                // Convert the string to a Date object
                const dateObject = new Date(params.row.date);

                // Check if the conversion was successful and dateObject is a valid Date
                if (!isNaN(dateObject.getTime())) {
                    // Format the Date object to "YYYY-MM-DD"
                    const formattedDate = `${dateObject.getFullYear()}-${String(dateObject.getMonth() + 1).padStart(2, '0')}-${String(dateObject.getDate()).padStart(2, '0')}`;
                    return moment(params.row.date).format(`${localeToFormat[userLang]}`);;
                } else {
                    // Handle the case where the conversion failed

                    return 'Invalid Date';
                }
            },

        },
        {
            field: 'employee',
            //@ts-ignore
            headerName: t("menu.menuHistory.employee"),

            sortable: false,
            disableColumnMenu: true,

            flex: 1,
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
        },
        {
            field: '.',
            //@ts-ignore
            headerName: t("menu.menuHistory.useThis"),
            sortable: false,
            flex: 1,
            cellClassName: "tableCellInCategory",
            headerClassName: "table-column-header ",
            disableColumnMenu: true,
            renderCell: (params) => {
                return (
                    <div>
                        {params.row.isLatest ? (
                            <Typography sx={{ color: "#333", textTransform: "none", fontSize: "0.875rem", fontWeight: "400" }}>Currently using</Typography>
                        ) : (
                            <Button onClick={() => handleUseThis(params.row.id)} sx={{ color: "#333", textTransform: "none", fontSize: "0.875rem", fontWeight: "400" }}>use this</Button>
                        )}
                    </div>
                );
            }

        },
    ];

    const [rows, setRows] = useState([])
    // const rows = [
    //     { id: 1, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 2, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 3, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 4, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 5, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 6, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 7, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 8, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    //     { id: 9, variations: 3, note: 'Category price changes', date: "12-05-23, 11:50", employee: "Mirai Kuriyamasa", useThis: "Currently Using" },
    // ];
    const getCellClass = () => {
        return 'tableCellInCategory'
    }

    const getRowClass = () => {
        return 'menuHistory-row'
    }
    useEffect(() => {
        if (menuData && menuData.menuHistory) {
            const list = menuData.menuHistory.edges.map((each: any) => ({
                id: each.node.menuId,
                variations: each.node.version || "null",
                note: each.node.note || "null",
                date: each.node.createdOn || "null",
                employee: each.node.updatedBy || "null",
                isLatest: each.node.isLatest || null,
                publishOn: each.node.publishOn
            }))
            setRows(list)
        }
    }, [menuData])
    const handleRowClass = () => {
        return (
            "menuListingRow"
        )
    }

    const handleCellClass = () => {
        return (
            "menuListigCell"
        )
    }
    return (
        <Box sx={{ padding: "1%" }}>
            <Box className="menuHistory">
                <IconButton onClick={handleNavigate} className="exit-container">
                    <ArrowBackIcon className="exitIcon" />
                </IconButton>
                <Typography className="menuHistory-text">{t("menu.menuHistory.menuHistory")}</Typography>
            </Box>
            <Box sx={{ height: 400, width: '100%' }}>
                <DataGrid
                    rows={rows}
                    getRowClassName={handleRowClass}
                    getCellClassName={handleCellClass}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    // getCellClassName={getCellClass}
                    // getRowClassName={getRowClass}
                    disableRowSelectionOnClick
                />
            </Box>
        </Box>
    )
}
export default MenuHistory