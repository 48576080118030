import React from "react";
import AddOnsSection from "../../common/addOnsSection";
import { Box, Link } from "@mui/material";
import ArrowBackSharpIcon from "@mui/icons-material/ArrowBackSharp";
import { Link as RouterLink } from "react-router-dom";

function Details() {
  return (
    <>
      <Box
        sx={{
          position: "relative",
        }}
      >
        <Link
          sx={{
            borderRadius: "50px",
            backgroundColor: "#EEEEEE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: { lg: "44px", xs: "32px" },
            height: { lg: "44px", xs: "32px" },
            position: "absolute",
            top: { lg: "20px", xs: "6px" },
            left: { lg: "20px", xs: "6px" },
            marginLeft: { sm: "20px", xs: "6px" },
          }}
          component={RouterLink}
          to="#"
        >
          <ArrowBackSharpIcon
            sx={{ width: "24px", height: "24px", color: "#707070" }}
          ></ArrowBackSharpIcon>
        </Link>
        <AddOnsSection setPayment={undefined} />
      </Box>
    </>
  );
}

export default Details;
