import React, { useState } from "react";
import cartImg from "../../../assets/icons/cart.png";
import calendarImg from "../../../assets/icons/calendar-today.png";
import cartCheckedImg from "../../../assets/icons/fi-rr-shopping-cart-check.png";
import creditCardImg from "../../../assets/icons/credit-card.png";
import { Box, Button, Grid } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import Steps from "../../components/Steps";
import LeftDetails from "./LeftDetails";
import RightDetails from "./RightDetails";
import { useNavigate } from "react-router-dom";
import "./styles.css";
import { localeToFormat } from "../../../utils/dateFormat";
import { useTranslation } from "react-i18next";
const moment = require("moment");
interface Props {
  detailData: any;
  storeData: any;
  handleOpen: () => void;
  addressData: any;
  setOpen: any;
  deliveryDetails: any;
  activeSteps: any;
}

const OrdersSummary = (props: Props) => {
  const currencySymbol = sessionStorage.getItem("currencySymbol");
  const { t } = useTranslation();
  const userLang = navigator.language;
  const disableList = ["Cancelled", "Void", "Hold", "Rejected"];
  const {
    detailData,
    storeData,
    handleOpen,
    addressData,
    setOpen,
    deliveryDetails = {},
    activeSteps,
  } = props;
  const customerDetail = detailData?.customerDetails
    ? JSON.parse(detailData?.customerDetails)
    : null;
  const cart = detailData?.cart ? JSON.parse(detailData?.cart?.cartItems) : [];

  const itemTotal = detailData?.cart?.itemTotal;
  const loyaltyAmount = detailData?.cart?.loyaltyAmount;
  const orderStatus = detailData?.orderStatus;

  const orderDate = detailData?.createdOn;
  //  moment(detailData?.createdOn).format("hh:mm a; MM-DD-YYYY");

  const navigate = useNavigate();
  return (
    <Box className="container order-list-container ">
      <Box
        display={"flex"}
        justifyContent={"space-between"}
        alignItems={"center"}
        className="bg-white"
        sx={{ marginBottom: "20px", padding: "8px 20px", width: "60%" }}
      >
        <Box
          className="arrow-back"
          onClick={() => navigate("/orders/orders-list")}
        >
          <ArrowBack />
        </Box>
        <Box className="text-title-bold">{t("orders.order_summary")}</Box>
        {/* <Box className="save-btn-dsbl">Save</Box> */}
      </Box>
      <Box className="css-pa">
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          flexWrap={"wrap"}
        >
          <Box display={"flex"} alignItems={"center"}>
            <Box
              mr={"10px"}
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <img src={cartImg} />
            </Box>
            <Box className="order-name">
              {t("orders.order")} #{detailData?.orderNo}{" "}
              {customerDetail ? `-${customerDetail.customer_name}` : ""}
            </Box>
          </Box>
          <Button
            variant="contained"
            disabled={disableList.includes(orderStatus)}
            onClick={() => setOpen(true)}
            sx={{ textTransform: "none" }}
            className={
              disableList.includes(orderStatus)
                ? "change-color-btn-disable"
                : "change-color-btn"
            }
          >
            {t("orders.change_status")}
          </Button>
        </Box>
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          className="order-sum-hd"
          flexWrap={"wrap"}
          gap={"20px"}
        >
          <Box display={"flex"} gap={"12px"}>
            <Box>
              <img src={calendarImg} />
            </Box>
            <Box>
              <Box className="text-gray fs-14">{t("orders.order_date")}</Box>
              <Box className="fs-18" fontWeight={"bold"}>
                {moment(orderDate).format(
                  `${localeToFormat[userLang]} hh:mm a`
                )}
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} gap={"12px"}>
            <Box>
              <img src={creditCardImg} />
            </Box>
            <Box>
              <Box className="text-gray fs-14">{t("orders.amount")}</Box>
              <Box className="fs-18" fontWeight={"bold"}>
                {currencySymbol} {detailData?.payableAmount}
              </Box>
            </Box>
          </Box>
          <Box display={"flex"} gap={"12px"}>
            <Box>
              <img src={cartCheckedImg} />
            </Box>
            <Box>
              <Box className="text-gray fs-14">{t("product.products")}</Box>
              <Box className="fs-18" fontWeight={"bold"}>
                {cart?.length}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          className="bg-white"
          sx={{ padding: "12px", marginBottom: "20px" }}
        >
          <Steps
            activeSteps={activeSteps}
            orderStatus={detailData?.orderStatus}
            deliveryStatus={detailData?.deliveryStatus}
          />
        </Box>
        <Grid container>
          <Grid item xs={12} md={8}>
            <LeftDetails
              detailData={detailData}
              customerDetail={customerDetail}
              cart={cart}
              orderDate={orderDate}
              addressData={addressData}
              itemTotal={itemTotal}
              loyaltyAmount={loyaltyAmount}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <RightDetails
              deliveryDetails={deliveryDetails}
              detailData={detailData}
              customerDetail={customerDetail}
              storeData={storeData ? storeData.store : {}}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default OrdersSummary;
