import {
  Box,
  Button,
  IconButton,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DEVICE_LIST_FOR_KDS,
  GET_PAIRED_DEVICE_DETAILS,
} from "../../graphicalQl/usequery/deviceManagementQuery";
import "./kioskSettings.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router";
import { useMutation, useQuery } from "@apollo/client";
import {
  UPDATE_DEVICE_IN_KDS,
  UPDATE_DEVICE_IN_POS,
} from "../../graphicalQl/mutation/deviceMangementMutation";

interface State {
  allowOrder: boolean;
  allowPayments: boolean;
  pairedDevice: string | null;
  storeName: string | null;
  headerDeviceName: string | null;
}

const initialValues: State = {
  allowPayments: false,
  pairedDevice: null,
  allowOrder: false,
  storeName: null,
  headerDeviceName: null,
};

const validationSchemaPart1 = Yup.object({
  pairedDevice: Yup.string().required("Device Name required"),
});

const KioskSettings = () => {
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const navigate = useNavigate();
  const { editId } = useParams();

  const [updateDevice] = useMutation(UPDATE_DEVICE_IN_POS, {
    onCompleted: (data) => {

    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });
  const [updateDeviceName] = useMutation(UPDATE_DEVICE_IN_KDS, {
    onCompleted: (data) => {

    },
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "businessClient" },
    refetchQueries: [DEVICE_LIST_FOR_KDS],
  });
  const {
    data: deviceData,
    loading: deviceLoading,
    error: deviceError,
  } = useQuery(GET_PAIRED_DEVICE_DETAILS, {
    context: { clientName: "businessClient" },
    variables: { deviceId: editId && +editId },
    fetchPolicy: "network-only",
  });

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });

  const handleSaveButton = () => {
    const collectedVariables = {
      deviceId: editId && +editId,
      businessId: +businessId,
      settings: JSON.stringify({
        allow_order_from_kiosk: formik.values.allowOrder,
        allow_payments: formik.values.allowPayments,
      }),
    };
    const filteredIdsFromKds = Object.fromEntries(
      Object.entries(collectedVariables).filter(([_, value]) => value !== null)
    );
    updateDevice({
      variables: filteredIdsFromKds,
    });
    updateDeviceName({
      variables: {
        deviceId: editId && +editId,
        deviceName: formik.values.pairedDevice,
      },
    });
    navigate("/devices/device-list");
  };

  useEffect(() => {
    if (deviceData) {
      const { pairedDevice } = deviceData;
      if (pairedDevice) {
        const { deviceName, store, deviceSettings } = pairedDevice;
        if (deviceSettings) {
          const { allow_order_from_kiosk, allow_payments } =
            JSON.parse(deviceSettings);

          formik.setFieldValue("allowOrder", allow_order_from_kiosk);
          formik.setFieldValue("allowPayments", allow_payments);
        }
        formik.setFieldValue("pairedDevice", deviceName);
        formik.setFieldValue("headerDeviceName", deviceName);
        formik.setFieldValue("storeName", store && store.storeName);
      }
    }
  }, [deviceData]);

  const [editToggle, setEditToggle] = useState<boolean>(false);
  const handleEditIcon = () => {
    setEditToggle(true);
  };

  const handleBackArrowButton = () => {
    navigate("/devices/device-list");
  };
  return (
    <Box sx={{ padding: "2%" }}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "1%",
          }}
        >
          <IconButton
            onClick={handleBackArrowButton}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <ArrowBackIcon />
          </IconButton>
          <Typography className="kiosk-settings-title">
            {formik.values.headerDeviceName} Settings
          </Typography>
          <Button
            type="submit"
            sx={{ textTransform: "none" }}
            color="warning"
            variant="contained"
          >
            Save
          </Button>
        </Box>
        <Typography className="kiosk-settings-kingfield-branch">
          {formik.values.storeName}
        </Typography>
        <Paper
          sx={{
            borderLeft: "2px solid #AE9BE2",
            width: "100%",
            marginTop: "1%",
            padding: "1%",
          }}
          elevation={1}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "40%",
            }}
          >
            <Typography className="kiosk-settings-device-name">
              Device Name
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: editToggle ? "60%" : "20%",
              }}
            >
              {editToggle ? (
                <Box sx={{ width: "100%" }}>
                  <TextField
                    sx={{ width: "100%" }}
                    name="pairedDevice"
                    value={formik.values.pairedDevice}
                    onChange={(e) => formik.handleChange(e)}
                    onBlur={formik.handleBlur}
                    id="outlined-basic"
                    variant="outlined"
                  />
                </Box>
              ) : (
                <Typography>{formik.values.pairedDevice}</Typography>
              )}
              {!editToggle && (
                <IconButton onClick={handleEditIcon}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "40%",
              marginTop: "2%",
            }}
          >
            <Typography>Allow Ordering from Kiosk system</Typography>
            <Switch
              name="allowOrder"
              checked={formik.values.allowOrder}
              onChange={(e) => formik.handleChange(e)}
              color="warning"
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "40%",
              marginTop: "2%",
            }}
          >
            <Typography>Allow Payments directly from Kiosk</Typography>
            <Switch
              name="allowPayments"
              checked={formik.values.allowPayments}
              onChange={(e) => formik.handleChange(e)}
              color="warning"
            />
          </Box>
        </Paper>
      </form>
    </Box>
  );
};

export default KioskSettings;
