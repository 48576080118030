import * as React from "react";
import Modal from "@mui/joy/Modal";
import { useState } from "react";
import NotificationMoal from "./notificationsModal"; // Adjust the import path as per your project structure
import AllNotificationsModal from "./allNotificationsModal"; // Adjust the import path as per your project structure

interface Props {
  sideBarNotification?: any;
  setSideBarNotification?: any;
  setOpen: (open: boolean) => void;
  open: boolean;
}

const PopupModal = ({
  sideBarNotification,
  setSideBarNotification,
  setOpen,
  open,
}: Props) => {
  const [openAll, setOpenAll] = useState(false);

  return (
    <>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={open}
        onClose={(event, reason) => {
          setOpen(false);
          setSideBarNotification(false);
        }}
        sx={{
          display: "flex",
          
          alignItems: `${sideBarNotification ? "center" : "start"}`,
          justifyContent: `${sideBarNotification ? "center" : "end"}`,
          position: "fixed",
          top: "80px",
          right: { xs: "20px", sm: "90px" },
          left: { xs: "20px", sm: "0px" },
          opacity: 1,
          marginTop: "14px",
        }}
      >
        <NotificationMoal
          ref={null}
          setOpen={setOpen}
          setOpenAll={setOpenAll}
        />
      </Modal>

      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={openAll}
        onClose={(event, reason) => {
          setOpenAll(false);
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          position: "fixed",
          top: "-14px",
          right: "0",
          opacity: 1,
        }}
      >
        <AllNotificationsModal ref={null} setOpenAll={setOpenAll} />
      </Modal>
    </>
  );
};

export default PopupModal;
