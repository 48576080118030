import { useEffect, useState } from "react";
import React, { useRef } from "react";

import { Box, Button, TextField, Typography } from "@mui/material";
import "./profile.css";
import ImageDrawer from "../menu/image/imageDrawer.tsx";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { useFormik } from "formik";
import { GET_PROFILE_DATA } from "../../graphicalQl/usequery/profileQuery.tsx";
import * as yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { UPDATE_CUSTOMER } from "../../graphicalQl/mutation/profileMutation.tsx";
import { toast } from "react-toastify";
import { useNavigate } from "react-router";
import { GET_UPDATE_USER_DETAILS } from "../../graphicalQl/usequery/userProfileUseQuery.tsx";
import { useTranslation } from "react-i18next";

// interface UserData {
//   firstName: string;
//   lastName: string;
//   email: string;

//   mobile: string;
// }

// const items = ["Epos (Digital and QR Menu)", "Admin App", "Restaurant Website/Mobile App"];

const SideProfile2 = () => {
  const validationSchema = yup.object({
    email: yup
      .string()
      .email("Enter a valid email")
      .required("Please enter the email"),
    firstName: yup.string().required("Please enter the first name"),
    lastName: yup.string().required("Please enter the last name"),
    mobile: yup.string().required("Please enter a mobile number"),
  });
  const employeeId = sessionStorage.getItem("employeeId");

  const businessId = sessionStorage.getItem("businessId") as string;
  const { data } = useQuery(GET_PROFILE_DATA, {
    context: { clientName: "userClient" },
    variables: {
      employeeId: employeeId && +employeeId,
    },
  });

  const [role, setRole] = useState<string | null>(null);
  const [imageFromApi, setImageFromApi] = useState<string | null>(null);
  const [colorCodeFromApi, setColorCodeFromApi] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    if (data && data.employee) {
      const list2 = {
        firstName: data.employee.firstName,

        lastName: data.employee.lastName,
        email: data.employee.email,
        mobile: data.employee.mobile,
      };

      formik.setValues(list2);
      setImageFromApi(data.employee.image);
      setColorCodeFromApi(data.employee.bgColourCode);
      setRole(data.employee.role && data.employee.role.roleName);
    }
  }, [data]);

  const [updateCustomer] = useMutation(UPDATE_CUSTOMER, {
    context: { clientName: "userClient" },
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    refetchQueries: [GET_UPDATE_USER_DETAILS],
  });

  const initialValues = {
    email: "",
    firstName: "",
    lastName: "",
    mobile: "",
  };

  const formik = useFormik({
    validationSchema,
    initialValues: initialValues,

    onSubmit: (values) => {
      updateCustomer({
        variables: {
          businessId: +businessId,
          employeeId: employeeId && +employeeId,
          firstName: values.firstName,
          lastName: values.lastName,
          email: values.email,
          mobile: values.mobile,
          image: uploadImg,
          bgColourCode: uploadColor,
        },
      });
    },
  });

  const [uploadImg, setUploadImg] = useState<string | null>(null);
  const [uploadColor, setUploadColor] = useState<string | null>(null);

  const handleImage = (value: string | null) => {
    setUploadImg(value);
  };
  const handleColor = (value: string | null) => {
    setUploadColor(value);
  };
  const imageDrawerRef = useRef<any>();
  const navigate = useNavigate();

  const handleCancel = () => {
    // formik.setValues(initialValues)
    // setUploadImg(null)
    // setUploadColor(null)
    // if (imageDrawerRef.current) {
    //     imageDrawerRef.current.handleNull();
    // }
    navigate("/");
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <Box sx={{ display: "flex", padding: "1%" }}>
        <Box sx={{ width: "30%" }}>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              gap: "20px",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingBottom: "12px",
            }}
            className="profile-mainCon"
          >
            <Box
              sx={{
                height: "150px",
                width: "150px",
                padding: "2px",
                mt: "12px",
              }}
              className="create-product-img-con"
            >
              <ImageDrawer
                ref={imageDrawerRef}
                handleImage={handleImage}
                handleColor={handleColor}
                colorCodeFromApi={colorCodeFromApi}
                imageFromApi={imageFromApi}
                heightValue={120}
              />
            </Box>

            <Typography className="role-style ">{role}</Typography>
            <Box sx={{ width: "80%" }}>
              <TextField
                label="First Name"
                name="firstName"
                sx={{ width: "100%" }}
                value={formik.values.firstName}
                onChange={(e) => formik.handleChange(e)}
              />
              {formik.errors.firstName && formik.touched.firstName ? (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {formik.errors.firstName}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ width: "80%" }}>
              <TextField
                label="Last Name"
                name="lastName"
                sx={{ width: "100%" }}
                value={formik.values.lastName}
                onChange={(e) => formik.handleChange(e)}
              />
              {formik.errors.lastName && formik.touched.lastName ? (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {formik.errors.lastName}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ width: "80%" }}>
              <TextField
                label="Email Address"
                disabled
                name="email"
                sx={{ width: "100%" }}
                value={formik.values.email}
                onChange={(e) => formik.handleChange(e)}
              />
              {/* <TextField label="Phone Number" sx={{ width: "80%" }} /> */}
              {formik.errors.email && formik.touched.email ? (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {formik.errors.email}
                </Typography>
              ) : null}
            </Box>
            <Box sx={{ width: "80%" }}>
              <MuiPhoneNumber
                defaultCountry={"in"}
                countryCodeEditable={true}
                id="mobile"
                name="mobile"
                label="Phone"
                variant="outlined"
                placeholder="Phone"
                disabled
                InputLabelProps={{ shrink: true }}
                value={formik.values.mobile}
                onChange={(value: any) => formik.setFieldValue("mobile", value)}
              />
              {formik.errors.mobile && formik.touched.mobile ? (
                <Typography sx={{ fontSize: "12px", color: "red" }}>
                  {formik.errors.mobile}
                </Typography>
              ) : null}
            </Box>
            {/* <FormGroup>
                        <FormControlLabel control={<Switch color="warning" />} label="Use same for billing details" labelPlacement="start" />

                    </FormGroup> */}
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-end",
                gap: "18px",
              }}
            >
              <Button className="profile-button" onClick={handleCancel}>
                Cancel
              </Button>
              <Button className="main-button" type="submit">
                Save
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
export default SideProfile2;
