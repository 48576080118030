import { gql } from "@apollo/client";

export const ACTIVITY_LOG_REPORTS = (
  businessId: number,
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number | null,
  change: string,
  employee: string
) => {
  const employeeParam = employee !== null ? `employeeName: "${employee}"` : "";
  return gql`
  query ActivityLogReports {
    activityLogReports(
        businessId: ${businessId},
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime:"${fromTime}"
        toTime:"${toTime}"
       locationId:${locationId},
       
        ${employeeParam}
      )
    }
  `;
};
