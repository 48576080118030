import { gql } from "@apollo/client";

export const SENT_MOBILE_OTP = gql`
mutation customerOtpGeneration($mobile:String,$email:String,$forRegistration:Boolean){
customerOtpGeneration(
 	mobile:$mobile
	forRegistration:$forRegistration
    email:$email
) {
	authenticate{
	
	
	email
	generatedAt}
	errorMessage
	
}
	
}
`
export const VERIFY_OTP = gql`
mutation customerAuthentication($mobile:String,$email:String,$otp:String){
customerAuthentication(
 	mobile:$mobile
 email:$email
	otp:$otp
	
) {
	success
	errorMessage
	authenticated
	
}
	
}
`

export const CREATE_BUSINESS = gql`
mutation createBusiness($firstName:String,$legalBusinessName:String,
$email:String,$mobile:String,$addressDetails:AddressInput!,
){
  createBusiness(	
			firstName:$firstName			
			legalBusinessName:$legalBusinessName
						email:$email
			mobile:$mobile
			addressDetails:$addressDetails
			
  ) {
   errorMessage
		success
   business{
		
			businessId
			
    }
		
  }
}
`

export const UPDATE_BUSINESS_IN_CREATE = gql`
mutation updateBusiness(
$businessId:ID!
$firstName:String
$legalBusinessName:String
$email:String
$mobile:String
$businessType:String
$addressDetails:AddressInput
$business_category:ID
){
  updateBusiness(	
			businessId:$businessId
			firstName:$firstName
			legalBusinessName:$legalBusinessName
			email:$email
			mobile:$mobile
	businessType:$businessType
			addressDetails:$addressDetails
			business_category:$business_category
  ) {
    errorMessage
		success
    business{
			businessId
			
		}
		
	}
	
}
`

export const GET_OUTLET = gql`
query {
  businessCategories(first:20) {
 edges{
	cursor
	node{
		businessCategoryId
		title
		image
		description
	}
}
    pageInfo{hasNextPage
		startCursor
		endCursor}
		
	}
}
`