import { Checkbox, Sheet } from "@mui/joy";
import { Box, Button, Modal, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../assets/close2.png";
import BackIcon from "../../../../assets/back.png";
import TypeSearch from "../../../common/layouts/SideBar/SearchInput";
import { useMutation, useQuery } from "@apollo/client";
import {
  ADD_CUSTOMER_GROUP,
  DELETE_CUSTOMER_GROUP,
  EDIT_CUSTOMER_GROUP,
} from "../../../../graphicalQl/mutation/customerDataMutation";
import {
  CUSTOMER_GROUP_LIST,
  SEARCH_CUSTOMERS,
} from "../../../../graphicalQl/usequery/customerDataQuery";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  setAddCustomerGroupModal: any;
  addCustomerGroupModal: any;
}

const AddCustomerGroup = (props: Props) => {
  const { setAddCustomerGroupModal, addCustomerGroupModal } = props;

  const { t } = useTranslation();
  const editMode = addCustomerGroupModal.mode === "update" ? true : false;
  // state to store selected customer IDs from the list to be added in a group
  const [selectedCustomers, setSelectedCustomers] = useState(
    editMode ? addCustomerGroupModal?.customerGroupDetails?.customerIds : []
  );

  // There are two views in this single component one for selection of customers and other for input of group name
  // state to handle view for selection of customers and input of customer group name
  const [groupNameState, setGroupNameState] = useState(false);

  const [customerSearchQuery, setCustomerSearchQuery] = useState("");
  const [customers, setCustomers] = useState([]);

  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false);

  // state to handle controlled text input of group name if in edit mode prefill with previous name
  const [groupName, setGroupName] = useState(
    addCustomerGroupModal?.customerGroupDetails?.name || ""
  );
  const onChangeGroupName = (event: any) => {
    setGroupName(event.target.value);
  };

  // query to add customer group mutation
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [errorStatus, setErrorStatus] = useState(false);

  const [
    addCustomerGroup,
    { data: addCustomerGroupData, error: addCustomerGroupError },
  ] = useMutation(ADD_CUSTOMER_GROUP, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.added")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      setErrorStatus(false);
    },
    onError: (error) => {
      if (
        error.message ===
        "Customer Group name should be unique within Business!"
      ) {
        setErrorStatus(true);
      }
    },
  });

  // query to search customers from customer list
  const {
    data: customersData,
    loading,
    error: searchCustomersError,
    refetch: refetchCustomerList,
  } = useQuery(SEARCH_CUSTOMERS, {
    context: { clientName: "customerClient" },
    variables: { businessId: +businessId, search: customerSearchQuery },
  });

  // mutation to delete customer group mutation
  const [
    deleteCustomerGroupMutation,
    { data: deleteCustomerGroupData, error: deleteCustomerGroupError },
  ] = useMutation(DELETE_CUSTOMER_GROUP, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  // mutation to edit customer group details
  const [
    editCustomerGroupMutation,
    { data: editCustomerGroupData, error: editCustomerGroupError },
  ] = useMutation(EDIT_CUSTOMER_GROUP, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  useEffect(() => {
    if (customersData) {
      // parse fetched data and assign it to customerData state variable
      const parsedCustomerData = customersData?.customerList?.edges?.map(
        (each: any) => ({
          id: each.node.uuid,
          customerName: each.node.firstName + " " + each.node.lastName,
          phone: each.node.mobile,
          mail: each.node.email,
          customerId: each.node.customerId,
        })
      );

      setCustomers(parsedCustomerData);
    }
  }, [customersData]);

  useEffect(() => {
    // check for successfull creation of group and do the necessary clean up
    if (addCustomerGroupData?.addCustomerGroup.success) {
      setAddCustomerGroupModal({
        state: false,
        mode: "add",
        customerGroupDetails: {},
      });
    }

    if (deleteCustomerGroupData?.deleteCustomerGroup.success) {
      addCustomerGroupModal?.refetchCustomerGroupList();
      setAddCustomerGroupModal({
        state: false,
        mode: "add",
        customerGroupDetails: {},
      });
    }

    if (editCustomerGroupData?.editCustomerGroup.success) {
      addCustomerGroupModal?.refetchCustomerGroupList();
      setAddCustomerGroupModal({
        state: false,
        mode: "add",
        customerGroupDetails: {},
      });
    }
  }, [addCustomerGroupData, deleteCustomerGroupData, editCustomerGroupData]);

  // handle selection and deselction of customers from the list
  const handleCheckboxChange = (customer: any) => () => {
    const currentIndex = selectedCustomers.indexOf(customer.customerId);
    const updatedCustomerList = [...selectedCustomers];

    if (currentIndex === -1) {
      updatedCustomerList.push(customer.customerId);
    } else {
      updatedCustomerList.splice(currentIndex, 1);
    }

    setSelectedCustomers(updatedCustomerList);
  };

  // call to add customer mutation
  const createCustomerGroup = () => {
    addCustomerGroup({
      variables: {
        businessId: +businessId,
        groupName: groupName.trim(),
        customerGroup: JSON.stringify(selectedCustomers),
      },
      refetchQueries: [CUSTOMER_GROUP_LIST],
    });
  };

  const deleteCustomerGroup = () => {
    deleteCustomerGroupMutation({
      variables: {
        groupIds: JSON.stringify([
          addCustomerGroupModal?.customerGroupDetails?.groupId,
        ]),
        businessId: +businessId,
      },
      refetchQueries: [CUSTOMER_GROUP_LIST],
    });
  };

  const editCustomerGroupDetails = () => {
    editCustomerGroupMutation({
      variables: {
        groupId: addCustomerGroupModal?.customerGroupDetails?.groupId,
        customerGroup: JSON.stringify(selectedCustomers),
        groupName,
      },
    });
  };

  const handleSearch = (searchTerm: string) => {
    setCustomerSearchQuery(searchTerm);
    refetchCustomerList({ search: searchTerm });
  };

  return (
    <>
      <Sheet
        variant="outlined"
        className="shortCutModalBoxShadow"
        sx={{
          width: "720px",
          outline: "none",
          borderRadius: "8px",
          border: "1px solid #EEEEEE",
          padding: "20px 32px",
          boxSizing: "border-box",
        }}
      >
        <Box
          sx={{
            display: "flex",
            marginBottom: "24px",
            borderBottom: "1px solid #D9D9D9",
            alignItems: "center",
            justifyContent: "space-between",
            paddingBottom: "20px",
          }}
        >
          <img
            src={!groupNameState ? CloseIcon : BackIcon}
            alt="close modal"
            onClick={() => {
              !groupNameState
                ? setAddCustomerGroupModal({
                    state: false,
                    mode: "add",
                    customerGroupDetails: {},
                  })
                : setGroupNameState(false);
            }}
          />
          <Typography
            sx={{
              fontFamily: "Poppins",
              fontWeight: "500",
              fontSize: "20px",
              lineHeight: "normal",
              color: "#333333",
            }}
          >
            {!groupNameState
              ? !editMode
                ? t("customer.add_customer_group")
                : t("customer.edit_customer_group")
              : t("customer.name_your_group")}
          </Typography>
          {!groupNameState ? (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              {editMode ? (
                <Button
                  onClick={() => setDeleteConfirmationModal(true)}
                  sx={{
                    height: "44px",
                    display: "flex",
                    justifyContent: "center",
                    padding: "4px 16px",
                    alignItems: "center",
                    gap: "10px",
                    borderRadius: "4px",
                    backgroundColor: "rgba(245, 67, 28, 0.10)",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "16px",
                      lineHeight: "27px",
                      fontWeight: "500",
                      fontFamily: "Poppins",
                      color: "#F5431C",
                      textTransform: "capitalize",
                    }}
                  >
                    {t("customer.delete_group")}
                  </Typography>
                </Button>
              ) : null}
              <Button
                onClick={() => {
                  editMode
                    ? editCustomerGroupDetails()
                    : setGroupNameState(true);
                }}
                disabled={selectedCustomers.length === 0}
                sx={{
                  width: "77px",
                  height: "44px",
                  backgroundColor:
                    selectedCustomers.length === 0 ? "#ABABAB" : "#F38B08",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#fefefe",
                    textTransform: "capitalize",
                  }}
                >
                  {t("buttons.save")}
                </Typography>
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Button
                onClick={() =>
                  setAddCustomerGroupModal({
                    state: false,
                    mode: "add",
                    customerGroupDetails: {},
                  })
                }
                sx={{
                  width: "97px",
                  height: "44px",
                  backgroundColor: "#EEE",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#707070",
                    textTransform: "capitalize",
                  }}
                >
                  {t("buttons.cancel")}
                </Typography>
              </Button>
              <Button
                onClick={() => createCustomerGroup()}
                sx={{
                  width: "155px",
                  height: "44px",
                  backgroundColor: groupName === "" ? "#ABABAB" : "#F38B08",

                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
                disabled={groupName === ""}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FEFEFE",
                    textTransform: "capitalize",
                  }}
                >
                  {t("customer.create_group")}
                </Typography>
              </Button>
            </Box>
          )}
        </Box>

        {!groupNameState ? (
          <>
            {editMode ? (
              <TextField
                type="text"
                fullWidth
                sx={{
                  marginBottom: "20px",
                }}
                defaultValue={addCustomerGroupModal?.customerGroupDetails?.name}
                label={t("customer.group_name")}
                //@ts-ignore
                placeholder={t("customer.group_name")}
                id="outlined-size-normal"
                onChange={onChangeGroupName}
              />
            ) : null}
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "normal",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#707070",
              }}
            >
              {selectedCustomers.length} {t("customer.customers_in_group")}{" "}
              {selectedCustomers.length === 0 && t("customer.group_para")}
            </Typography>
            <TypeSearch
              customSx={{ width: "600px" }}
              handleClearIconClick={() => setCustomerSearchQuery("")}
              //@ts-ignore
              placeholder={t("customer.search_by_name")}
              handleTextChange={handleSearch}
              value={customerSearchQuery}
            />

            <Box
              sx={{
                height: "291px",
                overflow: "scroll",
              }}
            >
              {customers.map((customer: any, index) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      padding: "20px 12px",
                      ":hover": {
                        backgroundColor: "#FFF8F0",
                      },
                      border: "2px solid #EEE",
                      marginBottom: "20px",
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          lineHeight: "normal",
                          fontWeight: "500",
                          fontFamily: "Poppins",
                          color: "#333",
                        }}
                      >
                        {customer.customerName}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          lineHeight: "normal",
                          fontWeight: "400",
                          fontFamily: "Poppins",
                          color: "#707070",
                        }}
                      >
                        {customer.mail + " , " + customer.phone}
                      </Typography>
                    </Box>
                    <Checkbox
                      checked={
                        selectedCustomers.indexOf(customer.customerId) !== -1
                      }
                      tabIndex={-1}
                      onClick={handleCheckboxChange(customer)}
                    />
                  </Box>
                );
              })}
            </Box>
          </>
        ) : (
          <>
            <TextField
              type="text"
              fullWidth
              sx={{
                margin: "32px 0px 12px 0px",
              }}
              label={t("customer.group_name")}
              //@ts-ignore
              placeholder={t("customer.group_name")}
              id="outlined-size-normal"
              onChange={onChangeGroupName}
            />

            {errorStatus && (
              <Typography sx={{ fontSize: "12px", color: "red" }}>
                {t("customer.group_parra2")}
              </Typography>
            )}
          </>
        )}
      </Sheet>

      {/* Delete confirmation modal */}
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={deleteConfirmationModal}
        onClose={() => {
          setDeleteConfirmationModal(false);
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "360px",
          top: "50px",
          opacity: 1,
        }}
      >
        <Sheet
          variant="outlined"
          className="shortCutModalBoxShadow"
          sx={{
            width: "720px",
            outline: "none",
            borderRadius: "8px",
            border: "1px solid #EEEEEE",
            padding: "20px 32px",
            boxSizing: "border-box",
          }}
        >
          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "20px",
            }}
          >
            <img
              src={CloseIcon}
              alt="close modal"
              onClick={() => setDeleteConfirmationModal(false)}
            />
          </Box>

          <Box
            sx={{
              display: "flex",
              marginBottom: "24px",
              borderBottom: "1px solid #D9D9D9",
              alignItems: "center",
              justifyContent: "space-between",
              paddingBottom: "20px",
              flexDirection: "column",
            }}
          >
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontWeight: "500",
                fontSize: "16px",
                lineHeight: "normal",
                color: "#707070",
                marginBottom: "50px",
              }}
            >
              {t("customer.delete_dailouge")}{" "}
            </Typography>

            <Box
              sx={{
                display: "flex",
                gap: "20px",
              }}
            >
              <Button
                onClick={() => setDeleteConfirmationModal(false)}
                sx={{
                  width: "130px",
                  height: "44px",
                  display: "flex",
                  justifyContent: "center",
                  borderRadius: "4px",
                  border: "1px solid #F38B08",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#F38B08",
                    textTransform: "capitalize",
                  }}
                >
                  {t("customer.dont_delete")}
                </Typography>
              </Button>
              <Button
                onClick={() => deleteCustomerGroup()}
                sx={{
                  width: "116px",
                  height: "44px",
                  backgroundColor: "#F5431C",
                  borderRadius: "4px",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "16px",
                    lineHeight: "normal",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FFF",
                    textTransform: "capitalize",
                  }}
                >
                  {t("customer.yes_delete")}
                </Typography>
              </Button>
            </Box>
          </Box>
        </Sheet>
      </Modal>
    </>
  );
};

export default AddCustomerGroup;
