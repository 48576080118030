import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Divider,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { STOCK_HISTORY_LIST } from "../../graphicalQl/usequery/inventoryListsQuery";

import "./inventoryLists.css";
import { useQuery } from "@apollo/client";
import { useSelector } from "react-redux";
import CircularLoader from "../../loader/loader";
import moment from "moment";
import { useTranslation } from "react-i18next";

import CustomFooter from "../category/footer";
import { localeToFormat } from "../../utils/dateFormat";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";
function collectVariantsName(data: any[]): string {
  let names: string[] = [];

  data.forEach((item) => {
    names.push(item.name);
  });

  return names.join(",");
}
function InventoryHistory() {
  const userLang = navigator.language;
  const { t } = useTranslation();
  function toPascalCase(str: any) {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join(" ");
  }
  const columns: GridColDef[] = [
    {
      field: "date",
      //@ts-ignore
      headerName: t("inventory.date"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
      renderCell: (params) => (
        <div className="inventory-list-table-row">
          {moment(params.value).format(`${localeToFormat[userLang]}, hh:mm`)}
        </div>
      ),
    },
    {
      field: "productName",
      //@ts-ignore
      headerName: t("inventory.product_name"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
      renderCell: (params) => (
        <Typography
          sx={{ textTransform: "capitalize" }}
          className="inventory-list-table-row"
        >
          {params.value}
        </Typography>
      ),
    },
    {
      field: "variantName",
      //@ts-ignore
      headerName: t("inventory.variation"),
      headerClassName: "inventory-list-table-column",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography
            sx={{ textTransform: "capitalize" }}
            className="inventory-list-table-row"
          >
            {params.value ? params.value : "N/A"}
          </Typography>
        );
      },
    },
    {
      field: "reason",
      //@ts-ignore
      headerName: t("inventory.reason"),
      headerClassName: "inventory-list-table-column",
      sortable: true,
      flex: 1,
      renderCell: (params) => (
        <div className="inventory-list-table-row">
          {toPascalCase(params.value)}
        </div>
      ),
    },
  ];
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [userInput, setUserInput] = React.useState<string | null>(null);
  const [historyList, setHistoryList] = useState<any[]>([]);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const filteringObject = {
    locationId: locationId && +locationId,
    businessId: +businessId,
    query: userInput,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };
  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const {
    loading: stockHistoryListLoading,
    error: stockHistoryListError,
    data: stockHistoryListData,
  } = useQuery(STOCK_HISTORY_LIST, {
    context: { clientName: "categoryClient" },
    variables: requiredFormatAddress,
    fetchPolicy: "network-only",
  });

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(
      stockHistoryListData?.inventoryTransactionList?.pageInfo.endCursor || null
    );
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(
      stockHistoryListData?.inventoryTransactionList?.pageInfo.startCursor ||
        null
    );
  };
  useEffect(() => {
    if (stockHistoryListData) {
      const { inventoryTransactionList } = stockHistoryListData;
      const { edges } = inventoryTransactionList;
      const filteredHistoryList =
        edges &&
        edges.map((eachHistory: any) => ({
          id: eachHistory.node.transactionId,
          date: eachHistory.node.createdOn,
          productName:
            eachHistory.node.inventory &&
            eachHistory.node.inventory.product &&
            eachHistory.node.inventory.product.name,
          variantName:
            eachHistory.node.inventory &&
            eachHistory.node.inventory.variantDetails &&
            collectVariantsName(
              JSON.parse(eachHistory.node.inventory.variantDetails)
            ),
          reason: eachHistory.node.reason,
        }));
      setHistoryList(filteredHistoryList);
    }
  }, [stockHistoryListData]);

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Box sx={{ padding: "10px" }}>
        <Typography className="inventory-header-name">
          {t("inventory.stock_history")}
        </Typography>
      </Box>

      <DelayedInput
        width="30%"
        margin="10px 0px 10px 8px"
        setSearchValue={setUserInput}
        placeHolder={t("inventory.Search by name/description")}
        size={"medium"}
      />

      {stockHistoryListLoading ? (
        <CircularLoader />
      ) : (
        <Box sx={{ padding: "10px", height: "70vh", width: "100%" }}>
          {/* <DataGrid
              rows={historyList}
              columns={columns}
              pageSizeOptions={[5, 10, 25]}
              disableRowSelectionOnClick
              getRowClassName={getRow}
              pagination
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: paginationModel.pageSize,
                  },
                },
              }}
              rowCount={
                stockHistoryListData?.inventoryTransactionList?.totalCount || 0
              }
              paginationModel={paginationModel}
              onPaginationModelChange={(newModel) => {
                let shouldUpdatePaginationModel = true;
                if (newModel.pageSize !== paginationModel.pageSize) {
                  setStartCursor(null);
                  setEndCursor(null);
                  setCurrentPage(0);
                  setPaginationModel({
                    ...newModel,
                    page: 0,
                  });
                  shouldUpdatePaginationModel = false;
                } else if (newModel.page > currentPage) {
                  handleEndCursor();
                } else if (newModel.page < currentPage) {
                  handleStartCursor();
                }

                if (shouldUpdatePaginationModel) {
                  setPaginationModel(newModel);
                  setCurrentPage(newModel.page);
                }
              }}
              paginationMode="server"
              components={{
                Footer: (props) => (
                  <CustomFooter
                    totalRowCount={Math.ceil(
                      (stockHistoryListData?.inventoryTransactionList
                        ?.totalCount || 0) / paginationModel.pageSize
                    )}
                    {...props}
                    page={paginationModel.page}
                  />
                ),
              }}
              sx={{
                "&.MuiDataGrid-columnHeaderTitle": {
                  fontWeight: "500",
                },
                "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                  outline: "solid #1976D2 0px !important",
                },
                "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                  {
                    outline: "solid #1976D2 0px !important",
                  },
                "& .MuiDataGrid-columnHeader": {
                  background: "#F5F5F5",
                },

                ".MuiTablePagination-displayedRows": {
                  display: "none", // :point_left: to hide huge pagination number
                },
              }}
            /> */}

          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={historyList}
            columns={columns}
            getRow={getRow}
            totalCount={
              stockHistoryListData?.inventoryTransactionList?.totalCount || 0
            }
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}

export default InventoryHistory;
