import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
interface State {
  text: any;
  format: any
}

function formatOrderType(orderType: string) {
  return orderType
    ?.split("_")
    ?.map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    ?.join(" ");
}

const Status = (props: State) => {
  const { text, format } = props
  let newTest;
  if (format) {
    newTest = formatOrderType(text);
  } else {
    newTest = text;
  }
  let color: string, background: string;
  switch (text) {
    case "Paid":
      color = "#3FAA20";
      background = "#00AE261A";
      break;
    case "Pending":
      color = "#F9B202";
      background = "#F9B2021A";
      break;
    case "Completed":
      color = "#02f905";
      background = "#00f04e1a";
      break;
    case "cancelled":
      color = "#F5431C";
      background = "#F5431C1A";
      break;

    default:
      color = "inherit";
      background = "inherit";
  }

  return (
    <Box>
      <Typography
        sx={{
          color,
          background,
          ...(text !== "default" && {
            display: "inline",
            padding: "4px 12px 4px 12px",
            borderRadius: "20px",
            fontWeight: "500",
            fontSize: "14px",
          }),
        }}
      >
        {newTest}
      </Typography>
    </Box>
  );
};

export default Status;
