import {
  FormGroup,
  Checkbox,
  FormControlLabel,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Paper,
  InputBase,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  TextField,
  Box,
  Button,
  Divider,
  IconButton,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import { EmplpoyeesList } from "../../graphicalQl/usequery/userProfileUseQuery";
import {
  DRIVER_COMMISSIONS_LIST,
  GETTING_DRIVER_DETAILS_FOR_UPDATE,
  DRIVERS_COMMISSIONS_HISTORY,
} from "../../graphicalQl/usequery/deliveryManagementQuery";
import {
  UPDATE_THE_DRIVER_COMMISSION,
  CREATE_THE_DRIVER_COMMISSION,
} from "../../graphicalQl/mutation/deliveryManagementMutation";
import { useQuery, useMutation } from "@apollo/client";
import CircularLoader from "../../loader/loader";
import * as Yup from "yup";
import { useFormik } from "formik";

import moment from "moment";
import { useSelector } from "react-redux";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import PercentIcon from "@mui/icons-material/Percent";
import DriverCommissionHistory from "./driverCommissionHistory";

import { useTranslation } from "react-i18next";

import "./driverCommission.css";

import { DataGrid, GridColDef } from "@mui/x-data-grid";

import Modal from "@mui/material/Modal";
import CustomFooter from "../category/footer";
import { localeToFormat } from "../../utils/dateFormat";
import DataGridTable from "../../utils/dataGridTable";
import DelayedInput from "../../utils/delayedInput";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 562,
  bgcolor: "background.paper",
  borderRadius: "8px",

  p: 3,
};

interface State {
  drivers: string[];
  commission: number | null;
  driverName: string | null;
  employeeId: string | null;
  commissionId: string | null;
}

const initiallValues: State = {
  drivers: [],
  commission: null,
  driverName: null,
  employeeId: null,
  commissionId: null,
};

function DriverCommission() {
  const userLang = navigator.language;
  const employeeId = sessionStorage.getItem("employeeId");

  const { t } = useTranslation();
  const validationSchema = Yup.object({
    commission: Yup.number().required("Required"),
    drivers: Yup.array()
      .min(1, "At least one driver must be selected")
      .required("At least one driver must be selected"),
  });

  const formik = useFormik({
    initialValues: initiallValues,
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleSaveButton();
    },
  });
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [rows, setRows] = React.useState<any[]>([]);

  const [searchInput, setSearchInput] = useState<string | null>(null);
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [driversList, setDriversList] = React.useState<any[]>([]);
  const PopoverOpen = Boolean(anchorEl);
  const id = PopoverOpen ? "simple-popover" : undefined;
  const checkedCount = driversList.filter(
    (location: any) => location.isChecked
  ).length;
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const filteredDataFromNull = {
    businessId: +businessId,
    locationId: +locationId,
    query: searchInput,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };

  const filteredDataFromNullForHistory = {
    employeeId: formik.values.employeeId && +formik.values.employeeId,
    locationId: +locationId,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(filteredDataFromNull).filter(([_, value]) => value !== null)
  );

  const filteredVariablesForHistory = Object.fromEntries(
    Object.entries(filteredDataFromNullForHistory).filter(
      ([_, value]) => value !== null
    )
  );

  const [noOfLocationsSelected, setNoOfLocationsSelected] =
    React.useState(checkedCount);
  const {
    loading: driverCommissionLoading,
    error: driverCommissionError,
    data: driverCommissionData,
  } = useQuery(DRIVER_COMMISSIONS_LIST, {
    context: { clientName: "userClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  const {
    loading: driverCommissionHistoryLoading,
    error: driverCommissionHistoryError,
    data: driverCommissionHistoryData,
  } = useQuery(DRIVERS_COMMISSIONS_HISTORY, {
    context: { clientName: "userClient" },
    variables: filteredVariablesForHistory,
    fetchPolicy: "network-only",
  });

  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(EmplpoyeesList, {
    context: { clientName: "userClient" },
    variables: {
      businessId: +businessId,
      locationId: +locationId,
      excludedEmployeeId: employeeId && +employeeId,
    },
  });

  const {
    data: updateDriverData,
    loading: updateDriverLoading,
    error: updateDriverError,
  } = useQuery(GETTING_DRIVER_DETAILS_FOR_UPDATE, {
    context: { clientName: "userClient" },
    variables: {
      id: formik.values.commissionId && +formik.values.commissionId,
    },
    fetchPolicy: "network-only",
  });
  const [updateCommission] = useMutation(UPDATE_THE_DRIVER_COMMISSION, {
    onCompleted: (data) => {
      if (data) {
        const { addDriverCommission } = data;
        if (addDriverCommission) {
          const { success } = addDriverCommission;
          if (success) {
            handleClose();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList, DRIVER_COMMISSIONS_LIST],
  });

  const [createCommission] = useMutation(CREATE_THE_DRIVER_COMMISSION, {
    onCompleted: (data) => {
      if (data) {
        const { addDriverCommission } = data;
        if (addDriverCommission) {
          const { success } = addDriverCommission;
          if (success) {
            handleClose();
          }
        }
      }
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList, DRIVER_COMMISSIONS_LIST],
  });
  useEffect(() => {
    if (driverCommissionHistoryData) {
      const { driverCommissionHistory } = driverCommissionHistoryData;
      if (driverCommissionHistory) {
        const { edges } = driverCommissionHistory;
        if (edges) {
          const filteredData = edges.map((each: any) => ({
            id: each.node.commissionId,
            commission: each.node.commission,
            date: each.node.date,
          }));
          setRowsForHistory(filteredData);
        }
      }
    }
  }, [driverCommissionHistoryData]);

  useEffect(() => {
    if (updateDriverData) {
      const { driverCommission } = updateDriverData;
      if (driverCommission) {
        const { commission, employee } = driverCommission;
        const driverName =
          employee && `${employee.firstName} ${employee.lastName}`;
        formik.setFieldValue("driverName", driverName);
        formik.setFieldValue("commission", commission);
        formik.setFieldValue("employeeId", employee?.employeeId);
        formik.setFieldValue("drivers", [+employee?.employeeId]);
      }
    }
  }, [updateDriverData]);
  useEffect(() => {
    if (driverCommissionData) {
      const { driverCommissions } = driverCommissionData;
      const { edges } = driverCommissions;
      const filtredData = edges.map((eachCommission: any) => ({
        id: eachCommission.node.employeeId,
        commissionId: eachCommission.node.commissionId,
        commission: eachCommission.node.commission,
        date: eachCommission.node.date,
        employee: eachCommission.node.employee && eachCommission.node.employee,
      }));
      const data = filtredData
        .filter((each: any) => each.id !== null)
        .map((each: any) => each);
      setRows(data);
    }
  }, [driverCommissionData]);
  useEffect(() => {
    if (employeesData) {
      if (employeesData !== null) {
        const requiredDataList = employeesData.employees.edges.map(
          (each: any) => ({
            id: each.node.employeeId,
            driverName: `${each.node.firstName} ${each.node.lastName}`,
            permissions:
              each.node.permissions && JSON.parse(each.node.permissions),
            isChecked: false,
          })
        );
        const filteredDriverList =
          requiredDataList &&
          requiredDataList.filter(
            (each: any) => each.permissions && each.permissions.includes(44)
          );
        setDriversList(filteredDriverList);
        setInitialRows(filteredDriverList);
      }
    }
  }, [employeesData]);

  const [allCheckboxChecked, setAllCheckboxChecked] = React.useState<any>(0);
  const [driverNameForHistory, setDriverNameForHistory] = useState<
    null | string
  >(null);
  const [commissionHistoryPopup, setCommissionHistoryPopup] =
    useState<boolean>(false);
  const [popupSearch, setPopupSearch] = React.useState("");
  const [initialRows, setInitialRows] = useState<any[]>([]);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
    formik.resetForm();
    setDriversList(initialRows);
    setNoOfLocationsSelected(0);
    setAllCheckboxChecked(false);
  };

  const handlePopupCheckbox = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => {
    const { checked } = event.target;
    const filteredList = driversList.map((each) => {
      if (each.id === id) {
        return { ...each, isChecked: checked };
      }
      return each;
    });
    const checkedCount = filteredList.filter(
      (location: any) => location.isChecked
    ).length;

    setAllCheckboxChecked(checkedCount === filteredList.length);
    setDriversList(filteredList);
    setNoOfLocationsSelected(checkedCount);
    const collectedIds = filteredList
      .filter((each: any) => each.isChecked)
      .map((eachDriver: any) => +eachDriver.id);
    formik.setFieldValue("drivers", collectedIds);
  };

  const handleEditClick = () => {
    setOpen(true);
    setAnchorEl(null);
  };

  const handleSearchIcon = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setPopupSearch(event.target.value);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const filteredDriversList = driversList.filter(
    (each) =>
      each.driverName &&
      each.driverName.toLocaleLowerCase().includes(popupSearch)
  );
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    commissionId: string,
    employee: any
  ) => {
    setAnchorEl(event.currentTarget);
    formik.setFieldValue("employeeId", id);
    formik.setFieldValue("commissionId", commissionId);
    setDriverNameForHistory(`${employee?.firstName} ${employee?.lastName}`);
  };

  const handleHistoryButton = () => {
    setCommissionHistoryPopup(true);
    setAnchorEl(null);
  };

  const popoverContent = (
    <List style={{ padding: "0px 0px 0px 0px" }}>
      <Button
        onClick={handleEditClick}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleHistoryButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("menu_setUp.history")} />
        </ListItem>
      </Button>
    </List>
  );

  const columns: GridColDef[] = [
    {
      field: "employee",
      //@ts-ignore
      headerName: t("deliveries.driver"),
      headerClassName: "driver-commission-table-header-name",
      flex: 1,
      renderCell: (params) => (
        <Box>
          <Typography
            sx={{ textTransform: "capitalize" }}
            className="user-list-table-row"
          >
            {params.row.employee.firstName} {params.row.employee.lastName}
          </Typography>
        </Box>
      ),
    },

    {
      field: "commission",
      //@ts-ignore
      headerName: t("deliveries.commission"),
      headerClassName: "driver-commission-table-header-name",
      flex: 1,
      align: "left",

      renderCell: (params) => (
        <Box>
          <Typography className="user-list-table-row">
            {params.row.commission}%
          </Typography>
        </Box>
      ),
    },

    {
      field: "date",
      //@ts-ignore
      headerName: t("deliveries.date"),
      headerClassName: "driver-commission-table-header-name",
      type: "datetime",
      flex: 1,
      renderCell: (params) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Typography className="user-list-table-row">
            {params.row.date
              ? moment(params.value).format(`${localeToFormat[userLang]}`)
              : "N/A"}
          </Typography>
        </Box>
      ),
    },
    {
      field: "Actions",
      // headerClassName: "user-role-header-names",
      headerClassName: "driver-commission-table-header-name",
      //@ts-ignore
      headerName: t("buttons.actions"),
      width: 120,
      // disableColumnMenu: true,
      renderCell: (params) => (
        <Box className="user-roles-header-hover" sx={{ marginLeft: "15px" }}>
          {/* <IconButton onClick={handleEditClick(params.row.id,params.row.commissionId)}>
          <EditIcon />
        </IconButton> */}
          <IconButton
            onClick={(event) =>
              handleClick(
                event,
                params.row.id,
                params.row.commissionId,
                params.row.employee
              )
            }
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </Box>
      ),
    },
  ];

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(
      driverCommissionData?.driverCommissions?.pageInfo.endCursor || null
    );
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(
      driverCommissionData?.driverCommissions?.pageInfo.startCursor || null
    );
  };

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleSaveButton = () => {
    if (formik.values.employeeId !== null) {
      updateCommission({
        variables: {
          commission: formik.values.commission && +formik.values.commission,
          employeeIds: JSON.stringify([
            formik.values.employeeId && +formik.values.employeeId,
          ]),
          businessId: +businessId,
          locationId: +locationId,
        },
      });
    } else {
      createCommission({
        variables: {
          commission: formik.values.commission && +formik.values.commission,
          employeeIds: JSON.stringify(formik.values.drivers),
          businessId: +businessId,
          locationId: +locationId,
        },
      });
    }
  };

  const handlePopupCheckbo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { checked } = e.target;
    const filteredList = driversList.map((each: any) => ({
      ...each,
      isChecked: checked,
    }));
    setNoOfLocationsSelected(checked ? filteredList.length : 0);
    setAllCheckboxChecked(checked);
    setDriversList(filteredList);
    const collectedIds = filteredList
      .filter((each: any) => each.isChecked)
      .map((eachDriver: any) => +eachDriver.id);
    formik.setFieldValue("drivers", collectedIds);
  };

  const handleCloseIcon = () => {
    handleClose();
  };

  let locationsText;
  if (noOfLocationsSelected === 0) {
    locationsText = t("deliveries.select_driver");
  } else if (noOfLocationsSelected === 1) {
    locationsText = t("deliveries.driver");
  } else {
    locationsText = t("deliveries.drivers");
  }

  const handleCommissionHistoryPopupClose = () => {
    setCommissionHistoryPopup(false);
    setDriverNameForHistory(null);
    setRowsForHistory([]);
  };

  const [rowsForHistory, setRowsForHistory] = useState<any[]>([]);

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <DriverCommissionHistory
        handlePopupClose={handleCommissionHistoryPopupClose}
        popUpOpen={commissionHistoryPopup}
        rows={rowsForHistory}
        DriverName={driverNameForHistory}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <Typography className="driver-commission-header-text">
          {t("deliveries.driver_commission")}
        </Typography>
        <Button
          onClick={handleOpen}
          className="driver-commission-header-button"
          variant="contained"
        >
          {t("deliveries.add_commission")}
        </Button>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "60%",
                  justifyContent: "space-between",
                }}
              >
                <IconButton
                  onClick={handleCloseIcon}
                  className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
                >
                  <CloseIcon />
                </IconButton>

                <Typography className="driver-commission-popup-header">
                  {t("deliveries.commission")}
                </Typography>
              </Box>
              <Divider sx={{ marginBottom: "15px", marginTop: "15px" }} />

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "60%",
                  }}
                >
                  {formik.values.employeeId === null && (
                    <Box sx={{ width: "100%", height: "7vh", zIndex: "10" }}>
                      <Accordion
                        sx={{ width: "100%", border: "2px solid #d9d9d9" }}
                        elevation={0}
                      >
                        <AccordionSummary
                          sx={{ width: "100%", marginBottom: 0 }}
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography
                            className="create-a-use-role-label"
                            sx={{ marginBottom: "0px" }}
                          >
                            {noOfLocationsSelected === 0
                              ? locationsText
                              : `${noOfLocationsSelected} ${locationsText}`}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box>
                            <Box sx={{ width: "100%" }}>
                              <TextField
                                onChange={(e) => handleSearchIcon(e)}
                                //@ts-ignore
                                placeholder={t("users.search_by_name")}
                                value={searchInput}
                                id="outlined-start-adornment"
                                sx={{ width: "95%" }}
                                color="warning"
                                InputProps={{
                                  startAdornment: (
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  ),
                                }}
                              />
                            </Box>
                          </Box>
                          <Box
                            sx={{
                              height: "30vh",
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "space-between",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "flex-start",
                                flexDirection: "column",
                                alignItems: "flex-start",
                                overflowY: "auto",
                                maxHeight: "32vh",
                                marginTop: "10px",
                                minHeight: "10vh",
                                WebkitOverflowScrolling: "touch",
                                "&::-webkit-scrollbar": {
                                  display: "none",
                                },
                              }}
                            >
                              <FormGroup>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      name="all"
                                      checked={allCheckboxChecked}
                                      onChange={(e) => handlePopupCheckbo(e)}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: "1.75rem",
                                        },
                                      }}
                                      color="warning"
                                    />
                                  }
                                  label={
                                    <Typography className="driver-commission-driver-label">
                                      {t("buttons.all")}
                                    </Typography>
                                  }
                                />
                                {filteredDriversList.map((each: any) => (
                                  <FormControlLabel
                                    key={each.id}
                                    control={
                                      <Checkbox
                                        onChange={(e) => {
                                          handlePopupCheckbox(e, each.id);
                                        }}
                                        sx={{
                                          "& .MuiSvgIcon-root": {
                                            fontSize: "1.75rem",
                                          },
                                        }}
                                        color="warning"
                                        checked={each.isChecked}
                                      />
                                    }
                                    label={
                                      <Typography
                                        sx={{ textTransform: "capitalize" }}
                                        className="driver-commission-driver-label"
                                      >
                                        {each.driverName}
                                      </Typography>
                                    }
                                  />
                                ))}
                              </FormGroup>
                            </Box>
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Box>
                  )}
                  {formik.touched.drivers && formik.errors.drivers && (
                    <Box>
                      <Typography
                        sx={{ marginTop: "8px" }}
                        className="create-a-user-formik-label"
                      >
                        {t("error.atleast_one_driver")}
                      </Typography>
                    </Box>
                  )}

                  {formik.values.employeeId !== null && (
                    <TextField
                      color="warning"
                      sx={{
                        marginTop: "10px",
                        marginBottom: "5px",
                        width: "100%",
                      }}
                      value={formik.values.driverName || ""}
                      label={t("deliveries.driver_name")}
                      variant="outlined"
                    />
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start",
                    flexDirection: "column",
                    width: "60%",
                  }}
                >
                  <FormControl
                    sx={{ margin: "20px 0px 5px 0px ", width: "100%" }}
                  >
                    <InputLabel htmlFor="outlined-adornment-amount">
                      {t("deliveries.commission")} %
                    </InputLabel>
                    <OutlinedInput
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        const val = e.target.value;
                        if (!val || /^[0-9]+$/.test(val)) {
                          formik.handleChange(e);
                        }
                      }}
                      type="text"
                      name="commission"
                      label="Commission %"
                      value={formik.values.commission || ""}
                      color="warning"
                      sx={{ width: "100%" }}
                      id="outlined-adornment-amount"
                      startAdornment={
                        <InputAdornment position="start">
                          <PercentIcon
                            sx={{ color: "#ABABAB", width: "15px" }}
                          />
                        </InputAdornment>
                      }
                    />
                  </FormControl>
                  {formik.touched.commission && formik.errors.commission && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
                </Box>
              </Box>
              <Box sx={{ textAlign: "right", marginTop: "20px" }}>
                <Button
                  className="driver-commission-popup-button"
                  variant="contained"
                  color="warning"
                  type="submit"
                >
                  {t("buttons.save")}
                </Button>
              </Box>
            </form>
          </Box>
        </Modal>
      </Box>
      <Box sx={{ width: "100%" }}>
        <DelayedInput
          width="30%"
          margin="10px 0px 10px 8px"
          setSearchValue={setSearchInput}
          placeHolder={t("users.search_by_name")}
          size={"medium"}
        />
      </Box>

      {driverCommissionLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            padding: "10px",
            height: "70vh",
            width: "100%",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={rows}
            columns={columns}
            getRow={getRow}
            totalCount={
              driverCommissionData?.driverCommissions?.totalCount || 0
            }
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}

export default DriverCommission;
