import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CamImage from "../../assets/menuQr/cam.svg";
import ScannerImg from "../../assets/menuQr/scan.svg";
import OrderImg from "../../assets/menuQr/orders.svg";
import BgLight from "../../assets/menuQr/bglight.png";
import BgDark from "../../assets/menuQr/bgdark.png";
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";
import Download from "../../assets/menuQr/download.svg";
import Print from "../../assets/menuQr/print.svg";
import { IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import html2canvas from "html2canvas";
import { useReactToPrint } from "react-to-print";
import jsPDF from "jspdf";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  borderRadius: "8px",
  bgcolor: "background.paper",
  p: 2,
};

interface State {
  open: boolean;
  handleCloseTablePopup: () => void;
  selectedId: any;
  id: any;
  url: string;
  handlePrint: any;
  downloadPdfDocument: () => void;
  handleCheckBox: (id: number) => void;
  tableNumber: number | null;
}

const QrCodePopup = (props: State) => {
  const { t } = useTranslation();
  const {
    open,
    handleCloseTablePopup,
    selectedId,
    id,
    url,
    tableNumber,

    handleCheckBox,
  } = props;

  const downloadPdfDocument = () => {
    if (componentRef.current) {
      html2canvas(componentRef.current, {
        useCORS: true,
        scale: 1,
      }).then((canvas) => {
        const imgData = canvas.toDataURL("image/png");
        const pdf = new jsPDF({
          orientation: "portrait",
          unit: "px",
          format: [canvas.width, canvas.height],
        });
        pdf.addImage(imgData, "PNG", 0, 0, canvas.width, canvas.height);
        pdf.save("download.pdf");
      });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const componentRef = React.useRef<HTMLDivElement>(null);
  return (
    <Modal
      open={open}
      onClose={handleCloseTablePopup}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton
            onClick={handleCloseTablePopup}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <CloseIcon />
          </IconButton>

          <Box sx={{ display: "flex", gap: "18px", alignItems: "center" }}>
            {selectedId === 2 ? (
              <IconButton onClick={() => handleCheckBox(1)}>
                <DarkModeIcon className="light-mode-icon-color" />
              </IconButton>
            ) : (
              <IconButton onClick={() => handleCheckBox(2)}>
                <LightModeIcon className="light-mode-icon-color" />
              </IconButton>
            )}

            <img
              src={Download}
              height={32}
              alt="Download"
              onClick={downloadPdfDocument}
            />
            <img height={32} src={Print} alt="Print" onClick={handlePrint} />
          </Box>
        </Box>
        <Box sx={{ display: "flex", gap: "24px", marginTop: "12px" }}>
          <div
            style={{
              backgroundImage: `url(${selectedId === 2 ? BgLight : BgDark})`,
              width: "450px",
              height: "600px",
              backgroundSize: "cover", // Changed from 'cover' to 'contain'
              backgroundRepeat: "no-repeat", // Prevents the image from repeating
              // backgroundPosition: 'center' // Centers the image within the Box
              paddingTop: "20px",
              textAlign: "center",
              border: " 12px solid #EEE",
              borderRadius: "10px",
            }}
            ref={componentRef}
          >
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography className="mainText" sx={{ width: "323px" }}>
                {t("menuQr.please_scan_code")}
              </Typography>
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                margintTop: "44px",
              }}
            >
              <Box
                sx={{
                  marginTop: "5px",
                  backgroundColor: "#ffffff",
                  width: "236px",
                  height: "236px",
                  border: " 3.457px solid #FBDBB4",
                  boderRadius: "10px",
                  padding: "10px",
                }}
              >
                <Box
                  sx={{
                    border: "3.457px solid #D37528",
                    borderRadius: "8px",
                    width: "210px",
                    height: "210px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* {id && imageLoaded && (
                                <img
                                    src={`https://api.qrserver.com/v1/create-qr-code/?size=230x230&data=${id}`}
                                    alt="QR Code"
                                    onLoad={() => se tImageLoaded(true)}
                                />
                            )}                             */}
                  {id && (
                    <QRCode
                      value={`${url}/?table=${tableNumber}`}
                      size={200}
                      level={"H"}
                      renderAs="canvas"
                      includeMargin={true}
                    />
                  )}
                </Box>
              </Box>
            </Box>
            {/* <Typography
              sx={{ marginTop: "8px", textTransform: "capitalize" }}
              className="menu-name"
            >
              {name}
            </Typography> */}
            <Typography
              sx={{
                marginTop: "16px",
                color: selectedId === 2 ? "#333" : "#eeeeee",
              }}
              className="menu-order"
            >
              {t("menuQr.how_to_order")}
            </Typography>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                marginTop: "70px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  marginTop: "0px",
                }}
              >
                <img
                  src={CamImage}
                  alt="camera"
                  style={{ width: "50px", height: "70px" }}
                />
                <div className="instruction-item">
                  <div className="number">
                    <span
                      className={
                        selectedId === 2
                          ? "gradient-selected"
                          : "gradient-default"
                      }
                    >
                      1
                    </span>
                  </div>
                  <p
                    style={{
                      position: "absolute",
                      top: "-10px",
                      color: selectedId === 2 ? "#333" : "#eeeeee",
                    }}
                    className="iconText"
                  >
                    {t("menuQr.open_camera")}
                  </p>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={ScannerImg}
                  alt="scanner"
                  style={{ width: "50px", height: "70px" }}
                />
                <div className="instruction-item">
                  <div className="number">
                    <span
                      className={
                        selectedId === 2
                          ? "gradient-selected"
                          : "gradient-default"
                      }
                    >
                      2
                    </span>
                  </div>
                  <p
                    style={{
                      position: "absolute",
                      top: "-10px",
                      color: selectedId === 2 ? "#333" : "#eeeeee",
                    }}
                    className="iconText"
                  >
                    {t("menuQr.aim_at_qr")}
                  </p>
                </div>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <img
                  src={OrderImg}
                  alt="order"
                  style={{ width: "50px", height: "70px" }}
                />
                <div className="instruction-item">
                  <div className="number">
                    <span
                      className={
                        selectedId === 2
                          ? "gradient-selected"
                          : "gradient-default"
                      }
                    >
                      3
                    </span>
                  </div>
                  <p
                    style={{
                      position: "absolute",
                      top: "-10px",
                      color: selectedId === 2 ? "#333" : "#eeeeee",
                    }}
                    className="iconText"
                  >
                    {t("menuQr.add_items_order")}
                  </p>
                </div>
              </Box>
            </div>
          </div>
          {/* <Box>
            <Typography className="menu-side-head ">
              {t("menuQr.customise_digital_menu")}
            </Typography>
            <Typography className="menu-name" sx={{ marginTop: "4px" }}>
              {t("menuQr.tailor_digital_menu")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: "48px",
                alignItems: "center",
                marginTop: "24px",
                justifyContent: "center",
              }}
            >
              {list.map((item, index) => (
                <Box key={index}>
                  <Box
                    sx={{
                      position: "relative",
                      display: "inline-block",
                      ...(item.isChecked
                        ? { border: "2px solid #F38B08" }
                        : {}),
                    }}
                    onClick={handleCheckBox(item.id)}
                  >
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{ width: "100%", display: "block" }}
                    />
                    <Checkbox
                      sx={{ position: "absolute", top: 0, right: 0 }}
                      color="warning"
                      checked={item.isChecked}
                    />
                  </Box>
                  <Typography className="theme-line" sx={{ marginTop: "12px" }}>
                    {item.name}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box> */}
        </Box>
      </Box>
    </Modal>
  );
};

export default QrCodePopup;
