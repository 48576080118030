import { gql } from "@apollo/client";

export const CREATE_SALES_TAX = gql`
mutation CreateTax($storeId:Int!,$business:Int!,$taxName:String,$taxPercentage:Float,$roundingRule:String,$items:JSONString,$includeTaxInItemPrice:Boolean){
    createTax(
        storeId:$storeId
        business:$business
        taxName:$taxName
        taxPercentage:$taxPercentage
        items:$items
        roundingRule:$roundingRule
        includeTaxInItemPrice:$includeTaxInItemPrice
        
      ) {
            errorMessage
            salesTax{taxId
            taxName}
            success
            }
    }
`

export const ALLOW_SALES_TAX = gql`
mutation branchSettings($storeId:Int!,$allowSalesTax:Boolean){
    branchSettings(
        storeId:$storeId
   
        allowSalesTax:$allowSalesTax
    ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`

export const ALLOW_SERVICE_TAX = gql`
mutation branchSettings($storeId:Int!,$allowServiceTax:Boolean){
    branchSettings(
        storeId:$storeId
   
        allowServiceTax:$allowServiceTax
    ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`
export const TAX_STATUS_CHANGE = gql`
mutation TaxStatusChange($taxId:ID!,$status:String){
    taxStatusChange(
        taxId:$taxId,
        status:$status
    ) {
        result
        success
        errorMessage
        
    }
        
    }
`

export const UPDATE_SALES_TAX = gql`
mutation UpdateTax($taxId:ID!,$taxName:String,$taxPercentage:Float,$roundingRule:String,$items:JSONString,$includeTaxInItemPrice:Boolean){
    updateTax(
        taxId:$taxId
        taxName:$taxName
        taxPercentage:$taxPercentage
        items:$items
        roundingRule:$roundingRule
        includeTaxInItemPrice:$includeTaxInItemPrice
        
      ) {
            errorMessage
            salesTax{taxId
            taxName}
            success
            }
    }
`

export const DELETE_SALES_TAX = gql`
mutation DeleteTaxes($taxIds:JSONString!){
	deleteTaxes(
		taxIds:$taxIds
		
		
	)
	{success
		result
		errorMessage
		
	}}
`

export const CREATE_SERVICE_CHARGE = gql`
mutation CreateCharge($storeId:Int!,$business:Int!,$chargeName:String,$chargePercentage:Float,$noOfCustomers:Int,$totalCartAmount:Float,$extraCharge:Float,$applicableTo:JSONString,
    $allowCustomSettings:Boolean,$extraChargeType:String
    
){
    createCharge(
        storeId:$storeId
        business:$business
        chargeName:$chargeName
        chargePercentage:$chargePercentage
        noOfCustomers: $noOfCustomers
        totalCartAmount: $totalCartAmount
        extraCharge: $extraCharge
        applicableTo: $applicableTo
        allowCustomSettings:$allowCustomSettings
        extraChargeType:$extraChargeType
        
        
      ) {
            errorMessage
            serviceCharge{chargeId
            chargeName}
            success
            }
    }`

export const UPDATE_SERVICE_CHARGE = gql`
    mutation UpdateCharge($chargeId:ID!,$chargeName:String,$chargePercentage:Float,$noOfCustomers:Int,$totalCartAmount:Float,$extraCharge:Float,$applicableTo:JSONString,
        $allowCustomSettings:Boolean,$extraChargeType:String

    ){
        updateCharge(
            chargeId:$chargeId
            chargeName:$chargeName
            chargePercentage:$chargePercentage
                
            noOfCustomers:  $noOfCustomers
            totalCartAmount:    $totalCartAmount
            extraCharge:    $extraCharge
            applicableTo:   $applicableTo
            allowCustomSettings:$allowCustomSettings
            extraChargeType:$extraChargeType
            
            
          ) {
                errorMessage
                serviceCharge{chargeId
                chargeName}
                success
                }
        }
    `

export const DELETE_SERVICE_CHARGE = gql`
    mutation DeleteCharge($chargeIds:JSONString!){
        deleteCharge(
            chargeIds:$chargeIds
            
            
        )
        {success
            message
            
    }
    }
    `

export const CHARGE_STATUS_CHANGE = gql`

    mutation ChargeStatusChange($chargeId:ID!,$status:String){
        chargeStatusCharge(
            chargeId:$chargeId,
            status:$status
        ) {
            success
            message
            
            
        }
            
        }
        `

export const LOCATION_STATUS_CHANGE = gql`
mutation statusChange($storeId:ID!,$status:String){
	statusChange(
		storeId:$storeId,
		status:$status
	){
		success
		result
		errorMessage
	}
}
`


export const CUSTOM_DISCOUNTS = gql`
mutation BranchSettings($storeId:Int!,$allowFlatDiscountsForPos:Boolean,$flatDiscountsForPos:JSONString,$allowPercentageDiscountsForPos:Boolean,$discountPercentagesForPos:JSONString,
    $flatAmountTipDetails:JSONString,
$flatDiscountsDetailsForPos:JSONString
){
    branchSettings(
        storeId:$storeId
        allowFlatDiscountsForPos:$allowFlatDiscountsForPos
        flatDiscountsForPos:$flatDiscountsForPos
        allowPercentageDiscountsForPos:$allowPercentageDiscountsForPos
    discountPercentagesForPos:$discountPercentagesForPos
    flatAmountTipDetails:$flatAmountTipDetails
flatDiscountsDetailsForPos:$flatDiscountsDetailsForPos
        
        
        
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`


export const CREATE_ROUNDING = gql`
mutation BranchSettings(
    $storeId:Int!,
     $roundingRule:String,
    
    ){
    branchSettings(
        storeId:$storeId
 
        roundingRule:$roundingRule
	
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`
export const CREATE_REFUND_CANCEL = gql`
mutation BranchSettings(
    $storeId:Int!,
$allowRefundReasons:Boolean
    $refundTimeLimit:Int
	$refundWith:JSONString
	$allowRefund:Boolean
	$refundReasons:JSONString
	$cancellationBasedOnStatus:JSONString
	$noOfMinsToAcceptCancellationForDelivery:Int
	$noOfMinsToAcceptCancellationForDinein:Int
   $noOfMinsToAcceptCancellationForCollection:Int
	
    ){
    branchSettings(
        storeId:$storeId
   
    refundTimeLimit:$refundTimeLimit
	refundWith:$refundWith
    allowRefundReasons:$allowRefundReasons
	allowRefund:$allowRefund
	refundReasons:$refundReasons
	cancellationBasedOnStatus:$cancellationBasedOnStatus
	noOfMinsToAcceptCancellationForDelivery:$noOfMinsToAcceptCancellationForDelivery
	noOfMinsToAcceptCancellationForDinein:$noOfMinsToAcceptCancellationForDinein
    noOfMinsToAcceptCancellationForCollection:$noOfMinsToAcceptCancellationForCollection
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`

export const SIGN_OUT_EVERYWHERE = gql`
mutation  signOutEverywhere ($refreshToken:String){
    signOutEverywhere(
    refreshToken:$refreshToken
    ){
    success
    }
    }
`

export const CREATE_ADVANCE_BOOKINGS = gql`
mutation BranchSettings(
    $storeId: Int!,
    $deliveryMaxAdvanceTime: Int,
    $deliveryMinAdvanceTime: Int,
    $deliveryNotificationTime: Int,
    $dineinMaxAdvanceTime: Int,
    $dineinMinAdvanceTime: Int,
    $dineinNotificationTime: Int,
    $collectionMaxAdvanceTime: Int,
    $collectionMinAdvanceTime: Int,
    $collectionNotificationTime: Int,
    $acceptAdvanceOrders: Boolean,
    $kitchenNotificationAfterAccept: Boolean,
    $dineinSlotTime: Int,
    $depositAmountForCollection: Int,
    $depositAmountForDelivery: Int,
    $depositAmountForDinein: Int,
    $depositTypeForCollection: String,
    $depositTypeForDelivery: String,
    $depositTypeForDinein: String,
    $acceptDepositForCollection: Boolean,
    $acceptDepositForDelivery: Boolean,
    $acceptDepositForDinein: Boolean,
    $applyDiscountsOnBookingDate:Boolean,
    $deliverySlotTime: Int,
    $collectionSlotTime: Int,
){
    branchSettings(
        storeId: $storeId,
        deliveryMaxAdvanceTime: $deliveryMaxAdvanceTime,
        deliveryMinAdvanceTime: $deliveryMinAdvanceTime,
        deliveryNotificationTime: $deliveryNotificationTime,
        dineinMaxAdvanceTime: $dineinMaxAdvanceTime,
        dineinMinAdvanceTime: $dineinMinAdvanceTime,
        dineinNotificationTime: $dineinNotificationTime,
        collectionMaxAdvanceTime: $collectionMaxAdvanceTime,
        collectionMinAdvanceTime: $collectionMinAdvanceTime,
        collectionNotificationTime: $collectionNotificationTime,
        acceptAdvanceOrders: $acceptAdvanceOrders,
        kitchenNotificationAfterAccept: $kitchenNotificationAfterAccept,
        dineinSlotTime: $dineinSlotTime,
        depositAmountForCollection: $depositAmountForCollection,
        depositAmountForDelivery: $depositAmountForDelivery,
        depositAmountForDinein: $depositAmountForDinein,
        depositTypeForCollection: $depositTypeForCollection,
        depositTypeForDelivery: $depositTypeForDelivery,
        depositTypeForDinein: $depositTypeForDinein,
        acceptDepositForCollection: $acceptDepositForCollection,
        acceptDepositForDelivery: $acceptDepositForDelivery,
        acceptDepositForDinein: $acceptDepositForDinein,
        applyDiscountsOnBookingDate: $applyDiscountsOnBookingDate,
        deliverySlotTime: $deliverySlotTime,
        collectionSlotTime: $collectionSlotTime,
    ) {
        errorMessage
        settings {
            id
            store {
                storeId
            }
        }
        success
    }
}
`;
export const OPEN_ITEMS_CREATE = gql`
mutation BranchSettings($storeId:Int!,
  

    $allowPosUserAdhocItem:Boolean
	$adhocItemMaxPrice:Float
	$allowDiscountOnAdhoc:Boolean
	$allowRewardOnAdhoc:Boolean
	$allowStockDeductionOnAdhoc:Boolean
    ){
    branchSettings(
        storeId:$storeId
    
    allowPosUserAdhocItem:$allowPosUserAdhocItem
	adhocItemMaxPrice:$adhocItemMaxPrice
	allowDiscountOnAdhoc:$allowDiscountOnAdhoc
	allowRewardOnAdhoc:$allowRewardOnAdhoc
	allowStockDeductionOnAdhoc:$allowStockDeductionOnAdhoc
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`

export const CREATE_MANAGING_ORDERS = gql`
mutation BranchSettings($storeId:Int!,
    $rejectingReasons:JSONString,$showReasonForRejecting:Boolean,$snoozeTimings:JSONString,$showSnoozeForAccept:Boolean,$modifyOrderRules:JSONString,$autoAcceptOrders:Boolean,
  
	
    ){
    branchSettings(
        storeId:$storeId
  
    showReasonForRejecting:$showReasonForRejecting
	rejectingReasons:$rejectingReasons
    snoozeTimings:$snoozeTimings
	showSnoozeForAccept:$showSnoozeForAccept
    modifyOrderRules:$modifyOrderRules
    autoAcceptOrders:$autoAcceptOrders
   
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`

export const MANAGE_TIPS2 = gql`
mutation BranchSettings($storeId:Int!,
    $collectTips:Boolean
	$percentageTips:JSONString
	$flatAmountTips:JSONString
	$allowSmartTips:Boolean
	$setCartAmountForSmartTips:Int
	$calculateTipAfterTaxes:Boolean
	$allowCustomTips:Boolean
	$separateTippingScreen:Boolean
    $percentageTipDetails:JSONString
	$flatAmountTipDetails:JSONString
   
    ){
    branchSettings(
        storeId:$storeId
  
    collectTips:$collectTips
	percentageTips:$percentageTips
	flatAmountTips:$flatAmountTips
	allowSmartTips:$allowSmartTips
	setCartAmountForSmartTips:$setCartAmountForSmartTips
	calculateTipAfterTaxes:$calculateTipAfterTaxes
	allowCustomTips:$allowCustomTips
	separateTippingScreen:$separateTippingScreen
    percentageTipDetails:$percentageTipDetails
	flatAmountTipDetails:$flatAmountTipDetails
   
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`

export const CREATE_ADVANCE_BOOKING = gql`
mutation BranchSettings($storeId:Int!,$deliveryMaxAdvanceTime:Int,$deliveryMinAdvanceTime:Int,$deliveryNotificationTime:Int,$dineinMaxAdvanceTime:Int,$dineinMinAdvanceTime:Int,$dineinNotificationTime:Int,$collectionMaxAdvanceTime:Int,$collectionMinAdvanceTime:Int,$collectionNotificationTime:Int,$acceptAdvanceOrders:Boolean,$paymentTypesForAdvanceBooking:JSONString,$kitchenNotificationAfterAccept:Boolean,$dineinSlotTime:Int,
    $rejectingReasons:JSONString,$showReasonForRejecting:Boolean,$snoozeTimings:JSONString,$showSnoozeForAccept:Boolean,$modifyOrderRules:JSONString,$autoAcceptOrders:Boolean,$roundingRule:String,
    $refundTimeLimit:Int
	$refundWith:JsonString
	$allowRefund:Boolean
	$refundReasons:JSONString
	$cancellationBasedOnStatus:JSONString
	$noOfMinsToAcceptCancellationForDelivery:Int
	$noOfMinsToAcceptCancellationForDinein:Int
    $collectTips:Boolean
	$percentageTips:JSONString
	$flatAmountTips:JSONString
	$allowSmartTips:Boolean
	$setCartAmountForSmartTips:Int
	$calculateTipAfterTaxes:Boolean
	$allowCustomTips:Boolean
	$separateTippingScreen:Boolean
    $percentageTipDetails:JSONString
	$flatAmountTipDetails:JSONString
    $allowPosUserAdhocItem:Boolean
	$adhocItemMaxPrice:Float
	$allowDiscountOnAdhoc:Boolean
	$allowRewardOnAdhoc:Boolean
	$allowStockDeductionOnAdhoc:Boolean
    ){
    branchSettings(
        storeId:$storeId
    deliveryMaxAdvanceTime:$deliveryMaxAdvanceTime
        deliveryMinAdvanceTime:$deliveryMinAdvanceTime          
        deliveryNotificationTime:$deliveryNotificationTime
        dineinMaxAdvanceTime:$dineinMaxAdvanceTime
        dineinMinAdvanceTime:$dineinMinAdvanceTime
        dineinNotificationTime:$dineinNotificationTime
        collectionMaxAdvanceTime:$collectionMaxAdvanceTime
        collectionMinAdvanceTime:$collectionMinAdvanceTime
        collectionNotificationTime:$collectionNotificationTime
         acceptAdvanceOrders:$acceptAdvanceOrders
        paymentTypesForAdvanceBooking:$paymentTypesForAdvanceBooking
        kitchenNotificationAfterAccept:$kitchenNotificationAfterAccept
    dineinSlotTime:$dineinSlotTime
    showReasonForRejecting:$showReasonForRejecting
	rejectingReasons:$rejectingReasons
    snoozeTimings:$snoozeTimings
	showSnoozeForAccept:$showSnoozeForAccept
    modifyOrderRules:$modifyOrderRules
    autoAcceptOrders:$autoAcceptOrders
    roundingRule:$roundingRule
    refundTimeLimit:$refundTimeLimit
	refundWith:$refundWith
	allowRefund:$allowRefund
	refundReasons:$refundReasons
	cancellationBasedOnStatus:$cancellationBasedOnStatus
	noOfMinsToAcceptCancellationForDelivery:$noOfMinsToAcceptCancellationForDelivery
	noOfMinsToAcceptCancellationForDinein:$noOfMinsToAcceptCancellationForDinein
    collectTips:$collectTips
	percentageTips:$percentageTips
	flatAmountTips:$flatAmountTips
	allowSmartTips:$allowSmartTips
	setCartAmountForSmartTips:$setCartAmountForSmartTips
	calculateTipAfterTaxes:$calculateTipAfterTaxes
	allowCustomTips:$allowCustomTips
	separateTippingScreen:$separateTippingScreen
    percentageTipDetails:$percentageTipDetails
	flatAmountTipDetails:$flatAmountTipDetails
    allowPosUserAdhocItem:$allowPosUserAdhocItem
	adhocItemMaxPrice:$adhocItemMaxPrice
	allowDiscountOnAdhoc:$allowDiscountOnAdhoc
	allowRewardOnAdhoc:$allowRewardOnAdhoc
	allowStockDeductionOnAdhoc:$allowStockDeductionOnAdhoc
      ) {
            errorMessage
            settings{id
            store{storeId}}
            success
            }
    }
`
