//write basic structure of the component
import { FormControl, InputAdornment, InputLabel, Select, TextField, MenuItem } from '@mui/material';
import React, { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import BasicRangeShortcuts from '../../components/reports/customers/filters/dateRangeFilter';
import { useTranslation } from 'react-i18next';

interface Props {
    search: string | null;
    setSearch: (search: string) => void;
    filterBy: string;
    setFilterBy: (filterBy: string) => void;
    setDate: (a: any) => void;
    date: any;

}
const Header = (props: Props) => {
    const { search, setSearch, filterBy, setFilterBy, setDate, date } = props;
    const [dateDropdownLabel, setDateDropdownLabel] = useState<string>("");
    const { t } = useTranslation()

    return (
        <div style={{ display: "flex", gap: "24px", paddingLeft: '22px', paddingBottom: "12px" }}>
            <TextField id="outlined-basic" placeholder="search by name" variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                InputProps={{
                    startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                }} />
            <FormControl >
                <InputLabel id="demo-simple-select-label">{t("reviews.filter_by")}</InputLabel>
                <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"

                    value={filterBy}
                    onChange={(e) => setFilterBy(e.target.value)}
                    label="FilterBy"
                    sx={{ width: "180px" }}

                >
                    <MenuItem value={0}>None</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                    <MenuItem value={5}>5</MenuItem>
                </Select>
            </FormControl>
            <Select
                displayEmpty
                sx={{
                    width: dateDropdownLabel.length > 10 ? 260 : 180,
                    height: 50,
                    marginRight: "30px",
                }}
                value={dateDropdownLabel}
                renderValue={(selected) => (selected ? selected : "Date")}
            >
                <BasicRangeShortcuts
                    setDate={setDate}
                    date={date}
                    setDateDropdownLabel={setDateDropdownLabel}
                />
            </Select>

        </div>
    )
}
export default Header;