import {
  InputBase,
  Box,
  Typography,
  Button,
  Autocomplete,
  TextField,
  Tooltip,
  IconButton,
  Switch,
  Divider,
  Paper,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputAdornment,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import SearchIcon from "@mui/icons-material/Search";
import { t } from "i18next";
import { useQuery } from "@apollo/client";
import { DELIVERY_LIST } from "../../graphicalQl/usequery/deliveryManagementQuery";
import CircularLoader from "../../loader/loader";
import { useTranslation } from "react-i18next";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

import "./deliveryList.css";
import { useNavigate } from "react-router";
import CustomFooter from "../category/footer";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";

function DeliveryList() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [rows, setRows] = React.useState<any[]>([]);
  const [searchInput, setSearchInput] = React.useState<string | null>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const handleUserInputChange = (text: string) => {
    setSearchInput(text);
  };

  const filteredDataFromNull = {
    businessId: +businessId,
    query: searchInput,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(filteredDataFromNull).filter(([_, value]) => value !== null)
  );

  const {
    loading: delivertListLoading,
    error: delivertListError,
    data: delivertListData,
  } = useQuery(DELIVERY_LIST, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (delivertListData) {
      const { storeList } = delivertListData;
      const { edges } = storeList;
      const filteredStoreList = edges.map((eachStore: any) => ({
        id: eachStore.node.storeId,
        storeName: eachStore.node.storeName,
      }));
      setRows(filteredStoreList);
    }
  }, [delivertListData]);

  const handleEditIcon = (id: string) => {
    navigate(`/${id}/delivery/zone`);
  };

  const getRowClassName = () => {
    return "user-list-table-row user-role-user-names-hover";
  };
  const columns: GridColDef[] = [
    {
      field: "storeName",
      headerClassName: "delivery-list-table-header-name",
      //@ts-ignore
      headerName: t("deliveries.store_name"),
      flex: 3,
      renderCell: (params) => (
        <Box>
          <Typography sx={{ textTransform: "capitalize" }}>
            {params.row.storeName}
          </Typography>
        </Box>
      ),
    },
    {
      field: "actions",
      //@ts-ignore
      headerName: t("deliveries.actions"),
      headerClassName: "delivery-list-table-header-name",
      flex: 0.5,
      renderCell: (params) => {
        return (
          <Box>
            <Tooltip title={t("buttons.edit")} placement="top" arrow>
              <IconButton onClick={() => handleEditIcon(params.row.id)}>
                <EditIcon />
              </IconButton>
            </Tooltip>
          </Box>
        );
      },
    },
  ];

  const handleAddNewLocationButton = () => {
    navigate("/location/create-location");
  };

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(delivertListData?.storeList?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(delivertListData?.storeList?.pageInfo.startCursor || null);
  };
  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };
  return (
    <Box sx={{ margin: "1%" }}>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "10px",
          width: "100%",
        }}
      >
        <Typography className="delivery-list-header-text">
          {t("deliveries.delivery")}
        </Typography>
        <Button
          onClick={handleAddNewLocationButton}
          variant="contained"
          className="delivery-list-header-button"
        >
          {t("deliveries.add_new_store")}
        </Button>
      </Box>

      <DelayedInput
        width="30%"
        margin="10px 0px 10px 8px"
        setSearchValue={setSearchInput}
        placeHolder={t("users.search_by_name")}
        size={"medium"}
      />

      {delivertListLoading ? (
        <CircularLoader />
      ) : (
        <Box
          sx={{
            height: "70vh",
            padding: "10px",
          }}
        >
          <DataGridTable
            shouldUpdatePaginationModelIsTrue={
              shouldUpdatePaginationModelIsTrue
            }
            handleUpdatingValuesToNull={handleUpdatingValuesToNull}
            paginationModel={paginationModel}
            rows={rows}
            columns={columns}
            getRow={getRowClassName}
            totalCount={delivertListData?.storeList?.totalCount || 0}
            handleEndCursor={handleEndCursor}
            handleStartCursor={handleStartCursor}
            currentPage={currentPage}
            imageHeight="250px"
            imageWidth="300px"
            heightForContainer="50vh"
          />
        </Box>
      )}
    </Box>
  );
}

export default DeliveryList;
