import React, { useEffect } from "react";
import {
  Box,
  Typography,
  Checkbox,
  FormControl,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  List,
  ListItem,
  Button,
  FormGroup,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { StaticDateTimePicker } from "@mui/x-date-pickers/StaticDateTimePicker";
import { useState } from "react";
import { Dayjs } from "dayjs";
import "../options/createProduct.css";
import "../category/createCategory.css";
import { useDispatch, useSelector } from "react-redux";
import { setVisibility } from "../reduxReducers/categoryReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useQuery } from "@apollo/client";
import { GET_SHOWON_DATA } from "../../graphicalQl/usequery/menuQuery";
import { useTranslation } from "react-i18next";
import { TimePicker } from "@mui/x-date-pickers";
import moment from "moment";

interface Props {
  categoryId: number | null | any;
  showOnData: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const style2 = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",

  boxShadow: 24,
  p: 4,
};

const validationSchema = Yup.object({
  endTime: Yup.string()
    .nullable()  // Allow null values
    .test('is-greater', 'End time must be greater than  start time', function (value) {
      const { startTime } = this.parent;
      // console.log(moment(startTime, 'hh:mm a').format('HH:mm'))
      // console.log(moment(value, 'hh:mm a').format('HH:mm'))

      if (startTime && value) {
        return moment(value, 'hh:mm a').format('HH:mm') > moment(startTime, 'hh:mm a').format('HH:mm');  // Check if endTime is greater than or equal to startTime
      }

      return true;  // If either value is not provided, don't run the validation
    })
    // Ensure endTime is required
    .notRequired()  // But allow it to be null
});
export const Visibility = (props: Props) => {
  const { t } = useTranslation();

  const { categoryId } = props;
  const dispatch = useDispatch();
  const {
    radioValue,
    startTime,
    endTime,
    weekDays,
    selectDate,
    showOnCheckBox,
    hideUntilCheckBox,
  } = useSelector((state: any) => state.categoryVisibilty);

  const [radioValue1, setValue] = React.useState("");
  useEffect(() => {
    setValue(radioValue);
  }, [radioValue]);

  const [open, setOpen] = React.useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleStore = () => {
    dispatch(setVisibility("startTime", ""));
    dispatch(setVisibility("endTime", ""));
  };
  const handleOpen = () => {
    if (!showOnCheckBox) {
      setOpen(true);
    }
  };
  const [openValue, setOpenValue] = React.useState(false);
  const handleCloseValue = () => {
    setOpenValue(false);
  };
  const handleOpenValue = () => {
    if (!hideUntilCheckBox) {
      setOpenValue(true);
    }
  };

  const formik = useFormik({
    initialValues: {
      startTime: startTime !== "" ? startTime : null,
      endTime: endTime !== "" ? endTime : null,
      showOnCheckBox: showOnCheckBox,
      hideUntilCheckBox: hideUntilCheckBox,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      handleClose();
    },
  });

  useEffect(() => {
    formik.setValues({
      startTime:
        startTime !== "" ? moment(startTime, "hh:mm a").format("h:mm a") : null,
      endTime:
        endTime !== "" ? moment(endTime, "hh:mm a").format("h:mm a") : null,
      showOnCheckBox: showOnCheckBox,
      hideUntilCheckBox: hideUntilCheckBox,
    });
  }, [startTime, endTime, showOnCheckBox, hideUntilCheckBox]);

  const [weekdaysState, setWeekdaysState] = useState(weekDays);
  const [allDayChecked, setAllDayChecked] = useState<boolean | string>(false);

  const updateAllDayCheckedState = (updatedWeekdays: any) => {
    const allChecked = updatedWeekdays.every((day: any) => day.isChecked);
    const someChecked = updatedWeekdays.some((day: any) => day.isChecked);
    if (allChecked) {
      setAllDayChecked(true);
    } else if (someChecked) {
      setAllDayChecked("indeterminate");
    } else {
      setAllDayChecked(false);
    }
  };

  const handleCheckboxChange =
    (id: number) => (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedWeekdays = weekdaysState.map((day: any) => {
        if (day.id === id) {
          return { ...day, isChecked: event.target.checked };
        }
        return day;
      });
      setWeekdaysState(updatedWeekdays);
      updateAllDayCheckedState(updatedWeekdays);
    };

  useEffect(() => {
    updateAllDayCheckedState(weekDays);
  }, [weekDays]);

  const handleAllDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isChecked = event.target.checked;
    const updatedWeekdays = weekdaysState.map((day: any) => ({
      ...day,
      isChecked,
    }));
    setWeekdaysState(updatedWeekdays);
    updateAllDayCheckedState(updatedWeekdays);
  };

  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs(selectDate));

  const handleDateChange = (newDate: Dayjs | null) => {
    if (newDate) {
      setSelectedDate(newDate);
      const serializedDate = newDate.toISOString();

      dispatch(setVisibility("selectDate", serializedDate));
    }
  };
  const formattedDateAndTime = selectedDate.format("D MMM hh:mm A");

  useEffect(() => {
    dispatch(setVisibility("weekDays", weekdaysState));
  }, [weekdaysState]);

  useEffect(() => {
    setWeekdaysState(weekDays);
  }, [weekDays]);

  useEffect(() => {
    setSelectedDate(dayjs(selectDate));
  }, [selectDate]);

  const { data: menuData } = useQuery(GET_SHOWON_DATA, {
    context: { clientName: "categoryClient" },
    variables: { menuId: categoryId && +categoryId },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (menuData && menuData.menu) {
      if (menuData && menuData.menu.hideUntil) {
        dispatch(setVisibility("hideUntilCheckBox", true));
      }
      if (menuData.menu.showOn) {
        dispatch(setVisibility("showOnCheckBox", true));
        dispatch(
          setVisibility("weekDays", [
            {
              id: 2,
              day: "Monday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Monday?.isOpen || false,
            },
            {
              id: 3,
              day: "Tuesday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Tuesday?.isOpen || false,
            },
            {
              id: 4,
              day: "Wednesday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Wednesday?.isOpen || false,
            },
            {
              id: 5,
              day: "Thursday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Thursday?.isOpen || false,
            },
            {
              id: 6,
              day: "Friday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Friday?.isOpen || false,
            },
            {
              id: 7,
              day: "Saturday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Saturday?.isOpen || false,
            },
            {
              id: 8,
              day: "Sunday",
              isChecked:
                JSON.parse(menuData.menu.showOn)?.Sunday?.isOpen || false,
            },
          ])
        );
      }
      menuData.menu.showOn &&
        dispatch(
          setVisibility(
            "startTime",
            JSON.parse(menuData.menu.showOn)?.Monday?.timings[0].openTime || ""
          )
        );

      menuData.menu.showOn &&
        dispatch(
          setVisibility(
            "endTime",
            JSON.parse(menuData.menu.showOn)?.Monday?.timings[0].closingTime || ""
          )
        );
    }
  }, [menuData]);

  return (
    <Box>
      <Box sx={{ display: "flex" }}>
        <Typography
          className="create-product-details"
          sx={{ marginRight: "2%" }}
        >
          {t("menu.catageory.visibility")}
          <span className="createCategory-para">
            ({t("menu.catageory.availabityOfCategory")}){" "}
          </span>
        </Typography>
        {showOnCheckBox && startTime && (
          <Typography className="category-visibility-showOnly">
            {t("menu.catageory.only_from")} {startTime} {t("menu.catageory.to")}{" "}
            {endTime}
          </Typography>
        )}
        {hideUntilCheckBox && (
          <Typography className="category-visibility-until">
            {t("menu.catageory.until")} {formattedDateAndTime}
          </Typography>
        )}
      </Box>
      <FormControl>
        <FormGroup row style={{ marginTop: "2%" }}>
          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.showOnCheckBox}
                onChange={(event) => {
                  dispatch(
                    setVisibility("showOnCheckBox", event.target.checked)
                  );
                }}
                color="warning"
              />
            }
            label={
              <Typography className="createCategory-radio-text">
                {t("menu.catageory.showOnlyFrom")}
              </Typography>
            }
            onClick={handleOpen}
          />

          <FormControlLabel
            control={
              <Checkbox
                checked={formik.values.hideUntilCheckBox}
                onChange={(event) => {
                  dispatch(
                    setVisibility("hideUntilCheckBox", event.target.checked)
                  );
                }}
                color="warning"
              />
            }
            label={
              <Typography className="createCategory-radio-text">
                {t("menu.catageory.hideUntil")}
              </Typography>
            }
            onClick={handleOpenValue}
          />
        </FormGroup>
      </FormControl>
      <div>
        <Modal
          open={open}
          // onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <form onSubmit={formik.handleSubmit}>
            <Box sx={style}>
              <Box sx={{ textAlign: "center" }}>
                <Typography className="createCategory-table-head">
                  {t("menu.catageory.showTime")}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-around",
                    marginTop: "2%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <Typography className="create_category_startTime">{t("menu.catageory.startTime")}</Typography>
                    <TextField
                      type="time"
                      name="startTime"
                      color="warning"
                      value={formik.values.startTime}
                      onChange={(event) => {
                        dispatch(setVisibility("startTime", event.target.value));
                        formik.handleChange(event);
                      }} onBlur={formik.handleBlur}
                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker", "TimePicker"]}>
                        <TimePicker
                          label="Start Time"
                          value={
                            formik.values.startTime
                              ? dayjs(formik.values.startTime, "hh:mm a")
                              : null
                          }
                          onChange={(newValue) => {
                            const timeValue = newValue
                              ? newValue.format("hh:mm a")
                              : "";
                            formik.handleChange({
                              target: {
                                name: "startTime",
                                value: timeValue,
                              },
                            });
                            dispatch(setVisibility("startTime", timeValue));
                            formik.setFieldTouched("startTime", true);
                          }}
                          sx={{
                            width: "150px",
                            "&.MuiFormControl-root.MuiTextField-root": {
                              minWidth: "unset",
                            },
                            "&.MuiFormLabel-root.Mui-error": {
                              color: "inherit",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>

                    {formik.touched.startTime && formik.errors.startTime && (
                      <div style={{ color: "red" }}>
                        {formik.errors.startTime as string}
                      </div>
                    )}
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    {/* <Typography className="create_category_startTime">{t("menu.catageory.endTime")}</Typography>
                    <TextField

                      type="time"
                      name="endTime"
                      color="warning"
                      value={formik.values.endTime}
                      onChange={(event) => {
                        dispatch(setVisibility("endTime", event.target.value));
                        formik.handleChange(event);
                      }} onBlur={formik.handleBlur}

                    /> */}
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker", "TimePicker"]}>
                        <TimePicker
                          label="End Time"
                          value={
                            formik.values.endTime
                              ? dayjs(formik.values.endTime, "hh:mm a")
                              : null
                          }
                          onChange={(newValue) => {
                            const timeValue = newValue
                              ? newValue.format("hh:mm a")
                              : "";
                            formik.handleChange({
                              target: {
                                name: "endTime",
                                value: timeValue,
                              },
                            });
                            dispatch(setVisibility("endTime", timeValue));

                            formik.setFieldTouched("endTime", true);
                          }}
                          sx={{
                            width: "150px",
                            "&.MuiFormControl-root.MuiTextField-root": {
                              minWidth: "unset",
                            },
                            "&.MuiFormLabel-root.Mui-error": {
                              color: "inherit",
                            },
                          }}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>
                </Box>
                <Box sx={{ marginTop: "1%" }}>
                  {formik.touched.endTime && formik.errors.endTime && (
                    <div style={{ color: "red" }}>
                      {formik.errors.endTime as string}
                    </div>
                  )}
                </Box>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="warning"
                      checked={allDayChecked === true}
                      indeterminate={allDayChecked === "indeterminate"}
                      onChange={handleAllDayChange}
                    />
                  }
                  label={
                    <Typography className="createCategory-row2">
                      {t("menu.catageory.all")}
                    </Typography>
                  }
                  className="createCategory-checkbox2"
                />
                <List style={{ display: "flex", flexWrap: "wrap" }}>
                  {weekdaysState.map((each: any) => (
                    <ListItem
                      key={each.id}
                      style={{ width: "45%", marginLeft: "5%" }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="warning"
                            checked={each.isChecked}
                            onChange={handleCheckboxChange(each.id)}
                          />
                        }
                        label={
                          <Typography className="createCategory-row2">
                            {each.day}
                          </Typography>
                        }
                        className="createCategory-checkbox"
                      />
                    </ListItem>
                  ))}
                </List>
              </Box>
              <Box sx={{ width: "100%", mt: "12px" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    className="create-product-cancel_Button"
                    onClick={() => {
                      handleClose();
                      handleStore();
                    }}
                    sx={{ marginRight: "10px" }}
                  >
                    {t("menu.catageory.cancel")}
                  </Button>

                  <Button className="main-button" type="submit">
                    {t("menu.catageory.save")}
                  </Button>
                </Box>
              </Box>
            </Box>
          </form>
        </Modal>
        <Modal
          open={openValue}
          onClose={handleCloseValue}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DemoContainer components={["StaticDateTimePicker"]}>
                <DemoItem>
                  <StaticDateTimePicker
                    disablePast={true}
                    value={selectedDate}
                    onChange={handleDateChange}
                    components={{
                      ActionBar: () => (
                        <div
                          style={{
                            position: "fixed",
                            bottom: 0,
                            left: 0,
                            right: 0,
                            padding: "10px",
                            backgroundColor: "white",
                            textAlign: "right",
                          }}
                        >
                          <Button
                            key="accept"
                            style={{
                              backgroundColor: "#F38B08",
                              color: "#FEFEFE",
                            }}
                            onClick={handleCloseValue}
                          >
                            Accept
                          </Button>
                        </div>
                      ),
                    }}
                  />
                </DemoItem>
              </DemoContainer>
            </LocalizationProvider>
          </Box>
        </Modal>
      </div>
    </Box>
  );
};
