import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Divider, IconButton, TextField } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

interface Props {
    setCustomModal: any;
    customModal: boolean;
    setModal: any
}

export default function CustomDomainModal(props: Props) {
    const { customModal, setCustomModal, setModal } = props

    const handleClose = () => {
        setCustomModal(false);
        setModal(true)
    };

    return (
        <div>

            <Modal
                open={customModal}
                // onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box >
                        <IconButton className="exit-container" onClick={handleClose}>
                            <ArrowBackIcon className="exitIcon" />
                        </IconButton>
                    </Box>
                    <Divider sx={{ marginTop: '12px' }} />
                    <Box sx={{ padding: "12px 36px" }}>
                        <Typography className="main-head16">Enter your Domain Name:</Typography>
                        <TextField fullWidth placeholder='mystore.co.in' sx={{ marginTop: '12px' }} />
                        <Box sx={{
                            display: 'flex',
                            width: '100%',
                            padding: '12px',
                            flexDirection: 'column',
                            alignItems: 'flex-start',
                            gap: '20px',
                            background: '#FBFBFB',
                        }}
                        >
                            <Box sx={{
                                display: 'flex',
                                padding: '4px 8px',
                                alignItems: 'flex-start',
                                gap: '10px',
                                borderRadius: '4px',
                                background: '#EEE',
                            }}>
                                <Typography className="url-text-style-grey">
                                    Domain name servers are listed on{' '}
                                    <span className="url-text-style-orange">Manage DNS</span> page.
                                </Typography>

                            </Box>
                            <Typography className='tablecell-text2'>If you are using external DNS, please point the DNS  A records for new domains to {' '}
                                <span className='url-text-style-red'> 20.11.971.1.0. </span>
                                SSL certificate will be generated automatically in a few minutes after the IP address is updated.</Typography>

                        </Box>
                    </Box>
                    <Box sx={{ width: "100%", textAlign: "right", marginTop: '12px' }}>
                        <Button className="main-button" >Next <ArrowForwardIcon /> </Button>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
