import { gql } from "@apollo/client";

export const TOP_PRODUCT_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query TopProductsReports {
        topProductsReports(
            toDate: "${toDate}"
            fromDate: "${fromDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}

        )
    }
  `;
};

export const PRODUCT_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
    query ProductsReports {
      productsReports(
        locationId: ${locationId}
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime: "${fromTime}"
        toTime: "${toTime}"
        ${employeeParam}
      )
    }
  `;
};
