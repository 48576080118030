import { Box, Button, Sheet, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/close2.png";
import { TextField } from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import {
  GENERATE_OTP,
  UPDATE_BUSINESS_MAIL,
  UPDATE_BUSINESS_MOBILE,
  VALIDATE_OTP,
} from "../../../../../graphicalQl/mutation/customerDataMutation";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

interface Props {
  verificationCodeModal: any;
  setVerificationCodeModal: any;
}

const UpdatePhoneModal = (props: Props) => {
  const { t } = useTranslation();
  const { verificationCodeModal, setVerificationCodeModal } = props;
  const [viewMode, setViewMode] = useState(
    verificationCodeModal?.data?.viewMode === "verify" ? "verify" : "sendCode"
  );
  const [mobile, setMobile] = useState(verificationCodeModal?.data?.mobile);
  const [otp, setOtp] = useState("");

  const MuiOtpInputStyled = styled(MuiOtpInput)({
    "& .MuiOtpInput-TextField": {
      width: "36px",
      height: "40px",
    },
    "& .MuiInputBase-root": {
      width: "36px",
      height: "40px",
    },
  });

  const matchIsNumeric = (text: any) => {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  };
  const [
    generateOTPMutation,
    { data: generateOTPData, error: generateOTPError },
  ] = useMutation(GENERATE_OTP, {
    context: { clientName: "userClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.otp_sent")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [
    validateOTPMutation,
    { data: validateOTPData, error: validateOTPError },
  ] = useMutation(VALIDATE_OTP, {
    context: { clientName: "userClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.otp_verified")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [
    updateBusinessMutation,
    { data: updateBusinessMutationData, error: updateBusinessMutationError },
  ] = useMutation(UPDATE_BUSINESS_MOBILE, {
    context: { clientName: "businessClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  useEffect(() => {
    if (generateOTPData?.customerOtpGeneration?.authenticate?.generatedAt) {
      // alert("OTP sent successfully");
      setViewMode("verify");
    }
  }, [generateOTPData]);

  useEffect(() => {
    if (verificationCodeModal?.data?.viewMode === "verify") {
      generateOTP();
    }
  }, []);

  useEffect(() => {
    if (updateBusinessMutationData?.updateBusiness?.success) {
      // alert("Mobile updated successfully");
      verificationCodeModal?.data?.refetchBusinessDetails();
      setVerificationCodeModal({
        state: false,
        data: {},
      });
    }
  }, [updateBusinessMutationData]);

  useEffect(() => {
    if (validateOTPData?.customerAuthentication?.authenticated) {
      // alert("OTP verified successfully");
      updateBusinessMutation({
        variables: {
          businessId: verificationCodeModal.data.businessId,
          mobile,
        },
      });
    }
  }, [validateOTPData]);

  const generateOTP = () => {
    generateOTPMutation({
      variables: {
        mobile,
        businessId: parseInt(verificationCodeModal.data.businessId),
      },
    });
  };
  const [errorStatus, setErrorStatus] = useState(false);

  const verifyOTP = () => {
    validateOTPMutation({
      variables: {
        mobile,
        businessId: parseInt(verificationCodeModal.data.businessId),
        otp: otp,
        // isAdminVerification: true,
      },
      onError: (error) => {
        setErrorStatus(true);
      },
    });
  };

  const [disable, setDisable] = useState(true);
  const handleComplete = () => {
    setDisable(!disable);
  };
  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "720px",
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
        ...(viewMode === "verify" && { paddingBottom: "120px" }),
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() => {
            setVerificationCodeModal({
              state: false,
              data: {},
            });
            setErrorStatus(false);
          }}
        />
        {viewMode === "verify" ? (
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            <Button
              onClick={() => {
                setViewMode("sendCode");
                setErrorStatus(false);
                setOtp("");
              }}
              sx={{
                width: "97px",
                height: "44px",
                backgroundColor: "#EEE",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#F38B08",
                  textTransform: "capitalize",
                }}
              >
                {t("two_factor.back")}
              </Typography>
            </Button>
            <Button
              onClick={() => verifyOTP()}
              sx={{
                width: "155px",
                height: "44px",
                backgroundColor: "#F38B08",
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#F38B08",
                },
              }}
              disabled={disable}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#FEFEFE",
                  textTransform: "capitalize",
                }}
              >
                {t("two_factor.verify")}
              </Typography>
            </Button>
          </Box>
        ) : (
          <></>
        )}
      </Box>

      <Box
        sx={{
          marginLeft: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          {viewMode === "sendCode"
            ? t("two_factor.change_mobile_number")
            : t("two_factor.enter_verifiaction_code")}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "32px",
          }}
        >
          {viewMode === "sendCode" ? (
            <span>{t("two_factor.mobile_para")}</span>
          ) : (
            <div>
              {t("two_factor.mai_text3")} {mobile}. {t("two_factor.mai_text4")}
              <br />
              <div style={{ color: "#F38B08" }}>
                {" "}
                {t("two_factor.resend_code")}
              </div>
            </div>
          )}
        </Typography>
      </Box>

      {viewMode === "sendCode" ? (
        <Box
          sx={{
            display: "flex",
            marginLeft: "22px",
            columnGap: "20px",
          }}
        >
          <TextField
            type="text"
            fullWidth
            sx={{
              width: "280px",
              height: "48px",
            }}
            label={t("two_factor.mobile")}
            //@ts-ignore
            placeholder={t("two_factor.mobile")}
            id="outlined-size-normal"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
        </Box>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "480px",
              height: "60px",
              padding: "10px 62px",
              borderRadius: "4px",
              border: "1px solid #EEE",
              marginLeft: "22px",
              marginTop: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MuiOtpInput
              validateChar={matchIsNumeric}
              TextFieldsProps={{ placeholder: "-" }}
              autoFocus
              value={otp}
              length={4}
              onComplete={handleComplete}
              onChange={(newValue) => {
                setOtp(newValue);
                setDisable(true);
                setErrorStatus(false);
              }}
              sx={{
                "& .MuiOtpInput-TextField": {
                  width: "36px",
                  height: "40px",
                },
                "& .MuiInputBase-root": {
                  width: "36px",
                  height: "40px",
                },
              }}
            />
          </Box>
          {errorStatus && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
                marginTop: "12px",
              }}
            >
              {t("two_factor.enter_valid_otp")}
            </Typography>
          )}
        </Box>
      )}

      {viewMode === "sendCode" ? (
        <Box
          sx={{
            margin: "33px 0 66px 22px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "400",
              fontFamily: "Poppins",
              color: "#707070",
            }}
          >
            {t("two_factor.mobile_para_2")}
          </Typography>

          <Box
            sx={{
              display: "flex",
              gap: "20px",
              marginTop: "20px",
              justifyContent: "flex-end",
            }}
          >
            <>
              {/* <Button
                onClick={() =>
                  updateBusinessMutation({
                    variables: {
                      businessId: verificationCodeModal.data.businessId,
                      mobile,
                    },
                  })
                }
                sx={{
                  width: "fit-content",
                  height: "44px",
                  backgroundColor: "#EEE",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#F38B08",
                    textTransform: "capitalize",
                  }}
                >
                  {t("two_factor.skip_verify")}
                </Typography>
              </Button> */}
              <Button
                onClick={() => generateOTP()}
                sx={{
                  width: "fit-content",
                  height: "44px",
                  backgroundColor: "#F38B08",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FEFEFE",
                    textTransform: "capitalize",
                  }}
                >
                  {t("two_factor.continue_to_verify")}
                </Typography>
              </Button>
            </>
          </Box>
        </Box>
      ) : (
        <Box></Box>
      )}
    </Sheet>
  );
};

export default UpdatePhoneModal;
