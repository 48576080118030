import { gql } from "@apollo/client";

// export const ACTIVITY_FOR_DASHBOARD = gql`
//   query orderClient {
//     activityForDashboard(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-04-29"
//       # 		fromTime:"08:00:00"
//       # 		toTime:"23:00:00"
//     )
//   }
// `;

export const ACTIVITY_FOR_DASHBOARD = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locations: string
) => {
  return gql`
     query orderClient {
       activityForDashboard(
         locationId: "${+locations}"
         fromDate: "${fromDate}"
         toDate: "${toDate}"
         fromTime:"${fromTime}"
         toTime:"${toTime}"
       )
     }
   `;
};

export const CUSTOMER_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locations: string
) => {
  if (!fromTime.includes("NaN") || !toTime.includes("NaN")) {
    return gql`
       query orderClient {
        customerReports(
           locationId: "${+locations}"
           fromDate: "${fromDate}"
           toDate: "${toDate}"
           fromTime:"${fromTime}"
           toTime:"${toTime}"
         )
       }
     `;
  }
  return null;
};

// export const CUSTOMER_REPORTS = gql`
//   query orderClient {
//     customerReports(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-03-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//     )
//   }
// `;
