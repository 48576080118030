import React from "react";
import "./deletePopup.css";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Divider, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./deletePopup.css";

interface Props {
  open: boolean;
  handleClose: () => void;
  handleOpen: () => void;
  handleSaveButton: () => void;
  mainText: string;
  button1Text: string;
  button2Text: string;
  popUpwidth: string;
}

const DeletePopup = (props: Props) => {
  const {
    open,
    handleClose,
    handleOpen,
    popUpwidth,
    handleSaveButton,
    mainText,
    button1Text,
    button2Text,
  } = props;
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: popUpwidth,
    bgcolor: "background.paper",
    borderRadius: "8px",
    p: 3,
  };
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box>
          <IconButton
            onClick={handleClose}
            className="create-a-use-role-back-arrow-icon-button create-a-use-role-back-arrow-button-hover"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider
          sx={{ marginTop: "5px", marginBottom: "5px", width: "100%" }}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "5px",
            marginBottom: "5px",
          }}
        >
          <Typography
            className="comfirmation-popup-text"
            sx={{ color: "#707070" }}
          >
            {mainText}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            width: "100%",
            gap: "10px",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Button
            onClick={handleSaveButton}
            className="delete-popup--outlined-button"
            variant="outlined"
          >
            {button1Text}
          </Button>
          <Button
            onClick={handleClose}
            className="delete-popup--contained-button"
            variant="contained"
          >
            {button2Text}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default DeletePopup;
