import {
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CloseIcon from "@mui/icons-material/Close";

const timePopupStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 3,
};

interface State {
  open: boolean;
  handleClose: () => void;
  formik: any;
}

const TimeRangePicker = (props: State) => {
  const { t } = useTranslation();
  const { open, handleClose, formik } = props;

  const handlePopupClearButton = () => {
    formik.resetForm();
  };

  // useEffect(() => {
  //     if (formik.values.startTime && formik.values.endTime) {
  //         if (formik.values.startTime > formik.values.endTime) {
  //             formik.setFieldValue("startTime", "");
  //         } else if (formik.values.endTime < formik.values.startTime) {
  //             formik.setFieldValue("endTime", "");
  //         }
  //     }
  // }, [formik.values.startTime, formik.values.endTime]);

  return (
    <Box>
      <Modal open={open}>
        <Box sx={timePopupStyle}>
          <form onSubmit={formik.handleSubmit}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "58%",
              }}
            >
              <IconButton
                onClick={handleClose}
                className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
              >
                <CloseIcon />
              </IconButton>
              <Typography className="delete-pop-up-text">
                {t("deliveries.set_time")}
              </Typography>
            </Box>

            <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />

            <Box sx={{ textAlign: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1%",
                  marginLeft: "3%",
                }}
              >
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography>{t("deliveries.start_time")}</Typography>
                  <TextField
                    color="warning"
                    type="time"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    name="startTime"
                    value={formik.values.startTime}
                    sx={{ width: "90%" }}
                  />
                </Box>
                <Box
                  sx={{
                    width: "50%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <Typography>{t("deliveries.end_time")}</Typography>
                  <TextField
                    color="warning"
                    type="time"
                    name="endTime"
                    onChange={(e) => {
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    value={formik.values.endTime}
                    sx={{ width: "90%" }}
                  />
                </Box>
              </Box>
            </Box>
            {(formik.touched.startTime && formik.errors.startTime) ||
            (formik.touched.endTime && formik.errors.endTime) ? (
              <Box>
                <Typography
                  sx={{
                    marginBottom: "5px",
                    marginLeft: "13px",
                    marginTop: "5px",
                  }}
                  className="create-a-user-formik-label"
                >
                  {formik.errors.endTime ===
                  "End time must be greater than start time"
                    ? t("error.end_time")
                    : t("error.required")}
                </Typography>
              </Box>
            ) : null}
            <Box sx={{ textAlign: "right", marginTop: "20px" }}>
              <Button
                onClick={handlePopupClearButton}
                sx={{ textTransform: "none", marginRight: "8px" }}
                variant="outlined"
                color="warning"
              >
                {t("buttons.clear")}
              </Button>
              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                variant="contained"
                color="warning"
              >
                {t("devices.done")}
              </Button>
            </Box>
          </form>
        </Box>
      </Modal>
    </Box>
  );
};

export default TimeRangePicker;
