import { Box } from "@mui/material";
import Select from "@mui/material/Select";
import BasicRangeShortcuts from "../customers/filters/dateRangeFilter";
import React, { FC, useEffect, useState } from "react";
import LocationSelection from "./locationSelection";
import styled from "styled-components";
import DaysSelectionTest from "./DaysSelectionTest";
import EmployeeSelection from "./EmployeeSelection";
import { useSelector } from "react-redux";
import { useQuery } from "@apollo/client";
import { GETTING_LOCATIONS_LIST } from "../../../graphicalQl/usequery/locationQuery";
import { EmplpoyeesList } from "../../../graphicalQl/usequery/userProfileUseQuery";
import { setReportData } from "../../../pages/reduxReducers/repoertReducer";
import { useDispatch } from "react-redux";

export interface DateType {
  startDate: string;
  endDate: string;
}

interface Locations {
  id: string;
  status: string;
  storeId: string;
  storeName: string;
  isChecked: boolean;
}
interface Props {
  setDate: (a: DateType) => void;
  date: DateType;
  setFromTime?: (a: string) => void;
  setToTime?: (a: string) => void;
  employee?: boolean;
  // setEmployee?: (a: string) => void;
  // locations: string;
  // setLocations: (a: string) => void;
}

const ReportsHeader: FC<Props> = ({
  setDate,
  date,
  setFromTime,
  setToTime,
  employee,
  // setEmployee,
}) => {
  const [dateDropdownLabel, setDateDropdownLabel] = useState<string>("");
  const HeaderFormWrapper = styled("div")({
    ".selectTag > div": {
      paddingTop: "0px",
      paddingBottom: "0px",
      display: "flex",
      alignItems: "center",
      height: "44px",
      lineHeight: "44px",
    },
    ".css-3dzjca-MuiPaper-root-MuiPopover-paper-MuiMenu-paper": {
      marginTop: "100px",
    },
  });

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const { data: locationsData } = useQuery(GETTING_LOCATIONS_LIST, {
    context: { clientName: "businessClient" },
    variables: { businessId: +businessId },
  });
  const [locationsList, seLocationsList] = useState<Locations[]>([]);
  const dispatch = useDispatch();
  const { locationId } = useSelector((state: any) => state.headerLocation);
  useEffect(() => {
    if (locationsData) {
      const requiredDataList: Locations[] = locationsData.storeList.edges.map(
        (each: any) => ({
          storeId: each.node.storeId,
          storeName: each.node.storeName,
          isChecked: false,
        })
      );

      seLocationsList(requiredDataList);

      dispatch(setReportData("reportLocation", locationId));
    }
  }, [locationsData]);

  const { reportLocation } = useSelector((state: any) => state.reportData);

  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(EmplpoyeesList, {
    context: { clientName: "userClient" },
    variables: {
      businessId: +businessId,
      locationId: (reportLocation && +reportLocation) || +locationId,
    },
  });
  const [employeeList, setEmployeeList] = useState<
    { id: string; label: string }[]
  >([]);
  useEffect(() => {
    if (employeesData) {
      if (employeesData !== null) {
        const requiredDataList = employeesData.employees.edges.map(
          (each: any) => ({
            id: each.node.uuid,
            label: `${each.node.firstName} ${each.node.lastName}`,
          })
        );
        setEmployeeList(requiredDataList);
      }
    }
  }, [employeesData]);
  return (
    <HeaderFormWrapper>
      <Box
        sx={{
          display: "flex",
          height: "44px",
          position: "static",
          "& .MuiOutlinedInput-root": {
            "&:focused fieldset": { borderColor: "#f39f08" },
            "&.Mui-focused fieldset": { borderColor: "#f39f08" },
          },
        }}
      >
        <Select
          displayEmpty
          sx={{
            width: dateDropdownLabel.length > 10 ? 230 : 180,
            height: 44,
            marginRight: "30px",
          }}
          value={dateDropdownLabel}
          renderValue={(selected) => (selected ? selected : "This Month")}
        >
          <BasicRangeShortcuts
            setDate={setDate}
            date={date}
            setDateDropdownLabel={setDateDropdownLabel}
          />
        </Select>

        <DaysSelectionTest />
        <div>
          <LocationSelection locationsList={locationsList} />
        </div>
        {employee ? (
          <div style={{ marginLeft: "24px" }}>
            <EmployeeSelection employeeList={employeeList} />
          </div>
        ) : null}
      </Box>
    </HeaderFormWrapper>
  );
};

export default ReportsHeader;
