import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import './createCategory.css'
import { useEffect } from 'react';
import { useTranslation } from "react-i18next";


interface Props {
  id: string;
  handleStepByProductModal: () => void;
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "50%",
  bgcolor: 'background.paper',

  boxShadow: 24,
  p: 4,
};

export default function ProductDailouge(props: Props) {
  const { id, handleStepByProductModal } = props
  const { t } = useTranslation();

  const [open, setOpen] = React.useState(false);


  const handleClose = () => {
    setOpen(false);

  };


  useEffect(() => {
    if (id === "") {
      setOpen(true)

    }

  }, [id])

  const handleOkayButton = () => {
    handleStepByProductModal()
  }
  return (
    <div>

      <Modal
        open={open}

        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", width: "60%", justifyContent: "space-between" }}>
            {/* <Box className="create-product-exit" sx={{display:"flex",justifyContent:"center",alignItems:"center"}} onClick={handleClose}>
    <CloseIcon className="create-product-exitIcon"  />
  </Box> */}


          </Box>

          <Box sx={{ padding: "3% 1% 3% 1%", marginTop: '1%', textAlign: 'center' }}>


            <Typography className='category-created'>

              {t("menu.catageory.please_fill")} <span className='create_category_product_text'>{t("menu.catageory.general_details")} </span> {t("menu.catageory.first_assign_products")}</Typography>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", marginTop: "2%" }}>

            <Button className='create_category_modal_next_button' sx={{ marginLeft: "3%", textTransform: "none" }} onClick={() => { handleClose(); handleOkayButton() }}>{t("menu.catageory.okay")}</Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}










