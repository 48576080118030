import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import NavigationButtons from "../../StepperNavigationContext/navigationControls";
// import Bar from "../../../../../assets/outLetImages/FineDining.png";
// import Cafe from "../../../../../assets/outLetImages/cafe.png";
// import Delivery from "../../../../../assets/outLetImages/deliveryStore.png";
// import casualDining from "../../../../../assets/outLetImages/casualDining.png";
// import CloudKitchen from "../../../../../assets/outLetImages/CloudKitchen.png";
// import PizzaShop from "../../../../../assets/outLetImages/PizzaShop.png";
// import BarRestaurant from "../../../../../assets/outLetImages/Bar.png";
import CheckIcon from "../../../../../assets/check-with-bg.png";
import ForwardArrow from "../../../../../assets/arrow-forward.png"
import { GET_OUTLET, UPDATE_BUSINESS_IN_CREATE } from "../../../../../graphicalQl/mutation/signUp";
import { useMutation, useQuery } from "@apollo/client";

interface Props {
  setOutletType: any
}



function OutletTypeSelection(props: Props) {
  const { setOutletType } = props
  const [outLetType, setOutletTypes] = useState<{ title: string, id: string, ImageInfo: string, subTitle: string }[]>(
    [

    ]
  );
  const { data } = useQuery(GET_OUTLET, { context: { clientName: "superUserClient" } })

  useEffect(() => {
    if (data && data.businessCategories) {
      const list = data.businessCategories.edges.map((each: any) => ({
        id: each.node.businessCategoryId,
        title: each.node.title,
        ImageInfo: each.node.image,
        subTitle: each.node.description
      }))
      setOutletTypes(list)
    }
  }, [data])

  const handleBack = () => {
    setOutletType(false)
  }
  const [outletTypeSelection, setOutletTypeSelection] = useState("");

  const getSelectionHandler = (type: any) => {
    setOutletTypeSelection(type);
  };

  const [updateBusinessType] = useMutation(UPDATE_BUSINESS_IN_CREATE, { context: { clientName: "businessClient" } })
  const [apiSucess, setApiSuccess] = useState(false)
  const handleApiCall = () => {
    updateBusinessType({
      variables: {
        businessId: sessionStorage.getItem("newBusiness") || 5,
        business_category: parseInt(outletTypeSelection)
      },
      onCompleted: () => {
        // handleNext()
        setApiSuccess(true)
        sessionStorage.removeItem("newBusiness")
        sessionStorage.removeItem("email")
        sessionStorage.removeItem("name")
        sessionStorage.removeItem("mobile")
      }
    })
  }
  return (
    <>
      <Box
        marginTop="2vh"
        textAlign="center"
        paddingX={{ sm: "20px", xs: "12px" }}
        marginBottom={'2vh'}
      >
        <Box
          sx={{
            backgroundColor: "#FBFBFB",
            paddingY: "2vh",
            paddingX: { sm: "32px", xs: "12px" },
            borderRadius: "8px",
          }}
        >
          <Box>
            <Typography fontSize={{ xs: '24px', sm: "24px" }} fontWeight={600} color={"#333333"} lineHeight={{ md: "24px", xs: "32px" }} fontFamily={"Poppins"}>
              Select Your Outlet Type
            </Typography>
            <Typography color={"#707070"} fontFamily={"Poppins"} mt={"12px"}>
              Personalize your account according to your business type by
              entering the required information below.
            </Typography>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            marginTop={"2vh"}
            flexWrap={"wrap"}
            sx={{ maxWidth: { md: "1360px", xs: "100%" } }}
            marginX={"auto"}
            gap={"32px"}
          >
            {outLetType.map((data) => (
              <Box
                sx={{
                  width: "278px",
                  height: "25vh",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor:
                    outletTypeSelection === data.id ? "#FFF8F0" : "",
                  cursor: "pointer",
                  position: "relative",
                }}
                onClick={() => getSelectionHandler(data.id)}
                border={"1px solid #F38B08"}
              >
                {outletTypeSelection === data.id && (
                  <Box position="absolute" top="10px" right="10px" >
                    <img src={CheckIcon} alt="CheckIcon" />
                  </Box>
                )}
                <img src={data.ImageInfo} alt="FineDineing" />
                <Typography
                  sx={{
                    marginTop: "10px",
                    fontFamily: "poppins",
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    color: "#F38B08",
                  }}
                >
                  {data.title}
                </Typography>
                {data.subTitle && <Typography sx={{ fontFamily: 'poppins', fontSize: '14px', lineHeight: '21px', color: '#707070', marginTop: '4px' }}>{data.subTitle}</Typography>}

              </Box>
            ))}
          </Box>

          <Box
            sx={{
              marginTop: { md: "2vh", xs: "40px" },
              marginBottom: "2vh",
              display: "flex",
              justifyContent: { sm: "end", xs: "center" },
              gap: '32px'
            }}
          >
            <Button onClick={handleBack}
              sx={{
                color: "#707070",
                fontSize: '14px', backgroundColor: "#ffffff",
                padding: '0px 24px',
                textTransform: "none"
              }}
            >Back</Button>
            <NavigationButtons customImage={ForwardArrow} handleApiCall={handleApiCall} apiSucess={apiSucess} />
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default OutletTypeSelection;
