import React, { useEffect, useState } from "react";
import { Box, TextField, IconButton, Button, Typography } from "@mui/material";
import "../options/createProduct.css";
import "../category/createCategory.css";
import { t } from "i18next";
import Chip from "@mui/material/Chip";
import AddIcon from "@mui/icons-material/Add";
import { useDispatch, useSelector } from "react-redux";
import { setproductConfiguration } from "../../store";
import { useMutation, useQuery } from "@apollo/client";
import { resetState } from "../../store";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  ADITIONAL_DETAILS_IN_PRODUCT,
  FILTERING_PRODUCTS_LIST,
} from "../../graphicalQl/usequery/productsListQuery";
import { UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_CREATION } from "../../graphicalQl/mutation/productMutation";
import { UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_EDIT } from "../../graphicalQl/mutation/productMutation";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router";
import { toast } from "react-toastify";
import uuid from "react-uuid";

const validationSchemaPart1 = Yup.object({
  // metaTag: Yup.string().typeError("* must be a number"),
});

interface Props {
  handleBack: () => void;
  handleComplete: () => void;
}

const ProductAditionalDetails = (props: Props) => {
  const { handleBack, handleComplete } = props;

  const dispatch = useDispatch();
  const { productId } = useSelector((state: any) => state.pricing);
  const { productIdByEdit } = useSelector((state: any) => state.app);

  const { metaTag, seoKeyWord, metaKeyWord } = useSelector(
    (state: any) => state.configuration
  );

  // const [chips, setChips] = useState<string[]>(seoKeyWord);
  // const [newChipText, setNewChipText] = useState("");

  // const handleAddChip = () => {
  //   if (newChipText.trim() !== "") {
  //     setChips([...chips, newChipText]);
  //     setNewChipText("");
  //   }
  // };

  // const handleDeleteChip = (chipToDelete: string) => {
  //   setChips(chips.filter((chip) => chip !== chipToDelete));
  // };
  // const [chips1, setChips1] = useState<string[]>(metaKeyWord);
  // const [newChipText1, setNewChipText1] = useState("");

  // const handleAddChip1 = () => {
  //   if (newChipText1.trim() !== "") {
  //     setChips1([...chips1, newChipText1]);
  //     setNewChipText1("");
  //   }
  // };

  // const handleDeleteChip1 = (chipToDelete: string) => {
  //   setChips1(chips1.filter((chip) => chip !== chipToDelete));
  // };

  const handleInputChange2 = (
    field: string,
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(setproductConfiguration(field, e.target.value));
  };

  // const handleChip = () => {
  //   dispatch(setproductConfiguration("seoKeyWord", chips));
  // };

  // const handleChip1 = () => {
  //   dispatch(setproductConfiguration("metaKeyWord", chips1));
  // };
  // useEffect(() => {
  //   handleChip();
  // }, [chips]);
  // useEffect(() => {
  //   handleChip1();
  // }, [chips1]);

  useEffect(() => {
    setChips1(metaKeyWord);
  }, [metaKeyWord]);

  useEffect(() => {
    setChips(seoKeyWord);
  }, [seoKeyWord]);

  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      metaTag: metaTag,
    },
    validationSchema: validationSchemaPart1,
    onSubmit: (values) => {
      handleComplete();
      navigate("/product/product-list");
      dispatch(resetState());
      if (productIdByEdit) {
        editProduct({
          variables: filteredBulkProduct,
          refetchQueries: [FILTERING_PRODUCTS_LIST],

          onCompleted: (data) => {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
        });
      } else {
        updateProduct({
          variables: filteredProduct,
          refetchQueries: [FILTERING_PRODUCTS_LIST],
          onCompleted: (data) => {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          },
        });
      }
    },
  });

  const [updateProduct, { loading: bulkLoading }] = useMutation(
    UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_CREATION,
    { context: { clientName: "categoryClient" } }
  );
  const [editProduct, { loading: updateLoading }] = useMutation(
    UPDATE_PRODUCT_ADITIONAL_DETAILS_IN_EDIT,
    {
      context: { clientName: "categoryClient" },
    }
  );

  const { data: aditionalDetailsDataFromApi } = useQuery(
    ADITIONAL_DETAILS_IN_PRODUCT,
    {
      context: { clientName: "categoryClient" },
      variables: { productId: +productIdByEdit },
    }
  );

  // useEffect(()=>{
  //   if(aditionalDetailsDataFromApi&&aditionalDetailsDataFromApi.product){
  //     if(aditionalDetailsDataFromApi.product.metatagKeywords){
  //       dispatch(setproductConfiguration("metaKeyWord",JSON.parse(aditionalDetailsDataFromApi.product.metatagKeywords)))
  //     }

  //     if(aditionalDetailsDataFromApi.product.seoKeyword){
  //       dispatch(setproductConfiguration("seoKeyWord",JSON.parse(aditionalDetailsDataFromApi.product.seoKeyword)))
  //     }
  //     dispatch(setproductConfiguration( "metaTag",  aditionalDetailsDataFromApi.product.metatagDescription ));
  //   }
  // },[aditionalDetailsDataFromApi])

  useEffect(() => {
    formik.setValues({
      ...formik.values,
      metaTag: metaTag,
    });
  }, [metaTag]);

  const [chips, setChips] = useState<any[]>(seoKeyWord);
  const [newChipText, setNewChipText] = useState("");

  useEffect(() => {
    if (seoKeyWord) {
      setChips(seoKeyWord);
    }
  }, [seoKeyWord]);

  const handleAddChip = () => {
    if (newChipText.trim() !== "") {
      // setChips(prevChips => {

      //   const updatedChips = [...prevChips, newChipText];
      //   dispatch(setCreateCategory("seoKeyWord", updatedChips));
      //   return updatedChips;
      // });
      const list = [...chips, { id: uuid(), name: newChipText }];
      dispatch(setproductConfiguration("seoKeyWord", list));
      setChips(list);
      setNewChipText("");
    }
  };

  const handleDeleteChip = (chipToDelete: any) => {
    setChips(chips.filter((chip) => chip.id !== chipToDelete));
  };

  const [chips1, setChips1] = useState<any[]>(metaKeyWord);
  const [newChipText1, setNewChipText1] = useState("");

  useEffect(() => {
    if (metaKeyWord) {
      setChips1(metaKeyWord);
    }
  }, [metaKeyWord]);

  const handleAddChip1 = () => {
    if (newChipText1.trim() !== "") {
      // setChips1(prevChips => {
      //   const updatedChips = [...prevChips, newChipText1];
      //   dispatch(setCreateCategory("metaKeyWord", updatedChips));
      //   return updatedChips;
      // });
      const list = [...chips1, { id: uuid(), name: newChipText1 }];
      dispatch(setproductConfiguration("metaKeyWord", list));
      setChips1(list);
      setNewChipText1("");
    }
  };

  const handleDeleteChip1 = (chipToDelete: any) => {
    setChips1(chips1.filter((chip) => chip.id !== chipToDelete));
  };

  const [meta, setMetaTag] = useState("");

  useEffect(() => {
    setMetaTag(metaTag);
  }, [metaTag]);

  // const { data: editDataValue } = useQuery(EDIT_CATEGORY, { variables: { categoryId: categoryId }, context: { clientName: 'categoryClient' } })
  // useEffect(() => {
  //   if (editDataValue) {
  //     if (editDataValue !== editData) {
  //       editDataValue.category.metatagDescription && dispatch(setCreateCategory("metaTag", editDataValue.category.metatagDescription));
  //       editDataValue.category.seoKeyword && dispatch(setCreateCategory("seoKeyWord", JSON.parse(editDataValue.category.seoKeyword).map((each: string) => {
  //         return { id: uuid(), name: each }
  //       })));
  //       editDataValue.category.metatagKeywords && dispatch(setCreateCategory("metaKeyWord", JSON.parse(editDataValue.category.metatagKeywords).map((each: string) => {
  //         return { id: uuid(), name: each }
  //       })));
  //       dispatch(setCreateCategory("editData", (editDataValue)));
  //     }

  //   }
  // }, [editDataValue]);

  const [charCount, setCharCount] = useState(0);

  const updateCharCount = (event: any) => {
    // Get the current character count
    let count = event.target.value.length;
    // Limit the input to 500 characters
    if (count > 500) {
      event.target.value = event.target.value.substring(0, 500);
      count = 500;
    }
    // Update the character count state
    setCharCount(count);
  };

  const aditionalaList = {
    productIds: JSON.stringify(productId),
    metatagDescription: metaTag,
    metatagKeywords:
      metaKeyWord.length > 0 ? JSON.stringify(metaKeyWord) : null,
    seoKeyword: seoKeyWord.length > 0 ? JSON.stringify(seoKeyWord) : null,
  };

  const filteredProduct = Object.fromEntries(
    Object.entries(aditionalaList).filter(
      ([_, value]) => value !== null && value !== "null" && value !== ""
    )
  );

  const bulkList = {
    productId: +productIdByEdit,
    metatagDescription: metaTag,
    metatagKeywords:
      metaKeyWord.length > 0 ? JSON.stringify(metaKeyWord) : null,
    seoKeyword: seoKeyWord.length > 0 ? JSON.stringify(seoKeyWord) : null,
  };

  const filteredBulkProduct = Object.fromEntries(
    Object.entries(bulkList).filter(
      ([_, value]) => value !== null && value !== "null" && value !== ""
    )
  );

  return (
    <Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            padding: "1.25rem 0.75rem ",
            display: "flex",
            flexDirection: "column",
            gap: "1.25rem",
            border: "1px solid #EEEEEE",
            marginBottom: "1.5rem",
          }}
        >
          {/* <div
            style={{ display: "flex", marginTop: "2%", width: "70%" }}
            className="create-product-chip"
          >
            <div>
              {chips.map((chip: string) => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={() => {
                    handleDeleteChip(chip);
                  }}
                />
              ))}
            </div>
            <div style={{ marginLeft: "auto" }}>
              <TextField
                label={t("menu.catageory.seo_keyword")}
                variant="standard"
                value={newChipText}
                onChange={(e) => setNewChipText(e.target.value)}
              />
              <IconButton
                onClick={() => {
                  handleAddChip();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div>
          <TextField
            multiline
            minRows={2}
            label={t("menu.catageory.meta_description")}
            placeholder="Meta tag description"
            name="metaTag"
            value={formik.values.metaTag}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleInputChange2("metaTag", e);
              formik.handleChange(e);
            }}
            style={{ width: "70%", marginTop: "2%" }}
            color="warning"
          />

          <div
            style={{ display: "flex", marginTop: "2%", width: "70%" }}
            className="create-product-chip"
          >
            <div>
              {chips1.map((chip: string) => (
                <Chip
                  key={chip}
                  label={chip}
                  onDelete={() => {
                    handleDeleteChip1(chip);
                  }}
                />
              ))}
            </div>
            <div style={{ marginLeft: "auto" }}>
              <TextField
                label={t("menu.catageory.meta_keywords")}
                variant="standard"
                value={newChipText1}
                onChange={(e) => setNewChipText1(e.target.value)}
              />
              <IconButton
                onClick={() => {
                  handleAddChip1();
                }}
              >
                <AddIcon />
              </IconButton>
            </div>
          </div> */}
          <div
            style={{ display: "flex", width: "70%", alignItems: "flex-start" }}
            className="create-product-chip"
          >
            <div style={{ display: "flex", flexWrap: "wrap", width: "60%" }}>
              {chips.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.name}
                  sx={{ margin: "1%" }}
                  onDelete={() => handleDeleteChip(chip.id)}
                />
              ))}
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label={t("menu.catageory.seo_keyword")}
                variant="standard"
                value={newChipText}
                onChange={(e: any) => setNewChipText(e.target.value)}
              />
              <IconButton onClick={handleAddChip}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
          {/* <TextField multiline
        minRows={2}
        label={t("menu.catageory.meta_description")}
        placeholder="Meta tag description"
        name='metaTag'
        value={meta}

        onChange={(event: any) => {
          dispatch(setCreateCategory("metaTag", event.target.value));

        }}
        style={{ width: "70%", }} color="warning" /> */}

          <div className="textarea-box" style={{ width: "70%" }}>
            <textarea
              id="textarea"
              style={{ width: "100%" }}
              name="description"
              value={metaTag}
              onChange={(event: any) => {
                dispatch(
                  setproductConfiguration("metaTag", event.target.value)
                );
                updateCharCount(event);
              }}
              placeholder={t("menu.catageory.metaDescription") || ""}
            >
              {metaTag}
            </textarea>
            <div id="charCount" className="char-count">
              {metaTag.length}/500
            </div>
          </div>
          <div
            style={{ display: "flex", width: "70%", alignItems: "flex-start" }}
            className="create-product-chip"
          >
            <div style={{ display: "flex", flexWrap: "wrap", width: "60%" }}>
              {chips1.map((chip) => (
                <Chip
                  key={chip.id}
                  label={chip.name}
                  sx={{ margin: "1%" }}
                  onDelete={() => handleDeleteChip1(chip.id)}
                />
              ))}
            </div>
            <div
              style={{
                marginLeft: "auto",
                display: "flex",
                alignItems: "center",
              }}
            >
              <TextField
                label={t("menu.catageory.meta_keywords")}
                variant="standard"
                value={newChipText1}
                onChange={(e: any) => setNewChipText1(e.target.value)}
              />
              <IconButton onClick={handleAddChip1}>
                <AddIcon />
              </IconButton>
            </div>
          </div>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", marginTop: "1%" }}>
          <Button
            sx={{ textTransform: "none" }}
            className="create_category_BacK_button"
            onClick={handleBack}
          >
            {<ArrowBackIcon />} {t("product.back")}
          </Button>
          {/* <Button className='createCategory' sx={{marginLeft:"3px"}} onClick={()=>{handleComplete();handleNavigateTolisting();handleFinishButton()}} >Finish </Button> */}
          <Button
            className="createCategory-next-active"
            type="submit"
            sx={{ marginLeft: "3px", textTransform: "none" }}
            disabled={bulkLoading || updateLoading}
          >
            {t("menu.catageory.finish")}{" "}
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default ProductAditionalDetails;
