import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Typography, Button, InputBase, IconButton } from "@mui/material";
import "../options/createProduct.css";

import "../category/createCategory.css";
import Modal from "@mui/material/Modal";
import { styled } from "@mui/system";

import { CloseOutlined } from "@mui/icons-material";
import SearchIcon from "@mui/icons-material/Search";
import { GET_PRODUCT } from "../../graphicalQl/usequery/categoryListQuery";
import { useMutation, useQuery } from "@apollo/client";
// import { CATEGORY_LIST } from '../../graphicalQl/usequery/categoryListQuery';
// import { ASSIGN_PRODUCTS } from '../../graphicalQl/mutation/mutation';
// import { DELTE_PRODUCT_FROM_CATEGORY } from '../../graphicalQl/mutation/mutation';
// import { PRODUCT_DATA_IN_CATEGORY } from '../../graphicalQl/usequery/categoryListQuery';
import CircularLoader from "../../loader/loader";
import {
  DataGrid,
  GridCallbackDetails,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import CurrencyDisplay from "../utils/currencySymbol";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ASSIGN_OPTION_TO_PRODUCTS } from "../../graphicalQl/mutation/optionsMutation";
import { GET_OPTIONS_LIST_IN_OPTIONS } from "../../graphicalQl/usequery/optionsQuery";
import { DELETE_OPTIONGROUP_IN_PRODUCT } from "../../graphicalQl/mutation/productMutation";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",

  transform: "translate(-50%, -50%)",
  width: "60%",
  bgcolor: "background.paper",

  boxShadow: 24,
};

interface Data {
  id: number;
  product: string;
  price: string;
  isChecked: boolean;
}

interface Props {
  open: boolean;
  upDateOpen: () => void;
  categoryId: string | undefined;
  assigncategoryDataIds: number[];
  setProductIds:any;
}
const CustomStyles = styled("div")({
  "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
    outline: "solid #1976d2 0px !important",
  },
  "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within": {
    outline: "solid #1976d2 0px !important",
  },
  "& .MuiDataGrid-columnHeaderTitle": {
    fontWeight: "500 !important",
  },
});

const AssignProducts = (props: Props) => {
  const { t } = useTranslation();

  const { open, upDateOpen, categoryId, assigncategoryDataIds ,setProductIds} = props;
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [openModal, setOpen] = React.useState<boolean>(false);
  useEffect(() => {
    setOpen(open);
  }, [open]);

  const handleClose = () => {
    setOpen(false);
    upDateOpen();
  };

  const [upDatedProductData, setProductData] = useState<Data[]>([]);
  const { data: productData, loading: productsLoading } = useQuery(
    GET_PRODUCT,
    {
      variables: { businessId: +businessId, locationId: locationId },
      context: { clientName: "categoryClient" },
      fetchPolicy: "network-only",
    }
  );

  useEffect(() => {
    if (productData) {
      const { productList } = productData;
      const { edges } = productList;
      if (edges.length > 0) {
        const productUpdatedList = edges.map((item: any) => ({
          id: +item.node.productId,
          product: item.node.name,
          price: item.node.priceToDisplay,
          isChecked: false,
        }));

        setProductData(productUpdatedList);
      }
    }
  }, [productData]);

  const [searchValue, setSearchValue] = useState<string>("");
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };
  const searchedResult = upDatedProductData.filter((each) =>
    each.product.toLowerCase().includes(searchValue.toLowerCase())
  );

  // const [assignProducts] = useMutation(ASSIGN_PRODUCTS, {
  //   onCompleted: (data) => {
  //     if (data) {

  //     }

  //   },
  //   onError: (error) => {
  //     // Handle any errors here, e.g., display an error message.
  //   },
  //   context: { clientName: 'categoryClient' },
  //   refetchQueries: [
  //     PRODUCT_DATA_IN_CATEGORY,// Specify the query using an object with a 'query' property
  //     CATEGORY_LIST,
  //   ],
  // });

  const columns: GridColDef[] = [
    {
      field: "product",
      //@ts-ignore
      headerName: t("menu.catageory.product"),
      headerClassName: "table-column-header",
      cellClassName: "dataGrid-table-cell",
      editable: false,
      sortable: true,
      disableColumnMenu: true,
      flex: 1,
    },
    {
      field: "price",
      //@ts-ignore
      headerName: t("menu.catageory.price"),
      headerClassName: "table-column-header",
      width: 150,
      align: "right",
      headerAlign: "center",
      cellClassName: "dataGrid-table-cell-less-wt",
      editable: false,
      disableColumnMenu: true,
      sortable: true,
      renderCell: (params) => {
        return (
          // <CurrencyDisplay countryName={'UnitedKingdom'} amount={params.row.price} />
          <Typography
            sx={{ marginRight: "16px" }}
            className="dataGrid-table-cell-less-wt"
          >
            {
              <CurrencyDisplay
                countryName={"UnitedKingdom"}
                amount={params.row.price ? params.row.price.split("-") : "0"}
              />
            }
          </Typography>
        );
      },
      // flex: 1,
    },
  ];

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "product",
      sort: "asc",
    },
  ]);

  // ... your other state variables and functions ...

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    // Update the state with the new sort model
    setSortModel(newSortModel);
    // You can perform additional logic here, if necessary
    // For example, you might want to update some state or fetch new data based on the sorting
  };
  const handleRowStyle = () => {
    return "dataGrid-table-row";
  };

  const [assignProducts] = useMutation(ASSIGN_OPTION_TO_PRODUCTS, {
    onCompleted: (data) => {
      handleClose();
      
    },
    onError: (error) => {
      // Handle any errors here, e.g., display an error message.
    },
    context: { clientName: "categoryClient" },
    refetchQueries: [GET_OPTIONS_LIST_IN_OPTIONS],
  });
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);
    
    const [deleteOptionGroup] = useMutation(DELETE_OPTIONGROUP_IN_PRODUCT, {
      context: { clientName: "categoryClient" },
    });

  const handleAssignProducts = () => {
    const uniqueValuesInList1 = rowSelectionModel.filter(item => !assigncategoryDataIds.includes(+item));
    if ( uniqueValuesInList1.length>0){
      assignProducts({
        variables: {
          optionId: categoryId && +categoryId,
          products: JSON.stringify(uniqueValuesInList1),
        },
      })
    }
    else{
      handleClose();
    }
    
  };
  useEffect(() => {
    if (openModal) {
      setRowSelectionModel(assigncategoryDataIds); // Assuming IDs are numbers
    }
  }, [assigncategoryDataIds, openModal]);

  const handleRowSelectionModelChange = (
    newRowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails,
  ) => {
    // setRowSelectionModel(newRowSelectionModel);
    const set2 = new Set(newRowSelectionModel);

    // Find elements in list1 that are not in list2
    const difference = rowSelectionModel.filter(item => !set2.has(item));
    
if (difference.length>0){
  deleteOptionGroup({
    variables:{
      optionId:categoryId && +categoryId,
      productIds:JSON.stringify(difference)
    },
    onCompleted:()=>{
      const updatedList = assigncategoryDataIds.filter(item => item !== difference[0]);
            setProductIds(updatedList)
    }
  })
}
    const list = Array.from([...newRowSelectionModel]);
    setRowSelectionModel(list);
  };

  if (productsLoading) {
    return <CircularLoader />;
  }
  return (
    <Modal
      open={openModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            margin: "0.7% 0px 0.833% 1.4%",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <IconButton className="exit-container" onClick={handleClose}>
            <CloseOutlined className="exitIcon" />
          </IconButton>
          <Typography className="createCategory-select-product">
            {t("menu.catageory.selectProducts")}
          </Typography>
          <Typography></Typography>
        </div>
        <Box
          sx={{
            width: "100%",
            backgroundColor: "#EEEEEE",
            padding: "16px",
            textAlign: "center",
          }}
        >
          <Typography className="product-modal-para">
            {t("menu.catageory.selectPara")}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            marginTop: "1.4%",
            marginBottom: "1.4%",
            marginLeft: "2.22%",
          }}
          className="product-search"
        >
          <IconButton type="button" aria-label="search">
            <SearchIcon />
          </IconButton>
          <InputBase
            sx={{ ml: 1, flex: 1 }}
            //@ts-ignore
            placeholder={t("menu.catageory.searchByName")}
            value={searchValue}
            name="search"
            onChange={handleSearch}
            color="warning"
          />
        </Box>
        <Box
          sx={{
            marginLeft: "2.22%",
            // WebkitOverflowScrolling: 'touch', '&::-webkit-scrollbar': {
            //   display: 'none',
            // },
          }}
        >
          <CustomStyles>
            <DataGrid
              rows={upDatedProductData}
              getRowClassName={handleRowStyle}
              columns={columns}
              rowHeight={48}
              columnHeaderHeight={40}
              sortModel={sortModel}
              filterModel={{
                items: [
                  {
                    field: "product",
                    operator: "contains",
                    value: searchValue,
                  },
                ],
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              pageSizeOptions={[5, 10, 25]}
              checkboxSelection={true}
              onSortModelChange={handleSortModelChange}
              onRowSelectionModelChange={handleRowSelectionModelChange}
              rowSelectionModel={rowSelectionModel}
              disableRowSelectionOnClick
              sx={{
                height: "50vh",
                "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            />
          </CustomStyles>
        </Box>

        <Box sx={{ width: "100%", padding: "10px", display: "flex" }}>
          <Button
            className="main-button"
            onClick={() => {
              handleAssignProducts();
            }}
            sx={{ textTransform: "none", marginLeft: "auto" }}
          >
            Assign products to option
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};
export default AssignProducts;
