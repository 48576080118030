import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'
interface State{
    open:boolean,
    handleClose:()=>void
}

const AlertPopupForLastRuleDelete = (props:State) => {
    const {t}=useTranslation()
    const {open,handleClose,}=props
    const alertPopupStyle = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        bgcolor: 'background.paper',
        borderRadius: "8px",
        p:4
    }
    
  return (
    <Modal open={open}
                      onClose={handleClose}
                      aria-labelledby="modal-modal-title"
                      aria-describedby="modal-modal-description"
                    >
                     <Box sx={alertPopupStyle}>
                        <Box sx={{textAlign:"center",marginBottom:"3%"}}>
                            <Typography className='alert-popup-text-in-area-wise'>{t("deliveries.please_refrain")}</Typography>
                        </Box>
                        <Box sx={{textAlign:"center",marginTop:"5%"}}>
                            <Button sx={{textTransform:"none"}} variant='contained' color='warning' onClick={handleClose}>
                                {t("buttons.ok")}
                            </Button>
                        </Box>

                     </Box>
      </Modal>
  )
}

export default AlertPopupForLastRuleDelete
