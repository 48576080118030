import React from "react";
import { Box, Typography, Button, List } from "@mui/material";
import Checkmark from "../../../../assets/check.svg";
import ShapeBottom from "../../../../assets/Shape.png";
import styled from "styled-components";
import WhiteCheckmark from "../../../../assets/whiteCheckmark.svg";
import TopEllipse from "../../../../assets/Top-Ellipse.png";
import BottomEllipse from "../../../../assets/Bottom-Ellipse.png";
import { useNavigation } from "../../../businessOnboarding/Steper/StepperNavigationContext/useNavigation";

const PlanWrapper = styled("div")({
  ".offercards .recommended": {
    display: "block",
  },
  ".offercards": {
    border: "1px solid #F38B08",
    borderTop: "4px solid #F38B08",
  },
  ".offercards .cardsbutton": {
    backgroundColor: "#F38B08",
    color: "#fff",
  },
});

interface State {
  plan: any
  setAddOnsSelectors: any

}


const CustomCard = (props: State) => {
  const { plan, setAddOnsSelectors } = props
  const { activeStep, handleBack, handleNext } = useNavigation();
  return (
    <PlanWrapper>
      <Box
        className="offercards"
        sx={{
          maxWidth: "226px",
          width: { sm: "100%", xs: "90%" },
          border: "1px solid #eeeeee",
          backgroundColor: `${plan.activePlan === true ? "black" : "#fff"} `,
          borderRadius: "8px",
          padding: "8px",
          fontFamily: "Poppins",
          position: "relative",
          cursor: "pointer",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: "0px",
            right: "0px",
          }}
        >
          <img
            src={`${plan.activePlan ? TopEllipse : ""}`}
            alt=""
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "-8px",
            left: "0px",
          }}
        >
          <img
            src={`${plan.activePlan ? BottomEllipse : ""}`}
            alt=""
          />
        </Box>
        {plan.recommended && (
          <Box
            className="recommended"
            id="recommendedBoxShadow"
            sx={{
              display: "none",
              backgroundColor: "#F38B08",
              paddingY: "6px",
              borderRadius: "20px",
              width: "165px",
              mx: "auto",
              position: "relative",
              top: "-24px",
            }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                lineHeight: "20px",
                fontWeight: "500",
                color: "#ffffff",
                fontFamily: "Poppins",
              }}
            >
              Recommended
            </Typography>
            <Box
              sx={{
                position: "absolute",
                left: "42%",
                bottom: "-16px",
              }}
            >
              <img src={ShapeBottom} alt="ShapeBottom" />
            </Box>
          </Box>
        )}
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "poppins",
            fontSize: "24px",
            LineHeight: "36px",
            fontWeight: "500",
            textAlign: "center",
            color: `${plan.activePlan ? "#fff" : "#333333"}`,
            marginTop: `${plan.activePlan ? "10px" : "0px"}`,
          }}
        >
          {plan.name}
        </Typography>
        <Typography
          sx={{
            display: `${plan.activePlan ? "block" : "none"}`,
            textAlign: "center",
            width: "94px",
            background: "#F38B081A",
            borderRadius: "20px",
            color: "#F38B08",
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "500",
            fontFamily: "Poppins",
            position: "absolute",
            right: "0px",
            top: "60px",

          }}
        >
          {plan.active}
        </Typography>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontFamily: "poppins",
            fontSize: "44px",
            lineHeight: "66px",
            textAlign: "center",
            fontWeight: "600",
            color: `${plan.activePlan ? "#fff" : "#333333"}`,
            marginTop: `${plan.activePlan ? "36px" : "20px"}`,
          }}
        >
          {plan.price}
        </Typography>
        {plan.custom && (
          <Typography
            component="h1"
            variant="h5"
            sx={{
              fontFamily: "poppins",
              fontSize: "32px",
              lineHeight: "48px",
              textAlign: "center",
              fontWeight: "600",
              colors: "#333333",
              marginTop: "20px",
            }}
          >
            {plan.custom}
          </Typography>
        )}
        <Typography
          sx={{
            fontFamily: "poppins",
            fontSize: "14px",
            lineHeight: "21px",
            textAlign: "center",
            color: `${plan.activePlan ? "#fff" : "#707070"}`,
            marginTop: "8px",
          }}
        >
          {plan.description}
        </Typography>
        <Button
          className="cardsbutton"
          onClick={() => handleNext()}
          sx={{
            fontFamily: "poppins",
            fontSize: "16px",
            lineHeight: "24px",
            fontWeight: "500",
            color: `${plan.activePlan ? "#fbfbfb" : "#F38B08"}`,
            textAlign: "center",
            width: "100%",
            border: "1px solid #F38B08",
            borderRadius: "8px",
            height: "44px",
            marginTop: "12px",
            marginBottom: "12px",
          }}
        >
          {plan.buttonText}
        </Button>
        <Typography
          sx={{
            fontSize: "14px",
            lineHeight: "21px",
            fontWeight: "500",
            color: `${plan.activePlan ? "#d9d9d9" : "#707070"}`,
            fontFamily: "Poppins",
            textAlign: "start",
          }}
        >
          {plan.offer}
        </Typography>
        <List sx={{ listStyle: "none" }}>
          {plan.features.map((feature: any, index: any) => (
            <li key={index}>
              <Box
                sx={{
                  display: "flex",
                  gap: "8px",
                  marginTop: "16px",
                }}
              >
                <img
                  // src={Checkmark}
                  src={`${plan.activePlan ? WhiteCheckmark : Checkmark}`}
                  alt="Checkmark"
                />
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontSize: "14px",
                    lineHeight: "21px",
                    color: `${plan.activePlan ? "#d9d9d9" : "#707070"}`,
                    textAlign: "start",
                  }}
                >
                  {feature}
                </Typography>
              </Box>
            </li>
          ))}
        </List>
      </Box>
    </PlanWrapper>
  );
};
export default CustomCard;
