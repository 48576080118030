import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ChangeEvent } from "react";
import Box from "@mui/material/Box";
import { SelectChangeEvent } from "@mui/material/Select";
import {
  Button,
  TextField,
  InputAdornment,
  Typography,
  styled,
} from "@mui/material";
import "./bundleListing.css";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { MoreVert, SearchRounded } from "@mui/icons-material";
import CircularLoader from "../../loader/loader";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { DELETE_BUNDLE } from "../../graphicalQl/mutation/bundleMutation";
import { Switch } from "@mui/material";
import { BUNDLE_LIST_DATA } from "../../graphicalQl/usequery/bundleQuery";
import { useQuery, useMutation } from "@apollo/client";
import DeleteDailouge from "../category/deleteDailouge";
import { DUPLICATE_BUNDLE } from "../../graphicalQl/mutation/bundleMutation";
import { BUNDLE_STATUS_CHANGE } from "../../graphicalQl/mutation/bundleMutation";
import { PRODUCT_LIST_IN_BUNDLE } from "../../graphicalQl/usequery/bundleQuery";
import { useSelector } from "react-redux";
import CurrencyDisplay from "../utils/currencySymbol";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import PopupWithTitle from "../popups/popupWithTitle";
import CustomFooter from "../category/footer";
import DelayedInput from "../../utils/delayedInput";
import NodataImage from "../../utils/nodataImage";

interface RowData {
  id: string;
  bundleName: string;
  status: boolean;
  products: number;
  price: number;
  image: string;
  setMeals: boolean;
}

export default function BundleListing() {
  const { t } = useTranslation();

  const [rows, setRows] = useState<RowData[]>([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  // const CustomStyles = styled('div')({
  //   '&.MuiDataGrid-columnHeaderTitle': {
  //     fontWeight: '500',
  //   },
  //   '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
  //     outline: 'solid #1976D2 0px !important',
  //   },
  //   '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
  //     outline: 'solid #1976D2 0px !important',
  //   },
  //   "& .MuiDataGrid-columnHeader": {
  //     background: "#F5F5F5",
  //   },
  // });

  const { locationId } = useSelector((state: any) => state.headerLocation);

  const [deletemodal, setDeleteModal] = useState(false);
  const [delteBundleId, setDeleteBundleId] = useState("");
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    setDeleteBundleId("");
  };

  const getDeleteBundleId = (id: string) => {
    setDeleteBundleId(id);
  };

  const handleEditNavigate = (id: string) => [
    navigate(`/bundle/${id}/update-bundle`),
  ];

  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.bundleList?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.bundleList?.pageInfo.startCursor || null);
  };

  const [deletBundle] = useMutation(DELETE_BUNDLE, {
    context: { clientName: "categoryClient", method: "POST" },
    refetchQueries: [BUNDLE_LIST_DATA],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [duplicateBundle] = useMutation(DUPLICATE_BUNDLE, {
    context: { clientName: "categoryClient", method: "POST" },
    refetchQueries: [BUNDLE_LIST_DATA],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography> {t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [bundleStatusChange] = useMutation(BUNDLE_STATUS_CHANGE, {
    context: { clientName: "categoryClient", method: "POST" },
    refetchQueries: [BUNDLE_LIST_DATA],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const handleDelete = (id: string) => {
    deletBundle({ variables: { bundleIds: JSON.stringify([+id]) } });
    setDeleteBundleId("");
  };

  const [filter, setfilter] = useState<any>(""); // You can set the initial value here

  const handleChangeFilter = (event: SelectChangeEvent) => {
    setfilter(event.target.value);
  };
  function CustomPopoverCell({
    value,
    bundleId,
  }: {
    value: number;
    bundleId: string;
  }) {
    const handleDuplicate = (id: string) => {
      duplicateBundle({ variables: { bundleId: id } });
    };
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const popoverContent = (
      <List style={{ padding: "0px 0px 0px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleEditNavigate(bundleId);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="bundle-Listing-pover-text-style"
          >
            <ListItemText primary={t("buttons.edit")} />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleDeleteModal();
            getDeleteBundleId(bundleId);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText primary={t("buttons.delete")} />
          </ListItem>
        </Button>
        <Divider />

        <Button
          style={{
            padding: "0px 0px 0px 0px",
            width: "100%",
            textTransform: "none",
          }}
          onClick={() => handleDuplicate(bundleId)}
        >
          <ListItem className="bundle-Listing-pover-text-style">
            <ListItemText primary={t("buttons.duplicate")} />
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }

  const [bundleStatusId, setBundleStatusId] = useState<number | null>(null);
  const [bundleStatus, setBundleStatus] = useState<boolean>(false);
  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setIsModalOpen1(true);
    // const status = event.target.checked ? "Active" : "InActive"
    // bundleStatusChange({ variables: { bundleId: +id, bundleStatus: status } })
    setBundleStatusId(id);
    setBundleStatus(event.target.checked);
  };
  const handleStatusCall = () => {
    bundleStatusChange({
      variables: {
        bundleId: bundleStatusId,
        bundleStatus: bundleStatus ? "Active" : "InActive",
      },
    });
  };

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state

    const updatedRows = rows.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    const bundleIds = updatedRows.map((each) => +each.id);

    deletBundle({ variables: { bundleIds: JSON.stringify(bundleIds) } });

    setRowSelectionModel([]);
  };

  const handleRowClass = () => {
    return "bundle-row";
  };

  const handleCellClass = () => {
    return "bundleListigCell";
  };
  const columns: GridColDef[] = [
    {
      field: "bundleName",
      //@ts-ignore
      headerName: t("menu.bundle.bundle-name"),
      headerClassName: "table-column-header",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            {params.row.image && (
              <Box className="bundle_image_container">
                <img
                  src={params.row.image}
                  alt="bundle"
                  style={{ width: "24px", height: "24px" }}
                />
              </Box>
            )}

            <Typography className="bundleListigCell">{params.value}</Typography>
          </Box>
        );
      },
    },

    {
      field: "products",
      //@ts-ignore
      headerName: t("menu.bundle.products"),
      headerClassName: "table-column-header",
      flex: 1,
      disableColumnMenu: true,
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("menu.bundle.status"),
      headerClassName: "table-column-header",
      flex: 1,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={(event) => handleStatus(event, params.row.id)}
            color="warning"
          />
        );
      },
    },
    {
      field: "setMeals",
      //@ts-ignore
      headerName: "Type",
      headerClassName: "table-column-header",
      flex: 1,
      disableColumnMenu: true,

      renderCell: (params) => {
        return params.row.setMeals ? (
          <Typography className="bundleListigCell">Set Meals</Typography>
        ) : (
          <Typography className="bundleListigCell">Combo</Typography>
        );
      },
    },
    {
      field: "price",
      //@ts-ignore

      headerName: t("menu.bundle.price"),
      headerClassName: "table-column-header",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <CurrencyDisplay
            countryName={"UnitedKingdom"}
            amount={params.row.price ? params.row.price : "0"}
          />
        );
      },
    },
    {
      field: "",
      //@ts-ignore

      headerName: t("menu.bundle.action"),
      headerClassName: "table-column-header",
      disableColumnMenu: true,
      flex: 1,
      filterable: false,
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <CustomPopoverCell
            value={params.row.category}
            bundleId={params.row.id}
          />
        );
      },
    },
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/bundle/create-bundle");
  };
  const [searchValue, setSearchValue] = useState("");
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const [isModalOpen1, setIsModalOpen1] = useState(false);

  const { loading, data } = useQuery(BUNDLE_LIST_DATA, {
    context: { clientName: "categoryClient" },
    variables: {
      name_Icontains: searchValue,
      productId: filter,
      businessId: +businessId,
      locationId: locationId,
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });
  React.useEffect(() => {
    if (data && data.bundleList) {
      const list = data.bundleList.edges.map((each: any) => ({
        id: each.node.bundleId,
        bundleName: each.node.name,
        status: each.node.bundleStatus === "Active" ? true : false,
        products: each.node.noOfProducts,
        image: each.node.image,
        price: each.node.amountAfterDiscount,
        setMeals: each.node.isSetMeals,
      }));

      setRows(list);
    }
  }, [data]);

  const [productFromApi, setProductFromApi] = useState<
    { id: string; name: string }[]
  >([]);

  const { data: productData } = useQuery(PRODUCT_LIST_IN_BUNDLE, {
    context: { clientName: "categoryClient" },
    variables: { businessId: +businessId, locationId: locationId },
  });
  React.useEffect(() => {
    if (productData && productData.productList) {
      const productList = productData.productList.edges.map((each: any) => ({
        id: each.node.productId,
        name: each.node.name,
      }));

      setProductFromApi(productList);
    }
  }, [productData]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "bundleName",
      sort: "asc",
    },
  ]);

  // ... your other state variables and functions ...

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };
  function capitalizeFirstLetter(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          margin: "0.75rem 1.25rem",
        }}
      >
        <Box sx={{ gap: "0.75rem", display: "flex", alignItems: "center" }}>
          {/* <FormControl style={{ width: "124px" }} >
            <InputLabel id="demo-simple-select-label">{t("menu.bundle.filter")}</InputLabel> */}
          {/* <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={filter}
              label={t("menu.bundle.filter")}
              onChange={handleChangeFilter}
              color='warning'

            >
              {menuItems}
            </Select> */}

          {/* <Autocomplete
            id="combo-box-demo"
            options={productFromApi}
            getOptionLabel={(option) => capitalizeFirstLetter(option.name.toString())} sx={{ width: 300 }}
            // onChange={handleChangeFilter}
            onChange={(event, newValue) => {
              setfilter(newValue?.id.toString()); // Update the state when the value changes
            }}
            renderInput={(params) => <TextField {...params} label={t("menu.bundle.filter")} />}

          /> */}

          {/* </FormControl> */}

          {/* <TextField variant='outlined' label="Search by name. description or sku" color='warning' sx={{width:"70%"}}/> */}
          {/* <TextField
            id="input-with-icon-textfield"
            label={t("menu.bundle.search")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchRounded />
                </InputAdornment>
              )
            }}
            variant="outlined"
            color='warning'
            onChange={handleSearch}
            sx={{ width: "200px" }}
          /> */}
          <DelayedInput
            setSearchValue={setSearchValue}
            //@ts-ignore
            placeHolder={t("menu.bundle.search")}
            size={"medium"}
          />
        </Box>
        {/* <Box sx={{display:"flex",justifyContent:"space-between",width:"22%"}}>
      <Button className='bundle-listing-button' sx={{textTransform:'none'}} onClick={handleNavigation}>{t("menu.bundle.create_bundle")} </Button>
      {rowSelectionModel.length>0 && <Button className='bundle-listing-button' sx={{textTransform:'none'}} onClick={()=>{handleDeleteModal()}}>{t("menu.bundle.delete")}</Button>}
      </Box> */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "0.75rem",
          }}
        >
          <Button
            className="bundle-listing-button"
            sx={{ textTransform: "none" }}
            onClick={handleNavigation}
          >
            {t("menu.bundle.create_bundle")}{" "}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button
              className="bundle-listing-button"
              sx={{ textTransform: "none" }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              {t("menu.bundle.delete")}
            </Button>
          )}
        </Box>
      </Box>
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDelete}
        id={delteBundleId}
        handleDeleteClick={handleDeleteClick}
        type="bundle"
      />
      <Box sx={{ height: "70vh", width: "97%", marginLeft: "1.25rem" }}>
        {loading ? (
          <CircularLoader />
        ) : (
          <DataGrid
            rows={rows}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            sortModel={sortModel}
            columnHeaderHeight={48}
            rowHeight={48}
            columns={columns}
            pageSizeOptions={[5, 15, 25]}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: paginationModel.pageSize,
                },
              },
            }}
            rowCount={data?.bundleList?.totalCount || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              if (newModel.page > currentPage) {
                handleEndCursor();
              } else if (newModel.page < currentPage) {
                handleStartCursor();
              }

              setCurrentPage(newModel.page); // Update the current page
              setPaginationModel(newModel);
            }}
            paginationMode="server"
            onSortModelChange={handleSortModelChange}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(
              newRowSelectionModel: GridRowSelectionModel
            ) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            components={{
              NoRowsOverlay: () => (
                <NodataImage
                  height="250px"
                  width="300px"
                  heightForContainer="50vh"
                />
              ),
              Footer: (props) => (
                <CustomFooter
                  totalRowCount={Math.ceil(
                    (data?.bundleList?.totalCount || 0) /
                      paginationModel.pageSize
                  )}
                  {...props}
                  page={paginationModel.page}
                />
              ),
            }}
            sx={{
              ".MuiTablePagination-displayedRows": {
                display: "none", // 👈 to hide huge pagination number
              },
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#f5f5f5",
              },
              // marginLeft: "20px",
            }}
          />
        )}
      </Box>

      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the menu?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => {
          setIsModalOpen1(false);
          handleStatusCall();
        }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
    </Box>
  );
}
