import React, { useEffect, useState } from "react";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import MoreVertOutlinedIcon from "@mui/icons-material/MoreVertOutlined";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import Popover from "@mui/material/Popover";
import { useQuery, useMutation } from "@apollo/client";
import NodataImage from "../../utils/nodataImage";
import {
  IconButton,
  Box,
  Button,
  Divider,
  Tooltip,
  Typography,
  List,
  ListItem,
  ListItemText,
  Drawer,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { EmplpoyeesList } from "../../graphicalQl/usequery/userProfileUseQuery";
import {
  Delete_Employee,
  ACTIVE_INACTIVE_USER_STATUS,
} from "../../graphicalQl/mutation/userProfileMutation";
import CircularLoader from "../../loader/loader";
import DriverProfile from "./userProfile";
import DeletePopup from "../popups/deletePopup";
import PopupWithTitle from "../popups/popupWithTitle";
import { useTranslation } from "react-i18next";
import moment from "moment";

import { useSelector } from "react-redux";
import { localeToFormat } from "../../utils/dateFormat";
import DelayedInput from "../../utils/delayedInput";
import DataGridTable from "../../utils/dataGridTable";

interface usersListState {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  date: string;
  phone: string;
  role: string;
}

function UserList() {
  const { t } = useTranslation();
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const employeeId = sessionStorage.getItem("employeeId");
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const navigate = useNavigate();
  const [usersList, setUsersList] = useState<usersListState[]>([]);
  const [showData, setShowData] = React.useState(usersList.map(() => true));
  const [deletePopup, setDeletePopup] = useState<boolean>(false);
  const [searchInput, setSearchInput] = React.useState<null | string>(null);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const requiredVariables = {
    businessId: +businessId,
    query: searchInput,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
    excludedEmployeeId: employeeId && +employeeId,
    locationId: +locationId,
  };

  const filteredCategory = Object.fromEntries(
    Object.entries(requiredVariables).filter(([_, value]) => value !== null)
  );

  const {
    loading: employeesDataLoading,
    error: employeesDataError,
    data: employeesData,
  } = useQuery(EmplpoyeesList, {
    context: { clientName: "userClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error:", error);
    },
  });

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(employeesData?.employees?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(employeesData?.employees?.pageInfo.startCursor || null);
  };

  const [deleteEmployee] = useMutation(Delete_Employee, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList],
  });

  const [userStatusMutation] = useMutation(ACTIVE_INACTIVE_USER_STATUS, {
    onCompleted: (data) => {},
    onError: (error) => {
      console.error("Mutation error:", error);
    },
    context: { clientName: "userClient" },
    refetchQueries: [EmplpoyeesList],
  });

  //poopover functions
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );
  const [statusText, setStatusText] = React.useState<string | null>(null);
  const PopoverOpen = Boolean(anchorEl);
  const id = PopoverOpen ? "simple-popover" : undefined;
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    id: string,
    status: string
  ) => {
    setAnchorEl(event.currentTarget);
    setProfileId(id);
    setStatusText(status);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //Popup funtions
  const [profileId, setProfileId] = React.useState<null | string>("");
  const [popUpOpen, setPopupOpen] = React.useState(false);
  const [statusPopup, setStatusPopup] = useState<boolean>(false);
  const handlePopupOpen = () => setPopupOpen(true);
  const handlePopupClose = () => {
    setPopupOpen(false);
    setProfileId(null);
  };

  const handleViewProfileButton = () => {
    handlePopupOpen();
    handlePopoverClose();
  };

  const getRow = () => {
    return "user-list-table-row user-role-user-names-hover";
  };

  const handleDeletePopupClose = () => {
    setDeletePopup(false);
  };

  useEffect(() => {
    if (employeesData) {
      if (employeesData !== null) {
        const requiredDataList = employeesData.employees.edges.map(
          (each: any) => ({
            id: each.node.employeeId,
            firstName: each.node.firstName,
            lastName: each.node.lastName,
            email: each.node.email,
            status: each.node.employeeStatus,
            date: each.node.createdOn,
            pin: each.node.pin,
            phone: each.node.mobile,
            role: each.node && each.node.role && each.node.role.roleName,
          })
        );
        const filteredDataFromEmployeeId = requiredDataList.filter(
          (each: any) => each.id !== employeeId
        );
        setUsersList(filteredDataFromEmployeeId);
      }
    }
  }, [employeesData]);

  const handleEditClick = () => {
    navigate(`/users/${profileId}/update-user`);
  };

  const handlePopupDelete = () => {
    deleteEmployee({
      variables: { employeeIds: JSON.stringify([profileId && +profileId]) },
    });
    setAnchorEl(null);
    setProfileId(null);
    setDeletePopup(false);
  };

  const handleDeletePopupOpen = () => {
    setDeletePopup(true);
    setAnchorEl(null);
  };
  const handleStatusPopupClose = () => {
    setStatusPopup(false);
    setProfileId(null);
    setAnchorEl(null);
    setStatusText(null);
  };

  const handleUserStatusButton = () => {
    if (profileId) {
      userStatusMutation({
        variables: {
          employeeId: profileId,
          employeeStatus: statusText === "ACTIVE" ? "InActive" : "Active",
        },
      });
    }
    setAnchorEl(null);
    setProfileId(null);
    setStatusText(null);
    handleStatusPopupClose();
  };

  const popoverContent = (
    <List style={{ padding: "0px 0px 0px 0px" }}>
      <Button
        onClick={() => {
          setStatusPopup(true);
          setAnchorEl(null);
        }}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{
            width: "180px",
            height: "40px",
            textTransform: "capitalize",
          }}
          className="pover-text-style"
        >
          <ListItemText
            primary={
              statusText === "ACTIVE"
                ? t("buttons.de_activate")
                : t("buttons.re_activate")
            }
          />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleViewProfileButton}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("users.view_profile")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleEditClick}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.edit")} />
        </ListItem>
      </Button>
      <Divider />
      <Button
        onClick={handleDeletePopupOpen}
        style={{ textTransform: "capitalize", padding: "0px 0px 0px 0px" }}
      >
        <ListItem
          style={{ width: "180px", height: "40px" }}
          className="pover-text-style"
        >
          <ListItemText primary={t("buttons.delete")} />
        </ListItem>
      </Button>
    </List>
  );
  const userLang = navigator.language;
  const columns: GridColDef[] = [
    {
      field: "id",
      //@ts-ignore
      headerName: t("buttons.id"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 0.1,
    },
    {
      field: "firstName",
      //@ts-ignore
      headerName: t("users.first_name"),
      disableColumnMenu: true,

      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "lastName",
      //@ts-ignore
      headerName: t("users.last_name"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 1,
      renderCell: (params) => (
        <Typography sx={{ textTransform: "capitalize" }}>
          {params.value}
        </Typography>
      ),
    },
    {
      field: "email",
      //@ts-ignore
      headerName: t("users.email"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 2,
    },
    {
      field: "phone",
      //@ts-ignore
      headerName: t("users.phone"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 1.5,
    },
    {
      field: "role",
      //@ts-ignore
      headerName: t("users.role"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 1,
    },
    {
      field: "date",
      //@ts-ignore
      headerName: t("users.date_added"),
      headerClassName: "user-role-header-names",
      flex: 1.2,
      disableColumnMenu: true,

      renderCell: (params) => (
        <Box>
          {moment(params.value).format(`${localeToFormat[userLang]}`)}
          {/* {moment(params.value).local().format("D - MMM - YYYY")} */}
        </Box>
      ),
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("users.status"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,

      flex: 1,
      renderCell: (params) => {
        let activeClassnames;
        if (params.value === "ACTIVE") {
          activeClassnames = "user-list-active-text";
        } else if (params.value === "INACTIVE") {
          activeClassnames = "user-list-inactive-text";
        }
        return (
          <Typography className={activeClassnames}>
            {params.value === "ACTIVE"
              ? t("buttons.active")
              : t("buttons.in_active")}
          </Typography>
        );
      },
    },
    {
      field: "pin",
      //@ts-ignore
      headerName: t("users.reveal_pin"),
      disableColumnMenu: true,

      headerClassName: "user-role-header-names",
      flex: 1,
      renderCell: (params) => {
        //show the visibleOffIcon and pin
        const toggleVisibility = (index: number) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        };
        //show the visibleIcon and stars
        const toggleVisibilityHide = (index: any) => {
          const updatedShowData = [...showData];
          updatedShowData[index] = !updatedShowData[index];
          setShowData(updatedShowData);
        };

        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            {showData[params.row.id]
              ? params.value
              : "* ".repeat(params.value && params.value.length)}
            {showData[params.row.id] ? (
              <Tooltip title={t("users.hide_pin")} placement="top" arrow>
                <IconButton onClick={() => toggleVisibilityHide(params.row.id)}>
                  <VisibilityOffIcon sx={{ marginLeft: "15px" }} />
                </IconButton>
              </Tooltip>
            ) : (
              <Tooltip title={t("users.view_pin")} placement="top" arrow>
                <IconButton onClick={() => toggleVisibility(params.row.id)}>
                  <VisibilityIcon sx={{ marginLeft: "15px" }} />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    {
      field: "Actions",
      //@ts-ignore
      headerName: t("users.actions"),
      headerClassName: "user-role-header-names",
      disableColumnMenu: true,
      renderCell: (params) => (
        <Box
          className="user-roles-header-hover"
          style={{ display: "flex", alignItems: "center", height: "32px" }}
        >
          <IconButton
            onClick={(event) =>
              handleClick(event, params.row.id, params.row.status)
            }
          >
            <MoreVertOutlinedIcon />
          </IconButton>
          <Popover
            id={id}
            open={PopoverOpen}
            anchorEl={anchorEl}
            onClose={handlePopoverClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </Box>
      ),
    },
  ];

  const mainText = `${t("users.are_you_sure_with_title")} ${
    statusText === "ACTIVE"
      ? t("buttons.de_activating")
      : t("buttons.re_activating")
  } ${t("users.user")}`;
  const button1Text = `${t("buttons.yes")}, ${
    statusText === "ACTIVE"
      ? t("buttons.de_activate")
      : t("buttons.re_activate")
  } ${t("users.user")}`;
  const title = `${
    statusText === "ACTIVE"
      ? t("buttons.de_activate")
      : t("buttons.re_activating")
  } ${t("users.user")}`;

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box sx={{ margin: "1%" }}>
      <Drawer anchor={"right"} open={popUpOpen} onClose={handlePopupClose}>
        <Box width={660}>
          <DriverProfile
            handlePopupClose={handlePopupClose}
            profileId={profileId}
          />
        </Box>
      </Drawer>
      <PopupWithTitle
        open={statusPopup}
        mainText={mainText}
        button1Text={button1Text}
        button2Text={t("buttons.no_idontwant")}
        popUpwidth="45%"
        functionForButton1={handleUserStatusButton}
        functionForButton2={handleStatusPopupClose}
        functionForCloseIcon={handleStatusPopupClose}
        widthForTitle="60%"
        title={title}
        button1Variant="outlined"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="kds-listing-deactivate-button-1"
        button2Style="kds-listing-deactivate-button-2"
        titleStyle="kds-listing-deactivate-title"
      />
      <DeletePopup
        open={deletePopup}
        handleClose={handleDeletePopupClose}
        handleOpen={() => setDeletePopup(true)}
        handleSaveButton={handlePopupDelete}
        mainText={`${t("buttons.are_you_sure_delete")} ${t("users.user")}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="45%"
      />

      <Box sx={{ width: "100%" }}>
        <Box className="user-requests-header-order">
          <Typography className="user-requests-header-title">
            {t("users.users")}
          </Typography>
          <Button
            className="user-list-add-user-button"
            onClick={() => {
              navigate("/users/create-user");
            }}
            sx={{ textTransform: "none" }}
            variant="contained"
          >
            {t("users.add_user")}
          </Button>
        </Box>

        <DelayedInput
          width="30%"
          margin="10px 0px 10px 8px"
          setSearchValue={setSearchInput}
          placeHolder={t("users.search_by_name")}
          size={"medium"}
        />

        {employeesDataLoading ? (
          <CircularLoader />
        ) : (
          <Box
            sx={{
              padding: "10px",
              height: "70vh",
              width: "100%",
            }}
          >
            <DataGridTable
              shouldUpdatePaginationModelIsTrue={
                shouldUpdatePaginationModelIsTrue
              }
              handleUpdatingValuesToNull={handleUpdatingValuesToNull}
              paginationModel={paginationModel}
              rows={usersList}
              columns={columns}
              getRow={getRow}
              totalCount={employeesData?.employees?.totalCount || 0}
              handleEndCursor={handleEndCursor}
              handleStartCursor={handleStartCursor}
              currentPage={currentPage}
              imageHeight="250px"
              imageWidth="300px"
              heightForContainer="50vh"
            />
          </Box>
        )}
      </Box>
    </Box>
  );
}
export default UserList;
