import { useSelector } from "react-redux";

const RESET_STATE = 'RESET_STATE';
interface ResetStateAction {
    type: typeof RESET_STATE;
}

export const resetState = (): ResetStateAction => ({
    type: RESET_STATE,
});

export interface ReportData {

    reportLocation: string | null,
    reportEmployee: string | null,


}



const REPORT_DETAILS = 'REPORT_DETAILS';



export interface MainReportData {
    type: typeof REPORT_DETAILS;
    payload: {
        field: string;
        name: string;
    };
}




const reportData: ReportData = {

    reportLocation: null,
    reportEmployee: null,

};


export const setReportData = (field: string, name: string | any): MainReportData => ({
    type: REPORT_DETAILS,
    payload: {
        field,
        name
    },
});





export const reportDataReducer = (state = reportData, action: MainReportData | ResetStateAction): ReportData => {
    switch (action.type) {
        case REPORT_DETAILS:
            return {
                ...state,
                [action.payload.field]: action.payload.name,
            };


        case RESET_STATE:
            return {
                ...reportData,
            };

        default:
            return state;
    }
};

