import { Box, Typography } from "@mui/material";
import React from "react";
import Button from "@mui/material/Button";
import InvoiceReceipt from "../../../assets/addOns/invoice-receipt-svgrepo-com 1.svg";
import BankCard from "../../../assets/addOns/bank-card-svgrepo-com 1.svg";
import MarketingSvg from "../../../assets/addOns/marketing-spam-svgrepo-com 1.svg";
import ComputerMonitor from "../../../assets/addOns/computer-monitor-svgrepo-com 1.svg";
import GiftBox from "../../../assets/addOns/gift-svgrepo-com 1.svg";
import CreateIcon from "@mui/icons-material/Create";
import Checkmark from "../../../assets/check.svg";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { List } from "@mui/material";

interface State{
  setPayment:any
}


function AddOnsSection(props:State) {
  const {setPayment}=props;
  return (
    <Box
      marginTop="16px"
      textAlign="center"
      paddingX={{ sm: "20px", xs: "12px" }}
      marginBottom={'78px'}
    >
      <Box
        sx={{
          backgroundColor: "#FBFBFB",
          paddingY: { sm: "44px", xs: "24px" },
          paddingX: { md: "32px", xs: "16px" },
          borderRadius: "8px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "30px",
            flexWrap: { xs: "wrap", lg: "nowrap" },
          }}
        >
          <Box sx={{ 
          maxWidth: "772px",
          width: "100%",
        }}>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "24px",
                fontWeight: "500",
                color: "#333333",
                lineHeight: "36px",
                textAlign: "center",

              }}
            >
              Add On’s to Customise Your Selected Package
            </Typography>
            <Typography
              sx={{
                fontFamily: "Poppins",
                fontSize: "15.5px",
                fontWeight: "400",
                color: "#707070",
                lineHeight: "24px",
                textAlign: "center",
                mt: "12px",
                whiteSpace: { xs: "wrap", xl: "nowrap" },
              }}
            >
              Crafted to draw in fresh customers and encourage return visits
              through these software solutions.
            </Typography>
            <Box
              sx={{
                paddingX: { sm: "18.5px", xs: "0px" },
                marginTop: "10px",
              }}
            >
              <Box
                sx={{
                  paddingX: "20px",
                  paddingY: "12px",
                  backgroundColor: "#FFFFFF",
                  borderRadius: "8px",
                  display: "flex",
                  alignItems: "center",
                  justifyItems: "center",
                  justifyContent:{xs:"center", lg:'start'},
                  flexWrap: "wrap",
                  gap: "20px",
                }}
              >
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box width={"50px"} height={"50px"}>
                    <img src={InvoiceReceipt} alt="InvoiceReceiptImg" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      Receipt Builder
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £12/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#707070",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      padding: "0px",
                      textTransform: "none",
                    }}
                    variant="text"
                  >
                    Remove
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box width={"50px"} height={"50px"}>
                    <img src={BankCard} alt="InvoiceReceiptImg" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      Gift Cards
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £8/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#F38B08",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      padding: "0",
                      textTransform: "none",
                    }}
                    variant="text"
                  >
                    Add to Pack
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={InvoiceReceipt} alt="InvoiceReceiptImg" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      Receipt Builder
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £12/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#F38B08",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      textTransform: "none",
                      padding: "0px",
                    }}
                    variant="text"
                  >
                    Add to Pack
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={MarketingSvg} alt="MarketingSvg" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      Email marketing
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £22/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#F38B08",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      textTransform: "none",
                      padding: "0px",
                    }}
                    variant="text"
                  >
                    Add to Pack
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={ComputerMonitor} alt="ComputerMonitor" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      POS
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £3/terminal
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#F38B08",
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "center",
                        textTransform: "none",
                        padding: "0px",
                      }}
                      variant="text"
                    >
                      Add to Pack
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #707070",
                          backgroundColor: "#D9D9D9",
                          cursor:'pointer',
                        }}
                      >
                        <Box
                          sx={{ width: "8px", border: "2px solid #707070" }}
                        ></Box>
                      </Box>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "27px",
                          color: "#333333",
                        }}
                      >
                        0
                      </Typography>
                      <Box
                        sx={{
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #00AE26",
                          backgroundColor: "#FFFFFF",
                          cursor:'pointer',
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#00AE26",
                            fontSize: "18px",
                            lineHeight: "14px",
                            fontWeight: "800",
                          }}
                        >
                          +
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #F38B08",
                    gap: "16px",
                    // borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={GiftBox} alt="GiftBoxImage" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      RLoyalty Points
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £12/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#707070",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      textTransform: "none",
                      padding: "0px",
                    }}
                    variant="text"
                  >
                    Remove
                  </Button>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={ComputerMonitor} alt="ComputerMonitor" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      POS
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £3/terminal
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        color: "#F38B08",
                        fontFamily: "poppins",
                        fontSize: "16px",
                        fontWeight: "500",
                        lineHeight: "24px",
                        textAlign: "center",
                        textTransform: "none",
                        padding: "0px",
                      }}
                      variant="text"
                    >
                      Add to Pack
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        gap: "16px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #707070",
                          backgroundColor: "#D9D9D9",
                          cursor:'pointer',
                        }}
                      >
                        <Box
                          sx={{ width: "8px", border: "2px solid #707070" }}
                        ></Box>
                      </Box>
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontWeight: "600",
                          fontSize: "18px",
                          lineHeight: "27px",
                          color: "#333333",
                        }}
                      >
                        0
                      </Typography>
                      <Box
                        sx={{
                          width: "18px",
                          height: "18px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          border: "2px solid #00AE26",
                          backgroundColor: "#FFFFFF",
                          cursor:'pointer',
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#00AE26",
                            fontSize: "18px",
                            lineHeight: "14px",
                            fontWeight: "800",
                          }}
                        >
                          +
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "210px",
                    height: "172px",
                    borderRadius: "4px",
                    border: "1px solid #EEEEEE",
                    gap: "16px",
                    borderColor: "#EEEEEE",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                  }}
                >
                  <Box>
                    <img src={InvoiceReceipt} alt="InvoiceReceiptImg" />
                  </Box>
                  <Box>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      Receipt Builder
                    </Typography>
                    <Typography
                      sx={{
                        color: "#333333",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        fontWeight: "500",
                        lineHeight: "21px",
                        textAlign: "center",
                      }}
                    >
                      £12/mo
                    </Typography>
                  </Box>
                  <Button
                    sx={{
                      color: "#F38B08",
                      fontFamily: "poppins",
                      fontSize: "16px",
                      fontWeight: "500",
                      lineHeight: "24px",
                      textAlign: "center",
                      textTransform: "none",
                      padding: "0px",
                    }}
                    variant="text"
                  >
                    Remove
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              maxWidth: "360px",
              width: "100%",
            }}
          >
            <Box
              sx={{
                border: "1px solid #EEEEEE",
                borderRadius: "4px",
                background: "white",
                marginX: { xs: "auto", md: "0" },
              }}
            >
              <Box
                sx={{
                  paddingTop: "20px",
                  paddingLeft: { sm: "19px", xs: "16px" },
                  paddingRight: { sm: "30px", xs: "16px" },
                  paddingBottom: "24px",
                }}
              >
                <Typography
                  sx={{
                    fontFamily: "poppins",
                    fontWeight: "500",
                    fontSize: "24px",
                    lineHeight: "36px",
                    color: "#333333",
                    textAlign: "start",
                  }}
                >
                  Your Order
                </Typography>
                <Box
                  sx={{
                    border: "1px solid #F9C584",
                    borderRadius: "4px",
                    marginTop: "12px",
                    paddingY: "7px",
                    paddingX: "10px",
                    textAlign: "right",
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "14px",
                      lineHeight: "21px",
                      color: "#707070",
                      whiteSpace: { xs: "wrap", lg: "nowrap" },
                      textAlign:'start',
                    }}
                  >
                    save 30% yearly & £10.5 per month with
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "end",
                      marginTop: "10px",
                    }}
                  >
                    <Button
                      sx={{
                        backgroundColor: "#F38B08",
                        width: "99px",
                        height: "29px",
                        fontFamily: "poppins",
                        fontSize: "14px",
                        lineHeight: "21px",
                        fontWeight: "400",
                        boxShadow: "none",
                        borderRadius: "4px",
                        color: "#fff",
                        padding: "0px",
                        textTransform: "none",
                      }}
                      variant="contained"
                    >
                      Annual Plan
                    </Button>
                  </Box>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "8px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontFamily: "poppins",
                        fontWeight: "500",
                        fontSize: { xs: "13px", md: "18px" },
                        lineHeight: "27px",
                        color: "#333333",
                        textAlign: "left",
                      }}
                    >
                      Gold Package
                    </Typography>
                    <CreateIcon
                      sx={{
                        width: "12px",
                        height: "12px",
                        fill: "#ABABAB",
                      }}
                    ></CreateIcon>
                  </Box>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: { xs: "12px", md: "14px" },
                      lineHeight: "21px",
                      color: "#707070",
                    }}
                  >
                    £49 + £2.00/mo
                  </Typography>
                </Box>
                <List
                  sx={{
                    listStyle: "none",
                    paddingY: "0px",
                  }}
                >
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "16px",
                      }}
                    >
                      <img src={Checkmark} alt="Checkmark" />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#707070",
                          textAlign: "left",
                        }}
                      >
                        Epos (Digital and QR Menu)
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "16px",
                      }}
                    >
                      <img src={Checkmark} alt="Checkmark" />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#707070",
                          textAlign: "left",
                        }}
                      >
                        Admin App
                      </Typography>
                    </Box>
                  </li>
                  <li>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "8px",
                        marginTop: "16px",
                      }}
                    >
                      <img src={Checkmark} alt="Checkmark" />
                      <Typography
                        sx={{
                          fontFamily: "poppins",
                          fontSize: "14px",
                          lineHeight: "21px",
                          color: "#707070",
                        }}
                      >
                        Restaurant Website/Mobile App
                      </Typography>
                    </Box>
                  </li>
                </List>
                <Box marginTop={"24px"}>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontWeight: "500",
                      fontSize: "18px",
                      lineHeight: "27px",
                      color: "#333333",
                      textAlign: "start",
                    }}
                  >
                    Add On’s
                  </Typography>
                  <List
                    sx={{
                      listStyle: "none",
                      paddingY: "0px",
                    }}
                  >
                    <li>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "16px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <img src={Checkmark} alt="Checkmark" />
                          <Typography
                            sx={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#333",
                            }}
                          >
                            Loyalty Points
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#707070",
                          }}
                        >
                          £20.00/mo
                        </Typography>
                      </Box>
                    </li>
                    <li>
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          alignItems: "center",
                          justifyContent: "space-between",
                          marginTop: "16px",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            gap: "8px",
                          }}
                        >
                          <img src={Checkmark} alt="Checkmark" />
                          <Typography
                            sx={{
                              fontFamily: "poppins",
                              fontSize: "14px",
                              lineHeight: "21px",
                              color: "#333",
                            }}
                          >
                            Receipt Builder
                          </Typography>
                        </Box>
                        <Typography
                          sx={{
                            fontFamily: "poppins",
                            fontSize: "14px",
                            lineHeight: "21px",
                            color: "#707070",
                          }}
                        >
                          £12.00/mo
                        </Typography>
                      </Box>
                    </li>
                  </List>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop:{xs:'100px', sm:'190px'},
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      lineHeight: "27px",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    Order Total
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "poppins",
                      fontSize: "18px",
                      lineHeight: "27px",
                      fontWeight: "500",
                      color: "#333",
                    }}
                  >
                    £84.00
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: { xs: "center", md: "end" },
            marginTop: { md: "32px", xs: "30px" },
            flexWrap: { sm: "nowrap", xs: "wrap" },
            gap: { sm: "10px", xs: "20px" },
          }}
        >
          <Button
            sx={{
              fontFamily: "poppins",
              width: "181px",
              height: "48px",
              foontSize: "18px",
              fontWeight: "500",
              lineHeight: "27px",
              gap: { xs: "10px", md: "24px" },
              color: "#F38B08",
              backgroundColor: "transparent",
              border: "2px solid #F38B08",
              boxShadow:'none !important',
              ":hover": {
                backgroundColor: " #F38B08",
                color: "#fff",
              },
              textTransform:'capitalize',
            }}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Skip & Next
          </Button>
          <Button
            onClick={() => setPayment(true)}
            sx={{
              backgroundColor: "#F38B08",
              width: { sm: "120px", xs: "181px" },
              height: "48px",
              color: "white",
              fontFamily: "poppins",
              foontSize: "18px",
              fontWeight: "500",
              lineHeight: "27px",
              boxShadow: "none",
              gap: { xs: "10px", md: "24px" },
              ":hover": {
                backgroundColor: "#F38B08",
              },
              textTransform:'capitalize',
            }}
            variant="contained"
            endIcon={<ArrowForwardIcon />}
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AddOnsSection;
