import { gql } from "@apollo/client";

export const RECENT_ORDERS_FOR_DASHBOARD=gql`
query recentOrdersForDashboard($locationId:ID,$fromDate:Date,$toDate:Date,$top:Int){
	recentOrdersForDashboard(
		locationId:$locationId
		fromDate:$fromDate
		toDate:$toDate
		top:$top	
	)
}
`

export const ACTIVITY_FOR_DASHBOARD=gql`
query activityForDashboard($locationId:ID,$fromDate:Date,$toDate:Date){
	activityForDashboard(locationId:$locationId,fromDate:$fromDate,toDate:$toDate)
}
`

export const STATISTICS_FOR_DATA=gql`
query statisticsForDashboard($locationId:ID,$fromDate:Date,$toDate:Date){
	statisticsForDashboard(
		locationId:$locationId
		fromDate:$fromDate
		toDate:$toDate
	)
}

`

export const POPULAR_ITEMS_FOR_DASHBOARD=gql`
query popularItemsForDashboard($locationId:ID,$fromDate:Date,$toDate:Date){
	popularItemsForDashboard(
		locationId:$locationId
		fromDate:$fromDate
		toDate:$toDate
		
		
	)
	
}


`