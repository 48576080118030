import { Box } from "@mui/material";
import React from "react";
import Application from "./application";

function SetupGuideComponenet() {
  return (
    <Box>
      <Application />
    </Box>
  );
}

export default SetupGuideComponenet;
