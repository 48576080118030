import { useMutation, useQuery } from "@apollo/client";
import { Modal, Typography } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { STORE_SETTINGS } from "../../../graphicalQl/usequery/customerDataQuery";
import EditOrderStatusName from "./editOrderStatusName";
import { UPDATE_ORDER_STATUS } from "../../../graphicalQl/mutation/customerDataMutation";
import { useSelector } from "react-redux";
import { t } from "i18next";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-columnHeader": {
    color: "#333333",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    backgroundColor: "#F5F5F5",
    border: "none",
  },
  "& .MuiDataGrid-row": {
    "&:hover": {
      backgroundColor: "rgba(243, 139, 8, 0.10) !important",
    },
  },
  "& .MuiDataGrid-cell": {
    color: "#000",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    border: "none",
  },
  "& .MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
    outline: "none !important",
  },
}));

const OrderStatus = () => {
  const [editOrderStatusNameModal, setEditOrderStatusNameModal] = useState({
    state: false,
    data: {},
  });

  const [storeSettings, setStoreSettings] = useState([]);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { data, loading, error, refetch } = useQuery(STORE_SETTINGS, {
    variables: {
      storeId: +locationId,
    },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });

  const [
    updateOrderStatus,
    { data: updateOrderStatusData, error: updateOrderStatusError },
  ] = useMutation(UPDATE_ORDER_STATUS, {
    context: { clientName: "businessClient" },
  });

  useEffect(() => {
    if (updateOrderStatusData?.branchSettings?.success) {
      refetch();
      setEditOrderStatusNameModal({
        state: false,
        data: {},
      });
    }
  }, [updateOrderStatusData]);

  const updateOrderStatusDetails = (id: number, newAlternateName: string) => {
    const updatedOrderStatuses =
      storeSettings &&
      storeSettings.map((orderStatus: any, index) => {
        return {
          name: orderStatus.name,
          alternate_name:
            index === id ? newAlternateName : orderStatus.alternateName,
        };
      });

    updateOrderStatus({
      variables: {
        storeId: +locationId,
        orderStatusLookup: JSON.stringify(updatedOrderStatuses),
      },
    });
  };

  useEffect(() => {
    if (data && data.storeSettings && data.storeSettings.orderStatusLookup) {
      const parsedData = JSON.parse(
        data?.storeSettings?.orderStatusLookup
      )?.map((data: any, index: any) => ({
        id: index + 1,
        name: data.name,
        alternateName: data.alternate_name,
      }));

      setStoreSettings(parsedData);
    }
  }, [data]);

  const columns: GridColDef[] = [
    {
      field: "id",
      headerName: t("buttons.id") || "",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "name",
      headerName: t("orders.name") || "",
      flex: 1,
      headerAlign: "center",
      sortable: false,
      filterable: false,
      align: "center",
    },
    {
      field: "alternateName",
      headerName: t("orders.alternate_name") || "",
      headerAlign: "center",
      flex: 1,
      sortable: false,
      filterable: false,
      align: "center",
    },
  ];

  return (
    <div>
      <Typography
        sx={{
          color: "#333",
          fontFamily: "Poppins",
          fontSize: "18px",
          fontStyle: "normal",
          fontWeight: 600,
          lineHeight: "normal",
          marginTop: "24px",
          marginLeft: "32px",
          marginBottom: "20px",
        }}
      >
        {t("orders.order_status")}
      </Typography>
      <div style={{ width: "100%" }}>
        <StyledDataGrid
          rows={storeSettings}
          columns={columns}
          // initialState={{
          //   pagination: {
          //     paginationModel: { pageSize: 25, page: 0 },
          //   },
          // }}
          disableColumnMenu
          onCellClick={(params, event, details) => {
            if (params.field === "alternateName") {
              setEditOrderStatusNameModal({
                state: true,
                data: {
                  ...params.row,
                  updateOrderStatusDetails,
                },
              });
            }
          }}
          hideFooter={true}
          hideFooterPagination={true}
          hideFooterSelectedRowCount={true}
          sx={{
            "&.MuiDataGrid-columnHeaderTitle": {
              fontWeight: "500",
            },
            "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
              outline: "solid #1976d2 0px !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
              {
                outline: "solid #1976d2 0px !important",
              },
            "& .MuiDataGrid-columnHeader": {
              background: "#f5f5f5",
            },
          }}
        />
      </div>
      <Modal
        disablePortal={true}
        aria-labelledby="close-modal-title"
        open={editOrderStatusNameModal.state}
        onClose={() => {
          setEditOrderStatusNameModal({
            state: false,
            data: {},
          });
        }}
        sx={{
          display: "flex",
          alignItems: "start",
          justifyContent: "end",
          right: "430px",
          top: "150px",
          opacity: 1,
        }}
      >
        <EditOrderStatusName
          editOrderStatusNameModal={editOrderStatusNameModal}
          setEditOrderStatusNameModal={setEditOrderStatusNameModal}
        />
      </Modal>
    </div>
  );
};

export default OrderStatus;
