import * as React from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import uuid from "react-uuid";
import DeleteIcon from "@mui/icons-material/Delete";
import { UPLOAD_IMAGE } from "../../../graphicalQl/mutation/imageMutation";
import { useMutation, useQuery } from "@apollo/client";
import { GET_IMAGE } from "../../../graphicalQl/usequery/imageQuery";
import { DELETE_IMAGE } from "../../../graphicalQl/mutation/imageMutation";
import CircularLoader from "../../../loader/loader";
import { useTranslation } from "react-i18next";

interface ImageData {
  id: string;
  img: string;
}

export default function Image() {
  const [imageData, setImageData] = React.useState<ImageData[]>([]);
  const [imageData1, setImageData1] = React.useState<ImageData[]>([]);
  const [imageData2, setImageData2] = React.useState<ImageData[]>([]);
  const [imageData3, setImageData3] = React.useState<ImageData[]>([]);
  const [imageData4, setImageData4] = React.useState<ImageData[]>([]);

  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [imageType, setImageType] = React.useState("catalog");

  const { data: getImage, loading: catalogLoading } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: { businessId: +businessId, imageType: "catalog" },
  });
  const { data: getImage1, loading: brandingLoading } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: { businessId: +businessId, imageType: "location" },
  });
  const { data: getImage2, loading: employeeLoading } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: { businessId: +businessId, imageType: "employee" },
  });

  const { data: getImage3, loading: businessLoading } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: { businessId: +businessId, imageType: "business_logo" },
  });

  const { data: getImage4, loading: favouriteLoading } = useQuery(GET_IMAGE, {
    context: { clientName: "mediaContext" },
    variables: { businessId: +businessId, imageType: "favicon" },
  });

  React.useEffect(() => {
    if (getImage && getImage.imageList) {
      const list = JSON.parse(getImage.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData(list);
    }
  }, [getImage]);
  React.useEffect(() => {
    if (getImage1 && getImage1.imageList) {
      const list = JSON.parse(getImage1.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData1(list);
    }
  }, [getImage1]);
  React.useEffect(() => {
    if (getImage2 && getImage2.imageList) {
      const list = JSON.parse(getImage2.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData2(list);
    }
  }, [getImage2]);

  React.useEffect(() => {
    if (getImage3 && getImage3.imageList) {
      const list = JSON.parse(getImage3.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData3(list);
    }
  }, [getImage3]);
  React.useEffect(() => {
    if (getImage4 && getImage4.imageList) {
      const list = JSON.parse(getImage4.imageList).map((each: any) => ({
        img: each,
        id: uuid(),
      }));

      setImageData4(list);
    }
  }, [getImage4]);

  // const getImageData = () => {
  //   const apiUrl = `http://51.75.170.243:5010/media/get_images?business=business-${businessId}`;
  //   axios.get(apiUrl)
  //     .then(response => {
  //       // Handle the response data here
  //       console.log('Response:', response.data);
  //       if (response.data && response.data.images) {
  //         const list = response.data.images.map((each: any) => ({
  //           img: each,
  //           id: uuid(),
  //         }));

  //         setImageData(list)
  //       }

  //     })
  //     .catch(error => {
  //       // Handle errors here
  //       //console.error('Error:', error);
  //     });
  // }
  // React.useEffect(() => {
  //   getImageData()
  // }, [])

  const [selectImg, setSelectImag] = React.useState("");
  const handleSelectImg = (id: string) => {
    const image = imageData.filter((each) => each.id === id)[0].img;
    setSelectImag(image);
  };

  const handleMouseLeave = () => {
    setSelectImag("");
  };

  const [selectImg1, setSelectImag1] = React.useState("");
  const handleSelectImg1 = (id: string) => {
    const image = imageData1.filter((each) => each.id === id)[0].img;
    setSelectImag1(image);
  };

  const handleMouseLeave1 = () => {
    setSelectImag1("");
  };
  const [selectImg2, setSelectImag2] = React.useState("");
  const [selectImg3, setSelectImag3] = React.useState("");
  const [selectImg4, setSelectImag4] = React.useState("");
  const handleSelectImg2 = (id: string) => {
    const image = imageData2.filter((each) => each.id === id)[0].img;
    setSelectImag2(image);
  };
  const handleSelectImg3 = (id: string) => {
    const image = imageData3.filter((each) => each.id === id)[0].img;
    setSelectImag3(image);
  };
  const handleSelectImg4 = (id: string) => {
    const image = imageData4.filter((each) => each.id === id)[0].img;
    setSelectImag4(image);
  };

  const handleMouseLeave2 = () => {
    setSelectImag2("");
  };

  const handleMouseLeave3 = () => {
    setSelectImag3("");
  };
  const handleMouseLeave4 = () => {
    setSelectImag4("");
  };

  const [imageValue, setImageValue] = React.useState<string | null>(null);
  const handleImageUpload = (file: File) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      setImageValue(e.target?.result as string);
    };
    reader.readAsDataURL(file);
  };

  const [uploadImage] = useMutation(UPLOAD_IMAGE, {
    context: { clientName: "mediaContext" },
  });
  const fetchData = async () => {
    // const apiUrl = 'http://51.75.170.243:5010/media/base64_image_upload';
    // const requestData = {
    //   "image":imageValue,
    //   "business_id":businessId,
    // };

    // try {
    //   const response: AxiosResponse = await axios.post(apiUrl, requestData, {

    //   });

    //   if (response.data) {
    //     const { access } = response.data;

    //   }

    //   setImageUrl(response.data&& response.data.image_url)
    //   getImageData()
    // }
    // catch (error) {

    //   if (axios.isAxiosError(error)) {

    //     const axiosError: AxiosError = error;
    //     if (axiosError.response) {

    //       console.error('Server responded with an error:', axiosError.response.data);
    //     } else if (axiosError.request) {

    //       console.error('No response received from the server');
    //     } else {

    //     }
    //   }
    // }
    uploadImage({
      variables: {
        businessId: +businessId,
        image: imageValue,
        imageType: imageType,
      },
      refetchQueries: [GET_IMAGE],
    });
  };
  React.useEffect(() => {
    if (imageValue !== null) {
      fetchData();
    }
  }, [imageValue]);
  const [deleteImage] = useMutation(DELETE_IMAGE, {
    context: { clientName: "mediaContext" },
    refetchQueries: [GET_IMAGE],
  });

  const handleDeleteImage = async (value: string) => {
    deleteImage({ variables: { image: value } });

    // const apiUrl = 'http://51.75.170.243:5010/media/delete_image';
    // const requestData = {
    //   "image_url": value,

    // };

    // try {
    //   const response: AxiosResponse = await axios.put(apiUrl, requestData, {

    //   });

    //   if (response.data) {
    //     const { access } = response.data;

    //   }

    // }
    // catch (error) {

    //   if (axios.isAxiosError(error)) {

    //     const axiosError: AxiosError = error;
    //     if (axiosError.response) {

    //       console.error('Server responded with an error:', axiosError.response.data);
    //     } else if (axiosError.request) {

    //       console.error('No response received from the server');
    //     } else {

    //     }
    //   }

    // };
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    const files = event.dataTransfer.files;
    if (files && files.length > 0) {
      const imageFile = files[0];
      handleImageUpload(imageFile);

      // Reset the value of the file input to close the file folder
    }
  };

  const handleDragOver = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
  };

  const handleChange = (event: SelectChangeEvent) => {
    setImageType(event.target.value as string);
  };

  if (catalogLoading || brandingLoading || employeeLoading) {
    return <CircularLoader />;
  }
  return (
    <Box>
      <Box
        sx={{
          padding: "20px 1.34% 12px 1.34%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Typography className="image-text-style">
            {t("image.image_library")}
          </Typography>
          <Typography className="image-para">
            {t("image.image_text")}
          </Typography>
        </Box>
        {/* <Box>
          <div
            className="drop-zone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
          >

            <Button
              className="main-button"
              onClick={() => {
                const input = document.getElementById('image-upload-input');
                if (input) {
                  input.click();
                }
              }}
            >
              {t("image.upload_img")}
            </Button>
            <input
              type="file"
              accept="image/png"
              hidden
              id="image-upload-input"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  handleImageUpload(file); // Pass the File object directly
                }
              }}
            />
          </div>

        </Box> */}
        <Box sx={{ minWidth: 220 }}>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">
              {t("image.image_type")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={imageType}
              label="Image Type"
              onChange={handleChange}
              // "catelog","customer","documents"
            >
              <MenuItem value="catalog">{t("image.catlog_img")}</MenuItem>
              <MenuItem value="location">{t("image.branding")}</MenuItem>
              <MenuItem value="employee">{t("image.employee_img")}</MenuItem>
              <MenuItem value="business_logo">
                {t("image.business_logo")}
              </MenuItem>
              <MenuItem value="favIcon">{t("image.fav_icon")}</MenuItem>
            </Select>
          </FormControl>
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{
          maxHeight: "70vh",
          overflowY: "scroll",
          WebkitOverflowScrolling: "touch",
          "&::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Box
            sx={{
              width: "60%",
            }}
            className="imageList-container2"
          >
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {t("image.catlog_img")}
              </Typography>
              <ImageList
                sx={{
                  width: "100%",
                  mt: "12px",
                  gap: "20px !important",
                }}
                cols={5}
              >
                {imageData &&
                  imageData.map((item) => (
                    <ImageListItem
                      key={item.img}
                      onMouseEnter={() => handleSelectImg(item.id)}
                      onMouseLeave={() => handleMouseLeave()}
                      sx={{
                        // border: selectImg === item.img ? "2px solid #222222" : "2px solid transparent",
                        height: "130px !important",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        srcSet={`${item.img}?w=104&h=130&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=104&h=130&fit=crop&auto=format`}
                        alt={item.id}
                        loading="lazy"
                        style={{
                          borderRadius: "8px",
                          height: "130px",

                          position: "relative",
                        }}
                      />
                      {selectImg === item.img && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,

                            width: "100%",
                            height: "100%",
                            display: "flex",
                            padding: "1px",
                            justifyContent: "flex-end", // Corrected from 'justify: center'
                            alignItems: "flex-start", // Corrected from 'align-items: center;'
                            gap: "10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.30)",
                          }}
                        >
                          {/* Add your right icon here */}
                          <IconButton
                            color="warning"
                            onClick={() => handleDeleteImage(item.img)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Box>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                marginTop: "12px",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {t("image.branding")}
              </Typography>
              <ImageList
                sx={{
                  width: "100%",
                  mt: "12px",
                  gap: "20px !important",
                }}
                cols={5}
              >
                {imageData1 &&
                  imageData1.map((item) => (
                    <ImageListItem
                      key={item.img}
                      onMouseEnter={() => handleSelectImg1(item.id)}
                      onMouseLeave={() => handleMouseLeave1()}
                      sx={{
                        // border: selectImg === item.img ? "2px solid #222222" : "2px solid transparent",
                        height: "130px !important",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        srcSet={`${item.img}?w=104&h=130&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=104&h=130&fit=crop&auto=format`}
                        alt={item.id}
                        loading="lazy"
                        style={{
                          borderRadius: "8px",
                          height: "130px",

                          position: "relative",
                        }}
                      />
                      {selectImg1 === item.img && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,

                            width: "100%",
                            height: "100%",
                            display: "flex",
                            padding: "1px",
                            justifyContent: "flex-end", // Corrected from 'justify: center'
                            alignItems: "flex-start", // Corrected from 'align-items: center;'
                            gap: "10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.30)",
                          }}
                        >
                          {/* Add your right icon here */}
                          <IconButton
                            color="warning"
                            onClick={() => handleDeleteImage(item.img)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Box>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>

            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                marginTop: "12px",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {t("image.employee_img")}
              </Typography>
              <ImageList
                sx={{
                  width: "100%",
                  mt: "12px",
                  gap: "20px !important",
                }}
                cols={5}
              >
                {imageData2 &&
                  imageData2.map((item) => (
                    <ImageListItem
                      key={item.img}
                      onMouseEnter={() => handleSelectImg2(item.id)}
                      onMouseLeave={() => handleMouseLeave2()}
                      sx={{
                        // border: selectImg === item.img ? "2px solid #222222" : "2px solid transparent",
                        height: "130px !important",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        srcSet={`${item.img}?w=104&h=130&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=104&h=130&fit=crop&auto=format`}
                        alt={item.id}
                        loading="lazy"
                        style={{
                          borderRadius: "8px",
                          height: "130px",

                          position: "relative",
                        }}
                      />
                      {selectImg2 === item.img && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,

                            width: "100%",
                            height: "100%",
                            display: "flex",
                            padding: "1px",
                            justifyContent: "flex-end", // Corrected from 'justify: center'
                            alignItems: "flex-start", // Corrected from 'align-items: center;'
                            gap: "10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.30)",
                          }}
                        >
                          {/* Add your right icon here */}
                          <IconButton
                            color="warning"
                            onClick={() => handleDeleteImage(item.img)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Box>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>

            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                marginTop: "12px",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {/* {t("image.employee_img")}
                 */}
                Business Logo
              </Typography>
              <ImageList
                sx={{
                  width: "100%",
                  mt: "12px",
                  gap: "20px !important",
                }}
                cols={5}
              >
                {imageData3 &&
                  imageData3.map((item) => (
                    <ImageListItem
                      key={item.img}
                      onMouseEnter={() => handleSelectImg3(item.id)}
                      onMouseLeave={() => handleMouseLeave3()}
                      sx={{
                        // border: selectImg === item.img ? "2px solid #222222" : "2px solid transparent",
                        height: "130px !important",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        srcSet={`${item.img}?w=104&h=130&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=104&h=130&fit=crop&auto=format`}
                        alt={item.id}
                        loading="lazy"
                        style={{
                          borderRadius: "8px",
                          height: "130px",
                          objectFit: "contain",
                          //   "&.MuiImageListItem-img": {
                          //   objectFit: "contain",
                          // },

                          position: "relative",
                        }}
                      />
                      {selectImg3 === item.img && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,

                            width: "100%",
                            height: "100%",
                            display: "flex",
                            padding: "1px",
                            justifyContent: "flex-end", // Corrected from 'justify: center'
                            alignItems: "flex-start", // Corrected from 'align-items: center;'
                            gap: "10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.30)",
                          }}
                        >
                          {/* Add your right icon here */}
                          <IconButton
                            color="warning"
                            onClick={() => handleDeleteImage(item.img)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Box>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>

            <Box
              sx={{
                maxHeight: "50vh",
                overflowY: "scroll",
                WebkitOverflowScrolling: "touch",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                marginTop: "12px",
              }}
            >
              <Typography sx={{ fontSize: "16px" }}>
                {/* {t("image.employee_img")}
                 */}
                Favourite Icons
              </Typography>
              <ImageList
                sx={{
                  width: "100%",
                  mt: "12px",
                  gap: "20px !important",
                }}
                cols={5}
              >
                {imageData4 &&
                  imageData4.map((item) => (
                    <ImageListItem
                      key={item.img}
                      onMouseEnter={() => handleSelectImg4(item.id)}
                      onMouseLeave={() => handleMouseLeave4()}
                      sx={{
                        // border: selectImg === item.img ? "2px solid #222222" : "2px solid transparent",
                        height: "130px !important",
                        borderRadius: "8px",
                      }}
                    >
                      <img
                        srcSet={`${item.img}?w=104&h=130&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=104&h=130&fit=crop&auto=format`}
                        alt={item.id}
                        loading="lazy"
                        style={{
                          borderRadius: "8px",
                          height: "130px",
                          objectFit: "contain",

                          position: "relative",
                        }}
                      />
                      {selectImg4 === item.img && (
                        <Box
                          sx={{
                            position: "absolute",
                            top: 0,
                            right: 0,

                            width: "100%",
                            height: "100%",
                            display: "flex",
                            padding: "1px",
                            justifyContent: "flex-end", // Corrected from 'justify: center'
                            alignItems: "flex-start", // Corrected from 'align-items: center;'
                            gap: "10px",
                            borderRadius: "4px",
                            cursor: "pointer",
                            background: "rgba(0, 0, 0, 0.30)",
                          }}
                        >
                          {/* Add your right icon here */}
                          <IconButton
                            color="warning"
                            onClick={() => handleDeleteImage(item.img)}
                          >
                            <DeleteIcon fontSize="medium" />
                          </IconButton>
                        </Box>
                      )}
                    </ImageListItem>
                  ))}
              </ImageList>
            </Box>
          </Box>
          <div
            className="drop-zone"
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            style={{ width: "31.1%", marginTop: "18px" }}
          >
            <Box
              sx={{
                width: "100%",
                background: "#F9F9F9",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                maxHeight: "50vh",
                padding: "12px",
              }}
            >
              <Box className="upload-container" sx={{ textAlign: "center" }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="150"
                  height="150"
                  viewBox="0 0 150 150"
                  fill="none"
                >
                  <g opacity="0.5">
                    <path
                      d="M120.938 62.75C116.688 41.1875 97.75 25 75 25C56.9375 25 41.25 35.25 33.4375 50.25C14.625 52.25 0 68.1875 0 87.5C0 108.188 16.8125 125 37.5 125H118.75C136 125 150 111 150 93.75C150 77.25 137.188 63.875 120.938 62.75ZM118.75 112.5H37.5C23.6875 112.5 12.5 101.312 12.5 87.5C12.5 74.6875 22.0625 64 34.75 62.6875L41.4375 62L44.5625 56.0625C50.5 44.625 62.125 37.5 75 37.5C91.375 37.5 105.5 49.125 108.688 65.1875L110.562 74.5625L120.125 75.25C129.875 75.875 137.5 84.0625 137.5 93.75C137.5 104.062 129.062 112.5 118.75 112.5ZM50 81.25H65.9375V100H84.0625V81.25H100L75 56.25L50 81.25Z"
                      fill="#ABABAB"
                    />
                  </g>
                </svg>

                <Box>
                  <Typography
                    className="color-text"
                    sx={{ marginBottom: "4px" }}
                  >
                    {t("image.drag_image")}
                  </Typography>
                  <Button
                    className={
                      imageType === "" ? "main-button-disable" : "main-button"
                    }
                    disabled={imageType === ""}
                    onClick={() => {
                      const input =
                        document.getElementById("image-upload-input");
                      if (input) {
                        input.click();
                      }
                    }}
                  >
                    {t("image.upload_img")}
                  </Button>
                  <input
                    type="file"
                    accept="image/png"
                    hidden
                    id="image-upload-input"
                    onChange={(e) => {
                      const file = e.target.files?.[0];
                      if (file) {
                        handleImageUpload(file); // Pass the File object directly
                      }
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </div>
        </Box>
      </Box>
    </Box>
  );
}
