// i want to use this data in my review component
//get the data using useQuery
import { useQuery, gql } from '@apollo/client';
import { useSelector } from 'react-redux';

interface Props {
    search: string;
    filterBy: string;
    date: any;
    setData: (data: any) => void;

}

export const GET_QUERY = gql`
query Ratings($storeId: Int,$customerName:String,$rating:Int,$fromDate:String,$toDate:String){
ratings(
	storeId:$storeId
	
	customerName:$customerName
	rating:$rating
 	fromDate:$fromDate
    toDate:$toDate
	
	
)
	{edges{node{orderId
	rating
		customerDetails
		
		reviewOptions
	review}}}
}
`


const ReviewData = (props: Props) => {
    const { search, filterBy, date, setData } = props;
    const locationId = useSelector((state: any) => state.locationId)
    const { data, loading, error } = useQuery(GET_QUERY, {
        context: { clientName: "orderClient" }, variables: {
            storeId: +locationId,
            customerName: search, rating: parseInt(filterBy), fromDate: date.startDate, toDate: date.endDate,

        },
        onCompleted: (data) => {
            setData(data)
        }
    },

    )




    return null
};

export default ReviewData;