/* eslint-disable react-hooks/exhaustive-deps */

import { Box } from "@mui/material";
import ReportsHeader, { DateType } from "../reportsHeaders/index";
import React, { useState, useEffect, useContext } from "react";
import ReportsTable from "../ReportsTable";
import { useQuery } from "@apollo/client";
import dayjs from "dayjs";
import { ReportsHeaderType } from "../../../types/reportsHeader";
import { DiscountReportType } from "../../../types/discountReports";
import { DISCOUNTS_REPORTS } from "../../../graphicalQl/usequery/discountsReport";
import { convertSnakeCaseToPascalCase } from "../../../utils/stringUtils";
import { useSelector } from "react-redux";
import { GraphContext } from "../../../Contexts/GraphContext";
import { convertTimeToUTC } from "../../../utils/utcformat";

const options = [
  { label: "Discount Name", value: "discount_name" },
  { label: "Discount Type", value: "discount_type" },
  { label: "Discounts Applied", value: "discount_apply" },
  { label: "Amount Discounted", value: "discount_amount" },
];

const DiscountsReports = () => {
  const [headers, setHeaders] = useState<Array<ReportsHeaderType>>([
    { label: "Discount Name", value: "discount_name" },
    { label: "Discount Type", value: "discount_type" },
    { label: "Discounts Applied", value: "discount_apply" },
    { label: "Amount Discounted", value: "discount_amount" },
  ]);
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const [labels, setLabels] = useState<Array<string>>([
    "discount_name",
    "discount_type",
    "discount_apply",
    "discount_amount",
  ]);
  const [discountReports, setDiscountReports] = useState<
    Array<DiscountReportType>
  >([]);
  const [date, setDate] = useState<DateType>({
    startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
    endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
  });
  // const [fromTime, setFromTime] = useState<string>("00:00:00");
  // const [toTime, setToTime] = useState<string>("24:00:00");
  const { fromTime, toTime } = useContext(GraphContext)


  const { reportLocation, reportEmployee
  } = useSelector((state: any) => state.reportData);

  
  const { loading: discountReportsLoading, data: discountReportsData } =
    useQuery(
      DISCOUNTS_REPORTS(
        date.startDate,
        date.endDate,
        fromTime === "00:00" ? fromTime : convertTimeToUTC(fromTime), toTime === "23:59" ? toTime : convertTimeToUTC(toTime),

         parseInt(reportLocation) || parseInt(locationId),
        reportEmployee
      ),
      {
        fetchPolicy: "no-cache",
        context: { clientName: "discountClient" },
      }
    );

  const currencySymbol = sessionStorage.getItem("currencySymbol")
  const getDiscountReports = () => {
    if (!discountReportsLoading) {
      const discountReports = discountReportsData?.discountReports;
      const newStoreReports = JSON.parse(discountReports || "[]").map(
        (discount: any) => ({
          ...discount,
          discount_type: convertSnakeCaseToPascalCase(discount.discount_type),
          discount_amount: ` ${currencySymbol}${discount.discount_amount}`
        })
      );
      setDiscountReports(newStoreReports);
    }
  };

  useEffect(() => {
    getDiscountReports();
  }, [discountReportsLoading]);

  return (
    <Box sx={{ padding: { md: "24px 42px", sm: "20px", xs: "16px" } }}>
      <ReportsHeader
        setDate={setDate}
        date={date}
        // setFromTime={setFromTime}
        // setToTime={setToTime}
        employee={true}
      // setEmployee={setEmployee}
      />
      <Box mt={0}>
        <ReportsTable
          labels={labels}
          headers={headers}
          options={options}
          setLabels={setLabels}
          setHeaders={setHeaders}
          data={discountReports}
          title={"Discounts"}
        />
      </Box>
    </Box>
  );
};

export default DiscountsReports;
