import React, { useEffect, useState } from "react";
import {
  Box,
  FormControlLabel,
  Checkbox,
  Modal,
  Typography,
  TextField,
  IconButton,
  Button,
  Divider,
  InputAdornment,
  Drawer,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import "react-circular-progressbar/dist/styles.css";

import "./inventoryLists.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import * as Yup from "yup";

import DeletePopup from "../popups/deletePopup";

import { INVENTORY_PRODUCT_LIST } from "../../graphicalQl/usequery/productsListQuery";
import {
  UPDATE_RECURRING_STOCK_TO_PRODUCT,
  UPDATE_RECURRING_SETTINGS,
} from "../../graphicalQl/mutation/inventoryListsMutation";
import {
  INVENTORY_LIST,
  RECURRING_SETTINGS,
} from "../../graphicalQl/usequery/inventoryListsQuery";
import { useSelector } from "react-redux";
import { useMutation, useQuery } from "@apollo/client";
import DateRangePicker from "../popups/dateRangePicker";
import "./recurringStockSettings.css";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import CustomFooter from "../category/footer";
import DataGridTable from "../../utils/dataGridTable";
import DelayedInput from "../../utils/delayedInput";
import CircularLoader from "../../loader/loader";

const InventoryPopUpStyle = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35%",
  bgcolor: "background.paper",
  borderRadius: "8px",
  p: 2,
};

interface RecurringStockState {
  drawerState: boolean;
  handleDrawerClose: () => void;
}

interface State {
  Sun: boolean;
  Mon: boolean;
  Tue: boolean;
  Wed: boolean;
  Thu: boolean;
  Fri: boolean;
  Sat: boolean;
  popUpSearch: string;
  inventoyListSearch: string;
  inventoryListPopUpOnChnage: string;
}

//intial states of checkbox elements
const intialStatesOfCheckbox: State = {
  Sun: false,
  Mon: false,
  Tue: false,
  Wed: false,
  Thu: false,
  Fri: false,
  Sat: false,
  popUpSearch: "",
  inventoyListSearch: "",
  inventoryListPopUpOnChnage: "",
};

function collectVariantsName(data: any[]): string {
  let names: string[] = [];

  data.forEach((item) => {
    names.push(item.name);
  });

  return names.join(",");
}

const RecurringStockSettings = (props: RecurringStockState) => {
  const { t } = useTranslation();
  const { drawerState, handleDrawerClose } = props;
  const [searchInput, setSearchInput] = useState<string | null>(null);
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const { locationId } = useSelector((state: any) => state.headerLocation);
  const [rows, setRows] = useState<any[]>([]);
  const [inventoryId, setInventoryId] = useState<string | null>(null);
  const [editPopup, setEditPopup] = useState<boolean>(false);
  const [showedCount, setShowedCount] = useState<number>(0);
  const [isDeleted, setIsDeleted] = useState<boolean>(false);
  const [deletingProduct, setDeletingProduct] = useState<string | null>(null);

  const [daysList, setDaysList] = useState<any[]>([
    {
      value: "sunday",
      label: t("inventory.sun"),
      isChecked: false,
    },
    {
      value: "monday",
      label: t("inventory.mon"),
      isChecked: false,
    },
    {
      value: "tuesday",
      label: t("inventory.tue"),
      isChecked: false,
    },
    {
      value: "wednesday",
      label: t("inventory.wed"),
      isChecked: false,
    },
    {
      value: "thursday",
      label: t("inventory.thu"),
      isChecked: false,
    },
    {
      value: "friday",
      label: t("inventory.fri"),
      isChecked: false,
    },
    {
      value: "saturday",
      label: t("inventory.sat"),
      isChecked: false,
    },
  ]);

  useEffect(() => {
    setDaysList([
      {
        value: "sunday",
        label: t("inventory.sun"),
        isChecked: false,
      },
      {
        value: "monday",
        label: t("inventory.mon"),
        isChecked: false,
      },
      {
        value: "tuesday",
        label: t("inventory.tue"),
        isChecked: false,
      },
      {
        value: "wednesday",
        label: t("inventory.wed"),
        isChecked: false,
      },
      {
        value: "thursday",
        label: t("inventory.thu"),
        isChecked: false,
      },
      {
        value: "friday",
        label: t("inventory.fri"),
        isChecked: false,
      },
      {
        value: "saturday",
        label: t("inventory.sat"),
        isChecked: false,
      },
    ]);
  }, [t]);

  const {
    loading: recurringSettingsLoading,
    error: recurringSettingsError,
    data: recurringSettingsData,
  } = useQuery(RECURRING_SETTINGS, {
    context: { clientName: "categoryClient" },
    variables: { locationId: +locationId },
  });

  useEffect(() => {
    if (recurringSettingsData) {
      const { recurringSettings } = recurringSettingsData;
      if (recurringSettings) {
        const { recurringDays } = recurringSettings;
        if (recurringDays) {
          const parsedJson = JSON.parse(recurringDays);
          const { days } = parsedJson;
          const updatedDaysList = daysList.map((day) => ({
            ...day,
            isChecked: days[day.value],
          }));

          setDaysList(updatedDaysList);

          // Set the new time value
          setSelectedTime(parsedJson && parsedJson["time"]);

          //   const defaultTime = '00:00'
          //   const dateTimeString = `${parsedFromDate} ${defaultTime}`;

          const parsedFromDate = parsedJson && parsedJson["from_date"];

          const parsedToDate = parsedJson && parsedJson["to_date"];

          formikForRecuuringStock.setFieldValue("startDate", parsedFromDate);
          formikForRecuuringStock.setFieldValue("endDate", parsedToDate);
        }
      }
    }
  }, [recurringSettingsData]);

  const handleEditPopupClose = () => {
    setEditPopup(false);

    setInventoryId(null);
    setIsDeleted(false);
    setDeletingProduct(null);
    formik.setFieldValue("recurringQuantity", null);
  };

  const initialValuesForRecurringStock = {
    startDate: null,
    endDate: null,
  };

  const initialValuesOfCollectingRecurringQuantity = {
    recurringQuantity: null,
  };

  const validationSchemaForCollectingRecurringQuantity = Yup.object({
    recurringQuantity: Yup.number().required("Quantity is required"),
  });

  const validationSchemaForRecurringstock = Yup.object({
    startDate: Yup.date().required("Start Date is required"),
    endDate: Yup.date()
      .required("End Date is required")
      .min(Yup.ref("startDate"), "End Date must be greater than From Date"),
  });

  const formik = useFormik({
    initialValues: initialValuesOfCollectingRecurringQuantity,
    validationSchema: validationSchemaForCollectingRecurringQuantity,
    onSubmit: (values) => {
      if (isDeleted) {
        handleRecurringPopupYesRemoveButton();
      } else {
        handleEditPopupSave();
      }
    },
  });

  const formikForRecuuringStock = useFormik({
    initialValues: initialValuesForRecurringStock,
    validationSchema: validationSchemaForRecurringstock,
    onSubmit: (values) => {
      handleRecurringStockSaveButton();
    },
  });

  // const [requiredFormatTime, setRequiredFormatTime] = React.useState<string | null>(null);
  const [selectedTime, setSelectedTime] = React.useState<string | null>(null);

  const [deletePopupOpen, setDeletePopupOpen] = React.useState(false);
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [currentPage, setCurrentPage] = React.useState(0);
  const filteringObject = {
    locationId: locationId && +locationId,
    businessId: +businessId,
    search: searchInput,
    isRecurring: true,
    first: !startCursor ? paginationModel.pageSize : null,
    after: endCursor,
    before: startCursor,
    last: startCursor && paginationModel.pageSize,
  };
  const requiredFormatAddress = Object.fromEntries(
    Object.entries(filteringObject).filter(([_, value]) => value !== null)
  );

  const [updateRecurringStockToProduct] = useMutation(
    UPDATE_RECURRING_STOCK_TO_PRODUCT,
    {
      onCompleted: (data) => {
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [INVENTORY_LIST, INVENTORY_LIST],
    }
  );

  const [updateRecurringStockSettings] = useMutation(
    UPDATE_RECURRING_SETTINGS,
    {
      onCompleted: (data) => {
      },
      onError: (error) => {
        // Handle any errors here, e.g., display an error message.
        console.error("Mutation error:", error);
      },
      context: { clientName: "categoryClient" },
      refetchQueries: [INVENTORY_LIST],
    }
  );

  const {
    loading: recurringListDataLoading,
    error: recurringListError,
    data: recurringListData,
  } = useQuery(INVENTORY_LIST, {
    context: { clientName: "categoryClient" },
    variables: requiredFormatAddress,
    fetchPolicy: "network-only",
  });

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(recurringListData?.inventoryList?.pageInfo.endCursor || null);
  };
  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(
      recurringListData?.inventoryList?.pageInfo.startCursor || null
    );
  };

  useEffect(() => {
    if (recurringListData) {
      const { inventoryList } = recurringListData;
      if (inventoryList) {
        const { edges } = inventoryList;
        const filteredDataList =
          edges &&
          edges.map((each: any) => ({
            id: each.node.inventoryId,
            recurringQuantity: each.node.recurringQuantity,
            name: each.node.product && each.node.product.name,
            sku: each.node.product && each.node.product.sku,
            stockCount: each.node.stockCount && each.node.stockCount,
            variantName: each.node.variantDetails
              ? collectVariantsName(JSON.parse(each.node.variantDetails))
              : null,
          }));
        setShowedCount(filteredDataList && filteredDataList.length);
        setRows(filteredDataList);
      }
    }
  }, [recurringListData]);

  const columns: GridColDef[] = [
    {
      field: "sku",
      //@ts-ignore
      headerName: t("inventory.sku"),
      headerClassName: "inventory-list-table-column1",
      flex: 0.8,
      renderCell: (params) => (
        <Typography>
          {params.value === null || params.value === "" ? "N/A" : params.value}
        </Typography>
      ),
    },
    {
      field: "name",
      //@ts-ignore
      headerName: t("inventory.product"),
      headerClassName: "inventory-list-table-column1",
      flex: 1.8,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "variation",
      //@ts-ignore
      headerName: t("inventory.variation"),
      headerClassName: "inventory-list-table-column1",
      flex: 1,
      renderCell: (params) => (
        <Typography>
          {params.row.variantName === null ? "N/A" : params.row.variantName}
        </Typography>
      ),
    },
    {
      field: "recurringQuantity",
      //@ts-ignore
      headerName: t("inventory.quantity"),
      headerClassName: "inventory-list-table-column1",
      flex: 1,
      renderCell: (params) => <Typography>{params.value}</Typography>,
    },
    {
      field: "",
      //@ts-ignore
      headerName: t("inventory.actions"),
      headerClassName: "inventory-list-table-column1",
      flex: 1,
      renderCell: (params) => (
        <div>
          <IconButton
            onClick={handleEditClick(
              params.row.id,

              params.row.recurringQuantity
            )}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={handleDeleteClick(
              params.row.id,

              params.row.variantName === null
                ? params.row.name
                : params.row.variantName
            )}
          >
            <DeleteIcon />
          </IconButton>
        </div>
      ),
    },
  ];

  const handleTimeChange = (time: string) => {
    setSelectedTime(time);
  };

  const handleCheckBox = (checked: boolean, value: string) => {
    const filterdDataList = daysList.map((eachDay: any) => {
      if (eachDay.value === value) {
        eachDay.isChecked = checked;
      }
      return eachDay;
    });
    setDaysList(filterdDataList);
  };

  const handleEditClick = (inventoryId: string, stockCount: string) => () => {
    setInventoryId(inventoryId);
    setEditPopup(true);
    formik.setFieldValue("recurringQuantity", stockCount);
  };

  const handleDeleteClick = (inventoryId: string, product: string) => () => {
    setIsDeleted(true);
    setInventoryId(inventoryId);
    setDeletingProduct(product);
  };

  const getFontSize = () => {
    return "inventory-list-table-row1 inventory-list-table-row-hover";
  };

  const handleChangeSearchQuery = (seachedText: string) => {
    setSearchInput(seachedText);
  };

  const handleEditPopupSave = () => {
    const filteringObject = {
      locationId: locationId && +locationId,
      businessId: +businessId,
      inventoryId: inventoryId && +inventoryId,
      recurringQuantity: formik.values.recurringQuantity
        ? +formik.values.recurringQuantity
        : null,
    };
    const filteredDataFromNULL = Object.fromEntries(
      Object.entries(filteringObject).filter(([_, value]) => value !== null)
    );

    updateRecurringStockToProduct({
      variables: filteredDataFromNULL,
    });
    handleEditPopupClose();
  };

  const handleRecurringPopupSaveButton = () => {};

  const handleRecurringPopupYesRemoveButton = () => {};

  const handleDeletePopupClose = () => {
    setInventoryId(null);
    setIsDeleted(false);
    setDeletingProduct(null);
  };

  const handleDeletePopupOpen = () => setIsDeleted(true);

  const handleDeleteRemoveButton = () => {
    const filteringObject = {
      locationId: locationId && +locationId,
      businessId: +businessId,
      inventoryId: inventoryId && +inventoryId,
      isRecurring: false,
    };
    const filteredDataFromNULL = Object.fromEntries(
      Object.entries(filteringObject).filter(([_, value]) => value !== null)
    );

    updateRecurringStockToProduct({
      variables: filteredDataFromNULL,
    });
    handleDeletePopupClose();
  };

  const handleRecurringStockSaveButton = () => {
    const timeAndDateAndDays = {
      from_date: formikForRecuuringStock.values.startDate,
      to_date: formikForRecuuringStock.values.endDate,
      time: selectedTime,
      days: daysList.reduce((acc, day) => {
        acc[day.value] = day.isChecked;
        return acc;
      }, {}),
    };

    const filteringObject = {
      locationId: locationId && +locationId,
      businessId: +businessId,
      recurringDays: JSON.stringify(timeAndDateAndDays),
    };
    const filteredDataFromNULL = Object.fromEntries(
      Object.entries(filteringObject).filter(([_, value]) => value !== null)
    );

    updateRecurringStockSettings({ variables: filteredDataFromNULL });
    handleDrawerClose();
    toast.success(
      <div>
        <Typography>
          {recurringSettingsData ? t("toast.updated") : t("toast.created")}
        </Typography>
      </div>,
      {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
        className: "custom_toast3",
      }
    );
  };

  const shouldUpdatePaginationModelIsTrue = (newModel: any) => {
    setPaginationModel(newModel);
    setCurrentPage(newModel.page);
  };

  const handleUpdatingValuesToNull = (newModel: any) => {
    setStartCursor(null);
    setEndCursor(null);
    setCurrentPage(0);
    setPaginationModel({
      ...newModel,
      page: 0,
    });
  };

  return (
    <Box>
      <DeletePopup
        open={isDeleted}
        handleClose={handleDeletePopupClose}
        handleOpen={handleDeletePopupOpen}
        handleSaveButton={handleDeleteRemoveButton}
        mainText={`${t("buttons.are_you_sure_delete")} "${deletingProduct}" ${t(
          "inventory.from_recurring"
        )}`}
        button1Text={t("buttons.yes_remove")}
        button2Text={t("buttons.dont_remove")}
        popUpwidth="35%"
      />

      <Modal
        open={editPopup}
        onClose={handleEditPopupClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <form onSubmit={formik.handleSubmit}>
          <Box sx={InventoryPopUpStyle}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                width: "65%",
              }}
            >
              <IconButton
                onClick={handleEditPopupClose}
                className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
              >
                <CloseIcon />
              </IconButton>
              {!isDeleted && (
                <Typography className="inventory-header-name">
                  {t("inventory.edit_recurring")}
                </Typography>
              )}
            </Box>
            <Box>
              <Divider sx={{ marginTop: "10px", marginBottom: "10px" }} />
            </Box>
            <Typography className="delete-pop-up-text">
              {isDeleted
                ? `${t("buttons.are_you_sure_delete")} "${deletingProduct}" ${t(
                    "inventory.from_recurring"
                  )}`
                : t("inventory.every_day")}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                width: "100%",
                marginTop: "15px",
              }}
            >
              {!isDeleted && (
                <Box>
                  <TextField
                    id="outlined-basic"
                    label={t("inventory.quantity")}
                    color="warning"
                    type="text"
                    value={formik.values.recurringQuantity || ""}
                    onChange={(e) => {
                      const val = e.target.value;
                      if (!val || /^[0-9]+$/.test(val)) {
                        formik.handleChange(e);
                      }
                    }}
                    onBlur={formik.handleBlur}
                    name="recurringQuantity"
                    variant="outlined"
                  />
                  {formik.touched.recurringQuantity &&
                    formik.errors.recurringQuantity && (
                      <Box>
                        <Typography className="create-a-user-formik-label">
                          {t("error.required")}
                        </Typography>
                      </Box>
                    )}
                </Box>
              )}
            </Box>
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                justifyContent: isDeleted ? "center" : "flex-end",
                width: isDeleted ? "100%" : "100%",
              }}
            >
              {isDeleted && (
                <Button
                  sx={{ textTransform: "none", marginRight: "10px" }}
                  onClick={handleEditPopupClose}
                  color="warning"
                  variant="outlined"
                >
                  {t("buttons.dont_remove")}
                </Button>
              )}
              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                color="warning"
                variant="contained"
              >
                {isDeleted ? t("buttons.yes_remove") : t("buttons.save")}
              </Button>
            </Box>
          </Box>
        </form>
      </Modal>

      <Drawer anchor={"right"} open={drawerState} onClose={handleDrawerClose}>
        <form onSubmit={formikForRecuuringStock.handleSubmit}>
          <Box sx={{ width: 650, display: "flex", flexDirection: "column" }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                padding: "10px 18px 10px 18px",
                width: "100%",
              }}
            >
              <IconButton
                onClick={handleDrawerClose}
                className="inventory-pop-up-cross-icon-button inventory-pop-up-cross-icon-button-hover"
              >
                <CloseIcon />
              </IconButton>
              <Typography className="inventory-pop-up-header-name">
                {t("inventory.recurring_stock")}
              </Typography>
              <Button
                type="submit"
                sx={{ textTransform: "none" }}
                color="warning"
                variant="contained"
              >
                {t("inventory.save")}
              </Button>
            </Box>

            <Box sx={{ padding: "5px 18px 5px 18px" }}>
              <Typography className="inventory-pop-up-paragraph1">
                {t("inventory.choose_a_date")}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
                padding: "10px 18px 10px 18px",
              }}
            >
              <DateRangePicker
                startDateWidth="90%"
                endDateWidth="90%"
                width="60%"
                formik={formikForRecuuringStock}
              />

              <Box sx={{ width: "35%" }}>
                <Typography>{t("inventory.select_time")}</Typography>
                <TextField
                  color="warning"
                  type="time"
                  value={selectedTime}
                  name="selectedTime"
                  onChange={(e) => handleTimeChange(e.target.value)}
                  sx={{ width: "90%" }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                width: "90%",
                display: "flex",
                flexWrap: "wrap",
                padding: "10px 18px 10px 15px",
              }}
            >
              {daysList.map((eachDay: any) => (
                <FormControlLabel
                  sx={{ margin: "5px" }}
                  key={eachDay.label} // Make sure to add a unique key
                  control={
                    <Checkbox
                      checked={eachDay.isChecked}
                      onChange={(e) =>
                        handleCheckBox(e.target.checked, eachDay.value)
                      }
                      name={eachDay.label}
                      color="warning"
                    />
                  }
                  label={<Typography>{eachDay.label}</Typography>}
                  className="createDiscount_checkBox"
                />
              ))}
            </Box>

            <Box sx={{ padding: "10px 18px 2px 18px" }}>
              <Divider sx={{ marginBottom: "10px" }} />
              <Typography className="inventory-pop-up-paragraph2">
                {t("inventory.edit_the_variants")}{" "}
              </Typography>
            </Box>

            <Box sx={{ padding: "5px 10px 10px 18px" }}>
              <DelayedInput
                width="60%"
                // margin="10px 0px 10px 8px"
                setSearchValue={setSearchInput}
                placeHolder={t("users.search_by_name")}
                size={"medium"}
              />

              <Typography
                sx={{ marginTop: "2px" }}
                className="inventory-pop-up-paragraph3"
              >
                {recurringListData?.inventoryList?.totalCount || 0}{" "}
                {t("inventory.44_variants")}{" "}
              </Typography>
            </Box>

            {recurringListDataLoading ? (
              <CircularLoader />
            ) : (
              <Box
                sx={{
                  height: "40vh",
                  width: "100%",
                  padding: "2px 18px 10px 18px",
                }}
              >
                <DataGridTable
                  shouldUpdatePaginationModelIsTrue={
                    shouldUpdatePaginationModelIsTrue
                  }
                  handleUpdatingValuesToNull={handleUpdatingValuesToNull}
                  paginationModel={paginationModel}
                  rows={rows}
                  columns={columns}
                  getRow={getFontSize}
                  totalCount={recurringListData?.inventoryList?.totalCount || 0}
                  handleEndCursor={handleEndCursor}
                  handleStartCursor={handleStartCursor}
                  currentPage={currentPage}
                  imageHeight="120px"
                  imageWidth="120px"
                  heightForContainer="22vh"
                  fontSizeForNoDataText="12px"
                />
              </Box>
            )}
          </Box>
        </form>
      </Drawer>
    </Box>
  );
};

export default RecurringStockSettings;
