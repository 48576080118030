// NavigationButtons.js
import React, { useEffect } from "react";
import { useNavigation } from "./useNavigation";
import Button from "../../../common/button";

interface State {
  customImage: any
  handleApiCall?: any
  apiSucess?: boolean
}



const NavigationButtons = (props: State) => {
  const { customImage, handleApiCall, apiSucess } = props;
  const { activeStep, handleBack, handleNext } = useNavigation();
  useEffect(() => {
    if (apiSucess) {
      handleNext()
    }
  }, [apiSucess])

  return (
    <>
      {activeStep !== 0 &&
        <Button clickHandler={handleBack} title={"Back"} width={""} />}
      {activeStep !== 2 && (
        <Button
          customImage={customImage}
          width={"200px"}
          clickHandler={handleApiCall}
          title={"Next"}
        />
      )}
    </>
  );
};

export default NavigationButtons;
