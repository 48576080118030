import { Box, Checkbox, Typography } from "@mui/material";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import React, { useState } from "react";

const ClockInAndOut = () => {
  const [featureDetails, setFeatureDetails] = useState([
    {
      title: "Allow users to clock in and clock out ",
      label: "Track cash that goes in and out of your drawer. Learn more",
      link: "https://www.google.com",
      enabled: true,
    },
    {
      title: "Pin based clock in & clock Out",
      label:
        "Track employees’ clock in/out time and calculate their total work hours. Learn more",
      link: "https://www.google.com",
      enabled: true,
    },
  ]);
  return (
    <Box>
      <Box
        sx={{
          marginTop: "23px",
          paddingLeft: "32px",
          paddingBottom: "22px",
          borderBottom: "1px solid #EEE",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "18px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
          }}
        >
          Clock In/Out Settings
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "14px",
            fontStyle: "normal",
            fontWeight: 400,
            lineHeight: "normal",
            marginTop: "4px",
          }}
        >
          set click In/Out preferences
        </Typography>
      </Box>
      <Box
        sx={{
          width: "691px",
          borderRadius: "4px",
          border: "1px solid #EEE",
          background: "#FFF",
          padding: "20px 12px",
          boxSizing: "border-box",
          borderLeft: "2px solid #AE9BE2",
          display: "flex",
          flexDirection: "column",
          rowGap: "16px",
          marginLeft: "32px",
          marginTop: "32px",
        }}
      >
        {featureDetails.map(({ title, label, enabled, link }, index) => (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              width: "681px",
              height: "30px",
            }}
          >
            <Typography
              sx={{
                color: "#333",
                fontFamily: "Poppins",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 500,
                lineHeight: "normal",
              }}
            >
              {title}
            </Typography>

            <Checkbox
              sx={{
                "& .MuiSvgIcon-root": { fontSize: 50 },
              }}
              icon={<ToggleOffIcon sx={{ color: "#F38B08" }} />}
              checkedIcon={<ToggleOnIcon sx={{ color: "#F38B08" }} />}
              checked={enabled}
              tabIndex={-1}
              onClick={() => {
                const currentFeatureDetails = featureDetails;
                currentFeatureDetails[index].enabled =
                  !currentFeatureDetails[index].enabled;
                setFeatureDetails([...currentFeatureDetails]);
              }}
            />
          </Box>
        ))}

        <Box
          sx={{
            width: "77px",
            height: "44px",
            padding: "8px 16px",
            backgroundColor: "#F38B08",
            display: "flex",
            justifyContent: "center",
            borderRadius: "4px",
            background: "#F38B08",
            "&:hover": {
              backgroundColor: "#F38B08",
            },
          }}
        >
          <Typography
            sx={{
              fontSize: "18px",
              fontWeight: "500",
              fontFamily: "Poppins",
              color: "#fefefe",
            }}
          >
            Save
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ClockInAndOut;
