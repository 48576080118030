import React, { FC, useEffect, useState } from "react";

import { DateType } from "../../reportsHeaders";
import { getGraphLabels } from "../../../../utils/getGraphLabels";
import DeliveryGraphMain from "./deliveryGraph";
import {
  DeliveryGraphData,
  DeliveryGraphType,
} from "../../../../types/deliveryReports";

interface Props {
  deliveryGraph: DeliveryGraphType[];
  date: DateType;
}

const DeliveryGraph: FC<Props> = ({ deliveryGraph, date }) => {
  const [labels, setLabels] = useState<Array<string>>([]);
  const [graphDataset, setGraphDataset] = useState<DeliveryGraphData>({
    net_sales: [0],
    sales: [0],
    refunds: [0],
  });

  useEffect(() => {
    const tempLabels = getGraphLabels(date.startDate, date.endDate);

    const tempNetSales = deliveryGraph?.map((cus) => {
      return cus.net_sales;
    });
    const tempSales = deliveryGraph?.map((cus) => {
      return cus.sales;
    });
    const tempRefunds = deliveryGraph?.map((cus) => {
      return cus.refunds;
    });

    setGraphDataset({
      net_sales: tempNetSales,
      refunds: tempRefunds,
      sales: tempSales,
    });
    setLabels(tempLabels);

  }, [date.endDate, date.startDate, deliveryGraph]);

  return (
    <>
      <DeliveryGraphMain labels={labels} graphDataset={graphDataset} />
    </>
  );
};

export default DeliveryGraph;
