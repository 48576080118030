import { Box, Button, Grid, Paper, TextField, Typography } from "@mui/material";
import { UPDATE_API_KEYS } from "../../graphicalQl/mutation/locationMutation";
import "./apiKeys.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useMutation, useQuery } from "@apollo/client";
import { GETTING_API_KEYS } from "../../graphicalQl/usequery/locationQuery";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

interface Google {
  googleApiKey: string | null;
  googleAnalyticsId: string | null;
}

interface SMTP {
  emailHost: string | null;
  emailHostUser: string | null;
  emailHostPassword: string | null;
  emailPort: string | null;
  fromEmail: string | null;
}

interface PackageInfo {
  orderingAppAndroidPackageID: string | null;
  orderingAppIOSPackageID: string | null;
  deliveryPartnerAppAndroidPackageID: string | null;
  deliveryPartnerAppIOSPackageID: string | null;
}

interface Twilio {
  twilioAccountNumber: string | null;
  twilioApiKey: string | null;
  twilioPhoneNumber: string | null;
}
interface Stripe {
  stripeApiKey: string | null;
  stripePaymentSucceededWebhookKey: string | null;
}
interface Hubrise {
  hubrisAuthorizationToken: string | null;
  hubrisId: string | null;
}

interface Handpoint {
  handPointApiKey: string | null;
}
interface Onesignal {
  onsignalCustomerApiId: string | null;
  onsignalCustomerRestApiKey: string | null;
  onsignalDeliveryApiId: string | null;
  onsignalDeliveryRestApiKey: string | null;
}

interface Facebook {
  facebookAppId: string | null;
  facebookClientToken: string | null;
}

const initialValuesFacebook: Facebook = {
  facebookAppId: null,
  facebookClientToken: null,
};

const initialValuesSmtp: SMTP = {
  emailHost: null,
  emailHostUser: null,
  emailHostPassword: null,
  emailPort: null,
  fromEmail: null,
};

const initialValuesGoogle: Google = {
  googleApiKey: null,
  googleAnalyticsId: null,
};

const initialValuesPackageInfo: PackageInfo = {
  orderingAppAndroidPackageID: null,
  orderingAppIOSPackageID: null,
  deliveryPartnerAppAndroidPackageID: null,
  deliveryPartnerAppIOSPackageID: null,
};

const initialValuesTwilio: Twilio = {
  twilioAccountNumber: null,
  twilioApiKey: null,
  twilioPhoneNumber: null,
};

const initialValuesStripe: Stripe = {
  stripeApiKey: null,
  stripePaymentSucceededWebhookKey: null,
};
const initialValuesHubrise: Hubrise = {
  hubrisAuthorizationToken: null,
  hubrisId: null,
};
const initialValuesHandpoint: Handpoint = {
  handPointApiKey: null,
};
const initialValuesOnesignal: Onesignal = {
  onsignalCustomerApiId: null,
  onsignalCustomerRestApiKey: null,
  onsignalDeliveryApiId: null,
  onsignalDeliveryRestApiKey: null,
};

const validationSchemaForGoogle = Yup.object({
  googleApiKey: Yup.string().required("required"),
  googleAnalyticsId: Yup.string().required("required"),
});

const validationSchemaForFacebook = Yup.object({
  facebookAppId: Yup.string().required("required"),
  facebookClientToken: Yup.string().required("required"),
});

const validationSchemaForPAckageInfo = Yup.object({
  orderingAppAndroidPackageID: Yup.string().required("required"),
  orderingAppIOSPackageID: Yup.string().required("required"),
  deliveryPartnerAppAndroidPackageID: Yup.string().required("required"),
  deliveryPartnerAppIOSPackageID: Yup.string().required("required"),
});

const validationSchemaForSmtpSettings = Yup.object({
  emailHost: Yup.string().required("required"),
  emailHostUser: Yup.string().required("required"),
  emailHostPassword: Yup.string().required("required"),
  emailPort: Yup.string().required("required"),
  fromEmail: Yup.string().required("required"),
});

const validationSchemaForTwilio = Yup.object({
  twilioAccountNumber: Yup.string().required("required"),
  twilioApiKey: Yup.string().required("required"),
  twilioPhoneNumber: Yup.string().required("required"),
});
const validationSchemaForStripe = Yup.object({
  stripeApiKey: Yup.string().required("required"),
  stripePaymentSucceededWebhookKey: Yup.string().required("required"),
});
const validationSchemaForHubrise = Yup.object({
  hubrisAuthorizationToken: Yup.string().required("required"),
  hubrisId: Yup.string().required("required"),
});
const validationSchemaForHandpoint = Yup.object({
  handPointApiKey: Yup.string().required("required"),
});
const validationSchemaForOnesignal = Yup.object({
  onsignalCustomerApiId: Yup.string().required("required"),
  onsignalCustomerRestApiKey: Yup.string().required("required"),
  onsignalDeliveryApiId: Yup.string().required("required"),
  onsignalDeliveryRestApiKey: Yup.string().required("required"),
});

const ApiKeys = () => {
  const { t } = useTranslation();
  const businessId: string = sessionStorage.getItem("businessId") as string;
  const filteredCategory = {
    businessId: businessId,
  };
  const {
    loading: loading,
    error: error,
    data: data,
  } = useQuery(GETTING_API_KEYS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });

  useEffect(() => {
    if (data) {
      const { business } = data;
      if (business) {
        const { apiKeys } = business;
        if (apiKeys) {
          const parsedApis = JSON.parse(apiKeys);
          console.log(parsedApis, "parsedApis");
          const {
            google_maps_api_key,
            google_analytics_id,

            ordering_app_onesignal_rest_api_key,

            ordering_app_onesignal_app_id,
            delivery_partner_app_onesignal_app_id,
            delivery_partner_app_onesignal_rest_api_key,

            twilio_account_sid,
            twilio_api_key,
            twilio_sender_number,
            stripe_api_key,
            stripe_payment_succeeded_webhook_key,
            hubrise_auth_token,
            hubrise_id,
            handpoint_api_key,
            ordering_app_android_package_id,
            ordering_app_ios_package_id,
            delivery_partner_app_android_package_id,
            delivery_partner_app_ios_package_id,
            facebook_app_id,
            facebook_client_token,

            email_host,
            email_host_user,
            email_host_password,
            email_port,
            from_email,
          } = parsedApis;

          console.log(
            ordering_app_onesignal_app_id,
            ordering_app_onesignal_rest_api_key,
            delivery_partner_app_onesignal_app_id,
            delivery_partner_app_onesignal_rest_api_key
          );

          formikForGoogle.setFieldValue(
            "googleApiKey",
            google_maps_api_key ? google_maps_api_key : null
          );

          formikForGoogle.setFieldValue(
            "googleAnalyticsId",
            google_analytics_id ? google_analytics_id : null
          );

          formikForTwilio.setFieldValue(
            "twilioAccountNumber",
            twilio_account_sid ? twilio_account_sid : null
          );
          formikForTwilio.setFieldValue(
            "twilioApiKey",
            twilio_api_key ? twilio_api_key : null
          );
          formikForTwilio.setFieldValue(
            "twilioPhoneNumber",
            twilio_sender_number ? twilio_sender_number : null
          );

          formikForStripe.setFieldValue(
            "stripeApiKey",
            stripe_api_key ? stripe_api_key : null
          );

          formikForStripe.setFieldValue(
            "stripePaymentSucceededWebhookKey",
            stripe_payment_succeeded_webhook_key
              ? stripe_payment_succeeded_webhook_key
              : null
          );

          formikForHubrise.setFieldValue(
            "hubrisAuthorizationToken",
            hubrise_auth_token ? hubrise_auth_token : null
          );
          formikForHubrise.setFieldValue(
            "hubrisId",
            hubrise_id ? hubrise_id : null
          );

          formikForHandpoint.setFieldValue(
            "handPointApiKey",
            handpoint_api_key ? handpoint_api_key : null
          );

          formikForOnesignal.setFieldValue(
            "onsignalCustomerApiId",
            ordering_app_onesignal_app_id ? ordering_app_onesignal_app_id : null
          );
          formikForOnesignal.setFieldValue(
            "onsignalCustomerRestApiKey",
            ordering_app_onesignal_rest_api_key
              ? ordering_app_onesignal_rest_api_key
              : null
          );

          formikForOnesignal.setFieldValue(
            "onsignalDeliveryApiId",
            delivery_partner_app_onesignal_app_id
              ? delivery_partner_app_onesignal_app_id
              : null
          );
          formikForOnesignal.setFieldValue(
            "onsignalDeliveryRestApiKey",
            delivery_partner_app_onesignal_rest_api_key
              ? delivery_partner_app_onesignal_rest_api_key
              : null
          );

          formikForPackageInfo.setFieldValue(
            "orderingAppAndroidPackageID",
            ordering_app_android_package_id
              ? ordering_app_android_package_id
              : null
          );
          formikForPackageInfo.setFieldValue(
            "orderingAppIOSPackageID",
            ordering_app_ios_package_id ? ordering_app_ios_package_id : null
          );
          formikForPackageInfo.setFieldValue(
            "deliveryPartnerAppAndroidPackageID",
            delivery_partner_app_android_package_id
              ? delivery_partner_app_android_package_id
              : null
          );
          formikForPackageInfo.setFieldValue(
            "deliveryPartnerAppIOSPackageID",
            delivery_partner_app_ios_package_id
              ? delivery_partner_app_ios_package_id
              : null
          );

          formikForFacebook.setFieldValue(
            "facebookAppId",
            facebook_app_id ? facebook_app_id : null
          );
          formikForFacebook.setFieldValue(
            "facebookClientToken",
            facebook_client_token ? facebook_client_token : null
          );

          formikForSmtp.setFieldValue(
            "emailHost",
            email_host ? email_host : null
          );
          formikForSmtp.setFieldValue(
            "emailHostUser",
            email_host_user ? email_host_user : null
          );
          formikForSmtp.setFieldValue(
            "emailHostPassword",
            email_host_password ? email_host_password : null
          );
          formikForSmtp.setFieldValue(
            "emailPort",
            email_port ? email_port : null
          );
          formikForSmtp.setFieldValue(
            "fromEmail",
            from_email ? from_email : null
          );
        }
      }
    }
  }, [data]);

  const [updateApiKeys] = useMutation(UPDATE_API_KEYS, {
    onCompleted: (data) => {
      if (data) {
        const { updateBusiness } = data;
        if (updateBusiness) {
          const { success } = updateBusiness;
          if (success) {
            toast.success(
              <div>
                <Typography>{t("toast.updated")}</Typography>
              </div>,
              {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
                className: "custom_toast3",
              }
            );
          }
        }
      }
    },
    onError: (error) => {
      if (error.message === "Ordering Android Package Id should be unique!") {
        toast.error(
          <div>
            <Typography>{t("error.ordering_android")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      } else if (
        error.message === "Ordering IOS Package Id should be unique!"
      ) {
        toast.error(
          <div>
            <Typography>{t("error.ordering_ios")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      } else if (
        error.message === "Delivery Android Package Id should be unique!"
      ) {
        toast.error(
          <div>
            <Typography>{t("error.delivery_android")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      } else if (
        error.message === "Delivery IOS Package Id should be unique!"
      ) {
        toast.error(
          <div>
            <Typography>{t("error.delivery_ios")}</Typography>
          </div>,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
            className: "custom_toast4",
          }
        );
      }
    },
    context: { clientName: "businessClient" },
    refetchQueries: [GETTING_API_KEYS],
  });

  const formikForGoogle = useFormik({
    initialValues: initialValuesGoogle,
    validationSchema: validationSchemaForGoogle,
    onSubmit: (values) => {
      const variables = {
        google_maps_api_key: formikForGoogle.values.googleApiKey,
        google_analytics_id: formikForGoogle.values.googleAnalyticsId,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            googleSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForTwilio = useFormik({
    initialValues: initialValuesTwilio,
    validationSchema: validationSchemaForTwilio,
    onSubmit: (values) => {
      const variables = {
        twilio_account_sid: formikForTwilio.values.twilioAccountNumber,
        twilio_api_key: formikForTwilio.values.twilioApiKey,
        twilio_sender_number: formikForTwilio.values.twilioPhoneNumber,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            twilioSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForStripe = useFormik({
    initialValues: initialValuesStripe,
    validationSchema: validationSchemaForStripe,
    onSubmit: (values) => {
      const variables = {
        stripe_api_key: formikForStripe.values.stripeApiKey,
        stripe_payment_succeeded_webhook_key:
          formikForStripe.values.stripePaymentSucceededWebhookKey,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            stripeSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForHubrise = useFormik({
    initialValues: initialValuesHubrise,
    validationSchema: validationSchemaForHubrise,
    onSubmit: (values) => {
      const variables = {
        hubrise_auth_token: formikForHubrise.values.hubrisAuthorizationToken,
        hubrise_id: formikForHubrise.values.hubrisId,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            hubriseSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForSmtp = useFormik({
    initialValues: initialValuesSmtp,
    validationSchema: validationSchemaForSmtpSettings,
    onSubmit: (values) => {
      const variables = {
        email_host: formikForSmtp.values.emailHost,
        email_host_user: formikForSmtp.values.emailHostUser,
        email_host_password: formikForSmtp.values.emailHostPassword,
        email_port: formikForSmtp.values.emailPort,
        from_email: formikForSmtp.values.fromEmail,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            emailSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForHandpoint = useFormik({
    initialValues: initialValuesHandpoint,
    validationSchema: validationSchemaForHandpoint,
    onSubmit: (values) => {
      const variables = {
        handpoint_api_key: formikForHandpoint.values.handPointApiKey,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            handpointSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForOnesignal = useFormik({
    initialValues: initialValuesOnesignal,
    validationSchema: validationSchemaForOnesignal,
    onSubmit: (values) => {
      const variables = {
        ordering_app_onesignal_app_id:
          formikForOnesignal.values.onsignalCustomerApiId,
        ordering_app_onesignal_rest_api_key:
          formikForOnesignal.values.onsignalCustomerRestApiKey,
        delivery_partner_app_onesignal_app_id:
          formikForOnesignal.values.onsignalDeliveryApiId,
        delivery_partner_app_onesignal_rest_api_key:
          formikForOnesignal.values.onsignalDeliveryRestApiKey,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            onesignalSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  const formikForPackageInfo = useFormik({
    initialValues: initialValuesPackageInfo,
    validationSchema: validationSchemaForPAckageInfo,
    onSubmit: (values) => {
      const variables = {
        ordering_app_android_package_id:
          formikForPackageInfo.values.orderingAppAndroidPackageID,
        ordering_app_ios_package_id:
          formikForPackageInfo.values.orderingAppIOSPackageID,
        delivery_partner_app_android_package_id:
          formikForPackageInfo.values.deliveryPartnerAppAndroidPackageID,
        delivery_partner_app_ios_package_id:
          formikForPackageInfo.values.deliveryPartnerAppIOSPackageID,
      };

      const variablesForDelivery = {
        delivery_partner_app_android_package_id:
          formikForPackageInfo.values.deliveryPartnerAppAndroidPackageID,
        delivery_partner_app_ios_package_id:
          formikForPackageInfo.values.deliveryPartnerAppIOSPackageID,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            orderingAppSettings: JSON.stringify(variables),
            deliveryPartnerAppSettings: JSON.stringify(variablesForDelivery),
          },
        },
      });
    },
  });

  const formikForFacebook = useFormik({
    initialValues: initialValuesFacebook,
    validationSchema: validationSchemaForFacebook,
    onSubmit: (values) => {
      const variables = {
        facebook_app_id: formikForFacebook.values.facebookAppId,
        facebook_client_token: formikForFacebook.values.facebookClientToken,
      };

      updateApiKeys({
        variables: {
          businessId: businessId,
          settings: {
            facebookSettings: JSON.stringify(variables),
          },
        },
      });
    },
  });

  return (
    <Grid container className="api-keys-main-container">
      <Grid container className="api-keys-header-text-order">
        <Grid item>
          <Typography className="api-keys-header">
            {t("api_keys.api_keys")}
          </Typography>
        </Grid>
      </Grid>

      <form style={{ width: "100%" }} onSubmit={formikForGoogle.handleSubmit}>
        <Grid
          sx={{
            width: "100%",
            // display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
          }}
          container
          className=""
        >
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.google")}
              </Typography>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.api_key")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForGoogle.handleBlur}
                  onChange={formikForGoogle.handleChange}
                  name="googleApiKey"
                  value={formikForGoogle.values.googleApiKey}
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="googleApiKey"
                />
                {formikForGoogle.touched.googleApiKey &&
                  formikForGoogle.errors.googleApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {/* {t("api_keys.api_key")}: */}
                  Analytics Id
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForGoogle.handleBlur}
                  onChange={formikForGoogle.handleChange}
                  name="googleAnalyticsId"
                  value={formikForGoogle.values.googleAnalyticsId}
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="googleAnalyticsId"
                />
                {formikForGoogle.touched.googleAnalyticsId &&
                  formikForGoogle.errors.googleAnalyticsId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form style={{ width: "100%" }} onSubmit={formikForTwilio.handleSubmit}>
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.twilio")}
              </Typography>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.account_sid")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForTwilio.handleBlur}
                  value={formikForTwilio.values.twilioAccountNumber}
                  onChange={formikForTwilio.handleChange}
                  name="twilioAccountNumber"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="twilioAccountNumbebr"
                />
                {formikForTwilio.touched.twilioAccountNumber &&
                  formikForTwilio.errors.twilioAccountNumber && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.api_key")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForTwilio.handleBlur}
                  value={formikForTwilio.values.twilioApiKey}
                  onChange={formikForTwilio.handleChange}
                  name="twilioApiKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="twilioApiKey"
                />
                {formikForTwilio.touched.twilioApiKey &&
                  formikForTwilio.errors.twilioApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.sender_number")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForTwilio.handleBlur}
                  value={formikForTwilio.values.twilioPhoneNumber}
                  onChange={formikForTwilio.handleChange}
                  name="twilioPhoneNumber"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="twilioPhoneNumber"
                />

                {formikForTwilio.touched.twilioPhoneNumber &&
                  formikForTwilio.errors.twilioPhoneNumber && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>
            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form style={{ width: "100%" }} onSubmit={formikForStripe.handleSubmit}>
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.stripe")}
              </Typography>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.api_key")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  value={formikForStripe.values.stripeApiKey}
                  onBlur={formikForStripe.handleBlur}
                  onChange={formikForStripe.handleChange}
                  name="stripeApiKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="stripeApiKey"
                />
                {formikForStripe.touched.stripeApiKey &&
                  formikForStripe.errors.stripeApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.stripe_payment")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  value={
                    formikForStripe.values.stripePaymentSucceededWebhookKey
                  }
                  onBlur={formikForStripe.handleBlur}
                  onChange={formikForStripe.handleChange}
                  name="stripePaymentSucceededWebhookKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="stripePaymentSucceededWebhookKey"
                />
                {formikForStripe.touched.stripePaymentSucceededWebhookKey &&
                  formikForStripe.errors.stripePaymentSucceededWebhookKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form style={{ width: "100%" }} onSubmit={formikForHubrise.handleSubmit}>
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.hubrise")}
              </Typography>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.authorization_token")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForHubrise.handleBlur}
                  value={formikForHubrise.values.hubrisAuthorizationToken}
                  onChange={formikForHubrise.handleChange}
                  name="hubrisAuthorizationToken"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="hubrisAuthorizationToken"
                />
                {formikForHubrise.touched.hubrisAuthorizationToken &&
                  formikForHubrise.errors.hubrisAuthorizationToken && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.hubrise_id")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForHubrise.handleBlur}
                  value={formikForHubrise.values.hubrisId}
                  onChange={formikForHubrise.handleChange}
                  name="hubrisId"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="hubrisId"
                />

                {formikForHubrise.touched.hubrisId &&
                  formikForHubrise.errors.hubrisId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form
        style={{ width: "100%" }}
        onSubmit={formikForHandpoint.handleSubmit}
      >
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.hand_point")}
              </Typography>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.api_key")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForHandpoint.handleBlur}
                  value={formikForHandpoint.values.handPointApiKey}
                  onChange={formikForHandpoint.handleChange}
                  name="handPointApiKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="handPointApiKey"
                />

                {formikForHandpoint.touched.handPointApiKey &&
                  formikForHandpoint.errors.handPointApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form
        style={{ width: "100%" }}
        onSubmit={formikForOnesignal.handleSubmit}
      >
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.one_signal")}
              </Typography>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {/* {t("api_keys.app_id")} */}
                  Ordering App One Signal App Id:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForOnesignal.handleBlur}
                  value={formikForOnesignal.values.onsignalCustomerApiId}
                  onChange={formikForOnesignal.handleChange}
                  name="onsignalCustomerApiId"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalCustomerApiId"
                />

                {formikForOnesignal.touched.onsignalCustomerApiId &&
                  formikForOnesignal.errors.onsignalCustomerApiId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>
            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {/* {t("api_keys.rest_api_key")}:
                   */}
                  Ordering App One Signal Rest API Key:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForOnesignal.handleBlur}
                  value={formikForOnesignal.values.onsignalCustomerRestApiKey}
                  onChange={formikForOnesignal.handleChange}
                  name="onsignalCustomerRestApiKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalCustomerRestApiKey"
                />

                {formikForOnesignal.touched.onsignalCustomerRestApiKey &&
                  formikForOnesignal.errors.onsignalCustomerRestApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {/* {t("api_keys.rest_api_key")}:
                   */}
                  Delivery Partner App One Signal App Id:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForOnesignal.handleBlur}
                  value={formikForOnesignal.values.onsignalDeliveryApiId}
                  onChange={formikForOnesignal.handleChange}
                  name="onsignalDeliveryApiId"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalDeliveryApiId"
                />

                {formikForOnesignal.touched.onsignalDeliveryApiId &&
                  formikForOnesignal.errors.onsignalDeliveryApiId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {/* {t("api_keys.rest_api_key")}:
                   */}
                  Delivery Partner App One Signal Rest API Key:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForOnesignal.handleBlur}
                  value={formikForOnesignal.values.onsignalDeliveryRestApiKey}
                  onChange={formikForOnesignal.handleChange}
                  name="onsignalDeliveryRestApiKey"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalDeliveryRestApiKey"
                />

                {formikForOnesignal.touched.onsignalDeliveryRestApiKey &&
                  formikForOnesignal.errors.onsignalDeliveryRestApiKey && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form
        style={{ width: "100%" }}
        onSubmit={formikForPackageInfo.handleSubmit}
      >
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.package_info")}
              </Typography>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.ordering_android")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForPackageInfo.handleBlur}
                  value={
                    formikForPackageInfo.values.orderingAppAndroidPackageID
                  }
                  onChange={formikForPackageInfo.handleChange}
                  name="orderingAppAndroidPackageID"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="orderingAppAndroidPackageID"
                />

                {formikForPackageInfo.touched.orderingAppAndroidPackageID &&
                  formikForPackageInfo.errors.orderingAppAndroidPackageID && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.ordering_ios")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForPackageInfo.handleBlur}
                  value={formikForPackageInfo.values.orderingAppIOSPackageID}
                  onChange={formikForPackageInfo.handleChange}
                  name="orderingAppIOSPackageID"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForPackageInfo.touched.orderingAppIOSPackageID &&
                  formikForPackageInfo.errors.orderingAppIOSPackageID && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.delivery_android")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForPackageInfo.handleBlur}
                  value={
                    formikForPackageInfo.values
                      .deliveryPartnerAppAndroidPackageID
                  }
                  onChange={formikForPackageInfo.handleChange}
                  name="deliveryPartnerAppAndroidPackageID"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForPackageInfo.touched
                  .deliveryPartnerAppAndroidPackageID &&
                  formikForPackageInfo.errors
                    .deliveryPartnerAppAndroidPackageID && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.delivery_ios")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForPackageInfo.handleBlur}
                  value={
                    formikForPackageInfo.values.deliveryPartnerAppIOSPackageID
                  }
                  onChange={formikForPackageInfo.handleChange}
                  name="deliveryPartnerAppIOSPackageID"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForPackageInfo.touched.deliveryPartnerAppIOSPackageID &&
                  formikForPackageInfo.errors
                    .deliveryPartnerAppIOSPackageID && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form style={{ width: "100%" }} onSubmit={formikForFacebook.handleSubmit}>
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.facebook")}
              </Typography>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.facebook_app")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForFacebook.handleBlur}
                  value={formikForFacebook.values.facebookAppId}
                  onChange={formikForFacebook.handleChange}
                  name="facebookAppId"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForFacebook.touched.facebookAppId &&
                  formikForFacebook.errors.facebookAppId && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.facebook_client_token")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForFacebook.handleBlur}
                  value={formikForFacebook.values.facebookClientToken}
                  onChange={formikForFacebook.handleChange}
                  name="facebookClientToken"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForFacebook.touched.facebookClientToken &&
                  formikForFacebook.errors.facebookClientToken && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>

      <form style={{ width: "100%" }} onSubmit={formikForSmtp.handleSubmit}>
        <Grid container className="">
          <Paper
            className="api-keys-paper-conatainer"
            sx={{ width: "80%" }}
            elevation={2}
          >
            <Grid item>
              <Typography className="api-keys-sub-header">
                {t("api_keys.smtp_settings")}
              </Typography>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.email_host")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForSmtp.handleBlur}
                  value={formikForSmtp.values.emailHost}
                  onChange={formikForSmtp.handleChange}
                  name="emailHost"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForSmtp.touched.emailHost &&
                  formikForSmtp.errors.emailHost && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.email_host_password")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForSmtp.handleBlur}
                  value={formikForSmtp.values.emailHostPassword}
                  onChange={formikForSmtp.handleChange}
                  name="emailHostPassword"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForSmtp.touched.emailHostPassword &&
                  formikForSmtp.errors.emailHostPassword && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.email_host_user")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForSmtp.handleBlur}
                  value={formikForSmtp.values.emailHostUser}
                  onChange={formikForSmtp.handleChange}
                  name="emailHostUser"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForSmtp.touched.emailHostUser &&
                  formikForSmtp.errors.emailHostUser && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.email_port")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForSmtp.handleBlur}
                  value={formikForSmtp.values.emailPort}
                  onChange={formikForSmtp.handleChange}
                  name="emailPort"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForSmtp.touched.emailPort &&
                  formikForSmtp.errors.emailPort && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-keys-key-and-textfield-order">
              <Grid>
                <Typography className="api-keys-key-name">
                  {t("api_keys.from_email")}:
                </Typography>
              </Grid>
              <Grid>
                <TextField
                  onBlur={formikForSmtp.handleBlur}
                  value={formikForSmtp.values.fromEmail}
                  onChange={formikForSmtp.handleChange}
                  name="fromEmail"
                  sx={{ width: "80%" }}
                  variant="outlined"
                  id="onsignalRestApiKey"
                />

                {formikForSmtp.touched.fromEmail &&
                  formikForSmtp.errors.fromEmail && (
                    <Box>
                      <Typography className="create-a-user-formik-label">
                        {t("error.required")}
                      </Typography>
                    </Box>
                  )}
              </Grid>
            </Grid>

            <Grid container className="api-key-button-order">
              <Grid item>
                <Button
                  type="submit"
                  sx={{ textTransform: "none" }}
                  color="warning"
                  variant="contained"
                >
                  {t("buttons.save")}
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </form>
    </Grid>
  );
};

export default ApiKeys;
