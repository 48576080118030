import { Sheet } from "@mui/joy";
import { Box, Button, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import CloseIcon from "../../../assets/close2.png";
import MuiPhoneNumber from "material-ui-phone-number-2";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CUSTOMER,
  UPDATE_CUSTOMER,
  DUPLICATE_CHECK_FOR_EMAIL_AND_MOBILE,
} from "../../../graphicalQl/mutation/customerDataMutation";
import dayjs from "dayjs";
import { CUSTOMER_DATA } from "../../../graphicalQl/usequery/customerDataQuery";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { GETTING_API_KEYS } from "../../../graphicalQl/usequery/locationQuery";
import { useSelector } from "react-redux";

interface Props {
  setAddCustomerModal: any;
  addCustomerModal: any;
  refetch: any;
}

const AddCustomer = (props: Props) => {
  const { phoneCountryCode } = useSelector(
    (state: any) => state.headerLocation
  );
  const { setAddCustomerModal, addCustomerModal, refetch } = props;
  const customerDetails = addCustomerModal.customerDetails;
  //  Mutations to create and update customers in the database
  const [duplicateErrorForMobile, setDuplicateErrorForMobile] =
    useState<boolean>(false);
  const [duplicateErrorForEmail, setDuplicateErrorForEmail] =
    useState<boolean>(false);
  const { t } = useTranslation();

  const [
    createCustomer,
    { data: createCustomerData, error: createCustomerError },
  ] = useMutation(CREATE_CUSTOMER, {
    context: { clientName: "customerClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    onError: (error) => {
      toast.error(
        <div>
          <Typography>{error.message}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          // pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // className: 'custom_toast3',
        }
      );
    },
  });

  const [DuplicateCheckForPhoneNUmber] = useMutation(
    DUPLICATE_CHECK_FOR_EMAIL_AND_MOBILE,
    {
      context: { clientName: "userClient" },
      onCompleted: (data) => {
        if (data) {
          const { duplicateCheck } = data;
          if (duplicateCheck) {
            const { duplicate } = duplicateCheck;
            if (duplicate) {
              setDuplicateErrorForMobile(true);
            } else {
              setDuplicateErrorForMobile(false);
            }
          }
        }
      },
      onError: (error) => {
        // toast.error(
        //   <div>
        //     <Typography>{error.message}</Typography>
        //   </div>,
        //   {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     // pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     // className: 'custom_toast3',
        //   }
        // );
      },
    }
  );

  const [DuplicateCheckForEmail] = useMutation(
    DUPLICATE_CHECK_FOR_EMAIL_AND_MOBILE,
    {
      context: { clientName: "userClient" },
      onCompleted: (data) => {
        if (data) {
          const { duplicateCheck } = data;
          if (duplicateCheck) {
            const { duplicate } = duplicateCheck;
            if (duplicate) {
              setDuplicateErrorForEmail(true);
            } else {
              setDuplicateErrorForEmail(false);
            }
          }
        }
      },
      onError: (error) => {
        // toast.error(
        //   <div>
        //     <Typography>{error.message}</Typography>
        //   </div>,
        //   {
        //     position: "top-right",
        //     autoClose: 5000,
        //     hideProgressBar: true,
        //     closeOnClick: true,
        //     // pauseOnHover: true,
        //     draggable: true,
        //     progress: undefined,
        //     theme: "light",
        //     // className: 'custom_toast3',
        //   }
        // );
      },
    }
  );

  const [
    updateCustomer,
    { data: updateCustomerData, error: updateCustomerError },
  ] = useMutation(UPDATE_CUSTOMER, {
    context: { clientName: "customerClient" },
    refetchQueries: [CUSTOMER_DATA],
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
    onError: (error) => {
      toast.error(
        <div>
          <Typography>{error.message}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          // className: 'custom_toast3',
        }
      );
    },
  });

  // TODO - to be updated based on requirements
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("Enter a valid email")
      .required("Please enter the email"),
    firstName: Yup.string().required("Please enter the first name"),
    lastName: Yup.string().required("Please enter the last name"),
    mobile: Yup.string()
      .required("Required")
      .matches(/^\d{10,11}$/, "Invalid Number"),
    company: Yup.string().required("Please enter the company name"),
    taxIdentifier: Yup.string().required("Please enter the taxIdentifier "),
    // address1: Yup.string().required("Please enter the adress"),

    // address2: Yup.string().required("Please enter the adress"),
    // country: Yup.string().required("Please enter the state"),
    // city: Yup.string().required("Please enter the city"),

    // postCode: Yup.string().required("Please enter the postCode"),
    // address1: Yup.string().when('mode', {
    //   is: (mode:string) => mode === 'add',
    //   then: Yup.string().required("Please enter the address"),
    //   otherwise: Yup.string().notRequired(),
    // }),
    address1: Yup.string().when("mode", {
      is: (value: string) => value === "add",
      then: (schema) => schema.required("Required"),
    }),
    address2: Yup.string().when("mode", {
      is: (value: string) => value === "add",
      then: (schema) => schema.required("Required"),
    }),
    country: Yup.string().when("mode", {
      is: (value: string) => value === "add",
      then: (schema) => schema.required("Required"),
    }),
    city: Yup.string().when("mode", {
      is: (value: string) => value === "add",
      then: (schema) => schema.required("Required"),
    }),
    postCode: Yup.string().when("mode", {
      is: (value: string) => value === "add",
      then: (schema) => schema.required("Required"),
    }),
  });

  //check for successfull updated and perform the required functions
  useEffect(() => {
    if (updateCustomerData?.updateCustomer.success) {
      refetch();
      setAddCustomerModal({
        state: false,
        mode: "add",
        customerDetails: {},
      });
    }

    if (createCustomerData?.createCustomer?.success) {
      // notify user  of successful creation of new customer - better way will be to add react toast

      // refetch customer list after adding new customer
      refetch();
      setAddCustomerModal({
        state: false,
        mode: "add",
        customerDetails: {},
      });
    }
  }, [updateCustomerData, createCustomerData]);

  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const removeCountryCode = (phoneNumber: any, countryCode: any) => {
    if (phoneNumber.startsWith(countryCode)) {
      return phoneNumber.slice(countryCode.length); // Remove country code
    }
    return phoneNumber; // Return the phone number unchanged if it doesn't start with the country code
  };
  const phoneNumberWithCountryCode = (customerDetails?.phone as String) ?? "";
  const mobileNumber = removeCountryCode(
    phoneNumberWithCountryCode,
    phoneCountryCode
  );
  const formik = useFormik({
    validationSchema,
    initialValues: {
      //editable if customer details passed
      firstName: (customerDetails?.customerName?.split(" ")[0] as String) ?? "",
      lastName: (customerDetails?.customerName?.split(" ")[1] as String) ?? "",
      mobile: mobileNumber,
      email: (customerDetails?.mail as String) ?? "",
      taxIdentifier: (customerDetails?.taxIdentifier as String) ?? "",
      address1: (customerDetails?.taxIdentifier as String) ?? "",
      company: (customerDetails?.company as String) ?? "",
      address2: (customerDetails?.taxIdentifier as String) ?? "",
      country: (customerDetails?.taxIdentifier as String) ?? "",
      city: (customerDetails?.taxIdentifier as String) ?? "",
      postCode: (customerDetails?.taxIdentifier as String) ?? "",
      birthday:
        customerDetails && customerDetails.dateOfBirth
          ? dayjs(customerDetails.dateOfBirth, "YYYY/MM/DD")
          : null,
      anniversary: customerDetails?.anniversary
        ? dayjs(customerDetails.anniversary, "YYYY/MM/DD")
        : null,
      mode: addCustomerModal.mode,
    },
    onSubmit: (values) => {
      const {
        firstName,
        lastName,
        email,
        mobile,
        birthday,
        anniversary,
        company,
        taxIdentifier,
        mode,
        ...rest
      } = values;
      if (addCustomerModal.mode === "add") {
        if (!duplicateErrorForEmail && !duplicateErrorForMobile) {
          createCustomer({
            variables: {
              businessId: +businessId,
              firstName,
              lastName,
              email,
              mobile: `${phoneCountryCode}${formik.values.mobile}`,
              addressDetails: {
                ...rest,
                isDefault: true,
                latitude: latitude.toString(),
                longitude: longitude.toString(),
              },
              dateOfBirth: values.birthday
                ? dayjs(values.birthday).format("YYYY/MM/DD")
                : null,
              anniversary: values.anniversary
                ? dayjs(values.anniversary).format("YYYY/MM/DD")
                : null,
              companyName: company,
              taxIdentifier: taxIdentifier,
            },
          });
          if (createCustomerError) {
          }
        }
      } else {
        if (!duplicateErrorForEmail && !duplicateErrorForMobile) {
          updateCustomer({
            variables: {
              businessId: +businessId,
              customerId: customerDetails.customerId,
              firstName,
              lastName,
              email,
              mobile: `${phoneCountryCode}${formik.values.mobile}`,
              companyName: company,
              taxIdentifier: taxIdentifier,
              dateOfBirth: values.birthday
                ? dayjs(values.birthday).format("YYYY/MM/DD")
                : null,
              anniversary: values.anniversary
                ? dayjs(values.anniversary).format("YYYY/MM/DD")
                : null,
            },
          });

          if (updateCustomerError) {
          }
        }
      }
    },
  });

  // const locationKey = "AIzaSyCDD-yuztbp1BhkGQHzpiTbxHS8Ve1H7WE";
  const filteredCategory = {
    businessId: businessId,
  };
  const [locationKey, setLocationKey] = useState<string | null>(null);
  const {
    loading: loading,
    error: error,
    data: googleData,
  } = useQuery(GETTING_API_KEYS, {
    context: { clientName: "businessClient" },
    variables: filteredCategory,
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (googleData) {
      const { business } = googleData;
      if (business) {
        const { apiKeys } = business;
        if (apiKeys) {
          const parsedApis = JSON.parse(apiKeys);
          const { google_maps_api_key } = parsedApis;
          setLocationKey(google_maps_api_key);
        }
      }
    }
  }, [googleData]);

  function getCurrentLocation(addressComponents: any) {
    let currentLocation = "";
    const lanmarkComponrnt = addressComponents.find((component: any) =>
      component.types.includes("landmark")
    );
    if (lanmarkComponrnt) {
      currentLocation += lanmarkComponrnt.long_name + ", ";
    }
    // Look for the locality (city)
    const localityComponent = addressComponents.find(
      (component: any) =>
        // component.types.includes("political") ||
        component.types.includes("sublocality") ||
        component.types.includes("sublocality_level_2")
    );
    if (localityComponent) {
      currentLocation += localityComponent.long_name + ", ";
    }
    const cityComponent = addressComponents.find((component: any) =>
      component.types.includes("locality")
    );
    if (cityComponent) {
      currentLocation += cityComponent.long_name + ", ";
    }
    const stateComponent = addressComponents.find((component: any) =>
      component.types.includes("administrative_area_level_1")
    );
    if (stateComponent) {
      currentLocation += stateComponent.long_name + ", ";
    }
    // If neither city nor state is available, use country
    const countryComponent = addressComponents.find((component: any) =>
      component.types.includes("country")
    );
    if (countryComponent) {
      currentLocation += countryComponent.long_name + ", ";
    }
    const postalCodeComponent = addressComponents.find((component: any) =>
      component.types.includes("postal_code")
    );
    if (postalCodeComponent) {
      currentLocation += postalCodeComponent.long_name;
    }
    return currentLocation;
  }

  useEffect(() => {
    const controller = new AbortController();
    const getPinData = async () => {
      try {
        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?address=${formik.values.postCode}&key=${locationKey}`
        );
        const data = await response.json();
        if (data) {
          if (data.status === "OK") {
            const { results } = data;
            if (results) {
              const { address_components, geometry } = results[0];
              const currentLocation = getCurrentLocation(address_components);
              formik.setFieldValue("address2", currentLocation);
              const { location } = geometry;
              const { lat, lng } = location;
              setLatitude(lat);
              setLongitude(lng);
              const extractCityAndState = (addressComponents: any) => {
                let city = "";
                let state = "";
                addressComponents.forEach((component: any) => {
                  if (
                    component.types.includes("postal_town") ||
                    component.types.includes("locality")
                  ) {
                    city = component.long_name;
                  } else if (
                    component.types.includes("administrative_area_level_1")
                  ) {
                    state = component.long_name;
                  }
                });
                return { city, state };
              };
              const { city, state } = extractCityAndState(address_components);
              formik.setFieldValue("city", city);
              formik.setFieldValue("country", state);
            }
          }
        }
        // fetch(
        //   `https://api.postalpincode.in/pincode/${
        //     formik.values.postCode && formik.values.postCode
        //   }`
        // )
        //   .then((response) => response.json())
        //   .then((data) => {
        //     if (data) {
        //       const { PostOffice } = data[0];
        //       if (PostOffice) {
        //         const { State, Division } = PostOffice[0];
        //         formik.setFieldValue("state", State);
        //         formik.setFieldValue("city", Division);
        //       }
        //     }
        //   });
      } catch (error) {}
    };
    getPinData();
    return () => {
      controller.abort();
    };
  }, [formik.values.postCode]);

  const getHelperTextForEmail = (): React.ReactNode => {
    if (duplicateErrorForEmail) {
      return (
        <Typography sx={{ fontSize: "10px", color: "red" }}>
          Already Existed
        </Typography>
      );
    }

    if (formik.touched.email && typeof formik.errors.email === "string") {
      return formik.errors.email;
    }

    return null;
  };

  const getHelperTextForMobile = (): React.ReactNode => {
    if (duplicateErrorForMobile) {
      return (
        <Typography sx={{ fontSize: "10px", color: "red" }}>
          Already Existed
        </Typography>
      );
    }

    if (formik.touched.mobile && typeof formik.errors.mobile === "string") {
      if (formik.errors.mobile === "Invalid Number") {
        return t("users.mobile_10_digits");
      } else {
        return t("error.required");
      }
    }

    return null;
  };

  const handleChangePhoneNumber = (value: any) => {
    const variables = {
      businessId: +businessId,
      mobile: `${phoneCountryCode}${value}`,
      userId: customerDetails.userAccount,
    };
    const filteredVariables = Object.fromEntries(
      Object.entries(variables).filter(([_, value]) => value !== null)
    );
    DuplicateCheckForPhoneNUmber({
      variables: filteredVariables,
    });
  };

  const handleChangeEmail = (e: any) => {
    const { value } = e.target;
    const variables = {
      businessId: +businessId,
      emailId: value,
      userId: customerDetails.userAccount,
    };
    const filteredVariables = Object.fromEntries(
      Object.entries(variables).filter(([_, value]) => value !== null)
    );
    DuplicateCheckForEmail({
      variables: filteredVariables,
    });
  };

  return (
    <Sheet
      sx={{
        width: "720px",
        outline: "none",
        borderRadius: "8px",
        // border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          gap: "196px",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() =>
            setAddCustomerModal({
              state: false,
              mode: "add",
              addCustomerModal: {},
            })
          }
        />
        <Typography
          sx={{
            fontFamily: "Poppins",
            fontWeight: "500",
            fontSize: "20px",
            lineHeight: "normal",
            color: "#333333",
          }}
        >
          {addCustomerModal.mode === "add"
            ? t("customer.add_customer")
            : t("customer.edit_customer")}
        </Typography>
      </Box>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <TextField
            sx={{ width: "323px" }}
            id="firstName"
            name="firstName"
            label={t("customer.first_name")}
            variant="outlined"
            //@ts-ignore
            placeholder={t("customer.first_name")}
            InputLabelProps={{ shrink: true }}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={
              formik.touched.firstName &&
              typeof formik.errors.firstName === "string"
                ? formik.errors.firstName
                : ""
            }
          />
          <TextField
            sx={{ width: "323px" }}
            id="lastName"
            name="lastName"
            label={t("customer.last_name")}
            variant="outlined"
            //@ts-ignore
            placeholder={t("customer.last_name")}
            InputLabelProps={{ shrink: true }}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={
              formik.touched.lastName &&
              typeof formik.errors.lastName === "string"
                ? formik.errors.lastName
                : ""
            }
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <Box>
            {/* <MuiPhoneNumber
              sx={{ width: "323px" }}
              defaultCountry={"in"}
              countryCodeEditable={true}
              disableCountryCode={false}
              id="mobile"
              name="mobile"
              label={t("customer.phone")}
              variant="outlined"
              //@ts-ignore
              placeholder={t("customer.phone")}
              InputLabelProps={{ shrink: true }}
              value={formik.values.mobile}
              onChange={(value: any) => {
                formik.setFieldValue("mobile", value, true);
                handleChangePhoneNumber(value);
              }}
              onBlur={formik.handleBlur}
              error={formik.touched.mobile && Boolean(formik.errors.mobile)}
            /> */}
            <TextField
              onBlur={formik.handleBlur}
              name="mobile"
              onChange={(e) => {
                const val = e.target.value;
                if (
                  !val ||
                  (/^[0-9]+$/.test(val) && e.target.value.length < 12)
                ) {
                  handleChangePhoneNumber(e.target.value);
                  formik.handleChange(e);
                }
              }}
              type="text"
              value={formik.values.mobile || ""}
              color="warning"
              id="demo-helper-text-misaligned"
              className="hide-number-input-icons"
              InputProps={{
                inputProps: {
                  minLength: 10,
                  maxLength: 11,
                },
              }}
              label={
                <Typography className="create-a-use-role-label">
                  {t("users.phone_number")}
                </Typography>
              }
              sx={{ width: "323px" }}
              error={Boolean(
                formik.touched.mobile &&
                  (typeof formik.errors.mobile === "string" ||
                    duplicateErrorForMobile)
              )}
              helperText={getHelperTextForMobile() || ""}
            />

            {/* {formik.touched.mobile && formik.errors.mobile && (
              <Box>
                <Typography className="create-a-user-formik-label">
                  {String(formik.errors.mobile) === "Invalid Number"
                    ? t("users.mobile_10_digits")
                    : t("error.required")}
                </Typography>
              </Box>
            )} */}
          </Box>
          <TextField
            sx={{ width: "323px" }}
            id="email"
            name="email"
            label={t("customer.email_address")}
            variant="outlined"
            //@ts-ignore
            placeholder={t("customer.email_address")}
            InputLabelProps={{ shrink: true }}
            value={formik.values.email}
            onChange={(e) => {
              formik.handleChange(e);
              handleChangeEmail(e);
            }}
            onBlur={formik.handleBlur}
            error={Boolean(
              formik.touched.email &&
                (typeof formik.errors.email === "string" ||
                  duplicateErrorForEmail)
            )}
            helperText={getHelperTextForEmail() || ""}
          />
          {/* {duplicateErrorForEmail && (
            <Typography sx={{ fontSize: "10px", color: "red" }}>
              Already Existed
            </Typography>
          )} */}
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <TextField
            sx={{ width: "323px" }}
            id="company"
            name="company"
            label={t("customer.company")}
            variant="outlined"
            //@ts-ignore
            placeholder={t("customer.company")}
            InputLabelProps={{ shrink: true }}
            value={formik.values.company}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.company && Boolean(formik.errors.company)}
            helperText={
              formik.touched.company &&
              typeof formik.errors.company === "string"
                ? formik.errors.company
                : ""
            }
          />
          <TextField
            sx={{ width: "323px" }}
            id="taxIdentifier"
            name="taxIdentifier"
            label={t("customer.tax")}
            variant="outlined"
            //@ts-ignore
            placeholder={t("customer.tax")}
            InputLabelProps={{ shrink: true }}
            value={formik.values.taxIdentifier}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.taxIdentifier &&
              Boolean(formik.errors.taxIdentifier)
            }
            helperText={
              formik.touched.taxIdentifier &&
              typeof formik.errors.taxIdentifier === "string"
                ? formik.errors.taxIdentifier
                : ""
            }
          />
        </Box>
        {addCustomerModal.mode === "add" && (
          <>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <TextField
                fullWidth
                id="address1"
                name="address1"
                label={t("customer.address1")}
                variant="outlined"
                //@ts-ignore
                placeholder={t("customer.address1")}
                InputLabelProps={{ shrink: true }}
                value={formik.values.address1}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.address1 && Boolean(formik.errors.address1)
                }
                helperText={
                  formik.touched.address1 &&
                  typeof formik.errors.address1 === "string"
                    ? formik.errors.address1
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <TextField
                sx={{ width: "323px" }}
                id="postCode"
                name="postCode"
                label={t("customer.post_code")}
                variant="outlined"
                //@ts-ignore
                placeholder={t("customer.post_code")}
                InputLabelProps={{ shrink: true }}
                value={formik.values.postCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.postCode && Boolean(formik.errors.postCode)
                }
                // helperText={formik.touched.postCode && formik.errors.postCode}
              />

              <TextField
                sx={{ width: "323px" }}
                id="address2"
                name="address2"
                label={t("customer.address2")}
                variant="outlined"
                //@ts-ignore
                placeholder={t("customer.address2")}
                InputLabelProps={{ shrink: true }}
                value={formik.values.address2}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={
                  formik.touched.address2 && Boolean(formik.errors.address2)
                }
                helperText={
                  formik.touched.address2 &&
                  typeof formik.errors.address2 === "string"
                    ? formik.errors.address2
                    : ""
                }
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "24px",
              }}
            >
              <TextField
                sx={{ width: "323px" }}
                id="city"
                name="city"
                label={t("customer.city")}
                variant="outlined"
                //@ts-ignore
                placeholder={t("customer.city")}
                InputLabelProps={{ shrink: true }}
                value={formik.values.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.city && Boolean(formik.errors.city)}
                helperText={
                  formik.touched.city && typeof formik.errors.city === "string"
                    ? formik.errors.city
                    : ""
                }
              />
              <TextField
                sx={{ width: "323px" }}
                id="country"
                name="country"
                label={t("customer.state")}
                variant="outlined"
                //@ts-ignore
                placeholder={t("customer.state")}
                InputLabelProps={{ shrink: true }}
                value={formik.values.country}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                error={formik.touched.country && Boolean(formik.errors.country)}
                helperText={
                  formik.touched.country &&
                  typeof formik.errors.country === "string"
                    ? formik.errors.country
                    : ""
                }
              />
            </Box>
          </>
        )}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "24px",
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                sx={{ width: "323px" }}
                maxDate={dayjs()}
                name="birthday"
                label={t("customer.state")}
                format="YYYY/MM/DD"
                value={formik.values.birthday}
                onChange={(value) =>
                  formik.setFieldValue("birthday", value, true)
                }
                slotProps={{
                  textField: {
                    variant: "outlined",

                    InputLabelProps: { shrink: true },
                    onBlur: formik.handleBlur,
                    id: "birthday",
                    placeholder: "Birthday",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["DatePicker"]}>
              <DatePicker
                sx={{ width: "323px" }}
                maxDate={dayjs()}
                format="YYYY/MM/DD"
                name="anniversary"
                label={t("customer.anniversary")}
                value={formik.values.anniversary}
                onChange={(value) =>
                  formik.setFieldValue("anniversary", value, true)
                }
                slotProps={{
                  textField: {
                    variant: "outlined",
                    color: "warning",

                    id: "anniversary",
                    placeholder: "Anniversary",
                  },
                }}
              />
            </DemoContainer>
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={() =>
              setAddCustomerModal({
                state: false,
                mode: "add",
                addCustomerModal: {},
              })
            }
            sx={{
              width: "97px",
              height: "44px",
              backgroundColor: "#EEE",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#F38B08",
                textTransform: "capitalize",
              }}
            >
              {t("buttons.cancel")}
            </Typography>
          </Button>
          <Button
            type="submit"
            sx={{
              width: "77px",
              height: "44px",
              backgroundColor: "#F38B08",
              display: "flex",
              justifyContent: "center",
              "&:hover": {
                backgroundColor: "#F38B08",
              },
            }}
          >
            <Typography
              sx={{
                fontSize: "18px",
                lineHeight: "27px",
                fontWeight: "500",
                fontFamily: "Poppins",
                color: "#fefefe",
                textTransform: "capitalize",
              }}
            >
              {t("buttons.save")}
            </Typography>
          </Button>
        </Box>
      </form>
    </Sheet>
  );
};

export default AddCustomer;
