import { gql } from "@apollo/client";

export const PAYMENT_METADATA_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query PaymentMetadata {
    paymentMetadata(
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime:"${fromTime}"
        toTime:"${toTime}"
        locationId:${locationId},
        ${employeeParam}
    )
    }
  `;
};

export const PAYMENT_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`

  query PaymentReports {
        paymentReports(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}

        )
    }
  `;
};
