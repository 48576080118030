import { gql } from "@apollo/client";

export const ORDER_GRAPH = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locations: number,
  employee: string | null
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";

  return gql`
     query orderClient {
      orderGraph(
         locationId: ${locations}
         fromDate: "${fromDate}"
         toDate: "${toDate}"
         fromTime:"${fromTime}"
         toTime:"${toTime}"
         ${employeeParam}
       )
     }
   `;
};

// export const ORDER_GRAPH = gql`
//   query orderClient{
//     orderGraph(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-02-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//     )
//   }
// `;

// export const ORDER_REPORTS = gql`
//   query orderClient{
//     orderReports(
//       locationId: 6
//       fromDate: "2024-02-01"
//       toDate: "2024-02-29"
//       fromTime: "08:00:00"
//       toTime: "23:00:00"
//     )
//   }
// `;

export const ORDER_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locations: number,
  employee: string | null
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";

  if (!fromTime.includes("Nan") && !toTime.includes("Nan")) {
    return gql`
       query orderClient {
        orderReports(
           locationId: ${locations}
           fromDate: "${fromDate}"
           toDate: "${toDate}"
           fromTime:"${fromTime}"
           toTime:"${toTime}"
           ${employeeParam}
         )
       }
     `;
  }
};
