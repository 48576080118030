import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  List,
  ListItem,
  Paper,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import "./recieptBuilder.css";
import image from "../../assets/prouctAddImage.png";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setMainData } from "../reduxReducers/recieptReducer";
import BarcodeImg from "../../assets/barcode.png";
import QrcodeImg from "../../assets/qrcode.png";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DragDropContext, Draggable, DropResult } from "react-beautiful-dnd";
import { StrictModeDroppable } from "../utils/strictModeDroppable.tsx";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  RECIEPT_BUILD_MUTATION,
  RECIEPT_UPDATE_MUTATION,
} from "../../graphicalQl/mutation/recieptMutation.tsx";
import { useMutation, useQuery } from "@apollo/client";
import FormatUnderlinedIcon from "@mui/icons-material/FormatUnderlined";
import FormatItalicIcon from "@mui/icons-material/FormatItalic";
import FormatBoldIcon from "@mui/icons-material/FormatBold";
import StrikethroughSIcon from "@mui/icons-material/StrikethroughS";
import { useNavigate, useParams } from "react-router";
import {
  RECIEPT_DETAIL_QUERY,
  RECIEPT_LIST_QUERY,
} from "../../graphicalQl/usequery/recieptQuery.tsx";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

// interface StyleList {
//   id: number;
//   fontSize: string;
//   fontWeight: string;
//   fontStyle: string;
//   textDecoration: string;
//   textTransform: string;
// }

interface Order {
  id: number;
  value: any;
  checked: boolean;
  align: string;
  name: string;
  label: string | null;
  style: any;
  variableName: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: "100%", marginLeft: "2%" }}
    >
      {value === index && (
        <Paper>
          <Box sx={{ p: 3 }}>
            <Box>{children}</Box>
          </Box>
        </Paper>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

const RecieptBuilder = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { recieptName, recieptType } = useSelector(
    (state: any) => state.recieptName
  );
  const [value, setValue] = React.useState(0);
  const navigate = useNavigate();
  const [imageValue, setImageValue] = React.useState<string | null>(null);
  const [imageResult, setImageResult] = React.useState(image);
  const [selectedValue, setSelectedValue] = useState("date1"); // Initialize with the default value 'date1'

  const fontSizeList = [
    {
      id: 1,
      value: "14px",
      label: 1,
    },
    { id: 2, value: "16px", label: 2 },
    { id: 3, value: "18px", label: 3 },
    { id: 4, value: "20px", label: 4 },
    { id: 5, value: "22px", label: 5 },
    { id: 6, value: "24px", label: 6 },
    { id: 7, value: "26px", label: 7 },
  ];
  const inputDate = new Date();

  const day = String(inputDate.getDate()).padStart(2, "0");
  const month = String(inputDate.getMonth() + 1).padStart(2, "0");
  const year = String(inputDate.getFullYear()).slice(-2); // Get the last two digits of the year
  const hours = String(inputDate.getHours()).padStart(2, "0");
  const minutes = String(inputDate.getMinutes()).padStart(2, "0");
  const formattedDate1 = `${day}-${month}-${year}, ${hours}:${minutes}`;
  const formattedDate2 = `${month}-${day}-${year}, ${hours}:${minutes}`;
  const formattedDate3 = `${year}-${month}-${day}, ${hours}:${minutes}`;
  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageResult(e.target?.result as string);
      };
      reader.readAsDataURL(file);
    }
  };

  const orderDetailsHeader: Order[] = [
    {
      id: 1,
      value: imageResult,
      checked: false,
      align: "center",
      name: "image",
      label: null,
      variableName: "store_image",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "capitalize",
        id: 0,
        textTransform: "",
      },
    },
    {
      id: 2,
      value: "mefhill",
      checked: false,
      align: "center",
      name: "name",
      label: null,
      variableName: "store_name",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 3,
      value:
        "Restaurent address here..Babukhan  Hyderabad, Telangana 500081,  079767 73165",
      checked: false,
      align: "center",
      name: "address",
      variableName: "store_address",
      label: null,
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 4,
      value: "collection",
      checked: false,
      align: "center",
      name: "orderType",
      label: "Order Type",
      variableName: "order_type",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 5,
      value: "  #12",
      checked: false,
      align: "center",
      name: "orderId",
      label: " Order #",
      variableName: "order_number",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "uppercase",
      },
    },
    {
      id: 38,
      value: "12",
      checked: false,
      align: "center",
      name: "kotNumber",
      label: "KOT No",
      variableName: "server_ticket_id",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "uppercase",
      },
    },
    {
      id: 6,
      value:
        selectedValue === "date1"
          ? formattedDate1
          : selectedValue === "date2"
          ? formattedDate2
          : formattedDate3,
      checked: false,
      align: "center",
      name: "date",
      variableName: "created_on",
      label: "",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 19,
      value: [{ qty: "Qty", name: "Item", price: "price" }],
      checked: false,
      align: "center",
      name: "header",
      variableName: "",
      label: null,
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 7,
      value: [
        { qty: 1, name: "pizza", price: 40, id: 1 },
        { qty: 2, name: "burger", price: 20, id: 2 },
        { qty: 1, name: "coke", price: 10, id: 3 },
      ],
      checked: false,
      align: "center",
      name: "items",
      variableName: "",
      label: null,
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 8,
      value: "online",
      checked: false,
      align: "center",
      name: "paymentType",
      label: "Payment Type",
      variableName: "payment_type",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 9,
      value: "  Done",
      checked: false,
      align: "center",
      name: "payStatus",
      label: "Pay Status",
      variableName: "payment_status",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 10,
      value: "sub Total 10",
      variableName: "subtotal",
      checked: false,
      align: "center",
      name: "total",
      label: null,

      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "",
      },
    },
    {
      id: 25,
      value: 80,
      variableName: "total",
      checked: false,
      align: "center",
      name: "finalTotal",
      label: "Total",

      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "",
      },
    },
    {
      id: 11,
      value:
        "10 Downing Street, London, SW1A 2AA, Greater London, United Kingdom",
      variableName: "customer_address",
      checked: false,
      align: "left",
      name: "customerDetails",
      label: "Delivery To",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 20,
      value: "John Smith",
      variableName: "customer_name",
      checked: false,
      align: "left",
      name: "customerName",
      label: "Customer Name",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 21,
      value: "+44 1234 567890",
      variableName: "customer_mobile",
      checked: false,
      align: "left",
      name: "customerMobile",
      label: "Customer Mobile",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 22,
      value: "30 Min",
      variableName: "collection_time",
      checked: false,
      align: "center",
      name: "collectionTime",
      label: "Collection Time",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 12,
      value: " 20 min",
      checked: false,
      align: "center",
      variableName: "delivery_time",
      name: "timeRequested",
      label: " Delivery Time ",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 13,
      value: "Jhon",
      checked: false,
      align: "center",
      variableName: "employee_name",
      name: "servedBy",
      label: "Served By",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 14,
      value:
        "Take home a bag of meatballs and 2 pkgs. of cream sauce for only $9.99 Made from an authentic recipe!",
      checked: false,
      align: "center",
      name: "aditionalText",
      variableName: "instructions",
      label: "instructions",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 77,
      value: "Kot45",
      checked: false,
      align: "center",
      name: "kotNumber",
      variableName: "kot_number",
      label: "Kot Number",
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    // {
    //   id: 15,
    //   value: "mefhill",
    //   checked: false,
    //   variableName: "powered_by",
    //   align: "center",
    //   name: "poweredBy",
    //   label: "powered By",
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: '',
    //     fontStyle: '',
    //     textDecoration: '',
    //     id: 0,
    //     textTransform: 'capitalize'
    //   }
    // },
    // {
    //   id: 16,
    //   value: "#22",
    //   checked: false,
    //   align: "center",
    //   name: "showServerNum",
    //   variableName: "employee_number",
    //   label: "Served By",
    //   style: {
    //     fontSize: '14px',
    //     fontWeight: '',
    //     fontStyle: '',
    //     textDecoration: '',
    //     id: 0,
    //     textTransform: 'capitalize'
    //   }
    // },
    {
      id: 17,
      value: "",
      checked: false,
      align: "center",
      name: "showBarCode",
      variableName: "barcode",
      label: null,
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
    {
      id: 18,
      value: "",
      checked: false,
      align: "center",
      variableName: "qr_code",
      name: "showQr",
      label: null,
      style: {
        fontSize: "14px",
        fontWeight: "",
        fontStyle: "",
        textDecoration: "",
        id: 0,
        textTransform: "capitalize",
      },
    },
  ];
  const [basicData, setBasicData] = React.useState<Order[]>(orderDetailsHeader);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  // const inputDate = new Date();

  // const day = String(inputDate.getDate()).padStart(2, '0');
  // const month = String(inputDate.getMonth() + 1).padStart(2, '0');
  // const year = String(inputDate.getFullYear()).slice(-2); // Get the last two digits of the year
  // const hours = String(inputDate.getHours()).padStart(2, '0');
  // const minutes = String(inputDate.getMinutes()).padStart(2, '0');
  // const formattedDate1 = `${day}-${month}-${year}, ${hours}:${minutes}`;
  // const formattedDate2 = `${month}-${day}-${year}, ${hours}:${minutes}`;
  // const formattedDate3 = `${year}-${month}-${day}, ${hours}:${minutes}`;

  const [checkedList, setCheckedList] = React.useState<Order[]>([]);

  const handleCheckValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setBasicData((prevValue) => {
      return prevValue.map((item) => {
        return item.name === name ? { ...item, checked: checked } : item;
      });
    });
    if (checked) {
      const item = basicData.find((each) => each.name === name);
      if (item) {
        setCheckedList((prevList) => [...prevList, item]);
      }
    } else {
      setCheckedList((prevList) =>
        prevList.filter((each) => each.name !== name)
      );
    }
  };

  const handleSelectedValue = (event: SelectChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setBasicData((prvValue) => {
      return prvValue.map((item: Order) => {
        if (item.name === name) {
          return {
            ...item,
            align: value.toString(), // Convert value to string
          };
        }
        return item;
      });
    });
    setCheckedList((prevValue) => {
      return prevValue.map((item) => {
        if (item.name === name) {
          return {
            ...item,
            align: value.toString(), // Convert value to string
          };
        }
        return item;
      });
    });
  };

  const [fontSize, setFontSize] = useState<string>("");
  const [fontWeight, setFontWeight] = useState<string>("normal");
  const [fontStyle, setFontStyle] = useState<string>("normal");
  const [textDecoration, setTextDecaration] = useState<string>("");
  const [textTransform, setTextTransform] = useState<string>("capitalize");
  const [saveButton, setSaveButton] = useState(false);
  const [idValue, setidValue] = useState<number>(0);
  const [totalList, setToatalList] = useState([
    {
      id: 1,
      value: "Sub Total",
      isChecked: true,
      amount: 70,
      variableName: "subtotal",
    },
    { id: 2, value: "Tax", isChecked: true, amount: 10, variableName: "tax" },
    {
      id: 3,
      value: "Discount",
      isChecked: true,
      amount: 5,
      variableName: "discount_amount",
    },
    // { id: 4, value: "Charges", isChecked: true, amount: 5, variableName: "charges" },
    { id: 4, value: "Tip", isChecked: true, amount: 5, variableName: "tip" },

    {
      id: 5,
      value: "Delivery Fee",
      isChecked: true,
      amount: 5,
      variableName: "delivery_fee",
    },
    // { id: 6, value: "Total", isChecked: true, amount: 80, variableName: "total" }
  ]);

  const handleFontWeight = () => {
    if (fontWeight === "bold") {
      setFontWeight("normal");
    } else {
      setFontWeight("bold");
    }
  };

  const handleFontStyle = () => {
    if (fontStyle === "italic") {
      setFontStyle("normal");
    } else {
      setFontStyle("italic");
    }
  };

  const handleUnderLine = () => {
    if (textDecoration === "underline") {
      setTextDecaration("");
    } else {
      setTextDecaration("underline");
    }
  };

  const handleLineThrough = () => {
    if (textDecoration === "line-through") {
      setTextDecaration("");
    } else {
      setTextDecaration("line-through");
    }
  };

  const handleCheckedTotalList = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { checked } = event.target;
    setToatalList((prevValue) => {
      return prevValue.map((item) => {
        return item.id === id ? { ...item, isChecked: checked } : item;
      });
    });
  };

  const handleStyle = (key: number) => {
    setSaveButton(true);
    setidValue(key);

    const selectedStyle = checkedList.filter((each) => each.id === key)[0]
      .style;
    setFontSize(selectedStyle.fontSize);
    setFontWeight(selectedStyle.fontWeight);
    setFontStyle(selectedStyle.fontStyle);
    setTextDecaration(selectedStyle.textDecoration);
    setTextTransform(selectedStyle.textTransform);
  };
  function handleSave2() {
    setSaveButton(false);
    setBasicData((prevValue) =>
      prevValue.map((item) =>
        item.id === idValue
          ? {
              ...item,
              style: {
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                textDecoration: textDecoration,
                textTransform,
              },
            }
          : item
      )
    );
    setCheckedList((prevValue) =>
      prevValue.map((item) =>
        item.id === idValue
          ? {
              ...item,
              style: {
                fontSize: fontSize,
                fontWeight: fontWeight,
                fontStyle: fontStyle,
                textDecoration: textDecoration,
                textTransform,
              },
            }
          : item
      )
    );
    setFontSize("");
    setFontWeight("");
    setFontStyle("");
    setTextDecaration("");
    setidValue(0);
    setTextTransform("");
  }
  useEffect(() => {}, [
    fontSize,
    fontWeight,
    fontStyle,
    idValue,
    textDecoration,
    textTransform,
  ]);

  const [recieptJsonForamt, setRecieptJsonForamt] = useState<any>([]);

  let stringValue: string;

  const currencySybol = sessionStorage.getItem("currencySymbol");

  const convertToThermalReceipt = () => {
    const thermalReceipt = checkedList.map((item) => {
      if (item.value === "solid") {
        return {
          line: "solid",
        };
      } else if (item.value === "dotted") {
        return {
          line: "dotted",
        };
      } else if (item.value === "empty") {
        return {
          emptyline: 1,
        };
      } else if (item.name === "items") {
        return {
          items: [
            {
              text: `_quantity_  ${stringValue} `,
              format: { case: item.style.textTransform },
              width: 2,
              styles: {
                align: "left",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
            {
              text: `_product_name_`,
              format: { case: item.style.textTransform },
              width: 6,
              styles: {
                align: "left",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
            {
              // text: `item_total`,
              text: `${currencySybol} _price_`,
              // text: "",
              format: { case: item.style.textTransform },
              width: 4,
              styles: {
                align: "right",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
          ],
        };
      } else if (item.name === "total") {
        return totalList
          .filter((each) => each.isChecked)
          .flatMap((each) => ({
            text: `${each.value} _${each.variableName}_`,
            format: { case: item.style.textTransform },
            width: 12,
            styles: {
              align: item.align,
              bold: item.style.fontWeight === "bold",
              size:
                fontSizeList.filter(
                  (each) => each.value === item.style.fontSize
                )[0] &&
                fontSizeList.filter(
                  (each) => each.value === item.style.fontSize
                )[0].label,
              underline: item.style.textDecoration === "underline",
              italic: item.style.fontStyle === "italic",
            },
          }));
      } else if (item.name === "header") {
        return {
          columns: item.value.flatMap((each: any) => [
            {
              text: "Qty",
              format: { case: item.style.textTransform },
              width: 2,
              styles: {
                align: "left",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
            {
              text: "Items",
              format: { case: item.style.textTransform },
              width: 6,
              styles: {
                align: "left",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
            {
              text: "Price",
              format: { case: item.style.textTransform },
              width: 4,
              styles: {
                align: "right",
                bold: item.style.fontWeight === "bold",
                size:
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0] &&
                  fontSizeList.filter(
                    (each) => each.value === item.style.fontSize
                  )[0].label,
                underline: item.style.textDecoration === "underline",
                italic: item.style.fontStyle === "italic",
              },
            },
          ]),
        };
      } else if (item.name === "image") {
        return {
          image: "_store_logo_",

          styles: {
            align: item.align,
          },
        };
      } else if (item.name === "showBarCode") {
        return {
          barcode: "_barcode_",
          styles: {
            align: item.align,
          },
        };
      } else if (item.name === "showQr") {
        return {
          qrcode: "_qr_code_",
          styles: {
            align: item.align,
          },
        };
      } else if (item.name === "textField") {
        return {
          text: item.value,
        };
      } else {
        return {
          text:
            item.label !== null
              ? `${item.label} _${item.variableName}_`
              : `_${item.variableName}_`,
          format: { case: item.style.textTransform },
          styles: {
            align: item.align,
            bold: item.style.fontWeight === "bold",
            size:
              fontSizeList.filter(
                (each) => each.value === item.style.fontSize
              )[0] &&
              fontSizeList.filter(
                (each) => each.value === item.style.fontSize
              )[0].label,
            underline: item.style.textDecoration === "underline",
            italic: item.style.fontStyle === "italic",
          },
        };
      }
    });

    setRecieptJsonForamt(thermalReceipt.flat());

    // Perform further operations with the thermal receipt data
  };
  useEffect(() => {
    convertToThermalReceipt();
  }, [checkedList]);

  // const [selectedValue, setSelectedValue] = useState('date1'); // Initialize with the default value 'date1'

  const handleSelectChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const [selectedValue2, setSelectedValue2] = useState("qty1"); // Initialize with the default value 'date1'

  const handleSelectChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue2(event.target.value);
  };

  switch (selectedValue2) {
    case "qty2":
      stringValue = "x";
      break;
    case "qty3":
      stringValue = "  ";
      break;
    default:
      stringValue = " ";
      break;
  }

  // const [selectedColor, setSelectedColor] = useState<string>('#000000');

  // const handleColorChange = (color: ColorResult) => {
  //   setSelectedColor(color.hex);
  // };
  const initialDragAndDropData = [
    {
      id: "solidLine",
      content: <Divider sx={{ borderTop: "1px solid" }} />,
    },
    {
      id: "dottedLine",
      content: <Divider sx={{ borderTop: "1px dotted" }} />,
    },
    {
      id: "empty",
      content: <Divider sx={{ borderTop: "0px dotted" }} />,
    },
    {
      id: "textfield",
      content: "Add Text",
    },
  ];
  const [deleteButton, setDeleteButton] = useState<number | null>(null);
  const [variableEdit, setVariableEdit] = useState<string>("");
  const handleMouseEnter = (name: number) => {
    setDeleteButton(name);
  };
  const handleMouseLeave = () => {
    setDeleteButton(null);
  };

  const handleEditLabel = (name: string) => {
    setVariableEdit(name);
  };
  const handleTextFieldMouseLeave = () => {
    setVariableEdit("");
  };
  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }
    if (
      result.draggableId === "solidLine" ||
      result.draggableId === "dottedLine" ||
      result.draggableId === "empty" ||
      result.draggableId === "textfield"
    ) {
      const item1 = {
        id: Date.now(),
        value: "solid",
        checked: true,
        align: "center",
        name: "solidLine",
        label: "",
        style: {
          fontSize: "",
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          textTransform: "",
        },
        variableName: "",
      };
      const item2 = {
        id: Date.now(),
        value: "dotted",
        checked: true,
        align: "center",
        name: "dottedLine",
        label: "",
        style: {
          fontSize: "",
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          textTransform: "",
        },
        variableName: "",
      };
      const item3 = {
        id: Date.now(),
        value: "empty",
        checked: true,
        align: "center",
        name: "empty",
        label: "",
        style: {
          fontSize: "",
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          textTransform: "",
        },
        variableName: "",
      };
      const item4 = {
        id: Date.now(),
        value: "",
        checked: true,
        align: "center",
        name: "textField",
        label: "",
        style: {
          fontSize: "",
          fontWeight: "",
          fontStyle: "",
          textDecoration: "",
          textTransform: "",
        },
        variableName: "",
      };

      const finalList =
        result.draggableId === "solidLine"
          ? item1
          : result.draggableId === "dottedLine"
          ? item2
          : result.draggableId === "empty"
          ? item3
          : item4;

      const items = Array.from(checkedList);
      items.splice(result.destination.index, 0, finalList);
      setCheckedList(items);
    } else {
      const items = Array.from(checkedList);
      const [reorderedItem] = items.splice(result.source.index, 1);
      items.splice(result.destination.index, 0, reorderedItem);

      setCheckedList(items);
    }
  };

  const handleDelteLine = (id: number) => {
    setCheckedList(checkedList.filter((each) => each.id !== id));
  };
  const handleEditValue = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { value } = e.target;
    setCheckedList((prevValue) => {
      return prevValue.map((item) => {
        return item.id === id ? { ...item, label: value } : item;
      });
    });
    setBasicData((prevValue) => {
      return prevValue.map((item) => {
        return item.id === id ? { ...item, label: value } : item;
      });
    });
  };

  const handleEditValue2 = (
    e: React.ChangeEvent<HTMLInputElement>,
    id: number
  ) => {
    const { value } = e.target;
    setCheckedList((prevValue) => {
      return prevValue.map((item) => {
        return item.id === id ? { ...item, value: value } : item;
      });
    });
  };

  const [createReciept] = useMutation(RECIEPT_BUILD_MUTATION, {
    context: { clientName: "businessClient" },
    refetchQueries: [RECIEPT_LIST_QUERY],
    onCompleted: () => {
      navigate("/reciept/reciept-list");
      toast.success(
        <div>
          <Typography>{t("toast.created")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      dispatch(setMainData("recieptName", null));
    },
  });
  const [updateReciept] = useMutation(RECIEPT_UPDATE_MUTATION, {
    context: { clientName: "businessClient" },
    refetchQueries: [RECIEPT_LIST_QUERY],
    onCompleted: () => {
      navigate("/reciept/reciept-list");
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
      dispatch(setMainData("recieptName", null));
    },
  });
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const handleSave = () => {
    if (!id) {
      createReciept({
        variables: {
          receipt: JSON.stringify(recieptJsonForamt),
          businessId: +businessId,
          receiptData: JSON.stringify(checkedList),
          receiptType: recieptType,
          receiptName: recieptName,
        },
      });
    } else {
      updateReciept({
        variables: {
          receipt: JSON.stringify(recieptJsonForamt),
          receiptId: +id,
          receiptData: JSON.stringify(checkedList),
          receiptType: recieptType,
          receiptName: recieptName,
        },
      });
    }
  };

  const theme = createTheme({
    components: {
      MuiTabs: {
        styleOverrides: {
          indicator: {
            display: "none",
          },
          root: {
            marginRight: "20px",
            // borderRight: "0px solid !important",
          },
        },
      },
      MuiTab: {
        styleOverrides: {
          root: {
            position: "relative",

            backgroundColor: "#fff",
            border: "1px solid #eee",
            fontFamily: "poppins",
            fontWeight: "500",
            padding: "14px, 12px, 14px, 12px",
            fontSize: "14px",
            color: "#333333",
            "&.Mui-selected": {
              border: "1px solid #eee",
              fontWeight: "500",
              fontFamily: "poppins",
              padding: "14px, 12px, 14px, 12px",
              fontSize: "14px",
              color: "#333333",
              backgroundColor: "#fff8f0",
              // '&::after': {
              //   content: '""',
              //   position: 'absolute',
              //   right: '-5px',
              //   top: '50%',
              //   transform: 'translateY(-50%)',
              //   borderTop: '10px solid transparent',
              //   borderBottom: '10px solid transparent',
              //   borderLeft: '10px solid #000', // change color to match your theme
              // },
            },
          },
        },
      },
    },
  });
  const dispatch = useDispatch();
  //get data form query and update to checkedList
  const { data } = useQuery(RECIEPT_DETAIL_QUERY, {
    variables: { receiptId: id },
    context: { clientName: "businessClient" },
    fetchPolicy: "network-only",
  });
  useEffect(() => {
    if (data && data.receipt && data.receipt.receiptData) {
      const variablesList = orderDetailsHeader.map(
        (each: any) => each.variableName
      );
      const checkedData = JSON.parse(data.receipt.receiptData).filter(
        (each: any) => variablesList.includes(each.variableName)
      );
      setCheckedList(checkedData);

      const list = checkedData.map((each: any) => each.id);
      const updateOrderDetailsHeader = orderDetailsHeader.map((each: any) => {
        if (list.includes(each.id)) {
          return { ...each, checked: true };
        } else {
          return each;
        }
      });
      setBasicData(updateOrderDetailsHeader);
      // setCheckedList(updateOrderDetailsHeader)
      dispatch(setMainData("recieptName", data.receipt.recieptName));
    }
  }, [data]);

  const [isHovered, setIsHovered] = useState("");
  const handleMouseEntertext = (value: any) => {
    setIsHovered(value);
  };

  const handleMouseLeavetext = (value: any) => {
    setIsHovered(value);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* <div className="arrow">arrow</div> */}

      <Box
        sx={{
          width: "100%",
          backgroundColor: "#FBFBFB",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          padding: "10px 10px 14px 14px",
        }}
      >
        <IconButton
          className="reciept-exitIcon"
          onClick={() => {
            navigate("/reciept/list");
            dispatch(setMainData("recieptName", null));
          }}
        >
          <ClearIcon />
        </IconButton>
        {id ? (
          <Typography className="reciept-title">Edit Reciept</Typography>
        ) : (
          <Typography className="reciept-title">Create Reciept</Typography>
        )}
        <Button onClick={handleSave} className="main-button">
          Save
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-around",
          border: "1px solid #EEEEEE",
          padding: "14px, 12px, 14px, 20px",
          backgroundColor: "#FFFFFF",
          marginBottom: "12px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography>Divider</Typography>
          <StrictModeDroppable droppableId="droppableLine">
            {(provided) => (
              <List
                sx={{ display: "flex" }}
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {initialDragAndDropData.map((each, index) => (
                  <Draggable
                    key={each.id.toString()}
                    draggableId={each.id.toString()}
                    index={index}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <ListItem
                          sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                        >
                          <Box
                            sx={{
                              border: "1px solid #D9D9D9",
                              padding: "18px",
                              width: "108px",
                              height: "42px",
                            }}
                          >
                            {each.content}
                          </Box>
                          {/* <Divider orientation="vertical" variant="middle" flexItem sx={{ height: "42px", backgroundColor: "#D9D9D9", marginLeft: "12px" }} /> */}
                        </ListItem>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </StrictModeDroppable>
        </Box>
        {saveButton && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "32%",
              justifyContent: "space-between",
            }}
          >
            <FormControl sx={{ width: "48px" }}>
              {/* <InputLabel id="demo-simple-select-label">fontSize</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fontSize}
                // label="fontSize"
                size="small"
                onChange={(e) => setFontSize(e.target.value)}
                color="warning"
                variant="standard"
                disableUnderline={true}
              >
                {fontSizeList.map((each) => (
                  <MenuItem value={each.value}>{each.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton
              onClick={handleFontWeight}
              className={
                fontWeight === "bold"
                  ? "reciept-iconActive "
                  : "reciept-inActive"
              }
            >
              {" "}
              <FormatBoldIcon />
            </IconButton>

            {/* <FormControl sx={{ width: "12%", marginRight: "1%" }}>
              <InputLabel id="demo-simple-select-label">fontWeight</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fontWeight} onChange={(e) => setFontWeight(e.target.value)}
                label="fontWeight"
                color='warning'
                size='small'
              >
                <MenuItem value="bold">bold</MenuItem>
                <MenuItem value="normal">normal</MenuItem>

              </Select>
            </FormControl> */}

            <IconButton
              onClick={handleFontStyle}
              className={
                fontStyle === "italic"
                  ? "reciept-iconActive "
                  : "reciept-inActive"
              }
            >
              {" "}
              <FormatItalicIcon />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />

            {/* <FormControl sx={{ width: "12%", marginRight: "1%" }}>
              <InputLabel id="demo-simple-select-label">fontStyle</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={fontStyle} onChange={(e) => setFontStyle(e.target.value)}
                label="fontStyle"
                color='warning'
                size='small'
              >
                <MenuItem value="italic">italic</MenuItem>
                <MenuItem value="normal">normal</MenuItem>

              </Select>
            </FormControl> */}
            <IconButton
              onClick={handleUnderLine}
              className={
                textDecoration === "underline"
                  ? "reciept-iconActive "
                  : "reciept-inActive"
              }
            >
              {" "}
              <FormatUnderlinedIcon />
            </IconButton>

            {/* <FormControl sx={{ width: "12%", marginRight: "1%" }}>
              <InputLabel id="demo-simple-select-label">Text Decaration</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={textDecoration} onChange={(e) => setTextDecaration(e.target.value)}
                label="fontStyle"
                color='warning'
                size='small'
              >
                <MenuItem value="underline">underline</MenuItem>
                <MenuItem value="dotted">dotted</MenuItem>

              </Select>
            </FormControl> */}
            <IconButton
              onClick={handleLineThrough}
              className={
                textDecoration === "line-through"
                  ? "reciept-iconActive "
                  : "reciept-inActive"
              }
            >
              {" "}
              <StrikethroughSIcon />
            </IconButton>
            <Divider orientation="vertical" variant="middle" flexItem />

            <FormControl sx={{ width: "48px" }}>
              {/* <InputLabel id="demo-simple-select-label">Text TransForm</InputLabel> */}
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={textTransform}
                onChange={(e) => setTextTransform(e.target.value)}
                color="warning"
                variant="standard"
                disableUnderline={true}
                size="small"
              >
                <MenuItem value="capitalize">Ab</MenuItem>
                <MenuItem value="lowercase">ab</MenuItem>
                <MenuItem value="uppercase">AB</MenuItem>
              </Select>
            </FormControl>
            <Divider orientation="vertical" variant="middle" flexItem />

            <IconButton onClick={handleSave2} className="reciept-iconButton">
              <CheckIcon sx={{ color: "#FFFFFF" }} />
            </IconButton>
            <IconButton
              onClick={() => setSaveButton(false)}
              className="reciept-iconButton2"
            >
              <ClearIcon sx={{ color: "#707070" }} />
            </IconButton>
          </div>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          height: "75vh",
          overflowY: "scroll",
          backgroundColor: "#FBFBFB",
        }}
      >
        <ThemeProvider theme={theme}>
          <Box
            sx={{
              flexGrow: 1,
              bgcolor: "#FBFBFB",
              display: "flex",
              width: "60%",
            }}
          >
            <Tabs
              orientation="vertical"
              //   variant="scrollable"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              sx={{ borderColor: "divider", width: "180px" }}
              indicatorColor="primary"
            >
              <Tab
                label="Header"
                {...a11yProps(0)}
                sx={{
                  textTransform: "none",
                  width: "100%",
                  borderRight: "1px solid #EEEE",
                }}
              />
              <Tab
                label="Body"
                {...a11yProps(1)}
                sx={{ textTransform: "none", width: "100%" }}
              />
              <Tab
                label="Footer"
                {...a11yProps(2)}
                sx={{ textTransform: "none", width: "100%" }}
              />
            </Tabs>
            <TabPanel value={value} index={0}>
              <Box>
                <Box className="reciept-box" sx={{}}>
                  <img
                    src={imageResult || image}
                    alt="profile"
                    style={{ width: "10%" }}
                    onClick={() => {
                      const input = document.getElementById(
                        "image-uplo</Typography>ad-input"
                      );
                      if (input) {
                        input.click();
                      }
                    }}
                  />
                  <input
                    type="file"
                    hidden
                    id="image-upload-input"
                    onChange={handleImageUpload}
                  />

                  {!imageResult && (
                    <Typography>Add the company image here</Typography>
                  )}

                  <Box sx={{ marginLeft: "auto", display: "flex" }}>
                    <Box sx={{ minWidth: 120 }}>
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Align
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          onChange={handleSelectedValue}
                          value={
                            basicData.filter((each) => each.name === "image")[0]
                              .align as "" | HTMLInputElement | undefined
                          }
                          label="Align"
                          color="warning"
                          size="small"
                          name="image"
                        >
                          <MenuItem value="right">Right</MenuItem>
                          <MenuItem value="center">center</MenuItem>
                          <MenuItem value="left">Left</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                    <Switch
                      color="warning"
                      name="image"
                      onChange={handleCheckValue}
                      checked={
                        basicData.filter((each) => each.name === "image")[0]
                          .checked
                      }
                    />
                  </Box>
                </Box>
                <Box className="reciept-box" sx={{ textAlign: "center" }}>
                  <Typography
                    className="reciept-box-text"
                    sx={{ width: "80%" }}
                  >
                    Restaurant Name
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        color="warning"
                        value={
                          basicData.filter((each) => each.name === "name")[0]
                            .align as "" | HTMLInputElement | undefined
                        }
                        name="name"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    sx={{ marginLeft: "auto" }}
                    name="name"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "name")[0]
                        .checked
                    }
                  />
                </Box>
                <Box className="reciept-box" sx={{ textAlign: "center" }}>
                  <Typography
                    className="reciept-box-text"
                    sx={{ width: "80%" }}
                  >
                    Address
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        value={
                          basicData.filter((each) => each.name === "address")[0]
                            .align as "" | HTMLInputElement | undefined
                        }
                        color="warning"
                        name="address"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    sx={{ marginLeft: "auto" }}
                    name="address"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "address")[0]
                        .checked
                    }
                  />
                </Box>
                <Box className="reciept-box" sx={{ textAlign: "center" }}>
                  <Typography
                    className="reciept-box-text"
                    sx={{ width: "80%" }}
                  >
                    Order Type
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        color="warning"
                        value={
                          basicData.filter(
                            (each) => each.name === "orderType"
                          )[0].align as "" | HTMLInputElement | undefined
                        }
                        name="orderType"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    sx={{ marginLeft: "auto" }}
                    name="orderType"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "orderType")[0]
                        .checked
                    }
                  />
                </Box>
                <Box className="reciept-box" sx={{ textAlign: "center" }}>
                  <Typography
                    className="reciept-box-text"
                    sx={{ width: "80%" }}
                  >
                    Order Id
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        color="warning"
                        value={
                          basicData.filter((each) => each.name === "orderId")[0]
                            .align as "" | HTMLInputElement | undefined
                        }
                        name="orderId"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    sx={{ marginLeft: "auto" }}
                    name="orderId"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "orderId")[0]
                        .checked
                    }
                  />
                </Box>
                <Box className="reciept-box" sx={{ textAlign: "center" }}>
                  <Typography
                    className="reciept-box-text"
                    sx={{ width: "80%" }}
                  >
                    Kot Number
                  </Typography>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        color="warning"
                        value={
                          basicData.filter(
                            (each) => each.name === "kotNumber"
                          )[0].align as "" | HTMLInputElement | undefined
                        }
                        name="kotNumber"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    sx={{ marginLeft: "auto" }}
                    name="kotNumber"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "kotNumber")[0]
                        .checked
                    }
                  />
                </Box>
                <Box
                  className="reciept-box2"
                  sx={{ justifyContent: "space-between" }}
                >
                  <Box sx={{ width: "80%" }}>
                    <Typography className="reciept-box-text">
                      Date & Time
                    </Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        value={selectedValue}
                        onChange={handleSelectChange}
                        name="radio-buttons-group"
                      >
                        <FormControlLabel
                          value="date1"
                          control={<Radio color="warning" />}
                          label={<Typography>{formattedDate1}</Typography>}
                          className="reciept-box3"
                        />
                        <FormControlLabel
                          value="date2"
                          control={<Radio color="warning" />}
                          label={<Typography>{formattedDate2}</Typography>}
                          className="reciept-box3"
                        />
                        <FormControlLabel
                          value="date3"
                          control={<Radio color="warning" />}
                          label={<Typography>{formattedDate3}</Typography>}
                          className="reciept-box3"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        value={
                          basicData.filter((each) => each.name === "date")[0]
                            .align as "" | HTMLInputElement | undefined
                        }
                        color="warning"
                        name="date"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    name="date"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "date")[0]
                        .checked
                    }
                  />
                </Box>
              </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
              <Box
                className="reciept-box2"
                sx={{ justifyContent: "space-between" }}
              >
                <Typography className="reciept-box-text">Header</Typography>
                <Box sx={{ marginLeft: "auto", display: "flex" }}>
                  <Box sx={{ minWidth: 120 }}>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Align
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        onChange={handleSelectedValue}
                        label="Align"
                        value={
                          basicData.filter((each) => each.name === "header")[0]
                            .align as "" | HTMLInputElement | undefined
                        }
                        color="warning"
                        name="header"
                        size="small"
                      >
                        <MenuItem value="right">Right</MenuItem>
                        <MenuItem value="center">center</MenuItem>
                        <MenuItem value="left">Left</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                  <Switch
                    color="warning"
                    name="header"
                    onChange={handleCheckValue}
                    checked={
                      basicData.filter((each) => each.name === "header")[0]
                        .checked
                    }
                  />
                </Box>
              </Box>
              <Box
                className="reciept-box2"
                sx={{ justifyContent: "space-between" }}
              >
                {/* <Typography className='reciept-box-text' >Items</Typography> */}
                <FormControl sx={{ width: "80%" }}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    value={selectedValue2}
                    onChange={handleSelectChange2}
                    name="radio-buttons-group"
                  >
                    {/* <FormControlLabel value="qty1" control={<Radio color='warning' />} label={<Typography >qty1 * Item name     $price</Typography>} className='reciept-box3' /> */}
                    <FormControlLabel
                      value="qty2"
                      control={<Radio color="warning" />}
                      label={<Typography>qty1 x Item name $price</Typography>}
                      className="reciept-box3"
                    />
                    <FormControlLabel
                      value="qty3"
                      control={<Radio color="warning" />}
                      label={<Typography>qty1 Item name $price</Typography>}
                      className="reciept-box3"
                    />
                  </RadioGroup>
                </FormControl>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter((each) => each.name === "items")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                      name="items"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  name="items"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "items")[0].checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Payment Type
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter(
                          (each) => each.name === "paymentType"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      name="paymentType"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="paymentType"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "paymentType")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Pay Status
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter((each) => each.name === "payStatus")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                      name="payStatus"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="payStatus"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "payStatus")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                {/* <Typography className='reciept-box-text' >Total</Typography> */}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    width: "80%",
                  }}
                >
                  {totalList.map((each: any) => (
                    <FormControl>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={each.isChecked}
                            onChange={(e) => handleCheckedTotalList(e, each.id)}
                            name={each.value}
                            color="warning"
                          />
                        }
                        label={each.value}
                      />
                    </FormControl>
                  ))}
                </Box>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter((each) => each.name === "total")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                      name="total"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="total"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "total")[0].checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Total
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter(
                          (each) => each.name === "finalTotal"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      name="finalTotal"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="finalTotal"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "finalTotal")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Customer Address
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter(
                          (each) => each.name === "customerDetails"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      name="customerDetails"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="customerDetails"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter(
                      (each) => each.name === "customerDetails"
                    )[0].checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Delivery Time
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter(
                          (each) => each.name === "timeRequested"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      name="timeRequested"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="timeRequested"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "timeRequested")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Collection Time
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      value={
                        basicData.filter(
                          (each) => each.name === "collectionTime"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      name="collectionTime"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="collectionTime"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter(
                      (each) => each.name === "collectionTime"
                    )[0].checked
                  }
                />
              </Box>
            </TabPanel>
            <TabPanel value={value} index={2}>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Customer Name
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      value={
                        basicData.filter(
                          (each) => each.name === "customerName"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      label="Align"
                      color="warning"
                      name="customerName"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="customerName"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "customerName")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Instructions
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      value={
                        basicData.filter(
                          (each) => each.name === "aditionalText"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      label="Align"
                      color="warning"
                      name="aditionalText"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="aditionalText"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "aditionalText")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Customer Mobile
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      value={
                        basicData.filter(
                          (each) => each.name === "customerMobile"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                      label="Align"
                      color="warning"
                      name="aditionalText"
                      size="small"
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="customerMobile"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter(
                      (each) => each.name === "customerMobile"
                    )[0].checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Served By
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      name="servedBy"
                      size="small"
                      value={
                        basicData.filter((each) => each.name === "servedBy")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="servedBy"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "servedBy")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Kot Number
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      name="kotNumber"
                      size="small"
                      value={
                        basicData.filter((each) => each.name === "kotNumber")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="kotNumber"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "kotNumber")[0]
                      .checked
                  }
                />
              </Box>
              {/* <Box className="reciept-box" sx={{ textAlign: "center" }} >
                <Typography className='reciept-box-text' sx={{ width: "80%" }}>Powered by</Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color='warning'
                      name="poweredBy"
                      size='small'
                      value={basicData.filter((each) => each.name === "poweredBy")[0].align as "" | HTMLInputElement | undefined}
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch color='warning' sx={{ marginLeft: "auto" }} name='poweredBy' onChange={handleCheckValue} checked={basicData.filter((each) => each.name === "poweredBy")[0].checked} />
              </Box> */}
              {/* <Box className="reciept-box" sx={{ textAlign: "center" }} >
                <Typography className='reciept-box-text' sx={{ width: "80%" }}>Show server num</Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color='warning'
                      name="showServerNum"
                      size='small'
                      value={basicData.filter((each) => each.name === "showServerNum")[0].align as "" | HTMLInputElement | undefined}
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch color='warning' sx={{ marginLeft: "auto" }} name='showServerNum' onChange={handleCheckValue} checked={basicData.filter((each) => each.name === "showServerNum")[0].checked} />
              </Box> */}
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Show Barcode
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      name="showBarCode"
                      size="small"
                      value={
                        basicData.filter(
                          (each) => each.name === "showBarCode"
                        )[0].align as "" | HTMLInputElement | undefined
                      }
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="showBarCode"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "showBarCode")[0]
                      .checked
                  }
                />
              </Box>
              <Box className="reciept-box" sx={{ textAlign: "center" }}>
                <Typography className="reciept-box-text" sx={{ width: "80%" }}>
                  Show QR code
                </Typography>
                <Box sx={{ minWidth: 120 }}>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">Align</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      onChange={handleSelectedValue}
                      label="Align"
                      color="warning"
                      name="showQr"
                      size="small"
                      value={
                        basicData.filter((each) => each.name === "showQr")[0]
                          .align as "" | HTMLInputElement | undefined
                      }
                    >
                      <MenuItem value="right">Right</MenuItem>
                      <MenuItem value="center">center</MenuItem>
                      <MenuItem value="left">Left</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
                <Switch
                  color="warning"
                  sx={{ marginLeft: "auto" }}
                  name="showQr"
                  onChange={handleCheckValue}
                  checked={
                    basicData.filter((each) => each.name === "showQr")[0]
                      .checked
                  }
                />
              </Box>
            </TabPanel>
            {/* <TabPanel value={value} index={3}>
            <div>
              <div>
                <div>
                  <div >
                    <div />
                    <SketchPicker color={selectedColor} onChange={handleColorChange} />
                  </div>
                </div>
              </div>
              <p>Selected color: {selectedColor}</p>
            </div>
          </TabPanel> */}
          </Box>
        </ThemeProvider>

        <Box
          sx={{
            width: "40%",
            marginLeft: "2%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            backgroundColor: "#FBFBFB",
          }}
          id="capture"
        >
          <Paper
            elevation={1}
            sx={{
              width: "300px",
              marginTop: "4%",
              border: "solid 1px #EEEEEE",
              padding: "10px",
            }}
          >
            <List>
              <StrictModeDroppable droppableId="droppable">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {checkedList.map((item, index) => (
                      <Draggable
                        key={item.id}
                        draggableId={item.id.toString()}
                        index={index}
                      >
                        {(provided) => (
                          <ListItem
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            onDoubleClick={() => handleStyle(item.id)}
                            sx={{
                              width: "100%",
                              padding: "0px",
                              margin: "4px 0 0 4px",
                            }}
                          >
                            <Box
                              sx={{
                                width: "100%",
                                textAlign: item.align,
                              }}
                              className={
                                idValue === item.id
                                  ? "select-paragraph"
                                  : "itemHover"
                              }
                            >
                              {item.name === "image" ? (
                                <img
                                  src={imageResult}
                                  alt="restaurant"
                                  style={{ width: "20%" }}
                                />
                              ) : item.name === "date" ? (
                                <Typography style={{ ...item.style }}>
                                  {" "}
                                  Date:{" "}
                                  {selectedValue === "date1"
                                    ? formattedDate1
                                    : selectedValue === "date2"
                                    ? formattedDate2
                                    : formattedDate3}
                                </Typography>
                              ) : item.name === "solidLine" ? (
                                <Box
                                  sx={{
                                    padding: "4px",
                                    display: "-moz-initial",
                                  }}
                                  onMouseEnter={() => {
                                    handleMouseEnter(item.id);
                                  }}
                                  onMouseLeave={() => {
                                    handleMouseLeave();
                                  }}
                                >
                                  {item.id === deleteButton ? (
                                    <Divider textAlign="right">
                                      <IconButton
                                        onClick={() => handleDelteLine(item.id)}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Divider>
                                  ) : (
                                    <Divider sx={{ borderTop: "1px solid" }} />
                                  )}
                                  {/* {deleteButton === item.id && <IconButton sx={{ position: 'absolute', right: "0" }} onClick={() => handleDelteLine(item.id)}><DeleteIcon /></IconButton>} */}
                                </Box>
                              ) : item.name === "dottedLine" ? (
                                <Box
                                  sx={{
                                    padding: "4px",
                                    display: "-moz-initial",
                                  }}
                                  onMouseEnter={() => {
                                    handleMouseEnter(item.id);
                                  }}
                                  onMouseLeave={() => {
                                    handleMouseLeave();
                                  }}
                                >
                                  {item.id === deleteButton ? (
                                    <Divider textAlign="right">
                                      <IconButton
                                        onClick={() => handleDelteLine(item.id)}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Divider>
                                  ) : (
                                    <Divider sx={{ borderTop: "1px dotted" }} />
                                  )}
                                </Box>
                              ) : item.name === "empty" ? (
                                <Box
                                  sx={{
                                    padding: "4px",
                                    display: "-moz-initial",
                                  }}
                                  onMouseEnter={() => {
                                    handleMouseEnter(item.id);
                                  }}
                                  onMouseLeave={() => {
                                    handleMouseLeave();
                                  }}
                                >
                                  {item.id === deleteButton ? (
                                    <Box textAlign="right">
                                      <IconButton
                                        onClick={() => handleDelteLine(item.id)}
                                      >
                                        <DeleteIcon fontSize="small" />
                                      </IconButton>
                                    </Box>
                                  ) : (
                                    <Box></Box>
                                  )}
                                </Box>
                              ) : item.name === "textField" ? (
                                <Box
                                  sx={{
                                    // position: 'relative',
                                    dispaly: "flex",
                                  }}
                                  onMouseEnter={() => {
                                    handleMouseEntertext(item.id);
                                  }}
                                  onMouseLeave={() => {
                                    handleMouseLeavetext("");
                                  }}
                                >
                                  <TextField
                                    fullWidth
                                    multiline
                                    placeholder="Enter Text"
                                    sx={{}}
                                    onChange={(
                                      e: React.ChangeEvent<HTMLInputElement>
                                    ) => handleEditValue2(e, item.id)}
                                    value={item.value}
                                  />
                                  {isHovered.toString() ===
                                    item.id.toString() && (
                                    <IconButton
                                      className="icon"
                                      sx={{
                                        position: "absolute",
                                        top: 0,
                                        right: 0,
                                        "&:hover": {
                                          backgroundColor: "#FFF8F0",
                                        },
                                      }}
                                      onClick={() => handleDelteLine(item.id)}
                                    >
                                      <DeleteIcon fontSize="small" />
                                    </IconButton>
                                  )}
                                </Box>
                              ) : item.name === "showQr" ? (
                                <Box>
                                  <img src={QrcodeImg} alt="qrcode" />
                                </Box>
                              ) : item.name === "showBarCode" ? (
                                <Box>
                                  <img src={BarcodeImg} alt="barcode" />
                                </Box>
                              ) : item.name === "items" ? (
                                <Box>
                                  {item.value &&
                                    item.value.map(
                                      (each: any, index: number) => {
                                        return (
                                          <Box
                                            key={index}
                                            sx={{
                                              display: "flex",
                                              width: "100%",
                                              justifyContent: "space-between",
                                            }}
                                          >
                                            <Typography
                                              style={{ ...item.style }}
                                            >{`${each.qty}  ${stringValue}  ${each.name}`}</Typography>
                                            {/* <Typography style={{ ...basicStyle.find((style: StyleList) => style.id === item.id), flex: 3 }}></Typography> */}
                                            <Typography
                                              style={{ ...item.style }}
                                            >
                                              {currencySybol} {each.price}
                                            </Typography>
                                          </Box>
                                        );
                                      }
                                    )}
                                </Box>
                              ) : item.name === "total" ? (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                  }}
                                >
                                  {totalList
                                    .filter((item) => item.isChecked === true)
                                    .map((each: any) => {
                                      return (
                                        <Typography
                                          style={{ ...item.style }}
                                        >{`${each.value}   $${each.amount}`}</Typography>
                                      );
                                    })}
                                </Box>
                              ) : item.name === "header" ? (
                                <Box
                                  key={index}
                                  sx={{
                                    display: "flex",
                                    width: "100%",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Typography
                                    style={{ ...item.style }}
                                  >{`Qty   Name`}</Typography>
                                  <Typography style={{ ...item.style }}>
                                    Price
                                  </Typography>
                                </Box>
                              ) : (
                                <Box
                                  onClick={() => {
                                    handleEditLabel(item.name);
                                  }}
                                  onMouseLeave={handleTextFieldMouseLeave}
                                >
                                  {variableEdit === item.name &&
                                  item.label !== null ? (
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                      }}
                                    >
                                      <TextField
                                        variant="standard"
                                        multiline
                                        onChange={(
                                          e: React.ChangeEvent<HTMLInputElement>
                                        ) => handleEditValue(e, item.id)}
                                        defaultValue={item.label}
                                        InputProps={{
                                          disableUnderline: true, // This removes the underline
                                        }}
                                        style={{
                                          ...item.style,
                                          width: "max-content",
                                        }}
                                      />
                                      <Typography>{`["${item.variableName}"]`}</Typography>
                                    </Box>
                                  ) : item.label !== null ? (
                                    <Typography
                                      style={{
                                        ...item.style,
                                        overflow: "hidden",
                                        width: "100%",
                                        wordWrap: "break-word",
                                        wordBreak: "break-all",
                                      }}
                                      className={
                                        idValue === item.id
                                          ? "select-paragraph"
                                          : undefined
                                      }
                                    >
                                      {item.label}: {item.value}
                                    </Typography>
                                  ) : (
                                    <Typography
                                      style={{
                                        ...item.style,
                                        overflow: "hidden",
                                        width: "100%",
                                        wordWrap: "break-word",
                                        wordBreak: "break-all",
                                      }}
                                      className={
                                        idValue === item.id
                                          ? "select-paragraph"
                                          : undefined
                                      }
                                    >
                                      {item.value}
                                    </Typography>
                                  )}
                                </Box>
                              )}
                            </Box>
                          </ListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </List>
          </Paper>
        </Box>
      </Box>
    </DragDropContext>
  );
};

export default RecieptBuilder;
