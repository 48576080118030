import { gql } from "@apollo/client";

export const EMPLOYEE_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
    query EmployeeReports {
      employeeReports(
        fromDate: "${fromDate}"
        toDate: "${toDate}"
        fromTime:"${fromTime}"
        toTime:"${toTime}"
        locationId:${locationId}
        ${employeeParam}
      )
    }
  `;
};
