import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { CATEGORY_LIST } from "../../graphicalQl/usequery/categoryListQuery";
import { useQuery } from "@apollo/client";
import {
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./assignItemsModal.css";
import SearchIcon from "@mui/icons-material/Search";
import { MENU_LIST } from "../../graphicalQl/usequery/menuQuery";
import { BUNDLE_LIST_DATA } from "../../graphicalQl/usequery/bundleQuery";
import { styled } from "@mui/system";
import { GET_PRODUCTS_IN_DISCOUNT } from "../../graphicalQl/usequery/discountQuery";
import Collapse from "@mui/material/Collapse";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import CurrencyDisplay from "../utils/currencySymbol";
import { PRODUCTS_LIST_IN_DISCOUNT } from "../../graphicalQl/usequery/productsListQuery";
import { set } from "react-hook-form";
import { useSelector } from "react-redux";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "70%",
    bgcolor: "background.paper",
};

interface Props {
    handleOpenModal: () => void;
    openModal: boolean;
    setFinalList: React.Dispatch<
        React.SetStateAction<
            { type: string; id: string; price: string; name: string }[]
        >
    >;
    buy1String: string;
    handleDataFromSubChild: (data: any) => void;
    setSecondFinalList: React.Dispatch<
        React.SetStateAction<
            { type: string; id: string; price: string; name: string }[]
        >
    >;
    itemsList: any;
    handleEligibleDataFromSubChild: (data: any) => void;
    eligibleItemsList: any;
}
export interface ChildRef {
    childFunction: (type: string, id: string, value: string) => void;
    copyAllItems: () => void;
}

interface Row {
    id: string;
    productCount: number;
    slugName: string;
    menuItems?: any;
}

interface Data {
    id: number;
    product: string;
    price: string;
    isChecked: boolean | string;
    disabled: boolean;
    accordionOpen: boolean;
    variants: {
        name: string;
        variantId: string;
        price: string;
        isChecked: boolean;
        disabled: boolean;
        variantGroup: string
    }[];
}

interface ProductItem {
    productId?: number[];
    variantIds?: number[];
}

type TypeSelect = "category" | "product" | "bundles" | "menu";
const AssignModalInDiscount = forwardRef<ChildRef, Props>(
    (props: Props, ref) => {
        const {
            openModal,
            handleOpenModal,
            setFinalList,
            handleDataFromSubChild,
            buy1String,
            eligibleItemsList,
            setSecondFinalList,
            itemsList,
            handleEligibleDataFromSubChild,
        } = props;
        const [open, setOpen] = React.useState(openModal);
        React.useEffect(() => {
            setOpen(openModal);
        }, [openModal]);

        const handleClose = () => {
            handleOpenModal();
            setOpen(false);
            setSearchValue("")
        };

        // const CustomStyles = styled("div")({
        //     "& .css-t89xny-MuiDataGrid-columnHeaderTitle": {
        //         color: "#333 !important",
        //         fontFamily: "Poppins, sans-serif !important",
        //         fontSize: "15px !important",
        //         fontStyle: "normal !important",
        //         fontWeight: "600 !important",
        //         lineHeight: "21px !important",
        //         textTransform: "capitalize !important",
        //     },
        //     "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
        //         outline: "solid #1976d2 0px !important",
        //     },
        //     "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
        //     {
        //         outline: "solid #1976d2 0px !important",
        //     },
        // });
        const { locationId } = useSelector((state: any) => state.headerLocation);
        const businessId: string = sessionStorage.getItem("businessId") as string;
        const currencySymbol = sessionStorage.getItem("currencySymbol")
        const { data: categoryData } = useQuery(CATEGORY_LIST, {
            context: { clientName: "categoryClient" },
            fetchPolicy: "cache-and-network",
            variables: { businessId: +businessId },
        });
        const [categoryList, setCategoryList] = React.useState<Row[]>([]);
        React.useEffect(() => {
            if (categoryData && categoryData.categoryList) {
                const list = categoryData.categoryList.edges.map(
                    (item: any, index: number) => ({
                        id: item.node.categoryId,
                        slugName: item.node.name,
                        // productCount: item.node.productCount,
                        productCount: (item.node.productCategory
                            ? item.node.productCategory.edges
                                .map((each: any) =>
                                    locationId &&
                                        +locationId === each.node.locationId &&
                                        each.node.isDeleted === false &&
                                        each.node.productStatus === "Active"
                                        ? +each.node.productId
                                        : null
                                )
                                .filter((id: any) => id !== null)
                            : []).length,
                        // productId: item.node.productCategory && item.node.productCategory.edges.map((each: any) => each.node.variants ? null : each.node.productId).filter(Boolean),
                        // varintIds: item.node.productCategory && item.node.productCategory.edges.map((each: any) => each.node.variants && each.node.variants.map((variant: any) => variant.variantId)).filter(Boolean)
                    })
                );

                setCategoryList(list);
            }
        }, [categoryData]);

        // const { data: productFilterdata, refetch } = useQuery(PRODUCTS_LIST_IN_DISCOUNT, {
        //     // { variables: { businessId: +businessId, categoryIds: JSON.stringify(selectCategoryIds), locationId: locationId }

        //     context: { clientName: "categoryClient" }
        // })
        // const [categoryProductData, setCategoryProductData] = useState<any>({ productId: [], variantIds: [] });
        // useEffect(() => {
        //     if (productFilterdata && productFilterdata.productList) {
        //         const list: ProductItem[] = productFilterdata.productList.edges.map((item: any) => ({
        //             productId: item.node.variants ? undefined : +item.node.productId,
        //             variantIds: item.node.variants ? item.node.variants.map((each: any) => +each.variantId) : undefined
        //         }));

        //         const productIds = list.map(item => item.productId).filter(productId => productId !== undefined);
        //         const variantIds = list.flatMap(item => item.variantIds ? item.variantIds : []).filter(variantId => variantId !== undefined);

        //         setCategoryProductData([{ productId: productIds, variantIds }]);
        //     }
        // }, [productFilterdata]);

        const [menuList, setMenuList] = React.useState<Row[]>([]);

        const { data } = useQuery(MENU_LIST, {
            context: { clientName: "categoryClient" },
            variables: { businessId: +businessId, locationId: locationId },
        });
        React.useEffect(() => {
            if (data && data.menuList) {
                const list = data.menuList.edges.map((each: any) => ({
                    id: each.node.menuId,
                    slugName: each.node.name,
                    productCount: each.node.productCount,
                    menuItems: each.node.menuItems && JSON.parse(each.node.menuItems),
                }));
                setMenuList(list);
            }
        }, [data]);
        const [bundleList, setBundleList] = React.useState<Row[]>([]);

        const { data: bundleData } = useQuery(BUNDLE_LIST_DATA, {
            context: { clientName: "categoryClient" },
            variables: { businessId: +businessId, locationId: locationId },
            fetchPolicy: "cache-and-network",
            onCompleted: (data) => { },
        });
        React.useEffect(() => {
            if (bundleData && bundleData.bundleList) {
                const list = bundleData.bundleList.edges.map((each: any) => ({
                    id: each.node.bundleId,
                    slugName: each.node.name,
                    productCount: each.node.noOfProducts,
                }));

                setBundleList(list);
            }
        }, [bundleData]);

        const [upDatedProductData, setProductData] = React.useState<Data[]>([]);
        const { data: productData } = useQuery(GET_PRODUCTS_IN_DISCOUNT, {
            variables: { businessId: +businessId, locationId: locationId },
            context: { clientName: "categoryClient" },
            fetchPolicy: "network-only",
        });

        React.useEffect(() => {
            if (productData) {
                const { productList } = productData;
                const { edges } = productList;
                if (edges.length > 0) {
                    const productUpdatedList = edges.map((item: any) => ({
                        id: +item.node.productId,
                        product: item.node.name,
                        price: item.node.price,
                        isChecked: false,
                        accordionOpen: false,
                        disabled: false,
                        variants:
                            item.node.variants &&
                            item.node.variants
                                .filter((each: any) => each.variantGroup === "Size")
                                .map((each: any) => ({
                                    name: each.name,
                                    variantId: each.variantId,
                                    price: each.additionalPrice,
                                    isChecked: false,
                                    disabled: false,
                                    variantGroup: each.variantGroup
                                })),
                    }));

                    setProductData(productUpdatedList);
                }
            }
        }, [productData]);

        const [typeSelect, setTypeSelect] = React.useState<TypeSelect>("category");

        const [rowSelectionModel, setRowSelectionModel] =
            React.useState<GridRowSelectionModel>([]);

        const [selectedIdsOftype, setSelectedIdsOftype] = React.useState<{
            [key: string]: string[];
        }>({
            category: [],
            product: [],
            bundles: [],
            menu: [],
            variantsIds: [],
        });

        const [productIds, setProductIds] = React.useState<{
            [key: string]: string[];
        }>({
            product: [],

            variantsIds: [],
        });

        useEffect(() => {
            setSelectedIdsOftype({
                category:
                    itemsList.category_ids &&
                    itemsList.category_ids.map((item: any) => item.toString()),
                product:
                    itemsList.product_ids &&
                    itemsList.product_ids.map((item: any) => item.toString()),
                bundles:
                    itemsList.bundle_ids &&
                    itemsList.bundle_ids.map((item: any) => item.toString()),
                menu:
                    itemsList.menu_ids &&
                    itemsList.menu_ids.map((item: any) => item.toString()),
                variantsIds:
                    itemsList.variant_ids &&
                    itemsList.variant_ids.map((item: any) => item.toString()),
            });
        }, [itemsList]);

        const [secondSelectedIdsOftype, setSecondSelectedIdsOftype] =
            React.useState<{
                [key: string]: string[];
            }>({
                category: [],
                product: [],
                bundles: [],
                menu: [],
                variantsIds: [],
            });

        useEffect(() => {
            setSecondSelectedIdsOftype({
                category: eligibleItemsList
                    ? eligibleItemsList.category_ids &&
                    eligibleItemsList.category_ids.map((item: any) => item.toString())
                    : [],
                product: eligibleItemsList
                    ? eligibleItemsList.product_ids &&
                    eligibleItemsList.product_ids.map((item: any) => item.toString())
                    : [],
                bundles: eligibleItemsList
                    ? eligibleItemsList.bundle_ids &&
                    eligibleItemsList.bundle_ids.map((item: any) => item.toString())
                    : [],
                menu: eligibleItemsList
                    ? eligibleItemsList.menu_ids &&
                    eligibleItemsList.menu_ids.map((item: any) => item.toString())
                    : [],
                variantsIds: eligibleItemsList
                    ? eligibleItemsList.variant_ids &&
                    eligibleItemsList.variant_ids.map((item: any) => item.toString())
                    : [],
            });
        }, [eligibleItemsList]);
        // useEffect(() => {
        //     if (rowSelectionModel && rowSelectionModel.length > 0 && typeSelect === "category") {
        //         refetch({ businessId: +businessId, categoryIds: rowSelectionModel && JSON.stringify(rowSelectionModel.map((each) => +each)), locationId: locationId })

        //     }

        // }, [rowSelectionModel])

        const handleDoneButton = () => {
            if (typeSelect !== "product") {
                const newRowSelectionModel: string[] = rowSelectionModel.map((item) =>
                    String(item)
                ); // Assuming GridRowSelectionModel is an array of numbers

                buy1String === "first"
                    ? setSelectedIdsOftype((prevState) => ({
                        ...prevState,
                        [typeSelect]: newRowSelectionModel,
                    }))
                    : setSecondSelectedIdsOftype((prevState) => ({
                        ...prevState,
                        [typeSelect]: newRowSelectionModel,
                    }));
            } else {
                // const productIds = upDatedProductData.filter((each) => each.isChecked !== false).map((item) => item.id.toString())
                // const variantIds = upDatedProductData.flatMap(product =>
                //     product.variants
                //         ?.filter(variant => variant.isChecked)
                //         .map(variant => variant.variantId.toString())
                // );

                // const updateVariant = variantIds.filter((each) => each !== undefined)
                buy1String === "first"
                    ? setSelectedIdsOftype((prevState) => ({
                        ...prevState,
                        product: productIds.product,
                        variantsIds: productIds.variantsIds,
                    }))
                    : setSecondSelectedIdsOftype((prevState) => ({
                        ...prevState,
                        product: productIds.product,
                        variantsIds: productIds.variantsIds,
                    }));
            }
        };

        useEffect(() => {
            buy1String === "first"
                ? setRowSelectionModel(selectedIdsOftype["category"])
                : setRowSelectionModel(secondSelectedIdsOftype["category"]);
            setTypeSelect("category");
            buy1String === "first"
                ? setProductIds({
                    product: selectedIdsOftype["product"],
                    variantsIds: selectedIdsOftype["variantsIds"],
                })
                : setProductIds({
                    product: secondSelectedIdsOftype["product"],
                    variantsIds: secondSelectedIdsOftype["variantsIds"],
                });
        }, [open]);

        const handleSelectChange = (event: SelectChangeEvent<string>) => {
            const selectedType = event.target.value as TypeSelect;
            setTypeSelect(selectedType);
            buy1String === "first"
                ? setRowSelectionModel(selectedIdsOftype[selectedType])
                : setRowSelectionModel(secondSelectedIdsOftype[selectedType]);

            if (selectedType === "product") {
                // const updateProduct = upDatedProductData.map(each => ({
                //     ...each,
                //     isChecked: selectedIdsOftype["product"] ? selectedIdsOftype["product"].includes(each.id.toString()) : false,
                //     variants: each.variants && each.variants.map((variant) => ({
                //         ...variant,
                //         isChecked: selectedIdsOftype["variantsIds"] ? selectedIdsOftype["variantsIds"].includes(variant.variantId.toString()) : false,
                //     }))
                // }));
                // setProductData(updateProduct)
                // setProductIds((prevState) => ({
                //     ...prevState,
                //     "product": selectedIdsOftype["product"],
                //     "variantsIds": selectedIdsOftype["variantsIds"]
                // }))
            }
        };

        let tableData = categoryList;

        switch (typeSelect) {
            case "category":
                tableData = categoryList;

                break;
            case "bundles":
                tableData = bundleList;
                break;
            case "menu":
                tableData = menuList;
                break;
            default:
        }

        const handleCheckboxChange = (id: string, value: boolean) => {
            // const updatedList = upDatedProductData.map((product) => {
            //     if (product.id.toString() === id) {
            //         return {
            //             ...product,
            //             isChecked: !product.isChecked,
            //             variants:
            //                 product.variants &&
            //                 product.variants.map((each) => {
            //                     return { ...each, isChecked: !product.isChecked };
            //                 }),
            //         };
            //     } else {
            //         return product;
            //     }
            // });
            if (value) {
                setProductIds((prevState) => {
                    const productInUpdatedList = upDatedProductData.find(
                        (product) => product.id.toString() === id
                    );
                    const variantIdsInProduct =
                        productInUpdatedList?.variants?.map((each) =>
                            each.variantId.toString()
                        ) || [];

                    return {
                        ...prevState,
                        variantsIds: Array.isArray(prevState["variantsIds"])
                            ? [...prevState["variantsIds"], ...variantIdsInProduct]
                            : [...variantIdsInProduct],
                        product: Array.isArray(prevState["product"])
                            ? [...prevState["product"], id]
                            : [id],
                    };
                });
            } else {
                setProductIds((prevState) => {
                    const productInUpdatedList = upDatedProductData.find(
                        (product) => product.id.toString() === id
                    );
                    const variantIdsInProduct =
                        productInUpdatedList?.variants?.map((each) =>
                            each.variantId.toString()
                        ) || [];

                    return {
                        ...prevState,
                        variantsIds: Array.isArray(prevState["variantsIds"])
                            ? prevState["variantsIds"].filter(
                                (each) => !variantIdsInProduct.includes(each)
                            )
                            : [],
                        product: Array.isArray(prevState["product"])
                            ? prevState["product"].filter((each) => each !== id)
                            : [],
                    };
                });
            }

            // setProductData(updatedList);
        };

        const handleVariantCheck = (
            productId: string,
            variantId: string,
            isChecked: boolean
        ) => {
            // setProductData(prevList => prevList.map(product => {
            //     if (product.id.toString() === productId) {
            //         const updatedVariants = product.variants.map(variant => {
            //             if (variant.variantId === variantId) {
            //                 return { ...variant, isChecked };
            //             }
            //             return variant;
            //         });

            //         const allVariantsChecked = updatedVariants.every(v => v.isChecked);
            //         const anyVariantChecked = updatedVariants.some(v => v.isChecked);

            //         let productChecked: string | boolean = false;
            //         if (allVariantsChecked) {
            //             productChecked = true;
            //         } else if (anyVariantChecked) {
            //             productChecked = "indeterminate"; // Intermediate state
            //         }

            //         return {
            //             ...product,
            //             variants: updatedVariants,
            //             isChecked: productChecked,
            //         };
            //     }
            //     return product;
            // }));
            if (isChecked) {
                setProductIds((prevState) => {
                    return {
                        ...prevState,
                        variantsIds: Array.isArray(prevState["variantsIds"])
                            ? [...prevState["variantsIds"], variantId]
                            : [variantId],
                        // "product": (() => {
                        //     const product = upDatedProductData.find((product) => product.id.toString() === productId);
                        //     const selectedVariantIds = product?.variants?.filter((variant) => selectedIdsOftype["variantsIds"]?.includes(variant.variantId.toString())).length;
                        //     const totalVariants = product?.variants?.length || 0;

                        //     return selectedVariantIds === totalVariants - 1 ? Array.isArray(prevState["product"]) ? [...prevState["product"], productId] : prevState["product"] : prevState["product"];
                        // })()
                        // "product":pre Array.isArray(prevState["product"]) ? [...prevState["product"], productId] : [productId]
                        product:
                            Array.isArray(prevState["product"]) &&
                                prevState["product"] &&
                                prevState["product"].includes(productId)
                                ? prevState["product"]
                                : [...(prevState["product"] || []), productId],
                    };
                });
            } else {
                setProductIds((prevState) => {
                    return {
                        ...prevState,
                        variantsIds: Array.isArray(prevState.variantsIds)
                            ? prevState.variantsIds.filter((each) => each !== variantId)
                            : [],
                        product: (() => {
                            const product = upDatedProductData.find(
                                (product) => product.id.toString() === productId
                            );
                            const selectedVariantIds = product?.variants?.filter((variant) =>
                                productIds.variantsIds?.includes(variant.variantId.toString())
                            ).length;
                            const totalVariants = product?.variants?.length || 0;
                            return selectedVariantIds === 1
                                ? prevState.product.filter((each) => each !== productId)
                                : prevState.product;
                        })(),
                    };
                });
            }

            setProductData((prevList) =>
                prevList.map((product) => {
                    if (product.id.toString() === productId) {
                        return {
                            ...product,
                            variants: product.variants.map((variant) => {
                                if (variant.variantId === variantId) {
                                    return { ...variant, isChecked };
                                }
                                return variant;
                            }),
                        };
                    }
                    return product;
                })
            );
        };

        // const [totalItemList, setTotalItemList] = React.useState<{ type: string, id: string, price: string, name: string }[]>([])

        React.useEffect(() => {
            const checkedMenuData = menuList.filter(
                (menu) =>
                    selectedIdsOftype["menu"] &&
                    selectedIdsOftype["menu"].includes(menu.id.toString())
            );

            const finalMenuData = checkedMenuData.map((menu) => ({
                type: "menu",
                id: menu.id,
                price: menu.productCount + " products",
                name: menu.slugName,
            }));

            setFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "menu"),
                ...finalMenuData,
            ]);

            const checkedCategoryData = categoryList.filter(
                (category) =>
                    selectedIdsOftype["category"] &&
                    selectedIdsOftype["category"].includes(category.id.toString())
            );

            const updatedFinalList = checkedCategoryData.map((category) => ({
                type: "category",
                id: category.id,
                price: category.productCount + " products",
                name: category.slugName,
            }));

            setFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "category"),
                ...updatedFinalList,
            ]);

            const checkedBundleData = bundleList.filter(
                (bundle) =>
                    selectedIdsOftype["bundles"] &&
                    selectedIdsOftype["bundles"].includes(bundle.id.toString())
            );

            const finalMenuData2 = checkedBundleData.map((bundle) => ({
                type: "bundles",
                id: bundle.id,
                price: bundle.productCount + " products",
                name: bundle.slugName,
            }));

            setFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "bundles"),
                ...finalMenuData2,
            ]);

            const checkedProductData = upDatedProductData.filter(
                (product) =>
                    selectedIdsOftype["product"] &&
                    selectedIdsOftype["product"].includes(product.id.toString())
            );

            const finalMenuData3 = checkedProductData.map((product) => ({
                type: "product",
                id: product.id.toString(),
                // price: product.price.toString(),
                //i want price value as price range of  checked variants of that product use min and max price of variants order only checked variants and if no variant is checked then use product price
                price: (() => {
                    const checkedVariants = product.variants?.filter((variant) =>
                        selectedIdsOftype["variantsIds"]?.includes(
                            variant.variantId.toString()
                        )
                    );
                    if (checkedVariants && checkedVariants.length > 0) {
                        const prices = checkedVariants.map((variant) => +variant.price);
                        return `${Math.min(...prices)} - ${Math.max(...prices)}`;
                    }
                    return product.price;
                })(),

                name: product.product,
            }));

            setFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "product"),
                ...finalMenuData3,
            ]);

            setRowSelectionModel(selectedIdsOftype[typeSelect]);
            handleDataFromSubChild(selectedIdsOftype);
        }, [
            selectedIdsOftype,
            menuList,
            upDatedProductData,
            bundleList,
            categoryList,
        ]);

        React.useEffect(() => {
            const checkedSecondMenuData = menuList && menuList.filter(
                (menu) =>
                    secondSelectedIdsOftype["menu"] &&
                    secondSelectedIdsOftype["menu"].includes(menu.id.toString())
            );

            const finalMenuData = checkedSecondMenuData && checkedSecondMenuData.map((menu) => ({
                type: "menu",
                id: menu.id,
                price: menu.productCount + " products",
                name: menu.slugName,
            }));
            setSecondFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "menu"),
                ...finalMenuData,
            ]);

            const checkedSecondCategoryData = categoryList && categoryList.filter(
                (category) =>
                    secondSelectedIdsOftype["category"] &&
                    secondSelectedIdsOftype["category"].includes(category.id.toString())
            );

            const updatedFinalList = checkedSecondCategoryData && checkedSecondCategoryData.map((category) => ({
                type: "category",
                id: category.id,
                price: category.productCount + " products",
                name: category.slugName,
            }));

            setSecondFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "category"),
                ...updatedFinalList,
            ]);

            const checkedSecondMenuData2 = bundleList && bundleList.filter(
                (bundle) =>
                    secondSelectedIdsOftype["bundles"] &&
                    secondSelectedIdsOftype["bundles"].includes(bundle.id.toString())
            );

            const finalMenuData2 = checkedSecondMenuData2 && checkedSecondMenuData2.map((bundle) => ({
                type: "bundles",
                id: bundle.id,
                price: bundle.productCount + " products",
                name: bundle.slugName,
            }));

            setSecondFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "bundles"),
                ...finalMenuData2,
            ]);

            const checkedSecondMenuData3 = upDatedProductData && upDatedProductData.filter(
                (product) =>
                    secondSelectedIdsOftype["product"] &&
                    secondSelectedIdsOftype["product"].includes(product.id.toString())
            );

            const finalMenuData3 = checkedSecondMenuData3 && checkedSecondMenuData3.map((product) => ({
                type: "product",
                id: product.id.toString(),
                price: product.price.toString(),
                name: product.product,
            }));

            setSecondFinalList((prevState) => [
                ...prevState.filter((each) => each.type !== "product"),
                ...finalMenuData3,
            ]);

            setRowSelectionModel(secondSelectedIdsOftype[typeSelect]);
            handleEligibleDataFromSubChild(secondSelectedIdsOftype);
        }, [
            secondSelectedIdsOftype,
            menuList,
            upDatedProductData,
            bundleList,
            categoryList,
        ]);
        const address: any = sessionStorage.getItem("address");
        function capitalizeFirstLetter(string: string) {
            return string && string.charAt(0).toUpperCase() + string.slice(1);
        }

        // const countryName = capitalizeFirstLetter(JSON.parse(address)[2].country);

        const handleAccordionOpen = (id: string) => {
            const index = upDatedProductData.findIndex(
                (product) => product.id.toString() === id
            );

            if (index !== -1) {
                const updatedProduct = {
                    ...upDatedProductData[index],
                    accordionOpen: !upDatedProductData[index].accordionOpen,
                };

                setProductData([
                    ...upDatedProductData.slice(0, index),
                    updatedProduct,
                    ...upDatedProductData.slice(index + 1),
                ]);
            }
        };

        function Row(props: { row: Data }) {
            const { row } = props;

            return (
                <React.Fragment>
                    <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
                        <TableCell>
                            {row.variants &&
                                (row.accordionOpen ? (
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleAccordionOpen(row.id.toString())}
                                    >
                                        <KeyboardArrowUpIcon />
                                    </IconButton>
                                ) : (
                                    <IconButton
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => handleAccordionOpen(row.id.toString())}
                                    >
                                        <KeyboardArrowDownIcon />
                                    </IconButton>
                                ))}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        color="warning"
                                        checked={
                                            productIds["product"]
                                                ? productIds["product"].includes(row.id.toString())
                                                : false
                                        }
                                        // indeterminate={upDatedProductData.find((product) => product.id.toString() === row.id.toString())?.variants?.some((variant) => variant.isChecked)}
                                        indeterminate={
                                            productIds["variantsIds"] &&
                                            row.variants &&
                                            productIds["variantsIds"].filter((variant) =>
                                                row.variants
                                                    ?.map((each) => each.variantId.toString())
                                                    .includes(variant)
                                            ).length < row.variants.length &&
                                            productIds["variantsIds"].filter((variant) =>
                                                row.variants
                                                    ?.map((each) => each.variantId.toString())
                                                    .includes(variant)
                                            ).length !== 0
                                        }
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                            handleCheckboxChange(row.id.toString(), e.target.checked)
                                        }
                                        disabled={row.disabled}
                                    // sx={{marginLeft:"1%"}}
                                    />
                                }
                                label={
                                    <Typography
                                        variant="body2"
                                        className="cell-text"
                                        sx={{ textTransform: "capitalize" }}
                                    >
                                        {row.product}
                                    </Typography>
                                }
                                sx={{ marginLeft: row.variants ? undefined : "6%" }}
                            />
                        </TableCell>

                        <TableCell align="center">
                            {/* <Typography className="cell-text">
                                {
                                    <CurrencyDisplay
                                        countryName={countryName}
                                        amount={row.price ? row.price.split("-") : 0}
                                    />
                                }
                            </Typography>{" "} */}
                            <Typography className="cell-text">
                                {currencySymbol}
                                {row.price}

                            </Typography>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={row.accordionOpen} timeout="auto">
                                <Box sx={{}}>
                                    <Table size="small" aria-label="purchases">
                                        <TableBody>
                                            {row.variants &&
                                                row.variants.map((variant) => (
                                                    <TableRow key={variant.variantId}>
                                                        <TableCell
                                                            component="th"
                                                            scope="row"
                                                            sx={{ width: "50%" }}
                                                        >
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        color="warning"
                                                                        checked={
                                                                            productIds["variantsIds"]
                                                                                ? productIds["variantsIds"].includes(
                                                                                    variant.variantId
                                                                                )
                                                                                : false
                                                                        }
                                                                        onChange={(e) =>
                                                                            handleVariantCheck(
                                                                                row.id.toString(),
                                                                                variant.variantId,
                                                                                e.target.checked
                                                                            )
                                                                        }
                                                                    // sx={{marginLeft:"1%"}}
                                                                    />
                                                                }
                                                                label={
                                                                    <Typography
                                                                        variant="body2"
                                                                        className="cell-text"
                                                                        sx={{ textTransform: "capitalize" }}
                                                                    >
                                                                        {variant.name}
                                                                    </Typography>
                                                                }
                                                                sx={{ marginLeft: "1%" }}
                                                            />
                                                        </TableCell>
                                                        <TableCell align="center">
                                                            {/* <Typography className="cell-text">
                                                                {
                                                                    <CurrencyDisplay
                                                                        countryName={countryName}
                                                                        amount={variant.price}
                                                                    />
                                                                }
                                                            </Typography>{" "} */}
                                                            <Typography className="cell-text"> {currencySymbol}
                                                                {variant.price}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </React.Fragment>
            );
        }

        const columns: GridColDef[] = [
            {
                field: "slugName",
                headerName: typeSelect,
                headerClassName: "header-text",
                cellClassName: "cell-text",
                flex: 1,
                editable: false,
                disableColumnMenu: true,
                renderCell(params) {
                    return (
                        <Typography className="cell-text" sx={{ textTransform: 'capitalize' }}>
                            {params.value}
                        </Typography>
                    );
                },
            },
            {
                field: "productCount",
                headerName: "Products",
                headerAlign: "center",
                headerClassName: "header-text",
                cellClassName: "cell-text2",
                flex: 1,
                editable: false,
                disableColumnMenu: true,
                align: "center",
                renderCell(params) {
                    return (
                        <Typography className="cell-text2">
                            {params.value} Products
                        </Typography>
                    );
                },
            },
        ];

        useImperativeHandle(ref, () => ({
            childFunction: (type: string, id: string, value: string) => {
                if (value === "first") {
                    if (type === "product") {
                        setSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            [type]:
                                prevState[type] &&
                                prevState[type].filter((each) => each !== id),
                        }));
                        setProductIds((prevState) => ({
                            ...prevState,
                            product:
                                selectedIdsOftype[type] &&
                                selectedIdsOftype[type].filter((each) => each !== id),
                        }));
                        const filteredProduct = upDatedProductData.filter(
                            (each) => each.id.toString() === id
                        );
                        const filteredVariants =
                            filteredProduct[0].variants &&
                            filteredProduct[0].variants.map((each) =>
                                each.variantId.toString()
                            );
                        setSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            variantsIds:
                                prevState["variantsIds"] &&
                                prevState["variantsIds"].filter((each) =>
                                    filteredVariants ? !filteredVariants.includes(each) : each
                                ),
                        }));
                        setProductIds((prevState) => ({
                            ...prevState,
                            variantsIds:
                                selectedIdsOftype["variantsIds"] &&
                                selectedIdsOftype["variantsIds"].filter((each) =>
                                    filteredVariants ? !filteredVariants.includes(each) : each
                                ),
                        }));
                    } else {
                        setSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            [type]:
                                prevState[type] &&
                                prevState[type].filter((each) => each !== id),
                        }));
                    }
                } else {
                    if (type === "product") {
                        setSecondSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            [type]:
                                prevState[type] &&
                                prevState[type].filter((each) => each !== id),
                        }));
                        setProductIds((prevState) => ({
                            ...prevState,
                            [type]:
                                secondSelectedIdsOftype[type] &&
                                secondSelectedIdsOftype[type].filter((each) => each !== id),
                        }));
                        const filteredProduct = upDatedProductData.filter(
                            (each) => each.id.toString() === id
                        );
                        const filteredVariants =
                            filteredProduct[0].variants &&
                            filteredProduct[0].variants.map((each) =>
                                each.variantId.toString()
                            );
                        setSecondSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            variantsIds:
                                prevState["variantsIds"] &&
                                prevState["variantsIds"].filter((each) =>
                                    filteredVariants ? !filteredVariants.includes(each) : each
                                ),
                        }));
                        setProductIds((prevState) => ({
                            ...prevState,
                            variantsIds:
                                secondSelectedIdsOftype["variantsIds"] &&
                                secondSelectedIdsOftype["variantsIds"].filter((each) =>
                                    filteredVariants ? !filteredVariants.includes(each) : each
                                ),
                        }));
                    } else {
                        setSecondSelectedIdsOftype((prevState) => ({
                            ...prevState,
                            [type]:
                                prevState[type] &&
                                prevState[type].filter((each) => each !== id),
                        }));
                    }
                }
            },
            copyAllItems: () => {
                setSecondSelectedIdsOftype(selectedIdsOftype);
            },
        }));

        const discountType = sessionStorage.getItem("discountType");
        const [searchValue, setSearchValue] = useState("")
        const searchedTableList = tableData.filter((each) => each.slugName.toLowerCase().includes(searchValue.toLowerCase()))
        const searchedProductList = upDatedProductData.filter((each) => each.product.toLowerCase().includes(searchValue.toLowerCase()))
        const handleRowStyle = () => {
            return "rowHover"
        }
        return (
            <div>

                <Modal
                    open={open}
                    // onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Box sx={{ padding: "10px 0px 0px 20px" }}>
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                                <IconButton sx={{ width: "40px", height: '40px', borderRadius: "4px", color: "#333333", display: "flex", alignItems: "center", justifyContent: "center", bgcolor: "#EEEEEE", "& hover": { backgroundColor: "#FFF8F0" } }} onClick={handleClose}>
                                    <Box >
                                        <CloseIcon sx={{ width: "24px", height: "24px" }} />
                                    </Box>
                                </IconButton>
                                <Typography className='head-text'>Select Products</Typography>
                                <Typography></Typography>
                            </Box>
                            <Divider sx={{ marginTop: "0.75rem", marginBottom: '1.375rem' }} />
                            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", gap: "2.5%", marginBottom: "2rem" }}>
                                <Box sx={{ width: "28%" }}>
                                    <FormControl fullWidth>
                                        <InputLabel id="demo-simple-select-label">select</InputLabel>
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            label="select"
                                            color='warning'
                                            size='medium'
                                            value={typeSelect}
                                            onChange={handleSelectChange}
                                        >
                                            <MenuItem value="category">All Categories</MenuItem>
                                            <MenuItem value="product">All Products</MenuItem>
                                            <MenuItem value="bundles">All Bundles</MenuItem>
                                            {(discountType === "customerGroup" || discountType === "forItem" || discountType === "freeDiscount") &&
                                                <MenuItem value="menu">All Menus</MenuItem>}

                                        </Select>
                                    </FormControl>
                                </Box>
                                <TextField sx={{ width: "62%" }} color='warning' label="Search by name"
                                    size='medium'
                                    onChange={(e) => setSearchValue(e.target.value)}
                                    InputProps={{
                                        startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
                                    }}
                                />
                            </Box>
                            {typeSelect !== "product" ? (
                                <Box sx={{ maxHeight: '40vh', width: '100%', overflowY: "scroll" }}>

                                    <DataGrid
                                        rows={searchedTableList}
                                        columns={columns}
                                        getRowClassName={handleRowStyle}
                                        rowHeight={48}
                                        columnHeaderHeight={48}
                                        // initialState={{
                                        //     pagination: {
                                        //         paginationModel: {
                                        //             pageSize: 5,
                                        //         },
                                        //     },
                                        // }}
                                        // pageSizeOptions={[5]}
                                        hideFooterPagination
                                        scrollbarSize={0}
                                        // sx={{ marginRight: "2%" }}
                                        checkboxSelection
                                        disableRowSelectionOnClick
                                        onRowSelectionModelChange={(newRowSelectionModel: GridRowSelectionModel) => {
                                            setRowSelectionModel(newRowSelectionModel);
                                        }}
                                        rowSelectionModel={rowSelectionModel}
                                        sx={{
                                            marginRight: "2%",
                                            '&.MuiDataGrid-columnHeaderTitle': {
                                                fontWeight: '500',
                                            },
                                            '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
                                                outline: 'solid #1976d2 0px !important',
                                            },
                                            '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
                                                outline: 'solid #1976d2 0px !important',
                                            },
                                            "& .MuiDataGrid-columnHeader": {

                                                background: "#f5f5f5",

                                            },
                                            // marginLeft: "20px",
                                            '.MuiTablePagination-displayedRows': {
                                                display: 'none', // 👈 to hide huge pagination number
                                            },
                                        }}
                                    />
                                </Box>) : (
                                <Box sx={{ maxHeight: '40vh', width: '100%', overflowY: "scroll" }}>
                                    <TableContainer component={Paper}>
                                        <Table aria-label="collapsible table">
                                            <TableHead>
                                                <TableRow>

                                                    <TableCell sx={{ width: "50%", pl: "10%" }} className="table-column-header">Products</TableCell>
                                                    <TableCell align='center' className="table-column-header">Price</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {searchedProductList.map((row, index) => (
                                                    <Row key={row.product + index} row={row} />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}

                            <Button className='main-button' sx={{ marginLeft: "85%", marginTop: "4px", marginBottom: "4px" }} onClick={() => { handleClose(); handleDoneButton() }}>Done</Button>

                        </Box>
                    </Box>
                </Modal>
            </div>
        )
        // return (
        //   <div>
        //     <Modal
        //       open={open}
        //       // onClose={handleClose}
        //       aria-labelledby="modal-modal-title"
        //       aria-describedby="modal-modal-description"
        //     >
        //       <Box sx={style}>
        //         <Box sx={{ padding: "10px 0px 0px 20px" }}>
        //           <Box
        //             sx={{
        //               display: "flex",
        //               alignItems: "center",
        //               justifyContent: "space-between",
        //             }}
        //           >
        //             <IconButton
        //               sx={{
        //                 width: "40px",
        //                 height: "40px",
        //                 borderRadius: "4px",
        //                 color: "#333333",
        //                 display: "flex",
        //                 alignItems: "center",
        //                 justifyContent: "center",
        //                 bgcolor: "#EEEEEE",
        //                 "& hover": { backgroundColor: "#FFF8F0" },
        //               }}
        //               onClick={handleClose}
        //             >
        //               <Box>
        //                 <CloseIcon sx={{ width: "24px", height: "24px" }} />
        //               </Box>
        //             </IconButton>
        //             <Typography className="head-text">Select Products</Typography>
        //             <Typography></Typography>
        //           </Box>
        //           <Divider
        //             sx={{ marginTop: "0.75rem", marginBottom: "1.375rem" }}
        //           />
        //           <Box
        //             sx={{
        //               display: "flex",
        //               alignItems: "center",
        //               justifyContent: "center",
        //               gap: "2.5%",
        //               marginBottom: "2rem",
        //             }}
        //           >
        //             <Box sx={{ width: "28%" }}>
        //               <FormControl fullWidth>
        //                 <InputLabel id="demo-simple-select-label">
        //                   select
        //                 </InputLabel>
        //                 <Select
        //                   labelId="demo-simple-select-label"
        //                   id="demo-simple-select"
        //                   label="select"
        //                   color="warning"
        //                   size="medium"
        //                   value={typeSelect}
        //                   onChange={handleSelectChange}
        //                 >
        //                   <MenuItem value="category">All Categories</MenuItem>
        //                   <MenuItem value="product">All Products</MenuItem>
        //                   <MenuItem value="bundles">All Bundles</MenuItem>
        //                   {(discountType === "customerGroup" ||
        //                     discountType === "forItem" ||
        //                     discountType === "freeDiscount") && (
        //                     <MenuItem value="menu">All Menus</MenuItem>
        //                   )}
        //                 </Select>
        //               </FormControl>
        //             </Box>
        //             <TextField
        //               sx={{ width: "62%" }}
        //               color="warning"
        //               label="Search by name"
        //               size="medium"
        //               onChange={(e) => setSearchValue(e.target.value)}
        //               InputProps={{
        //                 startAdornment: (
        //                   <InputAdornment position="start">
        //                     <SearchIcon />
        //                   </InputAdornment>
        //                 ),
        //               }}
        //             />
        //           </Box>
        //           {typeSelect !== "product" ? (
        //             <Box
        //               sx={{ maxHeight: "40vh", width: "100%", overflowY: "scroll" }}
        //             >
        //               <CustomStyles>
        //                 <DataGrid
        //                   rows={searchedTableList}
        //                   columns={columns}
        //                   getRowClassName={handleRowStyle}
        //                   rowHeight={48}
        //                   columnHeaderHeight={48}
        //                   // initialState={{
        //                   //     pagination: {
        //                   //         paginationModel: {
        //                   //             pageSize: 5,
        //                   //         },
        //                   //     },
        //                   // }}
        //                   // pageSizeOptions={[5]}
        //                   hideFooterPagination
        //                   scrollbarSize={0}
        //                   sx={{ marginRight: "2%" }}
        //                   checkboxSelection
        //                   disableRowSelectionOnClick
        //                   onRowSelectionModelChange={(
        //                     newRowSelectionModel: GridRowSelectionModel
        //                   ) => {
        //                     setRowSelectionModel(newRowSelectionModel);
        //                   }}
        //                   rowSelectionModel={rowSelectionModel}
        //                 />
        //               </CustomStyles>
        //             </Box>
        //           ) : (
        //             <Box
        //               sx={{ maxHeight: "40vh", width: "100%", overflowY: "scroll" }}
        //             >
        //               <TableContainer component={Paper}>
        //                 <Table aria-label="collapsible table">
        //                   <TableHead>
        //                     <TableRow>
        //                       <TableCell sx={{ width: "50%" }}>Products</TableCell>
        //                       <TableCell align="center">Price</TableCell>
        //                     </TableRow>
        //                   </TableHead>
        //                   <TableBody>
        //                     {searchedProductList.map((row, index) => (
        //                       <Row key={row.product + index} row={row} />
        //                     ))}
        //                   </TableBody>
        //                 </Table>
        //               </TableContainer>
        //             </Box>
        //           )}
        //           <Box sx={{ marginTop: "4px", marginBottom: "4px" }}>
        //             <Button
        //               className="main-button"
        //               sx={{ marginLeft: "85%" }}
        //               onClick={() => {
        //                 handleClose();
        //                 handleDoneButton();
        //               }}
        //             >
        //               Done
        //             </Button>
        //           </Box>
        //         </Box>
        //       </Box>
        //     </Modal>
        //   </div>
        // );
    }
);

export default AssignModalInDiscount;
