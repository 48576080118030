import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { useNavigate } from 'react-router';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "50%",
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
};

interface Props {
    modalState: boolean;
    setModalState: (value: boolean) => void;
    location: string
}

export default function SuccessMessage(props: Props) {
    const { modalState, setModalState, location } = props;
    const navigate = useNavigate()
    const handleClose = () => {
        setModalState(false);
        navigate("/bundle/bundle-listing");
    };

    return (
        <div>
            <Modal
                open={modalState}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ ...style, gap: '32px', display: "flex", flexDirection: "column", alignItems: "center", textAlign: "center" }}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                        Congratulations! Your new bundle has been successfully created in the {location} Store
                    </Typography>
                    <Button className='main-button' onClick={handleClose}>Okay</Button>

                </Box>
            </Modal>
        </div>
    );
}
