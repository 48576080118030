import { Box, Button, Sheet, Typography } from "@mui/joy";
import React, { useEffect, useState } from "react";
import CloseIcon from "../../../../../assets/close2.png";
import {
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import styled from "styled-components";
import { useMutation } from "@apollo/client";
import { useTranslation } from "react-i18next";
import {
  GENERATE_OTP,
  UPDATE_BUSINESS,
  VALIDATE_OTP,
} from "../../../../../graphicalQl/mutation/customerDataMutation";
import { toast } from "react-toastify";

interface Props {
  verificationCodeModal: any;
  setVerificationCodeModal: any;
  mobile: string;
}

const VerificationCode = (props: Props) => {
  const { verificationCodeModal, setVerificationCodeModal, mobile } = props;

  const { t } = useTranslation();
  const [viewMode, setViewMode] = useState("sendCode");
  const [rememberLoginFor90Days, setRememberLoginFor90Days] = useState(false);
  const [otp, setOtp] = useState("");

  // const MuiOtpInputStyled = styled(MuiOtpInput)({
  //   "& .MuiOtpInput-TextField": {
  //     width: "36px",
  //     height: "40px",
  //   },
  //   "& .MuiInputBase-root": {
  //     width: "36px",
  //     height: "40px",
  //   },
  // });

  const matchIsNumeric = (text: any) => {
    const isNumber = typeof text === "number";
    const isString = typeof text === "string";
    return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
  };
  const [
    generateOTPMutation,
    { data: generateOTPData, error: generateOTPError },
  ] = useMutation(GENERATE_OTP, {
    context: { clientName: "userClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.otp_sent")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [
    validateOTPMutation,
    { data: validateOTPData, error: validateOTPError },
  ] = useMutation(VALIDATE_OTP, {
    context: { clientName: "userClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>{t("toast.otp_verified")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  const [
    updateBusinessMutation,
    { data: updateBusinessMutationData, error: updateBusinessMutationError },
  ] = useMutation(UPDATE_BUSINESS, {
    context: { clientName: "businessClient" },
    onCompleted: (data) => {
      toast.success(
        <div>
          <Typography>
            Two Factor Authentication Turned On Successfully
          </Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });

  useEffect(() => {
    if (generateOTPData?.customerOtpGeneration?.authenticate?.generatedAt) {
      // alert("OTP sent successfully");
      setViewMode("verify");
    }
  }, [generateOTPData]);

  useEffect(() => {
    if (updateBusinessMutationData?.updateBusiness?.success) {
      // alert("Two factor authentication enabled successfully");
      verificationCodeModal?.data?.refetchBusinessDetails();
      setVerificationCodeModal({
        state: false,
        data: {},
      });
    }
  }, [updateBusinessMutationData]);

  useEffect(() => {
    if (validateOTPData?.customerAuthentication?.authenticated) {
      // alert("OTP verified successfully");
      updateBusinessMutation({
        variables: {
          businessId: verificationCodeModal.data.businessId,
          addressDetails: {},
          enableTwoStepVerification: true,
        },
      });
    }
  }, [validateOTPData]);

  const generateOTP = () => {
    generateOTPMutation({
      variables: {
        mobile: verificationCodeModal.data.mobile,
        businessId: parseInt(verificationCodeModal.data.businessId),
      },
    });
  };
  const [errorStatus, setErrorStatus] = useState(false);

  const verifyOTP = () => {
    validateOTPMutation({
      variables: {
        mobile: verificationCodeModal.data.mobile,
        businessId: parseInt(verificationCodeModal.data.businessId),
        otp: otp,
        isAdminVerification: true,
      },
      onError: (error) => {
        setErrorStatus(true);
      },
    });
  };
  const [disable, setDisable] = useState(true);
  const handleComplete = () => {
    setDisable(!disable);
  };

  return (
    <Sheet
      variant="outlined"
      className="shortCutModalBoxShadow"
      sx={{
        width: "720px",
        outline: "none",
        borderRadius: "8px",
        border: "1px solid #EEEEEE",
        padding: "20px 32px",
        boxSizing: "border-box",
      }}
    >
      <Box
        sx={{
          display: "flex",
          marginBottom: "24px",
          borderBottom: "1px solid #D9D9D9",
          alignItems: "center",
          justifyContent: "space-between",
          paddingBottom: "20px",
        }}
      >
        <img
          src={CloseIcon}
          alt="close modal"
          onClick={() => {
            setVerificationCodeModal({
              state: false,
              data: {},
            });
          }}
        />
        <Box
          sx={{
            display: "flex",
            gap: "20px",
          }}
        >
          {viewMode === "verify" ? (
            <>
              <Button
                onClick={() => {
                  setViewMode("sendCode");
                  setOtp("");
                }}
                sx={{
                  width: "97px",
                  height: "44px",
                  backgroundColor: "#EEE",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#F38B08",
                    textTransform: "capitalize",
                  }}
                >
                  {t("account_settings.back_button_text")}
                </Typography>
              </Button>
              <Button
                onClick={() => verifyOTP()}
                sx={{
                  width: "155px",
                  height: "44px",
                  backgroundColor: "#F38B08",
                  display: "flex",
                  justifyContent: "center",
                  "&:hover": {
                    backgroundColor: "#F38B08",
                  },
                }}
              >
                <Typography
                  sx={{
                    fontSize: "18px",
                    lineHeight: "27px",
                    fontWeight: "500",
                    fontFamily: "Poppins",
                    color: "#FEFEFE",
                    textTransform: "capitalize",
                  }}
                >
                  {t("account_settings.verify_button_text")}
                </Typography>
              </Button>
            </>
          ) : (
            <Button
              onClick={() => generateOTP()}
              sx={{
                width: "155px",
                cursor: "pointer",
                height: "44px",
                backgroundColor: "#F38B08",
                display: "flex",
                justifyContent: "center",
                "&:hover": {
                  backgroundColor: "#F38B08",
                },
              }}
            >
              <Typography
                sx={{
                  fontSize: "18px",
                  lineHeight: "27px",
                  fontWeight: "500",
                  fontFamily: "Poppins",
                  color: "#FEFEFE",
                  textTransform: "capitalize",
                }}
              >
                {t("account_settings.continue_button_text")}
              </Typography>
            </Button>
          )}
        </Box>
      </Box>

      <Box
        sx={{
          marginLeft: "22px",
        }}
      >
        <Typography
          sx={{
            color: "#333",
            fontFamily: "Poppins",
            fontSize: "20px",
            fontStyle: "normal",
            fontWeight: 600,
            lineHeight: "normal",
            marginTop: "24px",
            marginBottom: "24px",
          }}
        >
          {viewMode === "sendCode"
            ? "Enable two-step verification"
            : "Enter the verification code"}
        </Typography>
        <Typography
          sx={{
            color: "#707070",
            fontFamily: "Poppins",
            fontSize: "16px",
            fontStyle: "normal",
            fontWeight: 500,
            lineHeight: "normal",
            marginBottom: "32px",
          }}
        >
          {viewMode === "sendCode" ? (
            t("account_settings.enable_two_step_verification_description")
          ) : (
            <div>
              {t("account_settings.verification_code_sent_description")}{" "}
              {mobile}{" "}
              {t("account_settings.verification_code_sent_description2")}
              <br />
              <Button
                style={{ color: "#F38B08", backgroundColor: "#ffffff" }}
                onClick={() => generateOTP()}
              >
                {" "}
                {t("account_settings.resend_code_text")}
              </Button>
            </div>
          )}
        </Typography>
      </Box>

      {viewMode === "sendCode" ? (
        <Box
          sx={{
            display: "flex",
            marginLeft: "22px",
            columnGap: "20px",
          }}
        >
          {/* <FormControl sx={{ width: "280px" }}>
            <InputLabel id="Country">Country</InputLabel>
            <Select
              id="country"
              name="country"
              label="Country"
            // renderValue={(selected) => {
            //   // show placeholder text when no option is selected
            //   return selected ? selected : "Country";
            // }}
            //onChange={twoFactorAuthenticationModal}
            >
              {countries.map(({ label, phone, code }: { label: string, phone: string, code: string }) => (
                <MenuItem value={code}>{label + ` +${phone}`}</MenuItem>
              ))}
            </Select>
          </FormControl> */}
          <TextField
            type="text"
            fullWidth
            sx={{
              width: "280px",
              height: "48px",
            }}
            label={t("account_settings.phone_number_label")}
            //@ts-ignore
            placeholder={t("account_settings.phone_number_label")}
            id="outlined-size-normal"
            value={verificationCodeModal.data.mobile}
            //onChange={onChangeGroupName}
          />
        </Box>
      ) : (
        <Box sx={{ textAlign: "center" }}>
          <Box
            sx={{
              width: "480px",
              height: "60px",
              padding: "10px 62px",
              borderRadius: "4px",
              border: "1px solid #EEE",
              marginLeft: "22px",
              marginTop: "24px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MuiOtpInput
              validateChar={matchIsNumeric}
              TextFieldsProps={{ placeholder: "-" }}
              autoFocus
              value={otp}
              length={4}
              onComplete={handleComplete}
              onChange={(newValue) => {
                setOtp(newValue);
                setDisable(true);
                setErrorStatus(false);
              }}
              sx={{
                "& .MuiOtpInput-TextField": {
                  width: "36px",
                  height: "40px",
                },
                "& .MuiInputBase-root": {
                  width: "36px",
                  height: "40px",
                },
              }}
            />
          </Box>
          {errorStatus && (
            <Typography
              sx={{
                fontSize: "12px",
                color: "red",
                marginTop: "12px",
              }}
            >
              {t("account_settings.valid_otp_error")}
            </Typography>
          )}
        </Box>
      )}

      {viewMode === "sendCode" ? (
        <Box
          sx={{
            margin: "33px 0 66px 22px",
          }}
        >
          <Typography
            sx={{
              fontSize: "12px",
              fontWeight: "400",
              fontFamily: "Poppins",
              color: "#707070",
            }}
          >
            {t("account_settings.message_and_data_rates_apply")}{" "}
            <span style={{ color: "#333", fontWeight: 600, cursor: "pointer" }}>
              {" "}
              {t("account_settings.privacy_policy_text")}{" "}
            </span>
            and
            <span style={{ color: "#333", fontWeight: 600, cursor: "pointer" }}>
              {" "}
              {t("account_settings.text_message_terms")}
            </span>
          </Typography>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            padding: "12px 8px",
            alignItems: "center",
            marginLeft: "22px",
            marginBottom: "45px",
            marginTop: "24px",
          }}
        >
          <Typography
            sx={{
              ontSize: "14px",
              lineHeight: "27px",
              fontWeight: "400",
              fontFamily: "Poppins",
              color: "#333",
            }}
          >
            {t("account_settings.remember_device_text")}
          </Typography>
          <Checkbox
            checked={rememberLoginFor90Days}
            tabIndex={-1}
            onClick={() => setRememberLoginFor90Days(!rememberLoginFor90Days)}
          />
        </Box>
      )}
    </Sheet>
  );
};

export default VerificationCode;
