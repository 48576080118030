import { gql } from "@apollo/client";

export const RECIEPT_LIST_QUERY = gql`
query receiptList($businessId:ID){
    receiptList(businessId:$businessId) {
        edges{node{receiptId
        receiptName
        receipt
        receiptData
        receiptType
        createdOn
        receiptStatus
    }}
    }
            }
`
export const RECIEPT_NAME_QUERY = gql`
query ReceiptDetail($receiptId:ID){
    receipt(receiptId:$receiptId) {
        receiptId
       
        receiptType
        receiptName
        receiptStatus
    }}

`

export const RECIEPT_DETAIL_QUERY = gql`
query ReceiptDetail($receiptId:ID){
    receipt(receiptId:$receiptId) {
        receiptId
        receipt
        receiptData
        receiptName
    }}
`