import { gql } from "@apollo/client";

export const GET_OPTIONS_LIST_IN_OPTIONS = gql`
query OptionList($name_Icontains:String,$productId:ID,$businessId:ID,$first:Int,$after:String,$before:String,$last:Int){
    optionList(businessId:$businessId,name_Icontains:$name_Icontains,productId:$productId,first:$first,after:$after,before:$before,last:$last) {
           totalCount   
  pageInfo{hasNextPage,startCursor,endCursor,hasPreviousPage}
            edges{node{optionId
                
                
                name
                products
                optionValues{
                    name
                }
                }
            }
        
        
    }
        
    }
   
    
`
export const GET_OPTION_GROUP_IN_OPTIONS = gql`
query optionGroupList($businessId:ID){
    optionGroupList(businessId:$businessId) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{optionGroupId
                
                
            
                optionObjs{
                    name
                    
                    optionId
                    
                name}
                name
                }
            }
        
    }
        
    }
`

export const GET_PRODUCTS_IN_OPTIONS = gql`
query productList($businessId:ID,$locationId:ID){
    productList(businessId:$businessId,locationId:$locationId) {
            pageInfo{hasNextPage
            hasPreviousPage}
            edges{node{
                productId
                name
            }
            }
        
        
    }
        
    }`

export const MODIFIERS_LIST = gql`
query modifiersList($businessId:ID){
	modifiersList(businessId:$businessId){
	edges{
		node{modifierId
		name
		}
	}
		
	
	}
}`