import * as React from "react";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { PickersShortcutsItem } from "@mui/x-date-pickers/PickersShortcuts";
import { DateRange } from "@mui/x-date-pickers-pro";
import { styled } from "@mui/system";
import { getDateLabel } from "../../../../utils/getDateLabels";
import { Box, Typography } from "@mui/material";
import { DateType } from "../../reportsHeaders";
import { useTranslation } from "react-i18next";

interface Props {
  setDateDropdownLabel: (e: string) => void;

  setDate: (a: DateType) => void;
  date: DateType;
}

const DateRangeWrapper = styled("div")({
  ".MuiDateRangeCalendar-root > :first-child": {
    display: "none",
  },
  ".css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-toolbar": {
    gridColumn: "1",
  },
  ".css-4pax7l-MuiPickersLayout-root .MuiPickersLayout-shortcuts": {
    gridColumn: "2",
  },
  ".css-16b5y55-MuiPickersLayout-contentWrapper": {
    gridColumn: "1",
  },
  ".css-8m5rrr-MuiButtonBase-root-MuiChip-root": {
    borderRadius: "6px",
    width: "100%",
    height: "40px",
    fontWeight: 600,
    color: "#455560",
  },
});

const BasicRangeShortcuts: React.FC<Props> = ({
  setDateDropdownLabel,
  setDate,
  date,
}) => {
  const { t } = useTranslation();
  const updateDate = (value: DateRange<dayjs.Dayjs>) => {
    const start = value?.[0];
    const end = value?.[1];

    if (start !== null && end !== null) {
      // Add null checks here
      const label = getDateLabel(start, end, t);
      setDateDropdownLabel(label);
      setDate({
        startDate: start.format("YYYY-MM-DD"),
        endDate: end.format("YYYY-MM-DD"),
      });
    }
  };
  const [updatedShortCutItem, setUpdatedShortCutItem] = React.useState<
    PickersShortcutsItem<DateRange<Dayjs>>[]
  >([
    {
      label: t("dashboard.Today"),
      getValue: () => {
        const today = dayjs();
        return [today, today];
      },
    },
    {
      label: t("dashboard.Yesterday"),
      getValue: () => {
        const today = dayjs();
        return [today.subtract(1, "day"), today.subtract(1, "day")];
      },
    },
    {
      label: t("dashboard.This_Week"),
      getValue: () => {
        const today = dayjs();
        return [
          today.startOf("week").add(1, "day"),
          today.endOf("week").add(1, "day"),
        ];
      },
    },
    {
      label: t("dashboard.Last_Week"),
      getValue: () => {
        const today = dayjs();
        const prevWeek = today.subtract(7, "day");
        return [prevWeek.startOf("week"), prevWeek.endOf("week")];
      },
    },
    {
      label: t("dashboard.This_Month"),
      getValue: () => {
        const today = dayjs();
        return [today.startOf("month"), today.endOf("month")];
      },
    },
    {
      label: t("dashboard.Last_Month"),
      getValue: () => {
        const today = dayjs();
        const lastMonth = today.subtract(1, "month");
        return [lastMonth.startOf("month"), lastMonth.endOf("month")];
      },
    },
    {
      label: t("dashboard.This_Year"),
      getValue: () => {
        const today = dayjs();
        return [today.startOf("year"), today.endOf("year")];
      },
    },
    {
      label: t("dashboard.Last_Year"),
      getValue: () => {
        const today = dayjs();
        const lastYear = today.subtract(1, "year");
        return [lastYear.startOf("year"), lastYear.endOf("year")];
      },
    },
  ]);

  React.useEffect(() => {
    setUpdatedShortCutItem([
      {
        label: t("dashboard.Today"),
        getValue: () => {
          const today = dayjs();
          return [today, today];
        },
      },
      {
        label: t("dashboard.Yesterday"),
        getValue: () => {
          const today = dayjs();
          return [today.subtract(1, "day"), today.subtract(1, "day")];
        },
      },
      {
        label: t("dashboard.This_Week"),
        getValue: () => {
          const today = dayjs();
          return [
            today.startOf("week").add(1, "day"),
            today.endOf("week").add(1, "day"),
          ];
        },
      },
      {
        label: t("dashboard.Last_Week"),
        getValue: () => {
          const today = dayjs();
          const prevWeek = today.subtract(7, "day");
          return [prevWeek.startOf("week"), prevWeek.endOf("week")];
        },
      },
      {
        label: t("dashboard.This_Month"),
        getValue: () => {
          const today = dayjs();
          return [today.startOf("month"), today.endOf("month")];
        },
      },
      {
        label: t("dashboard.Last_Month"),
        getValue: () => {
          const today = dayjs();
          const lastMonth = today.subtract(1, "month");
          return [lastMonth.startOf("month"), lastMonth.endOf("month")];
        },
      },
      {
        label: t("dashboard.This_Year"),
        getValue: () => {
          const today = dayjs();
          return [today.startOf("year"), today.endOf("year")];
        },
      },
      {
        label: t("dashboard.Last_Year"),
        getValue: () => {
          const today = dayjs();
          const lastYear = today.subtract(1, "year");
          return [lastYear.startOf("year"), lastYear.endOf("year")];
        },
      },
    ]);
  }, [t]);

  return (
    <DateRangeWrapper>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <StaticDateRangePicker
          slotProps={{
            shortcuts: {
              items: updatedShortCutItem,
            },
            actionBar: { actions: [] },
          }}
          calendars={1}
          onChange={updateDate}
        />
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingX: "16px",
          }}
        >
          <Box sx={{ width: "46%" }}>
            <Typography sx={{ color: "#00000099", fontWeight: "600" }}>
              {t("dashboard.Start")}{" "}
            </Typography>
            <Typography
              sx={{
                border: "1px solid #EEEEEE",
                mt: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                height: "44px",
              }}
            >
              {date.startDate}
            </Typography>
          </Box>
          <Typography
            sx={{
              width: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            -
          </Typography>
          <Box sx={{ width: "46%" }}>
            <Typography sx={{ color: "#00000099", fontWeight: "600" }}>
              {t("dashboard.End")}{" "}
            </Typography>
            <Typography
              sx={{
                border: "1px solid #EEEEEE",
                mt: "12px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "8px",
                height: "44px",
              }}
            >
              {date.endDate}
            </Typography>
          </Box>
        </Box>
      </LocalizationProvider>
    </DateRangeWrapper>
  );
};

export default BasicRangeShortcuts;
