import { gql } from "@apollo/client";

export const TOP_CATEGORY_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query TopCategoriesReports {
        topCategoriesReports(
            toDate: "${toDate}"
            fromDate: "${fromDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}
        )
    }
  `;
};

export const CATEGORY_REPORTS = (
  fromDate: string,
  toDate: string,
  fromTime: string,
  toTime: string,
  locationId: number,
  employee: string
) => {
  const employeeParam = employee !== null ? `employee: "${employee}"` : "";
  return gql`
  query CategoriesReports {
        categoriesReports(
            fromDate: "${fromDate}"
            toDate: "${toDate}"
            fromTime:"${fromTime}"
            toTime:"${toTime}"
            locationId:${locationId},
            ${employeeParam}
        )
    }
  `;
};
