import * as React from "react";
import { useState } from "react";
import { useNavigate } from "react-router";
import { ChangeEvent } from "react";
import Box from "@mui/material/Box";

import { Button, TextField, InputAdornment, Typography } from "@mui/material";
import {
  Popover,
  List,
  ListItem,
  ListItemText,
  Divider,
  IconButton,
} from "@mui/material";
import { MoreVert, SearchRounded } from "@mui/icons-material";
import CircularLoader from "../../loader/loader";
import {
  DataGrid,
  GridColDef,
  GridRowSelectionModel,
  GridSortModel,
} from "@mui/x-data-grid";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useQuery, useMutation } from "@apollo/client";
import DeleteDailouge from "../category/deleteDailouge";
// import { PRODUCT_LIST_IN_BUNDLE } from '../../graphicalQl/usequery/bundleQuery';
import {
  DISCOUNT_EDIT_DATA,
  DISCOUNT_LIST,
} from "../../graphicalQl/usequery/discountQuery";
import { DISCOUNT_STATUS_CHANGE } from "../../graphicalQl/mutation/discountMutation";
import { DELETE_DISCOUNT } from "../../graphicalQl/mutation/discountMutation";
import { DUPLICATE_DISCOUNT } from "../../graphicalQl/mutation/discountMutation";
import { styled } from "@mui/system";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import CurrencyDisplay from "../utils/currencySymbol";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import PopupWithTitle from "../popups/popupWithTitle";
import CustomFooter from "../category/footer";

interface RowData {
  id: string;
  discountName: string;
  status: boolean;
  reason: string;
  amount: number;
  image: string;
  discountType: string;
  discountselectType: string;
  name: string;
  applyToAllItems: boolean;
}

const snakeToCamel = (str: string) =>
  str
    .toLowerCase()
    .replace(/([-_][a-z])/g, (group) =>
      group.toUpperCase().replace("-", " ").replace("_", " ")
    );

export default function BundleListing() {
  const { t } = useTranslation();

  // const CustomStyles = styled('div')({
  //   '&.MuiDataGrid-columnHeaderTitle': {
  //     fontWeight: '500',
  //   },
  //   '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
  //     outline: 'solid #1976d2 0px !important',
  //   },
  //   '& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within': {
  //     outline: 'solid #1976d2 0px !important',
  //   },
  // });

  const [rows, setRows] = useState<RowData[]>([]);
  const businessId: string = sessionStorage.getItem("businessId") as string;

  const [deletemodal, setDeleteModal] = useState(false);
  const [delteBundleId, setDeleteBundleId] = useState("");
  const handleDeleteModal = () => {
    setDeleteModal(!deletemodal);
    setDeleteBundleId("");
  };

  const getDeleteBundleId = (id: string) => {
    setDeleteBundleId(id);
  };
  const [endCursor, setEndCursor] = useState<string | null>(null);
  const [startCursor, setStartCursor] = useState<string | null>(null);
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 5,
  });
  const [currentPage, setCurrentPage] = React.useState(0);

  const handleEndCursor = () => {
    setStartCursor(null);
    setEndCursor(data?.discounts?.pageInfo.endCursor || null);
  };

  const handleStartCursor = () => {
    setEndCursor(null);
    setStartCursor(data?.discounts?.pageInfo.startCursor || null);
  };

  const handleEditNavigate = (id: string, value: string) => {
    navigate(`/discount/${id}/update-discounts`);
    let discountTypeFormat: string;
    // const discountType = sessionStorage.getItem("discountType")
    switch (value) {
      case "CUSTOMER_GROUP":
        discountTypeFormat = "customerGroup";
        break;
      case "BUY_N_GET_M":
        discountTypeFormat = "buy1Get1";
        break;
      case "EXACT_QUANTITY":
        discountTypeFormat = "exactQty";
        break;
      case "ITEM_OR_CATEGORY":
        discountTypeFormat = "forItem";
        break;
      case "FREE_DELIVERY":
        discountTypeFormat = "freeDiscount";
        break;
      case "MINIMUM_QUANTITY":
        discountTypeFormat = "minQty";
        break;
      default:
        discountTypeFormat = "";
        break;
    }

    sessionStorage.setItem(
      "discountType",
      discountTypeFormat && discountTypeFormat
    );
  };

  const [deleteDiscount] = useMutation(DELETE_DISCOUNT, {
    context: { clientName: "discountClient", method: "POST" },
    refetchQueries: [DISCOUNT_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.removed")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const snakeToPascal = (str: string) => {
    return str
      .split("_")
      .map(
        (word: any) =>
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
      )
      .join("");
  };
  const [duplicateDiscount] = useMutation(DUPLICATE_DISCOUNT, {
    context: { clientName: "discountClient", method: "POST" },
    refetchQueries: [DISCOUNT_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.duplicated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [discountStatusChange] = useMutation(DISCOUNT_STATUS_CHANGE, {
    context: { clientName: "discountClient", method: "POST" },
    refetchQueries: [DISCOUNT_LIST],
    onCompleted: () => {
      toast.success(
        <div>
          <Typography>{t("toast.updated")}</Typography>
        </div>,
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
          className: "custom_toast3",
        }
      );
    },
  });
  const [rowSelectionModel, setRowSelectionModel] =
    useState<GridRowSelectionModel>([]);

  const handleDelete = (id: string) => {
    deleteDiscount({ variables: { discountIds: JSON.stringify([+id]) } });
    setDeleteBundleId("");
  };
  const handleRowClass = () => {
    return "dataGrid-table-row";
  };
  const handleCellClass = () => {
    return "dataGrid-table-cell-less-wt";
  };

  function CustomPopoverCell({
    value,
    bundleId,
  }: {
    value: string;
    bundleId: string;
  }) {
    const handleDuplicate = (id: string) => {
      duplicateDiscount({ variables: { discountId: id } });
    };

    const handleHistoryNavigate = (id: string) => {
      navigate(`/discount/${id}/discount-history`);
    };
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? "simple-popover" : undefined;

    const popoverContent = (
      <List style={{ padding: "0px 0px 0px 0px" }}>
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleEditNavigate(bundleId, value);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="bundle-Listing-pover-text-style"
          >
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  {t("buttons.edit")}
                </Typography>
              }
            />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{ padding: "0px 0px 0px 0px", textTransform: "none" }}
          onClick={() => {
            handleDeleteModal();
            getDeleteBundleId(bundleId);
          }}
        >
          <ListItem
            style={{ width: "180px", height: "40px" }}
            className="menu-Listing-pover-text-style"
          >
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  {t("buttons.delete")}
                </Typography>
              }
            />
          </ListItem>
        </Button>
        <Divider />

        <Button
          style={{
            padding: "0px 0px 0px 0px",
            width: "100%",
            textTransform: "none",
          }}
          onClick={() => handleDuplicate(bundleId)}
        >
          <ListItem className="bundle-Listing-pover-text-style">
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  {t("buttons.duplicate")}
                </Typography>
              }
            />
          </ListItem>
        </Button>
        <Divider />
        <Button
          style={{
            padding: "0px 0px 0px 0px",
            width: "100%",
            textTransform: "none",
          }}
          onClick={() => handleHistoryNavigate(bundleId)}
        >
          <ListItem className="bundle-Listing-pover-text-style">
            <ListItemText
              primary={
                <Typography
                  sx={{ fontSize: "0.875rem", fontFamily: "Poppins" }}
                >
                  {t("selfMadeDiscount.offer_history")}
                </Typography>
              }
            />
          </ListItem>
        </Button>
      </List>
    );

    return (
      <Box>
        <div>
          <IconButton onClick={handleClick}>
            <MoreVert />
          </IconButton>

          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            style={{
              width: "180px",
              height: "225px",
              paddingTop: "0px",
              paddingBottom: "0px",
            }}
            elevation={8}
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }

  // const [id, setId] = useState<string | null>(null)
  // const { data: discountEditData } = useQuery(DISCOUNT_EDIT_DATA, {
  //   context: { clientName: "discountClient" }, variables: { discountId: id && +id }, fetchPolicy: "cache-and-network", onCompleted: (data) => {
  //   }
  // });

  // const [editData, setEditData] = useState<{ image: string, applyToAllItems: boolean, name: string, orderType: any[], orderAvailability: any[], paymentType: any[], deliveryZone: any[], discountUsageType: string, couponCode: string, endDate: string, startDate: string }>()

  // React.useEffect(() => {
  //   if (discountEditData && discountEditData.discount) {
  //     const list = {
  //       image: discountEditData.discount.image,
  //       applyToAllItems: discountEditData.discount.applyToAllItems,
  //       name: discountEditData.discount.name,
  //       orderType: JSON.parse(discountEditData.discount.orderType),
  //       orderAvailability: JSON.parse(discountEditData.discount.orderAvailability),
  //       paymentType: JSON.parse(discountEditData.discount.paymentType),
  //       deliveryZone: JSON.parse(discountEditData.discount.deliveryZone),
  //       discountUsageType: discountEditData.discount.discountUsageType,
  //       couponCode: discountEditData.discount.couponCode,
  //       startDate: discountEditData.discount.startDate,
  //       endDate: discountEditData.discount.endDate,
  //     }
  //     setEditData(list)
  //   }
  // }, [discountEditData])

  function CustomPopoverCell2({ value, id }: { value: string; id: string }) {
    const editData: any = rows.filter((each) => each.id === id);
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
      
    };

    const handlePopoverClose = () => {
      setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    let itemsText = editData[0].applyToAllItems
      ? "on  all items"
      : "on selected items";

    const popoverContent = (
      <Box sx={{ width: "300px", padding: "12px", gap: "8px" }}>
        <Box
          sx={{
            backgroundColor: "#EEEEEE",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "4px",
          }}
        >
          {editData[0].image && (
            <img
              src={editData[0] && editData[0].image}
              alt="discount"
              style={{ width: 60, height: 60 }}
            />
          )}
        </Box>
        <Box>
          <Typography className="dataGrid-table-cell">
            What the Clients get:
          </Typography>

          <Typography className="heavy-para-text">
            {editData[0].name} {itemsText}
          </Typography>

          <Typography className="dataGrid-table-cell">Condition :</Typography>
          {editData[0] && editData[0].orderType && (
            <Box sx={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
              <Typography className="heavy-para-text">orderType:</Typography>
              <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                {editData[0] &&
                  editData[0].orderType &&
                  editData[0].orderType.map((each: any) => (
                    <Typography className="heavy-para-text">
                      {` ${snakeToPascal(each)},  `}{" "}
                    </Typography>
                  ))}
              </Box>
            </Box>
          )}

          {editData[0] && editData[0].orderAvailability && (
            <Box sx={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
              <Typography className="heavy-para-text">
                orderAvailability:
              </Typography>
              <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                {editData[0] &&
                  editData[0].orderAvailability &&
                  editData[0].orderAvailability.map((each: any) => (
                    <Typography className="heavy-para-text">{each},</Typography>
                  ))}
              </Box>
            </Box>
          )}
          {editData[0] && editData[0].paymentType && (
            <Box sx={{ display: "flex", gap: "2px", flexWrap: "wrap" }}>
              <Typography className="heavy-para-text">paymentType:</Typography>
              <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
                {editData[0] &&
                  editData[0].paymentType &&
                  editData[0].paymentType.map((each: any) => (
                    <Typography className="heavy-para-text">{each},</Typography>
                  ))}
              </Box>
            </Box>
          )}
          {/* {editData && editData.deliveryZone &&
            <Box sx={{ display: 'flex', gap: "2px", flexWrap: "wrap" }}>
              <Typography className='discount-pop-para-text'>deliveryZone:</Typography>
              {editData && editData.deliveryZone && editData.deliveryZone.map((each) => (<Typography className='discount-pop-para-text'>{each},</Typography>))}
            </Box>
          } */}
          <Typography className="heavy-para-text">
            {editData[0] &&
              editData[0].discountUsageType &&
              snakeToPascal(editData[0] && editData[0].discountUsageType)}
          </Typography>

          <Typography className="dataGrid-table-cell">
            Who gets the Promo deal :
          </Typography>
          {editData[0] && editData[0].couponCode && (
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              <Typography className="heavy-para-text">Cupon code :</Typography>

              <Typography className="heavy-para-text">
                {editData[0] && editData[0].couponCode}
              </Typography>
            </Box>
          )}
          {editData[0] && editData[0].startDate && (
            <Box sx={{ display: "flex", gap: "4px", flexWrap: "wrap" }}>
              <Typography className="heavy-para-text">From :</Typography>

              <Typography className="heavy-para-text">
                {editData && editData[0].startDate} to{" "}
                {editData && editData[0].endDate}
              </Typography>
            </Box>
          )}
        </Box>
      </Box>
    );

    return (
      <Box>
        <div>
          <IconButton
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}
          >
            <VisibilityRoundedIcon />
          </IconButton>

          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: "none",
            }}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            onClose={handlePopoverClose}
            // disableRestoreFocus
          >
            {popoverContent}
          </Popover>
        </div>
      </Box>
    );
  }
  // const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {

  //   const status = event.target.checked ? "Active" : "InActive"
  //   discountStatusChange({ variables: { discountId: +id, discountStatus: status } })

  // };

  const handleDeleteClick = () => {
    // Filter out the selected rows from the current rows state

    const updatedRows = rows.filter((row) =>
      rowSelectionModel.includes(row.id)
    );

    const discountIds = updatedRows.map((each) => +each.id);

    deleteDiscount({ variables: { discountIds: JSON.stringify(discountIds) } });

    setRowSelectionModel([]);
  };
  const [isModalOpen1, setIsModalOpen1] = React.useState<boolean>(false);
  const [statusId, setStatusId] = useState<number | null>(null);
  const [statusState, setStatusState] = useState<boolean>(false);

  const handleStatus = (event: ChangeEvent<HTMLInputElement>, id: number) => {
    setStatusId(id);
    setStatusState(event.target.checked);
  };

  const handleStatusCall = () => {
    const status = statusState ? "Active" : "InActive";
    discountStatusChange({
      variables: { discountId: statusId && +statusId, discountStatus: status },
    });
  };

  const columns: GridColDef[] = [
    {
      field: "discountName",
      //@ts-ignore
      headerName: t("selfMadeDiscount.discount_name"),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      flex: 1.5,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", flexDirection: "row" }}
          >
            <CustomPopoverCell2
              value={params.row.discountType}
              id={params.row.id}
            />
            {params.row.image && (
              <Box className="bundle_image_container">
                <img
                  src={params.row.image}
                  alt="bundle"
                  style={{ width: "36px", height: "36px" }}
                />
              </Box>
            )}

            <Typography
              className="dataGrid-table-cell-less-wt"
              sx={{ ml: "8px" }}
            >
              {params.value}
            </Typography>
          </Box>
        );
      },
    },
    {
      field: "discountType",
      //@ts-ignore
      headerName: t("selfMadeDiscount.discount_type"),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography className="dataGrid-table-cell-less-wt">
            {snakeToCamel(params.value)}
          </Typography>
        );
      },
    },

    {
      field: "reason",
      //@ts-ignore
      headerName: t("selfMadeDiscount.reason"),
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,
      flex: 1,
      disableColumnMenu: true,
      renderCell: (params) => {
        return (
          <Typography className="dataGrid-table-cell-less-wt">
            {params.value}
          </Typography>
        );
      },
    },
    {
      field: "status",
      //@ts-ignore
      headerName: t("selfMadeDiscount.status"),
      cellClassName: "tableCellInCategory",
      headerClassName: "table-column-header ",
      align: "center",
      headerAlign: "center",
      hideSortIcons: true,

      flex: 1,
      disableColumnMenu: true,

      renderCell: (params) => {
        return (
          <Switch
            checked={params.row.status}
            onChange={(event) => {
              handleStatus(event, params.row.id);
              setIsModalOpen1(true);
            }}
            color="warning"
          />
        );
      },
    },
    {
      field: "amount",
      //@ts-ignore
      headerName: t("selfMadeDiscount.Discount"),
      align: "center",
      hideSortIcons: true,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",

      headerAlign: "center",
      disableColumnMenu: true,
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography className="dataGrid-table-cell-less-wt">

            {params.row.discountselectType ===null?'null':params.row.discountselectType === "PERCENTAGE" ? (
              `${params.value} %`
            ) : (
              <CurrencyDisplay
                countryName={"UnitedKingdom"}
                amount={+params.value}
              />
            )}
          </Typography>
        );
      },
    },
    {
      field: "k",
      //@ts-ignore
      headerName: t("buttons.actions"),
      disableColumnMenu: true,
      flex: 0.5,
      headerClassName: "table-column-header ",
      cellClassName: "tableCellInCategory",

      filterable: false,
      align: "center",
      hideSortIcons: true,
      renderCell: (params) => {
        return (
          <CustomPopoverCell
            value={params.row.discountType}
            bundleId={params.row.id}
          />
        );
      },
    },
  ];

  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/discount/discount");
  };
  const [searchValue, setSearchValue] = useState<string | null>(null);
  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };
  const { locationId } = useSelector((state: any) => state.headerLocation);

  const { loading, data } = useQuery(DISCOUNT_LIST, {
    context: { clientName: "discountClient" },
    variables: {
      name_Icontains: searchValue,
      businessId: +businessId,
      isPremadeDiscount: false,
      location: locationId.toString(),
      first: !startCursor ? paginationModel.pageSize : null,
      after: endCursor,
      before: startCursor,
      last: startCursor && paginationModel.pageSize,
    },
    fetchPolicy: "cache-and-network",
    onCompleted: (data) => {},
  });
  React.useEffect(() => {
    if (data && data.discounts) {
      const list = data.discounts.edges.map((each: any) => ({
        id: each.node.discountId,
        discountName: each.node.name,
        status: each.node.discountStatus === "Active" ? true : false,
        reason: each.node.reason && each.node.reason.reason,
        image: each.node.image,
        amount: each.node.amount,
        discountType: each.node.applyDiscountTo,
        discountselectType: each.node.discountType,
        applyToAllItems: each.node.applyToAllItems,
        name: each.node.name,
        orderType: JSON.parse(each.node.orderType),
        orderAvailability: JSON.parse(each.node.orderAvailability),
        paymentType: JSON.parse(each.node.paymentType),
        deliveryZone: JSON.parse(each.node.deliveryZone),
        discountUsageType: each.node.discountUsageType,
        couponCode: each.node.couponCode,
        startDate: each.node.startDate,
        endDate: each.node.endDate,
      }));

      setRows(list);
    }
  }, [data]);

  // const [productFromApi, setProductFromApi] = useState<{ id: string, name: string }[]>([])

  // const { data: productData } = useQuery(PRODUCT_LIST_IN_BUNDLE, { context: { clientName: "categoryClient" }, variables: { businessId: +businessId, locationId: 6 } })
  // React.useEffect(() => {
  //   if (productData && productData.productList) {
  //     const productList = productData.productList.edges.map((each: any) => ({
  //       id: each.node.productId,
  //       name: each.node.name
  //     }));

  //     setProductFromApi(productList)

  //   }

  // }, [productData]);

  const [sortModel, setSortModel] = useState<GridSortModel>([
    {
      field: "bundleName",
      sort: "asc",
    },
  ]);

  // ... your other state variables and functions ...

  const handleSortModelChange = (newSortModel: GridSortModel) => {
    setSortModel(newSortModel);
  };

  return (
    <Box sx={{ backgroundColor: "#FEFEFE", padding: "12px" }}>
      <Typography className="main-head" sx={{ ml: "20px" }}>
        {t("selfMadeDiscount.self_made_discounts")}
      </Typography>
      <Divider sx={{ mt: "12px", mb: "12px" }} />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          mb: "12px",
          ml: "20px",
        }}
      >
        <TextField
          id="input-with-icon-textfield"
          label={t("menu.bundle.search")}
          size="small"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchRounded />
              </InputAdornment>
            ),
          }}
          variant="outlined"
          color="warning"
          value={searchValue}
          onChange={handleSearch}
          sx={{ width: "24%" }}
        />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-evenly",
            gap: "0.625rem",
          }}
        >
          <Button
            className="main-button"
            sx={{ textTransform: "none" }}
            onClick={handleNavigation}
          >
            {" "}
            {t("selfMadeDiscount.create_discount")}{" "}
          </Button>
          {rowSelectionModel.length > 0 && (
            <Button
              className="main-button"
              sx={{ textTransform: "none" }}
              onClick={() => {
                handleDeleteModal();
              }}
            >
              {t("menu.bundle.delete")}
            </Button>
          )}
        </Box>
      </Box>
      <DeleteDailouge
        deletemodal={deletemodal}
        handleDeleteModal={handleDeleteModal}
        handleDleteByPopover={handleDelete}
        id={delteBundleId}
        handleDeleteClick={handleDeleteClick}
        type="Discount"
      />
      {/* <CustomStyles> */}
      <Box sx={{ width: "98%", marginLeft: "20px", maxHeight: "70vh" }}>
        {loading ? (
          <CircularLoader />
        ) : (
          <DataGrid
            rows={rows}
            getRowClassName={handleRowClass}
            getCellClassName={handleCellClass}
            sortModel={sortModel}
            columnHeaderHeight={48}
            rowHeight={48}
            columns={columns}
            pageSizeOptions={[5, 15, 25]}
            pagination
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: paginationModel.pageSize,
                },
              },
            }}
            rowCount={data?.discounts?.totalCount || 0}
            paginationModel={paginationModel}
            onPaginationModelChange={(newModel) => {
              let shouldUpdatePaginationModel = true;

              if (newModel.pageSize !== paginationModel.pageSize) {
                setStartCursor(null);
                setEndCursor(null);
                setCurrentPage(0);
                setPaginationModel({
                  ...newModel,
                  page: 0,
                });
                shouldUpdatePaginationModel = false; // Prevent further updates within this call
              } else if (newModel.page > currentPage) {
                handleEndCursor();
              } else if (newModel.page < currentPage) {
                handleStartCursor();
              }

              // Update the current page

              // Only update paginationModel here if it wasn't updated above
              if (shouldUpdatePaginationModel) {
                setPaginationModel(newModel);
                setCurrentPage(newModel.page);
              }
            }}
            paginationMode="server"
            components={{
              Footer: (props) => (
                <CustomFooter
                  totalRowCount={Math.ceil(
                    (data?.discounts?.totalCount || 0) /
                      paginationModel.pageSize
                  )}
                  {...props}
                  page={paginationModel.page}
                />
              ),
            }}
            onSortModelChange={handleSortModelChange}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={(
              newRowSelectionModel: GridRowSelectionModel
            ) => {
              setRowSelectionModel(newRowSelectionModel);
            }}
            rowSelectionModel={rowSelectionModel}
            sx={{
              "&.MuiDataGrid-columnHeaderTitle": {
                fontWeight: "500",
              },
              "& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus": {
                outline: "solid #1976d2 0px !important",
              },
              "& .MuiDataGrid-columnHeader:focus-within, .MuiDataGrid-cell:focus-within":
                {
                  outline: "solid #1976d2 0px !important",
                },
              "& .MuiDataGrid-columnHeader": {
                background: "#f5f5f5",
              },
              // marginLeft: "20px",
              ".MuiTablePagination-displayedRows": {
                display: "none", // 👈 to hide huge pagination number
              },
            }}
          />
        )}
      </Box>
      {/* </CustomStyles> */}

      <PopupWithTitle
        open={isModalOpen1}
        functionForCloseIcon={() => setIsModalOpen1(false)}
        title="status change"
        mainText="Are you sure you want to change the status of the Discount?"
        button1Text="cancel"
        button2Text="Yes Change"
        popUpwidth="700px"
        functionForButton1={() => setIsModalOpen1(false)}
        functionForButton2={() => {
          setIsModalOpen1(false);
          handleStatusCall();
        }}
        widthForTitle="60%"
        button1Variant="contained"
        button2Variant="contained"
        widthForButtons="100%"
        button1Style="main-button-red"
        button2Style="main-button"
        titleStyle="menu-status-title"
      />
    </Box>
  );
}
