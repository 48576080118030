import * as React from "react";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { Box, TextField, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

interface State {
  formik: any;
}

export default function TimePickerValue(props: State) {
  const { formik } = props;
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: "flex",
        width: "85%",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Box sx={{ width: "40%" }}>
        <Typography>{t("dashboard.Start_Time")}</Typography>
        <TextField
          color="warning"
          type="time"
          disabled={!formik.values.blockBookingsToggle}
          value={formik.values.startTime}
          name="startTime"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          inputProps={{
            step: 300, // 5 minutes interval
          }}
          onBlur={formik.handleBlur}
          sx={{ padding: "0px !important", minWidth: "150px !important" }}
        />
      </Box>
      <Box sx={{ width: "40%" }}>
        <Typography>{t("dashboard.End_Time")}</Typography>
        <TextField
          color="warning"
          type="time"
          disabled={!formik.values.blockBookingsToggle}
          value={formik.values.endTime}
          name="endTime"
          onChange={(e) => {
            formik.handleChange(e);
          }}
          onBlur={formik.handleBlur}
          sx={{ padding: "0px !important", minWidth: "150px !important" }}
        />
      </Box>
    </Box>

    // <LocalizationProvider dateAdapter={AdapterDayjs}>
    //   <DemoContainer components={["TimePicker", "TimePicker"]}>
    //     <div onBlur={(e) => formik.handleBlur(e)}>
    //       <TimePicker
    //         sx={{ padding: "0px !important", minWidth: "150px !important" }}
    //         label="Start Time"
    //         value={formik.values.startTime}
    //         name="startTime"
    //         onChange={(newValue) => handleStartTimeChange(newValue)}
    //         // onChange={(newValue) => {
    //         //   formik.setFieldValue("startTime", newValue);
    //         // }}
    //       />
    //     </div>
    //     <div onBlur={(e) => formik.handleBlur(e)}>
    //       <TimePicker
    //         sx={{ padding: "0px !important", minWidth: "150px !important" }}
    //         label="End Time"
    //         name="endTime"
    //         value={formik.values.endTime}
    //         // onChange={(newValue) => {
    //         //   formik.setFieldValue("endTime", newValue);
    //         // }}
    //         onChange={(newValue) => handleEndTimeChange(newValue)}
    //       />
    //     </div>
    //   </DemoContainer>
    // </LocalizationProvider>
  );
}
